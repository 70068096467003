// used for api call (sort is string formatted orderbyf(asc|desc)
export interface Pageable {
  page: number
  per_page: number
  sort?: string
}

export interface PagingParams {
  page: number
  perPage: number
  direction?: Direction
  orderBy?: string
}

export interface SortParams {
  direction: Direction
  orderBy: string
}

export enum Direction {
  ASC = 'asc',
  DESC = 'desc',
}

export const singlePageParams: PagingParams = {
  page: 0,
  perPage: 1,
  direction: Direction.DESC,
}
export const incrementNumber = (num: number): number => num + 1

export const decrementNumber = (num: number): number => (num > 0 ? num - 1 : 0)

export const createSortString = (
  orderBy: string,
  direction: Direction,
): string => {
  return `${orderBy}(${direction})`
}

export const getOrderByFromSort = (sort: string = ''): string => {
  const parts = sort.split('(')
  return parts[0]
}

export const getDirectionFromSort = (sort: string = ''): Direction => {
  return sort.indexOf(`(${Direction.DESC})`) > -1
    ? Direction.DESC
    : Direction.ASC
}

export const toggleDirectionInSort = (
  sort: string,
  key: string,
  defaultSortDirection: Direction = Direction.ASC,
) => {
  const direction = getDirectionFromSort(sort)
  const orderBy = getOrderByFromSort(sort)

  if (orderBy === key) {
    return createSortString(
      orderBy,
      direction === Direction.ASC ? Direction.DESC : Direction.ASC,
    )
  }

  return createSortString(key, defaultSortDirection)
}

export const getPageable = (params: PagingParams): Pageable => {
  let sort
  const { orderBy, direction } = params
  if (orderBy) {
    const dir = direction || Direction.ASC
    sort = createSortString(orderBy, dir)
  }
  return {
    page: incrementNumber(params.page),
    per_page: params.perPage,
    sort,
  }
}

export const getSorts = (sortParams: SortParams[]): string | undefined => {
  if (!sortParams || !sortParams.length) {
    return undefined
  }
  return sortParams
    .map((p) => {
      return createSortString(p.orderBy, p.direction)
    })
    .join(',')
}

export const hasPagingParamsChanged = (
  params: PagingParams,
  prevParams: PagingParams,
) => {
  if (!params && !prevParams) {
    return false
  } else if ((!prevParams && params) || (prevParams && !params)) {
    return true
  } else {
    return (
      prevParams.orderBy !== params.orderBy ||
      prevParams.direction !== params.direction ||
      prevParams.page !== params.page ||
      prevParams.perPage !== params.perPage
    )
  }
}

export const getTableRows = (data: any[], page: number, perPage: number) => {
  const begin = page * perPage
  const end = begin + perPage

  return data.slice(begin, end)
}
