import startCase from 'lodash/fp/startCase'
import toLower from 'lodash/fp/toLower'

import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import CloseIcon from '@mui/icons-material/Close'

import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import { PAGINATION } from 'components/common/EnhancedTable/EnhancedTablePagination'
import useTable from 'components/common/EnhancedTable/useTable'
import Text from 'components/common/Text'
import ReportProgress from './ReportProgress'

import { DATE_DISPLAY_FORMAT_TIME, formatDate } from 'services/dateService'

import { Report, ReportStatus } from 'types/Report'

import DownloadReportButton from './DownloadReportButton'

const StyledReportTableRow = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
})

const StyledSupportingText = styled(Text)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}))

const StyledReportProgress = styled('div')(({ theme }) => ({
  barColor: 'primary',
  marginLeft: theme.spacing(1),
}))

interface Props {
  sellerId: string | undefined
  reports?: Report[]
  isLoading: boolean
  title: string
  isDisabled: boolean
  onRequestCancel: ({
    sellerId,
    reportId,
  }: {
    sellerId: string | undefined
    reportId: string
  }) => () => void
  completedText?: string
}

const INIT_PAGE = 0
const INIT_PER_PAGE = 5

export const ReportTable = ({
  sellerId,
  reports = [],
  isLoading,
  title,
  isDisabled,
  onRequestCancel,
  completedText,
}: Props) => {
  const formatDownloadUrl = (item: Report) => {
    let fileDetails
    if (item.type === 'ORDER_DEFECTS') {
      let defectTypes = item?.parameters?.order_defect_types?.map(
        (defectType) => {
          return startCase(toLower(defectType))
        },
      )

      fileDetails = `: ${defectTypes?.join(', ')}`
    }
    if (
      item.status === ReportStatus.PENDING ||
      item.status === ReportStatus.PROCESSING
    ) {
      return (
        <StyledReportTableRow>
          <Grid item xs={11} style={{ paddingRight: '8px' }}>
            <StyledSupportingText
              type="micro"
              data-testid="report-generating-text"
            >
              Generating{fileDetails}
            </StyledSupportingText>
            <StyledReportProgress>
              <ReportProgress />
            </StyledReportProgress>
          </Grid>
          <Grid item xs={1}>
            <div style={{ margin: 0, width: 'min-content' }}>
              <Tooltip
                data-testid="cancel-button"
                title="Cancel report"
                placement="bottom-start"
              >
                <div>
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={onRequestCancel({
                      sellerId,
                      reportId: item.id,
                    })}
                    data-testid="cancel-icon-button"
                  >
                    <CloseIcon color="primary" />
                  </IconButton>
                </div>
              </Tooltip>
            </div>
          </Grid>
        </StyledReportTableRow>
      )
    } else if (item.status === ReportStatus.CANCELED) {
      return <Text type="micro">Cancelled{fileDetails}</Text>
    } else if (item.download_url) {
      return (
        <StyledReportTableRow>
          <Grid item xs={11} style={{ paddingRight: '8px' }}>
            <StyledSupportingText type="micro" data-testid="complete-text">
              {completedText || 'Complete'}
              {fileDetails}
            </StyledSupportingText>
            <StyledReportProgress>
              <ReportProgress status={ReportStatus.COMPLETE} />
            </StyledReportProgress>
          </Grid>
          <Grid item xs={1}>
            <DownloadReportButton
              text="Download"
              report={item}
              isDisabled={isDisabled}
              title={title}
              hasDateRange={false}
            />
          </Grid>
        </StyledReportTableRow>
      )
    } else if (item.status === ReportStatus.ERROR) {
      return (
        <StyledReportTableRow>
          <Grid item xs={11}>
            <StyledSupportingText type="micro" data-testid="report-error-text">
              {item.feedback && item.feedback.join()}
            </StyledSupportingText>
            <StyledReportProgress>
              <ReportProgress status={ReportStatus.ERROR} />
            </StyledReportProgress>
          </Grid>
        </StyledReportTableRow>
      )
    }
  }

  const fieldList: EnhancedTableFieldType<Report>[] = [
    {
      key: 'report_name',
      heading: 'Name',
    },
    {
      key: 'download_url',
      heading: 'Status',
      formatCell: formatDownloadUrl,
    },
    {
      key: 'last_modified',
      formatCell: ({ status, created }) => {
        const preface =
          status === ReportStatus.COMPLETE || status === ReportStatus.ERROR
            ? 'Generated'
            : 'Created'
        return `${preface} ${formatDate(created, DATE_DISPLAY_FORMAT_TIME)}`
      },
    },
  ]

  const { table } = useTable({
    page: INIT_PAGE,
    perPage: INIT_PER_PAGE,
  })

  const getPage = () => {
    const begin = table.state.page * INIT_PER_PAGE
    const end = begin + INIT_PER_PAGE

    return reports.slice(begin, end)
  }

  const rows = getPage()

  return (
    <EnhancedTable
      data-testid="report-card-table"
      isLoading={isLoading}
      data={rows}
      fieldList={fieldList}
      total={reports.length}
      rowsPerPage={table.state.perPage}
      page={table.state.page}
      paginationDisplayLocation={PAGINATION.BOTTOM}
      onChangePage={table.actions.changePage}
    />
  )
}

export default ReportTable
