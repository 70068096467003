import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import { getEnhancedTablePageableProps } from 'components/common/FilterBar/useSearchParams'
import { formatReportParameters } from './formatReportParameters'
import { formatReportType } from './formatReportType'
import DownloadReportTableCell from './DownloadReportTableCell'

import { Report } from 'types/Report'

export type Props = {
  memberOf: string[]
  isLoading: boolean
  data: Report[]
  total: number
  onRequestCancelReport: ({
    sellerId,
    reportId,
  }: {
    sellerId: string
    reportId: string
  }) => void
} & ReturnType<typeof getEnhancedTablePageableProps>

export const GeneratedReportsTable = ({
  memberOf,
  isLoading,
  data,
  total,
  onRequestCancelReport,
  ...pageableProps
}: Props) => {
  const fieldList: EnhancedTableFieldType<Report>[] = [
    {
      key: 'type',
      heading: 'Template Used',
      formatCell: formatReportType,
    },
    {
      key: '',
      heading: 'Report Filters',
      formatCellAsync: async (report: Report) => {
        const result = await formatReportParameters(report)

        if (Array.isArray(result)) {
          return (
            <>
              {result.map((str: string, index) => (
                <div key={index}>{str}</div>
              ))}
            </>
          )
        }

        return result
      },
    },
    {
      key: 'status',
      heading: 'Generated On',
      formatCell: (report: Report) => {
        return (
          <DownloadReportTableCell
            memberOf={memberOf}
            report={report}
            onRequestCancelReport={onRequestCancelReport}
          />
        )
      },
    },
  ]

  return (
    <EnhancedTable
      data-testid="seller-report-table"
      isLoading={isLoading}
      data={data}
      fieldList={fieldList}
      total={total}
      page={pageableProps.page}
      rowsPerPage={pageableProps.rowsPerPage}
      order={pageableProps.order}
      orderBy={pageableProps.orderBy}
      onChangePage={pageableProps.onChangePage}
      onChangeRowsPerPage={pageableProps.onChangeRowsPerPage}
      onRequestSort={pageableProps.onRequestSort}
    />
  )
}

export default GeneratedReportsTable
