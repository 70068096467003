import Typography from '@mui/material/Typography'
import { TypographyVariant } from '@mui/material/styles'
import { Address as AddressType } from 'types/Address'

interface Props {
  firstName?: string
  lastName?: string
  companyName?: string
  address: AddressType
  variant?: TypographyVariant
  className?: string
}

const makeElem = (
  val: string | undefined,
  testLabel: string,
  variant: TypographyVariant,
) =>
  val && (
    <Typography data-testid={testLabel} variant={variant}>
      {val}
    </Typography>
  )

export const Address = ({
  firstName = '',
  lastName = '',
  companyName = '',
  address,
  variant = 'body2',
}: Props) => {
  const fullName = [firstName, lastName].filter((i) => i).join(' ')
  const cityStateZip = [
    address.city,
    [address.state, address.postalCode].filter((i) => i).join(' '),
  ]
    .filter((i) => i)
    .join(', ')

  return (
    <div>
      {makeElem(fullName, 'fullName', variant)}
      {makeElem(companyName, 'companyName', variant)}
      {makeElem(address.address1, 'address1', variant)}
      {makeElem(address.address2, 'address2', variant)}
      {makeElem(cityStateZip, 'city-state-zip', variant)}
      {makeElem(address.countryCode, 'country-code', variant)}
    </div>
  )
}

export default Address
