import React from 'react'
import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import {
  formatDateMDYT,
  formatPercent,
} from 'components/common/EnhancedTable/formatters'
import useTable from 'components/common/EnhancedTable/useTable'

import { Direction } from 'services/pageableHelper'
import { getReferralBasePercentages } from 'services/referralBasePercentages'

import { ReferralBasePercentage } from 'types/BaseReferralFee'
import { CollectionResponse } from 'types/Response'

const StyledGridContainer = styled(Grid)({
  maxHeight: 300,
  overflow: 'auto',
})

interface Props {
  isOpen: boolean
  subtypeId: string
  subtypeName: string
}

const fieldList: EnhancedTableFieldType<ReferralBasePercentage>[] = [
  {
    key: 'start_date',
    heading: 'Start Date',
    hasSort: true,
    formatCell: formatDateMDYT('start_date'),
  },
  {
    key: 'referral_percentage',
    heading: 'Base Fee',
    hasSort: true,
    formatCell: formatPercent('referral_percentage'),
  },
]

export const BaseReferralFeeChangeHistory = ({
  isOpen,
  subtypeId,
  subtypeName,
}: Props) => {
  const [pending, setPending] = React.useState(false)
  const [referralFees, setReferralFees] = React.useState<
    ReferralBasePercentage[]
  >([])

  const { table } = useTable({
    direction: Direction.DESC,
    orderBy: 'start_date',
    page: 0,
    perPage: 20,
  })

  React.useEffect(() => {
    setPending(true)
    getReferralBasePercentages(table.state, false, subtypeId).then(
      (res: CollectionResponse<ReferralBasePercentage>) => {
        setReferralFees(res.data)
        setPending(false)
      },
    )
  }, [table.state, subtypeId])

  return (
    <DialogContainer title="Change History" isOpen={isOpen} isPending={pending}>
      <StyledGridContainer container>
        <Grid item xs={12}>
          <Typography>{subtypeName}</Typography>
        </Grid>
        <Grid item xs={12}>
          <EnhancedTable
            isLoading={pending}
            total={20}
            data={referralFees}
            fieldList={fieldList}
            onRequestSort={table.actions.sort}
            orderBy={table.state.orderBy}
            order={table.state.direction}
            paginationDisabled
          />
        </Grid>
      </StyledGridContainer>
    </DialogContainer>
  )
}

export default BaseReferralFeeChangeHistory
