import { useEffect, useReducer } from 'react'
import { useParams } from 'react-router-dom'

import styled from '@emotion/styled'
import ShoppingCardIcon from '@mui/icons-material/ShoppingCart'

import DisplayCard from 'components/common/DisplayCard'
import Link from 'components/common/Link'
import Text from 'components/common/Text'
import DataTiles from 'components/common/DataTiles'

import { GENERIC_API_ERROR } from 'constants/errors'

import { RoutePath } from 'services/NavigationHelper'
import {
  isoStringStartOfDay,
  isoStringEndOfDay,
  endOfYesterday,
  formatDate,
  DATE_DISPLAY_FORMAT,
} from 'services/dateService'
import { formatLocaleNumber } from 'services/formatNumber'
import { getOrderCount } from 'services/orders'
import { createQueryString } from 'services/urlHelper'

import { OrderStatus } from 'types/Orders'

import {
  fetchUnshippedData,
  initialState,
  reducer,
  rejectUnshippedData,
  resolveUnshippedData,
} from './reducer'

const StyledDiv = styled.div(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  padding: theme.spacing(2.5, 0),
}))

const unshippedStatuses = [
  OrderStatus.PARTIALLY_SHIPPED,
  OrderStatus.ACKNOWLEDGED_BY_SELLER,
]

const displayDate = (date: string) =>
  decodeURIComponent(`${formatDate(date, DATE_DISPLAY_FORMAT)}`)

const now = Date.now()

const generateDataItem = (
  sellerId: Nullable<string>,
  title: string,
  value: number,
  dates: Dictionary<string>,
) => {
  const pathname = sellerId
    ? `/${sellerId}${RoutePath.ORDERS}`
    : RoutePath.ALL_ORDERS

  return {
    title,
    element: (
      <Link
        to={{
          pathname,
          search: createQueryString({
            order_status: unshippedStatuses,
            ...dates,
          }),
        }}
      >
        {formatLocaleNumber(value)}
      </Link>
    ),
  }
}

export const UnshippedOrdersCard = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { sellerId } = useParams()

  useEffect(() => {
    let canceled = false
    dispatch(fetchUnshippedData())

    Promise.all([
      getOrderCount({
        seller_id: sellerId,
        order_status: unshippedStatuses,
        requested_shipment_date: `${isoStringStartOfDay(
          now,
        )}/${isoStringEndOfDay(now)}`,
      }),
      getOrderCount({
        seller_id: sellerId,
        order_status: unshippedStatuses,
        requested_shipment_date: `/${isoStringStartOfDay(now)}`,
      }),
      getOrderCount({
        seller_id: sellerId,
        order_status: unshippedStatuses,
      }),
    ])
      .then(([shipByToday, pastDue, total]) => {
        if (canceled) return

        dispatch(resolveUnshippedData(shipByToday, pastDue, total))
      })
      .catch(() => {
        if (canceled) return

        dispatch(rejectUnshippedData())
      })

    return () => {
      canceled = true
    }
  }, [sellerId])

  const unshippedOrderData = [
    generateDataItem(sellerId, 'To Be Shipped By Today', state.shipByToday, {
      requested_ship_start_date: displayDate(isoStringStartOfDay(now)),
      requested_ship_end_date: displayDate(isoStringEndOfDay(now)),
    }),
    generateDataItem(sellerId, 'Unshipped Past Due Date', state.pastDue, {
      requested_ship_end_date: displayDate(
        isoStringEndOfDay(endOfYesterday(now)),
      ),
    }),
  ]

  return (
    <DisplayCard
      iconColor="target"
      title={`Total Unshipped: ${state.total.toLocaleString()}`}
      icon={ShoppingCardIcon}
      isLoading={state.loading}
      hasWarning={state.error}
      warningTooltip="An error occurred while getting the information."
    >
      {state.error ? (
        <Text>{GENERIC_API_ERROR}</Text>
      ) : (
        <StyledDiv data-testid="data-tiles">
          <DataTiles data={unshippedOrderData} tileSize={6} />
        </StyledDiv>
      )}
    </DisplayCard>
  )
}

export default UnshippedOrdersCard
