import TextField from '@mui/material/TextField'

import { EnhancedTableFieldType } from 'components/common/EnhancedTable'

import formatCurrency from 'services/formatCurrency'

import { NewShippingRatesTableRow } from 'types/ShippingRates'

export const getTableFields = (
  updateLocalFutureRates: (newRate: NewShippingRatesTableRow) => void,
): EnhancedTableFieldType<NewShippingRatesTableRow>[] => {
  return [
    {
      key: 'min_pounds',
      heading: 'Start Weight (lbs)',
    },
    {
      key: 'maxPounds',
      heading: 'End Weight (lbs)',
    },
    {
      key: 'rateDescription',
      heading: 'Fee Description',
    },
    {
      key: 'currentRateInPennies',
      heading: 'Current Service Fees',
      formatCell: (rate) => {
        if (typeof rate.currentRateInPennies === 'number') {
          return formatCurrency(rate.currentRateInPennies / 100)
        } else {
          return ''
        }
      },
    },
    {
      key: 'futureRateText',
      heading: 'Future Service Fees *',
      formatCell: (rate) => {
        const onBlur = () => {
          if (rate.futureRateText === undefined) return

          if (rate.futureRateText === '') {
            updateLocalFutureRates({
              ...rate,
              futureRateText: undefined,
            })
            return
          }

          updateLocalFutureRates({ ...rate, isPending: true })

          const newRate: NewShippingRatesTableRow = {
            ...rate,
            futureRateInPennies: parseFloat(rate.futureRateText) * 100,
            futureRateText: undefined,
            isPending: false,
          }
          if (Number.isNaN(newRate.futureRateInPennies)) {
            newRate.futureRateInPennies = rate.futureRateInPennies
            updateLocalFutureRates(newRate)
            return
          }

          updateLocalFutureRates(newRate)
        }

        const onChange = (event: any) => {
          let value = event.currentTarget.value as string
          if (value[0] === '$') {
            value = value.substring(1)
          }
          updateLocalFutureRates({
            ...rate,
            futureRateText: value,
            futureRateInPennies: parseFloat(value) * 100,
          })
        }

        let value =
          typeof rate.futureRateInPennies === 'number' &&
          !isNaN(rate.futureRateInPennies)
            ? formatCurrency(rate.futureRateInPennies / 100)
            : ''

        if (rate.futureRateText !== undefined) {
          value = rate.futureRateText
        }

        return <TextField value={value} onChange={onChange} onBlur={onBlur} />
      },
    },
  ]
}
