import { useEffect } from 'react'

import * as yup from 'yup'
import { withFormik, Form, FormikProps } from 'formik'

import { white } from 'config/themeConfig'

import styled from '@emotion/styled'

import Grid from '@mui/material/Grid'
import SearchIcon from '@mui/icons-material/Search'

import InputField from 'components/common/form/InputField'

import { ValidationSchema } from 'services/validation'

interface FormValues {
  orders: string
}

interface ComponentProps {
  handleEnabled: (enabled: boolean) => void
  handleSubmit: (entries: string[]) => void
  submit: boolean
}

type Props = ComponentProps & FormikProps<FormValues>

const validationSchema: ValidationSchema = yup.object().shape({
  orders: yup
    .string()
    .label('orders')
    .test('len', 'Error: Only up to 100 orders can be entered', (val) => {
      if (!val) {
        return true
      }
      return val.split(/\r\n|\r|\n|\s/).length < 101
    }),
})

const StyledContainer = styled(Grid)({ display: 'flex', alignItems: 'center' })

const OverridesAsideOrderSearchForm = ({
  submit,
  handleEnabled,
  handleSubmit,
  isValid,
  dirty,
}: Props) => {
  const startAdornment = <SearchIcon color="primary" />

  useEffect(() => {
    handleEnabled(isValid && dirty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, dirty])

  useEffect(() => {
    if (submit) {
      handleSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit])

  return (
    <StyledContainer container>
      <Grid item xs={9}>
        <Form>
          <InputField
            data-testid="order-input"
            name="orders"
            placeholder="Enter Order IDs (paste in rows)"
            enableOnChangeValidation
            variant="outlined"
            InputProps={{
              startAdornment,
              sx: { backgroundColor: white.main, fontSize: 14 },
            }}
          />
        </Form>
      </Grid>
    </StyledContainer>
  )
}

export const OverridesAsideOrderSearchInput = withFormik<
  ComponentProps,
  FormValues
>({
  mapPropsToValues: () => ({
    orders: '',
  }),
  handleSubmit: async (values, { props }) => {
    const { orders } = values
    const { handleSubmit } = props

    handleSubmit(orders.trim().split(/\r\n|\r|\n|\s/))
  },
  enableReinitialize: true,
  validateOnMount: false,
  validationSchema,
})(OverridesAsideOrderSearchForm)

export default OverridesAsideOrderSearchInput
