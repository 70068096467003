import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from '@emotion/styled'

import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'

import ReportProgress from 'components/common/ReportCard/ReportProgress'
import DownloadReportButton from 'components/common/ReportCard/DownloadReportButton'
import { formatReportType } from './formatReportType'
import { hasReport } from './hasReport'
import { DialogEnum } from 'components/common/Dialog'

import {
  formatDate,
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
} from 'services/dateService'
import {
  createJiraIssue,
  JiraFields,
  JiraFeedbackType,
} from 'services/jiraIssues'

import { openDialog } from 'store/dialog/actionCreator'
import {
  currentSellerDisplayName,
  currentSellerId,
  currentSellerVmmId,
  email,
} from 'store/selectors'

import { Report, ReportStatus, ReportType } from 'types/Report'

const StyledTypographyError = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'issueReported',
})<{ issueReported: boolean }>((props) => ({
  color: props.issueReported
    ? props.theme.palette.success.main
    : props.theme.palette.error.main,
}))

export type Props = {
  memberOf: string[]
  report: Report
  onRequestCancelReport: ({
    sellerId,
    reportId,
  }: {
    sellerId: string
    reportId: string
  }) => void
}

export const UNEXPECT_ERROR_MESSAGE =
  'An unexpected error occurred. No report generated.'

const isUnexpectedError = (feedback: string[]) => {
  const msg = feedback.find(
    (str) => str.toUpperCase() === UNEXPECT_ERROR_MESSAGE.toUpperCase(),
  )

  return !!msg
}

const hasJiraIssueBtn = (report: Report) => {
  return (
    report.status === ReportStatus.ERROR &&
    report.feedback !== undefined &&
    isUnexpectedError(report.feedback)
  )
}

const getJiraIssueDescription = (report: Report) => {
  return `An error occurred when user generated a report for  ${
    report.id
  } for ${
    report.type
  }. Devs need to check the logs and write up a fix if necessary.

  {code}${JSON.stringify(report, null, '\t')}{code}`
}

export const DownloadReportTableCell = ({
  memberOf,
  report,
  onRequestCancelReport,
}: Props) => {
  const dispatch = useDispatch()
  const sellerId = useSelector(currentSellerId)
  const sellerDisplayName = useSelector(currentSellerDisplayName)
  const vmmId = useSelector(currentSellerVmmId)
  const userEmail = useSelector(email)

  const [issueReported, setIssueReported] = useState(false)
  const [pending, setPending] = useState(false)
  const enabled = hasReport(memberOf, report.type as ReportType)
  const title = report.report_name ?? formatReportType(report)

  const onCancel = () => {
    onRequestCancelReport({
      sellerId: report.seller_id,
      reportId: report.id,
    })
  }

  const onErrorReportClick = async () => {
    if (!sellerId || !sellerDisplayName || !vmmId) {
      return
    }

    setPending(true)

    const description = getJiraIssueDescription(report)

    try {
      await createJiraIssue({
        sellerId,
        vmmId,
        description,
        summary: 'Reported Bug from',
        email: userEmail,
        partnerName: sellerDisplayName,
        jiraField: JiraFields.FEEDBACK_TYPE_FIELD,
        jiraFieldValue: { id: JiraFeedbackType.BUGS_OR_SOFTWARE_DEFECTS },
      })

      dispatch(
        openDialog({
          dialogEnum: DialogEnum.REPORT_ERROR_FEEDBACK_DIALOG,
        }),
      )
    } finally {
      setIssueReported(true)
      setPending(false)
    }
  }

  if (
    report.status === ReportStatus.PENDING ||
    report.status === ReportStatus.PROCESSING
  ) {
    return (
      <Grid container alignItems="center">
        <Grid item xs={11} sx={{ pr: 1 }}>
          <Typography data-testid="report-pending">Generating...</Typography>

          <ReportProgress status={ReportStatus.PENDING} />
        </Grid>
        <Grid item xs={1}>
          <Box sx={{ margin: 0, width: 'min-content' }}>
            <Tooltip
              data-testid="cancel-button"
              title="Cancel report"
              placement="bottom-start"
            >
              <div>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={onCancel}
                  data-testid="cancel-icon-button"
                >
                  <CloseIcon color="primary" />
                </IconButton>
              </div>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    )
  } else if (report.status === ReportStatus.CANCELED) {
    return (
      <Typography data-testid="report-canceled">
        Generation canceled.
      </Typography>
    )
  } else if (report.status === ReportStatus.ERROR) {
    return (
      <Grid container alignItems="center">
        <Grid item xs={11} sx={{ pr: 1 }} data-testid="report-error">
          {hasJiraIssueBtn(report) ? (
            <StyledTypographyError
              data-testid="unexpected-error"
              issueReported={issueReported}
            >
              {issueReported ? (
                <span>
                  Thank you for your feedback. We are working to resolve the
                  issue.
                </span>
              ) : (
                <>
                  Generation failed unexpectedly.
                  <Button
                    sx={{ textTransform: 'none', fontSize: 14 }}
                    aria-label="button"
                    size="small"
                    variant="text"
                    disableElevation
                    disabled={pending}
                    onClick={onErrorReportClick}
                  >
                    Report this as a bug.
                  </Button>
                </>
              )}
            </StyledTypographyError>
          ) : (
            <StyledTypographyError
              data-testid="feedback"
              issueReported={issueReported}
            >
              {report?.feedback && report.feedback.join(', ')}
            </StyledTypographyError>
          )}
        </Grid>
      </Grid>
    )
  } else if (report.download_url) {
    return (
      <Grid container alignItems="center">
        <Grid item xs={11} sx={{ pr: 1 }}>
          <Typography data-testid="report-complete">
            {formatDate(report.created, DATE_FORMAT_MONTH_DAY_YEAR_TIME)}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <DownloadReportButton
            text="Download"
            report={report}
            isDisabled={!enabled}
            title={title}
            hasDateRange={false}
          />
        </Grid>
      </Grid>
    )
  } else {
    return null
  }
}

export default DownloadReportTableCell
