import axios from 'axios'

import groupBy from 'lodash/fp/groupBy'
import orderBy from 'lodash/fp/orderBy'
import sortBy from 'lodash/fp/sortBy'

import {
  formatDate,
  DATE_FORMAT_MONTH_DAY_YEAR,
  DATE_FORMAT_YEAR_MONTH_DAY_TIME,
} from 'services/dateService'

import apiConfig from 'config/apiConfig'

import {
  ContentHealth,
  ContentHealthError,
  Scorecard,
  ScorecardGroup,
} from 'types/BiReporting'

import { ItemInsightTemplate } from './../components/ItemInsightsPage/insightCardData'

const isContentHealthError = (
  data: ContentHealth[] | ContentHealthError,
): data is ContentHealthError => {
  return (
    data &&
    Array.isArray(data) === false &&
    Object.prototype.hasOwnProperty.call(data, 'code') === true
  )
}

export const fetchContentHealthScore = async ({
  sellerId,
  vmmId,
}: {
  sellerId: string
  vmmId: string
}): Promise<ContentHealth[]> => {
  const response = await axios.get(
    `${apiConfig.securityGateway}/sellers/${sellerId}/contenthealthscorehistory/${vmmId}`,
  )

  const { data } = response

  if (isContentHealthError(data)) {
    // The API returns a different response if a vendors scorecard is not set up.
    // Throw so that the caller can handle the error.
    if (data.code === 400) {
      throw new Error(data.message)
    }
  }

  return data
}

export const getContentHealthGroups = async ({
  sellerId,
  vmmId,
}: {
  sellerId: string
  vmmId: string
}): Promise<ScorecardGroup[]> => {
  const data = await fetchContentHealthScore({ sellerId, vmmId })
  const result = []
  let i = 0

  while (i < data.length) {
    const current = data[i]

    const scorecardGroups = formatContentHealthGroups(current.scorecards)

    result.push(...scorecardGroups)
    i++
  }

  return result
}

const groupOrder = [
  ItemInsightTemplate.GENERAL_TEMPLATE,
  ItemInsightTemplate.AA_TEMPLATE,
]

const formatContentHealthGroups = (
  scorecards: Scorecard[],
): ScorecardGroup[] => {
  const group = groupBy('templateName', scorecards)

  const groups = Object.keys(group)
    .map((card) => {
      const defaultDisplayProperties =
        getDefaultContentHealthDisplayProperties()
      return {
        templateName: card,
        scorecards: group[card],
        ...defaultDisplayProperties,
      }
    })
    .map((card) => {
      const orderedScorecards = orderBy('dateCreated', 'desc', card.scorecards)
      card.scorecards = orderedScorecards
      return card
    })

  for (let i = 0; i < groups.length; i++) {
    const group = groups[i]

    for (let j = 0; j < group.scorecards.length; j++) {
      const scorecard = group.scorecards[j]
      const score = scorecard.chScore
      const skus = scorecard.skuCount
      let date = formatDate(
        scorecard.dateCreated,
        DATE_FORMAT_MONTH_DAY_YEAR,
        DATE_FORMAT_YEAR_MONTH_DAY_TIME,
      )

      if (j === 0) {
        group.displayDate = date
        group.currentScore = score
        group.currentSkus = skus
        group.currentDownloadLink = scorecard.downloadLink
      }

      if (j === 1) {
        group.previousScore = scorecard.chScore
      }
    }
  }

  const orderedGroups = sortBy(
    (item) => groupOrder.indexOf(item.templateName as any),
    groups,
  )

  return orderedGroups
}

const getDefaultContentHealthDisplayProperties = () => ({
  displayDate: '',
  currentScore: 0,
  previousScore: 0,
  currentSkus: 0,
  currentDownloadLink: '',
})
