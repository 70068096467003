import React from 'react'

import Typography from '@mui/material/Typography'
import styled from '@emotion/styled'
import { error } from 'config/themeConfig'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import WarningIcon from 'components/common/WarningIcon'

import { releaseItem } from 'services/helios'

import { TargetPlusBarcodeDetails } from '../index'

const StyledFlexContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(2),
}))

const StyledImpactWarningInfo = styled(Typography)(({ theme }) => ({
  color: error.main,
  paddingTop: theme.spacing(2),
}))

const StyledImpactWarningHeader = styled(Typography)({
  color: error.main,
  fontWeight: 'bold',
})

export interface Props {
  isOpen: boolean
  barcodes: TargetPlusBarcodeDetails[]
  onSubmit: () => void
}

export const ConfirmBarcodeRelease = ({
  isOpen,
  barcodes,
  onSubmit,
}: Props) => {
  const [barcodessWithOrders, setBarcodesWithOrders] = React.useState<string[]>(
    [],
  )

  React.useEffect(() => {
    setBarcodesWithOrders(
      barcodes
        .filter((barcode) => barcode.orderCount! > 0)
        .map((orderBarcode) => {
          return orderBarcode.barcode
        }),
    )
  }, [barcodes])

  const handleSubmit = () => {
    let promises = barcodes.map((barcode) => {
      return releaseItem(barcode.barcodeInfo!.owner!.tcins[0])
    })
    Promise.all(promises).then(() => {
      onSubmit()
    })
  }

  return (
    <DialogContainer
      title="Confirm Release of Barcode(s)"
      isOpen={isOpen}
      onSubmit={handleSubmit}
      submitButtonText={'Release Barcodes'}
    >
      {barcodessWithOrders.length === 0 && (
        <Typography>
          Releasing these barcodes will allow Target First Party vendors or
          other Target Plus Partners to setup and sell these items.
        </Typography>
      )}
      {barcodessWithOrders.length > 0 && (
        <>
          <div data-testid="impact-warning">
            <StyledFlexContainer>
              <WarningIcon>
                <StyledImpactWarningHeader>
                  IMPACT OF RELEASING {barcodessWithOrders.join(', ')}
                </StyledImpactWarningHeader>
              </WarningIcon>
            </StyledFlexContainer>
            <StyledImpactWarningInfo>
              If these barcodes are not owned by anyone at the time of return,
              guests will NOT be able to return items having these barcodes.
            </StyledImpactWarningInfo>
            <StyledImpactWarningInfo>
              If a new partner/vendor owns these barcodes at the time of return,
              guests will be able to return their original items. However these
              items will be returned to the new partner/vendor.
            </StyledImpactWarningInfo>
          </div>
        </>
      )}
    </DialogContainer>
  )
}

export default ConfirmBarcodeRelease
