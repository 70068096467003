import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'

import ContentSpacer from 'components/common/ContentSpacer'
import { DialogEnum } from 'components/common/Dialog'
import useTable from 'components/common/EnhancedTable/useTable'
import EnhancedTable from 'components/common/EnhancedTable'
import HeaderTitle from 'components/common/HeaderTitle'
import TableSpacer from 'components/common/TableSpacer'
import TitleBar from 'components/common/TitleBar'

import { CONTENT_PADDING } from 'constants/layout'

import { isOneOfUserRoles } from 'services/authorization'
import {
  DATE_DISPLAY_FORMAT_TIME,
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
  formatDate,
  getIsoString,
} from 'services/dateService'
import { getPartnerDiscounts } from 'services/discounts'
import { Direction, PagingParams } from 'services/pageableHelper'
import { USER_ROLE_ADMIN, USER_ROLE_OPS } from 'services/roles'
import {
  formatShippingRatesForTable,
  getShippingRates,
  getFutureShippingRates,
} from 'services/shippingRates'

import { openDialog } from 'store/dialog/actionCreator'
import { getMemberOf } from 'store/selectors'

import { PartnerDiscount } from 'types/Discounts'
import { ShippingRatesTableRow, ShippingRateParams } from 'types/ShippingRates'

import {
  getCurrentRateFieldList,
  getDiscountsFieldList,
  getFutureRateFieldList,
} from './getReturnRateFieldList'

enum RatesTab {
  CURRENT_RATES = 'CURRENT_RATES',
  FUTURE_RATES = 'FUTURE_RATES',
  PARTNER_DISCOUNTS = 'PARTNER_DISCOUNTS',
}

const StyledTabs = styled(Tabs)({
  margin: `0 -${CONTENT_PADDING}px`,
  padding: `0 ${CONTENT_PADDING}px`,
})

const StyledContainer = styled(Grid)({ alignItems: 'center' })

const ReturnShippingRatesPage = () => {
  const reduxDispatch = useDispatch()
  const memberOf = useSelector(getMemberOf)
  const [isLoading, setIsLoading] = useState(false)
  const [currentShippingRates, setCurrentShippingRates] = useState<
    ShippingRatesTableRow[]
  >([])
  const [futureShippingRates, setFutureShippingRates] = useState<
    ShippingRatesTableRow[]
  >([])
  const [futureRatesId, setFutureRatesId] = useState<string>()
  const [tabValue, setTabValue] = useState<RatesTab>(RatesTab.CURRENT_RATES)

  const [futureRatesTableTrigger, setFutureRatesTableTrigger] = useState(0)
  const [partnerDiscounts, setPartnerDiscounts] = useState<PartnerDiscount[]>(
    [],
  )
  const [partnerDiscountsTotal, setPartnerDiscountsTotal] = useState(0)

  const [partnerDiscountsTableTrigger, setPartnerDiscountsTableTrigger] =
    useState(0)

  const canViewDiscounts = isOneOfUserRoles(memberOf, [
    USER_ROLE_ADMIN,
    USER_ROLE_OPS,
  ])

  const canAddFutureRates = isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN])

  const { table } = useTable({
    direction: Direction.ASC,
    orderBy: 'error_code',
    page: 0,
    perPage: 100,
  })

  useEffect(() => {
    const pagingParams: PagingParams = {
      page: 0,
      perPage: 100,
      orderBy: 'start_date',
      direction: Direction.ASC,
    }

    let mounted = true
    setIsLoading(true)
    const currentIsoDateTime = getIsoString(
      formatDate(Date.now(), DATE_DISPLAY_FORMAT_TIME),
    )
    let effectiveDate = currentIsoDateTime ?? undefined
    const currentShippingRateParams: ShippingRateParams = {
      effective_date: effectiveDate,
    }

    getShippingRates(currentShippingRateParams, pagingParams)
      .then((data) => {
        if (mounted) {
          if (data) {
            setCurrentShippingRates(formatShippingRatesForTable(data[0]))
          }
        }
      })
      .finally(() => {
        if (mounted) {
          setIsLoading(false)
        }
      })
    return () => {
      mounted = false
    }
  }, [])

  useEffect(() => {
    let mounted = true

    setIsLoading(true)

    const pagingParams: PagingParams = {
      page: 0,
      perPage: 1,
      orderBy: 'start_date',
      direction: Direction.ASC,
    }

    const currentIsoDateTime = getIsoString(
      formatDate(Date.now(), DATE_DISPLAY_FORMAT_TIME),
    )
    let startDate = currentIsoDateTime ?? undefined

    const futureShippingRateParams: ShippingRateParams = {
      start_date: startDate,
    }

    getFutureShippingRates({
      id: futureRatesId,
      rateParams: futureShippingRateParams,
      pagingParams,
    })
      .then((data) => {
        if (mounted) {
          if (data) {
            setFutureRatesId(data.id)
            const formattedRates = formatShippingRatesForTable(data)
            setFutureShippingRates(formattedRates)
          }
        }
      })
      .finally(() => {
        if (mounted) {
          setIsLoading(false)
        }
      })
    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [futureRatesTableTrigger])

  useEffect(() => {
    if (tabValue === RatesTab.PARTNER_DISCOUNTS) {
      let mounted = true
      setIsLoading(true)
      getPartnerDiscounts(table.state)
        .then(({ data, total }) => {
          if (mounted) {
            setPartnerDiscounts(data)
            setPartnerDiscountsTotal(total)
          }
        })
        .finally(() => {
          if (mounted) {
            setIsLoading(false)
          }
        })
      return () => {
        mounted = false
      }
    } else return
  }, [table.state, tabValue, partnerDiscountsTableTrigger])

  const reloadFutureRatesTable = () => {
    setFutureRatesTableTrigger((prev) => prev + 1)
  }

  const reloadPartnerDiscountsTable = () => {
    setPartnerDiscountsTableTrigger((prev) => prev + 1)
  }

  const addFutureRates = () => {
    reduxDispatch(
      openDialog({
        dialogEnum: DialogEnum.ADD_FUTURE_RATES,
        componentProps: {
          currentRates: currentShippingRates,
          futureRates: futureShippingRates,
          reloadTable: reloadFutureRatesTable,
          futureRatesId,
        },
      }),
    )
  }

  const handleTabChange = (_event: any, newValue: RatesTab) =>
    setTabValue(newValue)

  const getActionButtonLabel = futureShippingRates.length
    ? 'Edit Future Service Fees'
    : 'Add Future Service Fees'

  const handleUpdateDiscount = (discount?: PartnerDiscount) => {
    reduxDispatch(
      openDialog({
        dialogEnum: DialogEnum.EDIT_PARTNER_DISCOUNT,
        componentProps: {
          reloadTable: reloadPartnerDiscountsTable,
          discount,
        },
      }),
    )
  }

  const viewDiscountHistory = (discount: PartnerDiscount) => {
    reduxDispatch(
      openDialog({
        dialogEnum: DialogEnum.VIEW_DISCOUNT_HISTORY,
        componentProps: {
          sellerId: discount.seller_id,
          displayName: discount.display_name,
        },
      }),
    )
  }

  const partnerDiscountTableActions = [
    {
      label: 'Edit',
      callback: (element: PartnerDiscount) => () => {
        handleUpdateDiscount(element)
      },
      labelCallback: (element: PartnerDiscount) => {
        if (element.display_name) {
          return `Edit ${element.display_name} Rate`
        }

        return `Edit Rate`
      },
    },
    {
      label: 'View Discount History',
      callback: (element: PartnerDiscount) => () => {
        viewDiscountHistory(element)
      },
    },
  ]

  return (
    <div>
      <HeaderTitle title="Return Service Fees" />
      <ContentSpacer>
        <TitleBar
          variant="h2"
          title="Return Service Fees"
          hideDivider
          actionButtons={
            tabValue === RatesTab.FUTURE_RATES && canAddFutureRates
              ? [
                  <Button
                    key="add-future-rates"
                    data-testid="add-future-rates"
                    color="primary"
                    variant="contained"
                    onClick={addFutureRates}
                  >
                    {getActionButtonLabel}
                  </Button>,
                ]
              : undefined
          }
        />
      </ContentSpacer>
      <ContentSpacer>
        <StyledTabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
        >
          <Tab
            data-testid="current-rates-tab"
            value={RatesTab.CURRENT_RATES}
            key="current"
            label="Current Service Fees"
          />
          <Tab
            data-testid="future-rates-tab"
            value={RatesTab.FUTURE_RATES}
            key="future"
            label={`Future Service Fees ${
              futureShippingRates.length > 0 ? '' : '(None)'
            }`}
          />
          {canViewDiscounts && (
            <Tab
              data-testid="partner-discounts-tab"
              value={RatesTab.PARTNER_DISCOUNTS}
              key="discounts"
              label="Partner Discounts"
            />
          )}
        </StyledTabs>
      </ContentSpacer>
      {tabValue === RatesTab.CURRENT_RATES &&
        (currentShippingRates.length > 0 ? (
          <div>
            <Typography>
              The below return service fees are effective on{' '}
              {formatDate(
                currentShippingRates[0].start_date,
                DATE_FORMAT_MONTH_DAY_YEAR_TIME,
              )}
              .
            </Typography>
            <TableSpacer>
              <EnhancedTable
                data={currentShippingRates}
                fieldList={getCurrentRateFieldList}
                isLoading={isLoading}
              />
            </TableSpacer>
          </div>
        ) : (
          'No Current Fees Set'
        ))}
      {tabValue === RatesTab.FUTURE_RATES &&
        (futureShippingRates.length > 0 ? (
          <EnhancedTable
            data={futureShippingRates}
            fieldList={getFutureRateFieldList}
            isLoading={isLoading}
          />
        ) : (
          'No Future Fees Set'
        ))}
      {tabValue === RatesTab.PARTNER_DISCOUNTS && (
        <div>
          <StyledContainer container>
            <Grid item xs={12} sm={7}>
              <Typography>
                When a discount is specified at the partner level, partners will
                be charged reduced rates for all weight ranges.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Button
                data-testid="add-discount-button"
                variant="outlined"
                color="primary"
                disabled={false}
                onClick={() => handleUpdateDiscount()}
              >
                Add Partner Discount
              </Button>
            </Grid>
          </StyledContainer>
          <TableSpacer>
            <EnhancedTable
              onChangePage={table.actions.changePage}
              onChangeRowsPerPage={table.actions.changePerPage}
              order={table.state.direction}
              page={table.state.page}
              rowsPerPage={table.state.perPage}
              total={partnerDiscountsTotal}
              data={partnerDiscounts}
              fieldList={getDiscountsFieldList}
              isLoading={isLoading}
              actions={partnerDiscountTableActions}
            />
          </TableSpacer>
        </div>
      )}
    </div>
  )
}

export default ReturnShippingRatesPage
