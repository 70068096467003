import { call, put, select, takeEvery, take } from 'redux-saga/effects'

import {
  EDIT_USER_INFO,
  FETCH_SELLER_USER,
  FETCH_USER_ENTITLEMENTS,
  editUserInfoDone,
  fetchSellerUser,
  FETCH_SELLER_USER_DONE,
  FETCH_USER_ENTITLEMENTS_DONE,
  fetchSellerUserDone,
  fetchUserEntitlementsAction,
  fetchUserEntitlementsDone,
  EditUserInfoAction,
  FetchUserEntitlementsAction,
  EXTERNAL_SELLER_LOGIN,
  setExternalUserLogin,
} from './actionCreators'

import {
  fetchUserEntitlements,
  searchSellerUser,
  updateUserInfo,
} from 'services/sellerUser'
import {
  fetchAllSellers,
  FETCH_ALL_SELLERS_DONE,
  setCurrentSeller,
  SET_CURRENT_SELLER_DONE,
} from 'store/seller/actionCreators'
import { closeDialog } from 'store/dialog/actionCreator'
import * as selectors from 'store/selectors'
import { ExternalUserLogin } from 'types/UserInfo'
import { SmsSeller } from 'types/Seller'
import SellerUser from 'types/SellerUser'

export function* externalSellerLoginSaga() {
  try {
    yield put(setExternalUserLogin(ExternalUserLogin.PENDING))
    yield put(fetchAllSellers())
    yield put(fetchSellerUser())
    yield take(FETCH_ALL_SELLERS_DONE)
    yield take(FETCH_SELLER_USER_DONE)
    const sellers: SmsSeller[] = yield select(selectors.getSellers)

    if (sellers.length === 1) {
      yield put(setCurrentSeller(sellers[0].id))
      yield take(SET_CURRENT_SELLER_DONE)
    }

    yield put(setExternalUserLogin(ExternalUserLogin.SUCCESS))
  } catch (e) {
    yield put(setExternalUserLogin(ExternalUserLogin.ERROR))
  }
}

export function* fetchSellerUserSaga() {
  try {
    yield take(FETCH_ALL_SELLERS_DONE)
    const email: string = yield select(selectors.email)
    const sellerUser: SellerUser = yield call(searchSellerUser, email)

    if (sellerUser) {
      const sellerIds: string[] = yield select(selectors.sellerIds)

      yield put(fetchUserEntitlementsAction(sellerUser.id, sellerIds))
      yield take(FETCH_USER_ENTITLEMENTS_DONE)
      yield put(fetchSellerUserDone(sellerUser))
    }
  } catch (e) {
    console.error(`Error in fetchSellerUserSaga: ${e}`)
  }
}

export function* fetchUserEntitlementsSaga({
  userId,
  sellerIds,
}: FetchUserEntitlementsAction) {
  let entitlements = []
  try {
    if (sellerIds) {
      entitlements = yield call(fetchUserEntitlements, userId, sellerIds)
    }
    yield put(fetchUserEntitlementsDone(entitlements))
  } catch (e) {
    console.error(`Error in fetchUserEntitlementsSaga: ${e}`)
  }
}

export function* editUserInfoSaga({
  params: {
    userId,
    firstName,
    lastName,
    email,
    officeCountry,
    officePhoneCode,
    officePhoneNumber,
    mobileCountry,
    mobilePhoneCode,
    mobilePhoneNumber,
  },
}: EditUserInfoAction) {
  try {
    const payload: SellerUser = yield call(updateUserInfo, {
      userId,
      firstName,
      lastName,
      email,
      officeCountry,
      officePhoneCode,
      officePhoneNumber,
      mobileCountry,
      mobilePhoneCode,
      mobilePhoneNumber,
    })
    yield put(editUserInfoDone(payload))
    yield put(closeDialog())
  } catch (e) {
    console.error(`Error in editUserInfoSaga: ${e}`)
  }
}

export function* watchUserSagas() {
  yield takeEvery(EXTERNAL_SELLER_LOGIN, externalSellerLoginSaga)
  yield takeEvery(FETCH_SELLER_USER, fetchSellerUserSaga)
  yield takeEvery(FETCH_USER_ENTITLEMENTS, fetchUserEntitlementsSaga)
  yield takeEvery(EDIT_USER_INFO, editUserInfoSaga)
}
