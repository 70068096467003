import { useEffect, useReducer, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Location } from 'history'
import getOr from 'lodash/fp/getOr'
import isFinite from 'lodash/fp/isFinite'
import isNil from 'lodash/fp/isNil'
import omit from 'lodash/fp/omit'

import { EnhancedTableFieldType } from 'components/common/EnhancedTable'

import { trackCustomEvent } from 'services/fireflyInsights'
import { Direction } from 'services/pageableHelper'
import { createUrl, getParams } from 'services/urlHelper'

import { FireflyEvent } from 'types/FireflyInsights'
import { isSortEvent } from 'types/Guards'

type FilterValue = Nullable<string | string[] | number>

interface UpdateSearchParams {
  readonly type: 'UPDATE_SEARCH_PARAMS'
  payload: {
    params: Dictionary<FilterValue>
  }
}

interface BrowserAction {
  readonly type: 'BROWSER_ACTION'
  payload: {
    params: Dictionary<FilterValue>
  }
}

type Actions = UpdateSearchParams | BrowserAction

type FilterDispatch = React.Dispatch<Actions>

const updateSearchParams = (params: FilterState): UpdateSearchParams => ({
  type: 'UPDATE_SEARCH_PARAMS',
  payload: { params },
})

const browserAction = (params: Dictionary<FilterValue>): BrowserAction => ({
  type: 'BROWSER_ACTION',
  payload: {
    params,
  },
})

type ChangePageEvent = React.MouseEvent<HTMLElement, MouseEvent> | null

type ChangePerPageEvent = React.ChangeEvent<{ name?: string; value: unknown }>

const wrapHistoryPush =
  (
    navigate: any,
    location: any,
    dispatch: FilterDispatch,
    type: 'SORT' | 'CHANGE_PAGE' | 'CHANGE_PER_PAGE' | 'FILTER',
    actionCreator: (params: Dictionary<FilterValue>) => Actions,
  ) =>
  (
    params:
      | EnhancedTableFieldType
      | ChangePageEvent
      | ChangePerPageEvent
      | Dictionary<FilterValue>,
    page?: number,
  ) => {
    const search = getParams(location)
    let current: Dictionary<FilterValue> = {}

    if (type === 'SORT' && isSortEvent(params)) {
      let newDirection
      if (search.orderBy !== params.key) {
        newDirection =
          search.direction === 'asc' ? Direction.ASC : Direction.DESC
      } else {
        newDirection =
          search.direction === 'asc' ? Direction.DESC : Direction.ASC
      }

      current = {
        orderBy: params.key,
        direction: newDirection,
        page: defaultTableState.page,
      }
    } else if (type === 'CHANGE_PAGE') {
      if (isNil(page) || !params) {
        return
      }

      current = { page }
    } else if (type === 'CHANGE_PER_PAGE') {
      const newPerPage = getOr('', 'target.value', params) as string
      current = {
        page: defaultTableState.page,
        perPage: newPerPage
          ? parseInt(newPerPage, 10)
          : defaultTableState.perPage,
      }
    } else if (type === 'FILTER') {
      const pageParamNames = ['direction', 'orderBy', 'perPage']
      Object.entries(params as Dictionary<FilterValue>).forEach(
        (filterKeyValuePair) => {
          if (
            filterKeyValuePair[1] &&
            !pageParamNames.includes(filterKeyValuePair[0])
          ) {
            trackCustomEvent(
              FireflyEvent.FILTER_APPLIED,
              filterKeyValuePair[0],
              filterKeyValuePair[1] as string | number,
            )
          }
        },
      )
      current = {
        ...params,
        page: defaultTableState.page,
      } as Dictionary<FilterValue>
    }

    const nextSearch = {
      ...search,
      ...current,
    }

    const currentUrl = `${location.pathname}${location.search}`
    const nextUrl = createUrl(location.pathname, nextSearch)

    dispatch(actionCreator(current))

    if (currentUrl !== nextUrl) {
      navigate(nextUrl)
    }
  }

export interface SearchParamActions<T> {
  updateSearchParam: (newValue: Partial<T>) => void
  sort: (field: EnhancedTableFieldType) => void
  changePage: (event: ChangePageEvent, page?: number) => void
  changePerPage: (event: ChangePerPageEvent) => void
}

const searchParamActions = (
  navigate: any,
  location: any,
  dispatch: FilterDispatch,
) => ({
  updateSearchParam: wrapHistoryPush(
    navigate,
    location,
    dispatch,
    'FILTER',
    updateSearchParams,
  ),
  sort: wrapHistoryPush(
    navigate,
    location,
    dispatch,
    'SORT',
    updateSearchParams,
  ),
  changePage: wrapHistoryPush(
    navigate,
    location,
    dispatch,
    'CHANGE_PAGE',
    updateSearchParams,
  ),
  changePerPage: wrapHistoryPush(
    navigate,
    location,
    dispatch,
    'CHANGE_PER_PAGE',
    updateSearchParams,
  ),
})

export type TableState = {
  direction?: Direction
  orderBy?: string
  page: number
  perPage: number
}

type FilterState = Dictionary<FilterValue>

type State = TableState & FilterState

const defaultTableState: TableState = {
  page: 0,
  perPage: 20,
}

let appliedFilterCount = 0

function reducer(state: State, action: Actions): State {
  switch (action.type) {
    case 'UPDATE_SEARCH_PARAMS': {
      const { payload } = action
      const { params } = payload

      const newState = {
        ...state,
        ...params,
      }

      appliedFilterCount = calculateActiveFilters(newState)

      return newState
    }

    case 'BROWSER_ACTION': {
      const { payload } = action
      const { params } = payload

      const nextState = {} as State

      Object.keys(state).forEach((key) => {
        const paramValue = params[key]

        // Check if user removed predefined filter
        if (typeof paramValue === 'undefined') {
          //@ts-ignore
          nextState[key] = undefined
        } else {
          //@ts-ignore
          nextState[key] = paramValue
        }
      })

      const newState = {
        ...state,
        ...nextState,
        ...params,
      }

      return newState
    }
    default: {
      return state
    }
  }
}

const calculateActiveFilters = (params: State) => {
  const filters = omit(
    ['page', 'perPage', 'direction', 'orderBy'] as (keyof TableState)[],
    params,
  )
  return Object.values(filters).filter((value) => value !== undefined).length
}

export const getInitialParams = (location: Location) => {
  const initParams = getParams(location)
  let initPage: number | undefined = undefined
  let initPerPage: number | undefined = undefined
  if (initParams && initParams.page && typeof initParams.page === 'string') {
    initPage = parseInt(initParams.page, 10)
  }

  if (
    initParams &&
    initParams.perPage &&
    typeof initParams.perPage === 'string'
  ) {
    initPerPage = parseInt(initParams.perPage, 10)
  }

  return {
    ...initParams,
    ...(initParams.page && { page: initPage }),
    ...(initParams.perPage && { perPage: initPerPage }),
  }
}

const hasValues = (params: Dictionary<FilterValue>) => {
  const values = Object.values(params)

  return values.some((val) => val !== undefined)
}

export const useSearchParams = <T extends State>(
  filterState: T,
): [T, SearchParamActions<T>, number] => {
  const location = useLocation()
  const navigate = useNavigate()

  const initialParams = getInitialParams(location)

  const initialState: State = {
    ...filterState,
    ...initialParams,
  }

  appliedFilterCount = calculateActiveFilters(initialState)

  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (hasValues(filterState)) {
      const nextUrl = createUrl(location.pathname, initialState)

      // Call replace so that we update the url with the provided params on initialization but can still click the back button.
      navigate(nextUrl, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [isPopState, setIsPopState] = useState(false)

  useEffect(() => {
    const handlePopState = () => {
      setIsPopState(true)
    }

    window.addEventListener('popstate', handlePopState)
    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [])

  useEffect(() => {
    if (isPopState) {
      const params = getParams({
        search: location.search,
      }) as Dictionary<FilterValue>
      const page =
        typeof params?.page === 'string' ? parseInt(params.page, 10) : undefined
      const perPage =
        typeof params?.perPage === 'string'
          ? parseInt(params.perPage, 10)
          : undefined
      if (isFinite(page)) {
        params.page = page
      }
      if (isFinite(perPage)) {
        params.perPage = perPage
      }
      dispatch(browserAction(params))
      setIsPopState(false)
    }
  }, [isPopState, location])

  return [
    state as T,
    searchParamActions(navigate, location, dispatch),
    appliedFilterCount,
  ]
}

export const getEnhancedTablePageableProps = (
  searchParams: State,
  searchParamActions: SearchParamActions<State>,
) => ({
  page: searchParams.page,
  rowsPerPage: searchParams.perPage,
  order: searchParams.direction,
  orderBy: searchParams.orderBy,
  onChangePage: searchParamActions.changePage,
  onChangeRowsPerPage: searchParamActions.changePerPage,
  onRequestSort: searchParamActions.sort,
})
