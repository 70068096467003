import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import HeaderTitle from 'components/common/HeaderTitle'

import { trackCustomEvent } from 'services/fireflyInsights'
import { getContentHealthGroups } from 'services/vendorScoreCards'
import { RoutePath } from 'services/NavigationHelper'

import { currentSellerId, currentSellerVmmId } from 'store/selectors'
import { openDialog } from 'store/dialog/actionCreator'

import { ScorecardGroup } from 'types/BiReporting'
import { FireflyEventInfo } from 'types/FireflyInsights'

import InsightCard, { InsightDialogProps } from './InsightCard'

export const ItemInsightsPage = () => {
  const reduxDispatch = useDispatch()
  const params = useParams<{ sellerId: string }>()

  const sellerId = useSelector(currentSellerId)
  const vmmId = useSelector(currentSellerVmmId)

  const [scorecards, setScorecards] = useState<ScorecardGroup[]>()
  const [error, setError] = useState('')

  useEffect(() => {
    let mounted = true
    const getContentHealthScore = async () => {
      if (sellerId && vmmId) {
        try {
          const response = await getContentHealthGroups({
            sellerId,
            vmmId,
          })

          if (mounted) {
            setScorecards(response)
          }
        } catch (err: any) {
          if (mounted && err.message) {
            setError(err.message)
          }
        }
      }
    }

    getContentHealthScore()

    return () => {
      mounted = false
    }
  }, [sellerId, vmmId])

  const handleDownloadClick = (eventInfo: FireflyEventInfo) => {
    trackCustomEvent(eventInfo.type, eventInfo.key, eventInfo.value)
  }

  const handleOpenDialog = ({
    dialog,
    dialogProps,
    eventInfo,
  }: InsightDialogProps) => {
    trackCustomEvent(eventInfo.type, eventInfo.key, eventInfo.value)
    reduxDispatch(
      openDialog({ dialogEnum: dialog, componentProps: dialogProps }),
    )
  }

  const handlePageTransition = (eventInfo: FireflyEventInfo) => {
    trackCustomEvent(eventInfo.type, eventInfo.key, eventInfo.value)

    window.open(
      `${window.location.origin}/${params.sellerId}${RoutePath.PARTNER_SERVICES}`,
      '_blank',
    )
  }

  return (
    <div>
      <HeaderTitle title="Item Insights" />
      {!error &&
        scorecards &&
        scorecards.map((card, index) => (
          <Grid
            container
            key={card.templateName}
            sx={{ mt: index > 0 ? 4 : undefined }}
          >
            <Grid item xs={12}>
              <InsightCard
                group={card}
                onDownloadClick={handleDownloadClick}
                onOpenDialogClick={handleOpenDialog}
                onPageChangeClick={handlePageTransition}
              />
            </Grid>
          </Grid>
        ))}

      {error && (
        <Grid container>
          <Grid item xs={12}>
            <Typography>{error}</Typography>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default ItemInsightsPage
