import { trackCustomEvent } from 'services/fireflyInsights'
import { getHeliosItem } from 'services/helios'
import {
  getItemHistories,
  getSmsProduct,
  updateProductStatus,
} from 'services/items'
import { SortParams } from 'services/pageableHelper'

import {
  ListingStatus,
  MarketplaceProduct,
  ProductError,
  ProductReviewData,
  ProductSearchParams,
  SmsProduct,
  ValidationStatus,
  UpdateItemListingStatusParams,
  MarketplaceReviewProduct,
} from 'types/Item'
import { FireflyEvent } from 'types/FireflyInsights'
import { HeliosItem } from 'types/HeliosItem'

import { CollectionResponse } from 'types/Response'
import { getSortedMarketplaceProductsWithLocks } from './sellerProducts'

const PAGE_SIZE = 10

interface ProductReviewParams {
  searchParams: ProductSearchParams
  sortParams: SortParams[]
  skipItems: string[]
  skippedParents: string[]
}

export const getNextProductForReview = async (
  params: ProductReviewParams,
): Promise<ProductReviewData | undefined> => {
  let productReviewData: ProductReviewData | undefined = undefined
  let productResponse: CollectionResponse<MarketplaceReviewProduct> | undefined
  let page = 0

  while (
    !productReviewData &&
    (!productResponse || productResponse!.data.length === PAGE_SIZE)
  ) {
    productResponse = await getSortedMarketplaceProductsWithLocks(
      params.searchParams,
      page,
      PAGE_SIZE,
      params.sortParams,
    )

    // filter out locked and skipped items
    let queue = productResponse.data.filter((mp) => {
      return (
        !Object.hasOwn(mp, 'lock') &&
        !params.skipItems.includes(mp.marketplace_product.product_id)
      )
    })

    // if the parent was skipped, filter out the children
    if (!params.searchParams.parent_product_id) {
      queue = queue.filter(
        (mp) =>
          !params.skippedParents.includes(
            mp.marketplace_product.parent_product_id,
          ),
      )
    }

    let items = queue.map((item) => item.marketplace_product) // create MarketplaceProduct[] from MarketplaceReviewProduct[]
    productReviewData = await getProductReviewData(items)

    page++
  }

  return productReviewData
}

export const updateProductListingStatus = async ({
  sellerId,
  productId,
  statusId,
  tcin,
  listingStatus,
  errors,
}: UpdateItemListingStatusParams) => {
  const productStatusUpdate = {
    listing_status: listingStatus,
    errors,
  }

  // TODO: do we want to use tcin instead of product id?
  fireflyItemApproval(listingStatus, errors, tcin!)

  return await updateProductStatus({
    sellerId,
    productId,
    statusId,
    productStatusUpdate,
  })
}

const getProductReviewData = async (
  queue: MarketplaceProduct[],
): Promise<ProductReviewData | undefined> => {
  let index = 0
  let reviewProduct: MarketplaceProduct | undefined
  let heliosItem: HeliosItem | undefined
  let smsProduct: Nullable<SmsProduct>

  while (!reviewProduct && index < queue.length) {
    const queueIndex: MarketplaceProduct | undefined = queue[index]
    heliosItem = await checkHeliosItem(queueIndex)

    if (heliosItem) {
      smsProduct = await isValidForReview(queueIndex)

      reviewProduct = smsProduct ? queueIndex : undefined
    }

    index++
  }

  if (smsProduct && heliosItem && reviewProduct) {
    const itemHistories = await getItemHistories(
      reviewProduct!.seller_id,
      reviewProduct!.product_id,
    )

    return {
      reviewProduct,
      heliosItem,
      itemHistories,
      smsProduct,
    }
  }
}

const isValidForReview = async (
  product: MarketplaceProduct,
): Promise<SmsProduct | null> => {
  const smsProduct = await getSmsProduct({
    sellerId: product.seller_id,
    productId: product.id,
    params: { expand: 'fields' },
  })

  const { product_statuses } = smsProduct

  if (
    product_statuses[0].listing_status === ListingStatus.PENDING &&
    [
      ValidationStatus.BLOCKED as String,
      ValidationStatus.REVIEW as String,
      ValidationStatus.VALIDATED as String,
    ].includes(product_statuses[0].validation_status)
  ) {
    return smsProduct
  } else {
    return null
  }
}

const checkHeliosItem = async (
  reviewProduct: MarketplaceProduct | undefined,
): Promise<HeliosItem | undefined> => {
  if (reviewProduct && reviewProduct.tcin) {
    const heliosItem = await getHeliosItem({
      tcin: reviewProduct.tcin,
      version: reviewProduct.helios_version,
    })

    if (heliosItem.status !== ValidationStatus.INITIATED) {
      return heliosItem
    }
  }
  return Promise.resolve(undefined)
}

const fireflyItemApproval = (
  status: ListingStatus,
  errors: ProductError[],
  tcin: string,
) => {
  let event = FireflyEvent.ITEM_REJECTED
  if (status === ListingStatus.APPROVED) {
    event = errors.length
      ? FireflyEvent.ITEM_APPROVED_WITH_ERRORS
      : FireflyEvent.ITEM_APPROVED_WITHOUT_ERRORS
  }
  trackCustomEvent(event, 'item', tcin)
}
