import React from 'react'
import { useSelector } from 'react-redux'

import Typography from '@mui/material/Typography'

import { DialogEnum } from 'components/common/Dialog'
import EditButton from 'components/common/EditButton'
import useTable from 'components/common/EnhancedTable/useTable'
import Link from 'components/common/Link'
import TableSpacer from 'components/common/TableSpacer'
import TitleBar from 'components/common/TitleBar'

import ReferralFeeTable from './ReferralFeeTable'

import { isOneOfUserRoles } from 'services/authorization'
import { Direction, PagingParams } from 'services/pageableHelper'
import { getReferralPercentages } from 'services/referralFees'
import {
  USER_ROLE_ADMIN,
  USER_ROLE_OPS,
  USER_ROLE_PROGRAM_MANAGER,
} from 'services/roles'
import { RoutePath } from 'services/NavigationHelper'
import { getMemberOf, currentSellerId } from 'store/selectors'

import { ReferralPercentage } from 'types/ReferralFee'

export interface Props {
  displayTitle: boolean
}

const canShowRoles = [USER_ROLE_ADMIN, USER_ROLE_OPS, USER_ROLE_PROGRAM_MANAGER]

export const ReferralFeeContent = ({ displayTitle }: Props) => {
  const sellerId = useSelector(currentSellerId)
  const memberOf = useSelector(getMemberOf)
  const [pending, setPending] = React.useState(false)
  const [referralFees, setReferralFees] = React.useState<ReferralPercentage[]>(
    [],
  )
  const [total, setTotal] = React.useState(0)

  const { table } = useTable({
    direction: Direction.DESC,
    orderBy: 'start_date',
    page: 0,
    perPage: 20,
  })

  React.useEffect(() => {
    if (sellerId) {
      const fetchReferralFees = async ({
        direction,
        orderBy,
        page,
        perPage,
      }: PagingParams) => {
        setPending(true)
        const { data, total: totalCount } = await getReferralPercentages(
          sellerId,
          {
            direction,
            orderBy,
            page,
            perPage,
          },
        )
        setReferralFees(data)
        setTotal(totalCount)
        setPending(false)
      }

      fetchReferralFees(table.state)
    }
  }, [sellerId, table.state])

  const hide = !isOneOfUserRoles(memberOf, canShowRoles)

  return (
    <>
      {displayTitle && (
        <TitleBar
          title="Referral Fees"
          actionButtons={[
            <EditButton
              key="edit-referral-fees"
              aria-label="edit-referral-fees"
              dialogComponent={DialogEnum.EDIT_REFERRAL_FEES}
              componentProps={{ isBaseReferralFee: false }}
              hide={hide}
            />,
          ]}
        />
      )}
      <Typography>
        All fees are based on the subtype base referral fees unless a different
        referral fee is specified below. To view a complete list of allowed item
        types and referral fees for this partner,&nbsp;
        <Link to={`/${sellerId}${RoutePath.REPORTS}`}>
          download the allowed item type report
        </Link>
        .
      </Typography>
      <TableSpacer>
        <ReferralFeeTable
          table={table}
          data={referralFees}
          isPending={pending}
          total={total}
        />
      </TableSpacer>
    </>
  )
}

export default ReferralFeeContent
