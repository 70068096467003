import styled from '@emotion/styled'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import DialogContainer from 'components/common/Dialog/DialogContainer'
import FormControlLabel from '@mui/material/FormControlLabel'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { currentSeller, isSellersPending } from 'store/selectors'
import { editSeller } from 'store/seller/actionCreators'
import isBoolean from 'lodash/fp/isBoolean'

const StyledFormLabel = styled(FormLabel)({
  component: 'legend',
  fontSize: '0.725rem',
})

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginRight: theme.spacing(4),
}))

const EditSourcingInfo = ({ isOpen }: { isOpen: boolean }) => {
  const dispatch = useDispatch()

  const seller = useSelector(currentSeller)
  const isPending = useSelector(isSellersPending)

  const [isImporter, setIsImporter] = React.useState(
    seller?.is_importer_of_consumer_product ?? null,
  )
  const [isManufacturer, setIsManufacturer] = React.useState(
    seller?.is_manufacturer_of_consumer_product ?? null,
  )
  const [isReseller, setIsReseller] = React.useState(
    seller?.is_reseller_of_consumer_product ?? null,
  )

  const isValid =
    isBoolean(isImporter) && isBoolean(isManufacturer) && isBoolean(isReseller)

  const handleSubmit = () => {
    if (!seller) return

    dispatch(
      editSeller({
        ...seller,
        is_importer_of_consumer_product: isImporter as boolean,
        is_manufacturer_of_consumer_product: isManufacturer as boolean,
        is_reseller_of_consumer_product: isReseller as boolean,
      }),
    )
  }

  const YesNoRadioGroup = ({
    label,
    name,
    value,
    onChange,
  }: {
    label: string
    name: string
    value: boolean | null
    onChange: (value: boolean) => void
  }) => (
    <Grid item xs={12}>
      <StyledFormLabel>{label}</StyledFormLabel>
      <RadioGroup
        data-testid={`radio-group-${name}`}
        row={true}
        name={name}
        value={value}
        onChange={(_event: any, value: string) => {
          onChange(value === 'true')
        }}
      >
        <StyledFormControlLabel value={true} label="Yes" control={<Radio />} />
        <FormControlLabel value={false} label="No" control={<Radio />} />
      </RadioGroup>
    </Grid>
  )

  return (
    <DialogContainer
      title="Edit Product Sourcing Info"
      isOpen={isOpen}
      onSubmit={handleSubmit}
      isPending={isPending}
      isSubmitDisabled={!isValid}
    >
      <Grid container spacing={2}>
        <YesNoRadioGroup
          label="Do you manufacture the items listed & sold by your business?*"
          name="isManufacturer"
          value={isManufacturer}
          onChange={setIsManufacturer}
        />
        <YesNoRadioGroup
          label="Do you import items listed & sold by your business into the United
            States?*"
          name="isImporter"
          value={isImporter}
          onChange={setIsImporter}
        />
        <YesNoRadioGroup
          label="Do you resell items from another business?*"
          name="isReseller"
          value={isReseller}
          onChange={setIsReseller}
        />
      </Grid>
    </DialogContainer>
  )
}

export default EditSourcingInfo
