import MenuItem from '@mui/material/MenuItem'

const TitleBarItem = (props: {
  isDisabled: boolean
  label: string
  onClick: (event: any) => void
}) => {
  const { label, isDisabled, onClick, ...restProps } = props

  return (
    <MenuItem onClick={onClick} disabled={isDisabled} {...restProps}>
      {label}
    </MenuItem>
  )
}

TitleBarItem.defaultProps = {
  isDisabled: false,
}

export default TitleBarItem
