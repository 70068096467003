import get from 'lodash/fp/get'
import isEmpty from 'lodash/fp/isEmpty'

import { DialogEnum } from 'components/common/Dialog'

import { FinancialDisposition, PhysicalDisposition } from 'constants/categories'

import { isUserRoleSellerAdmin, isOneOfUserRoles } from 'services/authorization'
import { isPrivateCompany } from 'services/partnerDiversity'
import { USER_ROLE_OPS, USER_ROLE_ADMIN } from 'services/roles'
import {
  getAllResponsibilities,
  hasAllResponsibilities,
} from 'services/usersHelper'
import { RoutePath } from 'services/NavigationHelper'

import { ApiToken } from 'types/ApiTokens'
import { Legalization } from 'types/Legalization'
import { DiversityAnswer } from 'types/PartnerDiversity'
import { SmsSeller } from 'types/Seller'
import SellerUser from 'types/SellerUser'
import { SetupTask } from 'types/SetupTask'

export const getSellerTasks = ({
  seller,
  memberOf,
  contacts,
  diversityAnswers,
  apiTokens,
}: {
  seller: SmsSeller
  memberOf: string[]
  contacts: SellerUser[] | undefined
  diversityAnswers: DiversityAnswer[]
  apiTokens: ApiToken[] | undefined
}): SetupTask[] => {
  if (!seller) return []

  return [
    {
      title: 'Add EIN',
      description:
        'Employer Identification Number (EIN) is required for Stripe setup',
      dialogEnum: DialogEnum.EDIT_TAX_ID,
      complete: !!get('tax_id[0]', seller),
      enabled: isUserRoleSellerAdmin(memberOf),
    },
    {
      title: 'Add Business Structure',
      description:
        'Business structure (example: LLC) is required for Stripe setup',
      dialogEnum: DialogEnum.EDIT_BUSINESS_STRUCTURE,
      complete: !!get('business_structure[0]', seller),
      enabled: isUserRoleSellerAdmin(memberOf),
    },
    {
      title: 'Setup Stripe Account',
      description: 'Manage your company payments in Stripe account',
      link: `/${seller.id}${RoutePath.PAYOUTS_AND_ACCOUNT}`,
      complete: !!get('stripe_account_id[0]', seller),
      enabled:
        isUserRoleSellerAdmin(memberOf) &&
        !!get('business_structure[0]', seller) &&
        !!get('tax_id[0]', seller),
    },
    {
      title: 'Fill out Shipping Information',
      description:
        'Provide your address, shipping hours, carriers & service levels',
      link: `/${seller.id}${RoutePath.SHIPPING_INFO}`,
      complete:
        !!get('distribution_centers[0].address', seller) &&
        !!get(
          'distribution_centers[0].days_of_operation.working_hours[0]',
          seller,
        ) &&
        !!get('distribution_centers[0].ship_nodes[0]', seller),
      enabled: isUserRoleSellerAdmin(memberOf),
    },
    {
      title: 'Add Return Address',
      description: 'Provide your return address and c/o',
      dialogEnum: DialogEnum.EDIT_RETURN_ADDRESS,
      complete: !!get('return_policies[0]', seller),
      enabled:
        isUserRoleSellerAdmin(memberOf) && !get('return_policies[0]', seller),
    },
    {
      title: 'Add Other Marketplace info',
      description:
        'Provide information about advertising/selling on other marketplaces',
      dialogEnum: DialogEnum.EDIT_OTHER_MARKETPLACES,
      complete: Object.prototype.hasOwnProperty.call(
        seller,
        'in_other_marketplaces',
      ),
      enabled: isUserRoleSellerAdmin(memberOf),
    },
    {
      title: 'Add Product Sourcing Info',
      description: 'Displays on your Target.com page',
      dialogEnum: DialogEnum.EDIT_SOURCING_INFO,
      complete:
        Object.prototype.hasOwnProperty.call(
          seller,
          'is_manufacturer_of_consumer_product',
        ) &&
        Object.prototype.hasOwnProperty.call(
          seller,
          'is_importer_of_consumer_product',
        ) &&
        Object.prototype.hasOwnProperty.call(
          seller,
          'is_reseller_of_consumer_product',
        ),
      enabled: isUserRoleSellerAdmin(memberOf),
    },
    {
      title: 'Add Contact Info for Guest Inquiry',
      description: 'Displays on your Target.com page',
      dialogEnum: DialogEnum.EDIT_GUEST_CONTACT,
      complete: !!get('guest_services_email', seller),
      enabled: isUserRoleSellerAdmin(memberOf),
    },
    {
      title: 'Add About Company',
      description: 'Displays on your Target.com page',
      dialogEnum: DialogEnum.EDIT_ABOUT_COMPANY,
      complete: !!get('description[0]', seller),
      enabled: isUserRoleSellerAdmin(memberOf),
    },
    {
      title: 'Add Privacy Policy',
      description: 'Displays on your Target.com page',
      dialogEnum: DialogEnum.EDIT_PRIVACY_POLICY,
      complete: !!get('privacy_policy[0]', seller),
      enabled: isUserRoleSellerAdmin(memberOf),
    },
    ...(isPrivateCompany(seller)
      ? [
          {
            title: 'Add Diversity Information',
            description:
              'Provide information about your business and diversity certificates if any',
            complete: diversityAnswers.length > 0,
            dialogEnum: DialogEnum.PARTNER_DIVERSITY_SURVEY,
            enabled:
              isUserRoleSellerAdmin(memberOf) && !diversityAnswers.length,
          },
        ]
      : []),
    {
      title: 'Generate API Token',
      description: 'Used to authenticate integration access',
      link: `/${seller.id}${RoutePath.API_TOKENS}`,
      complete: !isEmpty(apiTokens),
      enabled: isUserRoleSellerAdmin(memberOf),
    },
    {
      title: 'Setup Users & Contacts',
      description:
        'Set up users and/or contacts responsible for customer support, sales, shipping, finance, item data and reverse logistics',
      link: `/${seller.id}${RoutePath.USER_ACCESS}`,
      complete: contacts
        ? hasAllResponsibilities(getAllResponsibilities(contacts))
        : false,
      enabled: isUserRoleSellerAdmin(memberOf),
    },
  ]
}

export const getTargetTasks = (
  seller: SmsSeller,
  memberOf: string[],
  legalizations: Legalization[] | undefined,
): SetupTask[] => {
  if (!seller) return []

  let requiredReturnPolicies = false
  const returnPolicies = get('return_policies', seller)

  if (returnPolicies) {
    requiredReturnPolicies =
      !!returnPolicies.find(
        (returnPolicy) =>
          returnPolicy.financial_disposition_id ===
            FinancialDisposition.WriteOff &&
          returnPolicy.physical_disposition_id === PhysicalDisposition.Destroy,
      ) &&
      !!returnPolicies.find(
        (returnPolicy) =>
          returnPolicy.financial_disposition_id ===
            FinancialDisposition.ChargeToPartner &&
          returnPolicy.physical_disposition_id ===
            PhysicalDisposition.ReturnViaCrc,
      ) &&
      !!returnPolicies.find(
        (returnPolicy) =>
          returnPolicy.financial_disposition_id ===
            FinancialDisposition.ChargeToPartner &&
          returnPolicy.physical_disposition_id === PhysicalDisposition.Destroy,
      )
  }

  return [
    {
      title: 'Confirm Tax Setup',
      description: `Partner must be set up in Target's tax system`,
      link: `/${seller.id}${RoutePath.BASIC_INFO}`,
      complete: !!get('internal_tax_setting.setup_complete', seller),
      enabled: isOneOfUserRoles(memberOf, [USER_ROLE_OPS, USER_ROLE_ADMIN]),
    },
    {
      title: 'Set Allowed Item Type Rules',
      description:
        'Allows partner to submit items under certain brands, subtypes or item types',
      link: `/${seller.id}${RoutePath.ITEM_LISTING_SETTINGS}`,
      complete: !isEmpty(legalizations),
      enabled: isOneOfUserRoles(memberOf, [USER_ROLE_OPS, USER_ROLE_ADMIN]),
    },
    {
      title: 'Upload Logo and Banner',
      description: `Displays on partner's Target.com page`,
      link: `/${seller.id}${RoutePath.BASIC_INFO}`,
      complete: !!get('header_image', seller) && !!get('logo_image', seller),
      enabled: isOneOfUserRoles(memberOf, [USER_ROLE_OPS, USER_ROLE_ADMIN]),
    },
    {
      title: 'Review About Company',
      description: 'Confirms that about company has been reviewed',
      link: `/${seller.id}${RoutePath.BASIC_INFO}`,
      complete: !!get(
        'reviewed',
        seller.review_indicators.find(
          (reviewIndicator) => reviewIndicator.name === 'DESCRIPTION',
        ),
      ),
      enabled:
        isOneOfUserRoles(memberOf, [USER_ROLE_OPS, USER_ROLE_ADMIN]) &&
        !!get('description', seller),
    },
    {
      title: 'Review Privacy Policy',
      description: 'Confirms that privacy policy has been reviewed',
      link: `/${seller.id}${RoutePath.BASIC_INFO}`,
      complete: !!get(
        'reviewed',
        seller.review_indicators.find(
          (reviewIndicator) => reviewIndicator.name === 'PRIVACY_POLICY',
        ),
      ),
      enabled:
        isOneOfUserRoles(memberOf, [USER_ROLE_OPS, USER_ROLE_ADMIN]) &&
        !!get('privacy_policy', seller),
    },
    {
      title: 'Review Return Policies',
      description: 'Confirms that all return policies have been entered',
      link: `/${seller.id}${RoutePath.RETURN_POLICIES}`,
      complete: requiredReturnPolicies,
      enabled:
        isOneOfUserRoles(memberOf, [USER_ROLE_OPS, USER_ROLE_ADMIN]) &&
        !!returnPolicies,
    },
  ]
}
