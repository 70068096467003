import { useState } from 'react'

import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import CloseIcon from '@mui/icons-material/Close'

import DataList from 'components/common/DataList'
import Input from 'components/common/Input'

import { ErrorCode } from 'types/ErrorCode'

interface Props {
  error: ErrorCode
  handleRemove: (error: ErrorCode) => () => void
  handleEdit: (error: ErrorCode) => void
}

export const ErrorCodeLineItem = ({
  error,
  handleRemove,
  handleEdit,
}: Props) => {
  const { error_code, reason } = error
  const [notes, setNotes] = useState<string>('')

  const handleNote = (value: string) => {
    setNotes(value.replace(/[\u{0080}-\u{FFFF}]/gu, ''))
    handleEdit({
      ...error,
      optionalNotes: value,
    })
  }

  return (
    <ListItem disableGutters>
      <Grid container>
        <Grid item xs={11}>
          <Grid container>
            <Grid item xs={12}>
              <DataList data={[{ title: error_code, value: reason }]} />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={11}>
              <Input
                id="additional-notes"
                name="notes"
                onChange={handleNote}
                helperText="Optional: Additional Notes"
                value={notes || ''}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            data-testid="remove-error"
            aria-label="remove"
            onClick={handleRemove(error)}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    </ListItem>
  )
}
