import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'
import { useState } from 'react'

import {
  Select,
  FormControl,
  MenuItem,
  IconButton,
  Button,
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import DoneIcon from '@mui/icons-material/Done'
import CancelIcon from '@mui/icons-material/Cancel'

import Text from 'components/common/Text'
import { DialogEnum } from '../Dialog'

import { white, filterChip } from 'config/themeConfig'
import { openDialog } from 'store/dialog/actionCreator'
import { REVIEW_QUEUE } from 'constants/userSettings'
import { sortBySavedSearchName } from 'services/savedSearchHelper'
import { SavedSearch } from 'types/SavedSearch'

type SelectOption = {
  saved_search_name: string[]
  value?: any
  children?: any[]
  disabled?: boolean
}

const StyledLabel = styled(Text)((props) => ({
  marginBottom: props.theme.spacing(1),
}))

const StyledSelect = styled(Select)(
  (props) => ({
    height: '36px',
    backgroundColor: white.main,
    color: props.theme.palette.grey[700],
    '& .MuiSelect-select': {
      padding: props.theme.spacing(1, 2, 1, 6),
      '&:focus': {
        backgroundColor: 'initial',
      },
    },
    '& .MuiSelect-icon': {
      right: 'initial',
      left: props.theme.spacing(2),
    },
    '& .MuiSelect-iconOpen': {
      transform: 'initial',
    },
    '&.MuiOutlinedInput-input': {
      height: '36px',
    },
  }),
  (props) => ({
    backgroundColor:
      props.value !== '' && props.value !== undefined
        ? filterChip.main
        : undefined,
    color:
      props.value !== '' && props.value !== undefined
        ? props.theme.palette.grey[900]
        : undefined,
  }),
)

const StyledActionBar = styled('div')({
  position: 'absolute',
  bottom: '0px',
  background: 'white',
  width: '100%',
  paddingBottom: '10px',
})

export interface Props {
  label?: string
  placeholder: string
  value: string | undefined
  data: SavedSearch[]
  selectionTitle?: string
  onChange: (params: any) => void
  onClear: any
  disabled?: boolean
}

const getNameFromValue = (value: string, data: SelectOption[]) => {
  const item = data.find((i) => i.saved_search_name[0] === value)
  return item ? window.atob(item?.saved_search_name[0] ?? '') : ''
}

export const SavedSearchSelectFilter = ({
  label,
  placeholder,
  value,
  data,
  selectionTitle,
  onChange,
  onClear,
  disabled,
}: Props) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)

  const handleMenuItemClick = (savedSearch: any) => {
    onChange(savedSearch)
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpenManageSavedSearchesDialog = () => {
    dispatch(
      openDialog({
        dialogEnum: DialogEnum.MANAGE_SAVED_SEARCH_DIALOG,
        componentProps: {
          page: REVIEW_QUEUE,
        },
      }),
    )
  }

  const iconComponent = value ? DoneIcon : ArrowDropDownIcon

  const endAdornment = value ? (
    <IconButton
      onClick={() => onClear()}
      data-testid="remove-filter"
      aria-label={`Remove ${placeholder} Filter`}
      size="large"
    >
      <CancelIcon color="primary" />
    </IconButton>
  ) : undefined

  return (
    <>
      {label && <StyledLabel type="bold">{label}</StyledLabel>}
      <FormControl fullWidth>
        <StyledSelect
          disabled={disabled}
          style={{ width: '300px' }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          }}
          displayEmpty
          variant="outlined"
          IconComponent={iconComponent}
          value={value ?? ''}
          inputProps={{
            'data-testid': 'saved-search-select',
            'aria-label':
              value !== undefined
                ? `${placeholder}: ${window.atob(value ?? '')}`
                : `${placeholder}: none selected`,
          }}
          endAdornment={endAdornment}
          renderValue={(selected: any) => {
            if (selected.length === 0) {
              return placeholder
            }

            const adornment = selectionTitle ?? ``
            const name = getNameFromValue(selected, data)

            return `${adornment}${name}`
          }}
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
        >
          <div
            style={{
              maxHeight: '300px',
              marginBottom: '50px',
              overflowY: 'scroll',
            }}
          >
            {sortBySavedSearchName(data).map(
              (savedSearch: SavedSearch, key) => {
                const savedSearchName = savedSearch?.saved_search_name?.[0]
                return (
                  <MenuItem
                    disableRipple
                    key={key}
                    value={savedSearchName}
                    data-testid={value}
                    onClick={() => handleMenuItemClick(savedSearch)}
                  >
                    {window.atob(savedSearchName ?? '')}
                  </MenuItem>
                )
              },
            )}
          </div>

          <StyledActionBar>
            <Button
              style={{ float: 'right', marginRight: '10px', marginTop: '10px' }}
              onClick={handleOpenManageSavedSearchesDialog}
              data-testid="manage-saved-filters-button"
            >
              Manage Saved Filters
            </Button>
          </StyledActionBar>
        </StyledSelect>
      </FormControl>
    </>
  )
}

export default SavedSearchSelectFilter
