import { useQuery } from '@tanstack/react-query'

import { getSeller } from 'services/seller'
import { getSellerByIdQueryKey } from './queries'

export const useSellerById = (sellerId?: string) => {
  const query = useQuery(getSellerByIdQueryKey(sellerId), () => {
    if (sellerId) {
      return getSeller(sellerId)
    }
    return null
  })

  return query
}
