import apiConfig, { ENVIRONMENT_PRODUCTION } from 'config/apiConfig'

interface FlagConfigItem {
  name: string
  stage: boolean
  prod: boolean
}

export enum FlagName {
  ITEM_ERRORS = 'ITEM_ERRORS',
  BUY_SHIPPING_MARKUP = 'BUY_SHIPPING_MARKUP',
  PROMO_VISIBILITY = 'PROMO_VISIBILITY',
}

const flagConfig: FlagConfigItem[] = [
  {
    name: FlagName.ITEM_ERRORS,
    stage: true,
    prod: true,
  },
  {
    name: FlagName.BUY_SHIPPING_MARKUP,
    stage: true,
    prod: false,
  },
  {
    name: FlagName.PROMO_VISIBILITY,
    stage: false,
    prod: false,
  },
]

export const flag = (flagName: string) => {
  const { stage, prod } = flagConfig.find(
    ({ name }) => name === flagName,
  ) as FlagConfigItem

  if (apiConfig.environment === ENVIRONMENT_PRODUCTION) {
    return prod
  }

  return stage
}
