import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { useTheme, Theme } from '@mui/material/styles'

import useMediaQuery from '@mui/material/useMediaQuery'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'

import { DialogEnum } from 'components/common/Dialog'
import Input from 'components/common/Input'
import TabularData, { FieldList } from 'components/common/TabularData'

import { isOneOfUserRoles } from 'services/authorization'
import { getConfidenceForBrandsWithAttributes } from 'services/confidence'
import { USER_ROLE_ADMIN, USER_ROLE_ITEM_MANAGER } from 'services/roles'

import { openDialog } from 'store/dialog/actionCreator'
import { getMemberOf } from 'store/selectors'

import { ConfidenceScore } from 'types/Confidence'

const StyledForm = styled('form')(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const StyledButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: 'flex',
  justifyContent: 'flex-end',
}))

const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}))

const fieldList: FieldList[] = [
  {
    key: 'name',
    displayName: 'Brand Name',
  },
  {
    key: 'description',
    displayName: 'Description',
  },
  {
    key: 'confidence',
    displayName: 'Confidence',
  },
]

export const ManageBrands = () => {
  const dispatch = useDispatch()
  const memberOf = useSelector(getMemberOf)
  const canEdit = isOneOfUserRoles(memberOf, [
    USER_ROLE_ADMIN,
    USER_ROLE_ITEM_MANAGER,
  ])

  const theme = useTheme<Theme>()
  const [value, setValue] = React.useState('')
  const [brands, setBrands] = React.useState<ConfidenceScore[] | null>(null)
  const [isPending, setIsPending] = React.useState(false)

  const matchesMediaQuery = useMediaQuery(theme.breakpoints.down('md'))
  const handleChange = (value: string) => {
    setValue(value)
  }

  const handleBlur = (value: string) => {
    setValue(value.trim())
  }

  const handleSubmit = async (
    event: React.MouseEvent | React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault()

    if (!value) return

    setIsPending(true)
    const { results } = await getConfidenceForBrandsWithAttributes(
      { page: 0, perPage: 30 },
      { q: value },
    )

    setBrands(results)
    setIsPending(false)
  }

  const clearTable = () => {
    setBrands(null)
    setValue('')
  }

  const showAddDialog = () => {
    dispatch(
      openDialog({
        dialogEnum: DialogEnum.ADD_BRAND,
      }),
    )
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        data-testid="container"
      >
        <Grid item xs={12}>
          <StyledForm onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Input
                  id="brand-search"
                  name="brand-search"
                  data-testid="brand-search"
                  helperText="Enter the Brand name as provided by the partner"
                  value={value}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  type="submit"
                  data-testid="submit-btn"
                  variant="contained"
                  color="primary"
                  fullWidth={matchesMediaQuery}
                  disabled={value.length < 2}
                >
                  search
                </Button>
              </Grid>
            </Grid>
          </StyledForm>
          {isPending && <LinearProgress />}
          {brands && (
            <>
              <StyledTypography>
                Target is looking a confidence rate of 80 of higher to be able
                to assign a brand.
              </StyledTypography>
              <Typography>A value will not be assigned if:</Typography>
              <ul>
                <li>The confidence rate is below 80.</li>
                <li>
                  The confidence rate is above 80, but there is a score tie.
                </li>
              </ul>
              <StyledTypography>
                If there is an appropriate brand below which should be assigned,
                have the partner submit the item with the brand name exactly as
                it appears below.
              </StyledTypography>
              <TabularData data={brands} fieldList={fieldList} />
              {canEdit && (
                <StyledButtonContainer>
                  <StyledButton
                    type="button"
                    data-testid="abort-btn"
                    variant="contained"
                    onClick={clearTable}
                    color="primary"
                    fullWidth={matchesMediaQuery}
                  >
                    Abort
                  </StyledButton>
                  <StyledButton
                    type="button"
                    data-testid="add-btn"
                    onClick={showAddDialog}
                    variant="contained"
                    color="primary"
                    fullWidth={matchesMediaQuery}
                  >
                    This is not a duplicate
                  </StyledButton>
                </StyledButtonContainer>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default ManageBrands
