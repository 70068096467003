import React from 'react'
import styled from '@emotion/styled'

import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'

const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  paddingTop: 0,
  '&:last-child': {
    paddingBottom: 0,
  },
}))

const StyledTitle = styled('span')({
  fontWeight: 'bold',
})

const StyledSpan = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
}))

const StyledFlexContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hasElement',
})<{ hasElement: boolean }>((props) => ({
  display: props.hasElement ? 'flex' : undefined,
  alignItems: props.hasElement ? 'center' : undefined,
}))

export interface DataListItem {
  title?: string | number
  value?: any
  element?: React.ReactNode
  listItemStyles?: object
}

export interface Props {
  className?: string
  data: DataListItem[]
}

export const DataList = ({ className, data }: Props) => {
  return (
    <List className={className} data-testid="data-list" disablePadding>
      {data.map((item, index) => {
        const { title, value, element } = item

        return (
          <StyledListItem
            key={index}
            data-testid="data-list-item"
            sx={item.listItemStyles ?? {}}
            disableGutters
          >
            <StyledFlexContainer hasElement={!!element}>
              <Typography data-testid="metadata-item" component="div">
                {title && (
                  <>
                    <StyledTitle data-testid="metadata-item-title">
                      {title}
                    </StyledTitle>{' '}
                  </>
                )}
                {value !== undefined && (
                  <StyledSpan data-testid="metadata-item-value">
                    {value}
                  </StyledSpan>
                )}
                {element && (
                  <StyledSpan data-testid="metadata-item-element">
                    {element}
                  </StyledSpan>
                )}
              </Typography>
            </StyledFlexContainer>
          </StyledListItem>
        )
      })}
    </List>
  )
}

export default DataList
