import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import Typography from '@mui/material/Typography'
import LinearProgress from '@mui/material/LinearProgress'

import { DialogEnum } from 'components/common/Dialog'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import EditButton from 'components/common/EditButton'
import { formatDateMDYT } from 'components/common/EnhancedTable/formatters'
import TitleBar from 'components/common/TitleBar'
import Text from 'components/common/Text'

import { getApiTokens } from 'services/apiTokens'
import { isUserRoleSellerAdmin, isOneOfUserRoles } from 'services/authorization'
import { USER_ROLE_OPS, USER_ROLE_ADMIN } from 'services/roles'
import { approvedToListStatuses } from 'services/seller'

import { SellerStatus, SmsSeller } from 'types/Seller'
import { getMemberOf, currentSeller } from 'store/selectors'

import { ApiToken } from 'types/ApiTokens'

const fieldList: EnhancedTableFieldType<ApiToken>[] = [
  {
    key: 'created',
    heading: 'Created Date',
    width: 30,
    formatCell: formatDateMDYT('created'),
  },
  {
    key: 'description',
    heading: 'Description',
    width: 40,
  },
  {
    key: 'last_used',
    heading: 'Last Used',
    width: 30,
    formatCell: formatDateMDYT('last_used', 'Never'),
  },
]

const sellerCanAddToken = (seller: SmsSeller) => {
  return [...approvedToListStatuses, SellerStatus.PARTNER_SETUP].includes(
    seller.status,
  )
}

const ApiTokensTable = () => {
  const [isPending, setIsPending] = useState(false)
  const [tokens, setTokens] = useState<ApiToken[]>([])
  const seller = useSelector(currentSeller)
  const memberOf = useSelector(getMemberOf)

  useEffect(() => {
    if (seller?.id) {
      fetchApiTokens()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seller])

  if (!seller) return null

  const fetchApiTokens = () => {
    setIsPending(true)
    getApiTokens(seller.id)
      .then(setTokens)
      .finally(() => setIsPending(false))
  }

  const hasEdit =
    (isUserRoleSellerAdmin(memberOf) && sellerCanAddToken(seller)) ||
    isOneOfUserRoles(memberOf, [USER_ROLE_OPS, USER_ROLE_ADMIN])

  return (
    <>
      <TitleBar
        title="API Tokens"
        actionButtons={[
          <EditButton
            key="edit-api-tokens"
            aria-label="edit-api-tokens"
            dialogComponent={DialogEnum.EDIT_API_TOKENS}
            componentProps={{ seller }}
            hide={!hasEdit}
            closeCallback={fetchApiTokens}
          />,
        ]}
      />
      <Text type="emphasis" paragraph>
        *Required before items can list.
      </Text>
      <Typography paragraph>
        API tokens are used to authenticate integration access, they can be used
        by a third party integrator on behalf of a partner and/or directly by a
        partner to access the Target Plus APIs. In order to generate an API
        token, you must be an admin user.
      </Typography>
      {isPending ? (
        <LinearProgress data-testid="api-tokens-progress" />
      ) : (
        <EnhancedTable
          fieldList={fieldList}
          data={tokens}
          isLoading={isPending}
        />
      )}
    </>
  )
}

export default ApiTokensTable
