import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'

import HeaderTitle from 'components/common/HeaderTitle'
import TitleBar from 'components/common/TitleBar'
import InformActCard from './InformActCard'
import InformActVerification from './InformActVerification'

import { getSellerVerificationsForIds } from 'services/sellerVerification'

import { getSellers, getUserEntitlements } from 'store/selectors'
import { setCurrentSeller } from 'store/seller/actionCreators'

import { SmsSeller } from 'types/Seller'
import {
  SellerVerification,
  SellerVerificationStatus,
} from 'types/SellerVerification'
import { Entitlement, Role } from 'types/SellerUser'

const getRoleForSeller = (
  seller: SmsSeller,
  entitlements: Entitlement[],
): Role | undefined => {
  const entitlement = entitlements.find((e) => e.resource_id === seller.id)

  return entitlement?.role
}

const getVerificationForSeller = (
  seller: SmsSeller,
  verifications: SellerVerification[],
): SellerVerification | undefined => {
  const verification = verifications.find((v) => v.seller_id === seller.id)

  return verification
}

export const InformActPage = () => {
  const reduxDispatch = useDispatch()
  const sellers = useSelector(getSellers)
  const entitlements = useSelector(getUserEntitlements)

  const [pending, setPending] = useState(false)
  const [verifications, setVerifications] = useState<SellerVerification[]>([])

  const [formData, setFormData] = useState<
    | {
        seller: SmsSeller
        verification: SellerVerification
      }
    | undefined
  >()

  const [reloadTrigger, setReloadTrigger] = useState(0)

  const hasBackButton = sellers.length > 1

  useEffect(() => {
    let mounted = true

    const ids = sellers.map((seller) => seller.id)

    if (ids.length) {
      setPending(true)

      getSellerVerificationsForIds(ids)
        .then((res) => {
          if (mounted) {
            setVerifications(res)

            if (sellers.length === 1) {
              const seller = sellers[0]
              const role = getRoleForSeller(seller, entitlements)
              const verification = getVerificationForSeller(seller, res)

              if (
                verification?.status === SellerVerificationStatus.PENDING &&
                role === Role.ADMIN
              ) {
                // If user is attached to only 1 seller AND is an admin for that seller AND verification status is pending just display the form.
                setFormData({
                  seller,
                  verification,
                })
              }
            }
          }
        })
        .finally(() => {
          if (mounted) {
            setPending(false)
          }
        })
    }

    return () => {
      mounted = false
    }

    // Remove sellers from dependency array so that tests do not blow up.
    // Possibly because sellers is an array and [] == [] is false.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadTrigger])

  const handleReview = (
    seller: SmsSeller,
    verification: SellerVerification,
  ) => {
    reduxDispatch(setCurrentSeller(seller.id))

    setFormData({
      seller,
      verification,
    })
  }

  const handleBackClick = ({ reload }: { reload: boolean }) => {
    setFormData(undefined)

    if (reload) {
      setReloadTrigger((prev) => prev + 1)
    }
  }

  return (
    <div data-testid="inform-act-page">
      <HeaderTitle title="Inform Act Verification" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitleBar title="Verify Inform Act Attributes" />
        </Grid>
      </Grid>
      {pending && <LinearProgress data-testid="progressBar" />}
      {!pending && !formData && (
        <Grid container spacing={2}>
          {sellers.map((seller) => {
            const informActVerification = getVerificationForSeller(
              seller,
              verifications,
            )

            const role = getRoleForSeller(seller, entitlements)

            return (
              <Grid item xs={12} key={seller.id}>
                <InformActCard
                  seller={seller}
                  role={role}
                  verification={informActVerification}
                  onReviewClick={handleReview}
                />
              </Grid>
            )
          })}
        </Grid>
      )}
      {!pending && formData && (
        <InformActVerification
          seller={formData.seller}
          verification={formData.verification}
          hasBackButton={hasBackButton}
          onBackClick={handleBackClick}
        />
      )}
    </div>
  )
}

export default InformActPage
