import { call, put, takeEvery } from 'redux-saga/effects'

import {
  fetchEnumerationsDone,
  fetchEnumerationsError,
  FETCH_ENUMERATIONS,
} from './actionCreators'

import { getEnumerations } from 'services/enumerations'

import { EnumerationDict } from 'types/Enumeration'

export function* fetchEnumerationsSaga() {
  try {
    const smsEnumerations: EnumerationDict = yield call(getEnumerations)
    const data = { ...smsEnumerations }
    yield put(fetchEnumerationsDone(data))
  } catch (e) {
    yield put(fetchEnumerationsError())
    console.error(`Failed to get the SMS enumerations: ${e}`)
  }
}

export function* enumerationSagas() {
  yield takeEvery(FETCH_ENUMERATIONS, fetchEnumerationsSaga)
}
