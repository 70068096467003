import React, { SyntheticEvent, PropsWithChildren } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import styled from '@emotion/styled'

const StyledRouterLink = styled(RouterLink, {
  shouldForwardProp: (prop) => prop !== 'flexContainer',
})<{ flexContainer: boolean | undefined }>(
  ({ theme }) => ({
    color: theme.palette.action.active,
  }),
  (props) => ({
    display: props.flexContainer ? 'flex' : undefined,
    alignItems: props.flexContainer ? 'center' : undefined,
  }),
)

interface Props {
  to: any
  onClick?: (event: SyntheticEvent) => void
  target?: string
  flexContainer?: boolean
  rel?: string | undefined
  state?: any
}

export const Link: React.FunctionComponent<PropsWithChildren<Props>> = ({
  to,
  children,
  onClick,
  target = '_self',
  flexContainer,
  state,
  ...restProps
}) => {
  return (
    <StyledRouterLink
      flexContainer={flexContainer}
      to={to}
      onClick={onClick}
      target={target}
      state={state}
      {...restProps}
    >
      {children}
    </StyledRouterLink>
  )
}

export default Link
