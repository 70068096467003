import { useSelector, useDispatch } from 'react-redux'
import Typography from '@mui/material/Typography'

import ContentSpacer from 'components/common/ContentSpacer'
import DialogContainer from 'components/common/Dialog/DialogContainer'
import Link from 'components/common/Link'

import { RoutePath } from 'services/NavigationHelper'

import { closeDialog } from 'store/dialog/actionCreator'
import { currentSellerId } from 'store/selectors'

export interface Props {
  isOpen: boolean
}

export const ErrorReturnsContact = (props: Props) => {
  const { isOpen } = props

  const dispatch = useDispatch()

  const sellerId = useSelector(currentSellerId)

  return (
    <DialogContainer isOpen={isOpen} title="User Cannot Be Deleted">
      <Typography>
        This user is associated with a return policy. The policy's contact needs
        to be edited before deleting this user.
      </Typography>
      <ContentSpacer />
      <Link
        data-testid="return-policies-link"
        to={`/${sellerId}${RoutePath.RETURN_POLICIES}`}
        onClick={() => dispatch(closeDialog())}
      >
        Click here to access the return policies page.
      </Link>
    </DialogContainer>
  )
}

export default ErrorReturnsContact
