import { useEffect, useReducer } from 'react'
import { useNavigate, useNavigationType, useLocation } from 'react-router-dom'
import { Location } from 'history'
import isNumber from 'lodash/fp/isNumber'
import startCase from 'lodash/fp/startCase'

import styled from '@emotion/styled'

import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'

import {
  reducer,
  updateSearchTerm,
  updateCategory,
  changePage,
  changePerPage,
  setPending,
  setArticles,
  browserAction,
  initialReducerState,
  State,
} from './reducer'
import PaginatedList from 'components/common/PaginatedList'
import Breadcrumb from 'components/common/Breadcrumb'

import SearchTitle from './SearchTitle'
import KnowledgeResult from './KnowledgeResult'

import { createUrl, getParams } from 'services/urlHelper'
import {
  getKnowledgeArticles,
  getKnowledgeArticlesByCategory,
} from 'services/knowledgeArticles'
import { trackCustomEvent } from 'services/fireflyInsights'

import { FireflyEvent } from 'types/FireflyInsights'
import { RoutePath } from 'services/NavigationHelper'

const StyledClearButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}))

const StyledResultHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  margin: theme.spacing(3, 0),
}))

const StyledLoader = styled(LinearProgress)(({ theme }) => ({
  margin: theme.spacing(0, -5),
}))

export const NO_RESULTS_MESSAGE =
  'Sorry we were unable to find any matching results. Please check your spelling or try a more generic search term.'

export const DEFAULT_PAGE = 0
export const DEFAULT_PER_PAGE = 10

const getUrlParams = (location: Location) => {
  const initParams = getParams(location)

  let initPage = DEFAULT_PAGE
  let initPerPage = DEFAULT_PER_PAGE
  let initSearchTerm: string | undefined = undefined
  let initCategory: string | undefined = undefined

  if (initParams) {
    if (initParams.page && typeof initParams.page === 'string') {
      initPage = parseInt(initParams.page, 10)
    }

    if (initParams.perPage && typeof initParams.perPage === 'string') {
      initPerPage = parseInt(initParams.perPage, 10)
    }

    if (initParams.searchTerm && typeof initParams.searchTerm === 'string') {
      initSearchTerm = initParams.searchTerm
    }

    if (initParams.category && typeof initParams.category === 'string') {
      initCategory = initParams.category
    }
  }

  return {
    ...initParams,
    ...(initParams.page ? { page: initPage } : { page: DEFAULT_PAGE }),
    ...(initParams.perPage
      ? { perPage: initPerPage }
      : { perPage: DEFAULT_PER_PAGE }),
    ...(initParams.searchTerm
      ? { searchTerm: initSearchTerm }
      : { searchTerm: undefined }),
    ...(initParams.category
      ? { category: initCategory }
      : { category: undefined }),
  }
}

const getNextUrl = ({
  pathname,
  searchTerm,
  category,
  page,
  perPage,
}: {
  pathname: string
  searchTerm: string | undefined
  category: string | undefined
  page: number
  perPage: number
}) => {
  return createUrl(pathname, {
    searchTerm,
    category,
    page,
    perPage,
  })
}

export const ArticleSearchResultsPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const navigationType = useNavigationType()

  const params = getUrlParams(location)

  const initialState: State = {
    ...initialReducerState,
    ...params,
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    // Add page and perPage to the url if they are not there.
    // Use history.replace to prevent another route from getting pushed onto the stack

    const currentUrl = `${location.pathname}${location.search}`
    const nextUrl = getNextUrl({
      pathname: location.pathname,
      searchTerm: initialState.searchTerm,
      category: initialState.category,
      page: initialState.page,
      perPage: initialState.perPage,
    })

    if (currentUrl !== nextUrl) {
      navigate(nextUrl, { replace: true })
    }
  }, [
    navigate,
    initialState.page,
    initialState.perPage,
    initialState.searchTerm,
    initialState.category,
    location.pathname,
    location.search,
    params,
  ])

  useEffect(() => {
    // Updating a url search param will not rerender a route.
    const urlParams = getUrlParams(location)

    if (
      typeof params.searchTerm === 'string' &&
      params.searchTerm !== state.searchTerm
    ) {
      dispatch(
        updateSearchTerm(params.searchTerm, urlParams.page, urlParams.perPage),
      )
    } else if (
      typeof params.category === 'string' &&
      params.category !== state.category
    ) {
      updateCategory(params.category, urlParams.page, urlParams.perPage)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.searchTerm, params.category, state.searchTerm, state.category])

  useEffect(() => {
    if (navigationType !== 'POP') return

    const params = getParams({
      search: location.search,
    }) as Dictionary<string | number>

    const page =
      typeof params?.page === 'string' ? parseInt(params.page, 10) : undefined

    const perPage =
      typeof params?.perPage === 'string'
        ? parseInt(params.perPage, 10)
        : undefined

    const searchTerm =
      typeof params?.searchTerm === 'string' ? params.searchTerm : undefined

    const category =
      typeof params?.category === 'string' ? params.category : undefined

    if (isNumber(page)) {
      params.page = page
    }

    if (isNumber(perPage)) {
      params.perPage = perPage
    }

    if (typeof searchTerm === 'string') {
      params.searchTerm = searchTerm
    }

    if (typeof category === 'string') {
      params.category = category
    }

    dispatch(browserAction(params))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, navigationType])

  useEffect(() => {
    let mounted = true

    const fetchArticlesBySearchTerm = async () => {
      if (typeof state.searchTerm === 'string') {
        dispatch(setPending(true))
        const response = await getKnowledgeArticles(
          state.searchTerm,
          state.page,
          state.perPage,
        )

        if (mounted) {
          dispatch(setArticles(response.data, response.total))
          trackCustomEvent(
            FireflyEvent.HELP_SEARCH,
            state.searchTerm,
            response.total,
          )
        }

        dispatch(setPending(false))
      }
    }

    const fetchArticlesByCategory = async () => {
      if (typeof state.category === 'string') {
        dispatch(setPending(true))
        const response = await getKnowledgeArticlesByCategory({
          category: state.category,
          page: state.page,
          perPage: state.perPage,
        })

        if (mounted) {
          dispatch(setArticles(response.data, response.total))
        }

        dispatch(setPending(false))
      }
    }

    if (state.searchTerm) {
      fetchArticlesBySearchTerm()
    } else if (state.category) {
      fetchArticlesByCategory()
    }

    return () => {
      mounted = false
    }
  }, [state.searchTerm, state.category, state.page, state.perPage])

  const handlePageChange = (nextPage: number) => {
    dispatch(changePage(nextPage))

    const nextUrl = getNextUrl({
      pathname: location.pathname,
      searchTerm: state.searchTerm,
      category: state.category,
      page: nextPage,
      perPage: state.perPage,
    })

    navigate(nextUrl)
  }

  const handlePerPageChange = (nextPerPage: number) => {
    dispatch(changePerPage(nextPerPage))

    const nextUrl = getNextUrl({
      pathname: location.pathname,
      searchTerm: state.searchTerm,
      category: state.category,
      page: initialReducerState.page,
      perPage: nextPerPage,
    })

    navigate(nextUrl)
  }

  const handleClear = () => {
    navigate(`/${RoutePath.HELP_RELATIVE}`)
  }

  const categoryTitle = state.category ? startCase(state.category) : ''

  return (
    <div>
      {state.pending && <StyledLoader />}
      {!state.pending && state.total === 0 && (
        <Typography>{NO_RESULTS_MESSAGE}</Typography>
      )}
      {!state.pending && state.total > 0 && state.articles.length > 0 && (
        <>
          {state.category && (
            <div>
              <Breadcrumb
                breadcrumbs={[
                  {
                    href: `/${RoutePath.HELP_RELATIVE}`,
                    name: `Help Center`,
                  },
                  {
                    name: categoryTitle,
                  },
                ]}
              />
            </div>
          )}
          <StyledResultHeader>
            <SearchTitle
              searchTerm={state.searchTerm}
              category={categoryTitle}
              total={state.total}
            />
            {state.searchTerm && (
              <StyledClearButton
                data-testid="search-clear"
                onClick={handleClear}
                color="primary"
              >
                <CloseIcon />
                Clear Results
              </StyledClearButton>
            )}
          </StyledResultHeader>
          <PaginatedList
            count={state.total}
            items={state.articles.map((article) => (
              <KnowledgeResult
                article={article}
                searchTerm={state.searchTerm}
                category={state.category}
              />
            ))}
            page={state.page}
            rowsPerPage={state.perPage}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerPageChange}
          />
        </>
      )}
    </div>
  )
}

export default ArticleSearchResultsPage
