import { Form } from 'formik'
import React from 'react'
import * as yup from 'yup'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

import styled from '@emotion/styled'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import CheckboxField from 'components/common/form/CheckboxField'
import InputField from 'components/common/form/InputField'
import PhoneField, {
  tollFreeErrorMsg,
  tollFreeRegex,
} from 'components/common/form/PhoneField'
import RadioGroupField from 'components/common/form/RadioGroupField'
import Text from 'components/common/Text'

import {
  LABEL_FIRST_NAME,
  LABEL_LAST_NAME,
  LABEL_EMAIL,
  LABEL_PHONE_NUMBER_OFFICE,
  LABEL_PHONE_NUMBER_MOBILE,
  LABEL_ERROR_PHONE_NUMBER_MOBILE,
  LABEL_ERROR_PHONE_NUMBER_OFFICE,
  LABEL_MOBILE_COUNTRY_CODE,
  LABEL_OFFICE_COUNTRY_CODE,
} from 'constants/labels'

import { getResponsibilityLabel } from 'services/functionalResponsibilities'
import { allResponsibilities } from 'services/usersHelper'
import { ValidationSchema } from 'services/validation'

import { CountryNameCode } from 'types/Country'
import SellerUser, { Role } from 'types/SellerUser'

import { Props } from './CreateSellerContact'

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

export interface FormValues {
  entitlement: Role | undefined
  firstName: string
  lastName: string
  email: string
  officeCountry: string
  mobileCountry: string
  officePhoneNumber: string
  mobilePhoneNumber: string
}

export const validationSchema: ValidationSchema = yup.object().shape({
  entitlement: yup.string().required(),
  firstName: yup.string().label(LABEL_FIRST_NAME).required(),
  lastName: yup.string().label(LABEL_LAST_NAME).required(),
  email: yup.string().label(LABEL_EMAIL).email().required(),
  officeCountry: yup.string().label(LABEL_OFFICE_COUNTRY_CODE),
  officePhoneNumber: yup.string().when(['officeCountry'], {
    is: (officeCountry: string) =>
      officeCountry !== CountryNameCode.UNITED_STATES,
    then: yup
      .string()
      .required()
      .label(LABEL_PHONE_NUMBER_OFFICE)
      .min(8)
      .max(20),
    otherwise: yup
      .string()
      .required()
      .label(LABEL_PHONE_NUMBER_OFFICE)
      .length(14, LABEL_ERROR_PHONE_NUMBER_OFFICE),
  }),
  mobileCountry: yup.string().label(LABEL_MOBILE_COUNTRY_CODE),
  mobilePhoneNumber: yup.string().when(['entitlement'], {
    is: (entitlement: any) => entitlement === Role.NONE,
    then: yup.string(),
    otherwise: yup.string().when(['mobileCountry'], {
      is: (mobileCountry: string) =>
        mobileCountry === CountryNameCode.UNITED_STATES,
      then: yup
        .string()
        .label(LABEL_PHONE_NUMBER_MOBILE)
        .test({
          name: 'is-not-toll-freetoll',
          message: tollFreeErrorMsg,
          test(this: yup.TestContext, value: any) {
            if (!value) {
              return true
            }

            return !tollFreeRegex.test(value)
          },
        })
        .length(14, LABEL_ERROR_PHONE_NUMBER_MOBILE)
        .required(),
      otherwise: yup
        .string()
        .label(LABEL_PHONE_NUMBER_MOBILE)
        .test({
          name: 'is-not-toll-freetoll',
          message: tollFreeErrorMsg,
          test(this: yup.TestContext, value: any) {
            if (!value) {
              return true
            }
            return !tollFreeRegex.test(value)
          },
        })
        .min(8)
        .max(20)
        .required(),
    }),
  }),
})

const disableMobile = (contact?: SellerUser) => {
  if (!contact) {
    return false
  }

  const mobilePhone = contact.phone_numbers?.find((phone) => {
    return phone.type === 'MOBILE'
  })

  return !!mobilePhone
}

const portalAccessLevelOptions = [
  {
    label: 'Administrator',
    description: 'Can view, edit, upload, & download.',
    value: Role.ADMIN,
  },
  {
    label: 'Read-Only',
    description: 'Can view & download content.',
    value: Role.READ,
  },
  {
    label: 'None (Contact)',
    description:
      'Cannot access portal but can be contacted for support issues.',
    value: Role.NONE,
  },
]

const CreateSellerContactForm = ({
  isOpen,
  isPending,
  isValid,
  dirty,
  handleSubmit,
  contact,
  sellerEntitlement,
  values,
  errors,
  touched,
}: Props) => {
  const [officeCountry, setOfficeCountry] = React.useState<string>(
    CountryNameCode.UNITED_STATES,
  )
  const [mobileCountry, setMobileCountry] = React.useState<string>(
    CountryNameCode.UNITED_STATES,
  )
  const [officePhoneError, setOfficePhoneError] = React.useState('')
  const [mobilePhoneError, setMobilePhoneError] = React.useState('')
  const [displayMobile, setDisplayMobile] = React.useState(
    sellerEntitlement ? sellerEntitlement.role !== Role.NONE : false,
  )
  const [userEditable, setUserEditable] = React.useState(
    sellerEntitlement ? sellerEntitlement.role === Role.NONE : true,
  )

  const isMobileDisabled = disableMobile(contact)

  const onPortalAccessLevelChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setDisplayMobile(value !== Role.NONE)
    setUserEditable(value === Role.NONE)
  }

  const title = contact ? `Manage Details` : 'Add New'

  React.useEffect(() => {
    setOfficeCountry(values.officeCountry)

    const error = errors?.officePhoneNumber

    if (touched['officePhoneNumber'] && error) {
      setOfficePhoneError(error)
    } else {
      setOfficePhoneError('')
    }
  }, [values.officeCountry, errors, touched])

  React.useEffect(() => {
    setMobileCountry(values.mobileCountry)

    const error = errors?.mobilePhoneNumber

    if (touched['mobilePhoneNumber'] && error) {
      setMobilePhoneError(error)
    } else {
      setMobilePhoneError('')
    }
  }, [values.mobileCountry, errors, touched])

  const isSaveDisabled = () => {
    if (isPending) {
      return true
    }
    if (contact?.id) {
      return !isValid || !dirty
    } else {
      return !isValid || !dirty
    }
  }

  return (
    <DialogContainer
      title={title}
      maxWidth="md"
      isOpen={isOpen}
      isPending={isPending}
      onSubmit={handleSubmit}
      isSubmitDisabled={isSaveDisabled()}
    >
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <StyledPaper elevation={0}>
              <Text>Portal Access Level*</Text>
              <RadioGroupField
                name="entitlement"
                options={portalAccessLevelOptions}
                disabled={isPending}
                onChange={onPortalAccessLevelChange}
                required
              />
            </StyledPaper>
            <StyledPaper elevation={0}>
              <Text>Responsible for handling issues related to</Text>
              <Typography variant="caption">
                (Optional: Select 1 or more)
              </Typography>
              {allResponsibilities.map((responsibility) => (
                <div key={responsibility}>
                  <CheckboxField
                    name={`responsibility-${responsibility}`}
                    label={getResponsibilityLabel(responsibility)}
                    disabled={isPending}
                  />
                </div>
              ))}
            </StyledPaper>
          </Grid>
          <Grid item xs={7}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <InputField
                  required
                  name="firstName"
                  label={LABEL_FIRST_NAME}
                  disabled={isPending || (!!contact && !userEditable)}
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  required
                  name="lastName"
                  label={LABEL_LAST_NAME}
                  disabled={isPending || (!!contact && !userEditable)}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  required
                  name="email"
                  label={LABEL_EMAIL}
                  disabled={isPending || !!contact}
                />
              </Grid>
              <Grid item xs={12}>
                <PhoneField
                  required
                  selectName="officeCountry"
                  name="officePhoneNumber"
                  id="office"
                  label={LABEL_PHONE_NUMBER_OFFICE}
                  disabled={isPending || (!!contact && !userEditable)}
                  country={officeCountry}
                  error={officePhoneError}
                />
              </Grid>
              {displayMobile && (
                <Grid item xs={12}>
                  <PhoneField
                    required
                    selectName="mobileCountry"
                    name="mobilePhoneNumber"
                    id="mobile"
                    label={LABEL_PHONE_NUMBER_MOBILE}
                    disabled={
                      isPending ||
                      (!!contact && !userEditable && isMobileDisabled)
                    }
                    country={mobileCountry}
                    error={mobilePhoneError}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </DialogContainer>
  )
}

export default CreateSellerContactForm
