import { combineReducers } from '@reduxjs/toolkit'

import dialogReducer from './dialog/reducer'
import layoutReducer from './layout/reducer'
import paymentAccountReducer from './paymentAccount/reducer'
import sellerReducer from './seller/reducer'
import userReducer from './user/reducer'
import notificationReducer from './notification/reducer'
import enumerationReducer from './enumeration/reducer'
import cacheReducer from './cache/reducer'
import itemReviewReducer from './itemReview/reducer'
import partnerDiversityReducer from './partnerDiversity/reducer'

import StoreState from 'types/state'

const rootReducer = combineReducers<StoreState>({
  dialog: dialogReducer,
  layout: layoutReducer,
  paymentAccount: paymentAccountReducer,
  seller: sellerReducer,
  user: userReducer,
  notification: notificationReducer,
  enumeration: enumerationReducer,
  cache: cacheReducer,
  itemReview: itemReviewReducer,
  partnerDiversity: partnerDiversityReducer,
})

export default rootReducer
