import { SelectOption } from './../types/SelectOption'
import { EnumerationValue } from './../types/Enumeration'
import axios from 'axios'
import startCase from 'lodash/fp/startCase'

import apiConfig from 'config/apiConfig'

import {
  Enumeration,
  EnumerationDict,
  EnumerationName,
} from 'types/Enumeration'
import { OrderStatus } from 'types/Orders'
import { SingleResponse } from 'types/Response'
import { Source } from 'types/Seller'

export async function getEnumerations(): Promise<EnumerationDict> {
  const { data }: SingleResponse<Enumeration[]> = await axios.get(
    `${apiConfig.sms}/enumerations`,
  )

  const dictionary = data.reduce((acc, curr) => {
    acc[curr.name] = curr.values
    return acc
  }, {} as EnumerationDict)

  return dictionary
}

export const getSellerShippingEnumerations =
  async (): Promise<EnumerationDict> => {
    const { data }: SingleResponse<Enumeration[]> = await axios.get(
      `${apiConfig.sellerShipping}/enumerations`,
    )

    const dictionary = data.reduce((acc, curr) => {
      acc[curr.name] = curr.values
      return acc
    }, {} as EnumerationDict)

    return dictionary
  }

export const sourceLabels = {
  [Source.ACENDA]: 'Acenda',
  [Source.BIG_COMMERCE]: 'BigCommerce',
  [Source.CHANNEL_ADVISOR]: 'Channel Advisor',
  [Source.COMMERCEHUB]: 'CommerceHub',
  [Source.DIRECT_FROM_SELLER]: 'Direct from Partner',
  [Source.ECOMDASH]: 'Ecomdash',
  [Source.GEEKSELLER]: 'GeekSeller',
  [Source.LISTING_MIRROR]: 'Listing Mirror',
  [Source.SELLBRITE]: 'Sellbrite',
  [Source.SELLER_ACTIVE]: 'SellerActive',
  [Source.SOLID_COMMERCE]: 'Solid Commerce',
  [Source.ZENTAIL]: 'Zentail',
}

export const orderStatusLabels = {
  [OrderStatus.PENDING]: 'Pending',
  [OrderStatus.RELEASED_FOR_SHIPMENT]: 'Released',
  [OrderStatus.ACKNOWLEDGED_BY_SELLER]: 'Unshipped',
  [OrderStatus.PARTIALLY_SHIPPED]: 'Partially Shipped',
  [OrderStatus.SHIPPED]: 'Shipped',
  [OrderStatus.CANCELED]: 'Cancelled',
  [OrderStatus.REFUNDED]: 'Refunded',
}

export const getPrettyName = ({
  enumeration,
  value,
}: {
  enumeration: EnumerationName
  value: string | undefined
}): string => {
  if (!value) {
    return ''
  }

  let dictionary = {} as Record<string, string>

  if (enumeration === EnumerationName.SOURCE) {
    dictionary = sourceLabels
  } else if (enumeration === EnumerationName.ORDER_STATUS) {
    dictionary = orderStatusLabels
  }

  return dictionary[value] || startCase(value.toLowerCase())
}

export const getOptionsFromEnumeration = (
  enumerationValues?: EnumerationValue[],
): SelectOption[] => {
  if (!enumerationValues) {
    return []
  }

  const list = enumerationValues.map((item) => ({
    name: item.properties.name,
    value: item.value,
  }))

  return list
}

type Property = 'description'
export const formatEnumerationBy = (
  property: Property,
  enumerationValues: EnumerationValue[],
): Array<{ name: string; value: string }> => {
  const sources = enumerationValues.map((source) => ({
    name: source.properties[property],
    value: source.value,
  }))

  return sources
}
