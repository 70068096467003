import { createFilterOptions } from '@mui/material'
import { SyntheticEvent, useState, KeyboardEvent, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getStateCodes } from 'store/selectors'
import { StateCode } from 'types/Enumeration'
import Typeahead from '.'

interface Props {
  onChange: (value: Nullable<StateCode[]>) => void
  eligibleStateCodes: Nullable<StateCode[]>
}

interface FilterOptionType {
  value: string
  properties: { name: string }
}

const StateCodeTypeahead = ({ onChange, eligibleStateCodes }: Props) => {
  const [inputValue, setInputValue] = useState('')
  const [selectedValue, setSelectedValue] =
    useState<Nullable<StateCode[]>>(eligibleStateCodes)

  const stateCodes = useSelector(getStateCodes)

  useEffect(() => {
    setSelectedValue(eligibleStateCodes)
  }, [eligibleStateCodes])

  const handleSelectedOptionChange = (
    _event: SyntheticEvent,
    selectedOptions: Nullable<StateCode[]>,
    reason: string,
  ) => {
    const event = _event as KeyboardEvent
    if (reason === 'removeOption' && event.key === 'Backspace') {
      return
    }

    onChange(selectedOptions)
    setSelectedValue(selectedOptions)
    setInputValue('')
  }

  const handleInputChange = (
    _event: SyntheticEvent,
    value: string,
    reason: string,
  ) => {
    if (reason === 'reset') {
      setInputValue('')
      return
    }

    setInputValue(value)
  }

  const getOptionLabel = (stateCode: StateCode) => stateCode.properties?.name

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: FilterOptionType) => option.properties.name,
  })

  return (
    <Typeahead
      aria-label="Search states"
      label="Search states"
      placeholder="Search states"
      options={stateCodes}
      getOptionLabel={getOptionLabel}
      onInputChange={handleInputChange}
      onChange={handleSelectedOptionChange}
      isOptionEqualToValue={(option: StateCode, value: Nullable<StateCode>) =>
        option.value === value?.value
      }
      inputValue={inputValue}
      value={selectedValue}
      filterOptions={filterOptions}
      multiple
      filterSelectedOptions
      disableClearable
    />
  )
}

export default StateCodeTypeahead
