import * as yup from 'yup'

import styled from '@emotion/styled'

import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import TimePicker from 'components/common/TimePicker'

import { DAYS } from 'constants/days'

import { convert12To24Hour, convert24To12Hour } from 'services/time'
import { validationHandler, ValidationSchema } from 'services/validation'
import { isAfterDate, parseDate } from 'services/dateService'

import { SmsDistributionCenter } from 'types/Seller'

import { DayOption } from '.'

const StyledTableCell = styled(TableCell)({
  borderBottom: 'none',
})

export const workingHoursSchema: ValidationSchema = yup.object().shape({
  openTime: yup.string().label('Open time').required(),
  closeTime: yup
    .string()
    .label('Close time')
    .required()
    .test({
      name: 'isAfter',
      message: 'Must be after open time',
      test(this: yup.TestContext, value: Nullable<string>) {
        const openTime = this.resolve(yup.ref('openTime')) as string

        if (!openTime || !value) {
          return true
        } else {
          const fromFormat =
            openTime.split(':').length === 2 ? 'HH:mm' : 'HH:mm:ss'
          const from = parseDate(openTime, fromFormat)

          const toFormat = value.split(':').length === 2 ? 'HH:mm' : 'HH:mm:ss'
          const to = parseDate(value, toFormat)

          return isAfterDate(to!, from!)
        }
      },
    }),
})

export const getDayOptions = (
  distributionCenter: Partial<SmsDistributionCenter>,
) => {
  const workingHours =
    distributionCenter?.days_of_operation?.working_hours ?? []

  const dayOptions = DAYS.map((day: string) => {
    const workingHour = workingHours.find(
      (val) => val.day.toLowerCase() === day.toLowerCase(),
    )
    const openTime = workingHour?.open_time ?? ''
    const closeTime = workingHour?.close_time ?? ''

    return {
      checked: !!workingHour,
      day,
      openTime,
      closeTime,
      validation: {},
    }
  })

  return dayOptions as DayOption[]
}

export const validateDayOptions = (dayOptions: DayOption[]) => {
  let isDaysValid = true

  const validatedDayOptions = dayOptions.map((day) => {
    if (day.checked) {
      const { validation, isValid } = validationHandler(workingHoursSchema, day)

      if (!isValid) {
        isDaysValid = false
      }

      day.validation = validation
    } else {
      day.validation = {}
    }

    return day
  })

  const workingHours = validatedDayOptions
    .map((option) => {
      if (option.checked) {
        return {
          day: option.day.toUpperCase(),
          open_time: option.openTime,
          close_time: option.closeTime,
        }
      }

      return false
    })
    .filter((day) => day)

  return { isDaysValid, validatedDayOptions, workingHours }
}

interface Props {
  dayOptions: DayOption[]
  onDayUpdate: (option: DayOption) => void
  isPending: boolean
}

const DaysOfOperationTable = ({
  dayOptions,
  onDayUpdate,
  isPending,
}: Props) => {
  const handleTimeChange = ({
    value,
    name,
    property,
  }: {
    value: string
    name: string
    property?: string
  }) => {
    const valueIn24Hour = convert12To24Hour(value)
    const option = dayOptions.find((opt) => opt.day === property)

    if (option) {
      const newOption = {
        ...option,
        [name]: valueIn24Hour,
      } as DayOption

      onDayUpdate(newOption)
    }
  }

  const handleCheckboxChange = (event: any) => {
    const { checked, value: day } = event.currentTarget

    const option = dayOptions.find((opt) => opt.day === day)

    if (!option) {
      return
    }

    let newOption = {
      ...option,
      checked,
      openTime: checked ? option.openTime : '',
      closeTime: checked ? option.closeTime : '',
      validation: checked ? option.validation : {},
    } as DayOption

    onDayUpdate(newOption)
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledTableCell padding="none" />
          <StyledTableCell>Open Time</StyledTableCell>
          <StyledTableCell>Close Time</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {dayOptions.map((option: DayOption, index) => {
          const {
            checked,
            day,
            openTime,
            closeTime,
            validation: dayValidation,
          } = option

          const openTimeIn12Hour = openTime && convert24To12Hour(openTime)
          const closeTimeIn12Hour = closeTime && convert24To12Hour(closeTime)

          return (
            <TableRow key={index}>
              <StyledTableCell padding="none">
                <FormControlLabel
                  control={
                    <Checkbox
                      data-testid="day-checkbox"
                      checked={checked}
                      tabIndex={-1}
                      disableRipple
                      onChange={handleCheckboxChange}
                      value={day}
                    />
                  }
                  label={day}
                />
              </StyledTableCell>
              <StyledTableCell>
                <TimePicker
                  isRequired
                  id={`${day}-Open`}
                  property={day}
                  name="openTime"
                  disabled={!checked || isPending}
                  value={openTimeIn12Hour ? openTimeIn12Hour.time : undefined}
                  onChange={handleTimeChange}
                  period={openTimeIn12Hour ? openTimeIn12Hour.period : 'AM'}
                  validation={dayValidation}
                />
              </StyledTableCell>
              <StyledTableCell>
                <TimePicker
                  isRequired
                  id={`${day}-Close`}
                  property={day}
                  name="closeTime"
                  disabled={!checked || isPending}
                  value={closeTimeIn12Hour ? closeTimeIn12Hour.time : undefined}
                  onChange={handleTimeChange}
                  period={closeTimeIn12Hour ? closeTimeIn12Hour.period : 'PM'}
                  validation={dayValidation}
                />
              </StyledTableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default DaysOfOperationTable
