export function scrollTo(elementId: string, scrollOffset: number) {
  const scrollElement = document.getElementById(elementId)
  if (scrollElement) {
    const y =
      scrollElement.getBoundingClientRect().top +
      window.pageYOffset +
      scrollOffset
    window.scrollTo({ top: y, behavior: 'smooth' })
  }
}
