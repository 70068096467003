import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import find from 'lodash/fp/find'

import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'

import { DialogEnum } from 'components/common/Dialog'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import Link from 'components/common/Link'

import { TargetPlusBarcodeDetails } from './index'

import { trackCustomEvent } from 'services/fireflyInsights'
import { RoutePath } from 'services/NavigationHelper'

import { openDialog } from 'store/dialog/actionCreator'

import { FireflyEvent } from 'types/FireflyInsights'

export interface Props {
  barcodes: TargetPlusBarcodeDetails[]
  handleRelease: () => void
  handleReset: () => void
}

const StyledButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme.spacing(2),
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

export const ReleaseBarcodes = ({
  barcodes,
  handleRelease,
  handleReset,
}: Props) => {
  const dispatch = useDispatch()

  const [releaseBarcodes, setReleaseBarcodes] = useState<
    TargetPlusBarcodeDetails[]
  >([])

  const onChangeCheckbox =
    (checkedBarcode: TargetPlusBarcodeDetails) =>
    (_event: React.FormEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        setReleaseBarcodes((existingReleaseBarcodes) => [
          ...existingReleaseBarcodes,
          checkedBarcode,
        ])
      } else {
        setReleaseBarcodes((existingReleaseBarcodes) =>
          existingReleaseBarcodes.filter(
            (releaseBarcode) =>
              releaseBarcode.barcode !== checkedBarcode.barcode,
          ),
        )
      }
    }

  const onChangeMainCheckbox =
    () => (_event: React.FormEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        setReleaseBarcodes(barcodes)
      } else {
        setReleaseBarcodes([])
      }
    }

  const fieldList: EnhancedTableFieldType<TargetPlusBarcodeDetails>[] = [
    {
      key: 'checkbox',
      heading: 'Checkbox',
      formatHeader: () => {
        return (
          <Checkbox
            data-testid={'all-checkbox'}
            checked={
              barcodes.length === releaseBarcodes.length && barcodes.length > 0
            }
            onChange={onChangeMainCheckbox()}
          />
        )
      },
      formatCell: (item) => {
        return (
          <Checkbox
            data-testid={`${item.barcode}-checkbox`}
            checked={!!find(item, releaseBarcodes)}
            onChange={onChangeCheckbox(item)}
          />
        )
      },
    },
    {
      key: 'barcode',
      heading: 'Barcode',
    },
    {
      key: 'orderCount',
      heading: 'Orders in the Last 120 Days',
    },
    {
      key: 'inventory',
      heading: 'Inventory',
    },
    {
      key: 'tcin',
      heading: 'TCIN',
      formatCell: (item) => {
        const tcin = item.barcodeInfo.owner?.tcins[0]
        const productId = item.productInfo?.productId
        const sellerId = item.productInfo?.sellerId
        const toPath = `/${sellerId}${RoutePath.ALL_ITEMS}/${productId}`
        return (
          <Link to={toPath} target={'_blank'}>
            {tcin}
          </Link>
        )
      },
    },
    {
      key: 'sellerName',
      heading: 'Owned By',
      formatCell: (item) => item.productInfo?.sellerName,
    },
  ]

  const openReleaseSuccess = () => {
    dispatch(
      openDialog({
        dialogEnum: DialogEnum.BARCODE_RELEASE_SUCCESS,
        componentProps: { count: releaseBarcodes.length },
      }),
    )
    handleRelease()
    trackCustomEvent(
      FireflyEvent.RELEASE_BARCODE_RELEASE,
      'release',
      releaseBarcodes.length,
    )
  }

  const openConfirmRelease = () => {
    dispatch(
      openDialog({
        dialogEnum: DialogEnum.CONFIRM_BARCODE_RELEASE,
        componentProps: {
          barcodes: releaseBarcodes,
          onSubmit: openReleaseSuccess,
        },
      }),
    )
  }

  return (
    <div data-testid="barcode-release">
      <StyledButtonContainer>
        <Button
          data-testid="reset-barcodes"
          onClick={handleReset}
          color="primary"
        >
          back to search
        </Button>
        <Button
          color="primary"
          variant="contained"
          data-testid="release-barcodes"
          onClick={openConfirmRelease}
          disabled={releaseBarcodes.length < 1}
        >
          release barcodes
        </Button>
      </StyledButtonContainer>
      <StyledDivider />
      <EnhancedTable isLoading={false} data={barcodes} fieldList={fieldList} />
    </div>
  )
}

export default ReleaseBarcodes
