import { sellerSagas } from './seller/sagas'
import { watchUserSagas } from './user/sagas'
import { paymentAccountSagas } from './paymentAccount/sagas'
import { enumerationSagas } from './enumeration/sagas'
import { dialogSagas } from './dialog/sagas'
import { itemReviewSagas } from './itemReview/sagas'
import { partnerDiversitySagas } from './partnerDiversity/sagas'

const sagas = {
  sellerSagas,
  watchUserSagas,
  paymentAccountSagas,
  enumerationSagas,
  dialogSagas,
  itemReviewSagas,
  partnerDiversitySagas,
}

export const initSagas = (sagaMiddleware: { run: any }) => {
  Object.values(sagas).forEach(sagaMiddleware.run.bind(sagaMiddleware))
}
