import { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import Select from 'components/common/Select'

import { useSelector, useDispatch } from 'react-redux'
import { editInternalTaxSetup } from 'store/seller/actionCreators'

import { currentSeller, isSellersPending } from 'store/selectors'

interface Props {
  isOpen: boolean
}

export const EditInternalTaxSetup = ({ isOpen }: Props) => {
  const seller = useSelector(currentSeller)
  const setupComplete = seller?.internal_tax_setting.setup_complete
  const isPending = useSelector(isSellersPending)

  const dispatch = useDispatch()

  const [completed, setCompleted] = useState(setupComplete)
  const [submitDisabled, setSubmitDisabled] = useState(true)

  useEffect(() => {
    setSubmitDisabled(setupComplete === completed)
  }, [completed, setupComplete])

  const handleSubmit = () => {
    dispatch(editInternalTaxSetup(completed!))
  }

  const handleInputChange = (value: boolean) => {
    setCompleted(value)
  }

  return (
    <DialogContainer
      data-testid="edit-source-dialog"
      isOpen={isOpen}
      title="Edit Internal Tax Setup"
      onSubmit={handleSubmit}
      isSubmitDisabled={submitDisabled}
      isPending={isPending}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Select
            isRequired
            id="internalTaxSetup"
            name="internalTaxSetup"
            data-testid="tax-setup-complete-input"
            isDisabled={isPending}
            label="Tax Setup Status"
            options={[
              { label: 'Not Completed', value: false },
              { label: 'Completed', value: true },
            ]}
            value={completed}
            keyName="label"
            valueName="value"
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export default EditInternalTaxSetup
