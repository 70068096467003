import axios from 'axios'

import apiConfig from 'config/apiConfig'

import { SmsSeller } from 'types/Seller'
import {
  DiversityQuestion,
  DiversityAnswer,
  DiversityRadio,
  DiversitySurveyPages,
} from 'types/PartnerDiversity'

export const isPrivateCompany = (seller: SmsSeller) => {
  return seller.is_private === true || seller.is_private === undefined
}

export const getPartnerDiversityAnswers = async (
  sellerId: string,
): Promise<DiversityAnswer[]> => {
  const response = await axios.get(
    `${apiConfig.sms}/sellers/${sellerId}/diversity_answers`,
  )

  return response.data
}

export const buildAllAnswers = (answers: Partial<DiversitySurveyPages>) => {
  const list: DiversityAnswer[] = []

  if (answers.WOMAN === DiversityRadio.YES) {
    const answer = buildAnswer({
      question: DiversityQuestion.WOMAN,
      selection: true,
      hasCertification:
        answers.WOMAN_CERT && answers.WOMAN_CERT_DATE ? true : false,
      certNumber: answers.WOMAN_CERT,
      expirationDate: answers.WOMAN_CERT_DATE,
    })

    list.push(answer)
  } else {
    // When no selection set the answer to false so that all diversity questions are set
    const answer = buildAnswer({
      question: DiversityQuestion.WOMAN,
      selection: false,
      hasCertification: false,
    })

    list.push(answer)
  }

  if (answers.WOMAN_OTHER) {
    let answer: DiversityAnswer

    if (answers.WOMAN === DiversityRadio.YES) {
      answer = buildAnswer({
        question: DiversityQuestion.WOMAN_OTHER,
        selection: true,
        hasCertification:
          answers.WOMAN_OTHER_CERT && answers.WOMAN_OTHER_CERT_DATE
            ? true
            : false,
        certNumber: answers.WOMAN_OTHER_CERT,
        expirationDate: answers.WOMAN_OTHER_CERT_DATE,
        agency: answers.WOMAN_OTHER,
      })
    } else {
      answer = buildAnswer({
        question: DiversityQuestion.WOMAN_OTHER,
        selection: false,
        hasCertification: false,
      })
    }

    list.push(answer)
  } else {
    const answer = buildAnswer({
      question: DiversityQuestion.WOMAN_OTHER,
      selection: false,
      hasCertification: false,
    })

    list.push(answer)
  }

  if (answers.BIPOC === DiversityRadio.YES) {
    const answer = buildAnswer({
      question: DiversityQuestion.BIPOC,
      selection: true,
      hasCertification:
        answers.BIPOC_CERT && answers.BIPOC_CERT_DATE ? true : false,
      certNumber: answers.BIPOC_CERT,
      expirationDate: answers.BIPOC_CERT_DATE,
      ethnicity: answers.BIPOC_ETHNICITY,
    })

    list.push(answer)
  } else {
    const answer = buildAnswer({
      question: DiversityQuestion.BIPOC,
      selection: false,
      hasCertification: false,
    })

    list.push(answer)
  }

  if (answers.LGBTQ === DiversityRadio.YES) {
    const answer = buildAnswer({
      question: DiversityQuestion.LGBTQ,
      selection: true,
      hasCertification:
        answers.LGBTQ_CERT && answers.LGBTQ_CERT_DATE ? true : false,
      certNumber: answers.LGBTQ_CERT,
      expirationDate: answers.LGBTQ_CERT_DATE,
    })

    list.push(answer)
  } else {
    const answer = buildAnswer({
      question: DiversityQuestion.LGBTQ,
      selection: false,
      hasCertification: false,
    })

    list.push(answer)
  }

  if (answers.VETERAN === DiversityRadio.YES) {
    const answer = buildAnswer({
      question: DiversityQuestion.VETERAN,
      selection: true,
      hasCertification:
        answers.VETERAN_CERT === DiversityRadio.YES ? true : false,
    })

    list.push(answer)
  } else {
    const answer = buildAnswer({
      question: DiversityQuestion.VETERAN,
      selection: false,
      hasCertification: false,
    })

    list.push(answer)
  }

  if (answers.SERVICE_DISABLED_VET === DiversityRadio.YES) {
    let answer: DiversityAnswer

    if (answers.VETERAN === DiversityRadio.YES) {
      answer = buildAnswer({
        question: DiversityQuestion.SERVICE_DISABLED_VET,
        selection: true,
        hasCertification:
          answers.SERVICE_DISABLED_VET_CERT === DiversityRadio.YES
            ? true
            : false,
      })
    } else {
      answer = buildAnswer({
        question: DiversityQuestion.SERVICE_DISABLED_VET,
        selection: false,
        hasCertification: false,
      })
    }

    list.push(answer)
  } else {
    const answer = buildAnswer({
      question: DiversityQuestion.SERVICE_DISABLED_VET,
      selection: false,
      hasCertification: false,
    })

    list.push(answer)
  }

  if (answers.SMALL_BUSINESS === DiversityRadio.YES) {
    const answer = buildAnswer({
      question: DiversityQuestion.SMALL_BUSINESS,
      selection: true,
      hasCertification:
        answers.SMALL_BUSINESS === DiversityRadio.YES ? true : false,
    })

    list.push(answer)
  } else {
    const answer = buildAnswer({
      question: DiversityQuestion.SMALL_BUSINESS,
      selection: false,
      hasCertification: false,
    })

    list.push(answer)
  }

  if (answers.SMALL_DISADVANTAGED_BUSINESS === DiversityRadio.YES) {
    const answer = buildAnswer({
      question: DiversityQuestion.SMALL_DISADVANTAGED_BUSINESS,
      selection: true,
      hasCertification:
        answers.SMALL_DISADVANTAGED_BUSINESS_CERT &&
        answers.SMALL_DISADVANTAGED_BUSINESS_CERT_DATE
          ? true
          : false,
      certNumber: answers.SMALL_DISADVANTAGED_BUSINESS_CERT,
      expirationDate: answers.SMALL_DISADVANTAGED_BUSINESS_CERT_DATE,
    })

    list.push(answer)
  } else {
    const answer = buildAnswer({
      question: DiversityQuestion.SMALL_DISADVANTAGED_BUSINESS,
      selection: false,
      hasCertification: false,
    })

    list.push(answer)
  }

  if (answers.DISADVANTAGED_BUSINESS_ENTERPRISE === DiversityRadio.YES) {
    const answer = buildAnswer({
      question: DiversityQuestion.DISADVANTAGED_BUSINESS_ENTERPRISE,
      selection: true,
      hasCertification:
        answers.DISADVANTAGED_BUSINESS_ENTERPRISE_CERT &&
        answers.DISADVANTAGED_BUSINESS_ENTERPRISE_CERT_DATE
          ? true
          : false,
      certNumber: answers.DISADVANTAGED_BUSINESS_ENTERPRISE_CERT,
      expirationDate: answers.DISADVANTAGED_BUSINESS_ENTERPRISE_CERT_DATE,
    })

    list.push(answer)
  } else {
    const answer = buildAnswer({
      question: DiversityQuestion.DISADVANTAGED_BUSINESS_ENTERPRISE,
      selection: false,
      hasCertification: false,
    })

    list.push(answer)
  }

  if (answers.DIFFERENTLY_ABLED === DiversityRadio.YES) {
    const answer = buildAnswer({
      question: DiversityQuestion.DIFFERENTLY_ABLED,
      selection: true,
      hasCertification:
        answers.DIFFERENTLY_ABLED_CERT && answers.DIFFERENTLY_ABLED_CERT_DATE
          ? true
          : false,
      certNumber: answers.DIFFERENTLY_ABLED_CERT,
      expirationDate: answers.DIFFERENTLY_ABLED_CERT_DATE,
    })

    list.push(answer)
  } else {
    const answer = buildAnswer({
      question: DiversityQuestion.DIFFERENTLY_ABLED,
      selection: false,
      hasCertification: false,
    })

    list.push(answer)
  }

  if (answers.HUB_ZONE === DiversityRadio.YES) {
    const answer = buildAnswer({
      question: DiversityQuestion.HUB_ZONE,
      selection: true,
      hasCertification:
        answers.HUB_ZONE_CERT && answers.HUB_ZONE_CERT_DATE ? true : false,
      certNumber: answers.HUB_ZONE_CERT,
      expirationDate: answers.HUB_ZONE_CERT_DATE,
    })

    list.push(answer)
  } else {
    const answer = buildAnswer({
      question: DiversityQuestion.HUB_ZONE,
      selection: false,
      hasCertification: false,
    })

    list.push(answer)
  }

  if (answers.ALASKAN_NATIVE === DiversityRadio.YES) {
    const answer = buildAnswer({
      question: DiversityQuestion.ALASKAN_NATIVE,
      selection: true,
      hasCertification:
        answers.ALASKAN_NATIVE_CERT && answers.ALASKAN_NATIVE_CERT_DATE
          ? true
          : false,
      certNumber: answers.ALASKAN_NATIVE_CERT,
      expirationDate: answers.ALASKAN_NATIVE_CERT_DATE,
    })

    list.push(answer)
  } else {
    const answer = buildAnswer({
      question: DiversityQuestion.ALASKAN_NATIVE,
      selection: false,
      hasCertification: false,
    })

    list.push(answer)
  }

  return list
}

export const buildAnswer = ({
  question,
  selection,
  hasCertification,
  ethnicity,
  certNumber,
  expirationDate,
  agency,
}: {
  question: DiversityQuestion
  selection: boolean
  hasCertification: boolean
  ethnicity?: string
  certNumber?: string
  expirationDate?: string
  agency?: string
}) => {
  const answer: DiversityAnswer = {
    diversity_question: question,
    answer: selection,
    has_certification: hasCertification,
  }

  if (ethnicity) {
    answer.ethnicity = ethnicity
  }

  if (certNumber && expirationDate) {
    answer.certification = {
      cert_number: certNumber,
      expiration_date: expirationDate,
    }

    if (agency) {
      answer.certification.agency = agency
    }
  }

  return answer
}

export const updateAllPartnerDiversityAnswers = async (
  sellerId: string,
  answers: Partial<DiversitySurveyPages>,
) => {
  const surveyAnswers = buildAllAnswers(answers)

  const res = await Promise.all(
    surveyAnswers.map((item) => updatePartnerDiversityAnswer(sellerId, item)),
  )

  return res
}

export const updatePartnerDiversityAnswer = async (
  sellerId: string,
  answer: DiversityAnswer,
) => {
  const response = await axios.post(
    `${apiConfig.sms}/sellers/${sellerId}/diversity_answers`,
    answer,
  )

  return response.data
}

export const formatAnswersForSurvey = (
  answers: DiversityAnswer[],
): DiversitySurveyPages => {
  const results: DiversitySurveyPages = {
    WOMAN: '',
    WOMAN_CERT: '',
    WOMAN_CERT_DATE: '',
    WOMAN_OTHER: '',
    WOMAN_OTHER_CERT: '',
    WOMAN_OTHER_CERT_DATE: '',
    BIPOC: '',
    BIPOC_ETHNICITY: '',
    BIPOC_CERT: '',
    BIPOC_CERT_DATE: '',
    LGBTQ: '',
    LGBTQ_CERT: '',
    LGBTQ_CERT_DATE: '',
    VETERAN: '',
    VETERAN_CERT: '',
    SERVICE_DISABLED_VET: '',
    SERVICE_DISABLED_VET_CERT: '',
    SMALL_BUSINESS: '',
    SMALL_DISADVANTAGED_BUSINESS: '',
    SMALL_DISADVANTAGED_BUSINESS_CERT: '',
    SMALL_DISADVANTAGED_BUSINESS_CERT_DATE: '',
    DISADVANTAGED_BUSINESS_ENTERPRISE: '',
    DISADVANTAGED_BUSINESS_ENTERPRISE_CERT: '',
    DISADVANTAGED_BUSINESS_ENTERPRISE_CERT_DATE: '',
    DIFFERENTLY_ABLED: '',
    DIFFERENTLY_ABLED_CERT: '',
    DIFFERENTLY_ABLED_CERT_DATE: '',
    HUB_ZONE: '',
    HUB_ZONE_CERT: '',
    HUB_ZONE_CERT_DATE: '',
    ALASKAN_NATIVE: '',
    ALASKAN_NATIVE_CERT: '',
    ALASKAN_NATIVE_CERT_DATE: '',
  }

  answers.forEach((item) => {
    if (item.diversity_question === DiversityQuestion.WOMAN) {
      results.WOMAN =
        item.answer === true ? DiversityRadio.YES : DiversityRadio.NO
      results.WOMAN_CERT = item.certification?.cert_number ?? ''
      results.WOMAN_CERT_DATE = item.certification?.expiration_date ?? ''
    }

    if (item.diversity_question === DiversityQuestion.WOMAN_OTHER) {
      results.WOMAN_OTHER = item.certification?.agency ?? ''
      results.WOMAN_OTHER_CERT = item.certification?.cert_number ?? ''
      results.WOMAN_OTHER_CERT_DATE = item.certification?.expiration_date ?? ''
    }

    if (item.diversity_question === DiversityQuestion.BIPOC) {
      results.BIPOC =
        item.answer === true ? DiversityRadio.YES : DiversityRadio.NO
      results.BIPOC_ETHNICITY = item.ethnicity ?? ''
      results.BIPOC_CERT = item.certification?.cert_number ?? ''
      results.BIPOC_CERT_DATE = item.certification?.expiration_date ?? ''
    }

    if (item.diversity_question === DiversityQuestion.LGBTQ) {
      results.LGBTQ =
        item.answer === true ? DiversityRadio.YES : DiversityRadio.NO
      results.LGBTQ_CERT = item.certification?.cert_number ?? ''
      results.LGBTQ_CERT_DATE = item.certification?.expiration_date ?? ''
    }

    if (item.diversity_question === DiversityQuestion.VETERAN) {
      results.VETERAN =
        item.answer === true ? DiversityRadio.YES : DiversityRadio.NO

      if (typeof item.has_certification !== 'undefined') {
        results.VETERAN_CERT =
          item.has_certification === true
            ? DiversityRadio.YES
            : DiversityRadio.NO
      }
    }

    if (item.diversity_question === DiversityQuestion.SERVICE_DISABLED_VET) {
      results.SERVICE_DISABLED_VET =
        item.answer === true ? DiversityRadio.YES : DiversityRadio.NO

      if (typeof item.has_certification !== 'undefined') {
        results.SERVICE_DISABLED_VET_CERT =
          item.has_certification === true
            ? DiversityRadio.YES
            : DiversityRadio.NO
      }
    }

    if (item.diversity_question === DiversityQuestion.SMALL_BUSINESS) {
      results.SMALL_BUSINESS =
        item.answer === true ? DiversityRadio.YES : DiversityRadio.NO
    }

    if (
      item.diversity_question === DiversityQuestion.SMALL_DISADVANTAGED_BUSINESS
    ) {
      results.SMALL_DISADVANTAGED_BUSINESS =
        item.answer === true ? DiversityRadio.YES : DiversityRadio.NO

      results.SMALL_DISADVANTAGED_BUSINESS_CERT =
        item.certification?.cert_number ?? ''
      results.SMALL_DISADVANTAGED_BUSINESS_CERT_DATE =
        item.certification?.expiration_date ?? ''
    }

    if (
      item.diversity_question ===
      DiversityQuestion.DISADVANTAGED_BUSINESS_ENTERPRISE
    ) {
      results.DISADVANTAGED_BUSINESS_ENTERPRISE =
        item.answer === true ? DiversityRadio.YES : DiversityRadio.NO

      results.DISADVANTAGED_BUSINESS_ENTERPRISE_CERT =
        item.certification?.cert_number ?? ''
      results.DISADVANTAGED_BUSINESS_ENTERPRISE_CERT_DATE =
        item.certification?.expiration_date ?? ''
    }

    if (item.diversity_question === DiversityQuestion.DIFFERENTLY_ABLED) {
      results.DIFFERENTLY_ABLED =
        item.answer === true ? DiversityRadio.YES : DiversityRadio.NO

      results.DIFFERENTLY_ABLED_CERT = item.certification?.cert_number ?? ''
      results.DIFFERENTLY_ABLED_CERT_DATE =
        item.certification?.expiration_date ?? ''
    }

    if (item.diversity_question === DiversityQuestion.HUB_ZONE) {
      results.HUB_ZONE =
        item.answer === true ? DiversityRadio.YES : DiversityRadio.NO

      results.HUB_ZONE_CERT = item.certification?.cert_number ?? ''
      results.HUB_ZONE_CERT_DATE = item.certification?.expiration_date ?? ''
    }

    if (item.diversity_question === DiversityQuestion.ALASKAN_NATIVE) {
      results.ALASKAN_NATIVE =
        item.answer === true ? DiversityRadio.YES : DiversityRadio.NO

      results.ALASKAN_NATIVE_CERT = item.certification?.cert_number ?? ''
      results.ALASKAN_NATIVE_CERT_DATE =
        item.certification?.expiration_date ?? ''
    }
  })

  return results
}
