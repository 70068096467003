import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryClient } from '@tanstack/react-query'
import startCase from 'lodash/fp/startCase'
import camelCase from 'lodash/fp/camelCase'

import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

import MoodIcon from '@mui/icons-material/Mood'

import TrackingNumberLink from 'components/common/TrackingNumberLink'
import FullScreenDialogContainer from 'components/common/Dialog/FullScreenDialogContainer'
import DialogContainer from 'components/common/Dialog/DialogContainer'

import FullScreenLoader from 'components/common/loader/FullScreenLoader'
import { TableState } from 'components/common/EnhancedTable/useTable'
import DisplayCard from 'components/common/DisplayCard'
import DataList from 'components/common/DataList'
import CommunicationHistory from 'components/OpenCases/OpenCaseDetail/CommunicationHistory'
import {
  tcinLink,
  returnOrderNumberLink,
} from 'components/common/EnhancedTable/formatters'

import {
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
  formatDate,
} from 'services/dateService'
import formatCurrency from 'services/formatCurrency'
import { trackTaskTime } from 'services/fireflyInsights'

import { closeDialog } from 'store/dialog/actionCreator'
import { getReturnReasons, getDisputeSubResponses } from 'store/selectors'

import { useSellerById } from 'hooks/useSellerQuery'

import { backgroundGrey, grey, error } from 'config/themeConfig'

import EmptyDisputeQueueContent from './EmptyDisputeQueueContent'
import DisputeSideBar from './DisputeSideBar'
import {
  useReturnDisputeReviewQuery,
  useUpdateReturnDisputeCaseQuery,
} from '../useReturnDisputesQuery'

import { INTERNAL_RETURN_DISPUTE_CASES_QUERY_KEY } from '../queries'
import PartnerSubmittedDetails from './PartnerSubmittedDetails'
import SystemMessage from './SystemMesage'
import DisputeItemDetails from './DisputeItemDetails'
import {
  displaySystemMessage,
  getDisputeByPhysicalDisposition,
} from './returnDisputeHelper'

import {
  JudgementValues,
  ReturnDispute,
  ReturnDisputeCasesSearchParams,
  ReturnDisputeResolution,
} from 'types/disputes'
import { Return, ReturnTrackingData } from 'types/Orders'
import StoreState from 'types/state'
import { statusText } from '../ReturnDisputeStatusChip'
import { FireflyEvent } from 'types/FireflyInsights'

const StyledDialogContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const StyledGrid = styled(Grid)(({ theme }) => ({
  marginLeft: 0,
  paddingBottom: theme.spacing(2),
  width: '100%',
  backgroundColor: backgroundGrey.main,
}))

const StyledStickyContainer = styled('div')(() => ({
  position: 'sticky',
  zIndex: 1000,
  top: 89,
  right: 0,
  left: 0,
}))

const StyledHorizontalDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  color: grey[300],
}))

const StyledVerticalDivider = styled(Divider)(() => ({
  color: grey[300],
}))

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'error',
})<{ error?: boolean }>(
  {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    marginBottom: '4px',
  },
  (props) => ({
    color: props.error === true ? error.main : undefined,
  }),
)

export interface Props {
  isOpen: boolean
  selectedDispute?: ReturnDispute
  searchParams: ReturnDisputeCasesSearchParams
  pagingParams: TableState
}

const NUM_DISPUTES_PER_REQUEST = 1

export const InternalReturnDisputeDialog = ({
  isOpen,
  selectedDispute,
  searchParams,
  pagingParams,
}: Props) => {
  const returnReasons = useSelector((state: StoreState) =>
    getReturnReasons(state),
  )

  const queryClient = useQueryClient()
  const reduxDispatch = useDispatch()

  const disputeSubReasonsEnum = useSelector(getDisputeSubResponses)

  const [page, setPage] = useState(pagingParams.page)
  const [close, setClose] = useState(false)
  const [currentDispute, setCurrentDispute] = useState<ReturnDispute>()
  const [startTime, setStartTime] = useState<number>()
  const [hasTrackedJudgement, setHasTrackedJudgment] = useState({
    tracked: false,
    caseNumber: '',
  })

  const { data: sellerData } = useSellerById(searchParams.seller_id)

  const { data, isLoading, status } = useReturnDisputeReviewQuery(
    searchParams,
    {
      page,
      perPage: NUM_DISPUTES_PER_REQUEST,
      orderBy: pagingParams.orderBy,
      direction: pagingParams.direction,
    },
    selectedDispute,
  )

  const { dispute, seller, item, productReturn, selectedCase } = data ?? {}
  const displayName = data?.seller?.display_name

  useEffect(() => {
    setCurrentDispute(dispute)
  }, [dispute])

  useEffect(() => {
    if (currentDispute?.case_number && isOpen) {
      setStartTime(new Date().getTime())
    }
  }, [currentDispute?.case_number, isOpen])

  const { updateDispute, isLoading: isUpdateLoading } =
    useUpdateReturnDisputeCaseQuery()

  const title = dispute
    ? `${startCase(
        camelCase(dispute?.judgement),
      )} - Dispute ${dispute?.case_number}`
    : ''

  const isReviewQueueEmpty = !dispute && status === 'success'

  const needsReview = dispute?.judgement === JudgementValues.NEEDS_REVIEW

  const disputeSubReasonText =
    disputeSubReasonsEnum.find((d) => d.value === dispute?.dispute_sub_reason)
      ?.properties.description ?? ''

  const handleCloseDialog = () => {
    reduxDispatch(closeDialog())
    if (hasTrackedJudgement.caseNumber !== currentDispute?.case_number) {
      trackTaskTime(
        startTime as number,
        FireflyEvent.RETURN_DISPUTE_JUDGEMENT,
        currentDispute?.case_number as string,
        'Closed Dialog',
        '',
        [
          startCase(camelCase(currentDispute?.dispute_reason as string)),
          displayName as string,
        ],
      )
      setHasTrackedJudgment({
        tracked: true,
        caseNumber: currentDispute?.case_number as string,
      })
    }

    // Invalidate query so that the table on the page refetches its data
    queryClient.invalidateQueries([INTERNAL_RETURN_DISPUTE_CASES_QUERY_KEY])
  }

  const handleSkipToNextClick = () => {
    if (hasTrackedJudgement.caseNumber !== currentDispute?.case_number) {
      trackTaskTime(
        startTime as number,
        FireflyEvent.RETURN_DISPUTE_JUDGEMENT,
        currentDispute?.case_number as string,
        'Skipped To Next',
        '',
        [
          startCase(camelCase(currentDispute?.dispute_reason as string)),
          displayName as string,
        ],
      )
      setHasTrackedJudgment({
        tracked: true,
        caseNumber: currentDispute?.case_number as string,
      })
    }
    setPage((prev) => prev + 1)

    setHasTrackedJudgment({
      tracked: false,
      caseNumber: currentDispute?.case_number as string,
    })
  }

  const handleDisputeSubmit = async (
    disputeResolution: ReturnDisputeResolution,
    disputeCaseNumber: string,
  ) => {
    if (
      !hasTrackedJudgement.tracked &&
      hasTrackedJudgement.caseNumber !== disputeCaseNumber
    ) {
      trackTaskTime(
        startTime as number,
        FireflyEvent.RETURN_DISPUTE_JUDGEMENT,
        disputeCaseNumber,
        statusText(disputeResolution.judgement),
        '',
        [
          startCase(camelCase(currentDispute?.dispute_reason as string)),
          displayName as string,
        ],
      )
      setHasTrackedJudgment({
        tracked: true,
        caseNumber: disputeCaseNumber,
      })
    }
    if (dispute) {
      const disputeUpdate: ReturnDispute = {
        ...dispute,
        ...disputeResolution,
      }

      await updateDispute({
        dispute: disputeUpdate,
        invalidate: !selectedDispute,
      })

      setCurrentDispute(undefined)

      if (selectedDispute) {
        setClose(true)
      }
    }
  }

  const getTrackingNumberContent = (
    dataType: string,
    trackingData?: ReturnTrackingData[],
  ) => {
    if (trackingData) {
      const trackingNumber = trackingData.find(
        (tracking) => tracking.tracking_number,
      )?.tracking_number

      const trackingSCAC = trackingData.find((tracking) => tracking.scac)?.scac

      const trackingBOL = trackingData.find(
        (tracking) => tracking.bill_of_lading,
      )?.bill_of_lading

      const licensePlate = trackingData.find(
        (tracking) => tracking.license_plate,
      )?.license_plate

      if (trackingNumber && dataType === 'tracking') {
        return (
          <TrackingNumberLink
            trackingNumber={trackingNumber}
            scac={trackingSCAC}
          />
        )
      }
      if (trackingSCAC && dataType === 'scac') {
        return trackingSCAC
      }
      if (trackingBOL && dataType === 'bol') {
        return trackingBOL
      }

      if (licensePlate && dataType === 'license_plate') {
        return licensePlate
      }
    }
  }

  const getReturnData = (dataType: string, productReturn?: Return) => {
    if (productReturn) {
      if (dataType === 'channel') {
        return productReturn.is_online ? 'Mail in' : 'In Store'
      }
      if (dataType === 'reason') {
        return returnReasons.find(
          (reason) => reason.value === productReturn.return_reason,
        )?.properties.description
      }
    }
  }

  return (
    <>
      {isReviewQueueEmpty || close ? (
        <DialogContainer
          isOpen={isOpen}
          maxWidth="md"
          closeButtonText="Close"
          closeOverride={handleCloseDialog}
          title={
            <StyledDialogContainer>
              <MoodIcon sx={{ mr: 1 }} />
              No More Disputes To Review For This Criteria
            </StyledDialogContainer>
          }
        >
          <EmptyDisputeQueueContent
            searchParams={searchParams}
            sellerDisplayName={sellerData?.display_name}
          />
        </DialogContainer>
      ) : (
        <FullScreenDialogContainer
          title={title}
          isOpen={isOpen}
          onRequestClose={handleCloseDialog}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={needsReview ? 8 : 12}>
              {dispute && productReturn && (
                <>
                  <DisplayCard
                    title={`Dispute ${dispute.case_number} - ${seller?.display_name}`}
                    description={`Created ${formatDate(
                      dispute.created,
                      DATE_FORMAT_MONTH_DAY_YEAR_TIME,
                    )} by ${dispute.created_by}`}
                  >
                    <StyledGrid container spacing={2}>
                      <Grid item xs={5}>
                        <DataList
                          data={[
                            {
                              title: 'Return Order Number:',
                              element: returnOrderNumberLink(false)({
                                seller_id: dispute.seller_id,
                                order_id: dispute.order_id,
                                return_order_number:
                                  dispute.return_order_number,
                              }),
                            },
                            {
                              title: 'TCIN:',
                              element: item ? (
                                tcinLink(false)({
                                  product_id: item?.product_id,
                                  seller_id: dispute.seller_id,
                                  tcin: dispute.tcin,
                                })
                              ) : (
                                <span>N/A</span>
                              ),
                            },
                            {
                              title: 'Tracking:',
                              element: getTrackingNumberContent(
                                'tracking',
                                productReturn.tracking_data,
                              ) ?? <span>N/A</span>,
                            },
                            {
                              title: 'SCAC (Carrier):',
                              element: getTrackingNumberContent(
                                'scac',
                                productReturn.tracking_data,
                              ) ?? <span>N/A</span>,
                            },
                            {
                              title: 'Bill of Lading (BOL):',
                              element: getTrackingNumberContent(
                                'bol',
                                productReturn.tracking_data,
                              ) ?? <span>N/A</span>,
                            },
                            {
                              title: 'License Plate:',
                              element: getTrackingNumberContent(
                                'license_plate',
                                productReturn.tracking_data,
                              ) ?? <span>N/A</span>,
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item sx={{ paddingBottom: 2 }}>
                        <StyledVerticalDivider orientation="vertical" />
                      </Grid>
                      <Grid item xs={5}>
                        <DataList
                          data={[
                            {
                              title: 'Return Policy:',
                              element: `${productReturn.financial_disposition}, ${productReturn.physical_disposition}`,
                            },
                            {
                              title: 'Return Channel:',
                              element: getReturnData('channel', productReturn),
                            },
                            {
                              title: 'Guest Return Reason:',
                              element: getReturnData('reason', productReturn),
                            },
                            {
                              title: 'Registry Item:',
                              element: productReturn.registry_item
                                ? 'Yes'
                                : 'No',
                            },
                            {
                              title: 'Item Chargeback:',
                              value: `${formatCurrency(
                                dispute.chargeback_amount,
                              )}`,
                            },
                            {
                              title: 'Return Service Fee:',
                              value: `${formatCurrency(dispute.service_fee)}`,
                            },
                          ]}
                        />
                      </Grid>
                    </StyledGrid>
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                      <Grid item xs={12}>
                        <StyledTypography
                          error
                          data-testid="return-dispute-reason-typography"
                        >
                          {startCase(camelCase(dispute.dispute_reason))}
                          {dispute.dispute_reason && disputeSubReasonText
                            ? ' - '
                            : ''}
                          {disputeSubReasonText}
                        </StyledTypography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <StyledTypography error>
                          Dispute Qty: {dispute.quantity}
                        </StyledTypography>
                      </Grid>
                    </Grid>
                    {displaySystemMessage(dispute.judgement) && (
                      <SystemMessage
                        returnDispute={dispute}
                        tracking={getDisputeByPhysicalDisposition(
                          productReturn,
                          'DEST',
                        )}
                      />
                    )}
                    <PartnerSubmittedDetails
                      sellerId={dispute.seller_id}
                      selectedCase={selectedCase}
                    />
                    <StyledHorizontalDivider orientation="horizontal" />
                    {item && <DisputeItemDetails item={item} />}
                    <Typography sx={{ fontWeight: 700 }}>
                      Communication History:
                    </Typography>
                    {seller && (
                      <CommunicationHistory
                        sellerId={seller.id}
                        selectedCase={selectedCase}
                      />
                    )}
                  </DisplayCard>
                </>
              )}
            </Grid>
            {needsReview && (
              <Grid item xs={12} md={4} data-testid="actions">
                {/* <Sticky enabled isDialog top={65}> */}
                <StyledStickyContainer>
                  {!selectedDispute && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          variant="outlined"
                          onClick={handleSkipToNextClick}
                        >
                          Skip to next
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    container
                    spacing={2}
                    sx={{ mt: !selectedDispute ? 2 : undefined }}
                  >
                    <Grid item xs={12}>
                      {dispute && (
                        <DisputeSideBar
                          dispute={dispute}
                          onHandleSubmit={handleDisputeSubmit}
                        />
                      )}
                    </Grid>
                  </Grid>
                </StyledStickyContainer>
              </Grid>
            )}
          </Grid>
          <FullScreenLoader
            isOpen={isLoading || isUpdateLoading || !currentDispute}
          />
        </FullScreenDialogContainer>
      )}
    </>
  )
}

export default InternalReturnDisputeDialog
