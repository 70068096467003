import Attribute from 'types/Attribute'

// consts
export const UPDATE_ITEM_TYPES_CACHE: string = 'UPDATE_ITEM_TYPES_CACHE'

// actions
export interface UpdateItemTypesCacheAction {
  readonly type: typeof UPDATE_ITEM_TYPES_CACHE
  payload: Attribute[]
}

// functions
export const updateItemTypesCache = (
  itemTypes: Attribute[],
): UpdateItemTypesCacheAction => {
  return {
    type: UPDATE_ITEM_TYPES_CACHE,
    payload: itemTypes,
  }
}

export type CacheActions = UpdateItemTypesCacheAction
