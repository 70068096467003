import { Grid } from '@enterprise-ui/canvas-ui-react'
import { StyledPromotionModalContainer } from '../styles'

import { UpdatedPromotion } from 'types/Promotion'

import IvyPromotionDetails from './IvyPromotionDetails'
import PartnerPromotionDetailsForm from './PartnerPromotionDetailsForm'

interface EditPromotionProps {
  promotion: UpdatedPromotion
}

export const EditPromotion = ({ promotion }: EditPromotionProps) => {
  return (
    <StyledPromotionModalContainer>
      <Grid.Item xs={8}>
        <PartnerPromotionDetailsForm promotion={promotion} />
      </Grid.Item>
      <Grid.Item xs={4}>
        <IvyPromotionDetails promotion={promotion} />
      </Grid.Item>
    </StyledPromotionModalContainer>
  )
}

export default EditPromotion
