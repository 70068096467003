import { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'

import styled from '@emotion/styled'
import Button from '@mui/material/Button'

import { options, useTheme } from '@amcharts/amcharts4/core'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

import { createTreeMap, disposeById } from 'services/amCharts'
import { saveFile } from 'services/files'
import { getProductCountForDownload } from 'services/productCounts'

import { transformDataForDownload } from '../helpers'

options.commercialLicense = true

const graphDiv = 'tree-map-graph'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

const StyledTreeMap = styled('div')(({ theme }) => ({
  height: 450,
  marginBottom: theme.spacing(2),
}))

const StyledItemTreeMapContainer = styled(Grid)({
  width: 0.6 * getWindowDimensions().width,
})

interface Props {
  chartData: any
}

export const ItemTreeMap = ({ chartData }: Props) => {
  const [csvData, setCsvData] = useState<string>('')

  useTheme(am4themes_animated)

  useEffect(() => {
    const chart = createTreeMap(graphDiv)
    chart.data = chartData || []
  }, [chartData])

  useEffect(() => {
    let mounted = true

    getProductCountForDownload().then((data) => {
      if (mounted) {
        if (data.error) return

        const downloadData = transformDataForDownload(data) || ''
        setCsvData(downloadData)
      }
    })

    return () => {
      disposeById(graphDiv)
      mounted = false
    }
  }, [])

  const handleDownload = () => {
    saveFile({ data: csvData, name: 'item-review-queue.csv' })
  }

  return (
    <Grid>
      <StyledItemTreeMapContainer>
        <Button
          color="primary"
          onClick={handleDownload}
          data-testid="download-button"
        >
          Download
        </Button>
        <StyledTreeMap id={graphDiv} data-testid="amcharts-div" />
      </StyledItemTreeMapContainer>
    </Grid>
  )
}

export default ItemTreeMap
