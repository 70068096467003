import { AboutLinks } from 'types/PartnerServices'
import { StyledDescriptionButton } from './styles'
import { trackCustomEvent } from 'services/fireflyInsights'
import { FireflyEvent } from 'types/FireflyInsights'

interface Props {
  aboutLinks: AboutLinks[]
}

export const AboutLink = ({ aboutLinks }: Props) => {
  const handleOnClick = (link: string, name: string) => {
    trackCustomEvent(FireflyEvent.PARTNER_SERVICE_LINK, 'selected_link', name)
    window.open(link, '_blank')
  }

  return (
    <>
      {aboutLinks.map((aboutLink, index) => (
        <StyledDescriptionButton
          rel="noopener noreferrer"
          variant="contained"
          color={index === 0 || aboutLink.direct ? 'primary' : 'secondary'}
          onClick={() => handleOnClick(aboutLink.link, aboutLink.name)}
        >
          {aboutLink.name}
        </StyledDescriptionButton>
      ))}
    </>
  )
}

export default AboutLink
