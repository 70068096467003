import axios from 'axios'

import memoize from 'lodash/fp/memoize'

import apiConfig from 'config/apiConfig'

import { Direction, incrementNumber } from 'services/pageableHelper'

import { HeliosListItem, HeliosItem } from 'types/HeliosItem'
import { CollectionResponse } from 'types/Response'

export interface HeliosListSearchParams {
  status?: string
  version?: string
  seller_id?: string
  tcin?: string
  external_id?: string
}

export interface HeliosListPagingParams {
  sort?: string
  order?: Direction
  page: number
  pageSize: number
}

export function getHeliosItemList(
  searchParams: HeliosListSearchParams,
  pagingParams: HeliosListPagingParams,
): Promise<CollectionResponse<HeliosListItem>> {
  const pageNum = incrementNumber(pagingParams.page)

  const config = {
    params: {
      ...searchParams,
      order: pagingParams.order,
      sort: pagingParams.sort,
      page: pageNum,
      page_size: pagingParams.pageSize,
    },
  }

  const url = `${apiConfig.helios}/items`

  return axios.get(url, config).then((res) => {
    const { data, headers } = res
    const total = headers['total-count']
      ? parseInt(headers['total-count'], 10)
      : 0

    return {
      total,
      data,
    }
  })
}

export function getHeliosItem({
  tcin,
  version,
}: {
  tcin: string
  version?: number
}): Promise<HeliosItem> {
  const config = {
    params: {
      version,
    },
  }

  const url = `${apiConfig.helios}/items/${tcin}`

  return axios.get(url, config).then((res) => {
    return res.data
  })
}

export async function releaseItem(tcin: string) {
  const config = {
    params: {
      tcin,
    },
  }

  return await axios.get(`${apiConfig.helios}/release`, config)
}

type ItemVersionDetails = Partial<{
  nextEnrichments: string
  sourceTimestamp: string
}>

export const getItemVersionDetails = async (itemVersionId: string) => {
  let nextEnrichments
  let sourceTimestamp
  try {
    const itemVersionDetails = await getHeliosItem({ tcin: itemVersionId })
    sourceTimestamp = itemVersionDetails.source_timestamp

    nextEnrichments = itemVersionDetails.next_enrichments?.[0]
  } catch (e) {
    console.error(e)
  }

  return {
    nextEnrichments,
    sourceTimestamp,
  }
}

export const memoizedGetItemVersionDetails = memoize(
  async (itemVersionId: string) => {
    let details: ItemVersionDetails = {}
    try {
      details = await getItemVersionDetails(itemVersionId)
    } catch (e) {
      console.error(e)
    }

    return details
  },
)
