import { useState } from 'react'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'

import startCase from 'lodash/fp/startCase'
import toLower from 'lodash/fp/toLower'

import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'

import DataList from 'components/common/DataList'
import DialogContainer from 'components/common/Dialog/DialogContainer'
import Input from 'components/common/Input'
import { formatDiscount } from 'components/PromotionsPage/PromotionsList'
import { DialogEnum } from 'components/common/Dialog'
import RadioGroup from 'components/common/RadioGroup/RadioGroup'
import PromotionStatusChip from '../PromotionStatusChip'

import {
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
  formatDate,
  isBeforeDate,
} from 'services/dateService'
import { deletePromotion, updatePromotion } from 'services/promotions'
import { validationHandler, ValidationSchema } from 'services/validation'

import { closeDialog, openDialog } from 'store/dialog/actionCreator'

import { Validation } from 'types/Validation'
import {
  MerchandiseHierarchy,
  Promotion,
  PromotionStatus,
} from 'types/Promotion'

const StyledBackDrop = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

interface Props {
  isOpen: boolean
  promotion: Promotion
  refreshData?: () => void
}

const validationSchema: ValidationSchema = yup.object().shape({
  name: yup.string().label('Name').required(),
})

export const EditPromotion = ({ isOpen, promotion, refreshData }: Props) => {
  const reduxDispatch = useDispatch()

  const [isPending, setIsPending] = useState(false)
  const [promotionUpdate, setPromotionUpdate] = useState(promotion)
  const [validation, setValidation] = useState<Validation>({})

  const handleInputChange = (value: string, property: string) => {
    setPromotionUpdate((prev) => ({
      ...prev,
      [property]: value,
    }))
  }

  const handleInputTrim = (value: string, property: string) => {
    const trimmedValue = value.trim()
    setPromotionUpdate((prev) => ({
      ...prev,
      [property]: trimmedValue,
    }))
  }

  const handleSubmit = () => {
    const { validation: formValidation, isValid } = validationHandler(
      validationSchema,
      {
        name: promotionUpdate.name,
      },
    )
    setValidation(formValidation)
    if (isValid) {
      setIsPending(true)
      updatePromotion(promotionUpdate)
        .then(() => {
          reduxDispatch(closeDialog())
        })
        .catch(() => {
          setIsPending(false)
        })
    }
  }

  const handleDeletePromotion = () => {
    deletePromotion(promotion).then(() => {
      reduxDispatch(closeDialog())
      if (refreshData) {
        refreshData()
      }
    })
  }

  const handleDelete = () => {
    const today = new Date()
    const startDate = new Date(promotion.start_date)
    const activePromo =
      promotion.status === PromotionStatus.PARTNER_VISIBLE &&
      isBeforeDate(today, startDate)

    if (activePromo) {
      const alertMessage =
        'This partner-visible promotion cannot be deleted since its start date and time has passed.'
      window.alert(alertMessage)
    } else {
      reduxDispatch(
        openDialog({
          dialogEnum: DialogEnum.CONFIRMATION_DIALOG,
          componentProps: {
            title: 'Delete Confirmation',
            submitText: 'Yes, delete',
            content:
              'Are you sure you want to delete this promotion? This cannot be undone.',
            onRequestSubmit: handleDeletePromotion,
          },
        }),
      )
    }
  }

  const getDivisions = (
    merchandiseHierarchies: Array<MerchandiseHierarchy>,
  ) => {
    const divisions: string[] = []
    merchandiseHierarchies.forEach((merchandiseHierarchy) => {
      const divisionName = merchandiseHierarchy.division_name
      if (divisionName) {
        divisions.push(startCase(toLower(divisionName)))
      }
    })

    return divisions.length ? divisions.join(', ') : 'None'
  }

  return (
    <DialogContainer
      title="Edit Promotion"
      deleteButtonText="Delete Promotion"
      onDelete={handleDelete}
      isOpen={isOpen}
      onSubmit={handleSubmit}
      isPending={isPending}
    >
      <Grid item xs={12}>
        <StyledBackDrop elevation={0}>
          <DataList
            data={[
              {
                title: 'Promo ID & Name:',
                value: `${promotion.promotion_id} - ${promotion.name}`,
              },
              {
                title: 'Priority:',
                value: promotion.priority_label ?? 'None',
              },
              {
                title: 'Umbrella (Promo Message):',
                value: promotion.umbrella_label ?? 'None',
              },
              {
                title: 'Discount:',
                value:
                  formatDiscount(
                    promotion.details[0]?.discount_type,
                    promotion.details[0]?.discount_value,
                  ) ?? 'None',
              },
              {
                title: 'Division:',
                value: getDivisions(promotion.merchandise_hierarchies),
              },
              {
                title: 'Start Date:',
                value: formatDate(
                  promotion.start_date as any,
                  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
                ),
                listItemStyles: { marginTop: '16px' },
              },
              {
                title: 'End Date:',
                value: formatDate(
                  promotion.end_date as any,
                  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
                ),
                listItemStyles: { marginBottom: '16px' },
              },
              {
                title: 'Status:',
                value: (
                  <PromotionStatusChip
                    status={promotion.status}
                    upstreamStatus={promotion.upstream_status}
                    isInline
                  />
                ),
              },
            ]}
          />
        </StyledBackDrop>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input
            id="priority_label"
            data-testid="priority_label"
            name="priority_label"
            label="Priority"
            isDisabled={isPending}
            value={promotionUpdate?.priority_label}
            onChange={handleInputChange}
            onBlur={handleInputTrim}
            validation={validation}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            id="umbrella_label"
            data-testid="umbrella_label"
            name="umbrella_label"
            label="Umbrella (Promo Message)"
            isDisabled={isPending}
            value={promotionUpdate.umbrella_label}
            onChange={handleInputChange}
            onBlur={handleInputTrim}
          />
        </Grid>
        <Grid item xs={12}>
          <RadioGroup
            id="status"
            name="status"
            label="Status"
            row={true}
            options={[
              {
                label: 'Partner Visible',
                value: PromotionStatus.PARTNER_VISIBLE,
              },
              {
                label: 'Partner Not Visible',
                value: PromotionStatus.PARTNER_NOT_VISIBLE,
              },
            ]}
            onChange={handleInputChange}
            value={promotionUpdate.status}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export default EditPromotion
