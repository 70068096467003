import React from 'react'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import GetAppIcon from '@mui/icons-material/GetApp'

import LineChart from 'components/common/Charts/LineChart'
import DateRangeSelector from 'components/common/DateRangePicker/DateRangeSelector'
import { DateRange } from 'components/common/DateRangePicker/rangeConfig'
import DisplayCard from 'components/common/DisplayCard'

import {
  getOrderDefectLineChartData,
  getOrderDefectRateOverTime,
  getTimePeriod,
  getTimePeriodInfo,
  getVendorFilter,
  LineChartData,
} from 'services/biReporting'
import { endOfYesterday } from 'services/dateService'
import { trackCustomEvent } from 'services/fireflyInsights'

import { BiReportingCards } from 'types/BiReporting'
import { FireflyEvent } from 'types/FireflyInsights'

export type Props = {
  title: string
  sellerId: string
  vmmId: string
  cardId: BiReportingCards
  graphCardId: BiReportingCards
  property: string
  dateRangeOptions: any
  defaultRange: DateRange
  minDate: Date
  from: Date
  to: Date
  isCustomDateRange: boolean
  onDateRangeChange: ({
    from,
    to,
    isCustom,
  }: {
    from: Date
    to: Date
    isCustom: boolean
  }) => void
  onReportGenerate?: ({ from, to }: { from: Date; to: Date }) => void
}

export const maxDate = endOfYesterday(new Date())

export const RateOverTimeCard: React.FC<Props> = ({
  title,
  sellerId,
  vmmId,
  cardId,
  graphCardId,
  property,
  dateRangeOptions,
  defaultRange,
  minDate,
  from,
  to,
  isCustomDateRange,
  onDateRangeChange,
  onReportGenerate,
}) => {
  const [rate, setRate] = React.useState<number>()
  const [pending, setPending] = React.useState(false)
  const [graphData, setGraphData] = React.useState<LineChartData[]>([])

  React.useEffect(() => {
    let mounted = true

    const fetchGraphData = async () => {
      if (mounted) {
        setPending(true)
      }

      const vendorFilter = getVendorFilter(vmmId)
      const timePeriod = getTimePeriod({
        interval: `Last 365 Days`,
      })

      const lineChartData = await getOrderDefectLineChartData({
        cardId: graphCardId,
        sellerId,
        filters: vendorFilter,
        timePeriod,
      })

      if (mounted) {
        setGraphData(lineChartData)
        setPending(false)
      }
    }

    fetchGraphData()

    return () => {
      mounted = false
    }
  }, [graphCardId, sellerId, vmmId])

  React.useEffect(() => {
    let mounted = true

    const fetchDefectRateOverTime = async () => {
      const vendorFilter = getVendorFilter(vmmId)
      const timePeriod = getTimePeriodInfo(from, to, isCustomDateRange)

      const defectRateValue = await getOrderDefectRateOverTime({
        key: property,
        cardId: cardId,
        sellerId,
        filters: vendorFilter,
        timePeriod,
      })

      if (mounted && defectRateValue !== undefined) {
        setRate(defectRateValue)
      }
    }

    fetchDefectRateOverTime()

    return () => {
      mounted = false
    }
  }, [cardId, property, sellerId, vmmId, isCustomDateRange, from, to])

  const handleDateChange = (
    newFrom: Date,
    newTo: Date,
    isValid: boolean,
    selectedIndex: number,
  ) => {
    if (!isValid) return
    const isCustom = selectedIndex === 4

    onDateRangeChange({
      from: newFrom,
      to: newTo,
      isCustom,
    })
  }

  const filterGraphData = (data: LineChartData[]) => {
    const subset = data.filter(
      (item) => item.fullDate && item.fullDate >= from && item.fullDate <= to,
    )

    return subset
  }

  const handleGenerateReport = () => {
    if (onReportGenerate) {
      onReportGenerate({ from, to })
      trackCustomEvent(
        FireflyEvent.ODR_USER_ACTION,
        'global',
        'download report',
      )
    }
  }

  return (
    <DisplayCard title={title} fullHeight={false}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {rate !== undefined && (
            <Typography
              variant="h4"
              component="p"
              data-testid="defect-rate-over-time"
            >
              {rate}%
            </Typography>
          )}
        </Grid>
        <Grid item xs={5}>
          <DateRangeSelector
            type="odrMetric"
            descriptor="Date Range"
            defaultRange={defaultRange}
            defaultFrom={from}
            defaultTo={maxDate}
            maxDate={maxDate}
            maxDateError="Must be yesterday or earlier"
            onDateChange={handleDateChange}
            disableDatesBefore={minDate}
            dateRangeOptions={dateRangeOptions}
          />
        </Grid>
        {!pending && (
          <Grid item xs={12} data-testid="line-chart">
            <LineChart
              data={filterGraphData(graphData)}
              tooltipFormatter={(value: any) => [`${value}%`]}
              tooltipLabelFormatter={(label: any) => label}
              ariaLabelCallback={(xValue, yValue) => `${yValue} ${xValue}`}
              referenceLinePosition={5}
              referenceLineValue="> 5% NON-COMPLIANT"
            />
          </Grid>
        )}
        {onReportGenerate && (
          <Grid item xs={12} lg={10}>
            <Button
              data-testid="generate-odr-report"
              color="primary"
              startIcon={<GetAppIcon />}
              onClick={handleGenerateReport}
            >
              Generate & Download Report
            </Button>
          </Grid>
        )}
      </Grid>
    </DisplayCard>
  )
}

export default RateOverTimeCard
