import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import keyBy from 'lodash/fp/keyBy'

import styled from '@emotion/styled'

import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Image from 'components/common/Image'
import Typography from '@mui/material/Typography'

import DataList from 'components/common/DataList'
import DisplayCard from 'components/common/DisplayCard'
import { skuLink, tcinLink } from 'components/common/EnhancedTable/formatters'

import { getCodeDescriptionAlias } from 'services/codes'
import {
  formatDate,
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
} from 'services/dateService'
import { getStoreLocation } from 'services/locations'

import { getReturnReasons } from 'store/selectors'

import { ReturnWithDetail } from 'types/Orders'

import { getStoreLocationQueryKeys, STORE_LOCATION } from './queries'

import ReturnTracking from './ReturnTracking'

const StyledText = styled(Typography)({ fontWeight: 500 })

const StyledDisplayCard = styled(DisplayCard)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const StyledDividerContainer = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(2, -2),
}))

const keyByValue = keyBy<{ value: string; description: string }>(
  (reason) => reason.value,
)

export const Returns = ({
  returnOrders,
}: {
  returnOrders: ReturnWithDetail[]
}) => {
  const { data: returnLocation } = useQuery(
    getStoreLocationQueryKeys(
      STORE_LOCATION,
      returnOrders[0].location_id ?? returnOrders[0].location_id,
    ),
    () => {
      if (!returnOrders[0].is_online && returnOrders[0].location_id) {
        return getStoreLocation(returnOrders[0].location_id)
      }
      return null
    },
  )

  const collatedReturnOrders = returnOrders.reduce((out, returnOrder) => {
    if (out[returnOrder.return_order_number]) {
      out[returnOrder.return_order_number].push(returnOrder)
    } else {
      out[returnOrder.return_order_number] = [returnOrder]
    }

    return out
  }, {} as any) as Dictionary<ReturnWithDetail[]>

  const rawReasons = useSelector(getReturnReasons)

  const returnReasons = keyByValue(
    rawReasons.map((reason) => ({
      value: reason.value,
      description: reason?.properties.description ?? reason.value,
    })),
  )

  return (
    <div data-testid="returns">
      <Grid container>
        {Object.values(collatedReturnOrders).map((returnOrders, index) => {
          const location = returnOrders[0].is_online
            ? 'Online'
            : `${
                returnOrders[0].location_id && returnLocation
                  ? `Store T-${returnOrders[0].location_id} in ${returnLocation.address[0].city}, ${returnLocation.address[0].region}`
                  : 'Unknown Store'
              }`

          const returnDate = formatDate(
            returnOrders[0].return_date,
            DATE_FORMAT_MONTH_DAY_YEAR_TIME,
          )
          return (
            <StyledDisplayCard
              key={index}
              title={`Return Order Number ${returnOrders[0].return_order_number}`}
              description={`Via ${location} ${
                returnDate && `on ${returnDate}`
              }`}
              fullWidth={true}
            >
              {returnOrders.map((returnOrder, index) => {
                return (
                  <Grid container key={index}>
                    <Grid item xs={12}>
                      <StyledText>{returnOrder.productTitle}</StyledText>
                    </Grid>
                    <Grid item xs={2}>
                      <Image
                        src={returnOrder.image}
                        alt="primary image"
                      ></Image>
                    </Grid>
                    <Grid item xs={4}>
                      <DataList
                        data={[
                          { title: 'SKU:', element: skuLink()(returnOrder) },
                          { title: 'TCIN:', element: tcinLink()(returnOrder) },
                          { title: 'Return Qty:', value: returnOrder.quantity },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DataList
                        data={[
                          {
                            title: 'Return Reason:',
                            value: returnReasons[returnOrder.return_reason]
                              ? returnReasons[returnOrder.return_reason]
                                  .description
                              : '',
                          },
                          {
                            title: 'Return Policy:',
                            value: `${getCodeDescriptionAlias(
                              returnOrder.financial_disposition,
                            )}, ${getCodeDescriptionAlias(
                              returnOrder.physical_disposition,
                            )}`,
                          },
                          {
                            title: 'Registry Item:',
                            value: returnOrder.registry_item ? 'Yes' : 'No',
                          },
                        ]}
                      />
                    </Grid>
                    <ReturnTracking
                      returnOrder={returnOrder}
                      returnLocation={returnLocation ?? undefined}
                    />
                    {index < returnOrders.length - 1 && (
                      <StyledDividerContainer item xs={12}>
                        <Divider variant="middle" />
                      </StyledDividerContainer>
                    )}
                  </Grid>
                )
              })}
            </StyledDisplayCard>
          )
        })}
      </Grid>
    </div>
  )
}

export default Returns
