import React from 'react'
import has from 'lodash/fp/has'

import Grid from '@mui/material/Grid'

import HeaderTitle from 'components/common/HeaderTitle'
import ReportCard from 'components/common/ReportCard'

import { ReportType, Report } from 'types/Report'

export const ItemUploadPage = () => {
  const getTemplateReports = React.useCallback(
    (reports: Report[]) =>
      reports.filter(
        (report) =>
          report.type === ReportType.BULK_PRODUCT_DATA_TEMPLATE &&
          has('get_products', report.parameters),
      ),
    [],
  )

  const getUploadReports = React.useCallback(
    (reports: Report[]) =>
      reports.filter(
        (report) =>
          report.type === ReportType.BULK_PRODUCT_DATA &&
          !has('get_products', report.parameters),
      ),
    [],
  )

  return (
    <>
      <HeaderTitle title="Item Upload" />
      <Grid container spacing={2} data-testid="item-upload-page">
        <Grid item xs={12} sm={8} lg={6}>
          <ReportCard
            reportType={ReportType.BULK_PRODUCT_DATA_TEMPLATE}
            getRelevantReports={getTemplateReports}
          ></ReportCard>
        </Grid>
        <Grid item xs={12} sm={8} lg={6}>
          <ReportCard
            reportType={ReportType.BULK_PRODUCT_DATA}
            getRelevantReports={getUploadReports}
          ></ReportCard>
        </Grid>
      </Grid>
    </>
  )
}

export default ItemUploadPage
