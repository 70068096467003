import { SavedSearch } from 'types/SavedSearch'

export const transformSavedSearch = (
  savedSearch: SavedSearch,
  listValues: string[],
) => {
  const newObject: any = {}
  for (const [key, value] of Object.entries(savedSearch)) {
    if (listValues.includes(key)) {
      // Keep arrays for specified param values
      newObject[key] = value
    } else if (
      Array.isArray(value) &&
      value.length === 1 &&
      typeof value[0] === 'string'
    ) {
      // Update other param values to strings
      newObject[key] = value[0]
    }
  }
  return newObject
}

const possibleFilters = [
  'published',
  'validation_status',
  'seller_id',
  'item_type_id',
  'tcin',
  'has_inventory',
  'previously_approved',
  'seller_status',
  'product_type_id',
  'saved_search_name',
]

export const getSelectedFilters = (searchParams: any) =>
  Object.fromEntries(
    Object.entries(searchParams).filter(
      ([key, value]) => possibleFilters.includes(key) && value !== undefined,
    ),
  ) ?? {}

export const sortBySavedSearchName = (savedSearches: SavedSearch[]) => {
  const decodedValue = (value: string[]) => atob(value[0])

  return savedSearches.sort((a: SavedSearch, b: SavedSearch) => {
    const savedSearchA = decodedValue(a.saved_search_name)
    const savedSearchB = decodedValue(b.saved_search_name)

    return savedSearchA.localeCompare(savedSearchB)
  })
}

export const isDuplicateSavedSearchName = (
  existingNames: string[],
  newName: string,
) => {
  const decodedFilterNames = existingNames.map((encodedName: string) =>
    window.atob(encodedName).toLowerCase(),
  )
  return decodedFilterNames.includes(newName.toLowerCase())
}
