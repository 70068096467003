import React from 'react'

import TableCell from '@mui/material/TableCell'
import styled from '@emotion/styled'

const StyledTableCell = styled(TableCell)({
  wordWrap: 'break-word',
}) as typeof TableCell

interface Props {
  children: React.ReactNode
  numeric?: boolean
  noBorder?: boolean
  minWidth?: number
  width?: number
  fixedWidth?: boolean
  extraPadding?: boolean
}

export const TabularDataCell = ({
  children,
  numeric,
  noBorder,
  minWidth,
  width,
  fixedWidth,
  extraPadding,
  ...rest
}: Props) => {
  const style = fixedWidth
    ? { width: `${width}px` }
    : minWidth || width
    ? { minWidth: `${minWidth}px`, width: `${width}%` }
    : undefined

  return (
    <StyledTableCell
      sx={{
        p: !extraPadding ? '0.5, 0.5, 0.5, 0' : undefined,
      }}
      style={style}
      {...rest}
    >
      {children}
    </StyledTableCell>
  )
}

export default TabularDataCell
