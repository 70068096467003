import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'

import DateRangePicker from 'components/common/DateRangePicker'
import DialogContainer from 'components/common/Dialog/DialogContainer'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import {
  formatAsCurrency,
  formatDateMDYT,
} from 'components/common/EnhancedTable/formatters'

import usePrevious from 'hooks/usePrevious'

import { datesEqual, getDateBefore } from 'services/dateService'
import { getPayouts } from 'services/payouts'
import { GenerateReportRequest } from 'services/reports'

import { closeDialog } from 'store/dialog/actionCreator'
import { currentSellerId } from 'store/selectors'

import { Payout } from 'types/Payout'

const StyledRoot = styled('div')({
  minHeight: 300,
})
const StyledTypography = styled(Typography)({
  paddingBottom: 25,
})

interface Props {
  isOpen: boolean
  onRequestSubmit: (reportParams: Partial<GenerateReportRequest>) => void
}

interface Range {
  from: Date
  to: Date
}
const fieldList: EnhancedTableFieldType<Payout>[] = [
  {
    key: 'arrival_date',
    heading: 'Payout Date',
    formatCell: formatDateMDYT('arrival_date'),
  },
  {
    key: 'id',
    heading: 'Payout ID',
  },
  {
    key: 'payout_amount',
    heading: 'Payout Amount',
    formatCell: formatAsCurrency('payout_amount'),
  },
]

const FinancialReconciliationReport = ({ isOpen, onRequestSubmit }: Props) => {
  const sellerId = useSelector(currentSellerId)

  const dispatch = useDispatch()

  const now = new Date()

  const [isPending, setIsPending] = React.useState(false)
  const [payouts, setPayouts] = React.useState<Payout[]>([])
  const [range, setRange] = React.useState<Range>({
    from: getDateBefore(now, 30, 'days'),
    to: now,
  })

  const prevRange = usePrevious(range) as Range | undefined

  React.useEffect(() => {
    if (!sellerId) {
      return
    }

    if (
      prevRange === undefined ||
      !datesEqual(prevRange.from, range.from) ||
      !datesEqual(prevRange.to, range.to)
    ) {
      setIsPending(true)

      getPayouts({
        sellerId: sellerId,
        fromDate: range.from,
        toDate: range.to,
      }).then((res) => {
        setPayouts(res)
        setIsPending(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range, sellerId])

  const handleDateChange = (newFrom: Date, newTo: Date) => {
    setRange({
      from: newFrom,
      to: newTo,
    })
  }

  const handleSubmit = () => {
    onRequestSubmit({
      startDate: range.from,
      endDate: range.to,
    })

    dispatch(closeDialog())
  }

  return (
    <DialogContainer
      title="Generate Financial Reconciliation Report"
      isOpen={isOpen}
      isPending={isPending}
      onSubmit={handleSubmit}
      submitButtonText="Generate"
      disableScroll
    >
      <StyledRoot data-testid="financial-reconciliation">
        <StyledTypography>Select date range of up to 90 days:</StyledTypography>
        <DateRangePicker
          isPending={isPending}
          from={range.from}
          to={range.to}
          dayRange={90}
          actionHandler={handleDateChange}
          actionLabel="Go"
        />
        <EnhancedTable
          data={payouts}
          fieldList={fieldList}
          isLoading={isPending}
          rowsPerPage={20}
          padding="none"
        />
      </StyledRoot>
    </DialogContainer>
  )
}

export default FinancialReconciliationReport
