import { useEffect, useState } from 'react'

import styled from '@emotion/styled'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import DialogActions from '@mui/material/DialogActions'
import { Button } from '@enterprise-ui/canvas-ui-react'

import { EditContainerProps } from './AllowedItemSettingsDialog'

import { BrandTypeahead, TaxonomyTypeahead } from 'components/common/Typeahead'

import { Brand } from 'types/Brand'
import Attribute, { BRAND, ITEM_TYPE } from 'types/Attribute'

const StyledChip = styled(Chip)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginRight: theme.spacing(1),
}))

export const EditAllowedItemSettingsContainer = ({
  type,
  legalization,
  primary,
  paired,
  doPrimaryChange,
  doAddPaired,
  doRemovePaired,
  doCancel,
  doSubmit,
}: EditContainerProps) => {
  const [taxonomy, setTaxonomy] = useState<Nullable<Attribute>>()

  useEffect(() => {
    if (primary) {
      setTaxonomy({
        type: primary.type,
        id: primary.id,
        name: primary.name ?? '',
        description: primary.description ?? '',
      })
    } else {
      setTaxonomy(null)
    }
  }, [primary])

  const handlePrimaryChange = (attribute: Nullable<Attribute>) => {
    if (attribute) {
      doPrimaryChange(attribute)
    } else {
      doPrimaryChange(undefined)
    }
  }

  const handleAddPairedAttribute = (brand: Nullable<Brand>) => {
    if (brand) {
      const attribute = {
        id: brand.brandId,
        name: brand.brandName,
        type: BRAND,
      }
      doAddPaired(attribute)
    }
  }

  const handleRemovePairedAttribute = (attribute: Attribute) => () => {
    if (attribute) {
      doRemovePaired(attribute)
    }
  }

  const handleSubmit = () => {
    // TODO validate the legalization
    const updated = {
      id: legalization.id,
      primary_attribute: primary!,
      paired_attributes: paired,
    }

    doSubmit(updated)
  }

  const label = type === ITEM_TYPE ? 'Item Type' : 'Subtype'

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TaxonomyTypeahead
                label={`Add Allowed ${label}*`}
                placeholder={`Search ${label}s`}
                searchType={type}
                onChange={handlePrimaryChange}
                value={taxonomy}
              />
            </Grid>
            <Grid item xs={12}>
              <BrandTypeahead
                label="Add Brands (optional)"
                placeholder="Search Brand Names"
                onChange={handleAddPairedAttribute}
                clearOnSelect
              />
            </Grid>
            <Grid item xs={12}>
              {paired.map((attr: Attribute, idx: number) => {
                return (
                  <StyledChip
                    key={idx}
                    label={attr.name}
                    onDelete={handleRemovePairedAttribute(attr)}
                    data-testid="brand-chip"
                  />
                )
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DialogActions>
            <Button
              className="hc-mr-dense hc-pr-expanded hc-pl-expanded"
              data-testid="dialog-cancel-button"
              type="secondary"
              onClick={doCancel}
            >
              Cancel
            </Button>
            <Button
              className="hc-mr-dense hc-pr-expanded hc-pl-expanded"
              data-testid="dialog-submit-button"
              type="primary"
              disabled={!primary}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </>
  )
}

export default EditAllowedItemSettingsContainer
