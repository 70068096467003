import { useEffect, useState } from 'react'

import MuiOutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import { Box, Typography } from '@mui/material'

import { Validation } from 'types/Validation'
import theme from 'config/themeConfig'
import ValidationErrorText from '../ValidationErrorText'

interface Props {
  id: string
  name: string // Should be a key in the validationSchema object
  value: string | number | undefined
  type?: string
  isRequired?: boolean
  isDisabled?: boolean
  isMultiline?: boolean
  isError?: boolean
  shrink?: boolean
  placeholder?: string
  label?: string
  onBlur?: (value: string, name: string, id: string) => void
  onChange: (value: string, name: string, id: string) => void
  rows?: string
  validation?: Validation
  helperText?: string
  inputProps?: any
  inputRef?: any
  startAdornment?: any
  maxCharacters?: number
  size?: 'small' | 'medium' | undefined
  variant?: 'standard' | 'outlined' | 'filled' | undefined
  autoFocus?: boolean
}

export const OutlinedInput = ({
  id,
  name,
  value,
  type = 'text',
  isRequired = false,
  isDisabled = false,
  isMultiline = false,
  shrink = true,
  size = 'small',
  placeholder,
  label,
  onBlur,
  onChange,
  rows,
  validation,
  helperText,
  inputProps,
  inputRef,
  startAdornment,
  maxCharacters,
  autoFocus = false,
  isError = false,
}: Props) => {
  const [hint, setHint] = useState<string>()
  const handleChange = (event: any) => {
    onChange(event.target.value, name, id)
  }
  const handleBlur = (event: any) => {
    if (onBlur) {
      onBlur(event.target.value, name, id)
    }
  }

  useEffect(() => {
    const currentValue = value?.toString()
    if (maxCharacters) {
      const len = currentValue?.length
        ? maxCharacters - currentValue?.length
        : maxCharacters
      const subject = len === 1 ? 'character' : 'characters'
      const updatedHint = `${len} ${subject} left (Maximum of ${maxCharacters}).`
      setHint(updatedHint)
    }
  }, [value, maxCharacters])

  return (
    <FormControl fullWidth required={isRequired} sx={{ minWidth: 120 }}>
      {label && (
        <InputLabel shrink={shrink} htmlFor={id}>
          {label}
        </InputLabel>
      )}
      <MuiOutlinedInput
        name={id}
        id={id}
        value={value}
        inputRef={inputRef}
        placeholder={placeholder}
        disabled={isDisabled}
        multiline={isMultiline}
        rows={rows}
        onChange={handleChange}
        onBlur={handleBlur}
        inputProps={{
          'aria-label': label,
          'data-testid': id,
          ...inputProps,
        }}
        size={size}
        type={type}
        startAdornment={startAdornment}
        autoFocus={autoFocus}
      />

      <Box width={'370px'} height={'40px'}>
        {helperText && (
          <Typography
            color={isError ? theme.palette.error.main : theme.palette.grey[700]}
          >
            {helperText}
          </Typography>
        )}

        {hint && !isError && (
          <FormHelperText id="name-error-text">
            {hint ? hint : helperText}
          </FormHelperText>
        )}
      </Box>
      {validation && <ValidationErrorText errors={validation} field={name} />}
    </FormControl>
  )
}

export default OutlinedInput
