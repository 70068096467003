import { ExternalUserLogin } from './../../types/UserInfo'
import { UserInfo } from 'types/UserInfo'
import SellerUser, { Entitlement } from 'types/SellerUser'

// CONSTANTS
export const SET_USER_INFO: string = 'SET_USER_INFO'
export const EXTERNAL_SELLER_LOGIN: string = 'EXTERNAL_SELLER_LOGIN'
export const FETCH_SELLER_USER: string = 'FETCH_SELLER_USER'
export const FETCH_SELLER_USER_DONE: string = 'FETCH_SELLER_USER_DONE'
export const FETCH_USER_ENTITLEMENTS: string = 'FETCH_USER_ENTITLEMENTS'
export const FETCH_USER_ENTITLEMENTS_DONE: string =
  'FETCH_USER_ENTITLEMENTS_DONE'
export const UPDATE_ENTITLEMENTS_BY_SELLER_ID: string =
  'UPDATE_ENTITLEMENTS_BY_SELLER_ID'
export const EDIT_USER_INFO: string = 'EDIT_USER_INFO'
export const EDIT_USER_INFO_DONE: string = 'EDIT_USER_INFO_DONE'
export const SET_EXTERNAL_USER_LOGIN: string = 'SET_EXTERNAL_USER_LOGIN'

// INTERFACES
export interface SetUserInfoAction {
  readonly type: typeof SET_USER_INFO
  payload: {
    userInfo: UserInfo
    isAuthenticated: boolean
  }
}

export interface ExternalSellerLoginAction {
  readonly type: typeof EXTERNAL_SELLER_LOGIN
}

export interface FetchSellerUserAction {
  readonly type: typeof FETCH_SELLER_USER
}

export interface FetchSellerUserDoneAction {
  readonly type: typeof FETCH_SELLER_USER_DONE
  payload: SellerUser
}

export interface FetchUserEntitlementsAction {
  readonly type: typeof FETCH_USER_ENTITLEMENTS
  userId: string
  sellerIds: string[]
}

export interface FetchUserEntitlementsDoneAction {
  type: typeof FETCH_USER_ENTITLEMENTS_DONE
  payload: Entitlement[]
}

export interface UpdateEntitlementsBySellerIdAction {
  readonly type: typeof UPDATE_ENTITLEMENTS_BY_SELLER_ID
  payload: string
}

export interface EditUserInfoParams {
  userId: string
  firstName: string
  lastName: string
  email: string
  officeCountry?: string
  officePhoneCode?: string
  mobileCountry?: string
  officePhoneNumber?: string
  mobilePhoneCode: string
  mobilePhoneNumber: string
}

export interface EditUserInfoAction {
  readonly type: typeof EDIT_USER_INFO
  params: EditUserInfoParams
}

export interface EditUserInfoDoneAction {
  readonly type: typeof EDIT_USER_INFO_DONE
  payload: SellerUser
}

export interface SetExternalUserLoginAction {
  readonly type: typeof SET_EXTERNAL_USER_LOGIN
  payload: ExternalUserLogin
}

// ACTIONS
export function setUserInfo({
  userInfo,
  isAuthenticated,
}: {
  userInfo: UserInfo
  isAuthenticated: boolean
}): SetUserInfoAction {
  return {
    type: SET_USER_INFO,
    payload: {
      userInfo,
      isAuthenticated,
    },
  }
}

export function fetchSellerUser(): FetchSellerUserAction {
  return { type: FETCH_SELLER_USER }
}

export function fetchSellerUserDone(
  payload: SellerUser,
): FetchSellerUserDoneAction {
  return { type: FETCH_SELLER_USER_DONE, payload }
}

export function fetchUserEntitlementsAction(
  userId: string,
  sellerIds: string[],
): FetchUserEntitlementsAction {
  return { type: FETCH_USER_ENTITLEMENTS, userId, sellerIds }
}

export function fetchUserEntitlementsDone(
  payload: Entitlement[],
): FetchUserEntitlementsDoneAction {
  return { type: FETCH_USER_ENTITLEMENTS_DONE, payload }
}

export function updateEntitlementsBySellerId(
  payload: string,
): UpdateEntitlementsBySellerIdAction {
  return {
    type: UPDATE_ENTITLEMENTS_BY_SELLER_ID,
    payload,
  }
}

export function externalSellerLogin(): ExternalSellerLoginAction {
  return { type: EXTERNAL_SELLER_LOGIN }
}

export function editUserInfo(params: EditUserInfoParams): EditUserInfoAction {
  return {
    type: EDIT_USER_INFO,
    params,
  }
}

export function editUserInfoDone(payload: SellerUser): EditUserInfoDoneAction {
  return { type: EDIT_USER_INFO_DONE, payload }
}

export function setExternalUserLogin(
  payload: ExternalUserLogin,
): SetExternalUserLoginAction {
  return {
    type: SET_EXTERNAL_USER_LOGIN,
    payload,
  }
}

export type UserActions =
  | SetUserInfoAction
  | ExternalSellerLoginAction
  | FetchSellerUserDoneAction
  | FetchUserEntitlementsDoneAction
  | UpdateEntitlementsBySellerIdAction
  | SetExternalUserLoginAction
