import LinearProgress from '@mui/material/LinearProgress'
import styled from '@emotion/styled'

import { success, warning } from 'config/themeConfig'

import { ReportStatus } from 'types/Report'

const StyledLinearProgress = styled(LinearProgress, {
  shouldForwardProp: (prop) => prop !== 'progressStatus',
})<{ progressStatus: any }>((props) => ({
  '& .MuiLinearProgress-barColorPrimary':
    props.progressStatus === ReportStatus.COMPLETE
      ? {
          background: success.main,
        }
      : props.progressStatus === ReportStatus.ERROR
      ? { background: warning.main }
      : undefined,
}))

export const ReportProgress = ({ status }: { status?: ReportStatus }) => {
  const value = status === 'COMPLETE' || status === 'ERROR' ? 100 : 0

  const variant =
    status === 'COMPLETE' || status === 'ERROR'
      ? 'determinate'
      : 'indeterminate'

  return (
    <StyledLinearProgress
      data-testid={`report-progress-${status}`}
      variant={variant}
      value={value}
      progressStatus={status}
      title={'Report Progress ' + value + '%'}
    />
  )
}

export default ReportProgress
