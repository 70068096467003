import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { withFormik, Form, FormikProps } from 'formik'
import * as yup from 'yup'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'

import { white } from 'config/themeConfig'
import styled from '@emotion/styled'

import InputField from 'components/common/form/InputField'
import SelectField from 'components/common/form/SelectField'
import RadioGroupField from 'components/common/form/RadioGroupField'
import Address from 'components/common/Address'
import PhoneField from 'components/common/form/PhoneField'
import { DialogEnum } from 'components/common/Dialog'
import Sticky from 'components/common/Sticky'

import { hydrateAddress } from 'services/addressHelper'

import { openDialog, closeDialog } from 'store/dialog/actionCreator'

import {
  buildVerificationAttribute,
  updateSellerVerification,
} from 'services/sellerVerification'

import { getLocationsFromCountryCode } from 'constants/locations'
import COUNTRIES from 'constants/countries'

import { CountryNameCode } from 'types/Country'
import { SmsSeller } from 'types/Seller'
import {
  SellerVerification,
  SellerVerificationAttributeName,
  SellerVerificationAttributeUpdate,
} from 'types/SellerVerification'
import { SmsAddress } from 'types/Address'
import { PhoneNumber } from 'types/SellerUser'
import { YesNo } from 'types/YesNo'

const StyledGridMessageContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))

const StyledGridHeader = styled(Grid)(() => ({
  background: white.main,
}))

const StyledRow = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(1),
}))

const StyledSpan = styled('span')({ fontWeight: 'bold' })

type RadioSelection = YesNo | ''

const validationSchema = yup.object().shape({
  TAX_ID: yup.string().required(),
  TAX_ID_INPUT: yup.string().when('TAX_ID', {
    is: (val: any) => val === YesNo.NO,
    then: yup.string().required('EIN is required'),
  }),
  LEGAL_NAME: yup.string().required(),
  LEGAL_NAME_INPUT: yup.string().when('LEGAL_NAME', {
    is: (val: any) => val === YesNo.NO,
    then: yup.string().required('Legal business name is required'),
  }),
  PRIMARY_ADDRESS: yup.string().required(),
  PRIMARY_ADDRESS_LINE_1_INPUT: yup.string().when('PRIMARY_ADDRESS', {
    is: (val: any) => val === YesNo.NO,
    then: yup.string().required('Address line 1 is required'),
  }),
  PRIMARY_ADDRESS_CITY_INPUT: yup.string().when('PRIMARY_ADDRESS', {
    is: (val: any) => val === YesNo.NO,
    then: yup.string().required('City is required'),
  }),
  PRIMARY_ADDRESS_POSTAL_CODE_INPUT: yup.string().when('PRIMARY_ADDRESS', {
    is: (val: any) => val === YesNo.NO,
    then: yup
      .string()
      .label('Zip code')
      .min(5)
      .max(9)
      .required('Zip code is required'),
  }),
  PRIMARY_ADDRESS_STATE_INPUT: yup.string().when('PRIMARY_ADDRESS', {
    is: (val: any) => val === YesNo.NO,
    then: yup.string().required('State is required'),
  }),
  PRIMARY_ADDRESS_COUNTRY_INPUT: yup.string().when('PRIMARY_ADDRESS', {
    is: (val: any) => val === YesNo.NO,
    then: yup.string().required('Country is required'),
  }),
  GUEST_SERVICES_EMAIL: yup.string().required(),
  GUEST_SERVICES_EMAIL_INPUT: yup.string().when('GUEST_SERVICES_EMAIL', {
    is: (val: any) => val === YesNo.NO,
    then: yup
      .string()
      .label('Email address')
      .email()
      .required('Email address is required'),
  }),
  HAS_GUEST_SERVICES_PHONE: yup.string().required(),
  HAS_GUEST_SERVICES_PHONE_INPUT: yup
    .string()
    .when('HAS_GUEST_SERVICES_PHONE', {
      is: (val: any) => val === YesNo.NO,
      then: yup.string().required(),
    }),
  GUEST_SERVICES_PHONE_COUNTRY_INPUT: yup
    .string()
    .when(['HAS_GUEST_SERVICES_PHONE', 'HAS_GUEST_SERVICES_PHONE_INPUT'], {
      is: (hasGuestServicesPhone: YesNo, hasGuestServicesPhoneInput: YesNo) =>
        hasGuestServicesPhone === YesNo.NO &&
        hasGuestServicesPhoneInput === YesNo.YES,
      then: yup.string().required(),
    }),
  GUEST_SERVICES_PHONE: yup
    .string()
    .when(['HAS_GUEST_SERVICES_PHONE', 'HAS_GUEST_SERVICES_PHONE_INPUT'], {
      is: (hasGuestServicesPhone: YesNo, hasGuestServicesPhoneInput: YesNo) =>
        hasGuestServicesPhone === YesNo.NO &&
        hasGuestServicesPhoneInput === YesNo.YES,
      then: yup
        .string()
        .label('Phone number')
        .length(14, 'Phone number is not a valid phone number')
        .required(),
    }),
  MANUFACTURER_OF_CONSUMER_PRODUCT: yup.string().required(),
  IMPORTER_OF_CONSUMER_PRODUCT: yup.string().required(),
  RESELLER_OF_CONSUMER_PRODUCT: yup.string().required(),
  IN_OTHER_MARKETPLACES: yup.string().required(),
  HAS_BENEFICIAL_OWNERSHIP_INFORMATION: yup.string().required(),
})

type FormValues = {
  TAX_ID: RadioSelection
  TAX_ID_INPUT: string
  LEGAL_NAME: RadioSelection
  LEGAL_NAME_INPUT: string
  PRIMARY_ADDRESS: RadioSelection
  PRIMARY_ADDRESS_LINE_1_INPUT: string
  PRIMARY_ADDRESS_LINE_2_INPUT: string
  PRIMARY_ADDRESS_CITY_INPUT: string
  PRIMARY_ADDRESS_POSTAL_CODE_INPUT: string
  PRIMARY_ADDRESS_STATE_INPUT: string
  PRIMARY_ADDRESS_COUNTRY_INPUT: string
  GUEST_SERVICES_EMAIL: RadioSelection
  GUEST_SERVICES_EMAIL_INPUT: string
  HAS_GUEST_SERVICES_PHONE: RadioSelection
  HAS_GUEST_SERVICES_PHONE_INPUT: YesNo
  GUEST_SERVICES_PHONE: string
  GUEST_SERVICES_PHONE_COUNTRY_INPUT: string
  MANUFACTURER_OF_CONSUMER_PRODUCT: RadioSelection
  IMPORTER_OF_CONSUMER_PRODUCT: RadioSelection
  RESELLER_OF_CONSUMER_PRODUCT: RadioSelection
  IN_OTHER_MARKETPLACES: RadioSelection
  HAS_BENEFICIAL_OWNERSHIP_INFORMATION: RadioSelection
}

export type ComponentProps = {
  seller: SmsSeller
  verification: SellerVerification
  hasBackButton: boolean
  onBackClick: ({ reload }: { reload: boolean }) => void
}

type Props = ComponentProps & FormikProps<FormValues>

const validateInputChange = (initialValues: FormValues, values: FormValues) => {
  if (
    values.TAX_ID === YesNo.NO &&
    values.TAX_ID_INPUT === initialValues.TAX_ID_INPUT
  ) {
    return false
  }

  if (
    values.LEGAL_NAME === YesNo.NO &&
    values.LEGAL_NAME_INPUT === initialValues.LEGAL_NAME_INPUT
  ) {
    return false
  }

  if (
    values.PRIMARY_ADDRESS === YesNo.NO &&
    values.PRIMARY_ADDRESS_LINE_1_INPUT ===
      initialValues.PRIMARY_ADDRESS_LINE_1_INPUT &&
    values.PRIMARY_ADDRESS_LINE_2_INPUT ===
      initialValues.PRIMARY_ADDRESS_LINE_2_INPUT &&
    values.PRIMARY_ADDRESS_CITY_INPUT ===
      initialValues.PRIMARY_ADDRESS_CITY_INPUT &&
    values.PRIMARY_ADDRESS_POSTAL_CODE_INPUT ===
      initialValues.PRIMARY_ADDRESS_POSTAL_CODE_INPUT &&
    values.PRIMARY_ADDRESS_STATE_INPUT ===
      initialValues.PRIMARY_ADDRESS_STATE_INPUT &&
    values.PRIMARY_ADDRESS_COUNTRY_INPUT ===
      initialValues.PRIMARY_ADDRESS_COUNTRY_INPUT
  ) {
    return false
  }

  if (
    values.GUEST_SERVICES_EMAIL === YesNo.NO &&
    values.GUEST_SERVICES_EMAIL_INPUT ===
      initialValues.GUEST_SERVICES_EMAIL_INPUT
  ) {
    return false
  }

  if (
    values.HAS_GUEST_SERVICES_PHONE === YesNo.NO &&
    values.HAS_GUEST_SERVICES_PHONE_INPUT ===
      initialValues.HAS_GUEST_SERVICES_PHONE_INPUT &&
    values.GUEST_SERVICES_PHONE === initialValues.GUEST_SERVICES_PHONE &&
    values.GUEST_SERVICES_PHONE_COUNTRY_INPUT ===
      initialValues.GUEST_SERVICES_PHONE_COUNTRY_INPUT
  ) {
    return false
  }

  if (values.MANUFACTURER_OF_CONSUMER_PRODUCT === '') {
    return false
  }

  if (values.IMPORTER_OF_CONSUMER_PRODUCT === '') {
    return false
  }

  if (values.RESELLER_OF_CONSUMER_PRODUCT === '') {
    return false
  }

  if (values.IN_OTHER_MARKETPLACES === '') {
    return false
  }

  if (values.HAS_BENEFICIAL_OWNERSHIP_INFORMATION === '') {
    return false
  }

  return true
}

export const formatResellerAndItemAttribute = (
  attributeValue: RadioSelection,
) => {
  if (attributeValue === YesNo.YES) {
    return true
  } else if (attributeValue === YesNo.NO) {
    return false
  }
}

export const InformActVerificationForm = ({
  seller,
  hasBackButton,
  onBackClick,
  isValid,
  isSubmitting,
  values,
  initialValues,
  errors,
  setFieldValue,
}: Props) => {
  const reduxDispatch = useDispatch()

  // The guest_services_phone inputs should be unset if has_guest_services_phone_input is `No`
  useEffect(() => {
    if (values.HAS_GUEST_SERVICES_PHONE_INPUT === YesNo.NO) {
      setFieldValue('GUEST_SERVICES_PHONE_COUNTRY_INPUT', '')
      setFieldValue('GUEST_SERVICES_PHONE', '')
    } else if (values.HAS_GUEST_SERVICES_PHONE_INPUT === YesNo.YES) {
      const countryInputValue =
        initialValues.GUEST_SERVICES_PHONE_COUNTRY_INPUT === ''
          ? CountryNameCode.UNITED_STATES
          : initialValues.GUEST_SERVICES_PHONE_COUNTRY_INPUT

      setFieldValue('GUEST_SERVICES_PHONE_COUNTRY_INPUT', countryInputValue)
      setFieldValue('GUEST_SERVICES_PHONE', initialValues.GUEST_SERVICES_PHONE)
    }
  }, [
    initialValues.GUEST_SERVICES_PHONE,
    initialValues.GUEST_SERVICES_PHONE_COUNTRY_INPUT,
    setFieldValue,
    values.HAS_GUEST_SERVICES_PHONE_INPUT,
    initialValues,
  ])

  const handleBackClick = () => {
    reduxDispatch(
      openDialog({
        dialogEnum: DialogEnum.CONFIRMATION_DIALOG,
        componentProps: {
          title: 'Changes will be lost',
          content: 'Would you like to proceed?',
          submitText: 'Proceed',
          onRequestSubmit: () => {
            onBackClick({ reload: false })
            reduxDispatch(closeDialog())
          },
        },
      }),
    )
  }

  return (
    <div data-testid="inform-act-form">
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">
              {seller.display_name}: Review the attributes below & indicate
              changes if any
            </Typography>
          </Grid>
        </Grid>
        <StyledGridMessageContainer container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              All partners are required to verify this information every year by
              law. Failure to comply will result in suspension from the Target
              Plus platform.
            </Typography>
          </Grid>
        </StyledGridMessageContainer>
        <Sticky enabled={true} top={112}>
          <StyledGridHeader container spacing={2}>
            <Grid item xs={3}>
              <Typography>Attribute</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>Information is accurate</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography>Changes to info (if any)</Typography>
            </Grid>
          </StyledGridHeader>
          <Divider />
        </Sticky>
        <StyledRow>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography>
                <StyledSpan>Employer Identification Number (EIN): </StyledSpan>
                {seller.tax_id}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <RadioGroupField
                required
                row={true}
                name="TAX_ID"
                options={[
                  { label: 'Yes', value: YesNo.YES },
                  { label: 'No', value: YesNo.NO },
                ]}
              />
            </Grid>
            {values.TAX_ID === YesNo.NO && (
              <Grid item xs={7}>
                <InputField
                  required
                  id="tax-id-input"
                  name="TAX_ID_INPUT"
                  label="EIN (include dashes if any)"
                />
              </Grid>
            )}
          </Grid>
        </StyledRow>
        <Divider />
        <StyledRow>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography>
                <StyledSpan>Legal Business Name: </StyledSpan>
                {seller.legal_business_name}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <RadioGroupField
                required
                row={true}
                name="LEGAL_NAME"
                options={[
                  { label: 'Yes', value: YesNo.YES },
                  { label: 'No', value: YesNo.NO },
                ]}
              />
            </Grid>
            {values.LEGAL_NAME === YesNo.NO && (
              <Grid item xs={7}>
                <InputField
                  required
                  id="legal-input"
                  name="LEGAL_NAME_INPUT"
                  label="Legal Business Name"
                />
              </Grid>
            )}
          </Grid>
        </StyledRow>
        <Divider />
        <StyledRow>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography>
                <StyledSpan>Headquarters Address</StyledSpan>
              </Typography>
              {seller.primary_address && (
                <Address address={hydrateAddress(seller.primary_address)} />
              )}
            </Grid>
            <Grid item xs={2}>
              <RadioGroupField
                required
                row={true}
                name="PRIMARY_ADDRESS"
                options={[
                  { label: 'Yes', value: YesNo.YES },
                  { label: 'No', value: YesNo.NO },
                ]}
              />
            </Grid>
            {values.PRIMARY_ADDRESS === YesNo.NO && (
              <Grid item xs={7}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputField
                      required
                      id="address-line-1"
                      name="PRIMARY_ADDRESS_LINE_1_INPUT"
                      label="Address Line 1"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      id="address-line-2"
                      name="PRIMARY_ADDRESS_LINE_2_INPUT"
                      label="Address Line 2"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      required
                      id="city"
                      name="PRIMARY_ADDRESS_CITY_INPUT"
                      label="City"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      required
                      id="postalCode"
                      name="PRIMARY_ADDRESS_POSTAL_CODE_INPUT"
                      label="Zip Code"
                    />
                  </Grid>
                  {seller.primary_address?.country_code && (
                    <>
                      <Grid item xs={12}>
                        <SelectField
                          required
                          id="state"
                          name="PRIMARY_ADDRESS_STATE_INPUT"
                          label="State"
                          options={getLocationsFromCountryCode(
                            seller.primary_address?.country_code,
                          )}
                          keyName="name"
                          valueName="abbreviation"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SelectField
                          required
                          id="country"
                          name="PRIMARY_ADDRESS_COUNTRY_INPUT"
                          label="Country"
                          options={COUNTRIES}
                          keyName="name"
                          valueName="code"
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </StyledRow>
        <Divider />
        <StyledRow>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography>
                <StyledSpan>Email Address for Guest Inquiries: </StyledSpan>
                {seller.guest_services_email}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <RadioGroupField
                required
                row={true}
                name="GUEST_SERVICES_EMAIL"
                options={[
                  { label: 'Yes', value: YesNo.YES },
                  { label: 'No', value: YesNo.NO },
                ]}
              />
            </Grid>
            {values.GUEST_SERVICES_EMAIL === YesNo.NO && (
              <Grid item xs={7}>
                <InputField
                  required
                  id="email-address-input"
                  name="GUEST_SERVICES_EMAIL_INPUT"
                  label="Email Address"
                />
              </Grid>
            )}
          </Grid>
        </StyledRow>
        <Divider />
        <StyledRow>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography>
                <StyledSpan>
                  US Phone Number Available for Guest Inquiries:{' '}
                </StyledSpan>
                {seller.has_guest_services_phone_number ? 'Yes' : 'No'}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <RadioGroupField
                required
                row={true}
                name="HAS_GUEST_SERVICES_PHONE"
                options={[
                  { label: 'Yes', value: YesNo.YES },
                  { label: 'No', value: YesNo.NO },
                ]}
              />
            </Grid>
            {values.HAS_GUEST_SERVICES_PHONE === YesNo.NO && (
              <Grid item xs={7}>
                <SelectField
                  required
                  id="has-guest-services-phone"
                  name="HAS_GUEST_SERVICES_PHONE_INPUT"
                  label="Do you have a US phone number for guest inquiry?"
                  options={[
                    {
                      name: 'Yes',
                      value: YesNo.YES,
                    },
                    {
                      name: 'No',
                      value: YesNo.NO,
                    },
                  ]}
                  keyName="name"
                  valueName="value"
                />
              </Grid>
            )}
          </Grid>
        </StyledRow>
        <StyledRow>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography>
                <StyledSpan>Phone Number: </StyledSpan>
                {seller.guest_services_phone_number?.number}
              </Typography>
            </Grid>
            {values.HAS_GUEST_SERVICES_PHONE === YesNo.NO && (
              <>
                <Grid item xs={2}></Grid>
                <Grid item xs={7}>
                  <PhoneField
                    required
                    countryDisabled
                    selectName="GUEST_SERVICES_PHONE_COUNTRY_INPUT"
                    name="GUEST_SERVICES_PHONE"
                    id="guest-services-phone-number"
                    label="Phone Number"
                    disabled={
                      isSubmitting ||
                      values.HAS_GUEST_SERVICES_PHONE_INPUT === YesNo.NO
                    }
                    country={CountryNameCode.UNITED_STATES}
                    error={errors.GUEST_SERVICES_PHONE ?? ''}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </StyledRow>
        <StyledTypography variant="h4">
          Please answer the following:
        </StyledTypography>
        <Divider />
        <StyledRow>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography>
                <StyledSpan>
                  Do you manufacture the items listed & sold by your business?
                </StyledSpan>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <RadioGroupField
                required
                row={true}
                name="MANUFACTURER_OF_CONSUMER_PRODUCT"
                options={[
                  { label: 'Yes', value: YesNo.YES },
                  { label: 'No', value: YesNo.NO },
                ]}
              />
            </Grid>
          </Grid>
        </StyledRow>
        <Divider />
        <StyledRow>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography>
                <StyledSpan>
                  Do you import items listed & sold by your business into the
                  US?
                </StyledSpan>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <RadioGroupField
                required
                row={true}
                name="IMPORTER_OF_CONSUMER_PRODUCT"
                options={[
                  { label: 'Yes', value: YesNo.YES },
                  { label: 'No', value: YesNo.NO },
                ]}
              />
            </Grid>
          </Grid>
        </StyledRow>
        <Divider />
        <StyledRow>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography>
                <StyledSpan>
                  Do you resell items from another business?
                </StyledSpan>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <RadioGroupField
                required
                row={true}
                name="RESELLER_OF_CONSUMER_PRODUCT"
                options={[
                  { label: 'Yes', value: YesNo.YES },
                  { label: 'No', value: YesNo.NO },
                ]}
              />
            </Grid>
          </Grid>
        </StyledRow>
        <Divider />
        <StyledRow>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography>
                <StyledSpan>
                  Do you advertise or sell items on other marketplaces?
                </StyledSpan>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <RadioGroupField
                required
                row={true}
                name="IN_OTHER_MARKETPLACES"
                options={[
                  { label: 'Yes', value: YesNo.YES },
                  { label: 'No', value: YesNo.NO },
                ]}
              />
            </Grid>
          </Grid>
        </StyledRow>

        <StyledRow>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography>
                <StyledSpan>
                  Has your beneficial ownership information (BOI) changed this
                  year?
                </StyledSpan>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <RadioGroupField
                required
                row={true}
                name="HAS_BENEFICIAL_OWNERSHIP_INFORMATION"
                options={[
                  { label: 'Yes', value: YesNo.YES },
                  { label: 'No', value: YesNo.NO },
                ]}
              />
            </Grid>
          </Grid>
        </StyledRow>

        <Grid container spacing={2}>
          <Grid item>
            <Button
              data-testid="submitBtn"
              color="primary"
              variant="contained"
              type="submit"
              disabled={
                !(isValid && validateInputChange(initialValues, values))
              }
            >
              Submit annual verification
            </Button>
          </Grid>
          {hasBackButton && (
            <Grid item>
              <Button
                color="primary"
                data-testid="backBtn"
                onClick={handleBackClick}
              >
                Back
              </Button>
            </Grid>
          )}
        </Grid>
      </Form>
    </div>
  )
}

export const InformActVerification = withFormik<ComponentProps, FormValues>({
  mapPropsToValues: ({ seller }) => ({
    TAX_ID: '',
    TAX_ID_INPUT: seller.tax_id ?? '',
    LEGAL_NAME: '',
    LEGAL_NAME_INPUT: seller.legal_business_name ?? '',
    PRIMARY_ADDRESS: '',
    PRIMARY_ADDRESS_LINE_1_INPUT: seller.primary_address?.address1 ?? '',
    PRIMARY_ADDRESS_LINE_2_INPUT: seller.primary_address?.address2 ?? '',
    PRIMARY_ADDRESS_CITY_INPUT: seller.primary_address?.city ?? '',
    PRIMARY_ADDRESS_POSTAL_CODE_INPUT:
      seller.primary_address?.postal_code ?? '',
    PRIMARY_ADDRESS_STATE_INPUT: seller.primary_address?.state ?? '',
    PRIMARY_ADDRESS_COUNTRY_INPUT: seller.primary_address?.country_code ?? '',
    GUEST_SERVICES_EMAIL: '',
    GUEST_SERVICES_EMAIL_INPUT: seller.guest_services_email ?? '',
    HAS_GUEST_SERVICES_PHONE: '',
    HAS_GUEST_SERVICES_PHONE_INPUT:
      seller.has_guest_services_phone_number === true ? YesNo.YES : YesNo.NO,
    GUEST_SERVICES_PHONE: seller.guest_services_phone_number?.number ?? '',
    GUEST_SERVICES_PHONE_COUNTRY_INPUT:
      seller.guest_services_phone_number?.country_code === '1'
        ? CountryNameCode.UNITED_STATES
        : '',
    MANUFACTURER_OF_CONSUMER_PRODUCT: '',
    IMPORTER_OF_CONSUMER_PRODUCT: '',
    RESELLER_OF_CONSUMER_PRODUCT: '',
    IN_OTHER_MARKETPLACES: '',
    HAS_BENEFICIAL_OWNERSHIP_INFORMATION: '',
  }),
  handleSubmit: async (values, { props }) => {
    const isYesOrNo = (val: RadioSelection) =>
      val === YesNo.YES ? YesNo.YES : YesNo.NO

    const getBool = (val: YesNo) => (val === YesNo.YES ? true : false)

    const primaryAddress: SmsAddress = {
      address1: values.PRIMARY_ADDRESS_LINE_1_INPUT,
      city: values.PRIMARY_ADDRESS_CITY_INPUT,
      state: values.PRIMARY_ADDRESS_STATE_INPUT,
      postal_code: values.PRIMARY_ADDRESS_POSTAL_CODE_INPUT,
      country_code: values.PRIMARY_ADDRESS_COUNTRY_INPUT,
      ...(values.PRIMARY_ADDRESS_LINE_2_INPUT
        ? { address2: values.PRIMARY_ADDRESS_LINE_2_INPUT }
        : {}),
    }

    const guestServicePhoneNumber: PhoneNumber =
      values.HAS_GUEST_SERVICES_PHONE_INPUT === YesNo.YES
        ? {
            number: values.GUEST_SERVICES_PHONE,
            country_code: values.GUEST_SERVICES_PHONE_COUNTRY_INPUT,
          }
        : ({} as PhoneNumber)

    const attributes: SellerVerificationAttributeUpdate[] = [
      buildVerificationAttribute(
        SellerVerificationAttributeName.TAX_ID,
        isYesOrNo(values.TAX_ID),
        values.TAX_ID_INPUT,
      ),
      buildVerificationAttribute(
        SellerVerificationAttributeName.LEGAL_NAME,
        isYesOrNo(values.LEGAL_NAME),
        values.LEGAL_NAME_INPUT,
      ),
      buildVerificationAttribute(
        SellerVerificationAttributeName.PRIMARY_ADDRESS,
        isYesOrNo(values.PRIMARY_ADDRESS),
        primaryAddress,
      ),
      buildVerificationAttribute(
        SellerVerificationAttributeName.GUEST_SERVICES_EMAIL,
        isYesOrNo(values.GUEST_SERVICES_EMAIL),
        values.GUEST_SERVICES_EMAIL_INPUT,
      ),
      buildVerificationAttribute(
        SellerVerificationAttributeName.HAS_GUEST_SERVICES_PHONE,
        isYesOrNo(values.HAS_GUEST_SERVICES_PHONE),
        getBool(values.HAS_GUEST_SERVICES_PHONE_INPUT),
      ),
      // GUEST_SERVICES_PHONE depends on HAS_GUEST_SERVICES_PHONE
      buildVerificationAttribute(
        SellerVerificationAttributeName.GUEST_SERVICES_PHONE,
        isYesOrNo(values.HAS_GUEST_SERVICES_PHONE),
        guestServicePhoneNumber,
      ),
      buildVerificationAttribute(
        SellerVerificationAttributeName.MANUFACTURER_OF_CONSUMER_PRODUCT,
        formatResellerAndItemAttribute(
          values.MANUFACTURER_OF_CONSUMER_PRODUCT,
        ) === props.seller.is_manufacturer_of_consumer_product
          ? YesNo.YES
          : YesNo.NO,
        formatResellerAndItemAttribute(values.MANUFACTURER_OF_CONSUMER_PRODUCT),
      ),
      buildVerificationAttribute(
        SellerVerificationAttributeName.IMPORTER_OF_CONSUMER_PRODUCT,
        formatResellerAndItemAttribute(values.IMPORTER_OF_CONSUMER_PRODUCT) ===
          props.seller.is_importer_of_consumer_product
          ? YesNo.YES
          : YesNo.NO,
        formatResellerAndItemAttribute(values.IMPORTER_OF_CONSUMER_PRODUCT),
      ),
      buildVerificationAttribute(
        SellerVerificationAttributeName.RESELLER_OF_CONSUMER_PRODUCT,
        formatResellerAndItemAttribute(values.RESELLER_OF_CONSUMER_PRODUCT) ===
          props.seller.is_reseller_of_consumer_product
          ? YesNo.YES
          : YesNo.NO,
        formatResellerAndItemAttribute(values.RESELLER_OF_CONSUMER_PRODUCT),
      ),
      buildVerificationAttribute(
        SellerVerificationAttributeName.IN_OTHER_MARKETPLACES,
        formatResellerAndItemAttribute(values.IN_OTHER_MARKETPLACES) ===
          props.seller.in_other_marketplaces
          ? YesNo.YES
          : YesNo.NO,
        formatResellerAndItemAttribute(values.IN_OTHER_MARKETPLACES),
      ),
      buildVerificationAttribute(
        SellerVerificationAttributeName.HAS_BENEFICIAL_OWNERSHIP_INFORMATION,
        formatResellerAndItemAttribute(
          values.HAS_BENEFICIAL_OWNERSHIP_INFORMATION,
        ) === props.seller.has_beneficial_ownership_information
          ? YesNo.YES
          : YesNo.NO,
        formatResellerAndItemAttribute(
          values.HAS_BENEFICIAL_OWNERSHIP_INFORMATION,
        ),
      ),
    ]

    await updateSellerVerification(
      props.seller.id,
      props.verification.id,
      attributes,
    )

    props.onBackClick({ reload: true })
  },
  enableReinitialize: true,
  validationSchema,
})(InformActVerificationForm)

export default InformActVerification
