import apiConfig from './apiConfig'

const oauthProviderConfig = {
  authorizationUrl: `${apiConfig.auth.authHost}${apiConfig.auth.authPath}`,
  logoutUrl: `${apiConfig.auth.logoutHost}${apiConfig.auth.logoutPath}`,
  clientId: apiConfig.auth.clientId,
  nonce: apiConfig.auth.nonce,
  redirectUri: apiConfig.auth.redirectUri,
  responseType: apiConfig.auth.responseType,
  scope: apiConfig.auth.scope,
  storageType: apiConfig.auth.storageType,
  tokenType: apiConfig.auth.tokenType,
}

export default oauthProviderConfig
