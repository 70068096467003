import Firefly, { CustomInteraction, Json } from 'firefly-sdk-ts'
import apiConfig from 'config/apiConfig'

import { FireflyEvent } from 'types/FireflyInsights'
import { Breadcrumb } from 'types/Layout'

const eventManagerConfig = {
  url: apiConfig.firefly,
  apiKey: apiConfig.apiGatewayKey,
  eventManagerKey: 'internalapp',
  persistMethod: 'localStorage',
  retries: 1,
}

let globalData: Json = {}
let loadTimestamp: number | undefined

export const resetGlobalData = () => {
  globalData = {}
  loadTimestamp = undefined
}

export const initializeFireFly = () => {
  Firefly.registerEventManagerWithConfig(eventManagerConfig)
  globalData.application = {
    name: 'Target Plus Portal',
  }
}
export const trackTaskTime = (
  startTime: number,
  eventType: FireflyEvent,
  key: string,
  value: string | number,
  location?: string,
  metrics?: string[],
) => {
  !metrics && (metrics = [])
  const end = new Date().getTime()
  const difference = ((end - startTime) / 1000).toString()
  metrics?.push(difference)

  trackCustomEvent(eventType, key, value, location, metrics)
}

export const setPageData = (title: string | Breadcrumb[]) => {
  globalData.view = {
    name:
      typeof title === 'string'
        ? title
        : title.map((breadcrumb) => breadcrumb.text).join(' > '),
  }

  const now = new Date().getTime()

  // push previous loadTimestamp and update
  globalData.customMetrics = {
    metric1: loadTimestamp ? ((now - loadTimestamp) / 1000).toString() : '',
  }
  loadTimestamp = now

  // clear out referrers, as they may contain access tokens
  if (globalData.browser) {
    Object.defineProperty(globalData.browser, 'referrer', {
      get: function () {
        return ''
      },
    })
  }

  if (globalData.appState) {
    Object.defineProperty(globalData.appState, 'referrer', {
      get: function () {
        return ''
      },
    })
  }
  Firefly.setGlobalDataLayer(globalData, eventManagerConfig.persistMethod)
  trackPageView(FireflyEvent.PAGE_LOAD)
}

export const setUserData = ({
  id,
  segment,
  external,
}: {
  id: string
  segment: string
  external: boolean
}) => {
  globalData.user = {
    id,
    segment,
    customDimension1: external ? 'External' : 'Internal',
  }
  Firefly.setGlobalDataLayer(globalData, eventManagerConfig.persistMethod)
}

export const setUserSeller = ({ name }: { name: string }) => {
  globalData.user = {
    ...globalData.user,
    customDimension2: name,
  }
  Firefly.setGlobalDataLayer(globalData, eventManagerConfig.persistMethod)
}

export const trackPageView = (eventType?: FireflyEvent) => {
  Firefly.getEventManager(eventManagerConfig).addEvent({
    event: {
      type: eventType,
    },
  })
}

export function trackCustomEvent(
  eventType: FireflyEvent,
  key: string,
  value: string | number,
  location?: string,
  metrics?: string[],
) {
  const data = {
    key,
    value: value.toString(),
  } as CustomInteraction

  if (location) {
    data.location = location
  }

  let customEvent = {
    event: { type: eventType },
    customInteraction: data,
  } as Json

  const metricsMap = new Map<string, string>()

  metrics?.map((metric, index) =>
    metricsMap.set('metric' + (index + 1), metric),
  )

  if (metrics) {
    customEvent = {
      ...customEvent,
      customMetrics: {
        metric1: metricsMap.get('metric1'),
        metric2: metricsMap.get('metric2'),
        metric3: metricsMap.get('metric3'),
      },
    }
  }

  Firefly.getEventManager(eventManagerConfig).addEvent(customEvent)
}

export function startTask(taskName: string) {
  Firefly.getEventManager(eventManagerConfig).addTaskInitEvent({
    task: { name: taskName },
  })
}

export function endTask(taskName: string) {
  Firefly.getEventManager(eventManagerConfig).addTaskSuccessEvent({
    task: { name: taskName },
  })
}

export function failTask(taskName: string) {
  Firefly.getEventManager(eventManagerConfig).addTaskFailEvent({
    task: { name: taskName },
  })
}

export function cancelTask(taskName: string) {
  Firefly.getEventManager(eventManagerConfig).addTaskCancelEvent({
    task: { name: taskName },
  })
}
