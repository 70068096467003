import { useDispatch } from 'react-redux'

import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import { closeDialog } from 'store/dialog/actionCreator'
import { DiversitySurveyPages } from 'types/PartnerDiversity'

export type Props = {
  page: number
  totalPages: number
  disabled: boolean
  submitText: string
  values?: any
  onPrevious?: (values: Partial<DiversitySurveyPages>) => void
  onSubmit: () => void
}

export const Actions = ({
  page,
  totalPages,
  values,
  onPrevious,
  onSubmit,
  disabled,
  submitText,
}: Props) => {
  const dispatch = useDispatch()

  const handlePrevious = () => {
    if (onPrevious) {
      onPrevious(values)
    }
  }

  const handleClose = () => {
    dispatch(closeDialog())
  }
  return (
    <DialogActions
      sx={{
        paddingLeft: 2.5,
        justifyContent: 'space-between',
      }}
    >
      <div>
        {page > 1 && (
          <Button color="primary" onClick={handlePrevious}>
            <ArrowBackIcon /> Back to Previous
          </Button>
        )}
      </div>

      <div>
        {page} of {totalPages}
      </div>
      <div>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          data-testid="dialog-submit-button"
          variant="contained"
          color="primary"
          disabled={disabled}
          onClick={onSubmit}
        >
          {submitText}
        </Button>
      </div>
    </DialogActions>
  )
}

export default Actions
