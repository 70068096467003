import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Grid from '@mui/material/Grid'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import { TaxonomyTypeahead } from 'components/common/Typeahead'

import { GenerateReportRequest } from 'services/reports'

import { closeDialog } from 'store/dialog/actionCreator'

import Attribute, { ITEM_TYPE } from 'types/Attribute'
import { ReportType } from 'types/Report'
import { currentSellerId } from 'store/selectors'

export interface Props {
  isOpen: boolean
  reportType: ReportType
  onRequestSubmit: (reportParams: GenerateReportRequest) => void
}

export const ItemAttributesAndValuesDialog = ({
  isOpen,
  reportType,
  onRequestSubmit,
}: Props) => {
  const dispatch = useDispatch()
  const sellerId = useSelector(currentSellerId)

  const [pending, setPending] = useState(false)
  const [itemType, setItemType] = useState<Attribute>()

  const title = pending
    ? 'Generating... Please wait'
    : 'Generate Attributes and Values Report'

  const handleItemTypeChange = (value: Nullable<Attribute>) => {
    if (!value) return
    setItemType(value)
  }

  const handleSubmit = async () => {
    if (!itemType) return

    setPending(true)

    try {
      onRequestSubmit({
        sellerId,
        type: reportType,
        parameters: {
          item_type_id: itemType.id,
        },
        reportName: itemType.name,
        format: 'EXCEL',
      })
    } catch (e) {
      console.error(`Error generating report for partner ${sellerId}: ${e}`)
    } finally {
      setPending(false)
      dispatch(closeDialog())
    }
  }

  return (
    <DialogContainer
      title={title}
      isOpen={isOpen}
      onSubmit={handleSubmit}
      isSubmitDisabled={!itemType}
      submitButtonText="Generate"
      isPending={pending}
      disableScroll
    >
      <Grid container>
        <Grid item xs={12}>
          <TaxonomyTypeahead
            searchType={ITEM_TYPE}
            placeholder="Item Type"
            onChange={handleItemTypeChange}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export default ItemAttributesAndValuesDialog
