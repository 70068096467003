import FeatureWrapper from 'components/common/FeatureWrapper'
import HeaderTitle from 'components/common/HeaderTitle'
import { ALL_ENVIRONMENTS } from 'config/apiConfig'

import { USER_ROLE_ADMIN } from 'services/roles'

import RatingCard from './RatingCard'

export const RatingsAndReviewPage = () => {
  return (
    <FeatureWrapper
      conditions={[
        {
          environments: ALL_ENVIRONMENTS,
          roles: [USER_ROLE_ADMIN],
        },
      ]}
    >
      <div>
        <HeaderTitle title="Ratings & Reviews" />
        <RatingCard />
      </div>
    </FeatureWrapper>
  )
}

export default RatingsAndReviewPage
