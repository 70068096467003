import { useState } from 'react'
import { Form, Grid } from '@enterprise-ui/canvas-ui-react'
import '@enterprise-ui/canvas-ui-css-datepicker'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'

import startCase from 'lodash/fp/startCase'
import {
  DATE_DISPLAY_FORMAT,
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
  formatDate,
} from 'services/dateService'

import { UpdatedPromotion } from 'types/Promotion'
import PromotionStatusChip from '../PromotionStatusChip'
import ProductTypes from './ProductTypes'
import {
  StyledFinalStatusRadio,
  StyledPromoDetailsGridContainer,
  StyledPartnerPromotionDetailsCard,
  StyledPromotionDetailsHeading,
} from '../styles'

export const PartnerPromotionDetailsForm = ({
  promotion,
}: {
  promotion: UpdatedPromotion
}) => {
  const [promoDescription, setPromoDescription] = useState<string | undefined>(
    promotion.promotion_description,
  )
  const [participationDeadline, setParticipationDeadline] = useState<
    Date | string
  >(formatDate(promotion.participation_end_date, DATE_DISPLAY_FORMAT) ?? '')
  const [promoNotes, setPromoNotes] = useState<string>(
    promotion.promotion_notes ?? '',
  )
  const [itemTypeRestrictions, setItemTypeRestrictions] = useState<string>(
    promotion.item_type_restrictions ? 'yes' : 'no',
  )
  const [categoryManagerApproval, setCategoryManagerApproval] =
    useState<boolean>(
      promotion.category_manager_approval_status === 'APPROVED' ? true : false,
    )
  const [ppcoeAgreement, setPpcoeAgreement] = useState<string>(
    promotion.ppcoe_agreement_approval_status ?? '',
  )
  const [siteExperienceApproval, setSiteExperienceApproval] = useState<string>(
    promotion.site_experience_approval_status ?? '',
  )
  const [internalReviewDetails, setInternalReviewDetails] = useState<string>(
    promotion.internal_review_notes ?? '',
  )
  const [finalStatus, setFinalStatus] = useState<string>(promotion.status)
  const [allowedProductSubtypes, setAllowedProductSubtypes] = useState(
    promotion.allowed_product_sub_types,
  )

  const formatDiscount = (discountType: string, discountValue: number) => {
    if (!discountType) return
    if (discountType === 'PercentageOff') return `${discountValue}%`
    else if (discountType === 'FixedPrice' || discountType === 'DollarOff')
      return `$${discountValue}`
  }
  return (
    <Form>
      <StyledPartnerPromotionDetailsCard>
        <StyledPromoDetailsGridContainer
          className="hc-pa-normal"
          spacing="dense"
        >
          <Grid.Item xs={12}>
            <Grid.Container spacing="dense" justify="space-between">
              <Grid.Item>
                <StyledPromotionDetailsHeading size={4}>
                  Partner Promotion Details
                </StyledPromotionDetailsHeading>
              </Grid.Item>
              <Grid.Item className="hc-mr-normal">
                <PromotionStatusChip
                  status={promotion?.status ?? ''}
                  upstreamStatus={promotion?.upstream_status}
                  isInline
                />
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>

          <Grid.Item xs={6}>
            <Form.Field
              label="Promo Description *"
              type="text"
              onChange={(event: any) => setPromoDescription(event.target.value)}
              placeholder="Enter Description"
              value={promoDescription}
            />
          </Grid.Item>
          <Grid.Item className="hc-mt-normal" xs={6}>
            <div>
              <strong>Discount: </strong>
            </div>
            <div data-testid="discount">
              {startCase(promotion?.details?.[0]?.discount_type)}{' '}
              {formatDiscount(
                promotion?.details?.[0]?.discount_type ?? '',
                promotion?.details[0]?.discount_value ?? 0,
              ) ?? 'None'}
            </div>
          </Grid.Item>
          <Grid.Item xs={6}>
            <DatePicker
              id="participation-deadline"
              disableDates={{ after: promotion?.start_date }}
              onUpdate={(_id: any, value: any) =>
                setParticipationDeadline(value)
              }
              placeholder="Select Date"
              label="Participation Deadline *"
              value={participationDeadline}
            />
          </Grid.Item>
          <Grid.Item className="hc-mt-normal" xs={6}>
            <div>
              <strong>Start Date: </strong>
              {formatDate(
                promotion?.start_date as any,
                DATE_FORMAT_MONTH_DAY_YEAR_TIME,
              )}
            </div>
            <div>
              <strong>End Date: </strong>
              {formatDate(
                promotion?.end_date as any,
                DATE_FORMAT_MONTH_DAY_YEAR_TIME,
              )}
            </div>
          </Grid.Item>

          <Grid.Item xs={12}>
            <Form.Field
              data-testid="promo-notes"
              label="Promo Notes *"
              onChange={(event: any) => setPromoNotes(event.target.value)}
              placeholder="Enter Notes"
              type="textarea"
              value={promoNotes}
            />
          </Grid.Item>
          <Grid.Item xs={12}>
            <Form.Field
              data-testid="item-type-restrictions"
              type="radio"
              label="Item Type Restrictions *"
              onUpdate={(_id: any, value: string) =>
                setItemTypeRestrictions(value)
              }
              options={[
                { value: 'yes', label: 'Yes', disabled: false },
                { value: 'no', label: 'No', disabled: false },
              ]}
              value={itemTypeRestrictions}
            />
          </Grid.Item>
          <Grid.Item xs={12}>
            <ProductTypes
              allowedProductSubtypes={allowedProductSubtypes}
              setAllowedProductSubtypes={setAllowedProductSubtypes}
            />
          </Grid.Item>
        </StyledPromoDetailsGridContainer>
      </StyledPartnerPromotionDetailsCard>
      <StyledPartnerPromotionDetailsCard className="hc-mt-normal">
        <StyledPromoDetailsGridContainer
          className="hc-pa-normal"
          spacing="dense"
        >
          <Grid.Item xs={12}>
            <StyledPromotionDetailsHeading size={4}>
              Approval Process
            </StyledPromotionDetailsHeading>
          </Grid.Item>
          <Grid.Item xs={6}>
            <p>Category Management Approval *</p>
            <Form.Field
              checked={categoryManagerApproval}
              data-testid="category-manager-approval"
              onChange={() =>
                setCategoryManagerApproval(!categoryManagerApproval)
              }
              label="Approved"
              type="checkbox"
            />
          </Grid.Item>
          <Grid.Item xs={6}>
            <Form.Field
              type="radio"
              label="PPCOE Agreement *"
              onUpdate={(_id: any, value: string) => setPpcoeAgreement(value)}
              options={[
                { value: 'APPROVED', label: 'Approved', disabled: false },
                {
                  value: 'NOT_REQUIRED',
                  label: 'Not Required',
                  disabled: false,
                },
              ]}
              value={ppcoeAgreement}
            />
          </Grid.Item>
          <Grid.Item xs={12}>
            <Form.Field
              type="radio"
              label="Site Experience Approval *"
              onUpdate={(_id: any, value: string) =>
                setSiteExperienceApproval(value)
              }
              options={[
                { value: 'APPROVED', label: 'Approved', disabled: false },
                {
                  value: 'NOT_REQUIRED',
                  label: 'Not Required',
                  disabled: false,
                },
              ]}
              value={siteExperienceApproval}
            />
          </Grid.Item>
          <Grid.Item xs={12}>
            <Form.Field
              data-testid="internal-review-details"
              onChange={(event: any) =>
                setInternalReviewDetails(event.target.value)
              }
              label="Internal Review Details"
              placeholder="Enter Text"
              type="textarea"
              value={internalReviewDetails}
            />
          </Grid.Item>
        </StyledPromoDetailsGridContainer>
      </StyledPartnerPromotionDetailsCard>
      <StyledFinalStatusRadio>
        <Form.Field
          className="hc-pa-normal"
          onUpdate={(_id: any, value: string) => setFinalStatus(value)}
          type="radio"
          label="Final Status *"
          options={[
            {
              value: 'PARTNER_VISIBLE',
              label: 'Visible to Partners',
              disabled: false,
            },
            {
              value: 'PARTNER_NOT_VISIBLE',
              label: 'Not Visible to Partners',
              disabled: false,
            },
          ]}
          value={finalStatus}
        />
      </StyledFinalStatusRadio>
    </Form>
  )
}

export default PartnerPromotionDetailsForm
