import { Grid, Typography } from '@mui/material'
import { StyledReportProgress } from './styles'
import ReportProgress from 'components/common/ReportCard/ReportProgress'
import { Report, ReportStatus } from 'types/Report'
import {
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
  formatDate,
} from 'services/dateService'

interface Props {
  report: Report
}

export const GenerationBar = ({ report }: Props) => {
  return report.status === ReportStatus.PENDING ||
    report.status === ReportStatus.PROCESSING ? (
    <Grid item xs={8} id={report.id}>
      <Typography data-testid="generating-text">Processing...</Typography>
      <StyledReportProgress>
        <ReportProgress />
      </StyledReportProgress>
    </Grid>
  ) : (
    <Typography>
      {formatDate(report.last_modified, DATE_FORMAT_MONTH_DAY_YEAR_TIME)}
    </Typography>
  )
}

export default GenerationBar
