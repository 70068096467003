import { EnumerationDict } from 'types/Enumeration'

export const FETCH_ENUMERATIONS = 'FETCH_ENUMERATIONS'
export const FETCH_ENUMERATIONS_DONE = 'FETCH_ENUMERATIONS_DONE'
export const FETCH_ENUMERATIONS_ERROR = 'FETCH_ENUMERATIONS_ERROR'

export const fetchEnumerations = () =>
  ({
    type: FETCH_ENUMERATIONS,
  }) as const

export const fetchEnumerationsDone = (enumerations: EnumerationDict) =>
  ({
    type: FETCH_ENUMERATIONS_DONE,
    payload: {
      enumerations,
    },
  }) as const

export const fetchEnumerationsError = () =>
  ({
    type: FETCH_ENUMERATIONS_ERROR,
  }) as const

export type EnumerationActions = ReturnType<
  | typeof fetchEnumerations
  | typeof fetchEnumerationsDone
  | typeof fetchEnumerationsError
>
