import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import Grid from '@mui/material/Grid'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import ValidationErrorText from 'components/common/ValidationErrorText'

import { validationHandler } from 'services/validation'
import { timezoneCode } from 'services/timezoneCode'

import { isSellersPending } from 'store/selectors'
import { editSellerDistributionCenter } from 'store/seller/actionCreators'

import { WorkingHours, SmsDistributionCenter } from 'types/Seller'
import { Validation } from 'types/Validation'

import DaysOfOperationTable, {
  getDayOptions,
  validateDayOptions,
} from './DaysOfOperationTable'

export interface DayOption {
  checked: boolean
  day: string
  openTime: string
  closeTime: string
  validation: Validation
}

interface Props {
  isOpen: boolean
  distributionCenter: Partial<SmsDistributionCenter>
}

export const validationSchema = yup.object().shape({
  working_hours: yup
    .array()
    .label('Working hours')
    .required('Must specify at least one set of hours'),
})

const EditHoursOfOperation = ({ distributionCenter, isOpen }: Props) => {
  const dispatch = useDispatch()

  const isPending = useSelector(isSellersPending)

  const [dayOptions, setDayOptions] = React.useState<DayOption[]>(
    getDayOptions(distributionCenter),
  )
  const [validation, setValidation] = React.useState<Validation>()

  const updateDayOptions = (newOption: DayOption) => {
    const newOptions = dayOptions.map((option) => {
      if (option.day.toUpperCase() === newOption.day.toUpperCase()) {
        return newOption
      }

      return option
    })

    setDayOptions(newOptions)
  }

  const handleSubmit = () => {
    const { isDaysValid, validatedDayOptions, workingHours } =
      validateDayOptions(dayOptions)

    setDayOptions(validatedDayOptions)

    if (!isDaysValid) {
      return
    }

    const { validation: formValidation, isValid } = validationHandler(
      validationSchema,
      {
        dayOptions,
        working_hours: workingHours,
      },
    )

    setValidation(formValidation)

    if (isValid) {
      const updatedDistributionCenter = {
        ...distributionCenter,
        days_of_operation: {
          days_closed: distributionCenter.days_of_operation?.days_closed ?? [],
          working_hours: workingHours as WorkingHours[],
        },
      }

      dispatch(editSellerDistributionCenter(updatedDistributionCenter))
    }
  }

  return (
    <DialogContainer
      title="Edit Shipping Hours"
      isOpen={isOpen}
      isPending={isPending}
      onSubmit={handleSubmit}
      subtitle={`Distribution Center Time Zone: ${timezoneCode(
        distributionCenter.timezone,
      )}`}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DaysOfOperationTable
            dayOptions={dayOptions}
            onDayUpdate={updateDayOptions}
            isPending={isPending}
          />
          {validation && (
            <ValidationErrorText errors={validation} field="working_hours" />
          )}
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export default EditHoursOfOperation
