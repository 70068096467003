import Typography from '@mui/material/Typography'

import formatPhoneNumber from 'services/formatPhoneNumber'
import { PhoneNumber as ContactPhoneNumber } from 'types/SellerUser'

interface Props {
  phone: ContactPhoneNumber | undefined
}

export const PhoneNumber = ({ phone }: Props) => {
  if (!phone) return null

  const formattedNumber = `+${phone.country_code} ${formatPhoneNumber(
    phone.number,
  )}`

  const extension = phone.extension_number
    ? `Ext: ${phone.extension_number}`
    : ''

  const contactNumber = `${formattedNumber} ${extension}`.trim()
  return (
    <Typography data-testid="contact-phone-number" component="span">
      {contactNumber}
    </Typography>
  )
}

export default PhoneNumber
