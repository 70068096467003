import { call, put, takeEvery, select } from 'redux-saga/effects'

import { getNextProductForReview } from 'services/itemReview'
import { Direction } from 'services/pageableHelper'
import { lockMarketplaceProduct } from 'services/sellerProducts'
import {
  FETCH_NEXT_ITEM,
  FetchNextItemAction,
  fetchNextItemDone,
  setItemReviewPending,
  setParentId,
} from 'store/itemReview/actionCreators'
import {
  itemReviewParentId,
  itemReviewSkipItems,
  itemReviewSkipParents,
} from 'store/selectors'

import { ProductReviewData } from 'types/Item'

export function* fetchProductReviewDataSaga({ params }: FetchNextItemAction) {
  try {
    yield put(setItemReviewPending(true))

    let searchParams = params.searchParams
    const parentId: string = yield select(itemReviewParentId)
    const skipItems: string[] = yield select(itemReviewSkipItems)
    const skippedParents: string[] = yield select(itemReviewSkipParents)
    if (parentId) {
      searchParams = {
        ...searchParams,
        parent_product_id: parentId,
      }
    }

    const sortParams = [
      { orderBy: 'relationship_type', direction: Direction.ASC },

      {
        orderBy: searchParams.orderBy
          ? searchParams.orderBy
          : 'relationship_type',
        direction: searchParams.direction
          ? searchParams.direction
          : Direction.ASC,
      },
    ]
    let data: ProductReviewData = yield call(getNextProductForReview, {
      skipItems,
      skippedParents,
      searchParams,
      sortParams,
    })

    if (!data && parentId) {
      // we must have reached the last child so go back to original search
      yield put(setParentId(undefined))
      data = yield call(getNextProductForReview, {
        skippedParents,
        skipItems,
        searchParams: params.searchParams,
        sortParams,
      })
    }

    yield put(fetchNextItemDone(data))
    yield call(lockMarketplaceProduct, data.reviewProduct.product_id)
  } catch (e) {
    console.error(`Failed to get next product for review: ${e}`)
  } finally {
    yield put(setItemReviewPending(false))
  }
}

export function* itemReviewSagas() {
  yield takeEvery(FETCH_NEXT_ITEM, fetchProductReviewDataSaga)
}
