import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import styled from '@emotion/styled'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import GetAppIcon from '@mui/icons-material/GetApp'

import { DialogEnum } from 'components/common/Dialog'
import FullScreenDialogContainer from 'components/common/Dialog/FullScreenDialogContainer'
import Text from 'components/common/Text'
import DateRangeSelector from 'components/common/DateRangePicker/DateRangeSelector'
import {
  DateRange,
  getRangeConfig,
  generateOrderDefectMetricRange,
  Range,
} from 'components/common/DateRangePicker/rangeConfig'
import EnhancedTable from 'components/common/EnhancedTable'

import {
  startOfDay,
  getDateOneYearAgo,
  formatDate,
  DATE_PICKER_FORMAT,
} from 'services/dateService'
import { isOneOfUserRoles } from 'services/authorization'
import { USER_ROLE_ADMIN, USER_ROLE_OPS } from 'services/roles'
import { FireflyEvent } from 'types/FireflyInsights'
import { trackCustomEvent } from 'services/fireflyInsights'
import { getGreenFieldMetricsForOrderDefectRateTable } from 'services/biReporting'

import { closeDialog, openDialog } from 'store/dialog/actionCreator'
import {
  currentSellerId,
  currentSellerVmmId,
  getMemberOf,
} from 'store/selectors'

import {
  getOrderDefectMetricTableQueryKeys,
  ORDER_DEFECT_METRIC_TABLE,
} from '../queries'

import { OrderDefectMetricType } from 'types/VendorStats'
import { BiReportingCards } from 'types/BiReporting'

import ApplyOverridesAside from './ApplyOverridesAside'
import RateOverTimeCard, { maxDate } from '../RateOverTimeCard'

const StyledHeader = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))

const StyledCallout = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRight: `1px solid ${theme.palette.grey[300]}`,
}))

const StyledTabContainer = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  margin: theme.spacing(0, -5, 4),
}))

const odrConfig = {
  [OrderDefectMetricType.CANCELLATION_RATE]: {
    title: 'Order Cancellation Details',
    label: 'Order Cancellations',
    description:
      'Order cancellations consist of orders that have a cancellation code attributed to a partner defect.',
    subtitle: 'Order Cancellations Over Time',
    graphCardId: BiReportingCards.CANCEL_RATE_LINE_CHART,
    rateCardId: BiReportingCards.CANCEL_RATE,
    tableCardId: BiReportingCards.CANCEL_RATE_TABLE,
    overrideCardId: BiReportingCards.CANCEL_RATE_OVERRIDE,
    rateKey: 'cancel_rate',
  },
  [OrderDefectMetricType.LATE_DELIVERY]: {
    title: 'Late Delivery Details',
    label: 'Late Deliveries',
    description:
      'Late deliveries consist of orders which are not received by the guest until after the estimated delivery date.',
    subtitle: 'Late Deliveries Over Time',
    graphCardId: BiReportingCards.LATE_DELIVERY_LINE_CHART,
    rateCardId: BiReportingCards.LATE_DELIVERY,
    tableCardId: BiReportingCards.LATE_DELIVERY_TABLE,
    overrideCardId: BiReportingCards.LATE_DELIVERY_OVERRIDE,
    rateKey: 'late_rate',
  },
  [OrderDefectMetricType.RETURN_RATE]: {
    title: 'Defective Return Details',
    label: 'Defective Returns',
    description:
      'Returns consist of orders which have a reason code attributed to a partner defect.',
    subtitle: 'Defective Returns Over Time',
    graphCardId: BiReportingCards.RETURN_RATE_LINE_CHART,
    rateCardId: BiReportingCards.RETURN_RATE,
    tableCardId: BiReportingCards.RETURN_RATE_TABLE,
    overrideCardId: BiReportingCards.RETURN_RATE_OVERRIDE,
    rateKey: 'return_rate',
  },
  [OrderDefectMetricType.CONTACT_RATE]: {
    title: 'Defective Contact Details',
    label: 'Defective Contacts',
    description:
      'Contacts consist of orders where the guest contact Target Guest Support for assistance.',
    subtitle: 'Defective Contacts Over Time',
    graphCardId: BiReportingCards.CONTACT_RATE_LINE_CHART,
    rateCardId: BiReportingCards.CONTACT_RATE,
    tableCardId: BiReportingCards.CONTACT_RATE_TABLE,
    overrideCardId: BiReportingCards.CONTACT_RATE_OVERRIDE,
    rateKey: 'contact_rate',
  },
}

const dateRangeOptions = generateOrderDefectMetricRange()
export interface Props {
  isOpen: boolean
  type: OrderDefectMetricType
  defectRate: number
}

enum TabValue {
  RATE_OVER_TIME = 'RATE_OVER_TIME',
  OVERRIDE = 'OVERRIDE',
}

const tableDefault = {
  page: 1,
}

export const OrderDefectMetrics = ({ isOpen, type, defectRate }: Props) => {
  const {
    title,
    label,
    description,
    subtitle,
    graphCardId,
    rateCardId,
    tableCardId,
    overrideCardId,
    rateKey,
  } = odrConfig[type]

  const dateOneYearAgo = startOfDay(getDateOneYearAgo())

  const last90Days = getRangeConfig(
    DateRange.LAST_90_DAYS,
    undefined,
    'odrMetric',
  )
  const defaultRange = {
    from: startOfDay(last90Days.from),
    to: startOfDay(last90Days.to),
  }

  const dispatch = useDispatch()
  const sellerId = useSelector(currentSellerId)
  const vmmId = useSelector(currentSellerVmmId)
  const memberOf = useSelector(getMemberOf)

  const [tab, setTab] = React.useState<TabValue>(TabValue.RATE_OVER_TIME)
  const [isAsideOpen, setIsAsideOpen] = React.useState<boolean>(false)
  const [range, setRange] = React.useState<Range>(defaultRange)
  const [isCustomRange, setIsCustomRange] = React.useState(false)

  const [tablePage, setTablePage] = React.useState(tableDefault.page)

  const cardId = tab === TabValue.RATE_OVER_TIME ? tableCardId : overrideCardId
  const trackingValue = tab === TabValue.RATE_OVER_TIME ? 'detail' : 'overrides'

  const queryClient = useQueryClient()
  const {
    data: queryData = {
      data: [],
      fieldList: [],
      total: 0,
    },
    isLoading,
    isError,
  } = useQuery(
    getOrderDefectMetricTableQueryKeys({
      sellerId,
      vmmId,
      cardId,
      range,
      isCustomRange,
      type,
      trackingValue,
    }),
    () =>
      getGreenFieldMetricsForOrderDefectRateTable({
        sellerId,
        vmmId,
        cardId,
        range,
        isCustomRange,
        type,
        trackingValue,
      }),
  )

  const handleChange = (_event: any, newValue: TabValue) => {
    setTab(newValue)
    setTablePage(tableDefault.page)
  }

  const handleDateChange = (
    newFrom: Date,
    newTo: Date,
    isValid: boolean,
    selectedIndex: number,
  ) => {
    if (!isValid) return
    const isCustom = selectedIndex === 4

    onRequestDateRangeChange({
      from: newFrom,
      to: newTo,
      isCustom,
    })
  }

  const onRequestDateRangeChange = ({
    from,
    to,
    isCustom,
  }: {
    from: Date
    to: Date
    isCustom: boolean
  }) => {
    setRange({ from, to })
    setIsCustomRange(isCustom)
  }

  const generateOdrReport = () => {
    if (!range?.from || !range?.to) {
      return
    }

    const startDate = formatDate(range.from, DATE_PICKER_FORMAT)
    const endDate = formatDate(range.to, DATE_PICKER_FORMAT)
    trackCustomEvent(FireflyEvent.ODR_USER_ACTION, type, 'download report')
    dispatch(
      openDialog({
        dialogEnum: DialogEnum.ORDER_DEFECT_RATE_GENERATE_DIALOG,
        componentProps: {
          sellerId,
          metricType: [type],
          startDate,
          endDate,
          timeframeLabel: `${label} ${startDate} to ${endDate}`,
        },
      }),
    )
  }

  const onOpenAside = () => {
    if (isAsideOpen) {
      return
    }

    trackCustomEvent(FireflyEvent.ODR_USER_ACTION, type, 'apply overrides')
    setIsAsideOpen(true)
  }

  const onRequestClose = () => {
    dispatch(closeDialog())
  }

  const getTableDataByPage = (data: any[], pageNumber: number) => {
    return data.slice((pageNumber - 1) * 20, pageNumber * 20)
  }

  const tableChangePage = (pageNumber: number) => {
    setTablePage(pageNumber)
  }

  const allowOverride = isOneOfUserRoles(memberOf, [
    USER_ROLE_OPS,
    USER_ROLE_ADMIN,
  ])

  if (!vmmId) {
    return null
  }

  return (
    <FullScreenDialogContainer
      isOpen={isOpen}
      title={title}
      onRequestClose={onRequestClose}
      aside={
        <ApplyOverridesAside
          type={type}
          onCancel={() => setIsAsideOpen(false)}
          onSubmit={() => {
            queryClient.invalidateQueries([ORDER_DEFECT_METRIC_TABLE])
            setTablePage(tableDefault.page)
          }}
        />
      }
      asideTitle="Apply Override(s)"
      isAsideOpen={isAsideOpen}
    >
      <StyledHeader container spacing={2}>
        <StyledCallout item sm={12} md={2}>
          <Typography variant="body1">Last 90 Days</Typography>
          <Typography variant="h2">{defectRate}%</Typography>
        </StyledCallout>
        <Grid item sm={12} md={8}>
          <Typography>{description} </Typography>
        </Grid>
        <Grid item sm={12} md={2}>
          {allowOverride && (
            <Button color="primary" variant="contained" onClick={onOpenAside}>
              Apply Overrides
            </Button>
          )}
        </Grid>
      </StyledHeader>
      <StyledTabContainer
        value={tab}
        onChange={handleChange}
        aria-label="date range for performance"
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab
          data-testid="tab-1"
          value={TabValue.RATE_OVER_TIME}
          label={title}
        />
        <Tab
          data-testid="tab-2"
          value={TabValue.OVERRIDE}
          label="All Overrides"
        />
      </StyledTabContainer>
      {sellerId && tab !== TabValue.OVERRIDE && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RateOverTimeCard
              title={subtitle}
              sellerId={sellerId}
              vmmId={vmmId}
              cardId={rateCardId}
              graphCardId={graphCardId}
              property={rateKey}
              dateRangeOptions={dateRangeOptions}
              defaultRange={DateRange.LAST_90_DAYS}
              minDate={dateOneYearAgo}
              from={range.from}
              to={range.to}
              isCustomDateRange={isCustomRange}
              onDateRangeChange={onRequestDateRangeChange}
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} lg={2}>
              <Text type="header">
                Defective Orders: {isLoading ? '' : queryData.total}
              </Text>
            </Grid>
            <Grid item xs={12} lg={10}>
              <Button
                data-testid="generate-odr-report"
                color="primary"
                startIcon={<GetAppIcon />}
                onClick={generateOdrReport}
              >
                Generate & Download Report
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {tab === TabValue.RATE_OVER_TIME && (
          <Grid item xs={12} data-testid="orders-table">
            {isError && (
              <div>
                Too many orders to display. Please click Generate & Download
                Report for the Order Defect details.
              </div>
            )}
            {!isError && (
              <EnhancedTable
                data={getTableDataByPage(queryData.data, tablePage)}
                fieldList={queryData.fieldList}
                isLoading={isLoading}
                total={queryData.total}
                onChangePage={(_event: any, page?: number) => {
                  if (page === undefined) return
                  tableChangePage(page + 1)
                }}
                page={tablePage - 1}
              />
            )}
          </Grid>
        )}
        {!isError && tab === TabValue.OVERRIDE && (
          <Grid item xs={12} data-testid="overrides-table">
            <DateRangeSelector
              type="odrMetric"
              descriptor="Date Range"
              defaultRange={DateRange.LAST_90_DAYS}
              defaultFrom={range.from}
              defaultTo={maxDate}
              maxDate={maxDate}
              maxDateError="Must be yesterday or earlier"
              onDateChange={handleDateChange}
              disableDatesBefore={dateOneYearAgo}
              dateRangeOptions={dateRangeOptions}
            />
            <EnhancedTable
              data={getTableDataByPage(queryData.data, tablePage)}
              fieldList={queryData.fieldList}
              isLoading={isLoading}
              total={queryData.total}
              onChangePage={(_event: any, page?: number) => {
                if (page === undefined) return
                tableChangePage(page + 1)
              }}
              page={tablePage - 1}
            />
          </Grid>
        )}
      </Grid>
    </FullScreenDialogContainer>
  )
}

export default OrderDefectMetrics
