import { useState } from 'react'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import FileUploader from 'components/common/FileUploader'
import Input from 'components/common/Input'

import { closeDialog } from 'store/dialog/actionCreator'

import { submitReport } from 'services/reports'
import { validationHandler } from 'services/validation'

import { ReportParameters, ReportType, Report } from 'types/Report'
import { Validation } from 'types/Validation'

import { XLXS_CONTENT_TYPE } from 'constants/fileTypes'

import isEmpty from 'lodash/fp/isEmpty'

interface Props {
  isOpen: boolean
  parameters?: ReportParameters
  reportType: ReportType
  onRequestSubmit: (report: Report) => void
}

const validationSchema = yup.object().shape({
  fileName: yup.string().required().label('File name'),
})

const FileUploadDialog = ({
  isOpen,
  parameters,
  reportType,
  onRequestSubmit,
}: Props) => {
  const [files, setFiles] = useState<Dictionary<File>>({})
  const [fileName, setFileName] = useState('')
  const [validation, setValidation] = useState<Validation>()
  const [pending, setPending] = useState(false)

  const dispatch = useDispatch()

  const title = pending ? 'Uploading... Please wait' : 'Upload'

  const handleChange = (value: string) => {
    setFileName(value)
  }

  const handleSubmit = async () => {
    const { validation: result, isValid } = validationHandler(
      validationSchema,
      {
        fileName,
      },
    )
    setValidation(result)

    if (isValid) {
      setPending(true)

      try {
        const report = await submitReport({
          type: reportType,
          parameters,
          reportName: fileName,
          format: 'EXCEL',
          reportInput: files[Object.keys(files)[0]],
        })

        onRequestSubmit(report)
      } catch (e) {
        console.error(`Error uploading report: ${e}`)
      } finally {
        setPending(false)
        dispatch(closeDialog())
      }
    }
  }

  return (
    <DialogContainer
      title={title}
      isOpen={isOpen}
      onSubmit={handleSubmit}
      isSubmitDisabled={isEmpty(files) || !fileName}
      submitButtonText="Generate"
      isPending={pending}
    >
      <Input
        id="fileName"
        name="fileName"
        value={fileName}
        label="File Name"
        isDisabled={pending}
        isRequired
        onChange={handleChange}
        validation={validation}
      />
      <FileUploader
        onSubmit={(files) => setFiles(files as Dictionary<File>)}
        accept={XLXS_CONTENT_TYPE}
        pending={pending}
      />
    </DialogContainer>
  )
}

export default FileUploadDialog
