import styled from '@emotion/styled'

import DoneIcon from '@mui/icons-material/Done'
import CancelIcon from '@mui/icons-material/Cancel'

import { filterChip } from 'config/themeConfig'

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignContent: 'center',
  width: '100%',
  backgroundColor: filterChip.main,
  color: theme.palette.grey[900],
  border: `2px solid ${theme.palette.grey[300]}`,
  padding: `2px 14px`,
  borderRadius: 4,
}))

const StyledSelection = styled('div')({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
})

const StyledDoneIcon = styled(DoneIcon)({
  color: 'primary',
  alignSelf: 'center',
})

type Props = {
  value: string
  title?: string
  onDelete?: () => void
}

export const FilterValue = ({ value, title, onDelete }: Props) => {
  const label = title ? `${title}: ${value}` : value

  return (
    <StyledRoot data-testid="filter-selection">
      <StyledSelection>
        <StyledDoneIcon />
        {label}
      </StyledSelection>
      {onDelete && (
        <CancelIcon data-testid="remove-filter" onClick={onDelete} />
      )}
    </StyledRoot>
  )
}

export default FilterValue
