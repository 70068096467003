import React from 'react'
import { useDispatch } from 'react-redux'

import { DialogEnum } from 'components/common/Dialog'
import Sparkline from 'components/common/Charts/Sparkline'

import {
  error,
  success,
  grey,
  primary,
  odrNonCompliant,
} from 'config/themeConfig'

import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Warning from '@mui/icons-material/Warning'

import { CancelOutlined, CheckCircleOutlined } from '@mui/icons-material'

import {
  getBiReportingValues,
  getTimePeriod,
  getVendorFilter,
} from 'services/biReporting'
import { fetchCardDataThruSecurityGateway } from 'services/securityGateway'

import { openDialog } from 'store/dialog/actionCreator'
import { BiReportingCards } from 'types/BiReporting'
import { OrderDefectMetricType } from 'types/VendorStats'
import { decimalRound } from 'services/number'

const StyledMetricContainerGrid = styled(Grid)(() => ({
  alignItems: 'center',
}))

const StyledCardTextGrid = styled(Grid)(() => ({
  color: grey[700],
  textAlign: 'left',
}))

type StyledCardTitleTypographyProps = {
  isDefective?: boolean
}

const StyledCardTitleTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isDefective',
})<StyledCardTitleTypographyProps>(
  (props) => ({
    color: props.isDefective ? error.main : undefined,
  }),
  ({ theme }) => ({
    textAlign: 'left',
    color: grey[700],
    marginBottom: theme.spacing(2),
    lineHeight: 'unset',
  }),
)

const StyledCompliantIcon = styled(CheckCircleOutlined)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: success.main,
}))

const StyledNonCompliantIcon = styled(CancelOutlined)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: odrNonCompliant.main,
}))

const StyledWarningIcon = styled(Warning)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: error.main,
}))

const StyledLast90Days = styled('div')(({ theme }) => ({
  color: grey[700],
  textAlign: 'center',
  padding: theme.spacing(4),
}))

const StyledMetricRateTypography = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledDetailsLinkTypography = styled(Typography)<{ component: string }>(
  ({ theme }) => ({
    textAlign: 'right',
    marginLeft: theme.spacing(5),
  }),
)

const StyledDetailsButton = styled(Button)(() => ({
  color: primary.main,
}))

export type Props = {
  sellerId?: string
  vmmId?: string
  metric: string
  mostDefective: boolean
  rate: number
  timeSeriesCardId: BiReportingCards
  greenfieldColumn: string
  odrMetricType: OrderDefectMetricType
  greenfieldInterval: string
}

export const OrderDefectMetricTile: React.FC<Props> = ({
  sellerId,
  vmmId,
  metric,
  mostDefective,
  rate,
  timeSeriesCardId,
  greenfieldColumn,
  odrMetricType,
  greenfieldInterval,
}) => {
  const reduxDispatch = useDispatch()

  const [graphData, setGraphData] = React.useState<Dictionary<any>[]>([])
  const [graphDataKey, setGraphDataKey] = React.useState<string>('')

  const setComplianceIcon = (rate: number | undefined) => {
    if (rate) {
      if (rate >= 0.0 && rate < 5.0) {
        return <StyledCompliantIcon height={20} />
      } else if (rate >= 5.0 && rate < 7.0) {
        return <StyledNonCompliantIcon height={20} />
      } else if (rate >= 7.0) {
        return <StyledWarningIcon height={20} />
      }
    } else {
      return <StyledCompliantIcon height={20} />
    }
  }

  const onRequestOpenDialog = () => {
    const params = {
      dialogEnum: DialogEnum.PERFORMANCE_METRICS,
      componentProps: {
        type: odrMetricType,
        defectRate: rate,
      },
    }
    reduxDispatch(openDialog(params))
  }

  React.useEffect(() => {
    let mounted = true

    if (sellerId && vmmId) {
      const vendorFilter = getVendorFilter(vmmId)
      const timeSeriesTimePeriod = getTimePeriod({
        interval: greenfieldInterval,
        granularity: 'Day',
      })

      fetchCardDataThruSecurityGateway(
        timeSeriesCardId,
        sellerId,
        vendorFilter,
        timeSeriesTimePeriod,
      ).then((res) => {
        if (mounted) {
          const metricRefId = res.card_config.card_query_attribute.columns.find(
            (item) => item.field_name === greenfieldColumn,
          )?.ref_id
          if (metricRefId) {
            setGraphDataKey(metricRefId)
          }

          const data = getBiReportingValues(res)
          if (data && metricRefId) {
            const roundedData = data.map((item) => ({
              ...item,
              [metricRefId]: decimalRound(item[metricRefId], 4),
            }))

            setGraphData(roundedData)
          }
        }
      })
    }
    return () => {
      mounted = false
    }
  }, [sellerId, vmmId, timeSeriesCardId, greenfieldColumn, greenfieldInterval])

  return (
    <StyledMetricContainerGrid container>
      <StyledCardTextGrid item sm={3}>
        <StyledCardTitleTypography
          data-testid={`${greenfieldColumn}-label`}
          variant="overline"
        >
          {metric}
        </StyledCardTitleTypography>
        {mostDefective && (
          <StyledCardTitleTypography data-testid="most-defective-label">
            Most Defective
          </StyledCardTitleTypography>
        )}
      </StyledCardTextGrid>
      <Grid item sm={3}>
        <StyledLast90Days>
          <Typography variant="body1"> Last 90 Days </Typography>
          <StyledMetricRateTypography
            data-testid={`${greenfieldColumn}-value`}
            variant="h2"
          >
            {rate}% {setComplianceIcon(rate)}
          </StyledMetricRateTypography>
        </StyledLast90Days>
      </Grid>
      <Grid item sm={4}>
        <Sparkline data={graphData} dataKey={graphDataKey} />
      </Grid>
      <Grid item sm={1}>
        <StyledDetailsLinkTypography
          component="span"
          variant="overline"
          data-testid={`${greenfieldColumn}-details-btn`}
        >
          <StyledDetailsButton onClick={onRequestOpenDialog}>
            View Details
          </StyledDetailsButton>
        </StyledDetailsLinkTypography>
      </Grid>
    </StyledMetricContainerGrid>
  )
}

export default OrderDefectMetricTile
