import { useEffect, useState, useCallback } from 'react'
import usePrevious from 'hooks/usePrevious'

import isEmpty from 'lodash/fp/isEmpty'
import camelCase from 'lodash/fp/camelCase'
import startCase from 'lodash/fp/startCase'

import styled from '@emotion/styled'
import { grey } from 'config/themeConfig'

import { Box, Grid, Tab, Tabs, Typography } from '@mui/material'

import { CONTENT_PADDING } from 'constants/layout'
import { sellerStatuses } from 'constants/sellerStatuses'
import { businessUnits } from 'constants/businessUnits'

import {
  BiColumn,
  BiReportingCards,
  BiReportingFilter,
} from 'types/BiReporting'
import { SellerStatus } from 'types/Seller'

import OnboardingTable from './OnboardingTable'

import FilterBar from 'components/common/FilterBar'
import HeaderTitle from 'components/common/HeaderTitle'
import MultiSelectFilter from 'components/common/FilterBar/MultiSelectFilter'
import { TableState } from 'components/ReturnDisputeCasesPage/ExternalReturnDisputeCases'
import { useSearchParams } from 'components/common/FilterBar/useSearchParams'
import Link from 'components/common/Link'
import { EnhancedTableFieldType } from 'components/common/EnhancedTable'

import { fetchCardData } from 'services/biReporting'

import { RoutePath } from 'services/NavigationHelper'
import { Direction } from 'services/pageableHelper'
import {
  DATE_DISPLAY_FORMAT,
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
  DATE_PICKER_FORMAT,
  formatDateLocalTime,
  formatDate,
} from 'services/dateService'
import formatCurrency from 'services/formatCurrency'

import { sortRowsByColumnKey } from './helpers'

enum OnboardingTab {
  PERFORMACE_METRICS = BiReportingCards.ONBOARDING_PERFORMANCE,
  ITEM_METRICS = BiReportingCards.ONBOARDING_ITEM,
  FULFILLMENT_METRICS = BiReportingCards.ONBOARDING_FULLFILLMENT,
}

const StyledTabs = styled(Tabs)({
  margin: `0 -${CONTENT_PADDING}px`,
  padding: `0 ${CONTENT_PADDING}px`,
})

const StyledTabContent = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(3),
}))

type SearchParams = TableState & {
  status: SellerStatus | SellerStatus[] | undefined
  business_unit: string | string[] | undefined
}

const DEFAULT_COLUMN_SORT_KEY = 'rcak7op15p8' // Legal Name colummn key

const initialSearchParams: SearchParams = {
  perPage: 20,
  page: 0,
  orderBy: DEFAULT_COLUMN_SORT_KEY,
  direction: Direction.ASC,
  status: undefined,
  business_unit: undefined,
}

export const OnboardingPage = () => {
  const [activeTab, setActiveTab] = useState<OnboardingTab>(
    OnboardingTab.PERFORMACE_METRICS,
  )
  const [dataLastUpdatedDate, setDataLastUpdatedDate] = useState<string>('')
  const [pending, setPending] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [data, setData] = useState<any>([])
  const [fieldList, setFieldList] = useState<any>([])

  const [searchParams, searchParamActions] =
    useSearchParams<SearchParams>(initialSearchParams)
  const prevSearchParams = usePrevious(searchParams)

  const handleTabChange = (_event: any, newValue: OnboardingTab) =>
    setActiveTab(newValue)

  const prevTab = usePrevious(activeTab)

  const getSellerDashboadLink = (sellerId: string, sellerName: string) => {
    return <Link to={`/${sellerId}${RoutePath.DASHBOARD}`}>{sellerName}</Link>
  }

  const mapOnboardingColumnsToFieldList = useCallback(
    (columns: BiColumn[]): EnhancedTableFieldType[] => {
      const sellerIdColumnKey = columns.find(
        (col) => col.field_name === 'seller_id',
      )?.ref_id

      const fieldList = columns.map((column) => {
        const field: EnhancedTableFieldType = {
          key: column.ref_id,
          heading: startCase(column.column_display_name),
          hasSort: true,
        }

        if (column.field_name === 'legal_business_n') {
          field.formatCell = (data: any) =>
            getSellerDashboadLink(data[sellerIdColumnKey!], data[column.ref_id])
        }

        if (
          column.field_name === 'partner_setup_status_n' ||
          column.field_name === 'source_n'
        ) {
          field.formatCell = (data: any) =>
            startCase(camelCase(data[column.ref_id]))
        }

        if (
          column.field_name === 'frst_sub_date' ||
          column.field_name === 'launch_date_time' ||
          column.field_name === 'order_d'
        ) {
          field.formatCell = (data: any) =>
            data[column.ref_id]
              ? formatDate(
                  data[column.ref_id],
                  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
                  DATE_PICKER_FORMAT,
                )
              : null
        }

        if (
          column.format_type === 'percentage' &&
          column.format_precision !== undefined
        ) {
          field.formatCell = (data: any) =>
            `${(data[column.ref_id] as Number).toFixed(
              parseInt(column.format_precision as string, 10),
            )}%`
        }

        if (column.format_type === 'number' && column.data_type === 'DOUBLE') {
          field.formatCell = (data: any) => formatCurrency(data[column.ref_id])
        }
        if (
          column.field_name === 'odr' ||
          column.column_display_name === '%live_vs_promised'
        ) {
          field.formatCell = (data: any) => {
            return (
              <Box sx={{ textAlign: 'right' }}>
                {(data[column.ref_id] as Number).toFixed(
                  parseInt(column.format_precision as string, 10),
                )}
                %
              </Box>
            )
          }
        }
        if (
          column.field_name === 'net_gmv' ||
          column.field_name === 'return' ||
          column.field_name === 'cancels' ||
          column.field_name === 'contacts' ||
          column.field_name === 'late'
        ) {
          field.formatCell = (data: any) => {
            return (
              <Box sx={{ textAlign: 'right' }}>
                {formatCurrency(data[column.ref_id])}
              </Box>
            )
          }
        }
        if (
          column.field_name === 'mp_planned_sku' ||
          column.field_name === 'published_instock' ||
          column.field_name === 'total_unshipped' ||
          column.field_name === 'unshipped_passed_due'
        ) {
          field.formatCell = (data: any) => {
            return <Box sx={{ textAlign: 'right' }}>{data[column.ref_id]}</Box>
          }
        }

        return field
      })
      // hide Seller Id column
      return fieldList.filter((elem) => {
        return elem.heading !== 'Seller Id'
      })
    },
    [],
  )

  useEffect(() => {
    let updatedValues = {}
    if (prevSearchParams) {
      updatedValues = Object.fromEntries(
        Object.entries(searchParams).filter(
          ([key, value]) =>
            prevSearchParams[key as keyof typeof prevSearchParams] !== value,
        ),
      )
    }

    if (!prevSearchParams || !isEmpty(updatedValues) || activeTab !== prevTab) {
      if (
        // if only orderBy or direction has changed, update data sorting and skip api call
        (Object.hasOwn(updatedValues, 'orderBy') ||
          Object.hasOwn(updatedValues, 'direction')) &&
        searchParams.orderBy &&
        searchParams.direction
      ) {
        setData(
          sortRowsByColumnKey(
            data,
            searchParams.orderBy,
            searchParams.direction,
          ),
        )
      } else {
        setPending(true)
        let filters: BiReportingFilter[] = []

        if (searchParams.status?.length && searchParams.status?.length > 0) {
          filters.push({
            type: 'in',
            dimension: 'partner_setup_status_n',
            display_name: 'status',
            obj_type: 'field',
            pattern: searchParams.status,
          })
        }

        if (
          searchParams.business_unit?.length &&
          searchParams.business_unit?.length > 0
        ) {
          filters.push({
            type: 'in',
            dimension: 'mp_business_unit',
            display_name: 'business_unit',
            obj_type: 'field',
            pattern: searchParams.business_unit,
          })
        }

        fetchCardData(activeTab.toString(), filters)
          .then((res) => {
            let tableData: any = res.query_results

            if (searchParams.orderBy && searchParams.direction) {
              tableData = sortRowsByColumnKey(
                tableData,
                searchParams.orderBy,
                searchParams.direction,
              )
            }

            setData(tableData)
            setTotal(tableData.length ?? 0)
            setFieldList(
              mapOnboardingColumnsToFieldList(
                res.card_config.card_query_attribute.columns,
              ),
            )
            setDataLastUpdatedDate(
              formatDateLocalTime(
                res.card_config.card_attribute.dataset_last_load_date,
                DATE_DISPLAY_FORMAT,
              ),
            )
          })
          .catch((e) => console.error(e))
          .finally(() => setPending(false))
      }
    }
  }, [
    activeTab,
    prevTab,
    searchParams,
    prevSearchParams,
    searchParamActions,
    mapOnboardingColumnsToFieldList,
    data,
  ])

  const handleClearFilters = () => {
    searchParamActions.updateSearchParam(initialSearchParams)
  }

  const handlePageChange = (event: any, page: number | undefined) => {
    searchParamActions.changePage(event, page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    searchParamActions.changePerPage(event)
  }

  const handleSort = (event: any) => {
    searchParamActions.sort(event)
  }

  const renderTab = () => {
    let component

    switch (activeTab) {
      case OnboardingTab.PERFORMACE_METRICS:
        component = (
          <OnboardingTable
            pending={pending}
            total={total}
            data={data}
            fieldList={fieldList}
            handlePageChange={handlePageChange}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            searchParams={searchParams}
            onRequestSort={handleSort}
            order={searchParams.direction}
            orderBy={searchParams.orderBy}
          />
        )
        break
      case OnboardingTab.ITEM_METRICS:
        component = (
          <OnboardingTable
            pending={pending}
            total={total}
            data={data}
            fieldList={fieldList}
            handlePageChange={handlePageChange}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            searchParams={searchParams}
            onRequestSort={handleSort}
            order={searchParams.direction}
            orderBy={searchParams.orderBy}
          />
        )
        break
      case OnboardingTab.FULFILLMENT_METRICS:
        component = (
          <OnboardingTable
            pending={pending}
            total={total}
            data={data}
            fieldList={fieldList}
            handlePageChange={handlePageChange}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            searchParams={searchParams}
            onRequestSort={handleSort}
            order={searchParams.direction}
            orderBy={searchParams.orderBy}
          />
        )
        break
    }
    return <StyledTabContent>{component}</StyledTabContent>
  }

  return (
    <div>
      <HeaderTitle title="Onboarding" />
      {dataLastUpdatedDate && (
        <Typography
          sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 2 }}
          color={grey[700]}
          data-testid="onboarding-data-last-updated"
        >
          {`Last updated: ${dataLastUpdatedDate}`}
        </Typography>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FilterBar
            count={total}
            onClear={handleClearFilters}
            appliedFilterCount={total}
          >
            <Grid item xs={12} lg={2}>
              <MultiSelectFilter
                label="Partner Status"
                placeholder="Partner Status"
                searchParam="status"
                value={searchParams.status as SellerStatus}
                onChange={searchParamActions.updateSearchParam}
                data={sellerStatuses}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <MultiSelectFilter
                label="Business Unit"
                placeholder="Business Unit"
                searchParam="business_unit"
                value={searchParams.business_unit}
                onChange={searchParamActions.updateSearchParam}
                data={businessUnits}
              />
            </Grid>
          </FilterBar>
        </Grid>
        <Grid item xs={12}>
          <StyledTabs
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
          >
            <Tab
              data-testid="performance-metrics-tab"
              value={OnboardingTab.PERFORMACE_METRICS}
              key="performance"
              label="Performance"
            />
            <Tab
              data-testid="item-metrics-tab"
              value={OnboardingTab.ITEM_METRICS}
              key="item"
              label="Item"
            />
            <Tab
              data-testid="fulfillment-metrics-tab"
              value={OnboardingTab.FULFILLMENT_METRICS}
              key="fulfillment"
              label="Fulfillment"
            />
          </StyledTabs>
        </Grid>
        <Grid item xs={12}>
          {renderTab()}
        </Grid>
      </Grid>
    </div>
  )
}

export default OnboardingPage
