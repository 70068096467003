import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Grid from '@mui/material/Grid'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import RadioGroup from 'components/common/RadioGroup/RadioGroup'

import { editSeller } from 'store/seller/actionCreators'
import { currentSeller, isSellersPending } from 'store/selectors'

export interface Props {
  isOpen: boolean
}

enum RadioOptions {
  YES = 'Yes',
  NO = 'No',
}

const EditOtherMarketplaces = ({ isOpen }: Props) => {
  const dispatch = useDispatch()

  const seller = useSelector(currentSeller)
  const isPending = useSelector(isSellersPending)

  const [otherMarketplaces, setOtherMarketplaces] = React.useState(
    seller?.in_other_marketplaces === undefined
      ? ''
      : seller?.in_other_marketplaces
      ? RadioOptions.YES
      : RadioOptions.NO,
  )

  const handleInputChange = (value: string) => setOtherMarketplaces(value)

  const handleSubmit = () => {
    if (seller) {
      const updatedSeller = {
        ...seller,
        in_other_marketplaces:
          otherMarketplaces === RadioOptions.YES ? true : false,
      }

      dispatch(editSeller(updatedSeller))
    }
  }

  return (
    <DialogContainer
      data-testid="edit-other-marketplaces-dialog"
      isOpen={isOpen}
      title={
        seller?.in_other_marketplaces === undefined
          ? 'Add Other Marketplaces Info'
          : 'Edit Other Marketplaces Info'
      }
      isPending={isPending}
      onSubmit={handleSubmit}
      isSubmitDisabled={otherMarketplaces === ''}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RadioGroup
            id="other-marketplaces"
            name="otherMarketplaces"
            options={[
              { label: RadioOptions.YES, value: RadioOptions.YES },
              { label: RadioOptions.NO, value: RadioOptions.NO },
            ]}
            value={otherMarketplaces || ''}
            onChange={handleInputChange}
            label="Do you advertise or sell items on other marketplaces?*"
          />
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export default EditOtherMarketplaces
