import { useEffect, useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import * as yup from 'yup'
import head from 'lodash/fp/head'

import styled from '@emotion/styled'
import { primary, grey } from 'config/themeConfig'
import Typography from '@mui/material/Typography'
import Publish from '@mui/icons-material/Publish'

import UploadSpinner from 'components/common/FileUploader/UploadSpinner'
import Text from 'components/common/Text'

import { validationHandler } from 'services/validation'
import { fullyEncodeFilename } from 'services/urlHelper'

import { MEGABYTES_AS_BYTES_10 } from 'constants/sizeLimits'

const StyledDiv = styled('div')(({ theme }) => ({
  backgroundColor: grey[100],
  border: `dashed 2px ${grey[300]}`,
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  cursor: 'pointer',
}))

const StyledText = styled(Text)(({ theme }) => ({
  marginTop: theme.spacing(1),
  fontWeight: 300,
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: primary.main,
  marginBottom: theme.spacing(0),
}))

interface Props {
  accept: string
  pending?: boolean
  multiple?: boolean
  onSubmit: (files: Dictionary<File | string>) => void
  typeErrorMessage?: string
  uploadInstructions?: string
  boldUploadInstructions?: string
  maxFileSize?: number
}

const validationSchema = yup.object().shape({
  files: yup.mixed().required().label('File'),
})

const convertBytesToMegabytes = (max: number): number => max / 1024 / 1024

const FileUploader = ({
  onSubmit,
  accept,
  pending,
  multiple = false,
  typeErrorMessage = 'File must be of type CSV or XLSX.',
  uploadInstructions,
  boldUploadInstructions,
  maxFileSize = MEGABYTES_AS_BYTES_10,
}: Props) => {
  const [files, setFiles] = useState<File[]>([])
  const [fileName, setFileName] = useState<string>('')
  const [error, setError] = useState<string>()

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: File[]) => {
      let acceptNameFiles: File[] = []
      let rejectNameFiles: File[] = []
      let rejectSizeFiles: File[] = []

      acceptedFiles.forEach((file) => {
        if (file.size > maxFileSize) {
          rejectSizeFiles.push(file)
        } else if (file.name.length > 63) {
          rejectNameFiles.push(file)
        } else {
          acceptNameFiles.push(file)
        }
      })

      setFiles((prevState) => [...prevState, ...acceptNameFiles])

      setError(head(rejectedFiles) ? typeErrorMessage : undefined)
      if (rejectSizeFiles.length) {
        setError(
          `Unable to attach file(s). Each file must be less than ${convertBytesToMegabytes(
            maxFileSize,
          )}MB in size`,
        )
      } else if (rejectNameFiles.length) {
        setError(
          'Unable to attach file, file name needs to be less than 63 characters (including the file extension)',
        )
      }
    },
    [maxFileSize, typeErrorMessage],
  )

  useEffect(() => {
    if (files.length === 0) return

    const { validation: result, isValid } = validationHandler(
      validationSchema,
      {
        files,
      },
    )
    setError(result.file ? result.file.join() : undefined)
    if (!isValid) {
      return
    }

    const fileDict = files.reduce((c, v) => {
      return {
        ...c,
        [v.name]: new File([v], fullyEncodeFilename(v.name), {
          type: v.type,
        }),
      }
    }, {}) as Dictionary<File>

    onSubmit(fileDict)
    setFileName(Object.keys(fileDict)[0])
    setFiles((prevState) =>
      prevState.filter((file) => !Object.keys(fileDict).includes(file.name)),
    )

    return
  }, [files, onSubmit])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple,
  })

  if (pending) {
    return <UploadSpinner />
  }

  return (
    <StyledDiv {...getRootProps()} data-testid="file-uploader">
      <input {...getInputProps()} />
      <StyledTypography paragraph>
        <Publish color="primary" />
        {isDragActive
          ? 'Drop the file here...'
          : "Drag 'n drop or click here to a select file"}
      </StyledTypography>
      {uploadInstructions && <StyledText>{uploadInstructions}</StyledText>}
      {boldUploadInstructions && (
        <Text type="bold">{boldUploadInstructions}</Text>
      )}
      {error && <Text type="error">{error}</Text>}
      {!error && !multiple && fileName && (
        <Text type="emphasis">File: {fileName}</Text>
      )}
    </StyledDiv>
  )
}

export default FileUploader
