import React from 'react'

import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'

import KnowledgeCategoryCard from './KnowledgeCategoryCard'

import { getKnowledgeCategories } from 'services/knowledgeArticles'

import { KnowledgeCategory } from 'types/KnowledgeArticle'

const StyledLoader = styled(LinearProgress)(({ theme }) => ({
  margin: theme.spacing(0, -5),
}))

export const HelpCategoriesPage = () => {
  const [pending, setPending] = React.useState(false)
  const [categories, setCategories] = React.useState<KnowledgeCategory[]>([])

  React.useEffect(() => {
    let mounted = true

    setPending(true)
    getKnowledgeCategories()
      .then((response) => {
        if (mounted) {
          setCategories(response)
        }
      })
      .finally(() => {
        if (mounted) {
          setPending(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [])

  return (
    <>
      {pending && <StyledLoader />}
      {!pending && categories.length > 0 && (
        <Grid container spacing={2}>
          {categories?.map((category) => {
            return (
              <Grid key={category.name} item xs={4}>
                <KnowledgeCategoryCard category={category} />
              </Grid>
            )
          })}
        </Grid>
      )}
    </>
  )
}

export default HelpCategoriesPage
