import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import {
  formatDateMDYT,
  orderLink,
} from 'components/common/EnhancedTable/formatters'
import useTable from 'components/common/EnhancedTable/useTable'

import TableSpacer from 'components/common/TableSpacer'

import { getPrettyName, orderStatusLabels } from 'services/enumerations'
import { getOrderList } from 'services/orders'
import { Direction } from 'services/pageableHelper'

import { currentSellerId } from 'store/selectors'

import { EnumerationName } from 'types/Enumeration'
import { Order } from 'types/Orders'
import { CollectionResponse } from 'types/Response'

export interface Props {
  tcin: string | undefined
}

export const ItemOrders = ({ tcin }: Props) => {
  const sellerId = useSelector(currentSellerId)

  const [orderResponse, setOrderResponse] = useState<CollectionResponse<Order>>(
    { data: [], total: 0 },
  )
  const [pending, setPending] = useState(false)
  const { table } = useTable({
    page: 0,
    perPage: 10,
    direction: Direction.DESC,
    orderBy: 'order_date',
  })

  useEffect(() => {
    if (!sellerId || !tcin) {
      return
    }

    setPending(true)

    getOrderList(table.state, { tcin, seller_id: sellerId })
      .then((res) => {
        setOrderResponse(res)
      })
      .finally(() => {
        setPending(false)
      })
  }, [table.state, sellerId, tcin])

  const fieldList: EnhancedTableFieldType<Order>[] = [
    {
      key: 'id',
      heading: 'Order ID',
      hasSort: true,
      formatCell: orderLink(),
    },
    {
      key: 'order_date',
      heading: 'Date & Time Placed',
      hasSort: true,
      formatCell: formatDateMDYT('order_date'),
    },
    {
      key: 'quantity',
      heading: 'Quantity',
      formatCell: (order) => {
        const itemOrderLine = order.order_lines.find(
          (orderLine) => orderLine.tcin === tcin,
        )

        return itemOrderLine?.quantity
      },
    },
    {
      key: 'status',
      heading: 'Status',
      formatCell: (order) => {
        const itemOrderLine = order.order_lines.find(
          (orderLine) => orderLine.tcin === tcin,
        )

        const orderStatuses = [
          ...new Set(
            itemOrderLine?.order_line_statuses.map((orderLineStatus) =>
              getPrettyName({
                enumeration: EnumerationName.ORDER_STATUS,
                value: orderLineStatus.status,
              }),
            ),
          ),
        ]

        if (
          orderStatuses?.length > 1 &&
          orderStatuses.includes(orderStatusLabels.SHIPPED)
        ) {
          return orderStatusLabels.PARTIALLY_SHIPPED
        }
        return orderStatuses?.join(', ')
      },
    },
  ]

  return (
    <>
      <TableSpacer>
        <EnhancedTable
          data={orderResponse.data}
          fieldList={fieldList}
          isLoading={pending}
          onChangePage={table.actions.changePage}
          onChangeRowsPerPage={table.actions.changePerPage}
          onRequestSort={table.actions.sort}
          orderBy={table.state.orderBy}
          order={table.state.direction}
          page={table.state.page}
          rowsPerPage={table.state.perPage}
          total={orderResponse.total}
        />
      </TableSpacer>
    </>
  )
}

export default ItemOrders
