import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'

import isEqual from 'lodash/fp/isEqual'

import styled from '@emotion/styled'

import Grid from '@mui/material/Grid'

import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import HeaderTitle from 'components/common/HeaderTitle'
import LabeledDataList from 'components/common/LabeledDataList'
import Link from 'components/common/Link'
import TableSpacer from 'components/common/TableSpacer'
import DateRangeFilter from 'components/common/FilterBar/DateRangeFilter'
import FilterBar from 'components/common/FilterBar'
import TypeaheadFilter from 'components/common/FilterBar/TypeaheadFilter'
import {
  useSearchParams,
  TableState,
  getEnhancedTablePageableProps,
} from 'components/common/FilterBar/useSearchParams'
import { OrderTab } from 'components/Orders/OrderDetails'
import { formatDateMDYT } from 'components/common/EnhancedTable/formatters'
import SelectFilter from 'components/common/FilterBar/SelectFilter'
import { DialogEnum } from 'components/common/Dialog'
import TrackingNumberLink from 'components/common/TrackingNumberLink'

import { grey } from 'config/themeConfig'

import { DOWNLOAD_COUNT_LIMIT_TOOLTIP } from 'constants/sizeLimits'

import {
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
  formatDateRange,
  formatDate,
  isoStringEndOfDay,
  isoStringStartOfDay,
} from 'services/dateService'
import { formatLocaleNumber } from 'services/formatNumber'
import {
  searchProductReturns,
  getReturnQuantity,
} from 'services/productReturns'
import { RoutePath } from 'services/NavigationHelper'
import { Direction } from 'services/pageableHelper'
import { getOptionalReturnedOnlineToggleValue } from 'services/toggle'
import { createMarkup } from 'services/createMarkup'
import {
  checkDownloadLimit,
  GenerateReportRequest,
  submitReport,
} from 'services/reports'
import { memoizedGetSellerDisplayName } from 'services/seller'

import { openDialog } from 'store/dialog/actionCreator'
import { currentSellerId, getReturnCenters } from 'store/selectors'

import { Return } from 'types/Orders'
import { ReportType } from 'types/Report'
import { INITIAL_RETURNS_SEARCH, RETURNS_SEARCH } from './queries'

const StyledTitleContainer = styled(Grid)({ display: 'flex' })

const StyledTitle = styled('div')({ flexGrow: 1 })

const StyledRow = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))

const StyledListItem = styled('div', {
  shouldForwardProp: (prop) => prop !== 'index' && prop !== 'isLastItem',
})<{ index: number; isLastItem: boolean }>((props) => ({
  paddingTop: props.index === 0 ? 0 : props.theme.spacing(2),
  paddingBottom: props.isLastItem ? 0 : props.theme.spacing(2),
  borderTop: props.index === 0 ? 'none' : `1px solid ${grey[300]}`,
}))

const ZERO_WIDTH_SPACE = '\u200b'

type SearchParams = TableState & {
  tcin: string | undefined
  license_plate: string | undefined
  tracking_number: string | undefined
  returnStartDate: string | undefined
  returnEndDate: string | undefined
  crcReceivedStartDate: string | undefined
  crcReceivedEndDate: string | undefined
  shipToPartnerStartDate: string | undefined
  shipToPartnerEndDate: string | undefined
  return_channel: string | undefined
  bill_of_lading: string | undefined
  location_id: string | undefined
  seller_id: string | undefined
  shipment_id: string | undefined
  return_order_number: string | undefined
  receiving_location_id: number | undefined
}

const initialSearchParams: SearchParams = {
  perPage: 100,
  page: 0,
  orderBy: 'return_date',
  direction: Direction.DESC,
  tcin: undefined,
  license_plate: undefined,
  tracking_number: undefined,
  crcReceivedStartDate: undefined,
  crcReceivedEndDate: undefined,
  returnStartDate: undefined,
  returnEndDate: undefined,
  shipToPartnerStartDate: undefined,
  shipToPartnerEndDate: undefined,
  return_channel: undefined,
  bill_of_lading: undefined,
  location_id: undefined,
  seller_id: undefined,
  shipment_id: undefined,
  return_order_number: undefined,
  receiving_location_id: undefined,
}

const buildSearchParams = ({
  sellerId,
  tcin,
  license_plate,
  tracking_number,
  returnStartDate,
  returnEndDate,
  crcReceivedStartDate,
  crcReceivedEndDate,
  shipToPartnerStartDate,
  shipToPartnerEndDate,
  return_channel,
  bill_of_lading,
  location_id,
  return_order_number,
  receiving_location_id,
  shipment_id,
}: {
  sellerId: string | undefined
  tcin: string | undefined
  license_plate: string | undefined
  tracking_number: string | undefined
  returnStartDate: string | undefined
  returnEndDate: string | undefined
  crcReceivedStartDate: string | undefined
  crcReceivedEndDate: string | undefined
  shipToPartnerStartDate: string | undefined
  shipToPartnerEndDate: string | undefined
  return_channel: string | undefined
  bill_of_lading: string | undefined
  location_id: string | undefined
  return_order_number: string | undefined
  receiving_location_id: number | undefined
  shipment_id: string | undefined
}) => {
  const returnChannelValue =
    getOptionalReturnedOnlineToggleValue(return_channel)
  const returnDateRange = formatDateRange(returnStartDate, returnEndDate)
  const crcRecievedDateRange = formatDateRange(
    crcReceivedStartDate,
    crcReceivedEndDate,
  )
  const shipToPartnerDateRange = formatDateRange(
    shipToPartnerStartDate,
    shipToPartnerEndDate,
  )

  const returnDate = returnDateRange || undefined
  const crcRecievedDate = crcRecievedDateRange || undefined
  const shipToPartnerDate = shipToPartnerDateRange || undefined
  return {
    seller_id: sellerId,
    tcin,
    license_plate,
    tracking_number,
    bill_of_lading,
    return_date: returnDate,
    crc_received_date: crcRecievedDate,
    ship_date: shipToPartnerDate,
    is_online: returnChannelValue,
    location_id,
    return_order_number,
    receiving_location_id,
    shipment_id,
  }
}

const updateOnlineTrackingData = (returnData: Return[]) => {
  return returnData.map((returnItem) => {
    if (returnItem.tracking_data?.length) {
      returnItem.tracking_data.forEach((trackingDataPoint) => {
        if (returnItem.is_online) {
          trackingDataPoint.license_plate = 'N/A'
          return trackingDataPoint
        }

        if (trackingDataPoint?.inventory_removal_reason === 'VOID' ?? false) {
          trackingDataPoint.license_plate = 'VOID'
          return trackingDataPoint
        }
      })
    }

    return returnItem
  })
}

export const ReturnedOrdersPage = () => {
  const sellerId = useSelector(currentSellerId)
  const returnCenters = useSelector(getReturnCenters)
  const reduxDispatch = useDispatch()

  const [searchParams, searchParamActions, appliedFilterCount] =
    useSearchParams<SearchParams>(initialSearchParams)

  const [bolTotal, setBolTotal] = useState<number>()
  const [returnsTotal, setReturnsTotal] = useState<number>()

  const fieldList: EnhancedTableFieldType<Return>[] = [
    {
      key: 'return_order_number',
      heading: 'Return Order Number',
      hasSort: true,
      formatCell: ({ seller_id, order_id, return_order_number }) => {
        const toPath = `/${seller_id}/orders/${order_id}`
        const previousPage = `/${seller_id}${RoutePath.RETURNED_ORDERS}`
        return (
          <Link
            to={{
              pathname: toPath,
              search: `?tab=${OrderTab.RETURNS}`,
            }}
            state={{ previousPage }}
          >
            {return_order_number}
          </Link>
        )
      },
    },
    ...(sellerId
      ? [
          {
            key: 'external_id',
            heading: 'Partner SKU',
          },
        ]
      : [
          {
            key: 'tcin',
            heading: 'Partner TCIN',
          },
        ]),
    {
      key: 'quantity',
      heading: 'Return Qty',
      hasSort: true,
      formatCell: (item) => formatLocaleNumber(item.quantity),
    },
    {
      key: '',
      heading: 'License Plate Number',
      cellPadding: `16px 16px 16px 0`,
      formatCell: (item) => {
        if (!item.tracking_data?.length) {
          return ''
        }

        if (
          !item.tracking_data.some((trackingData) => trackingData.license_plate)
        ) {
          return ''
        }

        const lastIndex = item.tracking_data.length - 1

        const dates = item.tracking_data.map((el, index) => {
          const licensePlate = el.license_plate ?? ''

          return (
            <StyledListItem
              key={index}
              index={index}
              isLastItem={index === lastIndex}
            >
              {licensePlate}
            </StyledListItem>
          )
        })

        return dates
      },
    },
    {
      key: 'tracking_data',
      heading: 'Tracking/PRO Number',
      formatCell: (item: Return) => {
        if (!item.tracking_data?.length) {
          return ''
        }

        if (
          !item.tracking_data.some(
            (trackingData) => trackingData.tracking_number,
          )
        ) {
          return ''
        }

        const lastIndex = item.tracking_data.length - 1

        const dates = item.tracking_data.map((el, index) => {
          if (item.is_online && !el.ship_date) {
            return ''
          }

          const trackingNumber = el.tracking_number ?? ''
          const scac = el.scac ?? ''

          return (
            <StyledListItem
              key={index}
              index={index}
              isLastItem={index === lastIndex}
            >
              <TrackingNumberLink trackingNumber={trackingNumber} scac={scac} />
            </StyledListItem>
          )
        })

        return dates
      },
    },
    {
      key: 'return_date',
      heading: 'Return Initiated Date',
      hasSort: true,
      formatCell: formatDateMDYT('return_date'),
    },
    ...(sellerId
      ? []
      : [
          {
            key: '',
            heading: 'Received by CRC Date',
            cellPadding: `16px 0 16px 16px`,
            formatCell: (item: Return) => {
              if (!item.tracking_data?.length) {
                return ''
              }

              const lastIndex = item.tracking_data.length - 1
              const defaultCrcReceivedDateValue = item.is_online ? 'N/A' : ''
              const dates = item.tracking_data.map((el, index) => {
                let crcReceivedDate = el.crc_received
                  ? formatDate(el.crc_received, DATE_FORMAT_MONTH_DAY_YEAR_TIME)
                  : defaultCrcReceivedDateValue

                const content =
                  crcReceivedDate === '' ? (
                    // Use zero width space character to make table cells with no content have the same height.
                    <div
                      dangerouslySetInnerHTML={createMarkup(ZERO_WIDTH_SPACE)}
                    />
                  ) : (
                    crcReceivedDate
                  )

                return (
                  <StyledListItem
                    key={index}
                    index={index}
                    isLastItem={index === lastIndex}
                    data-testid="returns-crc-date"
                  >
                    {content}
                  </StyledListItem>
                )
              })

              return dates
            },
          },
        ]),
    {
      key: '',
      heading: 'Shipped to Partner Date',
      cellPadding: `16px 0 16px 16px`,
      formatCell: (item: Return) => {
        if (!item.tracking_data?.length) {
          return ''
        }

        if (
          !item.tracking_data.some((trackingData) => trackingData.ship_date)
        ) {
          return ''
        }

        const lastIndex = item.tracking_data.length - 1

        const dates = item.tracking_data.map((el, index) => {
          const shipDate = el.ship_date
            ? formatDate(el.ship_date, DATE_FORMAT_MONTH_DAY_YEAR_TIME)
            : ''

          const content =
            shipDate === '' ? (
              // Use zero width space character to make table cells with no content have the same height.
              <div dangerouslySetInnerHTML={createMarkup(ZERO_WIDTH_SPACE)} />
            ) : (
              shipDate
            )

          return (
            <StyledListItem
              key={index}
              index={index}
              isLastItem={index === lastIndex}
            >
              {content}
            </StyledListItem>
          )
        })

        return dates
      },
    },
    ...(sellerId
      ? []
      : [
          {
            key: 'seller_id',
            heading: 'Partner Name',
            hasSort: false,
            formatCellAsync: async ({ seller_id }: { seller_id: string }) => {
              const displayName = await memoizedGetSellerDisplayName(seller_id)
              return (
                <Link to={`/${seller_id}${RoutePath.DASHBOARD}`}>
                  {displayName}
                </Link>
              )
            },
          },
        ]),
  ]

  const handleFilterClear = () => {
    searchParamActions.updateSearchParam(initialSearchParams)
  }

  const handleCreateReport =
    ({ type }: GenerateReportRequest) =>
    () => {
      const licensePlate = searchParams.license_plate
      const trackingNumber = searchParams.tracking_number
      const tcin = searchParams.tcin
      const billOfLading = searchParams.bill_of_lading
      const locationId = searchParams.location_id
      const returnOrderNumber = searchParams.return_order_number
      const receivingLocationId = searchParams.receiving_location_id
      const returnStartDate = searchParams.returnStartDate
        ? isoStringStartOfDay(searchParams.returnStartDate)
        : undefined

      const returnEndDate = searchParams.returnEndDate
        ? isoStringEndOfDay(searchParams.returnEndDate)
        : undefined
      const shipmentId = searchParams.shipment_id

      let is_online: boolean | undefined
      if (searchParams.return_channel === 'Online') {
        is_online = true
      } else if (searchParams.return_channel) {
        is_online = false
      }

      let crcReceivedDate: string | undefined
      if (
        searchParams.crcReceivedStartDate ||
        searchParams.crcReceivedEndDate
      ) {
        const crcReceivedStartDate = searchParams.crcReceivedStartDate
          ? isoStringStartOfDay(searchParams.crcReceivedStartDate)
          : ''

        const crcReceivedEndDate = searchParams.crcReceivedEndDate
          ? isoStringEndOfDay(searchParams.crcReceivedEndDate)
          : ''
        crcReceivedDate = `${crcReceivedStartDate}/${crcReceivedEndDate}`
      }

      let shipToPartnerDate: string | undefined
      if (
        searchParams.shipToPartnerStartDate ||
        searchParams.shipToPartnerEndDate
      ) {
        const shipToPartnerStartDate = searchParams.shipToPartnerStartDate
          ? isoStringStartOfDay(searchParams.shipToPartnerStartDate)
          : ''

        const shipToPartnerEndDate = searchParams.shipToPartnerEndDate
          ? isoStringEndOfDay(searchParams.shipToPartnerEndDate)
          : ''

        shipToPartnerDate = `${shipToPartnerStartDate}/${shipToPartnerEndDate}`
      }

      return submitReport({
        type,
        sellerId: searchParams.seller_id ?? sellerId,
        startDate: returnStartDate,
        endDate: returnEndDate,
        parameters: {
          tcin,
          is_online,
          license_plate: licensePlate,
          tracking_number: trackingNumber,
          bill_of_lading: billOfLading,
          location_id: locationId,
          crc_received_date: crcReceivedDate,
          ship_date: shipToPartnerDate,
          return_order_number: returnOrderNumber,
          receiving_location_id: receivingLocationId,
          shipment_id: shipmentId,
        },
      })
    }

  const handleDownload = () => {
    const reportType = sellerId
      ? ReportType.RETURN_ORDERS_EXTERNAL
      : ReportType.RETURN_ORDERS_INTERNAL
    const params = {
      dialogEnum: DialogEnum.REPORT_DOWNLOAD_DIALOG,
      componentProps: {
        title: 'GENERATING REPORT...PLEASE WAIT',
        reportTitle: 'Returned Orders',
        sellerId: sellerId ?? undefined,
        createReport: handleCreateReport({
          type: reportType,
        }),
      },
    }
    reduxDispatch(openDialog(params))
  }

  const labeledData = [
    {
      label: 'Total Returns:',
      data: returnsTotal ? formatLocaleNumber(returnsTotal) : 0,
    },
  ]

  const { data: returns, isLoading } = useQuery(
    [RETURNS_SEARCH, sellerId, searchParams],
    () => {
      const sellerIdParam = sellerId ?? searchParams.seller_id

      const params = buildSearchParams({
        sellerId: sellerIdParam,
        tcin: searchParams.tcin,
        license_plate: searchParams.license_plate,
        tracking_number: searchParams.tracking_number,
        returnStartDate: searchParams.returnStartDate,
        returnEndDate: searchParams.returnEndDate,
        crcReceivedStartDate: searchParams.crcReceivedStartDate,
        crcReceivedEndDate: searchParams.crcReceivedEndDate,
        shipToPartnerStartDate: searchParams.shipToPartnerStartDate,
        shipToPartnerEndDate: searchParams.shipToPartnerEndDate,
        return_channel: searchParams.return_channel,
        bill_of_lading: searchParams.bill_of_lading,
        location_id: searchParams.location_id,
        return_order_number: searchParams.return_order_number,
        receiving_location_id: searchParams.receiving_location_id,
        shipment_id: searchParams.shipment_id,
      })

      if (
        params?.bill_of_lading !== undefined &&
        Object.values({ ...params, bill_of_lading: undefined }).every(
          (field) => field === undefined,
        )
      ) {
        getReturnQuantity(params).then((resp) => {
          setBolTotal(resp)
        })
      } else {
        setBolTotal(undefined)
      }
      return searchProductReturns(
        {
          direction: searchParams.direction,
          orderBy: searchParams.orderBy,
          page: searchParams.page,
          perPage: searchParams.perPage,
        },
        {
          ...params,
        },
      )
    },
  )

  const { data: initialReturns } = useQuery({
    queryKey: [INITIAL_RETURNS_SEARCH, returns, sellerId],
    queryFn: () => {
      if (!returnsTotal) {
        if (!isEqual(initialSearchParams, searchParams)) {
          return searchProductReturns(
            {
              direction: initialSearchParams.direction,
              orderBy: initialSearchParams.orderBy,
              page: initialSearchParams.page,
              perPage: 1,
            },
            {
              seller_id: sellerId,
            },
          )
        } else {
          return returns
        }
      }
      return null
    },
    enabled: !!returns,
  })

  useEffect(() => {
    if (initialReturns) setReturnsTotal(initialReturns?.total)
  }, [initialReturns])

  const returnedOrders = returns?.data
    ? updateOnlineTrackingData(returns.data)
    : []
  const filteredCount = returns?.total ?? 0

  const title = sellerId ? 'Returns' : 'All Returns'

  return (
    <div id="scroll-ref">
      <HeaderTitle title={title} />
      <Grid container spacing={2}>
        <StyledTitleContainer item xs={12}>
          <StyledTitle>
            <LabeledDataList data={labeledData} />
          </StyledTitle>
        </StyledTitleContainer>
        <Grid item xs={12}>
          <FilterBar
            count={filteredCount}
            customCountTitle={bolTotal ? 'Unit Qty in BOL' : undefined}
            customCountValue={bolTotal}
            onClear={handleFilterClear}
            appliedFilterCount={appliedFilterCount}
            onDownload={handleDownload}
            disableDownload={checkDownloadLimit(filteredCount)}
            downloadTooltip={DOWNLOAD_COUNT_LIMIT_TOOLTIP}
          >
            <StyledRow container justifyContent="flex-start" spacing={2}>
              <Grid item xs={3}>
                <TypeaheadFilter
                  label="Return Order #"
                  value={searchParams.return_order_number}
                  sellerId={sellerId}
                  searchParam="return_order_number"
                  onChange={searchParamActions.updateSearchParam}
                />
              </Grid>
              <Grid item xs={3}>
                <TypeaheadFilter
                  label="Tracking"
                  value={searchParams.tracking_number}
                  sellerId={sellerId}
                  searchParam="tracking_number"
                  onChange={searchParamActions.updateSearchParam}
                />
              </Grid>
              <Grid item xs={3}>
                <TypeaheadFilter
                  label="License Plate"
                  value={searchParams.license_plate}
                  sellerId={sellerId}
                  searchParam="license_plate"
                  onChange={searchParamActions.updateSearchParam}
                />
              </Grid>
              <Grid item xs={3}>
                <TypeaheadFilter
                  label="Shipment ID"
                  value={searchParams.shipment_id}
                  sellerId={sellerId}
                  searchParam="shipment_id"
                  onChange={searchParamActions.updateSearchParam}
                />
              </Grid>
            </StyledRow>
            <StyledRow container justifyContent="flex-start" spacing={2}>
              <Grid item xs={3}>
                <TypeaheadFilter
                  label="Bill of Lading"
                  value={searchParams.bill_of_lading}
                  sellerId={sellerId}
                  searchParam="bill_of_lading"
                  onChange={searchParamActions.updateSearchParam}
                />
              </Grid>
              <Grid item xs={3}>
                <TypeaheadFilter
                  label="Item"
                  value={searchParams.tcin}
                  sellerId={sellerId}
                  searchParam="tcin"
                  onChange={searchParamActions.updateSearchParam}
                />
              </Grid>
            </StyledRow>
            <StyledRow container justifyContent="flex-start" spacing={2}>
              {!sellerId && (
                <Grid item xs={3}>
                  <TypeaheadFilter
                    label="Partner"
                    value={searchParams.seller_id}
                    sellerId={sellerId}
                    searchParam="seller_id"
                    onChange={searchParamActions.updateSearchParam}
                  />
                </Grid>
              )}
              <Grid item xs={3}>
                <SelectFilter
                  label="Return Channel"
                  placeholder="Return Channel"
                  value={searchParams.return_channel}
                  searchParam="return_channel"
                  onChange={searchParamActions.updateSearchParam}
                  data={[
                    {
                      name: 'Online',
                      value: 'Online',
                    },
                    {
                      name: 'In Store',
                      value: 'In Store',
                    },
                  ]}
                />{' '}
              </Grid>
              <Grid item xs={3}>
                <SelectFilter
                  label="Returns Center Location"
                  placeholder="Ex.: Indianapolis"
                  value={searchParams.receiving_location_id?.toString()}
                  searchParam="receiving_location_id"
                  onChange={searchParamActions.updateSearchParam}
                  data={returnCenters.map((returnCenter) => {
                    return {
                      name: returnCenter.properties.location,
                      value: returnCenter.properties.id.toString(),
                    }
                  })}
                />
              </Grid>
              <Grid item xs={3}>
                <TypeaheadFilter
                  label="Store Location"
                  value={searchParams.location_id}
                  searchParam="location_id"
                  onChange={searchParamActions.updateSearchParam}
                />
              </Grid>
            </StyledRow>
            <StyledRow container justifyContent="flex-start" spacing={2}>
              <Grid item xs={3}>
                <DateRangeFilter
                  label="Return Initiated Date"
                  startValue={searchParams.returnStartDate}
                  startSearchParam="returnStartDate"
                  endValue={searchParams.returnEndDate}
                  endSearchParam="returnEndDate"
                  onChange={searchParamActions.updateSearchParam}
                />
              </Grid>
              {!sellerId && (
                <Grid item xs={3}>
                  <DateRangeFilter
                    label="Received by CRC Date"
                    startValue={searchParams.crcReceivedStartDate}
                    startSearchParam="crcReceivedStartDate"
                    endValue={searchParams.crcReceivedEndDate}
                    endSearchParam="crcReceivedEndDate"
                    onChange={searchParamActions.updateSearchParam}
                  />
                </Grid>
              )}
              <Grid item xs={3}>
                <DateRangeFilter
                  label="Shipped to Partner Date"
                  startValue={searchParams.shipToPartnerStartDate}
                  startSearchParam="shipToPartnerStartDate"
                  endValue={searchParams.shipToPartnerEndDate}
                  endSearchParam="shipToPartnerEndDate"
                  onChange={searchParamActions.updateSearchParam}
                />
              </Grid>
            </StyledRow>
          </FilterBar>
          <TableSpacer>
            <EnhancedTable
              data={returnedOrders}
              fieldList={fieldList}
              isLoading={isLoading}
              total={filteredCount}
              verticalAlign="top"
              {...getEnhancedTablePageableProps(
                searchParams,
                searchParamActions,
              )}
            />
          </TableSpacer>
        </Grid>
      </Grid>
    </div>
  )
}

export default ReturnedOrdersPage
