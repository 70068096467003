import apiConfig, { ENVIRONMENT_PRODUCTION } from 'config/apiConfig'

const stageRoundelUrl = 'https://roundelmediastudio.perf.target.com/'
const prodRoundelUrl = 'https://roundelmediastudio.target.com/'

export const getRoundelUrl = () => {
  return apiConfig.environment !== ENVIRONMENT_PRODUCTION
    ? stageRoundelUrl
    : prodRoundelUrl
}
