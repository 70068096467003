import EditDatesClosed from '../EditDatesClosed'
import ShippingHoursTable from './ShippingHoursTable'
import { validationSchema as nameValidationSchema } from '../EditDistributionName'
import { validationSchema as workingHoursTimezoneValidationSchema } from '../EditHoursOfOperation'

import styled from '@emotion/styled'

import { Box, Grid, InputLabel, Paper, TextField } from '@mui/material'

import { AddressInput } from 'components/common/AddressInput'
import Select from 'components/common/Select'

import { LABEL_DISTRIBUTION_TIME_ZONE } from 'constants/labels'
import TIMEZONES from 'constants/timezones'

import { validationHandler } from 'services/validation'

import { getAddressValidationHandler } from 'services/addressHelper'
import { DistributionCenterFieldValues } from '.'

const StyledAddressBackDrop = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(2, 2, 36, 2),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4),
}))

const StyledBackDropTitle = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

export interface Props {
  isPending: boolean
  fieldValues: DistributionCenterFieldValues
  fieldErrors: any
  setFieldValue: (name: string, value: any) => void
  setFieldError: (field: string, validation: any) => void
}

export const AddDistributionCenterPageOne = ({
  isPending,
  fieldValues,
  fieldErrors,
  setFieldValue,
  setFieldError,
}: Props) => {
  const len = 20 - fieldValues.name.length
  const subject = len === 1 ? 'character' : 'characters'
  const nameHelpText = `${len} ${subject} left (Maximum of 20).`

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFieldValue(name, value)
    const { validation } = validationHandler(nameValidationSchema, {
      name: value,
    })
    setFieldError(name, validation)
  }

  const handleAddressChange = (value: string, property: string) => {
    setFieldValue('address', { [property]: value })
    const { validation } = getAddressValidationHandler({
      ...fieldValues.address,
      [property]: value,
    })
    setFieldError(property, { [property]: validation[property] })
  }

  const handleDatesClosedChange = (value: string, remove: boolean) => {
    let closed

    if (remove) {
      closed = fieldValues.daysClosed.filter(
        (closedDay: string) => closedDay !== value,
      )
    } else {
      closed = [...fieldValues.daysClosed, value]
    }

    setFieldValue('daysClosed', closed)
  }

  const handleTimeZoneChange = (value: any, property: string) => {
    setFieldValue(property, value)
    const { validation } = validationHandler(
      workingHoursTimezoneValidationSchema,
      {
        [property]: value,
      },
    )
    setFieldError(property, validation)
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={12} md={6}>
          <InputLabel shrink>Distribution Center Name</InputLabel>
          <TextField
            data-testid="name"
            value={fieldValues.name}
            error={!!fieldErrors.name}
            required
            disabled={isPending}
            onChange={handleNameChange}
            helperText={fieldErrors.name ? fieldErrors.name : nameHelpText}
            name="name"
            fullWidth
          />
          <StyledAddressBackDrop elevation={0}>
            <StyledBackDropTitle>Shipping Address*</StyledBackDropTitle>
            <AddressInput
              handleInputChange={handleAddressChange}
              isPending={isPending}
              address={fieldValues.address}
              validation={fieldErrors}
            />
          </StyledAddressBackDrop>
          <EditDatesClosed
            onChange={handleDatesClosedChange}
            daysClosed={fieldValues.daysClosed}
            isDisabled={isPending}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <Box sx={{ mb: 4.6 }}>
            <Select
              isRequired
              id="time-zone-code"
              name="timezone"
              label={LABEL_DISTRIBUTION_TIME_ZONE}
              isDisabled={isPending}
              onChange={handleTimeZoneChange}
              options={TIMEZONES}
              value={fieldValues.timezone}
              keyName="code"
              valueName="name"
              validation={fieldErrors.timezone}
            />
          </Box>
          <ShippingHoursTable
            isPending={isPending}
            fieldValues={fieldValues}
            setFieldValue={setFieldValue}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default AddDistributionCenterPageOne
