import TitleBar from 'components/common/TitleBar'
import { useState, useEffect, memo } from 'react'
import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import TableSpacer from 'components/common/TableSpacer'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import { getBulkLegalizationReports, pollReport } from 'services/reports'
import { useParams } from 'react-router-dom'
import { Direction } from 'services/pageableHelper'
import { Report, ReportStatus, ReportType } from '../../../src/types/Report'
import DownloadReportButton from 'components/common/ReportCard/DownloadReportButton'
import { useStateValue } from 'stateManager/StateProvider'
import useInterval from 'hooks/useInterval'
import GenerationBar from './GenerationBar'
import { currentSellerDisplayName } from 'store/selectors'

interface Props {
  title: string
  setBulkFileUpload?: () => void
}

export const ReportsTable = ({ title }: Props) => {
  const { sellerId } = useParams()
  const sellerName = useSelector(currentSellerDisplayName)
  const [reports, setReports] = useState<Report[]>([])
  const [inProgressReport, setInProgressReport] = useState<Report>()
  const [isLoading, setIsLoading] = useState(false)
  const { state, updateState } = useStateValue()

  const fieldList: EnhancedTableFieldType<Report>[] = [
    {
      key: 'type',
      heading: 'File Name',
      minWidth: 20,
      formatCell: () => {
        return `Bulk item type summary_${sellerName}`
      },
    },
    {
      key: 'created_by',
      heading: 'Generated By',
      minWidth: 40,
    },
    {
      key: 'last_modified',
      heading: 'Generated On',
      minWidth: 40,
      formatCell: (report: Report) => {
        return <GenerationBar report={report} />
      },
    },
    {
      key: 'feedback',
      heading: 'Processing Comments',
      minWidth: 40,
      formatCell: (report: Report) => {
        return <Typography>{report?.feedback}</Typography>
      },
    },
    {
      key: 'download_url',
      heading: 'Action',
      minWidth: 20,
      formatCell: (report: Report) => {
        return (
          <DownloadReportButton
            text="Download"
            report={report}
            isDisabled={!report.download_url}
            title="Download Report"
            hasDateRange={false}
          />
        )
      },
    },
  ]

  useInterval(
    () => {
      if (!inProgressReport) return

      pollReport(inProgressReport.id, sellerId).then(async (report) => {
        if (
          report.status === ReportStatus.PENDING ||
          report.status === ReportStatus.PROCESSING
        ) {
          return
        }

        setInProgressReport(undefined)
      })
    },
    inProgressReport ? 5000 : null,
  )

  useEffect(() => {
    if (!inProgressReport) {
      getBulkLegalizationReports(
        {
          page: 0,
          perPage: 100,
          direction: Direction.DESC,
          orderBy: 'created',
        },
        { type: ReportType.BULK_LEGALIZATION, sellerId },
      ).then(async (reports: any) => {
        setReports(reports.data)
      })
    }
  }, [inProgressReport, sellerId])

  useEffect(() => {
    if (state.isNewFileUpload) {
      setIsLoading(true)
      getBulkLegalizationReports(
        {
          page: 0,
          perPage: 100,
          direction: Direction.DESC,
          orderBy: 'created',
        },
        { type: ReportType.BULK_LEGALIZATION, sellerId },
      )
        .then(async (reports: any) => {
          setIsLoading(false)
          setReports(reports.data)
          updateState({
            isNewFileUpload: false,
          })
          const inProgress = reports.data.find(
            (report: Report) =>
              report.status === ReportStatus.PENDING ||
              report.status === ReportStatus.PROCESSING,
          )
          setInProgressReport(inProgress)
        })
        .finally(() => setIsLoading(false))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerId, state.isNewFileUpload])

  return (
    <>
      <TitleBar title={title} />
      <Typography id="generate-reports-table-subtitle1">
        This section allows the download of summary files for sellers configured
        by bulk upload item type feature.
      </Typography>
      <Typography id="generate-reports-table-subtitle1">
        Please note that duplicate item types will be removed from the uploaded
        file. Large file uploads may need upto an hour for processing and the
        files generated will be available for a period of 2 weeks from the date
        of upload.
      </Typography>
      <TableSpacer>
        <EnhancedTable
          fieldList={fieldList}
          data={reports}
          isLoading={isLoading}
        />
      </TableSpacer>
    </>
  )
}

export default memo(ReportsTable)
