import { useState, useEffect } from 'react'
import { withFormik, Form, FormikProps } from 'formik'
import * as yup from 'yup'

import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import { AttributeValueTypeahead } from 'components/common/Typeahead'
import InputField from 'components/common/form/InputField'
import SelectField from 'components/common/form/SelectField'
import { TIME, PERIOD } from 'components/common/TimePicker'

import {
  getAttributeValuesById,
  searchAcrossItemTaxonomyBy,
} from 'services/itemTaxonomies'
import { ValidationSchema, maxCharacterCount } from 'services/validation'
import {
  getTimePeriod,
  getIsoString,
  getTimeIn12Hour,
  isAfterDate,
  formatDate,
  DATE_PICKER_FORMAT,
} from 'services/dateService'
import { updateSeasonalEvent } from 'services/seasonalEvents'
import { isOneOfUserRoles } from 'services/authorization'
import { USER_ROLE_ADMIN, USER_ROLE_PROGRAM_MANAGER } from 'services/roles'

import { SeasonalEvent } from 'types/SeasonalEvent'
import { ITEM_TYPE, ITEM_SUBTYPE, MERCH_TYPE } from 'types/Attribute'
import { AttributeValue } from 'types/AttributeValue'

import { SEASON_OR_EVENT_DEPICTED_ID } from 'constants/attributes'

import useZoomTracking from 'hooks/useZoomTracking'

const StyledRoot = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
}))

const StyledGrid = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}))

const validationSchema: ValidationSchema = yup.object().shape({
  name: yup
    .string()
    .label('Name')
    .test({
      name: 'isName',
      test: maxCharacterCount('name', 50),
    })
    .required(),
  description: yup
    .string()
    .label('Description')
    .test({
      name: 'isDescription',
      test: maxCharacterCount('description', 80),
    })
    .required(),
  startDate: yup.string().label('Start Date').required(),
  startTime: yup.string().label('Start Time').required(),
  startPeriod: yup.string().label('AM/PM').required(),
  endDate: yup.string().label('End Date').required(),
  endTime: yup.string().label('End Time').required(),
  endPeriod: yup.string().label('AM/PM').required(),
  suggestedAssortment: yup
    .string()
    .label('Suggested Assortment')
    .test({
      name: 'isSuggestedAssortment',
      test: maxCharacterCount('suggestedAssortment', 200),
    }),
})

export const handleLoadOptions = async (name: string) => {
  if (!name) {
    return Promise.resolve([])
  }

  const results = await Promise.all([
    searchAcrossItemTaxonomyBy({
      name,
      type: ITEM_TYPE,
    }),
    searchAcrossItemTaxonomyBy({
      name,
      type: ITEM_SUBTYPE,
    }),
    searchAcrossItemTaxonomyBy({
      name,
      type: MERCH_TYPE,
    }),
  ])

  return results.flat()
}

export interface FormValues {
  name: string
  description: string
  startDate: string
  startTime: string
  startPeriod: string
  endDate: string
  endTime: string
  endPeriod: string
  seasonalAttributeValue: any
  suggestedAssortment: string
}

export interface ContainerProps {
  isOpen: boolean
  seasonalEvent?: SeasonalEvent
  memberOf?: string[]
  handleClose: () => void
  handleDelete: (seasonalEvent: SeasonalEvent) => () => void
}
export interface ComponentProps extends FormValues, ContainerProps {
  isValid: boolean
  handleSubmit: any
}

export type Props = ComponentProps & FormikProps<FormValues>

export const EditSeasonalEventForm = ({
  seasonalEvent,
  memberOf,
  isOpen,
  handleSubmit,
  handleDelete,
  values,
  isSubmitting,
  isValid,
  dirty,
  setFieldValue,
}: Props) => {
  const [pending, setPending] = useState(false)
  const [seasonalEventValue, setSeasonalEventValue] = useState<AttributeValue>()

  const canDelete =
    seasonalEvent?.id &&
    isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN, USER_ROLE_PROGRAM_MANAGER])

  useEffect(() => {
    let mounted = true

    if (seasonalEvent?.id && seasonalEvent?.seasonal_attribute_value_id) {
      setPending(true)

      getAttributeValuesById([seasonalEvent.seasonal_attribute_value_id]).then(
        (attributeValue) => {
          if (mounted && attributeValue.length > 0) {
            setSeasonalEventValue(attributeValue[0])

            setPending(false)
          }
        },
      )
    }
    return () => {
      mounted = false
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSeasonEventChange = (
    attributeValue: Nullable<AttributeValue>,
  ) => {
    if (attributeValue) {
      setFieldValue('seasonalAttributeValue', attributeValue)
    }
  }

  const onSeasonalEventDelete = () => {
    setFieldValue('seasonalAttributeValue', '')
  }

  const hasZoomIncreased = useZoomTracking()
  return (
    <DialogContainer
      data-testid="edit-source-dialog"
      isOpen={isOpen}
      title={seasonalEvent?.id ? 'Edit Event' : 'Add Event'}
      isPending={isSubmitting}
      onSubmit={handleSubmit}
      onDelete={
        canDelete && seasonalEvent?.id ? handleDelete(seasonalEvent) : undefined
      }
      deleteButtonText={canDelete ? 'Delete' : undefined}
      isSubmitDisabled={!isValid || !dirty}
      disableScroll={hasZoomIncreased ? false : true}
    >
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <InputField
                  required
                  name="name"
                  label="Event Name"
                  disabled={isSubmitting}
                  maxCharacters={50}
                  enableOnChangeValidation
                />
              </Grid>
              <Grid item xs={3}>
                <InputField
                  required
                  type="date"
                  name="startDate"
                  label="Start Date"
                  max={values.endDate}
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={2}>
                <SelectField
                  required
                  name="startTime"
                  label="Start Time"
                  options={TIME}
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={2}>
                <SelectField
                  required
                  name="startPeriod"
                  label="AM/PM"
                  options={PERIOD}
                  disabled={isSubmitting}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <InputField
                  required
                  multiline
                  maxRows={4}
                  name="description"
                  label="Guest Mindset"
                  disabled={isSubmitting}
                  maxCharacters={80}
                  enableOnChangeValidation
                />
              </Grid>
              <Grid item xs={3}>
                <InputField
                  required
                  type="date"
                  name="endDate"
                  label="End Date"
                  min={values.startDate}
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={2}>
                <SelectField
                  required
                  name="endTime"
                  label="End Time"
                  options={TIME}
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={2}>
                <SelectField
                  required
                  name="endPeriod"
                  label="AM/PM"
                  options={PERIOD}
                  disabled={isSubmitting}
                />
              </Grid>
            </Grid>
          </Grid>
          {!pending && (
            <StyledRoot>
              <Grid item xs={12}>
                <AttributeValueTypeahead
                  attributeId={SEASON_OR_EVENT_DEPICTED_ID}
                  label='Value to Send for Attribute "season or event depicted"'
                  value={seasonalEventValue}
                  onChange={handleSeasonEventChange}
                  isDisabled={isSubmitting}
                  onClear={onSeasonalEventDelete}
                />
              </Grid>
            </StyledRoot>
          )}
          <StyledGrid item xs={12}>
            <InputField
              multiline
              maxRows={4}
              name="suggestedAssortment"
              label="Suggested Assortment"
              disabled={isSubmitting}
              maxCharacters={200}
              enableOnChangeValidation
            />
          </StyledGrid>
        </Grid>
      </Form>
    </DialogContainer>
  )
}

export const EditSeasonalEvent = withFormik<ComponentProps, FormValues>({
  mapPropsToValues: (props) => {
    const { seasonalEvent } = props

    let startDate = ''
    let startTime = ''
    let startPeriod = ''
    let endDate = ''
    let endTime = ''
    let endPeriod = ''

    if (seasonalEvent?.start_date) {
      startDate = formatDate(seasonalEvent.start_date, DATE_PICKER_FORMAT)
      startTime = getTimeIn12Hour(seasonalEvent.start_date) ?? startTime
      startPeriod = getTimePeriod(seasonalEvent.start_date) ?? startPeriod
    }

    if (seasonalEvent?.end_date) {
      endDate = formatDate(seasonalEvent.end_date, DATE_PICKER_FORMAT)
      endTime = getTimeIn12Hour(seasonalEvent.end_date) ?? endTime
      endPeriod = getTimePeriod(seasonalEvent.end_date) ?? endPeriod
    }

    return {
      name: seasonalEvent?.name ?? '',
      description: seasonalEvent?.description ?? '',
      startDate,
      startTime,
      startPeriod,
      endDate,
      endPeriod,
      endTime,
      suggestedAssortment: seasonalEvent?.suggested_assortment ?? '',
      seasonalAttributeValue: seasonalEvent?.seasonal_attribute_value_id ?? '',
    }
  },
  handleSubmit: async (values, { props, setFieldError, setSubmitting }) => {
    const {
      name,
      description,
      startDate,
      startTime,
      startPeriod,
      endDate,
      endTime,
      endPeriod,
      suggestedAssortment,
      seasonalAttributeValue,
    } = values

    const isoStartDate = getIsoString(
      `${startDate} ${startTime} ${startPeriod}`,
    )
    const isoEndDate = getIsoString(`${endDate} ${endTime} ${endPeriod}`)

    if (isoStartDate && isoEndDate) {
      if (isAfterDate(new Date(isoStartDate), new Date(isoEndDate))) {
        setFieldError('startDate', 'Start date cannot be after end date')
        setSubmitting(false)
        return
      }

      const seasonalEventUpdate: SeasonalEvent = {
        ...(props.seasonalEvent?.id ? { id: props.seasonalEvent.id } : {}),
        name,
        description,
        start_date: isoStartDate,
        end_date: isoEndDate,
        suggested_assortment: suggestedAssortment,
        seasonal_attribute_value_id: seasonalAttributeValue.id ?? undefined,
      }

      try {
        await updateSeasonalEvent(seasonalEventUpdate)

        props.handleClose()
      } catch (e) {
        setSubmitting(false)
      }
    }
  },
  enableReinitialize: true,
  validationSchema,
})(EditSeasonalEventForm)

export default EditSeasonalEvent
