import { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'

import Grid from '@mui/material/Grid'

import Banner from 'components/common/Banner'
import HeaderTitle from 'components/common/HeaderTitle'
import TwoColumnLayout from 'components/common/TwoColumnLayout'
import UnshippedOrdersCard from 'components/common/UnshippedOrdersCard'

import { isOneOfUserRoles } from 'services/authorization'
import { getEffectiveNotifications } from 'services/notifications'
import {
  USER_ROLE_ADMIN,
  USER_ROLE_APP_SMS_ADMIN,
  USER_ROLE_APP_SMS_READ,
  USER_ROLE_FINANCE,
  USER_ROLE_OPS,
} from 'services/roles'

import {
  currentSellerStatus,
  currentSellerId,
  currentSellerVmmId,
  getIsBannerOpen,
  getMemberOf,
  getSellerContacts,
  hasStripeAccountId,
  isSellersPending,
} from 'store/selectors'

import { Notification, NotificationDisplayOn } from 'types/Notifications'
import { SellerStatus } from 'types/Seller'

import DefunctSellerCard from './DefunctSellerCard'
import GuidedSellerSetupCard from './GuidedSellerSetupCard'
import OrderDefectsCard from './OrderDefectsCard'
import SellerFinancialsCard from './SellerFinancialsCard'
import SellerItemsCard from './SellerItemsCard'
import FullScreenLoader from 'components/common/loader/FullScreenLoader'

const financialCardRoles = [
  USER_ROLE_ADMIN,
  USER_ROLE_OPS,
  USER_ROLE_FINANCE,
  USER_ROLE_APP_SMS_ADMIN,
]

export const Dashboard = () => {
  const financialCardRenderRef = useRef(false) // ref to avoid rendering the card too multiple times as redux updates state
  const [notifications, setNotifications] = useState<Notification[]>([])

  const isBannerOpen = useSelector(getIsBannerOpen)
  const contacts = useSelector(getSellerContacts)
  const sellerId = useSelector(currentSellerId)
  const isPending = useSelector(isSellersPending)
  const memberOf = useSelector(getMemberOf)
  const hasStripeAccount = useSelector(hasStripeAccountId)
  const sellerStatus = useSelector(currentSellerStatus)
  const vmmId = useSelector(currentSellerVmmId)

  const financialCardAuthorized = isOneOfUserRoles(memberOf, financialCardRoles)
  const hasStripeEnabled =
    hasStripeAccount &&
    !isOneOfUserRoles(memberOf, [USER_ROLE_APP_SMS_READ]) &&
    isOneOfUserRoles(memberOf, [USER_ROLE_APP_SMS_ADMIN])

  useEffect(() => {
    if (contacts && sellerId) {
      getEffectiveNotifications({
        contacts,
        hasStripeEnabled,
        memberOf,
        sellerId,
        displayOn: NotificationDisplayOn.PARTNER_DASHBOARD,
      }).then((resp) => {
        setNotifications(resp)
      })
    }
  }, [contacts, hasStripeEnabled, memberOf, sellerId])

  useEffect(() => {
    if (
      financialCardRenderRef.current !== true &&
      isPending === false &&
      financialCardAuthorized
    ) {
      financialCardRenderRef.current = true
    }
  }, [financialCardAuthorized, isPending])

  const showStepper =
    sellerStatus === SellerStatus.INITIATED ||
    sellerStatus === SellerStatus.PARTNER_SETUP

  return (
    <div data-testid="dashboard">
      <HeaderTitle title="Dashboard" />
      {!isPending && (
        <>
          {isBannerOpen && (
            <Grid container spacing={2} data-testid="notification-banner">
              <Grid item xs={12}>
                <Banner notifications={notifications} />
              </Grid>
            </Grid>
          )}
          {sellerStatus === SellerStatus.DEFUNCT && <DefunctSellerCard />}
          {sellerId && sellerStatus !== SellerStatus.DEFUNCT && (
            <Grid container spacing={2}>
              {showStepper ? (
                <Grid data-testid="status-stepper" item xs={12}>
                  <GuidedSellerSetupCard />
                </Grid>
              ) : (
                <TwoColumnLayout>
                  <UnshippedOrdersCard />
                  <OrderDefectsCard sellerId={sellerId} vmmId={vmmId} />
                  <SellerItemsCard sellerId={sellerId} />
                  {financialCardAuthorized &&
                    financialCardRenderRef.current === true && (
                      <SellerFinancialsCard />
                    )}
                </TwoColumnLayout>
              )}
            </Grid>
          )}
        </>
      )}
      <FullScreenLoader isOpen={isPending} />
    </div>
  )
}

export default Dashboard
