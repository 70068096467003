import React from 'react'
import styled from '@emotion/styled'
import Text from 'components/common/Text'

const StyledPin = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: theme.palette.grey[900],
}))

const StyledUpArrow = styled('div')(({ theme }) => ({
  width: 0,
  height: 0,
  borderLeft: `${theme.spacing(0.75)} solid transparent`,
  borderRight: `${theme.spacing(0.75)}  solid transparent`,
  borderBottom: `${theme.spacing(0.75)}  solid ${theme.palette.grey[900]}`,
}))

export type PinType = {
  value: number
  title?: string
  label: string
}

export type Props = {
  pin: PinType
}

export const Pin: React.FC<Props> = ({ pin }) => {
  const targetRef = React.createRef<HTMLDivElement>()

  const [width, setWidth] = React.useState(0)

  React.useEffect(() => {
    if (targetRef.current) {
      setWidth(targetRef.current.getBoundingClientRect().width)
    }
  }, [targetRef])

  const paddingWidth = 4
  const borderWidth = 1
  const style = {
    alignItems: 'center',
    position: 'absolute',
    top: 26,
    left: `calc(${pin.value}% - ${width / 2}px)`,
    right: 'inherit',
  }

  if (pin.value === 0) {
    style.left = `calc(${pin.value}% - ${
      width / 2 - (paddingWidth + borderWidth)
    }px)`
  } else if (pin.value === 100) {
    style.left = 'inherit'
    style.right = `calc(0% - ${width / 2 - (paddingWidth + borderWidth)}px)`
  }

  return (
    <StyledPin ref={targetRef} style={style as any}>
      <StyledUpArrow />
      <Text type="bodySm">{pin.title || pin.value}</Text>
      <Text type="bodySm">{pin.label}</Text>
    </StyledPin>
  )
}

export default Pin
