import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Grid, Tabs, Tab, Typography } from '@mui/material'

import ContentSpacer from 'components/common/ContentSpacer'
import { DialogEnum } from 'components/common/Dialog'
import useTable from 'components/common/EnhancedTable/useTable'
import FilterBar from 'components/common/FilterBar'
import DateRangeFilter from 'components/common/FilterBar/DateRangeFilter'
import Link from 'components/common/Link'
import {
  buildSearchParams,
  SeasonlEventsTab,
  TabPanel,
  Filters,
} from './InternalSeasonalEvents'
import SeasonalEventsCalendar from './SeasonalEventsCalendar'
import SeasonalEventsList from './SeasonalEventsList'

import { isOneOfUserRoles } from 'services/authorization'
import {
  DATE_DISPLAY_FORMAT,
  formatDateLocalTime,
  getDateOneYearFromToday,
} from 'services/dateService'
import { getSeasonalEvents, deleteSeasonalEvent } from 'services/seasonalEvents'
import { Direction } from 'services/pageableHelper'
import { USER_ROLE_ADMIN, USER_ROLE_PROGRAM_MANAGER } from 'services/roles'
import { RoutePath } from 'services/NavigationHelper'

import { SeasonalEvent } from 'types/SeasonalEvent'

import { closeDialog, openDialog } from 'store/dialog/actionCreator'
import { getMemberOf } from 'store/selectors'

import { CONTENT_PADDING } from 'constants/layout'

const StyledTabs = styled(Tabs)({
  margin: `0 -${CONTENT_PADDING}px`,
  padding: `0 ${CONTENT_PADDING}px`,
})

export const ExternalSeasonalEvents = () => {
  const today = formatDateLocalTime(new Date(), DATE_DISPLAY_FORMAT)
  const oneYearFromToday = formatDateLocalTime(
    getDateOneYearFromToday(),
    DATE_DISPLAY_FORMAT,
  )
  const initialFilters = {
    start_date: today,
    end_date: oneYearFromToday,
  }

  const reduxDispatch = useDispatch()
  const memberOf = useSelector(getMemberOf)

  const [tabValue, setTabValue] = useState<SeasonlEventsTab>(
    SeasonlEventsTab.CALENDAR,
  )
  const [refreshCount, setRefreshCount] = useState(0)
  const [filters, setFilters] = useState<Filters>(initialFilters)
  const [pending, setPending] = useState(true)
  const [seasonalEvents, setSeasonalEvents] = useState<SeasonalEvent[]>([])
  const [total, setTotal] = useState(0)

  const { table } = useTable({
    direction: Direction.ASC,
    orderBy: 'start_date',
    page: 0,
    perPage: 100,
  })

  useEffect(() => {
    let mounted = true

    setPending(true)

    getSeasonalEvents(table.state, { ...buildSearchParams(filters) }).then(
      (seasonalEvents) => {
        if (mounted) {
          setSeasonalEvents(seasonalEvents.data)
          setTotal(seasonalEvents.total)
          setPending(false)
        }

        return () => {
          mounted = false
        }
      },
    )
  }, [table.state, refreshCount, filters])

  const handleFilterChange = (filter?: keyof Filters) => (value: any) => {
    const searchParam = filter ?? Object.keys(value)[0]
    const val = value[searchParam]

    setFilters((prev) => ({
      ...prev,
      [searchParam]: val,
    }))
  }

  const onFilterClear = () => {
    setFilters({
      start_date: undefined,
      end_date: undefined,
    })
  }

  const refreshData = () => {
    setRefreshCount((prev: number) => prev + 1)
  }

  const handleTabChange = (_event: any, newValue: SeasonlEventsTab) => {
    setTabValue(newValue)
  }

  const handleEventAddEdit = (seasonalEvent?: SeasonalEvent) => {
    const handleClose = () => {
      reduxDispatch(closeDialog())
    }

    const onDelete = async (seasonalEvent: SeasonalEvent) => {
      await deleteSeasonalEvent(seasonalEvent)
      handleClose()
    }

    const confirmDelete = (seasonalEvent: SeasonalEvent) => () => {
      reduxDispatch(closeDialog())

      reduxDispatch(
        openDialog({
          dialogEnum: DialogEnum.CONFIRMATION_DIALOG,
          componentProps: {
            title: `Delete ${seasonalEvent.name}`,
            submitText: 'Delete',
            element: seasonalEvent,
            content:
              'Are you sure you want to delete this event from the seasonal event calendar? This will remove the event for all partners.',
            onRequestSubmit: onDelete,
          },
          closeCallback: refreshData,
        }),
      )
    }

    const data = seasonalEvent ?? {}

    hasEdit
      ? reduxDispatch(
          openDialog({
            dialogEnum: DialogEnum.EDIT_SEASONAL_EVENT,
            componentProps: {
              handleClose,
              handleDelete: confirmDelete,
              memberOf,
              seasonalEvent: { ...data },
            },
            closeCallback: refreshData,
          }),
        )
      : reduxDispatch(
          openDialog({
            dialogEnum: DialogEnum.VIEW_SEASONAL_EVENT,
            componentProps: {
              handleClose,
              seasonalEvent: { ...data },
            },
          }),
        )
  }

  const hasEdit = isOneOfUserRoles(memberOf, [
    USER_ROLE_ADMIN,
    USER_ROLE_PROGRAM_MANAGER,
  ])

  return (
    <div data-testid="events-page">
      <Typography>
        Target amplifies seasonal events to help the guest prepare for special
        moments. Refer to the calendar below and plan your assortment and
        inventory accordingly. Visit this
        <Link to={`${RoutePath.KNOWLEDGE_ARTICLE}/000098256`}>
          {' '}
          Help Center article
        </Link>{' '}
        to discover how you can take advantage of these events.
      </Typography>
      <ContentSpacer />

      <FilterBar count={total} onClear={onFilterClear}>
        <Grid item xs={12}>
          <DateRangeFilter
            label="Event Date Range"
            startValue={filters.start_date}
            startSearchParam="start_date"
            endValue={filters.end_date}
            endSearchParam="end_date"
            onChange={handleFilterChange()}
          />
        </Grid>
      </FilterBar>

      <ContentSpacer />

      <StyledTabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        aria-label="seasonal events tabs"
      >
        <Tab
          id={`tab-${SeasonlEventsTab.CALENDAR}`}
          aria-controls={`${SeasonlEventsTab.CALENDAR}-tab`}
          value={SeasonlEventsTab.CALENDAR}
          label="Calendar View"
        />
        <Tab
          id={`tab-${SeasonlEventsTab.LIST}`}
          aria-controls={`${SeasonlEventsTab.LIST}-tab`}
          value={SeasonlEventsTab.LIST}
          label="List View"
        />
      </StyledTabs>
      <TabPanel value={SeasonlEventsTab.LIST} selectedValue={tabValue}>
        <SeasonalEventsList
          handleEventAddEdit={handleEventAddEdit}
          seasonalEvents={seasonalEvents}
          pending={pending}
          total={total}
          table={table}
        />
      </TabPanel>
      <TabPanel value={SeasonlEventsTab.CALENDAR} selectedValue={tabValue}>
        <SeasonalEventsCalendar
          seasonalEvents={seasonalEvents}
          startDate={filters.start_date}
          endDate={filters.end_date}
          pending={pending}
          handleEventAddEdit={handleEventAddEdit}
        />
      </TabPanel>
    </div>
  )
}

export default ExternalSeasonalEvents
