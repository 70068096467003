import Link from 'components/common/Link'

import DataList from 'components/common/DataList'
import LiveLink from 'components/common/LiveLink'

import { RoutePath } from 'services/NavigationHelper'
import { SellerStatus } from 'types/Seller'
import startCase from 'lodash/fp/startCase'
import PriceBlock from 'components/Items/ItemDetailsPage/PriceBlock'
import { SmsProduct } from 'types/Item'

interface Props {
  sellerId: string
  sellerName: string
  relationshipType: string
  published: boolean
  buyUrl: string
  sellerStatus?: SellerStatus
  smsProduct: SmsProduct
}
export const ItemOverview = ({
  sellerId,
  sellerName,
  relationshipType,
  published,
  buyUrl,
  sellerStatus,
  smsProduct,
}: Props) => {
  const data = [
    {
      title: 'Partner:',
      element: (
        <Link to={`/${sellerId}${RoutePath.DASHBOARD}`} target="_blank">
          {sellerName}
        </Link>
      ),
    },
    {
      title: 'Partner Status:',
      value: sellerStatus ? startCase(sellerStatus.toLowerCase()) : '',
    },
    { title: 'Relationship Type:', value: relationshipType },
    {
      title: 'Published Status:',
      element: <LiveLink published={published} buyUrl={buyUrl} />,
    },
    {
      element: <PriceBlock smsProduct={smsProduct} itemOverview={true} />,
    },
  ]
  return <DataList data={data} />
}

export default ItemOverview
