import styled from '@emotion/styled'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import Image from 'components/common/Image'

import { MarketplaceProduct } from 'types/Item'

const StyledImageContainer = styled('div')(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(2),
  maxWidth: '200px',
}))

type Props = {
  item: MarketplaceProduct
}

export const DisputeItemDetails = ({ item }: Props) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 'bold' }}>
            Original Item Details:
          </Typography>
          <Typography>{item.title}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StyledImageContainer>
            <Image src={item.primary_image} alt={`image of ${item.title}`} />
          </StyledImageContainer>
        </Grid>
      </Grid>
    </>
  )
}

export default DisputeItemDetails
