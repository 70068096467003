import React from 'react'

import styled from '@emotion/styled'

import DialogContainer from 'components/common/Dialog/DialogContainer'

import { HeliosTopicMessage, SmsTopicMessage } from 'types/Beetle'

export type Props = {
  isOpen: boolean
  timelineMessage: HeliosTopicMessage | SmsTopicMessage
}

const StyledTitle = styled('span')(({ theme }) => ({
  paddingRight: theme.spacing(1),
  fontWeight: 'bold',
}))

const StyledGutter = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}))

export const MessageDetailsDialog: React.FC<Props> = ({
  isOpen,
  timelineMessage,
}) => {
  const prettifyJson = (data?: any) => {
    if (data === undefined) return null

    return Object.keys(data).map((key) => {
      return (
        <div key={key}>
          <StyledTitle>{key}</StyledTitle>
          {typeof data[key] !== 'object' && !Array.isArray(data[key]) && (
            <span>{data[key].toString()}</span>
          )}
          {data[key] && typeof data[key] === 'object' && (
            <StyledGutter>{prettifyJson(data[key])}</StyledGutter>
          )}
        </div>
      )
    })
  }

  return (
    <DialogContainer
      title="Message Details"
      isOpen={isOpen}
      closeButtonText="close"
    >
      {prettifyJson(timelineMessage)}
    </DialogContainer>
  )
}

export default MessageDetailsDialog
