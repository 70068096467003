import axios from 'axios'

import apiConfig from 'config/apiConfig'
import { CATEGORY_SHIPPING_SERVICE } from 'constants/categories'

import { VendorCategory } from 'types/Seller'

export function getVendorCategories(): Promise<VendorCategory[]> {
  return axios.get(`${apiConfig.marketplaceVendors}/categories`).then((res) => {
    // removing LTL as it is not an available ship service for T+ partners (...yet?)

    // removing 'PREMIUM' shipping service, this option is no longer available on Target.com
    // CYA since we don't know when it will actually be removed from the service.
    const vendorCategories: VendorCategory[] = res.data.map(
      (category: VendorCategory) => {
        if (category.category_id.trim() === CATEGORY_SHIPPING_SERVICE) {
          const filteredCodes = category.codes.filter(
            (code) => !['PREMIUM', 'LTL'].includes(code.code_name),
          )
          return {
            ...category,
            codes: filteredCodes,
          }
        }
        return category
      },
    )
    return vendorCategories
  })
}
