import apiConfig, { ENVIRONMENT_PRODUCTION } from 'config/apiConfig'

const stageDoceboUrl =
  'https://targetpartnerlearningsandbox.docebosaas.com/learn/dashboard'
const prodDoceboUrl = 'https://targetpartnerlearning.docebosaas.com/targetplus'

export const getDoceboUrl = () => {
  return apiConfig.environment !== ENVIRONMENT_PRODUCTION
    ? stageDoceboUrl
    : prodDoceboUrl
}
