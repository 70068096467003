import intersection from 'lodash/fp/intersection'

import {
  USER_ROLE_ADMIN,
  USER_ROLE_APP_SMS,
  USER_ROLE_APP_SMS_ADMIN,
  USER_ROLE_APP_SMS_READ,
  USER_ROLES,
  UserRole,
} from './roles'
import { Entitlement, Role } from 'types/SellerUser'
import apiConfig from 'config/apiConfig'

export function isUserRole(memberOf: string[] = [], role: string): boolean {
  return memberOf.includes(role)
}

export function isOneOfUserRoles(
  memberOf: string[] = [],
  roles: string[],
): boolean {
  return intersection(memberOf, roles).length > 0
}

export function isUserRoleProductAdmin(memberOf: string[]) {
  return isUserRole(memberOf, USER_ROLE_ADMIN)
}

export function isUserRoleSellerAdmin(memberOf: string[]) {
  return isUserRole(memberOf, USER_ROLE_APP_SMS_ADMIN)
}

export function isUserRoleAdmin(memberOf: string[] = []) {
  return isUserRoleProductAdmin(memberOf) || isUserRoleSellerAdmin(memberOf)
}

export function isUserRoleExternal(memberOf: string[]): boolean {
  return isUserRole(memberOf, USER_ROLE_APP_SMS)
}

export function isUserAuthorized(memberOf: string[]): boolean {
  const isAuthorized = memberOf.some((role) =>
    apiConfig.auth.roles.includes(role),
  )
  return isAuthorized
}

export function mapEntitlementsToRoles(
  entitlements: Entitlement[],
  memberOf: string[],
): string[] {
  const preservedRoles = memberOf.filter((item) => item === USER_ROLE_APP_SMS)

  const mappedEntitlements = entitlements.map((entitlement: Entitlement) => {
    return entitlement.role === Role.ADMIN
      ? USER_ROLE_APP_SMS_ADMIN
      : USER_ROLE_APP_SMS_READ
  })

  return [...preservedRoles, ...mappedEntitlements]
}

export function findUserRole(memberOf: string[]) {
  const currentRole = apiConfig.auth.roles.filter((n: string) => {
    return memberOf.indexOf(n) !== -1
  })
  const mappedRole = USER_ROLES.find(
    (role: UserRole) => role.code === currentRole[0],
  )
  return mappedRole ? mappedRole.name : undefined
}
