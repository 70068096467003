import React from 'react'

import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import Error from '@mui/icons-material/Error'
import Grid from '@mui/material/Grid'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'

import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'

const StyledTextAlign = styled('span')({
  display: 'flex',
  alignItems: 'center',
})

const StyledTitle = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  paddingBottom: theme.spacing(2),
}))

const StyledContent = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}))

const StyledPopoverRoot = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  minWidth: '300px',
}))

interface Props {
  body: string
  data: any[]
  errorMessage: string
  fieldList: EnhancedTableFieldType[]
  title: string
}

export const MessageWithPopover = ({
  body,
  data,
  errorMessage,
  fieldList,
  title,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<Nullable<Element>>(null)

  const onClose = () => {
    setAnchorEl(null)
  }

  const showPopover = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  return (
    <StyledTextAlign>
      <Error color="error" />
      <Button onClick={showPopover} sx={{ root: { textTransform: 'inherit' } }}>
        <Typography color="error">{errorMessage}</Typography>
      </Button>
      <Popover anchorEl={anchorEl} onClose={onClose} open={anchorEl !== null}>
        <StyledPopoverRoot container>
          <Grid item xs={12}>
            <StyledTitle variant={'h3'}>{title}</StyledTitle>
            <StyledContent>{body}</StyledContent>
            <EnhancedTable
              data={data}
              fieldList={fieldList}
              isLoading={false}
            />
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button color="primary" onClick={onClose}>
                Close
              </Button>
            </Grid>
          </Grid>
        </StyledPopoverRoot>
      </Popover>
    </StyledTextAlign>
  )
}

export default MessageWithPopover
