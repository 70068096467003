import React from 'react'
import { Field, FieldProps } from 'formik'

import Input, { InputProps } from '@mui/material/Input'

import formatPhoneNumber from 'services/formatPhoneNumber'

import { CountryNameCode } from 'types/Country'

export interface FormProps {
  name: string
  disabled: boolean
  required?: boolean
  country: string
  hasError: boolean
}

type Props = FormProps &
  Omit<InputProps, 'error' | 'name' | 'onChange' | 'value' | 'variant'>

export const InputPhone = ({
  name,
  disabled,
  required,
  country,
  hasError,
  ...rest
}: Props) => {
  return (
    <Field name={name}>
      {({ form, field }: FieldProps) => {
        const { setFieldValue } = form

        let onChange = field.onChange

        if (country === CountryNameCode.UNITED_STATES) {
          onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue(name, formatPhoneNumber(event.target.value)) as any
        }

        return (
          <Input
            {...rest}
            {...field}
            fullWidth
            type="text"
            name={name}
            id={name}
            required={required}
            error={hasError}
            disabled={disabled}
            onChange={onChange}
            inputProps={{
              'data-testid': name,
            }}
          />
        )
      }}
    </Field>
  )
}

export default InputPhone
