import { useState, useEffect, SyntheticEvent } from 'react'
import usePrevious from 'hooks/usePrevious'

import { Brand, BrandSearchParams } from 'types/Brand'

import { Direction } from 'services/pageableHelper'
import { findBrands } from 'services/brands'

import Typeahead from '.'
import InputLabel from '@mui/material/InputLabel'

interface Props {
  placeholder?: string
  onChange: (value: Nullable<Brand>, enteredValue: string) => void
  isDisabled?: boolean
  clearOnSelect?: boolean
  value?: Nullable<Brand>
  label?: string
}

const BrandTypeahead = ({
  placeholder = 'Search Brands',
  onChange,
  isDisabled,
  clearOnSelect = false,
  value: valueFromProps,
  label,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedValue, setSelectedValue] =
    useState<Nullable<Brand>>(valueFromProps)
  const [options, setOptions] = useState<Brand[]>([])
  const [loading, setLoading] = useState(false)

  const prevValue = usePrevious(valueFromProps)

  useEffect(() => {
    if (prevValue !== valueFromProps) {
      setSelectedValue(valueFromProps)
    }

    if (!valueFromProps) {
      setOptions([])
    }
  }, [prevValue, valueFromProps])

  useEffect(() => {
    if (options.length && !inputValue) {
      setOptions([])
    }
  }, [inputValue, options])

  const handleInputChange = async (
    _event: SyntheticEvent,
    enteredValue: string,
    reason: string,
  ) => {
    if (
      reason === 'clear' ||
      (selectedValue && reason === 'input' && enteredValue === '')
    ) {
      setOptions([])
      setInputValue('')
      setSelectedValue(null)
      return
    }

    setInputValue(enteredValue)

    const invalidInputValue =
      !enteredValue || enteredValue.length < 2 || reason === 'reset'
    if (invalidInputValue) {
      return
    }

    const paging = { page: 0, perPage: 20, direction: Direction.ASC }
    const params: BrandSearchParams = {
      brand_name: enteredValue,
      name_condition: 'BEGINS',
    }

    setLoading(true)

    await findBrands(paging, params, false)
      .then((resp) => setOptions(resp))
      .catch((e) => console.error(e))

    setLoading(false)
  }

  const handleSelectedOptionChange = (_event: SyntheticEvent, value: Brand) => {
    if (!(value instanceof Array)) {
      const searchParamValue = getOptionLabel(value as Brand)
      setSelectedValue(clearOnSelect ? null : (value as Nullable<Brand>))
      onChange(value as Nullable<Brand>, searchParamValue)
    }
  }

  const getOptionLabel = (returnedBrand: Brand) =>
    returnedBrand?.brandName ?? ''

  return (
    <>
      {label && <InputLabel shrink>{label}</InputLabel>}
      <Typeahead
        aria-label="Brand Typeahead"
        placeholder={placeholder}
        getOptionLabel={getOptionLabel}
        options={options}
        loading={loading}
        disabled={!!isDisabled}
        onInputChange={handleInputChange}
        onChange={handleSelectedOptionChange}
        inputValue={inputValue}
        value={selectedValue || null}
        isOptionEqualToValue={(option: Brand, value: Brand) =>
          option.brandId === value?.brandId
        }
      />
    </>
  )
}

export default BrandTypeahead
