import { Routes, Route } from 'react-router-dom'

import ContentSpacer from 'components/common/ContentSpacer'
import HeaderTitle from 'components/common/HeaderTitle'

import { RoutePath } from 'services/NavigationHelper'

import HelpCategoriesPage from './HelpCategoriesPage'
import HelpSearchBar from './HelpSearchBar'
import ArticleSearchResultsPage from './ArticleSearchResultsPage'

export const HelpPage = () => {
  return (
    <>
      <HeaderTitle title="Help Center" />
      <>
        <HelpSearchBar />
        <ContentSpacer />
        <Routes>
          <Route index element={<HelpCategoriesPage />} />
          <Route
            path={`${RoutePath.KNOWLEDGE_SEARCH_RELATIVE}`}
            element={<ArticleSearchResultsPage />}
          />
        </Routes>
      </>
    </>
  )
}

export default HelpPage
