import React from 'react'
import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'
import Pin, { PinType } from './Pin'
import { success, error, odrNonCompliant } from 'config/themeConfig'

const StyledRoot = styled('div')(({ theme }) => ({
  position: 'relative',
  paddingBottom: theme.spacing(5),
}))

const StyledContainer = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[500]}`,
  borderRadius: theme.spacing(2),
  width: `100%`,
  padding: theme.spacing(0.5),
  position: 'relative',
}))

const StyledBase = styled('div')(({ theme }) => ({
  borderRadius: theme.spacing(1),
  height: theme.spacing(1),
  backgroundColor: theme.palette.grey[500],
}))

const StyledTypography = styled(Typography)({
  fontSize: '0.875rem',
})

const StyledTab = styled('div')(({ theme }) => ({
  flex: '1 0 100%',
  borderRadius: theme.spacing(0.5),
  width: theme.spacing(0.5),
  height: 16,
  backgroundColor: '#000',
}))

type StyledOverlayProps = {
  score: number
  variant: LinearGaugeVariant
}

const StyledOverlay = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'score',
})<StyledOverlayProps>(
  ({ theme }) => ({
    height: theme.spacing(1),
    top: theme.spacing(0.5),
    left: theme.spacing(0.5),
    position: 'absolute',
    zIndex: 10,
  }),
  (props) => ({
    borderRadius:
      props.score === 100
        ? props.theme.spacing(1)
        : `${props.theme.spacing(1)} 0 0 ${props.theme.spacing(1)}`,
    width:
      props.score <= 100
        ? `calc(${props.score}% - ${props.theme.spacing(1)})`
        : `calc(100% - ${props.theme.spacing(1)})`,

    backgroundColor:
      props.variant === 'success'
        ? success.main
        : props.variant === 'error'
        ? error.main
        : props.variant === 'indeterminate'
        ? odrNonCompliant.main
        : props.variant === 'default'
        ? props.theme.palette.primary.main
        : '',
  }),
)

type StyledScoreProps = {
  score: number
  hideScore: boolean
}

const StyledScore = styled('div', {
  shouldForwardProp: (prop) => prop !== 'score' && prop !== 'hideScore',
})<StyledScoreProps>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: theme.spacing(2),
    color: '#000',
    position: 'absolute',
    zIndex: 11,
  }),
  (props) => ({
    top: props.hideScore ? 0 : props.theme.spacing(-2.5),
    left:
      props.hideScore && props.score === 0
        ? `calc(0% + ${props.theme.spacing(0.5)})`
        : props.hideScore && props.score >= 100
        ? `calc(100% - ${props.theme.spacing(1)})`
        : props.hideScore && props.score > 0 && props.score < 100
        ? `calc(${props.score}% - ${props.theme.spacing(0.5)})`
        : !props.hideScore && props.score === 0
        ? 0
        : !props.hideScore && props.score >= 100
        ? 100
        : !props.hideScore && props.score > 0 && props.score < 100
        ? `calc(${props.score}% - ${props.theme.spacing(1.5)})`
        : undefined,
  }),
)

export type LinearGaugeVariant =
  | 'default'
  | 'success'
  | 'indeterminate'
  | 'error'

type Props = {
  score: number
  hideScore?: boolean
  pins: PinType[]
  variant?: LinearGaugeVariant
}

export const Gauge: React.FC<Props> = (props) => {
  const { score, pins, hideScore = false, variant = 'default' } = props

  return (
    <StyledRoot data-testid="linear-gauge">
      <StyledContainer>
        <StyledBase />
        <StyledOverlay score={score} variant={variant} />
        <StyledScore score={score} hideScore={hideScore}>
          {hideScore ? null : <StyledTypography>{score}</StyledTypography>}
          <StyledTab />
        </StyledScore>
      </StyledContainer>
      {pins.map((pin: PinType) => (
        <Pin key={pin.label} pin={pin} />
      ))}
    </StyledRoot>
  )
}

export default Gauge
