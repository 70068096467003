import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import {
  Typography,
  List,
  ListItem,
  ListSubheader,
  Snackbar,
  Alert,
} from '@mui/material'

import { hideBanner } from 'store/notification/reducer'

import StoreState from 'types/state'
import { alert } from 'config/themeConfig'

const StyledErrorHeader = styled(ListSubheader)(() => ({
  color: alert.main,
  backgroundColor: alert.light,
  fontWeight: 600,
}))

const StyledErrorList = styled(ListItem)((props) => ({
  paddingTop: props.theme.spacing(3),
  paddingBottom: props.theme.spacing(3),
}))

const StyledRoot = styled(Snackbar)(() => ({
  maxWidth: 'none',
  flexWrap: 'nowrap',
}))

export const Notifications = () => {
  const reduxDispatch = useDispatch()

  const {
    isShown,
    message: notificationMessage,
    anchorOrigin,
    autoClose,
    severity,
  } = useSelector((state: StoreState) => state.notification)

  const createMessage = (): React.ReactNode => {
    if (typeof notificationMessage === 'string') {
      return <Typography>{notificationMessage}</Typography>
    }

    // TODO: bullet point is hardcoded here until we can figure out how to style it
    return (
      <List
        subheader={
          <StyledErrorHeader>{`${notificationMessage.code} - ${notificationMessage.message}`}</StyledErrorHeader>
        }
      >
        {notificationMessage.errors.map((error, index) => (
          <StyledErrorList key={index}>
            <span style={{ width: 20 }}>&#9679;</span>
            {error}
          </StyledErrorList>
        ))}
      </List>
    )
  }

  const handleRequestClose = (
    // @ts-ignore
    event: Event | SyntheticEvent<any, Event>,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }

    reduxDispatch(hideBanner())
  }

  const message = createMessage()

  return (
    <StyledRoot
      anchorOrigin={anchorOrigin}
      open={isShown}
      autoHideDuration={autoClose ? 5000 : undefined}
      onClose={autoClose ? handleRequestClose : undefined}
    >
      <Alert
        data-testid="alert"
        severity={severity}
        onClose={autoClose ? undefined : handleRequestClose}
        sx={{ whiteSpace: 'pre-line' }}
      >
        {message}
      </Alert>
    </StyledRoot>
  )
}

export default Notifications
