import styled from '@emotion/styled'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import WarningIcon from 'components/common/WarningIcon'

const StyledWarningContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))

const StyledWarningMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}))

export const PaymentWarningMessage = () => {
  return (
    <StyledWarningContainer
      container
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item>
        <WarningIcon>
          <StyledWarningMessage data-testid="payments-warning">
            Stripe account hasn't been set up yet
          </StyledWarningMessage>
        </WarningIcon>
      </Grid>
    </StyledWarningContainer>
  )
}

export default PaymentWarningMessage
