import React from 'react'
import { useSelector } from 'react-redux'

import Button from '@mui/material/Button'
import styled from '@emotion/styled'
import { white, grey } from 'config/themeConfig'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

import { isOneOfUserRoles } from 'services/authorization'
import {
  USER_ROLE_ADMIN,
  USER_ROLE_APP_SMS,
  USER_ROLE_DIGITAL_EXP_SUPPORT,
  USER_ROLE_OPS,
} from 'services/roles'

import { getMemberOf } from 'store/selectors'

type StyledHorizontalBarProps = {
  width?: number
}

const StyledHorizontalBar = styled('div')<StyledHorizontalBarProps>(
  (props) => ({
    width: `${props.width}%`,
  }),
  ({ theme }) => ({
    backgroundColor: white.main,
    height: theme.spacing(2),
    marginTop: theme.spacing(0.5),
    position: 'absolute',
    bottom: 0,
    right: 0,
  }),
)

const StyledDiv = styled('div')(({ theme }) => ({
  background: `repeating-linear-gradient(to top, 
    ${grey[200]}, 
    ${grey[200]} ${theme.spacing(2)}, 
    ${white.main} ${theme.spacing(2)}, 
    ${white.main} ${theme.spacing(2.5)})`,
  textIndent: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
}))

interface Props {
  children: any
  buttonText?: string
  canShowRoles?: string[]
  showByDefaultRoles?: string[]
}

export const PiiDataContainer = ({
  children,
  buttonText = 'Data',
  canShowRoles = [
    USER_ROLE_ADMIN,
    USER_ROLE_DIGITAL_EXP_SUPPORT,
    USER_ROLE_OPS,
  ],
  showByDefaultRoles = [USER_ROLE_APP_SMS],
}: Props) => {
  const roles = useSelector(getMemberOf)
  const canShow = isOneOfUserRoles(roles, canShowRoles)
  const showByDefault = isOneOfUserRoles(roles, showByDefaultRoles)
  const [isShowing, setIsShowing] = React.useState<boolean>(
    showByDefault ? true : false,
  )

  return (
    <div>
      {showByDefault || isShowing ? (
        <div data-testid="visible-pii-data">{children}</div>
      ) : (
        <div data-testid="hidden-pii-data" style={{ position: 'relative' }}>
          <StyledDiv>{children}</StyledDiv>
          <StyledHorizontalBar width={50} />
        </div>
      )}
      {(showByDefault || canShow) && (
        <Button
          sx={{ marginTop: 2 }}
          data-testid="pii-visiblity-button"
          startIcon={
            isShowing ? (
              <VisibilityOffIcon data-testid="hidden-icon" />
            ) : (
              <VisibilityIcon data-testid="visible-icon" />
            )
          }
          onClick={() => setIsShowing(!isShowing)}
        >
          {isShowing
            ? `Hide ${buttonText} (PII Data)`
            : `View ${buttonText} (PII Data)`}
        </Button>
      )}
    </div>
  )
}

export default PiiDataContainer
