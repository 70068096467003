import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import Text from 'components/common/Text'

import theme, { warning } from 'config/themeConfig'

import {
  isAfterDate,
  formatDate,
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
} from 'services/dateService'
import formatCurrency from 'services/formatCurrency'

import { SmsProduct, Price, Inventory } from 'types/Item'

const StyledRoot = styled(Grid)<{ itemOverview: boolean }>((props) => ({
  marginTop: props.itemOverview ? 'none' : theme.spacing(3),
}))

const StyledValue = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'missing',
})<{ missing: boolean }>((props) => ({
  fontSize: props.missing ? '0.875rem' : '1.5rem',
  color: props.missing ? warning.main : undefined,
}))

interface Props {
  smsProduct: SmsProduct
  itemOverview: boolean
}

const getInventoryInfo = (
  quantities: Inventory[],
): { total: number; latestDate: string } => {
  let total = 0
  let latestDate: string = ''

  quantities.forEach((item) => {
    total = total + item.quantity

    if (!latestDate) {
      latestDate = item.last_modified
    } else {
      latestDate = isAfterDate(
        new Date(item.last_modified),
        new Date(latestDate),
      )
        ? item.last_modified
        : latestDate
    }
  })

  return {
    total,
    latestDate,
  }
}

export const PriceBlock = ({ smsProduct, itemOverview }: Props) => {
  const productPrice = smsProduct.price || ({} as Partial<Price>)

  const prices = []

  if (!itemOverview) {
    if (smsProduct?.quantities?.length) {
      const data = getInventoryInfo(smsProduct.quantities)

      prices.push({
        title: 'Inventory',
        value: Intl.NumberFormat('en-US').format(data.total),
        date: data.latestDate,
      })
    } else {
      prices.push({
        title: 'Inventory',
        value: 'Missing Data',
        missing: true,
      })
    }
  }
  if (productPrice.list_price) {
    prices.push({
      title: 'List Price',
      value: formatCurrency(productPrice.list_price),
      date: productPrice.last_modified,
    })
  } else {
    prices.push({
      title: 'List Price',
      value: 'Missing Data',
      missing: true,
    })
  }

  if (productPrice.offer_price) {
    prices.push({
      title: 'Sale Price',
      value: formatCurrency(productPrice.offer_price),
      date: productPrice.last_modified,
    })
  }

  if (productPrice.map_price) {
    prices.push({
      title: 'MAP Price',
      value: formatCurrency(productPrice.map_price),
      date: productPrice.last_modified,
    })
  }

  return (
    <StyledRoot container spacing={2} itemOverview={itemOverview}>
      {prices.map(({ title, value, date, missing }) => (
        <Grid
          key={title}
          item
          xs={itemOverview ? undefined : 6}
          md={itemOverview ? undefined : 3}
        >
          <Text
            type="bold"
            color="textSecondary"
            data-testid={`title-${title}`}
          >
            {title}
          </Text>
          <StyledValue missing={!!missing} data-testid={`value-${title}`}>
            {value}
          </StyledValue>
          {date && (
            <Typography color="textSecondary">
              As of {formatDate(date, DATE_FORMAT_MONTH_DAY_YEAR_TIME)}
            </Typography>
          )}
        </Grid>
      ))}
    </StyledRoot>
  )
}

export default PriceBlock
