import styled from '@emotion/styled'

import { DATE_DISPLAY_FORMAT, formatDate } from 'services/dateService'

import Text from 'components/common/Text'

const StyledText = styled(Text)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}))

export interface Props {
  label?: string
  startDate?: string
  endDate?: string
}

export const DateRangeText = ({ label, startDate, endDate }: Props) => {
  return (
    <StyledText type="micro">
      {label ? `${label} ` : ''}
      {formatDate(startDate, DATE_DISPLAY_FORMAT)} -{' '}
      {formatDate(endDate, DATE_DISPLAY_FORMAT)}
    </StyledText>
  )
}

export default DateRangeText
