import { useCallback, type SyntheticEvent } from 'react'

import { Tabs } from '@enterprise-ui/canvas-ui-react'
import { useStateValue } from 'stateManager/StateProvider'
import { ITEM_TYPE } from 'types/Attribute'

export enum ItemTabsEnum {
  INDIVIDUAL = 'Individual Item',
  Bulk = 'Bulk Item',
}

interface ItemTabsType {
  name: ItemTabsEnum
}

const ItemTabs = () => {
  const { updateState, state } = useStateValue()

  const onTabSelect = useCallback(
    (_event: SyntheticEvent, tab: ItemTabsType) => {
      updateState({ tabName: tab.name })
    },
    [updateState],
  )

  return (
    <div className="hc-bg-grey07 hc-mt-md hc-mb-md">
      <Tabs activeTab={state.tabName} onTabSelect={onTabSelect} disableAdaptive>
        <Tabs.Item name={ItemTabsEnum.INDIVIDUAL}>Individual config</Tabs.Item>
        {state.type === ITEM_TYPE && (
          <Tabs.Item name={ItemTabsEnum.Bulk}>Bulk Upload</Tabs.Item>
        )}
      </Tabs>
    </div>
  )
}

export default ItemTabs
