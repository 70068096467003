import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import Grid from '@mui/material/Grid'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import Input from 'components/common/Input'

import { validationHandler, ValidationSchema } from 'services/validation'

import { editSeller } from 'store/seller/actionCreators'
import { currentSeller, isSellersPending } from 'store/selectors'

import { Validation } from 'types/Validation'

interface Props {
  isOpen: boolean
}

const validationSchema: ValidationSchema = yup.object().shape({
  legalBusinessName: yup.string().label('Legal business name').required(),
})

export const EditSellerLegalName = ({ isOpen }: Props) => {
  const seller = useSelector(currentSeller)
  const isPending = useSelector(isSellersPending)

  const dispatch = useDispatch()

  const [legalBusinessName, setLegalBusinessName] = React.useState(
    seller?.legal_business_name ?? '',
  )
  const [validation, setValidation] = React.useState<Validation>({})

  const handleInputChange = (value: string) => {
    setLegalBusinessName(
      value.replace(/[^a-zA-Z0-9\s{}()\\;',@%_+-=#$*.?:&[<>\]]*/g, ''),
    )
  }

  const handleInputTrim = (value: string) => {
    const trimmedValue = value.trim()
    setLegalBusinessName(trimmedValue)
  }

  const handleSubmit = () => {
    const { validation: formValidation, isValid } = validationHandler(
      validationSchema,
      {
        legalBusinessName: legalBusinessName,
      },
    )
    setValidation(formValidation)
    if (isValid && seller) {
      const newSeller = {
        ...seller,
        legal_business_name: legalBusinessName,
      }
      dispatch(editSeller(newSeller))
    }
  }

  return (
    <DialogContainer
      title="Edit Partner Name"
      isOpen={isOpen}
      onSubmit={handleSubmit}
      isPending={isPending}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input
            isRequired
            id="legal-business-name"
            data-testid="legal-business-name"
            name="legalBusinessName"
            label="Legal Business Name"
            isDisabled={isPending}
            value={legalBusinessName}
            onChange={handleInputChange}
            onBlur={handleInputTrim}
            validation={validation}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export default EditSellerLegalName
