import styled from '@emotion/styled'
import { grey } from 'config/themeConfig'
import { LineChart, Line, ResponsiveContainer } from 'recharts'

const StyledSparklineChart = styled(LineChart)(({ theme }) => ({
  margin: theme.spacing(2),
}))

const Sparkline = ({ data, dataKey }: { data: any; dataKey: string }) => {
  return (
    <ResponsiveContainer width="100%" height={80}>
      <StyledSparklineChart width={400} height={100} data={data}>
        <Line
          type="monotone"
          dataKey={dataKey}
          stroke={grey[300]}
          strokeWidth={2}
          dot={false}
        />
      </StyledSparklineChart>
    </ResponsiveContainer>
  )
}

export default Sparkline
