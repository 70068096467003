import { EnhancedTableFieldType } from 'components/common/EnhancedTable'
import {
  formatDateMDYT,
  publishedLink,
  tcinLink,
} from 'components/common/EnhancedTable/formatters'
import StatusText from 'components/common/StatusText'

import { formatLocaleNumber } from 'services/formatNumber'

import { MarketplaceProduct } from 'types/Item'

export const getItemsTableFieldList =
  (): EnhancedTableFieldType<MarketplaceProduct>[] => [
    {
      key: 'external_id',
      heading: 'Partner SKU',
      hasSort: true,
    },
    {
      key: 'tcin',
      heading: 'TCIN',
      hasSort: true,
      formatCell: tcinLink(),
    },
    {
      key: 'title',
      heading: 'Title',
      hasSort: true,
      minWidth: 20,
    },
    {
      key: 'item_type_name',
      heading: 'Item Type',
      hasSort: true,
    },
    {
      key: 'inventory',
      heading: 'Inventory',
      hasSort: true,
      formatCell: ({ inventory }) =>
        inventory !== undefined ? formatLocaleNumber(inventory) : '',
    },
    {
      key: 'relationship_type',
      heading: 'Relationship',
      hasSort: true,
    },
    {
      key: 'published',
      heading: 'Publish Status',
      hasSort: true,
      formatCell: publishedLink(),
    },
    {
      key: 'listing_status',
      heading: 'Latest Status',
      hasSort: true,
      formatCell: ({ listing_status }) => <StatusText label={listing_status} />,
    },
    {
      key: 'last_modified',
      heading: 'Last Item Update',
      hasSort: true,
      formatCell: formatDateMDYT('last_modified'),
    },
  ]
