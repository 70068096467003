import {
  EnumerationName,
  CaseType,
  ReturnReason,
  StateCode,
  DisputeResponse,
  DisputeSubReason,
  ReturnCenter,
} from 'types/Enumeration'
import StoreState from 'types/state'
import { SmsSeller, SellerStatus } from 'types/Seller'
import { isUserRoleExternal } from 'services/authorization'

export const getSellerState = (state: StoreState) => state.seller

export const getSellerContacts = (state: StoreState) =>
  getSellerState(state).contacts

export const currentSeller = (state: StoreState) =>
  getSellerState(state).currentSeller

export const isSellersPending = (state: StoreState) =>
  getSellerState(state).isSellersPending

export const currentSellerId = (state: StoreState) => currentSeller(state)?.id

export const currentSellerDisplayName = (state: StoreState) =>
  currentSeller(state)?.display_name

export const currentSellerVmmId = (state: StoreState) =>
  currentSeller(state)?.vmm_id

export const currentSellerCreatedDate = (state: StoreState) =>
  currentSeller(state)?.created

export const currentSellerSource = (state: StoreState) =>
  currentSeller(state)?.source

export const isInternationalCompany = (state: StoreState) =>
  currentSeller(state)?.primary_address?.country_code !== 'US'

export const currentSellerStatus = (state: StoreState) =>
  currentSeller(state)?.status

export const isDefunctSellerStatus = (state: StoreState) =>
  currentSellerStatus(state) === SellerStatus.DEFUNCT

export const getUser = (state: StoreState) => state.user

export const getUserId = (state: StoreState) => getUser(state).id

export const getMemberOf = (state: StoreState) => getUser(state).memberOf

export const getUserEntitlements = (state: StoreState) =>
  getUser(state).entitlements

export const getSellers = (state: StoreState): SmsSeller[] =>
  state.seller.sellers

export const getExternalUserLogin = (state: StoreState) =>
  getUser(state).externalUserLogin

export const isRoleExternalUserSelector = (state: StoreState) => {
  const memberOf = getMemberOf(state)

  const isRoleExternal = isUserRoleExternal(memberOf)

  return isRoleExternal
}

export const hasOneSellerSelector = (state: StoreState) => {
  const entitlements = getUserEntitlements(state)

  if (!entitlements.length) return false

  if (entitlements.length === 1) return true

  // Backend does not prevent a user from having multiple entitlements
  // for 1 seller so remove duplicates for this check
  const filteredEntitlements = entitlements.filter((item, index, self) => {
    return index === self.findIndex((i) => i.resource_id === item.resource_id)
  })

  return filteredEntitlements.length === 1
}

export const sellerIds = (state: StoreState) =>
  getSellers(state).map((seller) => seller.id || '')

export const email = (state: StoreState) => state.user.email

export const getFullName = (state: StoreState) =>
  `${state.user.firstName} ${state.user.lastName}`

export const getDistributionCenters = (state: StoreState) =>
  currentSeller(state)?.distribution_centers ?? []

export const getReturnPolicies = (state: StoreState) =>
  currentSeller(state)?.return_policies ?? []

export const getReturnFirstPolicy = (state: StoreState) =>
  getReturnPolicies(state)?.[0]

export const getEnumerationByKey = (state: StoreState, key: EnumerationName) =>
  state.enumeration.enumerations?.[key]

export const getEnumerationValues = (state: StoreState, key: EnumerationName) =>
  getEnumerationByKey(state, key)?.map((e) => e.value) ?? []

export const getReturnReasons = (state: StoreState) =>
  (getEnumerationByKey(
    state,
    EnumerationName.RETURN_REASON,
  ) as ReturnReason[]) ?? []

export const getDisputeResponses = (state: StoreState) =>
  (getEnumerationByKey(
    state,
    EnumerationName.DISPUTE_RESPONSE,
  ) as DisputeResponse[]) ?? []

export const getDisputeSubResponses = (state: StoreState) =>
  (getEnumerationByKey(
    state,
    EnumerationName.DISPUTE_SUB_REASON,
  ) as DisputeSubReason[]) ?? []

export const getReturnCenters = (state: StoreState) =>
  (getEnumerationByKey(
    state,
    EnumerationName.RETURN_CENTERS,
  ) as ReturnCenter[]) ?? []

export const getCaseTypes = (state: StoreState) =>
  (getEnumerationByKey(state, EnumerationName.CASE_TYPE) as CaseType[]) ?? []

export const getStateCodes = (state: StoreState) =>
  (getEnumerationByKey(state, EnumerationName.STATE_CODE) as StateCode[]) ?? []

export const getDialogIsDialogOpen = (state: StoreState) =>
  state.dialog.isDialogOpen

export const getDialogComponentName = (state: StoreState) =>
  state.dialog.componentName

export const getDialogComponentProps = (state: StoreState) =>
  state.dialog.componentProps

export const getDialogRenderDialog = (state: StoreState) =>
  state.dialog.renderDialog

export const getCloseDialogCallback = (state: StoreState) =>
  state.dialog.closeCallback

export const getItemReview = (state: StoreState) => state.itemReview

export const itemReviewParentId = (state: StoreState) =>
  getItemReview(state).parentId

export const itemReviewSkipItems = (state: StoreState) =>
  getItemReview(state).processedItems

export const itemReviewSkipParents = (state: StoreState) =>
  getItemReview(state).skippedParents

export const stripeAccountIdSelector = (state: StoreState) =>
  currentSeller(state)?.stripe_account_id

export const hasStripeAccountId = (state: StoreState) =>
  !!stripeAccountIdSelector(state)

export const getIsPaymentAccountConfirmed = (state: StoreState) =>
  state.paymentAccount.isPaymentAccountConfirmed

export const getIsRegistrationPending = (state: StoreState) =>
  state.paymentAccount.isRegistrationPending

export const getIsSmallScreenSize = (state: StoreState) =>
  state.layout.isSmallScreenSize

export const getIsFullPageLoaderOpen = (state: StoreState) =>
  state.layout.isFullPageLoaderOpen

export const getHeaderTitle = (state: StoreState) => state.layout.headerTitle

export const getDiversityAnswers = (state: StoreState) =>
  state.partnerDiversity.diversityAnswers

export const getIsBannerOpen = (state: StoreState) =>
  state.notification.isBannerOpen
