import { DialogEnum } from 'components/common/Dialog'
import {
  CLOSE_DIALOG,
  DialogAction,
  OPEN_DIALOG,
  OpenDialogAction,
  UNMOUNT_DIALOG,
} from './actionCreator'

export interface DialogState {
  isDialogOpen: boolean
  renderDialog: boolean
  componentName?: DialogEnum
  componentProps: any
  closeCallback?: () => void
}

export const initialState: DialogState = {
  isDialogOpen: false,
  renderDialog: false,
  componentProps: {},
}

export default function dialogReducer(
  state: DialogState = initialState,
  action: DialogAction,
): DialogState {
  switch (action.type) {
    case OPEN_DIALOG: {
      const { payload } = action as OpenDialogAction

      return {
        ...state,
        isDialogOpen: true,
        renderDialog: true,
        componentName: payload.dialogEnum,
        componentProps: payload.componentProps,
        closeCallback: payload.closeCallback,
      }
    }
    case CLOSE_DIALOG: {
      return {
        ...state,
        isDialogOpen: false,
      }
    }
    case UNMOUNT_DIALOG: {
      return {
        ...state,
        renderDialog: false,
      }
    }
    default: {
      return state
    }
  }
}
