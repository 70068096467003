import Grid from '@mui/material/Grid'

import styled from '@emotion/styled'

import DateRangeSelector from 'components/common/DateRangePicker/DateRangeSelector'
import {
  DateRange,
  generateRangeConfig,
  getRangeConfig,
  labelFromRange,
} from 'components/common/DateRangePicker/rangeConfig'
import Select from 'components/common/Select'

import {
  DATE_PICKER_FORMAT,
  formatDate,
  startOf,
  startOfDay,
} from 'services/dateService'
import { trackCustomEvent } from 'services/fireflyInsights'

import { FireflyEvent } from 'types/FireflyInsights'

import { Range } from './SalesMetricsCardContent'

const StyledDiv = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

interface Props {
  graphName: string
  defaultRange: Range
  range: Range
  onChangeRange: (range: Range) => void
  disableDatesBefore?: Date
  compareInterval: string
  onChangeCompareInterval: (interval: string) => void
  compareAggregate?: string | number
}

const range7Days = getRangeConfig(DateRange.LAST_7_DAYS)
export const defaultRange = {
  from: startOfDay(range7Days.from),
  to: startOfDay(range7Days.to),
}

const defaultFirstDate = new Date(
  formatDate(startOf(new Date(), 'year'), DATE_PICKER_FORMAT),
)

const RangeSelectors = ({
  graphName,
  defaultRange,
  range,
  onChangeRange,
  disableDatesBefore = defaultFirstDate,
  compareInterval,
  onChangeCompareInterval,
  compareAggregate,
}: Props) => {
  const getCompareOptions = () => {
    const range7Prior = getRangeConfig(DateRange.LAST_7_DAYS, range.from)
    const range30Prior = getRangeConfig(DateRange.LAST_30_DAYS, range.from)
    const options = [{ name: 'None', value: '' }]

    if (range7Prior) {
      options.push({
        name: labelFromRange('Prior 7 Days', range7Prior.from, range7Prior.to),
        value: '<< 7 Days',
      })
    }

    if (range30Prior) {
      options.push({
        name: labelFromRange(
          'Prior 30 Days',
          range30Prior.from,
          range30Prior.to,
        ),
        value: '<< 30 Days',
      })
    }

    return options
  }

  const dateRangeOptions = generateRangeConfig()
  const handleDateChange = (newFrom: Date, newTo: Date, isValid: boolean) => {
    if (!isValid) return

    onChangeRange({ from: newFrom, to: newTo })
  }

  const handleCompareIntervalChange = (value: string) => {
    onChangeCompareInterval(value)
    trackCustomEvent(FireflyEvent.COMPARE_RANGE_SELECTED, graphName, value)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <StyledDiv>
          <DateRangeSelector
            descriptor={graphName}
            defaultRange={DateRange.LAST_7_DAYS}
            defaultFrom={defaultRange.from}
            defaultTo={defaultRange.to}
            onDateChange={handleDateChange}
            disableDatesBefore={disableDatesBefore}
            dateRangeOptions={dateRangeOptions}
            description=""
          />
        </StyledDiv>
      </Grid>
      <Grid item xs={4}>
        <Select
          id={`date-compare-select-${graphName}`}
          name="date-compare-select"
          keyName="name"
          valueName="value"
          label={`Compare to ${compareAggregate ?? ''}`}
          options={getCompareOptions()}
          value={compareInterval}
          onChange={handleCompareIntervalChange}
        />
      </Grid>
    </Grid>
  )
}

export default RangeSelectors
