import differenceBy from 'lodash/fp/differenceBy'
import groupBy from 'lodash/fp/groupBy'

import { getStore } from 'store'
import * as actions from 'store/cache/actionCreators'
import Attribute from 'types/Attribute'
import StoreState from 'types/state'

const getState = (): StoreState => getStore().getState()

export const getItemTypesCache = () => getState().cache.itemTypes

export const updateItemTypesCache = (itemTypes: Attribute[]) => {
  const cachedItemTypes = getItemTypesCache()

  // make sure itemTypes do not already exist in the cache
  const uncachedItemTypes = differenceBy('id', itemTypes, cachedItemTypes)

  if (uncachedItemTypes.length) {
    getStore().dispatch(actions.updateItemTypesCache(uncachedItemTypes))
  }
}

export const getItemTypeIdGroupsFromCache = (
  ids: string[],
  cache: Attribute[],
) => {
  const cachedIds = 'cachedIds'
  const uncachedIds = 'uncachedIds'

  const idsInCache = cache.map((itemType) => itemType.id)

  const group = groupBy(
    (node) => (idsInCache.includes(node) ? cachedIds : uncachedIds),
    ids,
  )

  return {
    [cachedIds]: group.cachedIds,
    [uncachedIds]: group.uncachedIds,
  }
}
