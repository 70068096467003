import { Field, FieldProps } from 'formik'

import MenuItem from '@mui/material/MenuItem'
import Select, { SelectProps } from '@mui/material/Select'

import { getGeographicalCodes } from 'services/geographicalCodes'

type FormProps = {
  name: string
  id: string
  keyName?: string
  valueName?: string
}

type Props = FormProps &
  Omit<
    SelectProps,
    'error' | 'name' | 'onChange' | 'value' | 'variant' | 'type'
  >

const geographicalCodes = getGeographicalCodes()

const SelectCountryCode = ({ name, id, disabled, ...props }: Props) => {
  return (
    <Field name={name}>
      {({ form, field }: FieldProps) => {
        const { touched, errors, isSubmitting } = form

        const isDisabled =
          typeof disabled === 'undefined' ? isSubmitting : disabled

        const error = errors[name]
        const hasError = !!(touched[name] && error)

        return (
          <Select
            {...props}
            {...field}
            SelectDisplayProps={
              {
                'data-testid': name,
              } as any
            }
            id={id}
            inputProps={{ 'aria-label': `${id} country code` }}
            fullWidth
            error={hasError}
            variant="standard"
            value={field.value}
            disabled={isDisabled}
            renderValue={(selected) => {
              const item = geographicalCodes.find(
                (option) => option.iso_alpha_2_code === selected,
              )

              if (!item) return ''

              return `${item?.flag} +${item?.telephone_code}`
            }}
          >
            {geographicalCodes.map((option) => {
              return (
                <MenuItem
                  data-testid={`option-${option.country_name}-${option.iso_alpha_2_code}`}
                  key={`${option.country_name}-${option.iso_alpha_2_code}`}
                  value={option.iso_alpha_2_code}
                >
                  {option.flag} {option.formattedCountryName} +
                  {option.telephone_code}
                </MenuItem>
              )
            })}
          </Select>
        )
      }}
    </Field>
  )
}

export default SelectCountryCode
