import React from 'react'

import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarHalfIcon from '@mui/icons-material/StarHalf'
import styled from '@emotion/styled'

const StyledContainer = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2),
}))

const StyledStarIcon = styled(StarIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  verticalAlign: 'middle',
  display: 'inline-flex',
}))

const StyledStarHalfIcon = styled(StarHalfIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  verticalAlign: 'middle',
  display: 'inline-flex',
}))

const StyledStarBorderIcon = styled(StarBorderIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  verticalAlign: 'middle',
  display: 'inline-flex',
}))

interface Props {
  starsAverage?: string
}

export const StarRating = (props: Props) => {
  const { starsAverage } = props

  let stars: React.ReactNode[] = []
  const totalStars = 5

  if (starsAverage) {
    let starsRemainder
    if (parseFloat(starsAverage) >= 1) {
      starsRemainder = 0
    } else {
      starsRemainder = starsAverage
    }

    if (parseFloat(starsAverage) <= totalStars) {
      for (let i = parseFloat(starsAverage); i >= 1; i = i - 1) {
        stars.push(
          <StyledStarIcon data-testid="filled-star" key={`filled-star-${i}`} />,
        )
        starsRemainder = i - 1
      }
      if ((starsRemainder as number) >= 0.5 && (starsRemainder as number) < 1) {
        stars.push(
          <StyledStarHalfIcon data-testid="half-star" key="half-star" />,
        )
      }

      while (stars.length < totalStars) {
        stars.push(
          <StyledStarBorderIcon
            data-testid="empty-star"
            key={`empty-star-${totalStars - stars.length}`}
          />,
        )
      }
    }
  }

  return (
    <StyledContainer data-testid="star-rating-container">
      {stars.map((star) => star)}
    </StyledContainer>
  )
}

export default StarRating
