import { useState, useEffect, SyntheticEvent } from 'react'
import usePrevious from 'hooks/usePrevious'

import Typeahead from '.'

import { Direction } from 'services/pageableHelper'
import { getMarketplaceProducts } from 'services/items'
import { trackCustomEvent } from 'services/fireflyInsights'

import { FireflyEvent } from 'types/FireflyInsights'
import { MarketplaceProduct } from 'types/Item'

interface Props {
  placeholder?: string
  sellerId?: string
  itemTypeId?: string
  onChange: (value: Nullable<MarketplaceProduct>, enteredValue: string) => void
  value?: Nullable<MarketplaceProduct>
  onClear?: () => void
}

const ItemTypeahead = ({
  placeholder = 'SKU, Barcode or TCIN',
  sellerId,
  itemTypeId,
  onChange,
  value: valueFromProps,
  onClear,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedValue, setSelectedValue] =
    useState<Nullable<MarketplaceProduct>>(valueFromProps)
  const [options, setOptions] = useState<MarketplaceProduct[]>([])
  const [loading, setLoading] = useState(false)

  const prevValue = usePrevious(valueFromProps)

  useEffect(() => {
    if (prevValue !== valueFromProps) {
      setSelectedValue(valueFromProps)
    }

    if (!valueFromProps) {
      setOptions([])
    }
  }, [prevValue, valueFromProps])

  useEffect(() => {
    if (options.length && !inputValue) {
      setOptions([])
    }
  }, [inputValue, options])

  const handleInputChange = async (
    _event: SyntheticEvent,
    enteredValue: string,
    reason: string,
  ) => {
    if (
      onClear &&
      (reason === 'clear' ||
        (selectedValue && reason === 'input' && enteredValue === ''))
    ) {
      onClear()
      setOptions([])
      setInputValue('')
      setSelectedValue(null)
      return
    }

    setInputValue(enteredValue)
    const invalidInputValue = !enteredValue || reason === 'reset'
    if (invalidInputValue) {
      return
    }

    const paging = { page: 0, perPage: 20, direction: Direction.ASC }

    const params = {
      seller_id: sellerId,
      item_type_id: itemTypeId,
      query: enteredValue.trim(),
    }

    setLoading(true)
    const { data } = await getMarketplaceProducts(params, paging, true)
    setOptions(data.filter((product) => product.tcin))
    setLoading(false)
  }

  const handleSelectedOptionChange = (
    _event: SyntheticEvent,
    value: MarketplaceProduct,
  ) => {
    if (!(value instanceof Array)) {
      const searchParamValue = getOptionLabel(value as MarketplaceProduct)
      setSelectedValue(value as Nullable<MarketplaceProduct>)
      onChange(value as Nullable<MarketplaceProduct>, searchParamValue)

      trackCustomEvent(FireflyEvent.PAGE_SEARCH, 'search_category', 'item')
    }
  }

  const getOptionLabel = (returnedProduct: MarketplaceProduct) => {
    return returnedProduct?.tcin ?? ''
  }

  return (
    <Typeahead
      aria-label="TCIN Filter"
      placeholder={placeholder}
      getOptionLabel={getOptionLabel}
      options={options}
      loading={loading}
      onInputChange={handleInputChange}
      onChange={handleSelectedOptionChange}
      inputValue={inputValue}
      value={selectedValue}
      filterOptions={(options: MarketplaceProduct[]) =>
        options.filter(
          (option: MarketplaceProduct) =>
            inputValue === option.tcin ||
            inputValue === option.external_id ||
            inputValue === option.barcode,
        )
      }
    />
  )
}

export default ItemTypeahead
