import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { DialogEnum } from 'components/common/Dialog'
import DisplayCard from 'components/common/DisplayCard'
import Link from 'components/common/Link'

import { TargetPlusBarcodeDetails } from '../BarcodeRelease'
import BarcodeReleaseInput from '../BarcodeRelease/BarcodeReleaseInput'
import BarcodeItemSuspend from '../BarcodeRelease/BarcodeItemSuspend'

import { RoutePath } from 'services/NavigationHelper'

import { openDialog } from 'store/dialog/actionCreator'

export const BarcodeChange = () => {
  const dispatch = useDispatch()
  const [changeStep, setChangeStep] = useState(0)
  const [barcodes, setBarcodes] = useState<string[]>([])
  const [title, setTitle] = useState<string>('')
  const [subtitle, setSubtitle] = useState<string>('')

  const fieldList = [
    {
      key: 'barcode',
      heading: 'Barcode',
    },
    {
      key: 'tcin',
      heading: 'TCIN',
      formatCell: (item: TargetPlusBarcodeDetails) => {
        const tcin = item.barcodeInfo.owner?.tcins[0]
        const productId = item.productInfo?.productId
        const sellerId = item.productInfo?.sellerId
        const toPath = `/${sellerId}${RoutePath.ALL_ITEMS}/${productId}`
        return (
          <Link to={toPath} target={'_blank'}>
            {tcin}
          </Link>
        )
      },
    },
    {
      key: 'sellerName',
      heading: 'Owned By',
      formatCell: (item: TargetPlusBarcodeDetails) =>
        item.productInfo?.sellerName,
    },
    {
      key: 'orderCount',
      heading: 'Orders in the Last 120 Days',
    },
  ]

  React.useEffect(() => {
    switch (changeStep) {
      case 0:
        setTitle('Enable Partners to Change Barcodes (Limit to 100)')
        setSubtitle(
          'Release barcodes and allow partners to submit item versions with new barcodes',
        )
        break
      case 1:
        setTitle('Select Barcodes to be Released')
        setSubtitle(
          'Selected barcodes will be released and current version of the items will be rejected',
        )
        break
      default:
        setTitle('')
        setSubtitle('')
    }
  }, [changeStep])

  const handleResetBarcodes = () => {
    setBarcodes([])
    setChangeStep(0)
  }

  const handleBarcodeInputSubmit = (barcodeEntries: string[]) => {
    setBarcodes(barcodeEntries)
    setChangeStep(1)
  }

  const handleRejectRelease = (releaseBarcodes: TargetPlusBarcodeDetails[]) => {
    dispatch(
      openDialog({
        dialogEnum: DialogEnum.REJECT_RELEASE_BARCODE,
        componentProps: {
          releaseBarcodes,
          onClose: () => {
            handleResetBarcodes()
          },
        },
      }),
    )
  }

  return (
    <DisplayCard title={title} description={subtitle}>
      {changeStep === 0 && (
        <BarcodeReleaseInput handleSubmit={handleBarcodeInputSubmit} />
      )}
      {changeStep === 1 && (
        <BarcodeItemSuspend
          barcodes={barcodes}
          submitText="reject & release barcodes"
          fieldList={fieldList}
          handleReset={handleResetBarcodes}
          handleSubmit={handleRejectRelease}
        />
      )}
    </DisplayCard>
  )
}

export default BarcodeChange
