import ShipmentCard from './ShipmentCard'

import Text from 'components/common/Text'

import { useContext } from 'react'
import { OrderShipmentsContext } from './Context'

export const ShipmentContent = () => {
  const { orderShipments } = useContext(OrderShipmentsContext)

  return (
    <div data-testid="shipments">
      {!orderShipments.length && (
        <Text type="micro">This order has no shipments.</Text>
      )}
      {orderShipments.map((shipment, index) => {
        return (
          <ShipmentCard
            key={shipment.tracking_number}
            title={`SHIPMENT ${index + 1}`}
            shipment={shipment}
          />
        )
      })}
    </div>
  )
}

export default ShipmentContent
