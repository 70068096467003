import Attribute from 'types/Attribute'
import { ProductQuota } from 'types/ProductQuota'
import { Validation } from 'types/Validation'

export const setPending = (pending: boolean) =>
  ({
    type: 'SET_PENDING',
    payload: {
      pending,
    },
  }) as const

export const setValidation = ({
  validation,
  isValid,
}: {
  validation: Validation
  isValid: boolean
}) =>
  ({
    type: 'SET_VALIDATION',
    payload: {
      validation,
      isValid,
    },
  }) as const

export const closeAside = () =>
  ({
    type: 'CLOSE_ASIDE',
  }) as const

export const setTableData = (data: ProductQuota[], total: number) =>
  ({
    type: 'SET_TABLE_DATA',
    payload: {
      data,
      total,
    },
  }) as const

export const setItemType = (attribute: Attribute) =>
  ({
    type: 'SET_ITEM_TYPE',
    payload: {
      attribute,
    },
  }) as const

export const setQuotaValue = (value: string) =>
  ({
    type: 'SET_QUOTA_VALUE',
    payload: {
      value,
    },
  }) as const

export const addNewProductQuota = () =>
  ({
    type: 'ADD_NEW_PRODUCT_QUOTA',
  }) as const

export const editProductQuota = (productQuota: ProductQuota) =>
  ({
    type: 'EDIT_PRODUCT_QUOTA',
    payload: {
      productQuota,
    },
  }) as const

export type GlobalQuotasActions = ReturnType<
  | typeof setPending
  | typeof setValidation
  | typeof closeAside
  | typeof setTableData
  | typeof setItemType
  | typeof setQuotaValue
  | typeof addNewProductQuota
  | typeof editProductQuota
>

interface State {
  pending: boolean
  validation: Validation
  isValid: boolean
  totalQuotas: number
  allQuotas: ProductQuota[]
  isAsideOpen: boolean
  asideTitle: string
  isEdit: boolean
  initialQuotaValue: number
  quotaValue: string
  quotaId: string
  attribute: Nullable<Attribute>
}

const INITIAL_QUOTA = 0

export const initialReducerState: State = {
  pending: false,
  validation: {},
  isValid: false,
  totalQuotas: 0,
  allQuotas: [],
  isAsideOpen: false,
  asideTitle: '',
  isEdit: false,
  initialQuotaValue: INITIAL_QUOTA,
  quotaValue: INITIAL_QUOTA.toString(),
  quotaId: '',
  attribute: null,
}

export const reducer = (state: State, action: GlobalQuotasActions): State => {
  switch (action.type) {
    case 'SET_PENDING': {
      const { payload } = action
      return {
        ...state,
        pending: payload.pending,
      }
    }
    case 'SET_VALIDATION': {
      const { payload } = action
      return {
        ...state,
        validation: payload.validation,
        isValid: payload.isValid,
      }
    }
    case 'CLOSE_ASIDE': {
      return {
        ...state,
        isAsideOpen: false,
      }
    }
    case 'SET_TABLE_DATA': {
      const { payload } = action
      return {
        ...state,
        allQuotas: payload.data,
        totalQuotas: payload.total,
        pending: false,
      }
    }
    case 'SET_ITEM_TYPE': {
      const { payload } = action
      return {
        ...state,
        attribute: payload.attribute,
      }
    }
    case 'SET_QUOTA_VALUE': {
      return {
        ...state,
        quotaValue: action.payload.value,
      }
    }
    case 'ADD_NEW_PRODUCT_QUOTA': {
      return {
        ...state,
        isAsideOpen: true,
        asideTitle: 'Add Quota',
        isEdit: false,
        attribute: initialReducerState.attribute,
        initialQuotaValue: initialReducerState.initialQuotaValue,
        quotaValue: initialReducerState.quotaValue,
      }
    }
    case 'EDIT_PRODUCT_QUOTA': {
      const { payload } = action
      return {
        ...state,
        isAsideOpen: true,
        asideTitle: 'Edit Quota',
        isEdit: true,
        attribute: {
          id: payload.productQuota.taxonomy_id,
          name: payload.productQuota.taxonomyName,
        },
        initialQuotaValue: payload.productQuota.quota,
        quotaValue: payload.productQuota.quota.toString(),
        quotaId: payload.productQuota.id ?? '',
      }
    }
    default:
      return state
  }
}

export default reducer
