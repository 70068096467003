import axios from 'axios'

import apiConfig from 'config/apiConfig'
import { ReportType } from 'types/Report'

import { UserSettings } from 'types/UserSettings'

export const fetchUserSettings = async (): Promise<UserSettings> => {
  const response = await axios.get(`${apiConfig.sms}/user_settings`)

  return response.data
}

export const updateUserSettings = async ({ data }: { data: UserSettings }) => {
  const response = await axios.put(`${apiConfig.sms}/user_settings`, data)

  return response.data
}

type UpdateFavoriteReportParams = {
  reportType: ReportType
  settings: UserSettings
}

export const updateUserSettingsFavoriteReports = async ({
  reportType,
  settings,
}: UpdateFavoriteReportParams) => {
  const data = addOrRemoveFavoriteReport({ reportType, settings })

  const result = await updateUserSettings({ data })

  return result
}

export const addOrRemoveFavoriteReport = ({
  reportType,
  settings,
}: UpdateFavoriteReportParams): UserSettings => {
  const favoriteReports = settings.reports?.favorites ?? []

  const removeReport = favoriteReports.includes(reportType)

  const nextFavoriteReports = removeReport
    ? favoriteReports.filter((type) => reportType !== type)
    : [...favoriteReports, reportType]

  return {
    ...settings,
    reports: {
      favorites: nextFavoriteReports,
    },
  }
}
