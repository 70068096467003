import { memo, useState } from 'react'
import { Button, Dialog, Drawer, Input } from '@enterprise-ui/canvas-ui-react'
import { Typography } from '@mui/material'
import { XLXS_CONTENT_TYPE } from 'constants/fileTypes'
import { MEGABYTES_AS_BYTES_1 } from 'constants/sizeLimits'
import { DrawerButtons, FileContainer } from './styles'
import EnterpriseIcon, { CancelCircleIcon } from '@enterprise-ui/icons'
import { submitReport } from 'services/reports'
import { ReportType } from 'types/Report'
import { useDispatch } from 'react-redux'
import { showNotification } from 'store/notification/reducer'
import { useStateValue } from 'stateManager/StateProvider'

interface DrawerContainerProps {
  isDrawerVisible: boolean
  onRequestClose: () => void
}

const DrawerContainer = ({
  isDrawerVisible,
  onRequestClose,
}: DrawerContainerProps) => {
  const { state, updateState } = useStateValue()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [isPending, setIsPending] = useState<boolean>()
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>()

  const dispatch = useDispatch()

  const handleFileNameChange = (event: any) => {
    updateState({ fileName: event.target.value })
  }

  const handleConfirmation = () => {
    handleFileUpload()
    setIsDialogVisible(false)
  }

  const handleFileChange = (event: any) => {
    if (event.target.files[0]?.size <= MEGABYTES_AS_BYTES_1) {
      updateState({ file: event.target.files[0] })
      setErrorMessage('')
    } else {
      setErrorMessage('File size exceeds 1MB. Please reduce and retry.')
    }
  }

  const onClearFileButtonClick = () => {
    updateState({ file: undefined })
  }

  const handleFileUpload = async () => {
    if (isPending) {
      return
    }
    setIsPending(true)
    try {
      const savedReport = await submitReport({
        type: ReportType.BULK_ITEM_UNLIST,
        reportInput: state.file,
        reportName: state.fileName,
      })

      setErrorMessage('')
      if (savedReport) {
        dispatch(
          showNotification({
            isShown: true,
            message: 'Upload successful!',
            severity: 'success',
            autoClose: true,
          }),
        )
        updateState({
          isBulkUnlistNewFileUpload: true,
        })
        setIsPending(false)
        updateState({ file: undefined, fileName: '' })
        onRequestClose()
      }
    } catch (e) {
      setIsPending(false)
      updateState({ file: undefined, fileName: '' })
      setErrorMessage('Server error. Please retry.')
      dispatch(
        showNotification({
          isShown: true,
          message: 'Server error. Please retry.',
          autoClose: true,
        }),
      )
    }
  }

  return (
    <Drawer
      isVisible={isDrawerVisible}
      headingText="Upload Unlisting File"
      onRequestClose={onRequestClose}
      xs={3}
    >
      <div className="hc-bg-grey07 hc-pa-normal">
        <Typography className="hc-mb-min">File Name*</Typography>
        <Input.Text
          data-testid="file-name-input"
          value={state.fileName}
          onChange={handleFileNameChange}
          placeholder="Enter File Name"
        />
        {state.file ? (
          <FileContainer className="hc-bg-contrast-weak hc-mt-normal">
            <Typography className="hc-fs-sm hc-pl-md hc-pt-min">
              {state.file?.name}
            </Typography>
            <Button onClick={onClearFileButtonClick} type="ghost">
              <EnterpriseIcon icon={CancelCircleIcon} />
            </Button>
          </FileContainer>
        ) : (
          <div className="hc-mt-normal">
            <Input.DropArea
              accept={`.csv, ${XLXS_CONTENT_TYPE}`}
              fullwidth={true}
              onUpdate={handleFileChange}
              clickOnly
              dropText="Upload excel sheet"
              instructionText="Please upload the excel sheet containing only validated TCINs for unlisting"
            />
          </div>
        )}

        {errorMessage && (
          <div className="hc-fs-sm hc-mt-min hc-clr-error hc-bg-grey07">
            {errorMessage}
          </div>
        )}
      </div>

      <DrawerButtons className="display-flex hc-mt-md ">
        <Button
          className="hc-mr-dense hc-pr-expanded hc-pl-expanded"
          type="secondary"
          onClick={onRequestClose}
        >
          Cancel
        </Button>
        <Button
          className="hc-mr-dense hc-pr-expanded hc-pl-expanded"
          type="primary"
          disabled={!state.file || !state.fileName}
          onClick={() => setIsDialogVisible(true)}
          isLoading={isPending}
        >
          Save
        </Button>
      </DrawerButtons>
      <Dialog
        bodyText="You are about to unlist items. Please note that once an item is unlisted, sellers need to reslist them from Channel partner console/own console for direct partners. If the associated barcode is released, the partner may have to create a new listing. Do you wish to proceed?"
        headingText="Confirm bulk unlisting"
        isVisible={isDialogVisible}
        refuseButtonText="Cancel"
        onRefuse={() => {
          setIsDialogVisible(false)
        }}
        approveButtonText="Proceed to unlist"
        onApprove={handleConfirmation}
      />
    </Drawer>
  )
}

export default memo(DrawerContainer)
