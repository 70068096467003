import React, { useEffect, useRef, FC } from 'react'

import styled from '@emotion/styled'
import Popper from '@mui/material/Popper'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import DoneIcon from '@mui/icons-material/Done'
import CancelIcon from '@mui/icons-material/Cancel'

import Text from 'components/common/Text'

import { white } from 'config/themeConfig'

import { SmsSeller } from 'types/Seller'
import Attribute, { ITEM_TYPE, PRODUCT_TYPE } from 'types/Attribute'
import { isAttribute, isSmsSeller } from 'types/Guards'

import { useMultiSearch, Value, RequiredProps } from './useMultiSearch'

const StyledBtn = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'hasValue',
})<{ hasValue: boolean }>((props) => ({
  color: props.hasValue
    ? props.theme.palette.grey[900]
    : props.theme.palette.grey[700],

  borderColor: props.theme.palette.grey[400],

  backgroundColor: props.hasValue
    ? props.theme.palette.primary.light
    : white.main,

  '& .MuiSvgIcon-root': {
    color: props.theme.palette.primary.main,
  },

  '&:hover': {
    color: props.hasValue
      ? props.theme.palette.grey[900]
      : props.theme.palette.grey[700],

    backgroundColor: props.hasValue
      ? props.theme.palette.primary.light
      : white.main,

    borderColor: props.theme.palette.common.black,
  },
}))

const StyledSpace = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

const StyledChip = styled(Chip)<{ component: string }>(({ theme }) => ({
  margin: `${theme.spacing(0.5)} ${theme.spacing(0.5)} ${theme.spacing(0.5)} 0`,
  boxSizing: 'content-box',
  padding: theme.spacing(0.25),
  whiteSpace: 'break-spaces',
  '& .MuiChip-label': {
    whiteSpace: 'break-spaces',
  },
}))

const StyledBtnRow = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
})

const getButtonProps = (
  open: boolean,
  value: Value,
  text: string,
): { icon: React.ReactNode; text: string } => {
  if (!value) {
    return {
      text,
      icon: open === true ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />,
    }
  } else {
    return {
      text: Array.isArray(value) ? `${value.length} ${text}` : `1 ${text}`,
      icon: <DoneIcon color="primary" />,
    }
  }
}

export type Props<T> = {
  typeahead: FC<any>
  text: string
  value: Value
  searchParam: string
  sellerId?: string
  onChange: (params: Dictionary<string | string[] | undefined>) => void
  getItemAsync: (id: string) => Promise<T | undefined>
}

export function MultiSearchFilter<T extends RequiredProps>({
  typeahead,
  text,
  value,
  searchParam,
  sellerId,
  onChange,
  getItemAsync,
}: Props<T>) {
  const {
    open,
    data,
    dispatchSetStatus,
    dispatchSetData,
    dispatchTogglePopper,
    dispatchAddItem,
    dispatchRemoveItem,
  } = useMultiSearch<T>(value, getItemAsync)

  const anchorRef = useRef<HTMLButtonElement | null>(null)

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)
  useEffect(() => {
    if (anchorRef.current && prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const handleToggleOpen = () => {
    const nextValue = !open

    if (nextValue === true && value && value.length > data.length) {
      dispatchSetStatus('LOADING')
    } else if (nextValue === true && !value && data.length) {
      dispatchSetData([])
    }

    if (nextValue === false) {
      handleOnChange()
    }

    dispatchTogglePopper(nextValue)
  }

  const handleClose = (event: any) => {
    // If the popper is open and the user clicks the button again just bail
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    handleOnChange()

    dispatchTogglePopper(false)
  }

  const handleTypeaheadChange = (typeaheadValue: T | undefined | null) => {
    if (!typeaheadValue) {
      return
    }

    const isDuplicateValue = data.some((item) => item?.id === typeaheadValue.id)

    if (isDuplicateValue) {
      return
    }

    dispatchAddItem(typeaheadValue)
  }

  const handleDelete = (item: T) => () => {
    dispatchRemoveItem(item)
  }

  const handleOnChange = () => {
    const ids = data.map((item) => item.id)
    if (value === undefined && ids.length === 0) {
      return
    }
    onChange({ [searchParam]: ids.length ? ids : undefined })
  }

  const handleOnClear = (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    dispatchSetData([])
    onChange({ [searchParam]: undefined })
  }

  const id =
    open && Boolean(anchorRef.current) ? 'transition-popper' : undefined

  const buttonProps = getButtonProps(open, value, text)

  const endIcon = value ? (
    <CancelIcon
      onClick={handleOnClear}
      aria-label={`Remove ${text} Filter`}
      data-testid="remove-all"
      color="primary"
    />
  ) : undefined

  const TypeaheadComponent = typeahead

  const DATA_LIMIT = 20

  const typeaheadProps = {
    clearOnSelect: true,
    placeholder: text,
    onChange: handleTypeaheadChange,
    hasVmmIdSearch: !sellerId,
    isDisabled: data.length >= DATA_LIMIT,
    blurOnSelect: false,
    ...(searchParam === 'item_type_id'
      ? { searchType: ITEM_TYPE }
      : searchParam === 'product_type_id'
      ? { searchType: PRODUCT_TYPE }
      : {}),
  }

  return (
    <div>
      <StyledBtn
        fullWidth
        hasValue={!!value}
        ref={anchorRef}
        variant="outlined"
        sx={{
          textTransform: 'none',
          height: '36px',
          overflow: 'hidden',
          whiteSpace: 'noWrap',
          textOverflow: 'ellipsis',
          display: 'inline-block',
        }}
        onClick={handleToggleOpen}
        startIcon={buttonProps.icon}
        endIcon={endIcon}
      >
        {value ? buttonProps.text : text}
      </StyledBtn>
      <Popper
        transition
        id={id}
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper sx={{ p: 2, width: 300 }} data-testid="popper-root">
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <Stack spacing={1}>
                    <TypeaheadComponent {...typeaheadProps} />
                  </Stack>
                  <StyledSpace>
                    <Text type="bodySm">
                      {data.length === 0
                        ? `0 Added`
                        : `${data.length} Added, Max of ${DATA_LIMIT} allowed`}
                    </Text>
                  </StyledSpace>
                  <StyledSpace>
                    {data.map((item: SmsSeller | Attribute) => {
                      let label = ''

                      if (isSmsSeller(item)) {
                        label = item.display_name ?? item.legal_business_name
                      } else if (isAttribute(item)) {
                        label = item.name ?? item.id
                      }
                      return (
                        <StyledChip
                          key={item.id}
                          component="span"
                          label={label}
                          data-testid={`chip-${label}`}
                          onDelete={handleDelete(item as any)}
                        />
                      )
                    })}
                  </StyledSpace>
                  <StyledBtnRow>
                    <Button onClick={handleClose}>Done</Button>
                  </StyledBtnRow>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

export default MultiSearchFilter
