import Button from '@mui/material/Button'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

import ContentSpacer from 'components/common/ContentSpacer'
import { DialogEnum } from 'components/common/Dialog'
import Heading from 'components/common/Heading'
import Link from 'components/common/Link'
import TitleBar from 'components/common/TitleBar'
import RequiredContent from 'components/common/RequiredContent'

import { FinancialDisposition, PhysicalDisposition } from 'constants/categories'

import { isOneOfUserRoles } from 'services/authorization'
import { RoutePath } from 'services/NavigationHelper'
import { USER_ROLE_OPS, USER_ROLE_ADMIN } from 'services/roles'
import { getVendorCategories } from 'services/marketplaceVendors'
import { getReturnPolicies } from 'services/returnPoliciesService'
import { approvedToListStatuses } from 'services/seller'

import { closeDialog, openDialog } from 'store/dialog/actionCreator'
import {
  currentSellerId,
  getMemberOf,
  currentSellerStatus,
  isDefunctSellerStatus,
  getSellerContacts,
} from 'store/selectors'

import {
  SellerStatus,
  SmsReturnPolicy,
  VendorCategory,
  ReturnPolicyStatus,
} from 'types/Seller'

import ReturnPolicyCard from './ReturnPolicyCard'

const ReturnPoliciesContent = () => {
  const dispatch = useDispatch()
  const memberOf = useSelector(getMemberOf)
  const sellerId = useSelector(currentSellerId)
  const sellerStatus = useSelector(currentSellerStatus)
  const contacts = useSelector(getSellerContacts)

  const [returnPolicies, setReturnPolicies] = useState<SmsReturnPolicy[]>([])
  const [vendorCategories, setVendorCategories] = useState<VendorCategory[]>([])
  const [canDeactivate, setCanDeactivate] = useState<boolean>(false)
  const [refreshReturnPolicies, setRefreshReturnPolicies] =
    useState<boolean>(true)

  useEffect(() => {
    let mounted = true

    getVendorCategories().then((response) => {
      if (mounted) {
        setVendorCategories(response)
      }
    })

    return () => {
      mounted = false
    }
  }, [])

  useEffect(() => {
    let mounted = true

    if (sellerId && refreshReturnPolicies) {
      getReturnPolicies(sellerId).then((response) => {
        if (mounted) {
          setRefreshReturnPolicies(false)
          setReturnPolicies(response)
        }
      })
    }

    return () => {
      mounted = false
    }
  }, [sellerId, refreshReturnPolicies])

  useEffect(() => {
    setCanDeactivate(
      returnPolicies.filter(
        (returnPolicy) =>
          returnPolicy.physical_disposition_id ===
            PhysicalDisposition.ReturnViaCrc &&
          returnPolicy.financial_disposition_id ===
            FinancialDisposition.ChargeToPartner &&
          returnPolicy.status === ReturnPolicyStatus.ACTIVE,
      ).length > 1 &&
        isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN, USER_ROLE_OPS]) &&
        (
          [...approvedToListStatuses, SellerStatus.PARTNER_SETUP] as (
            | string
            | undefined
          )[]
        ).includes(sellerStatus),
    )
  }, [returnPolicies, memberOf, sellerStatus])

  const isDefunct = useSelector(isDefunctSellerStatus)
  const hasAdd =
    isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN, USER_ROLE_OPS]) && !isDefunct

  const isProductAdmin = isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN])
  const isOps = isOneOfUserRoles(memberOf, [USER_ROLE_OPS])

  const partnerApplicablePolicies = returnPolicies.filter(
    (returnPolicy) =>
      returnPolicy.financial_disposition_id !== FinancialDisposition.WriteOff,
  )

  const hazardousItemsPolicies = returnPolicies.filter(
    (returnPolicy) =>
      returnPolicy.financial_disposition_id === FinancialDisposition.WriteOff,
  )

  const handleCloseDialog = () => {
    dispatch(closeDialog())
  }

  const handleUpdatePolicies = () => {
    dispatch(
      openDialog({
        dialogEnum: DialogEnum.EDIT_RETURN_POLICY,
        componentProps: {
          handleCloseDialog,
          vendorCategories,
          isProductAdmin,
          isOps,
          contacts,
        },
        closeCallback: handleRefresh,
      }),
    )
  }

  const handleRefresh = () => {
    setRefreshReturnPolicies(true)
  }

  return (
    <div data-testid="return-policies">
      <TitleBar
        title="RETURN POLICIES"
        actionButtons={
          hasAdd
            ? [
                <Button
                  key="add-policy-button"
                  data-testid="add-policy-button"
                  onClick={handleUpdatePolicies}
                  color="primary"
                  variant="contained"
                  aria-label="add a new return policy"
                >
                  <AddIcon />
                  Add New Policy
                </Button>,
              ]
            : undefined
        }
      />
      <ContentSpacer>
        <Typography>
          At least one return policy is required before items can list. To
          change a return address or return contact, please{' '}
          <Link to={`/${sellerId}${RoutePath.OPEN_CASES}`} target="_blank">
            open a case
          </Link>
          .
        </Typography>
        {(!returnPolicies || !returnPolicies.length) && (
          <RequiredContent
            description="No return policies added."
            hasRequiredText={false}
          />
        )}
        {partnerApplicablePolicies.length > 0 && (
          <Heading
            title="Policy IDs that can be applied by Partner"
            variant="h3"
          />
        )}
        {partnerApplicablePolicies.map((returnPolicy, index) => {
          const { id } = returnPolicy

          return (
            <div key={id}>
              {index > 0 && <Divider />}
              <ReturnPolicyCard
                hasHeader={index === 0}
                returnPolicy={returnPolicy}
                vendorCategories={vendorCategories}
                canDeactivate={canDeactivate}
                refresh={handleRefresh}
              />
            </div>
          )
        })}
        {hazardousItemsPolicies.length > 0 && (
          <Heading
            title="Policy IDs applied by Target to certain hazardous items"
            variant="h3"
          />
        )}
        {hazardousItemsPolicies.map((returnPolicy, index) => {
          const { id } = returnPolicy
          return (
            <div key={id}>
              {index > 0 && <Divider />}
              <ReturnPolicyCard
                hasHeader={index === 0}
                returnPolicy={returnPolicy}
                vendorCategories={vendorCategories}
                canDeactivate={canDeactivate}
                refresh={handleRefresh}
              />
            </div>
          )
        })}
      </ContentSpacer>
    </div>
  )
}

export default ReturnPoliciesContent
