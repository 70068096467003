import axios from 'axios'

import apiConfig from 'config/apiConfig'

import { STRIPE_TRANSFER_PAGE_SIZE } from './payments'

import { isoStringStartOfDay, isoStringEndOfDay } from './dateService'
import { Payout } from 'types/Payout'
import { Response } from 'types/Response'
import { StripeParams } from 'types/StripeParams'

export async function getPayouts({
  sellerId,
  perPage = STRIPE_TRANSFER_PAGE_SIZE,
  resultsOffsetId,
  fromDate,
  toDate,
}: StripeParams): Promise<Payout[]> {
  const fromStr = fromDate ? isoStringStartOfDay(fromDate) : null
  const toStr = toDate ? isoStringEndOfDay(toDate) : null

  const config = {
    params: {
      per_page: perPage,
      results_offset_id: resultsOffsetId,
      created_before: toStr,
      created_after: fromStr,
    },
  }

  const { data }: Response<Payout> = await axios.get(
    `${apiConfig.sms}/sellers/${sellerId}/payouts_search`,
    config,
  )

  return data
}
