import List from '@mui/material/List'

import OrderItem from './OrderItem'

import { OrderItemData } from 'types/Orders'

interface Props {
  items: Array<OrderItemData & { unit_price?: number }>
}

export const OrderItemList = (props: Props) => {
  return (
    <List>
      {props.items.map((item, index) => {
        return <OrderItem key={index} orderItem={item} />
      })}
    </List>
  )
}

export default OrderItemList
