import FormHelperText from '@mui/material/FormHelperText'

import { Validation } from 'types/Validation'

interface Props {
  errors: Validation
  field: string
}

const ValidationErrorText = (props: Props) => (
  <div>
    {props.errors[props.field] && props.errors[props.field].length > 0 && (
      <div>
        {Array.isArray(props.errors[props.field]) &&
          props.errors[props.field].map((detail: string) => (
            <FormHelperText key={detail} error>
              {detail}
            </FormHelperText>
          ))}
        {typeof props.errors[props.field] === 'string' && (
          <FormHelperText error>{props.errors[props.field]}</FormHelperText>
        )}
      </div>
    )}
  </div>
)
ValidationErrorText.defaultProps = {
  errors: {},
  field: '',
}

export default ValidationErrorText
