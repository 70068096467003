import React from 'react'
import styled from '@emotion/styled'
import isFunction from 'lodash/fp/isFunction'
import startCase from 'lodash/fp/startCase'

import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'

import { EnhancedTableFieldType } from './index'
import EnhancedTableCell from './EnhancedTableCell'

import { Direction } from 'services/pageableHelper'

interface Props {
  direction?: Direction
  displayActionColumn?: boolean
  isUpdatedTable?: boolean
  fieldList: EnhancedTableFieldType[]
  fieldListContext?: any
  onClick?: any
  orderBy?: string
  paginationComponent?: React.ReactNode
}

const StyledContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const StyledSubHeading = styled.div((props) => ({
  order: 2, // Force subheading to come after heading when table column header is sortable
  width: '100%',
  fontSize: '0.875rem',
  fontWeight: 300,
  fontStyle: 'italic',
  color: props.theme.palette.grey[900],
  marginTop: props.theme.spacing(0.5),
}))

export const EnhancedTableHead = ({
  fieldList,
  fieldListContext,
  direction,
  orderBy,
  paginationComponent,
  displayActionColumn,
  onClick,
  isUpdatedTable,
}: Props) => {
  const createRequestSort = (field: object) => () => {
    if (onClick) {
      onClick(field)
    }
  }

  return (
    <TableHead>
      {paginationComponent && <TableRow>{paginationComponent}</TableRow>}
      <TableRow>
        {fieldList.map((item: any, index: number) => {
          const {
            key,
            formatHeader,
            heading,
            displayName,
            subHeading,
            hasSort,
            tooltip,
            minWidth,
            width,
          } = item

          const active = orderBy === key
          const label = heading
            ? heading
            : displayName
            ? displayName
            : startCase(key)

          let content
          if (formatHeader && isFunction(formatHeader)) {
            content = formatHeader(item, fieldListContext)
          } else {
            content = label
          }

          return (
            <EnhancedTableCell
              key={key || index}
              data-testid={`${key}-header`}
              label={label}
              active={active}
              direction={direction}
              hasSort={hasSort}
              onClick={createRequestSort(item)}
              minWidth={minWidth}
              width={width}
              variant="head"
              isUpdatedTable={isUpdatedTable}
            >
              <StyledContainer>
                {content}
                {tooltip && (
                  <Tooltip
                    sx={{
                      marginLeft: 1,
                    }}
                    title={tooltip}
                  >
                    <InfoIcon color="primary" />
                  </Tooltip>
                )}
              </StyledContainer>
              {subHeading && !!hasSort && (
                <StyledSubHeading>{subHeading}</StyledSubHeading>
              )}
              {subHeading && !hasSort && <div>{subHeading}</div>}
            </EnhancedTableCell>
          )
        })}
        {displayActionColumn && <EnhancedTableCell />}
      </TableRow>
    </TableHead>
  )
}

export default EnhancedTableHead
