import React from 'react'
import styled from '@emotion/styled'

import IconButton from '@mui/material/IconButton'
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'

const StyledRoot = styled.div((props) => ({
  flexShrink: 0,
  marginLeft: props.theme.spacing(2),
}))

export const TablePaginationActions = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
}: TablePaginationActionsProps) => {
  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement> | null,
  ) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement> | null,
  ) => {
    onPageChange(event, page + 1)
  }

  if (count <= rowsPerPage) {
    return null
  }

  return (
    <StyledRoot>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous"
        data-testid="previous"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next"
        data-testid="next"
      >
        <KeyboardArrowRight />
      </IconButton>
    </StyledRoot>
  )
}

export default TablePaginationActions
