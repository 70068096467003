import React from 'react'
import { useDispatch } from 'react-redux'
import getOr from 'lodash/fp/getOr'
import has from 'lodash/fp/has'
import sortBy from 'lodash/fp/sortBy'
import last from 'lodash/fp/last'
import flow from 'lodash/fp/flow'

import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'

import { DialogEnum } from 'components/common/Dialog'

import { openDialog } from 'store/dialog/actionCreator'
import { getItemHistories } from 'services/items'
import {
  formatDate,
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
} from 'services/dateService'

import { SmsProduct, ProductStatus, ListingStatus } from 'types/Item'

import ItemDataTable, { ItemDataEnum } from './ItemDataTable'

const getCreatedDate: (statuses: ProductStatus[]) => string = flow(
  sortBy('created'),
  last,
  getOr('', 'created'),
)

export type Props = {
  isItemPending: boolean
  smsProduct?: SmsProduct
}

export const LatestItemData: React.FC<Props> = ({
  smsProduct,
  isItemPending,
}) => {
  const reduxDispatch = useDispatch()
  const [pending, setPending] = React.useState(false)
  const [publishedItem, setPublishedItem] = React.useState<SmsProduct>()
  const hasDifferentPublishedVersion =
    getOr([], 'product_statuses', smsProduct).length > 1

  React.useEffect(() => {
    setPublishedItem(undefined)
    if (has('tcin', smsProduct) && hasDifferentPublishedVersion) {
      setPending(true)
      getItemHistories(smsProduct!.seller_id, smsProduct!.product_id)
        .then((response) => {
          const published = response.find(
            (product) =>
              !!product.product_statuses.find(
                (status) =>
                  status.current &&
                  status.listing_status === ListingStatus.APPROVED,
              ),
          )
          setPublishedItem(published)
        })
        .finally(() => setPending(false))
    }
  }, [smsProduct, hasDifferentPublishedVersion])

  const viewPublishedData = () =>
    reduxDispatch(
      openDialog({
        dialogEnum: DialogEnum.PUBLISHED_ITEM_DATA,
        componentProps: { publishedItem },
      }),
    )

  const createdDate = smsProduct
    ? getCreatedDate(smsProduct.product_statuses)
    : ''

  return (
    <React.Fragment>
      {isItemPending || pending ? (
        <LinearProgress data-testid="latest-item-progress" />
      ) : smsProduct ? (
        <React.Fragment>
          <Typography>
            The latest version of the item was received on{' '}
            {formatDate(createdDate, DATE_FORMAT_MONTH_DAY_YEAR_TIME)}.
          </Typography>
          {publishedItem && (
            <Typography paragraph>
              A previous version of this item is published.{' '}
              <Button onClick={viewPublishedData} color="primary">
                View published data
              </Button>
            </Typography>
          )}
          <ItemDataTable item={smsProduct} itemDataType={ItemDataEnum.LATEST} />
        </React.Fragment>
      ) : (
        <Typography>Latest data not available.</Typography>
      )}
    </React.Fragment>
  )
}

export default LatestItemData
