import { SyntheticEvent, useEffect, useState } from 'react'

import usePrevious from 'hooks/usePrevious'

import { InputLabel } from '@mui/material'

import Typeahead from '.'

import { Direction } from 'services/pageableHelper'
import { searchProductReturns } from 'services/productReturns'
import { getReturnsResearch } from 'services/sellerReturns'

import { Return, ReturnSearchParams, ReturnsResearch } from 'types/Orders'

export interface Props {
  sellerId: string | undefined
  onChange: (
    value: Nullable<Return | ReturnsResearch>,
    enteredValue: string,
  ) => void
  placeholder?: string
  label?: string
  clearOnSelect?: boolean
  value?: Nullable<Return | ReturnsResearch>
  onClear?: () => void
  perPage?: number
  returnsResearch?: boolean
}

const ShipmentIdTypeahead = ({
  sellerId,
  onChange,
  placeholder,
  label,
  clearOnSelect,
  value: valueFromProps,
  onClear,
  perPage,
  returnsResearch,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedValue, setSelectedValue] =
    useState<Nullable<Return | ReturnsResearch>>(valueFromProps)
  const [options, setOptions] = useState<Return[] | ReturnsResearch[]>([])
  const [loading, setLoading] = useState(false)

  const prevValue = usePrevious(valueFromProps)

  useEffect(() => {
    if (prevValue !== valueFromProps) {
      setSelectedValue(valueFromProps)
    }

    if (!valueFromProps) {
      setOptions([])
    }
  }, [prevValue, valueFromProps])

  useEffect(() => {
    if (options.length && !inputValue) {
      setOptions([])
    }
  }, [inputValue, options])

  const handleInputChange = async (
    _event: SyntheticEvent,
    enteredValue: string,
    reason: string,
  ) => {
    if (
      onClear &&
      (reason === 'clear' ||
        (selectedValue && reason === 'input' && enteredValue === ''))
    ) {
      onClear()
      setOptions([])
      setInputValue('')
      setSelectedValue(null)
      return
    }

    setInputValue(enteredValue)

    const invalidInputValue =
      !enteredValue || enteredValue.length < 4 || reason === 'reset'
    if (invalidInputValue) {
      return
    }

    const paging = {
      page: 0,
      perPage: perPage ? perPage : 20,
      direction: Direction.ASC,
    }

    const params: ReturnSearchParams = {
      seller_id: sellerId,
      shipment_id: enteredValue,
    }

    setLoading(true)
    const { data } = returnsResearch
      ? await getReturnsResearch(paging, { shipment_id: enteredValue })
      : await searchProductReturns(paging, params)
    setOptions(data)
    setLoading(false)
  }

  const handleSelectedOptionChange = (
    _event: SyntheticEvent,
    value: Return | ReturnsResearch,
  ) => {
    if (!(value instanceof Array)) {
      const searchParamValue = returnsResearch
        ? getReturnsResearchOptionLabel(value as ReturnsResearch)
        : getOptionLabel(value as Return)
      setSelectedValue(
        clearOnSelect ? null : (value as Nullable<Return | ReturnsResearch>),
      )
      onChange(value as Nullable<Return | ReturnsResearch>, searchParamValue)
    }
  }

  const getReturnsResearchOptionLabel = (returnItem: ReturnsResearch) =>
    returnItem?.shipment_id ?? ''

  const getOptionLabel = (returnItem: Return) => {
    const trackingInfo = returnItem?.tracking_data?.find((trackingItem) => {
      return trackingItem.shipment_id
        ? trackingItem.shipment_id.toString().includes(inputValue)
        : ''
    })
    return trackingInfo?.shipment_id?.toString() ?? ''
  }

  return (
    <div data-testid="shipment-id-typeahead">
      {label && <InputLabel shrink>{label}</InputLabel>}
      <Typeahead
        aria-label="Shipment ID Filter"
        placeholder={placeholder}
        getOptionLabel={
          returnsResearch ? getReturnsResearchOptionLabel : getOptionLabel
        }
        options={options}
        loading={loading}
        onInputChange={handleInputChange}
        onChange={handleSelectedOptionChange}
        inputValue={inputValue}
        value={selectedValue}
        isOptionEqualToValue={(
          option: Return | ReturnsResearch,
          value: Nullable<Return | ReturnsResearch>,
        ) => option.id === value?.id}
      />
    </div>
  )
}

export default ShipmentIdTypeahead
