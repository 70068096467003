import React from 'react'

import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import GetAppIcon from '@mui/icons-material/GetApp'
import FilterListIcon from '@mui/icons-material/FilterList'

import DisplayCard from 'components/common/DisplayCard'
import Text from 'components/common/Text'
import TabularData from 'components/common/TabularData'

import { GENERIC_API_ERROR } from 'constants/errors'

import { isoStringStartOfDay } from 'services/dateService'
import { saveFile } from 'services/files'
import {
  countAllSellersOrders,
  getUnshippedPastDue,
  transformUnshippedOrdersForDownload,
} from 'services/orders'

import { OrderStatus } from 'types/Orders'

const StyledGetAppIcon = styled(GetAppIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))
const StyledDisplayCard = styled(DisplayCard)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))
const StyledCell = styled('div')({
  display: 'flex',
  alignItems: 'center',
})
const StyledCount = styled('div')({ minWidth: '20%' })

const fetchUnshippedPastDue = () =>
  ({
    type: 'FETCH',
  }) as const

const rejectUnshippedPastDue = () =>
  ({
    type: 'REJECT',
  }) as const

const resolveUnshippedPastDue = (pastDueTotal: number, data: any) =>
  ({
    type: 'RESOLVE',
    payload: {
      pastDueTotal,
      data,
    },
  }) as const

type Actions = ReturnType<
  | typeof fetchUnshippedPastDue
  | typeof rejectUnshippedPastDue
  | typeof resolveUnshippedPastDue
>

type State = {
  loading: boolean
  error: boolean
  data: any
  pastDue: number
}

const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case 'FETCH': {
      return {
        ...state,
        loading: true,
      }
    }

    case 'REJECT': {
      return {
        ...state,
        loading: false,
        error: true,
      }
    }

    case 'RESOLVE': {
      return {
        ...state,
        pastDue: action.payload.pastDueTotal,
        data: action.payload.data,
        loading: false,
      }
    }

    default:
      return state
  }
}
const initialState = {
  loading: false,
  error: false,
  data: [],
  pastDue: 0,
}

const now = Date.now()

export type Props = {
  unshippedStatuses: OrderStatus[]
  onFilterBySellersUnshippedOrders: (sellerId: string) => () => void
}

export const UnshippedPastDue: React.FC<Props> = ({
  unshippedStatuses,
  onFilterBySellersUnshippedOrders,
}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fieldList = [
    {
      key: 'name',
      displayName: 'Partner',
      width: 100,
    },
    {
      key: 'count',
      displayName: 'Unshipped Past Due',
      width: 50,
      formatCell: (item: {
        count: number
        name: string
        seller_id: string
      }) => {
        return (
          <StyledCell>
            <StyledCount>{item.count}</StyledCount>
            <Button
              color="primary"
              data-testid={`${item.name}-btn`}
              onClick={onFilterBySellersUnshippedOrders(item.seller_id)}
            >
              <FilterListIcon />
            </Button>
          </StyledCell>
        )
      },
    },
  ]

  React.useEffect(() => {
    let canceled = false

    dispatch(fetchUnshippedPastDue())

    getUnshippedPastDue(unshippedStatuses, `/${isoStringStartOfDay(now)}`)
      .then((res) => {
        if (canceled) return
        dispatch(
          resolveUnshippedPastDue(
            res.orderCount,
            res.unshippedPastDuePartnerNames,
          ),
        )
      })
      .catch(() => {
        if (canceled) return
        dispatch(rejectUnshippedPastDue())
      })

    return () => {
      canceled = true
    }
  }, [unshippedStatuses])

  const handleDownloadClick = () => {
    countAllSellersOrders({
      order_status: unshippedStatuses,
      requested_shipment_date: `/${isoStringStartOfDay(now)}`,
    }).then((res) => {
      transformUnshippedOrdersForDownload(res.data).then((res) => {
        saveFile({ data: res, name: 'unshipped-past-due-orders.csv' })
      })
    })
  }

  return (
    <StyledDisplayCard
      title={`Total Unshipped Past Due: ${state.pastDue}`}
      isLoading={state.loading}
      hasWarning={state.error}
      warningTooltip="An error occurred while getting the information."
      actions={
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Button color="primary" onClick={handleDownloadClick}>
              <StyledGetAppIcon /> Download All Partner Counts
            </Button>
          </Grid>
        </Grid>
      }
    >
      {state.error ? (
        <Text>{GENERIC_API_ERROR}</Text>
      ) : (
        <TabularData
          borderTop
          extraPadding
          fieldList={fieldList}
          data={state.data}
        />
      )}
    </StyledDisplayCard>
  )
}

export default UnshippedPastDue
