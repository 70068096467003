import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import startCase from 'lodash/fp/startCase'

import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'

import styled from '@emotion/styled'

import Link from 'components/common/Link'
import { DialogEnum } from 'components/common/Dialog'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import { formatDateMDYT } from 'components/common/EnhancedTable/formatters'
import FilterBar from 'components/common/FilterBar'
import MultiSelectFilter from 'components/common/FilterBar/MultiSelectFilter'
import HeaderTitle from 'components/common/HeaderTitle'
import LabeledDataList from 'components/common/LabeledDataList'
import TableSpacer from 'components/common/TableSpacer'
import {
  useSearchParams,
  TableState,
  getEnhancedTablePageableProps,
} from 'components/common/FilterBar/useSearchParams'

import { DOWNLOAD_COUNT_LIMIT_TOOLTIP } from 'constants/sizeLimits'

import { isOneOfUserRoles } from 'services/authorization'
import { getPrettyName } from 'services/enumerations'
import { RoutePath } from 'services/NavigationHelper'
import { Direction } from 'services/pageableHelper'
import {
  USER_ROLE_ADMIN,
  USER_ROLE_OPS,
  USER_ROLE_ACQUISITIONS,
} from 'services/roles'
import { searchSellers, getSellerSources } from 'services/seller'

import {
  checkDownloadLimit,
  GenerateReportRequest,
  submitReport,
} from 'services/reports'

import { openDialog } from 'store/dialog/actionCreator'
import { getMemberOf } from 'store/selectors'

import { CollectionResponse } from 'types/Response'
import { EnumerationName } from 'types/Enumeration'
import { SmsSeller, SellerStatus } from 'types/Seller'
import { ReportType } from 'types/Report'
import { SelectOption } from 'types/SelectOption'

import { sellerStatuses } from 'constants/sellerStatuses'

import apiConfig, { ENVIRONMENT_PRODUCTION } from 'config/apiConfig'

const StyledButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const buildSearchParams = ({
  status,
  source,
  fields,
}: {
  status: SellerStatus | undefined
  source: string | undefined
  fields: string[]
}) => {
  return {
    status,
    source,
    fields,
  }
}

type SearchParams = TableState & {
  status: SellerStatus | undefined
  source: string | undefined
  fields: string[]
}

const initialSearchParams: SearchParams = {
  perPage: 100,
  page: 0,
  orderBy: 'last_modified',
  direction: Direction.DESC,
  status: undefined,
  source: undefined,
  fields: [
    'legal_business_name',
    'display_name',
    'status',
    'source',
    'created',
    'last_modified',
    'last_modified_by',
  ],
}

export const SellersPage = () => {
  const dispatch = useDispatch()
  const memberOf = useSelector(getMemberOf)

  const [searchParams, searchParamActions, appliedFilterCount] =
    useSearchParams<SearchParams>(initialSearchParams)
  const [isPending, setIsPending] = useState(false)
  const [sellers, setSellers] = useState<SmsSeller[]>([])

  const [totalSellers, setTotalSellers] = useState(0)
  const [filteredCount, setFilteredCount] = useState(0)
  const [sources, setSources] = useState<SelectOption[]>([])

  const sellerSearchParams = buildSearchParams({
    status: searchParams.status,
    source: searchParams.source,
    fields: searchParams.fields,
  })

  const fieldList: EnhancedTableFieldType<SmsSeller>[] = [
    {
      key: 'legal_business_name',
      hasSort: true,
      formatCell: ({ id, legal_business_name }) => (
        <Link to={`/${id}${RoutePath.DASHBOARD}`}>{legal_business_name}</Link>
      ),
    },
    {
      key: 'display_name',
      hasSort: true,
    },
    {
      key: 'status',
      heading: 'Status',
      hasSort: true,
      formatCell: ({ status }) => startCase(status),
    },
    {
      key: 'source',
      hasSort: true,
      formatCell: ({ source }) => {
        return getPrettyName({
          enumeration: EnumerationName.SOURCE,
          value: source,
        })
      },
    },
    {
      key: 'created',
      hasSort: false,
      formatCell: formatDateMDYT('created'),
    },
    {
      key: 'last_modified',
      hasSort: true,
      formatCell: formatDateMDYT('last_modified'),
    },
    {
      key: 'last_modified_by',
      hasSort: false,
    },
  ]

  useEffect(() => {
    let mounted = true

    getSellerSources({ status: 'ACTIVE' }).then(({ data }) => {
      const formattedSources = data.map((item) => ({
        name: item.description,
        value: item.id,
      }))

      if (mounted) {
        setSources(formattedSources)
      }
    })
    return () => {
      mounted = false
    }
  }, [])

  const getSearchSellers = (isTotalCount: boolean = false) => {
    return searchSellers(
      {
        perPage: isTotalCount ? 1 : searchParams.perPage,
        page: searchParams.page,
        orderBy: searchParams.orderBy,
        direction: searchParams.direction,
      },
      isTotalCount ? { fields: sellerSearchParams.fields } : sellerSearchParams,
    )
  }

  useEffect(() => {
    let mounted = true
    setIsPending(true)

    getSearchSellers().then((response: CollectionResponse<SmsSeller>) => {
      if (mounted) {
        const hasSearchParams =
          sellerSearchParams.status !== undefined ||
          sellerSearchParams.source !== undefined

        setIsPending(false)
        setSellers(response.data)
        setFilteredCount(response.total)

        if (hasSearchParams && totalSellers === 0) {
          getSearchSellers(true).then(
            (response: CollectionResponse<SmsSeller>) => {
              if (mounted) {
                setTotalSellers(response.total)
              }
            },
          )
        } else if (totalSellers === 0) {
          setTotalSellers(response.total)
        }
      }
    })

    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  const handleAddNew = () => {
    dispatch(
      openDialog({
        dialogEnum: DialogEnum.ADD_PARTNER,
        componentProps: {
          sources,
        },
      }),
    )
  }

  const handleCreateReport =
    ({ type }: GenerateReportRequest) =>
    () => {
      return submitReport({
        type,
        parameters: {
          sources: searchParams.source,
          statuses: searchParams.status,
        },
      })
    }

  const handleDownload = () => {
    dispatch(
      openDialog({
        dialogEnum: DialogEnum.REPORT_DOWNLOAD_DIALOG,
        componentProps: {
          title: 'GENERATING REPORT...PLEASE WAIT',
          reportTitle: 'Partner List',
          createReport: handleCreateReport({ type: ReportType.PARTNERS }),
        },
      }),
    )
  }

  const labeledData = [{ label: 'Total Partners:', data: totalSellers }]

  if (!sellers) {
    return <div>No sellers</div>
  }

  const onFilterClear = () => {
    searchParamActions.updateSearchParam(initialSearchParams)
  }

  const canEdit =
    isOneOfUserRoles(memberOf, [
      USER_ROLE_OPS,
      USER_ROLE_ADMIN,
      USER_ROLE_ACQUISITIONS,
    ]) && apiConfig.environment !== ENVIRONMENT_PRODUCTION

  return (
    <div>
      <HeaderTitle title="Partners" />
      <LabeledDataList data={labeledData} />
      {canEdit && (
        <StyledButton
          data-testid="add-seller-button"
          onClick={handleAddNew}
          color="primary"
          variant="contained"
          aria-label="add a new partner"
          disabled={isPending || sources.length === 0}
        >
          <AddIcon />
          ADD PARTNER
        </StyledButton>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FilterBar
            count={filteredCount}
            onClear={onFilterClear}
            onDownload={handleDownload}
            appliedFilterCount={appliedFilterCount}
            disableDownload={checkDownloadLimit(filteredCount)}
            downloadTooltip={DOWNLOAD_COUNT_LIMIT_TOOLTIP}
          >
            <Grid item xs={12} lg={2}>
              <MultiSelectFilter
                placeholder="Status"
                id="status"
                value={searchParams.status}
                searchParam="status"
                onChange={searchParamActions.updateSearchParam}
                data={sellerStatuses}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <MultiSelectFilter
                placeholder="Source"
                id="source"
                value={searchParams.source}
                searchParam="source"
                onChange={searchParamActions.updateSearchParam}
                data={sources}
              />
            </Grid>
          </FilterBar>
          <div>
            <TableSpacer>
              <EnhancedTable
                data={sellers}
                fieldList={fieldList}
                isLoading={isPending}
                total={filteredCount}
                {...getEnhancedTablePageableProps(
                  searchParams,
                  searchParamActions,
                )}
              />
            </TableSpacer>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default SellersPage
