import { useState } from 'react'

import IconButton from '@mui/material/IconButton'
import FilledStarIcon from '@mui/icons-material/Star'
import EmptyStarIcon from '@mui/icons-material/StarBorder'

export interface Props {
  onChangeRating: (rating: number) => void
  isDisabled?: boolean
}

export const Rating = (props: Props) => {
  const { onChangeRating, isDisabled } = props

  const [rating, setRating] = useState<number>(0)

  const getIcon = (star: number, currentRating: number) => {
    if (star <= currentRating) {
      return <FilledStarIcon color="primary" />
    } else {
      return <EmptyStarIcon color="primary" />
    }
  }

  const handleRatingClick = (event: any) => {
    setRating(parseInt(event.currentTarget.value, 10))
    onChangeRating(parseInt(event.currentTarget.value, 10))
  }

  const stars = [1, 2, 3, 4, 5]

  return (
    <>
      {stars.map((star) => {
        return (
          <IconButton
            data-testid={`${star}-star-rating`}
            disabled={isDisabled}
            key={star}
            aria-label={`${star} star`}
            value={star}
            onClick={handleRatingClick}
            size="large"
          >
            {getIcon(star, rating)}
          </IconButton>
        )
      })}
    </>
  )
}

export default Rating
