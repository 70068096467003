export const CATEGORY_CARRIER = 'SHPCARR'
export const CATEGORY_SHIPPING_SERVICE = 'SHPSERV'
export const CATEGORY_SERVICE_LEVEL_AGREEMENT = 'SRCLVLAGM'
export const CATEGORY_PHYSICAL_DISPOSITION = 'PHYDISPCOD'
export const CATEGORY_FINANCIAL_DISPOSITION = 'FINDISPCOD'

export const DEFAULT_SERVICE_LEVEL_AGREEMENT_ID = 6157

export const SLA_EXPRESS = 'EXPRESS'
export const SLA_STANDARD = 'STANDARD'

export enum PhysicalDisposition {
  Destroy = 6004,
  ReturnViaCrc = 6008,
  ReturnToPartner = 6080,
}

export enum FinancialDisposition {
  WriteOff = 6000,
  ChargeToPartner = 6002,
}
