import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import Input from 'components/common/Input'

import { validationHandler, ValidationSchema } from 'services/validation'

import { currentSeller, isSellersPending } from 'store/selectors'
import { editSeller } from 'store/seller/actionCreators'

import { Validation } from 'types/Validation'

interface Props {
  isOpen: boolean
}

const validationSchema: ValidationSchema = yup.object().shape({
  displayName: yup.string().min(2).max(35).label('Display name').required(),
})

export const EditDisplayName = ({ isOpen }: Props) => {
  const seller = useSelector(currentSeller)
  const isPending = useSelector(isSellersPending)

  const dispatch = useDispatch()

  const [displayName, setDisplayName] = React.useState(
    seller?.display_name ?? '',
  )
  const [validation, setValidation] = React.useState<Validation>({})

  const handleInputChange = (value: string) => {
    setDisplayName(
      value.replace(/[^a-zA-Z0-9\s{}()\\;',@%_+-=#$*.?:&[<>\]]*/g, ''),
    )
  }

  const handleInputTrim = (value: string) => {
    const trimmedValue = value.trim()
    setDisplayName(trimmedValue)
  }

  const handleSubmit = () => {
    const { validation: formValidation, isValid } = validationHandler(
      validationSchema,
      { displayName },
    )
    setValidation(formValidation)

    if (isValid && seller) {
      const newSeller = {
        ...seller,
        display_name: displayName,
      }

      dispatch(editSeller(newSeller))
    }
  }

  return (
    <DialogContainer
      title="Edit Display Name"
      isOpen={isOpen}
      isPending={isPending}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            The display name appears on products and seller pages that appear
            throughout the digital guest facing experiences.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Input
            isRequired
            id="display-name"
            name="displayName"
            label="Display Name"
            value={displayName}
            isDisabled={isPending}
            onChange={handleInputChange}
            onBlur={handleInputTrim}
            validation={validation}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export default EditDisplayName
