import { createContext, useContext, useState, ReactNode, FC } from 'react'

interface State {
  [key: string]: any
}

interface ContextValue {
  state: State
  updateState: (newState: Partial<State>) => void
}

interface StateProviderProps {
  children: ReactNode
  initialState?: State // Add initialState to the props
}

/**
 * StateProvider is used to manage the component-level global state. Since we use context, the state can be accessed across all child components.
 *
 * Usage:
 * <StateProvider initialState={{ key: 'value' }}>
 *  <AnyRootComponent />
 * </StateProvider>
 *
 * function AnyRootComponent() {
 *  const {state, updateState} = useStateValue()
 *  // The `state` will be initialized with the `initialState` if provided.
 * }
 */

const StateContext = createContext<ContextValue | null>(null)

export const StateProvider: FC<StateProviderProps> = ({
  children,
  initialState = {},
}) => {
  const [state, setState] = useState<State>(initialState) // Use initialState

  const updateState = (newState: Partial<State>) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }))
  }

  const value: ContextValue = {
    state,
    updateState,
  }

  return <StateContext.Provider value={value}>{children}</StateContext.Provider>
}

// Custom hook to use the state
export const useStateValue = (): ContextValue => {
  const context = useContext(StateContext)
  if (!context) {
    throw new Error('useStateValue must be used within a StateProvider')
  }
  return context
}
