import Grid from '@mui/material/Grid'

import Input from '../Input'
import Select from '../Select'

import COUNTRIES from 'constants/countries'
import { getLocationsFromCountryCode } from 'constants/locations'

import { DEFAULT_COUNTRY } from 'services/addressHelper'

import { Address } from 'types/Address'
import { Validation } from 'types/Validation'

interface Props {
  handleInputChange: (value: any, property: string) => void
  isPending: boolean
  address: Address
  validation: Validation
}

export const AddressInput = (props: Props) => {
  const { address, handleInputChange, validation, isPending } = props
  address.countryCode = address.countryCode || DEFAULT_COUNTRY

  const trimAndFireChange = (value: any, property: string) =>
    handleInputChange(value.trim(), property)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Input
          data-testid="address1"
          isRequired
          id="address-1"
          name="address1"
          label="Address Line 1"
          value={address.address1}
          isDisabled={isPending}
          onChange={handleInputChange}
          onBlur={trimAndFireChange}
          validation={validation}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          id="address-2"
          name="address2"
          label="Address Line 2"
          value={address.address2}
          isDisabled={isPending}
          onChange={handleInputChange}
          onBlur={trimAndFireChange}
          validation={validation}
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          isRequired
          id="city"
          name="city"
          label="City"
          value={address.city}
          isDisabled={isPending}
          onChange={handleInputChange}
          onBlur={trimAndFireChange}
          validation={validation}
        />
      </Grid>
      <Grid item xs={6}>
        <Select
          isRequired
          id="state"
          name="state"
          label="State"
          isDisabled={isPending}
          onChange={handleInputChange}
          options={getLocationsFromCountryCode(address.countryCode)}
          value={address.state}
          keyName="name"
          valueName="abbreviation"
          validation={validation}
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          isRequired
          id="postal-code"
          name="postalCode"
          label="Zip Code"
          value={address.postalCode}
          isDisabled={isPending}
          onChange={handleInputChange}
          onBlur={trimAndFireChange}
          validation={validation}
        />
      </Grid>
      <Grid item xs={6}>
        <Select
          isRequired
          id="country-code"
          name="countryCode"
          label="Country"
          isDisabled={isPending}
          onChange={handleInputChange}
          options={COUNTRIES}
          value={address.countryCode}
          keyName="name"
          valueName="code"
          validation={validation}
        />
      </Grid>
    </Grid>
  )
}

export default AddressInput
