import HeaderTitle from 'components/common/HeaderTitle'
import {
  StyledDescriptionHeaderButton,
  StyledPageDescription,
  StyledPageDescriptionButtons,
} from './styles'
import PartnerServicesOverview from '../../assets/pdfs/PartnerServicesOverview.pdf'
import { SERVICES_CONTACT_EMAIL } from 'constants/partnerServices'
import { trackCustomEvent } from 'services/fireflyInsights'
import { FireflyEvent } from 'types/FireflyInsights'

export const Header = () => {
  const handleContactOnClick = () => {
    window.open(`mailto:${SERVICES_CONTACT_EMAIL}`, '_blank')
    trackCustomEvent(
      FireflyEvent.PARTNER_SERVICE_CONTACT,
      'service',
      'partnerServices',
    )
  }

  const handleOverviewOnClick = () => {
    window.open(PartnerServicesOverview, '_blank')
  }

  const contactButton = (
    <StyledDescriptionHeaderButton
      variant="contained"
      color="primary"
      onClick={() => {
        handleContactOnClick()
      }}
      data-testid="contact-partner-service-button"
    >
      CONTACT US TO GET STARTED
    </StyledDescriptionHeaderButton>
  )

  const downloadButton = (
    <StyledDescriptionHeaderButton
      variant="contained"
      color="secondary"
      data-testid="download-overview-pdf-button"
      onClick={() => {
        handleOverviewOnClick()
      }}
    >
      DOWNLOAD OVERVIEW PDF
    </StyledDescriptionHeaderButton>
  )

  return (
    <>
      <HeaderTitle title="Services" />
      <StyledPageDescription>
        <StyledPageDescriptionButtons>
          {downloadButton}
          {contactButton}
        </StyledPageDescriptionButtons>
      </StyledPageDescription>
    </>
  )
}

export default Header
