import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'

import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import Grid from '@mui/material/Grid'

import Input from 'components/common/Input'
import ContentSpacer from 'components/common/ContentSpacer'
import FullScreenDialogContainer from 'components/common/Dialog/FullScreenDialogContainer'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import Select from 'components/common/Select'
import TitleBar from 'components/common/TitleBar'
import { PERIOD, TIME } from 'components/common/TimePicker'

import {
  getTimePeriod,
  getDateAfter,
  getIsoString,
  getTimeIn12Hour,
  formatDate,
  DATE_PICKER_FORMAT,
} from 'services/dateService'
import {
  getNewFutureRatesForTable,
  prepareShippingRateDataForSave,
  updateShippingRates,
} from 'services/shippingRates'

import { closeDialog } from 'store/dialog/actionCreator'

import {
  NewShippingRates,
  NewShippingRatesTableRow,
  ShippingRatesTableRow,
} from 'types/ShippingRates'

import { getTableFields } from './addFutureRatesTableFieldConfigs'

const StyledDatePicker = styled(Grid)(({ theme }) => ({
  flexBasis: '10%',
  paddingRight: theme.spacing(2),
}))

export interface Props {
  isOpen: boolean
  currentRates: ShippingRatesTableRow[]
  futureRates?: ShippingRatesTableRow[]
  reloadTable: () => void
  futureRatesId?: string
}

export const AddFutureRatesDialog = ({
  isOpen,
  currentRates,
  futureRates,
  reloadTable,
  futureRatesId,
}: Props) => {
  const futureRatesStartDate = futureRates?.[0]?.start_date
  const initFutureRatesStartDate = formatDate(
    futureRatesStartDate,
    DATE_PICKER_FORMAT,
  )
  const initStartTime = futureRatesStartDate
    ? getTimeIn12Hour(futureRatesStartDate)
    : ''
  const initStartPeriod = futureRatesStartDate
    ? getTimePeriod(futureRatesStartDate)
    : ''

  const dispatch = useDispatch()
  const [startDate, setStartDate] = useState<string>(initFutureRatesStartDate)
  const [startTime, setStartTime] = useState<string>(initStartTime ?? '')
  const [startPeriod, setStartPeriod] = useState<string>(initStartPeriod ?? '')
  const [isPending, setIsPending] = useState<boolean>(false)
  const futureShippingRates = getNewFutureRatesForTable(
    0,
    150,
    startDate,
    currentRates,
    futureRates,
  )

  const [rates, setRates] =
    React.useState<NewShippingRatesTableRow[]>(futureShippingRates)

  const updateRates = (newRate: NewShippingRatesTableRow) => {
    setRates((prev) =>
      prev.map((rate) =>
        rate.min_pounds === newRate.min_pounds ? newRate : rate,
      ),
    )
  }

  const fieldList: EnhancedTableFieldType<NewShippingRatesTableRow>[] =
    getTableFields(updateRates)

  const confirmAction = (): boolean => {
    const isDirty = rates !== futureShippingRates

    return !isDirty || window.confirm('Are you sure?')
  }

  const handleRequestClose = () => {
    if (confirmAction()) {
      dispatch(closeDialog())
    }
  }

  const minPickerDate = formatDate(
    getDateAfter(new Date(), 1),
    DATE_PICKER_FORMAT,
  )

  const handleDateChange = (value: string) => {
    setStartDate(value)
  }

  const handleTimeChange = (value: any) => setStartTime(value)
  const handlePeriodChange = (value: any) => setStartPeriod(value)

  const checkAllInfoEntered = () => {
    let disableSave = false
    if (!startDate || !startTime || !startPeriod) {
      disableSave = true
    }
    if (rates) {
      for (let i = 0; i <= rates.length - 1; i++) {
        if (
          rates[i].futureRateText !== '' &&
          rates[i].futureRateInPennies !== undefined &&
          !isNaN(rates[i].futureRateInPennies!)
        ) {
          continue
        } else {
          disableSave = true
          break
        }
      }
    }

    return disableSave
  }

  const handleSaveChanges = () => {
    const futureShippingRates = prepareShippingRateDataForSave(rates)
    const isoStartDate = getIsoString(
      `${startDate} ${startTime} ${startPeriod}`,
    )
    if (!isoStartDate) {
      return
    }
    const newFutureRates: NewShippingRates = {
      rates: futureShippingRates,
      start_date: isoStartDate,
    }
    setIsPending(true)
    updateShippingRates(futureRatesId, newFutureRates)
      .then(() => {
        reloadTable()
        dispatch(closeDialog())
      })
      .finally(() => setIsPending(false))
  }

  const isSaveDisabled = checkAllInfoEntered()

  return (
    <FullScreenDialogContainer
      title={
        futureRatesId ? 'Edit Future Service Fees' : 'Add Future Service Fees'
      }
      onRequestClose={handleRequestClose}
      isOpen={isOpen}
    >
      <ContentSpacer>
        <TitleBar
          variant="h2"
          title="Future Return Service Fees"
          actionButtons={[
            <Button
              key="save-rates-btn"
              data-testid="save-rates-btn"
              color="primary"
              variant="contained"
              onClick={handleSaveChanges}
              disabled={isSaveDisabled}
            >
              Save Changes
            </Button>,
          ]}
        />
      </ContentSpacer>
      <ContentSpacer>
        <Grid container alignItems="flex-end">
          <StyledDatePicker item>
            <Input
              isRequired
              type="date"
              name="startDate"
              label="Start Date"
              onChange={handleDateChange}
              isDisabled={isPending}
              id="start-date"
              value={startDate}
              inputProps={{
                min: minPickerDate,
              }}
            />
          </StyledDatePicker>
          <StyledDatePicker item>
            <Select
              isRequired
              name="startTime"
              id="start-time"
              data-testid="start-time"
              label="Start Time"
              onChange={handleTimeChange}
              options={TIME}
              value={startTime}
              isDisabled={isPending}
            />
          </StyledDatePicker>
          <StyledDatePicker item>
            <Select
              isRequired
              name="startPeriod"
              id="start-period"
              data-testid="start-period"
              label="AM/PM"
              onChange={handlePeriodChange}
              options={PERIOD}
              value={startPeriod}
              isDisabled={isPending}
            />
          </StyledDatePicker>
        </Grid>
      </ContentSpacer>
      <ContentSpacer>
        <EnhancedTable
          data={rates}
          total={rates.length}
          fieldList={fieldList}
          isLoading={false}
        />
      </ContentSpacer>
      <DialogActions>
        <Button
          data-testid="save-rates-btn-bottom"
          color="primary"
          variant="contained"
          onClick={handleSaveChanges}
          disabled={isSaveDisabled}
        >
          Save Changes
        </Button>
      </DialogActions>
    </FullScreenDialogContainer>
  )
}

export default AddFutureRatesDialog
