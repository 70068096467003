import Attribute from 'types/Attribute'

export const ATTRIBUTE_AND_VALUE_PAGE_ATTRIBUTES =
  'ATTRIBUTE_AND_VALUE_PAGE_ATTRIBUTES'
export const ATTRIBUTES_AND_VALUES = 'ATTRIBUTES_AND_VALUES'
export const REQUESTED_VALUES = 'REQUESTED_VALUES'

export const getAttributesAndValuesPageAttributesQueryKeys = (
  itemType?: Nullable<Attribute>,
) => [ATTRIBUTE_AND_VALUE_PAGE_ATTRIBUTES, itemType]
