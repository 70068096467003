interface ItemField {
  displayName: string
  displayGroup: string
  fieldName: string
  canonicalName: string | null
  order: number
  isImage: boolean
  warning?: string
}

export const ParentImageField = 'digital_assets.image.parent_image_url'

const logisticsWarning =
  'Target cannot apply logistics values that are sent as part of item content data. Please review the Logistics Data tab & work with your integrator to manage this.'

export const ITEM_FIELDS: Dictionary<ItemField> = {
  external_id: {
    displayName: 'Partner SKU',
    displayGroup: 'Identifiers',
    fieldName: 'external_id',
    canonicalName: 'external_id',
    order: 1,
    isImage: false,
  },
  'barcode.value': {
    displayName: 'Barcode',
    displayGroup: 'Identifiers',
    fieldName: 'barcode.value',
    canonicalName: 'identifiers.barcode_number',
    order: 2,
    isImage: false,
  },
  'item_type.name': {
    displayName: 'Item Type',
    displayGroup: 'Classification',
    fieldName: 'item_type.name',
    canonicalName: null,
    order: 3,
    isImage: false,
  },
  list_price: {
    displayName: 'List Price',
    displayGroup: 'Pricing',
    fieldName: 'list_price',
    canonicalName: null,
    order: 4,
    isImage: false,
  },
  map_price: {
    displayName: 'MAP Price',
    displayGroup: 'Pricing',
    fieldName: 'map_price',
    canonicalName: null,
    order: 5,
    isImage: false,
  },
  offer_price: {
    displayName: 'Sale Price',
    displayGroup: 'Pricing',
    fieldName: 'offer_price',
    canonicalName: null,
    order: 6,
    isImage: false,
  },
  'compliance.tax_category.tax_code': {
    displayName: 'Tax Code',
    displayGroup: 'Pricing',
    fieldName: 'compliance.tax_category.tax_code',
    canonicalName: 'tax_category.tax_code_id',
    order: 7,
    isImage: false,
  },
  relationship_type: {
    displayName: 'Relationship Type',
    displayGroup: 'Variation',
    fieldName: 'relationship_type',
    canonicalName: null,
    order: 8,
    isImage: false,
  },
  'variation.theme': {
    displayName: 'Variation Theme',
    displayGroup: 'Variation',
    fieldName: 'variation.theme',
    canonicalName: 'variation.theme',
    order: 9,
    isImage: false,
  },
  'child_variations.color': {
    displayName: 'Variation Color',
    displayGroup: 'Variation',
    fieldName: 'child_variations.color',
    canonicalName: 'variation.color',
    order: 10,
    isImage: false,
  },
  'child_variations.size': {
    displayName: 'Variation Size',
    displayGroup: 'Variation',
    fieldName: 'child_variations.size',
    canonicalName: 'variation.size',
    order: 11,
    isImage: false,
  },
  'descriptions.parent_title': {
    displayName: 'Parent Title',
    displayGroup: 'Variation',
    fieldName: 'descriptions.parent_title',
    canonicalName: 'product_description.title',
    order: 12,
    isImage: false,
  },
  'digital_assets.image.parent_image_url': {
    displayName: 'Parent Image URL',
    displayGroup: 'Variation',
    fieldName: 'digital_assets.image.parent_image_url',
    canonicalName: null,
    order: 13,
    isImage: true,
  },
  'descriptions.title': {
    displayName: 'Product Title',
    displayGroup: 'Core Content',
    fieldName: 'descriptions.title',
    canonicalName: 'product_description.title',
    order: 14,
    isImage: false,
  },
  'brands.name': {
    displayName: 'Brand',
    displayGroup: 'Core Content',
    fieldName: 'brands.name',
    canonicalName: 'brands[0].name',
    order: 15,
    isImage: false,
  },
  'descriptions.long_description': {
    displayName: 'Product Description',
    displayGroup: 'Core Content',
    fieldName: 'descriptions.long_description',
    canonicalName: 'product_description.downstream_description',
    order: 16,
    isImage: false,
  },
  'descriptions.bullets[1]': {
    displayName: 'Bullet Features 1',
    displayGroup: 'Core Content',
    fieldName: 'descriptions.bullets[1]',
    canonicalName: 'product_description.soft_bullet_description',
    order: 17,
    isImage: false,
  },
  'descriptions.bullets[2]': {
    displayName: 'Bullet Features 2',
    displayGroup: 'Core Content',
    fieldName: 'descriptions.bullets[2]',
    canonicalName: 'product_description.soft_bullet_description',
    order: 18,
    isImage: false,
  },
  'descriptions.bullets[3]': {
    displayName: 'Bullet Features 3',
    displayGroup: 'Core Content',
    fieldName: 'descriptions.bullets[3]',
    canonicalName: 'product_description.soft_bullet_description',
    order: 19,
    isImage: false,
  },
  'descriptions.bullets[4]': {
    displayName: 'Bullet Features 4',
    displayGroup: 'Core Content',
    fieldName: 'descriptions.bullets[4]',
    canonicalName: 'product_description.soft_bullet_description',
    order: 20,
    isImage: false,
  },
  'descriptions.bullets[5]': {
    displayName: 'Bullet Features 5',
    displayGroup: 'Core Content',
    fieldName: 'descriptions.bullets[5]',
    canonicalName: 'product_description.soft_bullet_description',
    order: 21,
    isImage: false,
  },
  'descriptions.bullets[6]': {
    displayName: 'Bullet Features 6',
    displayGroup: 'Core Content',
    fieldName: 'descriptions.bullets[6]',
    canonicalName: 'product_description.soft_bullet_description',
    order: 22,
    isImage: false,
  },
  'descriptions.bullets[7]': {
    displayName: 'Bullet Features 7',
    displayGroup: 'Core Content',
    fieldName: 'descriptions.bullets[7]',
    canonicalName: 'product_description.soft_bullet_description',
    order: 23,
    isImage: false,
  },
  'descriptions.bullets[8]': {
    displayName: 'Bullet Features 8',
    displayGroup: 'Core Content',
    fieldName: 'descriptions.bullets[8]',
    canonicalName: 'product_description.soft_bullet_description',
    order: 24,
    isImage: false,
  },
  'descriptions.bullets[9]': {
    displayName: 'Bullet Features 9',
    displayGroup: 'Core Content',
    fieldName: 'descriptions.bullets[9]',
    canonicalName: 'product_description.soft_bullet_description',
    order: 25,
    isImage: false,
  },
  'descriptions.bullets[10]': {
    displayName: 'Bullet Features 10',
    displayGroup: 'Core Content',
    fieldName: 'descriptions.bullets[10]',
    canonicalName: 'product_description.soft_bullet_description',
    order: 26,
    isImage: false,
  },
  'descriptions.bullets[11]': {
    displayName: 'Bullet Features 11',
    displayGroup: 'Core Content',
    fieldName: 'descriptions.bullets[11]',
    canonicalName: 'product_description.soft_bullet_description',
    order: 27,
    isImage: false,
  },
  'descriptions.bullets[12]': {
    displayName: 'Bullet Features 12',
    displayGroup: 'Core Content',
    fieldName: 'descriptions.bullets[12]',
    canonicalName: 'product_description.soft_bullet_description',
    order: 28,
    isImage: false,
  },
  'descriptions.bullets[13]': {
    displayName: 'Bullet Features 13',
    displayGroup: 'Core Content',
    fieldName: 'descriptions.bullets[13]',
    canonicalName: 'product_description.soft_bullet_description',
    order: 29,
    isImage: false,
  },
  'descriptions.bullets[14]': {
    displayName: 'Bullet Features 14',
    displayGroup: 'Core Content',
    fieldName: 'descriptions.bullets[14]',
    canonicalName: 'product_description.soft_bullet_description',
    order: 30,
    isImage: false,
  },
  'descriptions.bullets[15]': {
    displayName: 'Bullet Features 15',
    displayGroup: 'Core Content',
    fieldName: 'descriptions.bullets[15]',
    canonicalName: 'product_description.soft_bullet_description',
    order: 31,
    isImage: false,
  },
  'digital_assets.images.primary_image_url': {
    displayName: 'main_image_url',
    displayGroup: 'Core Content',
    fieldName: 'digital_assets.images.primary_image_url',
    canonicalName: 'images.primary_image',
    order: 32,
    isImage: true,
  },
  'digital_assets.images.alternate_image_urls[1]': {
    displayName: 'alt_image_url_1',
    displayGroup: 'Core Content',
    fieldName: 'digital_assets.images.alternate_image_urls[1]',
    canonicalName: 'images.alternative_images',
    order: 33,
    isImage: true,
  },
  'digital_assets.images.alternate_image_urls[2]': {
    displayName: 'alt_image_url_2',
    displayGroup: 'Core Content',
    fieldName: 'digital_assets.images.alternate_image_urls[2]',
    canonicalName: 'images.alternative_images',
    order: 34,
    isImage: true,
  },
  'digital_assets.images.alternate_image_urls[3]': {
    displayName: 'alt_image_url_3',
    displayGroup: 'Core Content',
    fieldName: 'digital_assets.images.alternate_image_urls[3]',
    canonicalName: 'images.alternative_images',
    order: 35,
    isImage: true,
  },
  'digital_assets.images.alternate_image_urls[4]': {
    displayName: 'alt_image_url_4',
    displayGroup: 'Core Content',
    fieldName: 'digital_assets.images.alternate_image_urls[4]',
    canonicalName: 'images.alternative_images',
    order: 36,
    isImage: true,
  },
  'digital_assets.images.alternate_image_urls[5]': {
    displayName: 'alt_image_url_5',
    displayGroup: 'Core Content',
    fieldName: 'digital_assets.images.alternate_image_urls[5]',
    canonicalName: 'images.alternative_images',
    order: 37,
    isImage: true,
  },
  'digital_assets.images.alternate_image_urls[6]': {
    displayName: 'alt_image_url_6',
    displayGroup: 'Core Content',
    fieldName: 'digital_assets.images.alternate_image_urls[6]',
    canonicalName: 'images.alternative_images',
    order: 38,
    isImage: true,
  },
  'digital_assets.images.alternate_image_urls[7]': {
    displayName: 'alt_image_url_7',
    displayGroup: 'Core Content',
    fieldName: 'digital_assets.images.alternate_image_urls[7]',
    canonicalName: 'images.alternative_images',
    order: 39,
    isImage: true,
  },
  'digital_assets.images.alternate_image_urls[8]': {
    displayName: 'alt_image_url_8',
    displayGroup: 'Core Content',
    fieldName: 'digital_assets.images.alternate_image_urls[8]',
    canonicalName: 'images.alternative_images',
    order: 40,
    isImage: true,
  },
  'digital_assets.images.alternate_image_urls[9]': {
    displayName: 'alt_image_url_9',
    displayGroup: 'Core Content',
    fieldName: 'digital_assets.images.alternate_image_urls[9]',
    canonicalName: 'images.alternative_images',
    order: 41,
    isImage: true,
  },
  'digital_assets.images.alternate_image_urls[10]': {
    displayName: 'alt_image_url_10',
    displayGroup: 'Core Content',
    fieldName: 'digital_assets.images.alternate_image_urls[10]',
    canonicalName: 'images.alternative_images',
    order: 42,
    isImage: true,
  },
  'digital_assets.images.alternate_image_urls[11]': {
    displayName: 'alt_image_url_11',
    displayGroup: 'Core Content',
    fieldName: 'digital_assets.images.alternate_image_urls[11]',
    canonicalName: 'images.alternative_images',
    order: 43,
    isImage: true,
  },
  'digital_assets.images.alternate_image_urls[12]': {
    displayName: 'alt_image_url_12',
    displayGroup: 'Core Content',
    fieldName: 'digital_assets.images.alternate_image_urls[12]',
    canonicalName: 'images.alternative_images',
    order: 44,
    isImage: true,
  },
  'digital_assets.images.alternate_image_urls[13]': {
    displayName: 'alt_image_url_13',
    displayGroup: 'Core Content',
    fieldName: 'digital_assets.images.alternate_image_urls[13]',
    canonicalName: 'images.alternative_images',
    order: 45,
    isImage: true,
  },
  'digital_assets.images.alternate_image_urls[14]': {
    displayName: 'alt_image_url_14',
    displayGroup: 'Core Content',
    fieldName: 'digital_assets.images.alternate_image_urls[14]',
    canonicalName: 'images.alternative_images',
    order: 46,
    isImage: true,
  },
  'digital_assets.images.alternate_image_urls[15]': {
    displayName: 'alt_image_url_15',
    displayGroup: 'Core Content',
    fieldName: 'digital_assets.images.alternate_image_urls[15]',
    canonicalName: 'images.alternative_images',
    order: 47,
    isImage: true,
  },
  'digital_assets.images.alternate_image_urls[16]': {
    displayName: 'alt_image_url_16',
    displayGroup: 'Core Content',
    fieldName: 'digital_assets.images.alternate_image_urls[16]',
    canonicalName: 'images.alternative_images',
    order: 48,
    isImage: true,
  },
  'digital_assets.images.alternate_image_urls[17]': {
    displayName: 'alt_image_url_17',
    displayGroup: 'Core Content',
    fieldName: 'digital_assets.images.alternate_image_urls[17]',
    canonicalName: 'images.alternative_images',
    order: 49,
    isImage: true,
  },
  'digital_assets.images.alternate_image_urls[18]': {
    displayName: 'alt_image_url_18',
    displayGroup: 'Core Content',
    fieldName: 'digital_assets.images.alternate_image_urls[18]',
    canonicalName: 'images.alternative_images',
    order: 50,
    isImage: true,
  },
  'digital_assets.images.alternate_image_urls[19]': {
    displayName: 'alt_image_url_19',
    displayGroup: 'Core Content',
    fieldName: 'digital_assets.images.alternate_image_urls[19]',
    canonicalName: 'images.alternative_images',
    order: 51,
    isImage: true,
  },
  'digital_assets.images.alternate_image_urls[20]': {
    displayName: 'alt_image_url_20',
    displayGroup: 'Core Content',
    fieldName: 'digital_assets.images.alternate_image_urls[20]',
    canonicalName: 'images.alternative_images',
    order: 52,
    isImage: true,
  },
  'package_dimensions.measurements': {
    displayName: 'Package Dimensions',
    displayGroup: 'Shipping',
    fieldName: 'package_dimensions.measurements',
    canonicalName: 'package_dimensions',
    order: 53,
    isImage: false,
  },
  'compliance.import_designation': {
    displayName: 'Import Designation',
    displayGroup: 'Compliance',
    fieldName: 'compliance.import_designation',
    canonicalName: 'handling.import_designation_description',
    order: 54,
    isImage: false,
  },
  'compliance.is_environmentally_sensitive': {
    displayName: 'Hazardous Indicator',
    displayGroup: 'Compliance',
    fieldName: 'compliance.is_environmentally_sensitive',
    canonicalName: 'enviromentally_sensitive',
    order: 55,
    isImage: false,
  },
  'compliance.is_electronic': {
    displayName: 'Electronic Indicator',
    displayGroup: 'Compliance',
    fieldName: 'compliance.is_electronic',
    canonicalName: 'compliance.is_electronic',
    order: 56,
    isImage: false,
  },
  'compliance.has_battery': {
    displayName: 'Batteries Installed or Included',
    displayGroup: 'Compliance',
    fieldName: 'compliance.has_battery',
    canonicalName: 'compliance.is_battery_installed_or_included',
    order: 57,
    isImage: false,
  },
  'compliance.is_proposition_65': {
    displayName: 'Prop 65',
    displayGroup: 'Compliance',
    fieldName: 'compliance.is_proposition_65',
    canonicalName: 'compliance.is_proposition_65',
    order: 58,
    isImage: false,
  },
  'compliance.proposition_65_warning_text': {
    displayName: 'Prop 65 Warning',
    displayGroup: 'Compliance',
    fieldName: 'compliance.proposition_65_warning_text',
    canonicalName: 'compliance.proposition_65_warning',
    order: 59,
    isImage: false,
  },
  shipping_exclusion: {
    displayName: 'Shipping Exclusion',
    displayGroup: 'Shipping',
    fieldName: 'shipping_exclusion',
    canonicalName: 'shipping_exclusion',
    order: 60,
    isImage: false,
    warning: logisticsWarning,
  },
  seller_return_policy: {
    displayName: 'Seller Return Policy',
    displayGroup: 'Logistics',
    fieldName: 'seller_return_policy',
    canonicalName: 'seller_return_policy',
    order: 61,
    isImage: false,
    warning: logisticsWarning,
  },
  'fulfillment.two_day_shipping_eligible': {
    displayName: 'Fulfillment Two Day Shipping Eligible',
    displayGroup: 'Fulfillment',
    fieldName: 'fulfillment.two_day_shipping_eligible',
    canonicalName: 'fulfillment.two_day_shipping_eligible',
    order: 62,
    isImage: false,
    warning: logisticsWarning,
  },
  'fulfillment.is_add_on': {
    displayName: 'Fulfillment Add On',
    displayGroup: 'Fulfillment',
    fieldName: 'fulfillment.is_add_on',
    canonicalName: 'fulfillment.is_add_on',
    order: 63,
    isImage: false,
    warning: logisticsWarning,
  },
}
