import React from 'react'
import { useSelector } from 'react-redux'

import { grey } from 'config/themeConfig'

import styled from '@emotion/styled'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'

import {
  getSingleBiReportingValue,
  getTimePeriod,
  getVendorFilter,
} from 'services/biReporting'
import { decimalRound } from 'services/number'
import { fetchCardDataThruSecurityGateway } from 'services/securityGateway'

import { currentSellerId, currentSellerVmmId } from 'store/selectors'

import { BiReportingCards } from 'types/BiReporting'
import { OrderDefectCategoryData } from 'types/OrderDefects'
import { OrderDefectMetricType } from 'types/VendorStats'

import OrderDefectMetricTile from './OrderDefectMetricTile'

export const vendorMetricsBiReportingCards = [
  BiReportingCards.LATE_DELIVERY,
  BiReportingCards.RETURN_RATE,
  BiReportingCards.CANCEL_RATE,
  BiReportingCards.CONTACT_RATE,
]

const StyledList = styled(List)(({ theme }) => ({
  paddingTop: theme.spacing(8),
  width: '100%',
}))

const StyledListItem = styled(ListItem)(() => ({
  borderTop: `1px solid ${grey[300]}`,
  minHeight: `100px`,
}))

interface Props {
  greenfieldInterval: string
}

export const OrderDefectMetricsTiles = ({ greenfieldInterval }: Props) => {
  const sellerId = useSelector(currentSellerId)
  const sellerVmmId = useSelector(currentSellerVmmId)
  const [lateDefectRate, setLateDefectRate] =
    React.useState<OrderDefectCategoryData>({
      metric: 'Late Deliveries',
      rate: 0,
      mostDefective: false,
      timeseriesId: BiReportingCards.LATE_DELIVERY_LINE_CHART,
      greenfieldColumn: 'late_rate',
      odrMetricType: OrderDefectMetricType.LATE_DELIVERY,
    })
  const [returnsDefectRate, setReturnsDefectRate] =
    React.useState<OrderDefectCategoryData>({
      metric: 'Defective Returns',
      rate: 0,
      mostDefective: false,
      timeseriesId: BiReportingCards.RETURN_RATE_LINE_CHART,
      greenfieldColumn: 'return_rate',
      odrMetricType: OrderDefectMetricType.RETURN_RATE,
    })
  const [cancelDefectRate, setCancelDefectRate] =
    React.useState<OrderDefectCategoryData>({
      metric: 'Cancellations',
      rate: 0,
      mostDefective: false,
      timeseriesId: BiReportingCards.CANCEL_RATE_LINE_CHART,
      greenfieldColumn: 'cancel_rate',
      odrMetricType: OrderDefectMetricType.CANCELLATION_RATE,
    })
  const [contactsDefectRate, setContactsDefectRate] =
    React.useState<OrderDefectCategoryData>({
      metric: 'Defective Contacts',
      rate: 0,
      mostDefective: false,
      timeseriesId: BiReportingCards.CONTACT_RATE_LINE_CHART,
      greenfieldColumn: 'contact_rate',
      odrMetricType: OrderDefectMetricType.CONTACT_RATE,
    })

  const findMostDefective = (rates: (number | undefined)[]) => {
    const numbers = rates.filter((num) => num !== undefined)
    let largestNum: number | undefined

    if (numbers.some((num) => num !== 0)) {
      largestNum = numbers.reduce((acc, curr) =>
        acc !== undefined && curr !== undefined && acc > curr ? acc : curr,
      )
    }
    return largestNum
  }

  React.useEffect(() => {
    let mounted = true

    if (sellerId && sellerVmmId) {
      const vendorFilter = getVendorFilter(sellerVmmId)
      const timePeriod = getTimePeriod({ interval: greenfieldInterval })

      Promise.all(
        vendorMetricsBiReportingCards.map((odrMetric) =>
          fetchCardDataThruSecurityGateway(
            odrMetric,
            sellerId,
            vendorFilter,
            timePeriod,
          ),
        ),
      ).then((res) => {
        if (mounted) {
          const lateDeliveries = getSingleBiReportingValue<number>(
            'late_rate',
            res[0],
          )

          const defectiveReturns = getSingleBiReportingValue<number>(
            'return_rate',
            res[1],
          )

          const cancellations = getSingleBiReportingValue<number>(
            'cancel_rate',
            res[2],
          )

          const defectiveContacts = getSingleBiReportingValue<number>(
            'contact_rate',
            res[3],
          )

          const largestNum = findMostDefective([
            lateDeliveries,
            defectiveReturns,
            cancellations,
            defectiveContacts,
          ])

          if (lateDeliveries !== undefined) {
            setLateDefectRate((prev) => ({
              ...prev,
              rate: decimalRound(lateDeliveries, 2),
              mostDefective:
                largestNum !== undefined
                  ? largestNum === lateDeliveries
                  : prev.mostDefective,
            }))
          }

          if (defectiveReturns !== undefined) {
            setReturnsDefectRate((prev) => ({
              ...prev,
              rate: decimalRound(defectiveReturns, 2),
              mostDefective:
                largestNum !== undefined
                  ? largestNum === defectiveReturns
                  : prev.mostDefective,
            }))
          }

          if (cancellations !== undefined) {
            setCancelDefectRate((prev) => ({
              ...prev,
              rate: decimalRound(cancellations, 2),
              mostDefective:
                largestNum !== undefined
                  ? largestNum === cancellations
                  : prev.mostDefective,
            }))
          }

          if (defectiveContacts !== undefined) {
            setContactsDefectRate((prev) => ({
              ...prev,
              rate: decimalRound(defectiveContacts, 2),
              mostDefective:
                largestNum !== undefined
                  ? largestNum === defectiveContacts
                  : prev.mostDefective,
            }))
          }
        }
      })
    }
    return () => {
      mounted = false
    }
  }, [sellerId, sellerVmmId, greenfieldInterval])

  return (
    <StyledList>
      {[
        lateDefectRate,
        returnsDefectRate,
        cancelDefectRate,
        contactsDefectRate,
      ].map((odrMetric) => (
        <StyledListItem key={`${odrMetric.metric}-tile`}>
          <OrderDefectMetricTile
            sellerId={sellerId}
            vmmId={sellerVmmId}
            metric={odrMetric.metric}
            mostDefective={odrMetric.mostDefective}
            rate={odrMetric.rate}
            timeSeriesCardId={odrMetric.timeseriesId}
            greenfieldColumn={odrMetric.greenfieldColumn}
            odrMetricType={odrMetric.odrMetricType}
            greenfieldInterval={greenfieldInterval}
          />
        </StyledListItem>
      ))}
    </StyledList>
  )
}

export default OrderDefectMetricsTiles
