import { useState, useMemo } from 'react'

import { useDispatch } from 'react-redux'

import mapValues from 'lodash/fp/mapValues'
import castArray from 'lodash/fp/castArray'

import { Grid, Box, InputLabel } from '@mui/material'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import { useUserSettingsContext } from 'components/context/UserSettingsProvider'
import OutlinedInput from '../Input/OutlinedInput'

import { useUpdateSavedSearchMutation } from 'hooks/useUserSettingsQuery'

import { closeDialog } from 'store/dialog/actionCreator'
import { showNotification } from 'store/notification/reducer'
import { removeNonASCII } from 'services/sanatizeStringHelper'
import { isDuplicateSavedSearchName } from 'services/savedSearchHelper'

interface Props {
  isOpen: boolean
  handleClose: () => void
  selectedFilters?: Object
  page: string
}

export const SavedSearchDialog = ({
  isOpen,
  handleClose,
  selectedFilters,
  page,
}: Props) => {
  const dispatch = useDispatch()
  const [filterName, setFilterName] = useState('')
  const { updateSavedSearches } = useUpdateSavedSearchMutation()
  const { userSettings, updateSelectedSavedSearch } = useUserSettingsContext()

  const currentPageUserSettings = useMemo(
    () => userSettings.data?.settings?.[`${page}_saved_searches`] || {},
    [userSettings, page],
  )
  const existingSavedSearchNames = useMemo(
    () =>
      Object.keys(currentPageUserSettings).map(
        (key: string) => currentPageUserSettings[key].saved_search_name[0],
      ),
    [currentPageUserSettings],
  )

  const encodedFilterName = window.btoa(filterName.trim())
  const isNewNameDuplicate = isDuplicateSavedSearchName(
    existingSavedSearchNames,
    filterName.trim(),
  )

  const handleSubmit = () => {
    const savedSearchKey = crypto.randomUUID().replace(/-/gi, '')
    const updatedSettings = {
      ...userSettings.data,
      settings: {
        [`${page}_saved_searches`]: {
          ...(currentPageUserSettings || {}),
          [`${savedSearchKey}`]: {
            ...(currentPageUserSettings[`${savedSearchKey}`] || {}),
            ...(currentPageUserSettings.saved_searches || {}),
            ...mapValues(castArray, {
              ...selectedFilters,
              saved_search_name: encodedFilterName,
            }),
          },
        },
      },
    }

    updateSavedSearches({ userSettings: updatedSettings })
    updateSelectedSavedSearch(
      {
        ...selectedFilters,
        saved_search_name: window.btoa(filterName),
      },
      page,
    )
    dispatch(
      showNotification({
        isShown: true,
        message: `${filterName} has been successfully created.`,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        severity: 'success',
        autoClose: true,
      }),
    )

    dispatch(closeDialog())
  }

  return (
    <DialogContainer
      title="Save Filters"
      subtitle="Give the selected filter a name for easy access to your most frequently used filter states."
      isOpen={isOpen}
      onSubmit={handleSubmit}
      closeOverride={handleClose}
      submitButtonText="Save Filter"
      isSubmitDisabled={!filterName || isNewNameDuplicate}
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel htmlFor="my-input" sx={{ fontSize: 12 }} required>
              Save Filter Name
            </InputLabel>
            <OutlinedInput
              isRequired
              autoFocus
              id="save-filters"
              name="save-filters"
              value={filterName}
              size="small"
              maxCharacters={50}
              inputProps={{
                'data-testid': 'save-filters-input',
                maxLength: 50,
              }}
              onChange={(value: string) => setFilterName(removeNonASCII(value))}
              helperText={
                isNewNameDuplicate
                  ? `"${filterName.trim()}" already exists. Create a new name for the filters.`
                  : ''
              }
              isError={isNewNameDuplicate}
            />
          </Grid>
        </Grid>
      </Box>
    </DialogContainer>
  )
}

export default SavedSearchDialog
