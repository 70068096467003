import { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useParams } from 'react-router-dom'

import usePrevious from 'hooks/usePrevious'

import { RoutePath } from 'services/NavigationHelper'

import { isUserRoleExternal } from 'services/authorization'
import { hasItemUploadPage, hasPromotions } from 'services/pageAccess'

import {
  currentSellerStatus,
  currentSellerId,
  currentSellerSource,
  getMemberOf,
} from 'store/selectors'
import { setCurrentSeller } from 'store/seller/actionCreators'

import AttributesAndValuesPage from 'components/AttributesAndValuesPage'
import BasicInfoPage from 'components/BasicInfoPage'
import Orders from 'components/Orders'
import ReturnedOrdersPage from 'components/ReturnedOrdersPage'
import UserAccessPage from 'components/UserAccessPage'
import ShippingInfoPage from 'components/ShippingInfoPage'
import ReturnPoliciesPage from 'components/ReturnPoliciesPage'
import FormsAndAgreementsPage from 'components/FormsAndAgreementsPage'
import OrderDefectsPage from 'components/OrderDefectsPage'
import SalesMetricsPage from 'components/SalesMetricsPage'
import ItemInsightsPage from 'components/ItemInsightsPage'
import RatingsAndReviewPage from 'components/RatingsAndReviewsPage'
import ReferralFeePage from 'components/ReferralFeesPage'
import ReportsPage from 'components/ReportsPage'
import Dashboard from 'components/Dashboard'
import Items from 'components/Items'
import ItemListingSettingsPage from 'components/ItemListingSettingsPage'
import PayoutsAndAccountPage from 'components/PayoutsAndAccountPage'
import ApiTokensPage from 'components/ApiTokensPage'
import ItemUploadPage from 'components/ItemUploadPage'
import OpenCasesPage from 'components/OpenCases'
import ReturnDisputeCasesPage from 'components/ReturnDisputeCasesPage'
import ErrorConfigurationPage from 'components/ErrorConfigurationPage'
import SeasonalEventsPage from 'components/SeasonalEventsPage'
import PromotionsPage from 'components/PromotionsPage'
import PartnerServicesPage from 'components/PartnerServicesPage'

const Sellers = () => {
  const dispatch = useDispatch()

  const sellerId = useSelector(currentSellerId)
  const source = useSelector(currentSellerSource)
  const sellerStatus = useSelector(currentSellerStatus)
  const memberOf = useSelector(getMemberOf)

  const [isInternal, setIsInternal] = useState(false)
  const [hasItemUpload, setHasItemUpload] = useState(false)

  const prevSource = usePrevious(source) as string | undefined
  const { sellerId: matchSellerId } = useParams()

  useEffect(() => {
    if (matchSellerId && (!sellerId || sellerId !== matchSellerId)) {
      dispatch(setCurrentSeller(matchSellerId))
    }

    if (!prevSource || prevSource !== source) {
      setIsInternal(!isUserRoleExternal(memberOf))
      setHasItemUpload(!!hasItemUploadPage(memberOf, source, sellerStatus))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberOf, sellerId, source, sellerStatus, matchSellerId])

  if (!sellerId) {
    return null
  }

  return (
    <Suspense fallback={<div></div>}>
      <Routes>
        <Route path={`${RoutePath.DASHBOARD}`} element={<Dashboard />} />
        <Route path={`${RoutePath.BASIC_INFO}`} element={<BasicInfoPage />} />
        <Route
          path={`${RoutePath.SHIPPING_INFO}`}
          element={<ShippingInfoPage />}
        />
        <Route
          path={`${RoutePath.RETURN_POLICIES}`}
          element={<ReturnPoliciesPage />}
        />
        <Route
          path={`${RoutePath.FORMS_AND_AGREEMENTS}`}
          element={<FormsAndAgreementsPage />}
        />
        <Route
          path={`${RoutePath.PERFORMANCE}`}
          element={<OrderDefectsPage />}
        />
        <Route
          path={`${RoutePath.SALES_METRICS}`}
          element={<SalesMetricsPage />}
        />
        <Route
          path={`${RoutePath.RATINGS_AND_REVIEWS}`}
          element={<RatingsAndReviewPage />}
        />
        <Route
          path={`${RoutePath.ITEM_INSIGHTS}`}
          element={<ItemInsightsPage />}
        />
        <Route path="orders/*" element={<Orders />} />
        <Route
          path={`${RoutePath.ATTRIBUTES_AND_VALUES}`}
          element={<AttributesAndValuesPage />}
        />
        <Route
          path={`${RoutePath.ITEM_LISTING_SETTINGS}`}
          element={<ItemListingSettingsPage />}
        />
        <Route path={`${RoutePath.ALL_ITEMS}/*`} element={<Items />} />
        <Route
          path={`${RoutePath.RETURNED_ORDERS}`}
          element={<ReturnedOrdersPage />}
        />
        <Route path={`${RoutePath.USER_ACCESS}`} element={<UserAccessPage />} />
        <Route path={`${RoutePath.API_TOKENS}`} element={<ApiTokensPage />} />
        <Route path={`${RoutePath.REPORTS}`} element={<ReportsPage />} />
        <Route
          path={`${RoutePath.PARTNER_SERVICES}`}
          element={<PartnerServicesPage />}
        />
        <Route
          path={`${RoutePath.PAYOUTS_AND_ACCOUNT}`}
          element={<PayoutsAndAccountPage />}
        />
        <Route
          path={`${RoutePath.REFERRAL_FEES}`}
          element={<ReferralFeePage />}
        />
        {hasItemUpload && (
          <Route
            path={`${RoutePath.ITEM_UPLOAD}`}
            element={<ItemUploadPage />}
          />
        )}
        <Route path={`${RoutePath.OPEN_CASES}/*`} element={<OpenCasesPage />} />
        <Route
          path={`${RoutePath.RETURN_DISPUTE_CASES}`}
          element={<ReturnDisputeCasesPage />}
        />
        {isInternal && (
          <Route
            path={`${RoutePath.ERROR_CONFIGURATION}`}
            element={<ErrorConfigurationPage />}
          />
        )}
        <Route
          path={`${RoutePath.EXTERNAL_CALENDAR}`}
          element={<SeasonalEventsPage />}
        />
        {hasPromotions(memberOf) && (
          <Route
            path={`${RoutePath.EXTERNAL_PROMOTIONS}`}
            element={<PromotionsPage />}
          />
        )}
      </Routes>
    </Suspense>
  )
}

export default Sellers
