import { DistributionCenterFieldValues } from '.'
import { DayOption } from '../EditHoursOfOperation'

import styled from '@emotion/styled'

import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'

import TimePicker from 'components/common/TimePicker'

import { convert12To24Hour, convert24To12Hour } from 'services/time'

const StyledShippingHoursBackDrop = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
}))

const StyledBackDropTitle = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

export interface Props {
  isPending: boolean
  fieldValues: DistributionCenterFieldValues
  setFieldValue: (name: string, value: any) => void
}

export const ShippingHoursTable = ({
  isPending,
  fieldValues,
  setFieldValue,
}: Props) => {
  const handleShippingDayCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const checked = event.currentTarget.checked
    const checkedDay = event.currentTarget.value

    const option = fieldValues.dayOptions.find(
      (opt: DayOption) => opt.day === checkedDay,
    )

    if (option) {
      const newOption = { ...option }
      newOption.checked = checked
      if (!checked) {
        newOption.openTime = ''
        newOption.closeTime = ''
        newOption.validation = {}
      }

      setFieldValue('dayOptions', newOption)
    }
  }

  const handleTimeChange = ({
    value,
    name,
    property,
  }: {
    value: string
    name: string
    property?: string
  }) => {
    const valueIn24Hour = convert12To24Hour(value)
    const option = fieldValues.dayOptions.find(
      (opt: DayOption) => opt.day === property,
    )
    if (option) {
      const newOption = { ...option }
      newOption[name] = valueIn24Hour

      setFieldValue('dayOptions', newOption)
    }
  }

  return (
    <StyledShippingHoursBackDrop elevation={0}>
      <StyledBackDropTitle>Shipping Hours*</StyledBackDropTitle>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Open</TableCell>
            <TableCell>Day of Week</TableCell>
            <TableCell>Opening Time</TableCell>
            <TableCell>Closing Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fieldValues.dayOptions.map((option: DayOption, index: any) => {
            const {
              checked,
              day,
              openTime,
              closeTime,
              validation: dayValidation,
            } = option

            const openTimeIn12Hour = openTime && convert24To12Hour(openTime)
            const closeTimeIn12Hour = closeTime && convert24To12Hour(closeTime)
            return (
              <TableRow key={index}>
                <TableCell padding="none">
                  <Checkbox
                    data-testid={`${day}-checkbox`}
                    checked={checked}
                    tabIndex={-1}
                    disableRipple
                    onChange={handleShippingDayCheckboxChange}
                    value={day}
                  />
                </TableCell>
                <TableCell>{day}</TableCell>
                <TableCell>
                  <TimePicker
                    isRequired
                    id={`${day}-Open`}
                    property={day}
                    name="openTime"
                    disabled={!checked || isPending}
                    value={openTimeIn12Hour ? openTimeIn12Hour.time : undefined}
                    onChange={handleTimeChange}
                    period={openTimeIn12Hour ? openTimeIn12Hour.period : 'AM'}
                    validation={dayValidation}
                  />
                </TableCell>
                <TableCell>
                  <TimePicker
                    isRequired
                    id={`${day}-Close`}
                    property={day}
                    name="closeTime"
                    disabled={!checked || isPending}
                    value={
                      closeTimeIn12Hour ? closeTimeIn12Hour.time : undefined
                    }
                    onChange={handleTimeChange}
                    period={closeTimeIn12Hour ? closeTimeIn12Hour.period : 'PM'}
                    validation={dayValidation}
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </StyledShippingHoursBackDrop>
  )
}

export default ShippingHoursTable
