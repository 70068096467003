export const businessUnits = [
  {
    name: 'Apparel and Accessories',
    value: 'Apparel and Accessories',
  },
  {
    name: 'Essentials, Beauty, Baby',
    value: 'Essentials, Beauty, Baby',
  },
  {
    name: 'Food & Beverage',
    value: 'Food & Beverage',
  },
  {
    name: 'Hardlines',
    value: 'Hardlines',
  },
  {
    name: 'Home',
    value: 'Home',
  },
  {
    name: 'Not Applicable',
    value: 'Not Applicable',
  },
]
