import React from 'react'
import { useDispatch } from 'react-redux'

import isEmpty from 'lodash/fp/isEmpty'

import styled from '@emotion/styled'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import DateRangePicker from 'components/common/DateRangePicker'
import { SellerTypeahead } from 'components/common/Typeahead'

import { getDateBefore } from 'services/dateService'
import { GenerateReportRequest, submitReport } from 'services/reports'

import { closeDialog } from 'store/dialog/actionCreator'

import { Report, ReportType } from 'types/Report'
import { SmsSeller } from 'types/Seller'

const StyledRoot = styled('div')({
  minHeight: 150,
})
const StyledChip = styled(Chip)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginRight: theme.spacing(1),
}))

const setDateRange = (fromDate: Date, toDate: Date, isValidDate: boolean) =>
  ({
    type: 'SET_DATE_RANGE',
    payload: {
      fromDate,
      toDate,
      isValidDate,
    },
  }) as const

const addSeller = (seller: SmsSeller) =>
  ({
    type: 'ADD_SELLER',
    payload: {
      seller,
    },
  }) as const

const removeSeller = (seller: SmsSeller) =>
  ({
    type: 'REMOVE_SELLER',
    payload: {
      seller,
    },
  }) as const

type Actions = ReturnType<
  typeof setDateRange | typeof addSeller | typeof removeSeller
>

interface State {
  isValidDate: boolean
  sellers: SmsSeller[]
  fromDate: Date
  toDate: Date
}

const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case 'SET_DATE_RANGE': {
      const { payload } = action
      const { toDate, fromDate, isValidDate } = payload

      const newState = {
        ...state,
        toDate,
        fromDate,
        isValidDate,
      }

      return newState
    }
    case 'ADD_SELLER': {
      const { payload } = action
      const { seller } = payload

      const newState = {
        ...state,
        sellers: state.sellers.map((seller) => seller.id).includes(seller.id)
          ? state.sellers
          : [...state.sellers, seller],
      }

      return newState
    }

    case 'REMOVE_SELLER': {
      const { payload } = action
      const { seller } = payload

      const newState = {
        ...state,
        sellers: state.sellers.filter(
          (stateSeller) => stateSeller.id !== seller.id,
        ),
      }

      return newState
    }

    default:
      return state
  }
}

export type Props = {
  isOpen: boolean
  onRequestSubmit: (report: Report) => void
}

export const PriceInventoryDialog: React.FC<Props> = ({
  isOpen,
  onRequestSubmit,
}) => {
  const initialState: State = {
    isValidDate: true,
    sellers: [],
    fromDate: getDateBefore(new Date(), 7, 'days'),
    toDate: new Date(),
  }

  const reduxDispatch = useDispatch()

  const [state, dispatch] = React.useReducer(reducer, initialState)

  const handleDateChange = (from: Date, to: Date, isValidDate: boolean) => {
    dispatch(setDateRange(from, to, isValidDate))
  }

  const handleSellerSelect = (value: SmsSeller | undefined | null) => {
    if (value) {
      dispatch(addSeller(value))
    }
  }

  const handleDelete = (seller: SmsSeller) => () => {
    dispatch(removeSeller(seller))
  }

  const handleSubmit = async () => {
    const { sellers, fromDate, toDate } = state

    const reportConfig: GenerateReportRequest = {
      type: ReportType.PARTNER_PRICE_DETAILS,
      format: 'EXCEL',
      parameters: {
        sellers: sellers.map((seller) => seller.id),
      },
      startDate: fromDate,
      endDate: toDate,
    }

    const report = await submitReport(reportConfig)

    onRequestSubmit(report)

    reduxDispatch(closeDialog())
  }

  return (
    <DialogContainer
      title="generate Price Change Details report"
      isOpen={isOpen}
      isSubmitDisabled={!state.isValidDate || isEmpty(state.sellers)}
      onSubmit={handleSubmit}
      submitButtonText="Generate"
      disableScroll
    >
      <StyledRoot data-testid="report-date-range">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DateRangePicker
              isPending={false}
              from={state.fromDate}
              to={state.toDate}
              actionHandler={handleDateChange}
            />
          </Grid>
          <Grid item xs={12}>
            <SellerTypeahead clearOnSelect onChange={handleSellerSelect} />
          </Grid>
          <Grid item xs={12}>
            {state.sellers.map((seller) => {
              return (
                <StyledChip
                  id={seller.id}
                  key={seller.id}
                  data-testid={seller.id}
                  label={seller.display_name ?? seller.legal_business_name}
                  onDelete={handleDelete(seller)}
                />
              )
            })}
          </Grid>
        </Grid>
      </StyledRoot>
    </DialogContainer>
  )
}

export default PriceInventoryDialog
