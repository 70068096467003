import './styles.scss'

export interface SkeletonLoaderProps {
  width?: string
  height: string
}

export const SkeletonLoader = ({ width, height }: SkeletonLoaderProps) => {
  return (
    <section
      className="skeleton"
      style={{
        width,
        height,
      }}
    />
  )
}
