import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'

import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import HeaderTitle from 'components/common/HeaderTitle'

import { CONTENT_PADDING } from 'constants/layout'

import { Direction } from 'services/pageableHelper'
import { getReturnWithDetail } from 'services/productReturns'
import { getParam } from 'services/urlHelper'
import { RoutePath } from 'services/NavigationHelper'

import { Order, ReturnWithDetail, Shipment } from 'types/Orders'

import OrderContent from './OrderContent'
import OrderHistories from './OrderHistories'
import OrderLines from './OrderLines'
import ShipmentContent from './ShipmentContent'
import Returns from './Returns'
import {
  getVendorFilter,
  getGreenfieldFilter,
  getTimePeriod,
} from 'services/biReporting'
import { currentSellerId, currentSellerVmmId } from 'store/selectors'
import { fetchCardDataWithFormattedKeys } from 'services/securityGateway'
import { BiReportingCards } from 'types/BiReporting'
import { getOrderWithItemDetails, getShipmentsByOrderId } from 'services/orders'
import { OrderShipmentsContext } from './Context'

const StyledTabs = styled(Tabs)({
  margin: `0 -${CONTENT_PADDING}px`,
  padding: `0 ${CONTENT_PADDING}px`,
})

const StyledTabContent = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(3),
}))

export enum OrderTab {
  ORDER_LINES = 'ORDER_LINES',
  SHIPMENTS = 'SHIPMENTS',
  HISTORY = 'HISTORY',
  RETURNS = 'RETURNS',
}

export const OrderDetails = () => {
  const params = useParams()
  const location = useLocation()

  const sellerId = useSelector(currentSellerId)
  const vmmId = useSelector(currentSellerVmmId)

  const [isPending, setIsPending] = useState<boolean>(false)
  const [order, setOrder] = useState<Order>()
  const [shipments, setShipments] = useState<Shipment[]>([])
  const [ODRImpactReasons, setODRImpactReasons] = useState<string[]>([])
  const [returnOrders, setReturnOrders] = useState<ReturnWithDetail[]>([])
  const [returnsPending, setReturnsPending] = useState<boolean>(false)

  const direction = Direction.ASC
  const orderBy = 'created'

  useEffect(() => {
    setIsPending(true)
    if (params.orderId && params.sellerId) {
      getOrderWithItemDetails(params.sellerId, params.orderId)
        .then((res) => {
          setOrder(res)
        })
        .finally(() => setIsPending(false))
    }
  }, [params.orderId, params.sellerId])

  const getShipments = useCallback(() => {
    if (order && sellerId) {
      try {
        getShipmentsByOrderId(sellerId, order.id, order.order_lines).then(
          (res) => {
            setShipments(res)
          },
        )
      } catch (e) {
        console.error(e)
      }
    }
  }, [order, sellerId])

  useEffect(() => {
    getShipments()
  }, [getShipments])

  useEffect(() => {
    let mounted = true

    if (order?.id && params.orderId === order.id && sellerId && vmmId) {
      const timePeriod = getTimePeriod({
        type: 'relative',
        interval: 'Last 180 Days',
      })
      const vendorFilter = getVendorFilter(vmmId)
      vendorFilter.push(
        getGreenfieldFilter({
          dimension: 'order_id',
          pattern: order.id,
        }),
      )

      fetchCardDataWithFormattedKeys(
        BiReportingCards.DEFECT_CATEGORY,
        sellerId,
        vendorFilter,
        timePeriod,
      ).then((resp) => {
        if (mounted) {
          const uniqueReasons = [
            ...new Set(resp.map((item) => item.defect_category)),
          ]
          setODRImpactReasons(uniqueReasons)
        }
      })
    }

    return () => {
      mounted = false
    }
  }, [params.orderId, order?.id, sellerId, vmmId])

  useEffect(() => {
    let mounted = true

    if (sellerId) {
      setReturnsPending(true)

      getReturnWithDetail(
        { direction, orderBy, page: 0, perPage: 100 },
        { seller_id: sellerId, order_id: params.orderId },
      )
        .then((resp) => {
          if (mounted) {
            setReturnOrders(resp)
          }
        })
        .finally(() => {
          if (mounted) {
            setReturnsPending(false)
          }
        })
    }

    return () => {
      mounted = false
    }
  }, [params.orderId, sellerId, direction])

  const tabParam = getParam(location, 'tab')
  const selectedTab = Array.isArray(tabParam) ? tabParam[0] : tabParam

  const tab = selectedTab ?? OrderTab.ORDER_LINES

  const [tabValue, setTabValue] = useState<string>(tab)

  const handleChange = (_event: any, newValue: OrderTab) =>
    setTabValue(newValue)

  const renderTab = () => {
    if (!order) return null

    let component = order && <OrderLines orderLines={order.order_lines} />
    switch (tabValue) {
      case OrderTab.SHIPMENTS:
        component = (
          <div>
            <OrderShipmentsContext.Provider
              value={{
                orderShipments: shipments,
                reloadShipments: getShipments,
              }}
            >
              <ShipmentContent />
            </OrderShipmentsContext.Provider>
          </div>
        )
        break
      case OrderTab.HISTORY:
        component = order && (
          <OrderHistories sellerId={order.seller_id} orderId={order.id} />
        )
        break
      case OrderTab.RETURNS:
        if (returnOrders.length > 0) {
          component = <Returns returnOrders={returnOrders} />
        }
        break
    }
    return <StyledTabContent>{component}</StyledTabContent>
  }

  const previousPage = location.state?.previousPage ?? ''

  return (
    <div data-testid="order-details-page">
      <HeaderTitle
        title={[
          {
            text: previousPage.endsWith(RoutePath.RETURNED_ORDERS)
              ? 'Returns'
              : 'Orders',
            route: previousPage,
          },
          { text: 'Order Detail' },
        ]}
      />
      <Grid container>
        <Grid item xs={12}>
          <OrderContent order={order} ODRImpactReasons={ODRImpactReasons} />
        </Grid>
        <Grid item xs={12}>
          <StyledTabs
            value={tabValue}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab value={OrderTab.ORDER_LINES} label="Order Lines" />
            <Tab value={OrderTab.SHIPMENTS} label="Shipments" />
            <Tab value={OrderTab.HISTORY} label="History" />
            {tab === OrderTab.RETURNS || returnOrders.length > 0 ? (
              <Tab value={OrderTab.RETURNS} label="Returns" />
            ) : null}
          </StyledTabs>
        </Grid>
        <Grid item xs={12}>
          {isPending || returnsPending ? (
            <LinearProgress data-testid="latest-item-progress" />
          ) : (
            renderTab()
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default OrderDetails
