import { FC } from 'react'
import styled from '@emotion/styled'
import { ZoomedImageProps } from 'types/PictureZoomable'
import { white, grey } from 'config/themeConfig'

const StyledZoomedImageBase = styled('div')({
  backgroundRepeat: 'no-repeat',
  backgroundColor: white.main,
  border: `1px solid ${grey[900]}`,

  position: 'absolute',
  top: 0,
  left: 0,

  width: '100%',
  height: '100%',

  transition: 'opacity 0.25s ease-in-out',
  opacity: 0,
  cursor: 'pointer',

  '&:hover': {
    opacity: 1,
    cursor: 'crosshair',
  },
})

export const ZoomedImage: FC<ZoomedImageProps> = ({
  xPos,
  yPos,
  zoomURL,
  zoomedWidth,
}) => {
  return (
    <StyledZoomedImageBase
      style={{
        // Setting the background-position (based on mouse position) in ZoomedImageBase would generate a bazillion classes in styled-components.
        // background-image faces a similar problem. So here they are in style instead.
        backgroundPosition: `${xPos}px ${yPos}px`,
        backgroundImage: `url(${zoomURL})`,
        backgroundSize: zoomedWidth ? `${zoomedWidth}px` : 'auto',
      }}
    />
  )
}

ZoomedImage.displayName = 'ZoomedImage'
