import {
  SET_HEADER_TITLE,
  OPEN_FULL_PAGE_LOADER,
  CLOSE_FULL_PAGE_LOADER,
  SET_SMALL_SCREEN_SIZE,
  SetSmallScreenSizeAction,
  LayoutActions,
  SetHeaderTitle,
} from './actionCreators'
import { Breadcrumb } from 'types/Layout'

export interface LayoutState {
  readonly isFullPageLoaderOpen: boolean
  readonly headerTitle: string | Breadcrumb[]
  readonly isSmallScreenSize: boolean
}

export const initialState = {
  isFullPageLoaderOpen: false,
  headerTitle: '',
  isSmallScreenSize: false,
}

export default function layoutReducer(
  state: LayoutState = initialState,
  action: LayoutActions,
): LayoutState {
  switch (action.type) {
    case SET_HEADER_TITLE: {
      const { payload } = action as SetHeaderTitle
      return {
        ...state,
        headerTitle: payload,
      }
    }

    case OPEN_FULL_PAGE_LOADER: {
      return {
        ...state,
        isFullPageLoaderOpen: true,
      }
    }

    case CLOSE_FULL_PAGE_LOADER: {
      return {
        ...state,
        isFullPageLoaderOpen: false,
      }
    }

    case SET_SMALL_SCREEN_SIZE: {
      const { payload } = action as SetSmallScreenSizeAction
      return {
        ...state,
        isSmallScreenSize: payload,
      }
    }
    default:
      return state
  }
}
