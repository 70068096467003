import { useEffect, useReducer } from 'react'

import WidgetsIcon from '@mui/icons-material/Widgets'

import DisplayCard from 'components/common/DisplayCard'
import Text from 'components/common/Text'
import TabularData from 'components/common/TabularData'
import Link from 'components/common/Link'

import { GENERIC_API_ERROR } from 'constants/errors'

import { formatLocaleNumber } from 'services/formatNumber'
import { getProductCount } from 'services/items'
import { RoutePath } from 'services/NavigationHelper'
import { createQueryString } from 'services/urlHelper'

import { ListingStatus, ValidationStatus } from 'types/Item'
import { Direction, PagingParams } from 'services/pageableHelper'

const fetchItemData = () =>
  ({
    type: 'FETCH',
  }) as const

const rejectItemData = () =>
  ({
    type: 'REJECT',
  }) as const

const resolveItemData = (
  review: number,
  blocked: number,
  validated: number,
  publishedInStock: number,
  publishedOutOfStock: number,
  unlisted: number,
) =>
  ({
    type: 'RESOLVE',
    payload: {
      review,
      blocked,
      validated,
      publishedInStock,
      publishedOutOfStock,
      unlisted,
    },
  }) as const

type Actions = ReturnType<
  typeof fetchItemData | typeof rejectItemData | typeof resolveItemData
>

type State = {
  loading: boolean
  error: boolean
  review: number
  blocked: number
  validated: number
  publishedInStock: number
  publishedOutOfStock: number
  unlisted: number
}

const initialState: State = {
  loading: false,
  error: false,
  review: 0,
  blocked: 0,
  validated: 0,
  publishedInStock: 0,
  publishedOutOfStock: 0,
  unlisted: 0,
}

const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case 'FETCH': {
      return {
        ...state,
        loading: true,
      }
    }

    case 'REJECT': {
      return {
        ...state,
        loading: false,
        error: true,
      }
    }

    case 'RESOLVE': {
      const { payload } = action
      const {
        review,
        blocked,
        validated,
        publishedInStock,
        publishedOutOfStock,
        unlisted,
      } = payload

      return {
        ...state,
        loading: false,
        review,
        blocked,
        validated,
        publishedInStock,
        publishedOutOfStock,
        unlisted,
      }
    }
    default:
      return state
  }
}

export const ItemsCard = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    let canceled = false

    dispatch(fetchItemData())

    Promise.all([
      getProductCount({
        listing_status: ListingStatus.PENDING,
        validation_status: ValidationStatus.REVIEW,
      }),
      getProductCount({
        listing_status: ListingStatus.PENDING,
        validation_status: ValidationStatus.BLOCKED,
      }),
      getProductCount({
        listing_status: ListingStatus.PENDING,
        validation_status: ValidationStatus.VALIDATED,
      }),
      getProductCount({ published: true, has_inventory: true }),
      getProductCount({ published: true, has_inventory: false }),
      getProductCount({ listing_status: ListingStatus.UNLISTED }),
    ])
      .then(
        ([
          review,
          blocked,
          validated,
          publishedInStock,
          publishedOutOfStock,
          unlisted,
        ]) => {
          if (canceled) return

          dispatch(
            resolveItemData(
              review,
              blocked,
              validated,
              publishedInStock,
              publishedOutOfStock,
              unlisted,
            ),
          )
        },
      )
      .catch(() => {
        if (canceled) return

        dispatch(rejectItemData())
      })

    return () => {
      canceled = true
    }
  }, [])

  const pagingParams: PagingParams = {
    page: 0,
    perPage: 100,
    direction: Direction.ASC,
    orderBy: 'last_modified',
  }

  const data = [
    {
      title: 'Review',
      element: (
        <Link
          to={{
            pathname: RoutePath.REVIEW_QUEUE,
            search: createQueryString({
              validation_status: ValidationStatus.REVIEW,
              ...pagingParams,
            }),
          }}
        >
          {formatLocaleNumber(state.review)}
        </Link>
      ),
    },
    {
      title: 'Blocked',
      element: (
        <Link
          to={{
            pathname: RoutePath.REVIEW_QUEUE,
            search: createQueryString({
              validation_status: ValidationStatus.BLOCKED,
              ...pagingParams,
            }),
          }}
        >
          {formatLocaleNumber(state.blocked)}
        </Link>
      ),
    },
    {
      title: 'Validated',
      element: (
        <Link
          to={{
            pathname: RoutePath.REVIEW_QUEUE,
            search: createQueryString({
              validation_status: ValidationStatus.VALIDATED,
              ...pagingParams,
            }),
          }}
        >
          {formatLocaleNumber(state.validated)}
        </Link>
      ),
    },
    {
      title: 'Published, In Stock',
      element: formatLocaleNumber(state.publishedInStock),
    },
    {
      title: 'Published, Out of Stock',
      element: formatLocaleNumber(state.publishedOutOfStock),
    },
    {
      title: 'Unlisted',
      element: formatLocaleNumber(state.unlisted),
    },
  ]

  return (
    <DisplayCard
      title="Items"
      isLoading={state.loading}
      iconColor="target"
      icon={WidgetsIcon}
      hasWarning={state.error}
      warningTooltip="An error occurred while getting the information."
    >
      {state.error ? (
        <Text>{GENERIC_API_ERROR}</Text>
      ) : (
        <TabularData
          borderTop
          extraPadding={true}
          fieldList={[
            {
              key: 'title',
              displayName: 'Status',
              width: 100,
            },
            {
              key: 'element',
              displayName: 'Total',
              width: 50,
            },
          ]}
          data={data}
        />
      )}
    </DisplayCard>
  )
}

export default ItemsCard
