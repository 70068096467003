import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Grid from '@mui/material/Grid'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import Text from 'components/common/Text'
import DateRangeSelector from 'components/common/DateRangePicker/DateRangeSelector'
import {
  DateRange,
  generateOrderDefectMetricRange,
  getRangeConfig,
} from 'components/common/DateRangePicker/rangeConfig'

import { getPrettyName } from 'services/enumerations'
import {
  DATE_FORMAT_MONTH_DAY,
  endOfYesterday,
  formatDate,
  startOfDay,
} from 'services/dateService'
import { GenerateReportRequest } from 'services/reports'

import { closeDialog } from 'store/dialog/actionCreator'
import { currentSellerId, getEnumerationValues } from 'store/selectors'

import StoreState from 'types/state'
import { EnumerationName } from 'types/Enumeration'
import { ReportType } from 'types/Report'

export type Props = {
  isOpen: boolean
  reportType: ReportType
  onRequestSubmit: (report: Partial<GenerateReportRequest>) => void
}

export const OrderDefectRateReportDialog: React.FC<Props> = ({
  isOpen,
  reportType,
  onRequestSubmit,
}) => {
  const sellerId = useSelector(currentSellerId)
  const orderDefectCategories = useSelector((state: StoreState) =>
    getEnumerationValues(state, EnumerationName.ORDER_DEFECT_CATEGORY),
  )
  const reduxDispatch = useDispatch()

  const dateRangeOptions = generateOrderDefectMetricRange()
  const last90Days = getRangeConfig(
    DateRange.LAST_90_DAYS,
    undefined,
    'odrMetric',
  )
  const defaultRange = {
    from: startOfDay(last90Days.from),
    to: startOfDay(last90Days.to),
  }

  const [pending, setPending] = React.useState(false)
  const [dates, setDates] = React.useState(defaultRange)
  const [checkboxes, setCheckboxes] = React.useState<
    Array<{ title: string; value: string; isChecked: boolean }>
  >(
    orderDefectCategories.map((enumeration) => ({
      title: getPrettyName({
        enumeration: EnumerationName.ORDER_DEFECT_CATEGORY,
        value: enumeration,
      }),
      value: enumeration,
      isChecked: false,
    })),
  )
  const [isDatesValid, setIsDatesValid] = React.useState(true)

  const handleInvalidSelection = () => {
    setIsDatesValid(false)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    const checked = event.target.checked

    setCheckboxes((prevState) =>
      prevState.map((item) => {
        if (name === item.title) {
          return {
            ...item,
            isChecked: checked,
          }
        }

        return item
      }),
    )
  }

  const handleDateChange = (from: Date, to: Date, isValid: boolean) => {
    if (isValid) {
      setIsDatesValid(true)
      setDates({
        from,
        to,
      })
    }
  }

  const handleSubmit = async () => {
    setPending(true)
    const orderDefectTypes = checkboxes
      .filter((checkbox) => checkbox.isChecked)
      .map((checkbox) => checkbox.value)

    try {
      onRequestSubmit({
        sellerId,
        type: reportType,
        startDate: dates.from,
        endDate: dates.to,
        parameters: {
          order_defect_types: orderDefectTypes,
        },
        reportName: `${formatDate(
          dates.from,
          DATE_FORMAT_MONTH_DAY,
        )} - ${formatDate(dates.to, DATE_FORMAT_MONTH_DAY)}`,
      })
    } catch (e) {
      console.error(
        `Error generating Order Defect Rate Report for partner ${sellerId}: ${e}`,
      )
    } finally {
      setPending(false)
      reduxDispatch(closeDialog())
    }
  }

  const isDisabled =
    pending ||
    !dates.from ||
    !dates.to ||
    checkboxes.every((checkbox) => checkbox.isChecked === false) ||
    !isDatesValid

  const maxDate = endOfYesterday(new Date())

  return (
    <DialogContainer
      title="Generate Order Defect Report"
      isOpen={isOpen}
      isSubmitDisabled={isDisabled}
      onSubmit={handleSubmit}
      submitButtonText="Generate"
      disableScroll
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Text variant="caption">Select one or more categories*</Text>
          <FormGroup>
            {checkboxes.map((item) => (
              <FormControlLabel
                key={item.title}
                control={
                  <Checkbox
                    checked={item.isChecked}
                    onChange={handleChange}
                    name={item.title}
                  />
                }
                label={item.title}
              />
            ))}
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <DateRangeSelector
            type="odrMetric"
            descriptor="date-range"
            defaultRange={DateRange.LAST_90_DAYS}
            defaultFrom={dates.from}
            defaultTo={maxDate}
            maxDate={maxDate}
            maxDateError="Must be yesterday or earlier"
            onDateChange={handleDateChange}
            dateRangeOptions={dateRangeOptions}
            onInvalidSelection={handleInvalidSelection}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export default OrderDefectRateReportDialog
