import apiConfig from 'config/apiConfig'
import EnhancedTable from 'components/common/EnhancedTable'
import ErrorBoundary from 'components/common/ErrorBoundary'
import HeaderTitle from 'components/common/HeaderTitle'
import TableSpacer from 'components/common/TableSpacer'
import TitleBar from 'components/common/TitleBar'

import { saveFile } from 'services/files'
import { downloadReport } from 'services/reports'
import StyledIcon from 'components/common/StyledIcon'

type agreementForm = {
  name: string
  url: string
  description: string
}

export const formData: agreementForm[] = [
  {
    name: 'Target Platform Services Agreement - Main Terms and Service Terms',
    url: `${apiConfig.sms}/templates/Target%20Platform%20Services%20Agreement%20%E2%80%93%20Main%20Terms%20and%20Service%20Terms.pdf`,
    description: 'Legally binding contract',
  },
  {
    name: 'Target Platform Selling Service - Service Policies',
    url: `${apiConfig.sms}/templates/Target%20Platform%20Selling%20Service%20%E2%80%93%20Service%20Policies.pdf`,
    description: 'Service policies for all partners',
  },
]

const FormsAndAgreementsPage = () => {
  const handleDownload = async (url: string, name: string) => {
    const data = await downloadReport(url)
    saveFile({ data, name, type: 'application/pdf' })
  }

  const fieldList = [
    {
      key: 'name',
      heading: 'Name',
    },
    {
      key: 'description',
      heading: 'Desription',
    },
  ]

  const tableActions = [
    {
      label: 'Download',
      icon: <StyledIcon iconType="download" />,
      callback: (form: agreementForm) => () => {
        const { url, name } = form
        handleDownload(url, name)
      },
    },
  ]

  return (
    <div data-testid="forms-and-agreements-page">
      <ErrorBoundary page="Forms and Agreements">
        <HeaderTitle title="Forms and Agreements" />
        <TitleBar title="General Documents" />
        <TableSpacer>
          <EnhancedTable
            paginationDisabled
            data={formData}
            fieldList={fieldList}
            isLoading={false}
            actions={tableActions}
          />
        </TableSpacer>
      </ErrorBoundary>
    </div>
  )
}

export default FormsAndAgreementsPage
