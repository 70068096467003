import { useState, useEffect } from 'react'

import { useSelector } from 'react-redux'

import { startOfDay, addDays } from 'date-fns'
import { isValid } from 'date-fns'

import styled from '@emotion/styled'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import DateInput from 'components/common/DateInput'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import {
  formatDateMDY,
  formatDateMDYT,
} from 'components/common/EnhancedTable/formatters'
import {
  getEnhancedTablePageableProps,
  TableState,
  useSearchParams,
} from 'components/common/FilterBar/useSearchParams'
import HeaderTitle from 'components/common/HeaderTitle'
import StyledIcon from 'components/common/StyledIcon'
import TableSpacer from 'components/common/TableSpacer'
import TitleBar from 'components/common/TitleBar'

import { isOneOfUserRoles } from 'services/authorization'
import {
  createClosedDate,
  deleteClosedDate,
  getClosedDates,
} from 'services/closedDates'
import {
  formatDateString,
  formatDateLocalTime,
  DATE_DISPLAY_FORMAT,
  DATE_PICKER_FORMAT,
} from 'services/dateService'
import { Direction } from 'services/pageableHelper'
import { USER_ROLE_ADMIN, USER_ROLE_OPS } from 'services/roles'

import { getMemberOf } from 'store/selectors'

import { ClosedDate } from 'types/ClosedDates'

const StyledButtonContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  display: 'flex',
  justifyContent: 'flex-start',
}))

const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}))

const fieldList: EnhancedTableFieldType<ClosedDate>[] = [
  {
    key: 'calendar_date',
    heading: 'Calendar Date',
    formatCell: formatDateMDY('calendar_date'),
  },
  {
    key: 'created_by',
    heading: 'Created By',
  },
  {
    key: 'created',
    heading: 'Created',
    formatCell: formatDateMDYT('created'),
  },
  {
    key: 'published_date',
    heading: 'Published',
    formatCell: formatDateMDYT('published_date'),
  },
]

const initialSearchParams: TableState = {
  perPage: 20,
  page: 0,
  orderBy: 'calendar_date',
  direction: Direction.ASC,
}

export const HolidayClosuresPage = () => {
  const memberOf = useSelector(getMemberOf)

  const [searchParams, searchParamActions] =
    useSearchParams<TableState>(initialSearchParams)

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<ClosedDate[]>([])
  const [total, setTotal] = useState(0)
  const [refreshCount, setRefreshCount] = useState(0)
  const [inputDate, setInputDate] = useState<string>()

  useEffect(() => {
    let mounted = true

    const fetchData = async () => {
      setLoading(true)

      const results = await getClosedDates({
        page: searchParams.page,
        perPage: searchParams.perPage,
        orderBy: searchParams.orderBy,
        direction: searchParams.direction,
      })

      if (mounted) {
        setData(results.data)
        setTotal(results.total)
        setLoading(false)
      }
    }

    fetchData()
    return () => {
      mounted = false
    }
  }, [
    refreshCount,
    searchParams.page,
    searchParams.perPage,
    searchParams.orderBy,
    searchParams.direction,
  ])

  const canEdit = isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN, USER_ROLE_OPS])
  const currentDate = new Date()
  const startOfDayDate = startOfDay(currentDate)
  const resultDate = addDays(startOfDayDate, 1)

  const minPickerDate = {
    disabled: {
      before: resultDate,
    },
  }

  const handleDateChange = (date: Date) => {
    if (!date) {
      return // exit early so users can use a keyboard
    }

    let inputValue
    if (isValid(date)) {
      inputValue = date ? formatDateLocalTime(date, DATE_DISPLAY_FORMAT) : ''
      setInputDate(inputValue)
    }
  }

  const handleDateInputClear = () => {
    setInputDate(undefined)
  }

  const handleDateAdd = async () => {
    if (inputDate) {
      await createClosedDate(
        formatDateString(inputDate, DATE_DISPLAY_FORMAT, DATE_PICKER_FORMAT),
      )

      setRefreshCount((prev: number) => prev + 1)
      setInputDate(undefined)
    }
  }

  const handleDateDelete = (closedDate: ClosedDate) => async () => {
    if (
      window.confirm(
        `Are you sure you want to delete ${formatDateLocalTime(
          closedDate.calendar_date,
          DATE_DISPLAY_FORMAT,
          DATE_PICKER_FORMAT,
        )}?`,
      )
    ) {
      await deleteClosedDate(closedDate.id)
      setRefreshCount((prev: number) => prev + 1)
    }
  }

  return (
    <div>
      <HeaderTitle title="Holiday Closures" />
      <TitleBar
        data-testid="holiday-title-bar"
        title="Pre-Set Holiday Close Dates"
      />
      <Grid container>
        <Grid item xs={12}>
          <Typography>
            The closure dates listed below apply to all Partners and
            Distribution Centers (ship nodes). These dates will be defaulted on
            all accounts at midnight (CST) on January 15th of each year. Note:
            Deleting a date only deletes from this managed list, and should be
            done prior to Jan 15th each year.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledButtonContainer>
            <DateInput
              isDisabled={!canEdit}
              value={inputDate}
              placeholder="Closure Date"
              onDelete={handleDateInputClear}
              dayPickerProps={{ ...minPickerDate } as any}
              onChange={handleDateChange}
            />
            <StyledButton
              data-testid="add-new-closed-date-button"
              variant="contained"
              onClick={handleDateAdd}
              disabled={!inputDate}
            >
              add
            </StyledButton>
          </StyledButtonContainer>
        </Grid>
        <Grid item xs={12}>
          <TableSpacer>
            <EnhancedTable
              total={total}
              data={data}
              fieldList={fieldList}
              isLoading={loading}
              {...getEnhancedTablePageableProps(
                searchParams,
                searchParamActions,
              )}
              actions={[
                {
                  callback: canEdit ? handleDateDelete : undefined,
                  label: 'Delete',
                  icon: <StyledIcon iconType="delete" />,
                  hideAction: (element: ClosedDate) => {
                    if (element.published_date) {
                      return true
                    }
                    return false
                  },
                },
              ]}
            />
          </TableSpacer>
        </Grid>
      </Grid>
    </div>
  )
}

export default HolidayClosuresPage
