import React from 'react'
import { useSelector } from 'react-redux'

import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'

import ListIcon from '@mui/icons-material/List'
import ShowChartIcon from '@mui/icons-material/ShowChart'

import ContentSpacer from 'components/common/ContentSpacer'
import DisplayCard from 'components/common/DisplayCard'
import HeaderTitle from 'components/common/HeaderTitle'

import { primary } from 'config/themeConfig'

import { currentSeller as getCurrentSeller } from 'store/selectors'

import { BiReportingCards } from 'types/BiReporting'

import SalesMetricsCardContent from './SalesMetricsCard/SalesMetricsCardContent'
import SalesMetricsTables from './SalesMetricsTables'

export const SalesMetricsPage = () => {
  const currentSeller = useSelector(getCurrentSeller)

  const [showGmvGraph, setShowGmvGraph] = React.useState(true)
  const [showConversionGraph, setShowConversionGraph] = React.useState(true)

  const handleClick = (group: string) => () => {
    if (group === 'gmv') {
      setShowGmvGraph((prev) => !prev)
    } else if (group === 'conversion') {
      setShowConversionGraph((prev) => !prev)
    }
  }

  const makeSwitcher = (onClick: () => void, showChart: boolean) => (
    <ButtonGroup color="primary" aria-label="Select display method">
      <Button
        aria-label={`Display data as chart${showChart ? ' (current)' : ''}`}
        sx={{ backgroundColor: showChart ? primary.light : undefined }}
        disableTouchRipple
        onClick={() => {
          !showChart && onClick()
        }}
      >
        <ShowChartIcon />
      </Button>
      <Button
        aria-label={`Display data as chart${!showChart ? ' (current)' : ''}`}
        sx={{ backgroundColor: !showChart ? primary.light : undefined }}
        disableTouchRipple
        onClick={() => {
          showChart && onClick()
        }}
      >
        <ListIcon />
      </Button>
    </ButtonGroup>
  )

  return (
    <>
      <HeaderTitle title="Sales Metrics" />
      <DisplayCard
        title="Gross Merchandise Volume (GMV) Over Time"
        headerActions={makeSwitcher(handleClick('gmv'), showGmvGraph)}
      >
        <SalesMetricsCardContent
          graphName="sales-metrics-graph"
          cardId={BiReportingCards.SALES_METRICS_GMV_OVER_TIME}
          seller={currentSeller}
          valueFormat="currency"
          aggregateType="total"
          displayType={showGmvGraph ? 'graph' : 'table'}
        />
      </DisplayCard>
      <ContentSpacer />
      <DisplayCard
        title="PDP Conversion Rate Over Time"
        headerActions={makeSwitcher(
          handleClick('conversion'),
          showConversionGraph,
        )}
      >
        <SalesMetricsCardContent
          graphName="conversion-metrics-graph"
          cardId={BiReportingCards.OVERALL_CONVERSION_RATE_OVER_TIME}
          seller={currentSeller}
          valueFormat="percentNoCalc"
          aggregateType="average"
          displayType={showConversionGraph ? 'graph' : 'table'}
        />
      </DisplayCard>
      <ContentSpacer />
      <SalesMetricsTables seller={currentSeller} />
    </>
  )
}

export default SalesMetricsPage
