import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import * as yup from 'yup'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { error } from 'config/themeConfig'

import ContentSpacer from 'components/common/ContentSpacer'
import DialogContainer from 'components/common/Dialog/DialogContainer'
import Input from 'components/common/Input'
import { SellerTypeahead } from 'components/common/Typeahead'

import {
  getPartnerDiscountHistory,
  updatePartnerDiscount,
} from 'services/discounts'
import { PagingParams } from 'services/pageableHelper'
import { validationHandler, ValidationSchema } from 'services/validation'

import { closeDialog } from 'store/dialog/actionCreator'

import { PartnerDiscount } from 'types/Discounts'
import { isSmsSeller } from 'types/Guards'
import { SmsSeller } from 'types/Seller'
import { Validation } from 'types/Validation'

const validationSchema: ValidationSchema = yup.object().shape({
  returnShippingRateDiscount: yup
    .string()
    .label('Discount')
    .test({
      name: 'max',
      message: 'Discount must be 100% or less.',
      test(this: yup.TestContext, value: any) {
        if (value) {
          return parseFloat(value) <= 100
        }
        return true
      },
    })
    .test({
      name: 'min',
      message: 'Discount must be 0% or greater.',
      test(this: yup.TestContext, value: any) {
        if (value) {
          return parseFloat(value) >= 0
        }
        return true
      },
    })
    .test({
      name: 'decimals',
      message: 'Discount must have no more than two decimal places.',
      test(this: yup.TestContext, value: any) {
        if (value.includes('.')) {
          return value.split('.')[1].length <= 2
        }
        return true
      },
    })
    .required(),
})

export interface Props {
  isOpen: boolean
  discount?: PartnerDiscount
  reloadTable: () => void
}

export const EditPartnerDiscountDialog = ({
  isOpen,
  discount,
  reloadTable,
}: Props) => {
  const dispatch = useDispatch()

  const [isPending, setIsPending] = useState<boolean>(false)
  const [discountPercent, setDiscountPercent] = useState<string>(
    discount?.discount_percentage
      ? (discount.discount_percentage * 100).toFixed(2)
      : '',
  )

  const [newDiscountSeller, setNewDiscountSeller] = useState<SmsSeller | null>(
    null,
  )
  const [isNewSellerDiscount, setIsNewSellerDiscount] = useState(true)
  const [validation, setValidation] = useState<Validation>({})
  const [isValid, setIsValid] = useState<boolean>()

  let title = `Add Partner Discount`
  let subtitle = `Partners with existing discounts will need to be edited using the table.`

  if (discount?.id) {
    title = `Edit ${discount.display_name} Rate`
    subtitle = `To remove a discount, you can set the discount value to zero.`
  }

  const handleSubmit = async () => {
    if ((discount?.id || newDiscountSeller?.id) && discountPercent) {
      const payload = {
        discountId: discount?.id,
        sellerId: newDiscountSeller?.id,
        discountPercentage: parseFloat(discountPercent) / 100,
      }

      setIsPending(true)

      await updatePartnerDiscount(payload)
        .then(() => {
          reloadTable()
          dispatch(closeDialog())
        })
        .finally(() => setIsPending(false))
    }
  }

  const handleSellerSelect = (value: SmsSeller | undefined | null) => {
    if (value && isSmsSeller(value)) {
      setNewDiscountSeller(value)
      const pagingParams: PagingParams = {
        page: 0,
        perPage: 1,
      }
      getPartnerDiscountHistory(value.id, pagingParams).then(
        (discountHistory) => {
          if (discountHistory.length > 0) {
            setIsNewSellerDiscount(false)
          } else {
            setIsNewSellerDiscount(true)
          }
        },
      )
    }
  }

  const handleInputChange = (value: string) => {
    setDiscountPercent(value)
  }

  useEffect(() => {
    const { validation: newValidation, isValid } = validationHandler(
      validationSchema,
      {
        returnShippingRateDiscount: discountPercent,
      },
    )

    setIsValid(isValid)
    setValidation(newValidation)
  }, [discount, discountPercent])

  const handleOnClear = () => {
    setNewDiscountSeller(null)
    setIsNewSellerDiscount(true)
  }

  const isDisabled = discount?.id
    ? !discountPercent ||
      !isValid ||
      parseFloat(discountPercent) / 100 === discount?.discount_percentage
    : !newDiscountSeller || !discountPercent || !isNewSellerDiscount || !isValid

  return (
    <DialogContainer
      data-testid="add-partner-discount-dialog"
      isOpen={isOpen}
      title={title}
      isPending={isPending}
      onSubmit={handleSubmit}
      isSubmitDisabled={isDisabled}
      subtitle={subtitle}
    >
      <Grid container spacing={2}>
        {!discount?.id && (
          <Grid item xs={12}>
            <SellerTypeahead
              onChange={handleSellerSelect}
              value={newDiscountSeller}
              onClear={handleOnClear}
            />
            {!isNewSellerDiscount && (
              <Typography color={error.main}>
                Partner discount already exists.
              </Typography>
            )}
            <ContentSpacer />
          </Grid>
        )}
        <Grid item xs={12}>
          <Input
            isRequired
            id="discount-percentage"
            data-testid="discount-percentage"
            label="Return Service Fee Discount (%)"
            name="returnShippingRateDiscount"
            isDisabled={isPending}
            value={discountPercent}
            onChange={handleInputChange}
            validation={validation}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export default EditPartnerDiscountDialog
