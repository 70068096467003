import * as yup from 'yup'
import { ApiToken } from 'types/ApiTokens'

const getValidationSchema = (tokens: ApiToken[] = []) => {
  return yup.object().shape({
    description: yup
      .string()
      .label('Description')
      .max(40)
      .required()
      .test({
        name: 'unique',
        message: 'Description must be unique',
        test(this: yup.TestContext, value: any) {
          return !tokens.map((token) => token.description).includes(value)
        },
      }),
  })
}

export default getValidationSchema
