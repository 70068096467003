import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import isEmpty from 'lodash/fp/isEmpty'

import DialogContainer from 'components/common/Dialog/DialogContainer'

import { DAYS } from 'constants/days'

import { hydrateSmsAddress } from 'services/addressHelper'
import { formatDate, DATE_DISPLAY_FORMAT_TIME } from 'services/dateService'
import { getVendorCategories } from 'services/marketplaceVendors'
import { validationHandler } from 'services/validation'
import { timezoneCode } from 'services/timezoneCode'

import { createSellerDistributionCenterAndShipNode } from 'store/seller/actionCreators'

import { ShipNode, SmsDistributionCenter, VendorCategory } from 'types/Seller'

import {
  slaSchema,
  validationSchema as carrierValidationSchema,
} from '../EditCarrierInfo/index'
import { CarrierInfoState } from '../EditCarrierInfo/useCarrierInfo'

import { DayOption } from '../EditHoursOfOperation'
import { workingHoursSchema } from '../EditHoursOfOperation/DaysOfOperationTable'

import AddDistributionCenterPageOne from './AddDistributionCenterPageOne'
import AddDistributionCenterPageTwo from './AddDistributionCenterPageTwo'
import { Address } from 'types/Address'

export interface Props {
  isOpen: boolean
  isPending: boolean
}

export type DistributionCenterFieldValues = {
  name: string
  address: Address
  daysClosed: string[]
  timezone: string
  dayOptions: any
  eligibleStates: string[]
}
const initialValues: DistributionCenterFieldValues = {
  name: '',
  address: {
    address1: '',
    address2: '',
    city: '',
    countryCode: '',
    postalCode: '',
    state: '',
  },
  daysClosed: [],
  timezone: '',
  dayOptions: DAYS.map((day: string) => {
    return {
      checked: false,
      day,
      openTime: '',
      closeTime: '',
      validation: {},
    }
  }),
  eligibleStates: [],
}

export const AddDistributionCenter = ({ isPending, isOpen }: Props) => {
  const dispatch = useDispatch()

  const [page, setPage] = useState(1)
  const [validateCarriers, setValidateCarriers] = useState(false)
  const [fieldValues, setFieldValues] =
    useState<DistributionCenterFieldValues>(initialValues)
  const [fieldErrors, setFieldErrors] = useState<any>({})
  const [carriers, setCarriers] = useState<CarrierInfoState>()
  const [vendorCategories, setVendorCategories] = useState<VendorCategory[]>([])

  const shipNodeName = formatDate(new Date(), DATE_DISPLAY_FORMAT_TIME)

  useEffect(() => {
    getVendorCategories().then((response) => setVendorCategories(response))
  }, [])

  const handlePageBack = () => {
    setPage(1)
  }
  const handlePageAdvance = () => {
    let isValidDayOptions = true
    const validatedDayOptions = fieldValues.dayOptions.map((day: DayOption) => {
      if (day.checked) {
        const { validation, isValid } = validationHandler(
          workingHoursSchema,
          day,
        )

        if (!isValid) {
          isValidDayOptions = false
        }

        return { ...day, validation }
      } else {
        return { ...day }
      }
    })

    setFieldValues((prevState: any) => {
      return { ...prevState, dayOptions: validatedDayOptions }
    })

    if (isValidDayOptions) {
      setPage(2)
    }
  }

  const validateSlas = (carriers: CarrierInfoState): boolean => {
    const { serviceLevelAgreements, shippingServiceOptions } = carriers
    let valid = true

    if (!carriers.orderCapacity) {
      serviceLevelAgreements.forEach((sla) => {
        if (sla.days_to_add || sla.max_days_to_add) {
          valid = false
        }
      })
    }

    shippingServiceOptions.map((currOption: any) => {
      const sla = serviceLevelAgreements.find(
        (service) => service.id === currOption.id,
      )
      if (sla) {
        const { validation, isValid } = validationHandler(slaSchema, sla)
        if (!isValid) {
          valid = false
        }

        if (sla.days_to_add && !sla.max_days_to_add) {
          valid = false

          return {
            ...currOption,
            validation: {
              ...validation,
              maxDaysToAdd: ['Required if Rollover or Capacity exist'],
            },
          }
        }
        return { ...currOption, validation }
      } else {
        return { ...currOption, validation: {} }
      }
    })

    return valid
  }

  const handleSubmit = () => {
    setValidateCarriers(true)

    if (carriers) {
      const { isValid } = validationHandler(carrierValidationSchema, carriers)
      const areSlasValid = validateSlas(carriers)
      if (isValid && areSlasValid) {
        const workingHours = fieldValues.dayOptions
          .map((day: DayOption) => {
            if (day.checked) {
              return {
                day: day.day.toUpperCase(),
                open_time: day.openTime,
                close_time: day.closeTime,
              }
            }

            return false
          })
          .filter((day: DayOption) => day)
        const daysOfOperation = {
          working_hours: workingHours,
          days_closed: fieldValues.daysClosed,
        }

        const maxOrdersPerDay =
          typeof carriers.orderCapacity === 'string' &&
          isFinite(parseInt(carriers.orderCapacity, 10))
            ? parseInt(carriers.orderCapacity, 10)
            : null

        const carrierInfoEdit: ShipNode = {
          name: shipNodeName,
          carriers: carriers.carriers,
          service_levels: carriers.serviceLevelAgreements,
          building_lead_time_id: carriers.buildingLeadTimeId,
          max_orders_per_day: maxOrdersPerDay,
        }

        const distributionCenter: Partial<SmsDistributionCenter> = {
          name: fieldValues.name,
          address: hydrateSmsAddress(fieldValues.address),
          two_day_state_codes: fieldValues.eligibleStates,
          days_of_operation: daysOfOperation,
          timezone: fieldValues.timezone,
        }

        dispatch(
          createSellerDistributionCenterAndShipNode(
            distributionCenter,
            carrierInfoEdit,
          ),
        )
      }
    }
  }

  const setFieldValue = (name: string, value: any) => {
    if (name === 'address') {
      setFieldValues((prevState: any) => {
        return { ...prevState, address: { ...prevState.address, ...value } }
      })
    } else if (name === 'dayOptions') {
      setFieldValues((prevState: any) => {
        return {
          ...prevState,
          dayOptions: prevState.dayOptions.map((option: any) => {
            if (option.day === value.day) {
              return value
            }
            return option
          }),
        }
      })
    } else if (name === 'eligibleStates') {
      setFieldValues((prevState: any) => {
        return {
          ...prevState,
          eligibleStates: value,
        }
      })
    } else {
      setFieldValues((prevState: any) => {
        return { ...prevState, [name]: value }
      })
    }
  }

  const setFieldError = (field: string, validation: any) => {
    setFieldErrors((prevState: any) => {
      if (validation[field]) {
        return { ...prevState, [field]: validation[field] }
      } else {
        delete prevState[field]
        return prevState
      }
    })
  }

  const disableSubmit = (page: number) => {
    if (page === 1) {
      return (
        !isEmpty(fieldErrors) ||
        !fieldValues.name ||
        !fieldValues.address.address1 ||
        !fieldValues.address.city ||
        !fieldValues.address.countryCode ||
        !fieldValues.address.postalCode ||
        !fieldValues.address.state ||
        !fieldValues.timezone ||
        !(
          fieldValues.dayOptions.filter((day: any) => day.checked === true)
            .length > 0
        )
      )
    } else if (page === 2) {
      return false
    }
  }

  return (
    <DialogContainer
      title="Add Distribution Center & Ship Node"
      isOpen={isOpen}
      isPending={isPending}
      onSubmit={page === 1 ? handlePageAdvance : handleSubmit}
      onPrevious={page === 2 ? handlePageBack : undefined}
      submitButtonText={page === 1 ? 'next' : 'done, save'}
      previousButtonText={'back to previous'}
      isSubmitDisabled={disableSubmit(page)}
      maxWidth="lg"
      cancelConfirmText="Closing this dialog box before completion of this task will prevent the creation of the Distribution Center"
      subtitle={
        page === 2
          ? `Distribution Center Time Zone: ${timezoneCode(
              fieldValues.timezone,
            )}`
          : undefined
      }
    >
      {page === 1 && (
        <AddDistributionCenterPageOne
          isPending={isPending}
          fieldValues={fieldValues}
          fieldErrors={fieldErrors}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
        />
      )}
      {page === 2 && vendorCategories.length > 0 && (
        <AddDistributionCenterPageTwo
          fieldValues={fieldValues}
          setFieldValue={setFieldValue}
          vendorCategories={vendorCategories}
          validateCarriers={validateCarriers}
          setCarriers={setCarriers}
          setValidateCarriers={setValidateCarriers}
        />
      )}
    </DialogContainer>
  )
}

export default AddDistributionCenter
