export const getUniqueValues = <T extends Partial<Record<keyof T, any>>>(
  list: T[],
  prop: keyof T,
): string[] => {
  const cache: Record<string, boolean> = {}
  const len = list.length

  for (let i = 0; i < len; i++) {
    const current = list[i]
    const property = current[prop]

    if (!Object.prototype.hasOwnProperty.call(cache, property)) {
      cache[property] = true
    }
  }

  return Object.keys(cache)
}
