import styled from '@emotion/styled'

import { CancelOutlined, CheckCircle } from '@mui/icons-material'

import { error, success } from 'config/themeConfig'

import { getResponsibilityLabel } from 'services/functionalResponsibilities'
import { allResponsibilities } from 'services/usersHelper'

const StyledDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(1),
}))

const StyledSuccessIcon = styled(CheckCircle)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: success.main,
}))

const StyledErrorIcon = styled(CancelOutlined)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: error.main,
}))

export interface Props {
  responsibilities: number[]
}

export const UserResponsibilities = ({ responsibilities }: Props) => {
  return (
    <>
      {allResponsibilities.map((responsibility) => {
        const completed = responsibilities.includes(responsibility)

        const testId = completed ? 'completed' : 'missing'

        return (
          <StyledDiv
            key={responsibility}
            data-testid={`${testId}-responsibility`}
          >
            {completed ? <StyledSuccessIcon /> : <StyledErrorIcon />}

            {getResponsibilityLabel(responsibility)}
          </StyledDiv>
        )
      })}
    </>
  )
}

export default UserResponsibilities
