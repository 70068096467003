import React, { PropsWithChildren } from 'react'

import CardContent from '@mui/material/CardContent'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'

import WarningIcon from '@mui/icons-material/WarningRounded'

import styled from '@emotion/styled'

const StyledLoader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

interface Props {
  hasWarning?: boolean
  isLoading: boolean
  noGutter: boolean
  title: string
  warningTooltip?: string
}

export const DisplayCardContent: React.FC<PropsWithChildren<Props>> = ({
  children,
  hasWarning,
  isLoading,
  noGutter,
  title,
  warningTooltip,
}) => {
  return (
    <CardContent sx={{ padding: !noGutter ? 2 : 0 }}>
      <Grid container alignItems="flex-start" alignContent="center">
        <Grid item>
          {hasWarning && (
            <Tooltip data-testid="warning-icon" title={warningTooltip || ''}>
              <WarningIcon style={{ color: 'gold' }} />
            </Tooltip>
          )}
        </Grid>
      </Grid>
      {isLoading && (
        <StyledLoader data-testid={`${title}-display-card-loader`}>
          <CircularProgress />
        </StyledLoader>
      )}
      {!isLoading && children}
    </CardContent>
  )
}

export default DisplayCardContent
