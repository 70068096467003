import { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import find from 'lodash/fp/find'

import styled from '@emotion/styled'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import TitleBar from 'components/common/TitleBar'
import DataList from 'components/common/DataList'
import { DialogEnum } from 'components/common/Dialog'
import EditButton from 'components/common/EditButton'
import RaisedCard from 'components/common/RaisedCard'
import LinkButton from 'components/common/LinkButton'
import HeaderTitle from 'components/common/HeaderTitle'
import PhoneNumber from 'components/common/PhoneNumber'
import Text from 'components/common/Text'

import { RoutePath } from 'services/NavigationHelper'

import {
  getSellers,
  getUser,
  getUserEntitlements,
  isRoleExternalUserSelector,
} from 'store/selectors'

import { PHONE_TYPE_OFFICE, PHONE_TYPE_MOBILE } from 'constants/phones'

import { SellerStatus } from 'types/Seller'
import { Role } from 'types/SellerUser'

import { SupportCode } from 'types/SupportCode'

const StyledSellerTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
}))

const StyledCardTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1.5),
}))

interface UserSeller {
  sellerId: string
  sellerName?: string
  userType: Role
  contactFor: boolean
}

export const UserProfilePage = () => {
  const sellers = useSelector(getSellers)
  const user = useSelector(getUser)
  const isExternalUser = useSelector(isRoleExternalUserSelector)
  const entitlements = useSelector(getUserEntitlements)

  const [userSellers, setUserSellers] = useState<UserSeller[]>([])

  const getSellersForUser = useCallback(() => {
    const entitlementsBySeller = entitlements.map((entitlement) => {
      const targetSeller = sellers.find(
        (seller) => seller.id === entitlement.resource_id,
      )
      const targetVMMData = user?.vmm_metadata?.find(
        (data) => data.seller_id === entitlement.resource_id,
      )
      const sellerName = targetSeller ? targetSeller.display_name : ''
      const sellerId = targetSeller ? targetSeller.id : ''
      const status = targetSeller ? targetSeller.status : undefined
      let contactFor = false
      if (
        targetVMMData?.functional_responsibilities?.includes(
          SupportCode.CUSTOMER_SUPPORT,
        )
      ) {
        contactFor = true
      }
      return {
        sellerName,
        sellerId,
        status,
        userType: entitlement.role,
        contactFor,
      }
    })

    const sellersForUser = entitlementsBySeller.filter(
      (seller) =>
        seller.status &&
        seller.status !== SellerStatus.DEFUNCT &&
        seller.status !== SellerStatus.INITIATED,
    )

    return sellersForUser
  }, [entitlements, sellers, user?.vmm_metadata])

  useEffect(() => {
    const sellerList = getSellersForUser()

    setUserSellers(sellerList)
  }, [entitlements, getSellersForUser, sellers])

  const officeNumber = find(
    (item) => item.type === PHONE_TYPE_OFFICE,
    user.phone_numbers,
  )
  const mobileNumber = find(
    (item) => item.type === PHONE_TYPE_MOBILE,
    user.phone_numbers,
  )

  const officeNumberComponent = officeNumber ? (
    <PhoneNumber phone={officeNumber} />
  ) : (
    <Text type="emphasis">None provided</Text>
  )

  const userInfo = [
    {
      title: 'Name:',
      value: user.fullName,
    },
    {
      title: 'Email:',
      value: user.email,
    },
    {
      title: 'Office:',
      element: officeNumberComponent,
    },
    {
      title: 'Mobile:',
      element: <PhoneNumber phone={mobileNumber} />,
    },
  ]

  if (!isExternalUser) return null

  return (
    <div data-testid="user-profile-page">
      <HeaderTitle title="My Profile" />
      <Grid container spacing={2}>
        <Grid item md={5}>
          <TitleBar
            title="USER INFO"
            actionButtons={[
              <EditButton
                key="edit-user-info-button"
                data-testid="edit-user-info-button"
                dialogComponent={DialogEnum.EDIT_USER_INFO}
                aria-label="Edit User Info"
              />,
            ]}
          />
          <DataList data-testid="user-info" data={userInfo} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StyledSellerTitle variant="h3">Partners</StyledSellerTitle>
        </Grid>
        <Grid container spacing={2}>
          {userSellers.map((seller, index) => (
            <Grid key={index} item xs={4}>
              <RaisedCard data-testid="user-seller-card">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <StyledCardTitle variant="h4">
                      {seller.sellerName}
                    </StyledCardTitle>
                  </Grid>
                  <Grid item xs={12}>
                    <DataList
                      data={[
                        {
                          title: 'User Type:',
                          value:
                            seller.userType === 'ADMIN' ? 'Admin' : 'View Only',
                        },
                        {
                          title: 'Contact for:',
                          value: seller.contactFor
                            ? 'Customer Support'
                            : 'None Specified',
                        },
                      ]}
                    />
                  </Grid>
                  <Grid container item xs={12} justifyContent="flex-end">
                    <LinkButton
                      color="primary"
                      to={`/${seller.sellerId}${RoutePath.DASHBOARD}`}
                    >
                      GO TO PARTNER
                    </LinkButton>
                  </Grid>
                </Grid>
              </RaisedCard>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  )
}

export default UserProfilePage
