import {
  DateRange,
  getRangeConfig,
} from 'components/common/DateRangePicker/rangeConfig'
import { DATE_PICKER_FORMAT, formatDate } from './dateService'

export function getGreenfield90DayInterval(): string {
  const last90Days = getRangeConfig(
    DateRange.LAST_90_DAYS,
    undefined,
    'odrMetric',
  )
  return formatGreenfieldDayInterval(last90Days)
}

export function formatGreenfieldDayInterval(days: {
  label: string
  from: Date
  to: Date
}): string {
  return `${formatDate(days.from, DATE_PICKER_FORMAT)}/${formatDate(
    days.to,
    DATE_PICKER_FORMAT,
  )}`
}
