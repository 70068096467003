import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import getOr from 'lodash/fp/getOr'

import { Button } from '@mui/material'
import Grid from '@mui/material/Grid'

import { DialogEnum } from 'components/common/Dialog'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import {
  formatDateMDYT,
  caseLink,
  prettyCase,
} from 'components/common/EnhancedTable/formatters'
import HeaderTitle from 'components/common/HeaderTitle'
import FilterBar from 'components/common/FilterBar'
import SelectFilter from 'components/common/FilterBar/SelectFilter'
import TypeaheadFilter from 'components/common/FilterBar/TypeaheadFilter'
import DateRangeFilter from 'components/common/FilterBar/DateRangeFilter'
import {
  useSearchParams,
  TableState,
  getEnhancedTablePageableProps,
} from 'components/common/FilterBar/useSearchParams'
import TitleBar from 'components/common/TitleBar'

import { isOneOfUserRoles } from 'services/authorization'
import { USER_ROLE_APP_SMS_ADMIN, USER_ROLE_APP_SMS_READ } from 'services/roles'
import { getOptionsFromEnumeration } from 'services/enumerations'
import { isoStringStartOfDay, isoStringEndOfDay } from 'services/dateService'

import { getCases } from 'services/cases'
import { openDialog } from 'store/dialog/actionCreator'
import { currentSellerId, getMemberOf, getCaseTypes } from 'store/selectors'

import { Case } from 'types/Case'
import { Direction } from 'services/pageableHelper'
import { CollectionResponse } from 'types/Response'

const fieldList: EnhancedTableFieldType<Case>[] = [
  {
    key: 'case_number',
    heading: 'Case Number',
    formatCell: caseLink(),
  },
  {
    key: 'subject',
  },
  {
    key: 'case_type',
    formatCell: prettyCase('case_type'),
  },
  {
    key: 'status',
  },
  {
    key: 'created',
    heading: 'Created Date',
    formatCell: formatDateMDYT('created'),
  },
  {
    key: 'seller_email',
    heading: 'Partner Email',
  },
  {
    key: 'last_modified',
    heading: 'Last Modified Date',
    formatCell: formatDateMDYT('last_modified'),
  },
]

type SearchParams = TableState & {
  status: string | undefined
  caseType: string | undefined
  case_number: string | undefined
  starts_on: string | undefined
  ends_on: string | undefined
}

const initialSearchParams = {
  perPage: 100,
  page: 0,
  direction: Direction.DESC,
  orderBy: 'created',
  status: undefined,
  caseType: undefined,
  case_number: undefined,
  starts_on: undefined,
  ends_on: undefined,
}

export const OpenCasesPage = () => {
  const reduxDispatch = useDispatch()

  const sellerId = useSelector(currentSellerId)
  const memberOf = useSelector(getMemberOf)
  const [caseList, setCaseList] = useState<CollectionResponse<Case>>({
    data: [],
    total: 0,
  })
  const [isCasesPending, setIsCasesPending] = useState(false)
  const [totalCases, setTotalCases] = useState(0)

  const caseTypes = getOptionsFromEnumeration(useSelector(getCaseTypes)).filter(
    (item) => item.value !== 'RETURNS_DISPUTES_V2',
  )

  const [searchParams, searchParamActions, appliedFilterCount] =
    useSearchParams<SearchParams>(initialSearchParams)
  const [refreshCount, setRefreshCount] = useState(0)

  const hasAccess = isOneOfUserRoles(memberOf, [
    USER_ROLE_APP_SMS_ADMIN,
    USER_ROLE_APP_SMS_READ,
  ])

  const caseListWithPartnerId = caseList.data.map((partnerCase) => {
    const issueDescription = getOr('', 'issue_description', partnerCase)

    return {
      ...partnerCase,
      seller_id: sellerId,
      issue_description:
        issueDescription.length > 75
          ? `${issueDescription.substring(0, 75)}...`
          : issueDescription,
    }
  })

  useEffect(() => {
    const fetchCases = async () => {
      if (sellerId) {
        const params = {
          ...searchParams,
          starts_on: searchParams.starts_on
            ? isoStringStartOfDay(searchParams.starts_on)
            : undefined,
          ends_on: searchParams.ends_on
            ? isoStringEndOfDay(searchParams.ends_on)
            : undefined,
          seller_id: sellerId,
        }

        setIsCasesPending(true)
        try {
          const data: CollectionResponse<Case> = await getCases(params)
          setCaseList(data)
          setTotalCases(data.total)
        } catch (e) {
          console.error(`Error in OpenCasesPage: ${e}`)
        } finally {
          setIsCasesPending(false)
        }
      }
    }
    fetchCases()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerId, searchParams, refreshCount])

  const onFilterClear = () => {
    searchParamActions.updateSearchParam(initialSearchParams)
  }

  const refreshData = () => {
    setRefreshCount((prev: number) => prev + 1)
  }

  const handleSubmitNewCase = () => {
    reduxDispatch(
      openDialog({
        dialogEnum: DialogEnum.ADD_NEW_CASE,
        componentProps: {
          handleClose: refreshData,
        },
      }),
    )
  }

  return (
    <div>
      <HeaderTitle title="Cases" />
      <TitleBar
        title={`Total Cases: ${totalCases}`}
        actionButtons={[
          hasAccess && (
            <Button
              key="add-new-case-button"
              data-testid="add-new-case-button"
              color="primary"
              variant="contained"
              onClick={handleSubmitNewCase}
              disabled={isCasesPending}
            >
              Submit a new case
            </Button>
          ),
        ]}
      />
      <FilterBar
        onClear={onFilterClear}
        appliedFilterCount={appliedFilterCount}
      >
        <Grid item xs={12} lg={2}>
          <TypeaheadFilter
            sellerId={sellerId}
            value={searchParams.case_number}
            searchParam="case_number"
            onChange={searchParamActions.updateSearchParam}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <SelectFilter
            placeholder="Case Status"
            value={searchParams.status}
            searchParam="status"
            onChange={searchParamActions.updateSearchParam}
            data={[
              { name: 'New', value: 'New' },
              { name: 'In Progress', value: 'In Progress' },
              { name: 'Resolved', value: 'Resolved' },
            ]}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <SelectFilter
            placeholder="Case Type"
            value={searchParams.caseType}
            searchParam="caseType"
            onChange={searchParamActions.updateSearchParam}
            data={caseTypes}
          />
        </Grid>
        <Grid item xs={12}>
          <DateRangeFilter
            label="Case Created"
            startValue={searchParams.starts_on}
            startSearchParam="starts_on"
            endValue={searchParams.ends_on}
            endSearchParam="ends_on"
            onChange={searchParamActions.updateSearchParam}
          />
        </Grid>
      </FilterBar>
      <EnhancedTable
        data={caseListWithPartnerId}
        fieldList={fieldList}
        isLoading={isCasesPending}
        total={totalCases}
        {...getEnhancedTablePageableProps(searchParams, searchParamActions)}
      />
    </div>
  )
}

export default OpenCasesPage
