import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'

import {
  fetchUserSettings,
  updateUserSettings,
  updateUserSettingsFavoriteReports,
} from 'services/userSettings'
import { ReportType } from 'types/Report'
import { UserSettings } from 'types/UserSettings'

import { getUserSettingsQueryKey } from './queries'

export const useUserSettingsQuery = () => {
  const { data, isLoading, status } = useQuery(getUserSettingsQueryKey(), () =>
    fetchUserSettings(),
  )

  return {
    data,
    isLoading,
    status,
  }
}

type ReportMutationVariables = {
  reportType: ReportType
  userSettings: UserSettings
}

type SavedSearchMutationVariables = {
  userSettings: UserSettings
}

export const useUpdateFavoriteReportsMutation = () => {
  const queryClient = useQueryClient()

  const { mutate, isLoading, status, reset } = useMutation<
    UserSettings,
    any,
    ReportMutationVariables
  >(
    ({ reportType, userSettings }) =>
      updateUserSettingsFavoriteReports({ reportType, settings: userSettings }),
    {
      onSuccess: () => {
        const queryKey = getUserSettingsQueryKey()

        queryClient.invalidateQueries(queryKey)
      },
    },
  )

  const updateFavoriteReports = ({
    reportType,
    userSettings,
  }: ReportMutationVariables) => {
    mutate({ reportType, userSettings })
  }

  return {
    updateFavoriteReports,
    isLoading,
    status,
    reset,
  }
}

export const useUpdateSavedSearchMutation = () => {
  const queryClient = useQueryClient()

  const { mutate, isLoading, status, reset } = useMutation<
    UserSettings,
    any,
    SavedSearchMutationVariables
  >(({ userSettings }) => updateUserSettings({ data: userSettings }), {
    onSuccess: () => {
      const queryKey = getUserSettingsQueryKey()
      queryClient.invalidateQueries(queryKey)
    },
  })

  const updateSavedSearches = ({
    userSettings,
  }: SavedSearchMutationVariables) => {
    mutate({ userSettings })
  }

  return {
    updateSavedSearches,
    isLoading,
    status,
    reset,
  }
}
