import Chip from '@mui/material/Chip'

import { olive, success, error, violet } from 'config/themeConfig'

import { JudgementValues } from 'types/disputes'

export const statusColor = (status: string) => {
  switch (status) {
    case JudgementValues.NEEDS_REVIEW:
      return olive.main
    case JudgementValues.VALID_CLAIM:
      return success.main
    case JudgementValues.INVALID_CLAIM:
      return error.main
    case JudgementValues.WAITING_ON_PARTNER:
      return violet.main
    default:
      return
  }
}

export const statusText = (status: string) => {
  switch (status) {
    case JudgementValues.NEEDS_REVIEW:
      return 'In Progress'
    case JudgementValues.VALID_CLAIM:
      return 'Approved'
    case JudgementValues.INVALID_CLAIM:
      return 'Denied'
    case JudgementValues.WAITING_ON_PARTNER:
      return 'Waiting On Partner'
    default:
      return ''
  }
}

interface Props {
  returnDisputeJudgement: JudgementValues
}

const ReturnDisputeStatusChip = ({ returnDisputeJudgement }: Props) => {
  return (
    <Chip
      label={statusText(returnDisputeJudgement)}
      variant="outlined"
      sx={{ color: statusColor(returnDisputeJudgement) }}
    />
  )
}

export default ReturnDisputeStatusChip
