import React from 'react'

import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'

import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import DialogActions from '@mui/material/DialogActions'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Switch from '@mui/material/Switch'

import { TaxonomyTypeahead } from 'components/common/Typeahead'
import Text from 'components/common/Text'

import Attribute, { ITEM_TYPE } from 'types/Attribute'

const StyledChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
}))

export interface Props {
  newItemTypeHolds: Attribute[]
  onRequestCancel: () => void
  onItemTypeAdd: (attribute: Nullable<Attribute>) => void
  onItemTypeChipRemove?: (key: string) => void
  onRequestSubmitNewHold: (autoApproval: boolean) => void
  autoApproval: boolean
}

export const AddItemTypeHoldsAside = ({
  newItemTypeHolds,
  onRequestCancel,
  onItemTypeAdd,
  onItemTypeChipRemove,
  onRequestSubmitNewHold,
  autoApproval,
}: Props) => {
  const [autoApprovalSetting, setAutoApprovalSetting] =
    React.useState<boolean>(autoApproval)

  React.useEffect(() => {
    setAutoApprovalSetting(autoApproval)
  }, [autoApproval])

  const handleDelete = (event: any) => {
    const key = event.currentTarget.parentNode.id

    if (onItemTypeChipRemove) {
      onItemTypeChipRemove(key)
    }
  }

  const handleAutoApprovalSwitch = (event: any) => {
    setAutoApprovalSetting(event.currentTarget.checked)
  }

  const handleSubmit = () => {
    onRequestSubmitNewHold(autoApprovalSetting)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>
          If turned on, error-free items will be automatically approved.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          data-testid="auto-approval-label"
          control={
            <Switch
              data-testid="auto-approval-switch"
              color="primary"
              checked={autoApprovalSetting}
              onChange={handleAutoApprovalSwitch}
              value="autoApprovalSetting"
            />
          }
          label={autoApprovalSetting ? 'On' : 'Off'}
        />
      </Grid>
      <Grid item xs={12}>
        <TaxonomyTypeahead
          label="Add holds (if any)"
          placeholder="Search Item Type"
          searchType={ITEM_TYPE}
          onChange={onItemTypeAdd}
          clearOnSelect
        />
      </Grid>
      <Grid item xs={12}>
        {!newItemTypeHolds.length && (
          <Text type="micro">No Item Type selected.</Text>
        )}
        {newItemTypeHolds.map((hold) => {
          return (
            <StyledChip
              id={hold.id}
              key={hold.id}
              data-testid={hold.id}
              label={hold.name}
              onDelete={onItemTypeChipRemove ? handleDelete : undefined}
            />
          )
        })}
      </Grid>
      <Grid item xs={12}>
        <DialogActions>
          <Button
            color="primary"
            onClick={onRequestCancel}
            data-testid="cancel"
          >
            cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={
              newItemTypeHolds.length < 1 &&
              autoApproval === autoApprovalSetting
            }
            data-testid="submit"
          >
            save
          </Button>
        </DialogActions>
      </Grid>
    </Grid>
  )
}

export default AddItemTypeHoldsAside
