import { useCallback, useState, useEffect } from 'react'

import styled from '@emotion/styled'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import StyledIcon from 'components/common/StyledIcon'

import { DialogEnum } from 'components/common/Dialog'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import { formatDateMDYT } from 'components/common/EnhancedTable/formatters'
import useTable from 'components/common/EnhancedTable/useTable'
import LabeledDataList from 'components/common/LabeledDataList'
import HeaderTitle from 'components/common/HeaderTitle'

import { isOneOfUserRoles } from 'services/authorization'
import {
  getProtectedLegalizationsWithNames,
  removeProtectedLegalization,
} from 'services/legalizations'
import { USER_ROLE_ADMIN, USER_ROLE_PROGRAM_MANAGER } from 'services/roles'
import { scrollTo } from 'services/scrollTo'

import { openDialog } from 'store/dialog/actionCreator'
import { BRAND } from 'types/Attribute'
import { ProtectedLegalization } from 'types/Legalization'
import { useDispatch, useSelector } from 'react-redux'
import { getMemberOf } from 'store/selectors'

const StyledContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))

const StyledText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const StyledTableHeader = styled(Grid)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(4),
}))

const fieldList: EnhancedTableFieldType<ProtectedLegalization>[] = [
  {
    key: 'name',
    heading: 'Brand',
    hasSort: false,
    formatCell: (legalization) => legalization.attribute.name || '',
  },
  {
    key: 'created',
    heading: 'Date Added',
    formatCell: formatDateMDYT('created'),
  },
  {
    key: 'created_by',
    heading: 'Added By',
    hasSort: false,
  },
]

export const ProtectedBrandsPage = () => {
  const dispatch = useDispatch()

  const memberOf = useSelector(getMemberOf)
  const canEdit = isOneOfUserRoles(memberOf, [
    USER_ROLE_PROGRAM_MANAGER,
    USER_ROLE_ADMIN,
  ])

  const [brands, setBrands] = useState<ProtectedLegalization[]>([])
  const [totalBrands, setTotalBrands] = useState(0)
  const [pending, setPending] = useState(false)

  const { table } = useTable({
    page: 0,
    perPage: 500,
  })

  const getProtectedBrands = useCallback(() => {
    setPending(true)

    getProtectedLegalizationsWithNames(BRAND, {
      page: table.state.page + 1,
      per_page: table.state.perPage,
    })
      .then(({ data, total }) => {
        setBrands(data)
        setTotalBrands(total)
      })
      .finally(() => {
        setPending(false)
        scrollTo('scroll-ref', 0)
      })
  }, [table.state])

  useEffect(() => {
    getProtectedBrands()
  }, [getProtectedBrands])

  const handleDeleteClick = (legalization: ProtectedLegalization) => () => {
    if (!legalization.id) return

    if (
      window.confirm(
        `Are you sure you want to delete ${legalization.attribute.name}?`,
      )
    ) {
      removeProtectedLegalization(legalization.id).then(getProtectedBrands)
    }
  }

  const handleAddBrandClick = () => {
    dispatch(
      openDialog({
        dialogEnum: DialogEnum.ADD_PROTECTED_BRAND,
        closeCallback: getProtectedBrands,
      }),
    )
  }

  const labeledData = [{ label: 'Total Brands:', data: totalBrands }]

  return (
    <div id="scroll-ref">
      <HeaderTitle title="Protected Brands" />
      <StyledContainer container spacing={2} alignItems="flex-end">
        <StyledText>
          Indicate protected brands. Other partners will be restricted from
          selling these brands unless an exception is made in the partner's
          terms.
        </StyledText>
        <StyledTableHeader item xs={12} md={9}>
          <LabeledDataList data={labeledData} />
          {canEdit && (
            <Button
              data-testid="add-brand-button"
              onClick={handleAddBrandClick}
              color="primary"
            >
              <AddIcon color="primary" />
              Add Brand
            </Button>
          )}
        </StyledTableHeader>
        <Grid item xs={12}>
          <EnhancedTable
            data={brands}
            total={totalBrands}
            fieldList={fieldList}
            isLoading={pending}
            page={table.state.page}
            rowsPerPage={table.state.perPage}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 500]}
            onChangePage={table.actions.changePage}
            onChangeRowsPerPage={table.actions.changePerPage}
            actions={[
              {
                callback: canEdit ? handleDeleteClick : undefined,
                label: 'Delete',
                icon: <StyledIcon iconType="delete" />,
              },
            ]}
          />
        </Grid>
      </StyledContainer>
    </div>
  )
}

export default ProtectedBrandsPage
