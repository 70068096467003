import { useEffect, useState } from 'react'
import order from 'lodash/fp/orderBy'

import StyledIcon from 'components/common/StyledIcon'

import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import useTable from 'components/common/EnhancedTable/useTable'
import TableSpacer from 'components/common/TableSpacer'

import { getNamesForType } from 'services/legalizations'
import { Direction } from 'services/pageableHelper'

import { BRAND, ITEM_SUBTYPE, ITEM_TYPE } from 'types/Attribute'
import { Legalization } from 'types/Legalization'

interface Props {
  isPending: boolean
  legalizations: Legalization[]
  orderBy: string
  perPage: number
  fieldList: EnhancedTableFieldType<Legalization>[]
  deleteFunction?: (element: Legalization) => void
  editFunction?: (element: Legalization) => void
}

export interface AllowedListRow {
  id: string
  brands: string
  subtypes: string
  itemTypes: string
}

const getRows = (
  legalizations: Legalization[],
  orderBy?: string,
  direction?: Direction,
): AllowedListRow[] => {
  const allowedListRows = legalizations
    ? legalizations.map((legalization: Legalization) => {
        return {
          id: legalization.id!,
          brands: getNamesForType(legalization, BRAND).join(', '),
          subtypes: getNamesForType(legalization, ITEM_SUBTYPE).join(', '),
          itemTypes: getNamesForType(legalization, ITEM_TYPE).join(', '),
        }
      })
    : []

  if (orderBy) {
    return order(orderBy, direction || 'asc', allowedListRows)
  } else {
    return allowedListRows
  }
}

export const LegalizationTable = (props: Props) => {
  const { orderBy, perPage, fieldList, legalizations } = props
  const [rows, setRows] = useState<AllowedListRow[]>([])

  const { table } = useTable({
    direction: Direction.ASC,
    orderBy,
    page: 0,
    perPage,
  })

  useEffect(() => {
    setRows(getRows(legalizations, table.state.orderBy, table.state.direction))
  }, [legalizations, table.state])

  const getPage = () => {
    const begin = table.state.page * table.state.perPage
    const end = begin + table.state.perPage

    return rows.slice(begin, end)
  }

  const handleEdit = (element: AllowedListRow) => () => {
    const { legalizations } = props
    if (props.editFunction) {
      const legalization = legalizations.find(
        (legalization) => legalization.id === element.id,
      )
      if (legalization) {
        props.editFunction(legalization)
      }
    }
  }

  const handleDelete = (element: AllowedListRow) => () => {
    const { legalizations } = props
    if (props.deleteFunction) {
      const legalization = legalizations.find(
        (legalization) => legalization.id === element.id,
      )
      if (legalization) {
        props.deleteFunction(legalization)
      }
    }
  }

  const { isPending, editFunction, deleteFunction } = props

  const editButtonFunction = editFunction ? handleEdit : undefined
  const deleteButtonFunction = deleteFunction ? handleDelete : undefined

  const allowedListPage = getPage()

  return (
    <TableSpacer>
      <EnhancedTable
        total={rows.length}
        onChangePage={table.actions.changePage}
        onChangeRowsPerPage={table.actions.changePerPage}
        onRequestSort={table.actions.sort}
        orderBy={table.state.orderBy}
        order={table.state.direction}
        page={table.state.page}
        rowsPerPage={table.state.perPage}
        fieldList={fieldList}
        fieldListContext={legalizations}
        paginationDisabled={false}
        data={allowedListPage}
        isLoading={isPending}
        actions={[
          {
            callback: editButtonFunction,
            label: 'Edit',
            icon: <StyledIcon iconType="edit" />,
          },
          {
            callback: deleteButtonFunction,
            label: 'Delete',
            icon: <StyledIcon iconType="delete" />,
          },
        ]}
      />
    </TableSpacer>
  )
}

export default LegalizationTable
