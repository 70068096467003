import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import Grid from '@mui/material/Grid'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import Input from 'components/common/Input'

import { validationHandler, ValidationSchema } from 'services/validation'

import { currentSeller, isSellersPending } from 'store/selectors'

import { editSeller } from 'store/seller/actionCreators'

import { Validation } from 'types/Validation'

interface Props {
  isOpen: boolean
}

const validationSchema: ValidationSchema = yup.object().shape({
  taxId: yup.string().label('Tax ID').required(),
})

export const EditSellerTaxId = ({ isOpen }: Props) => {
  const seller = useSelector(currentSeller)
  const isPending = useSelector(isSellersPending)

  const dispatch = useDispatch()

  const [taxId, setTaxId] = React.useState(seller?.tax_id ?? '')
  const [validation, setValidation] = React.useState<Validation>({})

  const handleInputChange = (value: string) => {
    setTaxId(value)
  }

  const handleInputTrim = (value: string) => {
    const trimmedValue = value.trim()
    setTaxId(trimmedValue)
  }

  const handleSubmit = () => {
    const { validation: formValidation, isValid } = validationHandler(
      validationSchema,
      { taxId },
    )

    setValidation(formValidation)

    if (isValid && taxId && seller) {
      const updatedSeller = {
        ...seller,
        tax_id: taxId,
      }

      dispatch(editSeller(updatedSeller))
    }
  }

  return (
    <DialogContainer
      data-testid="edit-source-dialog"
      isOpen={isOpen}
      title="Edit EIN"
      subtitle="Include dashes"
      isPending={isPending}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input
            isRequired
            id="tax-id"
            name="taxId"
            label="Employer Identification Number (EIN)"
            isDisabled={isPending}
            value={taxId}
            onChange={handleInputChange}
            onBlur={handleInputTrim}
            validation={validation}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export default EditSellerTaxId
