import IconButton from '@mui/material/IconButton'
import DownloadIcon from '@mui/icons-material/Download'
import Tooltip from '@mui/material/Tooltip'

import { saveReportAsFile } from 'services/reports'

import { Report } from 'types/Report'

export interface Props {
  text: string
  report: Report
  title: string
  hasDateRange: boolean
  isDisabled: boolean
  sellerName?: string
}

export const DownloadReportButton = ({
  report,
  title,
  hasDateRange,
  isDisabled,
  sellerName,
}: Props) => {
  const tooltipTitle = isDisabled
    ? 'You do not have access to this report'
    : 'Download report'

  return (
    <div style={{ margin: 0, width: 'min-content' }}>
      <Tooltip
        data-testid="download-button"
        title={tooltipTitle}
        placement="bottom-start"
      >
        <div>
          <IconButton
            size="small"
            color="primary"
            onClick={() =>
              saveReportAsFile({
                report,
                title,
                hasDateRange,
                sellerName,
              })
            }
            title="Download report"
            disabled={isDisabled}
            data-testid="download-icon-button"
          >
            <DownloadIcon color={isDisabled ? 'disabled' : 'primary'} />
          </IconButton>
        </div>
      </Tooltip>
    </div>
  )
}
export default DownloadReportButton
