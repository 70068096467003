import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { useQuery } from '@tanstack/react-query'

import { currentSellerId } from 'store/selectors'
import { getSellerShippingsLabels } from 'services/orders'
import TargetPlus from 'components/common/Icons/TargetPlus'

const StyledLogo = styled(TargetPlus)(({ theme }) => ({
  width: 25,
  verticalAlign: 'middle',
  margin: theme.spacing(0, 0.5, 0, 0),
}))
const StyledDivider = styled.span({
  marginLeft: 10,
  marginRight: 10,
})
interface Props {
  trackingNumber: string
  orderId: string
}

const BuyShippingLabel = ({ trackingNumber, orderId }: Props) => {
  const sellerId = useSelector(currentSellerId) ?? ''
  const { data, isLoading } = useQuery(
    ['SELER_SHIPPING_LABELS', sellerId, trackingNumber, orderId],
    () => getSellerShippingsLabels(sellerId, trackingNumber, orderId),
    {
      enabled: Boolean(sellerId && trackingNumber && orderId),
    },
  )

  if (isLoading) {
    return null
  }

  return (
    <>
      {data?.length ? (
        <>
          <StyledDivider>|</StyledDivider>
          <StyledLogo />
          <span>Buy Shipping</span>
        </>
      ) : null}
    </>
  )
}

export default BuyShippingLabel
