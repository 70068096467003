import React from 'react'

import styled from '@emotion/styled'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import { CONTENT_PADDING } from 'constants/layout'

import CommunicationHistory from './CommunicationHistory'
import { Case } from 'types/Case'

const StyledTabContainer = styled(Tabs)({
  margin: `0 -${CONTENT_PADDING}px`,
  padding: `0 ${CONTENT_PADDING}px`,
})

const StyledTabContent = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(3),
}))

enum CasesTab {
  COMMUNICATION_HISTORY,
}
export interface Props {
  sellerId: string
  selectedCase: Case | undefined
}

export const OpenCasesDetailTabs = ({ sellerId, selectedCase }: Props) => {
  const [tabValue, setTabValue] = React.useState(0)
  const handleChange = (_event: any, newValue: number) => setTabValue(newValue)

  return (
    <React.Fragment>
      <StyledTabContainer
        value={tabValue}
        onChange={handleChange}
        indicatorColor="primary"
        data-testid="case-detail-tabs"
      >
        <Tab label="Communication History" />
      </StyledTabContainer>
      <StyledTabContent>
        {tabValue === CasesTab.COMMUNICATION_HISTORY && (
          <CommunicationHistory
            sellerId={sellerId}
            selectedCase={selectedCase}
          />
        )}
      </StyledTabContent>
    </React.Fragment>
  )
}

export default OpenCasesDetailTabs
