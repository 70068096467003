import { createContext } from 'react'
import { Shipment } from 'types/Orders'

interface OrderShipmentsContextType {
  orderShipments: Shipment[]
  reloadShipments: () => void
}

export const OrderShipmentsContext = createContext(
  {} as OrderShipmentsContextType,
)
