import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { FormControlLabel } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'

import styled from '@emotion/styled'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import Text from 'components/common/Text'

import { GenerateReportRequest, submitReport } from 'services/reports'

import { closeDialog } from 'store/dialog/actionCreator'

import { Report, ReportType } from 'types/Report'
import { SupportCode } from 'types/SupportCode'
import { Role } from 'types/SellerUser'

const StyledBlock = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

export type Props = {
  isOpen: boolean
  reportType: ReportType
  onRequestSubmit: (report: Report) => void
}

interface CheckedItem<T> {
  checked: boolean
  code: T
}

type Access = CheckedItem<Role>
type Responsibility = CheckedItem<SupportCode>

type PortalAccessLevel = {
  admin: Access
  read: Access
  none: Access
}

type UserResponsibility = {
  customerSupport: Responsibility
  salesBusiness: Responsibility
  shippingFulfillment: Responsibility
  fiananceAccounting: Responsibility
  itemDataSetup: Responsibility
  reverseLogistics: Responsibility
}

export const PartnerContactReportDialog: React.FC<Props> = ({
  isOpen,
  reportType,
  onRequestSubmit,
}) => {
  const reduxDispatch = useDispatch()
  const [access, setAccess] = useState<PortalAccessLevel>({
    admin: {
      checked: false,
      code: Role.ADMIN,
    },
    read: {
      checked: false,
      code: Role.READ,
    },
    none: {
      checked: false,
      code: Role.NONE,
    },
  })

  const [responsibility, setResponsibility] = useState<UserResponsibility>({
    customerSupport: {
      checked: false,
      code: SupportCode.CUSTOMER_SUPPORT,
    },
    salesBusiness: {
      checked: false,
      code: SupportCode.SALES_BUSINESS,
    },
    shippingFulfillment: {
      checked: false,
      code: SupportCode.SHIPPING_AND_FULLFILMENT,
    },
    fiananceAccounting: {
      checked: false,
      code: SupportCode.FINANCE_ACCOUNTING,
    },
    itemDataSetup: {
      checked: false,
      code: SupportCode.ITEM_DATA_SETUP,
    },
    reverseLogistics: {
      checked: false,
      code: SupportCode.REVERSE_LOGISTICS,
    },
  })

  const handlePortalAccessChange =
    (property: keyof PortalAccessLevel) => () => {
      setAccess((prevProps) => {
        const item = prevProps[property]
        return {
          ...prevProps,
          [property]: {
            ...item,
            checked: !item.checked,
          },
        }
      })
    }

  const handleUserResponsibilityChange =
    (property: keyof UserResponsibility) => () => {
      setResponsibility((prevProps) => {
        const item = prevProps[property]
        return {
          ...prevProps,
          [property]: {
            ...item,
            checked: !item.checked,
          },
        }
      })
    }

  const handleSubmit = async () => {
    const roles = Object.keys(access).flatMap((key) => {
      const item = access[key as keyof PortalAccessLevel]

      return item.checked === true ? item.code : []
    })

    const codes = Object.keys(responsibility).flatMap((key) => {
      const item = responsibility[key as keyof UserResponsibility]

      return item.checked === true ? item.code : []
    })

    const reportConfig: GenerateReportRequest = {
      type: reportType,
      format: 'EXCEL',
    }

    if (roles.length || codes.length) {
      reportConfig.parameters = {}

      if (roles.length) {
        reportConfig.parameters.roles = roles
      }

      if (codes.length) {
        reportConfig.parameters.functional_responsibilities = codes
      }
    }

    const report = await submitReport(reportConfig)

    onRequestSubmit(report)

    reduxDispatch(closeDialog())
  }

  return (
    <DialogContainer
      title="Generate Partner Contacts Report"
      isOpen={isOpen}
      onSubmit={handleSubmit}
      submitButtonText="Generate"
      disableScroll
    >
      <StyledBlock container>
        <Grid item xs={12}>
          <Text type="bodySm">Filter Report by Portal Access Level</Text>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                id="ADMIN"
                data-testid="ADMIN"
                checked={access.admin.checked}
                onChange={handlePortalAccessChange('admin')}
                inputProps={{
                  'aria-label': 'administrator',
                }}
              />
            }
            label="Administrator"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                id="READ"
                data-testid="READ"
                checked={access.read.checked}
                onChange={handlePortalAccessChange('read')}
                inputProps={{
                  'aria-label': 'read',
                }}
              />
            }
            label="Read-Only"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                id="NONE"
                data-testid="NONE"
                checked={access.none.checked}
                onChange={handlePortalAccessChange('none')}
                inputProps={{
                  'aria-label': 'none',
                }}
              />
            }
            label="None (Contact)"
          />
        </Grid>
      </StyledBlock>
      <Grid container>
        <Grid item xs={12}>
          <Text type="bodySm">
            Filter Report by User/Contact Responsibilities
          </Text>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                id="customerSupport"
                data-testid="customerSupport"
                checked={responsibility.customerSupport.checked}
                onChange={handleUserResponsibilityChange('customerSupport')}
                inputProps={{
                  'aria-label': 'customerSupport',
                }}
              />
            }
            label="Customer Support"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                id="salesManagement"
                data-testid="salesManagement"
                checked={responsibility.salesBusiness.checked}
                onChange={handleUserResponsibilityChange('salesBusiness')}
                inputProps={{
                  'aria-label': 'salesManagement',
                }}
              />
            }
            label="Sales Rep / Business Management"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                id="shippingFulfillment"
                data-testid="shippingFulfillment"
                checked={responsibility.shippingFulfillment.checked}
                onChange={handleUserResponsibilityChange('shippingFulfillment')}
                inputProps={{
                  'aria-label': 'shippingFulfillment',
                }}
              />
            }
            label="Shipping & Fulfillment"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                id="fiananceAccounting"
                data-testid="fiananceAccounting"
                checked={responsibility.fiananceAccounting.checked}
                onChange={handleUserResponsibilityChange('fiananceAccounting')}
                inputProps={{
                  'aria-label': 'fiananceAccounting',
                }}
              />
            }
            label="Finance & Accounting"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                id="itemDataSetup"
                data-testid="itemDataSetup"
                checked={responsibility.itemDataSetup.checked}
                onChange={handleUserResponsibilityChange('itemDataSetup')}
                inputProps={{
                  'aria-label': 'itemDataSetup',
                }}
              />
            }
            label="Item Data & Setup"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                id="reverseLogistics"
                data-testid="reverseLogistics"
                checked={responsibility.reverseLogistics.checked}
                onChange={handleUserResponsibilityChange('reverseLogistics')}
                inputProps={{
                  'aria-label': 'reverseLogistics',
                }}
              />
            }
            label="Reverse Logistics"
          />
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export default PartnerContactReportDialog
