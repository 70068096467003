import { useEffect, useState, SyntheticEvent } from 'react'

import InputLabel from '@mui/material/InputLabel'

import { getFilteredAttributeValues } from 'services/itemTaxonomies'

import { AttributeValue } from 'types/AttributeValue'
import { STARTS_WITH } from 'types/TaxonomySearchParams'
import { Validation } from 'types/Validation'

import Typeahead from '.'
import usePrevious from 'hooks/usePrevious'

interface Props {
  attributeId: string
  placeholder?: string
  onChange: (value: Nullable<AttributeValue>, enteredValue: string) => void
  onClear?: () => void
  isDisabled?: boolean
  value?: Nullable<AttributeValue>
  clearOnSelect?: boolean
  name?: string
  validation?: Validation
  label?: string
}

const AttributeValueTypeahead = ({
  placeholder = 'Search Attribute Values',
  attributeId,
  onChange,
  onClear,
  isDisabled,
  value: valueFromProps,
  clearOnSelect = false,
  label,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedValue, setSelectedValue] =
    useState<Nullable<AttributeValue>>(null)
  const [options, setOptions] = useState<AttributeValue[]>([])
  const [loading, setLoading] = useState(false)

  const prevValue = usePrevious(valueFromProps)
  useEffect(() => {
    if (prevValue !== valueFromProps) {
      setSelectedValue(valueFromProps)
    }

    if (!valueFromProps) {
      setOptions([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevValue, valueFromProps])

  useEffect(() => {
    if (options.length && !inputValue) {
      setOptions([])
    }
  }, [inputValue, options])

  const handleInputChange = async (
    _event: SyntheticEvent,
    enteredValue: string,
    reason: string,
  ) => {
    if (
      onClear &&
      (reason === 'clear' ||
        (selectedValue && reason === 'input' && enteredValue === ''))
    ) {
      onClear()
      setOptions([])
      setInputValue('')
      setSelectedValue(null)
      return
    }

    setInputValue(enteredValue)

    const invalidInputValue = !enteredValue || enteredValue.length < 3
    if (invalidInputValue || reason === 'reset') {
      return
    }

    if (!enteredValue || !attributeId) {
      return Promise.resolve([])
    }

    setLoading(true)
    const response = await getFilteredAttributeValues(
      { page: 0, perPage: 10 },
      {
        searchVal: enteredValue,
        attributeId,
        nameSearchType: STARTS_WITH,
      },
    )

    const data = response.data
    setOptions(data)
    setLoading(false)
  }

  const handleSelectedOptionChange = (
    _event: SyntheticEvent,
    value: AttributeValue,
  ) => {
    if (!(value instanceof Array)) {
      const searchParamValue = getOptionLabel(value as AttributeValue)
      setSelectedValue(
        clearOnSelect ? null : (value as Nullable<AttributeValue>),
      )
      onChange(value as Nullable<AttributeValue>, searchParamValue)
    }
  }

  const getOptionLabel = (attributeValue: AttributeValue) =>
    attributeValue?.name ?? ''

  return (
    <>
      {label && <InputLabel shrink>{label}</InputLabel>}
      <Typeahead
        aria-label="Attribute Value Filter"
        label={placeholder}
        placeholder={placeholder}
        options={options}
        loading={loading}
        getOptionLabel={getOptionLabel}
        onInputChange={handleInputChange}
        onChange={handleSelectedOptionChange}
        isOptionEqualToValue={(
          option: AttributeValue,
          value: Nullable<AttributeValue>,
        ) => option.id === value?.id}
        disabled={!!isDisabled}
        inputValue={inputValue}
        value={selectedValue}
      />
    </>
  )
}

export default AttributeValueTypeahead
