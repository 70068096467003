import React from 'react'
import Grid from '@mui/material/Grid'

import OrderItem from './OrderItem'
import Heading from 'components/common/Heading'

import { OrderLine } from 'types/Orders'

interface Props {
  orderLines: OrderLine[]
}

export const OrderLines = ({ orderLines }: Props) => {
  return (
    <React.Fragment>
      {orderLines.map((line, index) => {
        return (
          <Grid container key={index} spacing={2}>
            <Grid item xs={12}>
              <Heading
                title={`Line ${line.order_line_number}`}
                variant="h3"
                component="h2"
              />
            </Grid>
            <Grid item xs={12}>
              <OrderItem key={index} orderItem={line} />
            </Grid>
          </Grid>
        )
      })}
    </React.Fragment>
  )
}

export default OrderLines
