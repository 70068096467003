import { InputLabel } from '@mui/material'
import usePrevious from 'hooks/usePrevious'
import { SyntheticEvent, useEffect, useState } from 'react'
import { Direction } from 'services/pageableHelper'
import { searchProductReturns } from 'services/productReturns'
import { Return, ReturnSearchParams } from 'types/Orders'
import Typeahead from '.'

export interface Props {
  sellerId: string | undefined
  onChange: (value: Nullable<Return>, enteredValue: string) => void
  placeholder?: string
  label?: string
  clearOnSelect?: boolean
  value?: Nullable<Return>
  onClear?: () => void
  perPage?: number
}

const ProductReturnTypeahead = ({
  sellerId,
  onChange,
  placeholder,
  label,
  clearOnSelect,
  value: valueFromProps,
  onClear,
  perPage,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedValue, setSelectedValue] =
    useState<Nullable<Return>>(valueFromProps)
  const [options, setOptions] = useState<Return[]>([])
  const [loading, setLoading] = useState(false)

  const prevValue = usePrevious(valueFromProps)

  useEffect(() => {
    if (prevValue !== valueFromProps) {
      setSelectedValue(valueFromProps)
    }

    if (!valueFromProps) {
      setOptions([])
    }
  }, [prevValue, valueFromProps])

  useEffect(() => {
    if (options.length && !inputValue) {
      setOptions([])
    }
  }, [inputValue, options])

  const handleInputChange = async (
    _event: SyntheticEvent,
    enteredValue: string,
    reason: string,
  ) => {
    if (
      onClear &&
      (reason === 'clear' ||
        (selectedValue && reason === 'input' && enteredValue === ''))
    ) {
      onClear()
      setOptions([])
      setInputValue('')
      setSelectedValue(null)
      return
    }

    setInputValue(enteredValue)

    const invalidInputValue =
      !enteredValue || enteredValue.length < 10 || reason === 'reset'
    if (invalidInputValue) {
      return
    }

    const paging = {
      page: 0,
      perPage: perPage ? perPage : 20,
      direction: Direction.ASC,
    }

    const params: ReturnSearchParams = {
      seller_id: sellerId,
      return_order_number: enteredValue,
    }

    setLoading(true)
    const { data } = await searchProductReturns(paging, params)
    setOptions(data)
    setLoading(false)
  }

  const handleSelectedOptionChange = (
    _event: SyntheticEvent,
    value: Return,
  ) => {
    if (!(value instanceof Array)) {
      const searchParamValue = getOptionLabel(value as Return)
      setSelectedValue(clearOnSelect ? null : (value as Nullable<Return>))
      onChange(value as Nullable<Return>, searchParamValue)
    }
  }

  const getOptionLabel = (item: Return) => {
    return item?.return_order_number ?? ''
  }

  return (
    <div data-testid="product-return-typeahead">
      {label && <InputLabel shrink>{label}</InputLabel>}
      <Typeahead
        aria-label="Product Return Filter"
        placeholder={placeholder}
        getOptionLabel={getOptionLabel}
        options={options}
        loading={loading}
        onInputChange={handleInputChange}
        onChange={handleSelectedOptionChange}
        inputValue={inputValue}
        value={selectedValue}
        isOptionEqualToValue={(option: Return, value: Nullable<Return>) =>
          option.id === value?.id
        }
        renderOption={(props: any, returnOrder: Return) => {
          return (
            <li {...props} key={returnOrder.id}>
              {returnOrder.return_order_number ?? ''}
            </li>
          )
        }}
      />
    </div>
  )
}

export default ProductReturnTypeahead
