import { useState } from 'react'
import { useSelector } from 'react-redux'

import styled from '@emotion/styled'

import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import DialogContainer from 'components/common/Dialog/DialogContainer'

import { success } from 'config/themeConfig'

import { deleteReturnPolicy } from 'services/returnPoliciesService'

import { currentSellerId } from 'store/selectors'

import { SmsReturnPolicy } from 'types/Seller'

const StyledDescription = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}))

const StyledTitle = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}))

const StyledFlexContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
}))

const StyledLoadingContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
}))

const StyledCheckCircleIcon = styled(CheckCircleIcon)({ color: success.main })

export interface Props {
  isOpen: boolean
  returnPolicy: SmsReturnPolicy
}

enum LoadingState {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
}

const getLoadingState = (success: boolean, pending: boolean): LoadingState => {
  if (success) {
    return LoadingState.SUCCESS
  } else if (pending) {
    return LoadingState.PENDING
  }
  return LoadingState.IDLE
}

export const DeactivateReturnPolicy = ({ isOpen, returnPolicy }: Props) => {
  const sellerId = useSelector(currentSellerId)
  const [isPending, setIsPending] = useState(false)
  const [success, setSuccess] = useState(false)

  const loadingState = getLoadingState(success, isPending)

  const getTitle = ({
    loadingState,
    vmmId,
  }: {
    loadingState: LoadingState
    vmmId?: string
  }) => {
    if (loadingState === LoadingState.SUCCESS) {
      return (
        <StyledFlexContainer>
          <StyledCheckCircleIcon />
          <StyledTitle>
            Success! Return Policy {returnPolicy.vmm_id} Deactivated
          </StyledTitle>
        </StyledFlexContainer>
      )
    } else if (loadingState === LoadingState.PENDING) {
      return null
    } else {
      return `Deactivate Return Policy ${vmmId}`
    }
  }

  const handleSubmit = () => {
    setIsPending(true)

    if (sellerId && returnPolicy.id) {
      deleteReturnPolicy(sellerId, returnPolicy.id!).then(() => {
        setSuccess(true)
        setIsPending(false)
      })
    }
  }

  const title = getTitle({ loadingState, vmmId: returnPolicy.vmm_id })

  return (
    <DialogContainer
      data-testid="deactivate-return-dialog"
      isOpen={isOpen}
      isPending={isPending}
      onSubmit={handleSubmit}
      title={title}
      submitButtonText="Deactivate"
      hideActions={loadingState !== LoadingState.IDLE}
      autoClose={loadingState === LoadingState.SUCCESS}
    >
      {loadingState === LoadingState.PENDING && (
        <StyledLoadingContainer>
          <CircularProgress />
        </StyledLoadingContainer>
      )}
      {loadingState === LoadingState.IDLE && (
        <>
          <StyledDescription>
            Are you sure you would like to deactivate this policy? You can’t
            re-activate after deactivation.
          </StyledDescription>
          <Typography>
            When this return policy is deactivated, you will not be able to
            apply this policy ID to new versions of items. All items with this
            policy will need to be updated with a different return policy.
          </Typography>
        </>
      )}
    </DialogContainer>
  )
}

export default DeactivateReturnPolicy
