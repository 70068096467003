export type DiversityCertification = {
  agency?: string
  cert_number: string
  expiration_date: string
}

export type DiversityAnswer = {
  diversity_question: DiversityQuestion
  answer: boolean
  ethnicity?: string
  certification?: DiversityCertification
  has_certification?: boolean
}

export enum DiversityRadio {
  YES = 'YES',
  NO = 'NO',
}

export type DiversitySelection = DiversityRadio | string

export enum DiversityQuestion {
  WOMAN = 'WOMAN',
  WOMAN_OTHER = 'WOMAN_OTHER',
  BIPOC = 'BIPOC',
  LGBTQ = 'LGBTQ',
  VETERAN = 'VETERAN',
  SERVICE_DISABLED_VET = 'SERVICE_DISABLED_VET',
  SMALL_BUSINESS = 'SMALL_BUSINESS',
  SMALL_DISADVANTAGED_BUSINESS = 'SMALL_DISADVANTAGED_BUSINESS',
  DISADVANTAGED_BUSINESS_ENTERPRISE = 'DISADVANTAGED_BUSINESS_ENTERPRISE',
  DIFFERENTLY_ABLED = 'DIFFERENTLY_ABLED',
  HUB_ZONE = 'HUB_ZONE',
  ALASKAN_NATIVE = 'ALASKAN_NATIVE',
}

export enum DiversityCertType {
  NMSDC = 'NMSDC',
  WBENC = 'WBENC',
  OTHER_AGENCY = 'OTHER_AGENCY',
  NGLCC = 'NGLCC',
  DD214 = 'DD214',
  VA_LETTER = 'VA_LETTER',
  USBLN = 'USBLN',
  SBD = 'SBD',
  DBE = 'DBE',
  HUB_ZONE_GOV_CERT = 'HUB_ZONE_GOV_CERT',
  ALASKAN_NATIVE_GOV_CERT = 'ALASKAN_NATIVE_GOV_CERT',
}

export type DiversitySurveyPageOne = {
  WOMAN: DiversitySelection
  WOMAN_CERT: string
  WOMAN_CERT_DATE: string
  WOMAN_OTHER: string
  WOMAN_OTHER_CERT: string
  WOMAN_OTHER_CERT_DATE: string
  BIPOC: DiversitySelection
  BIPOC_ETHNICITY: string
  BIPOC_CERT: string
  BIPOC_CERT_DATE: string
  LGBTQ: DiversitySelection
  LGBTQ_CERT: string
  LGBTQ_CERT_DATE: string
}

export type DiversitySurveyPageTwo = {
  VETERAN: DiversitySelection
  VETERAN_CERT: DiversitySelection
  SERVICE_DISABLED_VET: DiversitySelection
  SERVICE_DISABLED_VET_CERT: DiversitySelection
  SMALL_BUSINESS: DiversitySelection
  SMALL_DISADVANTAGED_BUSINESS: DiversitySelection
  SMALL_DISADVANTAGED_BUSINESS_CERT: string
  SMALL_DISADVANTAGED_BUSINESS_CERT_DATE: string
  DISADVANTAGED_BUSINESS_ENTERPRISE: DiversitySelection
  DISADVANTAGED_BUSINESS_ENTERPRISE_CERT: string
  DISADVANTAGED_BUSINESS_ENTERPRISE_CERT_DATE: string
}

export type DiversitySurveyPageThree = {
  DIFFERENTLY_ABLED: DiversitySelection
  DIFFERENTLY_ABLED_CERT: string
  DIFFERENTLY_ABLED_CERT_DATE: string
  HUB_ZONE: DiversitySelection
  HUB_ZONE_CERT: string
  HUB_ZONE_CERT_DATE: string
  ALASKAN_NATIVE: DiversitySelection
  ALASKAN_NATIVE_CERT: string
  ALASKAN_NATIVE_CERT_DATE: string
}

export type DiversitySurveyPages = DiversitySurveyPageOne &
  DiversitySurveyPageTwo &
  DiversitySurveyPageThree
