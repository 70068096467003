import React from 'react'
import getOr from 'lodash/fp/getOr'

import styled from '@emotion/styled'

import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'

import Select from 'components/common/Select'
import TimePicker from 'components/common/TimePicker'
import ValidationErrorText from 'components/common/ValidationErrorText'

import { SLA_EXPRESS, SLA_STANDARD } from 'constants/categories'
import { Code, ShippingService } from 'types/Seller'

import { SlaOption } from './useCarrierInfo'
import { getMaximumRolloverDays } from 'services/shippingMethod'
import FormControlLabel from '@mui/material/FormControlLabel'

import { convert12To24Hour, convert24To12Hour } from 'services/time'

const StyledCheckbox = styled(Checkbox)({ justifyContent: 'flex-start' })

const StyledDropdown = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}))

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  marginBottom: theme.spacing(-1),
}))

const daysToAddOptions = [
  { name: 'None', value: undefined },
  { name: '1 Day', value: 1 },
  { name: '2 Days', value: 2 },
  { name: '3 Days', value: 3 },
  { name: '4 Days', value: 4 },
  { name: '5 Days', value: 5 },
  { name: '6 Days', value: 6 },
  { name: '7 Days', value: 7 },
  { name: '8 Days', value: 8 },
  { name: '9 Days', value: 9 },
  { name: '10 Days', value: 10 },
]

export interface Props {
  shippingService?: ShippingService
  option: SlaOption
  onChange: (id: number, property: string, value: string) => void
  selectChange: (serviceLevel: ShippingService, checked: boolean) => void
  isPending: boolean
  buildingLeadTimeOptions: Array<Partial<Code>>
  timezone: string
  isExternalUser: boolean
  isExpress?: boolean
  hasRolloverEdit: boolean
}

const defaultTimeInTransit: Record<string, number> = {
  [SLA_EXPRESS]: 1,
  [SLA_STANDARD]: 5,
}

const getMaxDaysToAddOptions = (daysToAdd: number | undefined) => {
  if (!daysToAdd) return []

  const limit = 10

  const options: Array<{ name: string; value: number | undefined }> = [
    { name: 'None', value: undefined },
  ]

  for (let i = daysToAdd; i <= limit; i++) {
    const rollover = getMaximumRolloverDays(i, daysToAdd)
    const dayText = i === 1 ? 'Day' : 'Days'
    const rolloverText = rollover === 1 ? 'Rollover' : 'Rollovers'
    const name = `${i} ${dayText} (${rollover} ${rolloverText})`

    options.push({ name, value: i })
  }

  return options
}

export const EditShipping = ({
  onChange,
  selectChange,
  option,
  shippingService,
  isPending,
  buildingLeadTimeOptions,
  timezone,
  isExternalUser,
  isExpress = false,
  hasRolloverEdit,
}: Props) => {
  const handleInputChange = (value: any, property: string) => {
    onChange(option.id, property, value)
  }

  const handleTimeChange = ({
    value,
    name,
  }: {
    value: string
    name: string
  }) => {
    const valueIn24Hour = convert12To24Hour(value)

    handleInputChange(valueIn24Hour, name)
  }

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const checked = event.currentTarget.checked
    selectChange(
      {
        id: option.id,
        cut_off_time: cutOffTime,
        building_lead_time_id: buildingLeadTimeId,
        generic_time_in_transit: genericTimeInTransit,
      },
      checked,
    )
  }

  const { isSelected, name, validation, timeInTransitOptions } = option

  const cutOffTime = getOr('', 'cut_off_time', shippingService)
  const buildingLeadTimeId = getOr(
    undefined,
    'building_lead_time_id',
    shippingService,
  )
  const genericTimeInTransit = getOr(
    defaultTimeInTransit[name],
    'generic_time_in_transit',
    shippingService,
  )
  const daysToAdd = getOr(undefined, 'days_to_add', shippingService)
  const maxDaysToAdd = getOr(undefined, 'max_days_to_add', shippingService)

  return (
    <Grid container spacing={2} data-testid={`shipping-service-${name}`}>
      <Grid item xs={2}>
        <FormControlLabel
          control={
            <StyledCheckbox
              data-testid="shipping-service-checkbox"
              checked={isSelected}
              disableRipple
              onChange={handleCheckboxChange}
              disabled={isPending}
            />
          }
          label={name}
        />
      </Grid>
      <Grid item xs={10}>
        {isSelected && (
          <StyledDropdown container spacing={2}>
            <Grid
              item
              xs={4}
              md={2}
              sx={{ alignSelf: isExpress ? 'center' : undefined }}
            >
              {isExpress ? (
                <>
                  {genericTimeInTransit} Day
                  {genericTimeInTransit! > 1 ? 's' : ''}
                </>
              ) : (
                <>
                  <Select
                    isRequired
                    id={`generic-time-in-transit-${name}`}
                    name="genericTimeInTransit"
                    label="Time in Transit"
                    onChange={handleInputChange}
                    options={timeInTransitOptions}
                    value={genericTimeInTransit}
                    isDisabled={!isSelected || isPending || isExternalUser}
                    validation={validation}
                  />
                  {validation && (
                    <ValidationErrorText
                      errors={validation}
                      field="generic_time_in_transit"
                    />
                  )}
                </>
              )}
            </Grid>
            <Grid item xs={4} md={2.5}>
              <StyledInputLabel
                variant="standard"
                shrink
                htmlFor="cut-off-time"
              >
                Site Cutoff Time ({timezone})
              </StyledInputLabel>
              <TimePicker
                id="cut-off-time"
                isRequired
                name="cut_off_time"
                value={
                  cutOffTime ? convert24To12Hour(cutOffTime).time : undefined
                }
                noLabel
                onChange={handleTimeChange}
                period={
                  cutOffTime ? convert24To12Hour(cutOffTime).period : 'AM'
                }
                validation={validation}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <Select
                id={`building-lead-time-${name}`}
                name="buildingLeadTimeId"
                label="Building Lead Time"
                onChange={handleInputChange}
                options={buildingLeadTimeOptions}
                keyName="code_name"
                valueName="code_id"
                value={buildingLeadTimeId}
                isDisabled={!isSelected || isPending || isExternalUser}
                validation={validation}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <Select
                id={`days-to-add-${name}`}
                name="daysToAdd"
                label="Rollover Days"
                onChange={handleInputChange}
                options={daysToAddOptions}
                keyName="name"
                valueName="value"
                value={daysToAdd}
                isDisabled={!isSelected || isPending || !hasRolloverEdit}
                validation={validation}
                tooltip="If order capacity per day is exceeded, rollover is used to adjust BLT to manage guest delivery expectations"
              />
            </Grid>
            <Grid item xs={4} md={3}>
              <Select
                id={`max-days-to-add-${name}`}
                name="maxDaysToAdd"
                label="Max Rollover Days"
                onChange={handleInputChange}
                options={getMaxDaysToAddOptions(daysToAdd)}
                keyName="name"
                valueName="value"
                value={maxDaysToAdd}
                isDisabled={!isSelected || isPending || !hasRolloverEdit}
                validation={validation}
                tooltip="To ensure the best guest experience, BLT cannot be system adjusted beyond this limit"
              />
            </Grid>
          </StyledDropdown>
        )}
      </Grid>
    </Grid>
  )
}

export default EditShipping
