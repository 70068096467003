import axios from 'axios'

import { PagingParams, getPageable } from 'services/pageableHelper'
import { getProductCountForQuotas } from 'services/productCounts'
import { getNodesById } from './itemTaxonomies'

import apiConfig from 'config/apiConfig'

import { CollectionResponse } from 'types/Response'
import { ProductQuota } from 'types/ProductQuota'

export const getProductQuotas = async (
  pagingParams: PagingParams,
): Promise<CollectionResponse<ProductQuota>> => {
  const pageable = getPageable(pagingParams)

  const config = {
    params: pageable,
  }

  const response = await axios.get(`${apiConfig.sms}/product_quotas`, config)
  const { data, headers } = response

  const total = headers['x-total-count']
    ? parseInt(headers['x-total-count'], 10)
    : 0

  return {
    data,
    total,
  }
}

export const hydrateProductQuotas = async (
  pagingParams: PagingParams,
): Promise<CollectionResponse<ProductQuota>> => {
  const { data, total } = await getProductQuotas(pagingParams)

  const taxonomyIds = data.map((item) => item.taxonomy_id)

  const taxonomy = await getNodesById(taxonomyIds)
  const counts = await getProductCountForQuotas()

  const quotasWithTaxonomyNames = data.map((item) => {
    const taxonomyName =
      taxonomy.find((node) => node.id === item.taxonomy_id)?.name ?? ''
    const currentCount = counts[item.taxonomy_id] ?? 0

    return {
      ...item,
      taxonomyName,
      currentCount,
    }
  })

  return {
    data: quotasWithTaxonomyNames,
    total,
  }
}

export async function updateProductQuota(data: ProductQuota) {
  if (data.id) {
    return axios
      .put(`${apiConfig.sms}/product_quotas/${data.id}`, data)
      .then((res) => res.data)
  }

  return axios
    .post(`${apiConfig.sms}/product_quotas`, data)
    .then((res) => res.data)
}

export const removeProductQuota = async (productQuotaId: string) => {
  return axios
    .delete(`${apiConfig.sms}/product_quotas/${productQuotaId}`)
    .then((res) => res.data)
}
