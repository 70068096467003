import startCase from 'lodash/fp/startCase'
import sortBy from 'lodash/fp/sortBy'
import flow from 'lodash/fp/flow'
import map from 'lodash/fp/map'
import groupBy from 'lodash/fp/groupBy'

import geographicalCodes from 'config/geographicalCodes.json'

import { getCountryFlag } from './getCountryFlag'

import { GeographicalCodes } from 'types/GeographicalCode'
import { Country } from 'types/Country'
import memoize from 'lodash/fp/memoize'

export const getGeographicalCodes = memoize(() => {
  const results = flow(
    map<GeographicalCodes, GeographicalCodes>((code) => {
      const formattedCountryName = startCase(code.country_name.toLowerCase())
      let flag = code.country_name

      if (code.iso_alpha_2_code) {
        flag = getCountryFlag(code.iso_alpha_2_code)
      }

      return {
        ...code,
        formattedCountryName,
        flag,
      }
    }),
    sortBy<GeographicalCodes>('country_name'),
  )(geographicalCodes)

  return results
})

export const getDefaultCountryByCode = (code: string | number): string => {
  if (code === '') return ''

  const telephoneCode = typeof code === 'string' ? parseInt(code, 10) : code

  const dict = groupBy('telephone_code', geographicalCodes)

  const telephoneCodeOne = dict[1]

  const filteredCodes = telephoneCodeOne.filter(
    (country) => country.country_name !== Country.UNITED_STATES,
  )

  const unitedStatesCodes = telephoneCodeOne.find(
    (country) => country.country_name === Country.UNITED_STATES,
  )

  if (unitedStatesCodes) {
    dict[1] = [unitedStatesCodes, ...filteredCodes]
  }

  return dict[telephoneCode][0].country_name
}

export const getCountryByCountryCode = (countryCode: string) =>
  getGeographicalCodes().find((code) => code.iso_alpha_2_code === countryCode)

export const getTelephoneCodeByCountry = (countryCode: string) => {
  let telephoneCode = ''

  const geopraphicCode = getCountryByCountryCode(countryCode)

  if (geopraphicCode) {
    telephoneCode = geopraphicCode.telephone_code.toString()
  }

  return telephoneCode
}

export const getCountryByTelephoneCode = (countryCode: string) => {
  if (countryCode === '1') {
    return Country.UNITED_STATES
  }

  let geopraphicCode = ''

  const telephoneCode = getGeographicalCodes().find(
    (code) => code.telephone_code.toString() === countryCode,
  )

  if (telephoneCode) {
    geopraphicCode = telephoneCode.country_name
  }

  return geopraphicCode
}

export const getCountryCodeByTelephoneCode = (telephoneCode: string) => {
  if (telephoneCode === '1') {
    return 'US'
  }

  let countryCode = ''

  const geopraphicCode = getGeographicalCodes().find(
    (code) => code.telephone_code.toString() === telephoneCode,
  )

  if (geopraphicCode) {
    countryCode = geopraphicCode.iso_alpha_2_code
  }

  return countryCode
}
