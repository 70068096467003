import React from 'react'
import differenceWith from 'lodash/fp/differenceWith'
import map from 'lodash/fp/map'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import { ErrorCodeTypeahead } from 'components/common/Typeahead'
import DialogContainer from 'components/common/Dialog/DialogContainer'
import { FieldErrorLineItem } from 'components/ReviewQueuePage/Dialogs/FieldErrorLineItem'

import {
  FieldError,
  getDisplayName,
  getFieldErrors,
  getProductErrors,
} from 'services/itemHelper'

import { ErrorCode, Severity } from 'types/ErrorCode'
import { ProductError, ProductField, SmsProduct } from 'types/Item'
import { Validation } from 'types/Validation'

interface Props {
  isOpen: boolean
  field?: ProductField
  existingErrors?: ProductError[]
  onSubmit: (errors: ProductError[], fieldName?: string) => void
  handleClose: () => void
  item?: SmsProduct
}

const isEqual = (error: FieldError, other: FieldError) => {
  return error.code === other.code && error.message === other.message
}

export const AddErrorCodesDialog = ({
  existingErrors,
  field,
  isOpen,
  onSubmit,
  handleClose,
  item,
}: Props) => {
  const fieldErrors = existingErrors ? getFieldErrors(existingErrors) : []

  const [errors, setErrors] = React.useState(fieldErrors)
  const [validation, setValidation] = React.useState<Validation>({})

  const removeError = (error: FieldError) => {
    setErrors(differenceWith(isEqual, errors, [error]))
  }

  const editError = (error: FieldError) => {
    const updatedErrors = map((e) => (isEqual(e, error) ? error : e), errors)
    setErrors(updatedErrors)
  }

  const addError = (value: Nullable<ErrorCode>) => {
    if (value) {
      if (errors.find((e) => e.code === value.error_code)) {
        setValidation({ add_error: ['Duplicate error code.'] })
      } else {
        setValidation({})
        const displayName = field && getDisplayName(field)

        setErrors((prevErrors: FieldError[]) => [
          ...prevErrors,
          {
            message: value.reason,
            order: 1,
            critical: value.error_severity === Severity.CRITICAL,
            code: value.error_code,
            ...(displayName ? displayName : {}),
            ...(field ? { fieldName: field.name } : {}),
          },
        ])
      }
    }
  }

  const handleSubmit = () => {
    const fieldName = field?.name
    onSubmit(getProductErrors(errors), fieldName)
  }

  return (
    <DialogContainer
      title="Add Error"
      subtitle={
        field
          ? `Field: ${getDisplayName(field)} | Raw Value: ${field.value}`
          : undefined
      }
      isOpen={isOpen}
      onSubmit={handleSubmit}
      closeOverride={handleClose}
    >
      <Box sx={{ minHeight: 300 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ErrorCodeTypeahead
              name="add_error"
              onChange={addError}
              clearOnSelect
              validation={validation}
              hideDeleted
            />
          </Grid>
          <Grid item xs={12}>
            <FieldErrorLineItem
              errors={errors}
              handleRemove={removeError}
              handleEdit={editError}
              item={item}
            />
          </Grid>
        </Grid>
      </Box>
    </DialogContainer>
  )
}

export default AddErrorCodesDialog
