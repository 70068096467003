import Link from '@mui/material/Link'

import { getFilenameFromId, saveAttachment } from 'services/cases'

interface Props {
  sellerId: string
  attachmentId: string
}

export const DocumentAttachment = ({ sellerId, attachmentId }: Props) => {
  const attachmentName = getFilenameFromId(attachmentId)

  return (
    <Link
      data-testid="document-attachment"
      onClick={() => saveAttachment(sellerId, attachmentId)}
    >
      {attachmentName}
    </Link>
  )
}

export default DocumentAttachment
