import { useEffect, useState, SyntheticEvent } from 'react'
import usePrevious from 'hooks/usePrevious'
import { getPromotionById } from 'services/promotions'
import { Promotion } from 'types/Promotion'
import Typeahead from '.'

interface Props {
  placeholder?: string
  value?: Nullable<Promotion>
  onChange: (value: Nullable<Promotion>) => void
  onClear?: () => void
  clearOnSelect?: boolean
  isDisabled?: boolean
}

const PromotionTypeahead = ({
  value: valueFromProps,
  onChange,
  onClear,
  clearOnSelect,
  isDisabled,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedValue, setSelectedValue] =
    useState<Nullable<Promotion>>(valueFromProps)
  const [options, setOptions] = useState<Promotion[]>([])
  const [loading, setLoading] = useState(false)

  const prevValue = usePrevious(valueFromProps)
  useEffect(() => {
    if (prevValue !== valueFromProps) {
      setSelectedValue(valueFromProps)
    }

    if (!valueFromProps) {
      setOptions([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevValue, valueFromProps])

  useEffect(() => {
    if (options.length && !inputValue) {
      setOptions([])
    }
  }, [inputValue, options])

  const handleInputChange = async (
    _event: SyntheticEvent,
    enteredValue: string,
    reason: string,
  ) => {
    if (
      onClear &&
      (reason === 'clear' ||
        (selectedValue && reason === 'input' && enteredValue === ''))
    ) {
      onClear()
      setOptions([])
      setInputValue('')
      setSelectedValue(null)
      return
    }

    setInputValue(enteredValue)

    const invalidInputValue =
      !enteredValue || enteredValue.length < 3 || reason === 'reset'
    if (invalidInputValue) {
      return
    }

    if (enteredValue && enteredValue.length >= 9) {
      setLoading(true)
      const data = await getPromotionById(enteredValue)
      setOptions(data)
      setLoading(false)
    }
  }

  const handleSelectedOptionChange = (
    _event: SyntheticEvent,
    value: Promotion,
  ) => {
    if (!(value instanceof Array)) {
      setSelectedValue(clearOnSelect ? null : (value as Nullable<Promotion>))
      onChange(value as Nullable<Promotion>)
    }
  }

  const getOptionLabel = (promotion: Promotion) => promotion.promotion_id

  return (
    <div data-testid="promotion-typeahead">
      <Typeahead
        aria-label="Promotion ID"
        label="Promotion ID"
        placeholder="Promotion ID"
        getOptionLabel={getOptionLabel}
        options={options}
        loading={loading}
        onInputChange={handleInputChange}
        onChange={handleSelectedOptionChange}
        isOptionEqualToValue={(option: Promotion, value: Nullable<Promotion>) =>
          option.id === value?.id
        }
        disabled={!!isDisabled}
        inputValue={inputValue}
        value={selectedValue}
      />
    </div>
  )
}

export default PromotionTypeahead
