import React from 'react'
import { Field, FieldProps } from 'formik'

import TextField, { TextFieldProps } from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

interface FormProps {
  name: string
  options: Array<object | string | number>
  keyName?: string
  valueName?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void
  changeDependentField?: (value: any) => {
    dependentField: string
    dependentValue: string
  }
  optionDisableCallback?: (item: any) => boolean
}

type Props = FormProps &
  Omit<
    TextFieldProps,
    'error' | 'name' | 'onChange' | 'value' | 'variant' | 'type'
  >

const onFieldChange =
  (
    name: string,
    setFieldValue: any,
    onChange?: (
      event: React.ChangeEvent<HTMLInputElement>,
      value: string,
    ) => void,
    changeDependentField?: (value: any) => {
      dependentField: string
      dependentValue: string
    },
  ) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setFieldValue(name, value)
    if (onChange) {
      onChange(event, value)
    }
    if (changeDependentField) {
      const { dependentField, dependentValue } = changeDependentField(value)
      setFieldValue(dependentField, dependentValue)
    }
  }

const SelectField = ({
  name,
  options,
  helperText,
  disabled,
  keyName,
  valueName,
  onChange,
  changeDependentField,
  optionDisableCallback,
  ...props
}: Props) => (
  <Field name={name}>
    {({ form, field, meta }: FieldProps) => {
      const { isSubmitting, setFieldValue } = form
      const { touched, error } = meta

      const hasError = touched && !!error

      return (
        <TextField
          {...props}
          {...field}
          id={name}
          error={hasError}
          helperText={hasError ? error : helperText}
          variant="standard"
          InputLabelProps={{ shrink: true }}
          inputProps={{ 'aria-label': name }}
          disabled={isSubmitting || disabled}
          select
          fullWidth
          onChange={onFieldChange(
            name,
            setFieldValue,
            onChange,
            changeDependentField,
          )}
        >
          {options.map((option: any) => {
            if (typeof option === 'object' && keyName && valueName) {
              return (
                <MenuItem
                  data-testid={`select-${option[valueName]}`}
                  key={`${option[keyName]}-${option[valueName]}`}
                  value={option[valueName]}
                  disabled={
                    optionDisableCallback && optionDisableCallback(option)
                  }
                >
                  {option[keyName]}
                </MenuItem>
              )
            } else {
              return (
                <MenuItem
                  data-testid={`select-${option}`}
                  key={option}
                  value={option}
                  disabled={
                    optionDisableCallback && optionDisableCallback(option)
                  }
                >
                  {option}
                </MenuItem>
              )
            }
          })}
        </TextField>
      )
    }}
  </Field>
)

export default SelectField
