import styled from '@emotion/styled'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import DataList from 'components/common/DataList'
import Stepper from 'components/common/Stepper'
import Text from 'components/common/Text'

import {
  formatDate,
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
} from 'services/dateService'

import { Case } from 'types/Case'
import DownloadAttachment from 'components/common/Attachment/DownloadAttachment'

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(2),
})) as typeof Typography

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, -3),
}))

const getStepId = (status?: string) => {
  switch (status) {
    case 'New':
      return 0
    case 'In Progress':
      return 1
    case 'Resolved':
      return 3
    default:
      return 0
  }
}

export interface Props {
  sellerId: string
  selectedCase: Case | undefined
}

export const OpenCasesDetailContent = ({ sellerId, selectedCase }: Props) => {
  if (!selectedCase) return null

  const {
    case_number,
    issue_description,
    seller_email,
    status,
    created,
    last_modified,
    seller_order_number,
    subject,
    level1,
    level2,
    attachment_ids,
  } = selectedCase

  const caseData = [
    {
      title: 'Submitted by:',
      value: seller_email,
    },
    {
      title: 'Created Date:',
      value: formatDate(created, DATE_FORMAT_MONTH_DAY_YEAR_TIME),
    },
    {
      title: 'Last Modified:',
      value: formatDate(last_modified, DATE_FORMAT_MONTH_DAY_YEAR_TIME),
    },
  ]

  return (
    <Card data-testid="case-detail-content">
      <CardContent>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item md={9}>
            <StyledTitle variant="h6" component="h2" data-testid="case-title">
              Case {case_number}
            </StyledTitle>
          </Grid>
          <Stepper
            data-testid="case-status-stepper"
            steps={['New', 'In Progress', 'Resolved']}
            currentStep={getStepId(status)}
          />
          <StyledDivider />
          {(level1 || level2) && (
            <>
              <Grid item xs={12}>
                <Text type="bold">Case Type:</Text>
                {level1 && <Typography display="inline">{level1}</Typography>}
                {level2 && (
                  <Typography display="inline"> - {level2}</Typography>
                )}
              </Grid>
              <Grid item xs={12} />
            </>
          )}
          <Grid item xs={12}>
            <Text type="bold">Subject:</Text>
            <Typography data-testid="case-subject">{subject}</Typography>
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <Text type="bold">Description:</Text>
            <Typography>{issue_description}</Typography>
          </Grid>
          <Grid item xs={12} />
          {attachment_ids && attachment_ids.length > 0 && (
            <>
              <Grid item xs={12}>
                <Text type="bold">Attachment(s):</Text>
                {attachment_ids.map((id, key) => (
                  <div key={key} data-testid={`attachment-${key}`}>
                    <DownloadAttachment sellerId={sellerId} attachmentId={id} />
                  </div>
                ))}
              </Grid>
              <Grid item xs={12} />
            </>
          )}
          <Grid item xs={12}>
            <DataList
              data={[{ title: 'Order ID:', value: seller_order_number }]}
            />
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12} data-testid="order-information">
            <DataList data={caseData} />
          </Grid>
          <Grid item xs={12} />
        </Grid>
      </CardContent>
    </Card>
  )
}

export default OpenCasesDetailContent
