import Divider from '@mui/material/Divider'
import { grey } from 'config/themeConfig'

export const NavDivider = () => {
  return <Divider sx={{ backgroundColor: grey[500] }} />
}
export const ExpandedNavDivider = () => {
  return <Divider sx={{ backgroundColor: grey[500], mt: 0.5 }} />
}
export default NavDivider
