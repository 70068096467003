import { useEffect, useState, ChangeEvent, FormEvent } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import styled from '@emotion/styled'
import { grey, white } from 'config/themeConfig'

import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import SearchIcon from '@mui/icons-material/Search'

import { RoutePath } from 'services/NavigationHelper'

const StyledContainer = styled('div')(({ theme }) => ({
  backgroundImage:
    'linear-gradient(to right, rgba(204,0,0,0.2), rgba(54,108,217,0.2))',
  margin: theme.spacing(-5, -5, 0, -5),
  padding: theme.spacing(5),
  textAlign: 'center',
}))

const StyledText = styled(Typography)(({ theme }) => ({
  color: grey[900],
  fontSize: '1.75rem',
  fontWeight: 300,
  paddingBottom: theme.spacing(2),
}))

const StyledSearchButton = styled(Button)({
  height: 30,
})

export const HelpSearchBar = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [term, setTerm] = useState('')

  useEffect(() => {
    if (location.pathname === `/${RoutePath.HELP_RELATIVE}`) {
      setTerm('')
    }
  }, [location.pathname])

  const handleChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setTerm(event.currentTarget.value)
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    navigate(
      `/${RoutePath.HELP_RELATIVE}/${RoutePath.KNOWLEDGE_SEARCH_RELATIVE}?searchTerm=${term}`,
    )
  }

  return (
    <StyledContainer>
      <StyledText>How Can We Help You?</StyledText>
      <form onSubmit={handleSubmit}>
        <TextField
          size="small"
          id="help-search"
          name="help-search"
          placeholder="Find help articles on various topics"
          value={term}
          onChange={handleChange}
          variant="outlined"
          inputProps={{
            'data-testid': 'help-search',
          }}
          sx={{ marginRight: 1.5, width: '50%' }}
          InputProps={{
            sx: { backgroundColor: white.main, height: 30 },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="primary" />
              </InputAdornment>
            ),
          }}
        />
        <StyledSearchButton
          type="submit"
          data-testid="search-button"
          variant="contained"
          color="primary"
        >
          Search
        </StyledSearchButton>
      </form>
    </StyledContainer>
  )
}

export default HelpSearchBar
