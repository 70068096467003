import axios from 'axios'

import apiConfig from 'config/apiConfig'

import { DEFAULT_COUNTRY } from 'services/addressHelper'

import { SmsAddress, ValidateAddress } from 'types/Address'

export interface ValidateResponse {
  validated: boolean
  validAddresses?: SmsAddress[]
  error?: boolean
}

export const createSmsAddressFromStringAddress = (
  addressString: string,
): SmsAddress => {
  const addressArray = addressString.split(', ')

  if (addressArray.length === 5) {
    return {
      address1: addressArray[0],
      address2: addressArray[1],
      city: addressArray[2],
      state: addressArray[3],
      postal_code: addressArray[4],
      country_code: DEFAULT_COUNTRY,
    }
  } else {
    return {
      address1: addressArray[0],
      city: addressArray[1],
      state: addressArray[2],
      postal_code: addressArray[3],
      country_code: DEFAULT_COUNTRY,
    }
  }
}

export const createSmsAddressFromValidateAddress = (
  address: ValidateAddress,
): SmsAddress => {
  return {
    address1: address.address_line1,
    address2: address.address_line2 ?? '',
    city: address.city,
    state: address.state,
    postal_code: address.zip_code.substring(0, 5),
    country_code: DEFAULT_COUNTRY,
  }
}

export const createValidateAddressFromSmsAddress = (
  address: SmsAddress,
): ValidateAddress => {
  return {
    address_line1: address.address1,
    address_line2: address.address2,
    city: address.city,
    state: address.state,
    zip_code: address.postal_code,
  }
}

export const suggestAddresses = (address: string): Promise<SmsAddress[]> => {
  const config = {
    params: {
      search_string: address,
    },
  }

  return axios
    .get(
      `${apiConfig.securityGateway}/guest_address_validations/suggest_address`,
      config,
    )
    .then(({ data }) => {
      const addressSuggestions = data.suggestions
        .filter((suggestion: string) => suggestion !== 'No matches')
        .map((addressSuggestion: string) => {
          return createSmsAddressFromStringAddress(addressSuggestion)
        })

      return addressSuggestions
    })
}

export const validateAddress = (
  address: SmsAddress,
): Promise<ValidateResponse> => {
  let validated = true
  const addressToVerify: ValidateAddress =
    createValidateAddressFromSmsAddress(address)

  return axios
    .post(
      `${apiConfig.securityGateway}/guest_address_validations/verify_addresses`,
      {
        ...addressToVerify,
        show_address_suggestions: false,
      },
      { ignoredStatuses: [{ status: 400 }, { status: 409 }] } as any,
    )
    .then((response) => {
      if (response?.status === 409 && response?.data?.suggestions) {
        return {
          validated: false,
          validAddresses: response.data.suggestions.map((suggestion: any) => {
            return createSmsAddressFromValidateAddress(suggestion.address)
          }),
        }
      }
      const validAddress = createSmsAddressFromValidateAddress(
        response.data.address,
      )

      Object.keys(address).forEach((field) => {
        // @ts-ignore
        if (address[field] && address[field] !== validAddress[field]) {
          validated = false
        }
      })

      return {
        validated,
        validAddresses: [
          createSmsAddressFromValidateAddress(response.data.address),
        ],
      }
    })
    .catch(() => {
      return { validated: false, error: true }
    })
}
