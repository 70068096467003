import { useState, useEffect } from 'react'

import Typography from '@mui/material/Typography'

import { hydrateAddress } from 'services/addressHelper'
import { getOrderAddressesById } from 'services/orders'

import Address from 'components/common/Address'
import PiiDataContainer from 'components/common/PiiDataContainer'

import { OrderAddresses } from 'types/Orders'

interface Props {
  sellerId: string
  orderId: string
}

export const OrderAddressList = ({ orderId, sellerId }: Props) => {
  const [addresses, setAddresses] = useState<OrderAddresses | undefined>(
    undefined,
  )

  useEffect(() => {
    getOrderAddressesById(sellerId, orderId).then((res) => {
      setAddresses(res)
    })
  }, [sellerId, orderId])

  if (!addresses) return null

  const { shipping_address } = addresses
  const {
    first_name: firstName,
    last_name: lastName,
    company_name: companyName,
    email,
  } = shipping_address

  const address = hydrateAddress(shipping_address)

  return (
    <div data-testid="order-address">
      {shipping_address && (
        <PiiDataContainer buttonText="Address">
          <Address
            firstName={firstName}
            lastName={lastName}
            companyName={companyName}
            address={address}
          />
          {email && <Typography variant="body2">Email: {email}</Typography>}
        </PiiDataContainer>
      )}
    </div>
  )
}

export default OrderAddressList
