import {
  SELLERS_IS_PENDING,
  SELLERS_IS_PENDING_DONE,
  FETCH_ALL_SELLERS_DONE,
  FetchAllSellersDoneAction,
  SellerActions,
  SET_CURRENT_SELLER_DONE,
  SetCurrentSellerDoneAction,
  EDIT_SELLER,
  EDIT_SELLER_STATUS_DONE,
  EditSellerStatusDoneAction,
  EDIT_SELLER_STATUS,
  FETCH_SELLER_CONTACTS_DONE,
  FetchSellerContactsDoneAction,
  EDIT_SELLER_CONTACT_DONE,
  EditSellerContactDoneAction,
  CLEAR_SELLER_CONTACTS,
  CREATE_SELLER_DISTRIBUTION_CENTER_AND_SHIP_NODE,
  EDIT_SELLER_DISTRIBUTION_CENTER,
  EDIT_SELLER_SHIP_NODE,
} from './actionCreators'
import { SmsSeller } from 'types/Seller'
import SellerUser from 'types/SellerUser'

export interface SellerState {
  readonly currentSeller: SmsSeller | undefined
  readonly isSellersPending: boolean
  readonly sellers: SmsSeller[]
  readonly contacts: SellerUser[] | undefined
}

export const initialState = {
  sellers: [],
  isSellersPending: false,
  currentSeller: undefined,
  contacts: undefined,
}

export default function sellerReducer(
  state: SellerState = initialState,
  action: SellerActions,
): SellerState {
  switch (action.type) {
    case SELLERS_IS_PENDING: {
      return {
        ...state,
        isSellersPending: true,
      }
    }

    case SELLERS_IS_PENDING_DONE: {
      return {
        ...state,
        isSellersPending: false,
      }
    }
    case FETCH_ALL_SELLERS_DONE: {
      const { sellers } = action as FetchAllSellersDoneAction
      return {
        ...state,
        isSellersPending: false,
        sellers,
      }
    }
    case SET_CURRENT_SELLER_DONE: {
      const { payload } = action as SetCurrentSellerDoneAction
      return {
        ...state,
        isSellersPending: false,
        currentSeller: payload,
      }
    }

    case EDIT_SELLER: {
      return {
        ...state,
        isSellersPending: true,
      }
    }

    case EDIT_SELLER_STATUS: {
      return {
        ...state,
        isSellersPending: true,
      }
    }

    case EDIT_SELLER_STATUS_DONE: {
      const { payload } = action as EditSellerStatusDoneAction
      const seller = state.currentSeller as SmsSeller
      return {
        ...state,
        isSellersPending: false,
        currentSeller: {
          ...seller,
          status: payload,
        },
      }
    }

    case FETCH_SELLER_CONTACTS_DONE: {
      const { payload } = action as FetchSellerContactsDoneAction
      return {
        ...state,
        contacts: payload,
      }
    }

    case CREATE_SELLER_DISTRIBUTION_CENTER_AND_SHIP_NODE: {
      return {
        ...state,
        isSellersPending: true,
      }
    }

    case EDIT_SELLER_DISTRIBUTION_CENTER: {
      return {
        ...state,
        isSellersPending: true,
      }
    }

    case EDIT_SELLER_CONTACT_DONE: {
      const { payload } = action as EditSellerContactDoneAction
      const { contacts } = payload

      return {
        ...state,
        contacts,
      }
    }

    case CLEAR_SELLER_CONTACTS: {
      return {
        ...state,
        contacts: undefined,
      }
    }

    case EDIT_SELLER_SHIP_NODE: {
      return {
        ...state,
        isSellersPending: true,
      }
    }

    default:
      return state
  }
}
