import { ParentImageField } from 'constants/itemFields'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import differenceWith from 'lodash/fp/differenceWith'
import filter from 'lodash/fp/filter'
import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import head from 'lodash/fp/head'
import lodashOrderBy from 'lodash/fp/orderBy'
import map from 'lodash/fp/map'
import some from 'lodash/fp/some'
import take from 'lodash/fp/take'

import styled from '@emotion/styled'

import Grid from '@mui/material/Grid'
import DialogActions from '@mui/material/DialogActions'

import ActionButtons from './ActionButtons'
import DataChanges from './DataChanges'
import ErrorsOnPrevious from './ErrorsOnPrevious'
import OverviewContent from './Overview/OverviewContent'
import ReviewLatestItemData from './ReviewLatestItemData'
import LatestErrors from './LatestErrors'
import ErrorNotSavedDialog from './ErrorNotSavedDialog'
import InactivityDialog from './InactivityDialog'

import ActiveFilters from 'components/common/FilterBar/ActiveFilters'
import DisplayCard from 'components/common/DisplayCard'
import FullScreenDialogContainer from 'components/common/Dialog/FullScreenDialogContainer'
import FullScreenLoader from 'components/common/loader/FullScreenLoader'

import { closeDialog } from 'store/dialog/actionCreator'
import {
  fetchNextItem,
  setItemProcessed,
} from 'store/itemReview/actionCreators'
import { showNotification } from 'store/notification/reducer'

import { trackCustomEvent } from 'services/fireflyInsights'
import { Direction } from 'services/pageableHelper'
import { updateProductListingStatus } from 'services/itemReview'
import {
  getMarketplaceProductWithLock,
  unlockMarketplaceProduct,
} from 'services/sellerProducts'
import {
  isEqualError,
  FieldError,
  getLatestListingErrors,
  getCurrentListingErrors,
  isEqualProductError,
} from 'services/itemHelper'

import { getItemReview } from 'store/selectors'

import {
  SmsProduct,
  ProductSearchParams,
  ProductError,
  ListingStatus,
  RelationshipType,
  ProductField,
} from 'types/Item'
import { FireflyEvent } from 'types/FireflyInsights'
import { Severity } from 'types/ErrorCode'

import {
  SmsBulkApiWebSocket,
  BUMP_FAILURE,
  BUMP_REQUEST,
  BUMP_SUCCESS,
} from 'websockets/smsBulkApi'

import { useIdleTimer } from 'react-idle-timer'

const StyledCard = styled(DisplayCard)(({ theme }) => ({
  marginTop: theme.spacing(2),
}))
const StyledGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

export interface Props {
  filters: Dictionary<string | undefined>
  searchParams: ProductSearchParams
  direction?: Direction
  isOpen: boolean
}

export const ITEM_REVIEW_TIMEOUT = 15 * 60 * 1000 // 15 minutes in milliseconds

export const ItemReviewDialog = ({ filters, searchParams, isOpen }: Props) => {
  const dispatch = useDispatch()

  const { isItemReviewPending, productReviewData, reviewComplete } =
    useSelector(getItemReview)

  const [smsProduct, setSmsProduct] = useState<SmsProduct>()
  const [previousVersion, setPreviousVersion] = useState<SmsProduct>()
  const [errors, setErrors] = useState<ProductError[]>([])
  const [processedId, setProcessedId] = useState<string>()
  const [pending, setPending] = useState<boolean>(false)
  const [startTime, setStartTime] = useState<number>()
  const [interactionStart, setInteractionStart] = useState<number>()
  const [fetchCount, setFetchCount] = useState<number>(1)
  const [errorNotSavedOpen, setErrorNotSavedOpen] = useState<boolean>(false)
  const [time, setTime] = useState<number>(ITEM_REVIEW_TIMEOUT)
  const [isActive, setIsActive] = useState(true)
  const [showInactivityDialog, setShowInactivityDialog] = useState(false)

  // need this indicator so we don't fetch one too many times before closing the dialog.
  const [close, setClose] = useState(false)

  const { getRemainingTime, reset } = useIdleTimer({
    timeout: ITEM_REVIEW_TIMEOUT,
    events: [], // DOM events to watch for activity on.
    // Contains 'visibiltychange' and others by default, which we don't want.
    // https://idletimer.dev/docs/api/props#events
  })

  useEffect(() => {
    let timer: NodeJS.Timer

    if (isActive && time > 0) {
      timer = setInterval(() => {
        setTime(Math.ceil(getRemainingTime() / 1000))
      }, 1000)
    } else {
      setIsActive(false)
      setShowInactivityDialog(true)
    }

    return () => clearInterval(timer)
  }, [isActive, time, getRemainingTime])

  useEffect(() => {
    const productId = productReviewData
      ? productReviewData.reviewProduct.product_id
      : undefined

    if (!close && !isItemReviewPending && productId === processedId) {
      if (!startTime) {
        setStartTime(Date.now())
      } else {
        setFetchCount((prevState) => prevState + 1)
      }
      dispatch(fetchNextItem({ searchParams }))
    }
  }, [
    close,
    fetchCount,
    isItemReviewPending,
    processedId,
    productReviewData,
    searchParams,
    startTime,
    dispatch,
  ])

  useEffect(() => {
    const preventUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()

      if (productReviewData)
        unlockMarketplaceProduct(productReviewData?.reviewProduct.product_id)
    }

    window.addEventListener('beforeunload', preventUnload)

    return () => {
      window.removeEventListener('beforeunload', preventUnload)
    }
  }, [productReviewData])

  useEffect(() => {
    if (reviewComplete) {
      window.alert(
        `There are no valid items in the queue for your filter criteria`,
      )
      setClose(true)
      dispatch(closeDialog())
    }
  }, [reviewComplete, dispatch])

  useEffect(() => {
    if (isItemReviewPending) {
      setPreviousVersion(undefined)
      setSmsProduct(undefined)
      setErrors([])
    } else if (productReviewData) {
      if (startTime) {
        trackCustomEvent(
          FireflyEvent.ITEM_FETCHED,
          'timeElapsed',
          Date.now() - startTime,
        )
      }
      setInteractionStart(Date.now())
      setStartTime(undefined)
      setFetchCount(1)
      setTime(ITEM_REVIEW_TIMEOUT)
      reset()

      const getCreated = flow(get('product_statuses'), head, get('created'))

      /*eslint-disable */
      // @ts-ignore
      const [latestProduct, previousProduct] = take(2)(
        lodashOrderBy(
          (history: SmsProduct) => new Date(getCreated(history)),
          'desc',
          productReviewData.itemHistories,
        ),
      )
      /*eslint-enable */
      setPreviousVersion(previousProduct)
      if (productReviewData.smsProduct) {
        setSmsProduct(productReviewData.smsProduct)
        setErrors(getLatestListingErrors(productReviewData.smsProduct))
      }
    }
  }, [productReviewData, isItemReviewPending, startTime, fetchCount, reset])

  const submitReview = (status: ListingStatus, error?: ProductError) => {
    const { reviewProduct } = productReviewData!
    let statusId
    let versionErrors

    setPending(true)

    if (status === ListingStatus.SUSPENDED && smsProduct) {
      statusId = smsProduct.product_statuses.find(
        (status) => status.current,
      )!.id
      versionErrors = getCurrentListingErrors(smsProduct)
      if (error) {
        versionErrors.push(error)
      }
    } else {
      statusId = reviewProduct.product_status_id
      versionErrors = errors
    }

    updateProductListingStatus({
      sellerId: reviewProduct.seller_id,
      productId: reviewProduct.product_id,
      statusId,
      tcin: reviewProduct.tcin,
      listingStatus: status,
      errors: versionErrors,
    })
      .then(() => {
        setProcessedId(reviewProduct!.product_id)
      })
      .finally(() => {
        dispatch(setItemProcessed(reviewProduct, false))
        setPending(false)
        if (status === ListingStatus.SUSPENDED && !error) {
          setErrorNotSavedOpen(true)
        }
        unlockMarketplaceProduct(reviewProduct!.product_id)
      })
  }

  const skipToNext = () => {
    dispatch(setItemProcessed(productReviewData!.reviewProduct, true))
    setProcessedId(productReviewData!.reviewProduct.product_id)

    unlockMarketplaceProduct(productReviewData!.reviewProduct.product_id)
  }

  const reprocess = async () => {
    setPending(true)

    const ws = SmsBulkApiWebSocket(() => {
      const bumpParams: Dictionary<string | string[]> = {}
      if (productReviewData?.smsProduct.seller_id) {
        bumpParams.sellerId = productReviewData?.smsProduct.seller_id
      }

      ws.send(
        BUMP_REQUEST,
        JSON.stringify({
          ...bumpParams,
          tcins: [productReviewData!.reviewProduct.tcin],
        }),
        {
          'content-type': 'application/json',
        },
      )

      ws.subscribe(BUMP_SUCCESS, () => {
        setPending(false)
        ws.disconnect()
        skipToNext()
      })

      ws.subscribe(BUMP_FAILURE, () => {
        setPending(false)
        dispatch(
          showNotification({ isShown: true, message: 'Reprocessing failed' }),
        )
        ws.disconnect()
      })
    })
  }

  const handleCloseDialog = () => {
    setClose(true)
    unlockMarketplaceProduct(productReviewData!.reviewProduct.product_id)
    dispatch(closeDialog())
  }

  const handleInactivityDialogClose = () => {
    setShowInactivityDialog(false)
    handleCloseDialog()
  }

  const handleInactivityDialogContinue = () => {
    setShowInactivityDialog(false)

    if (productReviewData)
      getMarketplaceProductWithLock(productReviewData.reviewProduct.product_id)

    setIsActive(true)
    setTime(ITEM_REVIEW_TIMEOUT)
    reset()
  }

  const closeErrorNotSavedDialog = () => {
    setErrorNotSavedOpen(false)
  }

  const removeError = (error: FieldError) => {
    setErrors(differenceWith(isEqualError, errors, [error]))
  }

  const isSame = (error: ProductError, other: ProductError) => {
    return (
      error.field_name === other.field_name &&
      error.error_code === other.error_code
    )
  }

  const editFieldErrors = (fieldErrors: ProductError[], fieldName: string) => {
    const updated = flow(
      filter((e: ProductError) => {
        return (
          e.field_name !== fieldName || some((fe) => isSame(fe, e), fieldErrors)
        )
      }),
      map((e) => fieldErrors.find((fe) => isSame(fe, e)) || e),
    )(errors)

    const added = differenceWith(isEqualProductError, fieldErrors, errors)
    setErrors([...updated, ...added])
  }

  const disableApproved = !!errors.find(
    (error: ProductError) => error.error_severity === Severity.CRITICAL,
  )

  const isDataLoaded =
    !isItemReviewPending &&
    smsProduct &&
    productReviewData?.reviewProduct.product_id === smsProduct.product_id

  let title = ''
  let fallbackImage: string | undefined

  if (isDataLoaded) {
    if (productReviewData?.reviewProduct) {
      const titleData = productReviewData.reviewProduct
      title = `${titleData.tcin} ${titleData.title}`
    }
    if (
      productReviewData!.reviewProduct.relationship_type ===
      RelationshipType.VAP
    ) {
      const fallbackImageField = productReviewData!.smsProduct!.fields?.find(
        (field: ProductField) => field.name === ParentImageField,
      )
      fallbackImage = fallbackImageField?.value
    }
  }

  if (!isDataLoaded) {
    return (
      <FullScreenDialogContainer
        title={title}
        isOpen={isOpen}
        onRequestClose={handleCloseDialog}
      >
        <ActiveFilters filters={filters} defaultCollapsed={true} />
        <FullScreenLoader
          data-testid="loader"
          isOpen={isItemReviewPending || pending}
        />
      </FullScreenDialogContainer>
    )
  }

  return (
    <FullScreenDialogContainer
      title="Review Item"
      isOpen={isOpen}
      onRequestClose={handleCloseDialog}
    >
      <ActiveFilters filters={filters} />
      <OverviewContent
        title={title}
        product={productReviewData!.reviewProduct}
        smsProduct={productReviewData.smsProduct}
        onSubmitReview={submitReview}
        isApproveDisabled={disableApproved}
        skipToNext={skipToNext}
        reprocess={reprocess}
        fallbackImage={fallbackImage}
        interactionStart={interactionStart}
      />
      <StyledCard
        title="Data Changes Submitted"
        collapsible
        fullHeight={false}
        defaultExpanded
      >
        <DataChanges
          latestVersion={smsProduct?.fields}
          previousVersion={previousVersion?.fields}
        />
      </StyledCard>
      <StyledCard
        title="Errors on Previous Version"
        collapsible
        fullHeight={false}
        defaultExpanded
      >
        <ErrorsOnPrevious previousVersion={previousVersion} />
      </StyledCard>
      <StyledGrid container spacing={2}>
        <Grid item xs={12} md={8}>
          <DisplayCard title="Review Latest Item Data" fullHeight={false}>
            <ReviewLatestItemData
              product={productReviewData!.reviewProduct}
              heliosItem={productReviewData!.heliosItem}
              smsProduct={smsProduct!}
              errors={errors}
              editErrors={editFieldErrors}
            />
          </DisplayCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <LatestErrors
            latestVersion={smsProduct!}
            errors={errors}
            removeError={removeError}
          />
        </Grid>
      </StyledGrid>
      <DialogActions>
        <div>
          <ActionButtons
            validationStatus={
              productReviewData!.reviewProduct.validation_status
            }
            isApproveDisabled={disableApproved}
            submitReview={submitReview}
            skipToNext={skipToNext}
            reprocess={reprocess}
            interactionStart={interactionStart}
          />
        </div>
      </DialogActions>
      <ErrorNotSavedDialog
        isOpen={errorNotSavedOpen}
        onClose={closeErrorNotSavedDialog}
      />
      <FullScreenLoader isOpen={isItemReviewPending || pending} />
      {showInactivityDialog && (
        <InactivityDialog
          isOpen={showInactivityDialog}
          onClose={handleInactivityDialogClose}
          onContinue={handleInactivityDialogContinue}
        />
      )}
    </FullScreenDialogContainer>
  )
}

export default ItemReviewDialog
