import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import * as yup from 'yup'
import { withFormik, Form, FormikProps } from 'formik'

import Grid from '@mui/material/Grid'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import InputField from 'components/common/form/InputField'
import PhoneField, {
  tollFreeRegex,
  tollFreeErrorMsg,
} from 'components/common/form/PhoneField'

import { ValidationSchema } from 'services/validation'

import {
  editUserInfo,
  EditUserInfoAction,
  EditUserInfoParams,
} from 'store/user/actionCreators'

import StoreState from 'types/state'
import {
  LABEL_PHONE_NUMBER_MOBILE,
  LABEL_FIRST_NAME,
  LABEL_LAST_NAME,
  LABEL_EMAIL,
  LABEL_PHONE_NUMBER_OFFICE,
  LABEL_OFFICE_COUNTRY_CODE,
  LABEL_ERROR_PHONE_NUMBER_OFFICE,
  LABEL_MOBILE_COUNTRY_CODE,
  LABEL_ERROR_PHONE_NUMBER_MOBILE,
} from 'constants/labels'
import { PHONE_TYPE_MOBILE, PHONE_TYPE_OFFICE } from 'constants/phones'

import { CountryNameCode } from 'types/Country'
import { getTelephoneCodeByCountry } from 'services/geographicalCodes'

interface FormValues {
  firstName: string
  lastName: string
  email: string
  officeCountry: string
  officePhoneNumber: string
  mobileCountry: string
  mobilePhoneNumber: string
}
interface ComponentProps extends FormValues {
  userId: string
  isOpen: boolean
  handleSubmit: any
  editUserInfo: (params: EditUserInfoParams) => EditUserInfoAction
}

const validationSchema: ValidationSchema = yup.object().shape({
  firstName: yup.string().label('First name').required(),
  lastName: yup.string().label('Last name').required(),
  email: yup.string().label('Email').email().required(),
  officeCountry: yup.string().label(LABEL_OFFICE_COUNTRY_CODE),
  officePhoneNumber: yup.string().when(['officeCountry'], {
    is: (officeCountry: string) =>
      officeCountry !== CountryNameCode.UNITED_STATES,
    then: yup
      .string()
      .label(LABEL_PHONE_NUMBER_MOBILE)
      .test({
        name: 'is-office-country-code',
        test(this: yup.TestContext, value: any) {
          if (value && !this.parent.officeCountry) {
            return this.createError({
              path: 'officePhoneNumber',
              message: 'Please select a country.',
            })
          }

          return true
        },
      })
      .min(8)
      .max(20),
    otherwise: yup
      .string()
      .label(LABEL_PHONE_NUMBER_OFFICE)
      .length(14, LABEL_ERROR_PHONE_NUMBER_OFFICE)
      .required(),
  }),
  mobileCountry: yup.string().label(LABEL_MOBILE_COUNTRY_CODE),
  mobilePhoneNumber: yup.string().when(['mobileCountry'], {
    is: (mobileCountry: string) =>
      mobileCountry !== CountryNameCode.UNITED_STATES,
    then: yup
      .string()
      .label(LABEL_PHONE_NUMBER_MOBILE)
      .test({
        name: 'is-not-toll-freetoll',
        message: tollFreeErrorMsg,
        test(this: yup.TestContext, value: any) {
          if (!value) {
            return true
          }

          return !tollFreeRegex.test(value)
        },
      })
      .min(8)
      .max(20)
      .required(),
    otherwise: yup
      .string()
      .label(LABEL_PHONE_NUMBER_MOBILE)
      .test({
        name: 'is-not-toll-freetoll',
        message: tollFreeErrorMsg,
        test(this: yup.TestContext, value: any) {
          if (!value) {
            return true
          }

          return !tollFreeRegex.test(value)
        },
      })
      .length(14, LABEL_ERROR_PHONE_NUMBER_MOBILE)
      .required(),
  }),
})

export type Props = ComponentProps & FormikProps<FormValues>

const EditUserInfoForm = ({
  isOpen,
  isSubmitting,
  handleSubmit,
  isValid,
  dirty,
  values,
  errors,
  touched,
}: Props) => {
  const [officeCountry, setOfficeCountry] = React.useState<string>(
    CountryNameCode.UNITED_STATES,
  )
  const [mobileCountry, setMobileCountry] = React.useState<string>(
    CountryNameCode.UNITED_STATES,
  )
  const [officePhoneError, setOfficePhoneError] = React.useState('')
  const [mobilePhoneError, setMobilePhoneError] = React.useState('')

  React.useEffect(() => {
    setOfficeCountry(values.officeCountry)

    const error = errors?.officePhoneNumber

    if (touched['officePhoneNumber'] && error) {
      setOfficePhoneError(error)
    } else {
      setOfficePhoneError('')
    }
  }, [values.officeCountry, errors, touched])

  React.useEffect(() => {
    setMobileCountry(values.mobileCountry)

    const error = errors?.mobilePhoneNumber

    if (touched['mobilePhoneNumber'] && error) {
      setMobilePhoneError(error)
    } else {
      setMobilePhoneError('')
    }
  }, [values.mobileCountry, errors, touched])

  return (
    <DialogContainer
      title="User Info"
      isOpen={isOpen}
      isPending={isSubmitting}
      onSubmit={handleSubmit}
      isSubmitDisabled={!isValid || !dirty}
    >
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputField
              required
              name="firstName"
              label={LABEL_FIRST_NAME}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              required
              name="lastName"
              label={LABEL_LAST_NAME}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              required
              name="email"
              label={LABEL_EMAIL}
              disabled={true} // Temporarily disabling email edit by direction of POs
            />
          </Grid>
          <Grid item xs={12}>
            <PhoneField
              selectName="officeCountry"
              name="officePhoneNumber"
              id="office"
              label={LABEL_PHONE_NUMBER_OFFICE}
              disabled={isSubmitting}
              country={officeCountry}
              error={officePhoneError}
            />
          </Grid>
          <Grid item xs={12}>
            <PhoneField
              required
              selectName="mobileCountry"
              name="mobilePhoneNumber"
              id="mobile"
              label={LABEL_PHONE_NUMBER_MOBILE}
              disabled={isSubmitting}
              country={mobileCountry}
              error={mobilePhoneError}
            />
          </Grid>
        </Grid>
      </Form>
    </DialogContainer>
  )
}

export const EditUserInfo = withFormik<ComponentProps, FormValues>({
  mapPropsToValues: (props) => ({
    firstName: props.firstName,
    lastName: props.lastName,
    email: props.email,
    officeCountry: props.officeCountry ?? CountryNameCode.UNITED_STATES,
    officePhoneNumber: props.officePhoneNumber,
    mobileCountry: props.mobileCountry ?? CountryNameCode.UNITED_STATES,
    mobilePhoneNumber: props.mobilePhoneNumber,
  }),
  handleSubmit: async (values, { props }) => {
    const {
      firstName,
      lastName,
      email,
      officeCountry,
      officePhoneNumber,
      mobileCountry,
      mobilePhoneNumber,
    } = values

    const mobilePhoneCode = getTelephoneCodeByCountry(mobileCountry)

    let officePhoneCode
    if (officePhoneNumber) {
      officePhoneCode = getTelephoneCodeByCountry(officeCountry)
    }

    props.editUserInfo({
      userId: props.userId,
      firstName,
      lastName,
      email,
      officeCountry,
      officePhoneCode,
      officePhoneNumber,
      mobileCountry,
      mobilePhoneCode,
      mobilePhoneNumber,
    })
  },
  enableReinitialize: true,
  validationSchema,
})(EditUserInfoForm)

const mapStateToProps = (state: StoreState) => {
  const { user } = state

  const { id: userId, firstName, lastName, email, phone_numbers } = user

  const office = phone_numbers?.find((i) => i.type === PHONE_TYPE_OFFICE)
  const mobile = phone_numbers?.find((i) => i.type === PHONE_TYPE_MOBILE)

  let officeCountry = ''
  let officePhoneNumber = ''
  let mobileCountry = ''
  let mobilePhoneNumber = ''

  if (office) {
    officeCountry = office.country_name_code ?? CountryNameCode.UNITED_STATES
    officePhoneNumber = office.number
  }

  if (mobile) {
    mobileCountry = mobile.country_name_code ?? CountryNameCode.UNITED_STATES
    mobilePhoneNumber = mobile.number
  }

  return {
    userId,
    firstName,
    lastName,
    email,
    officeCountry,
    officePhoneNumber,
    mobileCountry,
    mobilePhoneNumber,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ editUserInfo }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(EditUserInfo)
