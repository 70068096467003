import { FormikProps, Formik } from 'formik'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import InputField from 'components/common/form/InputField'

import { maxCharacterCount } from 'services/validation'

import { editSellerDistributionCenter } from 'store/seller/actionCreators'

import { SmsDistributionCenter } from 'types/Seller'

type FormValues = {
  name: string
}

type ComponentProps = {
  distributionCenter: SmsDistributionCenter
  isOpen: boolean
}

type Props = ComponentProps

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .label('Distribution Center Name')
    .test({
      name: 'name',
      test: maxCharacterCount('name', 20),
    })
    .required(),
})

const EditDistributionName = ({ isOpen, distributionCenter }: Props) => {
  const dispatch = useDispatch()

  const onSubmit = async (values: FormValues) => {
    if (!distributionCenter) {
      return
    }

    const { name } = values
    const updatedDistributionCenter = {
      ...distributionCenter,
      name,
    }

    dispatch(editSellerDistributionCenter(updatedDistributionCenter))
  }

  return (
    <Formik
      initialValues={{ name: distributionCenter.name ?? '' }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        handleChange,
        isValid,
        dirty,
        handleSubmit,
        handleBlur,
        isSubmitting,
      }: FormikProps<FormValues>) => (
        <DialogContainer
          isOpen={isOpen}
          title="Edit Distribution Center Name"
          isPending={isSubmitting}
          isSubmitDisabled={!isValid || !dirty}
          onSubmit={handleSubmit}
        >
          <InputField
            required
            maxCharacters={20}
            name="name"
            label="Distribution Center Name"
            disabled={isSubmitting}
            onChange={handleChange}
            onKeyUp={handleBlur}
          />
        </DialogContainer>
      )}
    </Formik>
  )
}

export default EditDistributionName
