import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import mapValues from 'lodash/fp/mapValues'

import apiConfig from 'config/apiConfig'

import { formatHttpError } from 'services/formatHttpError'

import { HttpError, ErrorResponse } from 'types/HttpError'

export const API_KEY_HEADER = 'x-api-key'
export const ERROR_MESSAGE_NOT_AUTHORIZED_API =
  'You’re not authorized to do that.'

const interceptors = {
  request: -1,
  response: -1,
}

export default function configureHttpInterceptor(
  showNotification: (params: {
    isShown: boolean
    message: string | HttpError | ErrorResponse
  }) => void,
): void {
  if (interceptors.request > -1) {
    axios.interceptors.request.eject(interceptors.request)
  }

  if (interceptors.response > -1) {
    axios.interceptors.response.eject(interceptors.response)
  }

  // intercept all outgoing requests
  interceptors.request = axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      config.headers = config.headers || {}

      if (config.params) {
        const params = mapValues((value) => {
          if (Array.isArray(value)) {
            return value.join()
          } else {
            return value
          }
        }, config.params)
        config.params = params
      } else {
        config.params = {}
      }

      if (!config.url?.includes('jwks.json')) {
        config.headers[API_KEY_HEADER] = apiConfig.apiGatewayKey
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  // intercept all incoming responses
  interceptors.response = axios.interceptors.response.use(
    (response: AxiosResponse) => {
      return response
    },
    (error: ErrorResponse) => {
      if (error.response) {
        const ignoredStatuses = error.config.ignoredStatuses
        const code = error.response.status

        if (ignoredStatuses) {
          const err = ignoredStatuses.find((ignore) => ignore.status === code)

          if (err) {
            // some errors return data as a string. (i.e: /release-notes-external.md)
            if (typeof error.response.data === 'object') {
              error.response.data.status = code
            }

            if (err.unless) {
              const message = err.unless(error)

              if (message) {
                showNotification({
                  isShown: true,
                  message,
                })

                // Do not call additional promises in a chain if message is defined
                return Promise.reject(error)
              }
            }

            return error.response
          }
        }
        if (code === 403) {
          error.response.data.errors?.[0]
            ? showNotification({
                isShown: true,
                message: error.response.data.errors[0],
              })
            : showNotification({
                isShown: true,
                message: ERROR_MESSAGE_NOT_AUTHORIZED_API,
              })
        } else {
          showNotification({
            isShown: true,
            message: typeof error === 'string' ? error : formatHttpError(error),
          })
        }
      }

      return Promise.reject(error)
    },
  )
}
