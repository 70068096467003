import HeaderTitle from 'components/common/HeaderTitle'
import FeatureWrapper from 'components/common/FeatureWrapper'
import PromotionsList from './PromotionsList'

import {
  ALL_USER_ROLES,
  USER_ROLE_ADMIN,
  USER_ROLE_PROGRAM_MANAGER,
  USER_ROLE_REPORTER,
} from 'services/roles'

import {
  ALL_ENVIRONMENTS,
  ENVIRONMENT_DEVELOPMENT,
  ENVIRONMENT_STAGE,
} from 'config/apiConfig'
import { trackPageView } from 'services/fireflyInsights'
import { FireflyEvent } from 'types/FireflyInsights'

export const PromotionsPage = () => {
  trackPageView(FireflyEvent.PROMOTIONS_PAGEVIEWS)

  return (
    <FeatureWrapper
      conditions={[
        {
          environments: ALL_ENVIRONMENTS,
          roles: [
            USER_ROLE_ADMIN,
            USER_ROLE_PROGRAM_MANAGER,
            USER_ROLE_REPORTER,
          ],
        },
        {
          environments: [ENVIRONMENT_DEVELOPMENT, ENVIRONMENT_STAGE],
          roles: ALL_USER_ROLES,
        },
      ]}
    >
      <div data-testid="all-promotions">
        <HeaderTitle title={'Promotions'} />

        <PromotionsList />
      </div>
    </FeatureWrapper>
  )
}

export default PromotionsPage
