import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'

import Grid from '@mui/material/Grid'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import RadioGroup from 'components/common/RadioGroup/RadioGroup'

import { GenerateReportRequest } from 'services/reports'

import { closeDialog } from 'store/dialog/actionCreator'

import { ReportType } from 'types/Report'
import { currentSellerId } from 'store/selectors'

const StyledRoot = styled('div')({
  minHeight: 150,
})

export type Props = {
  isOpen: boolean
  reportType: ReportType
  onRequestSubmit: (reportParams: GenerateReportRequest) => void
}

type RadioLabel = string
type RadioValue = 'published' | 'unpublished'
type RadioOption = {
  label: RadioLabel
  value: RadioValue
}

const options: RadioOption[] = [
  { label: 'Published Items', value: 'published' },
  { label: 'Not Published Items', value: 'unpublished' },
]

export const InventoryAndPriceReportDialog = ({
  isOpen,
  reportType,
  onRequestSubmit,
}: Props) => {
  const sellerId = useSelector(currentSellerId)
  const reduxDispatch = useDispatch()

  const [status, setStatus] = useState<RadioValue | ''>('')

  const handleChange = (value: string) => {
    if (value === 'published' || value === 'unpublished') {
      setStatus(value)
    }
  }

  const handleSubmit = async () => {
    onRequestSubmit({
      sellerId,
      type: reportType,
      parameters: {
        is_published: status === 'published' ? true : false,
      },
    })

    reduxDispatch(closeDialog())
  }

  return (
    <DialogContainer
      title="Generate Inventory & Price Report"
      isOpen={isOpen}
      isSubmitDisabled={status !== 'published' && status !== 'unpublished'}
      onSubmit={handleSubmit}
      submitButtonText="Generate"
      disableScroll
    >
      <StyledRoot data-testid="report-date-range">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RadioGroup
              id="is-published"
              name="isPublished"
              options={options}
              value={status}
              onChange={handleChange}
              label="Select Item Publish Status*"
            />
          </Grid>
        </Grid>
      </StyledRoot>
    </DialogContainer>
  )
}

export default InventoryAndPriceReportDialog
