import { useEffect } from 'react'

import { withFormik, Form, FormikProps } from 'formik'
import * as yup from 'yup'
import styled from '@emotion/styled'

import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

import RadioGroupField from 'components/common/form/RadioGroupField'
import InputField from 'components/common/form/InputField'
import NumberMaskInputField from 'components/common/form/NumberMaskInputField'

import { maxCharacterCount } from 'services/validation'

import {
  JudgementValues,
  ReturnDispute,
  ReturnDisputeResolution,
} from 'types/disputes'

import { success } from 'config/themeConfig'
import { decimalRound } from 'services/number'
import formatCurrency from 'services/formatCurrency'

const StyledHeading = styled(Typography)({
  fontWeight: 700,
  color: success.main,
})

const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

enum ReverseServiceFee {
  YES = 'YES',
  NO = 'NO',
}

type FormValues = {
  judgement?: JudgementValues
  reverseServiceFee?: ReverseServiceFee
  refundAmount?: string
  notes: string
}

type ComponentProps = {
  dispute: ReturnDispute
  onHandleSubmit: (
    disputeUpdate: ReturnDisputeResolution,
    disputeCaseNumber: string,
  ) => Promise<void>
}

type Props = ComponentProps & FormikProps<FormValues>

const MAX_COUNT = 300

export const validationSchema = yup.object().shape({
  judgement: yup.string().label('Judgement').required(),
  notes: yup
    .string()
    .label('Additional Notes (Denial Reason)')
    .when(['judgement'], {
      is: (judgement: string) => judgement === JudgementValues.VALID_CLAIM,
      then: yup.string(),
      otherwise: yup
        .string()
        .test({
          name: 'hasNotes',
          test: maxCharacterCount('notes', MAX_COUNT),
        })
        .required(),
    }),
  refundAmount: yup
    .string()
    .label('Item Chargeback Reversal %')
    .when(['judgement'], {
      is: (judgement: string) => judgement !== JudgementValues.VALID_CLAIM,
      then: yup.string(),
      otherwise: yup
        .string()
        .test({
          name: 'hasRefundAmount',
          test(this: yup.TestContext, value: any) {
            if (!value) {
              return true
            }

            const path = 'refundAmount'
            const digits = getNumberFromPercentStr(value)

            if (digits < 0 || value.startsWith('-')) {
              return this.createError({
                path,
                message: `Min is 0`,
              })
            } else if (digits > 100) {
              return this.createError({
                path,
                message: `Max is 100`,
              })
            }

            return digits >= 0 && digits <= 100
          },
        })
        .required(),
    }),
  reverseServiceFee: yup
    .string()
    .label('Reverse Service Fees')
    .when(['judgement'], {
      is: (judgement: string) => judgement !== JudgementValues.VALID_CLAIM,
      then: yup.string(),
      otherwise: yup.string().required(),
    }),
})

const radioOptions = [
  { label: `Deny dispute`, value: JudgementValues.INVALID_CLAIM },
  {
    label: `Request more evidence from partner`,
    value: JudgementValues.WAITING_ON_PARTNER,
  },
  {
    label: `Approve dispute & payout`,
    value: JudgementValues.VALID_CLAIM,
  },
]

const getNumberFromPercentStr = (str: string) => {
  if (!str) {
    return 0
  }

  const digits = str.replace('%', '')

  const num = parseInt(digits, 10)

  if (isNaN(num)) {
    return 0
  }

  return num
}

const getRefundNum = (percent: number, chargeback: number) => {
  return decimalRound(chargeback * (percent / 100), 2)
}

const chargebackReversalPercentToDollars = (
  refundPercent: string,
  chargeback: number,
) => {
  const percent = getNumberFromPercentStr(refundPercent)

  const refund = getRefundNum(percent, chargeback)

  const amount = formatCurrency(refund)

  return amount
}

const NOTES_LABEL = `On submit, the default response additional notes and system
messages will be emailed to the partner and the case will be
resolved in Salesforce.`

const NOTES_WAITING_ON_PARTNER_LABEL = `On submit, the default response & notes requesting evidence will be emailed to the partner and dispute status will be “Waiting on Partner”.`

export const DisputeSideBarForm = ({
  dispute,
  isValid,
  dirty,
  isSubmitting,
  initialValues,
  values,
  setErrors,
  setValues,
  resetForm,
}: Props) => {
  useEffect(() => {
    // Reset inputs when radio changes
    if (values.judgement) {
      setValues({
        ...initialValues,
        judgement: values.judgement,
      })

      setErrors({})
    }
  }, [
    values.judgement,
    initialValues,
    setValues,
    setErrors,
    dispute.case_number,
  ])

  const handleReset = () => {
    resetForm({ values: initialValues })
  }

  return (
    <Paper elevation={2} sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StyledHeading variant="h3">
            Resolve Dispute: Approve or Deny
          </StyledHeading>
        </Grid>
      </Grid>
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledBox>
              <RadioGroupField
                required
                name="judgement"
                disabled={isSubmitting}
                options={radioOptions}
              />
            </StyledBox>
          </Grid>
        </Grid>

        {(values.judgement === JudgementValues.INVALID_CLAIM ||
          values.judgement === JudgementValues.WAITING_ON_PARTNER) && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <StyledBox>
                  <Typography variant="caption">
                    {values.judgement === JudgementValues.INVALID_CLAIM
                      ? NOTES_LABEL
                      : NOTES_WAITING_ON_PARTNER_LABEL}
                  </Typography>
                </StyledBox>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <StyledBox>
                  <InputField
                    required
                    multiline
                    name="notes"
                    label={
                      values.judgement === JudgementValues.INVALID_CLAIM
                        ? 'Additional Notes (Denial Reason)'
                        : 'Notes (Describe evidence needed)'
                    }
                    inputProps={{ 'data-testid': 'notes' }}
                    maxCharacters={MAX_COUNT}
                    enableOnChangeValidation
                    disabled={isSubmitting}
                  />
                </StyledBox>
              </Grid>
            </Grid>
          </>
        )}
        {values.judgement === JudgementValues.VALID_CLAIM && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <StyledBox>
                  <NumberMaskInputField
                    required
                    name="refundAmount"
                    label="Item Chargeback Reversal %"
                    inputProps={{ 'data-testid': 'refundAmount' }}
                    disabled={isSubmitting}
                    helperText={
                      values.refundAmount
                        ? chargebackReversalPercentToDollars(
                            values.refundAmount,
                            dispute.chargeback_amount,
                          )
                        : ''
                    }
                  />
                </StyledBox>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <StyledBox>
                  <RadioGroupField
                    required
                    row
                    name="reverseServiceFee"
                    label="Reverse Service Fee?"
                    disabled={isSubmitting}
                    options={[
                      { label: 'No', value: ReverseServiceFee.NO },
                      { label: 'Yes', value: ReverseServiceFee.YES },
                    ]}
                  />
                </StyledBox>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <StyledBox>
                  <Typography variant="caption">{NOTES_LABEL}</Typography>
                </StyledBox>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <StyledBox>
                  <InputField
                    multiline
                    name="notes"
                    label="Additional Notes (Optional)"
                    inputProps={{ 'data-testid': 'notes' }}
                    maxCharacters={MAX_COUNT}
                    enableOnChangeValidation
                    disabled={isSubmitting}
                  />
                </StyledBox>
              </Grid>
            </Grid>
          </>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledBox>
              <Button
                sx={{ mr: 2 }}
                data-testid="submit"
                type="submit"
                color="primary"
                variant="contained"
                disabled={!isValid || !dirty || isSubmitting}
              >
                Submit & go to next
              </Button>
              <Button onClick={handleReset}>Reset</Button>
            </StyledBox>
          </Grid>
        </Grid>
      </Form>
    </Paper>
  )
}

export const DisputeSideBar = withFormik<ComponentProps, FormValues>({
  mapPropsToValues: () => ({
    judgement: undefined,
    reverseServiceFee: undefined,
    notes: '',
    refundAmount: '',
  }),
  handleSubmit: async (values, { props, setSubmitting, resetForm }) => {
    const { judgement, notes, refundAmount, reverseServiceFee } = values

    if (!judgement) {
      return
    }

    const disputeUpdate: ReturnDisputeResolution = {
      judgement,
      ...(notes ? { notes } : {}),
    }

    if (judgement === JudgementValues.VALID_CLAIM) {
      disputeUpdate.final_response = JudgementValues.VALID_CLAIM

      if (refundAmount) {
        const refund = getRefundNum(
          getNumberFromPercentStr(refundAmount),
          props.dispute.chargeback_amount,
        )

        disputeUpdate.seller_refund_amount = refund
      }

      if (reverseServiceFee === ReverseServiceFee.NO) {
        disputeUpdate.service_fee_reversal = 0
      } else if (reverseServiceFee === ReverseServiceFee.YES) {
        disputeUpdate.service_fee_reversal = props.dispute.service_fee
      }
    }

    setSubmitting(true)

    try {
      await props.onHandleSubmit(disputeUpdate, props.dispute.case_number)
      resetForm()
    } finally {
      setSubmitting(false)
    }
  },
  enableReinitialize: true,
  validateOnMount: false,
  validationSchema,
})(DisputeSideBarForm)

export default DisputeSideBar
