import { useState, useEffect, SyntheticEvent } from 'react'

import { Direction } from 'services/pageableHelper'
import { getHeliosItemList } from 'services/helios'

import { HeliosListItem } from 'types/HeliosItem'
import Typeahead from '.'
import usePrevious from 'hooks/usePrevious'

export interface Props {
  placeholder?: string
  status?: string
  clearOnSelect?: boolean
  value?: Nullable<HeliosListItem>
  onChange: (value: Nullable<HeliosListItem>, enteredValue: string) => void
  onClear?: () => void
}

const HeliosItemTypeahead = ({
  placeholder,
  status,
  clearOnSelect = true,
  value: valueFromProps,
  onChange,
  onClear,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedValue, setSelectedValue] =
    useState<Nullable<HeliosListItem>>(valueFromProps)
  const [options, setOptions] = useState<HeliosListItem[]>([])
  const [loading, setLoading] = useState(false)

  const prevValue = usePrevious(valueFromProps)
  useEffect(() => {
    if (prevValue !== valueFromProps) {
      setSelectedValue(valueFromProps)
    }

    if (!valueFromProps) {
      setOptions([])
    }
  }, [prevValue, valueFromProps])

  useEffect(() => {
    if (options.length && !inputValue) {
      setOptions([])
    }
  }, [inputValue, options])

  const handleInputChange = async (
    _event: SyntheticEvent,
    enteredValue: string,
    reason: string,
  ) => {
    if (
      onClear &&
      (reason === 'clear' ||
        (selectedValue && reason === 'input' && enteredValue === ''))
    ) {
      onClear()
      setOptions([])
      setInputValue('')
      setSelectedValue(null)
      return
    }

    setInputValue(enteredValue)

    if (!enteredValue || enteredValue.length < 8 || reason === 'reset') {
      return Promise.resolve([])
    }

    const paging = {
      sort: 'source_timestamp',
      order: Direction.ASC,
      page: 0,
      pageSize: 1,
    }

    const params = {
      status: status,
      tcin: enteredValue,
    }

    const { data } = await getHeliosItemList(params, paging)

    setOptions(data)
    setLoading(false)
  }

  const handleSelectedOptionChange = (
    _event: SyntheticEvent,
    value: HeliosListItem,
  ) => {
    if (!(value instanceof Array)) {
      const searchParamValue = getOptionLabel(value as HeliosListItem)
      setSelectedValue(
        clearOnSelect ? null : (value as Nullable<HeliosListItem>),
      )
      onChange(value as Nullable<HeliosListItem>, searchParamValue)
    }
  }

  const getOptionLabel = (item: HeliosListItem) => {
    return item?.tcin ?? ''
  }

  return (
    <Typeahead
      aria-label="Helios TCIN Filter"
      placeholder={placeholder}
      getOptionLabel={getOptionLabel}
      label="Helios TCIN Filter"
      options={options}
      loading={loading}
      onInputChange={handleInputChange}
      onChange={handleSelectedOptionChange}
      isOptionEqualToValue={(
        option: HeliosListItem,
        value: Nullable<HeliosListItem>,
      ) => option.tcin === value?.tcin}
      inputValue={inputValue}
      value={selectedValue}
    />
  )
}

export default HeliosItemTypeahead
