import { Routes, Route } from 'react-router-dom'

import OrdersPage from './OrdersPage'
import OrderDetails from './OrderDetails'

export const Orders = () => {
  return (
    <Routes>
      <Route index element={<OrdersPage />} />
      <Route path=":orderId" element={<OrderDetails />} />
    </Routes>
  )
}

export default Orders
