import { useEffect, useState } from 'react'

import styled from '@emotion/styled'

import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'

import DateInput from 'components/common/DateInput'

import { getClosedDates } from '../DatesClosedContent'

import {
  DATE_DISPLAY_FORMAT,
  DATE_PICKER_FORMAT,
  formatDateLocalTime,
  formatDateString,
  isValidAndAfterToday,
  parseDate,
} from 'services/dateService'
import { add } from 'date-fns'

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(1),
  marginLeft: 0,
}))

const StyledContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))

const StyledLabel = styled(InputLabel)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}))

const StyledRoot = styled(Grid)({
  display: 'flex',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
})

export interface Props {
  daysClosed?: string[]
  onChange: (value: string, remove: boolean) => void
  isDisabled: boolean
}

interface DatesClosed {
  date: string
  selected: boolean
  displayDate: string
}

export const EditDatesClosed = ({
  daysClosed,
  onChange,
  isDisabled,
}: Props) => {
  const [inputDate, setInputDate] = useState<string>('')
  const [datesClosed, setDatesClosed] = useState<DatesClosed[] | undefined>(
    daysClosed
      ? getClosedDates(daysClosed)?.map((item: string) => ({
          date: formatDateString(item, DATE_DISPLAY_FORMAT, DATE_PICKER_FORMAT),
          selected: true,
          displayDate: item,
        }))
      : [],
  )

  useEffect(() => {
    const selected = isValidAndAfterToday(inputDate, DATE_DISPLAY_FORMAT)

    if (selected) {
      const closedDate = {
        date: formatDateString(
          inputDate,
          DATE_DISPLAY_FORMAT,
          DATE_PICKER_FORMAT,
        ),
        selected,
        displayDate: selected ? inputDate : '',
      }

      setDatesClosed((dates) => {
        const dateExists = dates?.find(({ date }) => date === closedDate.date)
          ? true
          : false
        if (dates) {
          if (dates && !dateExists) {
            dates = [...dates, closedDate]
          }
          const uniqueClosedDates = Array.from(new Set(dates))
          return uniqueClosedDates
        }
      })
      onChange(
        formatDateString(inputDate, DATE_DISPLAY_FORMAT, DATE_PICKER_FORMAT),
        false,
      )
      setInputDate('')
    }
  }, [inputDate, onChange])

  const handleChange = (date: Date | undefined) => {
    if (!date) {
      return // exit early so users can use a keyboard
    }

    const value = date ? formatDateLocalTime(date, DATE_DISPLAY_FORMAT) : ''

    setInputDate(value)
  }

  const handleDelete =
    (closeDate: { date: string; displayDate: string }) => () => {
      const closed = datesClosed?.filter((i) => i.date !== closeDate.date)

      setDatesClosed(closed)
      onChange(closeDate.date, true)
    }

  const minPickerDate = {
    disabled: { before: add(new Date(), { days: 1 }) },
  }

  const uniqueDates = Array.from(new Set(datesClosed))
  const content = uniqueDates?.map((dateSelected: DatesClosed, idx) => {
    return (
      <StyledChip
        id={idx.toString()}
        data-testid="date-chip"
        label={dateSelected.displayDate}
        onDelete={handleDelete(dateSelected)}
        key={idx}
      />
    )
  })

  return (
    <StyledContainer container spacing={2}>
      <Grid item xs={12}>
        <StyledLabel shrink>
          Dates Closed (when you will not process orders)
        </StyledLabel>
        <DateInput
          isDisabled={isDisabled}
          value={parseDate(inputDate)}
          placeholder="Closed Date"
          onChange={handleChange}
          dayPickerProps={{ ...minPickerDate }}
        />
      </Grid>
      <StyledRoot item xs={12}>
        {content}
      </StyledRoot>
    </StyledContainer>
  )
}

export default EditDatesClosed
