import styled from '@emotion/styled'
import { grey } from 'config/themeConfig'
import Link from 'components/common/Link'
import Text from 'components/common/Text'

const StyledBreadcrumb = styled('div')(({ theme }) => ({
  display: 'flex',
  paddingBottom: theme.spacing(3),
  borderBottom: `1px solid ${grey[300]}`,
}))

const StyledSpacer = styled('span')(({ theme }) => ({
  margin: `0 ${theme.spacing(0.5)}`,
}))

export type BreadcrumbItem = {
  href?: string
  name: string
}

type Props = {
  breadcrumbs: BreadcrumbItem[]
}
export const Breadcrumb = ({ breadcrumbs }: Props) => {
  const lastIndex = breadcrumbs.length - 1

  return (
    <StyledBreadcrumb data-testid="breadcrumb">
      {breadcrumbs.map((item, index) => (
        <span key={item.name}>
          {item.href ? (
            <Link to={item.href}>{item.name}</Link>
          ) : (
            <Text key={item.name} component="span">
              {item.name}
            </Text>
          )}
          {index < lastIndex && <StyledSpacer>{'> '}</StyledSpacer>}
        </span>
      ))}
    </StyledBreadcrumb>
  )
}

export default Breadcrumb
