import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import isEmpty from 'lodash/fp/isEmpty'

import styled from '@emotion/styled'
import CheckIcon from '@mui/icons-material/Check'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

import FullScreenDialogContainer from 'components/common/Dialog/FullScreenDialogContainer'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import { PhoneNumber } from 'components/common/PhoneNumber'
import TitleBar from 'components/common/TitleBar'
import ShippingAddressContent from '../../ShippingInfoPage/ShippingAddressContent'

import { closeDialog } from 'store/dialog/actionCreator'

import { getSeller } from 'services/seller'
import {
  getSellerVerification,
  updateSellerVerificationStatus,
} from 'services/sellerVerification'

import { SmsSeller } from 'types/Seller'
import {
  SellerVerification,
  SellerVerificationAttribute,
  SellerVerificationAttributeName,
  SellerVerificationStatus,
} from 'types/SellerVerification'

const getAttributeDisplayName = (attributeName: string) => {
  switch (attributeName) {
    case SellerVerificationAttributeName.TAX_ID:
      return 'EIN:'
    case SellerVerificationAttributeName.LEGAL_NAME:
      return 'Legal Business Name'
    case SellerVerificationAttributeName.PRIMARY_ADDRESS:
      return 'Headquarters Address:'
    case SellerVerificationAttributeName.GUEST_SERVICES_EMAIL:
      return 'Email Address for Guest Inquiries:'
    case SellerVerificationAttributeName.HAS_GUEST_SERVICES_PHONE:
      return 'Do you have a phone number available for guest inquiry:'
    case SellerVerificationAttributeName.GUEST_SERVICES_PHONE:
      return 'Phone Number for Guest Inquiries:'
    case SellerVerificationAttributeName.MANUFACTURER_OF_CONSUMER_PRODUCT:
      return 'Manufacturer of Product'
    case SellerVerificationAttributeName.IMPORTER_OF_CONSUMER_PRODUCT:
      return 'Omporter of Product'
    case SellerVerificationAttributeName.RESELLER_OF_CONSUMER_PRODUCT:
      return 'Reseller of Product'
    case SellerVerificationAttributeName.IN_OTHER_MARKETPLACES:
      return 'In Other Marketplaces'
    case SellerVerificationAttributeName.HAS_BENEFICIAL_OWNERSHIP_INFORMATION:
      return 'Has beneficial ownership information (BOI) changed this year?'
    default:
      return ''
  }
}

const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}))

const StyledCheckIcon = styled(CheckIcon)(({ theme }) => ({
  paddingRight: theme.spacing(1),
}))

const StyledMailOutlineIcon = styled(MailOutlineIcon)(({ theme }) => ({
  paddingRight: theme.spacing(1),
}))

const StyledTypography = styled(Typography)({
  fontWeight: 'bold',
})

const StyledMarginBottom = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))

const StyledTitle = styled('div')({
  fontWeight: 400,
  fontSize: '1.375rem',
})

const StyledSubtitle = styled('h3')(({ theme }) => ({
  width: '100%',
  fontSize: '0.9375rem',
  fontWeight: 300,
  color: theme.palette.grey[700],
  marginTop: theme.spacing(0.5),
}))
export interface Props {
  isOpen: boolean
  sellerId: string
}

const ignoredAttributes = [
  SellerVerificationAttributeName.MANUFACTURER_OF_CONSUMER_PRODUCT,
  SellerVerificationAttributeName.IMPORTER_OF_CONSUMER_PRODUCT,
  SellerVerificationAttributeName.RESELLER_OF_CONSUMER_PRODUCT,
  SellerVerificationAttributeName.IN_OTHER_MARKETPLACES,
]

export const ReviewInformActAttributes = ({ isOpen, sellerId }: Props) => {
  const reduxDispatch = useDispatch()

  const [isPending, setIsPending] = useState(false)
  const [seller, setSeller] = useState<SmsSeller>()
  const [verification, setVerification] = useState<SellerVerification>()
  const [changedAttributes, setChangedAttributes] = useState<
    SellerVerificationAttribute[]
  >([])

  useEffect(() => {
    let mounted = true
    setIsPending(true)

    Promise.all([getSeller(sellerId), getSellerVerification(sellerId)])
      .then(([seller, verification]) => {
        if (mounted) {
          setSeller(seller)
          setVerification(verification)

          const filteredAttributes = verification.attributes.filter(
            (attribute) => {
              return (
                attribute.is_changed &&
                ignoredAttributes.indexOf(attribute.attribute_name) === -1
              )
            },
          )

          setChangedAttributes(filteredAttributes)
        }
      })

      .finally(() => {
        if (mounted) {
          setIsPending(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [sellerId])

  const handleRequestClose = () => {
    reduxDispatch(closeDialog())
  }

  const getAttributeContent = (
    attribute: SellerVerificationAttribute,
    updatedValue?: any,
  ) => {
    switch (attribute.attribute_name) {
      case SellerVerificationAttributeName.TAX_ID:
        return (
          <Typography>
            {updatedValue ? updatedValue : seller?.tax_id}
          </Typography>
        )
      case SellerVerificationAttributeName.LEGAL_NAME:
        return (
          <Typography>
            {updatedValue ? updatedValue : seller?.legal_business_name}
          </Typography>
        )
      case SellerVerificationAttributeName.PRIMARY_ADDRESS:
        return (
          <ShippingAddressContent
            address={updatedValue ? updatedValue : seller?.primary_address}
          />
        )
      case SellerVerificationAttributeName.GUEST_SERVICES_EMAIL:
        return (
          <Typography>
            {updatedValue ? updatedValue : seller?.guest_services_email}
          </Typography>
        )
      case SellerVerificationAttributeName.GUEST_SERVICES_PHONE:
        if (
          (updatedValue && isEmpty(updatedValue)) ||
          (!updatedValue && isEmpty(seller?.guest_services_phone_number))
        ) {
          return <Typography>N/A</Typography>
        } else {
          return (
            <PhoneNumber
              phone={
                updatedValue
                  ? updatedValue
                  : seller?.guest_services_phone_number
              }
            />
          )
        }
      case SellerVerificationAttributeName.HAS_GUEST_SERVICES_PHONE:
        return (
          <Typography data-testid="has-guest-services-phone">
            {typeof updatedValue == 'boolean'
              ? updatedValue
                ? 'Yes'
                : 'No'
              : seller?.has_guest_services_phone_number
              ? 'Yes'
              : 'No'}
          </Typography>
        )

      case SellerVerificationAttributeName.HAS_BENEFICIAL_OWNERSHIP_INFORMATION:
        return (
          <Typography data-testid="has-beneficial-ownership-information">
            {typeof updatedValue == 'boolean'
              ? updatedValue
                ? 'Yes'
                : 'No'
              : seller?.has_beneficial_ownership_information
              ? 'Yes'
              : seller?.has_beneficial_ownership_information === false
              ? 'No'
              : 'N/A'}
          </Typography>
        )
      default:
        return <></>
    }
  }

  const fieldList: EnhancedTableFieldType<SellerVerificationAttribute>[] = [
    {
      key: 'attribute_name',
      heading: 'Current Attribute',
      formatCell: (attribute) => {
        return (
          <>
            <StyledTypography>
              {getAttributeDisplayName(attribute.attribute_name)}
            </StyledTypography>
            {getAttributeContent(attribute)}
          </>
        )
      },
    },
    {
      key: 'id',
      heading: 'Changes',
      formatCell: (attribute) => {
        return (
          <>
            <StyledTypography>
              {getAttributeDisplayName(attribute.attribute_name)}
            </StyledTypography>
            {getAttributeContent(attribute, attribute.requested_value)}
          </>
        )
      },
    },
  ]

  const handleVerifySubmission = () => {
    if (seller && verification) {
      updateSellerVerificationStatus(
        seller.id,
        verification.id,
        SellerVerificationStatus.VERIFIED,
      ).then(() => {
        handleRequestClose()
      })
    }
  }

  const handleRequestResubmission = () => {
    if (seller && verification) {
      updateSellerVerificationStatus(
        seller.id,
        verification.id,
        SellerVerificationStatus.PENDING,
      ).then(() => {
        handleRequestClose()
      })
    }
  }

  const getResubmissionEmails = () => {
    const requestedBy = [
      ...new Set(
        changedAttributes.map(
          (changedAttribute) => changedAttribute.requested_by,
        ),
      ),
    ]

    return requestedBy.join(', ')
  }

  return (
    <FullScreenDialogContainer
      title="Review INFORM Act Attributes"
      isOpen={isOpen}
      onRequestClose={handleRequestClose}
    >
      <StyledTitle>
        <TitleBar
          title={`Attributes that Need Review for ${seller?.display_name}`}
          variant="inherit"
          hideDivider
          actionButtons={[
            <Button
              key="verify-submission"
              data-testid="verify-submission"
              onClick={handleVerifySubmission}
              color="primary"
              variant="contained"
            >
              <StyledCheckIcon />
              Verify Submission
            </Button>,
            <Link
              href={`mailto:${getResubmissionEmails()}?subject=ACTION REQUIRED: Request to Resubmit INFORM Act Attributes&body=Hello,%0D%0A%0D%0ADue to inaccuracies in the information submitted, we request that you complete and resubmit the INFORM Act Attribute Verification Form at https://plus-portal.target.com/inform-act.%0D%0A%0D%0AThank you,%0D%0ATarget Plus Team`}
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
            >
              <StyledButton
                variant="outlined"
                key="request-resubmission"
                data-testid="request-resubmission"
                onClick={handleRequestResubmission}
                color="primary"
              >
                <StyledMailOutlineIcon />
                Request Resubmission
              </StyledButton>
            </Link>,
          ]}
        />
      </StyledTitle>
      <StyledMarginBottom>
        <StyledSubtitle>Submitted by {getResubmissionEmails()}</StyledSubtitle>
      </StyledMarginBottom>

      <EnhancedTable
        isLoading={isPending}
        fieldList={fieldList}
        data={changedAttributes}
      />
    </FullScreenDialogContainer>
  )
}

export default ReviewInformActAttributes
