import { OrderDefectRateParams } from 'types/BiReporting'

export const ORDER_DEFECT_METRIC_TABLE = 'ORDER_DEFECT_METRIC_DIALOG'

export const getOrderDefectMetricTableQueryKeys = ({
  sellerId,
  vmmId,
  cardId,
  range,
  isCustomRange,
  type,
  trackingValue,
}: OrderDefectRateParams) => [
  ORDER_DEFECT_METRIC_TABLE,
  sellerId,
  vmmId,
  cardId,
  range,
  isCustomRange,
  type,
  trackingValue,
]
