import { OrderDefectMetricType } from './VendorStats'
import { Range } from 'components/common/DateRangePicker/rangeConfig'

export interface BiData {
  card_config: {
    card_attribute: {
      viz_type: string
      card_title: string
      y_axis?: BiAxisDefinition[]
      x_axis?: BiAxisDefinition
      dataset_last_load_date?: string
    }
    card_query_attribute: {
      time_period: {
        interval: string
        type: string
        granularity?: string
        calendar_type: string
        compare_by?: {
          type: string
          values: string[]
        }
      }
      columns: BiColumn[]
      row_order?: BiSort[]
      dataset_filters?: BiFilter[]
      viewer_filters: BiViewerFilter[]
    }
  }
  query: {
    intervals: string[]
  }
  query_results: Dictionary<any>[]
  rows_returned: number
}

export interface BiColumn {
  column_display_name: string
  type: string
  field_name: string
  format_string?: string
  ref_id: string
  data_type: string
  format_type: string
  format_precision?: string
  width?: string
  show_total?: boolean
}

export interface BiSort {
  direction: 'ascending' | 'descending'
  obj_type: string
  field_name: string
  ref_id: string
}

export interface BiFilter {
  column: string
  type: string
  pattern: string
  obj_type: string
}

export interface BiViewerFilter {
  dimension: string
  obj_type: string
  type: string
  display_name: string
  ref_id?: string
}

export interface BiReportingFilter {
  dimension: string
  pattern: string | string[]
  type: string
  display_name?: string
  obj_type?: string
}

export type BiReportingTimePeriodType = 'relative' | 'absolute'
export interface BiReportingTimePeriod {
  calendar_type: string
  granularity?: string
  interval: string
  type: BiReportingTimePeriodType
  compare_by?: {
    type: string
    values: string[]
  }
}

export interface BiAxisDefinition {
  ref_id: string
  column_display_name: string
  type: string
  field_name?: string
}

export enum BiReportingCards {
  DEFECT_CATEGORY = '696008',
  SALES_METRICS_GMV_OVER_TIME = '142131',
  OVERALL_CONVERSION_RATE_OVER_TIME = '441264',
  BEST_SELLERS = '88771',
  TOP_CONVERTING = '440163',
  MOST_RETURNED = '93165',
  OVERALL_ORDER_DEFECT_RATE = '290696',
  OVERALL_ORDER_DEFECT_RATE_LINE_CHART = '290742',
  LATE_DELIVERY = '290810',
  LATE_DELIVERY_LINE_CHART = '290817',
  LATE_DELIVERY_TABLE = '291081',
  LATE_DELIVERY_OVERRIDE = '658020',
  RETURN_RATE = '290808',
  RETURN_RATE_LINE_CHART = '290816',
  RETURN_RATE_TABLE = '315485',
  RETURN_RATE_OVERRIDE = '658014',
  CANCEL_RATE = '290790',
  CANCEL_RATE_LINE_CHART = '290814',
  CANCEL_RATE_TABLE = '315982',
  CANCEL_RATE_OVERRIDE = '658019',
  CONTACT_RATE = '290797',
  CONTACT_RATE_LINE_CHART = '290815',
  CONTACT_RATE_TABLE = '315969',
  CONTACT_RATE_OVERRIDE = '658017',
  ONBOARDING_FULLFILLMENT = '1362457',
  ONBOARDING_ITEM = '1362453',
  ONBOARDING_PERFORMANCE = '1362452',
}

export interface Scorecard {
  chScore: number
  dateCreated: string
  downloadLink: string
  skuCount: number
  templateName: string
}

export interface ScorecardGroup {
  templateName: string
  scorecards: Scorecard[]
  displayDate: string
  currentScore: number
  currentSkus: number
  currentDownloadLink: string
  previousScore: number
}

export interface ContentHealth {
  scorecardName: string
  scorecards: Scorecard[]
}

export interface ContentHealthError {
  code: number
  status: 'error'
  message: string
}

export type OrderDefectRateParams = {
  sellerId?: string
  vmmId?: string
  cardId: BiReportingCards
  range: Range
  isCustomRange: boolean
  type: OrderDefectMetricType
  trackingValue: 'detail' | 'overrides'
}
