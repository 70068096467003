import { useState, useEffect } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'

import {
  getExternalSellerReportsQueryKey,
  getPollSellerReportsQueryKey,
  EXTERNAL_SELLER_REPORTS,
} from './queries'

import {
  GenerateReportRequest,
  getReports,
  pollReport,
  submitReport,
  cancelReport,
} from 'services/reports'
import { Report, ReportStatus, ReportType } from 'types/Report'
import { CollectionResponse } from 'types/Response'
import { Direction } from 'services/pageableHelper'

type CancelReportParams = {
  sellerId: string
  reportId: string
}

export type Props = {
  sellerId?: string
  userEmail: string
  reportType?: ReportType
  page: number
  perPage: number
  orderBy: string | undefined
  direction: Direction | undefined
}

const useSellerReports = ({
  sellerId,
  userEmail,
  reportType,
  page,
  perPage,
  orderBy,
  direction,
}: Props) => {
  const [pendingReport, setPendingReport] = useState<Report>()

  const queryClient = useQueryClient()

  const { data: result, isLoading: isTableLoading } = useQuery(
    getExternalSellerReportsQueryKey({
      sellerId,
      userEmail,
      page,
      perPage,
      orderBy,
      direction,
    }),
    () => {
      if (sellerId) {
        return getReports(
          { page, perPage, orderBy, direction },
          { sellerId, createdBy: userEmail },
        )
      }
    },
  )

  useEffect(() => {
    // Start polling if the data already has a pending or processing report when it initially loads.
    const pollingReport = result?.data.find(
      (report) =>
        report.type === reportType &&
        (report.status === ReportStatus.PENDING ||
          report.status === ReportStatus.PROCESSING),
    )

    if (pollingReport && pendingReport === undefined) {
      setPendingReport(pollingReport)
    }
  }, [result, reportType, pendingReport])

  const { data: polledReport } = useQuery(
    getPollSellerReportsQueryKey({ sellerId, reportId: pendingReport?.id }),
    () => {
      if (pendingReport?.id && sellerId) {
        return pollReport(pendingReport.id, sellerId)
      }
      return null
    },
    {
      onSuccess: (newReport) => {
        const queryKey = getExternalSellerReportsQueryKey({
          sellerId,
          userEmail,
          page,
          perPage,
          orderBy,
          direction,
        })

        // Update the table data while we are polling to show the current loading state
        if (pendingReport) {
          queryClient.setQueryData<CollectionResponse<Report>>(
            queryKey,
            (oldData) => {
              const reports = oldData?.data ?? []

              const updatedReports = reports?.map((report) => {
                if (report.id === newReport?.id) {
                  return newReport
                }

                return report
              })

              return {
                data: updatedReports,
                total: oldData?.total ?? 0,
              }
            },
          )
        }
      },
      refetchInterval: (data) => {
        return pendingReport &&
          (data?.status === ReportStatus.PENDING ||
            data?.status === ReportStatus.PROCESSING)
          ? 5000
          : false
      },
    },
  )

  const { mutate: generateReportMutation } = useMutation<
    Report,
    any,
    GenerateReportRequest
  >(({ ...generateReportParams }) => {
    return submitReport(generateReportParams)
  })

  const { mutate: cancelPendingReportMutation } = useMutation<
    any,
    any,
    CancelReportParams
  >(({ sellerId, reportId }) => {
    return cancelReport({
      sellerId,
      reportId,
    })
  })

  const generateReport = (reportParams: GenerateReportRequest) => {
    const defaultConfig = {
      type: reportType,
      sellerId,
    }

    // Clone so request.sellerId does not override props.sellerId
    const payload = {
      ...defaultConfig,
      ...reportParams,
    }

    if (!payload.type) {
      return
    }

    generateReportMutation(payload, {
      onSuccess: (data) => {
        // Refetch Table Data and start polling to check the generation status of the new report
        queryClient.invalidateQueries([EXTERNAL_SELLER_REPORTS])
        setPendingReport(data)
      },
    })
  }

  const cancelPendingReport = ({ sellerId, reportId }: CancelReportParams) => {
    cancelPendingReportMutation(
      { sellerId, reportId },
      {
        onSuccess: () => {
          // cancel polling for the query with this provided reportId queryKey
          const pollQueryKey = getPollSellerReportsQueryKey({
            sellerId,
            reportId,
          })
          queryClient.cancelQueries(pollQueryKey)

          return queryClient.invalidateQueries([EXTERNAL_SELLER_REPORTS])
        },
      },
    )
  }

  const tableData = result?.data ?? []
  const tableTotal = result?.total ?? 0
  const isReportGenerating =
    !!polledReport &&
    (polledReport.status === ReportStatus.PENDING ||
      polledReport.status === ReportStatus.PROCESSING)

  return {
    tableData,
    tableTotal,
    isTableLoading,
    isReportGenerating,
    generateReport,
    cancelPendingReport,
  }
}

export default useSellerReports
