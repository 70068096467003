import { SmsAddress } from 'types/Address'
import { PhoneNumber } from 'types/SellerUser'

export type SellerVerification = {
  id: string
  seller_id: string
  status: SellerVerificationStatus
  attributes: SellerVerificationAttribute[]
  created: string
  created_by: string
  last_modified: string
  last_modified_by: string
  has_changes?: boolean
}

export enum SellerVerificationStatus {
  PENDING = 'PENDING',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  VERIFIED = 'VERIFIED',
}

export type SellerVerificationRequestedValue =
  | SmsAddress
  | PhoneNumber
  | string
  | boolean

export type SellerVerificationAttribute = {
  id: string
  seller_id: string
  verification_id: string
  verified: string
  verified_by: string
  is_changed: boolean
  attribute_name: SellerVerificationAttributeName
  requested_value?: SellerVerificationRequestedValue
  requested: string
  requested_by: string
}

export type SellerVerificationAttributeUpdate = Omit<
  SellerVerificationAttribute,
  | 'id'
  | 'seller_id'
  | 'verification_id'
  | 'verified'
  | 'verified_by'
  | 'is_changed'
  | 'requested'
  | 'requested_by'
>

export enum SellerVerificationAttributeName {
  TAX_ID = 'TAX_ID',
  LEGAL_NAME = 'LEGAL_NAME',
  PRIMARY_ADDRESS = 'PRIMARY_ADDRESS',
  GUEST_SERVICES_EMAIL = 'GUEST_SERVICES_EMAIL',
  HAS_GUEST_SERVICES_PHONE = 'HAS_GUEST_SERVICES_PHONE',
  GUEST_SERVICES_PHONE = 'GUEST_SERVICES_PHONE',
  MANUFACTURER_OF_CONSUMER_PRODUCT = 'MANUFACTURER_OF_CONSUMER_PRODUCT',
  IMPORTER_OF_CONSUMER_PRODUCT = 'IMPORTER_OF_CONSUMER_PRODUCT',
  RESELLER_OF_CONSUMER_PRODUCT = 'RESELLER_OF_CONSUMER_PRODUCT',
  IN_OTHER_MARKETPLACES = 'IN_OTHER_MARKETPLACES',
  HAS_BENEFICIAL_OWNERSHIP_INFORMATION = 'HAS_BENEFICIAL_OWNERSHIP_INFORMATION',
}

export interface VerificationSearchParams {
  seller_id?: string
  status?: SellerVerificationStatus | SellerVerificationStatus[]
}
