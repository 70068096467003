import { useEffect, useState } from 'react'

import styled from '@emotion/styled'

import Typography from '@mui/material/Typography'

import DataList from 'components/common/DataList'
import PictureZoomable from 'components/common/PictureZoomable'
import TableSpacer from 'components/common/TableSpacer'
import TabularData, { FieldList } from 'components/common/TabularData'
import Text from 'components/common/Text'
import WarningIcon from 'components/common/WarningIcon'

import {
  formatDate,
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
} from 'services/dateService'
import { getPublishedProductStatus } from 'services/itemHelper'
import { getEnrichedProductFields } from 'services/itemEnrichment'
import { decodeUriSafe } from 'services/urlHelper'

import { error, success } from 'config/themeConfig'

import { ITEM_FIELDS } from 'constants/itemFields'

import {
  MarketplaceProduct,
  SmsProduct,
  EnrichedPublishedProductField,
} from 'types/Item'

const StyledFlexContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}))

export type Props = {
  marketplaceProduct: MarketplaceProduct
  smsProduct: SmsProduct
}

const getFieldElement = (field: EnrichedPublishedProductField) => {
  const content = field.isMerchandiseTypeAttribute ? (
    <Text>
      {field.name} - {field.displayName}
    </Text>
  ) : (
    <Text>{field.displayName}</Text>
  )

  return content
}

const getValueElement = (field: EnrichedPublishedProductField) => {
  if (field.isImage) {
    return field.enrichedValue ? (
      <PictureZoomable src={field.enrichedValue} alt={`${field.name} image`} />
    ) : (
      ''
    )
  } else if (field.enrichedValue || field.rawValue) {
    const enrichedStyle =
      field.enrichedValue !== field.rawValue
        ? {
            color: success.main,
          }
        : undefined

    const rawNode = field.rawValue ? (
      field.rawValue
    ) : (
      <Text type="emphasis">None</Text>
    )
    const enrichedNode = field.enrichedValue ? (
      <span style={enrichedStyle}>{field.enrichedValue}</span>
    ) : (
      <Text type="emphasis">None</Text>
    )

    return (
      <div>
        <DataList
          data={[
            {
              title: 'Raw',
              element: rawNode,
            },
            {
              title: 'Enriched',
              element: enrichedNode,
            },
          ]}
        />
        {field.helperText && (
          <Text data-testid="helper-text" type="micro">
            {field.helperText}
          </Text>
        )}
      </div>
    )
  } else if (
    field.displayValue &&
    field.name === ITEM_FIELDS['descriptions.long_description'].fieldName
  ) {
    return <Typography>{decodeUriSafe(field.displayValue)}</Typography>
  } else {
    return <Typography>{field.displayValue}</Typography>
  }
}

const fieldList: FieldList<EnrichedPublishedProductField>[] = [
  {
    key: 'displayName',
    displayName: 'Field Name',
    width: 20,
    formatCell: (field) => getFieldElement(field),
  },
  {
    key: 'displayValue',
    displayName: 'Field Value',
    width: 70,
    formatCell: (field) => getValueElement(field),
  },
]

export const EnrichedPublishedData: React.FC<Props> = ({
  marketplaceProduct,
  smsProduct,
}) => {
  const [enrichedFields, setEnrichedFields] = useState<
    EnrichedPublishedProductField[]
  >([])

  const publishedProductStatus = getPublishedProductStatus(smsProduct)

  useEffect(() => {
    let mounted = true

    if (
      publishedProductStatus &&
      publishedProductStatus.version &&
      marketplaceProduct.tcin
    ) {
      getEnrichedProductFields(
        marketplaceProduct.tcin,
        publishedProductStatus.version,
        smsProduct.seller_id,
        smsProduct.product_id,
      ).then((res) => {
        if (mounted) {
          setEnrichedFields(res)
        }
      })
    }

    return () => {
      mounted = false
    }
  }, [marketplaceProduct, publishedProductStatus, smsProduct])

  return (
    <>
      <StyledFlexContainer>
        <WarningIcon>
          <Typography color={error.main}>
            This tab is for Internal Target Team Members only, please do not
            share this information or screenshots with Partners.
          </Typography>
        </WarningIcon>
      </StyledFlexContainer>
      <Typography>
        The published version on target.com was updated on{' '}
        {formatDate(
          publishedProductStatus?.last_modified,
          DATE_FORMAT_MONTH_DAY_YEAR_TIME,
        )}
      </Typography>
      <TableSpacer>
        <TabularData data={enrichedFields} fieldList={fieldList} />
      </TableSpacer>
    </>
  )
}

export default EnrichedPublishedData
