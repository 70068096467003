import RegistrationResult from 'types/RegistrationResult'

export const REGISTER_WITH_PAYMENT_SERVICE: string =
  'REGISTER_WITH_PAYMENT_SERVICE'
export const REGISTER_WITH_PAYMENT_SERVICE_ERROR: string =
  'FETCH_REGISTER_WITH_PAYMENT_SERVICE_ERROR'
export const CONFIRM_PAYMENT_ACCOUNT: string = 'FETCH_CONFIRM_PAYMENT_ACCOUNT'
export const CONFIRM_PAYMENT_ACCOUNT_DONE: string =
  'FETCH_CONFIRM_PAYMENT_ACCOUNT_DONE'
export const RESET_PAYMENT_ACCOUNT: string = 'RESET_PAYMENT_ACCOUNT'

export interface RegisterWithPaymentServiceAction {
  readonly type: typeof REGISTER_WITH_PAYMENT_SERVICE
  payload: {
    redirectPath: string
  }
}

export interface RegisterWithPaymentServiceErrorAction {
  readonly type: typeof REGISTER_WITH_PAYMENT_SERVICE_ERROR
}

export interface ConfirmPaymentAccountAction {
  readonly type: typeof CONFIRM_PAYMENT_ACCOUNT
  payload: {
    sellerId: string
    registrationResult: RegistrationResult
  }
}

export interface ConfirmPaymentAccountDoneAction {
  readonly type: typeof CONFIRM_PAYMENT_ACCOUNT_DONE
  payload: {
    isConfirmed: boolean
  }
}

export interface ResetPaymentAccountAction {
  readonly type: typeof RESET_PAYMENT_ACCOUNT
}

export function registerWithPaymentService(
  redirectPath: string,
): RegisterWithPaymentServiceAction {
  return {
    type: REGISTER_WITH_PAYMENT_SERVICE,
    payload: {
      redirectPath,
    },
  }
}

export function registerWithPaymentServiceError(): RegisterWithPaymentServiceErrorAction {
  return {
    type: REGISTER_WITH_PAYMENT_SERVICE_ERROR,
  }
}

export function confirmPaymentAccount(
  sellerId: string,
  registrationResult: RegistrationResult,
): ConfirmPaymentAccountAction {
  return {
    type: CONFIRM_PAYMENT_ACCOUNT,
    payload: {
      sellerId,
      registrationResult,
    },
  }
}

export function confirmPaymentAccountDone(
  isConfirmed: boolean,
): ConfirmPaymentAccountDoneAction {
  return {
    type: CONFIRM_PAYMENT_ACCOUNT_DONE,
    payload: {
      isConfirmed,
    },
  }
}

export function resetPaymentAccount() {
  return {
    type: RESET_PAYMENT_ACCOUNT,
  }
}

export type PaymentAccountActions =
  | RegisterWithPaymentServiceAction
  | RegisterWithPaymentServiceErrorAction
  | ConfirmPaymentAccountAction
  | ConfirmPaymentAccountDoneAction
  | ResetPaymentAccountAction
