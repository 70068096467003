import Link from 'components/common/Link'

import DataList, { DataListItem } from 'components/common/DataList'

import { MarketplaceProduct, ListingStatus } from 'types/Item'

import SimpleMessage from './SimpleMessage'
import { ThemeAlignment } from './ThemeAlignment'
import VariationUniqueness from './VariationUniqueness'

export interface Props {
  product: MarketplaceProduct
  children: MarketplaceProduct[]
  parent: MarketplaceProduct
  parentCurrentStatus: ListingStatus | undefined
}

export const VariationOverview = ({
  product,
  children,
  parent,
  parentCurrentStatus,
}: Props) => {
  const { variation_theme: variationTheme } = parent
  const refinements = variationTheme
    ? variationTheme.split(/\s?&\s?|\s?and\s?/)
    : []

  const uniquenessElement = (
    <VariationUniqueness
      refinements={refinements}
      productId={product.product_id}
      relationshipType={product.relationship_type}
      variationChildren={children}
    />
  )
  const alignmentElement = (
    <ThemeAlignment
      refinements={refinements}
      product={product}
      variationChildren={children}
      variationTheme={variationTheme}
    />
  )

  const parentTcinElement = (
    <Link
      target="_blank"
      to={`${product.seller_id}/all-items/${product.parent_product_id}`}
    >
      {product.parent_tcin}
    </Link>
  )

  const parentStatusElement = parentCurrentStatus ? (
    <SimpleMessage
      isError={
        parentCurrentStatus === ListingStatus.APPROVED ? false : undefined
      }
      message={parentCurrentStatus}
    />
  ) : undefined

  const data: DataListItem[] = [
    {
      title: `${children.length} Total VCs`,
    },
    {
      title: 'Variation Theme Alignment:',
      element: alignmentElement,
    },
    {
      title: 'Variation Uniqueness:',
      element: uniquenessElement,
    },
  ]

  if (product.relationship_type === 'VC') {
    data.push({
      title: 'VAP TCIN:',
      element: parentTcinElement,
    })

    data.push({
      title: 'VAP Latest Listing Status:',
      element: parentStatusElement,
    })
  }

  return <DataList data={data} />
}

export default VariationOverview
