import React from 'react'

import { target } from 'config/themeConfig'

import MuiStep from '@mui/material/Step'
import MuiStepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector'
import { StepIconProps } from '@mui/material/StepIcon'
import MuiStepLabel from '@mui/material/StepLabel'
import MuiStepper from '@mui/material/Stepper'

import CompleteStepIcon from '@mui/icons-material/CheckBox'
import CurrentStepIcon from '@mui/icons-material/CheckBoxOutlined'
import FutureStepIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import styled from '@emotion/styled'

const StyledContainer = styled('div')({
  width: '100%',
})

const StyledMuiStepper = styled(MuiStepper)(({ theme }) => ({
  padding: `${theme.spacing(2)} 0`,
})) as typeof MuiStepper

const StyledCompleteStepIcon = styled(CompleteStepIcon)({
  color: target.main,
})

const StyledCurrentStepIcon = styled(CurrentStepIcon)({
  color: target.main,
})

const StyledStepConnector = styled(MuiStepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: target.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: target.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.grey[500],
    borderTopWidth: 2,
    borderRadius: 1,
  },
}))

export interface Props {
  className?: { container?: string; stepper?: string }
  steps: Array<string | React.ReactNode>
  currentStep: number
}

export const Stepper = ({ steps, currentStep }: Props) => {
  const stepIcon = (props: StepIconProps) => {
    if (props.completed) {
      return <StyledCompleteStepIcon />
    }
    if (props.active) {
      return <StyledCurrentStepIcon />
    }

    return <FutureStepIcon />
  }

  return (
    <StyledContainer>
      <StyledMuiStepper
        alternativeLabel
        activeStep={currentStep}
        connector={<StyledStepConnector />}
      >
        {steps.map((label, index) => (
          <MuiStep key={index}>
            <MuiStepLabel StepIconComponent={stepIcon}>{label}</MuiStepLabel>
          </MuiStep>
        ))}
      </StyledMuiStepper>
    </StyledContainer>
  )
}

export default Stepper
