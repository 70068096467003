import Typography from '@mui/material/Typography'

export interface Props {
  content: string
}
export const ContentWithLineBreaks = ({ content }: Props) => {
  return (
    <>
      {content.split(/\r?\n|\r/).map((i, index) => {
        return (
          <Typography
            paragraph
            data-testid={`content${index}`}
            key={index}
            variant="body1"
          >
            {i}
          </Typography>
        )
      })}
    </>
  )
}

export default ContentWithLineBreaks
