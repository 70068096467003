import React, { useEffect, useState } from 'react'

import capitalize from 'lodash/fp/capitalize'
import isArray from 'lodash/fp/isArray'
import isPlainObject from 'lodash/fp/isPlainObject'
import keys from 'lodash/fp/keys'
import orderBy from 'lodash/fp/orderBy'

import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { parseISO } from 'date-fns'

import DisplayCard from 'components/common/DisplayCard'
import TabularData, { FieldList } from 'components/common/TabularData'

import {
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
  formatDate,
} from 'services/dateService'
import { getHeliosItemList, getHeliosItem } from 'services/helios'
import { getSmsProduct } from 'services/items'
import { Direction } from 'services/pageableHelper'

import { HeliosListItem, MerchTypeAttribute } from 'types/HeliosItem'
import { MarketplaceProduct, ProductStatus, ProductError } from 'types/Item'

const StyledFieldLabel = styled(Typography)({
  fontWeight: 'bold',
  display: 'inline',
})

const StyledVersionData = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}))

const StyledContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(0),
}))
const StyledIndent = styled(Grid)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}))

// https://stackoverflow.com/questions/34727936/typescript-bracket-notation-property-access
export interface HeliosItemIndexable {
  [key: string]: any
}

export interface Props {
  item: MarketplaceProduct
  page: number
  onChangePage: (event: any, page?: number) => void
}

const formatLabel = (label: string) => {
  if (label.includes('(MTA):')) {
    return label
  }
  if (label.includes(' no format')) {
    return label.replace(' no format', '')
  }
  const parts = label.split('_').map((part) => {
    if (part === 'id') {
      return 'ID'
    }
    return capitalize(part)
  })
  return parts.join(' ')
}

export const ItemVersionsTable = ({ item, page, onChangePage }: Props) => {
  const [totalItemVersions, setTotalItemVersions] = useState<number>()
  const [itemVersionsList, setItemVersionsList] = useState<HeliosListItem[]>([])
  const [itemVersions, setItemVersions] = useState<HeliosItemIndexable[]>([])
  const [fieldList, setFieldList] = useState<FieldList<any>[]>([
    {
      key: 'field',
      displayName: 'Version Attributes',
      width: 30,
      formatCell: (value) => formatLabel(value.field),
    },
  ])
  const [itemDataFields, setItemDataFields] = useState<Array<string>>([])
  const [itemVersionData, setItemVersionData] = useState<Array<{}>>([])
  const [itemStatuses, setItemStatuses] = useState<ProductStatus[]>([])

  useEffect(() => {
    setItemVersionsList([])
    setItemVersions([])
    setFieldList([
      {
        key: 'field',
        displayName: 'Version Attributes',
        width: 30,
        formatCell: (value) => formatLabel(value.field),
        order: 9999,
      },
    ])
    setItemDataFields([])
    setItemVersionData([])
    setItemStatuses([])

    const pagingParams = {
      page: page,
      pageSize: 4,
      order: Direction.DESC,
      sort: 'version',
    }

    const searchParams = { tcin: item.tcin }

    const fetchHeliosItemList = async () => {
      const heliosItemList = await getHeliosItemList(searchParams, pagingParams)
      setTotalItemVersions(heliosItemList.total)
      setItemVersionsList(heliosItemList.data)
    }

    const fetchSmsProductStatuses = async () => {
      const smsProductWithStatus = await getSmsProduct({
        sellerId: item.seller_id,
        productId: item.id,
        params: { expand: 'product_statuses' },
      })
      setItemStatuses(smsProductWithStatus.product_statuses)
    }

    fetchHeliosItemList()
    fetchSmsProductStatuses()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, page])

  useEffect(() => {
    const renderArray = (array: any) => {
      const itemProperties = array.map((value: any) => {
        if (isPlainObject(value)) {
          return renderObject(value)
        } else {
          return value
        }
      })

      return itemProperties
    }

    const renderObject = (obj: any) => {
      const itemProperties = keys(obj).map((field: string) => {
        const value = obj[field]
        if (isArray(value)) {
          return (
            <Grid container key={field}>
              <Grid item xs={12}>
                <Typography sx={{ fontWeight: 'bold' }}>
                  {formatLabel(field)}:
                </Typography>
              </Grid>
              <StyledIndent item xs={12} key={field}>
                {renderArray(value)}
              </StyledIndent>
            </Grid>
          )
        } else if (isPlainObject(value)) {
          return renderNestedValue(field, value)
        } else {
          return renderSimpleValue(field, value)
        }
      })

      return itemProperties
    }

    const renderNestedValue = (field: string, value: any) => {
      return (
        <Grid container key={field}>
          <Grid item xs={12}>
            <Typography>{formatLabel(field)}:</Typography>
          </Grid>
          <StyledIndent item xs={12}>
            {renderObject(value)}
          </StyledIndent>
        </Grid>
      )
    }

    const renderSimpleValue = (field: string | null, value: any) => {
      if (field) {
        return (
          <StyledVersionData key={field}>
            <React.Fragment>
              <StyledFieldLabel>{formatLabel(field)}: </StyledFieldLabel>
              {value.toString()}
            </React.Fragment>
          </StyledVersionData>
        )
      }
      return <StyledVersionData>{value}</StyledVersionData>
    }

    if (itemVersionsList) {
      const fetchItemVersionDetails = async (item: HeliosListItem) => {
        const heliosItem = await getHeliosItem({
          tcin: item.tcin,
          version: item.version,
        })
        setItemVersions((i) => [...i, heliosItem])
        setFieldList((f) => [
          ...f,
          {
            key: `${heliosItem.version}`,
            displayName: `Version ${heliosItem.version}`,
            width: 30,
            formatCell: (value) => {
              if (!heliosItem.version || !value[heliosItem.version]) return

              const versionValue = value[heliosItem.version]

              if (!isArray(versionValue) && !isPlainObject(versionValue)) {
                return renderSimpleValue(null, versionValue)
              } else if (isArray(versionValue)) {
                return renderArray(versionValue)
              } else {
                return renderObject(versionValue)
              }
            },
            order: heliosItem.version,
          },
        ])
        let mtaItemDataFields: Array<string> = []
        heliosItem.merchandise_type_attributes.forEach((mta) => {
          mtaItemDataFields.push(`${mta.name} (MTA):`)
        })
        setItemDataFields((i) => [
          ...new Set([
            ...i,
            ...Object.keys(heliosItem),
            ...mtaItemDataFields,
            'version_errors',
            'updated_by',
          ]),
        ])
      }
      itemVersionsList.forEach((item) => {
        fetchItemVersionDetails(item)
      })
    }
  }, [itemVersionsList])

  useEffect(() => {
    setItemVersionData([])
    const allowedList: Array<string> = [
      'image_review',
      'next_enrichment',
      'child_items',
      'product_vendors',
      'purchase_limit',
      'relationship_type',
      'relationship_type_code',
      'tcin',
      'enrichment',
      'validation_errors',
      'version',
      'merchandise_type_attributes',
    ]

    const dateFields: Array<string> = [
      'source_timestamp',
      'created_timestamp',
      'intended_launch_date',
      'latest_update_timestamp',
    ]

    const getFieldOrder = (field: string) => {
      switch (field) {
        case 'status':
          return 1
        case 'latest_update_timestamp':
          return 2
        case 'updated_by':
          return 3
        case 'version_errors':
          return 99
        default:
          return 98
      }
    }

    itemDataFields.forEach((itemDataField) => {
      if (!allowedList.includes(itemDataField)) {
        let data: HeliosItemIndexable = { field: itemDataField }
        itemVersions.forEach((itemVersion) => {
          if (itemDataField.includes('(MTA):')) {
            data[itemVersion.version] =
              itemVersion.merchandise_type_attributes.filter(
                (mta: MerchTypeAttribute) => {
                  if (mta.name) {
                    return mta.name.includes(
                      itemDataField.replace(' (MTA):', ''),
                    )
                  } else {
                    return null
                  }
                },
              )
          } else if (itemDataField === 'version_errors') {
            const versionStatus = itemStatuses.find(
              (status: ProductStatus) => status.version === itemVersion.version,
            )
            if (versionStatus) {
              data[itemVersion.version] = versionStatus.errors.map(
                (error: ProductError) => {
                  if (!error.field_name) {
                    return { System: error.reason }
                  } else {
                    return { [`${error.field_name} no format`]: error.reason }
                  }
                },
              )
            }
          } else if (itemDataField === 'status') {
            if (
              item.published &&
              itemVersion.version === item.published_helios_version
            ) {
              data[itemVersion.version] = 'PUBLISHED'
            } else {
              data[itemVersion.version] = itemVersion[itemDataField]
            }
          } else if (itemDataField === 'updated_by') {
            const versionStatus = itemStatuses.find(
              (status: ProductStatus) => status.version === itemVersion.version,
            )
            if (versionStatus) {
              data[itemVersion.version] = versionStatus.last_modified_by
            } else {
              data[itemVersion.version] = ''
            }
          } else if (dateFields.includes(itemDataField)) {
            const parsedDate = parseISO(itemVersion[itemDataField])
            data[itemVersion.version] = formatDate(
              parsedDate,
              DATE_FORMAT_MONTH_DAY_YEAR_TIME,
            )
          } else {
            data[itemVersion.version] = itemVersion[itemDataField]
          }
          data.order = getFieldOrder(itemDataField)
        })
        setItemVersionData((d) => [...d, data])
      }
    })
  }, [itemDataFields, itemVersions, itemStatuses, item])

  const handlePrevPage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    onChangePage(event, page - 1)
  }

  const handleNextPage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    onChangePage(event, page + 1)
  }

  return (
    <StyledContainer container spacing={5}>
      <Grid item xs={12}>
        {fieldList && totalItemVersions && (
          <DisplayCard title={`Total Number of Versions: ${totalItemVersions}`}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Button
                  color="primary"
                  onClick={handlePrevPage}
                  disabled={page < 1}
                >
                  PREV
                </Button>
                <Button
                  color="primary"
                  onClick={handleNextPage}
                  disabled={page > Math.floor(totalItemVersions / 4) - 1}
                >
                  NEXT
                </Button>
              </Grid>
            </Grid>
            <TabularData
              fieldList={orderBy('order', 'desc', fieldList)}
              data={orderBy('order', 'asc', itemVersionData)}
            />
          </DisplayCard>
        )}
      </Grid>
    </StyledContainer>
  )
}

export default ItemVersionsTable
