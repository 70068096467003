import { useSelector } from 'react-redux'

import HeaderTitle from 'components/common/HeaderTitle'
import ContentSpacer from 'components/common/ContentSpacer'

import BarcodeRelease from './BarcodeRelease'
import BarcodeChange from './BarcodeChange'

import { isOneOfUserRoles } from 'services/authorization'
import {
  USER_ROLE_ADMIN,
  USER_ROLE_ITEM_MANAGER,
  USER_ROLE_PROGRAM_MANAGER,
} from 'services/roles'

import { getMemberOf } from 'store/selectors'

export const BarcodeReleasePage = () => {
  const memberOf = useSelector(getMemberOf)

  const hasBarcodeRelease = (memberOf: string[]) =>
    isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN, USER_ROLE_PROGRAM_MANAGER])

  const hasBarcodeChange = (memberOf: string[]) =>
    isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN, USER_ROLE_ITEM_MANAGER])

  return (
    <div data-testid="barcode-release">
      <HeaderTitle title="Barcode Release" />
      {hasBarcodeRelease(memberOf) && (
        <>
          <BarcodeRelease />
          <ContentSpacer />
        </>
      )}

      {hasBarcodeChange(memberOf) && <BarcodeChange />}
    </div>
  )
}

export default BarcodeReleasePage
