import * as yup from 'yup'
import { withFormik, Form, FormikProps } from 'formik'
import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import SearchIcon from '@mui/icons-material/Search'

import InputField from 'components/common/form/InputField'

import { ValidationSchema } from 'services/validation'

interface FormValues {
  barcodes: string
}

interface ComponentProps {
  handleSubmit?: any
}

type Props = ComponentProps & FormikProps<FormValues>

const validationSchema: ValidationSchema = yup.object().shape({
  barcodes: yup
    .string()
    .label('barcodes')
    .test('len', 'Error: Only up to 100 barcodes can be entered', (val) => {
      if (!val) {
        return true
      }
      return val.split(/\r\n|\r|\n|\s/).length < 101
    }),
})

const StyledGridContainer = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
})

const StyledNextButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}))

const BarcodeReleaseInputForm = ({ handleSubmit, isValid, dirty }: Props) => {
  const startAdornment = <SearchIcon color="primary" />
  return (
    <StyledGridContainer container>
      <Grid item xs={9}>
        <Form>
          <InputField
            data-testid="barcode-input"
            name="barcodes"
            placeholder="Enter Barcodes (paste in rows)"
            enableOnChangeValidation
            variant="outlined"
            InputProps={{
              startAdornment,
              sx: { fontSize: 14 },
            }}
          />
        </Form>
      </Grid>
      <Grid item xs={3}>
        <StyledNextButton
          data-testid="submit-barcodes"
          onClick={handleSubmit}
          disabled={!isValid || !dirty}
          variant="contained"
          color="primary"
        >
          NEXT
        </StyledNextButton>
      </Grid>
    </StyledGridContainer>
  )
}

export const BarcodeReleaseInput = withFormik<ComponentProps, FormValues>({
  mapPropsToValues: () => ({
    barcodes: '',
  }),
  handleSubmit: async (values, { props }) => {
    const { barcodes } = values
    const { handleSubmit } = props

    handleSubmit(barcodes.trim().split(/\r\n|\r|\n|\s/))
  },
  enableReinitialize: true,
  validationSchema,
})(BarcodeReleaseInputForm)

export default BarcodeReleaseInput
