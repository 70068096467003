import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import Grid from '@mui/material/Grid'

import Input from 'components/common/Input'

import { ShippingMethod } from 'types/ShippingMethods'
import { Validation } from 'types/Validation'

export interface Props {
  shippingMethod: Nullable<ShippingMethod>
  method: string
  carrier: string
  scac: string
  csc: string
  validation: Validation
  isValid: boolean
  onRequestCancel: () => void
  onRequestChange: ({
    type,
  }: {
    type: string
  }) => (value: string | undefined) => void
  onRequestSubmit: () => void
}

export const EditShippingMethodsAside = ({
  shippingMethod,
  method,
  carrier,
  scac,
  csc,
  validation,
  isValid,
  onRequestCancel,
  onRequestChange,
  onRequestSubmit,
}: Props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input
              id="method"
              name="method"
              value={method}
              isDisabled={!!(shippingMethod && shippingMethod.id)}
              validation={validation}
              onChange={onRequestChange({ type: 'method' })}
              helperText="Shipping Method *"
              inputProps={{
                maxLength: 30,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              id="carrier"
              name="carrier"
              value={carrier}
              validation={validation}
              onChange={onRequestChange({ type: 'carrier' })}
              helperText="Carrier *"
              inputProps={{
                maxLength: 20,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              id="scac"
              name="scac"
              value={scac}
              validation={validation}
              onChange={onRequestChange({ type: 'scac' })}
              helperText="SCAC *"
              inputProps={{
                maxLength: 20,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              id="csc"
              name="csc"
              value={csc}
              validation={validation}
              onChange={onRequestChange({ type: 'csc' })}
              helperText="CSC *"
              inputProps={{
                maxLength: 20,
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DialogActions>
              <Button
                color="primary"
                onClick={onRequestCancel}
                data-testid="cancel"
              >
                cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={onRequestSubmit}
                disabled={!isValid}
                data-testid="submit"
              >
                save
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EditShippingMethodsAside
