import { HttpError, ErrorResponse } from 'types/HttpError'
import { isHttpError } from 'types/Guards'

const ERROR_MESSAGE_GENERIC = 'Something went wrong.'

export function formatHttpError(error: ErrorResponse | HttpError): HttpError {
  if (isHttpError(error)) {
    return error
  }

  const message =
    error.response.data && error.response.data.message
      ? error.response.data.message
      : ERROR_MESSAGE_GENERIC
  let errors =
    error.response.data && error.response.data.errors
      ? error.response.data.errors
      : []

  errors = errors.map((error: any) => {
    if (typeof error === 'string') return error

    if (typeof error === 'object' && error.reason) {
      return error.reason
    }

    return 'Could not parse error message'
  })

  // Axios errors need to be shaped into an HttpError
  return {
    code: error.response.status,
    errors,
    level: 'error',
    message,
    type: 'http',
  }
}
