import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'

import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'

import EnhancedTable from 'components/common/EnhancedTable'
import { PAGINATION } from 'components/common/EnhancedTable/EnhancedTablePagination'
import FilterBar from 'components/common/FilterBar'
import {
  useSearchParams,
  TableState,
  getEnhancedTablePageableProps,
} from 'components/common/FilterBar/useSearchParams'
import TypeaheadFilter from 'components/common/FilterBar/TypeaheadFilter'
import SelectFilter from 'components/common/FilterBar/SelectFilter'
import MultiSelectFilter from 'components/common/FilterBar/MultiSelectFilter'
import HeaderTitle from 'components/common/HeaderTitle'
import LabeledDataList from 'components/common/LabeledDataList'
import TableSpacer from 'components/common/TableSpacer'

import { formatLocaleNumber } from 'services/formatNumber'
import { getProductCount, getMarketplaceProducts } from 'services/items'
import { Direction } from 'services/pageableHelper'
import {
  getOptionalPublishedToggleValue,
  getOptionalToggleValue,
} from 'services/toggle'

import { getEnumerationValues, currentSellerId } from 'store/selectors'

import {
  getAllItemsQueryKeys,
  getSellerIdQueryKeys,
  PRODUCT_COUNT_PUBLISHED,
  PRODUCT_COUNT_TOTAL,
} from './queries'

import { EnumerationName } from 'types/Enumeration'
import { ListingStatus, RelationshipType } from 'types/Item'
import StoreState from 'types/state'

import { getItemsTableFieldList } from './getItemsTableFieldList'

const StyledTitleContainer = styled(Grid)({
  display: 'flex',
})

const StyledTitle = styled('div')({
  flexGrow: 1,
})

type SearchParams = TableState & {
  item_type_id: string | undefined
  published: string | undefined
  current_listing_status: string[] | undefined
  listing_status: string[] | undefined
  has_inventory: string | undefined
  relationship_type: string | undefined
}

const initialSearchParams: SearchParams = {
  perPage: 20,
  page: 0,
  orderBy: 'last_modified',
  direction: Direction.DESC,
  item_type_id: undefined,
  published: undefined,
  current_listing_status: undefined,
  listing_status: undefined,
  has_inventory: undefined,
  relationship_type: undefined,
}

const fieldList = getItemsTableFieldList()

export const AllItemsPage = () => {
  const sellerId = useSelector(currentSellerId) ?? ''
  const listingStatusEnumeration = useSelector((state: StoreState) =>
    getEnumerationValues(state, EnumerationName.LISTING_STATUS),
  )

  const [searchParams, searchParamActions, appliedFilterCount] =
    useSearchParams<SearchParams>(initialSearchParams)

  const unlistedSelected = searchParams.listing_status?.includes(
    ListingStatus.UNLISTED,
  )
  const listingStatuses = listingStatusEnumeration.map((status) => {
    return {
      name: status,
      value: status,
      disabled:
        (unlistedSelected && status !== ListingStatus.UNLISTED) ||
        (!unlistedSelected &&
          searchParams.listing_status?.length &&
          status === ListingStatus.UNLISTED)
          ? true
          : false,
    }
  })

  const { data: items, isLoading } = useQuery(
    getAllItemsQueryKeys(searchParams, sellerId),
    () => {
      const published = getOptionalPublishedToggleValue(searchParams.published)
      const inventory = getOptionalToggleValue(searchParams.has_inventory)

      return getMarketplaceProducts(
        {
          seller_id: sellerId,
          item_type_id: searchParams.item_type_id,
          listing_status: unlistedSelected
            ? undefined
            : searchParams.listing_status,
          current_listing_status: unlistedSelected
            ? [ListingStatus.UNLISTED]
            : undefined,
          relationship_type: searchParams.relationship_type,
          published,
          has_inventory: inventory,
        },
        {
          page: searchParams.page,
          perPage: searchParams.perPage,
          orderBy: searchParams.orderBy,
          direction: searchParams.direction,
        },
      )
    },
  )

  const { data: publishedProductCount = 0 } = useQuery(
    getSellerIdQueryKeys(PRODUCT_COUNT_PUBLISHED, sellerId),
    () => getProductCount({ seller_id: sellerId, published: true }),
  )

  const { data: totalProductCount = 0 } = useQuery(
    getSellerIdQueryKeys(PRODUCT_COUNT_TOTAL, sellerId),
    () => getProductCount({ seller_id: sellerId }),
  )

  const handleClearFilter = () => {
    searchParamActions.updateSearchParam(initialSearchParams)
  }

  const labeledData = [
    { label: 'Total Items:', data: formatLocaleNumber(totalProductCount) },
    {
      label: 'Items Published:',
      data: formatLocaleNumber(publishedProductCount),
    },
  ]

  const total = items?.total ?? 0

  return (
    <div>
      <HeaderTitle title="All Items" />
      <Grid container spacing={2}>
        <StyledTitleContainer item xs={12}>
          <StyledTitle>
            <LabeledDataList
              data={labeledData}
              data-testid="labeled-data-list"
            />
          </StyledTitle>
        </StyledTitleContainer>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FilterBar
            count={items?.total}
            onClear={handleClearFilter}
            appliedFilterCount={appliedFilterCount}
          >
            <Grid item xs={12} lg={2}>
              <TypeaheadFilter
                value={searchParams.item_type_id}
                searchParam="item_type_id"
                onChange={searchParamActions.updateSearchParam}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <MultiSelectFilter
                placeholder="Data Update Status"
                searchParam="listing_status"
                value={searchParams.listing_status}
                data={listingStatuses}
                onChange={searchParamActions.updateSearchParam}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <SelectFilter
                placeholder="Publish Status"
                value={searchParams.published}
                searchParam="published"
                selectionTitle="Published: "
                onChange={searchParamActions.updateSearchParam}
                data={[
                  {
                    name: 'Yes',
                    value: 'Yes',
                  },
                  {
                    name: 'No',
                    value: 'No',
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <SelectFilter
                placeholder="Inventory Status"
                value={searchParams.has_inventory}
                searchParam="has_inventory"
                selectionTitle="Inventory Status: "
                onChange={searchParamActions.updateSearchParam}
                data={[
                  {
                    name: 'In Stock',
                    value: 'true',
                  },
                  {
                    name: 'Out of Stock',
                    value: 'false',
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <MultiSelectFilter
                placeholder="Relationship"
                searchParam="relationship_type"
                value={searchParams.relationship_type}
                data={[
                  {
                    name: 'VAPs',
                    value: RelationshipType.VAP,
                  },
                  {
                    name: 'VCs',
                    value: RelationshipType.VC,
                  },
                  {
                    name: 'SAs',
                    value: RelationshipType.SA,
                  },
                ]}
                onChange={searchParamActions.updateSearchParam}
              />
            </Grid>
          </FilterBar>
          <TableSpacer>
            <EnhancedTable
              data-testid="table"
              data={items?.data ?? []}
              fieldList={fieldList}
              isLoading={isLoading}
              {...getEnhancedTablePageableProps(
                searchParams,
                searchParamActions,
              )}
              paginationDisplayLocation={PAGINATION.BOTTOM}
              paginationDisabled={total <= searchParams.perPage}
              total={total}
            />
          </TableSpacer>
        </Grid>
      </Grid>
    </div>
  )
}

export default AllItemsPage
