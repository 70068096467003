import sortBy from 'lodash/fp/sortBy'
import map from 'lodash/fp/map'
import filter from 'lodash/fp/filter'
import flow from 'lodash/fp/flow'

import styled from '@emotion/styled'

import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'

import RequiredContent from 'components/common/RequiredContent'

import {
  formatDateString,
  getDateBefore,
  DATE_PICKER_FORMAT,
  DATE_DISPLAY_FORMAT,
} from 'services/dateService'

const StyledChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
  marginLeft: 0,
}))

const filterDates = (dateStrings: string[], compareDate: Date) => {
  return flow(
    sortBy((date: string) => new Date(date)),
    map((i) => formatDateString(i, DATE_PICKER_FORMAT, DATE_DISPLAY_FORMAT)),
    filter((item: string) => compareDate < new Date(item)),
  )(dateStrings)
}

export const getClosedDates = (dateStrings?: string[]) => {
  if (!dateStrings) return

  const todayMinusOneYear = getDateBefore(new Date(), 1, 'years')

  return filterDates(dateStrings, todayMinusOneYear)
}

interface Props {
  daysClosed?: string[]
}

export const DatesClosedContent = ({ daysClosed }: Props) => {
  const sortedDaysClosed = getClosedDates(daysClosed)

  if (!daysClosed?.length || !sortedDaysClosed || !sortedDaysClosed.length) {
    return (
      <RequiredContent hasRequiredText={false} description="None provided." />
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {sortedDaysClosed.map((date: string, index: number) => (
          <StyledChip key={index} label={date} />
        ))}
      </Grid>
    </Grid>
  )
}

export default DatesClosedContent
