import { useEffect, useState } from 'react'

import styled from '@emotion/styled'

import Chip from '@mui/material/Chip'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import ContentSpacer from 'components/common/ContentSpacer'
import DialogContainer from 'components/common/Dialog/DialogContainer'

import { statusColor } from '../index'

import { AttributeValueRequest } from 'types/AttributeValue'

import { getSellerRequestedValueDetail } from 'services/securityGateway'

const StyledFlexContainer = styled('span')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2, 2, 2, 0),
}))

const StyledTitleText = styled('span')(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}))

const StyledTitle = styled('span')({
  fontWeight: 'bold',
})

export interface Props {
  isOpen: boolean
  sellerId: string
  valueRequestId: string
  closeDialog: () => void
}

const ValueRequestDetail = ({ isOpen, sellerId, valueRequestId }: Props) => {
  const [pending, setPending] = useState(true)
  const [valueRequest, setValueRequest] = useState<AttributeValueRequest>()

  useEffect(() => {
    let mounted = true
    getSellerRequestedValueDetail(sellerId, valueRequestId).then((response) => {
      if (mounted) {
        setValueRequest(response)
        setPending(false)
      }
    })
    return () => {
      mounted = false
    }
  }, [sellerId, valueRequestId])

  return (
    <DialogContainer
      title={
        valueRequest ? (
          <StyledFlexContainer>
            <Chip
              label={
                valueRequest?.request_status === 'INPROGRESS'
                  ? 'IN PROGRESS'
                  : valueRequest?.request_status
              }
              variant="outlined"
              sx={{ color: statusColor(valueRequest?.request_status) }}
            />
            <StyledTitleText>VALUE REQUEST {valueRequest?.id}</StyledTitleText>
          </StyledFlexContainer>
        ) : (
          `VALUE REQUEST ${valueRequestId}`
        )
      }
      isOpen={isOpen}
      isPending={pending}
      closeButtonText="Close"
    >
      {valueRequest && !pending && (
        <>
          <Typography sx={{ color: statusColor(valueRequest?.request_status) }}>
            {valueRequest?.status_comment}
          </Typography>
          <ContentSpacer />
          <Typography>
            <StyledTitle>Value Requested</StyledTitle>:{' '}
            {valueRequest?.requested_value}
          </Typography>
          <Typography>
            <StyledTitle>Attribute</StyledTitle>: {valueRequest?.attribute_name}
          </Typography>
          <Typography>
            <StyledTitle>Item Type</StyledTitle>: {valueRequest?.item_type_name}
          </Typography>
          <ContentSpacer />
          <Typography>
            <StyledTitle>Request Date</StyledTitle>: {valueRequest?.created}
          </Typography>
          <Typography>
            <StyledTitle>Requested By</StyledTitle>: {valueRequest?.created_by}
          </Typography>
          <ContentSpacer />
          <Typography>
            <StyledTitle>Reason for Request</StyledTitle>:{' '}
            {valueRequest?.comment}
          </Typography>
          <ContentSpacer />
          <Typography>
            <StyledTitle>TCIN(s)</StyledTitle>:
            {valueRequest?.tcin_info
              .map((tcinInfo) => tcinInfo.tcin)
              .filter((tcin) => tcin)
              .join(', ')}
          </Typography>
        </>
      )}
      {!valueRequest && pending && (
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      )}
    </DialogContainer>
  )
}

export default ValueRequestDetail
