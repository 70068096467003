import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { DialogEnum } from 'components/common/Dialog'

import { primary, white } from 'config/themeConfig'

import styled from '@emotion/styled'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'

import { openDialog } from 'store/dialog/actionCreator'
import { isDefunctSellerStatus } from 'store/selectors'

const StyledRoot = styled('span')(({ theme }) => ({
  verticalAlign: 'text-top',
  paddingLeft: theme.spacing(1),
  display: 'inline-block',
}))

const StyledIconButton = styled(IconButton)(() => ({
  fontSize: '1rem',
  '& :hover': {
    backgroundColor: primary.main,
    borderRadius: '4px',
    '& svg': {
      fill: white.main,
    },
  },
}))

interface Props {
  dialogComponent: DialogEnum
  componentProps?: any
  closeCallback?: () => void
  hide?: boolean
  children?: React.ReactNode
  [key: string]: any
}

export const EditButton: React.FC<Props> = ({
  dialogComponent,
  componentProps,
  hide = false,
  closeCallback = () => {},
  children,
  ...rest
}: Props) => {
  const isDefunct = useSelector(isDefunctSellerStatus)
  const dispatch = useDispatch()

  const handleOpenDialog = () => {
    dispatch(
      openDialog({
        dialogEnum: dialogComponent,
        componentProps,
        closeCallback,
      }),
    )
  }

  if (isDefunct || hide) return null

  const content = children ?? <EditIcon color="primary" />

  return (
    <StyledRoot>
      <StyledIconButton
        data-testid={`${dialogComponent}-dialog-button`}
        onClick={handleOpenDialog}
        {...rest}
        size="large"
      >
        {content}
      </StyledIconButton>
    </StyledRoot>
  )
}

export default EditButton
