import Grid from '@mui/material/Grid'

import HeaderTitle from 'components/common/HeaderTitle'
import ErrorBoundary from 'components/common/ErrorBoundary'
import ReturnPoliciesContent from './ReturnPoliciesContent'

const ReturnPoliciesPage = () => {
  return (
    <div data-testid="return-policies-page">
      <ErrorBoundary page="Return Policies">
        <HeaderTitle title="Return Policies" />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ReturnPoliciesContent />
          </Grid>
        </Grid>
      </ErrorBoundary>
    </div>
  )
}

export default ReturnPoliciesPage
