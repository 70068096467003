import { SetupTask } from 'types/SetupTask'

export const getDefunctTasks = (): SetupTask[] => {
  return [
    {
      title: 'All Items Suspended',
      description:
        'All items are un-published & new versions are blocked from being published',
      complete: true,
      enabled: false,
    },
    {
      title: 'All Barcodes Released',
      description:
        'First Party vendors or other Target Plus Partners can sell items with these barcodes',
      complete: true,
      enabled: false,
    },
    {
      title: "User Access Removed for this Partner's Account",
      description: 'Users will not be allowed to log into Marketplace Portal',
      complete: true,
      enabled: false,
    },
    {
      title: 'API Tokens Deleted',
      description:
        "Partner's integration access to Target Plus APIs has been removed",
      complete: true,
      enabled: false,
    },
  ]
}
