import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import startCase from 'lodash/fp/startCase'
import styled from '@emotion/styled'

import Divider from '@mui/material/Divider'
import { Box } from '@mui/material'

import DisplayCard from 'components/common/DisplayCard'
import Stepper from 'components/common/Stepper'
import Link from 'components/common/Link'

import { fetchPartnerDiversityAnswers } from 'store/partnerDiversity/actionCreators'

import {
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
  formatDate,
} from 'services/dateService'
import { isPrivateCompany } from 'services/partnerDiversity'
import { getSellerLegalizationsWithNames } from 'services/legalizations'

import { getSellerTasks, getTargetTasks } from './tasks'
import SetupTaskTable from './SetupTaskTable'

import { SellerStatus } from 'types/Seller'

import { currentSeller, getMemberOf, getSellerContacts } from 'store/selectors'
import { getApiTokens } from 'services/apiTokens'
import { ApiToken } from 'types/ApiTokens'
import StoreState from 'types/state'
import { Legalization } from 'types/Legalization'

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, -2),
}))

export const GuidedSellerSetupCard = () => {
  const seller = useSelector(currentSeller)
  const memberOf = useSelector(getMemberOf)
  const contacts = useSelector(getSellerContacts)
  const [legalizations, setLegalizations] = useState<Legalization[]>([])
  const diversityAnswers = useSelector(
    (state: StoreState) => state.partnerDiversity.diversityAnswers,
  )

  const [apiTokens, setApiTokens] = useState<ApiToken[]>()

  const dispatch = useDispatch()

  useEffect(() => {
    if (seller?.id && seller?.status !== SellerStatus.INITIATED) {
      getSellerLegalizationsWithNames(seller.id).then((data) => {
        setLegalizations(data)
      })
      getApiTokens(seller.id).then((tokens) => setApiTokens(tokens))

      if (isPrivateCompany(seller)) {
        dispatch(fetchPartnerDiversityAnswers(seller.id))
      }
    }
  }, [dispatch, seller])

  if (seller === undefined) return null

  const steps = ['Initiated', 'Partner Setup', 'Established']
  let step = -1
  switch (seller.status) {
    case SellerStatus.INITIATED:
      step = 0
      break
    case SellerStatus.PARTNER_SETUP:
      step = 1
      break
    case SellerStatus.KICK_OFF:
    case SellerStatus.DATA_INTEGRATION:
    case SellerStatus.RAMP_UP:
    case SellerStatus.ONBOARDING_COMPLETE:
    case SellerStatus.ESTABLISHED:
      step = 2
  }

  return (
    <DisplayCard
      title={`Target Plus™ Program Status: ${startCase(seller.status)}`}
      description={`Last updated: ${formatDate(
        seller.last_modified,
        DATE_FORMAT_MONTH_DAY_YEAR_TIME,
      )}`}
    >
      <Stepper steps={steps} currentStep={step} />
      {step !== 0 && (
        <>
          <StyledDivider />
          <SetupTaskTable
            title="Partner's Tasks"
            subtitle={
              <div>
                Users with admin access can click the links below to complete
                their assigned tasks. Visit the{' '}
                <Link to={`/article/000143996`} target="_blank">
                  Account Setup Checklist
                </Link>{' '}
                within the Help Center for more information.
              </div>
            }
            tasks={getSellerTasks({
              seller,
              diversityAnswers,
              memberOf,
              contacts,
              apiTokens,
            })}
          />
          <Box sx={{ pt: 4 }}>
            <SetupTaskTable
              title="Target's Tasks"
              subtitle="The Target onboarding team will take care of these tasks."
              tasks={getTargetTasks(seller, memberOf, legalizations)}
            />
          </Box>
        </>
      )}
    </DisplayCard>
  )
}

export default GuidedSellerSetupCard
