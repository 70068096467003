import StyledIcon from 'components/common/StyledIcon'

import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import { TableHook } from 'components/common/EnhancedTable/useTable'
import TableSpacer from 'components/common/TableSpacer'

import { ShippingMethod } from 'types/ShippingMethods'

interface Props {
  table: TableHook
  data: ShippingMethod[]
  total: number
  isPending: boolean
  edit?: (element: ShippingMethod) => (event: any) => void
  remove?: (element: ShippingMethod) => (event: any) => void
}

const fieldList: EnhancedTableFieldType[] = [
  {
    key: 'shipping_method',
    heading: 'Shipping Method',
    hasSort: true,
  },
  {
    key: 'carrier',
    heading: 'Carrier',
    hasSort: true,
  },
  {
    key: 'scac',
    heading: 'SCAC',
    hasSort: true,
  },
  {
    key: 'service_code',
    heading: 'CSC',
    hasSort: true,
  },
]

export const ShippingMethodsTable = ({
  table,
  data,
  total,
  isPending,
  edit,
  remove,
}: Props) => {
  return (
    <TableSpacer>
      <EnhancedTable
        total={total}
        onChangePage={table.actions.changePage}
        onChangeRowsPerPage={table.actions.changePerPage}
        onRequestSort={table.actions.sort}
        orderBy={table.state.orderBy}
        order={table.state.direction}
        page={table.state.page}
        rowsPerPage={table.state.perPage}
        fieldList={fieldList}
        paginationDisabled={false}
        data={data}
        isLoading={isPending}
        actions={[
          {
            callback: edit,
            label: 'Edit',
            icon: <StyledIcon iconType="edit" />,
          },
          {
            callback: remove,
            label: 'Delete',
            icon: <StyledIcon iconType="delete" />,
          },
        ]}
      />
    </TableSpacer>
  )
}

export default ShippingMethodsTable
