import { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import LinearProgress from '@mui/material/LinearProgress'

import { DialogEnum } from 'components/common/Dialog'
import { formatDateMDYT } from 'components/common/EnhancedTable/formatters'
import TableSpacer from 'components/common/TableSpacer'
import TabularData, { FieldList } from 'components/common/TabularData'

import { getMarketplaceProducts } from 'services/items'
import {
  getProductValidationRules,
  deleteProductValidationRule,
  createProductValidationRule,
} from 'services/productValidationRules'

import { openDialog } from 'store/dialog/actionCreator'

import {
  ListingStatus,
  MarketplaceProduct,
  ValidationStatus,
  ProductSearchParams,
} from 'types/Item'
import {
  ErrorConfiguration,
  ResultingStatus,
} from 'types/ProductValidationRule'
import { CollectionResponse } from 'types/Response'
import { FormControlLabel, FormGroup, Switch, Typography } from '@mui/material'

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: 0,
  '& .MuiFormControlLabel-label': {
    marginLeft: theme.spacing(2),
  },
}))

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.grey[500],
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

interface Props {
  sellerId: string
  canEdit: boolean
  onCloseHook?: () => void
}

const ErrorConfigurationTable = ({ sellerId, onCloseHook, canEdit }: Props) => {
  const dispatch = useDispatch()
  const [pending, setPending] = useState(false)
  const [errorConfigurations, setErrorConfigurations] = useState<
    ErrorConfiguration[]
  >([])

  const getErrorConfigurations = useCallback(() => {
    Promise.all([
      getProductValidationRules(ResultingStatus.REVIEW),
      getProductValidationRules(ResultingStatus.VALIDATED, sellerId),
    ])
      .then(([globalErrors, sellerErrors]) => {
        const sellerConfigurations = sellerErrors.map((error) => ({
          id: error.id,
          errorCode: error.error_code,
          suppressedDate: error.last_modified,
          suppressed: true,
          lastModifiedBy: error.last_modified_by,
        }))
        const combinedConfigurations = globalErrors.map((error) => {
          const sellerConfig = sellerConfigurations.find(
            (config) => config.errorCode === error.error_code,
          )

          if (sellerConfig) {
            return sellerConfig
          } else {
            return {
              id: error.id,
              errorCode: error.error_code,
              suppressedDate: '',
              suppressed: false,
              lastModifiedBy: error.last_modified_by,
            }
          }
        })

        setErrorConfigurations(combinedConfigurations)
      })
      .finally(() => setPending(false))
  }, [sellerId])

  useEffect(() => {
    setPending(true)
    getErrorConfigurations()
  }, [getErrorConfigurations])

  if (pending) {
    return <LinearProgress data-testid="error-configurations-progress" />
  }

  const handleReprocess = (params: ProductSearchParams) => {
    getMarketplaceProducts(params, { page: 0, perPage: 1 }).then(
      (response: CollectionResponse<MarketplaceProduct>) => {
        if (response.total > 0) {
          dispatch(
            openDialog({
              dialogEnum: DialogEnum.BULK_RE_ENRICH,
              componentProps: {
                searchParams: params,
                filteredItems: response.total,
                onCloseHook: onCloseHook ? onCloseHook : null,
              },
            }),
          )
        }
      },
    )
  }

  const onChange =
    (error: ErrorConfiguration) =>
    async (_event: React.FormEvent<HTMLInputElement>) => {
      if (error.suppressed) {
        await deleteProductValidationRule(sellerId, error.id).then(() =>
          getErrorConfigurations(),
        )
      }

      if (!error.suppressed) {
        await createProductValidationRule(sellerId, error.errorCode)
          .then(() => getErrorConfigurations())
          .finally(() => {
            handleReprocess({
              listing_status: ListingStatus.PENDING,
              validation_status: ValidationStatus.REVIEW,
              seller_id: sellerId,
            })
          })
      }
    }

  const fieldList: FieldList<ErrorConfiguration>[] = [
    {
      key: 'errorCode',
      displayName: 'Errors',
    },
    {
      key: 'suppressed',
      displayName: 'Status',
      formatCell: (item) => {
        if (!canEdit) {
          return item.suppressed ? 'Suppressed' : 'Enabled'
        } else {
          return (
            <FormGroup>
              <StyledFormControlLabel
                control={
                  <StyledSwitch
                    data-testid={`status-switch-${item.id}`}
                    color="primary"
                    checked={!item.suppressed}
                    onChange={onChange(item)}
                  />
                }
                label={item.suppressed ? 'Suppressed' : 'Enabled'}
              />
            </FormGroup>
          )
        }
      },
    },
    {
      key: 'suppressedDate',
      displayName: 'Suppressed Date',
      formatCell: formatDateMDYT('suppressedDate'),
    },
    {
      key: 'lastModifiedBy',
      displayName: 'Suppressed By',
      formatCell: (item) => {
        return item.suppressed ? (
          <Typography> {item.lastModifiedBy}</Typography>
        ) : (
          ''
        )
      },
    },
  ]

  return (
    <TableSpacer>
      <TabularData data={errorConfigurations} fieldList={fieldList} />
    </TableSpacer>
  )
}

export default ErrorConfigurationTable
