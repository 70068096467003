export enum ReturnDescription {
  WRITE_OFF = 'Write-Off',
  RETURN_TO_VENDOR_CRC = 'Return to Vendor via CRC',
  CHARGE_TO_VENDOR = 'Charge to Vendor',
}

export enum ReturnDescriptionAlias {
  TARGET_WRITE_OFF = 'Target Write-Off',
  RETURN_TO_PARTNER_CRC = 'Return to Partner via CRC',
  CHARGE_TO_PARTNER = 'Charge to Partner',
}
