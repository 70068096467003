import { useState, useEffect, SyntheticEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Grid from '@mui/material/Grid'
import { AccessTime, FindInPage } from '@mui/icons-material'

import startCase from 'lodash/fp/startCase'
import camelCase from 'lodash/fp/camelCase'

import { openDialog } from 'store/dialog/actionCreator'

import { getDisputeSubResponses } from 'store/selectors'

import { CONTENT_PADDING } from 'constants/layout'

import { DialogEnum } from 'components/common/Dialog'
import HeaderTitle from 'components/common/HeaderTitle'
import Link from 'components/common/Link'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import { formatDateMDYT } from 'components/common/EnhancedTable/formatters'
import FilterBar from 'components/common/FilterBar'
import SelectFilter from 'components/common/FilterBar/SelectFilter'
import { TypeaheadFilter } from 'components/common/FilterBar/TypeaheadFilter'
import DateRangeFilter from 'components/common/FilterBar/DateRangeFilter'
import MultiSelectFilter from 'components/common/FilterBar/MultiSelectFilter'
import { Button, SvgIconProps } from '@mui/material'
import TableSpacer from 'components/common/TableSpacer'
import {
  getEnhancedTablePageableProps,
  useSearchParams,
} from 'components/common/FilterBar/useSearchParams'
import DisplayCard from 'components/common/DisplayCard'

import { memoizedGetSeller } from 'services/seller'
import { Direction } from 'services/pageableHelper'
import { fetchReturnDisputes } from 'services/returnDisputes'
import { formatDateRange } from 'services/dateService'

import {
  getLastModifiedFromDaysPending,
  useInternalReturnDisputesQuery,
} from './useReturnDisputesQuery'
import { getDisputeSubReasonDescription } from './Dialogs/returnDisputeHelper'

import {
  JudgementValues,
  ReturnDispute,
  ReturnDisputeDaysPending,
} from 'types/disputes'

const StyledTabsContainer = styled(Tabs)({
  margin: `0 -${CONTENT_PADDING}px`,
  padding: `0 ${CONTENT_PADDING}px`,
})

const StyledTabContent = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(3),
}))

const StyledDiv = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(3),
}))

const StyledText = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  marginLeft: theme.spacing(0.5),
}))

export enum DisputesTab {
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  WAITING_ON_PARTNER = 'WAITING_ON_PARTNER',
}

type CardData = {
  title: string
  icon: React.ComponentType<SvgIconProps>
  totalCount: number | undefined
  filteredCount: number | undefined
}
export type TableState = {
  direction?: Direction
  orderBy?: string
  page: number
  perPage: number
}

export type ReturnDisputesSearchParams = TableState & {
  source: 'RETURN_DISPUTE'
  daysPending: ReturnDisputeDaysPending | undefined
  seller_id: string | undefined
  case_id: string | undefined
  return_order_number: string | undefined
  tab: DisputesTab
  last_modified: string | undefined
  created: string | undefined
  category: string | undefined
  createdStartDate: string | undefined
  createdEndDate: string | undefined
  lastModifiedStartDate: string | undefined
  lastModifiedEndDate: string | undefined
}

export const initialSearchParams: ReturnDisputesSearchParams = {
  perPage: 50,
  page: 0,
  orderBy: 'last_modified',
  direction: Direction.ASC,
  source: 'RETURN_DISPUTE',
  daysPending: undefined,
  seller_id: undefined,
  case_id: undefined,
  return_order_number: undefined,
  tab: DisputesTab.NEEDS_REVIEW,
  last_modified: undefined,
  created: undefined,
  category: undefined,
  createdStartDate: undefined,
  createdEndDate: undefined,
  lastModifiedStartDate: undefined,
  lastModifiedEndDate: undefined,
}

export const InternalReturnDisputeCasesPage = () => {
  const reduxDispatch = useDispatch()
  const disputeSubReasons = useSelector(getDisputeSubResponses)

  const [infoCardData, setInfoCardData] = useState<CardData>()
  const [needsReviewTotal, setNeedsReviewTotal] = useState<number>()
  const [needsReviewOverFiveDaysTotal, setNeedsReviewOverFiveDaysTotal] =
    useState<number>()
  const [waitingOnPartnerTotal, setWaitingOnPartnerTotal] = useState<number>()
  const [
    waitingOnPartnerOverSevenDaysTotal,
    setWaitingOnPartnerOverSevenDaysTotal,
  ] = useState<number>()

  const [searchParams, searchParamActions, appliedFilterCount] =
    useSearchParams<ReturnDisputesSearchParams>(initialSearchParams)

  const handleFilterClear = () => {
    if (searchParams.tab === DisputesTab.NEEDS_REVIEW)
      searchParamActions.updateSearchParam(initialSearchParams)
    else
      searchParamActions.updateSearchParam({
        ...initialSearchParams,
        tab: DisputesTab.WAITING_ON_PARTNER,
      })
  }

  const createdDateRange = formatDateRange(
    searchParams.createdStartDate,
    searchParams.createdEndDate,
  )
  const lastModifiedDateRange = formatDateRange(
    searchParams.lastModifiedStartDate,
    searchParams.lastModifiedEndDate,
  )
  const lastModifiedDate = lastModifiedDateRange || undefined
  const createdDate = createdDateRange || undefined

  const { data: results, isLoading } = useInternalReturnDisputesQuery(
    {
      judgement: searchParams.tab,
      source: searchParams.source,
      seller_id: searchParams.seller_id,
      case_id: searchParams.case_id,
      return_order_number: searchParams.return_order_number,
      last_modified: searchParams.daysPending
        ? getLastModifiedFromDaysPending(searchParams.daysPending)
        : lastModifiedDate,

      dispute_reason: searchParams.category,
      created: createdDate,
    },
    {
      direction: searchParams.direction,
      orderBy: searchParams.orderBy,
      page: searchParams.page,
      perPage: searchParams.perPage,
    },
  )

  const tableData = results?.data ?? []
  const tableTotal = results?.total

  useEffect(() => {
    let mounted = true

    if (tableTotal !== undefined) {
      if (
        searchParams.tab === DisputesTab.NEEDS_REVIEW &&
        !searchParams.case_id &&
        !searchParams.return_order_number &&
        !searchParams.seller_id &&
        !searchParams.daysPending &&
        !searchParams.category &&
        !searchParams.created &&
        !searchParams.createdStartDate &&
        !searchParams.createdEndDate &&
        !searchParams.lastModifiedEndDate &&
        !searchParams.lastModifiedStartDate &&
        !searchParams.last_modified
      ) {
        setNeedsReviewTotal(tableTotal)
      } else {
        if (!needsReviewTotal) {
          fetchReturnDisputes(
            {
              judgement: JudgementValues.NEEDS_REVIEW,
              source: 'RETURN_DISPUTE',
              last_modified: lastModifiedDate,
            },
            { page: 0, perPage: 1 },
          ).then((results) => {
            if (mounted) setNeedsReviewTotal(results.total)
          })
        }
      }

      if (
        searchParams.tab === DisputesTab.WAITING_ON_PARTNER &&
        !searchParams.case_id &&
        !searchParams.return_order_number &&
        !searchParams.seller_id &&
        !searchParams.daysPending &&
        !searchParams.category &&
        !searchParams.created &&
        !searchParams.createdStartDate &&
        !searchParams.createdEndDate &&
        !searchParams.lastModifiedEndDate &&
        !searchParams.lastModifiedStartDate &&
        !searchParams.last_modified
      ) {
        setWaitingOnPartnerTotal(tableTotal)
      } else {
        if (!waitingOnPartnerTotal) {
          fetchReturnDisputes(
            {
              judgement: JudgementValues.WAITING_ON_PARTNER,
              source: 'RETURN_DISPUTE',
              last_modified: lastModifiedDate,
            },
            { page: 0, perPage: 1 },
          ).then((results) => {
            if (mounted) setWaitingOnPartnerTotal(results.total)
          })
        }
      }

      if (
        searchParams.tab === DisputesTab.NEEDS_REVIEW &&
        !searchParams.case_id &&
        !searchParams.return_order_number &&
        !searchParams.seller_id &&
        searchParams.daysPending === ReturnDisputeDaysPending.OVER_FIVE &&
        !searchParams.category &&
        !searchParams.created &&
        !searchParams.createdStartDate &&
        !searchParams.createdEndDate &&
        !searchParams.lastModifiedEndDate &&
        !searchParams.lastModifiedStartDate &&
        !searchParams.last_modified
      ) {
        setNeedsReviewOverFiveDaysTotal(tableTotal)
      } else {
        if (!needsReviewOverFiveDaysTotal) {
          fetchReturnDisputes(
            {
              judgement: JudgementValues.NEEDS_REVIEW,
              source: 'RETURN_DISPUTE',
              last_modified: getLastModifiedFromDaysPending(
                ReturnDisputeDaysPending.OVER_FIVE,
              ),
            },
            { page: 0, perPage: 1 },
          ).then((results) => {
            if (mounted) setNeedsReviewOverFiveDaysTotal(results.total)
          })
        }
      }

      if (
        searchParams.tab === DisputesTab.WAITING_ON_PARTNER &&
        !searchParams.case_id &&
        !searchParams.return_order_number &&
        !searchParams.seller_id &&
        searchParams.daysPending === ReturnDisputeDaysPending.OVER_SEVEN &&
        !searchParams.category &&
        !searchParams.created &&
        !searchParams.createdStartDate &&
        !searchParams.createdEndDate &&
        !searchParams.lastModifiedEndDate &&
        !searchParams.lastModifiedStartDate &&
        !searchParams.last_modified
      ) {
        setWaitingOnPartnerOverSevenDaysTotal(tableTotal)
      } else {
        if (!waitingOnPartnerOverSevenDaysTotal) {
          fetchReturnDisputes(
            {
              judgement: JudgementValues.WAITING_ON_PARTNER,
              source: 'RETURN_DISPUTE',
              last_modified: getLastModifiedFromDaysPending(
                ReturnDisputeDaysPending.OVER_SEVEN,
              ),
            },
            { page: 0, perPage: 1 },
          ).then((results) => {
            if (mounted) setWaitingOnPartnerOverSevenDaysTotal(results.total)
          })
        }
      }
    }

    return () => {
      mounted = false
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableTotal])

  useEffect(() => {
    switch (searchParams.tab) {
      case DisputesTab.NEEDS_REVIEW:
        setInfoCardData({
          title: 'Needs Review Over 5 Days: ',
          icon: FindInPage,
          totalCount: needsReviewTotal,
          filteredCount: needsReviewOverFiveDaysTotal,
        })
        break
      case DisputesTab.WAITING_ON_PARTNER:
        setInfoCardData({
          title: 'Waiting on Partner Over 7 Days: ',
          icon: AccessTime,
          totalCount: waitingOnPartnerTotal,
          filteredCount: waitingOnPartnerOverSevenDaysTotal,
        })
        break
    }
  }, [
    searchParams.tab,
    waitingOnPartnerTotal,
    needsReviewTotal,
    needsReviewOverFiveDaysTotal,
    waitingOnPartnerOverSevenDaysTotal,
  ])

  const handleReviewDisputes =
    (dispute?: ReturnDispute) => (event: SyntheticEvent<Element>) => {
      event.preventDefault()

      let componentProps: {
        searchParams: Partial<ReturnDisputesSearchParams> & {
          judgement?: JudgementValues
          dispute_reason?: string
        }
        pagingParams: Partial<TableState>
        selectedDispute?: ReturnDispute
      } = {
        searchParams: {},
        pagingParams: {},
      }

      if (dispute) {
        componentProps.selectedDispute = dispute
      } else {
        componentProps = {
          searchParams: {
            judgement: JudgementValues.NEEDS_REVIEW,
            source: searchParams.source,
            last_modified: searchParams.daysPending
              ? getLastModifiedFromDaysPending(searchParams.daysPending)
              : lastModifiedDate,
            seller_id: searchParams.seller_id,
            case_id: searchParams.case_id,
            return_order_number: searchParams.return_order_number,
            created: createdDate,
            dispute_reason: searchParams.category,
          },
          pagingParams: {
            page: searchParams.page,
            direction: searchParams.direction,
            perPage: searchParams.perPage,
            orderBy: searchParams.orderBy,
          },
        }
      }

      reduxDispatch(
        openDialog({
          dialogEnum: DialogEnum.INTERNAL_RETURN_DISPUTE_DIALOG,
          componentProps,
        }),
      )
    }

  const handleTabChange = (_event: any, newValue: DisputesTab) => {
    if (newValue === DisputesTab.NEEDS_REVIEW)
      searchParamActions.updateSearchParam(initialSearchParams)
    else {
      searchParamActions.updateSearchParam({
        ...initialSearchParams,
        tab: DisputesTab.WAITING_ON_PARTNER,
      })
    }
  }

  const fieldList: EnhancedTableFieldType<ReturnDispute>[] = [
    {
      key: 'case_number',
      heading: 'Dispute Number',
      formatCell: (rowItem) => {
        if (rowItem.case_number) {
          return (
            <Link
              onClick={handleReviewDisputes(rowItem)}
              to=""
              data-testid="dispute-detail-dialog"
            >
              {rowItem.case_number}
            </Link>
          )
        } else {
          return 'Dispute number generating...'
        }
      },
    },
    {
      key: 'seller_id',
      heading: 'Partner',
      formatCellAsync: async (dispute) => {
        const seller = await memoizedGetSeller(dispute.seller_id)

        return (
          seller?.display_name ??
          seller?.legal_business_name ??
          dispute.seller_id
        )
      },
    },
    {
      key: 'dispute_reason',
      heading: 'Category',
      formatCell: (item) => {
        return startCase(camelCase(item.dispute_reason))
      },
    },
    {
      key: 'dispute_sub_reason',
      heading: 'Dispute Reason',
      formatCell: ({ dispute_sub_reason }) => {
        if (dispute_sub_reason) {
          return getDisputeSubReasonDescription(
            dispute_sub_reason,
            disputeSubReasons,
          )
        }
      },
    },
    {
      key: 'created',
      heading: 'Created Date',
      formatCell: formatDateMDYT('created'),
    },
    {
      key: 'last_modified',
      heading: 'Last Modified',
      formatCell: formatDateMDYT('last_modified'),
    },
  ]

  return (
    <div data-testid="disputes-test-page">
      <HeaderTitle title="Return Disputes" />
      <StyledTabsContainer
        value={searchParams.tab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab
          value={DisputesTab.NEEDS_REVIEW}
          label={`Needs Review (${needsReviewTotal})`}
        />
        <Tab
          value={DisputesTab.WAITING_ON_PARTNER}
          label={`Waiting on Partner (${waitingOnPartnerTotal})`}
        />
      </StyledTabsContainer>
      <StyledTabContent>
        <>
          <StyledDiv>
            {infoCardData && (
              <DisplayCard
                title={infoCardData.title}
                icon={infoCardData.icon}
                description={`Total: ${infoCardData.totalCount}`}
                sidecar={<StyledText>{infoCardData.filteredCount}</StyledText>}
                noGutter={true}
              ></DisplayCard>
            )}
          </StyledDiv>
          <FilterBar
            count={tableTotal ?? 0}
            onClear={handleFilterClear}
            appliedFilterCount={appliedFilterCount}
          >
            <Grid item xs={3}>
              <SelectFilter
                label="Days Pending"
                placeholder="Days Pending"
                value={searchParams.daysPending}
                searchParam="daysPending"
                onChange={searchParamActions.updateSearchParam}
                data={[
                  {
                    name:
                      searchParams.tab === DisputesTab.NEEDS_REVIEW
                        ? '0-5 Days'
                        : '0-7 Days',
                    value:
                      searchParams.tab === DisputesTab.NEEDS_REVIEW
                        ? ReturnDisputeDaysPending.ZERO_TO_FIVE
                        : ReturnDisputeDaysPending.ZERO_TO_SEVEN,
                  },
                  {
                    name:
                      searchParams.tab === DisputesTab.NEEDS_REVIEW
                        ? 'Over 5 Days'
                        : 'Over 7 Days',
                    value:
                      searchParams.tab === DisputesTab.NEEDS_REVIEW
                        ? ReturnDisputeDaysPending.OVER_FIVE
                        : ReturnDisputeDaysPending.OVER_SEVEN,
                  },
                ]}
              />
            </Grid>
            <Grid item xs={3}>
              <MultiSelectFilter
                label="Category"
                placeholder="Category"
                value={searchParams.category}
                searchParam="category"
                onChange={searchParamActions.updateSearchParam}
                data={[
                  {
                    name: 'Return Not Received',
                    value: 'RETURN_NOT_RECEIVED',
                  },
                  {
                    name: 'Unsellable',
                    value: 'UNSELLABLE',
                  },
                  {
                    name: 'Wrong Item',
                    value: 'WRONG_ITEM',
                  },
                ]}
              />
            </Grid>
            <Grid item xs={3}>
              <TypeaheadFilter
                label="Partner"
                value={searchParams.seller_id}
                placeholder="Partner"
                searchParam="seller_id"
                onChange={searchParamActions.updateSearchParam}
              />
            </Grid>
            <Grid item xs={3}>
              <TypeaheadFilter
                label="Dispute Number"
                value={searchParams.case_id}
                placeholder="Dispute Number"
                searchParam="case_id"
                caseType={'RETURNS_DISPUTES_V2'}
                onChange={searchParamActions.updateSearchParam}
              />
            </Grid>
            <Grid item xs={3}>
              <TypeaheadFilter
                label="Return Order Number"
                value={searchParams.return_order_number}
                placeholder="Return Order #"
                searchParam="return_order_number"
                onChange={searchParamActions.updateSearchParam}
              />
            </Grid>
            <Grid item xs={4.5}>
              <DateRangeFilter
                label="Created Date"
                startValue={searchParams.createdStartDate}
                startSearchParam="createdStartDate"
                endValue={searchParams.createdEndDate}
                endSearchParam="createdEndDate"
                onChange={searchParamActions.updateSearchParam}
              />
            </Grid>
            <Grid item xs={4.5}>
              <DateRangeFilter
                label="Last Modified Date"
                startValue={searchParams.lastModifiedStartDate}
                startSearchParam="lastModifiedStartDate"
                endValue={searchParams.lastModifiedEndDate}
                endSearchParam="lastModifiedEndDate"
                onChange={searchParamActions.updateSearchParam}
              />
            </Grid>
          </FilterBar>
          {searchParams.tab === DisputesTab.NEEDS_REVIEW && (
            <>
              <TableSpacer />
              <Button
                color="primary"
                variant="contained"
                disabled={tableTotal === 0}
                onClick={handleReviewDisputes()}
                data-testid="internal-dispute-btn"
              >
                Review {tableTotal ?? 0} Disputes
              </Button>
            </>
          )}
          <EnhancedTable
            data-testid="internal-return-disputes-table"
            data={tableData}
            fieldList={fieldList}
            total={tableTotal ?? 0}
            isLoading={isLoading}
            {...getEnhancedTablePageableProps(searchParams, searchParamActions)}
          />
        </>
      </StyledTabContent>
    </div>
  )
}

export default InternalReturnDisputeCasesPage
