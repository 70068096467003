import WebSocketClient from './core'
import apiConfig from 'config/apiConfig'

export const BUMP_REQUEST = '/app/bump_products'
export const BUMP_SUCCESS = '/user/queue/bump_products'
export const BUMP_FAILURE = '/user/queue/bump_products/errors'

export const REJECT_REQUEST = '/app/reject_products'
export const REJECT_SUCCESS = '/user/queue/reject_products'
export const REJECT_FAILURE = '/user/queue/reject_products/errors'

export const SmsBulkApiWebSocket = (onConnect?: () => void) => {
  return WebSocketClient(`${apiConfig.sellerBulkOps}/stomp`, onConnect)
}
