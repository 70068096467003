import React from 'react'
import { useSelector } from 'react-redux'

import HeaderTitle from 'components/common/HeaderTitle'

import { currentSellerId } from 'store/selectors'

import SellerReportsPage from './SellerReports'
import InternalReports from './InternalReports'

export const ReportsPage = () => {
  const sellerId = useSelector(currentSellerId)

  return (
    <React.Fragment>
      <HeaderTitle title="Reports" />
      {sellerId && <SellerReportsPage data-testid="seller-reports" />}
      {!sellerId && <InternalReports data-testid="internal-reports" />}
    </React.Fragment>
  )
}

export default ReportsPage
