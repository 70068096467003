import styled from '@emotion/styled'
import { Button, Card, Grid, Link, Typography } from '@mui/material'
import BackgroundFinal from 'assets/img/banner 1_N_new.png'

export const StyledRoot = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  background: '#FAFAFA',
  minHeight: '330px',
}))

export const StyledTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontWeight: 600,
})) as typeof Typography

export const StyledServicesBlock = styled(Grid)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(5)}`,
  marginRight: theme.spacing(-5),
  marginLeft: theme.spacing(-5),
}))

export const StyledPageDescription = styled('div')(({ theme }) => ({
  margin: theme.spacing(-7, -10, 0, -10),
  padding: theme.spacing(0, 2),
  fontWeight: 400,
  fontSize: '2.125rem',
  color: theme.palette.grey[900],
  minHeight: 130,
  height: 170,
  backgroundImage: `url("${BackgroundFinal}")`,
  backgroundSize: '1620px 180px',
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  alignItems: 'center',
  justifyContent: 'center',
  gridGap: theme.spacing(2),
}))

export const StyledPageDescriptionButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  gridGap: theme.spacing(4),
}))

export const StyledDescriptionHeaderButton = styled(Button)({
  width: 300,
})

export const StyledDescriptionButton = styled(Button)({
  width: 250,
  marginRight: '24px',
})

export const LaunchLink = styled(Link)({
  color: 'inherit',
  textDecoration: 'inherit',
  '&:hover': {
    textDecoration: 'none',
  },
  '&:visited': {
    color: 'inherit',
  },
})
