import React from 'react'

import Grid from '@mui/material/Grid'

import HeaderTitle from 'components/common/HeaderTitle'
import ErrorBoundary from 'components/common/ErrorBoundary'

import ApiTokensTable from './ApiTokensTable'

const ApiTokensPage = () => {
  return (
    <React.Fragment>
      <ErrorBoundary page="API Tokens">
        <HeaderTitle title="API Tokens" />
        <Grid container>
          <Grid item xs={12}>
            <ApiTokensTable />
          </Grid>
        </Grid>
      </ErrorBoundary>
    </React.Fragment>
  )
}

export default ApiTokensPage
