import React from 'react'
import styled from '@emotion/styled'

const StyledImage = styled('img', {
  shouldForwardProp: (prop) => prop !== 'isThumbnail',
})<{ isThumbnail: boolean }>((props) => ({
  height: 'auto',
  width: '100%',
  maxWidth: props.isThumbnail ? '100px' : undefined,
}))
export interface Props {
  src: string | undefined
  alt: string
  thumbnail?: boolean
}

export const IMAGE_SRC_ERROR =
  'http://target.scene7.com/is/image/Target/image_coming_soon'

export const Image = ({ src = '', alt, thumbnail = false }: Props) => {
  const [error, setError] = React.useState<boolean>(false)

  const memoizedHandleError = React.useCallback(() => {
    setError(true)
  }, [])

  if (error) {
    return (
      <StyledImage
        data-testid="error-image"
        isThumbnail={thumbnail}
        alt="Coming soon"
        src={IMAGE_SRC_ERROR}
      />
    )
  }
  return (
    <StyledImage
      data-testid="image"
      isThumbnail={thumbnail}
      alt={alt}
      src={src}
      onError={memoizedHandleError}
    />
  )
}

export default Image
