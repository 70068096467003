import { useSelector } from 'react-redux'
import { getStateCodes } from 'store/selectors'

import { EditCarrierInfo } from '../EditCarrierInfo'
import { CarrierInfoState } from '../EditCarrierInfo/useCarrierInfo'

import styled from '@emotion/styled'

import InputLabel from '@mui/material/InputLabel'

import ContentSpacer from 'components/common/ContentSpacer'
import { StateCodeTypeahead } from 'components/common/Typeahead'
import { EligibleStateCodeContent } from 'components/ShippingInfoPage/EligibleStateCodeContent'

import { DATE_DISPLAY_FORMAT_TIME, formatDate } from 'services/dateService'

import { StateCode } from 'types/Enumeration'
import { VendorCategory } from 'types/Seller'

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}))

const StyledTypeaheadContainer = styled('div')(({ theme }) => ({
  width: theme.spacing(50),
}))

export interface Props {
  fieldValues: any
  vendorCategories: VendorCategory[]
  validateCarriers: boolean
  setFieldValue: (name: string, value: any) => void
  setCarriers: (carrierState: CarrierInfoState) => void
  setValidateCarriers: (validateCarriers: boolean) => void
}

export const AddDistributionCenterPageTwo = ({
  fieldValues,
  vendorCategories,
  validateCarriers,
  setFieldValue,
  setCarriers,
  setValidateCarriers,
}: Props) => {
  const stateCodes = useSelector(getStateCodes)
  const shipNodeName = formatDate(new Date(), DATE_DISPLAY_FORMAT_TIME)

  const filteredStateCodes = fieldValues.eligibleStates.map(
    (selectedCode: string) => {
      return stateCodes.find((sc) => sc.value === selectedCode)
    },
  )

  const handleEligibleStatesAdd = (
    selectedStateCodes: Nullable<StateCode[]>,
  ) => {
    const selectedCodes = selectedStateCodes
      ? selectedStateCodes.map((x) => x.value)
      : []
    setFieldValue('eligibleStates', selectedCodes)
  }

  const handleEligibleStatesDelete = (code: string) => {
    const eligibleStates = fieldValues.eligibleStates.filter(
      (stateCode: string) => stateCode !== code,
    )
    setFieldValue('eligibleStates', eligibleStates)
  }

  const handleCarrierChange = (CarrierState: CarrierInfoState) => {
    setCarriers(CarrierState)
    setValidateCarriers(false)
  }

  return (
    <>
      <StyledInputLabel shrink>
        States Eligible For 2 Day Shipping Upgrade
      </StyledInputLabel>
      <StyledTypeaheadContainer>
        <StateCodeTypeahead
          eligibleStateCodes={filteredStateCodes as Nullable<StateCode[]>}
          onChange={handleEligibleStatesAdd}
        />
      </StyledTypeaheadContainer>
      <EligibleStateCodeContent
        eligibleStateCodes={fieldValues.eligibleStates}
        handleDelete={handleEligibleStatesDelete}
      />
      <ContentSpacer />
      <EditCarrierInfo
        isOpen
        contentOnly
        shipNode={{
          name: shipNodeName,
        }}
        distributionCenter={fieldValues}
        vendorCategories={vendorCategories}
        onChange={handleCarrierChange}
        validate={validateCarriers}
      />
    </>
  )
}

export default AddDistributionCenterPageTwo
