import memoize from 'lodash/fp/memoize'

import {
  startOf,
  endOfToday,
  startOfDay,
  getDateBefore,
  endOf,
  DATE_FORMAT_MONTH_DAY,
  formatDate,
} from 'services/dateService'

export enum DateRange {
  LAST_7_DAYS = 'last-7-days',
  LAST_30_DAYS = 'last-30-days',
  LAST_60_DAYS = 'last-60-days',
  LAST_90_DAYS = 'last-90-days',
  LAST_120_DAYS = 'last-120-days',
  LAST_2_WEEKS = 'last-2-weeks',
  ODR_NEXT_MONTH = 'next-month',
  ODR_THIS_MONTH = 'this-month',
  ODR_1_MONTH_AGO = 'one-month-ago',
  THIS_QUARTER = 'this-quarter',
  LAST_QUARTER = 'last-quarter',
  THIS_YEAR = 'this-year',
  YESTERDAY = 'yesterday',
  CUSTOM = 'custom',
}

export type DateRangeDictionary = Record<
  string,
  {
    label: string
    from: Date
    to: Date
  }
>

export type DateRangeConfigType =
  | 'odr'
  | 'odrMetric'
  | 'returnDisputeFinancials'
  | undefined

export interface Range {
  from: Date
  to: Date
}

export const labelFromRange = (label: string, from: Date, to: Date) =>
  `${label} (` +
  `${formatDate(from, DATE_FORMAT_MONTH_DAY)} - ` +
  `${formatDate(to, DATE_FORMAT_MONTH_DAY)})`

const createRangeConfig = (label: string, from: Date, to: Date) => ({
  label: labelFromRange(label, from, to),
  from,
  to,
})

export const getRangeConfig = (
  range: DateRange,
  referenceDate?: Date,
  type?: DateRangeConfigType,
) => {
  if (type === 'odrMetric') {
    const config = generateOrderDefectMetricRange() as DateRangeDictionary
    return config[range]
  }

  if (type === 'returnDisputeFinancials') {
    const config =
      generateReturnDisputeFinancialsRangeConfig() as DateRangeDictionary
    return config[range]
  }

  const config = generateRangeConfig(referenceDate) as DateRangeDictionary

  return config[range]
}

export const generateRangeConfig = memoize((referenceDate?: Date) => {
  const keyDate = startOf(referenceDate ?? new Date(), 'day')

  return {
    [DateRange.LAST_7_DAYS]: createRangeConfig(
      'Last 7 Days',
      startOf(getDateBefore(keyDate, 7, 'days'), 'day'),
      endOfToday(keyDate),
    ),
    [DateRange.LAST_30_DAYS]: createRangeConfig(
      'Last 30 Days',
      startOf(getDateBefore(keyDate, 30, 'days'), 'day'),
      endOfToday(keyDate),
    ),
    [DateRange.THIS_QUARTER]: createRangeConfig(
      'This Quarter',
      startOf(keyDate, 'quarter'),
      endOfToday(keyDate),
    ),
    [DateRange.LAST_QUARTER]: createRangeConfig(
      'Last Quarter',
      getDateBefore(startOf(keyDate, 'quarter'), 1, 'quarter'),
      endOf(getDateBefore(startOf(keyDate, 'quarter'), 1, 'day'), 'day'),
    ),
    [DateRange.THIS_YEAR]: createRangeConfig(
      'This Year',
      startOf(keyDate, 'year'),
      endOfToday(keyDate),
    ),
    [DateRange.CUSTOM]: {
      label: 'Custom',
      from: keyDate,
      to: endOfToday(keyDate),
    },
  }
})

export const generateOrderDefectMetricRange = memoize(() => {
  const keyDate = startOfDay(getDateBefore(new Date(), 1, 'days'))

  return {
    [DateRange.LAST_30_DAYS]: createRangeConfig(
      'Last 30 Days',
      startOf(getDateBefore(keyDate, 30, 'days'), 'day'),
      endOf(keyDate, 'day'),
    ),
    [DateRange.LAST_60_DAYS]: createRangeConfig(
      'Last 60 Days',
      startOf(getDateBefore(keyDate, 60, 'days'), 'day'),
      endOf(keyDate, 'day'),
    ),
    [DateRange.LAST_90_DAYS]: createRangeConfig(
      'Last 90 Days',
      startOf(getDateBefore(keyDate, 90, 'days'), 'day'),
      endOf(keyDate, 'day'),
    ),
    [DateRange.LAST_120_DAYS]: createRangeConfig(
      'Last 120 Days',
      startOf(getDateBefore(keyDate, 120, 'days'), 'day'),
      endOf(keyDate, 'day'),
    ),
    [DateRange.CUSTOM]: {
      label: 'Custom',
      from: keyDate,
      to: endOfToday(keyDate),
    },
  }
})

export const generateReturnDisputeFinancialsRangeConfig = memoize(() => {
  const keyDate = startOfDay(getDateBefore(new Date(), 1, 'days'))

  return {
    [DateRange.LAST_7_DAYS]: createRangeConfig(
      'Last 7 Days',
      startOf(getDateBefore(keyDate, 6, 'days'), 'day'),
      endOfToday(keyDate),
    ),
    [DateRange.YESTERDAY]: {
      label: `Yesterday (${formatDate(keyDate, DATE_FORMAT_MONTH_DAY)})`,
      from: keyDate,
      to: endOfToday(keyDate),
    },
    [DateRange.CUSTOM]: {
      label: 'Custom',
      from: keyDate,
      to: endOfToday(keyDate),
    },
  }
})
