import React from 'react'

import { Field, FieldProps } from 'formik'

import RadioButtonUncheckedOutlined from '@mui/icons-material/RadioButtonUncheckedOutlined'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormLabel from '@mui/material/FormLabel'

import Text from 'components/common/Text'
import theme from 'config/themeConfig'

interface Option {
  label: string
  description?: string
  value: unknown
}

interface FormProps {
  name: string
  options: Option[]
  label?: string
  disabled?: boolean
  required?: boolean
  touchedFieldName?: string | string[]
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void
}

type Props = FormProps & Omit<RadioGroupProps, 'name' | 'onChange' | 'value'>

const RadioGroupField = ({
  name,
  label,
  options,
  disabled,
  required,
  touchedFieldName,
  onChange,
  row = false,
  ...props
}: Props) => {
  return (
    <Field name={name}>
      {({ form, field }: FieldProps) => {
        const { touched, errors, isSubmitting } = form
        const { value } = field

        const isDisabled =
          typeof disabled === 'undefined' ? isSubmitting : disabled
        const color = isDisabled ? 'disabled' : 'primary'
        const error = errors[name]
        const hasError = !!(touched[name] && error)

        const onCheckboxChange = (
          event: React.ChangeEvent<HTMLInputElement>,
          value: string,
        ) => {
          form.setFieldValue(name, value)

          if (onChange) {
            onChange(event, value)
          }

          if (touchedFieldName) {
            if (Array.isArray(touchedFieldName)) {
              touchedFieldName.forEach((item) => {
                form.setFieldTouched(item, true, false)
              })
            } else {
              form.setFieldTouched(touchedFieldName, true, false)
            }
          }
        }

        return (
          <FormControl
            component="fieldset"
            required={required}
            error={hasError}
            disabled={isDisabled}
          >
            {label && (
              <FormLabel component="legend" style={{ fontSize: '0.75rem' }}>
                {label}
              </FormLabel>
            )}
            <RadioGroup
              {...props}
              {...field}
              onChange={onCheckboxChange}
              value={value || ''}
              row={row}
            >
              {options.map((option, index) => (
                <FormControlLabel
                  data-testid={`${name} - ${option.value}`}
                  key={option.label}
                  value={option.value}
                  label={
                    <div
                      style={{
                        paddingTop: option.description
                          ? theme.spacing(2)
                          : undefined,
                      }}
                    >
                      <Text type="bold">{option.label}</Text>
                      {option.description && <Text>{option.description}</Text>}
                    </div>
                  }
                  control={
                    <Radio
                      aria-checked={value === option.value}
                      tabIndex={index}
                      icon={<RadioButtonUncheckedOutlined color={color} />}
                    />
                  }
                />
              ))}
            </RadioGroup>
            {hasError && (
              <FormHelperText>
                <>{error}</>
              </FormHelperText>
            )}
          </FormControl>
        )
      }}
    </Field>
  )
}

export default RadioGroupField
