import StyledIcon from 'components/common/StyledIcon'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import {
  formatPercent,
  formatDateMDYT,
} from 'components/common/EnhancedTable/formatters'
import { TableHook } from 'components/common/EnhancedTable/useTable'
import TableSpacer from 'components/common/TableSpacer'

import { ReferralBasePercentage } from 'types/BaseReferralFee'

interface Props {
  table: TableHook
  data: ReferralBasePercentage[]
  total: number
  isPending: boolean
  edit?: (element: ReferralBasePercentage) => (event: any) => void
  viewHistory?: (element: ReferralBasePercentage) => (event: any) => void
}

const fieldList: EnhancedTableFieldType<ReferralBasePercentage>[] = [
  {
    key: 'subtypeName',
    heading: 'Subtype',
    hasSort: false,
    minWidth: 40,
  },
  {
    key: 'referral_percentage',
    heading: 'Base Fee',
    hasSort: true,
    minWidth: 20,
    formatCell: formatPercent('referral_percentage'),
  },
  {
    key: 'start_date',
    heading: 'Start Date',
    hasSort: true,
    minWidth: 40,
    formatCell: formatDateMDYT('start_date'),
  },
]

export const BaseReferralFeeTable = ({
  table,
  data,
  total,
  isPending,
  edit,
  viewHistory,
}: Props) => {
  return (
    <TableSpacer>
      <EnhancedTable
        total={total}
        onChangePage={table.actions.changePage}
        onChangeRowsPerPage={table.actions.changePerPage}
        onRequestSort={table.actions.sort}
        orderBy={table.state.orderBy}
        order={table.state.direction}
        page={table.state.page}
        rowsPerPage={table.state.perPage}
        fieldList={fieldList}
        paginationDisabled={false}
        data={data}
        isLoading={isPending}
        actions={[
          {
            callback: edit,
            label: 'Edit',
            icon: <StyledIcon iconType="edit" />,
          },
          {
            callback: viewHistory,
            label: 'View History',
            icon: <StyledIcon iconType="history" />,
          },
        ]}
      />
    </TableSpacer>
  )
}

export default BaseReferralFeeTable
