import React from 'react'

import LinearProgress from '@mui/material/LinearProgress'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import styled from '@emotion/styled'

import DateRangeSelector from 'components/common/DateRangePicker/DateRangeSelector'
import {
  generateRangeConfig,
  DateRange,
} from 'components/common/DateRangePicker/rangeConfig'
import { getRangeConfig } from 'components/common/DateRangePicker/rangeConfig'
import DisplayCard from 'components/common/DisplayCard'
import EnhancedTable from 'components/common/EnhancedTable'

import { GENERIC_API_ERROR } from 'constants/errors'

import {
  startOfDay,
  formatDate,
  DATE_PICKER_FORMAT,
} from 'services/dateService'

import { trackCustomEvent } from 'services/fireflyInsights'
import {
  fetchCardDataThruSecurityGateway,
  mapColumnsToTableFieldList,
} from 'services/securityGateway'
import { getTimePeriod, getVendorFilter } from 'services/biReporting'

import { BiReportingCards } from 'types/BiReporting'
import { FireflyEvent } from 'types/FireflyInsights'
import { SmsSeller } from 'types/Seller'

import { actions, initialState, reducer } from './reducer'

const StyledContent = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(4),
}))

const StyledTabContent = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
}))

const StyledTabContainer = styled(Tabs)(({ theme }) => ({
  margin: theme.spacing(0, -2),
}))

const makeDateRange = (from: Date, to: Date) =>
  `${formatDate(from, DATE_PICKER_FORMAT)}/${formatDate(
    to,
    DATE_PICKER_FORMAT,
  )}`

interface Props {
  seller?: SmsSeller
}

const getCards = (actions: any) => [
  {
    id: BiReportingCards.BEST_SELLERS,
    fetch: actions.fetchBestSellers,
    fulfill: actions.fulfillBestSellers,
    reject: actions.rejectBestSellers,
  },
  {
    id: BiReportingCards.TOP_CONVERTING,
    fetch: actions.fetchTopConverting,
    fulfill: actions.fulfillTopConverting,
    reject: actions.rejectTopConverting,
  },
  {
    id: BiReportingCards.MOST_RETURNED,
    fetch: actions.fetchMostReturned,
    fulfill: actions.fulfillMostReturned,
    reject: actions.rejectMostReturned,
  },
]

const tabs = ['Best Seller', 'Top Converting', 'Most Returned']

export const SalesMetricsTables = ({ seller }: Props) => {
  const datenRangeOptions = generateRangeConfig()

  const rangeConfig = getRangeConfig(DateRange.LAST_7_DAYS)
  const defaultFrom = startOfDay(rangeConfig.from)
  const defaultTo = startOfDay(rangeConfig.to)

  const [selectedTab, setSelectedTab] = React.useState<number>(0)
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const [dateRange, setDateRange] = React.useState<string>(
    makeDateRange(defaultFrom, defaultTo),
  )

  const { bestSeller, topConverting, mostReturned } = state

  const handleDateChange = (newFrom: Date, newTo: Date, isValid: boolean) => {
    if (!isValid) return

    setDateRange(makeDateRange(newFrom, newTo))
  }

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    trackCustomEvent(FireflyEvent.TAB_SELECT, 'Sales Metrics', tabs[newValue])
    setSelectedTab(newValue)
  }

  React.useEffect(() => {
    if (!seller || !seller.vmm_id) {
      return
    }

    const vendorFilter = getVendorFilter(seller.vmm_id)
    const timePeriod = getTimePeriod({ interval: dateRange, type: 'absolute' })

    getCards(actions).forEach((card) => {
      dispatch(card.fetch())

      fetchCardDataThruSecurityGateway(
        card.id,
        seller.id,
        vendorFilter,
        timePeriod,
      )
        .then((res) => {
          const columns = res.card_config.card_query_attribute.columns
          const data = res.query_results

          const fieldList = mapColumnsToTableFieldList(columns)

          dispatch(card.fulfill(data, fieldList))
        })
        .catch(() => {
          dispatch(card.reject())
        })
    })
  }, [seller, dateRange])

  let tabState
  switch (selectedTab) {
    case 0:
      tabState = bestSeller
      break
    case 1:
      tabState = topConverting
      break
    case 2:
      tabState = mostReturned
      break
  }

  return (
    <DisplayCard
      title="Top 25 Item Performance"
      description="Click tabs to view items with the highest sales volume, conversion rates and return quantities respectively."
    >
      <StyledContent>
        <DateRangeSelector
          descriptor="item-performance"
          defaultRange={DateRange.LAST_7_DAYS}
          defaultFrom={defaultFrom}
          defaultTo={defaultTo}
          onDateChange={handleDateChange}
          disableDatesBefore={new Date(seller?.created ?? '')}
          dateRangeOptions={datenRangeOptions}
          description=""
        />
      </StyledContent>
      <StyledTabContainer
        onChange={handleTabChange}
        value={selectedTab}
        indicatorColor="primary"
        variant="fullWidth"
      >
        {tabs.map((tab: string, index: number) => (
          <Tab key={index} label={tab} />
        ))}
      </StyledTabContainer>
      <StyledTabContent>
        {!tabState && <LinearProgress />}
        {tabState?.error && <div>{GENERIC_API_ERROR}</div>}
        {tabState && (
          <EnhancedTable
            fieldList={tabState.fieldList}
            data={tabState.data}
            isLoading={tabState.loading}
            total={tabState.data.length}
          />
        )}
      </StyledTabContent>
    </DisplayCard>
  )
}

export default SalesMetricsTables
