import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'

import { RoutePath } from 'services/NavigationHelper'
import { getParams } from 'services/urlHelper'
import { getItem, removeItem } from 'services/storageHelper'

import { confirmPaymentAccount } from 'store/paymentAccount/actionCreators'
import { getIsPaymentAccountConfirmed } from 'store/selectors'

export const KEY_PAYMENT_REDIRECT_PATH: string = 'paymentRedirectPath'
export const KEY_SELLER_ID: string = 'sellerId'

const StripeAccountSuccessPage = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const isPaymentAccountConfirmed = useSelector(getIsPaymentAccountConfirmed)

  const sellerId = getItem(KEY_SELLER_ID)
  const redirectPath = getItem(KEY_PAYMENT_REDIRECT_PATH)

  useEffect(() => {
    const { state, code } = getParams(location)
    if (!isPaymentAccountConfirmed && sellerId && state && code) {
      const codeString = Array.isArray(code) ? code.join() : code
      const stateString = Array.isArray(state) ? state.join() : state

      dispatch(
        confirmPaymentAccount(sellerId, {
          state: stateString,
          authorization_code: codeString,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentAccountConfirmed, location, sellerId])

  const shouldRedirect = RoutePath.STRIPE_ACCOUNT_SUCCESS === location.pathname

  if (redirectPath && isPaymentAccountConfirmed && shouldRedirect) {
    removeItem(KEY_SELLER_ID)
    removeItem(KEY_PAYMENT_REDIRECT_PATH)

    return <Navigate data-testid="redirect" to={redirectPath} replace />
  }

  return null
}

export default StripeAccountSuccessPage
