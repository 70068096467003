import { ChangeEvent, ChangeEventHandler, useEffect, useState } from 'react'

import { isValid, parse } from 'date-fns'
import { DayPicker, DayPickerProps } from 'react-day-picker'
import {
  DATE_DISPLAY_FORMAT,
  formatDateLocalTime,
  parseDate,
} from 'services/dateService'
import DateTextField from './DateTextField'
import 'react-day-picker/dist/style.css'
import { Popover } from '@mui/material'
import styled from '@emotion/styled'
import InputForDatePicker from '../DateRangePicker/InputForDatePicker'

type StyledPopoverProps = {
  isDateRange: boolean
}

const StyledPopover = styled(Popover, {
  shouldForwardProp: (prop) => prop !== 'isDateRange',
})<StyledPopoverProps>((props) => ({
  left: props.isDateRange ? '0px' : '-38px',
  top: props.isDateRange ? '25px' : 'px',
}))

export type Props = {
  value: string | Date | undefined
  placement?: 'start' | 'end'
  placeholder: string
  onChange: (day: Date) => void
  dayPickerProps: DayPickerProps
  inputProps?: any
  onDelete?: () => void
  isDisabled?: boolean
  inputComponent: any
  dateDisplayFormat: string
}
export const DayPickerInput = ({
  value,
  placement,
  placeholder,
  onChange,
  dayPickerProps,
  inputProps,
  onDelete,
  isDisabled,
  dateDisplayFormat,
  inputComponent,
}: Props) => {
  const [selected, setSelected] = useState<Date | undefined>(
    value ? (value as Date) : undefined,
  )
  const [inputValue, setInputValue] = useState<Date | undefined | string>(
    value ? formatDateLocalTime(value, dateDisplayFormat) : '',
  )
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: ChangeEvent<HTMLInputElement>) => {
    setAnchorEl(event.target as HTMLElement)
  }

  useEffect(() => {
    if (!value && selected) {
      setInputValue('')
      setSelected(undefined)
      setIsPopoverOpen(false)
    }
  }, [value, selected])

  const handlePopoverClose = () => {
    setIsPopoverOpen(false)
  }

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setInputValue(e.target.value)
    const date = parse(e.target.value, DATE_DISPLAY_FORMAT, new Date())

    if (isValid(date)) {
      setSelected(date)
      handleDateChange(date)
      setIsPopoverOpen(false)
    } else {
      setSelected(undefined)
    }
  }

  const handleButtonClick: ChangeEventHandler<HTMLInputElement> = (e) => {
    setIsPopoverOpen(true)
    handleClick(e)
  }

  const handleDateChange = (date: Date | undefined) => {
    if (date) {
      setSelected(date)
      onChange(date)
      parseDate(date.toString())
      setInputValue(formatDateLocalTime(date, dateDisplayFormat))
    } else {
      setInputValue('')
    }
    setIsPopoverOpen(false)
  }

  return (
    <div>
      {inputComponent === true ? (
        <InputForDatePicker
          placeholder={placeholder}
          value={inputValue ? inputValue?.toString() : ''}
          onChange={handleInputChange}
          onClick={handleButtonClick}
          placement={placement}
          {...inputProps}
        />
      ) : (
        <DateTextField
          placeholder={placeholder}
          value={inputValue ? inputValue?.toString() : ''}
          onChange={handleInputChange}
          inputRef={inputComponent}
          onClick={handleButtonClick}
          placement={placement}
          isDisabled={isDisabled}
          onDelete={onDelete}
          {...inputProps}
        />
      )}

      {isPopoverOpen && (
        <StyledPopover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={isPopoverOpen}
          anchorEl={anchorEl}
          role="dialog"
          onClose={handlePopoverClose}
          isDateRange={inputComponent === true ? true : false}
        >
          {/* TODO: refactor to use range */}
          {/* @ts-ignore */}
          <DayPicker
            initialFocus={isPopoverOpen}
            mode="single"
            fromMonth={dayPickerProps.fromMonth}
            defaultMonth={selected}
            numberOfMonths={
              dayPickerProps.numberOfMonths ? dayPickerProps.numberOfMonths : 1
            }
            selected={
              dayPickerProps.selected ? dayPickerProps.selected : selected
            }
            onDayClick={handleDateChange}
            disabled={dayPickerProps.disabled}
          />
        </StyledPopover>
      )}
    </div>
  )
}
export default DayPickerInput
