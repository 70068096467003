import React from 'react'

import styled from '@emotion/styled'

import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import MuiSelect from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import Tooltip from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'

import ValidationErrorText from 'components/common/ValidationErrorText'

import { Validation } from 'types/Validation'

const StyledInputLabel = styled(InputLabel)({
  display: 'flex',
  alignItems: 'center',
})

interface Props {
  id: string
  isRequired?: boolean
  isDisabled?: boolean
  label?: string
  testLabel?: string
  keyName?: string
  name: string
  onChange: (value: any, name: string) => void
  onBlur?: (value: any, name: string) => void
  options: Array<object | string | number>
  validation?: Validation
  value?: string | number | boolean
  valueName?: string
  shrink?: boolean
  tooltip?: string
  optionDisableCallback?: (item: any) => boolean
}

const Select = ({
  isRequired = false,
  id,
  name,
  value = '',
  label = '',
  testLabel,
  isDisabled = false,
  options,
  validation,
  keyName,
  valueName,
  shrink = true,
  tooltip,
  onChange,
  onBlur,
  optionDisableCallback,
}: Props) => {
  const handleChange = (event: any): void => {
    onChange(event.target.value, name)
  }

  const handleBlur = (event: any): void => {
    if (onBlur) {
      onBlur(event.target.value, name)
    }
  }

  const selectProps = testLabel
    ? ({ 'data-test': testLabel } as React.HTMLAttributes<HTMLDivElement>)
    : undefined

  return (
    <FormControl required={isRequired} fullWidth>
      {label && (
        <StyledInputLabel variant="standard" shrink={shrink} htmlFor={id}>
          {label}
          {tooltip && (
            <Tooltip title={tooltip} placement="top">
              <InfoIcon color="primary" />
            </Tooltip>
          )}
        </StyledInputLabel>
      )}
      <MuiSelect
        sx={{ color: isDisabled ? 'inherit' : undefined }}
        variant="standard"
        id={id}
        data-testid="select"
        value={value}
        disabled={isDisabled}
        onChange={handleChange}
        onBlur={handleBlur}
        input={
          <Input
            id={id}
            inputProps={{
              'aria-label': label,
              'data-testid': 'select-input',
            }}
          />
        }
        SelectDisplayProps={selectProps}
      >
        {
          // eslint-disable-next-line array-callback-return
          options.map((option: any) => {
            if (typeof option === 'object' && keyName && valueName) {
              return (
                <MenuItem
                  data-testid={`select-${option[valueName]}`}
                  key={option[keyName]}
                  value={option[valueName]}
                  disabled={
                    optionDisableCallback && optionDisableCallback(option)
                  }
                >
                  {option[keyName]}
                </MenuItem>
              )
            } else if (
              typeof option === 'string' ||
              typeof option === 'number'
            ) {
              return (
                <MenuItem
                  data-testid={`select-${option}`}
                  key={option}
                  value={option}
                  disabled={
                    optionDisableCallback && optionDisableCallback(option)
                  }
                >
                  {option}
                </MenuItem>
              )
            }
          })
        }
      </MuiSelect>
      {validation && <ValidationErrorText errors={validation} field={name} />}
    </FormControl>
  )
}

export default Select
