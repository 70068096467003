import Typography from '@mui/material/Typography'

export type Props = {
  searchTerm: string | undefined
  category: string | undefined
  total: number
}

export const SearchTitle = ({ searchTerm, category, total }: Props) => {
  let title = ''

  if (searchTerm) {
    const predicate: string = total === 1 ? 'result' : 'results'

    title = `${total} ${predicate} for ${searchTerm}`
  } else if (category) {
    const predicate: string = total === 1 ? 'article' : 'articles'

    title = `${category} (${total} ${predicate})`
  }

  return <Typography variant="h4">{title}</Typography>
}

export default SearchTitle
