import { useState, useEffect, SyntheticEvent } from 'react'

import { InputLabel } from '@mui/material'

import { CONTAINS, STARTS_WITH } from 'types/TaxonomySearchParams'
import Attribute from 'types/Attribute'
import Typeahead from '.'

export type DataType = {
  id: string
  name: string
}
export interface Props<T> {
  label?: string
  placeholder?: string
  isDisabled?: boolean
  data: T[]
  onChange: (value: T) => void
  value?: Nullable<Attribute>
  filterBy: typeof CONTAINS | typeof STARTS_WITH
}

export function StaticTypeahead<T extends Attribute>({
  label,
  placeholder = 'Search Merch Type Attribute',
  isDisabled,
  data,
  onChange,
  value: valueFromProps,
  filterBy,
}: Props<T>) {
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedValue, setSelectedValue] =
    useState<Nullable<Attribute>>(valueFromProps)
  const [options, setOptions] = useState<Attribute[]>(data)
  useEffect(() => {
    if (!valueFromProps) {
      setSelectedValue(null)
      setInputValue('')
      setOptions([])
    }
  }, [valueFromProps])

  const handleSelectedOptionChange = (
    _event: SyntheticEvent,
    value: Attribute,
  ) => {
    if (!(value instanceof Array)) {
      setSelectedValue(value as Nullable<Attribute>)
      onChange(value as T)
    }
  }

  const getOptionLabel = (item: DataType) => {
    return item?.name ?? ''
  }

  const handleInputChange = async (
    _event: SyntheticEvent,
    enteredValue: string,
    reason: string,
  ) => {
    if (
      reason === 'clear' ||
      (selectedValue && reason === 'input' && enteredValue === '')
    ) {
      setOptions([])
      setInputValue('')
      setSelectedValue(null)
      return
    }

    setInputValue(enteredValue)

    const invalidInputValue = !enteredValue || enteredValue.length === 0
    if (invalidInputValue) {
      setOptions([])
      return
    }

    const filteredData = data.filter((item) => {
      if (item?.name) {
        if (filterBy === CONTAINS) {
          return !!item?.name
            ?.toUpperCase()
            .includes(enteredValue.toUpperCase())
        } else if (filterBy === STARTS_WITH) {
          return !!item?.name
            ?.toUpperCase()
            .startsWith(enteredValue.toUpperCase())
        }
        return false
      }
      return false
    })
    const results = filteredData.slice(0, 10)
    setOptions(results)
  }

  return (
    <>
      <InputLabel shrink>{label}</InputLabel>
      <Typeahead
        label="Search Merch Type Attribute"
        aria-label="Search Merch Type Attribute"
        placeholder={placeholder}
        disabled={isDisabled}
        options={options}
        getOptionLabel={getOptionLabel}
        onChange={handleSelectedOptionChange}
        onInputChange={handleInputChange}
        value={selectedValue}
        inputValue={inputValue}
      />
    </>
  )
}

export default StaticTypeahead
