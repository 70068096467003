import isFunction from 'lodash/fp/isFunction'

import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

import { EnhancedTableFieldType, VerticalAlign } from './index'
import EnhancedTableActionButtonCell, {
  TableActionItem,
} from './EnhancedTableActionButtonCell'
import EnhancedTableCell from './EnhancedTableCell'
import EnhancedTableCellAsync from './EnhancedTableCellAsync'
import EnhancedTableProgress from './EnhancedTableProgress'

import '../../../styles/table.scss'

interface Props {
  data: object[]
  displayActionColumn?: boolean
  actions?: TableActionItem[]
  fieldList: EnhancedTableFieldType[]
  fieldListContext?: any
  isLoading: boolean
  verticalAlign?: VerticalAlign
  nullText?: string
  hover?: boolean
  stripedRows?: boolean
  highlightRow?: number
}

export const EnhancedTableBody = ({
  data,
  displayActionColumn = false,
  actions,
  fieldList,
  fieldListContext,
  isLoading,
  verticalAlign,
  nullText = 'No results found',
  hover = true,
  stripedRows = true,
  highlightRow,
}: Props) => {
  const colspan = fieldList.length

  return (
    <TableBody className={stripedRows ? 'striped-rows' : ''}>
      {isLoading ? (
        <EnhancedTableProgress isLoading={isLoading} colspan={colspan} />
      ) : null}
      {!isLoading && !data.length ? (
        <TableRow>
          <TableCell colSpan={colspan} data-element="table-not-found">
            {nullText}
          </TableCell>
        </TableRow>
      ) : null}
      {!isLoading && data.length
        ? data.map((rowItem: object, index: number) => {
            return (
              <TableRow
                hover={hover}
                key={index}
                className={highlightRow === index ? 'highlight-row' : ''}
              >
                {fieldList.map((fieldItem, idx) => {
                  const { formatCell, formatCellAsync, cellPadding, key } =
                    fieldItem

                  if (formatCellAsync && isFunction(formatCellAsync)) {
                    return (
                      <EnhancedTableCellAsync
                        key={idx}
                        item={rowItem}
                        fieldListContext={fieldListContext}
                        formatCellAsync={formatCellAsync}
                        verticalAlign={verticalAlign}
                        cellPadding={cellPadding}
                        variant="body"
                      />
                    )
                  }

                  let content
                  if (formatCell && isFunction(formatCell)) {
                    content = formatCell(rowItem, fieldListContext)
                  } else {
                    // @ts-ignore
                    content = rowItem[key]
                  }

                  return (
                    <EnhancedTableCell
                      key={idx}
                      verticalAlign={verticalAlign}
                      cellPadding={cellPadding}
                      variant="body"
                    >
                      {content}
                    </EnhancedTableCell>
                  )
                })}
                {displayActionColumn && actions && (
                  <EnhancedTableActionButtonCell
                    actions={actions}
                    rowItem={rowItem}
                    isLoading={isLoading}
                  />
                )}
              </TableRow>
            )
          })
        : null}
    </TableBody>
  )
}

export default EnhancedTableBody
