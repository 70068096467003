import { FieldList } from 'components/common/TabularData'

export enum ScoreInfoType {
  Images = 'Images',
  QA = 'Q&A',
  FeatureBullets = 'Feature Bullets',
  LabelInfo = 'Label Info',
  LongCopy = 'Long Copy',
  Reviews = 'Product Reviews',
  Video = 'Video',
  RichMedia = 'Rich Media',
  SizeCharts = 'Size Charts',
}

export type ScoreInfo = {
  contentType: string
  weight: string
  bestPractice: string
}

export const getScoreInfo = (
  type: ScoreInfoType,
  weight: number,
): ScoreInfo => {
  switch (type) {
    case ScoreInfoType.Images:
      return {
        contentType: ScoreInfoType.Images,
        weight: `${weight}%`,
        bestPractice: '3 images or more',
      }
    case ScoreInfoType.QA:
      return {
        contentType: ScoreInfoType.QA,
        weight: `${weight}%`,
        bestPractice: 'Questions should be answered in less than 48 hours',
      }
    case ScoreInfoType.FeatureBullets:
      return {
        contentType: ScoreInfoType.FeatureBullets,
        weight: `${weight}%`,
        bestPractice: '4 to 8 bullets',
      }
    case ScoreInfoType.LabelInfo:
      return {
        contentType: ScoreInfoType.LabelInfo,
        weight: `${weight}%`,
        bestPractice: '',
      }
    case ScoreInfoType.LongCopy:
      return {
        contentType: ScoreInfoType.LongCopy,
        weight: `${weight}%`,
        bestPractice: 'At least 50 words in paragraph format',
      }
    case ScoreInfoType.Reviews:
      return {
        contentType: ScoreInfoType.Reviews,
        weight: `${weight}%`,
        bestPractice: '',
      }
    case ScoreInfoType.Video:
      return {
        contentType: ScoreInfoType.Video,
        weight: `${weight}%`,
        bestPractice: '',
      }
    case ScoreInfoType.RichMedia:
      return {
        contentType: ScoreInfoType.RichMedia,
        weight: `${weight}%`,
        bestPractice: '',
      }
    case ScoreInfoType.SizeCharts:
      return {
        contentType: ScoreInfoType.SizeCharts,
        weight: `${weight}%`,
        bestPractice: 'Relevant size chart emailed to Target team',
      }
    default:
      return {
        contentType: '',
        weight: '',
        bestPractice: '',
      }
  }
}

export const getFieldList = (): FieldList<ScoreInfo>[] => [
  {
    key: 'contentType',
    displayName: 'Content Type',
  },
  {
    key: 'weight',
    displayName: 'Weight',
  },
  {
    key: 'bestPractice',
    displayName: 'Target Best Practices',
    formatCell: (item: ScoreInfo) => {
      if (item.contentType === ScoreInfoType.LabelInfo) {
        return (
          <span>
            Provide active ingredients, chemical products and gluten inclusion
            using{' '}
            <a
              href="https://retailers.labelinsight.com/target_onboarding"
              target="_blank"
              rel="noopener noreferrer"
            >
              Label Insights
            </a>
          </span>
        )
      } else if (item.contentType === ScoreInfoType.Reviews) {
        return (
          <span>
            8 reviews or More, leverage{' '}
            <a
              href="https://resources.bazaarvoice.com/brand-invite-target.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Bazaar Voice
            </a>
          </span>
        )
      } else if (item.contentType === ScoreInfoType.Video) {
        return (
          <span>
            1 or more videos, leverage{' '}
            <a
              href="https://storyxpress.co/partner/target"
              target="_blank"
              rel="noopener noreferrer"
            >
              StoryXpress
            </a>
          </span>
        )
      } else if (item.contentType === ScoreInfoType.RichMedia) {
        return (
          <span>
            1 or assets, leverage{' '}
            <a
              href="http://targetcontentpublisher.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Syndigo
            </a>{' '}
            or{' '}
            <a
              href="https://www.salsify.com/targetrichmedia"
              target="_blank"
              rel="noopener noreferrer"
            >
              Salsify
            </a>
          </span>
        )
      } else {
        return item.bestPractice
      }
    },
  },
]
