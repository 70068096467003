import axios from 'axios'
import memoize from 'lodash/fp/memoize'

import apiConfig from 'config/apiConfig'

import updateObjectValues from './updateObjectValues'
import { PagingParams, getPageable } from './pageableHelper'

import { isSmsSeller } from 'types/Guards'
import { IgnoredStatus } from 'types/HttpError'
import { CollectionResponse } from 'types/Response'
import {
  SellerStatus,
  SmsSeller,
  SmsDistributionCenter,
  ShipNode,
  SellerSearchParams,
  SellerStatusHistory,
  SellerSource,
  SellerSourceStatus,
} from 'types/Seller'

export const approvedToListStatuses = [
  SellerStatus.KICK_OFF,
  SellerStatus.DATA_INTEGRATION,
  SellerStatus.RAMP_UP,
  SellerStatus.ONBOARDING_COMPLETE,
  SellerStatus.ESTABLISHED,
]

export function getAllSellers() {
  const config = {
    params: {
      per_page: 200,
    },
  }

  return axios
    .get(`${apiConfig.sms}/sellers`, config)
    .then(
      (res: {
        data: SmsSeller[]
        headers: any
      }): CollectionResponse<SmsSeller> => {
        const { data, headers = {} } = res

        const total = headers['x-total-count']
          ? parseInt(headers['x-total-count'], 10)
          : 0

        return {
          total,
          data,
        }
      },
    )
}

export async function getSeller(
  id: string,
  ignoredStatuses?: IgnoredStatus[],
): Promise<SmsSeller | undefined> {
  const config = ignoredStatuses
    ? {
        params: {},
        ignoredStatuses,
      }
    : undefined

  const seller = await axios.get(`${apiConfig.sms}/sellers/${id}`, config)

  if (isSmsSeller(seller.data)) {
    return seller.data
  }
}

export function getSellerStatusHistories(
  id: string,
): Promise<SellerStatusHistory[]> {
  return axios
    .get(`${apiConfig.sms}/sellers/${id}/status_histories`)
    .then((res) => res.data)
}

export const memoizedGetSeller = memoize(
  async (id: string) => await getSeller(id),
)

export const memoizedGetSellerDisplayName = memoize(async (id: string) => {
  let displayName
  try {
    const seller = await getSeller(id)
    displayName = seller?.display_name
  } catch (e) {
    console.error(e)
  }

  return displayName
})

export const memoizeGetSellersByVendorId = memoize(async (vendorId: string) => {
  try {
    const seller = await searchSellers(
      { page: 0, perPage: 1 },
      { vmm_id: vendorId },
    )

    return seller.data[0]
  } catch (e) {
    console.error(`Failed getting seller with Vendor ID ${vendorId}: ${e}`)
  }
})

let cancel: any

export async function searchSellers(
  pagingParams: PagingParams,
  searchParams: SellerSearchParams,
  options?: { shouldCancel: boolean },
): Promise<CollectionResponse<SmsSeller>> {
  const pageable = getPageable(pagingParams)
  const params = {
    ...pageable,
    ...searchParams,
  }

  const shouldCancel = options?.shouldCancel

  if (shouldCancel && cancel) {
    cancel()
  }

  const cancelToken = shouldCancel
    ? new axios.CancelToken((c) => {
        cancel = c
      })
    : undefined

  const { data, headers } = await axios.get(`${apiConfig.sms}/sellers_search`, {
    params,
    cancelToken,
  })
  const total = headers['x-total-count']
    ? parseInt(headers['x-total-count'], 10)
    : 0
  return { data, total }
}

export async function getSellerCount(
  searchParams: SellerSearchParams,
): Promise<number> {
  const config = {
    params: {
      ...searchParams,
      page: 1,
      per_page: 1,
    },
  }

  const res = await axios.get(`${apiConfig.sms}/sellers_search`, config)
  return res.headers['x-total-count']
    ? parseInt(res.headers['x-total-count'], 10)
    : 0
}

export function saveSeller(seller: Partial<SmsSeller>) {
  const sellerCreate = updateObjectValues(seller, '', null)
  return axios
    .post(`${apiConfig.sms}/sellers`, sellerCreate)
    .then((res) => res.data)
}

export function updateSeller(id: string, seller: SmsSeller) {
  const sellerUpdate = updateObjectValues(seller, '', null)

  return axios
    .put(`${apiConfig.sms}/sellers/${id}`, sellerUpdate)
    .then((res) => res.data)
}

export function updateSellerDistributionCenter(
  id: string,
  distributionCenter: Partial<SmsDistributionCenter>,
) {
  const distributionCenterUpdate = updateObjectValues(
    distributionCenter,
    '',
    null,
  )

  const url = `${apiConfig.sms}/sellers/${id}/distribution_centers`
  if (distributionCenter.id) {
    return axios
      .put(`${url}/${distributionCenter.id}`, distributionCenterUpdate)
      .then((res) => res.data)
  }

  return axios.post(url, distributionCenterUpdate).then((res) => res.data)
}

export function updateShipNode(
  sellerId: string,
  distributionCenterId: string,
  shipNode: ShipNode,
  isInternalShipNodesApi?: boolean,
) {
  const url = `${apiConfig.sms}/sellers/${sellerId}/distribution_centers/${distributionCenterId}/ship_nodes`

  const shipNodeUpdate = updateObjectValues(shipNode, '', null)

  if (shipNode.id) {
    const putUrl = isInternalShipNodesApi ? `${url}/internal` : url
    return axios
      .put(`${putUrl}/${shipNode.id}`, shipNodeUpdate)
      .then((res) => res.data)
  }

  return axios.post(url, shipNodeUpdate).then((res) => res.data)
}
export type SellerSourceParams = {
  status?: SellerSourceStatus
}

export const getSellerSources = (
  sourceParams: SellerSourceParams,
  pagingParams: PagingParams = {
    page: 0,
    perPage: 100,
  },
): Promise<CollectionResponse<SellerSource>> => {
  const pageable = getPageable(pagingParams)

  const config = {
    params: {
      ...sourceParams,
      ...pageable,
    },
  }

  return axios.get(`${apiConfig.sms}/seller_sources`, config).then((res) => {
    const { data, headers } = res
    const total = headers['x-total-count']
      ? parseInt(headers['x-total-count'], 10)
      : 0

    return {
      total,
      data,
    }
  })
}

export const getSellerSource = (sourceId: string): Promise<SellerSource> => {
  return axios
    .get(`${apiConfig.sms}/seller_sources/${sourceId}`)
    .then((res) => res.data)
}

export const addSellerSource = (
  description: string,
  status: SellerSourceStatus,
  multiShipNode: boolean,
) => {
  return axios
    .post(`${apiConfig.sms}/seller_sources`, {
      description,
      status,
      multi_ship_node: multiShipNode,
    })
    .then((res) => res.data)
}

export const updateSellerSource = (source: SellerSource) => {
  return axios
    .put(`${apiConfig.sms}/seller_sources/${source.id}`, source)
    .then((res) => res.data)
}
