import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import TagFacesIcon from '@mui/icons-material/TagFaces'
import DialogContainer from 'components/common/Dialog/DialogContainer'

type Props = {
  isOpen: boolean
}

export const ReportErrorFeedbackDialog = ({ isOpen }: Props) => {
  return (
    <DialogContainer
      title={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TagFacesIcon />
          <div>Thank you for your feeback</div>
        </Box>
      }
      isOpen={isOpen}
      isPending={false}
      isSubmitDisabled={true}
      hideActions={true}
      autoClose={true}
    >
      <Typography>Your feedback is greatly appreciated.</Typography>
    </DialogContainer>
  )
}

export default ReportErrorFeedbackDialog
