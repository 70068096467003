import React, { useState, useEffect } from 'react'

import find from 'lodash/fp/find'

import { error } from 'config/themeConfig'

import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import WarningIcon from 'components/common/WarningIcon'

import { TargetPlusBarcodeDetails } from './index'

import { getBarcodeDetails } from 'services/barcodeValidation'
import { getMarketplaceProducts, getSmsProduct } from 'services/items'
import { getOrderCount } from 'services/orders'
import { formatDateRange, getDateBefore } from 'services/dateService'

const StyledFlexContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
}))

const StyledButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme.spacing(2),
}))

const StyledDefunctWarningText = styled(Typography)({
  color: error.main,
})

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

export interface Props {
  barcodes: string[]
  submitText: string
  fieldList: EnhancedTableFieldType<TargetPlusBarcodeDetails>[]
  handleSubmit: (suspendBarcodes: TargetPlusBarcodeDetails[]) => void
  handleReset: () => void
}

const getItemSeller = async (tcin: string) => {
  const product = await getMarketplaceProducts(
    { tcin: tcin },
    { page: 0, perPage: 1 },
  )

  return {
    sellerId: product.data[0].seller_id,
    sellerName: product.data[0].seller_name,
    productId: product.data[0].id,
  }
}
const getItemOrder = async (tcin: string) => {
  const count = await getOrderCount({
    tcin: tcin,
    order_date: formatDateRange(getDateBefore(new Date(), 120), new Date()),
  })
  return count
}

const getItemInventory = async ({
  sellerId,
  productId,
}: {
  sellerId: string
  productId: string
}) => {
  const product = await getSmsProduct({
    sellerId,
    productId,
    params: { expand: 'fields' },
  })

  const inventory = product.quantities?.reduce(
    (totalQuantities, distributionCenter) => {
      return totalQuantities + distributionCenter.quantity
    },
    0,
  )

  return inventory
}

const getBarcodeSellersOrders = async (barcode: TargetPlusBarcodeDetails) => {
  const productInfo = barcode.barcodeInfo.owner
    ? await getItemSeller(barcode.barcodeInfo.owner.tcins[0])
    : ''
  const orderCount = barcode.barcodeInfo.owner
    ? await getItemOrder(barcode.barcodeInfo.owner.tcins[0])
    : ''

  const inventory = productInfo
    ? await getItemInventory({
        sellerId: productInfo.sellerId,
        productId: productInfo.productId,
      })
    : ''

  return {
    barcode: barcode.barcode,
    barcodeInfo: barcode.barcodeInfo,
    productInfo,
    orderCount,
    inventory,
  } as TargetPlusBarcodeDetails
}

export const BarcodeItemSuspend = ({
  barcodes,
  submitText,
  fieldList,
  handleSubmit,
  handleReset,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [targetPlusBarcodes, setTargetPlusBarcodes] = useState<
    TargetPlusBarcodeDetails[]
  >([])
  const [nonTargetPlusBarcodes, setNonTargetPlusBarcodes] = useState<string[]>(
    [],
  )
  const [invalidBarcodes, setInvalidBarcodes] = useState<string[]>([])
  const [itemSuspendBarcodes, setItemSuspendBarcodes] = useState<
    TargetPlusBarcodeDetails[]
  >([])

  const onChangeCheckbox =
    (checkedBarcode: TargetPlusBarcodeDetails) =>
    (_event: React.FormEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        setItemSuspendBarcodes((existingItemSuspendBarcodes) => [
          ...existingItemSuspendBarcodes,
          checkedBarcode,
        ])
      } else {
        setItemSuspendBarcodes((existingItemSuspendBarcodes) =>
          existingItemSuspendBarcodes.filter(
            (itemSuspendBarcode) =>
              itemSuspendBarcode.barcode !== checkedBarcode.barcode,
          ),
        )
      }
    }

  const onChangeMainCheckbox =
    () => (_event: React.FormEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        setItemSuspendBarcodes(targetPlusBarcodes)
      } else {
        setItemSuspendBarcodes([])
      }
    }

  fieldList = [
    {
      key: 'checkbox',
      heading: 'Checkbox',
      formatHeader: () => {
        return (
          <Checkbox
            data-testid={'all-checkbox'}
            checked={
              targetPlusBarcodes.length === itemSuspendBarcodes.length &&
              targetPlusBarcodes.length > 0
            }
            onChange={onChangeMainCheckbox()}
          />
        )
      },
      formatCell: (item: TargetPlusBarcodeDetails) => {
        return (
          <Checkbox
            data-testid={`${item.barcode}-checkbox`}
            checked={!!find(item, itemSuspendBarcodes)}
            onChange={onChangeCheckbox(item)}
          />
        )
      },
    },
    ...fieldList,
  ]

  useEffect(() => {
    let canceled = false
    setIsLoading(true)
    barcodes.forEach((barcode) => {
      getBarcodeDetails(barcode)
        .then((barcodeDetail) => {
          if (
            barcodeDetail.owner?.channel === 'TargetPlus' &&
            barcodeDetail.available === false
          ) {
            if (canceled) return
            setIsLoading(false)
            getBarcodeSellersOrders({
              barcode: barcode,
              barcodeInfo: barcodeDetail,
            }).then((response) => {
              if (canceled) return
              setTargetPlusBarcodes((prev) => {
                return [...prev, response].sort(
                  (a, b) => (b?.orderCount ?? 0) - (a?.orderCount ?? 0),
                )
              })
            })
          } else if (
            barcodeDetail.owner?.channel === 'Target' ||
            barcodeDetail.available === true
          ) {
            if (canceled) return
            setNonTargetPlusBarcodes((existingNonTargetPlusBarcodes) => [
              ...existingNonTargetPlusBarcodes,
              barcode,
            ])
          }
        })
        .catch((error) => {
          if (
            error.response?.data.reason ===
              'Barcode type is not supported by Target' ||
            error.response?.data.reason ===
              'Invalid checksum on provided barcode'
          ) {
            if (canceled) return
            setInvalidBarcodes((existingInvalidBarcodes) => [
              ...existingInvalidBarcodes,
              barcode,
            ])
          }
        })
    })
    setIsLoading(false)

    return () => {
      canceled = true
    }
  }, [barcodes])

  const onSubmit = () => {
    handleSubmit(itemSuspendBarcodes)
  }

  return (
    <div data-testid="barcode-item-suspension">
      {nonTargetPlusBarcodes.length > 0 && (
        <StyledFlexContainer data-testid="non-target-plus-barcodes">
          <WarningIcon>
            <StyledDefunctWarningText>
              Barcodes that are not owned by Target Plus:{' '}
              {nonTargetPlusBarcodes.join(', ')}
            </StyledDefunctWarningText>
          </WarningIcon>
        </StyledFlexContainer>
      )}
      {invalidBarcodes.length > 0 && (
        <StyledFlexContainer data-testid="invalid-barcodes">
          <WarningIcon>
            <StyledDefunctWarningText>
              Invalid Entries: {invalidBarcodes.join(', ')}
            </StyledDefunctWarningText>
          </WarningIcon>
        </StyledFlexContainer>
      )}
      <StyledButtonContainer>
        <Button
          data-testid="reset-barcodes"
          onClick={handleReset}
          color="primary"
        >
          back to search
        </Button>
        <Button
          color="primary"
          variant="contained"
          data-testid="suspend-items"
          onClick={onSubmit}
          disabled={itemSuspendBarcodes.length < 1}
        >
          {submitText}
        </Button>
      </StyledButtonContainer>
      <StyledDivider />
      <EnhancedTable
        isLoading={isLoading}
        data={targetPlusBarcodes}
        fieldList={fieldList}
      />
    </div>
  )
}

export default BarcodeItemSuspend
