import {
  Chart,
  DateAxis,
  LabelBullet,
  Legend,
  LineSeries,
  NavigationBar,
  TreeMap,
  ValueAxis,
  XYChart,
  XYCursor,
  ColumnSeries,
  CategoryAxis,
} from '@amcharts/amcharts4/charts'
import {
  color,
  create,
  LinearGradient,
  registry,
  TimeUnit,
  ColorSet,
  percent,
  Scrollbar,
  MouseCursorStyle,
  disposeAllCharts,
} from '@amcharts/amcharts4/core'

import { BiData } from 'types/BiReporting'
import { ChartSeasonalEvent } from 'types/SeasonalEvent'

/* General Methods */
export const getById = (id: string): Chart | undefined => {
  let chart
  if (!registry) return undefined

  registry.baseSprites.forEach((sprite) => {
    if (sprite.id === id) {
      chart = sprite as Chart
    }
  })

  return chart
}

export const disposeById = (id: string) => getById(id)?.dispose()

export const disposeAllAmChartsAtOnce = () => {
  disposeAllCharts()
}
/* LineGraph */
export interface LineGraphConfig {
  xAxis?: {
    zoom?: {
      to: Date
      from: Date
    }
  }
  series?: {
    valueFormat: string
  }
}

const getTooltip = (
  type: string,
  format: string,
  xKey: string,
  yKey: string,
) => {
  let tooltip = '{valueY}'

  if (type === 'timeseries') {
    if (format === 'currency') {
      tooltip = `[font-size: 0.9em]{${xKey}.formatDate("MMM d, yyyy")}[/]\n[bold]\${${yKey}}[/]`
    } else if (format === 'percentage') {
      tooltip = `[font-size: 0.9em]{${xKey}.formatDate("MMM d, yyyy")}[/]\n[bold]{${yKey}}%[/]`
    }
  }

  return tooltip
}

export const createLineGraph = (
  id: string,
  data: BiData,
  config?: LineGraphConfig,
): XYChart => {
  const gradient = new LinearGradient()
  gradient.addColor(color('#cc0000'))
  gradient.addColor(color('#6699ff'))

  const chart = create(id, XYChart)
  chart.id = id
  chart.numberFormatter.numberFormat = '###,###,###.##'

  const { card_attribute: card, card_query_attribute: query } = data.card_config

  const { x_axis: xAxisConfig, y_axis: yAxisConfig } = card

  const isCompareBy = data.query.intervals.length > 1

  const yRefId = yAxisConfig?.[0].ref_id
  if (yRefId === undefined) {
    return chart
  }

  const baseSeries = new LineSeries()
  baseSeries.name = 'Base'
  baseSeries.stroke = color('#6699ff')
  baseSeries.strokeWidth = 3
  baseSeries.dataFields.dateX = 'date'

  let compareSeries: LineSeries | undefined

  if (isCompareBy) {
    chart.legend = new Legend()
    chart.legend.position = 'top'

    compareSeries = new LineSeries()
    compareSeries.name = 'Compare'
    compareSeries.stroke = color('#cc0000')
    compareSeries.strokeWidth = 3
    compareSeries.dataFields.dateX = 'date'
  }

  const xAxis = chart.xAxes.push(new DateAxis())
  if (xAxis.tooltip) {
    xAxis.tooltip.disabled = true
  }

  if (xAxisConfig?.type === 'timeseries') {
    baseSeries.tooltipText = getTooltip(
      'timeseries',
      config?.series?.valueFormat ?? 'number',
      'date',
      'base',
    )

    if (compareSeries) {
      compareSeries.tooltipText = getTooltip(
        'timeseries',
        config?.series?.valueFormat ?? 'number',
        'compareDate',
        'compare',
      )
    }

    xAxis.baseInterval = {
      timeUnit: query.time_period.granularity?.toLowerCase() as TimeUnit,
      count: 1,
    }

    if (config?.xAxis?.zoom) {
      chart.events.on('ready', function () {
        if (config?.xAxis?.zoom?.from && config?.xAxis?.zoom?.to) {
          xAxis.zoomToDates(
            config?.xAxis?.zoom?.from,
            config?.xAxis?.zoom?.to,
            false,
            true,
          )
        }
      })
    }

    xAxis.dateFormatter.dateFormat = 'mmm d'
  } else {
    baseSeries.dataFields.valueX = xAxisConfig?.ref_id
  }

  const yAxis = chart.yAxes.push(new ValueAxis())
  if (yAxis.tooltip) {
    yAxis.tooltip.disabled = true
  }
  baseSeries.dataFields.valueY = 'base'

  if (baseSeries.tooltip) {
    baseSeries.tooltip.getFillFromObject = false
    baseSeries.tooltip.background.fill = color('#366CD9')
    baseSeries.tooltip.label.fill = color('#000000')
    baseSeries.tooltip.background.strokeOpacity = 0
  }

  chart.series.push(baseSeries)

  if (isCompareBy && compareSeries) {
    compareSeries.dataFields.valueY = 'compare'

    if (compareSeries.tooltip) {
      compareSeries.tooltip.getFillFromObject = false
      compareSeries.tooltip.background.fill = color('#fff1f0')
      compareSeries.tooltip.label.fill = color('#000000')
      compareSeries.tooltip.background.strokeOpacity = 0
    }

    chart.series.push(compareSeries)
  }

  chart.data = data.query_results

  chart.cursor = new XYCursor()
  chart.cursor.lineY.opacity = 0
  chart.cursor.lineX.opacity = 0
  chart.cursor.behavior = 'none'

  chart.zoomOutButton.disabled = true

  return chart
}

/* Bar graph */
export const createCategoryChart = (id: string, data: any) => {
  const chart = create(id, XYChart)
  chart.id = id

  const series = new ColumnSeries()
  series.columns.template.tooltipText = '{valueY}%'
  // always show value
  series.columns.template.alwaysShowTooltip = true
  // fix to above bar
  series.columns.template.tooltipY = 0
  series.dataFields.categoryX = 'title'
  series.dataFields.valueY = 'value'

  if (series.tooltip) {
    // hides backgorund and boarder of label
    series.tooltip.background.fillOpacity = 0
    series.tooltip.background.strokeOpacity = 0
    // orients the root of the object down
    series.tooltip.label.textAlign = 'middle'
    series.tooltip.pointerOrientation = 'down'
    // overrides the base functionality and shows "NA" when there is no value
    series.tooltip.label.adapter.add('text', (text, target) => {
      const context = target.dataItem?.dataContext as any
      return context?.value === 'N/A' ? 'N/A' : text
    })
  }

  const xAxis = chart.xAxes.push(new CategoryAxis())
  xAxis.dataFields.category = 'title'
  // forces display of all values
  xAxis.renderer.minGridDistance = 30
  // hide x axis grid lines
  xAxis.renderer.grid.template.opacity = 0

  const yAxis = chart.yAxes.push(new ValueAxis())
  // bump label inside and hides it
  yAxis.renderer.inside = true
  yAxis.renderer.opacity = 0
  // hides y axis grid lines
  yAxis.renderer.grid.template.opacity = 0

  yAxis.chart.series.push(series)

  chart.data = data

  return chart
}

/* Tree Map */
export const createTreeMap = (id: string) => {
  const chart = create(id, TreeMap)
  chart.id = id

  chart.layoutAlgorithm = chart.binaryTree
  chart.maxLevels = 1

  chart.dataFields.value = 'count'
  chart.dataFields.name = 'value'
  chart.dataFields.children = 'children'
  chart.dataFields.color = 'color'

  for (let i = 0; i < 3; i++) {
    const template = chart.seriesTemplates.create(i.toString(10))

    const bullet = template.bullets.push(new LabelBullet())
    bullet.locationY = 0.5
    bullet.locationX = 0.5
    bullet.label.text = '{name}: {value}'
    bullet.label.fill = color('#000')

    const column = template.columns.template
    column.tooltipText = '{name}: {value}'
    column.tooltipPosition = 'pointer'
  }

  chart.navigationBar = new NavigationBar()
  chart.homeText = 'All Pending Items'

  return chart
}

export const createGanttChart = ({
  id,
  data,
  minDate,
  maxDate,
  zoomStartDate,
  zoomEndDate,
  onClick,
}: {
  id: string
  data: ChartSeasonalEvent[]
  minDate: string
  maxDate: string
  zoomStartDate: string
  zoomEndDate: string
  onClick: (seasonalEvent: ChartSeasonalEvent) => void
}) => {
  const colorSet = new ColorSet()
  colorSet.saturation = 0.4

  const chart = create(id, XYChart)
  chart.data = data
  chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd'
  chart.dateFormatter.dateFormat = 'yyyy-MM-dd'
  chart.hiddenState.properties.opacity = 0 // this creates initial fade-in

  // Force chart to show all events by auto adjusting the height based on number of data items
  // https://www.amcharts.com/docs/v4/tutorials/auto-adjusting-chart-height-based-on-a-number-of-data-items/
  const cellSize = 50
  chart.events.on('datavalidated', function (ev) {
    // Get objects of interest
    const chart = ev.target
    const categoryAxis = chart.yAxes.getIndex(0)
    const axisPixelHeight = categoryAxis?.pixelHeight ?? 0
    // Calculate how we need to adjust chart height
    const adjustHeight = chart.data.length * cellSize - axisPixelHeight
    // get current chart height
    const targetHeight = chart.pixelHeight + adjustHeight
    // Set it on chart's container
    if (chart.svgContainer) {
      chart.svgContainer.htmlElement.style.height = targetHeight + 'px'
    }
  })

  const dateAxis = chart.xAxes.push(new DateAxis())
  dateAxis.baseInterval = { timeUnit: 'day', count: 1 }
  dateAxis.renderer.minGridDistance = 70
  dateAxis.min = new Date(minDate).getTime()
  dateAxis.max = new Date(maxDate).getTime()
  dateAxis.renderer.opposite = true
  dateAxis.renderer.grid.template.location = 0
  dateAxis.renderer.labels.template.wrap = true

  chart.events.on('ready', function () {
    dateAxis.zoomToDates(new Date(zoomStartDate), new Date(zoomEndDate))
  })

  const categoryAxis = chart.yAxes.push(new CategoryAxis())
  categoryAxis.dataFields.category = 'title'
  categoryAxis.renderer.grid.template.location = 0
  categoryAxis.renderer.inversed = true
  categoryAxis.renderer.labels.template.align = 'left'
  categoryAxis.renderer.labels.template.cursorOverStyle =
    MouseCursorStyle.pointer
  categoryAxis.renderer.labels.template.events.on('hit', function (event) {
    const data = event.target.dataItem.dataContext as ChartSeasonalEvent
    onClick({ ...data })
  })

  const series = chart.series.push(new ColumnSeries())
  series.columns.template.width = percent(10)
  series.columns.template.height = percent(70)
  if (series.tooltip) {
    series.tooltip.getFillFromObject = false
    series.tooltip.background.fill = color('white')
    series.tooltip.label.fill = color('black')
  }
  series.columns.template.tooltipText =
    '{description}: [bold]{openDateX}[/] - [bold]{dateX}[/]'
  series.dataFields.openDateX = 'start_date'
  series.dataFields.dateX = 'end_date'
  series.dataFields.categoryY = 'title'
  series.fill = color('#188294')
  series.stroke = color('#188294')

  chart.scrollbarX = new Scrollbar()

  return chart
}
