import apiConfig from 'config/apiConfig'

import { getItem, LOCAL_STORAGE_ACCESS_TOKEN } from 'services/storageHelper'

import Stomp, { StompFrame } from './Stomp'

export interface WebSocketInterface {
  disconnect: (callback?: () => void) => void
  send: (destination: string, body?: any, headers?: Dictionary<string>) => void
  subscribe: (
    destination: string,
    callback: (frame: StompFrame) => void,
    headers?: Dictionary<string>,
  ) => string
  unsubscribe: (id: string, headers?: Dictionary<string>) => void
}

const WebSocketClient = (host: string, onConnect?: () => void) => {
  let bearerToken = getItem(LOCAL_STORAGE_ACCESS_TOKEN) ?? ''

  const ws = Stomp().client(host)
  ws.connect(
    {
      Authorization: bearerToken,
      API_KEY_HEADER: apiConfig.apiGatewayKey,
      'accept-version': '1.2,1.1',
      'heart-beat': '10000,10000',
    },
    onConnect,
  )

  return {
    disconnect: ws.disconnect,
    send: ws.send,
    subscribe: ws.subscribe,
    unsubscribe: ws.unsubscribe,
  }
}

export default WebSocketClient
