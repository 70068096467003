import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import InternationalSurveyPageOne, {
  FormValues,
} from './InternationalSurveyPageOne'

import { editPartnerDiversityAnswers } from 'store/partnerDiversity/actionCreators'
import { currentSellerId } from 'store/selectors'

import { DiversityAnswer } from 'types/PartnerDiversity'
import { formatAnswersForSurvey } from 'services/partnerDiversity'

type Props = {
  answers: DiversityAnswer[]
}

export const InternationalDialogWrapper: React.FC<Props> = ({ answers }) => {
  const initialState = formatAnswersForSurvey(answers)
  const isEdit = answers.length > 0

  const sellerId = useSelector(currentSellerId)
  const reduxDispatch = useDispatch()

  const handleSubmit = (values: FormValues) => {
    if (sellerId) {
      reduxDispatch(editPartnerDiversityAnswers({ sellerId, answers: values }))
    }
  }

  return (
    <InternationalSurveyPageOne
      onSubmit={handleSubmit}
      isEdit={isEdit}
      initValues={{
        WOMAN: initialState.WOMAN,
        WOMAN_CERT: initialState.WOMAN_CERT,
        WOMAN_CERT_DATE: initialState.WOMAN_CERT_DATE,
        WOMAN_OTHER: initialState.WOMAN_OTHER,
        WOMAN_OTHER_CERT: initialState.WOMAN_OTHER_CERT,
        WOMAN_OTHER_CERT_DATE: initialState.WOMAN_OTHER_CERT_DATE,
      }}
    />
  )
}

export default InternationalDialogWrapper
