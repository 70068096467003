import React from 'react'
import snakeCase from 'lodash/fp/snakeCase'

import {
  DEFAULT_SERVICE_LEVEL_AGREEMENT_ID,
  CATEGORY_CARRIER,
  CATEGORY_SHIPPING_SERVICE,
  SLA_STANDARD,
} from 'constants/categories'
import {
  getCategoryCodes,
  getCodeName,
  getBuildingLeadTimeName,
  getOrderedServiceLevels,
} from 'services/codes'

import {
  Carrier,
  ShippingService,
  ShipNode,
  VendorCategory,
  Code,
} from 'types/Seller'
import { Validation } from 'types/Validation'

export interface Option {
  id: number
  name: string
  isSelected: boolean
  validation?: Validation
}

export interface SlaOption extends Option {
  timeInTransitOptions: number[]
}

export const selectCarrier = (id: number, selected: boolean) =>
  ({
    type: 'SELECT_CARRIER',
    payload: {
      id,
      selected,
    },
  }) as const

export const updateServiceLevel = (
  id: number,
  property: string,
  value?: string | number,
) =>
  ({
    type: 'UPDATE_SERVICE_LEVEL',
    payload: {
      id,
      property,
      value,
    },
  }) as const

export const selectServiceLevel = (
  serviceLevel: ShippingService,
  selected: boolean,
) =>
  ({
    type: 'SELECT_SERVICE_LEVEL',
    payload: {
      serviceLevel,
      selected,
    },
  }) as const

export const setValidation = (validation: Validation) =>
  ({
    type: 'SET_VALIDATION',
    payload: {
      validation,
    },
  }) as const

export const setSlaValidation = (validatedOptions: SlaOption[]) =>
  ({
    type: 'SET_SLA_VALIDATION',
    payload: {
      validatedOptions,
    },
  }) as const

export const setOrderCapacity = (num: string) =>
  ({
    type: 'SET_ORDER_CAPACITY',
    payload: {
      orderCapacity: num,
    },
  }) as const

export const setOrderCapacityValidation = (message: string) =>
  ({
    type: 'SET_ORDER_CAPACITY_VALIDATION',
    payload: {
      message,
    },
  }) as const

export type CarrierInfoActions = ReturnType<
  | typeof selectCarrier
  | typeof updateServiceLevel
  | typeof selectServiceLevel
  | typeof setValidation
  | typeof setSlaValidation
  | typeof setOrderCapacity
  | typeof setOrderCapacityValidation
>

export interface CarrierInfoState {
  carriers: Carrier[]
  carrierOptions: Option[]
  serviceLevelAgreements: ShippingService[]
  shippingServiceOptions: SlaOption[]
  buildingLeadTimeId: number
  buildingLeadTimeName: string
  orderCapacity: string
  validation: Validation
}

const reducer = (
  state: CarrierInfoState,
  action: CarrierInfoActions,
): CarrierInfoState => {
  switch (action.type) {
    case 'SELECT_CARRIER': {
      const {
        payload: { selected, id },
      } = action

      return {
        ...state,
        carriers: selected
          ? state.carriers.concat({ id })
          : state.carriers.filter((carrier) => carrier.id !== id),
        carrierOptions: state.carrierOptions.map((option) => {
          if (option.id === id) {
            return {
              ...option,
              isSelected: selected,
            }
          } else {
            return option
          }
        }),
      }
    }

    case 'UPDATE_SERVICE_LEVEL': {
      const {
        payload: { id, property, value },
      } = action
      const snakeProperty = snakeCase(property)

      const newState = {
        ...state,
        serviceLevelAgreements: state.serviceLevelAgreements.map((service) => {
          if (service.id === id) {
            return {
              ...service,
              [snakeProperty]: value,
              // reset max_days_to_add if daysToAdd is cleared out
              ...(property === 'daysToAdd' && value === undefined
                ? { max_days_to_add: undefined }
                : {}),
            }
          } else {
            return service
          }
        }),
      }

      return newState
    }

    case 'SELECT_SERVICE_LEVEL': {
      const {
        payload: { serviceLevel, selected },
      } = action

      const newState = {
        ...state,
        serviceLevelAgreements: selected
          ? state.serviceLevelAgreements.concat(serviceLevel)
          : state.serviceLevelAgreements.filter(
              (service) => service.id !== serviceLevel.id,
            ),
        shippingServiceOptions: state.shippingServiceOptions.map((option) => {
          if (option.id === serviceLevel.id) {
            return {
              ...option,
              isSelected: selected,
            }
          } else {
            return option
          }
        }),
      }

      return newState
    }

    case 'SET_VALIDATION': {
      const {
        payload: { validation },
      } = action

      return {
        ...state,
        validation,
      }
    }

    case 'SET_SLA_VALIDATION': {
      const {
        payload: { validatedOptions },
      } = action

      return {
        ...state,
        shippingServiceOptions: validatedOptions,
      }
    }

    case 'SET_ORDER_CAPACITY': {
      const { payload } = action
      const { orderCapacity } = payload

      return {
        ...state,
        orderCapacity,
      }
    }

    case 'SET_ORDER_CAPACITY_VALIDATION': {
      const { payload } = action

      const validation = {
        orderCapacity: [payload.message],
      }

      return {
        ...state,
        validation,
      }
    }

    default:
      return state
  }
}

const getTimeInTransit = (serviceName: string) => {
  if (serviceName === SLA_STANDARD) {
    return [3, 4, 5, 6, 7]
  } else {
    return []
  }
}

const getInitialState = (
  shipNode: ShipNode,
  vendorCategories: VendorCategory[],
) => {
  const carriers = shipNode.carriers || []
  const carrierOptions = getCategoryCodes(
    vendorCategories,
    CATEGORY_CARRIER,
  ).map((code: Code) => {
    const codeName = getCodeName(code.code_id, [code])

    return {
      id: code.code_id,
      name: codeName,
      isSelected: !!carriers.find((carrier) => carrier.id === code.code_id),
      validation: {},
    }
  })

  const serviceLevelAgreements = shipNode.service_levels || []

  const slaOptions: SlaOption[] = getCategoryCodes(
    vendorCategories,
    CATEGORY_SHIPPING_SERVICE,
    'desc',
  ).map(({ code_id, code_name }: Code) => {
    return {
      id: code_id,
      name: code_name,
      isSelected: !!serviceLevelAgreements.find(
        (service) => service.id === code_id,
      ),
      timeInTransitOptions: getTimeInTransit(code_name),
      validation: {},
    }
  })

  const shippingServiceOptions = getOrderedServiceLevels(
    slaOptions,
  ) as SlaOption[]

  const buildingLeadTimeId =
    shipNode.building_lead_time_id || DEFAULT_SERVICE_LEVEL_AGREEMENT_ID

  const buildingLeadTimeName = getBuildingLeadTimeName(
    vendorCategories,
    buildingLeadTimeId,
  )
  const orderCapacity = shipNode?.max_orders_per_day
    ? `${shipNode.max_orders_per_day}`
    : ''

  const initialState: CarrierInfoState = {
    carriers,
    carrierOptions,
    serviceLevelAgreements,
    shippingServiceOptions,
    buildingLeadTimeId,
    buildingLeadTimeName,
    orderCapacity,
    validation: {},
  }

  return initialState
}

const useCarrierInfo = (
  shipNode: ShipNode,
  vendorCategories: VendorCategory[],
) => {
  const initialState = React.useMemo(
    () => getInitialState(shipNode, vendorCategories),
    [shipNode, vendorCategories],
  )

  return React.useReducer(reducer, initialState)
}

export default useCarrierInfo
