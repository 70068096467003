import axios from 'axios'
import apiConfig from 'config/apiConfig'

export function updateSellerStatus(
  id: string,
  status: string,
): Promise<string> {
  return axios
    .put(`${apiConfig.sms}/seller_statuses/${id}`, {
      status: status,
    })
    .then((res) => res.data.status)
}
