import { useEffect, useState, memo } from 'react'

import isEmpty from 'lodash/fp/isEmpty'

import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import ContentSpacer from 'components/common/ContentSpacer'
import ImageAttachment from 'components/common/Attachment/ImageAttachment'
import DocumentAttachment from 'components/common/Attachment/DocumentAttachment'

import {
  AttachmentFileType,
  getAttachmentType,
  getFileExtension,
  getFilenameFromId,
} from 'services/cases'

import { Case } from 'types/Case'

const StyledTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
}))

const StyledGridContainer = styled(Grid)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridTemplateRows: 'auto 1fr auto',
  gridGap: theme.spacing(3),
}))

interface Props {
  sellerId: string
  selectedCase: Case | undefined
}

// We should not use the global scope, just used here to handle the dulpicate calls as states were not managed properly.
let attachmentsCopy: string[] = []

const PartnerSubmittedDetails = ({ sellerId, selectedCase }: Props) => {
  const [imageAttachments, setImageAttachments] = useState<string[]>([])
  const [documentAttachments, setDocumentAttachments] = useState<string[]>([])

  useEffect(() => {
    if (selectedCase?.attachment_ids?.length) {
      if (attachmentsCopy?.[0] === selectedCase?.attachment_ids?.[0]) {
        return
      }

      attachmentsCopy = selectedCase?.attachment_ids
      const attachmentIds = [...new Set(selectedCase.attachment_ids)]

      setDocumentAttachments(
        attachmentIds.filter(
          (attachmentId) =>
            getAttachmentType(
              getFileExtension(getFilenameFromId(attachmentId)),
            ) === AttachmentFileType.DOCUMENT_ATTACHMENT,
        ) ?? [],
      )
      setImageAttachments(
        attachmentIds.filter(
          (attachmentId) =>
            getAttachmentType(
              getFileExtension(getFilenameFromId(attachmentId)),
            ) === AttachmentFileType.IMAGE_ATTACHMENT,
        ) ?? [],
      )
    }

    return () => {
      setImageAttachments([])
      setDocumentAttachments([])
    }
  }, [selectedCase?.attachment_ids])

  return (
    <>
      <ContentSpacer />
      <StyledTypography>Issue Described by Partner:</StyledTypography>
      <Typography>{selectedCase?.issue_description}</Typography>

      {!isEmpty(selectedCase?.attachment_ids) && (
        <>
          <ContentSpacer />
          <StyledTypography>Evidence from Partner:</StyledTypography>
          <StyledGridContainer container>
            {documentAttachments.map((attachmentId) => {
              return (
                <Grid item key={attachmentId}>
                  <DocumentAttachment
                    key={attachmentId}
                    sellerId={sellerId}
                    attachmentId={attachmentId}
                  />
                </Grid>
              )
            })}
            {imageAttachments.map((attachmentId) => {
              return (
                <Grid item key={attachmentId}>
                  <ImageAttachment
                    key={attachmentId}
                    sellerId={sellerId}
                    attachmentId={attachmentId}
                  />
                </Grid>
              )
            })}
          </StyledGridContainer>
        </>
      )}
    </>
  )
}

export default memo(PartnerSubmittedDetails)
