import styled from '@emotion/styled'
import CircularProgress from '@mui/material/CircularProgress'
import Text from 'components/common/Text'

const StyledDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
}))

const StyledText = styled(Text)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}))

export const UploadSpinner = () => {
  return (
    <>
      <StyledDiv>
        <CircularProgress />
        <StyledText>Preparing your file...</StyledText>
      </StyledDiv>

      <Text type="warning">
        If you close this tab or window your report upload will fail.
      </Text>
    </>
  )
}

export default UploadSpinner
