import styled from '@emotion/styled'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import DoneIcon from '@mui/icons-material/Done'
import CancelIcon from '@mui/icons-material/Cancel'
import IconButton from '@mui/material/IconButton'

import Text from 'components/common/Text'

import { white, filterChip } from 'config/themeConfig'

import { SelectOption } from 'types/SelectOption'

const StyledLabel = styled(Text)((props) => ({
  marginBottom: props.theme.spacing(1),
}))

const StyledSelect = styled(Select)(
  (props) => ({
    height: '36px',
    backgroundColor: white.main,
    color: props.theme.palette.grey[700],
    '& .MuiSelect-select': {
      padding: props.theme.spacing(1, 2, 1, 6),
      '&:focus': {
        backgroundColor: 'initial',
      },
    },
    '& .MuiSelect-icon': {
      right: 'initial',
      left: props.theme.spacing(2),
    },
    '& .MuiSelect-iconOpen': {
      transform: 'initial',
    },
    '&.MuiOutlinedInput-input': {
      height: '36px',
    },
  }),
  (props) => ({
    backgroundColor:
      props.value !== '' && props.value !== undefined
        ? filterChip.main
        : undefined,
    color:
      props.value !== '' && props.value !== undefined
        ? props.theme.palette.grey[900]
        : undefined,
  }),
)

export interface Props {
  label?: string
  placeholder: string
  value: string | undefined | string[]
  searchParam: string
  data: SelectOption[]
  selectionTitle?: string
  onChange: (params: Dictionary<string | string[] | undefined>) => void
  disabled?: boolean
}

const getNameFromValue = (value: string, data: SelectOption[]) => {
  const item = data.find((i) => i.value === value)

  if (!item) {
    return ''
  }

  return item.name
}

export const SelectFilter = ({
  label,
  placeholder,
  value,
  searchParam,
  data,
  selectionTitle,
  onChange,
  disabled,
}: Props) => {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    if (typeof event.target.value === 'string') {
      const changeValue =
        event.target.value !== '' ? event.target.value : undefined
      onChange({ [searchParam]: changeValue })
    }
  }

  const handleClear = () => {
    onChange({ [searchParam]: undefined })
  }

  const iconComponent = value ? DoneIcon : ArrowDropDownIcon

  const endAdornment = value ? (
    <IconButton
      onClick={handleClear}
      data-testid="remove-filter"
      aria-label={`Remove ${placeholder} Filter`}
      size="large"
    >
      <CancelIcon color="primary" />
    </IconButton>
  ) : undefined

  return (
    <>
      {label && <StyledLabel type="bold">{label}</StyledLabel>}
      <FormControl fullWidth>
        <StyledSelect
          disabled={disabled}
          SelectDisplayProps={{ style: { paddingRight: 16 } }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          }}
          displayEmpty
          variant="outlined"
          IconComponent={iconComponent}
          onChange={handleChange}
          value={value || ''}
          inputProps={{
            'aria-label':
              value !== undefined
                ? `${placeholder}: ${value}`
                : `${placeholder}: none selected`,
          }}
          data-testid={placeholder}
          endAdornment={endAdornment}
          renderValue={(selected: any) => {
            if (selected.length === 0) {
              return placeholder
            }

            const adornment = selectionTitle ?? ``
            const name = getNameFromValue(selected, data)

            return `${adornment}${name}`
          }}
        >
          {data.map(({ name, value }: SelectOption) => {
            return (
              <MenuItem
                disableRipple
                key={name}
                value={value}
                data-testid={value}
              >
                {name}
              </MenuItem>
            )
          })}
        </StyledSelect>
      </FormControl>
    </>
  )
}

export default SelectFilter
