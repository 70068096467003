// ECMA2020 rewrite of Jeff Mesnil's library found at https://github.com/jmesnil/stomp-websocket
// (c) 2010 Jeff Mesnil -- http://jmesnil.net/
// Copyright (C) FuseSource, Inc. --  http://fusesource.com

export interface StompFrame {
  command: string
  headers: Dictionary<string>
  body: string | any
}

export interface StompConfig {
  defaultContentType?: string
  errorCallback?: (frame: StompFrame) => void
  receiptCallback?: (frame: StompFrame) => void
}

enum Commands {
  ACK = 'ACK',
  ABORT = 'ABORT',
  BEGIN = 'BEGIN',
  COMMIT = 'COMMIT',
  CONNECT = 'CONNECT',
  CONNECTED = 'CONNECTED',
  DISCONNECT = 'DISCONNECT',
  ERROR = 'ERROR',
  MESSAGE = 'MESSAGE',
  NACK = 'NACK',
  RECEIPT = 'RECEIPT',
  SEND = 'SEND',
  SUBSCRIBE = 'SUBSCRIBE',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
}

const Stomp = ({
  defaultContentType = 'text/plain',
  errorCallback,
  receiptCallback,
}: StompConfig = {}) => {
  const createFrame = (
    command: string,
    headers: Dictionary<string>,
    body: string,
  ) => {
    const headerStrings = Object.keys(headers).map(
      (header) => `${header}:${headers[header]}`,
    )
    return [command, ...headerStrings, '', body].join('\n')
  }

  const unpackMessage = (message: string) => {
    const [head, rawBody] = message.split(/\n\n/)
    const [command, ...headerStrings] = head.split('\n')

    const headers: Dictionary<string> = {}
    headerStrings.forEach((header) => {
      const [key, value] = header.split(':')
      if (!headers[key.trim()] && value) {
        headers[key.trim()] = value.trim()
      }
    })

    let readUntil = rawBody ? rawBody.indexOf('\0') : 0
    if (
      headers['content-length'] &&
      parseInt(headers['content-length']) < readUntil
    ) {
      readUntil = parseInt(headers['content-length'])
    }
    let body = rawBody ? rawBody.substr(0, readUntil) : ''

    return { command, headers, body } as StompFrame
  }

  const client = (url: string) => {
    let ws: WebSocket
    let subscriptions = {} as Dictionary<(frame: StompFrame) => void>
    let counter: number = 0
    let onConnect: ((frame: StompFrame) => void) | undefined
    let onError: ((frame: StompFrame) => void) | undefined = errorCallback
    let onReceipt: ((frame: StompFrame) => void) | undefined = receiptCallback
    let heartbeatId: number

    const handleMessage = ({ data }: { data: string | ArrayBuffer }) => {
      let message: string
      if (data instanceof ArrayBuffer) {
        const view = new Uint8Array(data)
        message = view.reduce(
          (out, char) => `${out}${String.fromCharCode(char)}`,
          '',
        )
      } else {
        message = data
      }

      const frame = unpackMessage(message)
      if (frame.headers['content-type'] === 'application/json') {
        frame.body = JSON.parse(frame.body as string)
      }
      if (frame.command === Commands.CONNECTED && onConnect) {
        onConnect(frame)
      } else if (frame.command === Commands.MESSAGE) {
        const subscriptionId = frame.headers?.subscription
        if (subscriptionId && subscriptions[subscriptionId]) {
          subscriptions[subscriptionId](frame)
        }
      } else if (frame.command === Commands.RECEIPT && onReceipt) {
        onReceipt(frame)
      } else if (frame.command === Commands.ERROR && onError) {
        onError(frame)
      }
    }

    const transmit = (
      command: string,
      headers: Dictionary<string> = {},
      body: string = '',
    ) => {
      const out = createFrame(command, headers, body) + '\0'
      ws.send(out)
    }

    const abort = (transaction: string, headers: Dictionary<string> = {}) => {
      headers.transaction = transaction
      transmit(Commands.ABORT, headers)
    }

    const ack = (message_id: string, headers: Dictionary<string> = {}) => {
      headers.id = message_id
      transmit(Commands.ACK, headers)
    }

    const begin = (transaction: string, headers: Dictionary<string> = {}) => {
      headers.transaction = transaction
      transmit(Commands.BEGIN, headers)
    }

    const commit = (transaction: string, headers: Dictionary<string> = {}) => {
      headers.transaction = transaction
      transmit(Commands.COMMIT, headers)
    }

    const connect = (
      headers: Dictionary<string>,
      connectCallback?: (frame: any) => void,
      connectErrorCallback?: (frame: any) => void,
    ) => {
      ws = new WebSocket(url)
      ws.binaryType = 'arraybuffer'
      ws.onmessage = handleMessage

      if (connectCallback) {
        onConnect = connectCallback
      }

      heartbeatId = window.setInterval(() => {
        ws.send('\n')
      }, 10000)

      ws.onclose = () => {
        const message = 'Whoops! Lost connection to ' + url
        if (connectErrorCallback) {
          connectErrorCallback(message)
        }
      }

      ws.onopen = () => {
        transmit(Commands.CONNECT, headers)
      }
    }

    const disconnect = (disconnectCallback?: () => void) => {
      transmit(Commands.DISCONNECT)
      ws.close()
      window.clearInterval(heartbeatId)
      if (disconnectCallback) {
        disconnectCallback()
      }
    }

    const nack = (message_id: string, headers: Dictionary<string> = {}) => {
      headers.id = message_id
      transmit(Commands.NACK, headers)
    }

    const send = (
      destination: string,
      body: string = '',
      headers: Dictionary<string> = {},
    ) => {
      headers.destination = destination
      if (!headers['content-length']) {
        headers['content-length'] = body.length.toString()
      }
      if (!headers['content-type']) {
        headers['content-type'] = defaultContentType
      }
      transmit(Commands.SEND, headers, body)
    }

    const subscribe = (
      destination: string,
      callback: (frame: StompFrame) => void,
      headers: Dictionary<string> = {},
      ackMode: 'auto' | 'client' | 'client-individual' = 'auto',
    ) => {
      const id = counter.toString()
      counter = counter + 1

      if (ackMode !== 'auto') {
        headers.ack = ackMode
      }

      subscriptions[id] = callback
      transmit(Commands.SUBSCRIBE, {
        ...headers,
        destination,
        id,
      })

      return id
    }

    const unsubscribe = (id: string, headers: Dictionary<string> = {}) => {
      delete subscriptions[id]
      headers.id = id
      transmit(Commands.UNSUBSCRIBE, headers)
    }

    return {
      abort,
      ack,
      begin,
      commit,
      connect,
      disconnect,
      nack,
      send,
      subscribe,
      unsubscribe,
    }
  }

  return {
    client,
  }
}

export default Stomp
