import React from 'react'
import { useSelector } from 'react-redux'

import Typography from '@mui/material/Typography'

import { DialogEnum } from 'components/common/Dialog'
import EditButton from 'components/common/EditButton'
import useTable from 'components/common/EnhancedTable/useTable'
import TableSpacer from 'components/common/TableSpacer'
import TitleBar from 'components/common/TitleBar'

import { isOneOfUserRoles } from 'services/authorization'
import { Direction } from 'services/pageableHelper'
import { USER_ROLE_ADMIN, USER_ROLE_OPS } from 'services/roles'
import { getShippingMethods } from 'services/shippingMethod'

import { getMemberOf } from 'store/selectors'

import { CollectionResponse } from 'types/Response'
import { ShippingMethod } from 'types/ShippingMethods'

import ShippingMethodTable from './ShippingMethodsTable'

const canShowRoles = [USER_ROLE_ADMIN, USER_ROLE_OPS]

export const ShippingMethodsContent = () => {
  const memberOf = useSelector(getMemberOf)
  const [isPending, setIsPending] = React.useState(false)
  const [shippingMethods, setShippingMethods] = React.useState<
    ShippingMethod[]
  >([])
  const [totalShippingMethods, setTotalShippingMethods] = React.useState(0)

  const { table } = useTable({
    direction: Direction.ASC,
    orderBy: 'shipping_method',
    page: 0,
    perPage: 100,
  })

  React.useEffect(() => {
    setIsPending(true)

    getShippingMethods(table.state).then(
      (response: CollectionResponse<ShippingMethod>) => {
        setIsPending(false)
        setShippingMethods(response.data)
        setTotalShippingMethods(response.total)
      },
    )
  }, [table.state])

  const hide = !isOneOfUserRoles(memberOf, canShowRoles)

  return (
    <>
      {
        <TitleBar
          title="Shipping Methods"
          actionButtons={[
            <EditButton
              key="edit-shipping-methods"
              aria-label="edit-shipping-methods"
              dialogComponent={DialogEnum.EDIT_SHIPPING_METHOD}
              hide={hide}
            />,
          ]}
        />
      }
      <Typography>
        Shipping methods are the acceptable way a Target Plus package can be
        delivered to our guests. Partners are required to use one of the
        shipping methods below.
      </Typography>
      <TableSpacer>
        <ShippingMethodTable
          table={table}
          data={shippingMethods}
          isPending={isPending}
          total={totalShippingMethods}
        />
      </TableSpacer>
    </>
  )
}

export default ShippingMethodsContent
