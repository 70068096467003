import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import BaseReferralFeeTable from './BaseReferralFeeTable'
import { DialogEnum } from 'components/common/Dialog'
import HeaderTitle from 'components/common/HeaderTitle'
import useTable from 'components/common/EnhancedTable/useTable'
import TitleBar from 'components/common/TitleBar'
import EditButton from 'components/common/EditButton'

import { getReferralBasePercentages } from 'services/referralBasePercentages'
import { Direction } from 'services/pageableHelper'
import { isOneOfUserRoles } from 'services/authorization'
import { USER_ROLE_ADMIN, USER_ROLE_OPS } from 'services/roles'

import { openDialog } from 'store/dialog/actionCreator'
import { getMemberOf } from 'store/selectors'

import { ReferralBasePercentage } from 'types/BaseReferralFee'
import { CollectionResponse } from 'types/Response'

const canEditRoles = [USER_ROLE_ADMIN, USER_ROLE_OPS]

export const BaseReferralFeesPage = () => {
  const memberOf = useSelector(getMemberOf)
  const reduxDispatch = useDispatch()

  const [pending, setPending] = React.useState(false)
  const [referralFees, setReferralFees] = React.useState<
    ReferralBasePercentage[]
  >([])
  const [total, setTotal] = React.useState(0)
  const [reloadToggle, setReloadToggle] = React.useState(false)

  const { table } = useTable({
    direction: Direction.DESC,
    orderBy: 'start_date',
    page: 0,
    perPage: 20,
  })

  React.useEffect(() => {
    setPending(true)
    getReferralBasePercentages(table.state, true).then(
      (res: CollectionResponse<ReferralBasePercentage>) => {
        setReferralFees(res.data)
        setTotal(res.total)
        setPending(false)
      },
    )
  }, [table.state, reloadToggle])

  const handleViewHistory =
    (referralPercentage: ReferralBasePercentage) => () => {
      const { sub_type_id, subtypeName } = referralPercentage
      reduxDispatch(
        openDialog({
          dialogEnum: DialogEnum.BASE_REFERRAL_FEE_HISTORY,
          componentProps: {
            subtypeId: sub_type_id,
            subtypeName,
          },
        }),
      )
    }

  const dialogClosedHandler = () => {
    setReloadToggle(!reloadToggle)
  }

  const canEdit = isOneOfUserRoles(memberOf, canEditRoles)

  return (
    <div>
      <HeaderTitle title="Base Referral Fees" />
      <TitleBar
        title="Base Referral Fees"
        actionButtons={[
          <EditButton
            key="edit-base-referral-fees"
            aria-label="edit-base-referral-fees"
            dialogComponent={DialogEnum.EDIT_REFERRAL_FEES}
            componentProps={{ isBaseReferralFee: true }}
            closeCallback={dialogClosedHandler}
            hide={!canEdit}
          />,
        ]}
      />
      <Grid container>
        <Grid item xs={12}>
          <Typography>
            Subtype base referral fees will act as the default referral fee for
            items within each subtype. Referral fees can be overridden for
            specific item types on a per-partner basis in partner's Referral
            Fees.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <BaseReferralFeeTable
            table={table}
            data={referralFees}
            total={total}
            isPending={pending}
            viewHistory={handleViewHistory}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default BaseReferralFeesPage
