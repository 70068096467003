import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'

import * as yup from 'yup'
import { Form, FormikProps, withFormik } from 'formik'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import InputField from 'components/common/form/InputField'
import RadioGroupField from 'components/common/form/RadioGroupField'
import ItemTypeaheadField from 'components/common/form/ItemTypeaheadField'
import Text from 'components/common/Text'
import { StaticTypeahead, TaxonomyTypeahead } from 'components/common/Typeahead'

import Attribute, { ITEM_TYPE } from 'types/Attribute'
import { MarketplaceProduct } from 'types/Item'

import theme from 'config/themeConfig'
import styled from '@emotion/styled'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import { Grid, Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'

import {
  getTaxonomyAssignmentAttributes,
  getTaxonomyAssignmentByItemTypeIdAndAttributeId,
} from 'services/itemTaxonomies'
import {
  buildItemTaxonomyValueRequestData,
  createTaxonomyAttributeAndValue,
} from 'services/securityGateway'
import { ValidationSchema, maxCharacterCount } from 'services/validation'

import { getAttributesAndValuesPageAttributesQueryKeys } from '../queries'

const dialogTitle = 'Request a new attribute value'
const notRequestableTitle = (
  <div>
    <SentimentVeryDissatisfiedIcon
      sx={{ verticalAlign: 'bottom' }}
      color="error"
      fontSize="large"
    />{' '}
    Value cannot be requested
  </div>
)

const successTitle = (
  <div>
    <CheckCircleIcon
      sx={{ verticalAlign: 'bottom' }}
      color="success"
      fontSize="large"
    />
    SUCCESS! REQUEST SUBMITTED
  </div>
)

const StyledTcinPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(2),
}))

const StyledTitle = styled('span')({
  fontWeight: 'bold',
})

interface FormValues {
  selectedItemType: string
  selectedAttribute: string
  newAttribute: string
  reason: string
  hasTcin: RadioOptions | ''
  tcin: Nullable<MarketplaceProduct>
  exampleTitle: string
  exampleDescription: string
}

export enum RadioOptions {
  YES = 'Yes',
  NO = 'No',
}

export interface ComponentProps extends FormValues {
  isOpen: boolean
  sellerId: string
  status: { success?: boolean } | undefined
  userEmail: string
  closeDialog: () => void
  handleSubmit: () => void
}

type Props = ComponentProps & FormikProps<FormValues>

export const validationSchema: ValidationSchema = yup.object().shape({
  selectedItemType: yup.string().label('Item Type').required(),
  selectedAttribute: yup.string().label('Merch Type Attribute').required(),
  newAttribute: yup
    .string()
    .label('New Value Name')
    .test({
      name: 'newAttribute',
      test: maxCharacterCount('newAttribute', 200),
    })
    .required(),
  reason: yup
    .string()
    .label('Reason for Request')
    .test({
      name: 'reason',
      test: maxCharacterCount('reason', 1000),
    })
    .required(),
  hasTcin: yup.string().required(),
  tcin: yup.object().when(['hasTcin'], {
    is: (hasTcin: string) => hasTcin === RadioOptions.YES,
    then: yup.object().label('TCIN Requiring New Value').required(),
    otherwise: yup.object().nullable(),
  }),
  exampleTitle: yup.string().when(['hasTcin'], {
    is: (hasTcin: string) => hasTcin === RadioOptions.NO,
    then: yup
      .string()
      .label('Example Product Title for Items(s) with New Value')
      .test({
        name: 'exampleTitle',
        test: maxCharacterCount('exampleTitle', 300),
      })
      .required(),
    otherwise: yup.string(),
  }),
  exampleDescription: yup.string().when(['hasTcin'], {
    is: (hasTcin: string) => hasTcin === RadioOptions.NO,
    then: yup
      .string()
      .label('Example Product Description')
      .test({
        name: 'exampleDescription',
        test: maxCharacterCount('exampleDescription', 1000),
      })
      .required(),
    otherwise: yup.string(),
  }),
})

const RequestAttributeValuesForm = ({
  isOpen,
  sellerId,
  setFieldValue,
  isSubmitting,
  isValid,
  dirty,
  handleSubmit,
  values,
  status,
  userEmail,
}: Props) => {
  const totalPages = 2

  const [page, setPage] = useState<number>(1)
  const [itemType, setItemType] = useState<Attribute>()
  const [attribute, setAttribute] = useState<Nullable<Attribute>>(null)

  const { data = [], isLoading } = useQuery(
    getAttributesAndValuesPageAttributesQueryKeys(itemType),
    () => {
      if (itemType?.id) {
        return getTaxonomyAssignmentAttributes(itemType.id)
      }
      return []
    },
  )

  const handleItemTypeChange = (value: Nullable<Attribute>) => {
    if (!value) return
    setItemType(value)
    setFieldValue('selectedItemType', value.id)
  }

  const handleAttributeChange = (value: Attribute) => {
    setAttribute(value)
    setFieldValue('selectedAttribute', value.id)
  }

  const handleItemTypeClear = () => {
    setItemType(undefined)
    setAttribute(null)
  }

  const onPreviousPage = () => {
    if (page > 1) {
      setPage((prev) => prev - 1)
    }
  }

  const onNextPage = async () => {
    if (itemType && attribute) {
      const assignments = await getTaxonomyAssignmentByItemTypeIdAndAttributeId(
        itemType.id,
        attribute.id,
      )
      if (assignments?.[0].allow_value_requests) {
        setPage((prev) => prev + 1)
      } else {
        setPage(0)
      }
    }
  }

  const getTitle = () => {
    if (status?.success) {
      return successTitle
    } else {
      return page === 0 ? notRequestableTitle : dialogTitle
    }
  }

  return (
    <DialogContainer
      title={getTitle()}
      isOpen={isOpen}
      isPending={false}
      isSubmitDisabled={
        page === 1 ? !(itemType && attribute) : !isValid || !dirty
      }
      submitButtonText={page < totalPages ? 'Next' : 'Submit'}
      onSubmit={
        page === 0 ? undefined : page < totalPages ? onNextPage : handleSubmit
      }
      previousButtonText="Back to Previous"
      onPrevious={page > 1 ? onPreviousPage : undefined}
      closeButtonText={page === 0 ? 'Close' : 'Cancel'}
      page={page}
      totalPages={totalPages}
      fullWidth={!status?.success}
      maxWidth={page === 2 ? 'md' : 'sm'}
      disableScroll={page === 2 ? false : true}
      autoClose={status?.success}
      hideActions={status?.success}
    >
      {!status?.success && (
        <Form>
          {page === 0 && (
            <Grid container>
              <Grid item xs={12}>
                <Text>
                  We are not accepting new values for {attribute?.name} under
                  item type {itemType?.name}. Please use one of the existing
                  values.
                </Text>
              </Grid>
            </Grid>
          )}

          {page === 1 && (
            <Grid container>
              <Grid item xs={12}>
                <TaxonomyTypeahead
                  label="Item Type *"
                  searchType={ITEM_TYPE}
                  placeholder="Search Item Type"
                  onChange={handleItemTypeChange}
                  onClear={handleItemTypeClear}
                  data-testid="item-type-typeahead"
                  value={itemType ?? null}
                />

                <div style={{ marginTop: theme.spacing(4) }}>
                  <StaticTypeahead<Attribute>
                    label="Merch Type Attribute *"
                    placeholder="Search Merch Type Attribute"
                    isDisabled={!itemType || isLoading}
                    data={data}
                    onChange={handleAttributeChange}
                    value={attribute}
                    filterBy="CONTAINS"
                    data-testid="merch-type-attribute-typeahead"
                  />
                </div>
              </Grid>
            </Grid>
          )}

          {page === 2 && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <Typography>
                    <StyledTitle>Requestor</StyledTitle>: {userEmail}
                  </Typography>
                  <Typography>
                    <StyledTitle>Item Type</StyledTitle>: {itemType?.name}
                  </Typography>
                  <Typography>
                    <StyledTitle>Attribute</StyledTitle>: {attribute?.name}
                  </Typography>
                </Grid>

                <Grid item xs={12} sx={{ mt: 4, mb: 4 }}>
                  <InputField
                    required
                    name="newAttribute"
                    label="New Value Name"
                    maxCharacters={200}
                    enableOnChangeValidation
                    disabled={isSubmitting}
                  />
                </Grid>

                <Grid item xs={12} sx={{ mt: 4 }}>
                  <InputField
                    required
                    multiline
                    rows="6"
                    name="reason"
                    label="Reason for Request (describe items with new value)"
                    maxCharacters={1000}
                    enableOnChangeValidation
                    disabled={isSubmitting}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <StyledTcinPaper elevation={0}>
                  <RadioGroupField
                    sx={{ mb: 4 }}
                    required
                    row
                    name="hasTcin"
                    options={[
                      { label: RadioOptions.YES, value: RadioOptions.YES },
                      { label: RadioOptions.NO, value: RadioOptions.NO },
                    ]}
                    label="Do You Have TCINs Submitted That Need the New Value?"
                  />
                  {values.hasTcin === '' && (
                    <Grid item xs={12} sx={{ mb: 29.5 }}></Grid>
                  )}
                  {values.hasTcin === RadioOptions.YES && (
                    <Grid item xs={12} sx={{ mb: 22.4 }}>
                      <ItemTypeaheadField
                        required
                        isDisabled={isSubmitting}
                        name="tcin"
                        label="TCIN Requiring New Value"
                        itemTypeId={itemType?.id}
                        sellerId={sellerId}
                      />
                    </Grid>
                  )}
                  {values.hasTcin === RadioOptions.NO && (
                    <Grid item xs={12} sx={{ mb: 1 }}>
                      <InputField
                        sx={{ mb: 4 }}
                        required
                        name="exampleTitle"
                        label="Example Product Title for Item(s) with New Value"
                        maxCharacters={300}
                        enableOnChangeValidation
                        disabled={isSubmitting}
                      />
                      <InputField
                        required
                        multiline
                        rows="4"
                        name="exampleDescription"
                        label="Example Product Description"
                        maxCharacters={1000}
                        enableOnChangeValidation
                        disabled={isSubmitting}
                      />
                    </Grid>
                  )}
                </StyledTcinPaper>
              </Grid>
            </Grid>
          )}
        </Form>
      )}
    </DialogContainer>
  )
}

export const RequestAttributeValues = withFormik<ComponentProps, FormValues>({
  mapPropsToValues: () => ({
    selectedItemType: '',
    selectedAttribute: '',
    newAttribute: '',
    reason: '',
    hasTcin: '',
    tcin: null,
    exampleTitle: '',
    exampleDescription: '',
  }),
  handleSubmit: async (values, { props, ...actions }) => {
    const checkTcin = values.hasTcin === RadioOptions.YES

    try {
      const data = await buildItemTaxonomyValueRequestData({
        sellerId: props.sellerId,
        marketplaceProduct: values.tcin,
        title: values.exampleTitle,
        name: values.newAttribute,
        description: values.exampleDescription,
        reason: values.reason,
        itemType: values.selectedItemType,
        attribute: values.selectedAttribute,
        checkTcin,
        userEmail: props.userEmail,
      })

      await createTaxonomyAttributeAndValue(props.sellerId, data)

      actions.setStatus({ success: true })
    } catch (e) {
      console.error(`Error creating new attribute and value request: ${e}`)
    }
  },
  enableReinitialize: true,
  validationSchema,
})(RequestAttributeValuesForm)

export default RequestAttributeValues
