import React from 'react'

import EnhancedTableCell from './EnhancedTableCell'

import { VerticalAlign } from './index'
type Props = {
  item: object
  fieldListContext: any
  formatCellAsync: (rowItem: object, context?: any) => Promise<React.ReactNode>
  verticalAlign?: VerticalAlign
  cellPadding?: string
  variant?: 'head' | 'body' | 'footer'
}

export const EnhancedTableCellAsync: React.FC<Props> = ({
  item,
  fieldListContext,
  formatCellAsync,
  verticalAlign,
  cellPadding,
  variant,
}) => {
  const [value, setValue] = React.useState<React.ReactNode>('...')

  React.useEffect(() => {
    let mounted = true

    formatCellAsync(item, fieldListContext).then((res) => {
      if (mounted) {
        setValue(res)
      }
    })

    return () => {
      mounted = false
    }
  }, [fieldListContext, formatCellAsync, item])

  return (
    <EnhancedTableCell
      verticalAlign={verticalAlign}
      cellPadding={cellPadding}
      variant={variant}
    >
      {value}
    </EnhancedTableCell>
  )
}

export default EnhancedTableCellAsync
