import React, { PropsWithChildren } from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Warning'

import { primary, success, error } from 'config/themeConfig'
import styled from '@emotion/styled'

const StyledWrapper = styled(Grid)(({ theme }) => ({
  border: '2px solid',
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(3),
})) as typeof Grid

const StyledContainer = styled(Grid)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  alignSelf: 'center',
})) as typeof Grid

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(3),
})) as typeof Typography

function getBorderColor(type: String): {} {
  return type === 'alert'
    ? {
        borderColor: error.main,
      }
    : type === 'information'
    ? {
        borderColor: primary.main,
      }
    : {
        borderColor: success.main,
      }
}

function getIconColor(type: String): {} {
  return type === 'alert'
    ? {
        color: error.main,
      }
    : type === 'information'
    ? {
        color: primary.main,
      }
    : {
        color: success.main,
      }
}

interface Props {
  title?: string
  type: 'alert' | 'information' | 'success'
}

const icons = {
  alert: WarningIcon,
  information: InfoIcon,
  success: CheckCircleIcon,
}

export const Message: React.FC<PropsWithChildren<Props>> = ({
  children,
  title,
  type,
}) => {
  const Icon = icons[type]

  return (
    <StyledWrapper container style={getBorderColor(type)}>
      <Grid item sx={{ display: 'flex' }}>
        <Icon fontSize="large" style={getIconColor(type)} />
        <StyledContainer item>
          {title && <StyledTypography variant="h6">{title}</StyledTypography>}
          {children}
        </StyledContainer>
      </Grid>
    </StyledWrapper>
  )
}

export default Message
