import getOr from 'lodash/fp/getOr'
import keyBy from 'lodash/fp/keyBy'
import mapValues from 'lodash/fp/mapValues'

import { MarketplaceProduct } from 'types/Item'

export const setPending = (pending: boolean) =>
  ({
    type: 'SET_PENDING',
    payload: {
      pending,
    },
  }) as const

export const setParent = (parent: MarketplaceProduct | undefined) =>
  ({
    type: 'SET_PARENT',
    payload: {
      parent,
    },
  }) as const

export const setChildren = (children: MarketplaceProduct[], total: number) =>
  ({
    type: 'SET_CHILDREN',
    payload: {
      children,
      total,
    },
  }) as const

export type RelationshipActions = ReturnType<
  typeof setPending | typeof setParent | typeof setChildren
>

export interface RelationshipsState {
  pending: boolean
  parent: MarketplaceProduct | undefined
  variations: string[]
  children: MarketplaceProduct[]
  total: number
}

export const initialState = {
  pending: false,
  parent: undefined,
  variations: [],
  children: [],
  total: 0,
}

const reducer = (state: RelationshipsState, action: RelationshipActions) => {
  switch (action.type) {
    case 'SET_PENDING': {
      const { payload } = action
      return {
        ...state,
        pending: payload.pending,
      }
    }
    case 'SET_PARENT': {
      const { payload } = action
      const { parent } = payload
      const theme = getOr('', 'variation_theme', parent)

      return {
        ...state,
        parent,
        variations: theme.split(/\s?&\s?|\s?and\s?/),
        pending: false,
      }
    }
    case 'SET_CHILDREN': {
      const { payload } = action
      const { children } = payload

      const variationChildren = children.map((child: MarketplaceProduct) => {
        return {
          ...child,
          ...mapValues('value', keyBy('refinement', child.variations)),
        }
      })

      return {
        ...state,
        children: variationChildren,
        total: payload.total,
        pending: false,
      }
    }

    default:
      return state
  }
}

export default reducer
