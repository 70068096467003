import filter from 'lodash/fp/filter'
import find from 'lodash/fp/find'
import get from 'lodash/fp/get'
import getOr from 'lodash/fp/getOr'
import toNumber from 'lodash/fp/toNumber'
import sortBy from 'lodash/fp/sortBy'
import reduce from 'lodash/fp/reduce'
import {
  EnrichedProductField,
  EnrichedPublishedProductField,
  EnrichedValue,
  MarketplaceProduct,
  ProductField,
  RelationshipType,
} from 'types/Item'
import { ITEM_FIELDS } from 'constants/itemFields'

import { getTaxonomyAssignments } from 'services/itemTaxonomies'
import { getHeliosItem } from './helios'

import { TaxonomyAssignment } from 'types/TaxonomyAssignment'
import { getBrand } from 'services/brands'
import { DataListItem } from 'components/common/DataList'
import { HeliosItem, HeliosValidationError } from 'types/HeliosItem'
import { getItemHistories } from './items'

const PRIMARY_IMAGE_FIELD = 'digital_assets.images.primary_image_url'

export const enrichProductFields = async (
  product: MarketplaceProduct,
  productFields: ProductField[],
  heliosItem: HeliosItem,
) => {
  const suppressedFields = [
    'package_dimensions.measurements.depth',
    'package_dimensions.measurements.height',
    'package_dimensions.measurements.width',
    'package_dimensions.measurements.weight',
    'compliance.tax_category.tax_code',
    'barcode.value',
    'compliance.import_designation',
    'compliance.is_environmentally_sensitive',
    'compliance.is_electronic',
    'compliance.has_battery',
    'fulfillment.is_add_on',
    'shipping_exclusion',
    'list_price',
    'map_price',
    'offer_price',
    'version_num',
    ...(product.relationship_type !== RelationshipType.VAP
      ? ['descriptions.parent_title']
      : []),
  ]

  const packageDepthField = productFields.find(
    (field) => field.name === 'package_dimensions.measurements.depth',
  )
  const packageHeightField = productFields.find(
    (field) => field.name === 'package_dimensions.measurements.height',
  )
  const packageWidthField = productFields.find(
    (field) => field.name === 'package_dimensions.measurements.width',
  )
  const packageWeightField = productFields.find(
    (field) => field.name === 'package_dimensions.measurements.weight',
  )

  const fields = filter(
    (field) => suppressedFields.indexOf(field.name.toLowerCase()) === -1,
    productFields,
  )

  if (product.relationship_type !== 'VAP') {
    fields.push({
      name: 'package_dimensions.measurements',
      value: `${packageDepthField?.value ?? 'NONE PROVIDED'}"D, ${
        packageHeightField?.value ?? 'NONE PROVIDED'
      }"H x ${packageWidthField?.value ?? 'NONE PROVIDED'}"W, weight: ${
        packageWeightField?.value ?? 'NONE PROVIDED'
      } lbs`,
    })
  }

  if (!fields || !fields.length) return []

  const enrichedFields = await hydrateProductFields(product, fields, heliosItem)
  return sortBy<EnrichedProductField>('order', enrichedFields)
}

const hydrateProductFields = async (
  product: MarketplaceProduct,
  fields: ProductField[] = [],
  heliosItem: HeliosItem,
): Promise<EnrichedProductField[]> => {
  if (!fields || !fields.length) return []

  const { merchandise_type_attributes, product_classification } = heliosItem
  const imageMap = buildImageMap(heliosItem, fields)
  let displayedAttributeValueIndex = 0
  let previousMtaId: string | undefined = undefined

  const assignments = await getTaxonomyAssignments(
    {
      itemTypeId: product.item_type_id.toString(),
    },
    { page: 0, perPage: 1000 },
  )

  const productFields = fields.map(async (field) => {
    const fieldData = ITEM_FIELDS[field.name]

    let displayName: string | DataListItem = getOr(
      field.name,
      'displayName',
      fieldData,
    )
    let displayValue: EnrichedValue = { raw: getOr('-', 'value', field) }
    let isMerchandiseTypeAttribute = false

    if (field.name === 'item_type.name') {
      displayValue.enriched = product_classification.item_type.name
    } else if (field.name === 'brands.name') {
      const brandId = get('brands[0].id', heliosItem)
      let brandValue = get('brands[0].name', heliosItem)
      let brandDescription
      if (brandId) {
        const brand = await getBrand(brandId)
        brandDescription = get('brandDescription', brand)
      }
      displayValue = {
        raw: field.value,
        enriched: brandValue,
        helperText: brandDescription,
      }
    } else if (getOr(false, 'isImage', fieldData)) {
      displayValue = { raw: field.value, enriched: imageMap[field.name] }
    } else {
      if (!isNaN(toNumber(field.name)) && merchandise_type_attributes) {
        const mta = merchandise_type_attributes.find(
          (attribute) => attribute.id === field.name,
        )
        if (mta) {
          isMerchandiseTypeAttribute = true
          displayName = { title: 'MTA:', value: mta.name }

          if (mta.id !== previousMtaId) {
            displayedAttributeValueIndex = 0
          }

          displayValue = {
            raw: field.value,
            enriched: `${mta.values[displayedAttributeValueIndex]
              ?.name} ${getOr('', 'unit_of_measure', mta)}`,
          }
          displayedAttributeValueIndex++
          previousMtaId = mta.id
        }
      }
    }

    return {
      ...field,
      isChecked: false,
      displayName,
      displayValue,
      isMerchandiseTypeAttribute,
      isImage: getOr(false, 'isImage', fieldData),
      isRequired: checkRequired(assignments, field.name),
      order: getOr(900, 'order', fieldData),
    }
  })
  return await Promise.all(productFields)
}

export const getEnrichedProductFields = async (
  tcin: string,
  version: number,
  sellerId: string,
  productId: string,
) => {
  const productHistory = await getItemHistories(sellerId, productId)

  const product = productHistory.find(
    (item) => item.product_statuses[0].version === version,
  )
  if (!product) {
    return []
  }
  const heliosItem = await getHeliosItem({
    tcin,
    version,
  })
  const fields = await enrichProductWithHeliosData(product.fields!, heliosItem)
  return fields
}

export const enrichProductWithHeliosData = async (
  productFields: ProductField[],
  heliosItem: HeliosItem,
) => {
  const enrichedFields = await hydrateProductFieldsWithHeliosData(
    productFields,
    heliosItem,
  )

  return sortBy<EnrichedPublishedProductField>('order', enrichedFields)
}

export const hydrateProductFieldsWithHeliosData = async (
  productFields: ProductField[] = [],
  heliosItem: HeliosItem,
): Promise<EnrichedPublishedProductField[]> => {
  if (!productFields || !productFields.length) return []

  const unassociatedMtaFields = mapMissingMtasToProductFields(
    productFields,
    heliosItem,
  )

  const allFields = [...productFields, ...unassociatedMtaFields]

  const fields = buildProductFieldsWithHeliosData(allFields, heliosItem)

  return await Promise.all(fields)
}

const mapMissingMtasToProductFields = (
  productFields: ProductField[] = [],
  heliosItem: HeliosItem,
): ProductField[] => {
  return heliosItem.merchandise_type_attributes
    .filter((mta) => {
      const field = productFields.find((f) => f.name === mta.id)
      return !field
    })
    .map((f) => ({
      name: f.id,
      value: '', // There is no associated sms product field for this mta.
    }))
}

export const buildProductFieldsWithHeliosData = (
  productFields: ProductField[] = [],
  heliosItem: HeliosItem,
) => {
  const imageMap = buildImageMap(heliosItem, productFields)

  return productFields.map(async (field) => {
    const fieldData = ITEM_FIELDS[field.name]

    const item: EnrichedPublishedProductField = {
      ...field,
      displayName: getOr(field.name, 'displayName', fieldData),
      displayValue: getOr(undefined, 'value', field),
      rawValue: undefined,
      enrichedValue: undefined,
      isMerchandiseTypeAttribute: false,
      order: getOr(900, 'order', fieldData),
      isImage: getOr(false, 'isImage', fieldData),
      helperText: undefined,
    }

    if (field.name === 'item_type.name') {
      item.rawValue = field.value
      item.enrichedValue = getOr(
        undefined,
        'product_classification.item_type.name',
        heliosItem,
      )
    } else if (field.name === 'brands.name') {
      const brandId = get('brands[0].id', heliosItem)
      let brandValue = get('brands[0].name', heliosItem)
      let brandDescription
      if (brandId) {
        const brand = await getBrand(brandId)
        brandDescription = get('brandDescription', brand)
      }
      item.rawValue = field.value
      item.enrichedValue = brandValue
      item.helperText = brandDescription
    } else if (getOr(false, 'isImage', fieldData)) {
      item.rawValue = field.value
      item.enrichedValue = imageMap[field.name]
    } else {
      if (
        !isNaN(toNumber(field.name)) &&
        heliosItem.merchandise_type_attributes
      ) {
        const mta = heliosItem.merchandise_type_attributes.find(
          (attribute) => attribute.id === field.name,
        )
        if (mta) {
          const enrichedValues = mta.values.map(({ name }) => name)
          const unitOfMeasure = getOr('', 'unit_of_measure', mta)
          item.isMerchandiseTypeAttribute = true
          item.displayName = mta.name
          item.rawValue = field.value
          item.enrichedValue = `${enrichedValues.join(', ')}${
            unitOfMeasure ? ` ${unitOfMeasure}` : ``
          }`
        }
      }
    }

    return item
  })
}

const buildImageMap = (heliosItem: HeliosItem, fields: ProductField[] = []) => {
  const imageFields = filter(
    (field) =>
      field.name !== PRIMARY_IMAGE_FIELD &&
      ITEM_FIELDS[field.name] &&
      ITEM_FIELDS[field.name].isImage,
    fields,
  )

  const { validation_errors, enrichment } = heliosItem
  let enrichedAltImages: string[] = []
  let enrichedPrimaryImage
  if (enrichment && enrichment.images) {
    const baseUrl = enrichment.images.base_url
    enrichedPrimaryImage = `${baseUrl}/${enrichment.images.primary_image}`
    enrichedAltImages = enrichment.images.alternate_images
      ? enrichment.images.alternate_images.map((image) => `${baseUrl}/${image}`)
      : []
  }

  const sortedImageFields = sortBy((field) => {
    return ITEM_FIELDS[field.name].order
  }, imageFields)

  let idx = 0
  return reduce(
    (imageMap: any, field: ProductField) => {
      let mapValue = null
      if (isImageValid(field.value, validation_errors)) {
        // the length check shouldn't be necessary, but you just can't trust the data these days
        if (idx >= enrichedAltImages.length) {
          console.error(
            `Missing image for ${field.name} from helios. ${enrichedAltImages}`,
          )
        }
        mapValue =
          idx < enrichedAltImages.length ? enrichedAltImages[idx++] : null
      }
      imageMap[field.name] = mapValue
      return imageMap
    },
    { 'digital_assets.images.primary_image_url': enrichedPrimaryImage },
    sortedImageFields,
  )
}

const isImageValid = (
  value: string,
  errors: HeliosValidationError[] | undefined,
) => {
  if (!errors || !errors.length) return true
  return !find((error) => error.reason.indexOf(value) > -1, errors)
}

const checkRequired = (
  assignments: TaxonomyAssignment[],
  fieldName: string,
) => {
  const assignment = assignments.find(
    (assignment) =>
      getOr('', 'attribute.mapped_property', assignment) === fieldName,
  )
  return assignment && assignment.required
}
