import { useEffect, useState } from 'react'
import filter from 'lodash/fp/filter'

import Radio from '@mui/material/Radio'
import ErrorIcon from '@mui/icons-material/Error'
import Typography from '@mui/material/Typography'

import DataList, { DataListItem } from 'components/common/DataList'
import Dialog, { DialogEnum } from 'components/common/Dialog'
import PictureZoomable from 'components/common/PictureZoomable'
import TabularData, { FieldList } from 'components/common/TabularData'

import { enrichProductFields } from 'services/itemEnrichment'
import { HeliosItem } from 'types/HeliosItem'
import {
  EnrichedProductField,
  MarketplaceProduct,
  ProductError,
  SmsProduct,
} from 'types/Item'

export interface Props {
  product: MarketplaceProduct
  smsProduct: SmsProduct
  heliosItem: HeliosItem
  errors: ProductError[]
  editErrors: (fieldErrors: ProductError[], fieldName: string) => void
}

const getFieldElement = (field: EnrichedProductField) => {
  const asterisk = field.isRequired ? (
    <span data-testid="required">*</span>
  ) : null

  return field.isMerchandiseTypeAttribute ? (
    <DataList data={[field.displayName as DataListItem]} />
  ) : (
    <Typography variant="caption">
      <>{field.displayName}</>
      {asterisk}
    </Typography>
  )
}

const getValueElement = (field: EnrichedProductField) => {
  if (field.isImage) {
    return field.displayValue.enriched ? (
      <PictureZoomable
        src={field.displayValue.enriched}
        alt={`${field.name} image`}
      />
    ) : (
      ''
    )
  } else if (field.displayValue.enriched) {
    return (
      <div>
        <DataList
          data={[
            { title: 'Raw', value: field.displayValue.raw },
            { title: 'Enriched', value: field.displayValue.enriched },
          ]}
        />
        {field.displayValue.helperText && (
          <Typography data-testid="helper-text" variant="caption">
            {field.displayValue.helperText}
          </Typography>
        )}
      </div>
    )
  }
  return <Typography>{field.displayValue.raw}</Typography>
}

const ReviewLatestItemData = ({
  product,
  heliosItem,
  smsProduct,
  errors,
  editErrors,
}: Props) => {
  const [fields, setFields] = useState<EnrichedProductField[]>([])
  const [selectedField, setSelectedField] = useState<$TSFixMe>()
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (smsProduct.fields) {
      enrichProductFields(product, smsProduct.fields, heliosItem).then(
        (data) => {
          setFields(data)
        },
      )
    }
    return () => setFields([])
  }, [smsProduct, product, heliosItem])

  const handleRadioClick = (field: EnrichedProductField) => () => {
    setSelectedField(field)
    setIsOpen(true)
  }

  const getFieldErrors = () => {
    return filter(
      {
        field_name: selectedField.name,
      },
      errors,
    )
  }

  const handleSubmit = (fieldErrors: ProductError[], fieldName: string) => {
    setIsOpen(false)
    setSelectedField(undefined)
    editErrors(fieldErrors, fieldName)
  }

  const handleClose = () => {
    setIsOpen(false)
    setSelectedField(undefined)
  }

  const fieldList: FieldList<EnrichedProductField>[] = [
    {
      key: 'displayName',
      displayName: 'Field Name',
      width: 20,
      formatCell: (field) => getFieldElement(field),
    },
    {
      key: 'displayValue',
      displayName: 'Field Value',
      width: 70,
      formatCell: (field) => getValueElement(field),
    },
    {
      key: 'isChecked',
      displayName: '',
      width: 10,
      formatCell: (field) => (
        <Radio
          data-testid={`error-radio-${field.name}`}
          checkedIcon={<ErrorIcon style={{ color: 'gold' }} />}
          checked={!!errors.find((error) => error.field_name === field.name)}
          onClick={handleRadioClick(field)}
        />
      ),
    },
  ]

  return (
    <>
      <TabularData data={fields} fieldList={fieldList} />
      {selectedField && (
        <Dialog
          isOpen={isOpen}
          componentName={DialogEnum.ADD_ERROR_CODES}
          componentProps={{
            field: selectedField,
            existingErrors: getFieldErrors(),
            onSubmit: handleSubmit,
            handleClose: handleClose,
            item: smsProduct,
          }}
        />
      )}
    </>
  )
}

export default ReviewLatestItemData
