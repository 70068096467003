import { EnhancedTableFieldType } from 'components/common/EnhancedTable'
import {
  orderStatus,
  formatDateMDYT,
} from 'components/common/EnhancedTable/formatters'
import Link from 'components/common/Link'

import { RoutePath } from 'services/NavigationHelper'
import { memoizedGetSellerDisplayName } from 'services/seller'
import { getLocalTimezoneAbbreviation } from 'services/timezoneCode'

import { Order } from 'types/Orders'

export const getOrdersTableFieldList = (
  isSeller: boolean,
): EnhancedTableFieldType<Order>[] => [
  {
    key: 'id',
    heading: 'Order ID',
    hasSort: true,
    formatCell: ({ id, seller_id }) => {
      const toPath = `/${seller_id}/orders/${id}`
      const previousPage = isSeller
        ? `/${seller_id}${RoutePath.ORDERS}`
        : RoutePath.ALL_ORDERS
      return (
        <Link
          to={{
            pathname: toPath,
          }}
          state={{ previousPage }}
        >
          {id}
        </Link>
      )
    },
  },
  {
    key: 'order_date',
    heading: 'Date & Time Placed',
    hasSort: true,
    formatCell: formatDateMDYT('order_date'),
  },
  {
    key: 'requested_shipment_date',
    heading: `Requested Ship Date (${getLocalTimezoneAbbreviation()})`,
    hasSort: true,
    formatCell: formatDateMDYT('requested_shipment_date'),
  },
  ...(isSeller
    ? []
    : [
        {
          key: 'seller_id',
          heading: 'Partner Name',
          hasSort: false,
          formatCellAsync: async ({ seller_id }: { seller_id: string }) => {
            const displayName = await memoizedGetSellerDisplayName(seller_id)
            return <Link to={`/${seller_id}/dashboard`}>{displayName}</Link>
          },
        },
      ]),
  {
    key: 'order_status',
    heading: 'Order Status',
    hasSort: true,
    formatCell: orderStatus('order_status'),
  },
]
