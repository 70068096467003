import React from 'react'
import { DayPickerProps } from 'react-day-picker'

import DateTextField, { Props as DateTextFieldProps } from './DateTextField'

import { DATE_DISPLAY_FORMAT } from 'services/dateService'
import DayPickerInput from './DayPickerInput'

export type Props = {
  value: Date | undefined | string
  placement?: 'start' | 'end'
  placeholder: string
  onChange: (day: Date) => void
  dayPickerProps: DayPickerProps
  onDelete?: () => void
  isDisabled?: boolean
}

export const DateInput = ({
  value,
  placement,
  placeholder,
  onChange,
  dayPickerProps,
  onDelete,
  isDisabled,
}: Props) => {
  return (
    <DayPickerInput
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      dateDisplayFormat={DATE_DISPLAY_FORMAT}
      onDelete={onDelete}
      inputComponent={React.forwardRef<
        HTMLAnchorElement & HTMLInputElement,
        DateTextFieldProps
      >((props, _ref) => (
        <DateTextField
          isDisabled={isDisabled}
          placement={placement}
          onDelete={onDelete}
          {...props}
        />
      ))}
      dayPickerProps={{
        ...dayPickerProps,
      }}
    />
  )
}

export default DateInput
