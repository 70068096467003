import { useEffect, useState, Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import isEqual from 'lodash/fp/isEqual'
import startCase from 'lodash/fp/startCase'
import { omit } from 'lodash/fp'

import { DialogEnum } from 'components/common/Dialog'
import HeaderTitle from 'components/common/HeaderTitle'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'

import LabeledDataList from 'components/common/LabeledDataList'
import TableSpacer from 'components/common/TableSpacer'

import {
  useSearchParams,
  getEnhancedTablePageableProps,
  getInitialParams,
} from 'components/common/FilterBar/useSearchParams'
import LiveLink from 'components/common/LiveLink'

import { transformDataForAmCharts } from './helpers'

import usePrevious from 'hooks/usePrevious'

import { ITEM_REJECT_LIMIT, ITEM_REPROCESSING_LIMIT } from 'constants/items'

import styled from '@emotion/styled'
import { Button, Grid, Tooltip } from '@mui/material'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import LockIcon from '@mui/icons-material/Lock'

import { isOneOfUserRoles } from 'services/authorization'
import { getProductCountForReviewQueue } from 'services/productCounts'
import {
  USER_ROLE_ADMIN,
  USER_ROLE_ITEM_APPROVER,
  USER_ROLE_ITEM_MANAGER,
} from 'services/roles'
import { scrollTo } from 'services/scrollTo'
import { getSortedMarketplaceProductsWithLocks } from 'services/sellerProducts'
import {
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
  formatDate,
} from 'services/dateService'

import { openDialog } from 'store/dialog/actionCreator'

import { resetQueue } from 'store/itemReview/actionCreators'
import { getMemberOf } from 'store/selectors'

import {
  MarketplaceReviewProduct,
  ValidationStatus,
  ValidationStatusLabel,
} from 'types/Item'
import { CollectionResponse } from 'types/Response'
import { ReviewQueueSearchParams } from 'types/ReviewQueueSearchParams'
import { isReviewQueueSearchParams } from 'types/Guards'

import ItemTreeMap from './ItemTreeMap/ItemTreeMap'
import ReviewQueueFilterBar from './ReviewQueueFilterBar'
import { buildSearchParams, initialSearchParams } from './helpers'

const StyledTitleContainer = styled(Grid)({
  display: 'flex',
})

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))

const StyledContainer = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
})

interface Props {
  showSideNav: boolean
}

export const ReviewQueuePage = ({ showSideNav }: Props) => {
  const dispatch = useDispatch()
  const memberOf = useSelector(getMemberOf)
  const scrollId = 'review-queue-scroll-ref'

  const location = useLocation()
  const initialParams = getInitialParams(location)

  const [searchParams, searchParamActions, appliedFilterCount] =
    useSearchParams<ReviewQueueSearchParams>(
      isReviewQueueSearchParams(initialParams)
        ? initialParams
        : initialSearchParams,
    )

  const [isPending, setIsPending] = useState(false)
  const [items, setItems] = useState<MarketplaceReviewProduct[]>([])
  const [filteredItems, setFilteredItems] = useState<undefined | number>()
  const [totalItems, setTotalItems] = useState<undefined | number>()
  const [chartData, setChartData] = useState<any>()
  const [hideChart, setHideChart] = useState<boolean>(false)
  const prevSearchParams = usePrevious(searchParams)

  const CollapseIcon = hideChart ? UnfoldMoreIcon : UnfoldLessIcon

  const fieldList: EnhancedTableFieldType<MarketplaceReviewProduct>[] = [
    {
      key: 'seller_name',
      heading: 'Partner',
      minWidth: 200,
      hasSort: true,
      formatCell: (reviewItem: MarketplaceReviewProduct) =>
        reviewItem.marketplace_product.seller_name,
    },
    {
      key: 'seller_status',
      heading: 'Partner Status',
      formatCell: (reviewItem: MarketplaceReviewProduct) =>
        reviewItem.marketplace_product.seller_status
          ? startCase(
              reviewItem.marketplace_product.seller_status.toLowerCase(),
            )
          : '',
      minWidth: 135,
    },
    {
      key: 'tcin',
      heading: 'TCIN',
      hasSort: true,
      minWidth: 100,
      formatCell: (reviewItem: MarketplaceReviewProduct) =>
        reviewItem.marketplace_product.tcin,
    },
    {
      key: 'product_type_name',
      heading: 'Product Type',
      minWidth: 150,
      hasSort: true,
      formatCell: (reviewItem: MarketplaceReviewProduct) =>
        reviewItem.marketplace_product.product_type_name
          ? startCase(
              reviewItem.marketplace_product.product_type_name.toLowerCase(),
            )
          : '',
    },
    {
      key: 'item_type_name',
      heading: 'Item Type',
      minWidth: 130,
      hasSort: true,
      formatCell: (reviewItem: MarketplaceReviewProduct) =>
        reviewItem.marketplace_product.item_type_name,
    },
    {
      key: 'title',
      minWidth: 350,
      hasSort: true,
      formatCell: (reviewItem: MarketplaceReviewProduct) =>
        reviewItem.marketplace_product.title,
    },
    {
      key: 'inventory',
      heading: 'Inventory',
      minWidth: 125,
      hasSort: true,
      formatCell: (reviewItem: MarketplaceReviewProduct) =>
        reviewItem.marketplace_product.inventory,
    },
    {
      key: 'relationship_type',
      heading: 'Relationship',
      minWidth: 145,
      hasSort: true,
      formatCell: (reviewItem: MarketplaceReviewProduct) =>
        reviewItem.marketplace_product.relationship_type,
    },
    {
      key: 'validation_status',
      heading: 'Status',
      minWidth: 110,
      hasSort: true,
      formatCell: (reviewItem: MarketplaceReviewProduct) =>
        reviewItem.marketplace_product.validation_status,
    },
    {
      key: 'published',
      formatCell: (reviewItem: MarketplaceReviewProduct) => {
        return (
          <LiveLink
            published={reviewItem.marketplace_product?.published}
            buyUrl={reviewItem.marketplace_product?.buy_url}
          />
        )
      },
      minWidth: 135,
      hasSort: true,
    },
    {
      key: 'previously_approved',
      formatCell: (reviewItem: MarketplaceReviewProduct) =>
        reviewItem.marketplace_product.previously_approved ? 'Yes' : 'No',
      minWidth: 200,
      hasSort: true,
    },
    {
      key: 'last_modified',
      heading: 'Last Item Update',
      minWidth: 220,
      formatCell: (reviewItem: MarketplaceReviewProduct) =>
        formatDate(
          reviewItem.marketplace_product.last_modified,
          DATE_FORMAT_MONTH_DAY_YEAR_TIME,
        ),
      hasSort: true,
    },
    {
      key: 'lock',
      heading: 'Locked Status',
      formatCell: (reviewItem: MarketplaceReviewProduct) =>
        reviewItem.lock ? (
          <Tooltip title="Item Locked: This item is currently being reviewed by another user.">
            <LockIcon fontSize="medium" />
          </Tooltip>
        ) : undefined,
      minWidth: 130,
    },
  ]

  const currentSearchParams = {
    published: searchParams.published,
    validationStatus: searchParams.validation_status,
    sellerId: searchParams.seller_id,
    itemTypeId: searchParams.item_type_id,
    tcin: searchParams.tcin,
    hasInventory: searchParams.has_inventory,
    previouslyApproved: searchParams.previously_approved,
    orderBy: searchParams.orderBy,
    direction: searchParams.direction,
    sellerStatus: searchParams.seller_status,
    productTypeId: searchParams.product_type_id,
  }

  useEffect(() => {
    let mounted = true

    getProductCountForReviewQueue().then((data) => {
      if (mounted) {
        if (!data.error) {
          const countsData = transformDataForAmCharts(data) || []

          setChartData(countsData)
          setTotalItems(
            countsData.reduce((total, value) => total + value.count, 0),
          )
        }
      }
    })

    return () => {
      mounted = false
    }
  }, [searchParams])

  useEffect(() => {
    if (
      !isEqual(
        omit('saved_search_name', prevSearchParams),
        omit('saved_search_name', searchParams),
      )
    ) {
      setIsPending(true)
      const marketPlaceSearchParams = buildSearchParams({
        published: searchParams.published,
        validationStatus: searchParams.validation_status,
        sellerId: searchParams.seller_id,
        itemTypeId: searchParams.item_type_id,
        tcin: searchParams.tcin,
        hasInventory: searchParams.has_inventory,
        previouslyApproved: searchParams.previously_approved,
        sellerStatus: searchParams.seller_status,
        productTypeId: searchParams.product_type_id,
      })

      getSortedMarketplaceProductsWithLocks(
        marketPlaceSearchParams,
        searchParams.page,
        searchParams.perPage,
        [{ orderBy: searchParams.orderBy, direction: searchParams.direction }],
      ).then((response: CollectionResponse<MarketplaceReviewProduct>) => {
        setIsPending(false)
        setItems(response.data)
        setFilteredItems(response.total)
        if (prevSearchParams && !isEqual(searchParams, prevSearchParams)) {
          scrollTo(scrollId, -195)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  const refreshTable = () => {
    dispatch(resetQueue())
    const marketPlaceSearchParams = buildSearchParams(currentSearchParams)
    getSortedMarketplaceProductsWithLocks(
      marketPlaceSearchParams,
      searchParams.page,
      searchParams.perPage,
      [{ orderBy: searchParams.orderBy, direction: searchParams.direction }],
    ).then((response: CollectionResponse<MarketplaceReviewProduct>) => {
      setIsPending(false)
      setItems(response.data)
      setFilteredItems(response.total)
    })
  }

  const handleStartReviewing = () => {
    if (items.length) {
      dispatch(
        openDialog({
          dialogEnum: DialogEnum.ITEM_REVIEW,
          componentProps: {
            filters: searchParams,
            searchParams: buildSearchParams(currentSearchParams),
          },
          closeCallback: refreshTable,
        }),
      )
    } else {
      window.alert(
        `There are no valid items in the queue for your filter criteria`,
      )
    }
  }

  const handleBulkReprocess = () => {
    dispatch(
      openDialog({
        dialogEnum: DialogEnum.BULK_RE_ENRICH,
        componentProps: {
          searchParams: buildSearchParams(currentSearchParams),
          filteredItems,
        },
        closeCallback: refreshTable,
      }),
    )
  }

  const handleBulkReject = () => {
    dispatch(
      openDialog({
        dialogEnum: DialogEnum.BULK_RE_ENRICH,
        componentProps: {
          searchParams: buildSearchParams(currentSearchParams),
          filteredItems,
          reject: true,
        },
        closeCallback: refreshTable,
      }),
    )
  }

  const canReview = isOneOfUserRoles(memberOf, [
    USER_ROLE_ADMIN,
    USER_ROLE_ITEM_APPROVER,
    USER_ROLE_ITEM_MANAGER,
  ])

  const canBulkReprocessReview =
    isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN, USER_ROLE_ITEM_MANAGER]) &&
    typeof searchParams.validation_status === 'string' &&
    searchParams.validation_status ===
      ValidationStatusLabel[ValidationStatus.REVIEW] &&
    (searchParams.tcin ||
      searchParams.seller_id ||
      searchParams.item_type_id) &&
    filteredItems !== undefined &&
    filteredItems <= ITEM_REPROCESSING_LIMIT &&
    filteredItems > 0

  const canBulkReprocessBlocked =
    isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN, USER_ROLE_ITEM_MANAGER]) &&
    searchParams.validation_status &&
    typeof searchParams.validation_status === 'string' &&
    searchParams.validation_status ===
      ValidationStatusLabel[ValidationStatus.BLOCKED] &&
    filteredItems !== undefined &&
    filteredItems <= ITEM_REPROCESSING_LIMIT &&
    filteredItems > 0

  const canRejectItems =
    isOneOfUserRoles(memberOf, [
      USER_ROLE_ADMIN,
      USER_ROLE_ITEM_MANAGER,
      USER_ROLE_ITEM_APPROVER,
    ]) &&
    typeof searchParams.validation_status === 'string' &&
    searchParams.validation_status ===
      ValidationStatusLabel[ValidationStatus.BLOCKED] &&
    searchParams.seller_id &&
    searchParams.item_type_id &&
    filteredItems !== undefined &&
    filteredItems <= ITEM_REJECT_LIMIT &&
    filteredItems > 0

  const labeledData = [{ label: 'Total Pending Items', data: totalItems || 0 }]

  return (
    <Grid container xs={12}>
      <HeaderTitle title="Review Queue" />
      <Grid item xs={12}>
        <StyledContainer>
          <LabeledDataList data={labeledData} />
          <Button onClick={() => setHideChart((prev) => !prev)}>
            <CollapseIcon />
            {!hideChart && <span>Hide Chart</span>}
            {hideChart && <span>Show Chart</span>}
          </Button>
        </StyledContainer>
        {!hideChart && (
          <div>
            <Suspense fallback={<div>Loading...</div>}>
              <ItemTreeMap chartData={chartData} />
            </Suspense>
          </div>
        )}
      </Grid>
      <StyledTitleContainer item xs={12}>
        <Grid container spacing={2}>
          {canRejectItems && (
            <Grid item>
              <StyledButton
                data-testid="bulk-reprocess-button"
                onClick={handleBulkReject}
                disabled={isPending}
                variant="outlined"
                color="primary"
              >
                Reject Items
              </StyledButton>
            </Grid>
          )}
          {(canBulkReprocessBlocked || canBulkReprocessReview) && (
            <Grid item>
              <StyledButton
                data-testid="bulk-reprocess-button"
                onClick={handleBulkReprocess}
                disabled={isPending}
                variant="outlined"
                color="primary"
              >
                Bulk Reprocess Items
              </StyledButton>
            </Grid>
          )}
          {canReview && (
            <Grid item sx={{ paddingBottom: 2 }}>
              <Button
                data-testid="start-reviewing-button"
                color="primary"
                onClick={handleStartReviewing}
                disabled={isPending}
                variant="contained"
              >
                Start reviewing
              </Button>
            </Grid>
          )}
        </Grid>
      </StyledTitleContainer>
      <Grid item xs={12}>
        <ReviewQueueFilterBar
          totalItems={totalItems}
          filteredItems={filteredItems}
          searchParamActions={searchParamActions}
          searchParams={searchParams}
          appliedFilterCount={appliedFilterCount}
        />
      </Grid>
      <Grid item xs={12}>
        <TableSpacer>
          <div>
            <div
              id={scrollId}
              style={{
                overflow: 'auto',
                width: showSideNav
                  ? 'calc(100vw - 400px)'
                  : 'calc(100vw - 100px)',
              }}
            >
              <EnhancedTable
                data-testid="table"
                data={items}
                fieldList={fieldList}
                isLoading={isPending}
                total={filteredItems}
                {...getEnhancedTablePageableProps(
                  searchParams,
                  searchParamActions,
                )}
              />
            </div>
          </div>
        </TableSpacer>
      </Grid>
    </Grid>
  )
}

export default ReviewQueuePage
