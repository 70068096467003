import { SyntheticEvent, useEffect, useState } from 'react'

import usePrevious from 'hooks/usePrevious'

import { Store } from 'types/Store'
import Typeahead from '.'

interface Props {
  onChange: (value: Nullable<Store>, enteredValue: string) => void
  isDisabled?: boolean
  clearOnSelect?: boolean
  value?: Nullable<Store>
  onClear?: () => void
}

const StoreNumberTypeahead = ({
  onChange,
  isDisabled,
  clearOnSelect = false,
  value: valueFromProps,
  onClear,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedValue, setSelectedValue] =
    useState<Nullable<Store>>(valueFromProps)
  const [options, setOptions] = useState<Store[]>([])

  const prevValue = usePrevious(valueFromProps)
  useEffect(() => {
    if (prevValue !== valueFromProps) {
      setSelectedValue(valueFromProps)
    }

    if (!valueFromProps) {
      setOptions([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevValue, valueFromProps])

  useEffect(() => {
    if (options.length && !inputValue) {
      setOptions([])
    }
  }, [inputValue, options])

  const handleInputChange = (
    _event: SyntheticEvent,
    enteredValue: string,
    reason: string,
  ) => {
    if (
      onClear &&
      (reason === 'clear' ||
        (selectedValue && reason === 'input' && enteredValue === ''))
    ) {
      onClear()
      setOptions([])
      setInputValue('')
      setSelectedValue(null)
      return
    }

    setInputValue(enteredValue)

    if (enteredValue && enteredValue.length > 3 && enteredValue.length < 6) {
      setOptions([{ location_id: parseInt(enteredValue) }])
    }
  }

  const handleSelectedOptionChange = (
    _event: SyntheticEvent,
    value: Nullable<Store>,
  ) => {
    const searchParamValue = getOptionLabel(value as Store)
    setSelectedValue(clearOnSelect ? null : (value as Nullable<Store>))
    onChange(value as Nullable<Store>, searchParamValue)
  }

  const getOptionLabel = (item: Store) =>
    item?.location_id.toString().padStart(4, '0')

  return (
    <Typeahead
      aria-label="4-5 Digit Store Number"
      label="4-5 Digit  Store Number"
      placeholder="4-5 Digit Store Number"
      options={options}
      getOptionLabel={getOptionLabel}
      onInputChange={handleInputChange}
      onChange={handleSelectedOptionChange}
      isOptionEqualToValue={(option: Store, value: Nullable<Store>) =>
        option.location_id === value?.location_id
      }
      disabled={!!isDisabled}
      inputValue={inputValue}
      value={selectedValue}
    />
  )
}

export default StoreNumberTypeahead
