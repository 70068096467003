import axios from 'axios'
import apiConfig from 'config/apiConfig'
import updateObjectValues from './updateObjectValues'
import { SmsReturnPolicy } from 'types/Seller'

export function updateReturnPolicy(
  sellerId: string,
  returnPolicy: SmsReturnPolicy,
): Promise<SmsReturnPolicy> {
  const data = updateObjectValues(returnPolicy, '', null)
  const url = `${apiConfig.sms}/sellers/${sellerId}/return_policies`

  if (returnPolicy.id) {
    return axios.put(`${url}/${returnPolicy.id}`, data).then((res) => res.data)
  }

  return axios.post(url, data).then((res) => res.data)
}

export function getReturnPolicies(
  sellerId: string,
): Promise<SmsReturnPolicy[]> {
  const url = `${apiConfig.sms}/sellers/${sellerId}/return_policies`

  return axios.get(url).then((res) => res.data)
}

export function deleteReturnPolicy(sellerId: string, returnPolicyId: string) {
  const url = `${apiConfig.sms}/sellers/${sellerId}/return_policies/${returnPolicyId}`

  return axios.delete(url)
}
