import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import ReactCursorPosition from 'react-cursor-position'

import { AspectRatio } from './AspectRatio'
import { ZoomableImage } from './ZoomableImage'
import { PictureZoomableProps } from 'types/PictureZoomable'

interface CursorPositionProps {
  noWidth: boolean
  noHeight: boolean
}

const StyledCursorPosition = styled(ReactCursorPosition, {
  shouldForwardProp: (prop) => prop !== 'noWidth' && prop !== 'noHeight',
})<CursorPositionProps>((props) => ({
  display: props.noWidth && props.noHeight ? 'inline-block' : 'block',
  height: '100%',
}))

const PictureZoomable = ({
  alt = '',
  aspectRatio,
  width,
  display,
  height,
  className,
  noZoom = false,
  onLoad,
  src,
  zoomedSrc,
  ...restProps
}: PictureZoomableProps) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (onLoad) {
      onLoad()
    }

    setLoaded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hasRatio = aspectRatio !== undefined
  const hasWidth = !!width
  const hasHeight = !!height

  return (
    <AspectRatio
      className={className}
      display={display}
      height={height}
      ratio={aspectRatio}
      width={width}
    >
      <StyledCursorPosition noHeight={!hasHeight} noWidth={!hasWidth}>
        <ZoomableImage
          alt={alt}
          hasHeight={hasHeight}
          hasRatio={hasRatio}
          hasWidth={hasWidth}
          loaded={loaded}
          noZoom={noZoom}
          src={src}
          zoomedSrc={zoomedSrc}
          {...restProps}
        />
      </StyledCursorPosition>
    </AspectRatio>
  )
}
PictureZoomable.displayName = 'PictureZoomable'

export default PictureZoomable
