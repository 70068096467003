import { useSelector } from 'react-redux'

import HeaderTitle from 'components/common/HeaderTitle'

import { currentSeller, getMemberOf } from 'store/selectors'

import ExternalSeasonalEvents from './ExternalSeasonalEvents'
import InternalSeasonalEvents from './InternalSeasonalEvents'
import { isUserRoleExternal } from 'services/authorization'

export const SeasonalEventsPage = () => {
  const seller = useSelector(currentSeller)
  const memberOf = useSelector(getMemberOf)
  const isInternal = !isUserRoleExternal(memberOf)
  return (
    <>
      <HeaderTitle title="Seasonal Calendar" />
      {seller && <ExternalSeasonalEvents data-testid="external-calendar" />}
      {!seller && isInternal && (
        <InternalSeasonalEvents data-testid="internal-calendar" />
      )}
    </>
  )
}

export default SeasonalEventsPage
