import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'

import ContentSpacer from 'components/common/ContentSpacer'
import {
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
  formatDate,
} from 'services/dateService'

import { ReturnDispute } from 'types/disputes'

const StyledTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
}))

interface Props {
  returnDispute: ReturnDispute
}

const CreatedContextInfo = ({ returnDispute }: Props) => {
  return (
    <>
      <ContentSpacer />
      <StyledTypography>Created:</StyledTypography>
      <Typography>
        {formatDate(returnDispute.created, DATE_FORMAT_MONTH_DAY_YEAR_TIME)} by{' '}
        {returnDispute.created_by}
      </Typography>
    </>
  )
}

export default CreatedContextInfo
