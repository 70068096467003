import {
  getExternalNav,
  ExternalNavParams,
} from 'components/common/SideNav/sideNavConfig'

export const getVanityRedirectConfig = ({
  memberOf,
  sellerSource,
  sellerStatus,
  openDialog,
}: ExternalNavParams) => {
  let config = getExternalNav({
    memberOf,
    sellerSource,
    sellerStatus,
    openDialog,
  })

  let flat: string[] = []

  // Iterate through the nav config, flattening subRoutes onto the config as we go.
  // Find only routes with a `to` property that can be viewed by the user.
  while (config.length > 0) {
    const curr = config.shift()

    if (curr?.to !== '/help') {
      if (curr?.subRoutes?.length) {
        config = config.concat(curr.subRoutes)
      } else if (curr?.to && curr.show !== false) {
        flat.push(curr.to)
      }
    }
  }

  return flat
}
