import isEmpty from 'lodash/fp/isEmpty'

import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'

import InfoIcon from '@mui/icons-material/Info'

import TabularDataCell from './TabularDataCell'
import styled from '@emotion/styled'
import { ReactNode } from 'react'

export interface FieldList<T = {}> {
  key: any
  displayName: string | ReactNode
  formatCell?: (arg: T) => any
  numeric?: boolean
  noBorder?: boolean
  minWidth?: number
  width?: number
  fixedWidth?: boolean
  order?: number
  tooltip?: string
}

const StyledTable = styled(Table)(({ theme }) => ({
  padding: theme.spacing(0.5, 0.5, 0.5, 0),
  tableLayout: 'fixed',
  flexBasis: '100%',
  maxWidth: '100%',
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  padding: theme.spacing(0.5, 0.5, 0.5, 0),
}))

const StyledHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

interface Props {
  data?: any
  fieldList: FieldList<any>[]
  hasHeader?: boolean
  extraPadding?: boolean
  borderTop?: boolean
}

const getContent = (rowItem: any, fieldItem: FieldList) => {
  const { key, formatCell, numeric = false } = fieldItem
  const align = numeric ? 'right' : 'left'
  let content = rowItem[key]
  if (formatCell) {
    content = formatCell(rowItem)
  }
  if (typeof content === 'string') {
    return <Typography align={align}>{content}</Typography>
  } else {
    return content
  }
}

export const TabularData = ({
  data,
  fieldList,
  hasHeader = true,
  extraPadding,
  borderTop,
}: Props) => {
  return (
    <StyledTable data-testid="table-data">
      {hasHeader && (
        <TableHead data-testid="tabular-data-head">
          <StyledTableRow>
            {fieldList.map((item: any) => {
              const {
                key,
                displayName,
                noBorder,
                minWidth,
                width,
                fixedWidth,
                numeric,
                tooltip,
              } = item

              return (
                <TabularDataCell
                  noBorder={noBorder}
                  minWidth={minWidth}
                  width={width}
                  fixedWidth={fixedWidth}
                  numeric={numeric}
                  extraPadding={extraPadding}
                  key={key}
                >
                  <StyledHeader>
                    {displayName}
                    {tooltip && (
                      <Tooltip placement="top" title={tooltip}>
                        <InfoIcon color="primary" />
                      </Tooltip>
                    )}
                  </StyledHeader>
                </TabularDataCell>
              )
            })}
          </StyledTableRow>
        </TableHead>
      )}
      <TableBody>
        {isEmpty(data) ? (
          <TableRow data-testid="no-data">
            <TableCell colSpan={fieldList.length}>No results</TableCell>
          </TableRow>
        ) : (
          data.map((rowItem: any, rowIndex: string | number) => {
            return (
              <StyledTableRow
                sx={{
                  borderTop:
                    borderTop === true && rowIndex === 0
                      ? '1px solid rgba(224, 224, 224, 1)'
                      : '',
                }}
                key={rowIndex}
                data-testid="data-row"
              >
                {fieldList.map((fieldItem, fieldIndex) => {
                  const { key, minWidth, width, noBorder, numeric } = fieldItem

                  return (
                    <TabularDataCell
                      key={key || fieldIndex}
                      minWidth={minWidth}
                      width={width}
                      noBorder={noBorder}
                      numeric={numeric}
                      extraPadding={extraPadding}
                      data-testid={`tab-data-${key}-${rowIndex}`}
                    >
                      {getContent(rowItem, fieldItem)}
                    </TabularDataCell>
                  )
                })}
              </StyledTableRow>
            )
          })
        )}
      </TableBody>
    </StyledTable>
  )
}

export default TabularData
