import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useSearchParams as useSearchParamsReactRouter,
  useLocation,
} from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import isEmpty from 'lodash/fp/isEmpty'
import isEqual from 'lodash/fp/isEqual'

import styled from '@emotion/styled'
import { Button } from '@mui/material'
import Grid from '@mui/material/Grid'

import { DialogEnum } from 'components/common/Dialog'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import FilterBar from 'components/common/FilterBar'
import DateRangeFilter from 'components/common/FilterBar/DateRangeFilter'
import MultiSelectFilter from 'components/common/FilterBar/MultiSelectFilter'
import TypeaheadFilter from 'components/common/FilterBar/TypeaheadFilter'
import {
  formatDateMDYT,
  returnOrderNumberLink,
} from 'components/common/EnhancedTable/formatters'
import Link from 'components/common/Link'
import TitleBar from 'components/common/TitleBar'
import ReturnDisputeStatusChip from './ReturnDisputeStatusChip'
import { getDisputeSubReasonDescription } from './Dialogs/returnDisputeHelper'

import {
  isOneOfUserRoles,
  isUserRoleProductAdmin,
} from 'services/authorization'
import { formatDateRange } from 'services/dateService'
import { Direction, getPageable } from 'services/pageableHelper'
import { checkDownloadLimit, submitReport } from 'services/reports'
import { fetchSellerReturnDisputes } from 'services/returnDisputes'

import { openDialog, closeDialog } from 'store/dialog/actionCreator'
import {
  currentSeller,
  getDisputeSubResponses,
  getMemberOf,
} from 'store/selectors'

import { ReturnDispute, JudgementValues } from 'types/disputes'
import { ReportType } from 'types/Report'

import { RETURN_DISPUTES_SEARCH } from './queries'
import { getCaseById, getCases } from 'services/cases'
import { getParam } from 'services/urlHelper'

import { DOWNLOAD_COUNT_LIMIT_TOOLTIP } from 'constants/sizeLimits'
import { USER_ROLE_APP_SMS_ADMIN, USER_ROLE_APP_SMS_READ } from 'services/roles'
import {
  useSearchParams,
  getEnhancedTablePageableProps,
} from 'components/common/FilterBar/useSearchParams'

const StyledFilterContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))

export type TableState = {
  direction: Direction
  orderBy: string
  page: number
  perPage: number
}

export type SearchParams = TableState & {
  case_id: string | undefined
  judgement: string[] | undefined
  return_order_number: string | undefined
  tcin: string | undefined
  license_plate: string | undefined
  createdStartDate: string | undefined
  createdEndDate: string | undefined
  lastModifiedStartDate: string | undefined
  lastModifiedEndDate: string | undefined
}

const initialSearchParams: SearchParams = {
  perPage: 20,
  page: 0,
  orderBy: 'last_modified',
  direction: Direction.DESC,
  case_id: undefined,
  judgement: undefined,
  return_order_number: undefined,
  tcin: undefined,
  license_plate: undefined,
  createdStartDate: undefined,
  createdEndDate: undefined,
  lastModifiedStartDate: undefined,
  lastModifiedEndDate: undefined,
}

const buildSearchParams = ({
  case_id,
  judgement,
  return_order_number,
  tcin,
  license_plate,
  lastModifiedStartDate,
  lastModifiedEndDate,
  createdStartDate,
  createdEndDate,
  direction,
  orderBy,
  page,
  perPage,
}: {
  case_id: string | undefined
  judgement: string[] | undefined
  return_order_number: string | undefined
  tcin: string | undefined
  license_plate: string | undefined
  lastModifiedStartDate: string | undefined
  lastModifiedEndDate: string | undefined
  createdStartDate: string | undefined
  createdEndDate: string | undefined
  direction: Direction
  orderBy: string | undefined
  page: number
  perPage: number
}) => {
  const pageable = getPageable({ direction, orderBy, page, perPage })
  const lastModifiedDateRange = formatDateRange(
    lastModifiedStartDate,
    lastModifiedEndDate,
  )
  const lastModifiedDate = lastModifiedDateRange || undefined

  const createdDateRange = formatDateRange(createdStartDate, createdEndDate)
  const createdDate = createdDateRange || undefined

  return {
    case_id,
    judgement,
    return_order_number,
    tcin,
    license_plate,
    last_modified: lastModifiedDate,
    created: createdDate,
    ...pageable,
  }
}

const ExternalReturnDisputeCasesPage = () => {
  const reduxDispatch = useDispatch()
  const [reactRouterSearchParams, setSearchParams] =
    useSearchParamsReactRouter()
  const seller = useSelector(currentSeller)
  const memberOf = useSelector(getMemberOf)
  const disputeSubReasons = useSelector(getDisputeSubResponses)

  const [searchParams, searchParamActions, appliedFilterCount] =
    useSearchParams<SearchParams>(initialSearchParams)

  const {
    data: returnDisputes,
    isLoading,
    refetch,
  } = useQuery([RETURN_DISPUTES_SEARCH, seller?.id, searchParams], () => {
    if (seller) {
      return fetchSellerReturnDisputes(seller.id, {
        ...buildSearchParams(searchParams),
        source: 'RETURN_DISPUTE',
      })
    }
  })

  const location = useLocation()
  const disputeParam = getParam(location, 'returnDisputeId')

  useEffect(() => {
    if (disputeParam && seller) {
      const selectedDisputeParam = Array.isArray(disputeParam)
        ? disputeParam[0]
        : disputeParam

      getCases({
        seller_id: seller.id,
        case_number: selectedDisputeParam,
        caseType: 'RETURNS_DISPUTES_V2',
        page: 0,
        perPage: 1,
      }).then((caseResponse) => {
        if (!isEmpty(caseResponse.data)) {
          fetchSellerReturnDisputes(seller.id, {
            case_id: caseResponse.data[0].case_id,
            source: 'RETURN_DISPUTE',
          }).then((returnDisputeResponse) => {
            if (!isEmpty(returnDisputeResponse.data)) {
              reduxDispatch(
                openDialog({
                  dialogEnum: DialogEnum.EXTERNAL_RETURN_DISPUTE_DIALOG,
                  componentProps: {
                    returnDispute: returnDisputeResponse.data[0],
                    handleCloseDialog,
                    selectedCase: caseResponse.data[0],
                  },
                }),
              )
            }
          })
        }
      })

      // remove search param from url once the dialog opens
      reactRouterSearchParams.delete('returnDisputeId')
      setSearchParams(reactRouterSearchParams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFilterClear = () => {
    searchParamActions.updateSearchParam(initialSearchParams)
  }

  const refreshTable = () => {
    if (isEqual(searchParams, initialSearchParams)) refetch()
    else onFilterClear()
  }

  const handleFileReturnDispute = () => {
    reduxDispatch(
      openDialog({
        dialogEnum: DialogEnum.FILE_RETURN_DISPUTE_DIALOG,
        componentProps: {
          handleClose: refreshTable,
        },
      }),
    )
  }

  const handleCloseDialog = () => {
    reduxDispatch(closeDialog())
  }

  const fieldList: EnhancedTableFieldType<ReturnDispute>[] = [
    {
      key: 'case_number',
      heading: 'Dispute',
      formatCell: (rowItem) => {
        if (rowItem.case_number) {
          return (
            <Link
              onClick={async (event) => {
                event.preventDefault()
                reduxDispatch(
                  openDialog({
                    dialogEnum: DialogEnum.EXTERNAL_RETURN_DISPUTE_DIALOG,
                    componentProps: {
                      returnDispute: rowItem,
                      handleCloseDialog,
                      selectedCase: await getCaseById({
                        sellerId: rowItem.seller_id,
                        caseId: rowItem.case_id,
                      }),
                    },
                  }),
                )
              }}
              to=""
              data-testid="dispute-detail-dialog"
            >
              {rowItem.case_number}
            </Link>
          )
        } else {
          return 'Dispute number generating...'
        }
      },
    },
    {
      key: 'subject',
      heading: 'Return Order & TCIN',
      formatCell: (rowItem) => {
        const formatReturnOrderNumberLink = returnOrderNumberLink(false)
        return (
          <>
            <p>{formatReturnOrderNumberLink(rowItem)}</p>
            <p>TCIN: {rowItem.tcin}</p>
          </>
        )
      },
    },
    {
      key: 'dispute_sub_reason',
      heading: 'Dispute Reason',
      formatCell: ({ dispute_sub_reason }) => {
        if (dispute_sub_reason) {
          return getDisputeSubReasonDescription(
            dispute_sub_reason,
            disputeSubReasons,
          )
        }
      },
    },
    {
      key: 'status',
      formatCell: ({ judgement }) => {
        return <ReturnDisputeStatusChip returnDisputeJudgement={judgement} />
      },
    },
    {
      key: 'created_by',
      heading: 'Created By',
    },
    {
      key: 'created',
      heading: 'Created Date',
      formatCell: formatDateMDYT('created'),
    },
    {
      key: 'last_modified',
      heading: 'Last Modified',
      formatCell: formatDateMDYT('last_modified'),
    },
  ]

  const handleCreateReport = () => () => {
    const params = buildSearchParams(searchParams)
    return submitReport({
      type: ReportType.PARTNER_CASE_DISPUTES_EXTERNAL,
      parameters: params,
      sellerId: seller?.id,
    })
  }

  const handleDownload = () => {
    const params = {
      dialogEnum: DialogEnum.REPORT_DOWNLOAD_DIALOG,
      componentProps: {
        title: 'GENERATING REPORT...PLEASE WAIT',
        reportTitle: 'Return Disputes',
        createReport: handleCreateReport(),
        sellerId: seller?.id,
      },
    }
    reduxDispatch(openDialog(params))
  }

  return (
    <>
      <TitleBar
        title="Return Disputes"
        actionButtons={[
          !isUserRoleProductAdmin(memberOf) &&
            isOneOfUserRoles(memberOf, [
              USER_ROLE_APP_SMS_ADMIN,
              USER_ROLE_APP_SMS_READ,
            ]) && (
              <Button
                key="file-return-dispute-button"
                color="primary"
                variant="contained"
                onClick={handleFileReturnDispute}
              >
                File a Return Dispute
              </Button>
            ),
        ]}
      />
      <FilterBar
        count={returnDisputes?.total}
        onClear={onFilterClear}
        onDownload={handleDownload}
        disableDownload={checkDownloadLimit(returnDisputes?.total)}
        downloadTooltip={DOWNLOAD_COUNT_LIMIT_TOOLTIP}
        appliedFilterCount={appliedFilterCount}
      >
        <StyledFilterContainer
          container
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item xs={3}>
            <MultiSelectFilter
              label="Dispute Status"
              placeholder="Status"
              value={searchParams.judgement}
              searchParam="judgement"
              onChange={searchParamActions.updateSearchParam}
              data={[
                {
                  name: 'Approved',
                  value: JudgementValues.VALID_CLAIM,
                },
                {
                  name: 'Denied',
                  value: JudgementValues.INVALID_CLAIM,
                },
                {
                  name: 'In Progress',
                  value: JudgementValues.NEEDS_REVIEW,
                },
                {
                  name: 'Waiting On Partner',
                  value: JudgementValues.WAITING_ON_PARTNER,
                },
              ]}
            />
          </Grid>
          <Grid item xs={3}>
            <TypeaheadFilter
              label="Dispute Number"
              placeholder="Dispute Number"
              sellerId={seller?.id}
              value={searchParams.case_id}
              searchParam="case_id"
              caseType={'RETURNS_DISPUTES_V2'}
              onChange={searchParamActions.updateSearchParam}
            />
          </Grid>
          <Grid item xs={3}>
            <TypeaheadFilter
              label="Return Order Number"
              placeholder="Return Order #"
              sellerId={seller?.id}
              value={searchParams.return_order_number}
              searchParam="return_order_number"
              onChange={searchParamActions.updateSearchParam}
            />
          </Grid>
          <Grid item xs={3}>
            <TypeaheadFilter
              label="Item"
              sellerId={seller?.id}
              value={searchParams.tcin}
              searchParam="tcin"
              onChange={searchParamActions.updateSearchParam}
            />
          </Grid>
        </StyledFilterContainer>
        <StyledFilterContainer
          container
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item xs={4.5}>
            <DateRangeFilter
              label="Created Date"
              startValue={searchParams.createdStartDate}
              startSearchParam="createdStartDate"
              endValue={searchParams.createdEndDate}
              endSearchParam="createdEndDate"
              onChange={searchParamActions.updateSearchParam}
            />
          </Grid>
          <Grid item xs={4.5}>
            <DateRangeFilter
              label="Last Modified Date"
              startValue={searchParams.lastModifiedStartDate}
              startSearchParam="lastModifiedStartDate"
              endValue={searchParams.lastModifiedEndDate}
              endSearchParam="lastModifiedEndDate"
              onChange={searchParamActions.updateSearchParam}
            />
          </Grid>
          <Grid item xs={3}>
            <TypeaheadFilter
              label="License Plate"
              value={searchParams.license_plate}
              sellerId={seller?.id}
              searchParam="license_plate"
              onChange={searchParamActions.updateSearchParam}
            />
          </Grid>
        </StyledFilterContainer>
      </FilterBar>
      <EnhancedTable
        data={returnDisputes?.data ?? []}
        fieldList={fieldList}
        isLoading={isLoading}
        total={returnDisputes?.total}
        {...getEnhancedTablePageableProps(searchParams, searchParamActions)}
      />
    </>
  )
}

export default ExternalReturnDisputeCasesPage
