import { PagingParams } from 'services/pageableHelper'

import { ReturnDisputeCasesSearchParams } from 'types/disputes'

export const INTERNAL_RETURN_DISPUTE_CASES_QUERY_KEY =
  'INTERNAL_RETURN_DISPUTE_CASES_QUERY_KEY'
export const RETURN_DISPUTE_REVIEW_QUERY_KEY = 'RETURN_DISPUTE_REVIEW_QUERY_KEY'
export const RETURN_DISPUTES_SEARCH = 'RETURN_DISPUTES_SEARCH'

export const getInternalReturnDisputeCasesQueryKey = ({
  seller_id,
  final_response,
  tcin,
  judgement,
  case_id,
  order_id,
  dispute_reason,
  return_order_number,
  last_modified,
  page,
  perPage,
  direction,
  orderBy,
  created,
}: ReturnDisputeCasesSearchParams & PagingParams) => [
  INTERNAL_RETURN_DISPUTE_CASES_QUERY_KEY,
  seller_id,
  final_response,
  tcin,
  judgement,
  case_id,
  order_id,
  dispute_reason,
  return_order_number,
  last_modified,
  page,
  perPage,
  direction,
  orderBy,
  created,
]

export const getReturnDisputeReviewQueryKey = ({
  seller_id,
  final_response,
  tcin,
  judgement,
  case_id,
  order_id,
  dispute_reason,
  return_order_number,
  last_modified,
  page,
  perPage,
  direction,
  orderBy,
}: ReturnDisputeCasesSearchParams & PagingParams) => [
  RETURN_DISPUTE_REVIEW_QUERY_KEY,
  seller_id,
  final_response,
  tcin,
  judgement,
  case_id,
  order_id,
  dispute_reason,
  return_order_number,
  last_modified,
  page,
  perPage,
  direction,
  orderBy,
]
