import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import has from 'lodash/fp/has'
import isEmpty from 'lodash/fp/isEmpty'

import styled from '@emotion/styled'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious'
import SkipNextIcon from '@mui/icons-material/SkipNext'

import ContentSpacer from 'components/common/ContentSpacer'
import DataList from 'components/common/DataList'
import { DialogEnum } from 'components/common/Dialog'
import EditButton from 'components/common/EditButton'
import HeaderTitle from 'components/common/HeaderTitle'
import Heading from 'components/common/Heading'
import Message from 'components/common/Message'
import Text from 'components/common/Text'
import TitleBar from 'components/common/TitleBar'

import { isOneOfUserRoles } from 'services/authorization'
import { getBuildingLeadTimeIdOverrideMessage } from 'services/codes'
import { formatDate, DATE_DISPLAY_FORMAT_TIME } from 'services/dateService'
import { getVendorCategories } from 'services/marketplaceVendors'
import {
  USER_ROLE_ADMIN,
  USER_ROLE_OPS,
  USER_ROLE_APP_SMS_ADMIN,
} from 'services/roles'
import { getSellerSource } from 'services/seller'
import { timezoneCode } from 'services/timezoneCode'

import { openDialog } from 'store/dialog/actionCreator'
import {
  currentSeller,
  getDistributionCenters,
  getMemberOf,
  getStateCodes,
  getUser,
} from 'store/selectors'

import { ShipNode, SmsDistributionCenter, VendorCategory } from 'types/Seller'

import CarrierInfo from './CarrierInfo'
import HoursOfOperationContent from './HoursOfOperationContent'
import ShippingAddressContent from './ShippingAddressContent'
import DatesClosedContent from './DatesClosedContent'

const StyledText = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}))

export const ShippingInfoPage = () => {
  const reduxDispatch = useDispatch()

  const seller = useSelector(currentSeller)
  const distributionCenters = useSelector(getDistributionCenters)
  const memberOf = useSelector(getMemberOf)
  const stateCodes = useSelector(getStateCodes)
  const user = useSelector(getUser)

  const [vendorCategories, setVendorCategories] = React.useState<
    VendorCategory[]
  >([])
  const [shipNode, setShipNode] = React.useState<ShipNode>()
  const [tabValue, setTabValue] = React.useState(0)
  const [isMultiShipNode, setIsMultiShipNode] = React.useState(false)

  React.useEffect(() => {
    let mounted = true

    if (
      seller?.source &&
      isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN, USER_ROLE_OPS])
    ) {
      getSellerSource(seller?.source).then((source) => {
        if (mounted) {
          setIsMultiShipNode(source.multi_ship_node)
        }
      })
    }

    return () => {
      mounted = false
    }
  }, [seller?.source, memberOf])

  React.useEffect(() => {
    if (!isEmpty(distributionCenters)) {
      const { ship_nodes } = distributionCenters[tabValue]
      if (ship_nodes?.length) {
        setShipNode(ship_nodes[0])
      } else {
        const lanId = user?.lanId ?? 'UNKNOWN'

        const date = formatDate(new Date(), DATE_DISPLAY_FORMAT_TIME)
        setShipNode({ name: `${lanId}-${date}` })
      }
    }
  }, [distributionCenters, tabValue, user])

  const hasBuildingLeadTimeIdOverride = !!shipNode?.service_levels?.some(
    has('building_lead_time_id_override'),
  )

  const message = hasBuildingLeadTimeIdOverride
    ? getBuildingLeadTimeIdOverrideMessage(
        shipNode?.service_levels,
        vendorCategories,
      )
    : ''

  React.useEffect(() => {
    getVendorCategories().then((response) => setVendorCategories(response))
  }, [])

  const handleTabChange = (_event: any, newValue: number) =>
    setTabValue(newValue)

  const formatStates = (twoDayStates: string[]) => {
    const states = twoDayStates.map((code) => {
      const stateCode = stateCodes.find((sc) => sc.value === code)
      return stateCode?.properties.name ?? code
    })

    states.sort()

    return states.join(', ')
  }

  const canEdit = isOneOfUserRoles(memberOf, [
    USER_ROLE_ADMIN,
    USER_ROLE_OPS,
    USER_ROLE_APP_SMS_ADMIN,
  ])

  const canCreate =
    isMultiShipNode &&
    isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN, USER_ROLE_OPS])

  const getDistributionCenterLabel = (
    distributionCenter: Partial<SmsDistributionCenter>,
  ) => {
    if (distributionCenter.name) {
      return `${distributionCenter.id} - ${distributionCenter.name}`
    } else {
      return distributionCenter.id
    }
  }

  const addDistributionCenter = () => {
    reduxDispatch(
      openDialog({ dialogEnum: DialogEnum.ADD_DISTRIBUTION_CENTER }),
    )
  }

  return (
    <div data-testid="shipping-returns-page">
      <HeaderTitle title="Shipping Info" />

      {hasBuildingLeadTimeIdOverride && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Message
              type="information"
              title="Building Lead Time has been System Adjusted"
            >
              {message}
            </Message>
          </Grid>
        </Grid>
      )}

      <ContentSpacer>
        <TitleBar
          variant="h2"
          title="distribution centers"
          hideDivider
          actionButtons={
            canCreate
              ? [
                  <Button
                    key="add-distribution-center"
                    data-testid="add-distribution-center"
                    color="primary"
                    variant="contained"
                    onClick={addDistributionCenter}
                  >
                    Add New Distribution Center
                  </Button>,
                ]
              : undefined
          }
        />
      </ContentSpacer>
      {isEmpty(distributionCenters) && (
        <ContentSpacer>
          <StyledText variant="body1">
            *Required before items can list
          </StyledText>
        </ContentSpacer>
      )}
      {!isEmpty(distributionCenters) && (
        <>
          <ContentSpacer>
            <Tabs
              style={{
                height: 50,
              }}
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              ScrollButtonComponent={(props) => {
                if (props.direction === 'left' && !props.disabled) {
                  return (
                    <IconButton
                      {...props}
                      style={{
                        width: 50,
                      }}
                    >
                      <SkipPreviousIcon color={'action'} fontSize="large" />
                    </IconButton>
                  )
                } else if (props.direction === 'right' && !props.disabled) {
                  return (
                    <IconButton
                      {...props}
                      style={{
                        width: 50,
                      }}
                    >
                      <SkipNextIcon color={'action'} fontSize="large" />
                    </IconButton>
                  )
                } else {
                  return null
                }
              }}
            >
              {distributionCenters.map((distributionCenter) => {
                return (
                  <Tab
                    key={distributionCenter.id}
                    label={getDistributionCenterLabel(distributionCenter)}
                  />
                )
              })}
            </Tabs>
          </ContentSpacer>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ContentSpacer>
                <StyledText variant="body1">
                  *Required before items can list
                </StyledText>
                <StyledText variant="h3">IDs & Timezone*</StyledText>
                <DataList
                  data={[
                    {
                      title: 'Distribution Center Name:',
                      value: distributionCenters[tabValue].name,
                      element: (
                        <EditButton
                          dialogComponent={DialogEnum.EDIT_DISTRIBUTION_NAME}
                          componentProps={{
                            distributionCenter: distributionCenters[tabValue],
                          }}
                          aria-label="edit distribution center name"
                          hide={!canEdit}
                        />
                      ),
                    },
                    {
                      title: 'Distribution Center ID:',
                      value: distributionCenters[tabValue].id,
                    },
                    { title: 'Ship Node:', value: shipNode?.vmm_id },
                    {
                      title: 'Distribution Center Time Zone:',
                      value: timezoneCode(
                        distributionCenters[tabValue].timezone,
                      ),
                      element: (
                        <EditButton
                          dialogComponent={
                            DialogEnum.EDIT_DISTRIBUTION_TIMEZONE
                          }
                          componentProps={{
                            distributionCenter: distributionCenters[tabValue],
                          }}
                          aria-label="edit distribution center name"
                          hide={!canEdit}
                        />
                      ),
                    },
                  ]}
                />
                <Heading title="Address*" variant="h3">
                  {canEdit && (
                    <EditButton
                      dialogComponent={DialogEnum.EDIT_SHIPPING_ADDRESS}
                      componentProps={{
                        distributionCenter: distributionCenters[tabValue],
                      }}
                      aria-label="edit address"
                    />
                  )}
                </Heading>
                <ShippingAddressContent
                  address={distributionCenters[tabValue].address}
                />
                <Heading title="Shipping Hours*" variant="h3">
                  {canEdit && (
                    <EditButton
                      dialogComponent={DialogEnum.EDIT_HOURS_OF_OPERATION}
                      componentProps={{
                        distributionCenter: distributionCenters[tabValue],
                      }}
                      aria-label="edit hours of operation"
                    />
                  )}
                </Heading>
                <HoursOfOperationContent
                  daysOfOperation={
                    distributionCenters[tabValue].days_of_operation
                  }
                  timezone={distributionCenters[tabValue].timezone}
                />
                <Heading title="Dates Closed*" variant="h3">
                  {canEdit && (
                    <EditButton
                      dialogComponent={DialogEnum.EDIT_DATES_CLOSED}
                      componentProps={{
                        distributionCenter: distributionCenters[tabValue],
                      }}
                      aria-label="edit hours of operation"
                    />
                  )}
                </Heading>
                <Typography variant="body1" sx={{ pb: 2 }}>
                  These dates reflect operational date closures in which this
                  distribution center will be closed and will not fulfill guest
                  orders.
                </Typography>
                <DatesClosedContent
                  daysClosed={
                    distributionCenters[tabValue].days_of_operation?.days_closed
                  }
                />
              </ContentSpacer>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TitleBar
                title="Carriers & Service Levels*"
                actionButtons={[
                  <EditButton
                    dialogComponent={DialogEnum.EDIT_CARRIER_INFO}
                    componentProps={{
                      vendorCategories,
                      shipNode,
                      distributionCenter: distributionCenters[tabValue],
                    }}
                    key="edit carriers"
                    aria-label="edit carriers"
                    hide={!canEdit}
                  />,
                ]}
              />
              {shipNode && (
                <CarrierInfo
                  shipNode={shipNode}
                  vendorCategories={vendorCategories}
                  hasBuildingLeadTimeIdOverride={hasBuildingLeadTimeIdOverride}
                  timezone={distributionCenters[tabValue].timezone}
                />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Heading title="States Eligible for 2-Day Shipping" variant="h3">
                {isOneOfUserRoles(memberOf, [
                  USER_ROLE_ADMIN,
                  USER_ROLE_OPS,
                ]) && (
                  <EditButton
                    dialogComponent={DialogEnum.EDIT_ELIGIBLE_STATES}
                    componentProps={{
                      distributionCenter: distributionCenters[tabValue],
                    }}
                    aria-label="edit hours of operation"
                  />
                )}
              </Heading>
              {(!distributionCenters[tabValue].two_day_state_codes ||
                !distributionCenters[tabValue].two_day_state_codes?.length) && (
                <Text type="micro">None Provided</Text>
              )}
              {distributionCenters[tabValue].two_day_state_codes && (
                <StyledText
                  variant="body1"
                  data-testid="formatted-two-day-states"
                >
                  {formatStates(
                    distributionCenters[tabValue].two_day_state_codes!,
                  )}
                </StyledText>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}

export default ShippingInfoPage
