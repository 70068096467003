import axios from 'axios'

import apiConfig from 'config/apiConfig'

import { saveFile } from 'services/files'

import { Case, CreateCaseParams, DisputeValidationsResponse } from 'types/Case'
import { SellerMatch } from 'types/Seller'
import { CollectionResponse } from 'types/Response'

import { PagingParams, Direction, getPageable } from './pageableHelper'

export interface GetCasesParams extends PagingParams {
  seller_id?: string
  status?: string
  caseType?: string
  case_number?: string
  starts_on?: string
  ends_on?: string
}

export const getCases = async ({
  caseType,
  case_number,
  starts_on,
  ends_on,
  direction = Direction.ASC,
  orderBy = 'created',
  page,
  perPage,
  seller_id,
  status,
}: GetCasesParams): Promise<CollectionResponse<Case>> => {
  const config = {
    params: {
      status,
      case_type: caseType,
      starts_on,
      ends_on,
      case_number,
      ...getPageable({ page, perPage, direction, orderBy }),
    },
  }

  const url = seller_id
    ? `${apiConfig.sms}/sellers/${seller_id}/cases`
    : `${apiConfig.sms}/cases`

  return axios
    .get<Case[]>(url, config)
    .then(({ data, headers }) => ({
      data,
      total: parseInt(headers['x-total-count'], 10),
    }))
    .catch(() => ({
      data: [],
      total: 0,
    }))
}

export const getCaseById = async ({
  sellerId,
  caseId,
}: {
  sellerId: string
  caseId: string
}): Promise<Case> => {
  const response = await axios.get(
    `${apiConfig.sms}/sellers/${sellerId}/cases/${caseId}`,
  )
  return response.data
}

export function deleteCaseReturnDisputes(sellerId: string, caseId: string) {
  const url = `${apiConfig.sms}/sellers/${sellerId}/cases/${caseId}`

  return axios.delete(url)
}

export const saveCase = async ({
  sellerId,
  subject,
  caseType,
  sellerOrderNumber,
  email,
  description,
  attachments,
}: CreateCaseParams & SellerMatch): Promise<Case> => {
  const data = new FormData()
  data.append(
    'case',
    JSON.stringify({
      subject,
      case_type: caseType,
      seller_order_number: sellerOrderNumber ? sellerOrderNumber : null,
      seller_email: email,
      issue_description: description,
    }),
  )

  attachments?.forEach((attachment) => {
    data.append('attachment', new Blob([attachment]), attachment.name)
  })

  const response = await axios.post(
    `${apiConfig.sms}/sellers/${sellerId}/cases`,
    data,
  )

  return response.data
}

export const downloadCaseAttachment = async (
  sellerId: string,
  attachmentId: string,
): Promise<Blob> => {
  const response = await axios.get(
    `${apiConfig.sms}/sellers/${sellerId}/attachments/${attachmentId}`,
    {
      headers: {
        Accept: 'application/octet-stream',
      },
      responseType: 'blob',
    },
  )

  return response.data
}

export const saveAttachment = (sellerId: string, attachmentId: string) => {
  downloadCaseAttachment(sellerId, attachmentId).then((resp) =>
    saveFile({
      data: resp,
      name: getFilenameFromId(attachmentId),
      type: 'application/octet-stream;charset=utf-8',
    }),
  )
}

export const caseAttachmentImageUrl = async (
  sellerId: string,
  attachmentId: string,
) => {
  const attachment = await downloadCaseAttachment(sellerId, attachmentId)
  const objectUrl = URL.createObjectURL(attachment)

  return objectUrl
}

export const disputeValidations = async (
  sellerId: string,
  caseId: string,
): Promise<DisputeValidationsResponse> => {
  const response = await axios.post(
    `${apiConfig.sms}/sellers/${sellerId}/cases/${caseId}/dispute_validations`,
  )

  return response.data
}

export enum AttachmentFileType {
  IMAGE_ATTACHMENT = 'IMAGE_ATTACHMENT',
  DOCUMENT_ATTACHMENT = 'DOCUMENT_ATTACHMENT',
}

export const getFilenameFromId = (id: string) => {
  const guidPattern = /^\w{8}-\w{4}-\w{4}-\w{4}-\w{12}-/
  return guidPattern.test(id) ? id.substr(37) : id
}

export const getFileExtension = (filename: string) => {
  return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2)
}

export const getAttachmentType = (fileExtension: string) => {
  switch (fileExtension.toLowerCase()) {
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'bmp':
    case 'png':
      return AttachmentFileType.IMAGE_ATTACHMENT
    case 'csv':
    case 'doc':
    case 'docm':
    case 'docx':
    case 'htm':
    case 'html':
    case 'pdf':
    case 'rtf':
    case 'xls':
    case 'xlsx':
      return AttachmentFileType.DOCUMENT_ATTACHMENT
    default:
      return ''
  }
}
