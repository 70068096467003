import React from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import DateRangePicker from 'components/common/DateRangePicker'
import { SellerTypeahead } from 'components/common/Typeahead'

import { getDateBefore } from 'services/dateService'
import { GenerateReportRequest } from 'services/reports'

import { closeDialog } from 'store/dialog/actionCreator'

import { SmsSeller } from 'types/Seller'
import { Typography } from '@mui/material'

const StyledRoot = styled('div')({
  minHeight: 150,
})
const StyledTypography = styled(Typography)({
  paddingBottom: 25,
})

const setDateRange = (fromDate: Date, toDate: Date, isValidDate: boolean) =>
  ({
    type: 'SET_DATE_RANGE',
    payload: {
      fromDate,
      toDate,
      isValidDate,
    },
  }) as const

const setSellerId = (sellerId: string | undefined) =>
  ({
    type: 'SET_SELLER_ID',
    payload: {
      sellerId,
    },
  }) as const

type Actions = ReturnType<typeof setDateRange | typeof setSellerId>

interface State {
  isValidDate: boolean
  sellerId: string | undefined
  fromDate: Date
  toDate: Date
}

const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case 'SET_DATE_RANGE': {
      const { payload } = action
      const { toDate, fromDate, isValidDate } = payload

      const newState = {
        ...state,
        toDate,
        fromDate,
        isValidDate,
      }

      return newState
    }
    case 'SET_SELLER_ID': {
      const { payload } = action
      const { sellerId } = payload

      const newState = {
        ...state,
        sellerId,
      }

      return newState
    }

    default:
      return state
  }
}

export type Props = {
  isOpen: boolean
  onRequestSubmit: (reportParams: Partial<GenerateReportRequest>) => void
}

export const DateAndSellerReportDialog: React.FC<Props> = ({
  isOpen,
  onRequestSubmit,
}) => {
  const initialState: State = {
    isValidDate: true,
    sellerId: undefined,
    fromDate: getDateBefore(new Date(), 30, 'days'),
    toDate: new Date(),
  }

  const reduxDispatch = useDispatch()

  const [state, dispatch] = React.useReducer(reducer, initialState)

  const handleDateChange = (from: Date, to: Date, isValidDate: boolean) => {
    dispatch(setDateRange(from, to, isValidDate))
  }

  const handleSellerSelect = (value: SmsSeller | undefined | null) => {
    const sellerId = value ? value.id : undefined

    dispatch(setSellerId(sellerId))
  }

  const handleSubmit = () => {
    const { sellerId, fromDate, toDate } = state

    onRequestSubmit({
      sellerId,
      startDate: fromDate,
      endDate: toDate,
    })

    reduxDispatch(closeDialog())
  }

  return (
    <DialogContainer
      title="Select Report Date Range"
      isOpen={isOpen}
      isSubmitDisabled={!state.isValidDate || !state.sellerId}
      onSubmit={handleSubmit}
      submitButtonText="Generate"
      disableScroll
    >
      <StyledRoot data-testid="report-date-range">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledTypography>
              Select date range of up to 90 days:
            </StyledTypography>
          </Grid>
          <Grid item xs={12}>
            <DateRangePicker
              isPending={false}
              from={state.fromDate}
              to={state.toDate}
              dayRange={90}
              actionHandler={handleDateChange}
            />
          </Grid>
          <Grid item xs={12}>
            <SellerTypeahead onChange={handleSellerSelect} />
          </Grid>
        </Grid>
      </StyledRoot>
    </DialogContainer>
  )
}

export default DateAndSellerReportDialog
