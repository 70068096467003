import { connect } from 'react-redux'
import { withFormik, Form, FormikProps } from 'formik'
import * as yup from 'yup'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import SelectField from 'components/common/form/SelectField'

import { LABEL_DISTRIBUTION_TIME_ZONE } from 'constants/labels'
import TIMEZONES from 'constants/timezones'

import {
  editSellerDistributionCenter,
  EditSellerDistributionCenterAction,
} from 'store/seller/actionCreators'

import { SmsDistributionCenter } from 'types/Seller'
import StoreState from 'types/state'

type FormValues = {
  timezone: string
}

type ComponentProps = {
  distributionCenter: SmsDistributionCenter
  isOpen: boolean
  editSellerDistributionCenter: (
    distributionCenter: Partial<SmsDistributionCenter>,
  ) => EditSellerDistributionCenterAction
  handleSubmit: () => void
  isSellersPending: boolean
}

type Props = ComponentProps & FormikProps<FormValues>

export const validationSchema = yup.object().shape({
  timezone: yup.string().label(LABEL_DISTRIBUTION_TIME_ZONE).required(),
})

const EditDistributionNameForm = ({
  isOpen,
  isValid,
  dirty,
  handleSubmit,
  isSellersPending,
}: Props) => {
  return (
    <DialogContainer
      isOpen={isOpen}
      title="Edit Distribution Center Time Zone"
      isPending={isSellersPending}
      isSubmitDisabled={!isValid || !dirty}
      onSubmit={handleSubmit}
    >
      <Form>
        <SelectField
          required
          id="time-zone-code"
          name="timezone"
          label={LABEL_DISTRIBUTION_TIME_ZONE}
          options={TIMEZONES}
          keyName="code"
          valueName="name"
          disabled={isSellersPending}
        />
      </Form>
    </DialogContainer>
  )
}

export const EditDistributionTimeZone = withFormik<ComponentProps, FormValues>({
  mapPropsToValues: (props) => {
    return {
      timezone: props.distributionCenter.timezone ?? '',
    }
  },
  handleSubmit: async (values, { props }) => {
    const { distributionCenter, editSellerDistributionCenter } = props
    if (!distributionCenter) {
      return
    }

    const { timezone } = values

    const updatedDistributionCenter = {
      ...distributionCenter,
      timezone,
    }

    editSellerDistributionCenter(updatedDistributionCenter)
  },
  enableReinitialize: true,
  validateOnMount: false,
  validationSchema,
})(EditDistributionNameForm)

const mapDispatchToProps = {
  editSellerDistributionCenter,
}

const mapStateToProps = (state: StoreState) => ({
  isSellersPending: state.seller.isSellersPending,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditDistributionTimeZone)
