import React from 'react'
import { useSelector } from 'react-redux'

import Grid from '@mui/material/Grid'

import ItemsCard from './ItemsCard'
import ReturnDisputesCard from './ReturnDisputesCard'
import SellersCard from './SellersCard'

import Banner from 'components/common/Banner'
import HeaderTitle from 'components/common/HeaderTitle'
import TwoColumnLayout from 'components/common/TwoColumnLayout'
import UnshippedOrdersCard from 'components/common/UnshippedOrdersCard'

import { getEffectiveNotifications } from 'services/notifications'

import { getIsBannerOpen, getMemberOf } from 'store/selectors'

import { Notification, NotificationDisplayOn } from 'types/Notifications'

const Home = () => {
  const isBannerOpen = useSelector(getIsBannerOpen)
  const memberOf = useSelector(getMemberOf)

  const [notifications, setNotifications] = React.useState<Notification[]>([])

  React.useEffect(() => {
    let mounted = true
    if (isBannerOpen) {
      getEffectiveNotifications({
        contacts: [],
        hasStripeEnabled: false,
        memberOf,
        displayOn: NotificationDisplayOn.HOMEPAGE,
      }).then((resp) => {
        if (mounted) {
          setNotifications(resp)
        }
      })
    }

    return () => {
      mounted = false
    }
  }, [isBannerOpen, memberOf])

  return (
    <div>
      <HeaderTitle title="Home" />
      {isBannerOpen && (
        <Grid container spacing={2} data-testid="notification-banner">
          <Grid item xs={12}>
            <Banner notifications={notifications} />
          </Grid>
        </Grid>
      )}
      <TwoColumnLayout>
        <UnshippedOrdersCard />
        <ItemsCard />
        <ReturnDisputesCard />
        <SellersCard />
      </TwoColumnLayout>
    </div>
  )
}

export default Home
