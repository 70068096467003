import { useEffect } from 'react'
import { Grid, Button } from '@mui/material'
import isEqual from 'lodash/fp/isEqual'
import { useDispatch } from 'react-redux'
import { isEmpty } from 'lodash/fp'

import FilterBar from 'components/common/FilterBar'
import SelectFilter from 'components/common/FilterBar/SelectFilter'
import MultiSearchFilter from 'components/common/FilterBar/MultiSearchFilter'
import MultiItemFilter from 'components/common/FilterBar/MultiItemFilter'
import { SellerTypeahead, TaxonomyTypeahead } from 'components/common/Typeahead'
import { SearchParamActions } from 'components/common/FilterBar/useSearchParams'
import MultiSelectFilter from 'components/common/FilterBar/MultiSelectFilter'
import { DialogEnum } from 'components/common/Dialog'
import { useUserSettingsContext } from 'components/context/UserSettingsProvider'

import { openDialog } from 'store/dialog/actionCreator'

import { REVIEW_QUEUE } from 'constants/userSettings'

import { getSeller } from 'services/seller'
import { getNodeById } from 'services/itemTaxonomies'
import { Direction } from 'services/pageableHelper'
import {
  getSelectedFilters,
  transformSavedSearch,
} from 'services/savedSearchHelper'

import { ValidationStatus, ValidationStatusLabel } from 'types/Item'
import { SellerStatus, SmsSeller } from 'types/Seller'
import Attribute from 'types/Attribute'
import { ReviewQueueSearchParams } from 'types/ReviewQueueSearchParams'
import { SavedSearch } from 'types/SavedSearch'

import usePrevious from 'hooks/usePrevious'

interface Props {
  totalItems: undefined | number
  filteredItems: undefined | number
  searchParams: ReviewQueueSearchParams
  searchParamActions: SearchParamActions<ReviewQueueSearchParams>
  appliedFilterCount: number
}

export const ReviewQueueFilterBar = ({
  totalItems,
  filteredItems,
  searchParams,
  searchParamActions,
  appliedFilterCount,
}: Props) => {
  const dispatch = useDispatch()
  const selectedFilters = getSelectedFilters(searchParams)
  const { updateSelectedSavedSearch, selectedSavedSearches } =
    useUserSettingsContext()

  // If the saved_search_name query-string param is set on mount, set it to state
  useEffect(() => {
    updateSelectedSavedSearch(
      searchParams?.saved_search_name ? selectedFilters : {},
      REVIEW_QUEUE,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const clearedFilters = {
    orderBy: 'last_modified',
    direction: Direction.ASC,
    page: 0,
    perPage: 100,
    published: undefined,
    validation_status: [
      ValidationStatus.REVIEW,
      ValidationStatus.BLOCKED,
      ValidationStatus.VALIDATED,
    ],
    seller_id: undefined,
    item_type_id: undefined,
    tcin: undefined,
    has_inventory: undefined,
    previously_approved: undefined,
    seller_status: undefined,
    product_type_id: undefined,
    saved_search_name: undefined,
  }

  const onFilterClear = () => {
    searchParamActions.updateSearchParam(clearedFilters)
    updateSelectedSavedSearch({}, REVIEW_QUEUE)
  }

  const handleSavedSearchSelect = (selectedValue: SavedSearch) => {
    const listValues = [
      'seller_status',
      'product_type_id',
      'tcin',
      'seller_id',
      'item_type_id',
    ]
    const savedSearch = transformSavedSearch(selectedValue, listValues)
    updateSelectedSavedSearch(savedSearch, REVIEW_QUEUE)
    searchParamActions.updateSearchParam({ ...clearedFilters, ...savedSearch })
  }

  const applySavedSearch = (savedSearchName: string) => {
    searchParamActions.updateSearchParam({
      ...searchParams,
      saved_search_name: savedSearchName,
    })
  }

  const previousSelectedSavedSearch = usePrevious(selectedSavedSearches)

  useEffect(() => {
    // Apply saved_search_name query-string param when saved search is selected
    if (
      !isEqual(searchParams, selectedSavedSearches[REVIEW_QUEUE]) &&
      !isEmpty(selectedSavedSearches[REVIEW_QUEUE])
    ) {
      applySavedSearch(selectedSavedSearches[REVIEW_QUEUE]?.saved_search_name)
    }

    // Clear saved search param when the selected search is deleted
    if (
      previousSelectedSavedSearch &&
      !isEmpty(previousSelectedSavedSearch[REVIEW_QUEUE]) &&
      isEmpty(selectedSavedSearches[REVIEW_QUEUE])
    ) {
      onFilterClear()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSavedSearches])

  const openSavedSearchDialog = () => {
    const dialogEnum = selectedFilters.saved_search_name
      ? DialogEnum.UPDATE_SAVED_SEARCH_DIALOG
      : DialogEnum.SAVED_SEARCH_DIALOG
    dispatch(
      openDialog({
        dialogEnum,
        componentProps: {
          selectedFilters,
          page: REVIEW_QUEUE,
          filterName: selectedFilters.saved_search_name ?? '',
        },
      }),
    )
  }

  const statusFilters = [
    {
      name: 'All Onboarding Statuses',
      children: [
        {
          name: 'Data Integration',
          value: SellerStatus.DATA_INTEGRATION,
          child: true,
        },
        {
          name: 'Ramp-Up',
          value: SellerStatus.RAMP_UP,
          child: true,
        },
        {
          name: 'Onboarding Complete',
          value: SellerStatus.ONBOARDING_COMPLETE,
          child: true,
        },
      ],
    },
    {
      name: 'Established',
      value: SellerStatus.ESTABLISHED,
    },
  ]

  return (
    <FilterBar
      count={filteredItems}
      total={totalItems}
      onClear={onFilterClear}
      appliedFilterCount={appliedFilterCount}
      handleSavedSearchSelect={handleSavedSearchSelect}
      page={REVIEW_QUEUE}
    >
      <Grid container alignItems="center">
        <Grid container sx={{ ml: 1, mt: 1, mb: 1 }}>
          <Grid item xs={12}>
            <MultiItemFilter
              fullWidth
              placeholder="Search TCIN(s)"
              value={searchParams.tcin ?? ''}
              maxValues={50}
              searchParam="tcin"
              onChange={searchParamActions.updateSearchParam}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ ml: 0 }} spacing={1}>
          <Grid item xs={12} lg="auto">
            <MultiSearchFilter<SmsSeller>
              typeahead={SellerTypeahead}
              text="Partner(s)"
              value={searchParams.seller_id}
              searchParam="seller_id"
              onChange={searchParamActions.updateSearchParam}
              getItemAsync={getSeller}
            />
          </Grid>
          <Grid item xs={12} lg="auto">
            <MultiSelectFilter
              placeholder="Partner Status"
              searchParam="seller_status"
              value={searchParams.seller_status}
              data={statusFilters}
              onChange={searchParamActions.updateSearchParam}
            />
          </Grid>
          <Grid item xs={12} lg="auto">
            <MultiSearchFilter<Attribute>
              typeahead={TaxonomyTypeahead}
              text="Product Type(s)"
              value={searchParams.product_type_id}
              searchParam="product_type_id"
              onChange={searchParamActions.updateSearchParam}
              getItemAsync={getNodeById}
            />
          </Grid>
          <Grid item xs={12} lg="auto">
            <MultiSearchFilter<Attribute>
              typeahead={TaxonomyTypeahead}
              text="Item Type(s)"
              value={searchParams.item_type_id}
              searchParam="item_type_id"
              onChange={searchParamActions.updateSearchParam}
              getItemAsync={getNodeById}
            />
          </Grid>
          <Grid item xs={12} lg="auto">
            <SelectFilter
              placeholder="Validation Status"
              value={
                typeof searchParams.validation_status === 'string'
                  ? searchParams.validation_status
                  : undefined
              }
              searchParam="validation_status"
              onChange={searchParamActions.updateSearchParam}
              data={[
                {
                  name: ValidationStatusLabel[ValidationStatus.VALIDATED],
                  value: ValidationStatus.VALIDATED,
                },
                {
                  name: ValidationStatusLabel[ValidationStatus.BLOCKED],
                  value: ValidationStatus.BLOCKED,
                },
                {
                  name: ValidationStatusLabel[ValidationStatus.REVIEW],
                  value: ValidationStatus.REVIEW,
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} lg="auto">
            <SelectFilter
              placeholder="Inventory Status"
              value={searchParams.has_inventory}
              searchParam="has_inventory"
              selectionTitle="Inventory Status: "
              onChange={searchParamActions.updateSearchParam}
              data={[
                {
                  name: 'In Stock',
                  value: 'true',
                },
                {
                  name: 'Out of Stock',
                  value: 'false',
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} lg="auto">
            <SelectFilter
              placeholder="Publish Status"
              value={searchParams.published}
              searchParam="published"
              selectionTitle="Published: "
              onChange={searchParamActions.updateSearchParam}
              data={[
                {
                  name: 'Yes',
                  value: 'Yes',
                },
                {
                  name: 'No',
                  value: 'No',
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} lg="auto">
            <SelectFilter
              placeholder="Previously Approved"
              value={searchParams.previously_approved}
              searchParam="previously_approved"
              selectionTitle="Previously Approved: "
              onChange={searchParamActions.updateSearchParam}
              data={[
                {
                  name: 'Yes',
                  value: 'true',
                },
                {
                  name: 'No',
                  value: 'false',
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} lg="auto">
            <Button
              disabled={
                Object.keys(selectedFilters).length === 0 ||
                isEqual(selectedSavedSearches[REVIEW_QUEUE], selectedFilters) ||
                (Object.keys(selectedFilters).length === 1 &&
                  typeof selectedSavedSearches[REVIEW_QUEUE]
                    .validation_status === 'object')
              }
              onClick={() => openSavedSearchDialog()}
              data-testid="save-filters-btn"
              variant="contained"
            >
              Save Filters
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </FilterBar>
  )
}

export default ReviewQueueFilterBar
