import { useState } from 'react'
import styled from '@emotion/styled'
import { useAuth } from '@praxis/component-auth'

import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { grey, white } from 'config/themeConfig'

import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'

import { RoutePath } from 'services/NavigationHelper'

import Link from '../Link'

const StyledDiv = styled('div')({
  display: 'flex',
})

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'sellerSelected',
})<{ sellerSelected: boolean }>(
  ({ theme }) => ({
    marginRight: theme.spacing(2),
    '& :hover': {
      color: grey[900],
      '& svg': {
        fill: grey[900],
      },
    },
  }),
  (props) => ({
    color: props.sellerSelected ? white.main : grey[900],
    '&:visited': {
      color: props.sellerSelected ? white.main : grey[900],
    },
  }),
)

const StyledAccountCircleIcon = styled(AccountCircleIcon)(({ theme }) => ({
  paddingRight: theme.spacing(1),
}))

const StyledLink = styled(Link)(({ theme }) => ({
  '&:hover': {
    textDecoration: 'none',
  },
  '&:visited': {
    color: theme.palette.text.primary,
  },
  textDecoration: 'none',
  color: theme.palette.text.primary,
}))

interface Props {
  sellerSelected: boolean
  isExternal: boolean
}

export const ProfileMenu = ({ sellerSelected, isExternal }: Props) => {
  const auth = useAuth()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <StyledDiv>
      <StyledButton
        aria-controls={open ? 'profile-button' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        color="secondary"
        sellerSelected={sellerSelected}
      >
        <StyledAccountCircleIcon fontSize="large" />
        Profile {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </StyledButton>
      <Menu
        id="profile-menu"
        aria-labelledby="profile-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {isExternal && (
          <MenuItem onClick={handleClose}>
            <StyledLink to={RoutePath.USER_PROFILE}>My Profile</StyledLink>
          </MenuItem>
        )}
        <MenuItem onClick={auth.logout}>Sign Out</MenuItem>
      </Menu>
    </StyledDiv>
  )
}

export default ProfileMenu
