export const LOCAL_STORAGE_ID_TOKEN = 'id_token'
export const LOCAL_STORAGE_ACCESS_TOKEN = 'access_token'
export const LOCAL_STORAGE_REDIRECT_PATH = 'app_redirect_path'
export const LOCAL_STORAGE_SURVEY_COMPLETED_REPORTS = 'survey_completed_reports'

export function getItem(key: string): string | null {
  return window.localStorage.getItem(key)
}

export function setItem(key: string, value: string): void {
  window.localStorage.setItem(key, value)
}

export function removeItem(key: string): void {
  window.localStorage.removeItem(key)
}
