import { HeadingBorder } from './styles'

export const UnlistItemsDescription = () => {
  return (
    <>
      <HeadingBorder className="hc-fs-xl">Item Unlisting Tool</HeadingBorder>
      <div className="hc-lh-dense hc-mt-dense">
        <p className="hc-clr-grey01 hc-mt-expanded" data-testid="tool-desc">
          Item unlisting tool allows Target+ operations team the control to
          unlist items for business requirements including out of stock issues.{' '}
        </p>
        <p className="hc-clr-grey01">
          <strong>Barcode release: </strong>Please note that once an item is
          unlisted, system will automatically check for orders against the
          unlisted TCINs. If there are no orders in the last 120 days, the
          Barcode will be released without any further action.
        </p>
        <p className="hc-clr-grey01">
          <strong>Relisting the TCIN:</strong> Once an item is unlisted, TM's
          need to reach out to the seller if they wish to relist the TCIN. If
          the barcode is released and is not assigned to any other seller, they
          can update and push the item again from the channel partner console/
          direct API and the barcode will be reassigned.
        </p>
      </div>
    </>
  )
}

export default UnlistItemsDescription
