import { SellerStatus } from 'types/Seller'

import startCase from 'lodash/fp/startCase'
import camelCase from 'lodash/fp/camelCase'

export const sellerStatuses = [
  {
    name: startCase(camelCase(SellerStatus.INITIATED)),
    value: SellerStatus.INITIATED,
  },
  {
    name: startCase(camelCase(SellerStatus.PARTNER_SETUP)),
    value: SellerStatus.PARTNER_SETUP,
  },
  {
    name: startCase(camelCase(SellerStatus.KICK_OFF)),
    value: SellerStatus.KICK_OFF,
  },
  {
    name: startCase(camelCase(SellerStatus.DATA_INTEGRATION)),
    value: SellerStatus.DATA_INTEGRATION,
  },
  {
    name: startCase(camelCase(SellerStatus.RAMP_UP)),
    value: SellerStatus.RAMP_UP,
  },
  {
    name: startCase(camelCase(SellerStatus.ONBOARDING_COMPLETE)),
    value: SellerStatus.ONBOARDING_COMPLETE,
  },
  {
    name: startCase(camelCase(SellerStatus.ESTABLISHED)),
    value: SellerStatus.ESTABLISHED,
  },
  {
    name: startCase(camelCase(SellerStatus.SUSPENDED)),
    value: SellerStatus.SUSPENDED,
  },
  {
    name: startCase(camelCase(SellerStatus.DEFUNCT)),
    value: SellerStatus.DEFUNCT,
  },
]
