import { useEffect, useCallback, useReducer } from 'react'
import * as yup from 'yup'

import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import { formatDateMDYT } from 'components/common/EnhancedTable/formatters'
import useTable from 'components/common/EnhancedTable/useTable'
import FullScreenDialogContainer from 'components/common/Dialog/FullScreenDialogContainer'
import TableSpacer from 'components/common/TableSpacer'
import Text from 'components/common/Text'

import styled from '@emotion/styled'

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import StyledIcon from 'components/common/StyledIcon'

import { formatLocaleNumber } from 'services/formatNumber'
import { Direction } from 'services/pageableHelper'
import {
  hydrateProductQuotas,
  removeProductQuota,
  updateProductQuota,
} from 'services/productQuotas'
import { validationHandler } from 'services/validation'

import { closeDialog } from 'store/dialog/actionCreator'

import Attribute from 'types/Attribute'
import { ProductQuota } from 'types/ProductQuota'

import reducer, {
  initialReducerState,
  closeAside,
  setItemType,
  setQuotaValue,
  setValidation,
  editProductQuota,
  addNewProductQuota,
  setTableData,
} from './EditGlobalQuotasReducer'
import AddGlobalQuotasAside from './AddGlobalQuotasAside'

import { setPending } from './EditGlobalQuotasReducer'
import { useDispatch } from 'react-redux'

const StyledRow = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const StyledButtonContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
}))

const fieldList: EnhancedTableFieldType<ProductQuota>[] = [
  {
    key: 'taxonomyName',
    heading: 'Item Type',
  },
  {
    key: 'quota',
    heading: 'Item Quota',
    formatCell: ({ quota }) => formatLocaleNumber(quota),
  },
  {
    key: 'currentCount',
    heading: 'Current Count',
    formatCell: ({ currentCount, quota }) => {
      const type = currentCount && currentCount > quota ? 'warning' : undefined

      return (
        <Text type={type}>
          {currentCount !== undefined ? formatLocaleNumber(currentCount) : '0'}
        </Text>
      )
    },
  },
  {
    key: 'last_modified',
    heading: 'Last Modified',
    formatCell: formatDateMDYT<ProductQuota>('last_modified'),
  },
  {
    key: 'last_modified_by',
    heading: 'Last Modified By',
  },
]

export interface Props {
  isOpen: boolean
}

export const globalQuotaValidationSchema = yup.object().shape({
  quota: yup
    .string()
    .matches(/^(?:100000|[1-9]\d{0,4})$/, 'Quota must be between 1 and 100000')
    .label('Quota')
    .required(),
})

export const EditGlobalQuotas = ({ isOpen }: Props) => {
  const { table } = useTable({
    direction: Direction.DESC,
    orderBy: 'last_modified',
    page: 0,
    perPage: 20,
  })
  const [state, dispatch] = useReducer(reducer, initialReducerState)
  const reduxDispatch = useDispatch()

  const fetchProductQuotas = useCallback(async () => {
    dispatch(setPending(true))
    try {
      const { data, total } = await hydrateProductQuotas({
        page: table.state.page,
        perPage: table.state.perPage,
        direction: table.state.direction,
        orderBy: table.state.orderBy,
      })
      dispatch(setTableData(data, total))

      dispatch(setPending(false))
    } catch (e) {
      dispatch(setPending(false))
    }
  }, [
    table.state.page,
    table.state.perPage,
    table.state.direction,
    table.state.orderBy,
  ])

  useEffect(() => {
    fetchProductQuotas()
  }, [fetchProductQuotas])

  const confirmAction = (): boolean => {
    const { isAsideOpen } = state
    if (!isAsideOpen) {
      return true
    }
    const isDirty = +state.quotaValue !== state.initialQuotaValue

    return !isDirty || window.confirm('Are you sure?')
  }

  const handleRequestClose = () => {
    if (confirmAction()) {
      reduxDispatch(closeDialog())
    }
  }

  const handleAddNew = () => {
    if (confirmAction()) {
      dispatch(addNewProductQuota())
    }
  }

  const handleCloseAside = () => {
    dispatch(closeAside())
  }

  const handleItemTypeChange = (attribute: Nullable<Attribute>) => {
    if (attribute) {
      dispatch(setItemType(attribute))
    }
  }

  const handleSetQuotaValue = (value: string) => {
    dispatch(setQuotaValue(value))
    const { validation, isValid } = validationHandler(
      globalQuotaValidationSchema,
      {
        quota: +value,
      },
    )
    dispatch(setValidation({ validation, isValid }))
  }

  const handleSubmit = async () => {
    if (state.isValid) {
      const data: ProductQuota = {
        ...(state.quotaId ? { id: state.quotaId } : {}),
        quota: +state.quotaValue,
        taxonomy_id: state.attribute!.id,
      }
      await updateProductQuota(data)
      await fetchProductQuotas()
      dispatch(closeAside())
    }
  }

  const handleEdit = (productQuota: ProductQuota) => () => {
    if (confirmAction()) {
      dispatch(editProductQuota(productQuota))
    }
  }

  const handleDelete = (productQuota: ProductQuota) => async () => {
    if (
      productQuota?.id &&
      window.confirm(
        `Are you sure you want to delete quota for ${productQuota.taxonomyName}?`,
      )
    ) {
      await removeProductQuota(productQuota.id)

      await fetchProductQuotas()
    }
  }

  return (
    <FullScreenDialogContainer
      title="Edit Global Item Types"
      onRequestClose={handleRequestClose}
      isOpen={isOpen}
      isAsideOpen={state.isAsideOpen}
      asideTitle={state.asideTitle}
      aside={
        <AddGlobalQuotasAside
          validation={state.validation}
          valid={state.isValid}
          isEdit={state.isEdit}
          itemType={state.attribute}
          quotaValue={state.quotaValue.toString()}
          onRequestCancel={handleCloseAside}
          onItemTypeChange={handleItemTypeChange}
          onRequestQuotaChange={handleSetQuotaValue}
          onRequestSubmitNewQuota={handleSubmit}
        />
      }
    >
      <StyledRow>
        All quotas are based on item types and only apply to VAPs (Variation
        Parents) and SAs (Stand Alone) items. Unless a quota limit is set,
        unlimited number of items can be published on Target.com.
      </StyledRow>
      <StyledButtonContainer>
        <Button
          data-testid="add-new-button"
          color="primary"
          onClick={handleAddNew}
        >
          add quota
        </Button>
      </StyledButtonContainer>
      <TableSpacer>
        <EnhancedTable
          total={state.totalQuotas}
          data={state.allQuotas}
          fieldList={fieldList}
          isLoading={state.pending}
          onChangePage={table.actions.changePage}
          onChangeRowsPerPage={table.actions.changePerPage}
          orderBy={table.state.orderBy}
          order={table.state.direction}
          page={table.state.page}
          rowsPerPage={table.state.perPage}
          actions={[
            {
              callback: handleEdit,
              label: 'Edit',
              icon: <StyledIcon iconType="edit" />,
            },
            {
              callback: handleDelete,
              label: 'Delete',
              icon: <StyledIcon iconType="delete" />,
            },
          ]}
        />
      </TableSpacer>
    </FullScreenDialogContainer>
  )
}

export default EditGlobalQuotas
