import React from 'react'

import { withFormik, Form, FormikProps } from 'formik'
import * as yup from 'yup'

import Actions from '../Actions'
import Content from '../Content'

import { StyledRow } from '../DomesticDiversitySurvey/DomesticSurveyPageOne'

import Grid from '@mui/material/Grid'

import InputField from 'components/common/form/InputField'
import RadioGroupField from 'components/common/form/RadioGroupField'
import Text from 'components/common/Text'

import { DiversityRadio, DiversitySelection } from 'types/PartnerDiversity'

const validationSchema = yup.object().shape({
  WOMAN: yup.string().required(),
  WOMAN_CERT: yup.string().test({
    name: 'isWomanCert',
    test(this: yup.TestContext, value: any) {
      if (value && !this.parent.WOMAN_CERT_DATE) {
        return this.createError({
          path: 'WOMAN_CERT_DATE',
          message: 'Required',
        })
      }

      return true
    },
  }),
  WOMAN_CERT_DATE: yup.string().when('WOMAN_CERT', {
    is: (field: string | any[]) => field && field.length > 0,
    then: yup.string().required('Required'),
    otherwise: yup.string(),
  }),
  WOMAN_OTHER: yup.string().test({
    name: 'isWomanOther',
    test(this: yup.TestContext, value: any) {
      if (value && !this.parent.WOMAN_OTHER_CERT) {
        return this.createError({
          path: 'WOMAN_OTHER_CERT',
          message: 'Required',
        })
      }

      return true
    },
  }),
  WOMAN_OTHER_CERT: yup.string().test({
    name: 'isWomanOtherCert',
    test(this: yup.TestContext, value: any) {
      if (value && !this.parent.WOMAN_OTHER_CERT_DATE) {
        return this.createError({
          path: 'WOMAN_OTHER_CERT_DATE',
          message: 'Required',
        })
      }

      return true
    },
  }),
  WOMAN_OTHER_CERT_DATE: yup.string().when('WOMAN_OTHER_CERT', {
    is: (field: string | any[]) => field && field.length > 0,
    then: yup.string().required('Required'),
    otherwise: yup.string(),
  }),
})

export type FormValues = {
  WOMAN: DiversitySelection
  WOMAN_CERT: string
  WOMAN_CERT_DATE: string
  WOMAN_OTHER: string
  WOMAN_OTHER_CERT: string
  WOMAN_OTHER_CERT_DATE: string
}

type ComponentProps = {
  initValues: FormValues
  isEdit: boolean
  onSubmit: (values: any) => void
}

type Props = ComponentProps & FormikProps<FormValues>

export const InternationalSurveyPageOneForm = ({
  isValid,
  dirty,
  values,
  handleSubmit,
  isSubmitting,
  setFieldValue,
}: Props) => {
  React.useEffect(() => {
    if (values.WOMAN === DiversityRadio.NO) {
      setFieldValue('WOMAN_CERT', '')
      setFieldValue('WOMAN_CERT_DATE', '')
      setFieldValue('WOMAN_OTHER', '')
      setFieldValue('WOMAN_OTHER_CERT', '')
      setFieldValue('WOMAN_OTHER_CERT_DATE', '')
    }
  }, [values.WOMAN, setFieldValue])

  return (
    <>
      <Content>
        <Form>
          <StyledRow>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Text type="bodySm" component="label">
                  Are you a Woman Owned Business?
                </Text>
              </Grid>
              {values.WOMAN === DiversityRadio.YES && (
                <>
                  <Grid item xs={4}>
                    <Text type="bodySm" component="label" htmlFor="woman-cert">
                      Women's Business Enterprise National Council (WBENC) or
                      Regional Affiliate
                    </Text>
                  </Grid>
                  <Grid item xs={4}>
                    <Text
                      type="bodySm"
                      component="label"
                      htmlFor="woman-cert-date"
                    >
                      WBENC Certificate Expiration Date
                    </Text>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <RadioGroupField
                  required
                  row={true}
                  name="WOMAN"
                  options={[
                    { label: 'Yes', value: DiversityRadio.YES },
                    { label: 'No', value: DiversityRadio.NO },
                  ]}
                />
              </Grid>
              {values.WOMAN === DiversityRadio.YES && (
                <>
                  <Grid item xs={4}>
                    <InputField
                      id="woman-cert"
                      name="WOMAN_CERT"
                      touchedFieldName="WOMAN_CERT_DATE"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField
                      id="woman-cert-date"
                      type="date"
                      name="WOMAN_CERT_DATE"
                    />
                  </Grid>
                </>
              )}
            </Grid>
            {values.WOMAN === DiversityRadio.YES && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Text type="bodySm" component="label" htmlFor="woman-other">
                      If Certified by an Agency Other than WBENC, Provide Agency
                      Name
                    </Text>
                  </Grid>
                  <Grid item xs={4}>
                    <Text
                      type="bodySm"
                      component="label"
                      htmlFor="woman-other-cert"
                    >
                      Other Agency Certificate Number
                    </Text>
                  </Grid>
                  <Grid item xs={4}>
                    <Text
                      type="bodySm"
                      component="label"
                      htmlFor="woman-other-cert-date"
                    >
                      Other Agency Certificate Expiration Date
                    </Text>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <InputField
                      id="woman-other"
                      name="WOMAN_OTHER"
                      touchedFieldName="WOMAN_OTHER_CERT"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField
                      id="woman-other-cert"
                      name="WOMAN_OTHER_CERT"
                      touchedFieldName="WOMAN_OTHER_CERT_DATE"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField
                      id="woman-other-cert-date"
                      type="date"
                      name="WOMAN_OTHER_CERT_DATE"
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </StyledRow>
        </Form>
      </Content>
      <Actions
        page={1}
        totalPages={1}
        disabled={!isValid || !dirty || isSubmitting}
        submitText="Done, Save"
        onSubmit={handleSubmit}
      />
    </>
  )
}

export const InternationalSurveyPageOne = withFormik<
  ComponentProps,
  FormValues
>({
  mapPropsToValues: (props) => ({
    WOMAN: props.initValues.WOMAN ?? '',
    WOMAN_CERT: props.initValues.WOMAN_CERT ?? '',
    WOMAN_CERT_DATE: props.initValues.WOMAN_CERT_DATE ?? '',
    WOMAN_OTHER: props.initValues.WOMAN_OTHER ?? '',
    WOMAN_OTHER_CERT: props.initValues.WOMAN_OTHER_CERT ?? '',
    WOMAN_OTHER_CERT_DATE: props.initValues.WOMAN_OTHER_CERT_DATE ?? '',
  }),
  handleSubmit: async (values, { props }) => {
    props.onSubmit(values)
  },
  enableReinitialize: true,
  validationSchema,
})(InternationalSurveyPageOneForm)

export default InternationalSurveyPageOne
