import { EnhancedTableFieldType } from 'components/common/EnhancedTable'

import Attribute, { ITEM_TYPE, PRODUCT_TYPE } from 'types/Attribute'
import { Case } from 'types/Case'
import { HeliosListItem } from 'types/HeliosItem'
import { HttpError } from 'types/HttpError'
import { MarketplaceProduct, RelationshipType } from 'types/Item'
import { Order, Return, ReturnsResearch } from 'types/Orders'
import { ProductLogistics, ProductLogisticsError } from 'types/ProductLogistics'
import { SmsDistributionCenter, SmsSeller } from 'types/Seller'
import { Store } from 'types/Store'
import { Promotion } from './Promotion'
import { Division } from 'types/Division'
import { ReviewQueueSearchParams } from './ReviewQueueSearchParams'

export const isSmsSeller = (value: unknown): value is SmsSeller => {
  return (
    !!value &&
    Object.prototype.hasOwnProperty.call(value, 'legal_business_name')
  )
}

export const isMarketplaceProduct = (
  value: unknown,
): value is MarketplaceProduct => {
  return (
    !!value &&
    Object.prototype.hasOwnProperty.call(value, 'id') &&
    Object.prototype.hasOwnProperty.call(value, 'seller_id') &&
    Object.prototype.hasOwnProperty.call(value, 'seller_name')
  )
}

export const isOrder = (value: unknown): value is Order => {
  return (
    !!value &&
    Object.prototype.hasOwnProperty.call(value, 'order_number') &&
    Object.prototype.hasOwnProperty.call(value, 'order_status')
  )
}

export const isReturn = (value: unknown): value is Return => {
  return (
    !!value &&
    Object.prototype.hasOwnProperty.call(value, 'return_date') &&
    Object.prototype.hasOwnProperty.call(value, 'return_order_number')
  )
}

export const isAttribute = (value: unknown): value is Attribute => {
  return (
    !!value &&
    Object.prototype.hasOwnProperty.call(value, 'type') &&
    ((value as any).type === ITEM_TYPE || (value as any).type === PRODUCT_TYPE)
  )
}

export const isHeliosItem = (value: unknown): value is HeliosListItem => {
  return (
    !!value && Object.prototype.hasOwnProperty.call(value, 'item_version_id')
  )
}

export const isStore = (value: unknown): value is Store =>
  !!value && Object.prototype.hasOwnProperty.call(value, 'location_id')

export const isCase = (value: unknown): value is Case => {
  return !!value && Object.prototype.hasOwnProperty.call(value, 'case_number')
}

export const isShipNodeID = (
  value: unknown,
): value is SmsDistributionCenter => {
  return (
    !!value &&
    Object.prototype.hasOwnProperty.call(value, 'distribution_centers')
  )
}

export const isProductLogistics = (
  value: unknown,
): value is ProductLogistics => {
  return (
    !!value &&
    Object.prototype.hasOwnProperty.call(value, 'seller_id') &&
    Object.prototype.hasOwnProperty.call(value, 'product_id')
  )
}

export const isProductLogisticsError = (
  value: unknown,
): value is ProductLogisticsError => {
  return !!value && Object.prototype.hasOwnProperty.call(value, 'errors')
}

export const isSortEvent = (params: any): params is EnhancedTableFieldType =>
  params &&
  typeof params.key === 'string' &&
  {}.hasOwnProperty.call(params, 'hasSort')

export const isHttpError = (res: unknown): res is HttpError =>
  !!res &&
  typeof res === 'object' &&
  typeof (res as any).response === 'undefined'

export const isRelationshipType = (
  relationshipType: unknown,
): relationshipType is RelationshipType => {
  const relationships = Object.keys(RelationshipType)

  if (typeof relationshipType === 'string') {
    return Object.keys(RelationshipType).some((el) => el === relationshipType)
  } else if (Array.isArray(relationshipType) && relationshipType.length > 0) {
    return relationshipType.every((el) => relationships.indexOf(el) !== -1)
  } else {
    return false
  }
}

export const isDivision = (value: unknown): value is Division => {
  return !!value && Object.prototype.hasOwnProperty.call(value, 'division_id')
}

export const isReturnsResearch = (value: unknown): value is ReturnsResearch => {
  return (
    !!value &&
    Object.prototype.hasOwnProperty.call(value, 'message_type') &&
    Object.prototype.hasOwnProperty.call(value, 'store_reference_field')
  )
}

export const isPromotion = (value: unknown): value is Promotion => {
  return !!value && Object.prototype.hasOwnProperty.call(value, 'promotion_id')
}

export const isReviewQueueSearchParams = (
  value: unknown,
): value is ReviewQueueSearchParams => {
  return (
    !!value &&
    Object.prototype.hasOwnProperty.call(value, 'page') &&
    Object.prototype.hasOwnProperty.call(value, 'perPage')
  )
}
