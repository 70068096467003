import { useState } from 'react'

import Button from '@mui/material/Button'

import LinkIcon from '@mui/icons-material/Link'

export interface Props {
  link: string
  title: string
  successTitle: string
  variant?: 'text' | 'outlined' | 'contained'
}

export const CopyLinkButton = ({
  link,
  title,
  successTitle,
  variant,
}: Props) => {
  const [buttonTitle, setButtonTitle] = useState(title)

  const handleClick = () => {
    navigator.clipboard.writeText(link).then(() => {
      setButtonTitle(successTitle)
    })
  }

  return (
    <Button
      data-testid="copy-link-button"
      startIcon={<LinkIcon />}
      color="primary"
      variant={variant ?? 'outlined'}
      onClick={handleClick}
    >
      {buttonTitle}
    </Button>
  )
}

export default CopyLinkButton
