import React from 'react'
import styled from '@emotion/styled'
import CheckCircle from '@mui/icons-material/CheckCircle'

import DialogContainer from 'components/common/Dialog/DialogContainer'

import { success } from 'config/themeConfig'

import useInterval from 'hooks/useInterval'

const StyledContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const StyledCheckCircleIcon = styled(CheckCircle)(({ theme }) => ({
  color: success.main,
  marginRight: theme.spacing(1),
}))

export const SuspendedSuccessDialog = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(true)

  useInterval(() => setIsOpen(false), 5000)

  return (
    <DialogContainer
      data-testid="suspended-success-dialog"
      isOpen={isOpen}
      maxWidth="md"
      title={
        <StyledContainer>
          <StyledCheckCircleIcon />
          SUSPENDED! NEXT SELECT BARCODES TO BE RELEASED...
        </StyledContainer>
      }
      hideActions
    >
      <div id="confirm-dialog-description">
        Items in your list that have been suspended will be shown next.
      </div>
    </DialogContainer>
  )
}

export default SuspendedSuccessDialog
