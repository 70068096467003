import WarningIcon from 'components/common/WarningIcon'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Typography from '@mui/material/Typography'
import styled from '@emotion/styled'
import { success } from 'config/themeConfig'

const StyledFlexContainer = styled('span')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
}))

const StyledTitleText = styled('span')(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}))

const StyledCheckCircleIcon = styled(CheckCircleIcon)({
  color: success.main,
})

export const RejectReleaseBarcodesTitle = ({
  total,
  errored,
  success,
  isPending,
}: {
  total: number
  errored: number
  success: boolean
  isPending: boolean
}) => {
  if (isPending) {
    return <div>REQUESTING REJECTION AND RELEASE...</div>
  }

  if (success) {
    if (errored > 0) {
      return (
        <StyledFlexContainer>
          <WarningIcon>
            <Typography>
              {errored} OUT OF {total} BARCODE(S) COULD NOT BE REJECTED &
              RELEASED
            </Typography>
          </WarningIcon>
        </StyledFlexContainer>
      )
    } else {
      return (
        <StyledFlexContainer>
          <StyledCheckCircleIcon />
          <StyledTitleText>
            SUCCESS! {total} ITEM(S) REJECTED & BARCODE(S) RELEASED
          </StyledTitleText>
        </StyledFlexContainer>
      )
    }
  }

  return <div>CONFIRM REJECT & RELEASE OF BARCODE(S)</div>
}

export default RejectReleaseBarcodesTitle
