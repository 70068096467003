import { useEffect, useState } from 'react'
import styled from '@emotion/styled'

import FormControl from '@mui/material/FormControl'
import Select, { SelectProps, SelectChangeEvent } from '@mui/material/Select'
import { MenuItem, Checkbox, ListItemText } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import DoneIcon from '@mui/icons-material/Done'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import CancelIcon from '@mui/icons-material/Cancel'
import IconButton from '@mui/material/IconButton'

import uniq from 'lodash/fp/uniq'

import Text from 'components/common/Text'

import { white, filterChip } from 'config/themeConfig'

import { SelectOption } from 'types/SelectOption'

type ValueProp = string | string[] | undefined

export type Props = {
  label?: string
  id?: string
  placeholder: string
  data: SelectOption[]
  value: ValueProp
  searchParam: string
  onChange: (params: Dictionary<string | string[] | undefined>) => void
}

const checkValue = (value: ValueProp) => !!(value && value.length)

const StyledLabel = styled(Text)((props) => ({
  marginBottom: props.theme.spacing(1),
}))

const StyledSelect = styled(Select)<SelectProps<ValueProp>>(
  (props) => ({
    backgroundColor: white.main,
    color: props.theme.palette.grey[700],
    '& .MuiSelect-select': {
      padding: props.theme.spacing(1, 2, 1, 6),
      '&:focus': {
        backgroundColor: 'initial',
      },
    },
    '& .MuiSelect-icon': {
      right: 'initial',
      left: props.theme.spacing(2),
    },
    '& .MuiSelect-iconOpen': {
      transform: 'initial',
    },
  }),
  (props) => ({
    backgroundColor: checkValue(props.value) ? filterChip.main : undefined,
    color: checkValue(props.value) ? props.theme.palette.grey[900] : undefined,
  }),
)

const getTitle = (values: string[], data: SelectOption[]) => {
  let displayValue: any

  if (!displayValue) {
    data.forEach((option) => {
      if (option.children) {
        option.children.forEach((subOption) => {
          if (subOption.value === values[0]) {
            displayValue = subOption
            return
          }
        })
      }
      if (!option.children && option.value === values[0]) {
        displayValue = option
      }
    })
  }

  const name = displayValue ? displayValue.name : 'Name not found'

  const adornment = values.length > 1 ? ` +${values.length - 1}` : ``

  return `${name}${adornment}`
}

export const MultiSelectFilter: React.FC<Props> = ({
  label,
  id,
  placeholder,
  data,
  value,
  searchParam,
  onChange,
}) => {
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<string[]>([])

  const hasValue = checkValue(value)

  useEffect(() => {
    let val: ValueProp

    if (!value) {
      val = []
    } else if (Array.isArray(value)) {
      val = value
    } else {
      val = [value]
    }

    setSelected(val)
  }, [value])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value
    if (Array.isArray(value)) {
      const items = value.filter(
        (item: string | undefined) => item !== undefined,
      )
      onChange({ [searchParam]: items })
    }
  }

  const handleClear = () => {
    onChange({ [searchParam]: undefined })
  }

  let iconComponent
  if (hasValue) {
    if (Array.isArray(value) && value.length > 1) {
      iconComponent = DoneAllIcon
    } else {
      iconComponent = DoneIcon
    }
  } else {
    iconComponent = ArrowDropDownIcon
  }

  const handleCategoryClick = (children: any) => {
    const options = children?.map((option: any) => option.value)
    let filteredItems: string[]

    const isCategorySelected = options?.every((item: any) =>
      selected.includes(item),
    )
    if (isCategorySelected) {
      filteredItems = selected.filter(
        (option: any) => !options?.includes(option),
      )
    } else {
      filteredItems = uniq([...selected, ...options]) ?? []
    }

    setSelected(filteredItems)
    onChange({ [searchParam]: filteredItems })
  }

  const getSelectMenuItems = (data: any) => {
    const result: any = []
    data.forEach(({ name, value, children, disabled }: SelectOption) => {
      if (children) {
        const isCategoryChecked = children.every(
          (option) => selected.indexOf(option.value) > -1,
        )
        const isIndeterminate =
          children.some((option) => selected.indexOf(option.value) > -1) &&
          !children.every((option) => selected.indexOf(option.value) > -1)

        result.push(
          <MenuItem
            selected={isCategoryChecked}
            onClick={() => handleCategoryClick(children)}
            key={name}
          >
            <Checkbox
              checked={isCategoryChecked}
              indeterminate={isIndeterminate}
            />
            <ListItemText primary={name} />
          </MenuItem>,
        )

        children.forEach(({ name, value }: SelectOption) => {
          result.push(
            <MenuItem key={name} value={value} sx={{ paddingLeft: 4 }}>
              <Checkbox
                checked={value ? selected.indexOf(value) > -1 : false}
              />
              <ListItemText primary={name} />
            </MenuItem>,
          )
        })
      } else {
        result.push(
          <MenuItem key={name} value={value} disabled={disabled ?? false}>
            <Checkbox checked={value ? selected.indexOf(value) > -1 : false} />
            <ListItemText primary={name} />
          </MenuItem>,
        )
      }
    })

    return result
  }

  const endAdornment = hasValue ? (
    <IconButton
      onClick={handleClear}
      data-testid="remove-filter"
      aria-label={`Remove ${placeholder} Filter`}
      size="large"
    >
      <CancelIcon color="primary" />
    </IconButton>
  ) : undefined

  return (
    <div>
      {label && <StyledLabel type="bold">{label}</StyledLabel>}
      <FormControl fullWidth>
        <StyledSelect
          multiple
          displayEmpty
          id={id}
          variant="outlined"
          placeholder={placeholder}
          open={open}
          value={selected}
          onOpen={handleOpen}
          onClose={handleClose}
          onChange={handleChange}
          data-testid={placeholder}
          inputProps={{
            'aria-label':
              selected.length > 0
                ? `${placeholder}: ${selected}`
                : `${placeholder}: none selected`,
          }}
          endAdornment={endAdornment}
          IconComponent={iconComponent}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
          renderValue={() => {
            if (selected.length === 0) {
              return placeholder
            }

            return getTitle(selected, data)
          }}
        >
          {getSelectMenuItems(data)}
        </StyledSelect>
      </FormControl>
    </div>
  )
}

export default MultiSelectFilter
