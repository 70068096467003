import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import { PAGINATION_TYPE } from 'components/common/EnhancedTable/EnhancedTablePagination'
import FilterBar from 'components/common/FilterBar'
import DateRangeFilter from 'components/common/FilterBar/DateRangeFilter'
import {
  useSearchParams,
  TableState,
} from 'components/common/FilterBar/useSearchParams'
import {
  formatDateMDYT,
  formatAsCurrency,
} from 'components/common/EnhancedTable/formatters'
import HeaderTitle from 'components/common/HeaderTitle'
import LabeledDataList from 'components/common/LabeledDataList'
import Link from 'components/common/Link'
import TableSpacer from 'components/common/TableSpacer'
import { DialogEnum } from 'components/common/Dialog'

import styled from '@emotion/styled'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { isUserRole, isUserRoleProductAdmin } from 'services/authorization'
import {
  parseDateRange,
  getDateBefore,
  formatDateRange,
  isoStringStartOfDay,
  isoStringEndOfDay,
  differenceInDays,
  formatDate,
  DATE_DISPLAY_FORMAT,
} from 'services/dateService'
import { RoutePath } from 'services/NavigationHelper'
import {
  getPaymentAccounts,
  STRIPE_TRANSFER_PAGE_SIZE,
} from 'services/payments'
import { getPayouts } from 'services/payouts'
import { GenerateReportRequest, submitReport } from 'services/reports'
import { USER_ROLE_APP_SMS_READ, USER_ROLE_APP_SMS_ADMIN } from 'services/roles'
import formatCurrency from 'services/formatCurrency'

import { openDialog } from 'store/dialog/actionCreator'
import {
  currentSellerId,
  getMemberOf,
  isDefunctSellerStatus,
  hasStripeAccountId,
  getIsRegistrationPending,
} from 'store/selectors'

import { Payout } from 'types/Payout'
import { ReportType } from 'types/Report'

import StripeAccountContent from './StripeAccountContent'

const StyledTableHeader = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  display: 'inline-flex',
  alignItems: 'center',
}))

type SearchParams = TableState & {
  payout_start_date: string | undefined
  payout_end_date: string | undefined
}

const initialPayoutStartDate = formatDate(
  getDateBefore(new Date(), 12, 'weeks'),
  DATE_DISPLAY_FORMAT,
)

const initialSearchParams: SearchParams = {
  perPage: 30,
  page: 0,
  payout_start_date: initialPayoutStartDate,
  payout_end_date: undefined,
}

const buildSearchParams = ({
  sellerId,
  payoutStartDate,
  payoutEndDate,
}: {
  sellerId: string | undefined
  payoutStartDate: string | undefined
  payoutEndDate: string | undefined
}) => {
  const payoutDateRange = formatDateRange(payoutStartDate, payoutEndDate)
  const payoutDate = payoutDateRange || undefined

  return {
    seller_id: sellerId,
    payout_date: payoutDate,
  }
}

const getPayoutsList = (
  sellerId: string,
  searchParams: SearchParams,
  resultsOffsetId?: string,
) => {
  const params = buildSearchParams({
    sellerId,
    payoutStartDate: searchParams.payout_start_date,
    payoutEndDate: searchParams.payout_end_date,
  })

  const [fromDate, toDate] = parseDateRange(params.payout_date)

  return getPayouts({
    sellerId,
    resultsOffsetId,
    fromDate,
    toDate,
  }).then((res) => res)
}

const isDateRangeGreater90Days = (
  startDate: string | undefined,
  endDate: string | undefined,
) => {
  const start = startDate ? new Date(startDate) : undefined
  const end = endDate ? new Date(endDate) : new Date()
  if (start && differenceInDays(start!, end) <= 90) {
    return false
  } else {
    return true
  }
}

export const PayoutsAndAccountPage = () => {
  const reduxDispatch = useDispatch()

  const sellerId = useSelector(currentSellerId)
  const isDefunct = useSelector(isDefunctSellerStatus)
  const hasStripeEnabled = useSelector(hasStripeAccountId)
  const isRegistrationPending = useSelector(getIsRegistrationPending)
  const memberOf = useSelector(getMemberOf)

  const isAuthorized = isUserRoleProductAdmin(memberOf)
  const isReadOnly = isUserRole(memberOf, USER_ROLE_APP_SMS_READ)
  const isExternalAdmin = isUserRole(memberOf, USER_ROLE_APP_SMS_ADMIN)

  const fieldList: EnhancedTableFieldType<Payout>[] = [
    {
      key: 'arrival_date',
      heading: 'Payout Date',
      formatCell: formatDateMDYT('arrival_date'),
    },
    {
      key: 'id',
      heading: 'Payout ID',
    },
    {
      key: 'payout_amount',
      heading: 'Payout Amount',
      formatCell: formatAsCurrency('payout_amount'),
    },
  ]
  const [searchParams, searchParamActions, appliedFilterCount] =
    useSearchParams<SearchParams>(initialSearchParams)

  const [pending, setPending] = React.useState(false)
  const [payouts, setPayouts] = React.useState<Payout[]>([])
  const [isPagingDisabled, setIsPagingDisabled] = React.useState(true)
  const [stripeBalance, setStripeBalance] = React.useState<string>()
  const [disableDownload, setDisableDownload] = React.useState(false)
  const [customDownloadTooltip, setCustomDownloadTooltip] = React.useState('')

  React.useEffect(() => {
    let mounted = true
    if (!isReadOnly && hasStripeEnabled && sellerId) {
      setPending(true)
      getPayoutsList(sellerId, searchParams).then((response) => {
        if (mounted) {
          setIsPagingDisabled(response.length < STRIPE_TRANSFER_PAGE_SIZE)
          setPending(false)
          setPayouts(response)
        }
      })
      getPaymentAccounts(sellerId).then((response) => {
        const balance = formatCurrency(response.balance, 'USD')
        if (mounted) {
          setStripeBalance(balance)
        }
      })
      const dateRangeTooBig = isDateRangeGreater90Days(
        searchParams.payout_start_date,
        searchParams.payout_end_date,
      )
      if (dateRangeTooBig) {
        setCustomDownloadTooltip(
          'Disabled for date range greater than 90 days.',
        )
      }
      setDisableDownload(dateRangeTooBig)
    }

    return () => {
      mounted = false
    }
  }, [
    sellerId,
    searchParams.payout_start_date,
    searchParams.payout_end_date,
    isReadOnly,
    hasStripeEnabled,
    searchParams,
  ])

  const getNextPage = async () => {
    if (isPagingDisabled) return

    const resultsOffsetId = payouts[payouts.length - 1].id

    getPayoutsList(sellerId!, searchParams, resultsOffsetId).then(
      (response) => {
        setPayouts(payouts.concat(response))
        setIsPagingDisabled(response.length < STRIPE_TRANSFER_PAGE_SIZE)
      },
    )
  }

  const handleFilterClear = () => {
    searchParamActions.updateSearchParam({
      payout_start_date: undefined,
      payout_end_date: undefined,
    })
  }

  const handleCreateReport =
    ({ type }: GenerateReportRequest) =>
    () => {
      const payoutStartDate = searchParams.payout_start_date
        ? isoStringStartOfDay(searchParams.payout_start_date)
        : undefined

      const payoutEndDate = searchParams.payout_end_date
        ? isoStringEndOfDay(searchParams.payout_end_date)
        : isoStringEndOfDay(new Date())

      return submitReport({
        type,
        sellerId,
        startDate: payoutStartDate,
        endDate: payoutEndDate,
      })
    }

  const handleDownload = () => {
    const params = {
      dialogEnum: DialogEnum.REPORT_DOWNLOAD_DIALOG,
      componentProps: {
        title: 'GENERATING REPORT...PLEASE WAIT',
        reportTitle: 'Payouts and Account',
        sellerId,
        createReport: handleCreateReport({
          type: ReportType.PAYOUT_RECONCILIATION,
        }),
      },
    }
    reduxDispatch(openDialog(params))
  }

  const labeledData = [{ label: 'Total Payouts:', data: payouts.length }]

  const title = 'Payouts & Account'

  if (isReadOnly) {
    return (
      <React.Fragment>
        <HeaderTitle title={title} />
        <Typography data-testid="non-admin-message">
          Payments and settings are managed in Stripe, but you must be an Admin
          to have access.
        </Typography>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <HeaderTitle title={title} />
      <Grid container spacing={2}>
        {!isDefunct && (
          <StripeAccountContent
            isExternalAdmin={isExternalAdmin}
            sellerId={sellerId}
            hasStripeEnabled={hasStripeEnabled}
            isRegistrationPending={isRegistrationPending}
            stripeBalance={stripeBalance}
          />
        )}
        <Grid item xs={12}>
          <StyledTableHeader>
            <LabeledDataList data={labeledData} />
            {isAuthorized && (
              <Link to={`/${sellerId}${RoutePath.REPORTS}`}>
                Access Reports
              </Link>
            )}
          </StyledTableHeader>
        </Grid>
        <Grid item xs={12}>
          <FilterBar
            onClear={handleFilterClear}
            appliedFilterCount={appliedFilterCount}
            onDownload={handleDownload}
            disableDownload={disableDownload}
            downloadTooltip={customDownloadTooltip}
          >
            <Grid item xs={12} lg={4}>
              <DateRangeFilter
                label="Payout Date"
                startValue={searchParams.payout_start_date}
                startSearchParam="payout_start_date"
                endValue={searchParams.payout_end_date}
                endSearchParam="payout_end_date"
                onChange={searchParamActions.updateSearchParam}
              />
            </Grid>
          </FilterBar>
          <TableSpacer>
            <EnhancedTable
              data={payouts}
              fieldList={fieldList}
              paginationType={PAGINATION_TYPE.LOAD_MORE}
              isLoading={pending}
              paginationDisabled={isPagingDisabled}
              nullText="No payouts"
              onChangePage={getNextPage}
            />
          </TableSpacer>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default PayoutsAndAccountPage
