import { CacheActions, UPDATE_ITEM_TYPES_CACHE } from './actionCreators'
import Attribute from 'types/Attribute'

export interface CacheState {
  itemTypes: Attribute[]
}

export const initialState = {
  itemTypes: [],
}

export default function cacheReducer(
  state: CacheState = initialState,
  action: CacheActions,
): CacheState {
  switch (action.type) {
    case UPDATE_ITEM_TYPES_CACHE: {
      return {
        ...state,
        itemTypes: [...state.itemTypes, ...action.payload],
      }
    }

    default:
      return state
  }
}
