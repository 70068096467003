import { useDispatch } from 'react-redux'

import mapValues from 'lodash/fp/mapValues'
import castArray from 'lodash/fp/castArray'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import { useUserSettingsContext } from 'components/context/UserSettingsProvider'

import { useUpdateSavedSearchMutation } from 'hooks/useUserSettingsQuery'

import { closeDialog, openDialog } from 'store/dialog/actionCreator'
import { showNotification } from 'store/notification/reducer'
import { DialogEnum } from '.'

interface Props {
  isOpen: boolean
  handleClose: () => void
  handleNext: () => void
  selectedFilters?: Object
  page: string
  filterName: string
}

export const UpdateSavedSearchDialog = ({
  isOpen,
  handleClose,
  selectedFilters,
  page,
  filterName,
}: Props) => {
  const dispatch = useDispatch()
  const { updateSavedSearches } = useUpdateSavedSearchMutation()
  const { userSettings, updateSelectedSavedSearch } = useUserSettingsContext()
  const findSavedSearchByName = (name: string) => {
    const searches = userSettings.data?.settings?.[`${page}_saved_searches`]
    let savedSearchKey = ''
    for (const key in searches) {
      if (searches[key].saved_search_name[0] === name) {
        savedSearchKey = key
      }
    }
    return savedSearchKey
  }

  const handleNext = () => {
    dispatch(closeDialog())
    dispatch(
      openDialog({
        dialogEnum: DialogEnum.SAVED_SEARCH_DIALOG,
        componentProps: {
          selectedFilters,
          page,
          filterName,
        },
      }),
    )
  }

  const handleSubmit = () => {
    const savedSearchKey = findSavedSearchByName(filterName)
    const updatedSettings = {
      ...userSettings.data,
      settings: {
        [`${page}_saved_searches`]: {
          ...(userSettings.data?.settings?.[`${page}_saved_searches`] || {}),
          [savedSearchKey]: {
            ...mapValues(castArray, {
              ...selectedFilters,
            }),
          },
        },
      },
    }
    updateSelectedSavedSearch(selectedFilters, page)
    updateSavedSearches({ userSettings: updatedSettings })
    dispatch(closeDialog())
    dispatch(
      showNotification({
        isShown: true,
        message: `${window.atob(filterName)} has been successfully updated.`,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        severity: 'success',
        autoClose: true,
      }),
    )
  }

  return (
    <DialogContainer
      title="Update Saved Filters"
      subtitle={`Would you like to update "${window.atob(
        filterName,
      )}" to the new filters you have selected or would you like to create a new saved filter?`}
      isOpen={isOpen}
      onSubmit={handleSubmit}
      onNext={handleNext}
      closeOverride={handleClose}
      submitButtonText="Update Filter"
      nextButtonText="Save As New"
      isSubmitDisabled={!filterName}
      flexboxRight={2}
    />
  )
}

export default UpdateSavedSearchDialog
