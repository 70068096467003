import axios from 'axios'

import apiConfig from 'config/apiConfig'
import { RatingsAndReviewsSummary } from 'types/RatingsAndReviews'

export function getRatingsAndReviewsSummary(
  sellerId: string,
): Promise<RatingsAndReviewsSummary> {
  return axios
    .get(
      `${apiConfig.securityGateway}/sellers/${sellerId}/ratings_and_reviews_summary`,
    )
    .then((res) => res.data)
}
