import {
  ItemReviewActions,
  FETCH_NEXT_ITEM_DONE,
  SET_ITEM_REVIEW_PENDING,
  SET_PARENT,
  SET_ITEM_PROCESSED,
  RESET_QUEUE,
  SetItemReviewPendingAction,
  FetchNextItemDoneAction,
  SetItemProcessedAction,
  SetParentAction,
} from 'store/itemReview/actionCreators'
import { ProductReviewData, RelationshipType } from 'types/Item'

export interface ItemReviewState {
  readonly isItemReviewPending: boolean
  readonly productReviewData?: ProductReviewData
  readonly parentId?: string
  readonly processedItems: string[]
  readonly skippedParents: string[]
  readonly reviewComplete: boolean
}

export const initialState: ItemReviewState = {
  isItemReviewPending: false,
  productReviewData: undefined,
  processedItems: [],
  skippedParents: [],
  reviewComplete: false,
}

export default function itemReviewReducer(
  state: ItemReviewState = initialState,
  action: ItemReviewActions,
) {
  switch (action.type) {
    case SET_ITEM_REVIEW_PENDING: {
      const { payload } = action as SetItemReviewPendingAction
      return {
        ...state,
        isItemReviewPending: payload,
      }
    }

    case FETCH_NEXT_ITEM_DONE: {
      const { payload } = action as FetchNextItemDoneAction
      const { productReviewData } = payload
      return {
        ...state,
        productReviewData,
        reviewComplete: !productReviewData,
      }
    }

    case SET_ITEM_PROCESSED: {
      const { payload } = action as SetItemProcessedAction
      const { product, skip } = payload
      const { processedItems } = state

      let parentId =
        product.relationship_type === RelationshipType.SA
          ? undefined
          : state.parentId
      if (product.relationship_type === RelationshipType.VAP) {
        parentId = skip ? undefined : product.product_id
      }

      const skippedParents =
        skip && product.relationship_type === RelationshipType.VAP
          ? [...state.skippedParents, product.product_id]
          : [...state.skippedParents]
      return {
        ...state,
        processedItems: [...processedItems, product.product_id],
        skippedParents,
        parentId,
      }
    }

    case SET_PARENT: {
      const { payload } = action as SetParentAction

      return {
        ...state,
        parentId: payload,
      }
    }

    case RESET_QUEUE: {
      return {
        ...initialState,
      }
    }

    default:
      return state
  }
}
