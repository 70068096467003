import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'

import DialogContainer from 'components/common/Dialog/DialogContainer'

import { timezoneCode } from 'services/timezoneCode'

import { isSellersPending } from 'store/selectors'
import { editSellerDistributionCenter } from 'store/seller/actionCreators'

import { DaysOfOperation, SmsDistributionCenter } from 'types/Seller'

import EditDatesClosed from '../EditDatesClosed'

const StyledCalendarContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(25),
}))

interface Props {
  isOpen: boolean
  distributionCenter: Partial<SmsDistributionCenter>
}

const EditDatesClosedDialog = ({ distributionCenter, isOpen }: Props) => {
  const dispatch = useDispatch()

  const isPending = useSelector(isSellersPending)

  const [daysClosed, setDaysClosed] = useState<string[]>(
    distributionCenter?.days_of_operation?.days_closed ?? [],
  )

  const handleDatesClosedChange = (value: string, remove: boolean) => {
    let closed

    if (remove) {
      closed = daysClosed.filter((i) => i !== value)
    } else {
      closed = [...daysClosed, value]
    }

    setDaysClosed(Array.from(new Set(closed)))
  }

  const handleSubmit = () => {
    const validDaysOfOperation = {
      ...distributionCenter.days_of_operation,
      days_closed: daysClosed,
    } as DaysOfOperation

    const updatedDistributionCenter = {
      ...distributionCenter,
      days_of_operation: validDaysOfOperation,
    }

    dispatch(editSellerDistributionCenter(updatedDistributionCenter))
  }

  return (
    <DialogContainer
      title="Edit Dates Closed"
      isOpen={isOpen}
      isPending={isPending}
      onSubmit={handleSubmit}
      subtitle={`Distribution Center Time Zone: ${timezoneCode(
        distributionCenter.timezone,
      )}`}
    >
      <StyledCalendarContainer>
        <EditDatesClosed
          onChange={handleDatesClosedChange}
          isDisabled={isPending}
          daysClosed={daysClosed}
        />
      </StyledCalendarContainer>
    </DialogContainer>
  )
}

export default EditDatesClosedDialog
