// User Settings Groups
// Used for creating categorized settings specific to a user via the /user_settings endpoint - saved searches, etc.

// The FilterBar component ((src/components/common/FilterBar/index.tsx) retrieves the page specific user_settings with these constants.
// The constants must match the pathname of the page. (ex: review-queue, all-orders, onboarding, internal-reports etc.)
export const REVIEW_QUEUE = 'review_queue'

// Used to match the pathname with the user_settings page specific key
export const getCurrentPage = (pathname: string) => {
  return pathname.replace(/\//g, '').replace(/-/g, '_')
}
