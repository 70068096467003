import HeaderTitle from 'components/common/HeaderTitle'
import { StateProvider } from 'stateManager/StateProvider'
import ReportsTable from './ReportsTable'
import UnlistItemsCards from './UnlistItemsCards'
import UnlistItemsDescription from './UnlistItemsDescription'

export const UnlistItemsPage = () => {
  return (
    <StateProvider>
      <div data-testid="unlist-items">
        <HeaderTitle title="Unlist Items" />
        <div>
          <UnlistItemsDescription />
          <UnlistItemsCards />
          <ReportsTable />
        </div>
      </div>
    </StateProvider>
  )
}

export default UnlistItemsPage
