import styled from '@emotion/styled'
import { Divider } from '@mui/material'

import DateInput from 'components/common/DateInput'
import Text from 'components/common/Text'

import {
  DATE_DISPLAY_FORMAT,
  formatDateLocalTime,
  parseDate,
} from 'services/dateService'

const StyledRoot = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
})

const StyledLabel = styled(Text)(({ theme }) => ({
  textAlign: 'left',
  marginBottom: theme.spacing(1),
}))

const StyledInputContainer = styled('div')({
  display: 'flex',
})

const StyledDivider = styled(Divider)(({ theme }) => ({
  width: 1,
  height: 31,
  backgroundColor: theme.palette.grey[300],
}))

export type Props = {
  label: string
  startValue: string | undefined
  startSearchParam: string
  endValue: string | undefined
  endSearchParam: string
  onChange: (params: Dictionary<string | string[] | undefined>) => void
  isDisabled?: boolean
}
export const DateRangeFilter = ({
  label,
  startValue,
  startSearchParam,
  endValue,
  endSearchParam,
  onChange,
  isDisabled,
}: Props) => {
  const startDate = parseDate(startValue)
  const endDate = parseDate(endValue)

  const handleChange = (param: string) => (date: Date | undefined) => {
    if (!date) {
      return // exit early so users can use a keyboard
    }

    const value = date
      ? formatDateLocalTime(date, DATE_DISPLAY_FORMAT)
      : undefined

    onChange({ [param]: value })
  }

  const handleDelete = (param: string) => () => {
    onChange({ [param]: undefined })
  }

  const selectedDays =
    startDate && endDate ? [{ from: startDate, to: endDate }] : undefined

  const startDisabledDays = endDate ? { disabled: { after: endDate } } : {}

  const endDisabledDays = startDate ? { disabled: { before: startDate } } : {}

  return (
    <>
      <StyledLabel type="bold">{label}</StyledLabel>
      <StyledRoot>
        <StyledInputContainer>
          <DateInput
            value={startDate}
            placeholder="Start Date"
            placement="start"
            onChange={handleChange(startSearchParam)}
            onDelete={handleDelete(startSearchParam)}
            dayPickerProps={{
              ...startDisabledDays,
              toMonth: endDate,
              numberOfMonths: 2,
              selected: selectedDays,
            }}
            isDisabled={isDisabled}
          />

          <StyledDivider></StyledDivider>

          <DateInput
            value={endDate}
            placeholder="End Date"
            placement="end"
            onChange={handleChange(endSearchParam)}
            onDelete={handleDelete(endSearchParam)}
            dayPickerProps={{
              ...endDisabledDays,
              month: startDate,
              fromMonth: startDate,
              numberOfMonths: 2,
              selected: selectedDays,
            }}
            isDisabled={isDisabled}
          />
        </StyledInputContainer>
      </StyledRoot>
    </>
  )
}

export default DateRangeFilter
