import styled from '@emotion/styled'

import { AppBar, Button, IconButton, Toolbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'

import { primary, white } from 'config/themeConfig'

const StyledCloseIcon = styled(CloseIcon)({
  color: white.main,
  '&:hover': {
    color: primary['main'],
  },
})

const StyledButton = styled(Button)({
  color: white.main,
  '&:hover': {
    color: primary['main'],
  },
})

export interface Props {
  message: string
  handleDelete: () => void
  handleClose: () => void
}

export const ActionBar = ({ message, handleDelete, handleClose }: Props) => {
  return (
    <AppBar
      position="fixed"
      color="primary"
      sx={{ top: 'auto', bottom: 0, margin: 0 }}
      data-testid="action-bar"
    >
      <Toolbar>
        <IconButton
          onClick={handleClose}
          sx={{ marginRight: 2 }}
          color="success"
          aria-label="close action bar"
          data-testid="action-bar-close-btn"
        >
          <StyledCloseIcon />
        </IconButton>
        <Typography
          sx={{ paddingLeft: 1, marginRight: 4 }}
          fontSize={16}
          fontWeight={700}
        >
          {message}
        </Typography>
        <StyledButton
          color="inherit"
          onClick={handleDelete}
          data-testid="action-bar-delete-btn"
        >
          <DeleteIcon />
          <Typography sx={{ paddingLeft: 1 }}>DELETE</Typography>
        </StyledButton>
        <IconButton color="inherit"></IconButton>
      </Toolbar>
    </AppBar>
  )
}
