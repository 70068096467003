import axios, { AxiosResponse } from 'axios'
import chunk from 'lodash/fp/chunk'
import find from 'lodash/fp/find'
import flatten from 'lodash/fp/flatten'
import unionBy from 'lodash/fp/unionBy'

import apiConfig from 'config/apiConfig'
import { getPageable, PagingParams } from 'services/pageableHelper'

import Attribute, {
  defaultNode,
  ITEM_TYPE,
  ITEM_SUBTYPE,
  MERCH_TYPE,
} from 'types/Attribute'
import {
  CONTAINS,
  STARTS_WITH,
  TARGET_EXTERNAL,
  TARGET_INTERNAL,
  TaxonomySearchParams,
} from 'types/TaxonomySearchParams'
import { TaxonomyAssignment } from 'types/TaxonomyAssignment'

import {
  getItemTypesCache,
  updateItemTypesCache,
  getItemTypeIdGroupsFromCache,
} from './cacheHelper'
import ItemAttribute from 'types/ItemAttribute'
import { AttributeValue } from 'types/AttributeValue'
import { CollectionResponse } from 'types/Response'
import { AttributeValueSearchParams } from 'types/AttributeValueSearchParams'

export async function getNodesById(nodeIds: string[]): Promise<Attribute[]> {
  // the DEFAULT node is representative of all non-defined nodes in Nexus
  // and is something that doesn't exist outside of SMS (so don't request it from Nexus)
  const nodes = nodeIds.filter((id) => id !== 'DEFAULT')
  const hasDefault = nodeIds.length > nodes.length

  if (nodes.length === 0) {
    return hasDefault ? Promise.resolve([defaultNode]) : Promise.resolve([])
  }

  const itemTypesCache = getItemTypesCache()
  const { uncachedIds, cachedIds } = getItemTypeIdGroupsFromCache(
    nodes,
    itemTypesCache,
  )

  const promises = chunk(200, uncachedIds).map((nodeChunk) => {
    return getNodesByIdSafe(nodeChunk)
  })

  if (hasDefault) {
    promises.unshift(Promise.resolve([defaultNode]))
  }

  if (cachedIds && cachedIds.length > 0) {
    promises.unshift(
      Promise.resolve(
        itemTypesCache.filter((type) => cachedIds.includes(type.id)),
      ),
    )
  }

  const results = await Promise.all(promises)

  return flatten(results)
}

export async function getNodeById(id: string): Promise<Attribute | undefined> {
  const config = {
    params: {},
    ignoredStatuses: [{ status: 404 }],
  }

  try {
    const response = await axios.get(
      `${apiConfig.nexus}/taxonomies/${id}`,
      config,
    )

    return response.data
  } catch (err) {
    console.error(`failed to get node ${id}: ${err}`)
  }
}

async function getNodesByIdSafe(nodes: string[]): Promise<Attribute[]> {
  const config = {
    params: {},
    ignoredStatuses: [{ status: 404 }],
  }

  let attributes: Attribute[] = []
  try {
    const responses: AxiosResponse<Attribute>[] = await Promise.all(
      nodes.map((node) =>
        axios.get(`${apiConfig.nexus}/taxonomies/${node}`, config),
      ),
    )
    attributes = responses
      .filter((response) => response.status !== 404)
      .map((response) => response.data)

    const missingNodes = getMissingNodes(responses, nodes)

    if (missingNodes.length) {
      const missingNodesConfig = {
        searchType: missingNodes[0].type!,
        status: 'DELETE',
        ids: missingNodes.map((node) => {
          return node.id
        }),
        perPage: 100,
      }

      const deleteStatusNodes = await searchItemTaxonomies(missingNodesConfig, {
        hasCancel: false,
      })

      const invalidNodes = missingNodes.map((missingNode) => {
        const deleteStatusNode = find(
          (node) => node.id === missingNode.id,
          deleteStatusNodes,
        )

        return {
          id: missingNode.id,
          name: `item type "${
            deleteStatusNode ? deleteStatusNode.name : missingNode.id
          }" is no longer valid`,
          type: deleteStatusNode ? deleteStatusNode.type : missingNode.type,
        }
      })

      attributes = [...attributes, ...invalidNodes]
    }

    updateItemTypesCache(attributes)
  } catch (e) {
    // Nexus failed so create a list of item type attributes
    // with the node id as the values

    attributes = nodes.map((node) => ({
      id: node,
      name: node,
    }))
  }

  return attributes
}

export const getMissingNodes = (
  items: AxiosResponse<Attribute>[],
  nodes: string[],
): Attribute[] => {
  const missingNodes: Attribute[] = []

  items.forEach((item, index) => {
    if (item.status === 404) {
      const node = {
        id: nodes[index],
        type: item.data.type ? item.data.type : ITEM_TYPE,
        deprecated: true,
      }

      missingNodes.push(node)
    }
  })

  return missingNodes
}

export const getTaxonomySearchTarget = (
  type: typeof ITEM_SUBTYPE | typeof ITEM_TYPE | typeof MERCH_TYPE,
) => {
  if (type === ITEM_TYPE) {
    return TARGET_EXTERNAL
  } else if (type === ITEM_SUBTYPE) {
    return [TARGET_EXTERNAL, TARGET_INTERNAL].join(',')
  } else if (type === MERCH_TYPE) {
    return TARGET_INTERNAL
  }
}

// Setup cancel token to cancel previous request if it is not complete
const CancelToken = axios.CancelToken
let cancel: any

export function searchItemTaxonomies(
  searchParams: TaxonomySearchParams,
  options: {
    hasCancel: boolean
  },
): Promise<Attribute[]> {
  if (options.hasCancel) {
    if (cancel) {
      cancel()
    }
  }
  const { name, nameSearchType, searchType, target, status, perPage } =
    searchParams
  const config = {
    params: {
      name: name,
      name_search_type: nameSearchType,
      per_page: perPage ? perPage : searchType === 'ITEM_TYPE' ? 15 : 5,
      type: searchType.toLowerCase(),
      target,
      status: status ?? 'ACTIVE',
    },
    cancelToken: new CancelToken(function executor(c) {
      cancel = c
    }),
  }

  return axios
    .get(`${apiConfig.nexus}/taxonomies`, config)
    .then((res) => {
      if (searchType === 'ITEM_TYPE') {
        return res.data
      }
      return res.data
    })
    .catch(() => {
      return []
    })
}

export const searchAcrossItemTaxonomyBy = async ({
  name,
  type,
}: {
  name: string
  type: typeof ITEM_SUBTYPE | typeof ITEM_TYPE | typeof MERCH_TYPE
}): Promise<Attribute[]> => {
  const options = {
    hasCancel: false,
  }

  const [startsWithResult, containsResult] = await Promise.all([
    searchItemTaxonomies(
      {
        name,
        searchType: type,
        target: getTaxonomySearchTarget(type),
        nameSearchType: STARTS_WITH,
      },
      options,
    ),
    searchItemTaxonomies(
      {
        name,
        searchType: type,
        target: getTaxonomySearchTarget(type),
        nameSearchType: CONTAINS,
      },
      options,
    ),
  ])

  const data = unionBy('id', startsWithResult, containsResult)

  return data
}

type TaxonomyAssignmentParams = {
  itemTypeId: string
  attributeGroup?: string[]
}
export function getTaxonomyAssignments(
  {
    itemTypeId,
    attributeGroup = ['core', 'price', 'selling', 'tax'],
  }: TaxonomyAssignmentParams,
  pagingParams: PagingParams,
): Promise<TaxonomyAssignment[]> {
  const pageable = getPageable(pagingParams)
  const config = {
    params: {
      attribute_group: attributeGroup.join(','),
      target: TARGET_EXTERNAL,
      taxonomy_id: itemTypeId,
      ...pageable,
    },
  }

  return axios
    .get(`${apiConfig.nexus}/assignments`, config)
    .then((res): TaxonomyAssignment[] => res.data)
    .catch(() => {
      return []
    })
}

export async function getTaxonomyAssignmentAttributes(itemTypeId: string) {
  const assignment = await getTaxonomyAssignments(
    { itemTypeId, attributeGroup: ['SELLING'] },
    { page: 0, perPage: 200 },
  )
  const attributes = assignment.map((item) => item.attribute)
  return attributes
}

export function getTaxonomyAssignmentByItemTypeIdAndAttributeId(
  itemTypeId: string,
  attributeId: string,
): Promise<TaxonomyAssignment[]> {
  const config = {
    params: {
      target: TARGET_EXTERNAL,
      taxonomy_id: itemTypeId,
      attribute_id: attributeId,
    },
  }

  return axios
    .get(`${apiConfig.nexus}/assignments`, config)
    .then((resp) => resp.data)
}

export function getAttributeValues(
  pagingParams: PagingParams,
  itemTypeId: string,
  attributeId: string,
) {
  const pageable = pagingParams ? getPageable(pagingParams) : undefined
  const config = {
    params: {
      target: TARGET_EXTERNAL,
      taxonomy_id: itemTypeId,
      attribute_id: attributeId,
      ...pageable,
    },
  }
  return axios
    .get(`${apiConfig.nexus}/attribute_values`, config)
    .then((res): CollectionResponse<AttributeValue> => {
      const { data, headers } = res
      const total = headers['x-total-count']
        ? parseInt(headers['x-total-count'], 10)
        : 0

      return {
        total,
        data,
      }
    })
}

export function searchAttributeValues(
  pagingParams: PagingParams,
  searchParams: AttributeValueSearchParams,
  options: {
    hasCancel: boolean
  },
) {
  if (options.hasCancel) {
    if (cancel) {
      cancel()
    }
  }
  const { attributeId, itemTypeId, nameSearchType, searchVal } = searchParams
  const pageable = getPageable(pagingParams)

  const config = {
    params: {
      taxonomy_id: itemTypeId,
      attribute_id: attributeId,
      target: TARGET_EXTERNAL,
      name_search_type: nameSearchType,
      name: searchVal,
      ...pageable,
    },
    CancelToken: new CancelToken(function executor(c) {
      cancel = c
    }),
  }

  return axios
    .get(`${apiConfig.nexus}/attribute_values`, config)
    .then((res): CollectionResponse<AttributeValue> => {
      const { data, headers } = res
      const total = headers['x-total-count']
        ? parseInt(headers['x-total-count'], 10)
        : 0

      return {
        data,
        total,
      }
    })
}

export async function getFilteredAttributeValues(
  pagingParams: PagingParams,
  searchParams: AttributeValueSearchParams,
): Promise<CollectionResponse<AttributeValue>> {
  const res = await searchAttributeValues(
    pagingParams,
    {
      attributeId: searchParams.attributeId,
      itemTypeId: searchParams.itemTypeId,
      searchVal: searchParams.searchVal,
      nameSearchType: searchParams.nameSearchType,
    },
    { hasCancel: false },
  )

  return { data: res.data, total: res.total }
}

export function getItemAttributeById(id: string): Promise<ItemAttribute> {
  return axios
    .get(`${apiConfig.nexus}/attributes/${id}`)
    .then((resp) => resp.data)
}

export async function getAttributeValuesById(
  valueIds: string[],
): Promise<AttributeValue[]> {
  const config = {
    params: {},
    ignoredStatuses: [{ status: 404 }],
  }

  let values: AttributeValue[] = []

  const responses: AxiosResponse<AttributeValue>[] = await Promise.all(
    valueIds.map((valueId) =>
      axios.get(`${apiConfig.nexus}/attribute_values/${valueId}`, config),
    ),
  )

  values = responses
    .filter((response) => response.status !== 404)
    .map((response) => response.data)

  return values
}
