import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import isFinite from 'lodash/fp/isFinite'

import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import Input from 'components/common/Input'
import ValidationErrorText from 'components/common/ValidationErrorText'

import EditShipping from './EditShipping'
import useCarrierInfo, {
  CarrierInfoState,
  Option,
  selectCarrier,
  selectServiceLevel,
  updateServiceLevel,
  setValidation,
  setSlaValidation,
  setOrderCapacity,
  setOrderCapacityValidation,
} from './useCarrierInfo'

import { editSellerShipNode } from 'store/seller/actionCreators'

import {
  isSellersPending,
  isRoleExternalUserSelector,
  getMemberOf,
} from 'store/selectors'

import { validationHandler, ValidationSchema } from 'services/validation'
import { getCategoryCodes } from 'services/codes'
import {
  CATEGORY_SERVICE_LEVEL_AGREEMENT,
  SLA_EXPRESS,
} from 'constants/categories'

import {
  ShipNode,
  ShippingService,
  SmsDistributionCenter,
  VendorCategory,
} from 'types/Seller'

import { USER_ROLE_ADMIN, USER_ROLE_OPS } from 'services/roles'
import { isOneOfUserRoles } from 'services/authorization'
import { timezoneCode, timezoneAbbreviation } from 'services/timezoneCode'

export interface Props {
  isOpen: boolean
  shipNode: ShipNode
  distributionCenter?: SmsDistributionCenter
  vendorCategories: VendorCategory[]
  onChange?: (CarrierInfo: CarrierInfoState) => void
  validate?: boolean
  contentOnly?: boolean
}

export const slaSchema: ValidationSchema = yup.object().shape({
  cut_off_time: yup.string().label('Cut off time').required(),
  generic_time_in_transit: yup.string().label('Time in transit').required(),
})

export const validationSchema: ValidationSchema = yup.object().shape({
  carriers: yup.array().label('Carriers').required(),
  serviceLevelAgreements: yup.array().label('SLAs').required(),
  buildingLeadTimeId: yup.number().label('Building lead time').required(),
})

export const EditCarrierInfo = ({
  shipNode,
  vendorCategories,
  distributionCenter,
  isOpen,
  onChange,
  validate,
  contentOnly = false,
}: Props) => {
  const reduxDispatch = useDispatch()

  const isPending = useSelector(isSellersPending)
  const isExternalUser = useSelector(isRoleExternalUserSelector)
  const memberOf = useSelector(getMemberOf)

  const [state, dispatch] = useCarrierInfo(shipNode, vendorCategories)

  useEffect(() => {
    if (onChange) {
      onChange(state)
    }
  }, [onChange, state])

  const timezone = timezoneCode(distributionCenter?.timezone)
  const timezoneAbbr = timezoneAbbreviation(distributionCenter?.timezone)

  const hasRolloverEdit = isOneOfUserRoles(memberOf, [
    USER_ROLE_ADMIN,
    USER_ROLE_OPS,
  ])

  const slaCodes = getCategoryCodes(
    vendorCategories,
    CATEGORY_SERVICE_LEVEL_AGREEMENT,
  )

  const buildingLeadTimeOptions = [
    {
      code_name: 'None',
      code_id: undefined,
    },
    ...slaCodes,
  ]

  const validateSlas = (): boolean => {
    const { serviceLevelAgreements, shippingServiceOptions } = state
    let valid = true

    if (!state.orderCapacity) {
      serviceLevelAgreements.forEach((sla) => {
        if (sla.days_to_add || sla.max_days_to_add) {
          valid = false
          dispatch(
            setOrderCapacityValidation(
              'Order Capacity Per Day must be provided',
            ),
          )
        }
      })
    }

    const updatedOptions = shippingServiceOptions.map((currOption) => {
      const sla = serviceLevelAgreements.find(
        (service) => service.id === currOption.id,
      )
      if (sla) {
        const { validation, isValid } = validationHandler(slaSchema, sla)
        if (!isValid) {
          valid = false
        }

        if (sla.days_to_add && !sla.max_days_to_add) {
          valid = false

          return {
            ...currOption,
            validation: {
              ...validation,
              maxDaysToAdd: ['Required if Rollover or Capacity exist'],
            },
          }
        }
        return { ...currOption, validation }
      } else {
        return { ...currOption, validation: {} }
      }
    })

    dispatch(setSlaValidation(updatedOptions))

    return valid
  }

  const handleSubmit = () => {
    const { validation, isValid } = validationHandler(validationSchema, state)
    dispatch(setValidation(validation))

    const areSlasValid = validateSlas()

    if (isValid && areSlasValid) {
      const maxOrdersPerDay =
        typeof state.orderCapacity === 'string' &&
        isFinite(parseInt(state.orderCapacity, 10))
          ? parseInt(state.orderCapacity, 10)
          : null

      const carrierInfoEdit = {
        carriers: state.carriers,
        service_levels: state.serviceLevelAgreements,
        building_lead_time_id: state.buildingLeadTimeId,
        max_orders_per_day: maxOrdersPerDay,
      }

      const saveShipNode = {
        ...shipNode,
        ...carrierInfoEdit,
      }

      if (distributionCenter?.id) {
        reduxDispatch(editSellerShipNode(distributionCenter.id, saveShipNode))
      }
    }
  }

  useEffect(() => {
    if (validate) {
      handleSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validate])

  const handleSelectCarrier =
    (option: Option) =>
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const checked = event.currentTarget.checked
      dispatch(selectCarrier(option.id, checked))
    }

  const handleServiceLevelChange = (
    id: number,
    property: string,
    value?: string | number,
  ) => {
    dispatch(updateServiceLevel(id, property, value))
  }

  const handleSelectServiceLevel = (
    serviceLevel: ShippingService,
    selected: boolean,
  ) => {
    dispatch(selectServiceLevel(serviceLevel, selected))
  }

  const handleOrderCapacityChange = (value: string) => {
    dispatch(setOrderCapacity(value))
  }

  const content = (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className="header">Carriers</Typography>
        </Grid>
        <Grid item xs={12}>
          {state.carrierOptions.map((option) => {
            const value = state.carriers.find(
              (carrier) => carrier.id === option.id,
            )

            return (
              <FormControlLabel
                key={option.id}
                sx={{ display: 'block' }}
                control={
                  <Checkbox
                    data-testid="carrier-checkbox"
                    checked={option.id === value?.id ?? false}
                    disableRipple
                    disabled={isPending}
                    onChange={handleSelectCarrier(option)}
                  />
                }
                label={option.name}
              />
            )
          })}
          {state.validation && (
            <ValidationErrorText errors={state.validation} field="carriers" />
          )}
        </Grid>
        <Grid item xs={4}>
          <Input
            isRequired
            id={`order-capacity`}
            name="orderCapacity"
            value={state.orderCapacity}
            type="text"
            label="Order Capacity Per Day"
            isDisabled={!hasRolloverEdit}
            onChange={handleOrderCapacityChange}
            validation={state.validation}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className="header">SLAs</Typography>
        </Grid>
        <Grid item xs={12}>
          {state.shippingServiceOptions.map((option) => {
            const value = state.serviceLevelAgreements.find(
              (service) => service.id === option.id,
            )

            return (
              <EditShipping
                key={option.id}
                shippingService={value}
                option={option}
                isPending={isPending}
                timezone={timezoneAbbr}
                onChange={handleServiceLevelChange}
                selectChange={handleSelectServiceLevel}
                buildingLeadTimeOptions={buildingLeadTimeOptions}
                isExpress={option.name === SLA_EXPRESS}
                isExternalUser={isExternalUser}
                hasRolloverEdit={hasRolloverEdit}
              />
            )
          })}
          {state.validation && (
            <ValidationErrorText
              errors={state.validation}
              field="serviceLevelAgreements"
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            If a service level does not have a building lead time,{' '}
            {state.buildingLeadTimeName} will be used as a default when within
            order capacity.
          </Typography>
        </Grid>
      </Grid>
    </>
  )

  if (contentOnly) {
    return content
  }

  return (
    <>
      <DialogContainer
        title="Edit Carrier Info"
        subtitle={`Distribution Center Time Zone: ${timezone}`}
        isOpen={isOpen}
        maxWidth="xl"
        isPending={isPending}
        onSubmit={handleSubmit}
      >
        {content}
      </DialogContainer>
    </>
  )
}

export default EditCarrierInfo
