import React from 'react'

import isArray from 'lodash/fp/isArray'
import isString from 'lodash/fp/isString'

import FilterBar from './index'
import FilterValue from './FilterValue'

import { getSeller } from 'services/seller'
import { getNodeById } from 'services/itemTaxonomies'

export type Props = {
  filters: Dictionary<string | string[] | undefined>
  defaultCollapsed?: boolean
}

export const ActiveFilters = ({ filters, defaultCollapsed }: Props) => {
  const [sellerDisplayName, setSellerDisplayname] = React.useState<string>()
  const [attribute, setAttribute] = React.useState<string>()

  React.useEffect(() => {
    if (filters.seller_id && isArray(filters.seller_id)) {
      Promise.all(
        filters.seller_id.map((sellerId) => getSeller(sellerId)),
      ).then((sellers) => {
        setSellerDisplayname(
          sellers
            .map(
              (seller) => seller?.display_name || seller?.legal_business_name,
            )
            .join(', '),
        )
      })
    }

    if (filters.item_type_id && isArray(filters.item_type_id)) {
      Promise.all(
        filters.item_type_id.map((itemTypeId) => getNodeById(itemTypeId)),
      ).then((itemTypes) => {
        setAttribute(itemTypes.map((itemType) => itemType?.name).join(', '))
      })
    }
  }, [filters])

  return (
    <FilterBar isSticky={false} defaultCollapsed={defaultCollapsed}>
      {sellerDisplayName && <FilterValue value={sellerDisplayName} />}
      {attribute && <FilterValue value={attribute} />}
      {filters.validation_status && isString(filters.validation_status) && (
        <FilterValue value={filters.validation_status} />
      )}
      {filters.published && (
        <FilterValue value={`Published: ${filters.published}`} />
      )}
      {filters.tcin && <FilterValue value={`TCIN: ${filters.tcin}`} />}
    </FilterBar>
  )
}

export default ActiveFilters
