import * as yup from 'yup'

const getValidationSchema = (typeLabel: string, feeLabel: string) =>
  yup.object().shape({
    attribute: yup
      .object()
      .label(typeLabel)
      .shape({
        id: yup.string(),
      })
      .nullable()
      .required(),
    percent: yup.string().label(feeLabel).max(2).required(),
  })

export default getValidationSchema
