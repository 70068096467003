import { SyntheticEvent, useEffect, useState } from 'react'
import usePrevious from 'hooks/usePrevious'

import Typeahead from '.'

import { SellerSearchParams, SmsSeller } from 'types/Seller'
import { Direction, PagingParams } from 'services/pageableHelper'
import { searchSellers } from 'services/seller'
import { createFilterOptions } from '@mui/material/Autocomplete'

interface Props {
  placeholder?: string
  isDisabled?: boolean
  clearOnSelect?: boolean
  value?: Nullable<SmsSeller>
  hasVmmIdSearch?: boolean
  onChange: (value: Nullable<SmsSeller>, enteredValue: string) => void
  onClear?: () => void
  blurOnSelect?: boolean
}

const SellerTypeahead = ({
  placeholder,
  isDisabled,
  clearOnSelect = false,
  value: valueFromProps,
  hasVmmIdSearch,
  onChange,
  onClear,
  blurOnSelect,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedValue, setSelectedValue] = useState<Nullable<SmsSeller>>(
    valueFromProps ? valueFromProps : null,
  )
  const [options, setOptions] = useState<SmsSeller[]>([])
  const [loading, setLoading] = useState(false)

  const prevValue = usePrevious(valueFromProps)

  useEffect(() => {
    if (prevValue !== valueFromProps) {
      setSelectedValue(valueFromProps)
    }

    if (!valueFromProps) {
      setOptions([])
    }
  }, [prevValue, valueFromProps])

  const findSellers = async ({
    query,
    vmmId,
  }: {
    query?: string
    vmmId?: string
  }): Promise<SmsSeller[]> => {
    const params: SellerSearchParams = {}

    if (query && query.length > 0) {
      params.q = query
    }

    if (hasVmmIdSearch && vmmId) {
      if (
        vmmId &&
        vmmId.length > 0 &&
        vmmId.length < 10 &&
        /^\d+$/.test(vmmId)
      ) {
        params.vmm_id = vmmId
      }
    }
    if (!params.q && !params.vmm_id) {
      return Promise.resolve([])
    }

    const paging: PagingParams = {
      page: 0,
      perPage: 20,
      direction: Direction.ASC,
      orderBy: 'display_name',
    }

    const { data } = await searchSellers(paging, params)

    return data
  }

  useEffect(() => {
    if (options.length && !inputValue) {
      setOptions([])
    }
  }, [inputValue, options])

  const handleInputChange = async (
    _event: SyntheticEvent,
    enteredValue: string,
    reason: string,
  ) => {
    if (
      onClear &&
      (reason === 'clear' ||
        (selectedValue && reason === 'input' && enteredValue === ''))
    ) {
      onClear()
      setOptions([])
      setInputValue('')
      setSelectedValue(null)
      return
    }

    if (!clearOnSelect || (clearOnSelect && reason !== 'reset')) {
      setInputValue(enteredValue)
    } else {
      setInputValue('')
      return
    }

    const invalidInputValue = !enteredValue || reason === 'reset'
    if (invalidInputValue) {
      return
    }

    setLoading(true)
    const trimmedQuery = enteredValue.trim()

    const [sellerNameResults, sellerVmmResults] = await Promise.all([
      findSellers({ query: trimmedQuery }),
      hasVmmIdSearch ? findSellers({ vmmId: trimmedQuery }) : [],
    ])

    const allSellerResults = [...sellerNameResults, ...sellerVmmResults]

    setOptions(allSellerResults)
    setLoading(false)
  }

  const handleSelectedOptionChange = (
    _event: SyntheticEvent,
    value: SmsSeller,
  ) => {
    if (!(value instanceof Array)) {
      const searchParamValue = getOptionLabel(value as SmsSeller)

      if (!clearOnSelect) {
        setSelectedValue(value as Nullable<SmsSeller>)
      }

      onChange(value as Nullable<SmsSeller>, searchParamValue)
    }
  }

  const getOptionLabel = (seller: SmsSeller) => {
    return (seller?.display_name || seller?.legal_business_name) ?? ''
  }

  const nameVmmPlaceholder = placeholder
    ? placeholder
    : hasVmmIdSearch
    ? 'Name or VMM (Vendor) ID'
    : 'Search Partner Name'

  const filterOptions = createFilterOptions({
    stringify: (option: SmsSeller) =>
      option.display_name + option.legal_business_name + option.vmm_id,
  })

  return (
    <Typeahead
      aria-label="Partner Filter"
      placeholder={nameVmmPlaceholder}
      getOptionLabel={getOptionLabel}
      options={options}
      loading={loading}
      onInputChange={handleInputChange}
      onChange={handleSelectedOptionChange}
      inputValue={inputValue}
      value={selectedValue}
      isOptionEqualToValue={(option: SmsSeller, value: Nullable<SmsSeller>) =>
        option.id === value?.id
      }
      blurOnSelect={blurOnSelect}
      disableClearable={clearOnSelect}
      disabled={isDisabled}
      renderOption={(props: any, seller: SmsSeller) => {
        return (
          <li {...props} key={seller.id}>
            {(seller?.display_name || seller?.legal_business_name) ?? ''}
          </li>
        )
      }}
      filterOptions={filterOptions}
    />
  )
}

export default SellerTypeahead
