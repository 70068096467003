import { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'

import FilterBar from 'components/common/FilterBar'
import TypeaheadFilter from 'components/common/FilterBar/TypeaheadFilter'
import {
  useSearchParams,
  TableState,
  getEnhancedTablePageableProps,
} from 'components/common/FilterBar/useSearchParams'

import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import { PAGINATION } from 'components/common/EnhancedTable/EnhancedTablePagination'
import HeaderTitle from 'components/common/HeaderTitle'
import LabeledDataList from 'components/common/LabeledDataList'
import TableSpacer from 'components/common/TableSpacer'

import { formatLocaleNumber } from 'services/formatNumber'
import {
  memoizedGetItemVersionDetails,
  getHeliosItemList,
} from 'services/helios'
import { memoizedGetSellerDisplayName } from 'services/seller'
import { Direction } from 'services/pageableHelper'

import { HeliosListItem } from 'types/HeliosItem'
import { ValidationStatus } from 'types/Item'

type SearchParams = TableState & {
  seller_id: string | undefined
  helios_tcin: string | undefined
}
const initialSearchParams: SearchParams = {
  direction: Direction.ASC,
  orderBy: 'source_timestamp',
  page: 0,
  perPage: 20,
  seller_id: undefined,
  helios_tcin: undefined,
}

const buildSearchParams = ({
  sellerId,
  tcin,
}: {
  sellerId?: string
  tcin?: string
}) => {
  return {
    tcin,
    seller_id: sellerId,
    status: ValidationStatus.INITIATED,
    version: 'latest',
  }
}

export const ItemsProcessingPage = () => {
  const fieldList: EnhancedTableFieldType<HeliosListItem>[] = [
    {
      key: '',
      heading: 'Partner Name',
      formatCellAsync: async (item) => {
        const displayName = await memoizedGetSellerDisplayName(item.seller_id)

        return displayName ?? item.vendor_name
      },
    },
    {
      key: 'tcin',
      heading: 'TCIN',
    },
    {
      key: 'external_id',
      heading: 'Partner SKU',
    },
    {
      key: 'item_type_name',
      heading: 'Item Type',
    },
    {
      key: 'product_title',
      heading: 'Title',
    },
    {
      key: 'relationship_type_code',
      heading: 'Relationship',
    },
    {
      key: '',
      heading: 'Waiting on',
      formatCellAsync: async (item) => {
        const details = await memoizedGetItemVersionDetails(
          item.item_version_id,
        )
        return details.nextEnrichments
      },
    },
    {
      key: '',
      heading: 'Latest Processing date',
      formatCellAsync: async (item) => {
        const details = await memoizedGetItemVersionDetails(
          item.item_version_id,
        )
        return details.sourceTimestamp
      },
    },
  ]

  const [searchParams, searchParamActions, appliedFilterCount] =
    useSearchParams<SearchParams>(initialSearchParams)

  const [pending, setPending] = useState(false)
  const [data, setData] = useState<HeliosListItem[]>([])
  const [total, setTotal] = useState(0)
  const [filteredCount, setFilteredCount] = useState(0)

  useEffect(() => {
    let mounted = true

    setPending(true)
    const itemSearchParams = buildSearchParams({
      sellerId: searchParams.seller_id,
      tcin: searchParams.helios_tcin,
    })

    getHeliosItemList(itemSearchParams, {
      sort: searchParams.orderBy,
      order: searchParams.direction,
      page: searchParams.page,
      pageSize: searchParams.perPage,
    }).then(({ data, total: filteredTotal }) => {
      if (mounted) {
        setFilteredCount(filteredTotal)
        setData(data)
        if (!total) {
          if (itemSearchParams.seller_id || itemSearchParams.tcin) {
            getHeliosItemList(
              buildSearchParams({
                sellerId: undefined,
                tcin: undefined,
              }),
              {
                sort: initialSearchParams.orderBy,
                order: initialSearchParams.direction,
                page: initialSearchParams.page,
                pageSize: 1,
              },
            ).then(({ total: allTotal }) => {
              if (mounted) {
                setTotal(allTotal)
              }
            })
          } else {
            setTotal(filteredTotal)
          }
        }
        setPending(false)
      }
    })

    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  const labeledData = [
    { label: 'Total Items', data: formatLocaleNumber(total) },
  ]

  const onFilterClear = () => {
    searchParamActions.updateSearchParam(initialSearchParams)
  }

  return (
    <div>
      <HeaderTitle title="Items Processing" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LabeledDataList data={labeledData} />
        </Grid>
        <Grid item xs={12}>
          <FilterBar
            count={filteredCount}
            onClear={onFilterClear}
            appliedFilterCount={appliedFilterCount}
          >
            <Grid item xs={12} lg={4}>
              <TypeaheadFilter
                value={searchParams.seller_id}
                searchParam="seller_id"
                onChange={searchParamActions.updateSearchParam}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TypeaheadFilter
                value={searchParams.helios_tcin}
                searchParam="helios_tcin"
                status={ValidationStatus.INITIATED}
                onChange={searchParamActions.updateSearchParam}
              />
            </Grid>
          </FilterBar>
          <TableSpacer>
            <EnhancedTable
              data={data}
              fieldList={fieldList}
              total={filteredCount}
              isLoading={pending}
              {...getEnhancedTablePageableProps(
                searchParams,
                searchParamActions,
              )}
              paginationDisplayLocation={PAGINATION.BOTTOM}
              paginationDisabled={filteredCount <= searchParams.perPage}
            />
          </TableSpacer>
        </Grid>
      </Grid>
    </div>
  )
}

export default ItemsProcessingPage
