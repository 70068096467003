import { call, select, takeEvery } from 'redux-saga/effects'

import { CLOSE_DIALOG } from './actionCreator'
import { getCloseDialogCallback } from '../selectors'

export function* closeDialogSaga() {
  const closeCallback: void | undefined = yield select(getCloseDialogCallback)
  if (closeCallback) {
    yield call(closeCallback)
  }
}

export function* dialogSagas() {
  yield takeEvery(CLOSE_DIALOG, closeDialogSaga)
}
