import { EnhancedTableFieldType } from 'components/common/EnhancedTable'

const fetchBestSellers = () => ({ type: 'FETCHING/BEST_SELLERS' }) as const

const fulfillBestSellers = (
  data: Dictionary<string | number | Date>[],
  fieldList: EnhancedTableFieldType[],
) =>
  ({
    type: 'FULFILLED/BEST_SELLERS',
    payload: {
      data,
      fieldList,
    },
  }) as const
const rejectBestSellers = () => ({ type: 'REJECTED/BEST_SELLERS' }) as const

const fetchTopConverting = () => ({ type: 'FETCHING/TOP_CONVERTING' }) as const

const fulfillTopConverting = (
  data: Dictionary<string | number | Date>[],
  fieldList: EnhancedTableFieldType[],
) =>
  ({
    type: 'FULFILLED/TOP_CONVERTING',
    payload: {
      data,
      fieldList,
    },
  }) as const
const rejectTopConverting = () => ({ type: 'REJECTED/TOP_CONVERTING' }) as const

const fetchMostReturned = () => ({ type: 'FETCHING/MOST_RETURNED' }) as const

const fulfillMostReturned = (
  data: Dictionary<string | number | Date>[],
  fieldList: EnhancedTableFieldType[],
) =>
  ({
    type: 'FULFILLED/MOST_RETURNED',
    payload: {
      data,
      fieldList,
    },
  }) as const
const rejectMostReturned = () => ({ type: 'REJECTED/MOST_RETURNED' }) as const

export const actions = {
  fetchBestSellers,
  fulfillBestSellers,
  rejectBestSellers,
  fetchTopConverting,
  fulfillTopConverting,
  rejectTopConverting,
  fetchMostReturned,
  fulfillMostReturned,
  rejectMostReturned,
}

type Actions = ReturnType<
  | typeof fetchBestSellers
  | typeof fulfillBestSellers
  | typeof rejectBestSellers
  | typeof fetchTopConverting
  | typeof fulfillTopConverting
  | typeof rejectTopConverting
  | typeof fetchMostReturned
  | typeof fulfillMostReturned
  | typeof rejectMostReturned
>
interface ReducerTableState {
  loading: boolean
  data: Dictionary<string | number | Date>[]
  fieldList: EnhancedTableFieldType[]
  error: boolean
}
type State = {
  bestSeller: ReducerTableState
  topConverting: ReducerTableState
  mostReturned: ReducerTableState
}

export const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case 'FETCHING/BEST_SELLERS': {
      return {
        ...state,
        bestSeller: {
          ...state.bestSeller,
          loading: true,
          error: false,
        },
      }
    }

    case 'FULFILLED/BEST_SELLERS': {
      return {
        ...state,
        bestSeller: {
          loading: false,
          error: false,
          data: action.payload.data,
          fieldList: action.payload.fieldList,
        },
      }
    }

    case 'REJECTED/BEST_SELLERS': {
      return {
        ...state,
        bestSeller: {
          ...state.bestSeller,
          loading: false,
          error: true,
        },
      }
    }

    case 'FETCHING/TOP_CONVERTING': {
      return {
        ...state,
        topConverting: {
          ...state.topConverting,
          loading: true,
          error: false,
        },
      }
    }

    case 'FULFILLED/TOP_CONVERTING': {
      return {
        ...state,
        topConverting: {
          loading: false,
          error: false,
          data: action.payload.data,
          fieldList: action.payload.fieldList,
        },
      }
    }

    case 'REJECTED/TOP_CONVERTING': {
      return {
        ...state,
        topConverting: {
          ...state.topConverting,
          loading: false,
          error: true,
        },
      }
    }

    case 'FETCHING/MOST_RETURNED': {
      return {
        ...state,
        mostReturned: {
          ...state.mostReturned,
          loading: true,
          error: false,
        },
      }
    }

    case 'FULFILLED/MOST_RETURNED': {
      return {
        ...state,
        mostReturned: {
          loading: false,
          error: false,
          data: action.payload.data,
          fieldList: action.payload.fieldList,
        },
      }
    }

    case 'REJECTED/MOST_RETURNED': {
      return {
        ...state,
        mostReturned: {
          ...state.mostReturned,
          loading: false,
          error: true,
        },
      }
    }

    default:
      return state
  }
}
const initialTableState = {
  loading: false,
  error: false,
  data: [],
  fieldList: [],
}
export const initialState: State = {
  bestSeller: initialTableState,
  topConverting: initialTableState,
  mostReturned: initialTableState,
}
