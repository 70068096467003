import { call, put, takeEvery } from 'redux-saga/effects'

import {
  fetchPartnerDiversityAnswers,
  fetchPartnerDiversityAnswersDone,
  fetchPartnerDiversityAnswersError,
  editPartnerDiversityAnswers,
  editPartnerDiversityAnswersDone,
  editPartnerDiversityAnswersError,
} from './actionCreators'

import { closeDialog } from 'store/dialog/actionCreator'

import {
  getPartnerDiversityAnswers,
  updateAllPartnerDiversityAnswers,
} from 'services/partnerDiversity'

import { DiversityAnswer } from 'types/PartnerDiversity'

export function* fetchPartnerDiversityAnswersSaga(
  action: ReturnType<typeof fetchPartnerDiversityAnswers>,
) {
  const sellerId = action.payload.sellerId

  try {
    const data: DiversityAnswer[] = yield call(
      getPartnerDiversityAnswers,
      sellerId,
    )

    yield put(fetchPartnerDiversityAnswersDone(data))
  } catch (e) {
    yield put(fetchPartnerDiversityAnswersError())
  }
}

export function* editPartnerDiversityAnswersSaga(
  action: ReturnType<typeof editPartnerDiversityAnswers>,
) {
  const { sellerId, answers } = action.payload

  try {
    const data: any[] = yield call(
      updateAllPartnerDiversityAnswers,
      sellerId,
      answers,
    )
    yield put(editPartnerDiversityAnswersDone(data))
    yield put(fetchPartnerDiversityAnswers(sellerId))
  } catch (e) {
    yield put(editPartnerDiversityAnswersError())
  } finally {
    yield put(closeDialog())
  }
}

export function* partnerDiversitySagas() {
  yield takeEvery(
    'FETCH_PARTNER_DIVERSITY_ANSWERS',
    fetchPartnerDiversityAnswersSaga,
  )
  yield takeEvery(
    'EDIT_PARTNER_DIVERSITY_ANSWERS',
    editPartnerDiversityAnswersSaga,
  )
}
