import { connect } from 'react-redux'

import { withFormik, FormikProps } from 'formik'
import { PHONE_TYPE_MOBILE, PHONE_TYPE_OFFICE } from 'constants/phones'

import { DEFAULT_COUNTRY } from 'services/addressHelper'
import { getTelephoneCodeByCountry } from 'services/geographicalCodes'

import { closeDialog, CloseDialogAction } from 'store/dialog/actionCreator'
import {
  createSellerAndAdminContact,
  CreateSellerAndAdminContactAction,
} from 'store/seller/actionCreators'

import { SmsSeller, CompanyType } from 'types/Seller'
import SellerUser from 'types/SellerUser'
import StoreState from 'types/state'
import { CountryNameCode } from 'types/Country'

import AddPartnerForm, {
  FormValues,
  validationSchema as formValidationSchema,
} from './AddPartnerForm'
import { SelectOption } from 'types/SelectOption'

interface ComponentProps {
  isOpen: boolean
  handleSubmit: any
  createSellerAndAdminContact: (
    seller: Partial<SmsSeller>,
    contact: SellerUser,
  ) => CreateSellerAndAdminContactAction
  closeDialog: () => CloseDialogAction
  currentSeller: SmsSeller | undefined
  sellerContacts: SellerUser[] | undefined
  isPending: boolean
  sources: SelectOption[]
}

export type Props = ComponentProps & FormikProps<FormValues>

export const AddPartner = withFormik<ComponentProps, FormValues>({
  mapPropsToValues: () => ({
    legalBusinessName: '',
    displayName: '',
    source: '',
    isPrivate: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postalCode: '',
    countryCode: DEFAULT_COUNTRY,
    firstName: '',
    lastName: '',
    email: '',
    officeCountry: CountryNameCode.UNITED_STATES,
    mobileCountry: CountryNameCode.UNITED_STATES,
    officePhoneNumber: '',
    mobilePhoneNumber: '',
    isEqualPhoneNumber: false,
  }),

  handleSubmit: async (values, { props }) => {
    const {
      legalBusinessName,
      displayName,
      source,
      isPrivate,
      address1,
      address2,
      city,
      state,
      postalCode,
      countryCode,
      firstName,
      lastName,
      email,
      officeCountry,
      mobileCountry,
      officePhoneNumber,
      mobilePhoneNumber,
    } = values

    const { createSellerAndAdminContact } = props

    const seller = {
      legal_business_name: legalBusinessName,
      display_name: displayName,
      is_private: isPrivate === CompanyType.PRIVATE ? true : false,
      source,
      primary_address: {
        address1: address1.trim(),
        address2: address2.trim(),
        city: city.trim(),
        state,
        postal_code: postalCode.trim(),
        country_code: countryCode,
      },
    }

    const mobileCountryCode = getTelephoneCodeByCountry(mobileCountry)

    const phoneNumbers = [
      {
        country_code: mobileCountryCode,
        country_name_code: mobileCountry,
        number: mobilePhoneNumber,
        type: PHONE_TYPE_MOBILE,
      },
    ]

    if (officePhoneNumber) {
      const officeCountryCode = getTelephoneCodeByCountry(officeCountry)

      phoneNumbers.push({
        country_code: officeCountryCode,
        country_name_code: officeCountry,
        number: officePhoneNumber,
        type: PHONE_TYPE_OFFICE,
      })
    }

    const sellerAdminUser = {
      first_name: firstName,
      last_name: lastName,
      email: email.trim(),
      phone_numbers: phoneNumbers,
    } as SellerUser

    createSellerAndAdminContact(seller, sellerAdminUser)
  },
  enableReinitialize: true,
  validationSchema: formValidationSchema,
})(AddPartnerForm)

const mapStateToProps = (state: StoreState) => {
  const { seller } = state

  return {
    currentSeller: seller.currentSeller,
    sellerContacts: seller.contacts,
    isPending: seller.isSellersPending,
  }
}

const mapDispatchToProps = {
  createSellerAndAdminContact,
  closeDialog,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPartner)
