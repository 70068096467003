import getOr from 'lodash/fp/getOr'
import * as yup from 'yup'

import { validationHandler } from './validation'

import { SmsAddress, Address } from 'types/Address'
import { Validation } from 'types/Validation'

export const DEFAULT_COUNTRY = 'US'

export const hydrateAddress = (address: Partial<SmsAddress>): Address => {
  return {
    address1: getOr('', 'address1', address),
    address2: getOr('', 'address2', address),
    city: getOr('', 'city', address),
    state: getOr('', 'state', address),
    postalCode: getOr('', 'postal_code', address),
    countryCode: getOr('', 'country_code', address),
  }
}

export const hydrateSmsAddress = (address: Address): SmsAddress => {
  return {
    address1: getOr('', 'address1', address),
    address2: getOr('', 'address2', address),
    city: getOr('', 'city', address),
    state: getOr('', 'state', address),
    postal_code: getOr('', 'postalCode', address),
    country_code: getOr('', 'countryCode', address),
  }
}

export const getAddressValidationHandler = (
  data: any,
): { validation: Validation; isValid: boolean } => {
  const validator = yup.object().shape({
    address1: yup.string().label('Address Line 1').max(75).required(),
    address2: yup.string().label('Address Line 2').max(75),
    city: yup.string().label('City').required(),
    state: yup.string().label('State').required(),
    postalCode: yup.string().label('Postal code').min(5).max(9).required(),
    countryCode: yup.string().label('Country code').required(),
  })

  return validationHandler(validator, data)
}
