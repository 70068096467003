import { ApiToken } from 'types/ApiTokens'
import { Validation } from 'types/Validation'

export const setNewToken = (newToken: ApiToken | undefined) =>
  ({ type: 'SET_NEW_TOKEN', payload: { newToken } }) as const

export const setDescription = (description: string) =>
  ({ type: 'SET_DESCRIPTION', payload: { description } }) as const

export const setValidation = (validation: Validation, isValid: boolean) =>
  ({
    type: 'SET_VALIDATION',
    payload: {
      validation,
      isValid,
    },
  }) as const

export type Action = ReturnType<
  typeof setNewToken | typeof setDescription | typeof setValidation
>

interface State {
  newToken: ApiToken | undefined
  description: string
  validation: Validation | undefined
  isValid: boolean
}

export const initialState: State = {
  newToken: undefined,
  description: '',
  validation: undefined,
  isValid: false,
}

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_NEW_TOKEN':
      return { ...state, newToken: action.payload.newToken }
    case 'SET_DESCRIPTION':
      return { ...state, description: action.payload.description }
    case 'SET_VALIDATION':
      return {
        ...state,
        isValid: action.payload.isValid,
        validation: action.payload.validation,
      }
    default:
      return state
  }
}

export default reducer
