import {
  EDIT_USER_INFO_DONE,
  FETCH_SELLER_USER_DONE,
  FETCH_USER_ENTITLEMENTS_DONE,
  FetchSellerUserDoneAction,
  FetchUserEntitlementsDoneAction,
  SET_USER_INFO,
  SetUserInfoAction,
  UserActions,
  EditUserInfoDoneAction,
  SET_EXTERNAL_USER_LOGIN,
  SetExternalUserLoginAction,
  UPDATE_ENTITLEMENTS_BY_SELLER_ID,
  UpdateEntitlementsBySellerIdAction,
} from './actionCreators'

import { mapEntitlementsToRoles } from 'services/authorization'
import { formatUserInfo } from 'services/security'
import { getItem, LOCAL_STORAGE_REDIRECT_PATH } from 'services/storageHelper'

import RegistrationLink from 'types/RegistrationLink'
import { Entitlement } from 'types/SellerUser'
import { UserInfo, ExternalUserLogin } from 'types/UserInfo'

const redirectPath = getItem(LOCAL_STORAGE_REDIRECT_PATH) || '/'

export interface UserState extends UserInfo {
  isAuthenticated: boolean
  isAuthorized: boolean
  redirectPath: string
  externalUserLogin: ExternalUserLogin
  entitlements: Entitlement[]
  registrationLink?: RegistrationLink
}

export const initialState = {
  isAuthenticated: false,
  isAuthorized: false,
  redirectPath,
  externalUserLogin: ExternalUserLogin.IDLE,
  entitlements: [],
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  fullName: '',
  lanId: '',
  memberOf: [],
  accessToken: '',
}

export default function userReducer(
  state: UserState = initialState,
  action: UserActions,
): UserState {
  switch (action.type) {
    case SET_EXTERNAL_USER_LOGIN: {
      const { payload } = action as SetExternalUserLoginAction
      return {
        ...state,
        externalUserLogin: payload,
      }
    }

    case SET_USER_INFO: {
      const { payload } = action as SetUserInfoAction
      const { userInfo, isAuthenticated } = payload

      const { userInfo: info, isAuthorized } = formatUserInfo(userInfo)

      const newState = {
        ...state,
        ...info,
        isAuthenticated,
        isAuthorized,
      }
      return newState
    }

    case FETCH_SELLER_USER_DONE: {
      const { payload } = action as FetchSellerUserDoneAction
      return {
        ...state,
        ...payload,
      }
    }

    case FETCH_USER_ENTITLEMENTS_DONE: {
      const { payload } = action as FetchUserEntitlementsDoneAction

      const memberOf = mapEntitlementsToRoles(payload, state.memberOf)

      return {
        ...state,
        isAuthorized: true,
        entitlements: payload,
        memberOf,
      }
    }

    case UPDATE_ENTITLEMENTS_BY_SELLER_ID: {
      const { payload } = action as UpdateEntitlementsBySellerIdAction

      const entitlementsBySellerId = state.entitlements.filter(
        (item) => item.resource_id === payload,
      )

      const memberOf = mapEntitlementsToRoles(
        entitlementsBySellerId,
        state.memberOf,
      )

      const newState = {
        ...state,
        memberOf,
      }

      return newState
    }

    case EDIT_USER_INFO_DONE: {
      const { payload } = action as EditUserInfoDoneAction
      const { first_name, last_name, email, phone_numbers } = payload

      return {
        ...state,
        email,
        firstName: first_name,
        fullName: `${first_name} ${last_name}`,
        lastName: last_name,
        phone_numbers,
      }
    }

    default:
      return state
  }
}
