import React from 'react'

import { withFormik, Form, FormikProps } from 'formik'
import * as yup from 'yup'

import styled from '@emotion/styled'

import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'

import Actions from '../Actions'
import Content from '../Content'
import InputField from 'components/common/form/InputField'
import RadioGroupField from 'components/common/form/RadioGroupField'
import SelectField from 'components/common/form/SelectField'
import Text from 'components/common/Text'

import { DiversityRadio, DiversitySelection } from 'types/PartnerDiversity'

export const StyledRow = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
}))

const validationSchema = yup.object().shape({
  WOMAN: yup.string().required(),
  WOMAN_CERT: yup.string().test({
    name: 'isWomanCert',
    test(this: yup.TestContext, value: any) {
      if (value && !this.parent.WOMAN_CERT_DATE) {
        return this.createError({
          path: 'WOMAN_CERT_DATE',
          message: 'Required',
        })
      }

      return true
    },
  }),
  WOMAN_CERT_DATE: yup.string().when('WOMAN_CERT', {
    is: (field: any) => field && field.length > 0,
    then: yup.string().required('Required'),
    otherwise: yup.string(),
  }),
  WOMAN_OTHER: yup.string().test({
    name: 'isWomanOther',
    test(this: yup.TestContext, value: any) {
      if (value && !this.parent.WOMAN_OTHER_CERT) {
        return this.createError({
          path: 'WOMAN_OTHER_CERT',
          message: 'Required',
        })
      }

      return true
    },
  }),
  WOMAN_OTHER_CERT: yup.string().test({
    name: 'isWomanOtherCert',
    test(this: yup.TestContext, value: any) {
      if (value && !this.parent.WOMAN_OTHER_CERT_DATE) {
        return this.createError({
          path: 'WOMAN_OTHER_CERT_DATE',
          message: 'Required',
        })
      }

      return true
    },
  }),
  WOMAN_OTHER_CERT_DATE: yup.string().when('WOMAN_OTHER_CERT', {
    is: (field: string | any[]) => field && field.length > 0,
    then: yup.string().required('Required'),
    otherwise: yup.string(),
  }),
  BIPOC: yup
    .string()
    .test({
      name: 'isBipoc',
      test(this: yup.TestContext, value: any) {
        if (value === DiversityRadio.YES && !this.parent.BIPOC_ETHNICITY) {
          return this.createError({
            path: 'BIPOC_ETHNICITY',
            message: 'Required',
          })
        }

        return true
      },
    })
    .required(),
  BIPOC_ETHNICITY: yup.string().when('BIPOC', {
    is: (field: DiversityRadio) => field && field === DiversityRadio.YES,
    then: yup.string().required('Required'),
    otherwise: yup.string(),
  }),
  BIPOC_CERT: yup.string().test({
    name: 'isBipocCert',
    test(this: yup.TestContext, value: any) {
      if (value && !this.parent.BIPOC_CERT_DATE) {
        return this.createError({
          path: 'BIPOC_CERT_DATE',
          message: 'Required',
        })
      }

      return true
    },
  }),
  BIPOC_CERT_DATE: yup.string().when('BIPOC_CERT', {
    is: (field: string | any[]) => field && field.length > 0,
    then: yup.string().required('Required'),
    otherwise: yup.string(),
  }),
  LGBTQ: yup.string().required(),
  LGBTQ_CERT: yup.string().test({
    name: 'isLgbtqCert',
    test(this: yup.TestContext, value: any) {
      if (value && !this.parent.LGBTQ_CERT_DATE) {
        return this.createError({
          path: 'LGBTQ_CERT_DATE',
          message: 'Required',
        })
      }

      return true
    },
  }),
  LGBTQ_CERT_DATE: yup.string().when('LGBTQ_CERT', {
    is: (field: string | any[]) => field && field.length > 0,
    then: yup.string().required('Required'),
    otherwise: yup.string(),
  }),
})

type FormValues = {
  WOMAN: DiversitySelection
  WOMAN_CERT: string
  WOMAN_CERT_DATE: string
  WOMAN_OTHER: string
  WOMAN_OTHER_CERT: string
  WOMAN_OTHER_CERT_DATE: string
  BIPOC: DiversitySelection
  BIPOC_ETHNICITY: string
  BIPOC_CERT: string
  BIPOC_CERT_DATE: string
  LGBTQ: DiversitySelection
  LGBTQ_CERT: string
  LGBTQ_CERT_DATE: string
}

type ComponentProps = {
  page: number
  initValues: FormValues
  isEdit: boolean
  onSubmit: (values: any) => void
}

type Props = ComponentProps & FormikProps<FormValues>

export const DomesticSurveyPageOneForm = ({
  page,
  isValid,
  values,
  handleSubmit,
  isSubmitting,
  setFieldValue,
}: Props) => {
  const setFieldValueCallBack = React.useCallback(
    (prop: string, val: string) => setFieldValue(prop, val),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  React.useEffect(() => {
    if (values.WOMAN === DiversityRadio.NO) {
      setFieldValueCallBack('WOMAN_CERT', '')
      setFieldValueCallBack('WOMAN_CERT_DATE', '')
      setFieldValueCallBack('WOMAN_OTHER', '')
      setFieldValueCallBack('WOMAN_OTHER_CERT', '')
      setFieldValueCallBack('WOMAN_OTHER_CERT_DATE', '')
    }
  }, [values.WOMAN, setFieldValueCallBack])

  React.useEffect(() => {
    if (values.BIPOC === DiversityRadio.NO) {
      setFieldValueCallBack('BIPOC_ETHNICITY', '')
      setFieldValueCallBack('BIPOC_CERT', '')
      setFieldValueCallBack('BIPOC_CERT_DATE', '')
    }
  }, [values.BIPOC, setFieldValueCallBack])

  React.useEffect(() => {
    if (values.LGBTQ === DiversityRadio.NO) {
      setFieldValueCallBack('LGBTQ_CERT', '')
      setFieldValueCallBack('LGBTQ_CERT_DATE', '')
    }
  }, [values.LGBTQ, setFieldValueCallBack])

  return (
    <>
      <Content>
        <Form>
          <StyledRow>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Text type="bodySm" component="label">
                  Are you a Woman Owned Business?
                </Text>
              </Grid>
              {values.WOMAN === DiversityRadio.YES && (
                <>
                  <Grid item xs={4}>
                    <Text type="bodySm" component="label" htmlFor="woman-cert">
                      Women's Business Enterprise National Council (WBENC) or
                      Regional Affiliate
                    </Text>
                  </Grid>
                  <Grid item xs={4}>
                    <Text
                      type="bodySm"
                      component="label"
                      htmlFor="woman-cert-date"
                    >
                      WBENC Certificate Expiration Date
                    </Text>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <RadioGroupField
                  required
                  row={true}
                  name="WOMAN"
                  options={[
                    { label: 'Yes', value: DiversityRadio.YES },
                    { label: 'No', value: DiversityRadio.NO },
                  ]}
                />
              </Grid>
              {values.WOMAN === DiversityRadio.YES && (
                <>
                  <Grid item xs={4}>
                    <InputField
                      id="woman-cert"
                      name="WOMAN_CERT"
                      touchedFieldName="WOMAN_CERT_DATE"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField
                      id="woman-cert-date"
                      type="date"
                      name="WOMAN_CERT_DATE"
                    />
                  </Grid>
                </>
              )}
            </Grid>
            {values.WOMAN === DiversityRadio.YES && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Text type="bodySm" component="label" htmlFor="woman-other">
                      If Certified by an Agency Other than WBENC, Provide Agency
                      Name
                    </Text>
                  </Grid>
                  <Grid item xs={4}>
                    <Text
                      type="bodySm"
                      component="label"
                      htmlFor="woman-other-cert"
                    >
                      Other Agency Certificate Number
                    </Text>
                  </Grid>
                  <Grid item xs={4}>
                    <Text
                      type="bodySm"
                      component="label"
                      htmlFor="woman-other-cert-date"
                    >
                      Other Agency Certificate Expiration Date
                    </Text>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <InputField
                      id="woman-other"
                      name="WOMAN_OTHER"
                      touchedFieldName="WOMAN_OTHER_CERT"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField
                      id="woman-other-cert"
                      name="WOMAN_OTHER_CERT"
                      touchedFieldName="WOMAN_OTHER_CERT_DATE"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField
                      id="woman-other-cert-date"
                      type="date"
                      name="WOMAN_OTHER_CERT_DATE"
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </StyledRow>
          <Divider />
          <StyledRow>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Text type="bodySm" component="label">
                  Are you a Black, Indigenous or People of Color (BIPOC) Owned
                  Business?
                </Text>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <RadioGroupField
                  required
                  row={true}
                  name="BIPOC"
                  options={[
                    { label: 'Yes', value: DiversityRadio.YES },
                    { label: 'No', value: DiversityRadio.NO },
                  ]}
                  touchedFieldName="BIPOC_ETHNICITY"
                />
              </Grid>
            </Grid>
            {values.BIPOC === DiversityRadio.YES && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Text
                      type="bodySm"
                      component="label"
                      htmlFor="bipoc-ethnicity"
                    >
                      Ethnicity Type
                    </Text>
                  </Grid>
                  <Grid item xs={4}>
                    <Text type="bodySm" component="label" htmlFor="bipoc-cert">
                      National Minority Supplier Development Council (NMSDC)
                      Certificate Number
                    </Text>
                  </Grid>
                  <Grid item xs={4}>
                    <Text
                      type="bodySm"
                      component="label"
                      htmlFor="bipoc-cert-date"
                    >
                      NMSDC Certificate Expiration Date
                    </Text>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <SelectField
                      required
                      id="bipoc-ethnicity"
                      name="BIPOC_ETHNICITY"
                      options={[
                        {
                          label: 'African American',
                          value: 'African American',
                        },
                        { label: 'Asian-Indian', value: 'Asian-Indian' },
                        { label: 'Asian-Pacific', value: 'Asian-Pacific' },
                        { label: 'Hispanic', value: 'Hispanic' },
                        { label: 'Native American', value: 'Native American' },
                      ]}
                      keyName="label"
                      valueName="value"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField
                      id="bipoc-cert"
                      name="BIPOC_CERT"
                      touchedFieldName="BIPOC_CERT_DATE"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField
                      id="bipoc-cert-date"
                      type="date"
                      name="BIPOC_CERT_DATE"
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </StyledRow>
          <Divider />
          <StyledRow>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Text type="bodySm" component="label">
                  Are you a Lesbian, Gay, Bisexual, Transgender, Queer,
                  Intersex, Asexual (LGBTQIA+) Owned Business?
                </Text>
              </Grid>
              {values.LGBTQ === DiversityRadio.YES && (
                <>
                  <Grid item xs={4}>
                    <Text type="bodySm" component="label" htmlFor="lgbtq-cert">
                      National Gay & Lesbian Chamber of Commerce (NGLCC)
                      Certificate Number
                    </Text>
                  </Grid>
                  <Grid item xs={4}>
                    <Text
                      type="bodySm"
                      component="label"
                      htmlFor="lgbtq-cert-date"
                    >
                      NGLCC Certificate Expiration Date
                    </Text>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <RadioGroupField
                  required
                  row={true}
                  name="LGBTQ"
                  options={[
                    { label: 'Yes', value: DiversityRadio.YES },
                    { label: 'No', value: DiversityRadio.NO },
                  ]}
                />
              </Grid>
              {values.LGBTQ === DiversityRadio.YES && (
                <>
                  <Grid item xs={4}>
                    <InputField
                      id="lgbtq-cert"
                      name="LGBTQ_CERT"
                      touchedFieldName="LGBTQ_CERT_DATE"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField
                      id="lgbtq-cert-date"
                      type="date"
                      name="LGBTQ_CERT_DATE"
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </StyledRow>
        </Form>
      </Content>
      <Actions
        page={page}
        totalPages={3}
        disabled={!isValid || isSubmitting}
        submitText="Next"
        onSubmit={handleSubmit}
        values={values}
      />
    </>
  )
}

export const DomesticSurveyPageOne = withFormik<ComponentProps, FormValues>({
  mapPropsToValues: (props) => ({
    WOMAN: props.initValues.WOMAN ?? '',
    WOMAN_CERT: props.initValues.WOMAN_CERT ?? '',
    WOMAN_CERT_DATE: props.initValues.WOMAN_CERT_DATE ?? '',
    WOMAN_OTHER: props.initValues.WOMAN_OTHER ?? '',
    WOMAN_OTHER_CERT: props.initValues.WOMAN_OTHER_CERT ?? '',
    WOMAN_OTHER_CERT_DATE: props.initValues.WOMAN_OTHER_CERT_DATE ?? '',
    BIPOC: props.initValues.BIPOC ?? '',
    BIPOC_ETHNICITY: props.initValues.BIPOC_ETHNICITY ?? '',
    BIPOC_CERT: props.initValues.BIPOC_CERT ?? '',
    BIPOC_CERT_DATE: props.initValues.BIPOC_CERT_DATE ?? '',
    LGBTQ: props.initValues.LGBTQ ?? '',
    LGBTQ_CERT: props.initValues.LGBTQ_CERT ?? '',
    LGBTQ_CERT_DATE: props.initValues.LGBTQ_CERT_DATE ?? '',
  }),
  handleSubmit: async (values, { props }) => {
    props.onSubmit(values)
  },
  enableReinitialize: true,
  validateOnMount: true,
  validationSchema,
})(DomesticSurveyPageOneForm)

export default DomesticSurveyPageOne
