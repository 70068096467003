import styled from '@emotion/styled'

import { Box } from '@mui/material'
import Grid from '@mui/material/Grid'
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'

import StarIcon from '@mui/icons-material/Star'

import { StarsDistribution } from 'types/RatingsAndReviews'

const StyledProgress = styled(Grid)({
  width: '90%',
})

const StyledStarLabel = styled(Box)(({ theme }) => ({
  minWidth: theme.spacing(7),
  marginLeft: theme.spacing(2),
  color: theme.palette.grey[500],
}))

const StyledStarIcon = styled(StarIcon)(({ theme }) => ({
  color: theme.palette.grey[500],
  margin: theme.spacing(1),
  verticalAlign: 'middle',
}))

const StyledLinearProgressContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  marginRight: theme.spacing(2),
}))

const StyledPercentageContainer = styled(Box)(({ theme }) => ({
  minWidth: theme.spacing(5),
  color: theme.palette.grey[500],
}))

const StyledContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
})

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number; label: string },
) {
  return (
    <StyledContainer>
      <StyledStarLabel>
        <Typography
          variant="body2"
          color="textSecondary"
          data-testid={`${props.label}-star-label`}
        >
          {props.label} <StyledStarIcon />
        </Typography>
      </StyledStarLabel>
      <StyledLinearProgressContainer>
        <LinearProgress
          variant="determinate"
          {...props}
          data-testid={`${props.label}-star-progress-${props.value}`}
        />
      </StyledLinearProgressContainer>
      <StyledPercentageContainer>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </StyledPercentageContainer>
    </StyledContainer>
  )
}

const getStarPercent = (count: number, starsCount: number) => {
  if (starsCount === 0) {
    return 0
  } else {
    return (count / starsCount) * 100
  }
}

interface Props {
  starsDistribution: StarsDistribution
  starsCount: number
}

export const StarDistribution = (props: Props) => {
  const { starsDistribution, starsCount } = props

  const starRow = Object.keys(starsDistribution)
    .reverse()
    .map((star) => {
      const value = getStarPercent(
        starsDistribution[star as keyof StarsDistribution],
        starsCount,
      )
      return (
        <Grid container key={star}>
          <StyledProgress item>
            <LinearProgressWithLabel
              variant="determinate"
              value={value}
              label={star}
            />
          </StyledProgress>
        </Grid>
      )
    })

  return <div data-testid="star-distribution-container">{starRow}</div>
}

export default StarDistribution
