import { useState } from 'react'
import { Input } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CancelCircleIcon } from '@enterprise-ui/icons'
import { Button } from '@enterprise-ui/canvas-ui-react'
import { FileContainer, ItemTypeButtons } from './styles'
import { MEGABYTES_AS_BYTES_1 } from 'constants/sizeLimits'
import { XLXS_CONTENT_TYPE } from 'constants/fileTypes'
import { Typography } from '@mui/material'
import { submitReport } from 'services/reports'
import { ReportType } from 'types/Report'
import { useDispatch, useSelector } from 'react-redux'
import { currentSellerId } from 'store/selectors'
import { showNotification } from 'store/notification/reducer'
import { useStateValue } from 'stateManager/StateProvider'

const BulkItemUpload = ({ onRequestClose }: { onRequestClose: Function }) => {
  const [file, setFile] = useState<File>()
  const [isPending, setIsPending] = useState<boolean>()
  const [errorMessage, setErrorMessage] = useState('')

  const dispatch = useDispatch()
  const sellerId = useSelector(currentSellerId)

  const { updateState } = useStateValue()

  const handleChange = (event: any) => {
    if (event.target.files[0]?.size <= MEGABYTES_AS_BYTES_1) {
      setFile(event.target.files[0])
      setErrorMessage('')
    } else {
      setErrorMessage('File size exceeds 1MB. Please reduce and retry.')
    }
  }

  const onClearFileButtonClick = () => {
    setFile(undefined)
  }

  const handleFileUpload = async () => {
    if (isPending) {
      return
    }
    setIsPending(true)
    try {
      const savedReport = await submitReport({
        type: ReportType.BULK_LEGALIZATION,
        reportInput: file,
        sellerId: sellerId,
      })

      setErrorMessage('')
      if (savedReport) {
        dispatch(
          showNotification({
            isShown: true,
            message: 'Upload successful!',
            severity: 'success',
            autoClose: true,
          }),
        )
        updateState({
          isNewFileUpload: true,
        })
        setIsPending(false)
        onRequestClose()
      }
    } catch (e) {
      setIsPending(false)
      setFile(undefined)
      setErrorMessage('Server error. Please retry.')
      dispatch(
        showNotification({
          isShown: true,
          message: 'Server error. Please retry.',
          autoClose: true,
        }),
      )
    }
  }

  return (
    <>
      <div className="hc-bg-grey07 hc-mt-md hc-mb-md hc-pa-md ">
        {file ? (
          <FileContainer className="hc-bg-contrast-weak">
            <Typography className="hc-fs-sm hc-pl-md hc-pt-min">
              {file?.name}
            </Typography>
            <Button onClick={onClearFileButtonClick} type="ghost">
              <EnterpriseIcon icon={CancelCircleIcon} />
            </Button>
          </FileContainer>
        ) : (
          <div>
            <Input.DropArea
              accept={`.csv, ${XLXS_CONTENT_TYPE}`}
              fullwidth={true}
              onUpdate={handleChange}
              clickOnly
              dropText="Upload excel sheet"
              instructionText="Please ensure the spreadsheet uploaded has ‘item_type’ column"
            />
          </div>
        )}

        {errorMessage && (
          <div className="hc-fs-sm hc-mt-min hc-clr-error hc-bg-grey07">
            {errorMessage}
          </div>
        )}
      </div>
      <ItemTypeButtons className="display-flex hc-mt-md ">
        <Button
          className="hc-mr-dense hc-pr-expanded hc-pl-expanded"
          type="secondary"
          onClick={onRequestClose}
        >
          Cancel
        </Button>
        <Button
          className="hc-mr-dense hc-pr-expanded hc-pl-expanded"
          type="primary"
          disabled={!file}
          onClick={handleFileUpload}
          isLoading={isPending}
        >
          Save
        </Button>
      </ItemTypeButtons>
    </>
  )
}

export default BulkItemUpload
