import React from 'react'
import { useDispatch } from 'react-redux'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import { BrandTypeahead } from 'components/common/Typeahead'

import { addProtectedLegalization } from 'services/legalizations'
import { closeDialog } from 'store/dialog/actionCreator'
import { Brand } from 'types/Brand'

interface Props {
  isOpen: boolean
}

const AddProtectedBrand = ({ isOpen }: Props) => {
  const dispatch = useDispatch()
  const [brand, setBrand] = React.useState('')

  const handleSubmit = () => {
    addProtectedLegalization(brand).then(() => {
      dispatch(closeDialog())
    })
  }

  const handleBrandChange = (brand: Nullable<Brand>) => {
    if (!brand) return

    setBrand(brand.brandId.toString())
  }

  return (
    <DialogContainer
      title="Add Protected Brand"
      isOpen={isOpen}
      onSubmit={handleSubmit}
      disableScroll
    >
      <BrandTypeahead onChange={handleBrandChange} />
    </DialogContainer>
  )
}

export default AddProtectedBrand
