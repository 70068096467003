import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import Input from 'components/common/Input'
import { Validation } from 'types/Validation'

export interface Props {
  editItemLimit: string
  validation: Validation
  isValid: boolean
  onRequestCancel: () => void
  onRequestLimitChange: (value: string) => void
  onRequestSubmit: () => void
}

export const EditApprovalSettingsAside = ({
  validation,
  isValid,
  editItemLimit,
  onRequestCancel,
  onRequestLimitChange,
  onRequestSubmit,
}: Props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>
          This is the maximum number of variation parents and standalone items
          that a partner can submit.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Input
          id="editItemLimit"
          data-testid="editItemLimit"
          label="Limit"
          name="editItemLimit"
          type="number"
          value={editItemLimit}
          validation={validation}
          onChange={onRequestLimitChange}
        />
      </Grid>
      <Grid item xs={12}>
        <DialogActions>
          <Button
            color="primary"
            onClick={onRequestCancel}
            data-testid="cancel"
          >
            cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onRequestSubmit}
            disabled={!isValid}
            data-testid="submit"
          >
            save
          </Button>
        </DialogActions>
      </Grid>
    </Grid>
  )
}

export default EditApprovalSettingsAside
