import DataList from 'components/common/DataList'

import isEmpty from 'lodash/fp/isEmpty'
import sortBy from 'lodash/fp/sortBy'

import styled from '@emotion/styled'

import Typography from '@mui/material/Typography'

import TabularData, { FieldList } from 'components/common/TabularData'
import RequiredContent from 'components/common/RequiredContent'
import { formatDateMDYT } from 'components/common/EnhancedTable/formatters'

import {
  getCodeName,
  getCategoryCodes,
  getBuildingLeadTimeName,
  getOrderedServiceLevels,
} from 'services/codes'
import { militaryToPeriodTime } from 'services/dateService'
import { formatLocaleNumber } from 'services/formatNumber'
import { getMaximumRolloverDays } from 'services/shippingMethod'
import { timezoneAbbreviation } from 'services/timezoneCode'

import {
  CATEGORY_CARRIER,
  CATEGORY_SHIPPING_SERVICE,
  CATEGORY_SERVICE_LEVEL_AGREEMENT,
  DEFAULT_SERVICE_LEVEL_AGREEMENT_ID,
} from 'constants/categories'
import { ShippingService, ShipNode, VendorCategory } from 'types/Seller'

const StyledDiv = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))

export interface Props {
  shipNode: ShipNode
  vendorCategories: VendorCategory[]
  hasBuildingLeadTimeIdOverride: boolean
  timezone?: string
}

export const CarrierInfo = ({
  shipNode,
  vendorCategories,
  hasBuildingLeadTimeIdOverride,
  timezone,
}: Props) => {
  const { carriers, service_levels } = shipNode
  const sortedCarriers = sortBy('id', carriers)
  const sortedServiceLevels = getOrderedServiceLevels(service_levels)
  const timezoneAbbr = timezoneAbbreviation(timezone)
  if (isEmpty(sortedCarriers) && isEmpty(sortedServiceLevels)) {
    return <RequiredContent description="None provided." />
  }

  const buildingLeadTimeName = getBuildingLeadTimeName(
    vendorCategories,
    shipNode.building_lead_time_id || DEFAULT_SERVICE_LEVEL_AGREEMENT_ID,
  )

  const carrierCodes = getCategoryCodes(vendorCategories, CATEGORY_CARRIER)

  const serviceCodes = getCategoryCodes(
    vendorCategories,
    CATEGORY_SHIPPING_SERVICE,
  )
  const serviceLevelCodes = getCategoryCodes(
    vendorCategories,
    CATEGORY_SERVICE_LEVEL_AGREEMENT,
  )

  const carrierNames =
    sortedCarriers.length > 0
      ? sortedCarriers
          .map((carrier) => getCodeName(carrier.id, carrierCodes))
          .join(', ')
      : 'N/A'

  const maxOrdersPerDay = shipNode.max_orders_per_day
    ? formatLocaleNumber(shipNode.max_orders_per_day)
    : 'N/A'

  const serviceLevelFieldList = [
    {
      key: 'id',
      displayName: 'Service Level*',
      formatCell: ({ id }) => getCodeName(id, serviceCodes),
    },
    {
      key: 'generic_time_in_transit',
      displayName: 'Time in Transit*',

      formatCell: ({ generic_time_in_transit }) =>
        `${generic_time_in_transit} day${
          generic_time_in_transit! > 1 ? 's' : ''
        }`,
    },
    {
      key: 'cut_off_time',
      displayName: `Site Cutoff Time (${timezoneAbbr})*`,

      formatCell: ({ cut_off_time }) => militaryToPeriodTime(cut_off_time),
    },
    {
      key: 'buildingLeadTimeName',
      displayName: 'Building Lead Time',

      formatCell: ({ building_lead_time_id }) =>
        getCodeName(building_lead_time_id, serviceLevelCodes),
    },
    {
      key: 'days_to_add',
      displayName: 'Rollover Days',
      tooltip:
        'If order capacity per day is exceeded, rollover is used to adjust BLT to manage guest delivery expectations',
      formatCell: ({ days_to_add }) => {
        if (!days_to_add) return ''

        if (days_to_add === 1) {
          return `${days_to_add} Day`
        } else {
          return `${days_to_add} Days`
        }
      },
    },
    {
      key: 'max_days_to_add',
      displayName: 'Max Rollover Days',
      tooltip:
        'To ensure the best guest experience, BLT cannot be system adjusted beyond this limit',
      formatCell: ({ days_to_add, max_days_to_add }) => {
        if (!days_to_add || !max_days_to_add) return ''

        const days = getMaximumRolloverDays(max_days_to_add, days_to_add)
        const dayText = max_days_to_add === 1 ? 'Day' : 'Days'
        const rolloverText = days === 1 ? 'Rollover' : 'Rollovers'
        return `${max_days_to_add} ${dayText} (${days} ${rolloverText})`
      },
    },
  ] as FieldList<ShippingService>[]

  if (hasBuildingLeadTimeIdOverride) {
    serviceLevelFieldList.push({
      key: 'building_lead_time_id_override',
      displayName: 'System Adjusted BLT',
      formatCell: ({ building_lead_time_id_override }) =>
        building_lead_time_id_override
          ? getCodeName(building_lead_time_id_override, serviceLevelCodes)
          : '',
    })

    serviceLevelFieldList.push({
      key: 'last_adjusted',
      displayName: 'BLT Last Adjusted',
      formatCell: formatDateMDYT('last_adjusted'),
    })
  }

  return (
    <>
      <StyledDiv>
        <DataList
          data={[
            { title: 'Carriers*:', value: carrierNames },
            { title: 'Order Capacity Per Day:', value: maxOrdersPerDay },
          ]}
        />
      </StyledDiv>
      <StyledDiv>
        <TabularData
          data={sortedServiceLevels}
          fieldList={serviceLevelFieldList}
        />
      </StyledDiv>
      <Typography>
        If a service level does not have a building lead time,{' '}
        {buildingLeadTimeName} will be used as a default when within order
        capacity.
      </Typography>
    </>
  )
}

export default CarrierInfo
