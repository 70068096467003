import axios from 'axios'

import apiConfig from 'config/apiConfig'

import { ReviewIndicator, ReviewIndicators } from 'types/Seller'

export const isReviewed = (
  indicators: ReviewIndicator[] | undefined,
  target: ReviewIndicators,
): boolean => {
  if (!indicators) return false

  const indicator = indicators.find((indicator) => indicator.name === target)

  return !indicator ? false : indicator.reviewed
}

export const areAllReviewed = (indicators: ReviewIndicator[] | undefined) => {
  if (!indicators) return false

  return indicators.every((indicator) => indicator.reviewed)
}

export const updateReviewIndicators = (
  sellerId: string,
  indicator: ReviewIndicators,
  reviewed: boolean,
) => {
  return axios
    .put(
      `${apiConfig.sms}/sellers/${sellerId}/review_indicators/${indicator}`,
      { reviewed },
    )
    .then((res) => res.data)
}
