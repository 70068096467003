import { MerchandiseHierarchy, UpdatedPromotion } from 'types/Promotion'
import { Heading } from '@enterprise-ui/canvas-ui-react'

import startCase from 'lodash/fp/startCase'

import {
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
  formatDate,
} from 'services/dateService'

import {
  StyledIVPromotionDetailsCard,
  StyledPromotionDetailsHeading,
} from '../styles'

export const IvyPromotionDetails = ({
  promotion,
}: {
  promotion?: UpdatedPromotion
}) => {
  const formatDiscount = (discountType: string, discountValue: number) => {
    if (!discountType) return
    if (discountType === 'PercentageOff') return `${discountValue}%`
    else if (discountType === 'FixedPrice' || discountType === 'DollarOff')
      return `$${discountValue}`
  }

  const getDivisions = (
    merchandiseHierarchies: Array<MerchandiseHierarchy>,
  ) => {
    const divisions: string[] = []
    merchandiseHierarchies.forEach((merchandiseHierarchy) => {
      const divisionName = merchandiseHierarchy.division_name
      if (divisionName) {
        divisions.push(startCase(divisionName.toLowerCase()))
      }
    })

    return divisions.length ? divisions.join(', ') : 'None'
  }

  return (
    <StyledIVPromotionDetailsCard className="hc-pa-normal">
      <StyledPromotionDetailsHeading size={4}>
        IVY Promotion Details
      </StyledPromotionDetailsHeading>
      <Heading className="hc-mt-normal" size={6}>
        IVY Promo ID & Name:
      </Heading>
      <p data-testid="promotion-id-and-name">
        {promotion?.promotion_id} - {promotion?.name}
      </p>
      <Heading className="hc-mt-normal" size={6}>
        Priority:
      </Heading>
      <p>{promotion?.priority_label ? promotion?.priority_label : 'None'}</p>
      <Heading className="hc-mt-normal" size={6}>
        Umbrella (Promo Message):
      </Heading>
      <p>{promotion?.umbrella_label ? promotion?.umbrella_label : 'None'}</p>
      <Heading className="hc-mt-normal" size={6}>
        Discount:
      </Heading>
      <p data-testid="discount">
        {startCase(promotion?.details?.[0]?.discount_type ?? '')}{' '}
        {formatDiscount(
          promotion?.details?.[0]?.discount_type ?? '',
          promotion?.details[0]?.discount_value ?? 0,
        ) ?? 'None'}
      </p>
      <Heading className="hc-mt-normal" size={6}>
        Division:
      </Heading>
      <p>{getDivisions(promotion?.merchandise_hierarchies ?? [])}</p>
      <p className="hc-mt-normal">
        <strong>Start Date: </strong>
        {formatDate(
          promotion?.start_date as string,
          DATE_FORMAT_MONTH_DAY_YEAR_TIME,
        )}
      </p>
      <p>
        <strong>End Date: </strong>
        {formatDate(
          promotion?.end_date as string,
          DATE_FORMAT_MONTH_DAY_YEAR_TIME,
        )}
      </p>
    </StyledIVPromotionDetailsCard>
  )
}

export default IvyPromotionDetails
