import DialogContainer from 'components/common/Dialog/DialogContainer'
import Image from 'components/common/Image'
import TabularData from 'components/common/TabularData'

import { getFieldList, ScoreInfo } from './dialogHelpers'
import { InsightDialogProps } from '../InsightCard'

interface Props extends InsightDialogProps {
  isOpen: boolean
  title: string
  imagePath: string
  data: ScoreInfo[]
}

const fieldList = getFieldList()

export const ItemInsightDialog = ({
  isOpen,
  title,
  imagePath,
  data,
}: Props) => {
  return (
    <DialogContainer title={title} isOpen={isOpen} closeButtonText="close">
      <div>
        <Image src={imagePath} alt="Pie chart for content health score" />
      </div>
      <TabularData fieldList={fieldList} data={data} />
    </DialogContainer>
  )
}

export default ItemInsightDialog
