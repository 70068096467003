import { useEffect } from 'react'

import { useTheme } from '@amcharts/amcharts4/core'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { error } from 'config/themeConfig'

import DisplayCard from 'components/common/DisplayCard'

import { createGanttChart, disposeAllAmChartsAtOnce } from 'services/amCharts'
import { trackCustomEvent } from 'services/fireflyInsights'

import { getSeasonalEventsForGanttChart } from 'services/seasonalEvents'
import {
  getDateAfter,
  getDateOneYearFromToday,
  isoStringStartOfDay,
  startOfDay,
  isoStringEndOfDay,
  endOfDay,
  DATE_PICKER_FORMAT,
} from 'services/dateService'

import { FireflyEvent } from 'types/FireflyInsights'
import { SeasonalEvent } from 'types/SeasonalEvent'

const StyledTitleContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignContent: 'center',
})

const StyledGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))
const StyledTypography = styled(Typography)({
  color: error.main,
})

export const chartId = 'gantt-chart'

type Range = {
  from: string
  to: string
  zoomStart: string
  zoomEnd: string
}

export const getChartDates = (fromDate?: Date, toDate?: Date): Range => {
  const timeUnit = 'years'
  const startDate = fromDate ?? new Date()
  const endDate = toDate ?? new Date()
  const from = isoStringStartOfDay(
    startOfDay(startDate, timeUnit),
    DATE_PICKER_FORMAT,
  )

  const to = toDate
    ? isoStringStartOfDay(endDate)
    : isoStringStartOfDay(
        getDateAfter(
          endOfDay(getDateAfter(endDate, 1, timeUnit), timeUnit),
          1,
          'days',
        ),
        DATE_PICKER_FORMAT,
      )

  const zoomStart = isoStringStartOfDay(
    startOfDay(startDate, timeUnit),
    DATE_PICKER_FORMAT,
  )

  const zoomEnd = fromDate
    ? isoStringEndOfDay(
        startOfDay(getDateAfter(endDate, 0, timeUnit), timeUnit),
        DATE_PICKER_FORMAT,
      )
    : isoStringEndOfDay(
        startOfDay(getDateAfter(endDate, 1, timeUnit), timeUnit),
        DATE_PICKER_FORMAT,
      )

  return {
    from,
    to,
    zoomStart,
    zoomEnd,
  }
}

interface Props {
  handleEventAddEdit: (seasonalEvent?: SeasonalEvent) => void
  seasonalEvents: SeasonalEvent[]
  startDate: string | undefined
  endDate: string | undefined
  pending: boolean
}

export const SeasonalEventsCalendar = ({
  seasonalEvents,
  startDate,
  endDate,
  pending,
  handleEventAddEdit,
}: Props) => {
  useTheme(am4themes_animated)

  const chartStartDate = startDate ? new Date(startDate) : new Date()
  const chartEndDate = endDate ? new Date(endDate) : getDateOneYearFromToday()
  const dates = getChartDates(chartStartDate, chartEndDate)

  const chartSeasonalEvents = getSeasonalEventsForGanttChart(seasonalEvents)

  useEffect(() => {
    trackCustomEvent(FireflyEvent.EVENTS_CALENDAR, 'event', 'click')
  }, [])

  useEffect(() => {
    // Cleanup charts from previous render to avoid memory leaks
    // https://www.amcharts.com/docs/v4/tutorials/chart-was-not-disposed/#Disposing_all_charts_at_once
    disposeAllAmChartsAtOnce()

    if (chartSeasonalEvents.length) {
      createGanttChart({
        id: chartId,
        data: chartSeasonalEvents,
        minDate: dates.from,
        maxDate: dates.to,
        zoomStartDate: dates.zoomStart,
        zoomEndDate: dates.zoomEnd,
        onClick: (seasonalEvent?: SeasonalEvent) => {
          trackCustomEvent(
            FireflyEvent.EVENTS_CALENDAR_DETAIL,
            'event',
            'click',
          )
          handleEventAddEdit(seasonalEvent)
        },
      })
    }

    return () => {
      disposeAllAmChartsAtOnce()
    }
  }, [chartSeasonalEvents, handleEventAddEdit, dates])

  return (
    <>
      <StyledGrid spacing={2} container>
        <Grid item xs={12}>
          <StyledTitleContainer>
            <Typography>
              Event dates are approximate and subject to change by Target.
            </Typography>
          </StyledTitleContainer>
        </Grid>
      </StyledGrid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DisplayCard title="Seasonal Events" hasHeader>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {chartSeasonalEvents.length === 0 && !pending && (
                  <StyledTypography>
                    No events found during the specified date range, please
                    modify your selection
                  </StyledTypography>
                )}
                {chartSeasonalEvents.length > 0 && (
                  <Box
                    sx={{ width: '100%' }}
                    data-testid={chartId}
                    id={chartId}
                  />
                )}
              </Grid>
            </Grid>
          </DisplayCard>
        </Grid>
      </Grid>
    </>
  )
}

export default SeasonalEventsCalendar
