import { Suspense } from 'react'

import { DataType, formatValue, ValueFormats } from './SalesMetricsCardContent'

import LineChart from 'components/common/Charts/LineChart'

interface Props {
  graphData?: DataType[]
  isCompare: boolean
  valueFormat: ValueFormats
}

const SalesMetricsGraph = ({ graphData, isCompare, valueFormat }: Props) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <LineChart
        data={graphData}
        tooltipFormatter={(value: any, name: string, props: any) =>
          isCompare
            ? [
                formatValue(value as number, valueFormat),
                name === 'base'
                  ? props.payload.date
                  : props.payload.compareDate,
              ]
            : [formatValue(value as number, valueFormat)]
        }
        tooltipLabelFormatter={(label: any) => {
          return isCompare ? '' : label
        }}
        ariaLabelCallback={(xValue, yValue) => {
          return `${xValue} ${formatValue(yValue, valueFormat)}`
        }}
      />
    </Suspense>
  )
}

export default SalesMetricsGraph
