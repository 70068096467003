import axios, { AxiosResponse } from 'axios'

import apiConfig from 'config/apiConfig'
import { getPageable, Direction } from './pageableHelper'
import {
  ProductValidationRule,
  ResultingStatus,
} from 'types/ProductValidationRule'

export async function getProductValidationRules(
  resultingStatus: ResultingStatus,
  sellerId?: string,
): Promise<ProductValidationRule[]> {
  const config = {
    params: {
      resulting_status: resultingStatus,
      ...getPageable({
        direction: Direction.ASC,
        orderBy: 'error_code',
        page: 0,
        perPage: 100,
      }),
    },
  }

  const url = sellerId
    ? `${apiConfig.sms}/sellers/${sellerId}/product_validation_rules`
    : `${apiConfig.sms}/product_validation_rules`

  const { data } = await axios.get(url, config)

  return data
}

export async function createProductValidationRule(
  sellerId: string,
  errorCode: string,
): Promise<AxiosResponse<ProductValidationRule>> {
  const validationRule = {
    seller_id: sellerId,
    error_code: errorCode,
    must_be_on_required: false,
    allow_new_versions: false,
    resulting_status: ResultingStatus.VALIDATED,
  }

  const response = await axios.post(
    `${apiConfig.sms}/sellers/${sellerId}/product_validation_rules`,
    validationRule,
  )

  return response
}

export async function deleteProductValidationRule(
  sellerId: string,
  errorId: string,
): Promise<AxiosResponse<any>> {
  const response = await axios.delete(
    `${apiConfig.sms}/sellers/${sellerId}/product_validation_rules/${errorId}`,
  )
  return response
}
