/**
 * @param componentName: Key in map of Dialog Content Components. See Dialog.js
 * @param componentProps: Object of props to pass to dialog
 * @returns {{type, payload: string}}
 */
import { DialogEnum } from 'components/common/Dialog'

export const OPEN_DIALOG: string = 'OPEN_DIALOG'

export interface OpenDialogParams {
  dialogEnum: DialogEnum
  componentProps?: any
  closeCallback?: () => void
}

export interface OpenDialogAction {
  readonly type: typeof OPEN_DIALOG
  payload: {
    dialogEnum: DialogEnum
    componentProps: {}
    closeCallback?: () => void
  }
}

export const CLOSE_DIALOG = 'CLOSE_DIALOG'

export interface CloseDialogAction {
  readonly type: typeof CLOSE_DIALOG
}

export const UNMOUNT_DIALOG = 'UNMOUNT_DIALOG'

export interface UnmountDialogAction {
  readonly type: typeof UNMOUNT_DIALOG
}

export function openDialog({
  dialogEnum,
  componentProps = {},
  closeCallback,
}: OpenDialogParams): OpenDialogAction {
  return {
    type: OPEN_DIALOG,
    payload: { dialogEnum, componentProps, closeCallback },
  }
}

export function closeDialog(): CloseDialogAction {
  return {
    type: CLOSE_DIALOG,
  }
}

export function unmountDialog(): UnmountDialogAction {
  return {
    type: UNMOUNT_DIALOG,
  }
}

export type DialogAction =
  | OpenDialogAction
  | CloseDialogAction
  | UnmountDialogAction
