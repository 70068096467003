import { useCallback, useEffect, useReducer } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as yup from 'yup'

import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'

import { ActionBar } from 'components/ActionBar'
import { DialogEnum } from 'components/common/Dialog'
import FullScreenDialogContainer from 'components/common/Dialog/FullScreenDialogContainer'
import FullscreenDialogContent from 'components/common/Dialog/FullscreenDialogContent'
import TabularData, { FieldList } from 'components/common/TabularData'
import TitleBar from 'components/common/TitleBar/TitleBar'

import { grey, success, warning } from 'config/themeConfig'

import { isOneOfUserRoles } from 'services/authorization'
import { getMarketplaceProducts } from 'services/items'
import {
  USER_ROLE_ADMIN,
  USER_ROLE_ITEM_MANAGER,
  USER_ROLE_OPS,
  USER_ROLE_PROGRAM_MANAGER,
} from 'services/roles'
import {
  getSellerAutoApproval,
  getSellerQuotas,
  updateAutoApprovalSettings,
  updateSellerQuotas,
} from 'services/sellerListingSettings'
import { validationHandler } from 'services/validation'

import { closeDialog, openDialog } from 'store/dialog/actionCreator'
import { currentSellerId, getMemberOf } from 'store/selectors'

import Attribute from 'types/Attribute'
import {
  ListingStatus,
  MarketplaceProduct,
  ValidationStatus,
  ProductSearchParams,
} from 'types/Item'
import { CollectionResponse } from 'types/Response'

import AddItemTypeHoldsAside from './AddItemTypeHoldsAside'
import EditApprovalSettingsAside from './EditApprovalSettingsAside'
import reducer, {
  addNewItemTypeHold,
  closeAside,
  initialReducerState,
  ModifyLegalization,
  openLimitAside,
  openTypeAside,
  removeNewItemTypeHold,
  setAutoApprovalEnabled,
  setItemLimit,
  setItemTypeHolds,
  setPending,
  setValidation,
  updateOnInputChange,
} from './EditApprovalSettingsReducer'

const StyledRow = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))
const StyledBold = styled(Typography)({
  fontWeight: 'bold',
})
const StyledApprovalStatus = styled(Typography)({
  color: grey[700],
})
const StyledApprovalStatusContainer = styled('span', {
  shouldForwardProp: (prop) => prop !== 'autoApprovalEnabled',
})<{ autoApprovalEnabled: boolean }>(({ theme, autoApprovalEnabled }) => ({
  textAlign: 'center',
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1),
  marginLeft: theme.spacing(1),
  backgroundColor: autoApprovalEnabled ? success.light : warning.light,
}))

export interface Props {
  currentItemCount: number
  isOpen: boolean
  autoApprovalEnabled: boolean
}

const allowedItemLimitEditRoles = [
  USER_ROLE_ADMIN,
  USER_ROLE_OPS,
  USER_ROLE_ITEM_MANAGER,
  USER_ROLE_PROGRAM_MANAGER,
]
const allowedAutoApprovalEditRoles = [
  USER_ROLE_ADMIN,
  USER_ROLE_OPS,
  USER_ROLE_ITEM_MANAGER,
]

export const itemLimitValidationSchema = yup.object().shape({
  editItemLimit: yup
    .string()
    .matches(
      /^(?:100000|[1-9]\d{0,4})$/,
      'Item Limit must be between 1 and 100000',
    )
    .max(6)
    .label('Item Limit'),
})

export const EditApprovalSettingsDialog = ({
  currentItemCount,
  isOpen,
}: Props) => {
  const reduxDispatch = useDispatch()

  const memberOf = useSelector(getMemberOf)
  const sellerId = useSelector(currentSellerId)

  const [state, dispatch] = useReducer(reducer, initialReducerState)

  const holds = state.itemTypeHolds.filter((itemType) => !itemType.remove)
  const removals = state.itemTypeHolds.filter((itemType) => itemType.remove)

  const fetchSellerListingSettings = useCallback(async () => {
    if (sellerId) {
      dispatch(setPending(true))

      const response = await getSellerAutoApproval(sellerId)
      dispatch(setAutoApprovalEnabled(response.enabled))
      dispatch(
        setItemTypeHolds(
          response.blockedList.map((item) => {
            return { ...item, remove: false }
          }),
        ),
      )
      const limit = await getSellerQuotas(sellerId)
      dispatch(setItemLimit(limit.toString()))
      dispatch(setPending(false))
    }
  }, [sellerId])

  useEffect(() => {
    fetchSellerListingSettings()
  }, [fetchSellerListingSettings])

  const confirmAction = (): boolean => {
    const { isAsideOpen } = state
    if (!isAsideOpen) {
      return true
    }
    const isDirty = state.editItemLimit !== state.itemLimit

    return !isDirty || window.confirm('You have unsaved changes, are you sure?')
  }

  const handleRequestClose = () => {
    if (confirmAction()) {
      reduxDispatch(closeDialog())
    }
  }

  const handleItemTypeHoldCancel = () => {
    let confirm = true

    if (state.itemTypeHolds.some((item) => item.remove)) {
      confirm = window.confirm(
        'You have unsaved Item Type removals, are you sure you want to cancel?',
      )
    }

    if (confirm) {
      dispatch(
        setItemTypeHolds(
          state.itemTypeHolds.map((item) => {
            return { ...item, remove: false }
          }),
        ),
      )
    }
  }

  const handleCloseAside = () => {
    dispatch(closeAside())
  }

  const handleItemLimitEdit = () => {
    if (confirmAction()) {
      dispatch(openLimitAside())

      if (removals.length > 0) {
        resetItemTypeHoldsCheckboxes()
      }
    }
  }

  const handleChange = (value: string) => {
    dispatch(updateOnInputChange(value))

    const { validation, isValid } = validationHandler(
      itemLimitValidationSchema,
      {
        editItemLimit: +value,
      },
    )

    dispatch(setValidation({ validation, isValid }))
  }

  const handleSubmit = () => {
    if (state.isValid && sellerId) {
      updateSellerQuotas(sellerId, +state.editItemLimit)
      fetchSellerListingSettings()
      dispatch(closeAside())
    }
  }

  const handleAddNew = () => {
    if (confirmAction()) {
      dispatch(openTypeAside())

      if (removals.length > 0) {
        resetItemTypeHoldsCheckboxes()
      }
    }
  }

  const handleReprocess = (params: ProductSearchParams) => {
    getMarketplaceProducts(params, { page: 0, perPage: 1 }).then(
      (response: CollectionResponse<MarketplaceProduct>) => {
        if (response.total > 0) {
          reduxDispatch(
            openDialog({
              dialogEnum: DialogEnum.BULK_RE_ENRICH,
              componentProps: {
                searchParams: params,
                filteredItems: response.total,
              },
            }),
          )
        }
      },
    )
  }

  const handleItemTypeSave = async () => {
    if (sellerId) {
      let confirm = true

      confirm = window.confirm(
        `Are you sure you want to remove the following Item Type Holds?: ${removals
          .map((removal) => removal.primary_attribute.name)
          .join(', ')}`,
      )

      if (confirm) {
        const holdItemTypeIds = holds.map((hold) => hold.id)
        const removalItemTypeIds = removals.map((removal) => removal.id)
        const autoApprovalSettings = {
          enabled: state.autoApprovalEnabled,
          item_types_blacklist: holdItemTypeIds,
        }

        // existing legalizations have ids
        // @ts-ignore
        await updateAutoApprovalSettings(sellerId, autoApprovalSettings)
        if (autoApprovalSettings.enabled) {
          handleReprocess({
            listing_status: ListingStatus.PENDING,
            validation_status: ValidationStatus.VALIDATED,
            seller_id: sellerId,
            // existing legalizations have ids
            // @ts-ignore
            item_type_id: removalItemTypeIds,
          })
        }
        await fetchSellerListingSettings()
      }
    }
  }

  const handlePrimaryChange = (attribute: Nullable<Attribute>) => {
    if (attribute) {
      dispatch(addNewItemTypeHold(attribute))
    }
  }

  const handlePrimaryRemoved = (key: string) => {
    dispatch(removeNewItemTypeHold(key))
  }

  const handleSubmitNewHold = async (autoApproval: boolean) => {
    if (sellerId) {
      dispatch(setAutoApprovalEnabled(autoApproval))
      const holds = state.itemTypeHolds.map((itemType) => itemType.id || '')

      const newHolds = state.newItemTypeHolds.map(
        (newItemType) => newItemType.id,
      )

      const autoApprovalSettings = {
        enabled: autoApproval,
        item_types_blacklist: holds.concat(newHolds),
      }
      await updateAutoApprovalSettings(sellerId, autoApprovalSettings)
      await fetchSellerListingSettings()
      if (autoApproval) {
        handleReprocess({
          listing_status: ListingStatus.PENDING,
          validation_status: ValidationStatus.VALIDATED,
          seller_id: sellerId,
        })
      } else {
        dispatch(closeAside())
      }
    }
  }

  const onCheckBoxChange =
    (item: ModifyLegalization) =>
    (_event: React.FormEvent<HTMLInputElement>, checked: boolean) => {
      const updatedItemTypeHolds = state.itemTypeHolds.map((current) => {
        if (current.primary_attribute.id === item.primary_attribute.id) {
          return {
            ...current,
            remove: checked,
          }
        } else {
          return current
        }
      })

      dispatch(setItemTypeHolds(updatedItemTypeHolds))
    }

  const handleCheckAll = () => {
    if (state.itemTypeHolds.length !== removals.length) {
      const updatedItemTypeHolds = state.itemTypeHolds.map((current) => {
        return {
          ...current,
          remove: true,
        }
      })

      dispatch(setItemTypeHolds(updatedItemTypeHolds))
    } else if (state.itemTypeHolds.length === removals.length) {
      const updatedItemTypeHolds = state.itemTypeHolds.map((current) => {
        return {
          ...current,
          remove: false,
        }
      })

      dispatch(setItemTypeHolds(updatedItemTypeHolds))
    }
  }

  const resetItemTypeHoldsCheckboxes = () => {
    const updatedItemTypeHolds = state.itemTypeHolds.map((current) => {
      return {
        ...current,
        remove: false,
      }
    })
    dispatch(setItemTypeHolds(updatedItemTypeHolds))
  }

  const itemTypeHoldsFieldList: FieldList<ModifyLegalization>[] = [
    {
      key: 'remove',
      width: 64,
      fixedWidth: true,
      displayName: (
        <Checkbox
          data-testid={`checkbox`}
          checked={
            state.itemTypeHolds.length > 0 &&
            state.itemTypeHolds.length === removals.length
          }
          indeterminate={
            state.itemTypeHolds.length !== removals.length &&
            removals.length > 0
          }
          disabled={state.itemTypeHolds.length === 0}
          onChange={handleCheckAll}
        />
      ),
      formatCell: (item: ModifyLegalization) => {
        return (
          <Checkbox
            data-testid={`${item.primary_attribute.id}-checkbox`}
            checked={item.remove}
            onChange={onCheckBoxChange(item)}
          />
        )
      },
    },
    {
      key: 'name',
      displayName: 'Item Type',
      formatCell: (item: ModifyLegalization) => {
        return item.primary_attribute.name
      },
    },
  ]

  const hasItemLimitEdit = isOneOfUserRoles(memberOf, allowedItemLimitEditRoles)
  const hasAutoApprovalEdit = isOneOfUserRoles(
    memberOf,
    allowedAutoApprovalEditRoles,
  )

  const selectedItemTypeHoldsMessage =
    removals.length === 1
      ? `${removals.length} Item Type Hold Selected`
      : `${removals.length} Item Type Holds Selected`

  return (
    <FullScreenDialogContainer
      title="Manage Item Limit & Auto Approval"
      onRequestClose={handleRequestClose}
      isOpen={isOpen}
      isAsideOpen={state.isAsideOpen}
      asideTitle={state.asideTitle}
      aside={
        state.isItemLimitAside ? (
          <EditApprovalSettingsAside
            validation={state.validation}
            isValid={state.isValid}
            editItemLimit={state.editItemLimit}
            onRequestCancel={handleCloseAside}
            onRequestLimitChange={handleChange}
            onRequestSubmit={handleSubmit}
          />
        ) : (
          <AddItemTypeHoldsAside
            newItemTypeHolds={state.newItemTypeHolds}
            onRequestCancel={handleCloseAside}
            onItemTypeAdd={handlePrimaryChange}
            onItemTypeChipRemove={handlePrimaryRemoved}
            onRequestSubmitNewHold={handleSubmitNewHold}
            autoApproval={state.autoApprovalEnabled}
          />
        )
      }
    >
      <TitleBar
        title="Item Limit"
        actionButtons={
          hasItemLimitEdit
            ? [
                <Button
                  key="edit-limit-button"
                  data-testid="edit-limit-button"
                  color="primary"
                  onClick={handleItemLimitEdit}
                >
                  edit limit
                </Button>,
              ]
            : undefined
        }
      />
      <StyledRow>
        <StyledBold display="inline" variant="h4">
          Item Limit: {state.itemLimit}
        </StyledBold>
      </StyledRow>
      <StyledRow>
        <StyledBold>Current Count (VAPs & SAs): {currentItemCount}</StyledBold>
      </StyledRow>
      <StyledRow>
        Partner item limit is the maximum number of variation parents and
        standalone items that can be submitted at any time, regardless of
        autoapproval.
      </StyledRow>
      <StyledRow>
        <TitleBar
          title="Auto Approval"
          actionButtons={
            hasAutoApprovalEdit
              ? [
                  <Button
                    key="edit-auto-approval"
                    data-testid="edit-auto-approval"
                    color="primary"
                    onClick={handleAddNew}
                  >
                    turn {state.autoApprovalEnabled ? 'off' : 'on'} auto
                    approval
                  </Button>,
                ]
              : undefined
          }
        />
        <StyledRow>
          <StyledBold display="inline" variant="h4">
            Auto Approval:
          </StyledBold>
          <StyledApprovalStatusContainer
            autoApprovalEnabled={state.autoApprovalEnabled}
          >
            <StyledApprovalStatus display="inline" variant="overline">
              {state.autoApprovalEnabled ? 'ON' : 'OFF'}
            </StyledApprovalStatus>
          </StyledApprovalStatusContainer>
        </StyledRow>
        <StyledRow>
          When auto approval is off, all submitted items are subject to manual
          review. When auto approval is on, submitted items that are error-free
          will be automatically approved.
        </StyledRow>
      </StyledRow>

      <TitleBar
        title="Item Type Holds"
        actionButtons={
          hasAutoApprovalEdit
            ? [
                <Button
                  key="add-new-button"
                  data-testid="add-new-button"
                  color="primary"
                  onClick={handleAddNew}
                >
                  add item type hold
                </Button>,
              ]
            : undefined
        }
      />

      <FullscreenDialogContent
        subtext={
          'When auto approval is on, error-free submitted items of the following item types will be subjected to manual review'
        }
      >
        <TabularData
          data={state.itemTypeHolds}
          fieldList={itemTypeHoldsFieldList}
        />
        {removals.length > 0 && (
          <ActionBar
            message={selectedItemTypeHoldsMessage}
            handleDelete={handleItemTypeSave}
            handleClose={handleItemTypeHoldCancel}
          />
        )}
      </FullscreenDialogContent>
    </FullScreenDialogContainer>
  )
}

export default EditApprovalSettingsDialog
