import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import Grid from '@mui/material/Grid'

import ContentSpacer from 'components/common/ContentSpacer'
import DialogContainer from 'components/common/Dialog/DialogContainer'
import Input from 'components/common/Input'
import Select from 'components/common/Select'

import { addSellerSource } from 'services/seller'

import { closeDialog } from 'store/dialog/actionCreator'

import { SOURCES } from '../queries'

import { SellerSource, SellerSourceStatus } from 'types/Seller'

export interface Props {
  isOpen: boolean
}

type MutationVariables = {
  description: string
  status: SellerSourceStatus
  isMultiShipNode: boolean
}

export const AddIntegrationDialog = ({ isOpen }: Props) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const [newSource, setNewSource] = useState('')
  const [multiShipNode, setMultiShipNode] = useState(false)

  const { mutate, isLoading } = useMutation<
    SellerSource,
    any,
    MutationVariables
  >(({ description, status, isMultiShipNode }) => {
    return addSellerSource(description, status, isMultiShipNode)
  })

  const handleInputChange = (value: any) => {
    setNewSource(value)
  }
  const handleMultiShipNodeChange = (multiShipNodeStatus: boolean) =>
    setMultiShipNode(multiShipNodeStatus)

  const handleInputTrim = (value: string) => {
    const trimmedValue = value.trim()
    setNewSource(trimmedValue)
  }

  const handleSubmit = () => {
    mutate(
      {
        description: newSource,
        status: 'ACTIVE',
        isMultiShipNode: multiShipNode,
      },
      {
        onSuccess: () => {
          // Passing only the query key invalidates all queries with the same key
          // Can take an array and will invalidate only queries that match each element in the array.
          // Ex: queryClient.invalidateQueries(['sources', page, perPage])
          queryClient.invalidateQueries([SOURCES])
          dispatch(closeDialog())
        },
      },
    )
  }

  return (
    <DialogContainer
      data-testid="add-integration-dialog"
      isOpen={isOpen}
      title="Add Integration"
      isPending={isLoading}
      onSubmit={handleSubmit}
      isSubmitDisabled={newSource.length < 1}
      subtitle="Partners use integrations to send their data to Target."
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input
            isRequired
            id="integration-name"
            data-testid="integration-name"
            label="Name"
            name="integrationName"
            isDisabled={isLoading}
            value={newSource}
            onChange={handleInputChange}
            onBlur={handleInputTrim}
          />
          <ContentSpacer />
          <Select
            isRequired
            id="multi-ship-node-status"
            name="multiShipNodeStatus"
            label="Ability to have multiple ship nodes/distribution centers"
            isDisabled={isLoading}
            onChange={handleMultiShipNodeChange}
            options={[
              {
                label: 'Enabled',
                value: true,
              },
              {
                label: 'Disabled',
                value: false,
              },
            ]}
            value={multiShipNode}
            keyName="label"
            valueName="value"
          />
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export default AddIntegrationDialog
