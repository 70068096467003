import { SyntheticEvent, useState, useEffect } from 'react'

import unionBy from 'lodash/fp/unionBy'

import InputLabel from '@mui/material/InputLabel'

import {
  searchItemTaxonomies,
  getTaxonomySearchTarget,
} from 'services/itemTaxonomies'

import Attribute, {
  ITEM_SUBTYPE,
  ITEM_TYPE,
  PRODUCT_TYPE,
} from 'types/Attribute'
import { CONTAINS, STARTS_WITH } from 'types/TaxonomySearchParams'
import { Validation } from 'types/Validation'

import Typeahead from '../Typeahead'
import ValidationErrorText from '../ValidationErrorText'
import usePrevious from 'hooks/usePrevious'

interface Props {
  placeholder?: string
  searchType?: typeof ITEM_TYPE | typeof ITEM_SUBTYPE | typeof PRODUCT_TYPE
  onChange: (value: Nullable<Attribute>) => void
  onClear?: () => void
  isDisabled?: boolean
  value?: Nullable<Attribute>
  clearOnSelect?: boolean
  name?: string
  validation?: Validation
  label?: string
  onLoadOptions?: (name: string) => Promise<Attribute[]>
  blurOnSelect?: boolean
}

const TaxonomyTypeahead = ({
  placeholder = 'Search Item Types',
  searchType,
  onChange,
  onClear,
  isDisabled,
  value: valueFromProps = null,
  clearOnSelect = false,
  name,
  validation,
  label,
  onLoadOptions,
  blurOnSelect,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedValue, setSelectedValue] =
    useState<Nullable<Attribute>>(valueFromProps)
  const [options, setOptions] = useState<Attribute[]>([])
  const [loading, setLoading] = useState(false)

  const prevValue = usePrevious(valueFromProps)

  useEffect(() => {
    if (prevValue !== valueFromProps) {
      setSelectedValue(valueFromProps)
    }

    if (!valueFromProps) {
      setOptions([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueFromProps])

  const handleLoadOptions = async (enteredValue: string) => {
    if (!enteredValue || !searchType) {
      return Promise.resolve([])
    }

    const target = getTaxonomySearchTarget(searchType)

    const params = {
      name: enteredValue,
      searchType,
      target,
    }

    const options = {
      hasCancel: false,
    }

    setLoading(true)
    const startsWithResponse = await searchItemTaxonomies(
      {
        ...params,
        nameSearchType: STARTS_WITH,
      },
      options,
    )

    const containsResponse = await searchItemTaxonomies(
      {
        ...params,
        nameSearchType: CONTAINS,
      },
      options,
    )

    const data = unionBy('id', startsWithResponse, containsResponse)

    const exactMatch = data.filter(
      (item) =>
        item.name?.toString().toLowerCase() ===
        params.name.toString().toLowerCase(),
    )

    function matches(element: Attribute) {
      if (element?.name === exactMatch[0]?.name) {
        return element
      }
    }
    const foundIndex = data.findLastIndex((item) => {
      return matches(item)
    })

    if (foundIndex >= 0) {
      data.splice(foundIndex, 1)
    }

    data.unshift(...exactMatch)

    return data
  }

  const handleInputChange = async (
    _event: SyntheticEvent,
    enteredValue: string,
    reason: string,
  ) => {
    if (
      reason === 'clear' ||
      (selectedValue && reason === 'input' && enteredValue === '')
    ) {
      if (onClear) {
        onClear()
      }

      setOptions([])
      setInputValue('')
      setSelectedValue(null)
      return
    }

    if (!clearOnSelect && reason === 'reset') {
      setInputValue(enteredValue)
      setOptions([])
      return
    }

    if (clearOnSelect && reason === 'reset') {
      setInputValue('')
      setOptions([])
      return
    }

    setInputValue(enteredValue)
    setLoading(true)
    const data = onLoadOptions
      ? await onLoadOptions(enteredValue)
      : await handleLoadOptions(enteredValue)
    setOptions(data)
    setLoading(false)
  }

  const handleSelectedOptionChange = (
    _event: SyntheticEvent,
    value: Attribute,
  ) => {
    if (!(value instanceof Array)) {
      setSelectedValue(clearOnSelect ? null : (value as Nullable<Attribute>))
      onChange(value as Nullable<Attribute>)
    }
  }

  const getOptionLabel = (attribute: Attribute) => attribute?.name ?? ''

  return (
    <>
      {label && <InputLabel shrink>{label}</InputLabel>}
      <Typeahead
        label="Item Type Filter"
        options={options}
        loading={loading}
        onInputChange={handleInputChange}
        isOptionEqualToValue={(option: Attribute, value: Nullable<Attribute>) =>
          option.id === value?.id
        }
        inputValue={inputValue}
        aria-label="Item Type Filter"
        placeholder={placeholder}
        getOptionLabel={getOptionLabel}
        filterOptions={(options: any) => options}
        onChange={handleSelectedOptionChange}
        disabled={!!isDisabled}
        value={selectedValue}
        blurOnSelect={blurOnSelect}
      />
      {validation && (
        <div data-testid="validation">
          <ValidationErrorText errors={validation} field={name} />
        </div>
      )}
    </>
  )
}

export default TaxonomyTypeahead
