import startCase from 'lodash/fp/startCase'

import { EnhancedTableFieldType } from 'components/common/EnhancedTable'
import {
  publishedLink,
  tcinLink,
} from 'components/common/EnhancedTable/formatters'
import StatusText from 'components/common/StatusText'

import { MarketplaceProduct } from 'types/Item'

export const getRelationshipsFieldList = (
  variations: string[],
): EnhancedTableFieldType<MarketplaceProduct>[] => {
  const variationFields: EnhancedTableFieldType[] = variations.map(
    (variation: string) => ({
      key: variation,
      heading: startCase(variation),
    }),
  )

  return [
    {
      key: 'tcin',
      heading: 'TCIN',
      hasSort: true,
      formatCell: tcinLink(),
    },
    ...variationFields,
    {
      key: 'inventory',
      heading: 'Inventory',
    },
    {
      key: 'published',
      heading: 'Published',
      hasSort: true,
      formatCell: publishedLink(),
    },
    {
      key: 'listing_status',
      heading: 'Latest Item Data',
      hasSort: true,
      formatCell: ({ listing_status }) => <StatusText label={listing_status} />,
    },
  ] as EnhancedTableFieldType<MarketplaceProduct>[]
}
