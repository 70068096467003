import axios from 'axios'

import apiConfig from 'config/apiConfig'
import {
  getPageable,
  getSorts,
  PagingParams,
  singlePageParams,
  SortParams,
} from './pageableHelper'

import { ITEM_REPROCESSING_LIMIT } from 'constants/items'

import {
  ItemDetailsResponse,
  MarketplaceProduct,
  ProductSearchParams,
  ProductStatus,
  SmsProduct,
  SmsProductResponse,
  UpdateProductStatusParams,
} from 'types/Item'
import { Response, CollectionResponse, SingleResponse } from 'types/Response'
import {
  Logistics,
  ProductLogistics,
  ProductLogisticsError,
  ProductLogisticsField,
} from 'types/ProductLogistics'

export function getItemDetails({
  sellerId,
  productId,
}: {
  sellerId: string
  productId: string
}): Promise<ItemDetailsResponse> {
  return Promise.all([
    getMarketplaceProducts(
      {
        product_id: productId,
      },
      singlePageParams,
    ),
    getSmsProduct({ sellerId, productId, params: { expand: 'fields' } }),
  ]).then((res) => {
    const marketplaceProduct = res[0].data[0]
    const smsProduct = res[1]

    return { marketplaceProduct, smsProduct }
  })
}

export function getSmsProduct({
  sellerId,
  productId,
  params = {},
}: {
  sellerId: string
  productId: string
  params?: object
}): Promise<SmsProduct> {
  return axios
    .get(`${apiConfig.sms}/sellers/${sellerId}/products/${productId}`, {
      params,
    })
    .then((res: SingleResponse<SmsProductResponse>) => {
      // add product_id property and set it to id value to avoid confusion
      // of the id here vs product_id in product history.
      return {
        ...res.data,
        product_id: res.data.id,
      }
    })
}

const CancelToken = axios.CancelToken
let cancel: any
export function getMarketplaceProducts(
  searchParams: ProductSearchParams,
  pagingParams: PagingParams,
  doCancel: boolean = false,
) {
  if (doCancel && cancel) {
    cancel()
  }

  const cancelToken = doCancel
    ? new CancelToken(function executor(c) {
        cancel = c
      })
    : undefined
  const sortParams = pagingParams.orderBy
    ? [{ orderBy: pagingParams.orderBy!, direction: pagingParams.direction! }]
    : []

  return getSortedMarketplaceProducts(
    searchParams,
    pagingParams.page,
    pagingParams.perPage,
    sortParams,
    cancelToken,
  )
}

// allow for multiple sorts
export function getSortedMarketplaceProducts(
  searchParams: ProductSearchParams,
  page: number,
  perPage: number,
  sortParams: SortParams[] = [],
  cancelToken: any = undefined,
) {
  const pageable = getPageable({ page, perPage })
  const { orderBy, direction, ...refinedSearchParams } = searchParams
  const config = {
    params: {
      ...refinedSearchParams,
      ...pageable,
      sort: getSorts(sortParams),
    },
    cancelToken,
  }

  return axios
    .get(`${apiConfig.marketplaceProducts}/products`, config)
    .then((res): CollectionResponse<MarketplaceProduct> => {
      const { data } = res

      return {
        data: data.results,
        total: data.total_count,
      }
    })
}

export async function getTcinsForBulkReprocessing(
  searchParams: ProductSearchParams,
): Promise<string[]> {
  const perPage = 200
  let page = 0
  let list: string[] = []

  while (list.length < ITEM_REPROCESSING_LIMIT) {
    const { data, total } = await getMarketplaceProducts(searchParams, {
      page,
      perPage,
    })

    const tcins = data.filter((item) => item.tcin).map((item) => item.tcin)

    page = page + 1
    // @ts-ignore non-tcin items already filtered out above
    list = [...list, ...tcins]

    if (list.length === total) {
      break
    }
  }

  return list
}

export async function getAllMarketplaceProducts(
  searchParams: ProductSearchParams,
): Promise<CollectionResponse<MarketplaceProduct>> {
  let page = 0
  const perPage = 200
  const firstPage = await getMarketplaceProducts(searchParams, {
    page,
    perPage,
  })
  const total = firstPage.total

  let data = [...firstPage.data]
  while (data.length < total) {
    page += 1
    const nextPage = await getMarketplaceProducts(searchParams, {
      page,
      perPage,
    })
    data = data.concat(nextPage.data)
  }

  return { data, total }
}

export async function getProductCount(
  searchParams: ProductSearchParams,
): Promise<number> {
  const config = {
    params: {
      ...searchParams,
      page: 1,
      per_page: 1,
    },
  }

  const { data } = await axios.get(
    `${apiConfig.marketplaceProducts}/products`,
    config,
  )

  return data.total_count
}

export async function getMarketplaceProductByTcin(
  tcin: string,
): Promise<MarketplaceProduct | undefined> {
  const result = await getMarketplaceProductByIdentifier({ tcin })
  return result
}

export async function getMarketplaceProductById(
  productId: string,
): Promise<MarketplaceProduct | undefined> {
  const result = await getMarketplaceProductByIdentifier({
    productId,
  })
  return result
}

export async function getMarketplaceProductByIdentifier({
  productId,
  tcin,
}: {
  productId?: string
  tcin?: string
}): Promise<MarketplaceProduct | undefined> {
  let result

  const params = {
    product_id: productId,
    tcin,
  }
  try {
    const response = await getMarketplaceProducts(params, singlePageParams)

    if (response && response.data && response.data.length) {
      result = response.data[0]
    }
  } catch (e) {
    console.error(
      `Error getting item from marketplace-products api. product id: ${productId}, tcin: ${tcin}`,
    )
  }

  return result
}

export async function getItemHistories(
  sellerId: string,
  productId: string,
): Promise<SmsProduct[]> {
  const { data }: Response<SmsProduct> = await axios.get(
    `${apiConfig.sms}/sellers/${sellerId}/product_histories`,
    {
      params: { product_id: productId },
    },
  )

  return data
}

export function updateProductStatus({
  sellerId,
  productId,
  statusId,
  productStatusUpdate,
}: UpdateProductStatusParams): Promise<ProductStatus> {
  return axios
    .put(
      `${apiConfig.sms}/sellers/${sellerId}/products/${productId}/statuses/${statusId}`,
      productStatusUpdate,
    )
    .then((res) => res.data)
}

export async function getProductLogistics({
  sellerId,
  productId,
}: {
  sellerId: string
  productId: string
}): Promise<ProductLogistics | ProductLogisticsError> {
  const config = {
    params: {},
    ignoredStatuses: [{ status: 404 }],
  }
  const response = await axios.get(
    `${apiConfig.sms}/sellers/${sellerId}/product_logistics/${productId}`,
    config,
  )

  return response.data
}

export function getFormattedProductLogistics(
  productLogistics: ProductLogistics | undefined,
): Logistics[] {
  if (!productLogistics) {
    return []
  }

  const { feedback, status, fields } = productLogistics

  const formattedData: Logistics[] = fields.map(
    (field: ProductLogisticsField) => {
      const message = feedback?.find((item) => item.source_field === field.name)
        ?.message
      const code = feedback?.find((item) => item.source_field === field.name)
        ?.code

      return {
        ...field,
        status,
        message,
        code,
      }
    },
  )

  return formattedData
}
