import React from 'react'

import styled from '@emotion/styled'
import { useTheme, Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'

import { MerchTypeAttributeTypeahead } from 'components/common/Typeahead'
import Input from 'components/common/Input'
import TabularData, { FieldList } from 'components/common/TabularData'

import { getConfidenceForMerchTypeAttributes } from 'services/confidence'

import { MerchTypeAttribute } from 'types/MerchTypeAttribute'
import { ConfidenceScore } from 'types/Confidence'

const StyledForm = styled('form')(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const StyledBoldText = styled('span')({
  fontWeight: 'bold',
})

const fieldList: FieldList[] = [
  {
    key: 'name',
    displayName: 'Value',
  },
  {
    key: 'confidence',
    displayName: 'Confidence',
  },
]

export const MerchTypeAttributesSearch = () => {
  const theme = useTheme<Theme>()
  const matchesMediaQuery = useMediaQuery(theme.breakpoints.down('md'))

  const inputRef = React.useRef<HTMLInputElement>(null)
  const [attribute, setAttribute] = React.useState<MerchTypeAttribute | null>(
    null,
  )
  const [value, setValue] = React.useState('')
  const [pending, setPending] = React.useState(false)
  const [data, setData] = React.useState<ConfidenceScore[]>([])

  React.useEffect(() => {
    if (inputRef?.current && attribute) {
      setValue('')
      inputRef.current.focus()
    }
  }, [attribute])

  const onRequestAttributeChange = (attribute: MerchTypeAttribute) => {
    setAttribute(attribute)
  }

  const onRequestChangeValue = (value: string) => {
    setValue(value)
  }

  const handleSubmit = async (
    event: React.MouseEvent | React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault()
    if (!attribute || !value) return

    setPending(true)
    const { results } = await getConfidenceForMerchTypeAttributes(
      { page: 0, perPage: 30 },
      { q: value, attribute_id: attribute.id },
    )

    setData(results)
    setPending(false)
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      data-testid="container"
    >
      <Grid item xs={12}>
        <StyledForm onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <MerchTypeAttributeTypeahead
                onChange={onRequestAttributeChange}
                value={attribute}
              />
            </Grid>
            {!!attribute && (
              <>
                <Grid item xs={12} md={4}>
                  <Input
                    id="mtaValue"
                    name="mtaValue"
                    data-testid="mtaValue"
                    helperText="Enter the MTA value as provided by the partner"
                    value={value}
                    isDisabled={!attribute}
                    onChange={onRequestChangeValue}
                    inputRef={inputRef}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Button
                    type="submit"
                    data-testid="submit-btn"
                    variant="contained"
                    color="primary"
                    disabled={!attribute || !value}
                    fullWidth={matchesMediaQuery}
                  >
                    search
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </StyledForm>
        {pending && <LinearProgress />}
        {data && data.length > 0 && (
          <>
            <StyledTypography>
              Target is looking for a{' '}
              <StyledBoldText>confidence rate of 72</StyledBoldText> or higher
              to be able to assign an MTA value.
            </StyledTypography>
            <Typography>A value will not be assigned if:</Typography>
            <ul>
              <li>The confidence rate is below 72.</li>
              <li>
                The confidence rate is higher than 72, but there is a score tie.
              </li>
            </ul>
            <StyledTypography>
              If there is an appropriate MTA value below that should be
              assigned, have the partner submit the item with the value as it
              exactly appears below.
            </StyledTypography>
            <TabularData data={data} fieldList={fieldList} />
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default MerchTypeAttributesSearch
