import axios from 'axios'

import reject from 'lodash/fp/reject'
import has from 'lodash/fp/has'

import { getPageable, PagingParams } from './pageableHelper'
import apiConfig from 'config/apiConfig'
import { ErrorCode } from 'types/ErrorCode'
import { CollectionResponse } from 'types/Response'
import { IgnoredStatus } from 'types/HttpError'

export async function getErrorCodes(
  params: PagingParams,
): Promise<CollectionResponse<ErrorCode>> {
  const config = {
    params: getPageable(params),
  }

  const { data, headers } = await axios.get(
    `${apiConfig.sms}/listing_errors`,
    config,
  )
  const total = headers['x-total-count']
    ? parseInt(headers['x-total-count'], 10)
    : 0
  return { data, total }
}

export function getErrorCode(
  errorCode: string,
  ignoredStatuses?: IgnoredStatus[],
): Promise<ErrorCode> {
  const config = ignoredStatuses
    ? {
        params: {},
        ignoredStatuses,
      }
    : {}

  return axios
    .get(`${apiConfig.sms}/listing_errors/${errorCode}`, config)
    .then((res) => {
      return res.data
    })
}

export async function errorCodeExists(errorCode: string) {
  const ignoredStatuses = [{ status: 404 }]

  const result = await getErrorCode(errorCode, ignoredStatuses)

  if (has('error_code', result)) {
    return true
  }

  return false
}

export function updateErrorCode({
  id,
  data,
}: {
  id?: number
  data: Partial<ErrorCode>
}): Promise<ErrorCode> {
  const action = id ? 'put' : 'post'
  const pathParam = id ? `/${id}` : ''

  const url = `${apiConfig.sms}/listing_errors${pathParam}`

  return axios({
    method: action,
    url,
    data,
  }).then((res) => {
    return res.data
  })
}

const CancelToken = axios.CancelToken
let cancel: any

export async function searchErrorCodes(query: string): Promise<ErrorCode[]> {
  if (cancel) {
    cancel()
  }

  const config = {
    params: {
      q: query,
    },
    cancelToken: new CancelToken(function executor(c) {
      cancel = c
    }),
  }
  const { data } = await axios.get(
    `${apiConfig.sms}/listing_errors_search`,
    config,
  )

  const errors = getNonSystemErrors(data)

  return errors
}

export const getNonSystemErrors = (errorCodes: ErrorCode[]) =>
  reject((error: ErrorCode) => error.error_category === 'DATA_VALIDATION')(
    errorCodes,
  )

export const isProtectedError = (errorCode: ErrorCode) =>
  errorCode.error_code >= 700 && errorCode.error_code <= 899
