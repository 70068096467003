import axios from 'axios'

import apiConfig from 'config/apiConfig'

import { getPageable, PagingParams } from 'services/pageableHelper'

import { SingleResponse } from 'types/Response'
import { MerchTypeAttribute } from 'types/MerchTypeAttribute'

interface ItemAttributeParams extends PagingParams {
  name: string
  searchType?: 'CONTAINS' | 'STARTS_WITH'
}

export const searchMerchTypeAttributes = ({
  name,
  searchType,
  page,
  perPage,
}: ItemAttributeParams): Promise<MerchTypeAttribute[]> => {
  const config = {
    params: {
      name,
      name_search_type: searchType,
      ...getPageable({ page, perPage }),
    },
  }

  return axios
    .get(`${apiConfig.nexus}/attributes`, config)
    .then((res: SingleResponse<MerchTypeAttribute[]>) => res.data)
}
