import axios from 'axios'
import uniq from 'lodash/fp/uniq'

import apiConfig from 'config/apiConfig'
import { getNodesById } from './itemTaxonomies'
import { Legalization } from 'types/Legalization'

export async function getSellerAutoApproval(
  sellerId: string,
): Promise<{ enabled: boolean; blockedList: Legalization[] }> {
  const config: any = {
    ignoredStatuses: [{ status: 404 }],
  }

  const res = await axios.get(
    `${apiConfig.sms}/seller_autoapproval_settings/${sellerId}`,
    config,
  )
  if (res.status === 404) {
    return Promise.resolve({
      enabled: false,
      blockedList: [],
    })
  }

  const itemTypeAttributes = await getNodesById(
    uniq(res.data.item_types_blacklist),
  )

  // Push these into a legalization type so they can be used in tables
  const itemTypes = itemTypeAttributes.map((attribute) => ({
    primary_attribute: attribute,
  }))
  return {
    enabled: res.data.enabled,
    blockedList: itemTypes,
  }
}

export async function getSellerQuotas(sellerId: string): Promise<number> {
  const { data } = await axios.get(`${apiConfig.sms}/seller_quotas/${sellerId}`)
  return data.quota
}

export const updateSellerQuotas = async (sellerId: string, data: number) => {
  const response = await axios.put(
    `${apiConfig.sms}/seller_quotas/${sellerId}`,
    { quota: data },
  )
  return response.data
}

export const updateAutoApprovalSettings = async (
  sellerId: string,
  autoApprovalSettings: {
    enabled: boolean
    item_types_blacklist: string[]
  },
) => {
  const response = await axios.put(
    `${apiConfig.sms}/seller_autoapproval_settings/${sellerId}`,
    autoApprovalSettings,
  )
  return response.data
}
