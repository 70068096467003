import { useEffect, useState } from 'react'

import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'

import { CheckCircle } from '@mui/icons-material'

import DataList from 'components/common/DataList'
import Input from 'components/common/Input'
import { itemTypeOverrideMessage } from './LatestErrors'

import { FieldError } from 'services/itemHelper'
import { overrideItemType } from 'services/itemOverrides'
import { getNodeById } from 'services/itemTaxonomies'

import Attribute from 'types/Attribute'
import { SmsProduct } from 'types/Item'

import { itemTypeErrorCode } from 'constants/listingError'
import { success } from 'config/themeConfig'

const StyledRoot = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  '&:first-child': {
    marginTop: 0,
  },
}))

const StyledItemTypeError = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

const StyledSuccess = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

const StyledFlexContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const StyledIcon = styled(CheckCircle)(({ theme }) => ({
  marginRight: theme.spacing(2),
  color: success.main,
  fontSize: 44,
}))

const StyledCancelText = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(6),
}))
interface Props {
  errors: FieldError[]
  handleRemove: (error: FieldError) => void
  handleEdit: (error: FieldError) => void
  item?: SmsProduct
}

export const FieldErrorLineItem = ({
  errors,
  handleRemove,
  handleEdit,
  item,
}: Props) => {
  const [itemOverridden, setItemOverridden] = useState(false)
  const [itemType, setItemType] = useState<Attribute>()

  const handleNote = (error: FieldError) => (value: string | undefined) => {
    handleEdit({
      ...error,
      notes: value,
    })
  }

  useEffect(() => {
    let mounted = true

    if (
      item?.item_type_id &&
      errors.some((error) => error.code === itemTypeErrorCode)
    ) {
      getNodeById(item.item_type_id.toString()).then((node) => {
        if (mounted) {
          setItemType(node)
        }
      })
    }

    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const removeError = (error: FieldError) => () => {
    let mounted = true

    if (error.code === itemTypeErrorCode && item?.tcin && item?.item_type_id) {
      overrideItemType(item.tcin, item.item_type_id).then(() => {
        if (mounted) {
          setItemOverridden(true)
        }
      })
    }

    handleRemove(error)

    return () => {
      mounted = false
    }
  }

  return (
    <List data-testid="item-errors-list">
      {itemOverridden && (
        <ListItem disableGutters>
          <Grid item xs={12}>
            <StyledFlexContainer>
              <StyledIcon data-testid="icon-complete" />
              <StyledSuccess>
                The item type prediction has been updated to "{itemType?.name}"
                and this error will not show on future versions.
              </StyledSuccess>
            </StyledFlexContainer>
            <StyledCancelText>
              If you click Cancel, the prediction update will not be reversed.
            </StyledCancelText>
          </Grid>
        </ListItem>
      )}
      {errors.map((error, idx) => {
        return (
          <StyledRoot disableGutters key={idx}>
            <Grid container>
              <Grid item xs={11}>
                <Grid container>
                  <Grid item xs={12}>
                    <DataList
                      data={[{ title: error.code, value: error.message }]}
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={11}>
                    <Input
                      id="additional-notes"
                      name="notes"
                      onChange={handleNote(error)}
                      helperText="Optional: Additional Notes"
                      value={error.notes || ''}
                    />
                  </Grid>
                  {error.code === itemTypeErrorCode && !itemOverridden && (
                    <Grid item xs={12}>
                      <StyledItemTypeError>
                        {itemTypeOverrideMessage(itemType?.name)}
                      </StyledItemTypeError>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  data-testid="remove-error"
                  aria-label="remove"
                  onClick={removeError(error)}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </StyledRoot>
        )
      })}
    </List>
  )
}
