import React from 'react'
import styled from '@emotion/styled'
import FormControl from '@mui/material/FormControl'
import Select from 'components/common/Select'
import ValidationErrorText from 'components/common/ValidationErrorText'

import { Validation } from 'types/Validation'

const StyledRoot = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const StyledTime = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(2),
  minWidth: 'inherit',
}))

export const TIME = [
  '1:00',
  '1:30',
  '2:00',
  '2:30',
  '3:00',
  '3:30',
  '4:00',
  '4:30',
  '5:00',
  '5:30',
  '6:00',
  '6:30',
  '7:00',
  '7:30',
  '8:00',
  '8:30',
  '9:00',
  '9:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
]

export const PERIOD: ['AM', 'PM'] = ['AM', 'PM']

export type Props = {
  value?: string
  period?: 'AM' | 'PM'
  id?: string
  property?: string
  name: string
  disabled?: boolean
  isRequired?: boolean
  validation?: Validation
  noLabel?: boolean
  onChange?: ({
    value,
    name,
    property,
  }: {
    value: string
    name: string
    property?: string
  }) => void
}

const findItemByValue = (value: Nullable<string>) => {
  if (!value) {
    return
  }

  return TIME.find((item) => item === value)
}

export const TimePicker: React.FC<Props> = ({
  value,
  period = 'AM',
  id,
  name,
  property,
  disabled,
  isRequired,
  validation,
  noLabel,
  onChange,
}) => {
  const [time, setTime] = React.useState<string | undefined>(
    findItemByValue(value),
  )
  const [meridiem, setMeridiem] = React.useState<'AM' | 'PM'>(
    period ?? PERIOD[0],
  )

  const handleTimeChange = (value: string) => {
    setTime(value)

    if (onChange) {
      onChange({
        value: `${value} ${meridiem}`,
        name,
        property,
      })
    }
  }

  const handleMeridiemChange = (value: 'AM' | 'PM') => {
    setMeridiem(value)

    if (onChange && time) {
      onChange({
        value: `${time} ${value}`,
        name,
        property,
      })
    }
  }

  return (
    <FormControl required={isRequired}>
      <StyledRoot>
        <StyledTime>
          <Select
            label={noLabel ? '' : 'Time'}
            name={name}
            id={id ? `${id}-time` : `time`}
            data-testid={id ? `${id}-time` : `time`}
            isDisabled={disabled}
            onChange={handleTimeChange}
            onBlur={handleTimeChange}
            options={TIME}
            value={time}
          />
        </StyledTime>
        <div>
          <Select
            label={noLabel ? '' : 'am/pm'}
            name={name}
            id={id ? `${id}-period` : `period`}
            isDisabled={disabled}
            onChange={handleMeridiemChange}
            onBlur={handleMeridiemChange}
            options={PERIOD}
            value={meridiem}
          />
        </div>
      </StyledRoot>
      {validation && <ValidationErrorText errors={validation} field={name} />}
    </FormControl>
  )
}

export default TimePicker
