import { primary } from 'config/themeConfig'
import { Global } from '@emotion/react'

const GlobalStyles = () => (
  <Global
    styles={{
      html: {
        fontFamily: 'Roboto, sans-serif',
      },
      body: {
        margin: 0,
      },
      a: {
        textDecoration: 'none',
        color: primary.main,
        cursor: 'pointer',
      },
      'a:visited': {
        textDecoration: 'none',
        color: primary.main,
      },
      'a:hover': {
        textDecoration: 'underline',
      },
      'a:focus': {
        outline: '1px dashed black',
        outlineOffset: '4px',
      },
    }}
  />
)

export default GlobalStyles
