import { useState, useEffect } from 'react'
import { Form } from 'formik'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'

import styled from '@emotion/styled'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import InputField from 'components/common/form/InputField'
import PhoneField, {
  tollFreeErrorMsg,
  tollFreeRegex,
} from 'components/common/form/PhoneField'
import SelectField from 'components/common/form/SelectField'

import COUNTRIES from 'constants/countries'
import {
  LABEL_COMPANY_TYPE,
  LABEL_EMAIL,
  LABEL_ERROR_PHONE_NUMBER_MOBILE,
  LABEL_ERROR_PHONE_NUMBER_OFFICE,
  LABEL_FIRST_NAME,
  LABEL_LAST_NAME,
  LABEL_MOBILE_COUNTRY_CODE,
  LABEL_OFFICE_COUNTRY_CODE,
  LABEL_PHONE_NUMBER_MOBILE,
  LABEL_PHONE_NUMBER_OFFICE,
  LABEL_SOURCE,
} from 'constants/labels'
import { getLocationsFromCountryCode } from 'constants/locations'

import { RoutePath } from 'services/NavigationHelper'
import { ValidationSchema } from 'services/validation'

import { CountryNameCode } from 'types/Country'
import { CompanyType } from 'types/Seller'

import { Props } from './AddPartner'
import { containsInvalidCharacters } from 'services/usersHelper'

const StyledInputField = styled(InputField)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

const StyledSelectField = styled(SelectField)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
}))

export interface FormValues {
  legalBusinessName: string
  displayName: string
  source: string
  isPrivate: string
  address1: string
  address2: string
  city: string
  state: string
  postalCode: string
  countryCode: string
  firstName: string
  lastName: string
  email: string
  officeCountry: string
  mobileCountry: string
  officePhoneNumber: string
  mobilePhoneNumber: string
  isEqualPhoneNumber: boolean
}

const nameMsg = 'Name cannot contain special characters'

export const validationSchema: ValidationSchema = yup.object().shape({
  legalBusinessName: yup
    .string()
    .label('Legal Business Name')
    .test(
      nameMsg,
      nameMsg,
      (value) => !containsInvalidCharacters(value as string),
    )
    .required(),
  displayName: yup
    .string()
    .min(2)
    .max(35)
    .label('Display Name')
    .test(
      nameMsg,
      nameMsg,
      (value) => !containsInvalidCharacters(value as string),
    )
    .required(),

  source: yup.string().label('Source').required(),
  isPrivate: yup.string().label('Company Type').required(),
  address1: yup.string().label('Address Line 1').max(75).required(),
  address2: yup.string().label('Address Line 2').max(75),
  city: yup.string().label('City').required(),
  state: yup.string().label('State').required(),
  postalCode: yup.string().label('Zip Code').min(5).max(9).required(),
  countryCode: yup.string().label('Country Code').required(),
  firstName: yup.string().label('First Name').required(),
  lastName: yup.string().label('Last Name').required(),
  email: yup.string().label('Email').email().required(),
  officeCountry: yup.string().label(LABEL_OFFICE_COUNTRY_CODE),
  officePhoneNumber: yup.string().when(['officeCountry'], {
    is: (officeCountry: string) =>
      officeCountry !== CountryNameCode.UNITED_STATES,
    then: yup.string().min(8).max(20),
    otherwise: yup
      .string()
      .label(LABEL_PHONE_NUMBER_OFFICE)
      .length(14, LABEL_ERROR_PHONE_NUMBER_OFFICE)
      .required(),
  }),
  mobileCountry: yup.string().label(LABEL_MOBILE_COUNTRY_CODE),
  mobilePhoneNumber: yup.string().when(['mobileCountry'], {
    is: (mobileCountry: string) =>
      mobileCountry !== CountryNameCode.UNITED_STATES,
    then: yup
      .string()
      .label(LABEL_PHONE_NUMBER_MOBILE)
      .test({
        name: 'is-not-toll-freetoll',
        message: tollFreeErrorMsg,
        test(this: yup.TestContext, value: any) {
          if (!value) {
            return true
          }

          return !tollFreeRegex.test(value)
        },
      })
      .min(8)
      .max(20)
      .required(),
    otherwise: yup
      .string()
      .label(LABEL_PHONE_NUMBER_MOBILE)
      .test({
        name: 'is-not-toll-freetoll',
        message: tollFreeErrorMsg,
        test(this: yup.TestContext, value: any) {
          if (!value) {
            return true
          }

          return !tollFreeRegex.test(value)
        },
      })
      .length(14, LABEL_ERROR_PHONE_NUMBER_MOBILE)
      .required(),
  }),
})

const AddPartnerForm = ({
  isOpen,
  isValid,
  dirty,
  handleSubmit,
  currentSeller,
  sellerContacts,
  sources,
  isPending,
  closeDialog: dispatchCloseDialog,
  values,
  errors,
  touched,
}: Props) => {
  const [officeCountry, setOfficeCountry] = useState<string>(
    CountryNameCode.UNITED_STATES,
  )
  const [mobileCountry, setMobileCountry] = useState<string>(
    CountryNameCode.UNITED_STATES,
  )
  const [officePhoneError, setOfficePhoneError] = useState('')
  const [mobilePhoneError, setMobilePhoneError] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    setOfficeCountry(values.officeCountry)

    const error = errors?.officePhoneNumber

    if (touched['officePhoneNumber'] && error) {
      setOfficePhoneError(error)
    } else {
      setOfficePhoneError('')
    }
  }, [values.officeCountry, errors, touched])

  useEffect(() => {
    setMobileCountry(values.mobileCountry)

    const error = errors?.mobilePhoneNumber

    if (touched['mobilePhoneNumber'] && error) {
      setMobilePhoneError(error)
    } else {
      setMobilePhoneError('')
    }
  }, [values.mobileCountry, errors, touched])

  useEffect(() => {
    if (currentSeller && sellerContacts) {
      navigate(`/${currentSeller.id}${RoutePath.BASIC_INFO}`)
      dispatchCloseDialog()
    }
  }, [navigate, dispatchCloseDialog, currentSeller, sellerContacts])

  return (
    <DialogContainer
      isOpen={isOpen}
      title="Add New Partner"
      isPending={isPending}
      isSubmitDisabled={!isValid || !dirty}
      onSubmit={handleSubmit}
      maxWidth="md"
    >
      <Form>
        <Grid container spacing={2} data-testid="add-partner">
          <Grid item xs={3}>
            <StyledInputField
              required
              name="legalBusinessName"
              label="Legal Business Name"
              inputProps={{ 'data-testid': 'legal-name' }}
              disabled={isPending}
            />
          </Grid>
          <Grid item xs={3}>
            <StyledInputField
              required
              name="displayName"
              label="Display Name"
              inputProps={{ 'data-testid': 'display-name' }}
              disabled={isPending}
            />
          </Grid>
          <Grid item xs={3}>
            <StyledSelectField
              required
              name="source"
              options={sources}
              keyName="name"
              valueName="value"
              label={LABEL_SOURCE}
              inputProps={{ 'data-testid': 'source' }}
              disabled={isPending}
            />
          </Grid>
          <Grid item xs={3}>
            <StyledSelectField
              required
              name="isPrivate"
              options={[
                { label: 'Private', value: CompanyType.PRIVATE },
                { label: 'Public', value: CompanyType.PUBLIC },
              ]}
              keyName="label"
              valueName="value"
              label={LABEL_COMPANY_TYPE}
              inputProps={{ 'data-testid': 'isPrivate' }}
              disabled={isPending}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <StyledPaper elevation={0}>
              Headquarters Address (displayed on Target.com)
              <StyledInputField
                required
                name="address1"
                label="Address Line 1"
                inputProps={{ 'data-testid': 'address-1' }}
                disabled={isPending}
                maxCharacters={75}
              />
              <StyledInputField
                name="address2"
                label="Address Line 2"
                inputProps={{ 'data-testid': 'address-2' }}
                disabled={isPending}
                maxCharacters={75}
              />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <StyledInputField
                    required
                    name="city"
                    label="City"
                    inputProps={{ 'data-testid': 'city' }}
                    disabled={isPending}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledSelectField
                    required
                    name="state"
                    options={getLocationsFromCountryCode(values.countryCode)}
                    keyName="name"
                    valueName="abbreviation"
                    label="State"
                    inputProps={{ 'data-testid': 'state' }}
                    disabled={isPending}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <StyledSelectField
                    required
                    name="countryCode"
                    options={COUNTRIES}
                    keyName="name"
                    valueName="code"
                    label="Country"
                    inputProps={{ 'data-testid': 'country' }}
                    disabled={isPending}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledInputField
                    required
                    name="postalCode"
                    label="Zip Code"
                    inputProps={{ 'data-testid': 'postal-code' }}
                    disabled={isPending}
                  />
                </Grid>
              </Grid>
            </StyledPaper>
          </Grid>
          <Grid item xs={6}>
            <StyledPaper elevation={0}>
              Admin User
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <StyledInputField
                    required
                    name="firstName"
                    label={LABEL_FIRST_NAME}
                    inputProps={{ 'data-testid': 'first-name' }}
                    disabled={isPending}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledInputField
                    required
                    name="lastName"
                    label={LABEL_LAST_NAME}
                    inputProps={{ 'data-testid': 'last-name' }}
                    disabled={isPending}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledInputField
                    required
                    name="email"
                    label={LABEL_EMAIL}
                    inputProps={{ 'data-testid': 'email' }}
                    disabled={isPending}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PhoneField
                    selectName="officeCountry"
                    name="officePhoneNumber"
                    id="office"
                    label={LABEL_PHONE_NUMBER_OFFICE}
                    disabled={isPending}
                    country={officeCountry}
                    error={officePhoneError}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PhoneField
                    required
                    selectName="mobileCountry"
                    name="mobilePhoneNumber"
                    id="mobile"
                    label={LABEL_PHONE_NUMBER_MOBILE}
                    disabled={isPending}
                    country={mobileCountry}
                    error={mobilePhoneError}
                  />
                </Grid>
              </Grid>
            </StyledPaper>
          </Grid>
        </Grid>
      </Form>
    </DialogContainer>
  )
}

export default AddPartnerForm
