import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import styled from '@emotion/styled'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import DisplayCard from 'components/common/DisplayCard'
import StarRating from 'components/common/StarRating'
import WarningIcon from 'components/common/WarningIcon'
import { DialogEnum } from 'components/common/Dialog'

import { GENERIC_API_ERROR } from 'constants/errors'

import { formatLocaleNumber } from 'services/formatNumber'
import { getRatingsAndReviewsSummary } from 'services/ratingsAndReviews'
import { GenerateReportRequest, submitReport } from 'services/reports'
import { trackCustomEvent } from 'services/fireflyInsights'

import { currentSellerId } from 'store/selectors'
import { openDialog } from 'store/dialog/actionCreator'

import { StarsDistribution } from 'types/RatingsAndReviews'
import { ReportType } from 'types/Report'
import { FireflyEvent } from 'types/FireflyInsights'

import StarDistribution from './StarDistribution'

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
}))

const StyledWarning = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.main,
}))

const StyledTextContainer = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(1),
}))

const defaultStarDistribution: StarsDistribution = {
  '1': 0,
  '2': 0,
  '3': 0,
  '4': 0,
  '5': 0,
}

const getAverageRatingTitle = (starsRating: string | undefined) => {
  const titleText = 'Average Partner Rating: '
  return starsRating ? `${titleText}${starsRating}` : `${titleText} N/A`
}

const getAverageRatingDescription = (starsCount: number) => {
  return `Based on ${formatLocaleNumber(starsCount)} Ratings (All Time)`
}

export const createPartnerReviewsReport =
  ({ type, sellerId }: GenerateReportRequest) =>
  () => {
    return submitReport({
      type,
      sellerId,
    })
  }

export const RatingCard = () => {
  const reduxDispatch = useDispatch()

  const sellerId = useSelector(currentSellerId)
  const [starsAverage, setStarsAverage] = React.useState<string>()
  const [starsCount, setStarsCount] = React.useState<number>(0)
  const [starsDistribution, setStarsDistribution] =
    React.useState<StarsDistribution>(defaultStarDistribution)
  const [error, setError] = React.useState('')

  React.useEffect(() => {
    let mounted = true
    const getRatingsSummary = async () => {
      if (sellerId) {
        try {
          const summary = await getRatingsAndReviewsSummary(sellerId)
          if (mounted && summary) {
            setStarsAverage(summary.stars_average.toFixed(2))
            setStarsCount(summary.stars_count)
            if (summary.stars_distribution) {
              setStarsDistribution(summary.stars_distribution)
            }
          }
        } catch (err) {
          if (mounted) {
            setError(
              'Partner ratings and reviews data could not be retrieved at this time. Please try again later.',
            )
          }
        }
      }
    }
    getRatingsSummary()
    return () => {
      mounted = false
    }
  }, [sellerId])

  const openDownloadReportDialog = () => {
    trackCustomEvent(
      FireflyEvent.PARTNER_RATINGS_AND_REVIEW_DOWNLOAD_REPORT,
      'event',
      'click',
    )

    reduxDispatch(
      openDialog({
        dialogEnum: DialogEnum.REPORT_DOWNLOAD_DIALOG,
        componentProps: {
          title: 'GENERATING REPORT...PLEASE WAIT',
          reportTitle: 'Reviews',
          sellerId,
          createReport: createPartnerReviewsReport({
            type: ReportType.PARTNER_REVIEWS,
            sellerId,
          }),
        },
      }),
    )
  }
  return (
    <DisplayCard
      title={getAverageRatingTitle(starsAverage)}
      sidecar={<StarRating starsAverage={starsAverage} />}
      description={getAverageRatingDescription(starsCount)}
      actions={
        <Button onClick={openDownloadReportDialog} color="primary">
          download partner reviews
        </Button>
      }
    >
      {!error && (
        <Grid container spacing={2}>
          <Grid item xs={9} md={8}>
            <StarDistribution
              starsCount={starsCount}
              starsDistribution={starsDistribution}
            />
          </Grid>
          <StyledTextContainer item xs={3} md={3}>
            <StyledTitle>How Your Rating is Calculated</StyledTitle>
            Each submission is weighted using its star rating and the average of
            this score is your partner rating.
          </StyledTextContainer>
        </Grid>
      )}
      {error && (
        <WarningIcon>
          <StyledWarning>{GENERIC_API_ERROR}</StyledWarning>
        </WarningIcon>
      )}
    </DisplayCard>
  )
}

export default RatingCard
