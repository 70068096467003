import { Routes, Route } from 'react-router-dom'

import OpenCasesPage from './OpenCasesPage'
import OpenCaseDetail from './OpenCaseDetail'

export const OpenCases = () => {
  return (
    <Routes>
      <Route index element={<OpenCasesPage />} />
      <Route path=":caseId" element={<OpenCaseDetail />} />
    </Routes>
  )
}

export default OpenCases
