import axios from 'axios'

import apiConfig from 'config/apiConfig'

import { PagingParams, getPageable } from 'services/pageableHelper'

import { ClosedDate } from 'types/ClosedDates'
import { CollectionResponse } from 'types/Response'

export async function getClosedDates(
  pagingParams: PagingParams,
): Promise<CollectionResponse<ClosedDate>> {
  const pageableParams = getPageable(pagingParams)

  const config = {
    params: pageableParams,
  }

  const response = await axios.get(
    `${apiConfig.sms}/holiday_closure_dates`,
    config,
  )

  const total = response.headers['x-total-count']
    ? parseInt(response.headers['x-total-count'], 10)
    : 0

  return {
    total,
    data: response.data,
  }
}

export const deleteClosedDate = (id: string) => {
  return axios.delete(`${apiConfig.sms}/holiday_closure_dates/${id}`)
}

export const createClosedDate = async (date: string): Promise<ClosedDate> => {
  const result = await axios.post(`${apiConfig.sms}/holiday_closure_dates`, {
    calendar_date: date,
  })

  return result.data
}
