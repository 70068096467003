import SellerUser from 'types/SellerUser'
import { SupportCode } from 'types/SupportCode'

export const addCustomerSupport = (responsibilities: number[]): number[] => {
  if (responsibilities.includes(SupportCode.CUSTOMER_SUPPORT)) {
    return [...responsibilities]
  }
  return [...responsibilities, SupportCode.CUSTOMER_SUPPORT]
}

export const removeCustomerSupport = (
  responsibilities = [] as number[],
): number[] => {
  return responsibilities.filter((i) => i !== SupportCode.CUSTOMER_SUPPORT)
}

export const isCustomerSupportForSeller = (
  sellerId: string,
  contact: SellerUser,
): boolean => {
  const { vmm_metadata } = contact

  if (!vmm_metadata || !vmm_metadata.length) return false

  const dataForSeller = vmm_metadata.find((i) => i.seller_id === sellerId)

  if (!dataForSeller || !dataForSeller.functional_responsibilities) return false

  return dataForSeller.functional_responsibilities.includes(
    SupportCode.CUSTOMER_SUPPORT,
  )
}

export const filterResponsibilityBySellerId = (
  sellerId: string,
  contacts: SellerUser[],
): SellerUser[] => {
  const newContacts = contacts.map((contact) => {
    if (contact.vmm_metadata && contact.vmm_metadata.length) {
      contact.vmm_metadata = contact.vmm_metadata.filter(
        (item) => item.seller_id === sellerId,
      )
    }

    return contact
  })

  return newContacts
}

export const getResponsibilityLabel = (num: number): string => {
  switch (num) {
    case SupportCode.CUSTOMER_SUPPORT: {
      return 'Customer Support'
    }
    case SupportCode.SALES_BUSINESS: {
      return 'Sales Rep / Business Management'
    }
    case SupportCode.SHIPPING_AND_FULLFILMENT: {
      return 'Shipping & Fulfillment'
    }
    case SupportCode.FINANCE_ACCOUNTING: {
      return 'Finance & Accounting'
    }
    case SupportCode.ITEM_DATA_SETUP: {
      return 'Item Data & Setup'
    }
    case SupportCode.REVERSE_LOGISTICS: {
      return 'Reverse Logistics'
    }
    default:
      return ''
  }
}
