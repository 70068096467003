import EnhancedTable from 'components/common/EnhancedTable'
import TableSpacer from 'components/common/TableSpacer'

import { Direction, getTableRows } from 'services/pageableHelper'

export interface ComponentProps {
  pending: boolean
  total: number
  data: any[]
  fieldList: any[]
  handlePageChange: (event: any, page: number | undefined) => void
  handleChangeRowsPerPage: (event: any) => void
  searchParams: any
  onRequestSort?: (event: any) => void
  order?: Direction
  orderBy?: string
}

export const OnboardingTable = ({
  pending,
  total,
  data,
  fieldList,
  handlePageChange,
  handleChangeRowsPerPage,
  searchParams,
  onRequestSort,
  order,
  orderBy,
}: ComponentProps) => {
  const rows = getTableRows(data, searchParams.page, searchParams.perPage)
  return (
    <TableSpacer>
      <EnhancedTable
        data={rows}
        fieldList={fieldList}
        isLoading={pending}
        total={total}
        page={searchParams.page}
        rowsPerPage={searchParams.perPage}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onRequestSort={onRequestSort}
        order={order}
        orderBy={orderBy}
      />
    </TableSpacer>
  )
}

export default OnboardingTable
