import styled from '@emotion/styled'

import { DATE_DISPLAY_FORMAT } from 'services/dateService'
import { Validation } from 'types/Validation'
import DayPickerInput from '../DateInput/DayPickerInput'

const StyledDayPickerInput = styled(DayPickerInput)({
  position: 'absolute',
  left: 0,
  zIndex: 3,
  background: '#fff',
  boxShadow: '0 2px 5px rgba(0,0,0,.15)',
})
export interface Props {
  value: string | Date | undefined
  id: string
  label: string
  placeholder: string
  selected?: any
  disabled?: any
  month?: Date
  fromMonth?: Date
  toMonth?: Date
  validation?: Validation
  onChange: (date: Date) => void
  helpertext?: string
}

export const DatePickerInput = ({
  value,
  id,
  label,
  placeholder,
  validation,
  selected,
  disabled,
  fromMonth,
  toMonth,
  month,
  onChange,
  helpertext,
}: Props) => {
  return (
    <StyledDayPickerInput
      value={value}
      inputComponent={true}
      placeholder={placeholder}
      dateDisplayFormat={DATE_DISPLAY_FORMAT}
      inputProps={{
        id,
        label,
        validation,
        helpertext,
      }}
      dayPickerProps={{
        selected,
        disabled,
        fromMonth,
        toMonth,
        month,
      }}
      onChange={onChange}
    />
  )
}

export default DatePickerInput
