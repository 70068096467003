import uniq from 'lodash/fp/uniq'
import mapValues from 'lodash/fp/mapValues'
import keyBy from 'lodash/fp/keyBy'

import styled from '@emotion/styled'

import { MarketplaceProduct, RelationshipType } from 'types/Item'
import SimpleMessage from './SimpleMessage'
import MessageWithPopover from './MessageWithPopover'

const StyledSpan = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
}))

interface Props {
  variationChildren: MarketplaceProduct[]
  refinements: string[]
  productId: string
  relationshipType: string
}

function VariationUniqueness({
  variationChildren,
  productId,
  relationshipType,
  refinements,
}: Props) {
  const getVariationTheme = (child: MarketplaceProduct) => {
    return refinements.reduce((themeString, refinement) => {
      const variation =
        child.variations?.find(
          (variation) => variation.refinement === refinement,
        ) ?? ''
      return themeString + (variation ? variation.value?.toLowerCase() : '')
    }, '')
  }

  const childThemeStrings = variationChildren.map((child) =>
    getVariationTheme(child),
  )

  const isVariationUnique = (child: MarketplaceProduct) => {
    const variationTheme = getVariationTheme(child)
    return (
      childThemeStrings.filter((theme) => theme === variationTheme).length === 1
    )
  }

  if (childThemeStrings.length === uniq(childThemeStrings).length) {
    const message =
      relationshipType === RelationshipType.VAP
        ? 'All VCs unique'
        : 'VC is unique'

    return <SimpleMessage isError={false} message={message} />
  }

  if (relationshipType === RelationshipType.VC) {
    const child = variationChildren.find(
      (product) => product.product_id === productId,
    )
    if (!child) {
      // this should never happen, but did (shrug)
      return <SimpleMessage isError message="Data is out of sync." />
    }
    if (isVariationUnique(child!)) {
      return <SimpleMessage isError={false} message={'VC is unique'} />
    }
  }

  let duplicateCount = 0
  const childData = variationChildren
    .map((child) => {
      const duplicate = !isVariationUnique(child)
      if (duplicate) {
        duplicateCount++
      }
      return {
        tcin: child.tcin,
        ...mapValues('value', keyBy('refinement', child.variations)),
        duplicate,
      }
    })
    .filter((row) => {
      return relationshipType === RelationshipType.VAP || row.duplicate
    })

  const fieldList = [
    {
      key: 'tcin',
      heading: 'TCIN',
      hasSort: false,
    },
    ...refinements.map((key) => ({
      key,
      heading: key,
      hasSort: false,
      formatCell: (item: any) => {
        if (item.duplicate) {
          return <StyledSpan>{item[key]}</StyledSpan>
        }

        return item[key]
      },
    })),
  ]

  const errorMessage =
    relationshipType === RelationshipType.VAP
      ? `${duplicateCount} VC duplicates`
      : `1 of ${duplicateCount} duplicates`

  return (
    <MessageWithPopover
      body={`VAP Variation Theme: ${refinements.join(' & ')}`}
      data={childData}
      errorMessage={errorMessage}
      fieldList={fieldList}
      title={'VC Theme Conflict'}
    />
  )
}

export default VariationUniqueness
