import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import isEqual from 'lodash/fp/isEqual'
import unionWith from 'lodash/fp/unionWith'
import differenceWith from 'lodash/fp/differenceWith'

import { currentSeller as getCurrentSeller } from 'store/selectors'
import { useStateValue } from 'stateManager/StateProvider'
import { updateSellerLegalization } from 'services/legalizations'

import Attribute, { BRAND, ITEM_SUBTYPE, ITEM_TYPE } from 'types/Attribute'
import { Legalization } from 'types/Legalization'
import { SmsSeller } from 'types/Seller'

import EditBrandContainer from '../dialogs/EditBrandContainer'
import EditAllowedItemSettingsContainer from '../dialogs/EditAllowedItemSettingsContainer'

interface Props {
  type: typeof BRAND | typeof ITEM_SUBTYPE | typeof ITEM_TYPE
  onDrawerClose: () => void
  setLegalizations?: () => void
}

const UpdateItemListings = ({
  type,
  onDrawerClose,
  setLegalizations,
}: Props) => {
  const currentSeller = useSelector(getCurrentSeller) as SmsSeller
  const { state } = useStateValue()

  const [legalization, setLegalization] = useState<Partial<Legalization>>({})
  const [editPrimary, setEditPrimary] = useState<Attribute>()
  const [editPaired, setEditPaired] = useState<Attribute[]>([])

  useEffect(() => {
    if (state.isEdit) {
      setLegalization(state.editLegalizationData)
      setEditPrimary(state?.editLegalizationData?.primary_attribute ?? {})
      setEditPaired(state?.editLegalizationData?.paired_attributes ?? [])
    }
  }, [state.isEdit, state.editLegalizationData])

  const handleSubmit = (legalization: Legalization) => {
    updateSellerLegalization(
      currentSeller.id,
      legalization.id,
      legalization,
    ).then(() => {
      setLegalizations && setLegalizations()
      onDrawerClose()
    })

    setLegalization({})
    setEditPrimary(undefined)
    setEditPaired([])
  }

  const handlePrimaryChange = (primary: Attribute | undefined) => {
    setEditPrimary(primary)
  }

  const handleAddPaired = (paired: Attribute) => {
    setEditPaired((prev) => unionWith(isEqual, prev, [paired]))
  }

  const handleRemovePaired = (paired: Attribute) => {
    setEditPaired((prev) => differenceWith(isEqual, prev, [paired]))
  }

  const EditContainer =
    type === BRAND ? EditBrandContainer : EditAllowedItemSettingsContainer

  return (
    <section className="hc-bg-grey07 hc-pa-normal">
      <EditContainer
        type={type}
        legalization={legalization}
        primary={editPrimary}
        paired={editPaired}
        doPrimaryChange={handlePrimaryChange}
        doAddPaired={handleAddPaired}
        doRemovePaired={handleRemovePaired}
        doSubmit={handleSubmit}
        doCancel={onDrawerClose}
      />
    </section>
  )
}

export default UpdateItemListings
