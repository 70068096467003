import { withFormik, Form, FormikProps } from 'formik'
import * as yup from 'yup'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import styled from '@emotion/styled'

import DisplayCard from 'components/common/DisplayCard'
import RadioGroupField from 'components/common/form/RadioGroupField'
import InputField from 'components/common/form/InputField'
import Text from 'components/common/Text'

import { maxCharacterCount } from 'services/validation'
type FormValues = {
  choice: string
  reason: string
}

type ComponentProps = {
  articleId: string
  answered: boolean
  onSubmit: (value: string) => void
}

type Props = ComponentProps & FormikProps<FormValues>

export enum Choices {
  YES = 'Yes',
  NO_UNHELPFUL = 'No unhelpful',
  NO_CONFUSING = 'No confusing',
  OTHER = 'Other',
}

const StyledCenteredDiv = styled('div')({
  display: 'grid',
  justifyContent: 'center',
  alignContent: 'center',
})

const MAX_COUNT = 100

const validationSchema = yup.object().shape({
  choice: yup.string().required(),
  reason: yup
    .string()
    .label('Describe your issue')
    .when(['choice'], {
      is: (choice: string) => choice !== Choices.OTHER,
      then: yup.string(),
      otherwise: yup
        .string()
        .test({
          name: 'isReason',
          test: maxCharacterCount('reason', MAX_COUNT),
        })
        .required(),
    }),
})

const radioOptions = [
  { label: `Yes, thanks!`, value: Choices.YES },
  {
    label: `No, it didn't solve my problem`,
    value: Choices.NO_UNHELPFUL,
  },
  {
    label: `No, it was confusing/difficult to read`,
    value: Choices.NO_CONFUSING,
  },
  { label: `Other`, value: Choices.OTHER },
]

export const ArticleSurveyForm = ({
  answered,
  isValid,
  dirty,
  values,
  isSubmitting,
}: Props) => {
  return (
    <DisplayCard title="Was This Article Helpful?">
      {answered && (
        <StyledCenteredDiv>
          <Text>Thank you</Text>
        </StyledCenteredDiv>
      )}
      {!answered && (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RadioGroupField
                required
                name="choice"
                disabled={isSubmitting}
                options={radioOptions}
              />
            </Grid>
            {values.choice === Choices.OTHER && (
              <Grid item xs={12}>
                <InputField
                  required
                  name="reason"
                  label="Describe your issue"
                  maxCharacters={100}
                  enableOnChangeValidation
                  inputProps={{ 'data-testid': 'reason' }}
                  disabled={isSubmitting}
                />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                disabled={!isValid || !dirty || isSubmitting}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </DisplayCard>
  )
}

export const ArticleSurvey = withFormik<ComponentProps, FormValues>({
  mapPropsToValues: () => ({
    choice: '',
    reason: '',
  }),
  handleSubmit: async (values, { props }) => {
    const option = radioOptions.find((item) => item.value === values.choice)
    const key = option?.label

    const value =
      values.choice === Choices.OTHER
        ? `${Choices.OTHER}: ${values.reason}`
        : values.choice
    if (key) {
      props.onSubmit(value)
    }
  },
  enableReinitialize: true,
  validationSchema,
})(ArticleSurveyForm)

export default ArticleSurvey
