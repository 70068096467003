import React from 'react'

import styled from '@emotion/styled'

import ItemTypeSearch from './ItemTypeSearch'
import ManageBrands from './ManageBrands'
import MerchTypeAttributesSearch from './MerchTypeAttributesSearch'

import DisplayCard from 'components/common/DisplayCard'
import HeaderTitle from 'components/common/HeaderTitle'
import Select from 'components/common/Select'

const StyledContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
}))

type Names = 'Brand' | 'Item Types' | 'Merch Type Attributes'

const options: Names[] = ['Brand', 'Item Types', 'Merch Type Attributes']

const DataMappingsPage = () => {
  const [selection, setSelection] = React.useState<Names>(options[0])

  const handleSelectChange = (value: Names) => {
    setSelection(value)
  }

  return (
    <>
      <HeaderTitle title="Data Mapping" />
      <DisplayCard title="What do you have questions on?">
        <Select
          id="data-mapping-select"
          name="data-mapping-select"
          onChange={handleSelectChange}
          options={options.map((name) => name)}
          value={selection}
        />
      </DisplayCard>
      <StyledContainer>
        {selection === 'Brand' && <ManageBrands />}
        {selection === 'Item Types' && <ItemTypeSearch />}
        {selection === 'Merch Type Attributes' && <MerchTypeAttributesSearch />}
      </StyledContainer>
    </>
  )
}

export default DataMappingsPage
