import React from 'react'
import { SvgIconProps } from '@mui/material/SvgIcon'
import theme, { target } from 'config/themeConfig'

export default function TargetPlus(svgData: SvgIconProps) {
  return React.createElement(
    'svg',
    {
      focusable: 'false',
      tabIndex: svgData.tabIndex,
      width: svgData.width,
      height: svgData.height,
      className: svgData.className,
      style: svgData.style,
      version: '1.1',
      viewBox: '0 0 135 71',
      xmlns: 'http://www.w3.org/2000/svg',
    },
    // Target Logo
    React.createElement('path', {
      d: 'M36.000,71.000 C16.118,71.000 0.000,55.106 0.000,35.500 C0.000,15.894 16.118,0.000 36.000,0.000 C55.882,0.000 72.000,15.894 72.000,35.500 C72.000,55.106 55.882,71.000 36.000,71.000 ZM36.000,12.000 C22.745,12.000 12.000,22.745 12.000,36.000 C12.000,49.255 22.745,60.000 36.000,60.000 C49.255,60.000 60.000,49.255 60.000,36.000 C60.000,22.745 49.255,12.000 36.000,12.000 ZM36.000,48.000 C29.373,48.000 24.000,42.627 24.000,36.000 C24.000,29.373 29.373,24.000 36.000,24.000 C42.627,24.000 48.000,29.373 48.000,36.000 C48.000,42.627 42.627,48.000 36.000,48.000 Z',
      fill: svgData.fill || target.main,
    }),
    // Plus
    React.createElement('path', {
      d: 'M116.000,41.000 L116.000,61.000 L105.000,61.000 L105.000,41.000 L85.000,41.000 L85.000,30.000 L105.000,30.000 L105.000,11.000 L116.000,11.000 L116.000,30.000 L135.000,30.000 L135.000,41.000 L116.000,41.000 Z',
      fill: svgData.fill || theme.palette.common.black,
    }),
    // TM
    React.createElement('path', {
      d: 'M131.416,58.456 L130.502,58.456 L130.502,60.993 L130.129,60.993 L130.129,58.456 L129.217,58.456 L129.217,58.149 L131.416,58.149 L131.416,58.456 ZM133.252,60.470 L134.182,58.149 L134.668,58.149 L134.668,60.993 L134.293,60.993 L134.293,59.886 L134.328,58.690 L133.395,60.993 L133.107,60.993 L132.176,58.696 L132.213,59.886 L132.213,60.993 L131.838,60.993 L131.838,58.149 L132.322,58.149 L133.252,60.470 Z',
      fill: svgData.fill || theme.palette.common.black,
    }),
  )
}
