import axios from 'axios'

import apiConfig from 'config/apiConfig'
import { SortParams, getPageable, getSorts } from './pageableHelper'

import {
  MarketplaceProductLockWithStatus,
  MarketplaceReviewProduct,
  ProductSearchParams,
} from 'types/Item'
import { CollectionResponse } from 'types/Response'

export function getSortedMarketplaceProductsWithLocks(
  searchParams: ProductSearchParams,
  page: number,
  perPage: number,
  sortParams: SortParams[] = [],
  cancelToken: any = undefined,
) {
  const pageable = getPageable({ page, perPage })
  const { orderBy, direction, ...refinedSearchParams } = searchParams
  const config = {
    params: {
      ...refinedSearchParams,
      ...pageable,
      sort: getSorts(sortParams),
    },
    cancelToken,
  }

  return axios
    .get(`${apiConfig.sellerProducts}/marketplace_review_products`, config)
    .then((res): CollectionResponse<MarketplaceReviewProduct> => {
      const { data, headers } = res

      const total = headers['x-total-count']
        ? parseInt(headers['x-total-count'], 10)
        : 0

      return {
        data,
        total,
      }
    })
}

export function getMarketplaceProductWithLock(productId: string) {
  // Get and lock for product for review by product ID
  // returns object with 'product', not 'marketplace_product'
  return axios
    .get(`${apiConfig.sellerProducts}/review_products/${productId}`)
    .then((res) => res.data)
}

export function unlockMarketplaceProduct(
  productId: string,
): Promise<MarketplaceProductLockWithStatus> {
  return axios
    .post(`${apiConfig.sellerProducts}/products/${productId}:unlock`)
    .then((res) => res.data)
}

export function lockMarketplaceProduct(
  productId: string,
): Promise<MarketplaceProductLockWithStatus> {
  return axios
    .post(`${apiConfig.sellerProducts}/products/${productId}:lock`)
    .then((res) => res.data)
}
