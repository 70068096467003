import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import getOr from 'lodash/fp/getOr'
import groupBy from 'lodash/fp/groupBy'
import isEqual from 'lodash/fp/isEqual'
import unionWith from 'lodash/fp/unionWith'
import differenceWith from 'lodash/fp/differenceWith'

import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'

import FullScreenDialogContainer from 'components/common/Dialog/FullScreenDialogContainer'
import FullscreenDialogContent from 'components/common/Dialog/FullscreenDialogContent'

import { closeDialog } from 'store/dialog/actionCreator'

import { currentSeller as getCurrentSeller, getMemberOf } from 'store/selectors'

import { isUserRoleExternal } from 'services/authorization'
import {
  deleteSellerLegalization,
  getSellerLegalizationsWithNames,
  updateSellerLegalization,
} from 'services/legalizations'

import Attribute, { BRAND, ITEM_SUBTYPE, ITEM_TYPE } from 'types/Attribute'
import { Legalization } from 'types/Legalization'
import { SmsSeller } from 'types/Seller'

import EditBrandContainer from './EditBrandContainer'
import EditAllowedItemSettingsContainer from './EditAllowedItemSettingsContainer'
import {
  getBrandAllowedListType,
  getItemTypeAllowedListType,
  getSubtypeAllowedListType,
  AllowedItemTypeProps,
} from '../ItemSettingsTableFieldTypes'
import LegalizationTable from '../LegalizationTable'

interface Props {
  type: typeof BRAND | typeof ITEM_SUBTYPE | typeof ITEM_TYPE
  isOpen: boolean
}

const StyledSeller = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}))

type ValidTypes = typeof ITEM_TYPE | typeof ITEM_SUBTYPE | typeof BRAND

export interface EditContainerProps {
  type: ValidTypes
  legalization: Partial<Legalization>
  primary: Nullable<Attribute>
  paired: Attribute[]

  doPrimaryChange: (attribute: Attribute | undefined) => void
  doAddPaired: (attribute: Attribute) => void
  doRemovePaired: (attribute: Attribute) => void
  doSubmit: (legalization: Legalization) => void
  doCancel: () => void
}

const getLegalizations = (
  legalizations: Legalization[],
  type: ValidTypes,
): Legalization[] => {
  const typeMap = groupBy('primary_attribute.type', legalizations)
  return getOr([], type, typeMap)
}

const getTypeProps = (
  type: ValidTypes,
  isInternal: boolean,
): AllowedItemTypeProps => {
  switch (type) {
    case BRAND:
      return getBrandAllowedListType({
        isInternal,
      })
    case ITEM_TYPE:
      return getItemTypeAllowedListType({
        isInternal,
      })
    case ITEM_SUBTYPE:
      return getSubtypeAllowedListType({
        isInternal,
      })
    default:
      return getBrandAllowedListType({
        isInternal,
      })
  }
}

const AllowedItemSettingsDialog = ({ isOpen, type }: Props) => {
  const dispatch = useDispatch()
  const currentSeller = useSelector(getCurrentSeller) as SmsSeller
  const memberOf = useSelector(getMemberOf)

  const [allowedList, setAllowedList] = useState<Legalization[]>([])

  useEffect(() => {
    getSellerLegalizationsWithNames(currentSeller.id).then((data) => {
      setAllowedList(getLegalizations(data, type))
    })
  }, [currentSeller, type])

  const [legalization, setLegalization] = useState<Partial<Legalization>>({})
  const [editPrimary, setEditPrimary] = useState<Attribute>()
  const [editPaired, setEditPaired] = useState<Attribute[]>([])

  const [isAsideOpen, setIsAsideOpen] = useState<boolean>(false)

  const confirmAction = () => {
    if (!isAsideOpen) {
      return true
    }

    const isDirty =
      !isEqual(legalization.primary_attribute, editPrimary) ||
      !isEqual(legalization.paired_attributes || [], editPaired)

    return !isDirty || window.confirm('Are you sure?')
  }

  const handleSubmit = (legalization: Legalization) => {
    updateSellerLegalization(
      currentSeller.id,
      legalization.id,
      legalization,
    ).then(() => {
      getSellerLegalizationsWithNames(currentSeller.id).then((data) => {
        setAllowedList(getLegalizations(data, type))
      })
    })

    setLegalization({})
    setEditPrimary(undefined)
    setEditPaired([])
    setIsAsideOpen(false)
  }

  const handleCancel = () => {
    setIsAsideOpen(false)
  }

  const handleClose = () => {
    if (confirmAction()) {
      setLegalization({})
      dispatch(closeDialog())
    }
  }

  const deleteFunction = (legalizationToDelete: Legalization) => {
    if (
      window.confirm(
        `Are you sure you want to delete ${legalizationToDelete.primary_attribute.name} from the list?`,
      )
    ) {
      deleteSellerLegalization(currentSeller.id, legalizationToDelete.id!).then(
        () => {
          getSellerLegalizationsWithNames(currentSeller.id).then((data) => {
            setAllowedList(getLegalizations(data, type))
          })
        },
      )

      if (legalizationToDelete.id === legalization.id) {
        setLegalization({})
      }
    }
  }

  const editFunction = (legalization: Legalization) => {
    if (confirmAction()) {
      setLegalization(legalization)
      setEditPrimary(legalization.primary_attribute)
      setEditPaired(legalization.paired_attributes || [])
      setIsAsideOpen(true)
    }
  }

  const handleAddNew = () => {
    if (confirmAction()) {
      setLegalization({})
      setEditPrimary(undefined)
      setEditPaired([])
      setIsAsideOpen(true)
    }
  }

  const handlePrimaryChange = (primary: Attribute | undefined) => {
    setEditPrimary(primary)
  }

  const handleAddPaired = (paired: Attribute) => {
    setEditPaired((prev) => unionWith(isEqual, prev, [paired]))
  }

  const handleRemovePaired = (paired: Attribute) => {
    setEditPaired((prev) => differenceWith(isEqual, prev, [paired]))
  }

  const EditContainer =
    type === BRAND ? EditBrandContainer : EditAllowedItemSettingsContainer

  const typeProps = getTypeProps(type, !isUserRoleExternal(memberOf))

  return (
    <FullScreenDialogContainer
      title={`Edit ${typeProps.title} List`}
      isOpen={isOpen}
      onRequestClose={handleClose}
      isAsideOpen={isAsideOpen}
      aside={
        <EditContainer
          type={type}
          legalization={legalization}
          primary={editPrimary}
          paired={editPaired}
          doPrimaryChange={handlePrimaryChange}
          doAddPaired={handleAddPaired}
          doRemovePaired={handleRemovePaired}
          doSubmit={handleSubmit}
          doCancel={handleCancel}
        />
      }
    >
      <StyledSeller variant="h3">
        {currentSeller.display_name || currentSeller.legal_business_name}
      </StyledSeller>
      <FullscreenDialogContent
        subtext={typeProps.subtitle}
        addNewButtonText={`Add new ${typeProps.title}`}
        addNewHandler={handleAddNew}
      >
        <LegalizationTable
          isPending={false}
          legalizations={allowedList}
          {...typeProps}
          editFunction={editFunction}
          deleteFunction={deleteFunction}
        />
      </FullscreenDialogContent>
    </FullScreenDialogContainer>
  )
}

export default AllowedItemSettingsDialog
