import React from 'react'
import { useDispatch } from 'react-redux'
import head from 'lodash/fp/head'

import useInterval from 'hooks/useInterval'

import {
  getReports,
  pollReport,
  GenerateReportRequest,
  submitReport,
  cancelReport,
} from 'services/reports'

import { openDialog } from 'store/dialog/actionCreator'
import { showNotification } from 'store/notification/reducer'

import {
  Report,
  ReportStatus,
  ReportType,
  ReportParameters,
} from 'types/Report'

import { DialogEnum } from '../Dialog'
import { Direction } from 'services/pageableHelper'

export interface CancelReportParams {
  sellerId: string | undefined
  reportId: string
}

export interface UseReportsResponse {
  actions: {
    cancel: (params: CancelReportParams) => () => Promise<void>
    generate: () => void
  }
  results: {
    inProgressReport?: Report
    latestReport?: Report
    reports?: Report[]
  }
  statuses: {
    hasError: boolean
    isGettingReports: boolean
    success: boolean
  }
}

interface UseReportsParams {
  dialogEnum?: DialogEnum
  getAdditionalInfo?: (reports: Report[]) => Promise<Report[]>
  getParameters?: () => ReportParameters
  getRelevantReports?: (reports: Report[]) => Report[]
  getReportName?: () => string | undefined
  hasDateRange?: boolean
  hasTable?: boolean
  isInternal?: boolean
  reportType: ReportType
  sellerId?: string
  pollDelay?: number
  statusTimeout?: number
}

export const useReports = ({
  dialogEnum,
  getAdditionalInfo,
  getParameters,
  getRelevantReports,
  getReportName,
  hasDateRange,
  hasTable,
  isInternal,
  reportType,
  sellerId,
  pollDelay = 5000,
  statusTimeout = 3500,
}: UseReportsParams): UseReportsResponse => {
  const [inProgressReport, setInProgressReport] = React.useState<Report>()
  const [isGettingReports, setIsGettingReports] = React.useState(false)
  const [latestReport, setLatestReport] = React.useState<Report>()
  const [reports, setReports] = React.useState<Report[]>([])
  const [success, setSuccess] = React.useState(false)
  const [hasError, setHasError] = React.useState(false)

  const dispatch = useDispatch()

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (success && !hasError) {
        setSuccess(false)
      } else if (!success && hasError) {
        setHasError(false)
      }
    }, statusTimeout)
    return () => {
      clearTimeout(timeout)
    }
  }, [success, hasError, statusTimeout])

  React.useEffect(() => {
    setIsGettingReports(true)

    getReports(
      {
        page: 0,
        perPage: 100,
        direction: Direction.DESC,
        orderBy: 'created',
      },
      { sellerId },
    ).then(async (reports) => {
      let relevantReports = getRelevantReports
        ? getRelevantReports(reports.data)
        : reports.data.filter((report) => report.type === reportType)

      if (getAdditionalInfo) {
        relevantReports = await getAdditionalInfo(relevantReports)
      }

      const inProgress = relevantReports.find(
        (report) =>
          report.status === ReportStatus.PENDING ||
          report.status === ReportStatus.PROCESSING,
      )

      const latest = relevantReports.find(
        (report) => report.status === ReportStatus.COMPLETE,
      )

      setIsGettingReports(false)
      setInProgressReport(inProgress)
      setLatestReport(latest)

      if (hasTable) {
        setReports(relevantReports)
      }
    })
  }, [reportType, hasTable, sellerId, getRelevantReports, getAdditionalInfo])

  useInterval(
    () => {
      if (!inProgressReport) return

      const id = !isInternal ? sellerId : undefined

      pollReport(inProgressReport.id, id).then(async (report) => {
        if (
          report.status === ReportStatus.PENDING ||
          report.status === ReportStatus.PROCESSING
        ) {
          return
        }

        setInProgressReport(undefined)

        // Assume that the report is good if it has a download URL
        // TODO this issue was fixed in BE. status check for error and use feedback for any type of messages.
        if (report.download_url) {
          setLatestReport(report)
          setSuccess(true)
        } else {
          setHasError(true)
          const errorMessage = head(report.feedback) || 'Report failed'

          dispatch(
            showNotification({
              isShown: true,
              message: `ERROR: ${errorMessage}`,
            }),
          )
        }
        let relevantReports: Report
        if (getAdditionalInfo) {
          const reportWithSellerName = await getAdditionalInfo([report])
          relevantReports = reportWithSellerName[0] ?? report
        }

        if (!hasTable) return

        // The card has a table so update the report data for the table
        setReports((prev) =>
          prev.map((item) => {
            if (relevantReports && relevantReports.id === item.id) {
              return relevantReports
            } else if (item.id === report.id) {
              return report
            } else {
              return item
            }
          }),
        )
      })
    },
    inProgressReport ? pollDelay : null,
  )

  const generateReport = () => {
    let parameters
    let reportName
    if (getParameters) {
      parameters = getParameters()
    }

    if (getReportName) {
      reportName = getReportName()
    }

    if (dialogEnum) {
      const onRequestSubmit = hasDateRange ? handleSubmitReport : uploadReport

      dispatch(
        openDialog({
          dialogEnum,
          componentProps: {
            onRequestSubmit,
            sellerId,
            parameters,
            reportName,
            reportType,
          },
        }),
      )
    } else {
      handleSubmitReport({ parameters, reportName })
    }
  }

  const uploadReport = (report: Report) => {
    if (hasTable) {
      setReports((prev) => [report, ...prev])
    }

    setInProgressReport(report)
  }

  const handleSubmitReport = async (
    reportParams: Partial<GenerateReportRequest> = {},
  ) => {
    const defaultConfig = {
      sellerId,
      isInternal,
      type: reportType,
    }

    // Clone so request.sellerId does not override props.sellerId
    const payload = {
      ...defaultConfig,
      ...reportParams,
    }
    let report = await submitReport(payload)

    // generating report
    if (getAdditionalInfo) {
      const reports = await getAdditionalInfo([report])
      report = reports[0] ?? report
    }

    if (hasTable) {
      setReports((prev) => [report, ...prev])
    }

    setInProgressReport(report)
  }

  const handleCancel =
    ({ sellerId, reportId }: CancelReportParams) =>
    async () => {
      await cancelReport({ sellerId, reportId })

      if (hasTable) {
        setReports((prev) =>
          prev.map((item) => {
            if (item.id === reportId) {
              return {
                ...item,
                status: ReportStatus.CANCELED,
              }
            }

            return item
          }),
        )

        return // exit and keep polling api if other reports are being generated
      }

      setInProgressReport(undefined)
    }

  return {
    actions: {
      cancel: handleCancel,
      generate: generateReport,
    },
    results: {
      inProgressReport,
      latestReport,
      reports,
    },
    statuses: {
      hasError,
      isGettingReports,
      success,
    },
  }
}

export default useReports
