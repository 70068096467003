import { useEffect, useReducer } from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { getRelationshipsFieldList } from './getRelationshipsFieldList'
import reducer, {
  initialState,
  setChildren,
  setParent,
  setPending,
} from './RelationshipsReducer'

import DataList from 'components/common/DataList'
import EnhancedTable from 'components/common/EnhancedTable'
import useTable from 'components/common/EnhancedTable/useTable'
import Link from 'components/common/Link'
import LiveLink from 'components/common/LiveLink'
import StatusText from 'components/common/StatusText'
import TableSpacer from 'components/common/TableSpacer'
import TitleBar from 'components/common/TitleBar/TitleBar'

import {
  getMarketplaceProductById,
  getMarketplaceProducts,
} from 'services/items'
import { RoutePath } from 'services/NavigationHelper'
import { MarketplaceProduct, RelationshipType } from 'types/Item'
import { CollectionResponse } from 'types/Response'

export type Props = {
  isItemPending: boolean
  marketplaceProduct?: MarketplaceProduct
}

export const Relationships: React.FC<Props> = ({
  isItemPending,
  marketplaceProduct,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { parent, children, total, pending, variations } = state

  const { table } = useTable({
    page: 0,
    perPage: 20,
  })

  useEffect(() => {
    if (
      marketplaceProduct &&
      marketplaceProduct.relationship_type === RelationshipType.VC
    ) {
      dispatch(setPending(true))
      getMarketplaceProductById(marketplaceProduct.parent_product_id)
        .then((response) => dispatch(setParent(response)))
        .finally(() => dispatch(setPending(false)))
    } else {
      dispatch(setParent(marketplaceProduct))
    }
  }, [marketplaceProduct])

  useEffect(() => {
    if (parent) {
      dispatch(setPending(true))
      getMarketplaceProducts(
        { parent_product_id: parent.product_id },
        table.state,
      )
        .then((response: CollectionResponse<MarketplaceProduct>) => {
          dispatch(setChildren(response.data, response.total))
        })
        .finally(() => dispatch(setPending(false)))
    } else {
      dispatch(setChildren([], 0))
    }
  }, [parent, table.state])

  const parentData = parent
    ? [
        {
          title: 'VAP TCIN',
          element: (
            <Link
              to={`/${parent.seller_id}${RoutePath.ALL_ITEMS}/${parent.product_id}`}
            >
              {parent.tcin}
            </Link>
          ),
        },
        {
          title: 'Latest Status',
          element: <StatusText label={parent.listing_status} />,
        },
        {
          title: 'Published',
          element: (
            <LiveLink published={parent.published} buyUrl={parent.buy_url} />
          ),
        },
      ]
    : []

  if (
    !parent ||
    (marketplaceProduct &&
      marketplaceProduct.relationship_type === RelationshipType.SA)
  ) {
    return <Typography>This item has no relationships.</Typography>
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitleBar title="Variation Family" />
          <Typography>
            A list of all the items within a Variation Parent (VAP) family.
          </Typography>
        </Grid>
        <Grid item>
          <DataList data={parentData} />
        </Grid>
      </Grid>
      <TableSpacer>
        <EnhancedTable
          data={children}
          fieldList={getRelationshipsFieldList(variations)}
          isLoading={pending || isItemPending}
          onChangePage={table.actions.changePage}
          onChangeRowsPerPage={table.actions.changePerPage}
          onRequestSort={table.actions.sort}
          orderBy={table.state.orderBy}
          order={table.state.direction}
          page={table.state.page}
          rowsPerPage={table.state.perPage}
          total={total}
        />
      </TableSpacer>
    </>
  )
}

export default Relationships
