import HeaderTitle from 'components/common/HeaderTitle'
import ErrorBoundary from 'components/common/ErrorBoundary'
import UserContent from './UserContent'

const UserAccessPage = () => {
  return (
    <div data-testid="user-access-page">
      <ErrorBoundary page="Users & Contacts">
        <HeaderTitle title="Users & Contacts" />
        <UserContent />
      </ErrorBoundary>
    </div>
  )
}

export default UserAccessPage
