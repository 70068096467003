import { PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import Tooltip from '@mui/material/Tooltip'
import Warning from '@mui/icons-material/Warning'
import { error } from 'config/themeConfig'

type Props = {
  tooltip?: string
}

type StyledWarningProps = {
  hasChildren: boolean
}

const StyledRoot = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const StyledWarning = styled(Warning, {
  shouldForwardProp: (prop) => prop !== 'hasChildren',
})<StyledWarningProps>(
  {
    color: error.main,
  },
  (props) => ({
    marginRight: props.hasChildren ? props.theme.spacing(1) : 0,
  }),
)

export const WarningIcon: React.FC<PropsWithChildren<Props>> = ({
  tooltip,
  children,
}) => {
  if (tooltip) {
    return (
      <StyledRoot>
        <Tooltip title={tooltip}>
          <StyledWarning hasChildren={!!children} />
        </Tooltip>
        {children}
      </StyledRoot>
    )
  }

  return (
    <StyledRoot>
      <StyledWarning hasChildren={!!children} />
      {children}
    </StyledRoot>
  )
}

export default WarningIcon
