import { Routes, Route } from 'react-router-dom'

import ItemDetailsPage from './ItemDetailsPage'
import AllItemsPage from './AllItemsPage'

export const Items = () => {
  return (
    <Routes>
      <Route index element={<AllItemsPage />} />
      <Route path=":productId" element={<ItemDetailsPage />} />
    </Routes>
  )
}

export default Items
