import React from 'react'
import { useSelector } from 'react-redux'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { DialogEnum } from 'components/common/Dialog'
import EditButton from 'components/common/EditButton'
import HeaderTitle from 'components/common/HeaderTitle'
import TitleBar from 'components/common/TitleBar'

import { isOneOfUserRoles } from 'services/authorization'
import { USER_ROLE_ADMIN, USER_ROLE_ITEM_MANAGER } from 'services/roles'

import { getMemberOf } from 'store/selectors'

import ErrorCodesTable from './ErrorCodesTable'

export const ErrorCodesPage = () => {
  const memberOf = useSelector(getMemberOf)
  const [reload, setReload] = React.useState(false)
  const canEdit = isOneOfUserRoles(memberOf, [
    USER_ROLE_ADMIN,
    USER_ROLE_ITEM_MANAGER,
  ])

  const handleDialogClose = () => {
    setReload(!reload)
  }

  return (
    <>
      <HeaderTitle title="Error Codes" />
      <TitleBar
        title="Error Codes"
        actionButtons={[
          <EditButton
            key="edit-error-codes"
            aria-label="edit-error-codes"
            dialogComponent={DialogEnum.EDIT_ERROR_CODES}
            closeCallback={handleDialogClose}
            hide={!canEdit}
          />,
        ]}
      />
      <Grid container>
        <Grid item xs={12}>
          <Typography data-testid="error-code-blurb">
            These error codes are used while manually reviewing items in the
            item queue.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ErrorCodesTable reload={reload} />
        </Grid>
      </Grid>
    </>
  )
}

export default ErrorCodesPage
