import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as yup from 'yup'
import styled from '@emotion/styled'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import Input from 'components/common/Input'
import RadioGroup from 'components/common/RadioGroup/RadioGroup'

import { createVmmBrand } from 'services/brands'
import { validationHandler } from 'services/validation'

import { closeDialog } from 'store/dialog/actionCreator'

import { Validation } from 'types/Validation'
import { getUser } from 'store/selectors'

const StyledContainer = styled('div')((props) => ({
  marginBottom: props.theme.spacing(1),
}))

export interface Props {
  isOpen: boolean
}

export const AddBrandDialog = ({ isOpen }: Props) => {
  const dispatch = useDispatch()
  const userId = useSelector(getUser).lanId

  const defaultBrand = {
    name: '',
    abbreviation: '',
    description: '',
    userId,
    isProperty: 'N',
    isPersonality: 'N',
  }
  const [brand, setBrand] = useState(defaultBrand)
  const [submitDisabled, setSubmitDisabled] = useState(false)

  const [validation, setValidation] = useState<Validation>()
  const schemaDefinition = {
    name: yup.string().required().min(2).max(35),
    abbreviation: yup.string().required().max(10),
    description: yup.string().required().max(100),
  }

  const validationSchema = yup.object().shape(schemaDefinition)

  const getPartialValidationSchema = (newBrand: any) => {
    const partialDefinition: any = {}

    if (newBrand.name.length >= 2) {
      partialDefinition.name = schemaDefinition.name
    }

    if (newBrand.abbreviation.length > 0) {
      partialDefinition.abbreviation = schemaDefinition.abbreviation
    }

    if (newBrand.description.length > 0) {
      partialDefinition.description = schemaDefinition.description
    }

    return yup.object().shape(partialDefinition)
  }

  const handleInputChange = (value: string, name: string) => {
    const updatedBrand = { ...brand, [name]: value }
    setBrand(updatedBrand)
    const { validation: partialValidation } = validationHandler(
      getPartialValidationSchema(updatedBrand),
      updatedBrand,
    )

    setValidation(partialValidation)
  }

  const handleInputBlur = (value: string, name: string) => {
    const updatedBrand = { ...brand, [name]: value.trim() }
    setBrand(updatedBrand)
  }

  const addBrand = () => {
    const { validation: newValidation, isValid } = validationHandler(
      validationSchema,
      brand,
    )
    setValidation(newValidation)

    if (!isValid) return

    setSubmitDisabled(true)

    createVmmBrand(brand)
      .then(() => {
        dispatch(closeDialog())
      })
      .finally(() => {
        setSubmitDisabled(false)
      })
  }

  return (
    <DialogContainer
      title="Add New Brand"
      isOpen={isOpen}
      onSubmit={addBrand}
      isSubmitDisabled={submitDisabled}
      data-testid="add-brand-container"
    >
      <StyledContainer>
        <Input
          id="add-brand-name"
          name="name"
          type="string"
          label="Brand Name*"
          value={brand.name}
          validation={validation}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
        />
      </StyledContainer>
      <StyledContainer>
        <Input
          id="add-brand-abbreviation"
          name="abbreviation"
          type="string"
          label="Brand Abbreviation*"
          value={brand.abbreviation}
          validation={validation}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
        />
      </StyledContainer>
      <StyledContainer>
        <Input
          id="add-brand-description"
          name="description"
          type="textArea"
          label="Brand Description*"
          value={brand.description}
          validation={validation}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
        />
      </StyledContainer>
      <RadioGroup
        id="add-brand-isPersonality"
        name="isPersonality"
        label="Is this Brand a Personality/Character?*"
        options={[
          { label: 'Yes', value: 'Y' },
          { label: 'No', value: 'N' },
        ]}
        value={brand.isPersonality}
        onChange={handleInputChange}
      />
      <RadioGroup
        id="add-brand-isProperty"
        name="isProperty"
        label="Is this Brand a Property?*"
        options={[
          { label: 'Yes', value: 'Y' },
          { label: 'No', value: 'N' },
        ]}
        value={brand.isProperty}
        onChange={handleInputChange}
      />
    </DialogContainer>
  )
}

export default AddBrandDialog
