function formatPhoneNumber(input: string = ''): string {
  // credit https://stackoverflow.com/questions/30058927/format-a-phone-number-as-a-user-types-using-pure-javascript
  input = input.replace(/\D/g, '')
  input = input.substring(0, 10)
  const size = input.length
  let newInput
  if (size === 0) {
    newInput = input
  } else if (size < 4) {
    newInput = '(' + input
  } else if (size < 7) {
    newInput = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6)
  } else {
    newInput =
      '(' +
      input.substring(0, 3) +
      ') ' +
      input.substring(3, 6) +
      '-' +
      input.substring(6, 10)
  }
  return newInput
}

export default formatPhoneNumber
