import {
  CompressedChildGroup,
  ProductCountResponse,
  PropertyValueCount,
} from 'types/productCounts'
import { ReviewQueueSearchParams } from 'types/ReviewQueueSearchParams'
import { ListingStatus, ValidationStatus } from 'types/Item'

import { Direction } from 'services/pageableHelper'
import {
  getOptionalPublishedToggleValue,
  getOptionalToggleValue,
} from 'services/toggle'

const compressChildGroupStructure = (
  count: PropertyValueCount,
): CompressedChildGroup[] | undefined => {
  if (!count.child_group) {
    return
  }

  const valueCounts = count.child_group.property_value_counts.map(
    (child: PropertyValueCount) => {
      const newChild: CompressedChildGroup = {
        value: child.value,
        count: child.count,
        children: undefined,
      }

      if (child.child_group) {
        newChild.children = compressChildGroupStructure(child)
      }

      return newChild
    },
  )

  return valueCounts.length > 0 ? valueCounts : undefined
}

export const transformDataForAmCharts = (data: ProductCountResponse) => {
  const counts = data.product_counts[0].property_values

  const pending = counts.property_value_counts[0]

  if (!pending || !pending.child_group) {
    return []
  }

  const compressedData = compressChildGroupStructure(pending)

  if (compressedData) {
    return compressedData.map((count) => {
      switch (count.value) {
        case ValidationStatus.VALIDATED:
          return { ...count, color: '#00e676' }
        case ValidationStatus.REVIEW:
          return { ...count, color: '#ffea00' }
        case ValidationStatus.BLOCKED:
          return { ...count, color: '#ff1744' }
        default:
          return count
      }
    })
  }
}

export const transformDataForDownload = (
  data: ProductCountResponse,
): string => {
  const downloadData = '"Partner","Item Type","Status","Count"\n'
  const counts = data.product_counts[0].property_values

  const sellers = counts.property_value_counts

  if (!sellers) return downloadData

  const sellerStrings = sellers.map((seller) => {
    const accumulator: Dictionary<number> = {}

    const children = seller.child_group
      ? seller.child_group.property_value_counts
      : []
    const childStrings = children.map((child) => {
      const grandchildren = child.child_group
        ? child.child_group.property_value_counts
        : []

      const grandchildStrings = grandchildren.map((grandchild) => {
        accumulator[grandchild.value] =
          accumulator[grandchild.value] !== undefined
            ? accumulator[grandchild.value] + grandchild.count
            : grandchild.count

        return `"${seller.value}","${child.value}","${grandchild.value}","${grandchild.count}"\n`
      })
      return grandchildStrings.join('')
    })

    let out = ''
    Object.keys(accumulator).forEach((key) => {
      out = out.concat(
        `"${seller.value}","All item types","${key}","${accumulator[key]}"\n`,
      )
    })

    return out.concat(...childStrings)
  })
  return downloadData.concat(...sellerStrings)
}

export const initialSearchParams: ReviewQueueSearchParams = {
  orderBy: 'last_modified',
  direction: Direction.ASC,
  page: 0,
  perPage: 100,
  published: undefined,
  validation_status: [
    ValidationStatus.REVIEW,
    ValidationStatus.BLOCKED,
    ValidationStatus.VALIDATED,
  ],
  seller_id: undefined,
  item_type_id: undefined,
  tcin: undefined,
  has_inventory: undefined,
  previously_approved: undefined,
  seller_status: undefined,
  product_type_id: undefined,
  saved_search_name: undefined,
}

export const buildSearchParams = ({
  published,
  validationStatus,
  sellerId,
  itemTypeId,
  tcin,
  hasInventory,
  previouslyApproved,
  orderBy,
  direction,
  sellerStatus,
  productTypeId,
}: {
  published?: string
  validationStatus?: string | string[]
  sellerId?: string | string[]
  itemTypeId?: string
  tcin?: string
  hasInventory?: string
  previouslyApproved?: string
  orderBy?: string
  direction?: Direction
  sellerStatus?: string[]
  productTypeId?: string
}) => {
  const publishedValue = getOptionalPublishedToggleValue(published)
  const inventory = getOptionalToggleValue(hasInventory)
  const previouslyApprovedValue = getOptionalToggleValue(previouslyApproved)
  const validation = validationStatus
    ? validationStatus
    : [
        ValidationStatus.BLOCKED,
        ValidationStatus.REVIEW,
        ValidationStatus.VALIDATED,
      ]
  return {
    published: publishedValue,
    validation_status: validation,
    seller_id: sellerId,
    item_type_id: itemTypeId,
    tcin,
    has_inventory: inventory,
    previously_approved: previouslyApprovedValue,
    listing_status: ListingStatus.PENDING,
    orderBy: orderBy,
    direction: direction,
    seller_status: sellerStatus,
    product_type_id: productTypeId,
  }
}
