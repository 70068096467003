export interface Notification extends NotificationUpdate {
  id: string
  created: string
  created_by: string
  last_modified: string
  last_modified_by: string
}

export interface NotificationUpdate {
  id?: string
  title: string
  message: string
  priority: NotificationPriority
  display_for: string[]
  display_on?: NotificationDisplayOn[]
  start_date: string
  end_date: string
}

export enum NotificationDisplayOn {
  PARTNER_DASHBOARD = 'PARTNER_DASHBOARD',
  HOMEPAGE = 'HOMEPAGE',
}

export enum NotificationPriority {
  CRITICAL = 'CRITICAL',
  WARNING = 'WARNING',
  INFORM = 'INFORM',
}
