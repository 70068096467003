import axios from 'axios'

import apiConfig from 'config/apiConfig'

import RegistrationResult from 'types/RegistrationResult'
import { PaymentAccount } from 'types/PaymentAccount'

export const STRIPE_TRANSFER_PAGE_SIZE = 30

export async function getRegistrationUrl(sellerId: string) {
  const { data } = await axios.post(
    `${apiConfig.sms}/sellers/${sellerId}/payments_create_registration_link`,
    null,
  )

  const registrationUrl = createRegistrationUrl(data)

  return registrationUrl
}

export function createRegistrationUrl({
  client_id,
  state,
}: {
  client_id: string
  state: string
}): string {
  return `${apiConfig.stripeAuthUrl}?redirect_uri=${window.location.origin}/stripe_account_success&client_id=${client_id}&state=${state}&suggested_capabilities[]=card_payments&transfers`
}

export async function confirmAccount(
  sellerId: string,
  registrationResult: RegistrationResult,
) {
  const response = await axios.post(
    `${apiConfig.sms}/sellers/${sellerId}/payments_confirm_account`,
    registrationResult,
  )

  return response
}

export async function createLoginLink(
  sellerId: string,
): Promise<string | undefined> {
  try {
    const { data } = await axios.post(
      `${apiConfig.sms}/sellers/${sellerId}/payments_create_login_link`,
      null,
    )

    return data.url
  } catch (e) {
    return undefined
  }
}

export async function getPaymentAccounts(
  sellerId: string,
): Promise<PaymentAccount> {
  const response = await axios.get(
    `${apiConfig.sms}/payment_accounts/${sellerId}`,
  )

  return response.data
}

export async function getStripeAccountId(sellerId: string): Promise<String> {
  const response = await axios.get(
    `${apiConfig.sms}/sellers/${sellerId}/stripe_account_id`,
  )

  return response.data.stripe_account_id
}
