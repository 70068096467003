export const DOC_CONTENT_TYPE = 'application/msword'
export const DOCM_CONTENT_TYPE =
  'application/vnd.ms-word.document.macroEnabled.12'
export const DOCX_CONTENT_TYPE =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
export const XLS_CONTENT_TYPE = 'application/vnd.ms-excel'
export const XLSM_CONTENT_TYPE =
  'application/vnd.ms-excel.sheet.macroEnabled.12'
export const XLXS_CONTENT_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export const ACCEPTED_FILE_TYPES = `image/jpeg, image/png, image/gif,image/bmp, ${DOC_CONTENT_TYPE}, ${DOCM_CONTENT_TYPE}, ${DOCX_CONTENT_TYPE}, ${XLS_CONTENT_TYPE}, ${XLSM_CONTENT_TYPE}, ${XLXS_CONTENT_TYPE}, application/pdf, application/rtf, text/html, text/csv`
export const FILE_TYPE_ERROR_MESSAGE =
  'File must be of type .jpg, .jpeg, .gif, .bmp, .png, .csv, .doc, .docm, .docx, .htm, .html, .pdf, .rtf, .xls, .xlsm, or .xlsx'
