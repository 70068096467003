import React from 'react'
import { useSelector } from 'react-redux'

import { withFormik, Form, FormikProps } from 'formik'
import * as yup from 'yup'
import toString from 'lodash/fp/toString'
import toNumber from 'lodash/fp/toNumber'

import Grid from '@mui/material/Grid'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'

import InputField from 'components/common/form/InputField'
import SelectField from 'components/common/form/SelectField'
import RadioGroupField from 'components/common/form/RadioGroupField'

import { updateErrorCode, errorCodeExists } from 'services/errorCodes'
import { maxCharacterCount } from 'services/validation'

import { getEnumerationValues } from 'store/selectors'

import { EnumerationName } from 'types/Enumeration'
import { ErrorCode, Severity } from 'types/ErrorCode'
import StoreState from 'types/state'

const validationSchema = (props: Props) => {
  return yup.object().shape({
    code: yup
      .number()
      .label('Error code')
      .typeError('Error code must be numeric')
      .min(100, 'Error code must be 3 digits')
      .max(999, 'Error code must be 3 digits')
      .required()
      .test({
        name: 'isSystemError',
        message: '7xx codes are reserved/required for System Errors',
        test(this: yup.TestContext, value: Nullable<number>) {
          const category = this.resolve(yup.ref('category'))
          const isDataValidation = category === 'DATA_VALIDATION'
          const path = 'code'

          if (!value || value.toString().length !== 3) {
            return true
          }

          return errorCodeExists(value.toString()).then((res) => {
            if (res && props.isNew) {
              return this.createError({
                path,
                message: 'Error code already exists',
              })
            } else {
              if (!category) {
                return true
              }

              if (!props.isAdmin) {
                if (!isDataValidation && value >= 700 && value <= 799) {
                  return this.createError({
                    path: 'code',
                    message:
                      'Not authorized to create 7xx codes reserved for system errors',
                  })
                } else if (value >= 800 && value <= 899) {
                  return this.createError({
                    path,
                    message: 'Not authorized to create 8xx codes',
                  })
                } else {
                  return true
                }
              } else if (isDataValidation) {
                return value >= 700 && value <= 799
              } else {
                return value < 700 || value > 799
              }
            }
          })
        },
      }),
    category: yup.string().label('Error category').required(),
    reason: yup
      .string()
      .label('Error text')
      .test({
        name: 'isError text',
        test: maxCharacterCount('reason', 200),
      })
      .required(),
    action: yup
      .string()
      .label('Action text')
      .test({
        name: 'isAction text',
        test: maxCharacterCount('action', 200),
      })
      .max(200, 'Partner action text is limited to 200 characters'),
    severity: yup.string().label('Severity').required(),
  })
}

interface FormValues {
  code: string
  category: string
  reason: string
  action: string
  severity: string
}

export interface ComponentProps {
  isAdmin: boolean
  isNew: boolean
  errorCode: Partial<ErrorCode>
  onCancel: () => void
  onSubmitHook: () => void
  onDirtyChange: (dirty: boolean) => void
}

type Props = ComponentProps & FormikProps<FormValues>

const severityOptions = [
  {
    label: 'Critical',
    value: Severity.CRITICAL,
  },
  {
    label: 'Non-critical',
    value: Severity.NON_CRITICAL,
  },
]

const EditErrorCodeForm = ({
  isAdmin,
  isNew,
  onCancel,
  onDirtyChange,
  isValid,
  dirty,
  isSubmitting,
}: Props) => {
  const categories = useSelector((state: StoreState) =>
    getEnumerationValues(state, EnumerationName.ERROR_CATEGORY),
  )

  React.useEffect(() => onDirtyChange(dirty), [dirty, onDirtyChange])

  let filteredCategories: string[]
  if (!isAdmin) {
    filteredCategories = categories.filter((category) => {
      return category !== 'DATA_VALIDATION'
    })
  } else {
    filteredCategories = [...categories]
  }

  return (
    <Form>
      <Grid container spacing={2} data-testid="error-code-aside">
        <Grid item xs={12}>
          <InputField
            required
            name="code"
            label="Create a 3 digit code"
            helperText="(Must be numeric)"
            disabled={!isNew}
            inputProps={{
              'data-testid': 'input-code',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectField
            required
            name="category"
            options={filteredCategories}
            label="Select Error Category"
            data-testid="category"
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            required
            multiline
            name="reason"
            label="Enter Error Text for Partner"
            helperText="(Limit to 200 characters)"
            maxCharacters={200}
            rows={3}
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            multiline
            name="action"
            label="Enter Partner Action Text"
            helperText="(Limit to 200 characters)"
            maxCharacters={200}
            rows={3}
          />
        </Grid>
        <Grid item xs={12}>
          <RadioGroupField
            name="severity"
            options={severityOptions}
            required
            row
          />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DialogActions>
              <Button
                color="primary"
                type="reset"
                disabled={isSubmitting}
                onClick={onCancel}
                data-testid="cancel"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={!isValid || !dirty || isSubmitting}
                color="primary"
                data-testid="submit"
              >
                Save
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  )
}

export const EditErrorCodeAside = withFormik<ComponentProps, FormValues>({
  mapPropsToValues: ({ errorCode }) => ({
    code: toString(errorCode.error_code),
    category: toString(errorCode.error_category),
    reason: toString(errorCode.reason),
    action: toString(errorCode.error_resolution),
    severity: toString(errorCode.error_severity),
  }),
  handleSubmit: async (values, { props, ...actions }) => {
    try {
      await updateErrorCode({
        id: props.isNew ? undefined : toNumber(values.code),
        data: {
          error_code: toNumber(values.code),
          error_category: values.category,
          reason: values.reason,
          error_resolution: values.action,
          error_severity: values.severity,
        },
      })
      if (props.isNew) {
        actions.resetForm()
      }
      props.onSubmitHook()
    } catch (error) {
      console.error(`Submit Failed:: ${error}`)
    }

    actions.setSubmitting(false)
  },
  enableReinitialize: true,
  validationSchema,
})(EditErrorCodeForm)

export default EditErrorCodeAside
