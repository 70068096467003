import React from 'react'
import { SvgIconProps } from '@mui/material/SvgIcon'

export default function Bullseye(svgData: SvgIconProps) {
  return React.createElement(
    'svg',
    {
      focusable: 'false',
      tabIndex: svgData.tabIndex,
      width: svgData.width ?? 24,
      height: svgData.height ?? 24,
      className: svgData.className,
      style: svgData.style,
      version: '1.1',
      viewBox: '0 0 24 24',
      xmlns: 'http://www.w3.org/2000/svg',
    },

    React.createElement(
      'g',
      { fill: svgData.fill || '#FFFFFF' },
      React.createElement('path', {
        d: 'M12 1c6.074 0 10.999 4.925 10.999 11 0 6.074-4.925 10.999-11 10.999-6.074 0-10.999-4.925-10.999-11C1 5.925 5.925 1 12 1zm0 18.173a7.174 7.174 0 10-.001-14.347 7.174 7.174 0 000 14.347zm0-3.653a3.52 3.52 0 110-7.04 3.52 3.52 0 010 7.04z',
      }),
    ),
  )
}
