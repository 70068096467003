import React from 'react'

import { DialogEnum } from 'components/common/Dialog'
import TitleBar from 'components/common/TitleBar'
import Heading from 'components/common/Heading'
import EditButton from 'components/common/EditButton'
import DataList, { DataListItem } from 'components/common/DataList'

import {
  formatDate,
  DATE_DISPLAY_FORMAT,
  DATE_PICKER_FORMAT,
} from 'services/dateService'

import { DiversityAnswer, DiversityQuestion } from 'types/PartnerDiversity'

type FormattedAnswers = {
  label: string
  answers: DataListItem[]
}

const getGroupedAnswers = (answers: DiversityAnswer[]): FormattedAnswers[] => {
  const categoryWoman: FormattedAnswers = {
    label: `Woman Owned Business`,
    answers: [],
  }
  const categoryBIPOC: FormattedAnswers = {
    label: `BIPOC Owned Business`,
    answers: [],
  }

  const categoryLGBTQIA: FormattedAnswers = {
    label: `LGBTQIA Owned Business`,
    answers: [],
  }

  const categoryVeteran: FormattedAnswers = {
    label: `Veteran Owned Business`,
    answers: [],
  }

  const categorySmallBusiness: FormattedAnswers = {
    label: `Small/Disadvantaged Business`,
    answers: [],
  }

  const categoryDifferentlyAbled: FormattedAnswers = {
    label: `Differently Abled Owned Business`,
    answers: [],
  }

  const categoryHubZoneAlaskanNativeBusiness: FormattedAnswers = {
    label: `HUB Zone or Alaskan Native Owned Business`,
    answers: [],
  }

  for (let i = 0; i < answers.length; i++) {
    const current = answers[i]

    if (
      current.diversity_question === DiversityQuestion.WOMAN &&
      current.answer === true
    ) {
      categoryWoman.answers.push({
        title: `Women's Business Enterprise National Council (WBENC) or Regional Affiliate Certificate Number:`,
        value: current.certification?.cert_number ?? 'N/A',
      })
      categoryWoman.answers.push({
        title: `WBENC Certificate Expiration Date:`,
        value: current.certification?.expiration_date
          ? formatDate(
              current.certification?.expiration_date,
              DATE_DISPLAY_FORMAT,
              DATE_PICKER_FORMAT,
            )
          : 'N/A',
      })
    }

    if (
      current.diversity_question === DiversityQuestion.WOMAN_OTHER &&
      current.answer === true
    ) {
      categoryWoman.answers.push({
        title: `If Certified by An Agency Other than WBENC, Privide Agency Name:`,
        value: current.certification?.agency ?? 'N/A',
      })
      categoryWoman.answers.push({
        title: `Other Agency Certificate Number:`,
        value: current.certification?.cert_number ?? 'N/A',
      })
      categoryWoman.answers.push({
        title: `Other Agency Certificate Expiration Date:`,
        value: current.certification?.expiration_date
          ? formatDate(
              current.certification?.expiration_date,
              DATE_DISPLAY_FORMAT,
              DATE_PICKER_FORMAT,
            )
          : 'N/A',
      })
    }

    if (
      current.diversity_question === DiversityQuestion.BIPOC &&
      current.answer === true
    ) {
      categoryBIPOC.answers.push({
        title: 'Ethnicity Type:',
        value: current.ethnicity ?? 'N/A',
      })
      categoryBIPOC.answers.push({
        title:
          'National Minority Supplier Development Council (NMSDC) Certificate Number:',
        value: current.certification?.cert_number ?? 'N/A',
      })
      categoryBIPOC.answers.push({
        title: 'NMSDC Certificate Expiration Date:',
        value: current.certification?.expiration_date
          ? formatDate(
              current.certification?.expiration_date,
              DATE_DISPLAY_FORMAT,
              DATE_PICKER_FORMAT,
            )
          : 'N/A',
      })
    }

    if (
      current.diversity_question === DiversityQuestion.LGBTQ &&
      current.answer === true
    ) {
      categoryLGBTQIA.answers.push({
        title:
          'National Gay & Lesbian Chamber of Commerce (NGLCC) Certificate Number:',
        value: current.certification?.cert_number ?? 'N/A',
      })
      categoryLGBTQIA.answers.push({
        title: 'NGLCC Certificate Expiration Date:',
        value: current.certification?.expiration_date
          ? formatDate(
              current.certification?.expiration_date,
              DATE_DISPLAY_FORMAT,
              DATE_PICKER_FORMAT,
            )
          : 'N/A',
      })
    }

    if (
      current.diversity_question === DiversityQuestion.VETERAN &&
      current.answer === true
    ) {
      categoryVeteran.answers.push({
        title: 'DD214 from Department of Defense:',
        value: current.has_certification ? 'Yes' : 'No',
      })
    }

    if (
      current.diversity_question === DiversityQuestion.SERVICE_DISABLED_VET &&
      current.answer === true
    ) {
      categoryVeteran.answers.push({
        title: 'Service Disabled Veteran Owned Business:',
        value: current.answer ? 'Yes' : 'No',
      })
      categoryVeteran.answers.push({
        title:
          'Letter from Department of Veterans Affairs Stating Disablilty %:',
        value: current.has_certification ? 'Yes' : 'No',
      })
    }

    if (
      current.diversity_question === DiversityQuestion.SMALL_BUSINESS &&
      current.answer === true
    ) {
      categorySmallBusiness.answers.push({
        title:
          'Are you a Small and/or Disadvantaged Business Certified by SBA(8)A:',
        value: current.answer ? 'Yes' : 'No',
      })
    }

    if (
      current.diversity_question ===
        DiversityQuestion.SMALL_DISADVANTAGED_BUSINESS &&
      current.answer === true
    ) {
      categorySmallBusiness.answers.push({
        title:
          'Are you a Small Disadvantaged Business (SDB) Certified by the Government:',
        value: current.answer ? 'Yes' : 'No',
      })
      categorySmallBusiness.answers.push({
        title: 'SDB Government Certificate Number:',
        value: current.certification?.cert_number ?? 'N/A',
      })
      categorySmallBusiness.answers.push({
        title: 'NGLCC Certificate Expiration Date:',
        value: current.certification?.expiration_date
          ? formatDate(
              current.certification?.expiration_date,
              DATE_DISPLAY_FORMAT,
              DATE_PICKER_FORMAT,
            )
          : 'N/A',
      })
    }

    if (
      current.diversity_question ===
        DiversityQuestion.DISADVANTAGED_BUSINESS_ENTERPRISE &&
      current.answer === true
    ) {
      categorySmallBusiness.answers.push({
        title:
          'Are you a Disadvantaged Business Enterprise (DBE) Certified by the Government:',
        value: current.answer ? 'Yes' : 'No',
      })
      categorySmallBusiness.answers.push({
        title: 'DBE Government Certificate Number:',
        value: current.certification?.cert_number ?? 'N/A',
      })
      categorySmallBusiness.answers.push({
        title: 'DBE Certificate Expiration Date:',
        value: current.certification?.expiration_date
          ? formatDate(
              current.certification?.expiration_date,
              DATE_DISPLAY_FORMAT,
              DATE_PICKER_FORMAT,
            )
          : 'N/A',
      })
    }

    if (
      current.diversity_question === DiversityQuestion.DIFFERENTLY_ABLED &&
      current.answer === true
    ) {
      categoryDifferentlyAbled.answers.push({
        title: 'US Business Leadership Network (USBLN) Certificate Number:',
        value: current.certification?.cert_number ?? 'N/A',
      })
      categoryDifferentlyAbled.answers.push({
        title: 'USBLN Certificate Expiration Date:',
        value: current.certification?.expiration_date
          ? formatDate(
              current.certification?.expiration_date,
              DATE_DISPLAY_FORMAT,
              DATE_PICKER_FORMAT,
            )
          : 'N/A',
      })
    }

    if (
      current.diversity_question === DiversityQuestion.HUB_ZONE &&
      current.answer === true
    ) {
      categoryHubZoneAlaskanNativeBusiness.answers.push({
        title: 'HUB Zone Business certified by the Government:',
        value: current.answer ? 'Yes' : 'No',
      })
      categoryHubZoneAlaskanNativeBusiness.answers.push({
        title: 'HUB Zone Business Government Certificate Number:',
        value: current.certification?.cert_number ?? 'N/A',
      })
      categoryHubZoneAlaskanNativeBusiness.answers.push({
        title: 'HUB Zone Business Government Certificate Expiration Date:',
        value: current.certification?.expiration_date
          ? formatDate(
              current.certification?.expiration_date,
              DATE_DISPLAY_FORMAT,
              DATE_PICKER_FORMAT,
            )
          : 'N/A',
      })
    }

    if (
      current.diversity_question === DiversityQuestion.ALASKAN_NATIVE &&
      current.answer === true
    ) {
      categoryHubZoneAlaskanNativeBusiness.answers.push({
        title: 'Alaskan Native Owned Business certified by the Government:',
        value: current.answer ? 'Yes' : 'No',
      })
      categoryHubZoneAlaskanNativeBusiness.answers.push({
        title: 'Alaskan Native Owned  Business Government Certificate Number:',
        value: current.certification?.cert_number ?? 'N/A',
      })
      categoryHubZoneAlaskanNativeBusiness.answers.push({
        title:
          'Alaskan Native Owned Business Government Certificate Expiration Date:',
        value: current.certification?.expiration_date
          ? formatDate(
              current.certification?.expiration_date,
              DATE_DISPLAY_FORMAT,
              DATE_PICKER_FORMAT,
            )
          : 'N/A',
      })
    }
  }

  const result: FormattedAnswers[] = []

  if (categoryWoman.answers.length) {
    result.push(categoryWoman)
  }

  if (categoryBIPOC.answers.length) {
    result.push(categoryBIPOC)
  }

  if (categoryLGBTQIA.answers.length) {
    result.push(categoryLGBTQIA)
  }

  if (categoryVeteran.answers.length) {
    result.push(categoryVeteran)
  }

  if (categorySmallBusiness.answers.length) {
    result.push(categorySmallBusiness)
  }

  if (categoryDifferentlyAbled.answers.length) {
    result.push(categoryDifferentlyAbled)
  }

  if (categoryHubZoneAlaskanNativeBusiness.answers.length) {
    result.push(categoryHubZoneAlaskanNativeBusiness)
  }

  return result
}

export type Props = {
  answers: DiversityAnswer[]
  isEditable: boolean
}
export const DiversityContent: React.FC<Props> = ({ answers, isEditable }) => {
  let content: React.ReactNode

  if (answers.length === 0) {
    content = 'No information provided.'
  } else if (answers.every((a) => a.answer === false)) {
    content = 'Not a diverse-owned business'
  } else {
    const results = getGroupedAnswers(answers)
    content = results.map((result) => {
      return (
        <div key={result.label}>
          <Heading title={result.label} variant="h3" />
          <DataList data={result.answers} />
        </div>
      )
    })
  }

  return (
    <div>
      <TitleBar title="DIVERSITY INFO" />
      <DataList
        data={[
          {
            title: 'Diversity Details',
            element: (
              <EditButton
                dialogComponent={DialogEnum.PARTNER_DIVERSITY_SURVEY}
                aria-label="edit partner diversity survey"
                hide={!isEditable}
              />
            ),
          },
        ]}
      />
      <div data-testid="diversity-content">{content}</div>
    </div>
  )
}

export default DiversityContent
