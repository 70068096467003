import React from 'react'

import styled from '@emotion/styled'
import IconButton from '@mui/material/IconButton'
import OutlinedInput from '@mui/material/OutlinedInput'
import CancelIcon from '@mui/icons-material/Cancel'
import DateRangeIcon from '@mui/icons-material/DateRange'
import DoneIcon from '@mui/icons-material/Done'

import DateTextMask from 'components/common/DateRangePicker/DateTextMask'

import { white, filterChip } from 'config/themeConfig'

import { Modifier } from 'react-day-picker'

const StyledOutlinedInput = styled(OutlinedInput, {
  shouldForwardProp: (prop) => prop !== 'placement',
})<{ value: Modifier; placement: string | undefined }>((props) => ({
  backgroundColor: props.value ? filterChip.main : white.main,
  borderRadius: props.placement
    ? props.placement === 'start'
      ? '4px 0 0 4px'
      : '0 4px 4px 0'
    : '4px 4px 4px 4px',
  '& fieldset': props.placement
    ? props.placement === 'start'
      ? { borderRightWidth: 0 }
      : { borderLeftWidth: 0 }
    : undefined,
  '&:hover fieldset':
    props.placement === 'start'
      ? { borderRightWidth: 1 }
      : { borderLeftWidth: 1 },
  '&focused fieldset':
    props.placement === 'start'
      ? { borderRightWidth: 1 }
      : { borderLeftWidth: 1 },
  input: {
    padding: props.theme.spacing(1, 2, 1, 3),
    fontSize: 14,
  },
}))

export interface Props {
  placement?: 'start' | 'end'
  placeholder: string
  value: Modifier
  onChange: any
  inputRef: React.Ref<any>
  onDelete?: () => void
  isDisabled?: boolean
  onClick?: () => void
}
const DateTextField = React.forwardRef<
  HTMLAnchorElement & HTMLDivElement,
  Props
>(
  (
    { placement, value, placeholder, inputRef, onDelete, isDisabled, ...rest },
    _ref,
  ) => {
    const startAdornment = value ? (
      <DoneIcon color="primary" />
    ) : (
      <DateRangeIcon color="primary" />
    )

    const endAdornment = value ? (
      <IconButton
        onClick={onDelete}
        data-testid="remove-filter"
        aria-label={`Remove ${placeholder} Filter`}
        size="large"
      >
        <CancelIcon color="primary" />
      </IconButton>
    ) : undefined

    return (
      <StyledOutlinedInput
        notched={true}
        placement={placement}
        disabled={isDisabled}
        fullWidth
        placeholder={placeholder}
        value={value}
        inputRef={inputRef}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        inputComponent={DateTextMask as any}
        inputProps={{
          'data-testid': 'date-text-field',
          showMask: false,
          ...rest,
        }}
      />
    )
  },
)

export default DateTextField
