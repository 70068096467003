import React from 'react'

import Typography from '@mui/material/Typography'

export interface Props {
  buyUrl?: string
  displayNotLive?: boolean
  published?: boolean
}

export const LiveLink = ({
  buyUrl,
  displayNotLive = true,
  published = false,
}: Props) => {
  if (!published && !displayNotLive) return null

  let content: React.ReactNode = 'No'
  if (published) {
    content = (
      <a href={buyUrl} target="_blank" rel="noopener noreferrer">
        Yes
      </a>
    )
  }

  return <Typography component="span">{content}</Typography>
}

export default LiveLink
