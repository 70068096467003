import React from 'react'

import List from '@mui/material/List'
import Typography from '@mui/material/Typography'

import { Case, CaseEmails } from 'types/Case'
import { Email } from './Email'

export interface Props {
  sellerId: string
  selectedCase: Case | undefined
}

export const CommunicationHistory = ({ sellerId, selectedCase }: Props) => {
  let emails: CaseEmails[] = []

  if (selectedCase && selectedCase.emails) {
    emails = selectedCase.emails.sort((a, b) =>
      b.created.localeCompare(a.created),
    )
  }

  return (
    <React.Fragment>
      {emails && emails.length > 0 ? (
        <List data-testid="case-email-list">
          {emails.map((email, index) => (
            <Email
              sellerId={sellerId}
              email={email}
              key={index}
              index={index}
              expandMessage={index === 0}
            />
          ))}
        </List>
      ) : (
        <Typography>No emails</Typography>
      )}
    </React.Fragment>
  )
}

export default CommunicationHistory
