import { useState, useEffect, FormEvent, ChangeEvent } from 'react'
import styled from '@emotion/styled'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import CancelIcon from '@mui/icons-material/Cancel'

import Text from 'components/common/Text'

import { white, filterChip } from 'config/themeConfig'

const StyledLabel = styled(Text)((props) => ({
  marginBottom: props.theme.spacing(1),
}))

const StyledText = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'hasValue',
})<{ hasValue: string | boolean | undefined }>(
  (props) => ({
    backgroundColor: white.main,
    color: props.theme.palette.grey[700],
    '& .MuiOutlinedInput-input': {
      fontSize: 14,
      padding: props.theme.spacing(1, 2, 1, 1),
      '&:focus': {
        backgroundColor: 'initial',
      },
    },
  }),
  (props) => ({
    backgroundColor: props.hasValue ? filterChip.main : undefined,
    color: props.hasValue ? props.theme.palette.grey[900] : undefined,
  }),
)

export type Props = {
  fullWidth?: boolean
  label?: string
  id?: string
  value: string | undefined
  searchParam: string
  paramsToClearOnChange?: string[]
  onChange: (params: Dictionary<string | string[] | undefined>) => void
  disabled?: boolean
}
export const TextFilter = ({
  fullWidth,
  label,
  id,
  value,
  searchParam,
  paramsToClearOnChange,
  onChange,
  disabled,
}: Props) => {
  const [text, setText] = useState<string>('')

  useEffect(() => {
    if (!value) {
      setText('')
    } else {
      setText(value)
    }
  }, [value])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value)

    if (value && event.target.value === '') {
      onChange({
        [searchParam]: undefined,
      })
    }
  }

  const handleClear = () => {
    onChange({ [searchParam]: undefined })
    setText('')
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const val = typeof text === 'string' && text !== '' ? text : undefined
    let paramsToClear = {}
    if (Array.isArray(paramsToClearOnChange)) {
      paramsToClear = paramsToClearOnChange.reduce((prev, curr) => {
        prev[curr] = undefined

        return prev
      }, {} as any)
    }
    onChange({ ...paramsToClear, [searchParam]: val })
  }

  const endAdornment =
    value && text ? (
      <IconButton
        onClick={handleClear}
        data-testid="remove-filter"
        aria-label={`Remove ${label}`}
        size="large"
      >
        <CancelIcon color="primary" />
      </IconButton>
    ) : undefined

  return (
    <form onSubmit={handleSubmit}>
      {label && (
        <StyledLabel type="bold" component="label" htmlFor={id}>
          {label}
        </StyledLabel>
      )}
      <StyledText
        data-testid="text-input"
        fullWidth={fullWidth}
        disabled={disabled}
        variant="outlined"
        value={text}
        onChange={handleChange}
        hasValue={value && text !== '' && text !== undefined}
        InputProps={{
          id,
          endAdornment,
        }}
      />
    </form>
  )
}

export default TextFilter
