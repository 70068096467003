export interface CountryType {
  name: string
  code: string
}

const COUNTRIES: CountryType[] = [
  {
    name: 'United States',
    code: 'US',
  },
]

export default COUNTRIES
