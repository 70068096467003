import React from 'react'
import styled from '@emotion/styled'

import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

const StyledButtonContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
})

const StyledChildContainer = styled('div')({
  width: '100%',
})

interface Props {
  subtext: string
  addNewButtonText?: string
  addNewHandler?: () => void
  children: React.ReactNode
}

export const FullscreenDialogContent = ({
  subtext,
  addNewButtonText,
  addNewHandler,
  children,
}: Props) => {
  return (
    <>
      <Typography sx={{ marginBottom: 2 }}>{subtext}</Typography>
      {addNewHandler && (
        <StyledButtonContainer>
          <Button
            data-testid="add-new-button"
            color="primary"
            onClick={addNewHandler}
          >
            {addNewButtonText}
          </Button>
        </StyledButtonContainer>
      )}
      <StyledChildContainer>{children}</StyledChildContainer>
    </>
  )
}

export default FullscreenDialogContent
