import React from 'react'

import { withFormik, Form, FormikProps } from 'formik'
import * as yup from 'yup'

import { StyledRow } from './DomesticSurveyPageOne'

import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'

import Actions from '../Actions'
import Content from '../Content'
import InputField from 'components/common/form/InputField'
import RadioGroupField from 'components/common/form/RadioGroupField'
import Text from 'components/common/Text'

import {
  DiversityRadio,
  DiversitySelection,
  DiversitySurveyPages,
} from 'types/PartnerDiversity'

const validationSchema = yup.object().shape({
  VETERAN: yup.string().required(),
  VETERAN_CERT: yup.string().when('VETERAN', {
    is: (field: string | any[]) => field && field === DiversityRadio.YES,
    then: yup.string().required('Required'),
    otherwise: yup.string(),
  }),
  SERVICE_DISABLED_VET: yup.string().when('VETERAN', {
    is: (field: string | any[]) => field && field === DiversityRadio.YES,
    then: yup.string().required('Required'),
    otherwise: yup.string(),
  }),
  SERVICE_DISABLED_VET_CERT: yup.string().when('VETERAN', {
    is: (field: string | any[]) => field && field === DiversityRadio.YES,
    then: yup.string().required('Required'),
    otherwise: yup.string(),
  }),
  SMALL_BUSINESS: yup.string().required(),
  SMALL_DISADVANTAGED_BUSINESS: yup.string().required(),
  SMALL_DISADVANTAGED_BUSINESS_CERT: yup.string().test({
    name: 'isSmallDisadvantagedBusinessCert',
    test(this: yup.TestContext, value: any) {
      if (value && !this.parent.SMALL_DISADVANTAGED_BUSINESS_CERT_DATE) {
        return this.createError({
          path: 'SMALL_DISADVANTAGED_BUSINESS_CERT_DATE',
          message: 'Required',
        })
      }

      return true
    },
  }),
  SMALL_DISADVANTAGED_BUSINESS_CERT_DATE: yup
    .string()
    .when('SMALL_DISADVANTAGED_BUSINESS_CERT', {
      is: (field: string | any[]) => field && field.length > 0,
      then: yup.string().required('Required'),
      otherwise: yup.string(),
    }),
  DISADVANTAGED_BUSINESS_ENTERPRISE: yup.string().required(),
  DISADVANTAGED_BUSINESS_ENTERPRISE_CERT: yup.string().test({
    name: 'isDisadvantedBusinessEnterpriseCert',
    test(this: yup.TestContext, value: any) {
      if (value && !this.parent.DISADVANTAGED_BUSINESS_ENTERPRISE_CERT_DATE) {
        return this.createError({
          path: 'DISADVANTAGED_BUSINESS_ENTERPRISE_CERT_DATE',
          message: 'Required',
        })
      }

      return true
    },
  }),
  DISADVANTAGED_BUSINESS_ENTERPRISE_CERT_DATE: yup
    .string()
    .when('DISADVANTAGED_BUSINESS_ENTERPRISE_CERT', {
      is: (field: string | any[]) => field && field.length > 0,
      then: yup.string().required('Required'),
      otherwise: yup.string(),
    }),
})

type FormValues = {
  VETERAN: DiversitySelection
  VETERAN_CERT: DiversitySelection
  SERVICE_DISABLED_VET: DiversitySelection
  SERVICE_DISABLED_VET_CERT: DiversitySelection
  SMALL_BUSINESS: DiversitySelection
  SMALL_DISADVANTAGED_BUSINESS: DiversitySelection
  SMALL_DISADVANTAGED_BUSINESS_CERT: String
  SMALL_DISADVANTAGED_BUSINESS_CERT_DATE: String
  DISADVANTAGED_BUSINESS_ENTERPRISE: DiversitySelection
  DISADVANTAGED_BUSINESS_ENTERPRISE_CERT: String
  DISADVANTAGED_BUSINESS_ENTERPRISE_CERT_DATE: String
}

type ComponentProps = {
  page: number
  isEdit: boolean
  initValues: FormValues
  onPrevious?: (values: Partial<DiversitySurveyPages>) => void
  onSubmit: (values: any) => void
}

type Props = ComponentProps & FormikProps<FormValues>

export const DomesticSurveyPageTwoForm = ({
  page,
  onPrevious,
  isValid,
  values,
  handleSubmit,
  isSubmitting,
  setFieldValue,
}: Props) => {
  React.useEffect(() => {
    if (values.VETERAN === DiversityRadio.NO) {
      setFieldValue('VETERAN_CERT', '')
      setFieldValue('SERVICE_DISABLED_VET', '')
      setFieldValue('SERVICE_DISABLED_VET_CERT', '')
    }
  }, [values.VETERAN, setFieldValue])

  React.useEffect(() => {
    if (values.SMALL_DISADVANTAGED_BUSINESS === DiversityRadio.NO) {
      setFieldValue('SMALL_DISADVANTAGED_BUSINESS_CERT', '')
      setFieldValue('SMALL_DISADVANTAGED_BUSINESS_CERT_DATE', '')
    }
  }, [values.SMALL_DISADVANTAGED_BUSINESS, setFieldValue])

  React.useEffect(() => {
    if (values.DISADVANTAGED_BUSINESS_ENTERPRISE === DiversityRadio.NO) {
      setFieldValue('DISADVANTAGED_BUSINESS_ENTERPRISE_CERT', '')
      setFieldValue('DISADVANTAGED_BUSINESS_ENTERPRISE_CERT_DATE', '')
    }
  }, [values.DISADVANTAGED_BUSINESS_ENTERPRISE, setFieldValue])

  return (
    <>
      <Content>
        <Form>
          <StyledRow>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Text type="bodySm" component="label">
                  Are you a Veteran Owned Business?
                </Text>
              </Grid>
              {values.VETERAN === DiversityRadio.YES && (
                <Grid item xs={6}>
                  <Text type="bodySm" component="label">
                    Do you have a DD214 (Certificate of Release or Discharge
                    from Active Duty) from the Department of Defense?
                  </Text>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <RadioGroupField
                  required
                  row={true}
                  name="VETERAN"
                  options={[
                    { label: 'Yes', value: DiversityRadio.YES },
                    { label: 'No', value: DiversityRadio.NO },
                  ]}
                  touchedFieldName={[
                    'VETERAN_CERT',
                    'SERVICE_DISABLED_VET',
                    'SERVICE_DISABLED_VET_CERT',
                  ]}
                />
              </Grid>
              {values.VETERAN === DiversityRadio.YES && (
                <Grid item xs={4}>
                  <RadioGroupField
                    required
                    row={true}
                    name="VETERAN_CERT"
                    options={[
                      { label: 'Yes', value: DiversityRadio.YES },
                      { label: 'No', value: DiversityRadio.NO },
                    ]}
                  />
                </Grid>
              )}
            </Grid>
            {values.VETERAN === DiversityRadio.YES && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Text type="bodySm" component="label">
                      Are you a Service-Disabled Veteran Owned Business?
                    </Text>
                  </Grid>
                  <Grid item xs={6}>
                    <Text type="bodySm" component="label">
                      Do you have a Letter from the Department of Veterans
                      Affairs Stating % of Disability?
                    </Text>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <RadioGroupField
                      required
                      row={true}
                      name="SERVICE_DISABLED_VET"
                      options={[
                        { label: 'Yes', value: DiversityRadio.YES },
                        { label: 'No', value: DiversityRadio.NO },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <RadioGroupField
                      required
                      row={true}
                      name="SERVICE_DISABLED_VET_CERT"
                      options={[
                        { label: 'Yes', value: DiversityRadio.YES },
                        { label: 'No', value: DiversityRadio.NO },
                      ]}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </StyledRow>
          <Divider />
          <StyledRow>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Text type="bodySm" component="label">
                  Are you a Small and/or Disadvantaged Business Certified by
                  SBA(8)A?
                </Text>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <RadioGroupField
                  required
                  row={true}
                  name="SMALL_BUSINESS"
                  options={[
                    { label: 'Yes', value: DiversityRadio.YES },
                    { label: 'No', value: DiversityRadio.NO },
                  ]}
                />
              </Grid>
            </Grid>
          </StyledRow>
          <StyledRow>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Text type="bodySm" component="label">
                  Are you a Small Disadvantaged Business (SDB) Certified by the
                  Government?
                </Text>
              </Grid>
              {values.SMALL_DISADVANTAGED_BUSINESS === DiversityRadio.YES && (
                <>
                  <Grid item xs={4}>
                    <Text
                      type="bodySm"
                      component="label"
                      htmlFor="small-disadvantaged-business-cert"
                    >
                      SDB Government Certificate Number
                    </Text>
                  </Grid>
                  <Grid item xs={4}>
                    <Text
                      type="bodySm"
                      component="label"
                      htmlFor="small-disadvantaged-business-cert-date"
                    >
                      SDB Government Certificate Expiration Date
                    </Text>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <RadioGroupField
                  required
                  row={true}
                  name="SMALL_DISADVANTAGED_BUSINESS"
                  options={[
                    { label: 'Yes', value: DiversityRadio.YES },
                    { label: 'No', value: DiversityRadio.NO },
                  ]}
                />
              </Grid>
              {values.SMALL_DISADVANTAGED_BUSINESS === DiversityRadio.YES && (
                <>
                  <Grid item xs={4}>
                    <InputField
                      id="small-disadvantaged-business-cert"
                      name="SMALL_DISADVANTAGED_BUSINESS_CERT"
                      touchedFieldName="SMALL_DISADVANTAGED_BUSINESS_CERT_DATE"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField
                      type="date"
                      id="small-disadvantaged-business-cert-date"
                      name="SMALL_DISADVANTAGED_BUSINESS_CERT_DATE"
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </StyledRow>
          <StyledRow>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Text type="bodySm" component="label">
                  Are you a Disadvantaged Business Enterprise (DBE) Certified by
                  the Government?
                </Text>
              </Grid>
              {values.DISADVANTAGED_BUSINESS_ENTERPRISE ===
                DiversityRadio.YES && (
                <>
                  <Grid item xs={4}>
                    <Text
                      type="bodySm"
                      component="label"
                      htmlFor="disadvantaged-business-enterprise-cert"
                    >
                      DBE Government Certificate Number
                    </Text>
                  </Grid>
                  <Grid item xs={4}>
                    <Text
                      type="bodySm"
                      component="label"
                      htmlFor="disadvantaged-business-enterprise-cert"
                    >
                      DBE Government Certificate Expiration Date
                    </Text>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <RadioGroupField
                  required
                  row={true}
                  name="DISADVANTAGED_BUSINESS_ENTERPRISE"
                  options={[
                    { label: 'Yes', value: DiversityRadio.YES },
                    { label: 'No', value: DiversityRadio.NO },
                  ]}
                />
              </Grid>
              {values.DISADVANTAGED_BUSINESS_ENTERPRISE ===
                DiversityRadio.YES && (
                <>
                  <Grid item xs={4}>
                    <InputField
                      id="disadvantaged-business-enterprise-cert"
                      name="DISADVANTAGED_BUSINESS_ENTERPRISE_CERT"
                      touchedFieldName="DISADVANTAGED_BUSINESS_ENTERPRISE_CERT_DATE"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField
                      type="date"
                      id="disadvantaged-business-enterprise-cert-date"
                      name="DISADVANTAGED_BUSINESS_ENTERPRISE_CERT_DATE"
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </StyledRow>
        </Form>
      </Content>
      <Actions
        page={page}
        totalPages={3}
        values={values}
        disabled={!isValid || isSubmitting}
        submitText="Next"
        onSubmit={handleSubmit}
        onPrevious={onPrevious}
      />
    </>
  )
}

export const DomesticSurveyPageTwo = withFormik<ComponentProps, FormValues>({
  mapPropsToValues: (props) => ({
    VETERAN: props.initValues.VETERAN ?? '',
    VETERAN_CERT: props.initValues.VETERAN_CERT ?? '',
    SERVICE_DISABLED_VET: props.initValues.SERVICE_DISABLED_VET ?? '',
    SERVICE_DISABLED_VET_CERT: props.initValues.SERVICE_DISABLED_VET_CERT ?? '',
    SMALL_BUSINESS: props.initValues.SMALL_BUSINESS ?? '',
    SMALL_DISADVANTAGED_BUSINESS:
      props.initValues.SMALL_DISADVANTAGED_BUSINESS ?? '',
    SMALL_DISADVANTAGED_BUSINESS_CERT:
      props.initValues.SMALL_DISADVANTAGED_BUSINESS_CERT ?? '',
    SMALL_DISADVANTAGED_BUSINESS_CERT_DATE:
      props.initValues.SMALL_DISADVANTAGED_BUSINESS_CERT_DATE ?? '',
    DISADVANTAGED_BUSINESS_ENTERPRISE:
      props.initValues.DISADVANTAGED_BUSINESS_ENTERPRISE ?? '',
    DISADVANTAGED_BUSINESS_ENTERPRISE_CERT:
      props.initValues.DISADVANTAGED_BUSINESS_ENTERPRISE_CERT ?? '',
    DISADVANTAGED_BUSINESS_ENTERPRISE_CERT_DATE:
      props.initValues.DISADVANTAGED_BUSINESS_ENTERPRISE_CERT_DATE ?? '',
  }),
  handleSubmit: async (values, { props }) => {
    props.onSubmit(values)
  },
  enableReinitialize: true,
  validateOnMount: true,
  validationSchema,
})(DomesticSurveyPageTwoForm)

export default DomesticSurveyPageTwo
