import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Input, Tooltip } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { InfoIcon } from '@enterprise-ui/icons'

import {
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material'

import DialogContainer from 'components/common/Dialog/DialogContainer'

import { GenerateReportRequest } from 'services/reports'

import { closeDialog } from 'store/dialog/actionCreator'
import { currentSellerId } from 'store/selectors'

import { ReportType } from 'types/Report'
import { Severity } from 'types/ErrorCode'
import { StyledCheckbox } from '../styles'
import { flag, FlagName } from 'flag'

export interface Props {
  isOpen: boolean
  reportType: ReportType
  onRequestSubmit: (reportParams: GenerateReportRequest) => void
}

const PUBLISHED = 'PUBLISHED'
const UNPUBLISHED = 'UNPUBLISHED'
const UNLISTED = 'UNLISTED'

export const ItemErrorsReportDialog = ({
  isOpen,
  reportType,
  onRequestSubmit,
}: Props) => {
  const dispatch = useDispatch()
  const sellerId = useSelector(currentSellerId)
  const isItemErrorsEnabled = flag(FlagName.ITEM_ERRORS)

  const [pending, setPending] = useState(false)
  const [severity, setSeverity] = useState(Severity.CRITICAL)
  const [itemStatus, setItemStatus] = useState<string[]>([
    PUBLISHED,
    UNPUBLISHED,
  ])

  const title = pending ? 'Generating... Please wait' : 'Item Error Report'

  const handleErrorSeverityChange = (event: any) => {
    const value = event.target.value
    setSeverity(value)
  }

  const handleItemStatusChange = (status: string) => {
    if (itemStatus.includes(status)) {
      setItemStatus(
        itemStatus.filter(
          (individualItemStatus: string) => individualItemStatus !== status,
        ),
      )
    } else {
      setItemStatus([...itemStatus, status])
    }
  }

  const handleSubmit = async () => {
    setPending(true)
    try {
      onRequestSubmit({
        sellerId,
        type: reportType,
        parameters: {
          ...(isItemErrorsEnabled && { item_status: itemStatus }),
          //@ts-ignore
          error_severity: severity === 'All' ? undefined : severity,
        },
        reportName: 'ITEM_ERRORS_V2',
        format: 'EXCEL',
      })
    } catch (e) {
      console.error(`Error generating report for partner ${sellerId}: ${e}`)
    } finally {
      setPending(false)
      dispatch(closeDialog())
    }
  }

  return (
    <DialogContainer
      title={title}
      isOpen={isOpen}
      onSubmit={handleSubmit}
      submitButtonText="Generate"
      isPending={pending}
      disableScroll
      isSubmitDisabled={isItemErrorsEnabled && !itemStatus.length}
    >
      <Grid container>
        <Grid item xs={12}>
          {isItemErrorsEnabled && (
            <>
              <div>Select Item status *</div>
              <StyledCheckbox className="hc-mb-expanded">
                <div className="display-flex hc-mt-min">
                  <Input.Checkbox
                    checked={itemStatus.includes(PUBLISHED)}
                    label="Published"
                    onChange={() => handleItemStatusChange(PUBLISHED)}
                  />
                  <Tooltip
                    className="hc-ml-md hc-mt-dense"
                    content="Published Items"
                    location="right"
                  >
                    <EnterpriseIcon icon={InfoIcon} />
                  </Tooltip>
                </div>
                <div className="display-flex">
                  <Input.Checkbox
                    checked={itemStatus.includes(UNPUBLISHED)}
                    label="Unpublished"
                    onChange={() => handleItemStatusChange(UNPUBLISHED)}
                  />
                  <Tooltip
                    className="hc-ml-md hc-mt-dense"
                    content="Unpublished (excluding unlisted items)"
                    location="right"
                  >
                    <EnterpriseIcon icon={InfoIcon} />
                  </Tooltip>
                </div>
                <div className="display-flex">
                  <Input.Checkbox
                    checked={itemStatus.includes(UNLISTED)}
                    label="Unlisted"
                    onChange={() => handleItemStatusChange(UNLISTED)}
                  />
                  <Tooltip
                    className="hc-ml-md hc-mt-dense"
                    content=" Items with current status as unlisted"
                    location="right"
                  >
                    <EnterpriseIcon icon={InfoIcon} />
                  </Tooltip>
                </div>
              </StyledCheckbox>
            </>
          )}

          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Select Item Criticality:
            </FormLabel>
            <RadioGroup
              aria-labelledby="radio-buttons-group-label"
              name="radio-buttons-group"
              onChange={handleErrorSeverityChange}
              value={severity}
            >
              <FormControlLabel
                value={Severity.CRITICAL}
                control={<Radio />}
                label="Critical Errors Only"
              />
              <FormControlLabel
                value={Severity.NON_CRITICAL}
                control={<Radio />}
                label="Non-Critical Errors Only"
              />
              <FormControlLabel
                value="All"
                control={<Radio />}
                label="All Item Errors"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export default ItemErrorsReportDialog
