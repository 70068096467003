export const LABEL_FIRST_NAME = 'First Name'
export const LABEL_LAST_NAME = 'Last Name'
export const LABEL_EMAIL = 'Email'
export const LABEL_PHONE_NUMBER_OFFICE = 'Office Number*'
export const LABEL_PHONE_NUMBER_MOBILE =
  'Mobile Number for Two Factor Authentication*'
export const LABEL_OFFICE_COUNTRY_CODE = 'Office Country Code'
export const LABEL_MOBILE_COUNTRY_CODE = 'Mobile Country Code'
export const LABEL_BUSINESS_STRUCTURE = 'Business Structure'
export const LABEL_SOURCE = 'Source'
export const LABEL_COMPANY_TYPE = 'Company Type'

export const LABEL_ERROR_PHONE_NUMBER_OFFICE =
  'Office phone number is not a valid phone number'
export const LABEL_ERROR_PHONE_NUMBER_MOBILE =
  'Mobile phone number is not a valid phone number'

export const LABEL_DISTRIBUTION_TIME_ZONE =
  'Time Zone (applies to shipping hours & site cutoff times)'
