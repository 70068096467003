import axios from 'axios'

import { toQueryParams } from 'utilities/url'
import apiConfig from 'config/apiConfig'

interface ItemTypeParams {
  status: string
  id: string
  depth: number
  perPage: number
  target: 'EXTERNAL' | 'INTERNAL'
}

export interface ItemTypeTaxonomies {
  id: string
  label: string
  value: string
}

export async function getItemTypeTaxonomies(
  params: ItemTypeParams,
): Promise<ItemTypeTaxonomies[]> {
  const { status, id, depth, target, perPage } = params

  const queryParams = toQueryParams({
    status,
    depth,
    target,
    parent_id: id,
    per_page: perPage,
  })

  const { data } = await axios.get(
    `${apiConfig.nexus}/taxonomies?${queryParams}`,
  )

  const itemTypeTaxonomiesData: ItemTypeTaxonomies[] = data?.map(
    ({ id, name }: any) => ({
      id,
      label: name,
      value: id,
    }),
  )

  return itemTypeTaxonomiesData
}
