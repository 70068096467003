import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import startCase from 'lodash/fp/startCase'
import styled from '@emotion/styled'
import {
  error,
  success,
  target,
  warning,
  grey,
  white,
} from 'config/themeConfig'

import {
  Chip,
  IconButton,
  MenuItem,
  Select,
  Typography,
  Button,
} from '@mui/material'

import { KeyboardArrowDown } from '@mui/icons-material'
import HelpIcon from '@mui/icons-material/HelpOutline'
import MenuIcon from '@mui/icons-material/Menu'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import LinkButton from '../LinkButton'
import Link from 'components/common/Link'
import TargetPlus from 'components/common/Icons/TargetPlus'
import { OmniTypeahead } from 'components/common/Typeahead'

import { getDoceboUrl } from 'services/docebo'
import { trackCustomEvent } from 'services/fireflyInsights'
import { RoutePath } from 'services/NavigationHelper'
import { approvedToListStatuses } from 'services/seller'
import { isOneOfUserRoles } from 'services/authorization'

import {
  currentSeller,
  getSellers,
  isRoleExternalUserSelector,
} from 'store/selectors'
import { setCurrentSeller } from 'store/seller/actionCreators'

import { FireflyEvent } from 'types/FireflyInsights'
import { SmsSeller, SellerStatus } from 'types/Seller'
import { Breadcrumb } from 'types/Layout'

import ProfileMenu from './ProfileMenu'
import { getMemberOf } from 'store/selectors'

import { Layout, Grid } from '@enterprise-ui/canvas-ui-react'
import {
  USER_ROLE_ADMIN,
  USER_ROLE_ITEM_APPROVER,
  USER_ROLE_ITEM_MANAGER,
  USER_ROLE_OPS,
} from 'services/roles'

type StyledProps = {
  showSideNav?: boolean
  selectedSeller?: SmsSeller
  isDefunct?: boolean
}

const StyledAppBar = styled(Layout.Header, {
  shouldForwardProp: (prop) =>
    prop !== 'showSideNav' && prop !== 'selectedSeller' && prop !== 'isDefunct',
})<StyledProps>(
  ({ theme }) => ({
    top: 0,
    left: 'auto',
    right: 'auto',
    boxShadow: 'none',
    marginLeft: 0,
    [`.hasSecondaryNav &`]: {
      paddingBottom: theme.spacing(6),
    },
    minHeight: '54px',
    gridColumn: '2/span 4 !important',
  }),
  (props) => ({
    backgroundColor: props.selectedSeller ? target.main : grey[300],
  }),
)

const StyledHelpButton = styled(LinkButton, {
  shouldForwardProp: (prop) =>
    prop !== 'showSideNav' && prop !== 'selectedSeller' && prop !== 'isDefunct',
})<StyledProps>(
  ({ theme }) => ({
    width: theme.spacing(20),
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      color: grey[900],
      '& svg': {
        fill: grey[900],
      },
    },
  }),
  (props) => ({
    color: props.selectedSeller ? white.main : grey[900],
    '&:visited': {
      color: props.selectedSeller ? white.main : grey[900],
    },
  }),
)

const StyledMenuButton = styled(IconButton, {
  shouldForwardProp: (prop) =>
    prop !== 'showSideNav' && prop !== 'selectedSeller' && prop !== 'isDefunct',
})<StyledProps>({ color: grey[100] }, (props) => ({
  '& svg': {
    color: props.selectedSeller
      ? `${grey[100]} !important`
      : `${grey[900]} !important`,
  },
  '& :hover>svg': {
    fill: props.selectedSeller ? grey[900] : undefined,
  },
}))

const StyledLogo = styled(TargetPlus)(({ theme }) => ({
  width: 48,
  height: 25,
  margin: theme.spacing(0, 2, 0, 0),
}))

const StyledSellerSelect = styled(Select)({
  '& svg': {
    color: grey[700],
  },
})

const StyledSubheader = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'showSideNav' && prop !== 'selectedSeller' && prop !== 'isDefunct',
})<StyledProps>(
  ({ theme }) => ({
    paddingLeft: theme.spacing(5),
    color: grey[900],
  }),
  (props) => ({
    backgroundColor: props.isDefunct ? warning.light : grey[200],
  }),
)

const StyledTitle = styled(Typography)({
  // remove focus outline
  outline: 'none',
  '& > a': {
    color: 'inherit',
  },
})

const StyledButton = styled(Button)(({ theme }) => ({
  color: `${theme.palette.common.white}`,
  '&:visited': {
    color: `${theme.palette.common.white}`,
  },
  '&:hover': {
    color: theme.palette.primary.main,
  },
})) as typeof Button

interface Props {
  title: string | Breadcrumb[]
  menuAction?: () => void
  showSideNav: boolean
}

export const Header = ({ title, menuAction, showSideNav }: Props) => {
  const navigate = useNavigate()

  const reduxDispatch = useDispatch()

  const selectedSeller = useSelector(currentSeller)
  const sellers = useSelector(getSellers)
  const isExternalUser = useSelector(isRoleExternalUserSelector)
  const memberOf = useSelector(getMemberOf)

  const titleRef = useRef<HTMLElement>(null)

  useEffect(() => {
    titleRef?.current?.focus()
  }, [title, titleRef])

  const [isHovered, setIsHovered] = useState(false)

  const handleSellerChange = (event: any): void => {
    navigate(`/${event.target.value}${RoutePath.DASHBOARD}`)
    reduxDispatch(setCurrentSeller(event.target.value))
  }

  const statusChipColor =
    selectedSeller?.status === SellerStatus.INITIATED
      ? grey[700]
      : selectedSeller?.status === SellerStatus.PARTNER_SETUP
      ? grey[900]
      : selectedSeller?.status &&
        approvedToListStatuses.includes(selectedSeller?.status)
      ? success.main
      : selectedSeller?.status === SellerStatus.SUSPENDED ||
        selectedSeller?.status === SellerStatus.DEFUNCT
      ? error.main
      : ''

  const renderBreadcrumb = (title: Breadcrumb[]) => {
    return (
      <>
        {title.map((breadcrumb, index) => {
          const text = `${index > 0 ? ' > ' : ''}${breadcrumb.text}`
          return breadcrumb.route ? (
            <Link to={breadcrumb.route} key={breadcrumb.text}>
              {text}
            </Link>
          ) : (
            text
          )
        })}
      </>
    )
  }

  if (isExternalUser && sellers[0]?.id && !selectedSeller) {
    reduxDispatch(setCurrentSeller(sellers[0].id))
  }

  const isDefunct = selectedSeller?.status === SellerStatus.DEFUNCT
  const showSelect = isExternalUser && sellers.length > 1
  const currentSellerId = selectedSeller ? selectedSeller.id : undefined
  const logoFill =
    selectedSeller && isHovered
      ? grey[900]
      : selectedSeller && !isHovered
      ? grey[100]
      : undefined

  const doceboEnabled =
    selectedSeller?.status !== SellerStatus.INITIATED &&
    selectedSeller?.status !== SellerStatus.PARTNER_SETUP &&
    selectedSeller?.status !== SellerStatus.SUSPENDED &&
    selectedSeller?.status !== SellerStatus.DEFUNCT

  const isAllowedInternalUser = isOneOfUserRoles(memberOf, [
    USER_ROLE_ITEM_APPROVER,
    USER_ROLE_ITEM_MANAGER,
    USER_ROLE_OPS,
    USER_ROLE_ADMIN,
  ])

  const trackHelpClick = () => {
    trackCustomEvent(FireflyEvent.HELP_BUTTON, 'event', 'click')
  }

  const trackDoceboClick = () => {
    trackCustomEvent(FireflyEvent.DOCEBO_BUTTON, 'event', 'click')
  }

  return (
    <StyledAppBar
      fixed
      includeRail
      selectedSeller={selectedSeller}
      className="hc-pr-none hc-pl-none hc-pb-none hc-pb-none"
    >
      <div className="header">
        <div className="display-flex hc-ml-lg">
          {!showSideNav && (
            <StyledMenuButton
              className="display-flex hc-mr-sm"
              onClick={menuAction}
              aria-label="Open Navigation Menu"
              showSideNav={showSideNav}
              selectedSeller={selectedSeller}
            >
              <MenuIcon fontSize="large" />
            </StyledMenuButton>
          )}
          <LinkButton
            aria-label="Marketplace Portal Home"
            to={RoutePath.HOME}
            onMouseEnter={() => {
              setIsHovered(true)
            }}
            onMouseLeave={() => {
              setIsHovered(false)
            }}
            color="secondary"
          >
            <StyledLogo fill={logoFill} />
          </LinkButton>
        </div>
        <div style={{ flexGrow: 1 }} className="hc-mr-lg hc-ml-lg">
          {!isExternalUser && (
            <OmniTypeahead
              placeholder="Search Partners, Orders, Returns, Items, or Cases"
              partners
              items
              orders
              returns
              cases
              shipNode
              noResultsMessage="Enter Partner Name, Order ID, Return Order Number, Ship Advice, Tracking Number, License Plate, SKU, Barcode, TCIN, Case Number or Ship Node ID"
            />
          )}
          {!!selectedSeller && isExternalUser && (
            <OmniTypeahead
              placeholder="Search for Orders, Returns, or Items"
              items
              orders
              returns
              sellerId={isExternalUser ? selectedSeller.id : undefined}
              noResultsMessage="Enter Order ID, Return Order Number, Ship Advice, Tracking Number, License Plate, SKU, Barcode or TCIN"
            />
          )}
        </div>
        <div className="display-flex">
          <StyledHelpButton
            className="hc-pr-sm"
            to={RoutePath.HELP}
            onClick={trackHelpClick}
            color="secondary"
            selectedSeller={selectedSeller}
          >
            <HelpIcon fontSize="large" className="hc-pr-dense" />
            Help Center
          </StyledHelpButton>
          <ProfileMenu
            sellerSelected={selectedSeller ? true : false}
            isExternal={isExternalUser}
          />
        </div>
      </div>
      <Layout.SectionHeader className="hc-bg-grey04 hc-pr-none hc-pl-none">
        <StyledSubheader isDefunct={isDefunct}>
          <Grid.Container>
            <Grid.Item style={{ flexGrow: 1 }} className="hc-pb-md">
              {title && (
                <StyledTitle
                  tabIndex={-1}
                  ref={titleRef}
                  data-testid="header-title"
                  variant="overline"
                  color={selectedSeller ? 'initial' : 'textPrimary'}
                  style={{ lineHeight: 1 }}
                >
                  {typeof title === 'string' ? title : renderBreadcrumb(title)}
                </StyledTitle>
              )}
            </Grid.Item>
            <Grid.Item
              className="hc-pb-dense hc-pr-none"
              style={{ paddingTop: '6px' }}
            >
              {(isAllowedInternalUser || isExternalUser) && doceboEnabled && (
                <StyledButton
                  color="primary"
                  variant="contained"
                  data-testid="docebo-btn"
                  href={getDoceboUrl()}
                  onClick={trackDoceboClick}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Docebo Learning"
                  className="hc-mr-md"
                >
                  <OpenInNewIcon fontSize="small" sx={{ mr: 1 }} />
                  Learning On Docebo
                </StyledButton>
              )}
            </Grid.Item>
            <Grid.Item className="hc-pb-dense hc-pt-dense hc-pr-none">
              {selectedSeller && (
                <Chip
                  data-testid="status-chip"
                  label={startCase(selectedSeller.status)}
                  variant="outlined"
                  sx={{
                    color: statusChipColor,
                    border: `1px solid ${statusChipColor}`,
                  }}
                />
              )}
            </Grid.Item>
            <Grid.Item>
              {!showSelect && selectedSeller && (
                <Typography
                  variant="overline"
                  component="p"
                  data-testid="seller-name"
                  color="initial"
                  lineHeight={1.5}
                >
                  {selectedSeller.display_name ||
                    selectedSeller.legal_business_name}
                </Typography>
              )}
            </Grid.Item>
            <Grid.Item className="hc-mr-xl">
              {showSelect && currentSellerId && (
                <StyledSellerSelect
                  data-testid="seller-select"
                  autoWidth
                  IconComponent={KeyboardArrowDown}
                  value={currentSellerId}
                  onChange={handleSellerChange}
                  variant="standard"
                  disableUnderline
                >
                  {sellers!.map((seller: SmsSeller) => {
                    if (seller.status === SellerStatus.DEFUNCT) {
                      return null
                    }
                    return (
                      <MenuItem
                        data-testid={`select-${seller.id}`}
                        key={seller.id}
                        value={seller.id}
                      >
                        {seller.display_name || seller.legal_business_name}
                      </MenuItem>
                    )
                  })}
                </StyledSellerSelect>
              )}
            </Grid.Item>
          </Grid.Container>
        </StyledSubheader>
      </Layout.SectionHeader>
    </StyledAppBar>
  )
}

export default Header
