import { Fragment, useState, memo } from 'react'
import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'
import { Button } from '@enterprise-ui/canvas-ui-react'

import LegalizationTable from './LegalizationTable'
import { AllowedItemTypeProps } from './ItemSettingsTableFieldTypes'

import TitleBar from 'components/common/TitleBar'
import {
  currentSeller as getCurrentSeller,
  isDefunctSellerStatus,
} from 'store/selectors'
import { useStateValue } from 'stateManager/StateProvider'

import { ItemTabsEnum } from './EditDrawer/ItemTabs'
import DrawerContainer from './EditDrawer/DrawerContainer'
import { Legalization } from 'types/Legalization'
import {
  deleteSellerLegalization,
  getSellerLegalizationsWithNames,
} from 'services/legalizations'
import { useSelector } from 'react-redux'
import { SmsSeller } from 'types/Seller'
import { BRAND, ITEM_SUBTYPE, ITEM_TYPE } from 'types/Attribute'

type ValidTypes = typeof ITEM_TYPE | typeof ITEM_SUBTYPE | typeof BRAND

const StyledBold = styled(Typography)({
  fontWeight: 'bold',
})
const StyledTitle = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

interface Props extends AllowedItemTypeProps {
  isPending: boolean
  legalizations: Legalization[]
  setLegalizations?: (legalizations: Legalization[]) => void
  hasEdit: boolean
  onCloseDialog?: () => void
}

export const LegalizationTableContainer = ({
  isPending,
  legalizations,
  hasEdit,
  orderBy,
  fieldList,
  perPage,
  title,
  subtitle,
  type,
  onCloseDialog,
  setLegalizations,
}: Props) => {
  const currentSeller = useSelector(getCurrentSeller) as SmsSeller
  const isDefunct = useSelector(isDefunctSellerStatus)

  const [legalization, setLegalization] = useState<Partial<Legalization>>({})

  const [isEditDrawerVisible, setIsEditDrawerVisible] = useState(false)
  const { updateState } = useStateValue()

  const editFunction = (element: Legalization) => {
    updateState({
      type,
      isEdit: true,
      tabName: ItemTabsEnum.INDIVIDUAL,
      renderIndividualConfig: true,
      editLegalizationData: element,
    })
    setIsEditDrawerVisible(!isEditDrawerVisible)
  }

  const deleteFunction = (legalizationToDelete: Legalization) => {
    if (
      window.confirm(
        `Are you sure you want to delete ${legalizationToDelete.primary_attribute.name} from the list?`,
      )
    ) {
      deleteSellerLegalization(currentSeller.id, legalizationToDelete.id!).then(
        () => {
          getSellerLegalizationsWithNames(currentSeller.id).then((data) => {
            setLegalizations?.(data)
          })
        },
      )

      if (legalizationToDelete.id === legalization.id) {
        setLegalization({})
      }
    }
  }

  const toggleDrawer = () => {
    updateState({
      type,
      isEdit: false,
      tabName: ItemTabsEnum.INDIVIDUAL,
      renderIndividualConfig: !isEditDrawerVisible,
    })
    setIsEditDrawerVisible(!isEditDrawerVisible)
  }

  const getButtonText = (type: ValidTypes) => {
    const mappingTypes = {
      [BRAND]: 'Brands',
      [ITEM_SUBTYPE]: 'Subtypes',
      [ITEM_TYPE]: 'Item Types',
    }

    return mappingTypes[type]
  }

  return (
    <Fragment>
      {hasEdit && (
        <TitleBar
          title={title}
          actionButtons={[
            !isDefunct && (
              <Button
                className="hc-txt-uppercase"
                data-testid={`edit-${title}-button`}
                type="primary"
                onClick={toggleDrawer}
              >
                Add {getButtonText(type)}
              </Button>
            ),
          ]}
        />
      )}
      {!hasEdit && (
        <StyledTitle data-testid="noEditTitle">
          <StyledBold display="inline" variant="h4">
            {title}
          </StyledBold>
        </StyledTitle>
      )}
      <Typography>{subtitle}</Typography>
      <LegalizationTable
        isPending={isPending}
        legalizations={legalizations}
        perPage={perPage}
        orderBy={orderBy}
        fieldList={fieldList}
        editFunction={hasEdit ? editFunction : undefined}
        deleteFunction={hasEdit ? deleteFunction : undefined}
      />
      <DrawerContainer
        isEditDrawerVisible={isEditDrawerVisible}
        onRequestClose={toggleDrawer}
        setLegalizations={onCloseDialog} // onCloseDialog props making the call to Legalizations API
      />
    </Fragment>
  )
}

LegalizationTableContainer.defaultProps = {
  hasEdit: false,
}

export default memo(LegalizationTableContainer)
