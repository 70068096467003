import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useQuery } from '@tanstack/react-query'

import styled from '@emotion/styled'
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import HeaderTitle from 'components/common/HeaderTitle'
import useTable from 'components/common/EnhancedTable/useTable'
import { DialogEnum } from 'components/common/Dialog'
import FilterBar from 'components/common/FilterBar'
import TypeaheadFilter from 'components/common/FilterBar/TypeaheadFilter'

import { TabPanel } from './TabPanel'
import CarrierMarkupCard from './CarrierMarkupCard'
import PartnerDiscountTable from './PartnerDiscountTable'

import { CONTENT_PADDING } from 'constants/layout'
import {
  getEffectiveMarkupByCarrier,
  getMarkupsByCarrier,
  getPartnerDiscounts,
} from 'services/sellerShippingManagement'
import { Direction } from 'services/pageableHelper'
import { DATE_DISPLAY_FORMAT, formatISODate } from 'services/dateService'

import { Discount, Markup } from 'types/Markup'
import { Carrier } from 'types/Carrier'

import { closeDialog, openDialog } from 'store/dialog/actionCreator'
import { showNotification } from 'store/notification/reducer'
import SelectFilter from 'components/common/FilterBar/SelectFilter'

export enum ShippingMarkupTab {
  CARRIER_MARKUP = 'CARRIER_MARKUP',
  PARTNER_DISCOUNT = 'PARTNER_DISCOUNT',
}

const StyledTabsContainer = styled(Tabs)({
  margin: `0 -${CONTENT_PADDING}px`,
  padding: `0 ${CONTENT_PADDING}px`,
})

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

export const ShippingMarkupPage = () => {
  const [tabValue, setTabValue] = useState<ShippingMarkupTab>(
    ShippingMarkupTab.CARRIER_MARKUP,
  )

  const initialFilters = {
    seller_id: undefined,
    carrier: undefined,
  }

  const [selectValue, setSelectValue] = useState<any>('')
  const [effectiveMarkup, setEffectiveMarkup] = useState<Markup>()
  const [upcomingMarkup, setUpcomingMarkup] = useState<Markup>()
  const [filters, setFilters] = useState<Filters>(initialFilters)

  const dispatch = useDispatch()
  const { table } = useTable({
    direction: Direction.DESC,
    orderBy: 'last_modified',
    page: 0,
    perPage: 20,
  })

  type Filters = {
    seller_id: string | undefined
    carrier: Carrier | undefined
  }

  const buildSearchParams = ({ seller_id, carrier }: Filters) => {
    return {
      seller_id,
      carrier,
    }
  }

  const { refetch: refetchEffectiveMarkup } = useQuery(
    ['GET_EFFECTIVE_MARKUP', selectValue],
    () => {
      if (selectValue) {
        getEffectiveMarkupByCarrier(selectValue).then((res) => {
          setEffectiveMarkup(res)
          return res
        })
      }
      return null
    },
  )

  useQuery(['GET_UPCOMING_MARKUP', effectiveMarkup], () => {
    if (selectValue) {
      getMarkupsByCarrier(selectValue).then((res) => {
        setUpcomingMarkup(
          res.find(
            (markup: Markup) => markup.start_date === effectiveMarkup?.end_date,
          ),
        )
        return res
      })
    }
    return null
  })

  const {
    data: partnerDiscounts,
    isLoading,
    refetch: refetchPartnerDiscounts,
  } = useQuery(['GET_PARTNER_DISCOUNTS', tabValue, table, filters], () => {
    if (tabValue === ShippingMarkupTab.PARTNER_DISCOUNT) {
      return getPartnerDiscounts(table.state, {
        effective_date: new Date(),
        ...buildSearchParams(filters),
      })
    }
    return null
  })

  const handleTabChange = (_event: any, newValue: ShippingMarkupTab) => {
    setTabValue(newValue)
  }

  const handleMenuItemClick = async (event: any) => {
    setSelectValue(event.target.value)
  }

  const handleDialogClose = (discount: Discount, sellerName: string) => {
    const discountStartDate = formatISODate(
      discount.start_date,
      DATE_DISPLAY_FORMAT,
    )
    const discountRate = discount.rate * 100
    const discountCarrier =
      discount.carrier === Carrier.FEDEX ? 'FedEx' : discount.carrier

    const message = `${discountCarrier} Carrier markup rate for ${sellerName} has ${
      discount.id ? 'been updated' : 'been added'
    }.\n ${discountRate}% will take effect on ${discountStartDate} at 12am CST`

    dispatch(closeDialog())
    dispatch(
      showNotification({
        isShown: true,
        message,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        severity: 'success',
        autoClose: true,
      }),
    )

    refetchPartnerDiscounts()
  }

  const handleFilterChange = (filter?: keyof Filters) => (value: any) => {
    const searchParam = filter ?? Object.keys(value)[0]
    const val = value[searchParam]

    setFilters((prev) => ({
      ...prev,
      [searchParam]: val,
    }))
  }

  const handleFilterClear = () => {
    setFilters(initialFilters)
  }

  return (
    <div data-testid="shipping-markup-page">
      <HeaderTitle title="Buy Shipping Markup" />
      <StyledTabsContainer
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        aria-label="Buy Shipping Markup Tabs"
      >
        <Tab value={ShippingMarkupTab.CARRIER_MARKUP} label="Carrier Markup" />
        <Tab
          value={ShippingMarkupTab.PARTNER_DISCOUNT}
          label="Partner Discount"
        />
      </StyledTabsContainer>
      <TabPanel
        value={ShippingMarkupTab.CARRIER_MARKUP}
        selectedValue={tabValue}
      >
        <Grid container>
          <Grid item xs={2} pb={2}>
            <FormControl fullWidth size="small">
              <InputLabel>Select Carrier</InputLabel>
              <Select
                id="selectCarrier"
                name="selectCarrier"
                data-testid="carrier-select"
                label="Select Carrier"
                onChange={handleMenuItemClick}
                value={selectValue}
                placeholder="Select Carrier"
              >
                <MenuItem value="FEDEX">FedEx</MenuItem>
                <MenuItem value="UPS">UPS</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {selectValue && effectiveMarkup && (
            <Grid item xs={12}>
              <CarrierMarkupCard
                carrier={selectValue}
                effectiveMarkup={effectiveMarkup}
                upcomingMarkup={upcomingMarkup}
                refreshData={refetchEffectiveMarkup}
              />
            </Grid>
          )}
        </Grid>
      </TabPanel>
      <TabPanel
        value={ShippingMarkupTab.PARTNER_DISCOUNT}
        selectedValue={tabValue}
      >
        <Grid container>
          <Grid item xs={12}>
            <div className="hc-fs-sm">
              Discounts added for a specific partner will be applied to the
              carrier markups on the carrier markup tab.
            </div>
            <StyledButton
              data-testid="add-partner-discount-button"
              onClick={() =>
                dispatch(
                  openDialog({
                    dialogEnum: DialogEnum.ADD_PARTNER_DISCOUNT,
                    componentProps: {
                      handleClose: handleDialogClose,
                    },
                  }),
                )
              }
              color="primary"
              variant="contained"
              aria-label="add a new partner discount"
            >
              <AddIcon />
              ADD PARTNER DISCOUNT
            </StyledButton>
          </Grid>
          <Grid item xs={12} sx={{ pt: 2 }}>
            <FilterBar
              count={partnerDiscounts?.total}
              onClear={handleFilterClear}
            >
              <Grid item xs={3}>
                <TypeaheadFilter
                  label="Partner"
                  placeholder="Search Partner"
                  value={filters.seller_id}
                  searchParam="seller_id"
                  onChange={handleFilterChange()}
                />
              </Grid>
              <Grid item xs={3}>
                <SelectFilter
                  label="Carrier"
                  placeholder="Select Carrier"
                  value={filters.carrier}
                  searchParam="carrier"
                  onChange={handleFilterChange()}
                  data={[
                    { name: 'UPS', value: Carrier.UPS },
                    { name: 'FedEx', value: Carrier.FEDEX },
                  ]}
                />
              </Grid>
            </FilterBar>
          </Grid>
          <Grid item xs={12}>
            <PartnerDiscountTable
              table={table}
              isPending={isLoading}
              data={partnerDiscounts?.data ?? []}
              total={partnerDiscounts?.total ?? 0}
              handleClose={handleDialogClose}
            />
          </Grid>
        </Grid>
      </TabPanel>
    </div>
  )
}

export default ShippingMarkupPage
