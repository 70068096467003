import Grid from '@mui/material/Grid'

import HeaderTitle from 'components/common/HeaderTitle'
import ShippingMethodsContent from './ShippingMethodsContent'

export const ShippingMethodsPage = () => {
  return (
    <div>
      <HeaderTitle title="Shipping Methods" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ShippingMethodsContent />
        </Grid>
      </Grid>
    </div>
  )
}

export default ShippingMethodsPage
