import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { DialogEnum } from 'components/common/Dialog'
import DisplayCard from 'components/common/DisplayCard'
import Link from 'components/common/Link'
import Stepper from 'components/common/Stepper'

import BarcodeReleaseInput from './BarcodeReleaseInput'
import BarcodeItemSuspend from './BarcodeItemSuspend'
import ReleaseBarcodes from './ReleaseBarcodes'

import {
  cancelTask,
  endTask,
  startTask,
  trackCustomEvent,
} from 'services/fireflyInsights'
import { getCurrentProductStatus } from 'services/itemHelper'
import { getSmsProduct, updateProductStatus } from 'services/items'
import { RoutePath } from 'services/NavigationHelper'

import { openDialog, closeDialog } from 'store/dialog/actionCreator'

import { BarcodeInfo } from 'types/Barcodes'
import {
  FireflyEvent,
  FireflyTask,
  FireflyTaskStatus,
} from 'types/FireflyInsights'
import { ListingStatus, ProductError } from 'types/Item'

export interface TargetPlusBarcodeDetails {
  barcode: string
  barcodeInfo: BarcodeInfo
  productInfo?: {
    sellerId: string
    sellerName: string
    productId: string
  }
  orderCount?: number
}

export const BarcodeRelease = () => {
  const dispatch = useDispatch()
  const [releaseStep, setReleaseStep] = useState(0)
  const [barcodes, setBarcodes] = useState<string[]>([])
  const [itemSuspendBarcodes, setItemSuspendBarcodes] = useState<
    TargetPlusBarcodeDetails[]
  >([])
  const [title, setTitle] = useState<string>('')
  const [subtitle, setSubtitle] = useState<string>('')
  const [taskStatus, setTaskStatus] = useState<FireflyTaskStatus>(
    FireflyTaskStatus.TASK_UNSTARTED,
  )
  const steps = ['Search Barcodes', 'Suspend Items', 'Release Barcodes']

  const fieldList = [
    {
      key: 'barcode',
      heading: 'Barcode',
    },
    {
      key: 'orderCount',
      heading: 'Orders in the Last 120 Days',
    },
    {
      key: 'inventory',
      heading: 'Inventory',
    },
    {
      key: 'tcin',
      heading: 'TCIN',
      formatCell: (item: TargetPlusBarcodeDetails) => {
        const tcin = item.barcodeInfo.owner?.tcins[0]
        const productId = item.productInfo?.productId
        const sellerId = item.productInfo?.sellerId
        const toPath = `/${sellerId}${RoutePath.ALL_ITEMS}/${productId}`
        return (
          <Link to={toPath} target={'_blank'}>
            {tcin}
          </Link>
        )
      },
    },
    {
      key: 'sellerName',
      heading: 'Owned By',
      formatCell: (item: TargetPlusBarcodeDetails) =>
        item.productInfo?.sellerName,
    },
  ]

  useEffect(() => {
    switch (releaseStep) {
      case 0:
        setTitle('Step 1: Search Barcodes for Release (Limit to 100)')
        setSubtitle(
          'Only Target Plus Owned Barcodes can be released using Marketplace Portal',
        )
        break
      case 1:
        setTitle('Step 2: Verify Details & Suspend Items')
        setSubtitle('Select items to be suspended')
        break
      case 2:
        setTitle('Step 3: Select Barcodes to be Released')
        setSubtitle(
          'Selected barcodes will be freed for other Target Vendors or Target Plus Partners to use',
        )
        break
      default:
        setTitle('')
        setSubtitle('')
    }
  }, [releaseStep])

  useEffect(() => () => cancelTask(FireflyTask.RELEASE_BARCODE), [])

  const handleResetBarcodes = () => {
    setBarcodes([])
    setItemSuspendBarcodes([])
    setReleaseStep(0)
  }

  const handleBarcodeInputSubmit = (barcodeEntries: string[]) => {
    if (
      [
        FireflyTaskStatus.TASK_UNSTARTED,
        FireflyTaskStatus.TASK_COMPLETE,
      ].includes(taskStatus)
    ) {
      setTaskStatus(FireflyTaskStatus.TASK_STARTED)
      startTask(FireflyTask.RELEASE_BARCODE)
    }
    trackCustomEvent(
      FireflyEvent.RELEASE_BARCODE_SEARCH,
      'search',
      barcodeEntries.length,
    )
    setBarcodes(barcodeEntries)
    setReleaseStep(1)
  }

  const handleBarcodeItemSuspend = (
    error: ProductError,
    suspendedBarcodes: TargetPlusBarcodeDetails[],
  ) => {
    let promises = suspendedBarcodes.map((suspendedBarcodes) => {
      return getSmsProduct({
        sellerId: suspendedBarcodes.productInfo!.sellerId,
        productId: suspendedBarcodes.productInfo!.productId,
        params: { expand: 'product_statuses' },
      }).then((smsProduct) => {
        const currentProductStatus = getCurrentProductStatus(smsProduct)
        if (!currentProductStatus) {
          console.error('No current product status found')
          return
        } else {
          if (currentProductStatus.listing_status !== ListingStatus.SUSPENDED) {
            const productStatusUpdate = {
              listing_status: ListingStatus.SUSPENDED,
              errors: [error],
            }
            updateProductStatus({
              sellerId: suspendedBarcodes.productInfo!.sellerId,
              productId: suspendedBarcodes.productInfo!.productId,
              statusId: currentProductStatus.id,
              productStatusUpdate,
            })
          }
        }
      })
    })
    Promise.all(promises).then(() => {
      dispatch(openDialog({ dialogEnum: DialogEnum.SUSPENDED_SUCCESS_DIALOG }))

      trackCustomEvent(
        FireflyEvent.RELEASE_BARCODE_SUSPEND,
        'suspend',
        suspendedBarcodes.length,
      )
      setItemSuspendBarcodes(suspendedBarcodes)
      setReleaseStep(2)
    })
  }

  const onSubmit = (suspendedBarcodes: TargetPlusBarcodeDetails[]) => {
    dispatch(
      openDialog({
        dialogEnum: DialogEnum.SUSPENDED_ITEM_DIALOG,
        componentProps: {
          onSubmit: (error: ProductError) => {
            handleBarcodeItemSuspend(error, suspendedBarcodes)
          },
          itemCount: suspendedBarcodes.length,
          onClose: () => {
            dispatch(closeDialog())
          },
        },
      }),
    )
  }

  const handleBarcodeRelease = () => {
    endTask(FireflyTask.RELEASE_BARCODE)
    setTaskStatus(FireflyTaskStatus.TASK_COMPLETE)
    setReleaseStep(0)
  }

  return (
    <DisplayCard title={title} description={subtitle}>
      <Stepper steps={steps} currentStep={releaseStep} />
      {releaseStep === 0 && (
        <BarcodeReleaseInput handleSubmit={handleBarcodeInputSubmit} />
      )}
      {releaseStep === 1 && (
        <BarcodeItemSuspend
          barcodes={barcodes}
          submitText="suspend and continue"
          fieldList={fieldList}
          handleSubmit={onSubmit}
          handleReset={handleResetBarcodes}
        />
      )}
      {releaseStep === 2 && (
        <ReleaseBarcodes
          barcodes={itemSuspendBarcodes}
          handleRelease={handleBarcodeRelease}
          handleReset={handleResetBarcodes}
        />
      )}
    </DisplayCard>
  )
}

export default BarcodeRelease
