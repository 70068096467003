import { EnhancedTableFieldType } from 'components/common/EnhancedTable'
import {
  formatPercentTwoDecimals,
  formatDateMDYT,
} from 'components/common/EnhancedTable/formatters'

import Link from 'components/common/Link'

import formatCurrency from 'services/formatCurrency'

import { PartnerDiscount } from 'types/Discounts'
import { ShippingRatesTableRow } from 'types/ShippingRates'

export const getCurrentRateFieldList: EnhancedTableFieldType<ShippingRatesTableRow>[] =
  [
    {
      key: 'min_pounds',
      heading: 'Start Weight (lbs)',
    },
    {
      key: 'maxPounds',
      heading: 'End Weight (lbs)',
    },
    {
      key: 'rateDescription',
      heading: 'Fee Description',
    },
    {
      key: 'currentRateInPennies',
      heading: 'Service Fee',
      formatCell: (rate) => {
        if (rate && rate.currentRateInPennies) {
          return formatCurrency(rate.currentRateInPennies / 100)
        } else {
          return 'None'
        }
      },
    },
    {
      key: 'last_modified_by',
      heading: 'Last Modified By',
    },
    {
      key: 'last_modified',
      heading: 'Last Modified',
      formatCell: formatDateMDYT('last_modified'),
    },
  ]

export const getFutureRateFieldList: EnhancedTableFieldType<ShippingRatesTableRow>[] =
  [
    {
      key: 'min_pounds',
      heading: 'Start Weight (lbs)',
    },
    {
      key: 'maxPounds',
      heading: 'End Weight (lbs)',
    },
    {
      key: 'rateDescription',
      heading: 'Fee Description',
    },
    {
      key: 'currentRateInPennies',
      heading: 'Service Fee',
      formatCell: (rate) => {
        if (rate && rate.currentRateInPennies) {
          return formatCurrency(rate.currentRateInPennies / 100)
        } else {
          return 'None'
        }
      },
    },
    {
      key: 'start_date',
      heading: 'Future Start Date',
      formatCell: formatDateMDYT('start_date'),
    },
    {
      key: 'last_modified_by',
      heading: 'Last Modified By',
    },
    {
      key: 'last_modified',
      heading: 'Last Modified',
      formatCell: formatDateMDYT('last_modified'),
    },
  ]

export const getDiscountsFieldList: EnhancedTableFieldType<PartnerDiscount>[] =
  [
    {
      key: 'display_name',
      heading: 'Partner',
      formatCell: (discount) => {
        return (
          <Link to={`/${discount.seller_id}/dashboard`}>
            {discount.display_name}
          </Link>
        )
      },
    },
    {
      key: 'discount_percentage',
      heading: 'Discount (%)',
      formatCell: formatPercentTwoDecimals('discount_percentage'),
    },
    {
      key: 'last_modified',
      heading: 'Last Modified',
      formatCell: formatDateMDYT('last_modified'),
    },
    {
      key: 'last_modified_by',
      heading: 'Last Modified By',
    },
  ]
