import {
  ResponsiveContainer,
  LineChart as RechartsLineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ReferenceLine,
  Label,
} from 'recharts'

import { error, primary } from 'config/themeConfig'

const LineChart = ({
  data,
  tooltipFormatter,
  tooltipLabelFormatter,
  ariaLabelCallback,
  referenceLinePosition,
  referenceLineValue,
}: {
  data: any
  tooltipFormatter: any
  tooltipLabelFormatter: any
  ariaLabelCallback: (xValue: any, yValue: any) => string
  referenceLinePosition?: number
  referenceLineValue?: string
}) => {
  const CustomDot = ({ payload, isCompare = false }: any) => {
    const { date, base, compareDate, compare } = payload

    if (!isCompare) {
      if (!date || !base) return <div />

      return <text aria-label={ariaLabelCallback(date, base)} />
    }

    if (!compareDate || !compare) return <div />

    return <text aria-label={ariaLabelCallback(compareDate, compare)} />
  }

  return (
    <ResponsiveContainer width="100%" height={250}>
      <RechartsLineChart data={data} margin={{ bottom: 20 }}>
        <CartesianGrid strokeDasharray="3, 3" />
        <YAxis />
        <XAxis
          dataKey="date"
          angle={30}
          dy={10}
          tick={{ 'aria-hidden': true }}
        />
        <Tooltip
          formatter={tooltipFormatter}
          labelFormatter={tooltipLabelFormatter}
        />
        <Line
          type="monotone"
          dataKey="base"
          stroke={primary.main}
          dot={<CustomDot />}
          strokeWidth={3}
        />
        <Line
          type="monotone"
          dataKey="compare"
          stroke="#cc0000"
          dot={<CustomDot isCompare />}
          strokeWidth={3}
        />
        {referenceLinePosition !== undefined && (
          <ReferenceLine
            y={referenceLinePosition}
            stroke={error.main}
            strokeWidth={2}
            strokeDasharray="5 5"
          >
            {referenceLineValue && (
              <Label offset={5} position="top" style={{ fill: error.main }}>
                {referenceLineValue}
              </Label>
            )}
          </ReferenceLine>
        )}
      </RechartsLineChart>
    </ResponsiveContainer>
  )
}

export default LineChart
