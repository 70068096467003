import { useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ProtectedElement, useAuth } from '@praxis/component-auth'

import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'

import UserHome from 'components/common/UserHome'
import NotAuthorizedPage from 'components/NotAuthorized/NotAuthorizedPage'
import ScrollToTop from 'components/common/ScrollToTop'

import { RoutePath } from 'services/NavigationHelper'

import { setUserInfo } from 'store/user/actionCreators'

import GlobalStyles from 'styles/global'
import themeConfig from 'config/themeConfig'
import apiConfig from 'config/apiConfig'

import { ALL_USER_ROLES } from 'services/roles'
import { UserInfo } from 'types/UserInfo'
import { UserSettingsProvider } from './context/UserSettingsProvider'

import { Layout } from '@enterprise-ui/canvas-ui-react'

import '@enterprise-ui/canvas-ui-css'
import '@enterprise-ui/canvas-ui-css-autocomplete'

import '../../src/styles/flex.scss'
import '../../src/styles/canvas-overrides.scss'

export const App = () => {
  const reduxDispatch = useDispatch()

  const auth = useAuth()

  useEffect(() => {
    const { session } = auth

    if (session) {
      const { userInfo } = session

      const isAuthenticated = auth.isAuthenticated()
      if (userInfo) {
        const user: UserInfo = {
          ...userInfo,
          id: '',
        }

        reduxDispatch(
          setUserInfo({
            userInfo: user,
            isAuthenticated,
          }),
        )
      }
    }
  }, [auth, reduxDispatch])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themeConfig}>
        <UserSettingsProvider>
          <CssBaseline>
            <GlobalStyles />
            <BrowserRouter basename={apiConfig.auth.basename}>
              <ScrollToTop />
              <Layout data-testid="layout" darkMode="false">
                <Routes>
                  <Route
                    path={RoutePath.NOT_AUTHORIZED}
                    element={
                      <ProtectedElement allowed={[]}>
                        <NotAuthorizedPage />
                      </ProtectedElement>
                    }
                  ></Route>
                  <Route
                    path="*"
                    element={
                      <ProtectedElement
                        allowed={ALL_USER_ROLES}
                        unauthorizedRoute={`${apiConfig.auth.basename}${RoutePath.NOT_AUTHORIZED}`}
                      >
                        <UserHome />
                      </ProtectedElement>
                    }
                  ></Route>
                </Routes>
              </Layout>
            </BrowserRouter>
          </CssBaseline>
        </UserSettingsProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
