import { useSelector } from 'react-redux'

import Typography from '@mui/material/Typography'

import HeaderTitle from 'components/common/HeaderTitle'
import TitleBar from 'components/common/TitleBar'

import { isOneOfUserRoles } from 'services/authorization'
import { USER_ROLE_ADMIN, USER_ROLE_ITEM_MANAGER } from 'services/roles'

import { getMemberOf, currentSeller } from 'store/selectors'

import ErrorConfigurationTable from './ErrorConfigurationTable'

export const ErrorConfigurationPage = () => {
  const memberOf = useSelector(getMemberOf)
  const seller = useSelector(currentSeller)
  const sellerId = seller?.id
  const sellerName = seller?.display_name

  const canEdit = !!(
    isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN, USER_ROLE_ITEM_MANAGER]) &&
    sellerId &&
    sellerName
  )

  return (
    <>
      <HeaderTitle title="Error Configuration" />
      <TitleBar title="Error Configuration" />
      <Typography paragraph>
        Provides the ability at partner level to suppress errors that lead to an
        item being placed in review status.
      </Typography>
      <Typography paragraph>
        If an error is suppressed and there are no other review/blocked status
        errors on the item, the item will process as a validated status item. By
        default, all errors are enabled.
      </Typography>
      {sellerId && (
        <ErrorConfigurationTable sellerId={sellerId} canEdit={canEdit} />
      )}
    </>
  )
}

export default ErrorConfigurationPage
