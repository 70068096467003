import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import Select from 'components/common/Select'

import { LABEL_SOURCE } from 'constants/labels'

import { getSellerSources } from 'services/seller'
import { validationHandler, ValidationSchema } from 'services/validation'

import { currentSeller, isSellersPending } from 'store/selectors'
import { editSeller } from 'store/seller/actionCreators'

import { Validation } from 'types/Validation'

import { SellerSource } from 'types/Seller'

export interface Props {
  isOpen: boolean
}

const formValidationSchema: ValidationSchema = yup.object().shape({
  primarySource: yup.string().label('Source').required(),
})

export const EditSource = ({ isOpen }: Props) => {
  const seller = useSelector(currentSeller)
  const isPending = useSelector(isSellersPending)

  const dispatch = useDispatch()

  const [sources, setSources] = useState<SellerSource[]>([])
  const [primarySource, setPrimarySource] = useState(seller?.source ?? '')
  const [validation, setValidation] = useState<Validation>({})

  useEffect(() => {
    let mounted = true

    getSellerSources({ status: 'ACTIVE' }).then(({ data }) => {
      if (mounted) {
        setSources(data)
      }
    })
    return () => {
      mounted = false
    }
  }, [])

  const handleInputChange = (value: any) => {
    setPrimarySource(value)
  }

  const handleSubmit = () => {
    const { validation: formValidation, isValid } = validationHandler(
      formValidationSchema,
      {
        primarySource,
      },
    )

    setValidation(formValidation)

    if (isValid && primarySource && seller) {
      const updatedSeller = {
        ...seller,
        source: primarySource,
      }

      dispatch(editSeller(updatedSeller))
    }
  }

  return (
    <DialogContainer
      data-testid="edit-source-dialog"
      isOpen={isOpen}
      title="Edit Source"
      isPending={isPending}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {sources.length === 0 && (
            // material-ui will throw a warning in tests if the <Select /> is rendered before it's options are available
            <Grid container justifyContent="center" spacing={2}>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          )}
          {sources.length > 0 && (
            <Select
              isRequired
              id="primarySource"
              name="primarySource"
              data-testid="source"
              label={LABEL_SOURCE}
              isDisabled={isPending}
              onChange={handleInputChange}
              options={sources}
              value={primarySource}
              keyName="description"
              valueName="id"
              validation={validation}
            />
          )}
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export default EditSource
