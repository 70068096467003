import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useQuery } from '@tanstack/react-query'

import isEmpty from 'lodash/fp/isEmpty'
import { Box, Tab, Tabs, Typography, Grid, Badge } from '@mui/material'
import styled from '@emotion/styled'

import TitleBar from 'components/common/TitleBar'
import ContentSpacer from 'components/common/ContentSpacer'
import { DialogEnum } from 'components/common/Dialog'
import {
  TableState,
  useSearchParams,
  getEnhancedTablePageableProps,
} from 'components/common/FilterBar/useSearchParams'
import Link from 'components/common/Link'

import { CONTENT_PADDING } from 'constants/layout'
import { white } from 'config/themeConfig'

import {
  currentSellerId,
  currentSellerVmmId,
  email,
  getMemberOf,
} from 'store/selectors'
import { openDialog } from 'store/dialog/actionCreator'

import { Direction } from 'services/pageableHelper'
import { isUserRole } from 'services/authorization'
import { USER_ROLE_APP_SMS_READ } from 'services/roles'
import { fetchContentHealthScore } from 'services/vendorScoreCards'
import { RoutePath } from 'services/NavigationHelper'
import { getPayouts } from 'services/payouts'

import { getPayoutsQueryKey } from './queries'
import { hasReport, hasReportsTab, SellerReportsTab } from './hasReport'
import useSellerReports from './useSellerReports'
import GeneratedReportsTable from './GeneratedReportsTable'
import SellerReportTable from './SellerReportTable'

import { ReportType, SellerReportTableRow } from 'types/Report'

import { FireflyEvent } from 'types/FireflyInsights'
import { trackCustomEvent } from 'services/fireflyInsights'
import { useUserSettingsContext } from 'components/context/UserSettingsProvider'

const StyledText = styled(Typography)(
  ({ theme }) => ({
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
  }),
  (props) => ({
    variant: props.variant,
  }),
) as typeof Typography

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
})) as typeof Typography

const StyledTabs = styled(Tabs)({
  margin: `0 -${CONTENT_PADDING}px`,
  padding: `0 ${CONTENT_PADDING}px`,
  background: white.main,
})

const StyledTabLabel = styled('span')(({ theme }) => ({
  padding: theme.spacing(1),
}))

const StyledGrid = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(5),
}))

const initialSearchParams: TableState = {
  perPage: 5,
  page: 0,
  orderBy: 'created',
  direction: Direction.DESC,
}

export const SellerReports = () => {
  const dispatch = useDispatch()
  const sellerId = useSelector(currentSellerId)
  const vmmId = useSelector(currentSellerVmmId)
  const userEmail = useSelector(email)
  const memberOf = useSelector(getMemberOf)
  const isReadOnly = isUserRole(memberOf, USER_ROLE_APP_SMS_READ)

  const [searchParams, searchParamActions] =
    useSearchParams<TableState>(initialSearchParams)

  const { status: financeStatus } = useQuery(
    getPayoutsQueryKey({ sellerId }),
    () => {
      if (sellerId && !isReadOnly) {
        return getPayouts({
          sellerId,
        })
      }
    },
    {
      retry: false,
    },
  )

  const salesTaxReportMinDate = new Date(2023, 0, 1)

  const { userSettings } = useUserSettingsContext()

  const [tabValue, setTabValue] = useState<SellerReportsTab>()
  const favoriteReports = userSettings.data?.reports?.favorites ?? []

  const filteredFavoriteReports = favoriteReports?.filter(
    (reportType: ReportType) => hasReport(memberOf, reportType),
  )

  const [contentScoreDownloadLink, setContentScoreDownloadLink] =
    useState<string>('')

  useEffect(() => {
    if (tabValue === undefined && userSettings.status === 'success') {
      setTabValue(
        filteredFavoriteReports.length
          ? SellerReportsTab.FAVORITES
          : SellerReportsTab.ITEM_DATA,
      )
    }
  }, [tabValue, userSettings, isReadOnly, filteredFavoriteReports.length])

  useEffect(() => {
    let mounted = true
    const getContentHealthScore = async () => {
      if (sellerId && vmmId) {
        try {
          const response = await fetchContentHealthScore({
            sellerId,
            vmmId,
          })

          if (mounted) {
            setContentScoreDownloadLink(response[0].scorecards[0].downloadLink)
          }
        } catch (err: any) {
          if (mounted && err.message) {
            console.error(`Error generating Content Score report: ${err}`)
          }
        }
      }
    }

    getContentHealthScore()

    return () => {
      mounted = false
    }
  }, [sellerId, vmmId])

  const { tableData, tableTotal, isTableLoading, cancelPendingReport } =
    useSellerReports({
      sellerId,
      userEmail,
      page: searchParams.page,
      perPage: searchParams.perPage,
      orderBy: searchParams.orderBy,
      direction: searchParams.direction,
    })

  const {
    generateReport: generateFinanceReport,
    isReportGenerating: isFinanceReportGenerating,
  } = useSellerReports({
    sellerId,
    userEmail,
    reportType: ReportType.PAYOUT_RECONCILIATION,
    page: searchParams.page,
    perPage: searchParams.perPage,
    orderBy: searchParams.orderBy,
    direction: searchParams.direction,
  })

  const {
    generateReport: generateItemErrorsReport,
    isReportGenerating: isItemErrorsReportGenerating,
  } = useSellerReports({
    sellerId,
    userEmail,
    reportType: ReportType.ITEM_ERRORS,
    page: searchParams.page,
    perPage: searchParams.perPage,
    orderBy: searchParams.orderBy,
    direction: searchParams.direction,
  })

  const {
    generateReport: generateItemErrorsV2Report,
    isReportGenerating: isItemErrorsV2ReportGenerating,
  } = useSellerReports({
    sellerId,
    userEmail,
    reportType: ReportType.ITEM_ERRORS_V2,
    page: searchParams.page,
    perPage: searchParams.perPage,
    orderBy: searchParams.orderBy,
    direction: searchParams.direction,
  })

  const {
    generateReport: generateAllowedItemTypesReport,
    isReportGenerating: isAllowedItemTypesReportGenerating,
  } = useSellerReports({
    sellerId,
    userEmail,
    reportType: ReportType.ALLOWED_ITEM_TYPES,
    page: searchParams.page,
    perPage: searchParams.perPage,
    orderBy: searchParams.orderBy,
    direction: searchParams.direction,
  })

  const {
    generateReport: generateItemsWithLogisticsErrorsReport,
    isReportGenerating: isItemsWithLogisticsErrorsReportGenerating,
  } = useSellerReports({
    sellerId,
    userEmail,
    reportType: ReportType.PRODUCT_LOGISTIC_ERRORS,
    page: searchParams.page,
    perPage: searchParams.perPage,
    orderBy: searchParams.orderBy,
    direction: searchParams.direction,
  })

  const {
    generateReport: generateOrdersReport,
    isReportGenerating: isOrdersReportGenerating,
  } = useSellerReports({
    sellerId,
    userEmail,
    reportType: ReportType.ORDERS,
    page: searchParams.page,
    perPage: searchParams.perPage,
    orderBy: searchParams.orderBy,
    direction: searchParams.direction,
  })

  const {
    generateReport: generateReturnsReport,
    isReportGenerating: isReturnsReportGenerating,
  } = useSellerReports({
    sellerId,
    userEmail,
    reportType: ReportType.RETURN_ORDERS_EXTERNAL,
    page: searchParams.page,
    perPage: searchParams.perPage,
    orderBy: searchParams.orderBy,
    direction: searchParams.direction,
  })

  const {
    generateReport: generateOrderDefectRateReport,
    isReportGenerating: isOrderDefectRateReportGenerating,
  } = useSellerReports({
    sellerId,
    userEmail,
    reportType: ReportType.ORDER_DEFECTS,
    page: searchParams.page,
    perPage: searchParams.perPage,
    orderBy: searchParams.orderBy,
    direction: searchParams.direction,
  })

  const {
    generateReport: generateSalesMetricsReport,
    isReportGenerating: isSalesMetricsReportGenerating,
  } = useSellerReports({
    sellerId,
    userEmail,
    reportType: ReportType.SALES_METRICS,
    page: searchParams.page,
    perPage: searchParams.perPage,
    orderBy: searchParams.orderBy,
    direction: searchParams.direction,
  })

  const {
    generateReport: generateAttributeValuesReport,
    isReportGenerating: isAttributeValuesReportGenerating,
  } = useSellerReports({
    sellerId,
    userEmail,
    reportType: ReportType.ATTRIBUTE_VALUES,
    page: searchParams.page,
    perPage: searchParams.perPage,
    orderBy: searchParams.orderBy,
    direction: searchParams.direction,
  })

  const {
    generateReport: generateInventoryAndPriceReport,
    isReportGenerating: isInventoryAndPriceReportGenerating,
  } = useSellerReports({
    sellerId,
    userEmail,
    reportType: ReportType.INVENTORY,
    page: searchParams.page,
    perPage: searchParams.perPage,
    orderBy: searchParams.orderBy,
    direction: searchParams.direction,
  })

  const {
    generateReport: generatePartnerReviewsReport,
    isReportGenerating: isPartnerReviewsReportGenerating,
  } = useSellerReports({
    sellerId,
    userEmail,
    reportType: ReportType.PARTNER_REVIEWS,
    page: searchParams.page,
    perPage: searchParams.perPage,
    orderBy: searchParams.orderBy,
    direction: searchParams.direction,
  })

  const {
    generateReport: generateSalesTaxReport,
    isReportGenerating: isSalesTaxReportGenerating,
  } = useSellerReports({
    sellerId,
    userEmail,
    reportType: ReportType.SALES_TAX,
    page: searchParams.page,
    perPage: searchParams.perPage,
    orderBy: searchParams.orderBy,
    direction: searchParams.direction,
  })

  const generateContentScoreReport = () => {
    trackCustomEvent(
      FireflyEvent.ITEM_INSIGHT,
      'general',
      'score report download',
    )
    window.location.href = contentScoreDownloadLink
  }

  const handleChange = (_event: any, newValue: SellerReportsTab) => {
    setTabValue(newValue)
  }

  const reportRows: SellerReportTableRow[] = [
    {
      tab: SellerReportsTab.FINANCE,
      type: ReportType.PAYOUT_RECONCILIATION,
      description:
        'View Stripe transfers per order line and payouts in a given time period.',
      tooltip:
        financeStatus === 'error'
          ? 'Unable to verify Stripe account for generation'
          : '',
      isDisabled: financeStatus !== 'success',
      isPending: isFinanceReportGenerating,
    },
    {
      tab: SellerReportsTab.FINANCE,
      type: ReportType.SALES_TAX,
      description: 'View taxable sales details by state.',
      isDisabled: false,
      isPending: isSalesTaxReportGenerating,
    },

    {
      tab: SellerReportsTab.ITEM_DATA,
      type: ReportType.ITEM_ERRORS,
      description: 'See actions required to fix errors on rejected items.',
      isDisabled: false,
      isPending: isItemErrorsReportGenerating,
    },
    {
      tab: SellerReportsTab.ITEM_DATA,
      type: ReportType.ITEM_ERRORS_V2,
      description: 'See rejected Items by Critical and Non-Critical errors.',
      isDisabled: false,
      isPending: isItemErrorsV2ReportGenerating,
    },
    {
      tab: SellerReportsTab.ITEM_DATA,
      type: ReportType.ALLOWED_ITEM_TYPES,
      description:
        'View every allowed item type with description, referral rate, subtype and brand restrictions (if any).',
      isDisabled: false,
      isPending: isAllowedItemTypesReportGenerating,
    },
    {
      tab: SellerReportsTab.ITEM_DATA,
      type: ReportType.ATTRIBUTE_VALUES,
      description:
        'For a specific item type, view required & recommended attributes with allowed values for each.',
      isDisabled: false,
      isPending: isAttributeValuesReportGenerating,
    },
    {
      tab: SellerReportsTab.PERFORMANCE,
      type: ReportType.ORDER_DEFECTS,
      description: (
        <span>
          Look into late deliveries, cancelations, guest contact and returns
          that negatively affected your{' '}
          <Link to={`/${sellerId}${RoutePath.PERFORMANCE}`}>ODR</Link>.
        </span>
      ),
      isDisabled: false,
      isPending: isOrderDefectRateReportGenerating,
    },
    {
      tab: SellerReportsTab.ORDERS,
      type: ReportType.ORDERS,
      description:
        'View SKU level order data with requested ship date, delivery date, unit price and RedCard discount (if any).',
      isDisabled: false,
      isPending: isOrdersReportGenerating,
    },
    {
      tab: SellerReportsTab.RETURNS,
      type: ReportType.RETURN_ORDERS_EXTERNAL,
      description:
        'View SKU level return data with guest return reason, qty, dispositions, locations, tracking, BOL, license plate (if any) and more.',
      isDisabled: false,
      isPending: isReturnsReportGenerating,
    },
    {
      tab: SellerReportsTab.PERFORMANCE,
      type: ReportType.SALES_METRICS,
      description:
        'See item level GMV, conversion rate or return rate data for all units sold in a given time period.',
      isDisabled: false,
      isPending: isSalesMetricsReportGenerating,
    },
    {
      tab: SellerReportsTab.ITEM_DATA,
      type: ReportType.PRODUCT_LOGISTIC_ERRORS,
      description: (
        <span>
          See items with errors on return policy, 2-day shipping, shipping
          add-on or shipping exclusion values.{' '}
          <Link to={`${RoutePath.KNOWLEDGE_ARTICLE}/000110167`}>
            Learn more.
          </Link>
        </span>
      ),
      isDisabled: false,
      isPending: isItemsWithLogisticsErrorsReportGenerating,
    },
    {
      tab: SellerReportsTab.ITEM_DATA,
      type: ReportType.INVENTORY,
      description:
        'View latest SKU level retail price, MAP, offer price & inventory at each distribution center along with last updated dates.',
      isDisabled: false,
      isPending: isInventoryAndPriceReportGenerating,
    },
    {
      tab: SellerReportsTab.PERFORMANCE,
      type: ReportType.PARTNER_REVIEWS,
      description:
        'View all time partner level ratings and reviews from Target.com. Note: these are not item specific.',
      isDisabled: false,
      isPending: isPartnerReviewsReportGenerating,
    },
    {
      tab: SellerReportsTab.PERFORMANCE,
      type: ReportType.CONTENT_SCORE,
      description:
        'Bi-weekly evaluation of content quality and accuracy for all published VAPs and SAs',
      isDisabled: !contentScoreDownloadLink,
      isPending: false,
    },
  ]

  const handleGenerateReport = (reportType: ReportType) => () => {
    if (reportType === ReportType.PAYOUT_RECONCILIATION) {
      dispatch(
        openDialog({
          dialogEnum: DialogEnum.FINANCIAL_RECONCILIATION_REPORT,
          componentProps: {
            type: reportType,
            onRequestSubmit: generateFinanceReport,
          },
        }),
      )
    } else if (reportType === ReportType.ORDERS) {
      dispatch(
        openDialog({
          dialogEnum: DialogEnum.ORDERS_REPORT,
          componentProps: {
            type: reportType,
            onRequestSubmit: generateOrdersReport,
          },
        }),
      )
    } else if (reportType === ReportType.RETURN_ORDERS_EXTERNAL) {
      dispatch(
        openDialog({
          dialogEnum: DialogEnum.RETURN_ORDERS_REPORT,
          componentProps: {
            type: reportType,
            onRequestSubmit: generateReturnsReport,
          },
        }),
      )
    } else if (reportType === ReportType.SALES_METRICS) {
      dispatch(
        openDialog({
          dialogEnum: DialogEnum.SALES_METRICS_REPORT,
          componentProps: {
            reportType,
            onRequestSubmit: generateSalesMetricsReport,
          },
        }),
      )
    } else if (reportType === ReportType.ATTRIBUTE_VALUES) {
      dispatch(
        openDialog({
          dialogEnum: DialogEnum.ITEM_ATTRIBUTES_AND_VALUES,
          componentProps: {
            reportType,
            onRequestSubmit: generateAttributeValuesReport,
          },
        }),
      )
    } else if (reportType === ReportType.ITEM_ERRORS) {
      generateItemErrorsReport({ type: reportType })
    } else if (reportType === ReportType.ITEM_ERRORS_V2) {
      dispatch(
        openDialog({
          dialogEnum: DialogEnum.ITEM_ERRORS_REPORT_DIALOG,
          componentProps: {
            reportType,
            onRequestSubmit: generateItemErrorsV2Report,
          },
        }),
      )
    } else if (reportType === ReportType.ALLOWED_ITEM_TYPES) {
      generateAllowedItemTypesReport({ type: reportType })
    } else if (reportType === ReportType.ORDER_DEFECTS) {
      dispatch(
        openDialog({
          dialogEnum: DialogEnum.ORDER_DEFECT_RATE_REPORT_DIALOG,
          componentProps: {
            reportType,
            onRequestSubmit: generateOrderDefectRateReport,
          },
        }),
      )
    } else if (reportType === ReportType.PRODUCT_LOGISTIC_ERRORS) {
      generateItemsWithLogisticsErrorsReport({ type: reportType })
    } else if (reportType === ReportType.INVENTORY) {
      dispatch(
        openDialog({
          dialogEnum: DialogEnum.IVENTORY_AND_PRICE_REPORT_DIALOG,
          componentProps: {
            reportType,
            onRequestSubmit: generateInventoryAndPriceReport,
          },
        }),
      )
    } else if (reportType === ReportType.PARTNER_REVIEWS) {
      generatePartnerReviewsReport({ type: reportType })
    } else if (reportType === ReportType.CONTENT_SCORE) {
      generateContentScoreReport()
    } else if (reportType === ReportType.SALES_TAX) {
      dispatch(
        openDialog({
          dialogEnum: DialogEnum.SALES_TAX_REPORT,
          componentProps: {
            type: reportType,
            onRequestSubmit: generateSalesTaxReport,
            dayRange: 365,
            minDate: salesTaxReportMinDate,
            disableDatesBefore: salesTaxReportMinDate,
            reportName: 'Partner Sales and Tax',
          },
        }),
      )
    }
  }

  return (
    <>
      <TitleBar title="All Reports" />
      <StyledText variant="h3" component="h2">
        Reports Generated by Me
      </StyledText>
      <ContentSpacer>
        <StyledSubtitle>
          {isEmpty(tableData)
            ? 'No reports generated yet.'
            : 'Large reports may take up to 1 hour to generate.'}
        </StyledSubtitle>
      </ContentSpacer>
      {!isEmpty(tableData) && (
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          data-testid="seller-reports"
        >
          <StyledGrid item xs={12}>
            <GeneratedReportsTable
              memberOf={memberOf}
              isLoading={isTableLoading}
              data={tableData}
              total={tableTotal}
              onRequestCancelReport={cancelPendingReport}
              {...getEnhancedTablePageableProps(
                searchParams,
                searchParamActions,
              )}
            />
          </StyledGrid>
        </Grid>
      )}
      <StyledText id="reportTemplates" variant="h3" component="h2">
        Report Templates
      </StyledText>
      {tabValue && (
        <Box sx={{ pt: 2 }}>
          <StyledTabs
            aria-labelledby="reportTemplates"
            indicatorColor="primary"
            value={tabValue}
            onChange={handleChange}
          >
            <Tab
              value={SellerReportsTab.FAVORITES}
              label={
                <Badge
                  color="primary"
                  badgeContent={filteredFavoriteReports?.length}
                  max={50}
                  invisible={filteredFavoriteReports?.length === 0}
                >
                  <StyledTabLabel>Favorites</StyledTabLabel>
                </Badge>
              }
              data-testid="seller-favorites-tab"
            />

            {hasReportsTab(memberOf, SellerReportsTab.FINANCE) && (
              <Tab
                value={SellerReportsTab.FINANCE}
                label={<StyledTabLabel>Finance</StyledTabLabel>}
                data-testid="seller-finance-tab"
              />
            )}
            <Tab
              value={SellerReportsTab.ORDERS}
              label="Orders"
              data-testid="seller-orders-tab"
            />
            <Tab
              value={SellerReportsTab.RETURNS}
              label="Returns"
              data-testid="seller-returns-tab"
            />
            <Tab
              value={SellerReportsTab.ITEM_DATA}
              label={<StyledTabLabel>Item Data</StyledTabLabel>}
              data-testid="seller-item-data-tab"
            />
            <Tab
              value={SellerReportsTab.PERFORMANCE}
              label={<StyledTabLabel>Performance</StyledTabLabel>}
              data-testid="seller-performance-tab"
            />
            <Tab
              value={SellerReportsTab.ALL_REPORTS}
              label={<StyledTabLabel>All Reports</StyledTabLabel>}
              data-testid="seller-all-reports-tab"
            />
          </StyledTabs>
          <Box sx={{ pt: 3 }}>
            <SellerReportTable
              data={reportRows}
              userSettings={userSettings?.data}
              tab={tabValue}
              memberOf={memberOf}
              handleClick={handleGenerateReport}
            />
          </Box>
        </Box>
      )}
    </>
  )
}
export default SellerReports
