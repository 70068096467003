import React from 'react'

import styled from '@emotion/styled'

import PrintHTML from 'components/common/PrintHTML'

import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import GroupIcon from '@mui/icons-material/Group'

import Bullseye from 'components/common/Icons/Bullseye'

import {
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
  formatDate,
} from 'services/dateService'

import { backgroundGrey, target } from 'config/themeConfig'

import { CaseEmails } from 'types/Case'
import DownloadAttachment from 'components/common/Attachment/DownloadAttachment'

export interface Props {
  email: CaseEmails
  sellerId: string
  index: number
  expandMessage: boolean
}

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, -3),
}))

const StyledEmailAddress = styled('span')({
  fontWeight: 'bold',
})

const StyledEmailBody = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(14),
}))

const StyledEmailContent = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}))

const StyledPartnerMessageAvatarBackground = styled(Avatar)({
  backgroundColor: backgroundGrey.main,
})

const StyledTargetMessageAvatarBackground = styled(Avatar)({
  backgroundColor: target.light,
})

const StyledBullseye = styled(Bullseye)({
  color: target.main,
})

const StyledGroupIcon = styled(GroupIcon)(({ theme }) => ({
  color: theme.palette.grey[900],
}))

const StyledTitleButton = styled(Button)({
  textTransform: 'none',
  verticalAlign: 'middle',
})

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isExpanded',
})<{ isExpanded: boolean }>((props) => ({
  transform: props.isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
  marginLeft: !props.isExpanded ? 'auto' : undefined,
  transition: !props.isExpanded
    ? `transitions.create('transform', {
        duration: transitions.duration.shortest,
      })`
    : undefined,
}))

export const Email = ({ email, sellerId, index, expandMessage }: Props) => {
  const attachmentIds = email.attachment_ids

  const [expanded, setExpanded] = React.useState(expandMessage)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  let isFromTarget = false
  if (email?.from_address?.split('@')[1] === 'target.com') {
    isFromTarget = true
  }

  return (
    <>
      <ListItem data-testid="email" key={index} disableGutters>
        <Grid container spacing={2}>
          <Grid item>
            <StyledIconButton
              isExpanded={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label={`Expand ${email.from_address} sent a message to ${email.to_address}`}
              data-testid="expand-icon"
              size="large"
            >
              <ExpandMoreIcon />
            </StyledIconButton>
          </Grid>
          <Grid item>
            {!isFromTarget && (
              <StyledPartnerMessageAvatarBackground>
                <StyledGroupIcon data-testid="from-seller-icon" />
              </StyledPartnerMessageAvatarBackground>
            )}
            {isFromTarget && (
              <StyledTargetMessageAvatarBackground data-testid="from-target-icon">
                <StyledBullseye fill={target.main} />
              </StyledTargetMessageAvatarBackground>
            )}
          </Grid>
          <Grid item xs={8}>
            <StyledTitleButton onClick={handleExpandClick}>
              <Typography variant="body1">
                <StyledEmailAddress data-testid="from-address">
                  {email.from_address}
                </StyledEmailAddress>
                <span> sent a message to </span>
                <StyledEmailAddress data-testid="to-address">
                  {email.to_address}
                </StyledEmailAddress>
              </Typography>
            </StyledTitleButton>
          </Grid>
          <Grid item xs={3}>
            <Typography align="right">
              {formatDate(email.created, DATE_FORMAT_MONTH_DAY_YEAR_TIME)}
            </Typography>
          </Grid>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <StyledEmailBody>
              <StyledEmailContent item xs={12}>
                <Typography variant="body1" data-testid="email-subject">
                  Subject: {email.subject}
                </Typography>
              </StyledEmailContent>
              <StyledEmailContent item xs={12}>
                <PrintHTML content={email.text_body} />
              </StyledEmailContent>
              {attachmentIds &&
                attachmentIds.map((attachmentId) => (
                  <DownloadAttachment
                    key={attachmentId}
                    sellerId={sellerId}
                    attachmentId={attachmentId}
                  />
                ))}
            </StyledEmailBody>
          </Collapse>
        </Grid>
      </ListItem>
      <StyledDivider />
    </>
  )
}
