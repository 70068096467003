import styled from '@emotion/styled'
import { grey, white } from 'config/themeConfig'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import CircularProgress from '@mui/material/CircularProgress'

const StyledAppBar = styled(AppBar)({
  backgroundColor: grey[900],
  boxShadow: 'none',
  position: 'sticky',
})

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  margin: theme.spacing(0, 2),
  color: white.main,
}))

const StyledButton = styled(Button)(({ theme }) => ({
  color: white.main,
  '& :hover>svg': {
    fill: grey[900],
  },
  marginRight: theme.spacing(2),
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: white.main,
  '& :hover>svg': {
    fill: grey[900],
  },
  marginRight: theme.spacing(2),
}))

export interface Props {
  title?: string
  submitButtonText?: string
  isDisabled: boolean
  onClose: () => any
  onSubmit?: () => any
}

export const DialogHeader = ({
  title,
  submitButtonText,
  isDisabled,
  onClose,
  onSubmit,
}: Props) => {
  const submitButtonContent = isDisabled ? (
    <StyledCircularProgress size={20} />
  ) : (
    submitButtonText
  )

  return (
    <StyledAppBar>
      <Toolbar>
        <StyledIconButton
          data-testid="dialog-xclose-button"
          aria-label="Close"
          disabled={isDisabled}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </StyledIconButton>
        {title && (
          <Typography
            variant="h1"
            color="inherit"
            sx={{ flex: 1 }}
            data-testid="dialog-header-title"
          >
            {title}
          </Typography>
        )}
        {submitButtonText && (
          <StyledButton
            data-testid="dialog-save-button"
            aria-label="Save"
            disabled={isDisabled}
            onClick={onSubmit}
          >
            {submitButtonContent}
          </StyledButton>
        )}
        <StyledButton
          data-testid="dialog-close-button"
          aria-label="Close"
          disabled={isDisabled}
          onClick={onClose}
        >
          Close
        </StyledButton>
      </Toolbar>
    </StyledAppBar>
  )
}

DialogHeader.defaultProps = {
  isDisabled: false,
}

export default DialogHeader
