import { useEffect, useState, SyntheticEvent } from 'react'
import usePrevious from 'hooks/usePrevious'

import { Division } from 'types/Division'
import { getDivisions } from 'services/itemGroups'
import { excludedDivisions } from 'constants/excludedDivisions'
import toLower from 'lodash/fp/toLower'
import startCase from 'lodash/fp/startCase'

import Typeahead from '.'

interface Props {
  placeholder?: string
  value?: Nullable<Division>
  onChange: (value: Nullable<Division>, enteredValue: string) => void
  onClear?: () => void
}

const DivisionTypeahead = ({
  placeholder = 'Division',
  value: valueFromProps,
  onChange,
  onClear,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedValue, setSelectedValue] =
    useState<Nullable<Division>>(valueFromProps)
  const [options, setOptions] = useState<Division[]>([])
  const [loading, setLoading] = useState(false)

  const [divisions, setDivisions] = useState<Division[]>([])

  const prevValue = usePrevious(valueFromProps)

  useEffect(() => {
    if (prevValue !== valueFromProps) {
      setSelectedValue(valueFromProps)
    }

    if (!valueFromProps) {
      setOptions([])
    }
  }, [prevValue, valueFromProps])

  useEffect(() => {
    getDivisions().then((res) => {
      setDivisions(res)
    })
  }, [])

  useEffect(() => {
    if (options.length && !inputValue) {
      setOptions([])
    }
  }, [inputValue, options])

  const handleInputChange = async (
    _event: SyntheticEvent,
    enteredValue: string,
    reason: string,
  ) => {
    if (
      onClear &&
      (reason === 'clear' ||
        (selectedValue && reason === 'input' && enteredValue === ''))
    ) {
      onClear()
      setOptions([])
      setInputValue('')
      setSelectedValue(null)
      return
    }

    setInputValue(enteredValue)
    const invalidInputValue = !enteredValue || reason === 'reset'
    if (invalidInputValue) {
      return
    }

    setLoading(true)

    const filteredData = divisions.filter((item) => {
      if (item?.division_name) {
        return !!item?.division_name.includes(enteredValue.toUpperCase())
      }
      return false
    })

    const data = filteredData.filter(
      (value) => !excludedDivisions.includes(value.division_name),
    )

    setOptions(data)
    setLoading(false)
  }

  const handleSelectedOptionChange = (
    _event: SyntheticEvent,
    value: Nullable<Division>,
  ) => {
    const searchParamValue = getOptionLabel(value as Division)
    setSelectedValue(value as Nullable<Division>)
    onChange(value as Nullable<Division>, searchParamValue)
  }

  const getOptionLabel = (division: Division) =>
    startCase(toLower(division?.division_name)) ?? ''

  return (
    <div data-testid="division-typeahead">
      <Typeahead
        aria-label="Search Division"
        placeholder={placeholder}
        getOptionLabel={getOptionLabel}
        options={options}
        loading={loading}
        onInputChange={handleInputChange}
        onChange={handleSelectedOptionChange}
        inputValue={inputValue}
        value={selectedValue}
        isOptionEqualToValue={(option: Division, value: Nullable<Division>) =>
          option.division_id === value?.division_id
        }
      />
    </div>
  )
}

export default DivisionTypeahead
