import React from 'react'
import { isArray } from 'util'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import DisplayCard from 'components/common/DisplayCard'
import HeaderTitle from 'components/common/HeaderTitle'
import Input from 'components/common/Input'

import {
  getGeographicalCodes,
  getTelephoneCodeByCountry,
  getCountryCodeByTelephoneCode,
} from 'services/geographicalCodes'
import { searchSellerUser, updateSellerUser } from 'services/sellerUser'

import SellerUser, { PhoneNumber } from 'types/SellerUser'
import { Typography } from '@mui/material'

const geographicalCodes = getGeographicalCodes()

const UserPhoneEditPage = () => {
  const [sellerUser, setSellerUser] = React.useState<SellerUser>()
  const [userEmail, setUserEmail] = React.useState<string>()
  const [saving, setSaving] = React.useState<boolean>(false)
  const [showSaved, setShowSaved] = React.useState<boolean>(false)

  const handleEmailChange = (value: string) => {
    setUserEmail(value)
  }

  const handleUpdateField =
    (field: string) => (value: string, name: string) => {
      const type = name.substr(0, name.indexOf('-'))

      if (!sellerUser?.phone_numbers) return

      const numbers = sellerUser.phone_numbers.map((phoneNumber) => {
        if (phoneNumber.type === type) {
          return {
            ...phoneNumber,
            [field]: value,
          }
        } else {
          return phoneNumber
        }
      })

      setSellerUser({
        ...sellerUser,
        phone_numbers: numbers,
      })
    }

  const handleCountryCodeChange = (event: SelectChangeEvent<string>) => {
    if (!sellerUser?.phone_numbers || !event.target) return

    const { name, value } = event.target
    const type = name?.substr(0, name.indexOf('-'))

    const countryName = getTelephoneCodeByCountry(value as string)

    const numbers = sellerUser.phone_numbers.map((phoneNumber) => {
      if (phoneNumber.type === type) {
        return {
          ...phoneNumber,
          country_code: countryName,
          country_name_code: value as string,
        }
      } else {
        return phoneNumber
      }
    })

    setSellerUser({
      ...sellerUser,
      phone_numbers: numbers,
    })
  }

  const handleSearchClick = async () => {
    if (!userEmail) return

    const users = (await searchSellerUser(userEmail)) as
      | SellerUser
      | SellerUser[]
    setSellerUser(isArray(users) ? users[0] : users)
  }

  const handleSave = () => {
    if (!sellerUser) return

    setSaving(true)
    updateSellerUser(sellerUser).then(() => {
      setSaving(false)
      setShowSaved(true)
      setTimeout(() => setShowSaved(false), 5000)
    })
  }

  return (
    <div>
      <HeaderTitle title="User Phone Edit" />
      <DisplayCard title="User Phone Edit">
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Input
              type="text"
              placeholder="User Email"
              name="user-email"
              id="user-email"
              onChange={handleEmailChange}
              value={userEmail ?? ''}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearchClick}
              disabled={!userEmail}
            >
              Search
            </Button>
          </Grid>
        </Grid>
        {sellerUser && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {`User Name: ${sellerUser.first_name} ${sellerUser.last_name}`}
            </Grid>
            {sellerUser.phone_numbers?.map(
              (phoneNumber: PhoneNumber, key: number) => {
                if (phoneNumber.type !== 'MOBILE') return null

                return (
                  <Grid item xs={12} key={key}>
                    <Grid container spacing={1}>
                      <Grid item xs={2}>
                        <Select
                          id={`${phoneNumber.type}-country_name_code`}
                          name={`${phoneNumber.type}-country_name_code`}
                          inputProps={{
                            'aria-label': `${phoneNumber.type} country code`,
                          }}
                          fullWidth
                          variant="standard"
                          value={
                            phoneNumber.country_name_code ??
                            getCountryCodeByTelephoneCode(
                              phoneNumber.country_code ?? '1',
                            )
                          }
                          renderValue={(selected: any) => {
                            const item = geographicalCodes.find(
                              (option) => option.iso_alpha_2_code === selected,
                            )

                            if (!item) return ''

                            return `${item?.flag} +${item?.telephone_code}`
                          }}
                          onChange={handleCountryCodeChange}
                        >
                          {geographicalCodes.map((option) => {
                            return (
                              <MenuItem
                                data-testid={`option-${option.country_name}-${option.iso_alpha_2_code}`}
                                key={`${option.country_name}-${option.iso_alpha_2_code}`}
                                value={option.iso_alpha_2_code}
                              >
                                {option.flag} {option.formattedCountryName} +
                                {option.telephone_code}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </Grid>
                      <Grid item xs={5}>
                        <Input
                          type="text"
                          id={`${phoneNumber.type}-number`}
                          name={`${phoneNumber.type}-number`}
                          value={phoneNumber.number}
                          onChange={handleUpdateField('number')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )
              },
            )}
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    disabled={saving}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  {showSaved && <Typography>Saved!</Typography>}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </DisplayCard>
    </div>
  )
}

export default UserPhoneEditPage
