import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import Grid from '@mui/material/Grid'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import Input from 'components/common/Input'

import { validationHandler, ValidationSchema } from 'services/validation'

import { currentSeller, isSellersPending } from 'store/selectors'
import { editSeller } from 'store/seller/actionCreators'

import { Validation } from 'types/Validation'

interface Props {
  isOpen: boolean
}

const urlRegex = new RegExp(/^https:\/\/target.scene7.com.*$/, 'i')

const validationSchema: ValidationSchema = yup.object().shape(
  {
    headerImage: yup.string().when('logoImage', {
      is: (val: string) => urlRegex.test(val),
      then: yup.string().matches(urlRegex, {
        message: 'Header image must start with https://target.scene7.com',
        excludeEmptyString: true,
      }),
      otherwise: yup.string().matches(urlRegex, {
        message: 'Header image must start with https://target.scene7.com',
        excludeEmptyString: true,
      }),
    }),
    logoImage: yup.string().when('headerImage', {
      is: (val: string) => urlRegex.test(val),
      then: yup.string().matches(urlRegex, {
        message: 'Logo image must start with https://target.scene7.com',
        excludeEmptyString: true,
      }),
      otherwise: yup.string().matches(urlRegex, {
        message: 'Logo image must start with https://target.scene7.com',
        excludeEmptyString: true,
      }),
    }),
  },
  [
    ['logoImage', 'headerImage'],
    ['headerImage', 'logoImage'],
  ],
)

export const EditBannerAndLogo = ({ isOpen }: Props) => {
  const seller = useSelector(currentSeller)
  const isPending = useSelector(isSellersPending)

  const dispatch = useDispatch()

  const [logoImage, setLogoImage] = useState(seller?.logo_image ?? '')
  const [headerImage, setHeaderImage] = useState(seller?.header_image ?? '')
  const [validation, setValidation] = useState<Validation>({})

  const handleLogoChange = (value: string) => {
    setLogoImage(value)
  }

  const handleHeaderChange = (value: string) => {
    setHeaderImage(value)
  }

  const handleSubmit = () => {
    const { validation: formValidation, isValid } = validationHandler(
      validationSchema,
      { logoImage, headerImage },
    )
    setValidation(formValidation)

    if (isValid && seller) {
      const newSeller = {
        ...seller,
        logo_image: logoImage,
        header_image: headerImage,
      }

      dispatch(editSeller(newSeller))
    }
  }

  return (
    <DialogContainer
      title="Edit Partner Logo & Banner"
      subtitle="Images will display on partner page on Target.com."
      isOpen={isOpen}
      isPending={isPending}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2} data-testid="edit-image-urls">
        <Grid item xs={12}>
          <Input
            isRequired
            id="logo-image"
            name="logoImage"
            label="Logo Image"
            value={logoImage}
            isDisabled={isPending}
            onChange={handleLogoChange}
            validation={validation}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            isRequired
            id="header-image"
            name="headerImage"
            label="Banner Image"
            value={headerImage}
            isDisabled={isPending}
            onChange={handleHeaderChange}
            validation={validation}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export default EditBannerAndLogo
