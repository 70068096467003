import { type FC, type PropsWithChildren } from 'react'
import styled from '@emotion/styled'

import { ReactNode } from 'react'

export interface AspectRatioProps {
  children?: ReactNode
  childrenPositionAbsolute?: boolean
  className?: string
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down'
  ratio?: string
  width?: number | string | boolean
  height?: number | string | boolean
  'data-test'?: string
  display?: string
}

export interface AspectRatioChildrenProps {
  positionAbsolute: boolean
  isRatioValid: boolean
  hasWidth: boolean
  hasHeight: boolean
}

export interface AspectRatioCanvasProps {
  width?: string
  height?: string
  hasWidth: boolean
  hasHeight: boolean
}

const FLOAT_FORMAT = '[0-9]*\\.?[0-9]+'
const ratioFormat = new RegExp(`${FLOAT_FORMAT}x${FLOAT_FORMAT}`)

const isValidRatio = (r?: string) => r && ratioFormat.test(r)

interface ContainerStyles {
  display?: string
  height?: string
  width?: string
}

const containerStyles = (
  noDimensions: boolean,
  isRatioValid: boolean,
  fixedWidth?: number | string | boolean,
  fixedHeight?: number | string | boolean,
  display?: string,
) => {
  if (noDimensions) return {}

  const STYLES: ContainerStyles = {
    display: display ?? 'inline-block',
  }

  if (typeof fixedWidth === 'string') {
    STYLES.width = fixedWidth
  }

  if (typeof fixedHeight === 'string' && !(isRatioValid && fixedWidth)) {
    STYLES.height = fixedHeight
  }

  return STYLES
}

const StyledAspectRatioContainer = styled('div')({
  position: 'relative',
  height: '100%',
})

const StyledAspectRatioChildren = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'positionAbsolute' &&
    prop !== 'isRatioValid' &&
    prop !== 'hasHeight' &&
    prop !== 'hasWidth',
})<AspectRatioChildrenProps>((props) => ({
  position:
    props.positionAbsolute && props.isRatioValid ? 'absolute' : undefined,
  top: props.isRatioValid ? 0 : undefined,
  left: props.isRatioValid ? 0 : undefined,
  width: props.isRatioValid || props.hasWidth ? '100%' : undefined,
  height: props.isRatioValid || props.hasHeight ? '100%' : undefined,
}))

const StyledAspectRatioCanvas = styled('canvas', {
  shouldForwardProp: (prop) => prop !== 'hasHeight',
})<AspectRatioCanvasProps>((props) => ({
  display: 'block',
  visibility: 'hidden',
  width: props.hasWidth ? '100%' : undefined,
  height: props.hasHeight ? '100%' : undefined,
}))

export const AspectRatio: FC<PropsWithChildren<AspectRatioProps>> = ({
  ratio,
  children,
  className,
  display,
  width,
  height,
  'data-test': testId,
  childrenPositionAbsolute = true,
}) => {
  const hasWidth = !!width
  const hasHeight = !!height
  const noDimensions = !hasWidth && !hasHeight

  const [isRatioValid, ratioWidth, ratioHeight] =
    isValidRatio(ratio) && ratio
      ? [true, ...ratio.split('x')]
      : [false, undefined, undefined]

  return (
    <StyledAspectRatioContainer
      className={className}
      data-test={testId}
      style={containerStyles(
        noDimensions,
        isRatioValid,
        width,
        height,
        display,
      )}
    >
      <StyledAspectRatioChildren
        hasHeight={hasHeight}
        hasWidth={hasWidth}
        isRatioValid={isRatioValid}
        positionAbsolute={childrenPositionAbsolute}
      >
        {children}
      </StyledAspectRatioChildren>
      {isRatioValid && (
        <StyledAspectRatioCanvas
          aria-hidden
          hasHeight={hasHeight}
          hasWidth={hasWidth}
          height={ratioHeight}
          width={ratioWidth}
        />
      )}
    </StyledAspectRatioContainer>
  )
}

AspectRatio.displayName = 'AspectRatio'
