import { useSelector } from 'react-redux'

import DomesticDiversitySurvey from './DomesticDiversitySurvey'
import InternationalDiversitySurvey from './InternationalDiversitySurvey'

import { isInternationalCompany } from 'store/selectors'

import StoreState from 'types/state'
import { DiversityAnswer } from 'types/PartnerDiversity'

export type Props = {
  isOpen: boolean
}
export const PartnerDiversitySurvey = ({ isOpen }: Props) => {
  const diversityAnswers = useSelector<StoreState, DiversityAnswer[]>(
    (state) => state.partnerDiversity.diversityAnswers,
  )
  const internationalCompany = useSelector(isInternationalCompany)

  return internationalCompany ? (
    <InternationalDiversitySurvey isOpen={isOpen} answers={diversityAnswers} />
  ) : (
    <DomesticDiversitySurvey isOpen={isOpen} answers={diversityAnswers} />
  )
}

export default PartnerDiversitySurvey
