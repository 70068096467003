import { ReactNode } from 'react'
import { DialogEnum } from 'components/common/Dialog'
import { SellerReportsTab } from 'components/ReportsPage/hasReport'
import { OrderStatus } from './Orders'

export enum ReportType {
  ASSORTMENT_ANALYSIS = 'ASSORTMENT_ANALYSIS',
  ATTRIBUTE_VALUES = 'ATTRIBUTE_VALUES',
  AUTO_APPROVAL_BLOCKED_LIST = 'AUTO_APPROVAL_BLOCKED_LIST',
  BULK_ITEM_UNLIST = 'BULK_ITEM_UNLIST',
  BULK_PRODUCT_DATA = 'BULK_PRODUCT_DATA',
  BULK_PRODUCT_DATA_TEMPLATE = 'BULK_PRODUCT_DATA_TEMPLATE',
  BULK_LEGALIZATION = 'BULK_LEGALIZATION',
  CONTENT_SCORE = 'CONTENT_SCORE',
  INVENTORY = 'INVENTORY',
  INFORM_ACT = 'INFORM_ACT',
  ITEM_ERRORS = 'ITEM_ERRORS',
  ITEM_ERRORS_V2 = 'ITEM_ERRORS_V2',
  ALLOWED_ITEM_TYPES = 'ALLOWED_ITEM_TYPES',
  MARKETPLACE_USERS = 'MARKETPLACE_USERS',
  ORDER_DEFECTS = 'ORDER_DEFECTS',
  ORDERS = 'ORDERS',
  ORDERS_INTERNAL = 'ORDERS_INTERNAL',
  ORDERS_MISSING_TRANSFERS = 'ORDERS_MISSING_TRANSFERS',
  ORDERS_PAST_SLA = 'ORDERS_PAST_SLA',
  PARTNER_CASE_DISPUTES = 'PARTNER_CASE_DISPUTES',
  PARTNER_CASE_DISPUTES_EXTERNAL = 'PARTNER_CASE_DISPUTES_EXTERNAL',
  PARTNER_DIVERSITY = 'PARTNER_DIVERSITY',
  PARTNER_FINANCIAL_DETAILS = 'PARTNER_FINANCIAL_DETAILS',
  PARTNER_PRICE_DETAILS = 'PARTNER_PRICE_DETAILS',
  PARTNER_REVIEWS = 'PARTNER_REVIEWS',
  PARTNER_QUOTA_COUNTS = 'PARTNER_QUOTA_COUNTS',
  PARTNER_RETURN_POLICIES = 'PARTNER_RETURN_POLICIES',
  PARTNERS = 'PARTNERS',
  PAYOUT_RECONCILIATION = 'PAYOUT_RECONCILIATION',
  RETURN_ORDERS_EXTERNAL = 'RETURN_ORDERS_EXTERNAL',
  RETURN_ORDERS_INTERNAL = 'RETURN_ORDERS_INTERNAL',
  RETURNS_RESEARCH = 'RETURNS_RESEARCH',
  PRODUCT_LOGISTIC_ERRORS = 'PRODUCT_LOGISTIC_ERRORS',
  PRODUCT_LOGISTIC_ERRORS_INTERNAL = 'PRODUCT_LOGISTIC_ERRORS_INTERNAL',
  PROMOTIONS = 'PROMOTIONS',
  SALES_METRICS = 'SALES_METRICS',
  SALES_TAX = 'SALES_TAX',
  SELLER_OPERATIONS_DETAILS = 'SELLER_OPERATIONS_DETAILS',
  VALID_RETURN_DISPUTES = 'VALID_RETURN_DISPUTES',
}

export enum ReportStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  COMPLETE = 'COMPLETE',
  CANCELED = 'CANCELED',
  ERROR = 'ERROR',
}

export interface Report {
  id: string
  type: string
  status: ReportStatus
  created: string
  start_date?: string
  end_date?: string
  report_name?: string
  seller_id: string
  created_by: string
  download_url?: string
  last_modified: string
  last_modified_by: string
  errors?: string[] // this is deprecated. use status to determine error and feedback for messages.
  feedback?: string[]
  format?: string
  parameters?: ReportParameters
}

export interface RecentReports {
  pending: Report | undefined
  last: Report | undefined
}

export interface ReportParameters {
  statuses?: string | string[]
  order_status?: OrderStatus[]
  is_published?: boolean
  item_type_id?: string
  item_type_name?: string
  get_products?: boolean
  acknowledged_partially_shipped_sla_hours?: number
  pending_sla_hours?: number
  case_id?: string
  order_defect_types?: string[]
  sources?: string | string[]
  source?: string
  start_date?: string
  end_date?: string
  requested_shipment_date_start?: string
  requested_shipment_date_end?: string
  tcin?: string
  license_plate?: string
  tracking_number?: string
  return_date?: string
  crc_received_date?: string
  ship_date?: string
  return_channel?: string
  bill_of_lading?: string
  location_id?: string
  roles?: string[]
  functional_responsibilities?: number[]
  return_order_number?: string
  receiving_location_id?: number
  is_online?: boolean
  vmm_id?: number
  store_number?: string
  scan_date?: string
  sellers?: string[]
  start_day?: string
  end_day?: string
  metrics_type?: string
  include_metadata?: boolean
  shipment_id?: string
  effective_date?: string
  error_severity?: string
  item_status?: string[]
}

export interface ReportParametersInterface {
  onChange: (parameters: ReportParameters) => void
}

export type SellerReportTableRow = {
  tab: SellerReportsTab
  type: ReportType
  description: ReactNode
  dialogEnum?: DialogEnum
  tooltip?: string
  isPending: boolean
  isDisabled: boolean
}
