import { useReducer, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { DialogEnum } from 'components/common/Dialog'
import EditButton from 'components/common/EditButton'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import { formatDateMDYT } from 'components/common/EnhancedTable/formatters'
import {
  useSearchParams,
  TableState,
} from 'components/common/FilterBar/useSearchParams'
import HeaderTitle from 'components/common/HeaderTitle'
import TableSpacer from 'components/common/TableSpacer'
import TitleBar from 'components/common/TitleBar'
import Text from 'components/common/Text'

import { formatLocaleNumber } from 'services/formatNumber'
import { hydrateProductQuotas } from 'services/productQuotas'
import { Direction } from 'services/pageableHelper'
import { USER_ROLE_ADMIN, USER_ROLE_PROGRAM_MANAGER } from 'services/roles'

import { ProductQuota } from 'types/ProductQuota'
import { isOneOfUserRoles } from 'services/authorization'

import { getMemberOf } from 'store/selectors'

const loadProductQuota = () => ({ type: 'LOAD_PRODUCT_QUOTA' }) as const
const loadProductQuotaSuccess = ({
  data,
  total,
}: {
  data: ProductQuota[]
  total: number
}) =>
  ({
    type: 'LOAD_PRODUCT_QUOTA_SUCCESS',
    payload: {
      data,
      total,
    },
  }) as const

const loadProductQuotaError = () =>
  ({ type: 'LOAD_PRODUCT_QUOTA_ERROR' }) as const

type Action = ReturnType<
  | typeof loadProductQuota
  | typeof loadProductQuotaSuccess
  | typeof loadProductQuotaError
>

const fieldList: EnhancedTableFieldType<ProductQuota>[] = [
  {
    key: 'taxonomyName',
    heading: 'Item Type',
  },
  {
    key: 'quota',
    heading: 'Item Quota',
    formatCell: ({ quota }) => formatLocaleNumber(quota),
  },
  {
    key: 'currentCount',
    heading: 'Current Count',
    tooltip:
      'Number of VAPs & SAs currently published or currently pending with no previous approved versions.',
    formatCell: ({ currentCount, quota }) => {
      const type = currentCount && currentCount > quota ? 'warning' : undefined

      return (
        <Text type={type}>
          {currentCount !== undefined ? formatLocaleNumber(currentCount) : '0'}
        </Text>
      )
    },
  },
  {
    key: 'last_modified',
    heading: 'Last Modified',
    formatCell: formatDateMDYT<ProductQuota>('last_modified'),
  },
  {
    key: 'last_modified_by',
    heading: 'Last Modified By',
  },
]

type State = {
  pending: boolean
  total: number
  data: ProductQuota[]
}

const initialState: State = {
  pending: false,
  data: [],
  total: 0,
}

const initialSearchParams: TableState = {
  direction: Direction.DESC,
  orderBy: 'last_modified',
  page: 0,
  perPage: 20,
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'LOAD_PRODUCT_QUOTA': {
      return {
        ...state,
        pending: true,
      }
    }

    case 'LOAD_PRODUCT_QUOTA_SUCCESS': {
      const { payload } = action
      const { data, total } = payload
      return {
        ...state,
        pending: false,
        data,
        total,
      }
    }

    case 'LOAD_PRODUCT_QUOTA_ERROR': {
      return {
        ...state,
        pending: false,
      }
    }

    default:
      return state
  }
}

const canEditRoles = [USER_ROLE_ADMIN, USER_ROLE_PROGRAM_MANAGER]

export const GlobalQuotasPage = () => {
  const memberOf = useSelector(getMemberOf)

  const [searchParams, searchParamActions] =
    useSearchParams<TableState>(initialSearchParams)
  const [state, dispatch] = useReducer(reducer, initialState)
  const [reloadToggle, setReloadToggle] = useState(false)

  useEffect(() => {
    let mounted = true
    const fetchProductQuotas = async () => {
      dispatch(loadProductQuota())
      try {
        const { data, total } = await hydrateProductQuotas({
          page: searchParams.page,
          perPage: searchParams.perPage,
          direction: searchParams.direction,
          orderBy: searchParams.orderBy,
        })
        if (mounted) {
          dispatch(loadProductQuotaSuccess({ data, total }))
        }
      } catch (e) {
        if (mounted) {
          dispatch(loadProductQuotaError())
        }
      }
    }

    fetchProductQuotas()

    return () => {
      mounted = false
    }
  }, [searchParams, reloadToggle])

  const dialogClosedHandler = () => {
    setReloadToggle(!reloadToggle)
  }

  const canEdit = isOneOfUserRoles(memberOf, canEditRoles)
  return (
    <div>
      <HeaderTitle title="Global Quotas" />
      <TitleBar
        title="Global Item Type Quotas"
        actionButtons={[
          <EditButton
            key="edit-global-quotas"
            aria-label="edit-global-quotas"
            dialogComponent={DialogEnum.EDIT_GLOBAL_QUOTAS}
            closeCallback={dialogClosedHandler}
            hide={!canEdit}
          />,
        ]}
      />
      <Grid container>
        <Grid item xs={12}>
          <Typography>
            All item type quotas only apply to VAPs (Variation Parents) and SAs
            (Stand Alone) items.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableSpacer>
            <EnhancedTable
              total={state.total}
              data={state.data}
              fieldList={fieldList}
              isLoading={state.pending}
              onChangePage={searchParamActions.changePage}
              onChangeRowsPerPage={searchParamActions.changePerPage}
              orderBy={searchParams.orderBy}
              order={searchParams.direction}
              page={searchParams.page}
              rowsPerPage={searchParams.perPage}
            />
          </TableSpacer>
        </Grid>
      </Grid>
    </div>
  )
}

export default GlobalQuotasPage
