import { useState } from 'react'
import { PartnerService } from 'types/PartnerServices'
import {
  LaunchLink,
  StyledDescriptionButton,
  StyledRoot,
  StyledTitle,
} from '../styles'
import {
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import AboutLink from '../AboutLink'
import { FireflyEvent } from 'types/FireflyInsights'
import { trackCustomEvent } from 'services/fireflyInsights'

export const ContentServicesCard = ({
  title,
  serviceProvider,
  description,
  image,
  menuContent,
  aboutLinks,
}: PartnerService) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleOnClick = (link: string) => {
    trackCustomEvent(FireflyEvent.PARTNER_SERVICE_LINK, 'selected_link', link)
  }

  return (
    <StyledRoot data-testid={`${title}-card`}>
      <CardHeader
        data-testid="display-card-header"
        title={
          <StyledTitle variant="h6" component="h2">
            {title}
          </StyledTitle>
        }
        subheader={
          serviceProvider ? (
            <Typography variant="body1" color="textSecondary">
              by {serviceProvider}
            </Typography>
          ) : null
        }
        avatar={<img src={image} alt={`${serviceProvider}`} height="55px" />}
      />
      <CardContent style={{ minHeight: '41%', marginTop: '16px' }}>
        <Grid container alignItems="flex-start" alignContent="center">
          <Grid item>
            <Typography variant="body1" color="textSecondary">
              {description}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions style={{ paddingTop: '16px' }}>
        {menuContent && (
          <>
            <StyledDescriptionButton
              rel="noopener noreferrer"
              variant="contained"
              color="primary"
              onClick={handleMenuOpen}
              endIcon={<ExpandMore />}
            >
              {menuContent?.menuLabel}
            </StyledDescriptionButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {menuContent?.menuItems.map((menuItem, index) => (
                <MenuItem key={index}>
                  <LaunchLink
                    href={menuItem.link}
                    target="_blank"
                    onClick={() => handleOnClick(menuItem.description)}
                  >
                    {menuItem.description}
                  </LaunchLink>
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
        {aboutLinks && <AboutLink aboutLinks={aboutLinks} />}
      </CardActions>
    </StyledRoot>
  )
}

export default ContentServicesCard
