import { useDispatch } from 'react-redux'
import { closeDialog } from 'store/dialog/actionCreator'

import { useState, useEffect } from 'react'

import styled from '@emotion/styled'

import { Divider, Typography } from '@mui/material'

import FullScreenDialogContainer from 'components/common/Dialog/FullScreenDialogContainer'
import useTable from 'components/common/EnhancedTable/useTable'

import { getSeller } from 'services/seller'
import { Direction, PagingParams } from 'services/pageableHelper'
import { useQuery } from '@tanstack/react-query'
import { getDiscountsBySellerId } from 'services/sellerShippingManagement'
import PartnerDiscountHistoryTable from '../PartnerDiscountHistoryTable'
import { Carrier } from 'types/Carrier'

interface ComponentProps {
  isOpen: boolean
  sellerId: string
  carrier: Carrier
}

type Props = ComponentProps

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(3),
}))

const ViewPartnerDiscountHistory = ({ isOpen, sellerId, carrier }: Props) => {
  const dispatch = useDispatch()

  const [sellerName, setSellerName] = useState<string>('')

  const { table } = useTable({
    direction: Direction.ASC,
    orderBy: 'start_date',
    page: 0,
    perPage: 20,
  })

  const { data: discounts, isLoading } = useQuery(
    ['GET_PARTNER_DISCOUNT_HISTORY', sellerId, table],
    () => {
      const { direction, orderBy, page, perPage }: PagingParams = table.state
      return getDiscountsBySellerId(sellerId, carrier, {
        direction,
        orderBy,
        page,
        perPage,
      })
    },
  )

  useEffect(() => {
    getSeller(sellerId).then((res) => {
      if (res?.display_name) setSellerName(res.display_name)
      else if (res?.legal_business_name) setSellerName(res.legal_business_name)
    })
  }, [sellerId])

  return (
    <FullScreenDialogContainer
      title={`${sellerName} Discount History`}
      isOpen={isOpen}
      onRequestClose={() => dispatch(closeDialog())}
    >
      <Typography
        tabIndex={-1}
        variant="h2"
        color={'textPrimary'}
        pb={1}
        style={{ lineHeight: 1 }}
      >
        {`${sellerName} Discount History`}
      </Typography>
      <StyledDivider data-testid="divider-test" color="#333" />
      <Typography
        tabIndex={-1}
        variant="subtitle2"
        color={'textPrimary'}
        style={{ lineHeight: 1, color: '#666' }}
      >
        All carrier markups start/end at 12am CST.
      </Typography>

      <PartnerDiscountHistoryTable
        table={table}
        data={discounts?.data ?? []}
        isPending={isLoading}
        total={discounts?.total ?? 0}
      />
    </FullScreenDialogContainer>
  )
}

export default ViewPartnerDiscountHistory
