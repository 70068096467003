import React from 'react'

import { withFormik, Form, FormikProps } from 'formik'
import * as yup from 'yup'

import { StyledRow } from './DomesticSurveyPageOne'

import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'

import Actions from '../Actions'
import Content from '../Content'
import InputField from 'components/common/form/InputField'
import RadioGroupField from 'components/common/form/RadioGroupField'
import Text from 'components/common/Text'

import {
  DiversityRadio,
  DiversitySelection,
  DiversitySurveyPages,
} from 'types/PartnerDiversity'

const validationSchema = yup.object().shape({
  DIFFERENTLY_ABLED: yup.string().required(),
  DIFFERENTLY_ABLED_CERT: yup.string().test({
    name: 'isDifferentlyAbledCert',
    test(this: yup.TestContext, value: any) {
      if (value && !this.parent.DIFFERENTLY_ABLED_CERT_DATE) {
        return this.createError({
          path: 'DIFFERENTLY_ABLED_CERT_DATE',
          message: 'Required',
        })
      }

      return true
    },
  }),
  DIFFERENTLY_ABLED_CERT_DATE: yup.string().when('USBLN', {
    is: (field: string | any[]) => field && field.length > 0,
    then: yup.string().required('Required'),
    otherwise: yup.string(),
  }),
  HUB_ZONE: yup.string().required(),
  HUB_ZONE_CERT: yup.string().test({
    name: 'isHubZoneCert',
    test(this: yup.TestContext, value: any) {
      if (value && !this.parent.HUB_ZONE_CERT_DATE) {
        return this.createError({
          path: 'HUB_ZONE_CERT_DATE',
          message: 'Required',
        })
      }

      return true
    },
  }),
  HUB_ZONE_CERT_DATE: yup.string().when('HUB_ZONE_CERT', {
    is: (field: string | any[]) => field && field.length > 0,
    then: yup.string().required('Required'),
    otherwise: yup.string(),
  }),
  ALASKAN_NATIVE: yup.string().required(),
  ALASKAN_NATIVE_CERT: yup.string().test({
    name: 'isAlaskanNativeCert',
    test(this: yup.TestContext, value: any) {
      if (value && !this.parent.ALASKAN_NATIVE_CERT_DATE) {
        return this.createError({
          path: 'ALASKAN_NATIVE_CERT_DATE',
          message: 'Required',
        })
      }

      return true
    },
  }),
  ALASKAN_NATIVE_CERT_DATE: yup.string().when('ALASKAN_NATIVE_CERT', {
    is: (field: string | any[]) => field && field.length > 0,
    then: yup.string().required('Required'),
    otherwise: yup.string(),
  }),
})

type FormValues = {
  DIFFERENTLY_ABLED: DiversitySelection
  DIFFERENTLY_ABLED_CERT: string
  DIFFERENTLY_ABLED_CERT_DATE: string
  HUB_ZONE: DiversitySelection
  HUB_ZONE_CERT: string
  HUB_ZONE_CERT_DATE: string
  ALASKAN_NATIVE: DiversitySelection
  ALASKAN_NATIVE_CERT: string
  ALASKAN_NATIVE_CERT_DATE: string
}

type ComponentProps = {
  page: number
  isEdit: boolean
  initValues: FormValues
  onPrevious?: (values: Partial<DiversitySurveyPages>) => void
  onSubmit: (values: any) => void
}

type Props = ComponentProps & FormikProps<FormValues>

export const DomesticSurveyPageTwoForm = ({
  page,
  onPrevious,
  isValid,
  values,
  handleSubmit,
  isSubmitting,
  setFieldValue,
}: Props) => {
  React.useEffect(() => {
    if (values.DIFFERENTLY_ABLED === DiversityRadio.NO) {
      setFieldValue('DIFFERENTLY_ABLED_CERT', '')
      setFieldValue('DIFFERENTLY_ABLED_CERT_DATE', '')
    }
  }, [values.DIFFERENTLY_ABLED, setFieldValue])

  React.useEffect(() => {
    if (values.HUB_ZONE === DiversityRadio.NO) {
      setFieldValue('HUB_ZONE_CERT', '')
      setFieldValue('HUB_ZONE_CERT_DATE', '')
    }
  }, [values.HUB_ZONE, setFieldValue])

  React.useEffect(() => {
    if (values.ALASKAN_NATIVE === DiversityRadio.NO) {
      setFieldValue('ALASKAN_NATIVE_CERT', '')
      setFieldValue('ALASKAN_NATIVE_CERT_DATE', '')
    }
  }, [values.ALASKAN_NATIVE, setFieldValue])
  return (
    <>
      <Content>
        <Form>
          <StyledRow>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Text type="bodySm" component="label">
                  Are you a Differently-Abled Owned Business?
                </Text>
              </Grid>
              {values.DIFFERENTLY_ABLED === DiversityRadio.YES && (
                <>
                  <Grid item xs={4}>
                    <Text
                      type="bodySm"
                      component="label"
                      htmlFor="differently-abled-cert"
                    >
                      US Business Leadership Network (USBLN) Certificate Number
                    </Text>
                  </Grid>
                  <Grid item xs={4}>
                    <Text
                      type="bodySm"
                      component="label"
                      htmlFor="differently-abled-cert-date"
                    >
                      USBLN Certificate Expiration Date
                    </Text>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <RadioGroupField
                  required
                  row={true}
                  name="DIFFERENTLY_ABLED"
                  options={[
                    { label: 'Yes', value: DiversityRadio.YES },
                    { label: 'No', value: DiversityRadio.NO },
                  ]}
                />
              </Grid>
              {values.DIFFERENTLY_ABLED === DiversityRadio.YES && (
                <>
                  <Grid item xs={4}>
                    <InputField
                      id="differently-abled-cert"
                      name="DIFFERENTLY_ABLED_CERT"
                      touchedFieldName="DIFFERENTLY_ABLED_CERT_DATE"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField
                      type="date"
                      id="differently-abled-cert-date"
                      name="DIFFERENTLY_ABLED_CERT_DATE"
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </StyledRow>
          <Divider />
          <StyledRow>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Text type="bodySm" component="label">
                  Is your Business Located in a HUB Zone?
                </Text>
              </Grid>
              {values.HUB_ZONE === DiversityRadio.YES && (
                <>
                  <Grid item xs={4}>
                    <Text
                      type="bodySm"
                      component="label"
                      htmlFor="hub-zone-cert"
                    >
                      HUB Zone Business Government Certificate Number
                    </Text>
                  </Grid>
                  <Grid item xs={4}>
                    <Text
                      type="bodySm"
                      component="label"
                      htmlFor="hub-zone-cert-date"
                    >
                      HUB Zone Business Government Certificate Expiration Date
                    </Text>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <RadioGroupField
                  required
                  row={true}
                  name="HUB_ZONE"
                  options={[
                    { label: 'Yes', value: DiversityRadio.YES },
                    { label: 'No', value: DiversityRadio.NO },
                  ]}
                />
              </Grid>
              {values.HUB_ZONE === DiversityRadio.YES && (
                <>
                  <Grid item xs={4}>
                    <InputField
                      id="hub-zone-cert"
                      name="HUB_ZONE_CERT"
                      touchedFieldName="HUB_ZONE_CERT_DATE"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField
                      id="hub-zone-cert-date"
                      type="date"
                      name="HUB_ZONE_CERT_DATE"
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </StyledRow>
          <StyledRow>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Text type="bodySm" component="label">
                  Are you an Alaskan Native Owned Business?
                </Text>
              </Grid>
              {values.ALASKAN_NATIVE === DiversityRadio.YES && (
                <>
                  <Grid item xs={4}>
                    <Text
                      type="bodySm"
                      component="label"
                      htmlFor="alaskan-native-cert"
                    >
                      Alaskan Native Owned Business Government Certificate
                      Number
                    </Text>
                  </Grid>
                  <Grid item xs={4}>
                    <Text
                      type="bodySm"
                      component="label"
                      htmlFor="alaskan-native-cert-date"
                    >
                      Alaskan Native Owned Business Government Certificate
                      Expiration Date
                    </Text>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <RadioGroupField
                  required
                  row={true}
                  name="ALASKAN_NATIVE"
                  options={[
                    { label: 'Yes', value: DiversityRadio.YES },
                    { label: 'No', value: DiversityRadio.NO },
                  ]}
                />
              </Grid>
              {values.ALASKAN_NATIVE === DiversityRadio.YES && (
                <>
                  <Grid item xs={4}>
                    <InputField
                      id="alaskan-native-cert"
                      name="ALASKAN_NATIVE_CERT"
                      touchedFieldName="ALASKAN_NATIVE_CERT_DATE"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField
                      type="date"
                      id="alaskan-native-cert-date"
                      name="ALASKAN_NATIVE_CERT_DATE"
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </StyledRow>
        </Form>
      </Content>
      <Actions
        page={page}
        totalPages={3}
        values={values}
        disabled={!isValid || isSubmitting}
        submitText="Done, Save"
        onSubmit={handleSubmit}
        onPrevious={onPrevious}
      />
    </>
  )
}

export const DomesticSurveyPageTwo = withFormik<ComponentProps, FormValues>({
  mapPropsToValues: (props) => ({
    DIFFERENTLY_ABLED: props.initValues.DIFFERENTLY_ABLED ?? '',
    DIFFERENTLY_ABLED_CERT: props.initValues.DIFFERENTLY_ABLED_CERT ?? '',
    DIFFERENTLY_ABLED_CERT_DATE:
      props.initValues.DIFFERENTLY_ABLED_CERT_DATE ?? '',
    HUB_ZONE: props.initValues.HUB_ZONE ?? '',
    HUB_ZONE_CERT: props.initValues.HUB_ZONE_CERT ?? '',
    HUB_ZONE_CERT_DATE: props.initValues.HUB_ZONE_CERT_DATE ?? '',
    ALASKAN_NATIVE: props.initValues.ALASKAN_NATIVE ?? '',
    ALASKAN_NATIVE_CERT: props.initValues.ALASKAN_NATIVE_CERT ?? '',
    ALASKAN_NATIVE_CERT_DATE: props.initValues.ALASKAN_NATIVE_CERT_DATE ?? '',
  }),
  handleSubmit: async (values, { props }) => {
    props.onSubmit(values)
  },
  enableReinitialize: true,
  validateOnMount: true,
  validationSchema,
})(DomesticSurveyPageTwoForm)

export default DomesticSurveyPageTwo
