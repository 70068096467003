import { useSelector } from 'react-redux'

import HeaderTitle from 'components/common/HeaderTitle'

import { currentSellerId } from 'store/selectors'

import ExternalReturnDisputeCases from './ExternalReturnDisputeCases'
import InternalReturnDisputeCases from './InternalReturnDisputeCases'

export const ReturnDisputesCasesPage = () => {
  const sellerId = useSelector(currentSellerId)

  return (
    <>
      <HeaderTitle title="Return Disputes" />
      {sellerId && (
        <ExternalReturnDisputeCases data-testid="external-return-disputes" />
      )}
      {!sellerId && (
        <InternalReturnDisputeCases data-testid="internal-return-disputes" />
      )}
    </>
  )
}

export default ReturnDisputesCasesPage
