import axios from 'axios'

import apiConfig from 'config/apiConfig'

import { EnhancedTableFieldType } from 'components/common/EnhancedTable'

import {
  DATE_FORMAT_MONTH_DAY,
  DATE_PICKER_FORMAT,
  formatDate,
} from './dateService'
import {
  fetchCardDataThruSecurityGateway,
  mapColumnsToTableFieldList,
  CardParams,
} from './securityGateway'

import { decimalRound } from 'services/number'

import {
  BiData,
  BiReportingTimePeriod,
  BiReportingTimePeriodType,
  BiReportingFilter,
  OrderDefectRateParams,
} from 'types/BiReporting'

import { FireflyEvent } from 'types/FireflyInsights'
import { trackCustomEvent } from './fireflyInsights'

export const getVendorFilter = (vmmId: string): BiReportingFilter[] => [
  {
    dimension: 'vendor_id',
    pattern: vmmId.toString(),
    type: 'in',
  },
]

export const getGreenfieldFilter = ({
  dimension,
  pattern,
}: {
  dimension: string
  pattern: string
}): BiReportingFilter => ({
  dimension: dimension,
  pattern: pattern,
  type: 'in',
})

export const getTimePeriod = ({
  interval,
  type = 'relative',
  granularity,
  compareIntervalValues,
}: {
  interval: string
  type?: BiReportingTimePeriodType
  granularity?: string
  compareIntervalValues?: string[]
}): BiReportingTimePeriod => ({
  calendar_type: 'Fiscal',
  type,
  interval,
  ...(granularity ? { granularity } : {}),
  ...(compareIntervalValues
    ? {
        compare_by: {
          type: 'relative',
          values: compareIntervalValues,
        },
      }
    : {}),
})

export const getTimePeriodInfo = (from: Date, to: Date, isCustom: boolean) => {
  let interval: string
  let type: BiReportingTimePeriodType = isCustom ? `absolute` : `relative`

  const fromDate = formatDate(from, DATE_PICKER_FORMAT)
  const toDate = formatDate(to, DATE_PICKER_FORMAT)

  interval = `${fromDate}/${toDate}`

  const timePeriod = getTimePeriod({ interval, type })

  return timePeriod
}

export const fetchCardData = (
  id: string,
  filters?: BiReportingFilter[],
): Promise<BiData> => {
  return axios
    .post(`${apiConfig.biReporting}/execute_cards?card_id=${id}`, {
      filters,
    })
    .then((result) => result.data)
}

export const getBiReportingValues = (
  data: BiData,
): Dictionary<any>[] | undefined => {
  const result = data.query_results

  if (result) {
    return result
  }
}

export const getSingleBiReportingValue = <T>(
  fieldName: string,
  data: BiData,
): T | undefined => {
  const results = getBiReportingValues(data)

  if (!results || !results.length) {
    return
  }

  const column = data.card_config.card_query_attribute.columns.find(
    (item) => item.field_name === fieldName,
  )

  const refId = column?.ref_id

  if (results && refId) {
    return results[0][refId] as T
  }
}

export const getOrderDefectRateOverTime = async ({
  key,
  cardId,
  sellerId,
  filters,
  timePeriod,
}: {
  key: string
} & CardParams): Promise<number | undefined> => {
  const data = await fetchCardDataThruSecurityGateway(
    cardId,
    sellerId,
    filters,
    timePeriod,
  )

  const defectRate = getSingleBiReportingValue<number>(key, data)

  if (defectRate !== undefined) {
    const roundedDefectRate = decimalRound(defectRate, 2)
    return roundedDefectRate
  }
}

export type OrderDefectTableAndFieldListResponse = {
  total: number
  fieldList: EnhancedTableFieldType[]
  data: any[]
}

export const getOrderDefectTableDataAndFieldList = async ({
  cardId,
  sellerId,
  filters,
  timePeriod,
}: CardParams): Promise<OrderDefectTableAndFieldListResponse> => {
  const response = await fetchCardDataThruSecurityGateway(
    cardId,
    sellerId,
    filters,
    timePeriod,
  )

  const total = response.rows_returned
  const data = response.query_results
  const columns = response.card_config.card_query_attribute.columns

  const fieldList = mapColumnsToTableFieldList(columns)

  return {
    total,
    data,
    fieldList,
  }
}

export type LineChartData = {
  base: string | number
  date: string
  fullDate?: Date
  compare?: number
  compareDate?: string
}

export const getOrderDefectLineChartData = async ({
  cardId,
  sellerId,
  filters,
  timePeriod,
}: CardParams): Promise<LineChartData[]> => {
  const results = await fetchCardDataThruSecurityGateway(
    cardId,
    sellerId,
    filters,
    timePeriod,
  )

  const data = transformDataForLineGraph(results)

  const lineGraphData = data.map((item) => {
    const date = formatDate(item.date, DATE_FORMAT_MONTH_DAY)

    const point: LineChartData = {
      base: decimalRound(item.base, 4),
      fullDate: item.date,
      date,
    }

    return point
  })

  return lineGraphData
}

export const transformDataForLineGraph = (
  data: BiData,
  isCompareBy: boolean = false,
) => {
  const { card_attribute: card } = data.card_config
  const { x_axis: xAxisConfig, y_axis: yAxisConfig } = card
  const yRefId = yAxisConfig?.[0].ref_id

  if (!xAxisConfig || !yRefId) {
    return []
  }

  if (!isCompareBy) {
    data.query_results = data.query_results.map((row) => ({
      date: new Date(row[xAxisConfig.ref_id]),
      base: row[yRefId],
    }))

    return data.query_results
  }

  let mappedData: Dictionary<Dictionary<string | number | Date>> = {}

  data.query_results.forEach((row) => {
    const [rawDate, altDate] = (row[xAxisConfig.ref_id] as string).split('||')

    if (rawDate !== altDate) {
      return
    }

    const compareData = data.query_results.find((result) => {
      const [compareRaw, compareAlt] = (
        result[xAxisConfig.ref_id] as string
      ).split('||')
      return compareAlt === altDate && compareRaw !== compareAlt
    })

    if (compareData === undefined) {
      return
    }

    mappedData[altDate] = {
      date: new Date(rawDate),
      base: row[yRefId],
      compare: compareData[yRefId],
      compareDate: new Date(
        (compareData[xAxisConfig.ref_id] as string).split('||')[0],
      ),
    }
  })

  return Object.values(mappedData)
}

export const getGreenFieldMetricsForOrderDefectRateTable = async ({
  sellerId,
  vmmId,
  cardId,
  range,
  isCustomRange,
  type,
  trackingValue,
}: OrderDefectRateParams) => {
  if (!sellerId || !vmmId) {
    return {
      data: [],
      fieldList: [],
      total: 0,
    }
  }

  const vendorFilter = getVendorFilter(vmmId)
  const timePeriod = getTimePeriodInfo(range.from, range.to, isCustomRange)

  trackCustomEvent(FireflyEvent.ODR_DETAIL_VIEW, type, trackingValue)

  const response = await getOrderDefectTableDataAndFieldList({
    cardId,
    sellerId,
    timePeriod,
    filters: vendorFilter,
  })

  return response
}
