import styled from '@emotion/styled'

export const ItemTypeButtons = styled.div`
  display: flex;
  justify-content: end;
`
export const FileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px dashed black;
  border-radius: 8px;
  padding: 24px 10px 24px 10px;
`

export const StyledReportProgress = styled('div')(({ theme }) => ({
  barColor: 'primary',
  marginLeft: theme.spacing(1),
}))
