import React from 'react'
import { connect } from 'react-redux'
import { withFormik, Form, FormikProps } from 'formik'
import * as yup from 'yup'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { surveyConfig } from './surveyConfig'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import InputField from 'components/common/form/InputField'
import Rating from 'components/common/Rating'

import { trackCustomEvent } from 'services/fireflyInsights'
import { createJiraIssue, JiraFields } from 'services/jiraIssues'
import {
  setItem,
  LOCAL_STORAGE_SURVEY_COMPLETED_REPORTS,
} from 'services/storageHelper'
import { maxCharacterCount } from 'services/validation'

import { closeDialog, CloseDialogAction } from 'store/dialog/actionCreator'

import {
  currentSellerId,
  currentSellerVmmId,
  email,
  currentSellerDisplayName,
} from 'store/selectors'

import { FireflyEvent } from 'types/FireflyInsights'
import StoreState from 'types/state'
import styled from '@emotion/styled'

type FormValues = {
  rating: number
  description: string
  email: string
}

type Status = {
  success: boolean
}

type ComponentProps = {
  sellerId: string | undefined
  vmmId: string | undefined
  sellerDisplayName: string | undefined
  userEmail: string
  isOpen: boolean
  status: Status | undefined
  closeDialog: () => CloseDialogAction
  handleSubmit: () => void
}

type Props = ComponentProps & FormikProps<FormValues>

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const StyledDiv = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const validationSchema = yup.object().shape({
  rating: yup.number().required(),
  description: yup.string().test({
    name: 'isDescription',
    test: maxCharacterCount('description', 1000),
  }),
})

const SurveyForm = ({
  isOpen,
  isSubmitting,
  isValid,
  dirty,
  status,
  closeDialog,
  handleSubmit,
  setFieldValue,
}: Props) => {
  const title = status?.success
    ? 'thank you for your feedback!'
    : `rate your ${surveyConfig.featureName} page experience`

  React.useEffect(() => {
    let timeout: NodeJS.Timeout

    if (status?.success) {
      timeout = setTimeout(() => {
        closeDialog()
      }, 3000)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [status, closeDialog])

  const updateRating = (rating: number) => {
    setFieldValue('rating', rating)
  }

  const handleCancel = () => {
    setItem(LOCAL_STORAGE_SURVEY_COMPLETED_REPORTS, 'true')
  }

  return (
    <DialogContainer
      isOpen={isOpen}
      title={title}
      isPending={isSubmitting}
      isSubmitDisabled={!isValid || !dirty}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      hideActions={status?.success}
      submitButtonText="submit"
      disableClickAway
    >
      {!status?.success && (
        <>
          <Typography>On a scale of 1-5 stars (5 being the best),</Typography>
          <StyledTypography>
            How easy is it to find what you're looking for on this page?
          </StyledTypography>
          <StyledDiv>
            <Rating isDisabled={isSubmitting} onChangeRating={updateRating} />
          </StyledDiv>
          <StyledTypography>
            How can we improve your experience?
          </StyledTypography>

          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputField
                  required
                  multiline
                  enableOnChangeValidation
                  rows="4"
                  maxCharacters={1000}
                  name="description"
                  disabled={isSubmitting}
                />
              </Grid>
            </Grid>
          </Form>
        </>
      )}
    </DialogContainer>
  )
}

export const SurveyDialog = withFormik<ComponentProps, FormValues>({
  mapPropsToValues: (props) => {
    return {
      rating: 0,
      description: '',
      email: props.userEmail,
    }
  },
  handleSubmit: async (values, { props, ...actions }) => {
    if (!props.sellerId || !props.vmmId || !props.sellerDisplayName) {
      return
    }

    const { rating, description, email } = values
    const { sellerDisplayName, sellerId, vmmId } = props

    const data = {
      summary: `${surveyConfig.featureName} survey from`,
      sellerId,
      description,
      email,
      vmmId,
      partnerName: sellerDisplayName,
      jiraField: JiraFields.RATING_FIELD,
      jiraFieldValue: rating,
      environment: surveyConfig.jiraEnvironment,
    }

    await createJiraIssue(data)

    trackCustomEvent(
      FireflyEvent.SURVEY_FEEDBACK,
      'survey',
      'submit',
      surveyConfig.featureName,
    )

    setItem(LOCAL_STORAGE_SURVEY_COMPLETED_REPORTS, 'true')

    setTimeout(() => {
      actions.setStatus({ success: true })
    }, 2000)
  },
  enableReinitialize: true,
  validationSchema,
})(SurveyForm)

const mapStateToProps = (state: StoreState) => {
  const sellerId = currentSellerId(state)
  const sellerDisplayName = currentSellerDisplayName(state)
  const vmmId = currentSellerVmmId(state)
  const userEmail = email(state)

  return {
    sellerId,
    sellerDisplayName,
    vmmId,
    userEmail,
  }
}

const mapDispatchToProps = {
  closeDialog,
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyDialog)
