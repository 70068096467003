import { memo, startTransition } from 'react'
import { Drawer } from '@enterprise-ui/canvas-ui-react'
import { useStateValue } from 'stateManager/StateProvider'
import { BRAND, ITEM_SUBTYPE, ITEM_TYPE } from 'types/Attribute'
import ItemTabs, { ItemTabsEnum } from './ItemTabs'
import RadioGroup from 'components/common/RadioGroup/RadioGroup'
import UpdateItemListings from './UpdateItemListings'
import BulkItemUpload from './BulkItemUpload'

interface DrawerContainerProps {
  isEditDrawerVisible: boolean
  onRequestClose: () => void
  setLegalizations?: () => void
}

const DrawerContainer = ({
  isEditDrawerVisible,
  onRequestClose,
  setLegalizations,
}: DrawerContainerProps) => {
  const { updateState, state } = useStateValue()

  const getItemType = (type: string) => {
    switch (type) {
      case BRAND:
        return 'Brands'
      case ITEM_TYPE:
        return 'Item Types'
      case ITEM_SUBTYPE:
        return 'Product Subtypes'
    }
  }

  const handleChange = (value: string) => {
    updateState({
      type: value,
      renderIndividualConfig: false,
      tabName: ItemTabsEnum.INDIVIDUAL,
    })
    startTransition(() => {
      updateState({ renderIndividualConfig: true })
    })
  }

  return (
    <Drawer
      isVisible={isEditDrawerVisible}
      headingText={
        state.isEdit
          ? `Edit ${getItemType(state.type)}`
          : `Add ${getItemType(state.type)}`
      }
      onRequestClose={onRequestClose}
      xs={3}
    >
      <div>
        {!state.isEdit && (
          <>
            <div className="hc-bg-grey07 hc-pl-dense">
              <RadioGroup
                defaultValue={state.type ?? ''}
                id="item-type-options"
                name=""
                value={state.type ?? ''}
                onChange={handleChange}
                row
                options={[
                  { label: getItemType(ITEM_TYPE) ?? '', value: ITEM_TYPE },
                  {
                    label: getItemType(ITEM_SUBTYPE) ?? '',
                    value: ITEM_SUBTYPE,
                  },
                  { label: getItemType(BRAND) ?? '', value: BRAND },
                ]}
              />
            </div>
            <ItemTabs />
          </>
        )}
        {state.renderIndividualConfig &&
          state.tabName === ItemTabsEnum.INDIVIDUAL && (
            <UpdateItemListings
              setLegalizations={setLegalizations}
              onDrawerClose={onRequestClose}
              type={state.type}
            />
          )}
        {state.tabName === ItemTabsEnum.Bulk && (
          <BulkItemUpload onRequestClose={onRequestClose} />
        )}
      </div>
    </Drawer>
  )
}

export default memo(DrawerContainer)
