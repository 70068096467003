import styled from '@emotion/styled'
import CircularProgress from '@mui/material/CircularProgress'

interface Props {
  isOpen: boolean
}

const StyledRoot = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: 'auto',
  zIndex: theme.zIndex.modal,
  background: 'rgba(255, 255, 255, 0.8)',
}))

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  margin: 'auto',
  position: 'relative',
  display: 'block',
  color: theme.palette.grey[500],
  top: 'calc(50% - 4em)', // center spinner on parent
}))

const FullScreenLoader = ({ isOpen }: Props) => {
  if (!isOpen) return null

  return (
    <StyledRoot data-testid="fullscreen-loader">
      <StyledCircularProgress size={100} />
    </StyledRoot>
  )
}

export default FullScreenLoader
