import React from 'react'
import { useSelector } from 'react-redux'

import { Link } from 'react-router-dom'

import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import { SvgIconProps } from '@mui/material/SvgIcon'

import styled from '@emotion/styled'
import { grey, success, target, white } from 'config/themeConfig'

import { RoutePath } from 'services/NavigationHelper'
import { createQueryString } from 'services/urlHelper'

import { getIsSmallScreenSize } from 'store/selectors'

import { SideNav as CanvasSideNav } from '@enterprise-ui/canvas-ui-react'

const StyledContainer = styled('div')({
  margin: 0,
  cursor: 'pointer',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  outline: 'none',
  userSelect: 'none',
  textDecoration: 'none',
  width: '100%',
  '&:hover': {
    textDecoration: 'none',
  },
})

const StyledLink = styled(Link)({
  margin: 0,
  cursor: 'pointer',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  outline: 'none',
  userSelect: 'none',
  textDecoration: 'none',
  width: '100%',
  '&:hover': {
    textDecoration: 'none',
  },
})

const StyledChip = styled(Chip)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  backgroundColor: success.main,
}))

type StyledProps = {
  isActive?: boolean
  iconRotateLeft?: boolean
}

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<StyledProps>((props) => ({
  paddingLeft: props.theme.spacing(2),
  paddingRight: 0,
  fontSize: '1rem',
  letterSpacing: '0.00983em',
  lineHeight: '1.75',
  '&>sup': {
    fontSize: '0.75rem',
  },
  color: props.isActive ? white.main : grey[300],
}))

const StyledListItemButton = styled(CanvasSideNav.NavigationItem, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<StyledProps>((props) => ({
  padding: '4px 4px 4px 16px !important',
  margin: props.theme.spacing(0.25, 0.5),
  '&:hover': {
    color: grey[300],
    backgroundColor: '#C7000070 !important',
    textDecoration: 'none',
    borderRadius: '4px',
  },
  backgroundColor: props.isActive ? `${target.main} !important` : undefined,
  borderRadius: props.isActive ? 4 : undefined,
  '& svg': {
    fill: props.isActive ? `${white.main} !important` : undefined,
    width: '20px',
    height: '20px',
  },
  '& span': {
    width: '20px',
    height: '20px',
  },
  '&:focus': {
    backgroundColor: props.isActive ? `${target.main} !important` : undefined,
    fontWeight: '500',
  },
}))

export interface Props {
  selectedItem: RoutePath
  iconComponent?: React.ComponentType<SvgIconProps>
  iconRotateLeft?: any
  sellerId?: string
  to?: RoutePath
  queryParams?: Dictionary<any>
  text: string
  beta?: boolean
  newFlag?: boolean
  show?: boolean
  isLink?: boolean
  id?: string
  onClick?: () => void
  closeSideNav: () => void
}

export const SideNavItem = (props: Props) => {
  const {
    selectedItem,
    to,
    queryParams,
    text,
    iconComponent: IconComponent,
    iconRotateLeft,
    sellerId,
    beta = false,
    newFlag = false,
    show = true,
    isLink = true,
    id,
    onClick,
    closeSideNav,
  } = props

  const isSmallScreenSize = useSelector(getIsSmallScreenSize)

  if (!show) return null

  const isActive = to?.split('?')[0] === selectedItem
  let link = sellerId ? `/${sellerId}${to}` : to

  if (queryParams) {
    link = `${link}?${createQueryString(queryParams)}`
  }

  const handleClick = () => {
    if (isSmallScreenSize) {
      closeSideNav()
    }
    if (onClick) {
      onClick()
    }
  }

  if (onClick && !isLink) {
    return (
      <StyledListItemButton id={id} isActive={isActive} onClick={onClick}>
        {IconComponent && (
          <IconComponent
            sx={{
              fill: grey[300],
              transform: iconRotateLeft ? 'rotate(270deg)' : undefined,
            }}
          />
        )}
        <StyledTypography isActive={isActive}>{text}</StyledTypography>
      </StyledListItemButton>
    )
  }
  return isLink && link ? (
    <StyledLink
      data-testid={`side-nav-link-${to?.slice(1)}`}
      to={link}
      onClick={handleClick}
    >
      <StyledListItemButton isActive={isActive}>
        {IconComponent && (
          <IconComponent
            sx={{
              fill: grey[300],
              transform: iconRotateLeft ? 'rotate(270deg)' : undefined,
            }}
          />
        )}
        <StyledTypography isActive={isActive}>
          {text}
          {beta && (
            <StyledTypography display="inline" isActive={isActive}>
              beta
            </StyledTypography>
          )}
        </StyledTypography>
        {newFlag && <StyledChip label="NEW" color="primary" />}
      </StyledListItemButton>
    </StyledLink>
  ) : (
    <StyledContainer>
      <StyledListItemButton id={id} isActive={isActive}>
        {IconComponent && (
          <IconComponent
            sx={{
              fill: grey[300],
              transform: iconRotateLeft ? 'rotate(270deg)' : undefined,
            }}
          />
        )}
        <StyledTypography isActive={isActive}>{text}</StyledTypography>
      </StyledListItemButton>
    </StyledContainer>
  )
}

export default SideNavItem
