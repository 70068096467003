import { configureStore } from '@reduxjs/toolkit'
import { Store } from 'redux'
import createSagaMiddleware from 'redux-saga'

import rootReducer from './rootReducer'
import { initSagas } from './initSagas'

let store: Store
export const initStore = () => {
  const sagaMiddleware = createSagaMiddleware()

  store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        thunk: false,
        serializableCheck: {
          ignoredActions: ['OPEN_DIALOG', 'STORE_CASE_ATTACHMENTS'],
          ignoredPaths: [
            'dialog.closeCallback',
            'dialog.componentProps.handleClose',
            'payload.files.Screen',
          ],
        },
      }).prepend(sagaMiddleware)
    },
    devTools: process.env.NODE_ENV === 'development' ? true : false,
  })

  initSagas(sagaMiddleware)

  return store
}

export const getStore = () => store
