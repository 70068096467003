export const getOptionalToggleValue = (value: string | undefined) => {
  let toggleValue

  if (value === 'true') {
    toggleValue = true
  } else if (value === 'false') {
    toggleValue = false
  }
  return toggleValue
}

export const getOptionalPublishedToggleValue = (value: string | undefined) => {
  let toggleValue

  if (typeof value === 'string') {
    if (value.toUpperCase() === 'YES') {
      toggleValue = true
    } else if (value.toUpperCase() === 'NO') {
      toggleValue = false
    }
  }

  return toggleValue
}

export const getOptionalReturnedOnlineToggleValue = (
  value: string | undefined,
) => {
  let toggleValue

  if (typeof value === 'string') {
    if (value.toUpperCase() === 'ONLINE') {
      toggleValue = true
    } else if (value.toUpperCase() === 'IN STORE') {
      toggleValue = false
    }
  }

  return toggleValue
}
