import axios from 'axios'

import apiConfig from 'config/apiConfig'

import {
  getItemTypesCache,
  getItemTypeIdGroupsFromCache,
  updateItemTypesCache,
} from './cacheHelper'
import { getPageable, PagingParams } from './pageableHelper'

import Attribute, { BRAND } from 'types/Attribute'
import {
  BrandSearchResult,
  BrandSearchParams,
  Brand,
  VmmBrand,
} from 'types/Brand'
import { SingleResponse } from 'types/Response'

let cancel: any

export function getBrandAttribute(brandId: string): Promise<Attribute> {
  return axios
    .get(`${apiConfig.businessPartnerBrands}/${brandId}`)
    .then((res: SingleResponse<{ brand: Brand[] }>) => {
      let brandAttribute: Attribute = {
        type: BRAND,
        id: brandId,
        name: brandId,
      }

      if (res.data.brand && res.data.brand.length) {
        const brand = res.data.brand[0]

        brandAttribute = {
          type: BRAND,
          id: brandId,
          name: brand.brandName,
          description: brand.brandDescription,
        }
      }

      return brandAttribute
    })
}

export function getBrand(brandId: number): Promise<Brand | undefined> {
  return axios
    .get(`${apiConfig.businessPartnerBrands}/${brandId}`)
    .then((res: SingleResponse<{ brand: [Brand] }>) => {
      if (res.data.brand && res.data.brand.length) {
        return res.data.brand[0]
      }
    })
    .catch(() => {
      return undefined
    })
}

export async function getBrandAttributes(ids: string[]): Promise<Attribute[]> {
  return await Promise.all(ids.map(async (id) => getBrandAttribute(id)))
}

export async function getBrandAttributesAndCacheResults(
  brandIds: string[],
): Promise<Attribute[]> {
  const result = await Promise.all(brandIds.map((id) => getBrandAttribute(id)))

  updateItemTypesCache(result)

  return result
}

export async function getBrandAttributesFromCache(
  ids: string[],
): Promise<Attribute[]> {
  const itemTypesCache = getItemTypesCache()
  const { cachedIds, uncachedIds } = getItemTypeIdGroupsFromCache(
    ids,
    itemTypesCache,
  )

  const promises =
    uncachedIds?.length > 0
      ? getBrandAttributesAndCacheResults(uncachedIds)
      : Promise.resolve([])

  const cached =
    cachedIds?.length > 0
      ? Promise.resolve(
          itemTypesCache.filter((type) => cachedIds.includes(type.id)),
        )
      : Promise.resolve([])

  const result = await Promise.all([cached, promises])

  return result.flat()
}

export async function findBrands(
  pagingParams: PagingParams,
  searchParams: BrandSearchParams,
  doCancel: boolean = false,
): Promise<Brand[]> {
  if (doCancel && cancel) cancel()

  const pageable = getPageable(pagingParams)
  const cancelToken = doCancel
    ? new axios.CancelToken((c) => {
        cancel = c
      })
    : undefined

  const config = {
    params: { ...searchParams, ...pageable },
    cancelToken,
  }

  return await axios
    .get(`${apiConfig.businessPartnerBrands}/search`, config)
    .then((res: BrandSearchResult) =>
      res.data.brand.map(
        (brand) =>
          ({
            brandId: brand.brandId.toString(),
            brandName: brand.brandName,
            brandDescription: brand.brandDescription,
          }) as Brand,
      ),
    )
}

export async function createVmmBrand({
  name,
  abbreviation,
  description,
  userId,
  isProperty,
  isPersonality,
}: VmmBrand) {
  return await axios.post(`${apiConfig.brands}/brands`, {
    brand_name: name,
    brand_abbreviation: abbreviation,
    brand_description: description,
    create_user: userId,
    is_property_flag: isProperty,
    is_personality_flag: isPersonality,
  })
}
