import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import Chip from '@mui/material/Chip'

import HeaderTitle from 'components/common/HeaderTitle'
import TableSpacer from 'components/common/TableSpacer'
import TitleBar from 'components/common/TitleBar'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import { formatDateMDYT } from 'components/common/EnhancedTable/formatters'
import {
  getEnhancedTablePageableProps,
  TableState,
  useSearchParams,
} from 'components/common/FilterBar/useSearchParams'
import Text from 'components/common/Text'
import { DialogEnum } from 'components/common/Dialog'

import { getNotifications, deleteNotification } from 'services/notifications'
import { isBetweenDates, isAfterDate } from 'services/dateService'
import { Direction } from 'services/pageableHelper'
import { USER_ROLE_APP_SMS_ADMIN, USER_ROLE_APP_SMS_READ } from 'services/roles'

import { openDialog, closeDialog } from 'store/dialog/actionCreator'

import { success, warning, grey } from 'config/themeConfig'

import { Notification, NotificationDisplayOn } from 'types/Notifications'

import { DisplayFor } from './Dialogs/EditNotification'

const fieldList: EnhancedTableFieldType<Notification>[] = [
  {
    key: 'title',
    heading: 'Title & Message',
    formatCell: (rowItem) => {
      return (
        <>
          <Text type="bold">{rowItem.title}</Text>
          <Text>{rowItem.message}</Text>
        </>
      )
    },
  },
  {
    key: 'priority',
    heading: 'Priority',
  },
  {
    key: 'display_on',
    heading: 'Display Location',
    formatCell: (rowItem) => {
      if (
        rowItem.display_on?.includes(NotificationDisplayOn.HOMEPAGE) &&
        rowItem.display_on.includes(NotificationDisplayOn.PARTNER_DASHBOARD)
      ) {
        return 'Internal Homepage and Partner Dashboard'
      } else if (
        rowItem.display_on?.includes(NotificationDisplayOn.HOMEPAGE) &&
        !rowItem.display_on.includes(NotificationDisplayOn.PARTNER_DASHBOARD)
      ) {
        return 'Internal Homepage'
      } else if (
        !rowItem.display_on?.includes(NotificationDisplayOn.HOMEPAGE) &&
        rowItem.display_on?.includes(NotificationDisplayOn.PARTNER_DASHBOARD)
      ) {
        return 'Partner Dashboard'
      }
    },
  },
  {
    key: 'display_for',
    heading: 'Displays For',
    formatCell: (rowItem) => {
      if (
        rowItem.display_for.includes(USER_ROLE_APP_SMS_ADMIN) &&
        rowItem.display_for.includes(USER_ROLE_APP_SMS_READ) &&
        rowItem.display_for.includes(DisplayFor.INTERNAL)
      ) {
        return 'Partner Admins, Read Only & Target TMs'
      } else if (
        rowItem.display_for.includes(USER_ROLE_APP_SMS_ADMIN) &&
        !rowItem.display_for.includes(USER_ROLE_APP_SMS_READ) &&
        rowItem.display_for.includes(DisplayFor.INTERNAL)
      ) {
        return 'Partner Admins & Target TMs'
      } else if (
        !rowItem.display_for.includes(USER_ROLE_APP_SMS_ADMIN) &&
        !rowItem.display_for.includes(USER_ROLE_APP_SMS_READ) &&
        rowItem.display_for.includes(DisplayFor.INTERNAL)
      ) {
        return 'Only Target TMs'
      }
    },
  },
  {
    key: 'start_date',
    heading: 'Start Date',
    formatCell: formatDateMDYT('start_date'),
  },
  {
    key: 'end_date',
    heading: 'End Date',
    formatCell: formatDateMDYT('end_date'),
  },
  {
    key: '',
    heading: 'Status',
    formatCell: (rowItem) => {
      const localStartDate = new Date(rowItem.start_date)
      const localEndDate = new Date(rowItem.end_date)

      let bgColor = grey['300']
      let label = 'PAST'

      if (isBetweenDates(new Date(), localStartDate, localEndDate)) {
        label = 'LIVE'
        bgColor = success.light
      } else if (isAfterDate(localStartDate, new Date())) {
        label = 'FUTURE'
        bgColor = warning.light
      }

      return (
        <Chip
          label={label}
          style={{
            width: '100%',
            backgroundColor: bgColor,
          }}
        />
      )
    },
  },
  {
    key: 'last_modified',
    heading: 'Last Modified',
    formatCell: formatDateMDYT('last_modified'),
  },
]

const initialSearchParams: TableState = {
  perPage: 20,
  page: 0,
  orderBy: 'last_modified',
  direction: Direction.DESC,
}

export const AlertManagementPage = () => {
  const reduxDispatch = useDispatch()

  const [searchParams, searchParamActions] =
    useSearchParams<TableState>(initialSearchParams)

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Notification[]>([])
  const [total, setTotal] = useState(0)
  const [refreshCount, setRefreshCount] = useState(0)

  useEffect(() => {
    let mounted = true

    const fetchData = async () => {
      setLoading(true)

      const results = await getNotifications({
        page: searchParams.page,
        perPage: searchParams.perPage,
        orderBy: searchParams.orderBy,
        direction: searchParams.direction,
      })

      if (mounted) {
        setData(results.data)
        setTotal(results.total)
        setLoading(false)
      }
    }

    fetchData()
    return () => {
      mounted = false
    }
  }, [
    refreshCount,
    searchParams.page,
    searchParams.perPage,
    searchParams.orderBy,
    searchParams.direction,
  ])

  // Helper to reload the data without changing pagination state
  const refreshData = () => {
    setRefreshCount((prev: number) => prev + 1)
  }

  const handleCloseDialog = () => {
    reduxDispatch(closeDialog())
  }

  const handleUpdateNotification = (notification?: Notification) => {
    reduxDispatch(
      openDialog({
        dialogEnum: DialogEnum.EDIT_NOTIFICATION,
        componentProps: {
          handleCloseDialog,
          notification,
        },
        closeCallback: refreshData,
      }),
    )
  }

  const handlePreviewNotification = (notification: Notification) => {
    reduxDispatch(
      openDialog({
        dialogEnum: DialogEnum.PREVIEW_NOTIFICATION,
        componentProps: {
          notification,
        },
      }),
    )
  }

  const handleDeleteNotification = async (element: Notification) => {
    await deleteNotification(element.id)
    handleCloseDialog()
  }

  const tableActions = [
    {
      label: 'Edit Details',
      callback: (element: Notification) => () => {
        handleUpdateNotification(element)
      },
    },
    {
      label: 'Preview',
      callback: (element: Notification) => () => {
        handlePreviewNotification(element)
      },
    },
    {
      label: 'Delete',
      callback: (element: Notification) => () => {
        reduxDispatch(
          openDialog({
            dialogEnum: DialogEnum.CONFIRMATION_DIALOG,
            componentProps: {
              title: 'Delete Banner Message',
              submitText: 'Delete alert',
              element,
              content:
                'Are you sure you want to delete this? This will remove the banner message from all partner dashboards.',
              onRequestSubmit: handleDeleteNotification,
            },
            closeCallback: refreshData,
          }),
        )
      },
    },
  ]

  return (
    <div data-testid="alert-management-page">
      <HeaderTitle title="Alert Management Page" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitleBar
            title="Dashboard Banner"
            actionButtons={[
              <Button
                key="add-seller-button"
                data-testid="add-seller-button"
                onClick={() => handleUpdateNotification()}
                color="primary"
                variant="contained"
              >
                <AddIcon />
                Add New
              </Button>,
            ]}
          />
          <TableSpacer>
            <EnhancedTable
              data={data}
              total={total}
              fieldList={fieldList}
              isLoading={loading}
              actions={tableActions}
              {...getEnhancedTablePageableProps(
                searchParams,
                searchParamActions,
              )}
            />
          </TableSpacer>
        </Grid>
      </Grid>
    </div>
  )
}

export default AlertManagementPage
