import { useDispatch } from 'react-redux'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import { useUserSettingsContext } from 'components/context/UserSettingsProvider'

import { useUpdateSavedSearchMutation } from 'hooks/useUserSettingsQuery'
import { openDialog } from 'store/dialog/actionCreator'
import { DialogEnum } from '.'

interface Props {
  isOpen: boolean
  handleClose: () => void
  page: string
  savedSearchName: string
  savedSearchKey: string
}

export const DeleteSavedSearchDialog = ({
  isOpen,
  page,
  savedSearchName,
  savedSearchKey,
}: Props) => {
  const dispatch = useDispatch()
  const { updateSavedSearches } = useUpdateSavedSearchMutation()
  const { userSettings, updateSelectedSavedSearch, selectedSavedSearches } =
    useUserSettingsContext()

  const openManagedSavedSearchDialog = (isDelete = false) => {
    dispatch(
      openDialog({
        dialogEnum: DialogEnum.MANAGE_SAVED_SEARCH_DIALOG,
        componentProps: {
          page,
          message: isDelete ? `"${savedSearchName}" has been deleted.` : '',
        },
      }),
    )
  }

  const handleDelete = () => {
    if (
      selectedSavedSearches[page]?.saved_search_name ===
      window.btoa(savedSearchName)
    ) {
      updateSelectedSavedSearch({}, page)
    }

    const updatedSettings = {
      ...userSettings.data,
    }
    delete updatedSettings.settings[`${page}_saved_searches`][savedSearchKey]
    updateSavedSearches({ userSettings: updatedSettings })
    openManagedSavedSearchDialog(true)
  }

  return (
    <DialogContainer
      title="Delete Saved Filters"
      subtitle={`Are you sure you want to delete "${savedSearchName}"?`}
      isOpen={isOpen}
      onSubmit={handleDelete}
      closeOverride={openManagedSavedSearchDialog}
      submitButtonText="YES, DELETE"
      closeButtonText="NO, CANCEL"
      flexboxRight={2}
    />
  )
}

export default DeleteSavedSearchDialog
