import Typeahead from '.'

import { useState, useEffect, SyntheticEvent } from 'react'
import usePrevious from 'hooks/usePrevious'

import { MerchTypeAttribute } from 'types/MerchTypeAttribute'
import { searchMerchTypeAttributes } from 'services/merchTypeAttributes'

import flow from 'lodash/fp/flow'
import orderBy from 'lodash/fp/orderBy'
import slice from 'lodash/fp/slice'
import uniqBy from 'lodash/fp/uniqBy'

import { Direction } from 'services/pageableHelper'

export interface Props {
  value?: Nullable<MerchTypeAttribute>
  placeholder?: string
  isDisabled?: boolean
  clearOnSelect?: boolean
  onChange: (value: MerchTypeAttribute, enteredValue: string) => void
}

const MerchTypeAttributeTypeahead = ({
  value: valueFromProps,
  placeholder = 'Search Merch Type Attributes',
  isDisabled = false,
  clearOnSelect = false,
  onChange,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedValue, setSelectedValue] =
    useState<Nullable<MerchTypeAttribute>>(valueFromProps)
  const [options, setOptions] = useState<MerchTypeAttribute[]>([])
  const [loading, setLoading] = useState(false)

  const prevValue = usePrevious(valueFromProps)

  useEffect(() => {
    if (prevValue !== valueFromProps) {
      setSelectedValue(valueFromProps)
    }

    if (!valueFromProps) {
      setOptions([])
    }
  }, [prevValue, valueFromProps])

  useEffect(() => {
    if (options.length && !inputValue) {
      setOptions([])
    }
  }, [inputValue, options])

  const orderSortMerchTypeAttributes: (
    list: MerchTypeAttribute[],
  ) => MerchTypeAttribute[] = flow(
    orderBy(
      (attr: MerchTypeAttribute) => attr.name.toUpperCase(),
      Direction.ASC,
    ),
    uniqBy('name'),
    slice(0, 10),
  )

  const handleInputChange = async (
    _event: SyntheticEvent,
    enteredValue: string,
    reason: string,
  ) => {
    if (
      reason === 'clear' ||
      (selectedValue && reason === 'input' && enteredValue === '')
    ) {
      setOptions([])
      setInputValue('')
      setSelectedValue(null)
      return
    }

    setInputValue(enteredValue)
    const invalidInputValue =
      !enteredValue || enteredValue.length < 3 || reason === 'reset'
    if (invalidInputValue) {
      return
    }

    setLoading(true)
    const containsMerchTypeAttributes = await searchMerchTypeAttributes({
      name: enteredValue,
      searchType: 'CONTAINS',
      page: 0,
      perPage: 10,
    })

    const startsWithMerchTypeAttributes = await searchMerchTypeAttributes({
      name: enteredValue,
      searchType: 'STARTS_WITH',
      page: 0,
      perPage: 10,
    })

    const data = orderSortMerchTypeAttributes([
      ...containsMerchTypeAttributes,
      ...startsWithMerchTypeAttributes,
    ])

    setOptions(data)
    setLoading(false)
  }

  const handleSelectedOptionChange = (
    _event: SyntheticEvent,
    value: MerchTypeAttribute,
  ) => {
    if (!(value instanceof Array)) {
      const searchParamValue = getOptionLabel(value as MerchTypeAttribute)
      setSelectedValue(
        clearOnSelect ? null : (value as Nullable<MerchTypeAttribute>),
      )
      onChange(value as MerchTypeAttribute, searchParamValue)
    }
  }

  const getOptionLabel = (attribute: MerchTypeAttribute) =>
    attribute?.name ?? ''

  return (
    <Typeahead
      aria-label="Merch Type Attribute Typeahead"
      placeholder={placeholder}
      getOptionLabel={getOptionLabel}
      options={options}
      loading={loading}
      disabled={isDisabled}
      onInputChange={handleInputChange}
      onChange={handleSelectedOptionChange}
      inputValue={inputValue}
      value={selectedValue}
      isOptionEqualToValue={(
        option: MerchTypeAttribute,
        value: Nullable<MerchTypeAttribute>,
      ) => option.name === value?.name}
    />
  )
}

export default MerchTypeAttributeTypeahead
