import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AddressInput from 'components/common/AddressInput'
import DialogContainer from 'components/common/Dialog/DialogContainer'

import {
  getAddressValidationHandler,
  hydrateAddress,
  hydrateSmsAddress,
} from 'services/addressHelper'

import { isSellersPending } from 'store/selectors'
import { editSellerDistributionCenter } from 'store/seller/actionCreators'

import { Address } from 'types/Address'
import { SmsSeller, SmsDistributionCenter } from 'types/Seller'
import { Validation } from 'types/Validation'

interface Props {
  isOpen: boolean
  seller: SmsSeller
  distributionCenter: Partial<SmsDistributionCenter>
}

const EditShippingAddress = ({ distributionCenter, isOpen }: Props) => {
  const [address, setAddress] = React.useState<Address>(
    hydrateAddress(distributionCenter?.address ?? {}),
  )
  const [validation, setValidation] = React.useState<Validation>({})

  const dispatch = useDispatch()

  const isPending = useSelector(isSellersPending)

  const handleInputChange = (value: any, property: string) => {
    setAddress((prev) => ({
      ...prev,
      [property]: value,
    }))
  }

  const handleSubmit = () => {
    const { validation: formValidation, isValid } =
      getAddressValidationHandler(address)

    setValidation(formValidation)

    if (isValid) {
      const updatedDistributionCenter = {
        ...distributionCenter,
        name: address.city,
        address: hydrateSmsAddress(address),
      }
      dispatch(editSellerDistributionCenter(updatedDistributionCenter))
    }
  }

  return (
    <DialogContainer
      title="Edit Shipping Address"
      isOpen={isOpen}
      isPending={isPending}
      onSubmit={handleSubmit}
    >
      <AddressInput
        handleInputChange={handleInputChange}
        isPending={isPending}
        address={address}
        validation={validation}
      />
    </DialogContainer>
  )
}

export default EditShippingAddress
