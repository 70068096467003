import axios from 'axios'

import apiConfig from 'config/apiConfig'

import { OrderDefectMetricType } from 'types/VendorStats'
import { Order } from 'types/Orders'

export const createOverride = async (
  sellerId: string,
  type: OrderDefectMetricType,
  order: Order,
) => {
  const data = {
    order_number: order.order_number,
    ship_advice_number: order.ship_advice_number,
    override_type: type,
  }

  const response = await axios.post(
    `${apiConfig.sellerOrders}/sellers/${sellerId}/order_defect_overrides`,
    data,
  )

  return response.data
}
