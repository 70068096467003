import axios from 'axios'

import apiConfig from 'config/apiConfig'

import { PagingParams, getPageable } from './pageableHelper'

import { CollectionResponse } from 'types/Response'
import {
  SellerVerification,
  SellerVerificationAttributeName,
  SellerVerificationRequestedValue,
  SellerVerificationAttributeUpdate,
  SellerVerificationStatus,
  VerificationSearchParams,
} from 'types/SellerVerification'
import { YesNo } from 'types/YesNo'

export const getSellerVerifications = async (
  pagingParams: PagingParams = {
    page: 0,
    perPage: 100,
  },
  searchParams: VerificationSearchParams = {},
): Promise<CollectionResponse<SellerVerification>> => {
  const pageable = getPageable(pagingParams)

  const config = {
    params: { ...pageable, ...searchParams },
  }

  return axios
    .get(`${apiConfig.sms}/seller_verifications`, config)
    .then((res) => {
      const { data, headers } = res
      const total = headers['x-total-count']
        ? parseInt(headers['x-total-count'], 10)
        : 0

      return {
        total,
        data,
      }
    })
}

export const getSellerVerification = async (
  id: string,
): Promise<SellerVerification> => {
  const response = await axios.get(
    `${apiConfig.sms}/sellers/${id}/seller_verification`,
  )

  return response.data
}

export const getSellerVerificationsForIds = async (
  ids: string[],
): Promise<SellerVerification[]> => {
  const data = await Promise.all(ids.map((id) => getSellerVerification(id)))

  return data
}

export const updateSellerVerification = async (
  sellerId: string,
  verificationId: string,
  data: SellerVerificationAttributeUpdate[],
) => {
  const response = await axios.put(
    `${apiConfig.sms}/sellers/${sellerId}/seller_verification/${verificationId}`,
    data,
  )

  return response.data
}

export const updateSellerVerificationStatus = async (
  sellerId: string,
  verificationId: string,
  status: SellerVerificationStatus,
) => {
  const response = await axios.put(
    `${apiConfig.sms}/sellers/${sellerId}/seller_verification/${verificationId}/status`,
    { status },
  )

  return response.status
}

export const buildVerificationAttribute = (
  attributeName: SellerVerificationAttributeName,
  enabled: YesNo,
  requestedValue?: SellerVerificationRequestedValue,
): SellerVerificationAttributeUpdate => {
  if (enabled === YesNo.YES) {
    return {
      attribute_name: attributeName,
    }
  } else {
    return {
      attribute_name: attributeName,
      requested_value: requestedValue,
    }
  }
}
