import { useSelector } from 'react-redux'

import { isOneOfUserRoles } from 'services/authorization'
import {
  USER_ROLE_ADMIN,
  USER_ROLE_DIGITAL_EXP_SUPPORT,
  USER_ROLE_OPS,
} from 'services/roles'

import { getMemberOf } from 'store/selectors'

import { Shipment } from 'types/Orders'

import ShipmentCardHeader from './ShipmentCardHeader'
import OrderItemList from './OrderItemList'

export interface Props {
  title: string
  shipment: Shipment
}

const ShipmentCard = ({ shipment, title }: Props) => {
  const memberOf = useSelector(getMemberOf)
  const canEdit = isOneOfUserRoles(memberOf, [
    USER_ROLE_ADMIN,
    USER_ROLE_DIGITAL_EXP_SUPPORT,
    USER_ROLE_OPS,
  ])

  return (
    <div data-testid="shipment-card">
      <ShipmentCardHeader title={title} canEdit={canEdit} shipment={shipment} />
      <OrderItemList items={shipment.fulfillments} />
    </div>
  )
}

export default ShipmentCard
