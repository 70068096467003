export const SUSPENSION_CODES = [
  {
    reason: 'Item is being recalled',
    error_code: 801,
  },
  {
    reason: "Item may not align with Target's merchandising strategy",
    error_code: 802,
  },
  {
    reason: 'Item is being removed due to quality concerns',
    error_code: 803,
  },
  {
    reason: 'Item is going to be supplied by another source',
    error_code: 804,
  },
  {
    reason: 'Target is no longer going to list this item',
    error_code: 805,
  },
  {
    reason: 'Other reason',
    error_code: 806,
  },
]
