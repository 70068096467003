import { SyntheticEvent, useEffect, useState } from 'react'

import { suggestAddresses } from 'services/addressValidation'

import Typeahead from '.'
import ValidationErrorText from '../ValidationErrorText'

import { SmsAddress } from 'types/Address'
import { Validation } from 'types/Validation'

import usePrevious from 'hooks/usePrevious'

export interface Props {
  onChange: (
    enteredValue: string,
    selectedValue: Nullable<Partial<SmsAddress>>,
  ) => void
  value?: Partial<SmsAddress>
  onClear?: () => void
  validation?: Validation
}

const getAddressSuggestions = async (query: string): Promise<SmsAddress[]> => {
  if (!query || query.length < 5) {
    return Promise.resolve([])
  }
  return suggestAddresses(query)
}

const AddressTypeahead = ({
  onChange,
  value: valueFromProps,
  validation,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedValue, setSelectedValue] =
    useState<Nullable<Partial<SmsAddress>>>(null)
  const [options, setOptions] = useState<Partial<SmsAddress>[]>([])

  const prevValue = usePrevious(valueFromProps)

  useEffect(() => {
    if (prevValue !== valueFromProps) {
      setSelectedValue(valueFromProps)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueFromProps])

  const handleSelectedOptionChange = (
    _event: SyntheticEvent,
    value: SmsAddress,
  ) => {
    if (!(value instanceof Array)) {
      const optionLabel = getOptionLabel(value, true)
      setSelectedValue(value)
      onChange(optionLabel, value as Nullable<SmsAddress>)
    }
  }

  const handleInputChange = async (
    _event: SyntheticEvent,
    enteredValue: string,
    reason: string,
  ) => {
    if (
      reason === 'clear' ||
      (selectedValue && reason === 'input' && enteredValue === '')
    ) {
      setOptions([])
      setInputValue('')
      onChange('', null)
      setSelectedValue(null)
      return
    }

    if (reason === 'reset' && selectedValue) {
      setInputValue(selectedValue?.address1 ?? '')
      return
    }

    setInputValue(enteredValue)
    onChange(enteredValue, null)

    const invalidInputValue = !enteredValue || enteredValue.length < 3
    if (invalidInputValue || reason === 'reset') {
      return
    }

    if (!enteredValue) {
      return Promise.resolve([])
    }

    const data = await getAddressSuggestions(enteredValue)
    setOptions(data)
  }

  const getOptionLabel = (address: SmsAddress, isSelected = false) => {
    if (address?.address1) {
      return isSelected
        ? address.address1
        : `${address.address1}, ${address.city}, ${address.state}, ${address.postal_code}`
    }
    return ''
  }

  return (
    <>
      <Typeahead
        aria-label="Address Line 1"
        label="Address Line 1*"
        getOptionLabel={getOptionLabel}
        onChange={handleSelectedOptionChange}
        onInputChange={handleInputChange}
        value={selectedValue ?? null}
        options={options}
        inputValue={inputValue}
        isOptionEqualToValue={(
          option: SmsAddress,
          value: Nullable<SmsAddress>,
        ) => option.address1 === value?.address1}
        filterOptions={(options: SmsAddress[]) => options}
        variant="standard"
        freeSolo
        disableClearable
        hideStartAdornment
        displayLabel
      />
      {validation && (
        <div data-testid="validation">
          <ValidationErrorText errors={validation} field={'address1'} />
        </div>
      )}
    </>
  )
}

export default AddressTypeahead
