import { useSelector } from 'react-redux'
import { getSellerContacts } from 'store/selectors'

import {
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'

import ContentSpacer from 'components/common/ContentSpacer'
import Select from 'components/common/Select'
import { Input } from 'components/common/Input'
import { AddressTypeahead } from 'components/common/Typeahead'

import { AddressFields, ProgressState } from './EditReturnPolicy'

import styled from '@emotion/styled'
import { grey, success, error } from 'config/themeConfig'

import {
  CATEGORY_FINANCIAL_DISPOSITION,
  CATEGORY_PHYSICAL_DISPOSITION,
  FinancialDisposition,
  PhysicalDisposition,
} from 'constants/categories'
import { ReturnDescriptionAlias } from 'constants/returnPolicies'
import { getLocationsFromCountryCode } from 'constants/locations'
import COUNTRIES from 'constants/countries'

import { DEFAULT_COUNTRY } from 'services/addressHelper'
import { getCategoryCodes } from 'services/codes'

import { Validation } from 'types/Validation'
import { IdLabelPair } from 'types/IdLabelPair'
import SellerUser from 'types/SellerUser'
import { Code, SmsReturnPolicy, VendorCategory } from 'types/Seller'
import { SmsAddress } from 'types/Address'

const StyledProvidedAddressRadio = styled(FormControlLabel)(({ theme }) => ({
  color: error.main,
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
}))

const StyledValidatedAddressRadio = styled(FormControlLabel)(({ theme }) => ({
  color: success.main,
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
}))

const StyledFieldsBackDrop = styled(Paper)(({ theme }) => ({
  backgroundColor: grey[100],
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
}))

const StyledInput = styled(Input)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
}))

const StyledSelect = styled(Select)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
}))

const StyledDiv = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  '.MuiAutocomplete-root': {
    backgroundColor: 'transparent',
  },
}))

const StyledTypography = styled(Typography)({ fontWeight: 'bold' })

const StyledErrorTypography = styled(Typography)({
  fontWeight: 'bold',
  color: error.main,
})

export interface Props {
  progress: ProgressState
  returnPolicy: Partial<SmsReturnPolicy>
  validatedAddresses: SmsAddress[]
  selectedAddressIndex: number
  handleRadioSelect: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleInputChange: (value: any, property: string) => void
  isExternalAdmin: boolean
  vendorCategories: VendorCategory[]
  validation: Validation
}

export const EditReturnPolicyDialogContent = ({
  progress,
  returnPolicy,
  validatedAddresses,
  selectedAddressIndex,
  handleRadioSelect,
  handleInputChange,
  isExternalAdmin,
  vendorCategories,
  validation,
}: Props) => {
  const isDispositionsDisabled = !!returnPolicy.id

  const financialDispositionCodes = getCategoryCodes(
    vendorCategories,
    CATEGORY_FINANCIAL_DISPOSITION,
  )

  const physicalDispositionCodes = getCategoryCodes(
    vendorCategories,
    CATEGORY_PHYSICAL_DISPOSITION,
  )

  const contacts = useSelector(getSellerContacts)

  const sellerContacts: IdLabelPair[] =
    contacts?.map((user: SellerUser) => ({
      id: user.id,
      label: `${user.last_name}, ${user.first_name}`,
    })) ?? []

  const addressRadioLabel = (address: any, validated: boolean) => {
    return (
      <>
        <StyledTypography sx={{ color: 'black' }}>
          {validated
            ? 'Use this verified address:'
            : 'Use the address you entered:'}
        </StyledTypography>
        <StyledTypography>{address.address1}</StyledTypography>
        <StyledTypography>{address.address2}</StyledTypography>
        <StyledTypography>{`${address.city}, ${address.state} ${address.postal_code}`}</StyledTypography>
        <StyledTypography>{address.country_code}</StyledTypography>
      </>
    )
  }

  const handleAddressLine1Change = (
    enteredValue: string,
    selectedValue: Nullable<Partial<SmsAddress>>,
  ) => {
    if (selectedValue) {
      handleInputChange(selectedValue.address1, AddressFields.ADDRESS_1)
      handleInputChange(selectedValue.address2, AddressFields.ADDRESS_2)
      handleInputChange(selectedValue.city, AddressFields.CITY)
      handleInputChange(selectedValue.state, AddressFields.STATE)
      handleInputChange(selectedValue.postal_code, AddressFields.ZIP)
      handleInputChange(selectedValue.country_code, DEFAULT_COUNTRY)
    } else {
      handleInputChange(enteredValue, AddressFields.ADDRESS_1)
    }
    return
  }

  const handleAddressLine1OnClear = () => {
    handleInputChange('', AddressFields.ADDRESS_1)
    handleInputChange('', AddressFields.ADDRESS_2)
    handleInputChange('', AddressFields.CITY)
    handleInputChange('', AddressFields.STATE)
    handleInputChange('', AddressFields.ZIP)
  }

  return (
    <>
      {progress === ProgressState.VALIDATE && (
        <Grid container>
          <Typography>
            The address you entered could not be verified. Using a verified
            address helps prevent delays in shipping. Select from the addresses
            below or go back to edit your originally entered address.
          </Typography>

          <FormControl component="fieldset" fullWidth>
            <RadioGroup
              name="address"
              value={selectedAddressIndex}
              onChange={handleRadioSelect}
            >
              <StyledProvidedAddressRadio
                value={0}
                control={<Radio />}
                label={addressRadioLabel(returnPolicy.return_address, false)}
              />
              {validatedAddresses.map((validatedAddress, index) => (
                <StyledValidatedAddressRadio
                  key={index + 1}
                  value={index + 1}
                  control={<Radio />}
                  label={addressRadioLabel(validatedAddress, true)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
      )}

      {progress === ProgressState.IDLE && (
        <Grid container spacing={2}>
          <Grid item xs={12} data-testid="financial-disposition">
            <Select
              data-testid="financial-disposition"
              id="financial-disposition"
              label="Financial Disposition"
              name="financial_disposition_id"
              isRequired
              isDisabled={isDispositionsDisabled || isExternalAdmin}
              optionDisableCallback={(item: Code) =>
                returnPolicy.physical_disposition_id ===
                  PhysicalDisposition.ReturnViaCrc &&
                item.code_description ===
                  ReturnDescriptionAlias.TARGET_WRITE_OFF
              }
              options={financialDispositionCodes}
              keyName="code_description"
              valueName="code_id"
              onChange={handleInputChange}
              value={returnPolicy.financial_disposition_id}
              validation={validation}
            />
          </Grid>
          <Grid item xs={12} data-testid="physical-disposition">
            <Select
              data-testid="physicalDispositionSelector"
              id="physical-disposition"
              label="Physical Disposition"
              name="physical_disposition_id"
              isRequired
              isDisabled={isDispositionsDisabled || isExternalAdmin}
              optionDisableCallback={(item: Code) =>
                (!returnPolicy.id &&
                  item.code_description === 'Return to Seller') ||
                (returnPolicy.financial_disposition_id ===
                  FinancialDisposition.WriteOff &&
                  item.code_description ===
                    ReturnDescriptionAlias.RETURN_TO_PARTNER_CRC)
              }
              options={physicalDispositionCodes}
              keyName="code_description"
              valueName="code_id"
              onChange={handleInputChange}
              value={returnPolicy.physical_disposition_id}
              validation={validation}
            />
          </Grid>
          {returnPolicy.physical_disposition_id &&
            returnPolicy.physical_disposition_id !==
              PhysicalDisposition.ReturnViaCrc && (
              <Grid item xs={12}>
                <StyledFieldsBackDrop elevation={0}>
                  Note: Partner return address is not required for this return
                  policy.
                </StyledFieldsBackDrop>
              </Grid>
            )}
          {returnPolicy.physical_disposition_id &&
            returnPolicy.physical_disposition_id ===
              PhysicalDisposition.ReturnViaCrc && (
              <Grid item xs={12}>
                <StyledFieldsBackDrop elevation={0}>
                  Partner Return Address
                  <Grid container spacing={2}>
                    <Grid item xs={12} data-testid="return-contact">
                      <StyledSelect
                        data-testid="return-contact"
                        id="return-contact"
                        label="User Contact Name"
                        name="return_seller_user_id"
                        isRequired
                        options={sellerContacts}
                        keyName="label"
                        valueName="id"
                        onChange={handleInputChange}
                        value={returnPolicy.return_seller_user_id}
                        validation={validation}
                      />
                    </Grid>
                  </Grid>
                  <StyledInput
                    data-testid="in-care-of"
                    id="in-care-of"
                    label="In Care Of"
                    name="in_care_of"
                    isRequired
                    value={returnPolicy.in_care_of}
                    onChange={handleInputChange}
                    validation={validation}
                  />
                  <StyledDiv>
                    <AddressTypeahead
                      onChange={handleAddressLine1Change}
                      value={
                        returnPolicy.return_address?.address1
                          ? returnPolicy.return_address
                          : undefined
                      }
                      onClear={handleAddressLine1OnClear}
                      validation={validation}
                    />
                  </StyledDiv>
                  <StyledInput
                    data-testid="address-2"
                    id="address2"
                    label="Address Line 2"
                    name="address2"
                    value={returnPolicy.return_address?.address2 ?? ''}
                    onChange={handleInputChange}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <StyledInput
                        data-testid="city"
                        id="city"
                        label="City"
                        name="city"
                        isRequired
                        value={returnPolicy.return_address?.city}
                        onChange={handleInputChange}
                        validation={validation}
                      />
                    </Grid>
                    <Grid item xs={6} data-testid="state">
                      <StyledSelect
                        data-testid="state"
                        id="state"
                        label="State"
                        name="state"
                        isRequired
                        options={getLocationsFromCountryCode(
                          returnPolicy.return_address?.country_code
                            ? returnPolicy.return_address.country_code
                            : DEFAULT_COUNTRY,
                        )}
                        keyName="name"
                        valueName="abbreviation"
                        onChange={handleInputChange}
                        value={returnPolicy.return_address?.state}
                        validation={validation}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <StyledSelect
                        data-testid="country"
                        id="country"
                        label="Country"
                        name="country_code"
                        isRequired
                        options={COUNTRIES}
                        keyName="name"
                        valueName="code"
                        onChange={handleInputChange}
                        value={returnPolicy.return_address?.country_code}
                        validation={validation}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <StyledInput
                        data-testid="postal-code"
                        id="postal-code"
                        label="Zip Code"
                        name="postal_code"
                        isRequired
                        value={returnPolicy.return_address?.postal_code}
                        onChange={handleInputChange}
                        validation={validation}
                      />
                    </Grid>
                  </Grid>
                </StyledFieldsBackDrop>
              </Grid>
            )}
        </Grid>
      )}

      {progress === ProgressState.ERROR && (
        <>
          <Typography>
            Please ensure that all the information below is correct before
            saving. Using a verified address helps prevent delays in shipping.
          </Typography>
          <ContentSpacer />
          <StyledTypography>Address you provided:</StyledTypography>
          <StyledErrorTypography>
            {returnPolicy?.return_address?.address1}
          </StyledErrorTypography>
          <StyledErrorTypography>
            {returnPolicy?.return_address?.address2}
          </StyledErrorTypography>
          <StyledErrorTypography>{`${returnPolicy?.return_address?.city}, ${returnPolicy?.return_address?.state} ${returnPolicy?.return_address?.postal_code}`}</StyledErrorTypography>
          <StyledErrorTypography>
            {returnPolicy?.return_address?.country_code}
          </StyledErrorTypography>
        </>
      )}

      {progress === ProgressState.PENDING && <LinearProgress />}
    </>
  )
}

export default EditReturnPolicyDialogContent
