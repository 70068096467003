import axios from 'axios'

import apiConfig from 'config/apiConfig'
import getOr from 'lodash/fp/getOr'
import sortBy from 'lodash/fp/sortBy'

import { ItemData, MarketplaceProduct } from 'types/Item'
import {
  Fulfillment,
  Order,
  OrderCounts,
  OrderHistory,
  OrderItemData,
  OrderLine,
  OrderSearchParams,
  OrderStatus,
  sellerOrdersCount,
  Shipment,
} from 'types/Orders'
import { CollectionResponse, Response } from 'types/Response'

import { toQueryParams } from 'utilities/url'
import { getMarketplaceProductByTcin } from './items'
import { Direction, getPageable, PagingParams } from './pageableHelper'
import { getSeller } from './seller'

const CancelToken = axios.CancelToken
let cancel: any

export function getOrderList(
  pagingParams: PagingParams,
  searchParams: OrderSearchParams = {},
  doCancel: boolean = false,
) {
  if (doCancel && cancel) {
    cancel()
  }
  const { seller_id: sellerId } = searchParams
  const pageable = getPageable(pagingParams)
  const cancelToken = doCancel
    ? new CancelToken(function executor(c) {
        cancel = c
      })
    : undefined
  const config = {
    params: {
      ...pageable,
      ...searchParams,
      ...(sellerId ? { seller_id: undefined } : {}),
    },
    cancelToken,
  }
  let url = `${apiConfig.sellerOrders}/orders_search`
  if (sellerId) {
    url = `${apiConfig.sellerOrders}/sellers/${sellerId}/orders`
  }

  return axios.get(url, config).then((res): CollectionResponse<Order> => {
    const { data, headers } = res
    const total = headers['x-total-count']
      ? parseInt(headers['x-total-count'], 10)
      : 0

    return {
      total,
      data,
    }
  })
}

export async function getOrderById(
  sellerId: string,
  orderId: string,
): Promise<Order> {
  const response = await axios.get(
    `${apiConfig.sellerOrders}/sellers/${sellerId}/orders/${orderId}`,
  )

  return response.data
}

export function getOrderHistory(
  sellerId: string,
  orderId: string,
): Promise<OrderHistory[]> {
  const config = {
    params: {
      order_id: orderId,
      per_page: 100,
      sort: 'event_timestamp(asc)',
    },
  }

  const url = `${apiConfig.sellerOrders}/sellers/${sellerId}/order_histories`

  return axios.get(url, config).then((res) => res.data)
}

export async function getOrderWithItemDetails(
  sellerId: string,
  orderId: string,
): Promise<Order> {
  const order = await getOrderById(sellerId, orderId)

  const { order_lines: orderLines } = order

  const orderLinePromises =
    orderLines &&
    orderLines.map(async (line) => {
      const marketplaceData: MarketplaceProduct | undefined =
        await getMarketplaceProductByTcin(line.tcin)

      return hydrateItemData<OrderLine>(line, marketplaceData)
    })

  const hydratededOrderLines = await Promise.all(orderLinePromises)

  return {
    ...order,
    order_lines: hydratededOrderLines,
  }
}

export async function getOrderAddressesById(sellerId: string, orderId: string) {
  const response = await axios.get(
    `${apiConfig.sellerOrders}/sellers/${sellerId}/order_addresses/${orderId}`,
  )

  return response.data
}

export function getReturnCount({
  seller_id,
}: {
  seller_id: string
}): Promise<{ count: number }> {
  const config = {
    params: {
      per_page: 1,
      page: 1,
    },
  }
  return axios
    .get(`${apiConfig.sms}/sellers/${seller_id}/product_returns`, config)
    .then((res) => {
      const { headers } = res
      const count = headers['x-total-count']
        ? parseInt(headers['x-total-count'], 10)
        : 0
      return {
        count,
      }
    })
}

export async function getOrderCount(
  params: OrderSearchParams,
): Promise<number> {
  const response: CollectionResponse<Order> = await getOrderList(
    {
      perPage: 1,
      page: 0,
      direction: Direction.DESC,
    },
    params,
  )
  return response.total
}

export function getOrderCounts(sellerId: string): Promise<OrderCounts> {
  return Promise.all([
    getOrderCount({
      seller_id: sellerId,
      order_status: OrderStatus.ACKNOWLEDGED_BY_SELLER,
    }),
    getOrderCount({
      seller_id: sellerId,
      order_status: OrderStatus.PARTIALLY_SHIPPED,
    }),
    getOrderCount({
      seller_id: sellerId,
    }),
    getReturnCount({ seller_id: sellerId }),
  ]).then((res) => {
    return {
      unShipped: res[0],
      partiallyShipped: res[1],
      total: res[2],
      returned: res[3].count,
    }
  })
}

export async function getShipmentsByOrderId(
  sellerId: string,
  orderId: string,
  orderLines: OrderLine[],
): Promise<any> {
  const response: Response<Fulfillment> = await axios.get(
    `${apiConfig.sellerOrders}/sellers/${sellerId}/orders/${orderId}/fulfillments`,
  )

  const { data } = response

  const fulfillments = sortBy(
    (fulfillment) => new Date(fulfillment.shipped_date),
    data,
  )

  const shipments = createShipments(fulfillments, orderLines)

  return shipments
}

export function createShipments(
  fulfillments: Fulfillment[],
  orderLinesWithItemDetails: OrderLine[],
): Shipment[] {
  const newFulfillments = fulfillments.map((fulfillment) => {
    const orderLine = orderLinesWithItemDetails.find(
      (line) => line.order_line_number === fulfillment.order_line_number,
    )

    return hydrateItemData<Fulfillment>(fulfillment, orderLine)
  })

  const shipmentDict = newFulfillments.reduce<Dictionary<Shipment>>(
    (acc, curr: Fulfillment) => {
      if (acc[curr.tracking_number]) {
        acc[curr.tracking_number].fulfillments.push(curr)
      } else {
        acc[curr.tracking_number] = {
          last_modified: curr.last_modified,
          order_id: curr.order_id,
          seller_id: curr.seller_id,
          shipped_date: curr.shipped_date,
          shipping_method: curr.shipping_method,
          tracking_number: curr.tracking_number,
          fulfillments: [curr],
        }
      }

      return acc
    },
    {},
  )

  const shipments = Object.values(shipmentDict)

  return shipments
}

export async function bulkUpdateFulfillments(
  sellerId: string,
  orderId: string,
  fulfillments: Fulfillment[],
): Promise<Fulfillment[]> {
  const { data } = await axios.put(
    `${apiConfig.sellerOrders}/sellers/${sellerId}/orders/${orderId}/bulk_fulfillments_update`,
    { items: fulfillments },
  )

  return data.results
}

export async function getSellerShippingsLabels(
  sellerId: string,
  trackingNumber: string,
  orderId: string,
) {
  const url = apiConfig.sellerShippingsLabels.replace('<SELLER_ID>', sellerId)
  const queryParams = toQueryParams({
    order_id: orderId,
    tracking_number: trackingNumber,
  })

  const { data } = await axios.get(`${url}?${queryParams}`)

  return data
}

export function hydrateItemData<T extends OrderItemData>(
  source: T,
  itemData: ItemData | undefined,
): T {
  const getOrEmptyString = getOr('')
  return {
    ...source,
    ...(!source.tcin && { tcin: getOrEmptyString('tcin', itemData) as string }),
    external_id: getOrEmptyString('external_id', itemData),
    primary_image: getOrEmptyString('primary_image', itemData),
    title: getOrEmptyString('title', itemData),
    barcode: getOrEmptyString('barcode', itemData),
    seller_id: source.seller_id || getOrEmptyString('seller_id', itemData),
    product_id: getOrEmptyString('product_id', itemData),
  }
}

export async function getOrdersCountsPerSeller(
  searchParams: OrderSearchParams,
  pagingParams: PagingParams,
): Promise<CollectionResponse<{ seller_id: string; count: number }>> {
  const pageable = getPageable(pagingParams)
  const config = {
    params: {
      ...searchParams,
      ...pageable,
    },
  }

  return axios
    .get(`${apiConfig.sellerOrders}/orders_counts`, config)
    .then((res) => {
      const { data, headers } = res

      const total = headers['x-total-count']
        ? parseInt(headers['x-total-count'], 10)
        : 0

      return {
        data,
        total,
      }
    })
}

export async function countAllSellersOrders(
  searchParams: OrderSearchParams,
): Promise<CollectionResponse<{ seller_id: string; count: number }>> {
  let page = 0
  const perPage = 200
  const firstPage = await getOrdersCountsPerSeller(searchParams, {
    page,
    perPage,
  })
  const total = firstPage.total
  let data = [...firstPage.data]
  while (data.length < total) {
    page += 1
    const nextPage = await getOrdersCountsPerSeller(searchParams, {
      page,
      perPage,
    })
    data = data.concat(nextPage.data)
  }

  return { data, total }
}

export function transformUnshippedOrdersForDownload(
  data: sellerOrdersCount[],
): Promise<string> {
  return getUnshippedPastDuePartnerNames(data).then((res) => {
    const downloadData =
      '"SMS ID","Partner Name","Count of Unshipped Orders Past Due"\n'
    const sellerStrings = res.map((seller) => {
      return `"${seller.seller_id}","${seller.name}","${seller.count}"\n`
    })
    return downloadData.concat(sellerStrings.join(''))
  })
}

export async function getUnshippedPastDuePartnerNames(
  unshippedOrders: sellerOrdersCount[],
) {
  const sellers = await Promise.all(
    unshippedOrders.map((unshippedOrder) =>
      getSeller(unshippedOrder.seller_id),
    ),
  )
  const hydratedUnshippedPastDueOrders = unshippedOrders.map(
    (unshippedOrder) => {
      const seller = sellers.find((s) => s?.id === unshippedOrder.seller_id)
      let name = ''
      if (seller) {
        name = seller.display_name || seller.legal_business_name
      }
      return { ...unshippedOrder, name }
    },
  )
  return hydratedUnshippedPastDueOrders
}

export async function getUnshippedPastDue(
  orderStatuses: OrderStatus[],
  requestedShipDate: string,
) {
  const orderCount = await getOrderCount({
    order_status: orderStatuses,
    requested_shipment_date: requestedShipDate,
  })

  const orderCountsPerSeller = await getOrdersCountsPerSeller(
    { order_status: orderStatuses, requested_shipment_date: requestedShipDate },
    { perPage: 5, page: 0 },
  )

  const unshippedPastDuePartnerNames = await getUnshippedPastDuePartnerNames(
    orderCountsPerSeller.data,
  )

  return {
    orderCount,
    unshippedPastDuePartnerNames,
  }
}
