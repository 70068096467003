import startCase from 'lodash/fp/startCase'
import DisplayCard from 'components/common/DisplayCard'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import { olive, success, error, teal, violet, grey } from 'config/themeConfig'
import styled from '@emotion/styled'
import DataList from 'components/common/DataList'
import WarningIcon from 'components/common/WarningIcon'
import Link from 'components/common/Link'
import OrderAddressList from './OrderAddressList'
import { Divider } from '@mui/material'
import {
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
  formatDate,
} from 'services/dateService'
import { RoutePath } from 'services/NavigationHelper'

import { Order } from 'types/Orders'

export interface Props {
  order?: Order
  ODRImpactReasons?: string[]
}

const StyledText = styled(Typography)(
  ({ theme }) => ({
    marginBottom: theme.spacing(1),
  }),
  (props) => ({
    variant: props.variant,
  }),
) as typeof Typography

const StyledDiv = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const getOrderData = ({ order_date, requested_delivery_date }: Order) => [
  {
    title: 'Placed:',
    value: formatDate(order_date, DATE_FORMAT_MONTH_DAY_YEAR_TIME),
  },
  {
    title: 'Deliver by:',
    value: formatDate(requested_delivery_date, DATE_FORMAT_MONTH_DAY_YEAR_TIME),
  },
]

function formatOrderStatus(status: string): {
  status?: string
  color?: string
} {
  return status === 'ACKNOWLEDGED_BY_SELLER'
    ? { status: 'UNSHIPPED', color: error.main }
    : status === 'SHIPPED'
    ? { status: 'SHIPPED TO GUEST', color: success.main }
    : status === 'REFUNDED'
    ? { status: status, color: teal.main }
    : status === 'PENDING'
    ? { status: status, color: grey[700] }
    : status === 'CANCELED'
    ? { status: status, color: grey[900] }
    : status === 'RELEASED_FOR_SHIPMENT'
    ? { status: startCase(status), color: olive.main }
    : status === 'PARTIALLY_SHIPPED'
    ? { status: startCase(status), color: violet.main }
    : { status: '', color: '' }
}

export const OrderContent = ({ order, ODRImpactReasons }: Props) => {
  if (!order) return null

  const data = getOrderData(order)
  const orderStatus = formatOrderStatus(order.order_status)

  const renderODRImpactMessage = (defect: string) => {
    let reason
    switch (defect) {
      case 'Late':
        reason = 'Late Delivery'
        break
      case 'Cancel':
        reason = 'Cancellations'
        break
      case 'Return':
        reason = 'Defective Returns'
        break
      case 'Contact':
        reason = 'Defective Contacts'
        break
    }

    return (
      <>
        <WarningIcon>
          <Typography variant="body1">
            This order affects your{' '}
            <Link to={`/${order.seller_id}${RoutePath.PERFORMANCE}`}>
              Order Defect Rate
            </Link>{' '}
            due to {reason}.
          </Typography>
        </WarningIcon>
      </>
    )
  }

  return (
    <>
      <StyledText variant="h2" component="h1">
        Order {order.id}
      </StyledText>
      <StyledDivider />
      {ODRImpactReasons?.length !== 0 &&
        ODRImpactReasons?.map((reason) => (
          <StyledDiv key={reason}>{renderODRImpactMessage(reason)}</StyledDiv>
        ))}
      <StyledDiv>
        <Chip
          label={orderStatus.status}
          variant="outlined"
          sx={{ color: orderStatus.color }}
        />
      </StyledDiv>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <DisplayCard title={`Overview`}>
            <Grid item xs={12} data-testid="order-information">
              <DataList data={data} />
            </Grid>
          </DisplayCard>
        </Grid>
        <Grid item sm={6}>
          <DisplayCard title={`Ship To`}>
            <OrderAddressList sellerId={order.seller_id} orderId={order.id} />
          </DisplayCard>
        </Grid>
      </Grid>
    </>
  )
}

export default OrderContent
