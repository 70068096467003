import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import Grid from '@mui/material/Grid'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import Select from 'components/common/Select'

import { LABEL_BUSINESS_STRUCTURE } from 'constants/labels'

import { validationHandler, ValidationSchema } from 'services/validation'

import { editSeller } from 'store/seller/actionCreators'
import {
  currentSeller,
  getEnumerationValues,
  isSellersPending,
} from 'store/selectors'

import StoreState from 'types/state'
import { EnumerationName } from 'types/Enumeration'
import { Validation } from 'types/Validation'

export interface Props {
  isOpen: boolean
}

const formValidationSchema: ValidationSchema = yup.object().shape({
  businessStructure: yup.string().label('Business structure').required(),
})

const EditBusinessStructure = ({ isOpen }: Props) => {
  const seller = useSelector(currentSeller)
  const isPending = useSelector(isSellersPending)
  const businessStructures = useSelector((state: StoreState) =>
    getEnumerationValues(state, EnumerationName.BUSINESS_STRUCTURE),
  )
  const dispatch = useDispatch()

  const [businessStructure, setBusinessStructure] = React.useState(
    seller?.business_structure ?? '',
  )
  const [validation, setValidation] = React.useState<Validation>({})

  const handleInputChange = (value: any) => setBusinessStructure(value)

  const handleSubmit = () => {
    const { validation: formValidation, isValid } = validationHandler(
      formValidationSchema,
      {
        businessStructure,
      },
    )

    setValidation(formValidation)

    if (seller && isValid && businessStructure) {
      const updatedSeller = {
        ...seller,
        business_structure: businessStructure,
      }

      dispatch(editSeller(updatedSeller))
    }
  }

  return (
    <DialogContainer
      data-testid="edit-business-structure-dialog"
      isOpen={isOpen}
      title="Edit Business Structure"
      isPending={isPending}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Select
            isRequired
            id="business-structure"
            name="businessStructure"
            data-testid="business-structure-menu"
            label={LABEL_BUSINESS_STRUCTURE}
            isDisabled={isPending}
            onChange={handleInputChange}
            options={businessStructures}
            value={businessStructure}
            keyName="label"
            valueName="value"
            validation={validation}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export default EditBusinessStructure
