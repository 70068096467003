export const pyramids = [
  {
    group_id: '1',
    group_name: 'APPAREL/ACCESS',
  },
  {
    group_id: '3',
    group_name: 'HARDLINES',
  },
  {
    group_id: '4',
    group_name: 'HOME',
  },
  {
    group_id: '6',
    group_name: 'ESSENTIALS & BEAUTY',
  },
  {
    group_id: '10',
    group_name: 'FOOD/BEVERAGE',
  },
]
