import DialogContainer from 'components/common/Dialog/DialogContainer'

export interface Props {
  isOpen: boolean
  title: string
  submitText: string
  onRequestSubmit: (element?: any) => void
  content: string
  element?: any
}
export const ConfirmationDialog = ({
  isOpen,
  title,
  submitText,
  onRequestSubmit,
  content,
  element,
}: Props) => {
  const handleSubmit = () => {
    onRequestSubmit(element)
  }
  return (
    <DialogContainer
      isOpen={isOpen}
      title={title}
      onSubmit={handleSubmit}
      submitButtonText={submitText}
    >
      {content}
    </DialogContainer>
  )
}

export default ConfirmationDialog
