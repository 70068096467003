import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Grid from '@mui/material/Grid'
import RadioGroup from 'components/common/RadioGroup/RadioGroup'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import Text from 'components/common/Text'
import DateRangeSelector from 'components/common/DateRangePicker/DateRangeSelector'
import {
  DateRange,
  generateRangeConfig,
  getRangeConfig,
} from 'components/common/DateRangePicker/rangeConfig'

import {
  endOfYesterday,
  startOfDay,
  formatDate,
  DATE_FORMAT_MONTH_DAY,
  DATE_PICKER_FORMAT,
} from 'services/dateService'
import { GenerateReportRequest } from 'services/reports'

import { closeDialog } from 'store/dialog/actionCreator'
import { currentSellerId } from 'store/selectors'

import { ReportType } from 'types/Report'

interface Props {
  isOpen: boolean
  reportType: ReportType
  onRequestSubmit: (reportParams: Partial<GenerateReportRequest>) => void
}

const SalesMetricsReport = ({ isOpen, reportType, onRequestSubmit }: Props) => {
  const sellerId = useSelector(currentSellerId)
  const reduxDispatch = useDispatch()

  const dateRangeOptions = generateRangeConfig()
  const last30Days = getRangeConfig(DateRange.LAST_30_DAYS, undefined)
  const defaultRange = {
    from: startOfDay(last30Days.from),
    to: startOfDay(last30Days.to),
  }

  enum SalesMetricsReports {
    ITEM_LEVEL_GMV = 'ITEM_LEVEL_GMV',
    ITEM_CONVERSION = 'ITEM_CONVERSION',
    ITEM_RETURN_RATE = 'ITEM_RETURN_RATE',
  }

  const [dates, setDates] = useState(defaultRange)
  const [salesMetricType, setSalesMetricType] = useState<string>(
    SalesMetricsReports.ITEM_LEVEL_GMV,
  )
  const [isDatesValid, setIsDatesValid] = useState(true)

  const handleInvalidSelection = () => {
    setIsDatesValid(false)
  }

  const handleChange = (value: string) => setSalesMetricType(value)

  const handleDateChange = (from: Date, to: Date, isValid: boolean) => {
    if (isValid) {
      setIsDatesValid(true)
      setDates({
        from,
        to,
      })
    }
  }

  const handleSubmit = async () => {
    onRequestSubmit({
      sellerId,
      type: reportType,
      parameters: {
        start_day: formatDate(dates.from, DATE_PICKER_FORMAT),
        end_day: formatDate(dates.to, DATE_PICKER_FORMAT),
        metrics_type: salesMetricType,
      },
      reportName: `${salesMetricType} - ${formatDate(
        dates.from,
        DATE_FORMAT_MONTH_DAY,
      )} - ${formatDate(dates.to, DATE_FORMAT_MONTH_DAY)}`,
      format: 'CSV',
    })

    reduxDispatch(closeDialog())
  }

  const isDisabled = !dates.from || !dates.to || !isDatesValid

  const maxDate = endOfYesterday(new Date())

  return (
    <DialogContainer
      title="Generate Sales Report"
      isOpen={isOpen}
      isSubmitDisabled={isDisabled}
      onSubmit={handleSubmit}
      submitButtonText="Generate"
      disableScroll
    >
      <Grid container>
        <Grid item xs={12}>
          <DateRangeSelector
            descriptor="date-range"
            description="Date Range"
            defaultRange={DateRange.LAST_30_DAYS}
            defaultFrom={dates.from}
            defaultTo={maxDate}
            maxDate={maxDate}
            maxDateError="Must be yesterday or earlier"
            onDateChange={handleDateChange}
            dateRangeOptions={dateRangeOptions}
            onInvalidSelection={handleInvalidSelection}
          />
        </Grid>
        <Grid item xs={12}>
          <Text variant="caption">Report Type*</Text>
          <RadioGroup
            id="sales-metric-type"
            name="salesMetricsType"
            options={[
              {
                label: 'Item Level GMV',
                value: SalesMetricsReports.ITEM_LEVEL_GMV,
              },
              {
                label: 'Item Conversion',
                value: SalesMetricsReports.ITEM_CONVERSION,
              },
              {
                label: 'Item Return Rate',
                value: SalesMetricsReports.ITEM_RETURN_RATE,
              },
            ]}
            value={salesMetricType || ''}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export default SalesMetricsReport
