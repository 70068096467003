import { useState, useEffect } from 'react'

import TabularData, { FieldList } from '../common/TabularData'

import { DataListItem } from 'components/common/DataList'
import DisplayCard from 'components/common/DisplayCard'
import Link from 'components/common/Link'
import Text from 'components/common/Text'
import MultiSelectFilter from 'components/common/FilterBar/MultiSelectFilter'

import { GENERIC_API_ERROR } from 'constants/errors'

import WidgetsIcon from '@mui/icons-material/Widgets'

import { formatLocaleNumber } from 'services/formatNumber'
import { getProductCount } from 'services/items'
import { getProductCountsForItemsByListingStatus } from 'services/productCounts'
import { createQueryString } from 'services/urlHelper'
import { RoutePath } from 'services/NavigationHelper'

import { ListingStatus, RelationshipType } from 'types/Item'

export interface Props {
  sellerId: string
}

const RELATIONSHIP_TYPE = 'relationship_type'

export const SellerItemsCard = ({ sellerId }: Props) => {
  const [loading, setLoading] = useState(false)
  const [totalItems, setTotalItems] = useState(0)
  const [publishedInStockItems, setPublishedInStockItems] = useState(0)
  const [publishedOutOfStockItems, setPublishedOutOfStockItems] = useState(0)
  const [unlistedItems, setUnlistedItems] = useState(0)
  const [pendingItems, setPendingItems] = useState(0)
  const [error, setError] = useState(false)
  const [relationshipTypes, setRelationshipTypes] = useState<
    string | string[] | undefined
  >()

  useEffect(() => {
    let unmounted = false
    setLoading(true)

    Promise.all([
      getProductCountsForItemsByListingStatus({ sellerId, relationshipTypes }),
      getProductCount({
        seller_id: sellerId,
        published: true,
        has_inventory: true,
        relationship_type: relationshipTypes,
      }),
      getProductCount({
        seller_id: sellerId,
        published: true,
        has_inventory: false,
        relationship_type: relationshipTypes,
      }),
    ])
      .then(([productCounts, publishedInStock, publishedOutOfStock]) => {
        if (!unmounted) {
          const counts =
            productCounts.product_counts[0].property_values.property_value_counts.reduce<{
              total: number
              unlisted: number
              pending: number
            }>(
              (prev, curr) => {
                const total = prev.total + curr.count

                const unlisted =
                  curr.value === ListingStatus.UNLISTED
                    ? curr.count
                    : prev.unlisted

                const pending =
                  curr.value === ListingStatus.PENDING
                    ? curr.count
                    : prev.pending

                return {
                  total,
                  unlisted,
                  pending,
                }
              },
              {
                total: 0,
                unlisted: 0,
                pending: 0,
              },
            )

          setTotalItems(counts.total)
          setUnlistedItems(counts.unlisted)
          setPendingItems(counts.pending)
          setPublishedInStockItems(publishedInStock)
          setPublishedOutOfStockItems(publishedOutOfStock)
        }
      })
      .catch(() => {
        if (!unmounted) {
          setError(true)
        }
      })
      .finally(() => {
        if (!unmounted) {
          setLoading(false)
        }
      })

    return () => {
      unmounted = true
    }
  }, [sellerId, relationshipTypes])

  const onFilterChange = (param: Dictionary<string | string[] | undefined>) => {
    const value = param[RELATIONSHIP_TYPE]
    setRelationshipTypes(value)
  }

  const data: DataListItem[] = [
    {
      title: 'Total',
      element: (
        <Link
          to={{
            pathname: `/${sellerId}${RoutePath.ALL_ITEMS}`,
            search: createQueryString({
              relationship_type: relationshipTypes,
            }),
          }}
        >
          {formatLocaleNumber(totalItems)}
        </Link>
      ),
    },
    {
      title: 'Published, In Stock',
      element: (
        <Link
          to={{
            pathname: `/${sellerId}${RoutePath.ALL_ITEMS}`,
            search: createQueryString({
              published: 'Yes',
              has_inventory: true,
              relationship_type: relationshipTypes,
            }),
          }}
        >
          {formatLocaleNumber(publishedInStockItems)}
        </Link>
      ),
    },
    {
      title: 'Published, Out of Stock',
      element: (
        <Link
          to={{
            pathname: `/${sellerId}${RoutePath.ALL_ITEMS}`,
            search: createQueryString({
              published: 'Yes',
              has_inventory: false,
              relationship_type: relationshipTypes,
            }),
          }}
        >
          {formatLocaleNumber(publishedOutOfStockItems)}
        </Link>
      ),
    },
    {
      title: 'Unlisted',
      element: (
        <Link
          to={{
            pathname: `/${sellerId}${RoutePath.ALL_ITEMS}`,
            search: createQueryString({
              listing_status: ListingStatus.UNLISTED,
              relationship_type: relationshipTypes,
            }),
          }}
        >
          {formatLocaleNumber(unlistedItems)}
        </Link>
      ),
    },
    {
      title: 'Pending',
      element: (
        <Link
          to={{
            pathname: `/${sellerId}${RoutePath.ALL_ITEMS}`,
            search: createQueryString({
              listing_status: ListingStatus.PENDING,
              relationship_type: relationshipTypes,
            }),
          }}
        >
          {formatLocaleNumber(pendingItems)}
        </Link>
      ),
    },
  ]

  return (
    <DisplayCard
      title="Items"
      iconColor="target"
      icon={WidgetsIcon}
      isLoading={loading}
      hasWarning={error}
      warningTooltip="An error occurred while getting the information."
      headerActions={
        <MultiSelectFilter
          placeholder="Filter by VAP, VC, SA"
          searchParam={RELATIONSHIP_TYPE}
          value={relationshipTypes}
          data={[
            {
              name: 'VAPs',
              value: RelationshipType.VAP,
            },
            {
              name: 'VCs',
              value: RelationshipType.VC,
            },
            {
              name: 'SAs',
              value: RelationshipType.SA,
            },
          ]}
          onChange={onFilterChange}
        />
      }
    >
      {error ? (
        <Text>{GENERIC_API_ERROR}</Text>
      ) : (
        <TabularData
          borderTop
          hasHeader={false}
          extraPadding={true}
          fieldList={
            [
              {
                key: 'title',
                displayName: '',
                width: 100,
              },
              {
                key: 'element',
                displayName: '',
                width: 50,
                formatCell: ({ element }) => element,
              },
            ] as FieldList<DataListItem>[]
          }
          data={data}
        />
      )}
    </DisplayCard>
  )
}

export default SellerItemsCard
