import { SvgIconProps } from '@mui/material'

import WidgetsIcon from '@mui/icons-material/Widgets'
import WatchIcon from '@mui/icons-material/Watch'
import ReceiptIcon from '@mui/icons-material/Receipt'

import { DialogEnum } from 'components/common/Dialog'
import { FireflyEventInfo, FireflyEvent } from 'types/FireflyInsights'
import { getScoreInfo, ScoreInfo, ScoreInfoType } from './Dialogs/dialogHelpers'

export const ItemInsightTemplate = {
  GENERAL_TEMPLATE: 'Target Plus - CH Audit Template',
  AA_TEMPLATE: 'Target Plus A&A Specific - CH Audit Template',
  LABEL_INFO_TEMPLATE: 'Target Plus Label Info - CH Audit Template',
} as const

type InsightCardData = {
  title: string
  description: string
  icon: React.ComponentType<SvgIconProps>
  dialog: DialogEnum
  dialogProps: Dictionary<string | ScoreInfo[]>
  downloadFireflyInfo: FireflyEventInfo
  dialogFireflyInfo: FireflyEventInfo
  pageChangeFireflyInfo: FireflyEventInfo
}

const createFireflyEvents = (
  key: string,
): {
  downloadFireflyInfo: FireflyEventInfo
  dialogFireflyInfo: FireflyEventInfo
  pageChangeFireflyInfo: FireflyEventInfo
} => ({
  downloadFireflyInfo: {
    type: FireflyEvent.ITEM_INSIGHT,
    key,
    value: 'score report download',
  },

  dialogFireflyInfo: {
    type: FireflyEvent.ITEM_INSIGHT,
    key,
    value: 'open weighting and best practices dialog',
  },

  pageChangeFireflyInfo: {
    type: FireflyEvent.ITEM_INSIGHT,
    key,
    value: 'navigate to services pages.',
  },
})

export const insightCardData: Record<string, InsightCardData> = {
  [ItemInsightTemplate.GENERAL_TEMPLATE]: {
    title: 'General Content Score',
    description:
      'Bi-weekly evaluation of content quality and accuracy for all published VAPs and SAs that fall under the General template.',
    icon: WidgetsIcon,
    dialog: DialogEnum.ITEM_INSIGHT_DIALOG,
    dialogProps: {
      title: 'Score weighting & best practices',
      imagePath:
        'https://target.scene7.com/is/image/Target/ContentHealth_ScoreWeight?wid=1280&fmt=pjpeg',
      data: [
        getScoreInfo(ScoreInfoType.Images, 30),
        getScoreInfo(ScoreInfoType.QA, 30),
        getScoreInfo(ScoreInfoType.FeatureBullets, 10),
        getScoreInfo(ScoreInfoType.LongCopy, 10),
        getScoreInfo(ScoreInfoType.Reviews, 10),
        getScoreInfo(ScoreInfoType.SizeCharts, 5),
        getScoreInfo(ScoreInfoType.Video, 5),
      ],
    },
    ...createFireflyEvents('general'),
  },
  [ItemInsightTemplate.AA_TEMPLATE]: {
    title: 'Apparel & Accessories Content Score',
    description:
      'Bi-weekly evaluation of content quality and accuracy for all published VAPs and SAs that fall under the A&A template.',
    icon: WatchIcon,
    dialog: DialogEnum.ITEM_INSIGHT_DIALOG,
    dialogProps: {
      title: 'A&A score weighting & best practices',
      imagePath:
        'https://target.scene7.com/is/image/Target/ContentHealth_ScoreWeight_AA?wid=1280&fmt=pjpeg',
      data: [
        getScoreInfo(ScoreInfoType.QA, 20),
        getScoreInfo(ScoreInfoType.FeatureBullets, 20),
        getScoreInfo(ScoreInfoType.SizeCharts, 15),
        getScoreInfo(ScoreInfoType.Images, 15),
        getScoreInfo(ScoreInfoType.Reviews, 10),
        getScoreInfo(ScoreInfoType.LongCopy, 10),
        getScoreInfo(ScoreInfoType.Video, 5),
        getScoreInfo(ScoreInfoType.RichMedia, 5),
      ],
    },
    ...createFireflyEvents('aa'),
  },
  [ItemInsightTemplate.LABEL_INFO_TEMPLATE]: {
    title: 'Label Info Score',
    description:
      'Bi-weekly evaluation of content quality and accuracy for all published VAPs and SAs that fall under the Label Info template.',
    icon: ReceiptIcon,
    dialog: DialogEnum.ITEM_INSIGHT_DIALOG,
    dialogProps: {
      title: 'Score weighting & best practices',
      imagePath:
        'https://target.scene7.com/is/image/Target/GUEST_24e34c16-e506-45e8-845b-bdacda358ce2?wid=1280&fmt=pjpeg',
      data: [
        getScoreInfo(ScoreInfoType.Images, 20),
        getScoreInfo(ScoreInfoType.QA, 30),
        getScoreInfo(ScoreInfoType.FeatureBullets, 10),
        getScoreInfo(ScoreInfoType.LabelInfo, 10),
        getScoreInfo(ScoreInfoType.LongCopy, 10),
        getScoreInfo(ScoreInfoType.Reviews, 10),
        getScoreInfo(ScoreInfoType.Video, 5),
        getScoreInfo(ScoreInfoType.RichMedia, 5),
      ],
    },
    ...createFireflyEvents('label_info'),
  },
}
