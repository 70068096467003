export const fetchUnshippedData = () =>
  ({
    type: 'FETCH',
  }) as const

export const rejectUnshippedData = () =>
  ({
    type: 'REJECT',
  }) as const

export const resolveUnshippedData = (
  shipByToday: number,
  pastDue: number,
  total: number,
) =>
  ({
    type: 'RESOLVE',
    payload: {
      shipByToday,
      pastDue,
      total,
    },
  }) as const

type Actions = ReturnType<
  | typeof resolveUnshippedData
  | typeof fetchUnshippedData
  | typeof rejectUnshippedData
>

type State = {
  loading: boolean
  error: boolean
  shipByToday: number
  pastDue: number
  total: number
}

export const initialState: State = {
  loading: false,
  error: false,
  shipByToday: 0,
  pastDue: 0,
  total: 0,
}

export const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case 'FETCH': {
      return {
        ...state,
        loading: true,
      }
    }

    case 'REJECT': {
      return {
        ...state,
        loading: false,
        error: true,
      }
    }

    case 'RESOLVE': {
      const { payload } = action
      const { shipByToday, pastDue, total } = payload

      return {
        ...state,
        loading: false,
        shipByToday,
        pastDue,
        total,
      }
    }
    default:
      return state
  }
}
