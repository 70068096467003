import { Breadcrumb } from 'types/Layout'

export const SET_HEADER_TITLE = 'SET_HEADER_TITLE'
export const OPEN_FULL_PAGE_LOADER = 'OPEN_FULL_PAGE_LOADER'
export const CLOSE_FULL_PAGE_LOADER = 'CLOSE_FULL_PAGE_LOADER'
export const SET_SMALL_SCREEN_SIZE = 'SET_SMALL_SCREEN_SIZE'

export interface SetHeaderTitle {
  readonly type: typeof SET_HEADER_TITLE
  payload: string | Breadcrumb[]
}

export interface OpenFullPageLoaderAction {
  readonly type: typeof OPEN_FULL_PAGE_LOADER
}

export interface CloseFullPageLoaderAction {
  readonly type: typeof CLOSE_FULL_PAGE_LOADER
}

export interface SetSmallScreenSizeAction {
  readonly type: typeof SET_SMALL_SCREEN_SIZE
  payload: boolean
}

export function setHeaderTitle(
  headerTitle: string | Breadcrumb[],
): SetHeaderTitle {
  return {
    type: SET_HEADER_TITLE,
    payload: headerTitle,
  }
}

export function openFullPageLoader(): OpenFullPageLoaderAction {
  return {
    type: OPEN_FULL_PAGE_LOADER,
  }
}

export function closeFullPageLoader(): CloseFullPageLoaderAction {
  return {
    type: CLOSE_FULL_PAGE_LOADER,
  }
}

export function setSmallScreenSize(
  smallScreenSizeOn: boolean,
): SetSmallScreenSizeAction {
  return {
    type: SET_SMALL_SCREEN_SIZE,
    payload: smallScreenSizeOn,
  }
}

export type LayoutActions =
  | SetHeaderTitle
  | OpenFullPageLoaderAction
  | CloseFullPageLoaderAction
  | SetSmallScreenSizeAction
