import { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import HeaderTitle from 'components/common/HeaderTitle'

import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'

import { RoutePath } from 'services/NavigationHelper'

import OpenCasesDetailContent from './OpenCasesDetailContent'

import { OpenCasesDetailTabs } from './OpenCasesDetailTabs'
import { Case } from 'types/Case'

import { getCaseById } from 'services/cases'

export const OpenCaseDetail = () => {
  const params = useParams()
  const dispatch = useDispatch()

  const [isCasesPending, setIsCasesPending] = useState(false)
  const [selectedCase, setSelectedCase] = useState<Case>()

  const sellerId = params.sellerId

  useEffect(() => {
    const fetchCases = async () => {
      setIsCasesPending(true)
      const caseId = params.caseId
      if (!caseId || !sellerId) return

      try {
        const data: Case = await getCaseById({
          sellerId,
          caseId,
        })
        setSelectedCase(data)
        setIsCasesPending(false)
      } catch (e) {
        console.error(`Error in OpenCaseDetail: ${e}`)
      } finally {
        setIsCasesPending(false)
      }
    }
    fetchCases()
  }, [dispatch, params.caseId, sellerId])

  if (!selectedCase) return null

  return (
    <div data-testid="case-detail-page">
      <HeaderTitle
        title={[
          {
            text: 'Cases',
            route: `/${sellerId}${RoutePath.OPEN_CASES}`,
          },
          { text: 'Case Detail' },
        ]}
      />
      {sellerId && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {isCasesPending ? (
              <LinearProgress data-testid="latest-item-progress" />
            ) : (
              <OpenCasesDetailContent
                sellerId={sellerId}
                selectedCase={selectedCase}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <OpenCasesDetailTabs
              sellerId={sellerId}
              selectedCase={selectedCase}
            />
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default OpenCaseDetail
