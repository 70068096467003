import {
  PaymentAccountActions,
  REGISTER_WITH_PAYMENT_SERVICE,
  REGISTER_WITH_PAYMENT_SERVICE_ERROR,
  CONFIRM_PAYMENT_ACCOUNT_DONE,
  ConfirmPaymentAccountDoneAction,
  RESET_PAYMENT_ACCOUNT,
} from './actionCreators'

export interface PaymentAccountState {
  readonly isRegistrationPending: boolean
  readonly isPaymentAccountConfirmed: boolean
}

export const initialState = {
  isRegistrationPending: false,
  isPaymentAccountConfirmed: false,
}

export default function paymentAccountReducer(
  state: PaymentAccountState = initialState,
  action: PaymentAccountActions,
) {
  switch (action.type) {
    case REGISTER_WITH_PAYMENT_SERVICE: {
      return {
        ...state,
        isRegistrationPending: true,
      }
    }

    case REGISTER_WITH_PAYMENT_SERVICE_ERROR: {
      return {
        ...state,
        isRegistrationPending: false,
      }
    }

    case CONFIRM_PAYMENT_ACCOUNT_DONE: {
      const { payload } = action as ConfirmPaymentAccountDoneAction
      const { isConfirmed: isPaymentAccountConfirmed } = payload

      return {
        ...state,
        isPaymentAccountConfirmed,
      }
    }

    case RESET_PAYMENT_ACCOUNT: {
      return initialState
    }

    default:
      return state
  }
}
