import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import DomesticSurveyPageOne from './DomesticSurveyPageOne'
import DomesticSurveyPageTwo from './DomesticSurveyPageTwo'
import DomesticSurveyPageThree from './DomesticSurveyPageThree'

import { editPartnerDiversityAnswers } from 'store/partnerDiversity/actionCreators'
import { currentSellerId } from 'store/selectors'

import { DiversityAnswer, DiversitySurveyPages } from 'types/PartnerDiversity'
import { formatAnswersForSurvey } from 'services/partnerDiversity'

export type Props = {
  answers: DiversityAnswer[]
}

const previousPage = (answers: Partial<DiversitySurveyPages>) =>
  ({
    type: 'PREVIOUS_PAGE',
    payload: {
      answers,
    },
  }) as const

const updateAnswers = (answers: Partial<DiversitySurveyPages>) =>
  ({
    type: 'UPDATE_ANSWERS',
    payload: {
      answers,
    },
  }) as const

type Action = ReturnType<typeof previousPage | typeof updateAnswers>

type State = DiversitySurveyPages & {
  page: number
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'PREVIOUS_PAGE': {
      if (state.page <= 1) {
        return state
      }

      return {
        ...state,
        ...action.payload.answers,
        page: state.page - 1,
      }
    }

    case 'UPDATE_ANSWERS': {
      const page = state.page < 3 ? state.page + 1 : state.page

      return {
        ...state,
        ...action.payload.answers,
        page,
      }
    }
    default:
      return state
  }
}

const DomesticDialogWrapper: React.FC<Props> = ({ answers }) => {
  const sellerId = useSelector(currentSellerId)

  const reduxDispatch = useDispatch()

  const isEdit = answers.length > 0

  const initialState = {
    page: 1,
    ...formatAnswersForSurvey(answers),
  }

  const [state, dispatch] = React.useReducer(reducer, initialState)
  const handlePreviousPage = (values: Partial<DiversitySurveyPages>) => {
    dispatch(previousPage(values))
  }

  const handleSubmit = (values: Partial<DiversitySurveyPages>) => {
    if (state.page === 1 || state.page === 2) {
      dispatch(updateAnswers(values))
    }

    if (state.page === 3) {
      const data = {
        ...state,
        ...values,
      }

      if (sellerId) {
        reduxDispatch(editPartnerDiversityAnswers({ sellerId, answers: data }))
      }
    }
  }

  return (
    <>
      {state.page === 1 && (
        <DomesticSurveyPageOne
          page={state.page}
          onSubmit={handleSubmit}
          isEdit={isEdit}
          initValues={{
            WOMAN: state.WOMAN,
            WOMAN_CERT: state.WOMAN_CERT,
            WOMAN_CERT_DATE: state.WOMAN_CERT_DATE,
            WOMAN_OTHER: state.WOMAN_OTHER,
            WOMAN_OTHER_CERT: state.WOMAN_OTHER_CERT,
            WOMAN_OTHER_CERT_DATE: state.WOMAN_OTHER_CERT_DATE,
            BIPOC: state.BIPOC,
            BIPOC_ETHNICITY: state.BIPOC_ETHNICITY,
            BIPOC_CERT: state.BIPOC_CERT,
            BIPOC_CERT_DATE: state.BIPOC_CERT_DATE,
            LGBTQ: state.LGBTQ,
            LGBTQ_CERT: state.LGBTQ_CERT,
            LGBTQ_CERT_DATE: state.LGBTQ_CERT_DATE,
          }}
        />
      )}
      {state.page === 2 && (
        <DomesticSurveyPageTwo
          page={state.page}
          onSubmit={handleSubmit}
          onPrevious={handlePreviousPage}
          isEdit={isEdit}
          initValues={{
            VETERAN: state.VETERAN,
            VETERAN_CERT: state.VETERAN_CERT,
            SERVICE_DISABLED_VET: state.SERVICE_DISABLED_VET,
            SERVICE_DISABLED_VET_CERT: state.SERVICE_DISABLED_VET_CERT,
            SMALL_BUSINESS: state.SMALL_BUSINESS,
            SMALL_DISADVANTAGED_BUSINESS: state.SMALL_DISADVANTAGED_BUSINESS,
            SMALL_DISADVANTAGED_BUSINESS_CERT:
              state.SMALL_DISADVANTAGED_BUSINESS_CERT,
            SMALL_DISADVANTAGED_BUSINESS_CERT_DATE:
              state.SMALL_DISADVANTAGED_BUSINESS_CERT_DATE,
            DISADVANTAGED_BUSINESS_ENTERPRISE:
              state.DISADVANTAGED_BUSINESS_ENTERPRISE,
            DISADVANTAGED_BUSINESS_ENTERPRISE_CERT:
              state.DISADVANTAGED_BUSINESS_ENTERPRISE_CERT,
            DISADVANTAGED_BUSINESS_ENTERPRISE_CERT_DATE:
              state.DISADVANTAGED_BUSINESS_ENTERPRISE_CERT_DATE,
          }}
        />
      )}
      {state.page === 3 && (
        <DomesticSurveyPageThree
          page={state.page}
          onSubmit={handleSubmit}
          onPrevious={handlePreviousPage}
          isEdit={isEdit}
          initValues={{
            DIFFERENTLY_ABLED: state.DIFFERENTLY_ABLED,
            DIFFERENTLY_ABLED_CERT: state.DIFFERENTLY_ABLED_CERT,
            DIFFERENTLY_ABLED_CERT_DATE: state.DIFFERENTLY_ABLED_CERT_DATE,
            HUB_ZONE: state.HUB_ZONE,
            HUB_ZONE_CERT: state.HUB_ZONE_CERT,
            HUB_ZONE_CERT_DATE: state.HUB_ZONE_CERT_DATE,
            ALASKAN_NATIVE: state.ALASKAN_NATIVE,
            ALASKAN_NATIVE_CERT: state.ALASKAN_NATIVE_CERT,
            ALASKAN_NATIVE_CERT_DATE: state.ALASKAN_NATIVE_CERT_DATE,
          }}
        />
      )}
    </>
  )
}

export default DomesticDialogWrapper
