import LinearProgress from '@mui/material/LinearProgress'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

interface Props {
  colspan: number
  isLoading: boolean
}

export const EnhancedTableProgress = (props: Props) => {
  const { colspan, isLoading } = props

  if (!isLoading) return null

  return (
    <TableRow data-testid="table-progress">
      <TableCell colSpan={colspan}>
        <LinearProgress />
      </TableCell>
    </TableRow>
  )
}

export default EnhancedTableProgress
