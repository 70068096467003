import styled from '@emotion/styled'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'

import InputPhone from './InputPhone'
import SelectCountryCode from './SelectCountryCode'

// Regular expression to test if a phone number is a toll free number
export const tollFreeRegex =
  /^\(?8(00|22|33|44|55|66|81|82|83|84|85|86|87|88)\)?/

export const tollFreeErrorMsg = 'Toll free numbers are not a valid entry'

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(2),
  position: 'relative',
}))

const StyledSelect = styled('div')({
  width: '20%',
})

const StyledFormControl = styled(FormControl)({
  position: 'initial',
  width: '100%',
})

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  left: 0,
  top: theme.spacing(-2),
}))

const StyledInput = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2),
}))

type Props = {
  selectName: string
  name: string
  id: string
  disabled: boolean
  countryDisabled?: boolean
  label: string
  required?: boolean
  country: string
  error: string
}

export const PhoneField = ({
  selectName,
  name,
  id,
  disabled,
  countryDisabled,
  label,
  required,
  country,
  error,
}: Props) => {
  return (
    <StyledRoot>
      <StyledSelect>
        <SelectCountryCode
          name={selectName}
          id={id}
          disabled={disabled || countryDisabled}
        />
      </StyledSelect>
      <StyledFormControl error={!!error.length}>
        <StyledInputLabel variant="standard" htmlFor={name} shrink={true}>
          {label}
        </StyledInputLabel>
        <StyledInput>
          <InputPhone
            name={name}
            disabled={disabled}
            hasError={!!error}
            required={required}
            country={country}
          />
        </StyledInput>
        {error && (
          <FormHelperText data-testid="phone-error">{error}</FormHelperText>
        )}
      </StyledFormControl>
    </StyledRoot>
  )
}

export default PhoneField
