import React from 'react'

import GroupIcon from '@mui/icons-material/Group'

import DisplayCard from 'components/common/DisplayCard'
import Text from 'components/common/Text'
import Link from 'components/common/Link'
import TabularData from 'components/common/TabularData'

import { GENERIC_API_ERROR } from 'constants/errors'

import { getSellerCount } from 'services/seller'
import { createQueryString } from 'services/urlHelper'
import { RoutePath } from 'services/NavigationHelper'

import { SellerStatus } from 'types/Seller'

const fetchSellerCount = () =>
  ({
    type: 'FETCH',
  }) as const

const rejectSellerCount = () =>
  ({
    type: 'REJECT',
  }) as const

const resolveSellerCount = (
  total: number,
  initiated: number,
  partner_setup: number,
  kick_off: number,
  data_integration: number,
  ramp_up: number,
  onboarding_complete: number,
  established: number,
  suspended: number,
  defunct: number,
) =>
  ({
    type: 'RESOLVE',
    payload: {
      total,
      initiated,
      partner_setup,
      kick_off,
      data_integration,
      ramp_up,
      onboarding_complete,
      established,
      suspended,
      defunct,
    },
  }) as const

type Actions = ReturnType<
  typeof fetchSellerCount | typeof resolveSellerCount | typeof rejectSellerCount
>

type State = {
  loading: boolean
  error: boolean
  total: number
  initiated: number
  partner_setup: number
  kick_off: number
  data_integration: number
  ramp_up: number
  onboarding_complete: number
  established: number
  suspended: number
  defunct: number
}

const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case 'FETCH': {
      return {
        ...state,
        loading: true,
      }
    }

    case 'REJECT': {
      return {
        ...state,
        loading: false,
        error: true,
      }
    }

    case 'RESOLVE': {
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    }
    default:
      return state
  }
}
const initialState = {
  loading: false,
  error: false,
  total: 0,
  initiated: 0,
  partner_setup: 0,
  kick_off: 0,
  data_integration: 0,
  ramp_up: 0,
  onboarding_complete: 0,
  established: 0,
  suspended: 0,
  defunct: 0,
}

const fieldList = [
  {
    key: 'title',
    displayName: '',
    width: 100,
  },
  {
    key: 'element',
    displayName: '',
    width: 50,
  },
]

export const SellersCard = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  React.useEffect(() => {
    let canceled = false

    dispatch(fetchSellerCount())

    Promise.all([
      getSellerCount({}),
      getSellerCount({ status: SellerStatus.INITIATED }),
      getSellerCount({ status: SellerStatus.PARTNER_SETUP }),
      getSellerCount({ status: SellerStatus.KICK_OFF }),
      getSellerCount({ status: SellerStatus.DATA_INTEGRATION }),
      getSellerCount({ status: SellerStatus.RAMP_UP }),
      getSellerCount({ status: SellerStatus.ONBOARDING_COMPLETE }),
      getSellerCount({ status: SellerStatus.ESTABLISHED }),
      getSellerCount({ status: SellerStatus.SUSPENDED }),
      getSellerCount({ status: SellerStatus.DEFUNCT }),
    ])
      .then(
        ([
          total,
          initiated,
          partner_setup,
          kick_off,
          data_integration,
          ramp_up,
          onboarding_complete,
          established,
          suspended,
          defunct,
        ]) => {
          if (canceled) return

          dispatch(
            resolveSellerCount(
              total,
              initiated,
              partner_setup,
              kick_off,
              data_integration,
              ramp_up,
              onboarding_complete,
              established,
              suspended,
              defunct,
            ),
          )
        },
      )
      .catch(() => {
        if (canceled) return

        dispatch(rejectSellerCount())
      })

    return () => {
      canceled = true
    }
  }, [])

  const data = [
    {
      title: 'Total',
      element: <Link to={RoutePath.SELLERS}>{state.total}</Link>,
    },
    {
      title: 'Initiated',
      element: (
        <Link
          to={{
            pathname: RoutePath.SELLERS,
            search: createQueryString({
              status: SellerStatus.INITIATED,
            }),
          }}
        >
          {state.initiated}
        </Link>
      ),
    },
    {
      title: 'Partner Setup',
      element: (
        <Link
          to={{
            pathname: RoutePath.SELLERS,
            search: createQueryString({
              status: SellerStatus.PARTNER_SETUP,
            }),
          }}
        >
          {state.partner_setup}
        </Link>
      ),
    },
    {
      title: 'Kick Off',
      element: (
        <Link
          to={{
            pathname: RoutePath.SELLERS,
            search: createQueryString({
              status: SellerStatus.KICK_OFF,
            }),
          }}
        >
          {state.kick_off}
        </Link>
      ),
    },
    {
      title: 'Data Integration',
      element: (
        <Link
          to={{
            pathname: RoutePath.SELLERS,
            search: createQueryString({
              status: SellerStatus.DATA_INTEGRATION,
            }),
          }}
        >
          {state.data_integration}
        </Link>
      ),
    },
    {
      title: 'Ramp Up',
      element: (
        <Link
          to={{
            pathname: RoutePath.SELLERS,
            search: createQueryString({
              status: SellerStatus.RAMP_UP,
            }),
          }}
        >
          {state.ramp_up}
        </Link>
      ),
    },
    {
      title: 'Onboarding Complete',
      element: (
        <Link
          to={{
            pathname: RoutePath.SELLERS,
            search: createQueryString({
              status: SellerStatus.ONBOARDING_COMPLETE,
            }),
          }}
        >
          {state.onboarding_complete}
        </Link>
      ),
    },
    {
      title: 'Established',
      element: (
        <Link
          to={{
            pathname: RoutePath.SELLERS,
            search: createQueryString({
              status: SellerStatus.ESTABLISHED,
            }),
          }}
        >
          {state.established}
        </Link>
      ),
    },
    {
      title: 'Suspended',
      element: (
        <Link
          to={{
            pathname: RoutePath.SELLERS,
            search: createQueryString({
              status: SellerStatus.SUSPENDED,
            }),
          }}
        >
          {state.suspended}
        </Link>
      ),
    },
    {
      title: 'Defunct',
      element: (
        <Link
          to={{
            pathname: RoutePath.SELLERS,
            search: createQueryString({
              status: SellerStatus.DEFUNCT,
            }),
          }}
        >
          {state.defunct}
        </Link>
      ),
    },
  ]

  return (
    <DisplayCard
      title="Partners"
      isLoading={state.loading}
      iconColor="target"
      icon={GroupIcon}
      hasWarning={state.error}
      warningTooltip="An error occurred while getting the information."
    >
      {state.error ? (
        <Text>{GENERIC_API_ERROR}</Text>
      ) : (
        <TabularData
          borderTop
          extraPadding
          hasHeader={false}
          fieldList={fieldList}
          data={data}
        />
      )}
    </DisplayCard>
  )
}

export default SellersCard
