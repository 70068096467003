import Typography from '@mui/material/Typography'

import TableSpacer from 'components/common/TableSpacer'
import TabularData, { FieldList } from 'components/common/TabularData'
import Text from 'components/common/Text'

import {
  formatDate,
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
} from 'services/dateService'

import { Logistics } from 'types/ProductLogistics'
export type Props = {
  logistics: Logistics[]
  lastModified: string | undefined
}

const fieldList: FieldList<Logistics>[] = [
  {
    key: 'name',
    displayName: 'name',
  },
  {
    key: 'value',
    displayName: 'Value',
  },
  {
    key: 'status',
    displayName: 'Status',
    formatCell: (item) => {
      if (item.message) {
        return <Text type="error">{item.message}</Text>
      }

      return item.status
    },
  },
]

const getLogisticsTitle = (
  logistics: Logistics[],
  date: string | undefined,
) => {
  if (!logistics.length) {
    return 'No data provided, default values will be applied by Target for return policy, 2 day shipping eligibility, add-on, and shipping exclusions.'
  } else if (logistics.length && date) {
    return `Last update sent on ${formatDate(
      date,
      DATE_FORMAT_MONTH_DAY_YEAR_TIME,
    )}. If null or invalid values are sent defaults
  will be applied by Target.`
  }
}

export const LogisticsData = ({ logistics, lastModified }: Props) => {
  const title = getLogisticsTitle(logistics, lastModified)

  return (
    <div>
      <Typography>{title}</Typography>
      {logistics.length > 0 && (
        <>
          <TableSpacer />
          <TabularData data={logistics} fieldList={fieldList} />
        </>
      )}
    </div>
  )
}
