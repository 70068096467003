import { ComponentType } from 'react'

import apiConfig, { ENVIRONMENT_PRODUCTION } from 'config/apiConfig'

import { DialogEnum } from 'components/common/Dialog'

import { SvgIconProps } from '@mui/material/SvgIcon'
import AlarmIcon from '@mui/icons-material/Alarm'
import AssignmentIcon from '@mui/icons-material/Assignment'
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import DateRangeIcon from '@mui/icons-material/DateRange'
import DnsIcon from '@mui/icons-material/Dns'
import GavelIcon from '@mui/icons-material/Gavel'
import ListIcon from '@mui/icons-material/List'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined'
import PriceCheckIcon from '@mui/icons-material/PriceCheck'
import RedoIcon from '@mui/icons-material/Redo'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import StoreIcon from '@mui/icons-material/Store'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import WarningIcon from '@mui/icons-material/Warning'
import WcIcon from '@mui/icons-material/Wc'

import {
  USER_ROLE_ADMIN,
  USER_ROLE_APP_SMS_ADMIN,
  USER_ROLE_ACQUISITIONS,
  USER_ROLE_COMMUNICATIONS,
  USER_ROLE_DIGITAL_EXP_SUPPORT,
  USER_ROLE_FINANCE,
  USER_ROLE_ITEM_APPROVER,
  USER_ROLE_OPS,
  USER_ROLE_PROGRAM_MANAGER,
  USER_ROLE_REPORTER,
  USER_ROLE_ITEM_MANAGER,
} from 'services/roles'
import { approvedToListStatuses } from 'services/seller'

import { ReportType } from 'types/Report'
import { SellerStatus } from 'types/Seller'

interface ReportCardData {
  title: string
  description: string
  icon?: ComponentType<SvgIconProps>
  hasDateRange?: boolean
  hasTable?: boolean
  isInternal?: boolean
  dialogEnum?: DialogEnum
  generateText?: string
  completedText?: string
  limitToRoles?: string[]
  limitToStatus?: SellerStatus[]
  templateLink?: string
  templateName?: string
}

const logisticErrorsDescription: string =
  'All items with logistics data that have errors.'

export const cardData: Record<string, ReportCardData> = {
  [ReportType.ASSORTMENT_ANALYSIS]: {
    title: 'Assortment Analysis',
    description: `Provides insights into a partner's assortment.`,
    icon: ListIcon,
    dialogEnum: DialogEnum.ASSORTMENT_ANALYSIS_REPORT,
    hasTable: true,
    generateText: 'Upload and Generate Report ',
    completedText: 'Analysis Complete',
    templateLink: `${apiConfig.sms}/templates/assortment_analysis_template.xlsx`,
    templateName: 'assortment_analysis_template.xlsx',
  },
  [ReportType.ATTRIBUTE_VALUES]: {
    title: 'Item Attributes and Values',
    description:
      'Provides the attributes and available values for a given Item Type.',
    hasTable: true,
    icon: DnsIcon,
    dialogEnum: DialogEnum.ITEM_ATTRIBUTES_AND_VALUES,
  },
  [ReportType.AUTO_APPROVAL_BLOCKED_LIST]: {
    title: 'Auto Approval Item Type Holds',
    description:
      'Item types which require manual review for partners on auto approval.',
    icon: RemoveCircleOutlineIcon,
  },
  [ReportType.BULK_PRODUCT_DATA_TEMPLATE]: {
    title: 'Item Type Template',
    description:
      'Search for and download an Item Type Template for your items.',
    dialogEnum: DialogEnum.ITEM_TYPE_SEARCH_DIALOG,
    hasTable: true,
    limitToRoles: [
      USER_ROLE_ADMIN,
      USER_ROLE_OPS,
      USER_ROLE_APP_SMS_ADMIN,
      USER_ROLE_ITEM_MANAGER,
      USER_ROLE_ITEM_APPROVER,
    ],
  },
  [ReportType.BULK_PRODUCT_DATA]: {
    title: 'Upload Items',
    description: 'Upload the populated item template for item creation.',
    dialogEnum: DialogEnum.ITEM_TYPE_UPLOAD_REPORT,
    generateText: 'Upload Items',
    hasTable: true,
    limitToRoles:
      apiConfig.environment === ENVIRONMENT_PRODUCTION
        ? [USER_ROLE_ADMIN, USER_ROLE_APP_SMS_ADMIN]
        : [USER_ROLE_ADMIN, USER_ROLE_OPS, USER_ROLE_APP_SMS_ADMIN],
    limitToStatus: approvedToListStatuses,
  },
  [ReportType.ALLOWED_ITEM_TYPES]: {
    title: 'Allowed Item Types',
    description: 'Item types which a partner is allowed to submit items under.',
    icon: VerifiedUserIcon,
  },
  [ReportType.VALID_RETURN_DISPUTES]: {
    title: 'Approved Return Dispute Financials',
    description: 'Approved disputes that need to be paid out in a time period.',
    icon: PriceCheckIcon,
    dialogEnum: DialogEnum.APPROVED_RETURN_DISPUTE_FINANCIALS_DIALOG,
    hasDateRange: true,
    hasTable: true,
    isInternal: true,
  },
  [ReportType.INVENTORY]: {
    title: 'Inventory and Price',
    description: 'Item inventory and price information.',
    icon: ShoppingCartIcon,
  },
  [ReportType.ITEM_ERRORS]: {
    title: 'Item Errors',
    description: 'All items which the latest data update status is rejected.',
    icon: RemoveShoppingCartIcon,
  },
  [ReportType.MARKETPLACE_USERS]: {
    title: 'Partner Contact List',
    description:
      'A list of all the partner contacts within the portal across all partners.',
    icon: SupervisorAccountIcon,
    dialogEnum: DialogEnum.PARTNER_CONTACT_REPORT_DIALOG,
  },
  [ReportType.ORDERS]: {
    title: 'Orders',
    description: 'Order details across the various order statuses.',
    icon: LocalShippingIcon,
    dialogEnum: DialogEnum.ORDERS_REPORT,
    hasDateRange: true,
  },
  [ReportType.ORDERS_MISSING_TRANSFERS]: {
    title: 'Unpaid Orders',
    description:
      'Orders which have not yet received an associated Stripe transfer.',
    icon: CreditCardIcon,
    dialogEnum: DialogEnum.DATE_AND_SELLER_REPORT_DIALOG,
    hasDateRange: true,
    isInternal: true,
    limitToRoles: [
      USER_ROLE_ADMIN,
      USER_ROLE_ACQUISITIONS,
      USER_ROLE_COMMUNICATIONS,
      USER_ROLE_ITEM_APPROVER,
      USER_ROLE_OPS,
      USER_ROLE_PROGRAM_MANAGER,
      USER_ROLE_REPORTER,
      USER_ROLE_DIGITAL_EXP_SUPPORT,
      USER_ROLE_FINANCE,
    ],
  },
  [ReportType.SALES_METRICS]: {
    title: 'Sales Metrics',
    description: 'GMV & conversion rate data for all items with sales.',
    icon: TrendingUpIcon,
    dialogEnum: DialogEnum.SALES_METRICS_REPORT,
    hasTable: true,
    hasDateRange: true,
    limitToRoles: [
      USER_ROLE_ADMIN,
      USER_ROLE_OPS,
      USER_ROLE_APP_SMS_ADMIN,
      USER_ROLE_FINANCE,
      USER_ROLE_PROGRAM_MANAGER,
      USER_ROLE_REPORTER,
      USER_ROLE_ITEM_APPROVER,
      USER_ROLE_ITEM_MANAGER,
    ],
  },
  [ReportType.PAYOUT_RECONCILIATION]: {
    title: 'Financial Reconciliation',
    description: `Each payout's associated orders, payments, debits, services/fees,
    and taxes.`,
    icon: MonetizationOnIcon,
    dialogEnum: DialogEnum.FINANCIAL_RECONCILIATION_REPORT,
    hasDateRange: true,
    limitToRoles: [
      USER_ROLE_ADMIN,
      USER_ROLE_OPS,
      USER_ROLE_APP_SMS_ADMIN,
      USER_ROLE_FINANCE,
      USER_ROLE_PROGRAM_MANAGER,
    ],
  },
  [ReportType.RETURN_ORDERS_EXTERNAL]: {
    title: 'Returns',
    description: 'Provides Item level return details.',
    icon: RedoIcon,
    dialogEnum: DialogEnum.RETURN_ORDERS_REPORT,
    hasDateRange: true,
  },
  [ReportType.RETURN_ORDERS_INTERNAL]: {
    title: 'Returns Internal',
    description:
      'Returns information for a partner including tracking number and status.',
    icon: RedoIcon,
    dialogEnum: DialogEnum.DATE_AND_SELLER_REPORT_DIALOG,
    hasDateRange: true,
    hasTable: true,
    isInternal: true,
  },
  [ReportType.PRODUCT_LOGISTIC_ERRORS]: {
    title: 'Logistic Errors',
    description: logisticErrorsDescription,
    icon: AssignmentLateIcon,
  },
  [ReportType.PRODUCT_LOGISTIC_ERRORS_INTERNAL]: {
    title: 'All Logistic Errors',
    description: logisticErrorsDescription,
    icon: AssignmentLateIcon,
  },
  [ReportType.INFORM_ACT]: {
    title: 'INFORM Act Attributes',
    description:
      'HQ address, general email, phone #, marketplace & product sourcing info from all partners.',
    icon: StoreIcon,
  },
  [ReportType.SELLER_OPERATIONS_DETAILS]: {
    title: 'Partner Operations Details',
    description:
      'Partner details associated with operating days, hours and shipping information.',
    icon: DateRangeIcon,
  },
  [ReportType.ORDERS_PAST_SLA]: {
    title: 'Orders Past SLA',
    description:
      'Provides list of unacknowledged or partially shipped orders, which have gone past their SLA.',
    icon: AlarmIcon,
    dialogEnum: DialogEnum.DAYS_PAST_SLA,
  },
  [ReportType.PARTNER_PRICE_DETAILS]: {
    title: 'Price Change Details',
    description:
      'List price, offer price, MAP and discount with update date, status and inventory across partners.',
    icon: MonetizationOnIcon,
    dialogEnum: DialogEnum.PRICE_INVENTORY_REPORT,
  },
  [ReportType.PARTNER_FINANCIAL_DETAILS]: {
    title: 'Finance Details',
    description: 'Partner Stripe and tax info.',
    icon: MonetizationOnOutlinedIcon,
  },
  [ReportType.ORDER_DEFECTS]: {
    title: 'Order Defects',
    description: 'Defective orders & details for each defect category.',
    hasTable: true,
    icon: WarningIcon,
    dialogEnum: DialogEnum.ORDER_DEFECT_RATE_REPORT_DIALOG,
  },
  [ReportType.PARTNER_DIVERSITY]: {
    title: 'Diverse Partners',
    description:
      'Information pertaining to partner diversity & certifications if any.',
    icon: WcIcon,
  },
  [ReportType.PARTNER_RETURN_POLICIES]: {
    title: 'Partner Return Policies',
    description:
      'Return dispositions, return addresses & more for all partners.',
    icon: AssignmentIcon,
  },
  [ReportType.PARTNER_QUOTA_COUNTS]: {
    title: 'Item Quota & Counts',
    description:
      'Quotas, current count, counts of published in stock & out of stock, counts of unlisted',
    icon: GavelIcon,
  },
}
