import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'

import DialogContainer from 'components/common/Dialog/DialogContainer'

const StyledDescription = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))

const StyledErrorMessage = styled(Typography)({
  color: '#AF491E',
})

interface Props {
  isOpen: boolean
  onClose: () => void
}

export const ErrorNotSavedDialog = ({ isOpen, onClose }: Props) => {
  return (
    <DialogContainer
      data-testid="error-not-saved-dialog"
      isOpen={isOpen}
      title="ERROR: SUSPENSION REASON COULD NOT BE SAVED"
      closeButtonText="OK"
      onSubmit={onClose}
      maxWidth="md"
    >
      <StyledDescription>
        This item has been suspended and any new versions will be blocked.
      </StyledDescription>
      <StyledErrorMessage>
        However, the reason for suspension could not be saved. Please contact
        the Target Plus product team for support.
      </StyledErrorMessage>
    </DialogContainer>
  )
}

export default ErrorNotSavedDialog
