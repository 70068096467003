import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'

import ContentSpacer from 'components/common/ContentSpacer'

import { ReturnDispute } from 'types/disputes'
import { ReturnTrackingData } from 'types/Orders'
const StyledTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
}))

interface Props {
  returnDispute: ReturnDispute
  tracking?: ReturnTrackingData
}

const SystemMessage = ({ returnDispute, tracking }: Props) => {
  return (
    <>
      <ContentSpacer />
      <StyledTypography>System Message:</StyledTypography>
      {tracking && (
        <Typography>
          {returnDispute.quantity} item(s) was destroyed in store.
        </Typography>
      )}
      <Typography>{returnDispute.final_response_description}.</Typography>
    </>
  )
}

export default SystemMessage
