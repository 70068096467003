import styled from '@emotion/styled'
import { Chip, Tooltip, Typography } from '@mui/material'

import { grey, success, error, white } from 'config/themeConfig'

import { PromotionStatus } from 'types/Promotion'

export interface ComponentProps {
  status: string
  upstreamStatus?: string
  isInline?: boolean
}

const StyledFlexContainer = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
})

const PromotionStatusChip = ({
  status,
  upstreamStatus,
  isInline,
}: ComponentProps) => {
  let tooltipText: string
  let backgroundColor: string
  let color: string
  let border: string

  const partnerVisible = status === PromotionStatus.PARTNER_VISIBLE
  const partnerNotVisible = status === PromotionStatus.PARTNER_NOT_VISIBLE
  const deactivated = upstreamStatus === 'deactivated'

  let statusText: string | undefined
  if (status) {
    statusText = status.replaceAll('_', ' ')
  }

  if (deactivated && partnerVisible) {
    tooltipText =
      'This promotion is visible to partners, but has been deactivated in IVY.'
    backgroundColor = success.light
    color = grey[900]
    border = `1px solid ${error.main}`
  } else if (deactivated && partnerNotVisible) {
    tooltipText =
      'This promotion is not visible to partners, and has been deactivated in IVY.'
    backgroundColor = white.main
    color = grey[900]
    border = `1px solid ${error.main}`
  } else if (partnerNotVisible) {
    tooltipText =
      'This promotion will not display on the partner-facing promotions page.'
    backgroundColor = white.main
    color = grey[900]
    border = `1px solid ${grey[900]}`
  } else if (partnerVisible) {
    tooltipText =
      'This promotion will display on the partner-facing promotions page.'
    backgroundColor = success.main
    color = white.main
    border = 'none'
  }

  function getChip() {
    return (
      <>
        {deactivated && (
          <Typography
            color="error"
            textAlign="center"
            sx={{ paddingBottom: 1, fontWeight: 700 }}
          >
            Deactivated in IVY
          </Typography>
        )}
        <Tooltip title={tooltipText}>
          <Chip
            label={statusText}
            style={{
              backgroundColor,
              color,
              border,
              width: '100%',
            }}
          />
        </Tooltip>
      </>
    )
  }

  return (
    <>
      {isInline ? (
        <StyledFlexContainer>{getChip()}</StyledFlexContainer>
      ) : (
        getChip()
      )}
    </>
  )
}

export default PromotionStatusChip
