import axios from 'axios'
import apiConfig from 'config/apiConfig'

export const getDivisions = () => {
  const config = {
    params: {
      per_page: 1000,
      // Do not pass page param to this API! It does not work as expected.
    },
  }

  return axios
    .get(`${apiConfig.itemGroups}/merchandise_hierarchy_divisions`, config)
    .then((res) => {
      return res.data
    })
}
