import styled from '@emotion/styled'

import LockIcon from '@mui/icons-material/Lock'
import LinkButton from 'components/common/LinkButton'

const StyledWrapper = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  position: 'absolute',
  top: '0',
  width: '100%',
}))

const StyledContent = styled('div')(() => ({
  boxSizing: 'border-box',
  margin: '0 auto',
  maxWidth: '320px',
  padding: '20px 30px',
  textAlign: 'center',
}))

const StyledHeading = styled('div')(() => ({
  fontWeight: 300,
  fontSize: '34px',
  margin: '0',
  opacity: 0.75,
}))

const StyledSubheading = styled('div')(() => ({
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '1.5',
  margin: '1.8em 10px 2em',
  opacity: 0.5,
}))

export const ERROR_MESSAGE_NOT_AUTHORIZED_TITLE = 'Not Authorized'
export const ERROR_MESSAGE_NOT_AUTHORIZED_PAGE = `You're not authorized to view this page.
      This may be because your email address is already being used for a Target Partners Online account.
      Please inform Target + support that you saw this message.`

const NotAuthorized = () => {
  return (
    <StyledWrapper>
      <StyledContent>
        <StyledHeading>{ERROR_MESSAGE_NOT_AUTHORIZED_TITLE}</StyledHeading>
        <StyledSubheading>{ERROR_MESSAGE_NOT_AUTHORIZED_PAGE}</StyledSubheading>
        <LinkButton
          role="link"
          to="/"
          title={ERROR_MESSAGE_NOT_AUTHORIZED_TITLE}
          aria-label={ERROR_MESSAGE_NOT_AUTHORIZED_TITLE}
          color="primary"
        >
          <LockIcon />
        </LinkButton>
      </StyledContent>
    </StyledWrapper>
  )
}

export default NotAuthorized
