import TableCell, { TableCellProps } from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'

import { Direction } from 'services/pageableHelper'
import { VerticalAlign } from './index'

import { grey } from 'config/themeConfig'
interface Props extends Omit<TableCellProps, 'classes'> {
  active?: boolean
  isUpdatedTable?: boolean
  children?: React.ReactNode
  columns?: number
  direction?: Direction
  hasSort?: boolean
  label?: string
  onClick?: any
  minWidth?: number
  width?: number
  verticalAlign?: VerticalAlign
  cellPadding?: string
  variant?: 'head' | 'body' | 'footer'
}

const defaultStyle = {
  minWidth: undefined,
  width: undefined,
  overflowWrap: 'break-word',
}

export const EnhancedTableCell = ({
  active,
  children,
  columns = 1,
  direction,
  hasSort,
  label,
  onClick,
  minWidth,
  width,
  verticalAlign,
  cellPadding,
  variant,
  isUpdatedTable,
  ...rest
}: Props) => {
  const style: any =
    minWidth || width
      ? {
          ...defaultStyle,
          minWidth: `${minWidth}px`,
          width: `${width}%`,
        }
      : defaultStyle

  if (hasSort && onClick) {
    const directionLabel =
      direction === Direction.ASC ? 'ascending' : 'descending'
    const sortLabel = `sort by ${label} ${directionLabel}`

    // Use the sx prop because the MuiTableCell style overrides in config/themeConfig has priority
    // over a styled(TableCell) component but sx takes predence over themeConfig overrides.
    // This leads to strange behavior since not all css properties are accessible on the sx prop
    // Ex: overflow* has to be passed in to the style prop instead of sx or styled(TableCell)
    return (
      <TableCell
        sx={{
          fontWeight: isUpdatedTable ? 'bold' : 500,
          fontSize: '0.9375rem',
          overflowWrap: 'break-word',
          '& :focus': {
            outline: '1px dashed black',
            outlineOffset: '4px',
          },
          borderBottomWidth: '2px',
          color: grey[700],
        }}
        colSpan={columns}
        style={style}
        {...rest}
      >
        <TableSortLabel
          sx={{
            flexWrap: 'wrap',
          }}
          active={active}
          aria-label={sortLabel}
          data-element="table-sortable-cell"
          data-testid="enhanced-table-sortable-cell"
          direction={direction}
          onClick={onClick}
        >
          {children}
        </TableSortLabel>
      </TableCell>
    )
  }

  return (
    <TableCell
      sx={{
        fontWeight: isUpdatedTable ? 'bold' : 500,
        color: grey[700],
        padding: cellPadding,
        borderBottomWidth: variant === 'body' ? 1 : 2,
        verticalAlign,
      }}
      colSpan={columns}
      data-element="table-cell"
      data-testid="enhanced-table-cell"
      style={style}
      variant={variant}
      {...rest}
    >
      {children}
    </TableCell>
  )
}

export default EnhancedTableCell
