import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import styled from '@emotion/styled'
import BullseyeWithTarget from 'components/common/Icons/BullseyeWithTarget'
import checkIsIe from 'services/browserUtils'
import ChatBotWidget from 'components/common/Footer/ChatBotWidget'

const isIe = checkIsIe(navigator.userAgent)

const StyledFooter = styled(Grid)(({ theme }) => ({
  width: '100%',
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  textAlign: 'center',
}))

const StyledLogo = styled(BullseyeWithTarget)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  height: isIe ? 50 : 'auto',
}))
export const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <StyledFooter
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      data-testid="footer"
      role="contentinfo"
    >
      <Grid item sx={{ fontSize: '0.75rem' }}>
        &trade; &amp; &copy; {currentYear} target brands, inc.
      </Grid>
      <Grid item>
        <StyledLogo fill="#C00" width={40} />
      </Grid>
      <Grid item>
        <Typography variant="body2" align="center">
          Expect More. Pay Less.
        </Typography>
      </Grid>
      <ChatBotWidget />
    </StyledFooter>
  )
}

export default Footer
