import React, { PropsWithChildren } from 'react'
import kebabCase from 'lodash/fp/kebabCase'

import styled from '@emotion/styled'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import { SvgIconProps } from '@mui/material/SvgIcon'

import DisplayCardContent from './DisplayCardContent'
import DisplayCardHeader from './DisplayCardHeader'

type StyledProps = {
  fullHeight?: boolean
  fullWidth?: boolean
}

const StyledCardRoot = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'fullHeight' && prop !== 'fullWidth',
})<StyledProps>((props) => ({
  overflow: 'visible',
  height: props.fullHeight ? '100%' : '',
  width: props.fullHeight ? '100%' : '',
}))

export interface Props {
  className?: string
  title: string
  sidecar?: React.ReactNode
  icon?: React.ComponentType<SvgIconProps>
  iconColor?: 'target' | 'success' | 'error'
  description?: string
  fullHeight?: boolean
  fullWidth?: boolean
  noGutter?: boolean
  isLoading?: boolean
  hasWarning?: boolean
  warningTooltip?: string
  actions?: React.ReactNode
  actionsStyles?: object
  collapsible?: boolean
  defaultExpanded?: boolean
  hasHeader?: boolean
  headerActions?: React.ReactNode
  topLevelComponent?: React.ReactNode
}

export const DisplayCard: React.FC<PropsWithChildren<Props>> = ({
  className,
  title,
  sidecar,
  icon,
  iconColor,
  description,
  noGutter,
  isLoading,
  hasWarning,
  warningTooltip,
  fullHeight = true,
  fullWidth,
  actions,
  actionsStyles,
  children,
  collapsible = false,
  defaultExpanded = false,
  hasHeader = true,
  headerActions,
  topLevelComponent,
}) => {
  const [expanded, setExpanded] = React.useState(
    !collapsible || defaultExpanded,
  )

  return (
    <StyledCardRoot
      className={className}
      fullHeight={fullHeight}
      fullWidth={fullWidth}
      data-testid={`${kebabCase(title)}-report-card`}
    >
      {topLevelComponent}
      {hasHeader && (
        <DisplayCardHeader
          collapsible={collapsible}
          description={description}
          expanded={expanded}
          icon={icon}
          iconColor={iconColor}
          onRequestExpand={() => setExpanded(!expanded)}
          title={title}
          sidecar={sidecar}
          headerActions={headerActions}
        />
      )}
      <Collapse data-testid="display-card-collapse" in={expanded}>
        <DisplayCardContent
          hasWarning={hasWarning!}
          isLoading={isLoading!}
          noGutter={noGutter!}
          title={title}
          warningTooltip={warningTooltip}
        >
          {children}
        </DisplayCardContent>
        {actions && <CardActions sx={actionsStyles}>{actions}</CardActions>}
      </Collapse>
    </StyledCardRoot>
  )
}

export default DisplayCard
