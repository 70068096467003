import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import Grid from '@mui/material/Grid'

import { TaxonomyTypeahead } from 'components/common/Typeahead'
import Input from 'components/common/Input'

import Attribute, { ITEM_TYPE, ITEM_SUBTYPE } from 'types/Attribute'
import { Validation } from 'types/Validation'

export interface Props {
  attribute: Nullable<Attribute>
  percent: string
  isTypeaheadDisabled: boolean
  validation: Validation
  isValid: boolean
  isBaseFee: boolean
  onRequestChange: ({
    type,
  }: {
    type: 'attribute' | 'percent'
  }) => (value: Nullable<Attribute | string>) => void
  onRequestCancel: () => void
  onRequestSubmit: () => void
}

export const EditReferralFeesAside = ({
  attribute,
  percent,
  isTypeaheadDisabled,
  validation,
  isValid,
  isBaseFee,
  onRequestChange,
  onRequestCancel,
  onRequestSubmit,
}: Props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TaxonomyTypeahead
              placeholder={`Select a ${isBaseFee ? 'Subtype' : 'Item Type'}`}
              searchType={isBaseFee ? ITEM_SUBTYPE : ITEM_TYPE}
              onChange={onRequestChange({ type: 'attribute' })}
              value={attribute}
              isDisabled={isTypeaheadDisabled}
              clearOnSelect={false}
              name="attribute"
              validation={validation}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input
              id="percent"
              name="percent"
              type="number"
              value={percent}
              isDisabled={!attribute || !attribute.id}
              validation={validation}
              onChange={onRequestChange({ type: 'percent' })}
              helperText={`${
                isBaseFee ? 'Base' : 'Referral'
              } Fee Percentage, ex: 15`}
              inputProps={{
                min: 0,
                max: 99,
                step: 1,
                maxLength: 2,
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DialogActions>
              <Button
                color="primary"
                onClick={onRequestCancel}
                data-testid="cancel"
              >
                cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={onRequestSubmit}
                disabled={!isValid}
                data-testid="submit"
              >
                save
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EditReferralFeesAside
