import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import FullScreenDialogContainer from 'components/common/Dialog/FullScreenDialogContainer'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import { formatDateMDYT } from 'components/common/EnhancedTable/formatters'

import { deleteApiToken, getApiTokens } from 'services/apiTokens'
import { isUserRole } from 'services/authorization'
import { USER_ROLE_OPS } from 'services/roles'

import { closeDialog } from 'store/dialog/actionCreator'
import { getMemberOf } from 'store/selectors'

import { ApiToken } from 'types/ApiTokens'
import { SmsSeller } from 'types/Seller'

import AddApiTokensAside from './AddApiTokensAside'

const StyledButtonContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
})

const StyledTypography = styled(Typography)(
  ({ theme }) => ({
    marginBottom: theme.spacing(2),
  }),
  (props) => ({
    variant: props.variant,
  }),
)

interface Props {
  seller: SmsSeller
  isOpen: boolean
}

const fieldList: EnhancedTableFieldType<ApiToken>[] = [
  {
    key: 'created',
    heading: 'Created Date',
    minWidth: 30,
    formatCell: formatDateMDYT('created'),
  },
  {
    key: 'description',
    heading: 'Description',
    minWidth: 35,
  },
  {
    key: 'last_used',
    heading: 'Last Used',
    minWidth: 25,
    formatCell: formatDateMDYT('last_used', 'Never'),
  },
  {
    key: 'delete',
    heading: '',
    minWidth: 10,
    formatCell: ({ id }, context: any) => (
      <Button
        data-testid="delete-token-button"
        color="primary"
        onClick={context.handleDelete(id)}
      >
        Delete
      </Button>
    ),
  },
]

const EditApiTokensDialog = ({ seller, isOpen }: Props) => {
  const memberOf = useSelector(getMemberOf)

  const dispatch = useDispatch()

  const [isPending, setIsPending] = React.useState(false)
  const [isAsideOpen, setIsAsideOpen] = React.useState(false)
  const [tokens, setTokens] = React.useState<ApiToken[]>([])
  const [tokenGenerated, setTokenGenerated] = React.useState(false)

  const fetchApiTokens = React.useCallback(() => {
    setIsPending(true)
    getApiTokens(seller.id).then((tokens) => {
      setTokens(tokens)
      setIsPending(false)
    })
  }, [seller.id])

  React.useEffect(() => {
    if (seller?.id) {
      fetchApiTokens()
    }
  }, [seller.id, fetchApiTokens])

  const handleRequestClose = () => {
    let confirm = true

    if (tokenGenerated) {
      confirm = window.confirm(
        'Remember to store your token somewhere secure, the full token will never be displayed again. Are you sure you want to proceed?',
      )
    }

    if (confirm) {
      dispatch(closeDialog())
    }
  }

  const handleShowAside = () => {
    setIsAsideOpen(true)
  }

  const handleCloseAside = () => {
    setIsAsideOpen(false)
    setTokenGenerated(false)
  }

  const handleTokenGenerated = () => {
    setTokenGenerated(true)
    fetchApiTokens()
  }

  const handleDelete =
    (tokenId: string) =>
    (event: any): void => {
      if (!event) return

      if (
        window.confirm(
          `Delete terminates access to Target's platform using this token, are you sure you want to proceed?`,
        )
      ) {
        deleteApiToken(seller.id, tokenId).then(fetchApiTokens)
      }
    }

  const canGenerate = !isUserRole(memberOf, USER_ROLE_OPS)

  return (
    <FullScreenDialogContainer
      title="Edit API Tokens"
      onRequestClose={handleRequestClose}
      isOpen={isOpen}
      isAsideOpen={isAsideOpen}
      asideTitle="Generate New Token"
      aside={
        <AddApiTokensAside
          sellerId={seller.id}
          tokens={tokens}
          onCloseAside={handleCloseAside}
          onTokenGenerated={handleTokenGenerated}
        />
      }
    >
      <StyledTypography variant="h3">{seller.display_name}</StyledTypography>
      <Typography>
        API tokens are used to authenticate integration access, they can be used
        by a third party integrator on behalf of a partner and/or directly by a
        partner to access the Target Plus APIs. In order to generate an API
        token, you must be an admin user.
      </Typography>
      {canGenerate && (
        <StyledButtonContainer>
          <Button
            data-testid="open-aside-button"
            color="primary"
            onClick={handleShowAside}
          >
            + generate new token
          </Button>
        </StyledButtonContainer>
      )}
      <EnhancedTable
        isLoading={isPending}
        fieldList={fieldList}
        fieldListContext={{ handleDelete }}
        data={tokens}
      />
    </FullScreenDialogContainer>
  )
}

export default EditApiTokensDialog
