import MuiInput from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import ValidationErrorText from '../ValidationErrorText'

import { Validation } from 'types/Validation'
import { useEffect, useState } from 'react'

interface Props {
  id: string
  name: string // Should be a key in the validationSchema object
  value: string | number | undefined
  type?: string
  isRequired?: boolean
  isDisabled?: boolean
  isMultiline?: boolean
  shrink?: boolean
  placeholder?: string
  label?: string
  onBlur?: (value: string, name: string, id: string) => void
  onChange: (value: string, name: string, id: string) => void
  rows?: string
  validation?: Validation
  helperText?: string
  inputProps?: any
  inputRef?: any
  startAdornment?: any
  maxCharacters?: number
  variant?: 'standard' | 'outlined' | 'filled' | undefined
}

export const Input = ({
  id,
  name,
  value,
  type = 'text',
  isRequired = false,
  isDisabled = false,
  isMultiline = false,
  shrink = true,
  placeholder,
  label,
  onBlur,
  onChange,
  rows,
  validation,
  helperText,
  inputProps,
  inputRef,
  startAdornment,
  maxCharacters,
  variant = 'standard',
}: Props) => {
  const [hint, setHint] = useState<string>()
  const handleChange = (event: any) => {
    onChange(event.target.value, name, id)
  }
  const handleBlur = (event: any) => {
    if (onBlur) {
      onBlur(event.target.value, name, id)
    }
  }

  useEffect(() => {
    const currentValue = value?.toString()
    if (maxCharacters) {
      const len = currentValue?.length
        ? maxCharacters - currentValue?.length
        : maxCharacters
      const subject = len === 1 ? 'character' : 'characters'
      const updatedHint = `${len} ${subject} left (Maximum of ${maxCharacters}).`
      setHint(updatedHint)
    }
  }, [value, maxCharacters])

  return (
    <FormControl fullWidth required={isRequired} sx={{ minWidth: 120 }}>
      {label && (
        <InputLabel variant={variant} shrink={shrink} htmlFor={id}>
          {label}
        </InputLabel>
      )}
      <MuiInput
        id={id}
        value={value}
        inputRef={inputRef}
        placeholder={placeholder}
        disabled={isDisabled}
        multiline={isMultiline}
        rows={rows}
        onChange={handleChange}
        onBlur={handleBlur}
        inputProps={{
          'aria-label': label,
          'data-testid': id,
          ...inputProps,
        }}
        type={type}
        startAdornment={startAdornment}
      />
      {helperText ||
        (hint && (
          <FormHelperText id="name-error-text">
            {hint ? hint : helperText}
          </FormHelperText>
        ))}
      {validation && <ValidationErrorText errors={validation} field={name} />}
    </FormControl>
  )
}

export default Input
