import debounce from 'lodash/fp/debounce'
import { useEffect, useState } from 'react'

function useZoomTracking() {
  const [dimensions, setDimensions] = useState({
    outerWidth: window.outerHeight,
    innerWidth: window.innerWidth,
  })
  const [zoomLevel, setZoomLevel] = useState<number>()
  const [hasZoomIncreased, setHasZoomIncreased] = useState<boolean>(false)

  useEffect(() => {
    const handleResize = debounce(1000, () => {
      setDimensions({
        outerWidth: window.outerHeight,
        innerWidth: window.innerWidth,
      })
    })

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  useEffect(() => {
    const updatedZoomLevel =
      Math.round((dimensions.outerWidth / dimensions.innerWidth) * 100) / 100

    if (zoomLevel !== updatedZoomLevel) {
      if (updatedZoomLevel > 1) {
        setHasZoomIncreased(true)
      }
      if (updatedZoomLevel < 1) {
        setHasZoomIncreased(false)
      }
      setZoomLevel(updatedZoomLevel)
    }
  }, [zoomLevel, dimensions.outerWidth, dimensions.innerWidth])

  return hasZoomIncreased
}

export default useZoomTracking
