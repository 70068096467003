import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { currentSellerId, getReturnPolicies } from 'store/selectors'

import * as yup from 'yup'

import { success } from 'config/themeConfig'
import styled from '@emotion/styled'

import CheckCircle from '@mui/icons-material/CheckCircle'

import DialogContainer from 'components/common/Dialog/DialogContainer'

import { PhysicalDisposition, FinancialDisposition } from 'constants/categories'

import {
  editReturnPolicy,
  fetchSellerContacts,
} from 'store/seller/actionCreators'

import { DEFAULT_COUNTRY } from 'services/addressHelper'
import { validateAddress } from 'services/addressValidation'
import { trackCustomEvent } from 'services/fireflyInsights'
import { validationHandler, ValidationSchema } from 'services/validation'

import { SmsAddress } from 'types/Address'
import { FireflyEvent } from 'types/FireflyInsights'
import { SmsReturnPolicy, VendorCategory } from 'types/Seller'
import { Validation } from 'types/Validation'

import EditReturnPolicyDialogContent from './EditReturnPolicyDialogContent'

const StyledSuccessIconTitle = styled(CheckCircle)(({ theme }) => ({
  color: success.main,
  marginRight: theme.spacing(1),
}))

export enum AddressFields {
  ADDRESS_1 = 'address1',
  ADDRESS_2 = 'address2',
  CITY = 'city',
  COUNTRY = 'country_code',
  ZIP = 'postal_code',
  STATE = 'state',
}

export interface Props {
  isOpen: boolean
  vendorCategories: VendorCategory[]
  isExternalAdmin: boolean
  existingReturnPolicy?: SmsReturnPolicy
}

const validationSchema: ValidationSchema = yup.object().shape({
  financial_disposition_id: yup
    .number()
    .label('Financial disposition')
    .required(),
  physical_disposition_id: yup
    .number()
    .label('Physical disposition')
    .required(),
  return_seller_user_id: yup
    .string()
    .label('User Contact Name')
    .when('physical_disposition_id', {
      is: PhysicalDisposition.ReturnViaCrc,
      then: yup.string().required('Required'),
      otherwise: yup.string(),
    }),
  in_care_of: yup
    .string()
    .label('In Care Of')
    .max(35)
    .when('physical_disposition_id', {
      is: PhysicalDisposition.ReturnViaCrc,
      then: yup.string().required('Required'),
      otherwise: yup.string(),
    }),
  address1: yup
    .string()
    .label('Address Line 1')
    .when('physical_disposition_id', {
      is: PhysicalDisposition.ReturnViaCrc,
      then: yup.string().max(35).required('Required'),
      otherwise: yup.string(),
    }),
  city: yup
    .string()
    .label('City')
    .when('physical_disposition_id', {
      is: PhysicalDisposition.ReturnViaCrc,
      then: yup.string().required('Required'),
      otherwise: yup.string(),
    }),
  state: yup
    .string()
    .label('State')
    .when('physical_disposition_id', {
      is: PhysicalDisposition.ReturnViaCrc,
      then: yup.string().required('Required'),
      otherwise: yup.string(),
    }),
  postal_code: yup
    .string()
    .label('Zip Code')
    .when('physical_disposition_id', {
      is: PhysicalDisposition.ReturnViaCrc,
      then: yup.string().min(5).max(10).required('Required'),
      otherwise: yup.string(),
    }),
  country_code: yup
    .string()
    .label('Country')
    .when('physical_disposition_id', {
      is: PhysicalDisposition.ReturnViaCrc,
      then: yup.string().required('Required'),
      otherwise: yup.string(),
    }),
})

export enum ProgressState {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  VALIDATE = 'VALIDATE',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

const EditReturnPolicy = ({
  isOpen,
  vendorCategories,
  isExternalAdmin,
  existingReturnPolicy,
}: Props) => {
  const dispatch = useDispatch()

  const sellerId = useSelector(currentSellerId)
  const returnPolicies = useSelector(getReturnPolicies)

  const [returnPolicy, setReturnPolicy] = useState<Partial<SmsReturnPolicy>>(
    existingReturnPolicy ?? {
      in_care_of: '',
      return_seller_user_id: '',
      return_address: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        country_code: DEFAULT_COUNTRY,
        postal_code: '',
      },
    },
  )
  const [validation, setValidation] = useState<Validation>({})
  const [progress, setProgress] = useState<ProgressState>(ProgressState.IDLE)
  const [validatedAddresses, setValidatedAddresses] = useState<SmsAddress[]>([])
  const [selectedAddressIndex, setSelectedAddressIndex] = useState<number>(0)

  useEffect(() => {
    if (sellerId) {
      dispatch(fetchSellerContacts(sellerId))
    }

    if (!returnPolicy.id && isExternalAdmin) {
      handleInputChange(
        FinancialDisposition.ChargeToPartner,
        'financial_disposition_id',
      )
      handleInputChange(
        PhysicalDisposition.ReturnViaCrc,
        'physical_disposition_id',
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerId])

  const vmmId = returnPolicy?.vmm_id ?? ''

  const handleInputChange = (value: any, property: string) => {
    if (property === 'physical_disposition_id') {
      if (value === PhysicalDisposition.Destroy) {
        setReturnPolicy((prev) => ({
          ...prev,
          [property]: value,
          return_address: undefined,
        }))
      }
    }

    if (
      property === AddressFields.ADDRESS_1 ||
      property === AddressFields.ADDRESS_2 ||
      property === AddressFields.CITY ||
      property === AddressFields.COUNTRY ||
      property === AddressFields.ZIP ||
      property === AddressFields.STATE
    ) {
      setReturnPolicy((prev) => ({
        ...prev,
        return_address: {
          ...prev.return_address,
          [property]: value,
        },
      }))
    } else {
      setReturnPolicy((prev) => ({
        ...prev,
        [property]: value,
      }))
    }
  }

  const handleSubmit = () => {
    setProgress(ProgressState.PENDING)
    const { validation, isValid } = validationHandler(validationSchema, {
      financial_disposition_id: returnPolicy.financial_disposition_id,
      physical_disposition_id: returnPolicy.physical_disposition_id,
      return_seller_user_id: returnPolicy.return_seller_user_id,
      in_care_of: returnPolicy.in_care_of,
      address1: returnPolicy.return_address?.address1,
      city: returnPolicy.return_address?.city,
      state: returnPolicy.return_address?.state,
      postal_code: returnPolicy.return_address?.postal_code,
      country_code: returnPolicy.return_address?.country_code,
    })
    setValidation(validation)

    if (!isValid) {
      setProgress(ProgressState.IDLE)
      return
    }

    // don't allow a user to create a new return policy with the same
    // financial and phyiscal dispositions as an existing policy
    // unless the physical disposition is return to partner via CRC
    if (!returnPolicy.id && returnPolicies) {
      if (
        returnPolicy.physical_disposition_id !==
        PhysicalDisposition.ReturnViaCrc
      ) {
        const existingPolicy = returnPolicies.find((policy) => {
          return (
            policy.financial_disposition_id ===
              returnPolicy.financial_disposition_id &&
            policy.physical_disposition_id ===
              returnPolicy.physical_disposition_id
          )
        })

        if (existingPolicy && existingPolicy.status === 'ACTIVE') {
          window.alert(
            `Return Policy ${existingPolicy.vmm_id} already contains the specified Physical and Financial dispositions`,
          )
          setProgress(ProgressState.IDLE)
          return
        }
      }
    }

    if (returnPolicy.physical_disposition_id === PhysicalDisposition.Destroy) {
      handleSave()
    }

    if (returnPolicy.return_address) {
      // ignoring ts error here, error is because returnPolicy could be a partial, but
      // return_address won't be, which is what we care about here
      // @ts-ignore
      validateAddress(returnPolicy.return_address).then(
        ({ validated, validAddresses, error }) => {
          if (validated) {
            handleSave()
          } else if (validAddresses) {
            setValidatedAddresses(validAddresses)
            setProgress(ProgressState.VALIDATE)
          } else if (error) {
            setProgress(ProgressState.ERROR)
          }
        },
      )
    }
  }

  const handleSave = () => {
    if (selectedAddressIndex === 0) {
      dispatch(editReturnPolicy(returnPolicy))
    } else {
      dispatch(
        editReturnPolicy({
          ...returnPolicy,
          return_address: validatedAddresses[selectedAddressIndex - 1],
        }),
      )
    }
    if (progress === ProgressState.ERROR && returnPolicy.return_address) {
      trackCustomEvent(
        FireflyEvent.ADDRESS_VERIFICATION_FAIL,
        'RETURN_ADDRESS',
        Object.values(returnPolicy.return_address).join(),
      )
    }
    setProgress(ProgressState.SUCCESS)
  }

  const getDialogTitle = () => {
    if (progress === ProgressState.IDLE) {
      return existingReturnPolicy ? `Edit ${vmmId} Policy` : 'Add New Policy'
    } else if (progress === ProgressState.VALIDATE) {
      return 'Confirm Your Address'
    } else if (progress === ProgressState.SUCCESS) {
      return (
        <>
          <StyledSuccessIconTitle /> success! changes saved
        </>
      )
    } else if (progress === ProgressState.ERROR) {
      return 'address could not be verified'
    }
  }

  const getDialogSubmit = () => {
    if (progress === ProgressState.IDLE) {
      return handleSubmit
    } else if (progress === ProgressState.VALIDATE || ProgressState.ERROR) {
      return handleSave
    }
  }

  const handleRadioSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAddressIndex(
      parseInt((event.target as HTMLInputElement).value, 10),
    )
  }

  const handleBack = () => {
    setProgress(ProgressState.IDLE)
  }

  const getSubmitText = () => {
    switch (progress) {
      case ProgressState.IDLE:
        return 'Next'
      case ProgressState.VALIDATE:
        return 'Save'
      case ProgressState.ERROR:
        return 'Save Anyway'
    }
  }

  return (
    <DialogContainer
      title={getDialogTitle()}
      isOpen={isOpen}
      isPending={progress === ProgressState.PENDING}
      onSubmit={getDialogSubmit()}
      submitButtonText={getSubmitText()}
      hideActions={
        progress === ProgressState.PENDING || progress === ProgressState.SUCCESS
      }
      onPrevious={
        progress === ProgressState.ERROR || progress === ProgressState.VALIDATE
          ? handleBack
          : undefined
      }
      previousButtonText="Back"
      autoClose={progress === ProgressState.SUCCESS}
      maxWidth="xs"
    >
      <EditReturnPolicyDialogContent
        progress={progress}
        returnPolicy={returnPolicy}
        validatedAddresses={validatedAddresses}
        selectedAddressIndex={selectedAddressIndex}
        handleRadioSelect={handleRadioSelect}
        handleInputChange={handleInputChange}
        isExternalAdmin={isExternalAdmin}
        vendorCategories={vendorCategories}
        validation={validation}
      />
    </DialogContainer>
  )
}

export default EditReturnPolicy
