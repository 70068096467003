import React from 'react'

import styled from '@emotion/styled'
import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import ToolBar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/system'

const StyledIconSubContainer = styled(Icon)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingRight: theme.spacing(1),
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(3),
}))

interface Props {
  children?: React.ReactNode
  hideDivider?: boolean
  subLabel?: string
  title: string
  variant?:
    | 'inherit'
    | 'button'
    | 'overline'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | undefined
  actionButtons?: React.ReactNode[]
}

const TitleBar = ({
  children,
  hideDivider,
  subLabel,
  title,
  variant = 'h2',
  actionButtons,
}: Props) => {
  return (
    <div>
      <ToolBar disableGutters sx={{ alignItems: 'flex-end' }}>
        {children && (
          <StyledIconSubContainer data-testid="icon-test">
            {children}
          </StyledIconSubContainer>
        )}
        <Typography data-testid="title-bar-text" variant={variant}>
          {title}
        </Typography>
        {subLabel && <Typography variant="caption">{subLabel}</Typography>}
        {actionButtons && (
          <Box data-testid="title-bar-action" sx={{ marginLeft: 'auto' }}>
            {actionButtons}
          </Box>
        )}
      </ToolBar>
      {!hideDivider && <StyledDivider data-testid="divider-test" />}
    </div>
  )
}

export default TitleBar
