import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import apiConfig, {
  ENVIRONMENT_PRODUCTION,
  EXTERNAL_PROD_HOSTNAME,
  EXTERNAL_STAGE_HOSTNAME,
} from 'config/apiConfig'

import Grid from '@mui/material/Grid'
import FindInPageIcon from '@mui/icons-material/FindInPage'
import IconButton from '@mui/material/IconButton'

import VerifiedStatus from './VerifiedStatus'
import CopyLinkButton from 'components/common/CopyLinkButton'
import { DialogEnum } from 'components/common/Dialog'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import { formatDateMDYT } from 'components/common/EnhancedTable/formatters'
import FilterBar from 'components/common/FilterBar'
import MultiSelectFilter from 'components/common/FilterBar/MultiSelectFilter'
import TypeaheadFilter from 'components/common/FilterBar/TypeaheadFilter'
import {
  useSearchParams,
  TableState,
  getEnhancedTablePageableProps,
} from 'components/common/FilterBar/useSearchParams'
import HeaderTitle from 'components/common/HeaderTitle'
import TableSpacer from 'components/common/TableSpacer'
import TitleBar from 'components/common/TitleBar'

import { isOneOfUserRoles } from 'services/authorization'
import { USER_ROLE_ADMIN, USER_ROLE_OPS } from 'services/roles'
import { memoizedGetSeller } from 'services/seller'
import { getSellerVerifications } from 'services/sellerVerification'
import { RoutePath } from 'services/NavigationHelper'

import { openDialog } from 'store/dialog/actionCreator'
import { getMemberOf } from 'store/selectors'

import {
  SellerVerification,
  SellerVerificationStatus,
} from 'types/SellerVerification'

import startCase from 'lodash/fp/startCase'

type SearchParams = TableState & {
  status: SellerVerificationStatus | SellerVerificationStatus[] | undefined
  seller_id: string | undefined
}

const initialSearchParams: SearchParams = {
  perPage: 20,
  page: 0,
  status: undefined,
  seller_id: undefined,
}

export const InformActVerificationPage = () => {
  const reduxDispatch = useDispatch()

  const [searchParams, searchParamActions] =
    useSearchParams<SearchParams>(initialSearchParams)
  const [isPending, setIsPending] = useState(false)
  const [totalVerifications, setTotalVerifications] = useState(0)
  const [verifications, setVerifications] = useState<SellerVerification[]>([])
  const [refreshTrigger, setRefreshTrigger] = useState(0)

  useEffect(() => {
    let mounted = true
    setIsPending(true)

    getSellerVerifications(
      {
        page: searchParams.page,
        perPage: searchParams.perPage,
      },
      {
        seller_id: searchParams.seller_id,
        status: searchParams.status,
      },
    ).then(({ total, data }) => {
      if (mounted) {
        setVerifications(data)
        setTotalVerifications(total)
        setIsPending(false)
      }
    })

    return () => {
      mounted = false
    }
  }, [searchParams, refreshTrigger])

  const handleFilterClear = () => {
    searchParamActions.updateSearchParam(initialSearchParams)
  }

  let url
  if (apiConfig.environment === ENVIRONMENT_PRODUCTION) {
    url = `https://${EXTERNAL_PROD_HOSTNAME}${RoutePath.INFORM_ACT}`
  } else {
    url = `https://${EXTERNAL_STAGE_HOSTNAME}${RoutePath.INFORM_ACT}`
  }

  const memberOf = useSelector(getMemberOf)
  const canReview = isOneOfUserRoles(memberOf, [USER_ROLE_OPS, USER_ROLE_ADMIN])

  const handleReview = (sellerVerification: SellerVerification) => () => {
    reduxDispatch(
      openDialog({
        dialogEnum: DialogEnum.REVIEW_INFORM_ACT_ATTRIBUTES,
        componentProps: {
          sellerId: sellerVerification.seller_id,
        },
        closeCallback: () => setRefreshTrigger(refreshTrigger + 1),
      }),
    )
  }

  const fieldList: EnhancedTableFieldType<SellerVerification>[] = [
    {
      key: 'seller_id',
      heading: 'Partner Name',
      formatCellAsync: async (rowItem) => {
        const data = await memoizedGetSeller(rowItem.seller_id)
        if (data && data.display_name) return data.display_name
      },
    },
    {
      key: 'changes',
      heading: 'Any Changes',
      formatCell: (rowItem) => {
        const hasChanges = rowItem.has_changes ? 'Yes' : 'No'
        return <>{hasChanges}</>
      },
    },
    {
      key: 'status',
      heading: 'Verification Status',
    },
    {
      key: '',
      heading: 'Partner Status',
      formatCellAsync: async (rowItem) => {
        const data = await memoizedGetSeller(rowItem.seller_id)
        if (data) return <>{startCase(data.status)}</>
      },
    },
    {
      key: 'last_modified',
      formatCell: formatDateMDYT('last_modified'),
    },
    {
      key: 'last_modified_by',
    },
  ]

  if (canReview) {
    fieldList.push({
      key: 'id',
      heading: 'Review',
      formatCell: (rowItem) => {
        if (rowItem.status === SellerVerificationStatus.NEEDS_REVIEW) {
          return (
            <IconButton
              data-testid="review-button"
              color="primary"
              onClick={handleReview(rowItem)}
            >
              <FindInPageIcon />
            </IconButton>
          )
        }
      },
    })
  }

  const statusFilters = [
    {
      name: 'Pending',
      value: SellerVerificationStatus.PENDING,
    },
    {
      name: 'Needs Review',
      value: SellerVerificationStatus.NEEDS_REVIEW,
    },
    {
      name: 'Verified',
      value: SellerVerificationStatus.VERIFIED,
    },
  ]

  const handleFilterByStatus = (status: SellerVerificationStatus) => () => {
    searchParamActions.updateSearchParam({
      ...initialSearchParams,
      status,
    })
  }

  return (
    <div>
      <HeaderTitle title="Inform Act Verification" />
      <TitleBar
        title="Inform Act Verification"
        actionButtons={[
          <CopyLinkButton
            key="copy-link"
            title="copy link to verification form"
            successTitle="link copied!"
            link={url}
            variant="text"
          />,
        ]}
      />
      <VerifiedStatus onFilterByStatus={handleFilterByStatus} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FilterBar count={totalVerifications} onClear={handleFilterClear}>
            <Grid item xs={3} data-testid="partner-typeahead">
              <TypeaheadFilter
                value={searchParams.seller_id}
                searchParam="seller_id"
                onChange={searchParamActions.updateSearchParam}
              />
            </Grid>
            <Grid item xs={3}>
              <MultiSelectFilter
                placeholder="Verification Status"
                searchParam="status"
                value={searchParams.status}
                data={statusFilters}
                onChange={searchParamActions.updateSearchParam}
              />
            </Grid>
          </FilterBar>
          <TableSpacer>
            <EnhancedTable
              data={verifications}
              fieldList={fieldList}
              isLoading={isPending}
              total={totalVerifications}
              {...getEnhancedTablePageableProps(
                searchParams,
                searchParamActions,
              )}
            />
          </TableSpacer>
        </Grid>
      </Grid>
    </div>
  )
}

export default InformActVerificationPage
