import React from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import DateRangePicker from 'components/common/DateRangePicker'

import { getDateBefore } from 'services/dateService'
import { GenerateReportRequest } from 'services/reports'

import { closeDialog } from 'store/dialog/actionCreator'

const StyledRoot = styled('div')({
  minHeight: 150,
})
const StyledTypography = styled(Typography)({
  paddingBottom: 25,
})

export interface Props {
  isOpen: boolean
  onRequestSubmit: (reportParams: Partial<GenerateReportRequest>) => void
  dayRange?: number
  minDate?: Date
  disableDatesBefore?: Date
  reportName?: string
}

const DateRangeDialog = ({
  isOpen,
  onRequestSubmit,
  dayRange,
  minDate,
  disableDatesBefore,
  reportName,
}: Props) => {
  const now = new Date()
  const [isValid, setIsValid] = React.useState(true)
  const [fromDate, setFromDate] = React.useState(getDateBefore(now, 30, 'days'))
  const [toDate, setToDate] = React.useState(now)

  const dispatch = useDispatch()

  const handleDateChange = (from: Date, to: Date, isValid: boolean) => {
    setIsValid(isValid)
    setFromDate(from)
    setToDate(to)
  }

  const handleSubmit = () => {
    onRequestSubmit({
      startDate: fromDate,
      endDate: toDate,
      reportName: reportName ?? undefined,
    })

    dispatch(closeDialog())
  }

  return (
    <DialogContainer
      title="Select Report Date Range"
      isOpen={isOpen}
      isSubmitDisabled={!isValid}
      onSubmit={handleSubmit}
      submitButtonText="Generate"
      disableScroll
    >
      <StyledRoot data-testid="report-date-range">
        <StyledTypography>{`Select date range of up to ${
          dayRange ?? 90
        } days:`}</StyledTypography>
        <DateRangePicker
          isPending={false}
          from={fromDate}
          to={toDate}
          minDate={minDate}
          disabledDatesBefore={disableDatesBefore}
          dayRange={dayRange ?? 90}
          actionHandler={handleDateChange}
        />
      </StyledRoot>
    </DialogContainer>
  )
}

export default DateRangeDialog
