import { useState } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import FileUploader from 'components/common/FileUploader'
import { TaxonomyTypeahead } from 'components/common/Typeahead'

import { submitReport } from 'services/reports'

import { closeDialog } from 'store/dialog/actionCreator'

import Attribute, { ITEM_TYPE } from 'types/Attribute'
import { ReportParameters, ReportType, Report } from 'types/Report'

import { XLXS_CONTENT_TYPE } from 'constants/fileTypes'

import isEmpty from 'lodash/fp/isEmpty'

const StyledItemTypeDropdownBuffer = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(5),
}))
const StyledFileSelection = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}))

interface Props {
  isOpen: boolean
  sellerId: string
  parameters?: ReportParameters
  reportType: ReportType
  reportName: string
  onRequestSubmit: (report: Report) => void
}

export const ItemTypeUpload = ({
  isOpen,
  sellerId,
  reportType,
  onRequestSubmit,
}: Props) => {
  const dispatch = useDispatch()
  const [files, setFiles] = useState<Dictionary<File>>({})
  const [pending, setPending] = useState(false)
  const [itemType, setItemType] = useState<Attribute>()

  const title = pending ? 'Uploading... Please wait' : 'Upload Items'

  const handleItemTypeChange = (value: Nullable<Attribute>) => {
    if (!value) return
    setItemType(value)
  }

  const handleSubmit = async () => {
    if (!itemType) return

    setPending(true)

    try {
      const report = await submitReport({
        sellerId,
        type: reportType,
        parameters: {
          item_type_id: itemType.id,
        },
        reportName: itemType.name,
        format: 'EXCEL',
        reportInput: files[Object.keys(files)[0]],
      })
      onRequestSubmit(report)
    } catch (e) {
      console.error(`Error uploading report for partner ${sellerId}: ${e}`)
    } finally {
      setPending(false)
      dispatch(closeDialog())
    }
  }

  return (
    <DialogContainer
      title={title}
      isOpen={isOpen}
      onSubmit={handleSubmit}
      isSubmitDisabled={isEmpty(files) || !itemType}
      submitButtonText="Upload"
      isPending={pending}
    >
      <Grid container>
        <StyledItemTypeDropdownBuffer item xs={12}>
          <TaxonomyTypeahead
            searchType={ITEM_TYPE}
            placeholder="Item Type"
            onChange={handleItemTypeChange}
          />
        </StyledItemTypeDropdownBuffer>
        <StyledFileSelection item xs={12}>
          <FileUploader
            onSubmit={(files) => setFiles(files as Dictionary<File>)}
            accept={`.csv, ${XLXS_CONTENT_TYPE}`}
            pending={pending}
          />
        </StyledFileSelection>
      </Grid>
    </DialogContainer>
  )
}

export default ItemTypeUpload
