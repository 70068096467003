import { SyntheticEvent, useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Button, Typography } from '@mui/material'

import FullScreenDialogContainer from 'components/common/Dialog/FullScreenDialogContainer'
import Link from 'components/common/Link'

import useTable from 'components/common/EnhancedTable/useTable'

import ReferralFeeTable from '../ReferralFeeTable'
import { isFormDirty } from 'components/ReferralFees/isFormDirty'
import getValidationSchema from 'components/ReferralFees/validationSchema'
import EditReferralFeesAside from 'components/ReferralFees/EditReferralFeesAside'
import BaseReferralFeeTable from 'components/BaseReferralFeesPage/BaseReferralFeeTable'

import { closeDialog } from 'store/dialog/actionCreator'
import { currentSeller } from 'store/selectors'

import { RoutePath } from 'services/NavigationHelper'
import { PagingParams, Direction } from 'services/pageableHelper'
import {
  getReferralPercentages,
  buildReferralPercentage,
  updateReferralPercentages,
} from 'services/referralFees'
import { validationHandler } from 'services/validation'
import {
  getReferralBasePercentages,
  saveReferralBasePercentage,
} from 'services/referralBasePercentages'

import Attribute from 'types/Attribute'
import { ReferralBasePercentage } from 'types/BaseReferralFee'
import { ReferralPercentage } from 'types/ReferralFee'
import get from 'lodash/fp/get'

const StyledButtonContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
}))

const StyledRow = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

export interface Props {
  isOpen: boolean
  isBaseReferralFee: boolean
}

export const EditReferralFees = ({ isOpen, isBaseReferralFee }: Props) => {
  const { table } = useTable({
    direction: Direction.DESC,
    orderBy: 'start_date',
    page: 0,
    perPage: 20,
  })

  const globalDispatch = useDispatch()

  // referral fees table state
  const [referralFees, setReferralFees] = useState<
    ReferralPercentage[] | ReferralBasePercentage[]
  >([])
  const [totalReferralFees, setTotalReferralFees] = useState(0)
  const [isPending, setIsPending] = useState(false)

  // form state
  const [percent, setPercent] = useState('')
  const [attribute, setAttribute] = useState<Nullable<Attribute>>(null)
  const [validation, setValidation] = useState({})
  const [isValid, setIsValid] = useState(false)
  const [isAsideOpen, setIsAsideOpen] = useState(false)
  const [isTypeaheadDisabled, setIsTypeaheadDisabled] = useState(false)
  const [referralPercentage, setReferralPercentage] = useState<
    ReferralPercentage | ReferralBasePercentage | undefined | null
  >(null)

  const seller = useSelector(currentSeller)
  const sellerId = seller?.id ?? ''
  const displayName = seller?.display_name ?? ''

  const validationSchema = isBaseReferralFee
    ? getValidationSchema('Subtype', 'Base Fee Percentage')
    : getValidationSchema('Item Type', 'Referral Fee Percentage')

  const fetchReferralFees = useCallback(
    async ({ direction, orderBy, page, perPage }: PagingParams) => {
      setIsPending(true)
      if (isBaseReferralFee) {
        const { data, total } = await getReferralBasePercentages(
          {
            direction,
            orderBy,
            page,
            perPage,
          },
          true,
        )
        setReferralFees(data)
        setTotalReferralFees(total)
      } else {
        const { data, total } = await getReferralPercentages(sellerId, {
          direction,
          orderBy,
          page,
          perPage,
        })

        setReferralFees(data)
        setTotalReferralFees(total)
      }
      setIsPending(false)
    },
    [sellerId, isBaseReferralFee],
  )

  useEffect(() => {
    fetchReferralFees(table.state)
  }, [fetchReferralFees, table.state])

  const confirmAction = (): boolean => {
    if (!isAsideOpen) {
      return true
    }

    const isDirty = isFormDirty({
      referralPercentage,
      attribute,
      percent,
    })

    return !isDirty || window.confirm('Are you sure?')
  }

  const handleRequestClose = (event?: SyntheticEvent) => {
    if (confirmAction()) {
      globalDispatch(closeDialog())
    } else if (event) {
      // if user stays, don't navigate the parent away
      event.stopPropagation()
    }
  }

  const handleCloseAside = () => {
    setIsAsideOpen(false)
  }

  const resetAddFeeFormState = () => {
    setIsTypeaheadDisabled(false)
    setIsAsideOpen(true)
    setReferralPercentage(null)
    setIsValid(false)
    setValidation({})
    setPercent('')
    setAttribute(null)
  }

  const handleAddNewFee = () => {
    if (confirmAction()) {
      resetAddFeeFormState()
    }
  }

  const handleEditFee =
    (selectedReferralPercentage: ReferralPercentage | ReferralBasePercentage) =>
    () => {
      if (confirmAction()) {
        const percent = (
          selectedReferralPercentage.referral_percentage * 100
        ).toFixed()
        const attribute = {
          id:
            get('item_type_id', selectedReferralPercentage) ||
            get('sub_type_id', selectedReferralPercentage),
          name:
            get('itemTypeName', selectedReferralPercentage) ||
            get('subtypeName', selectedReferralPercentage),
        }

        // set form state
        setIsAsideOpen(true)
        setIsTypeaheadDisabled(true)
        setValidation({})
        setIsValid(true)
        setReferralPercentage(selectedReferralPercentage)
        setPercent(percent)
        setAttribute(attribute)
      }
    }

  const handleFormChange =
    ({ type }: { type: 'attribute' | 'percent' }) =>
    (value: Nullable<Attribute | string>) => {
      if (type === 'attribute') {
        setAttribute(value as Nullable<Attribute>)
      } else if (type === 'percent') {
        setPercent(value as string)
      }

      const { validation, isValid } = validationHandler(validationSchema, {
        ...{ attribute, percent },
        [type]: value,
      })

      setValidation(validation)
      setIsValid(isValid)
    }

  const handleSubmit = async () => {
    if (isValid) {
      const data = buildReferralPercentage({
        itemTypeId: attribute!.id,
        percent: percent,
      })

      try {
        if (isBaseReferralFee) {
          await saveReferralBasePercentage({
            sub_type_id: attribute!.id,
            referral_percentage: parseInt(percent, 10) / 100,
          })
        } else {
          await updateReferralPercentages(sellerId, data)
        }

        if (referralPercentage) {
          setIsAsideOpen(false) // close on edit
        } else {
          resetAddFeeFormState()
        }
        await fetchReferralFees(table.state)
      } catch (e) {
        console.error(`Submit Failed:: ${e}`)
      }
    }
  }

  return (
    <FullScreenDialogContainer
      title={
        isBaseReferralFee ? 'Edit Base Referral Fees' : 'Edit Referral Fees'
      }
      isOpen={isOpen}
      onRequestClose={handleRequestClose}
      isAsideOpen={isAsideOpen}
      asideTitle={referralPercentage ? 'Edit' : 'Add New'}
      aside={
        <EditReferralFeesAside
          attribute={attribute}
          percent={percent}
          isTypeaheadDisabled={isTypeaheadDisabled}
          validation={validation}
          isValid={isValid}
          isBaseFee={isBaseReferralFee}
          onRequestChange={handleFormChange}
          onRequestCancel={handleCloseAside}
          onRequestSubmit={handleSubmit}
        />
      }
    >
      {isBaseReferralFee ? (
        <StyledRow>
          Subtype base referral fees will act as the default referral fee for
          items within each subtype. Referral fees can be overridden for
          specific item types on a per-partner basis in partner's Referral Fees.
        </StyledRow>
      ) : (
        <>
          <StyledRow variant="h3">{displayName}</StyledRow>
          <StyledRow>
            All fees are based on the subtype base referral fees unless a
            different referral fee is specified below. To view a complete list
            of allowed item types and referral fees for this partner,&nbsp;
            <Link
              to={`/${sellerId}${RoutePath.REPORTS}`}
              onClick={handleRequestClose}
            >
              download the allowed item type report
            </Link>
            .
          </StyledRow>
        </>
      )}
      <StyledButtonContainer>
        <Button
          data-testid="add-new-button"
          color="primary"
          onClick={handleAddNewFee}
        >
          {isBaseReferralFee ? 'Add New Base Fee' : 'Add New Referral Fee'}
        </Button>
      </StyledButtonContainer>
      {isBaseReferralFee ? (
        <BaseReferralFeeTable
          table={table}
          data={referralFees as ReferralBasePercentage[]}
          isPending={isPending}
          total={totalReferralFees}
          edit={handleEditFee}
        />
      ) : (
        <ReferralFeeTable
          table={table}
          data={referralFees as ReferralPercentage[]}
          isPending={isPending}
          total={totalReferralFees}
          edit={handleEditFee}
        />
      )}
    </FullScreenDialogContainer>
  )
}

export default EditReferralFees
