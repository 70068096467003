import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'

import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import Address from 'components/common/Address'
import { DialogEnum } from 'components/common/Dialog'
import EditButton from 'components/common/EditButton'
import Text from 'components/common/Text'

import { getCodeDescription } from 'services/codes'
import { hydrateAddress } from 'services/addressHelper'

import {
  CATEGORY_PHYSICAL_DISPOSITION,
  CATEGORY_FINANCIAL_DISPOSITION,
} from 'constants/categories'
import { ReturnDescriptionAlias } from 'constants/returnPolicies'

import { getSellerContacts, isRoleExternalUserSelector } from 'store/selectors'

import {
  VendorCategory,
  SmsReturnPolicy,
  ReturnPolicyStatus,
} from 'types/Seller'

import { openDialog } from 'store/dialog/actionCreator'

const StyledText = styled(Text)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}))

const StyledDiv = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1),
}))

export interface Props {
  hasHeader?: boolean
  returnPolicy: SmsReturnPolicy
  vendorCategories: VendorCategory[]
  canDeactivate: boolean
  refresh: () => void
}

export const ReturnPolicyCard = ({
  hasHeader,
  returnPolicy,
  vendorCategories,
  canDeactivate,
  refresh,
}: Props) => {
  const dispatch = useDispatch()

  const isExternalUser = useSelector(isRoleExternalUserSelector)
  const contacts = useSelector(getSellerContacts)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDeactivate = () => {
    handleMenuClose()
    dispatch(
      openDialog({
        dialogEnum: DialogEnum.DEACTIVATE_RETURN_POLICY,
        componentProps: {
          returnPolicy,
        },
        closeCallback: refresh,
      }),
    )
  }

  const handleEdit = () => {
    handleMenuClose()
    dispatch(
      openDialog({
        dialogEnum: DialogEnum.EDIT_RETURN_POLICY,
        componentProps: {
          existingReturnPolicy: returnPolicy,
          vendorCategories,
          contacts,
        },
        closeCallback: refresh,
      }),
    )
  }

  const {
    vmm_id,
    financial_disposition_id,
    physical_disposition_id,
    in_care_of,
    returnContact,
    return_address = {},
    status,
  } = returnPolicy

  const physicalDisposition = getCodeDescription(
    vendorCategories,
    CATEGORY_PHYSICAL_DISPOSITION,
    physical_disposition_id,
  )
  const financialDisposition = getCodeDescription(
    vendorCategories,
    CATEGORY_FINANCIAL_DISPOSITION,
    financial_disposition_id,
  )

  const address = hydrateAddress(return_address)

  return (
    <List>
      <ListItem disableGutters>
        <Grid container spacing={2}>
          <Grid item xs={10} md={1}>
            {hasHeader && <StyledText type="bold">Policy ID</StyledText>}
            {vmm_id}
          </Grid>
          <Grid item xs={10} md={1}>
            {hasHeader && <StyledText type="bold">Status</StyledText>}
            {status}
          </Grid>
          <Grid item xs={12} md={4}>
            {hasHeader && <StyledText type="bold">Dispositions</StyledText>}
            <StyledDiv>
              <Text data-testid="financial-disposition">
                Financial:{' '}
                {!financialDisposition ? 'None provided' : financialDisposition}
              </Text>
              <Text data-testid="physical-disposition">
                Physical:{' '}
                {!physicalDisposition ? 'None provided' : physicalDisposition}
              </Text>
            </StyledDiv>
          </Grid>
          <Grid item xs={10} md={4}>
            {physicalDisposition && physicalDisposition !== 'Destroy' && (
              <div>
                {hasHeader && (
                  <StyledText>
                    <b>Partner Return Address</b>{' '}
                    <i>(No Residential Addresses)</i>
                  </StyledText>
                )}
                {returnContact && (
                  <Text>{`${returnContact.first_name} ${returnContact.last_name}`}</Text>
                )}
                {in_care_of && <Text>{in_care_of}</Text>}
                <Address address={address} />
              </div>
            )}
            {physicalDisposition && physicalDisposition === 'Destroy' && (
              <div>
                {hasHeader && (
                  <StyledText>
                    <b>Partner Return Address</b>{' '}
                    <i>(No Residential Addresses)</i>
                  </StyledText>
                )}
                <Text>Not Applicable</Text>
              </div>
            )}
          </Grid>
          {physicalDisposition &&
            physicalDisposition ===
              ReturnDescriptionAlias.RETURN_TO_PARTNER_CRC &&
            !canDeactivate &&
            status === ReturnPolicyStatus.ACTIVE &&
            !isExternalUser && (
              <Grid item xs={2} md={2}>
                <span>
                  <EditButton
                    dialogComponent={DialogEnum.EDIT_RETURN_POLICY}
                    aria-label="edit return dispositions"
                    componentProps={{
                      existingReturnPolicy: returnPolicy,
                      vendorCategories,
                      contacts,
                    }}
                    closeCallback={refresh}
                  />
                </span>
              </Grid>
            )}
          {physicalDisposition &&
            physicalDisposition ===
              ReturnDescriptionAlias.RETURN_TO_PARTNER_CRC &&
            canDeactivate &&
            status === ReturnPolicyStatus.ACTIVE &&
            !isExternalUser && (
              <Grid item xs={2} md={2}>
                <IconButton
                  onClick={handleMenuOpen}
                  data-testid="deactivate-menu"
                  size="large"
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleEdit}>Edit Return Address</MenuItem>
                  <MenuItem onClick={handleDeactivate}>
                    Deactivate Policy
                  </MenuItem>
                </Menu>
              </Grid>
            )}
        </Grid>
      </ListItem>
    </List>
  )
}

export default ReturnPolicyCard
