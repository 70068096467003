import React from 'react'
import { SvgIconProps } from '@mui/material/SvgIcon'

export default function BullseyeWithTarget(svgData: SvgIconProps) {
  return React.createElement(
    'svg',
    {
      focusable: 'false',
      tabIndex: svgData.tabIndex,
      width: svgData.width,
      height: svgData.height,
      className: svgData.className,
      style: svgData.style,
      version: '1.1',
      viewBox: '0 0 70 90',
      xmlns: 'http://www.w3.org/2000/svg',
    },
    React.createElement(
      'g',
      { fill: svgData.fill || '#FFFFFF' },
      React.createElement('path', {
        d: 'M69.25 35.77A34.25 34.25 0 1 1 35 1.52a34.25 34.25 0 0 1 34.25 34.25zM35 12.92a22.85 22.85 0 1 0 22.85 22.85A22.85 22.85 0 0 0 35 12.92z',
      }),
      React.createElement('circle', { cx: 35, cy: 35.77, r: 11.74 }),
      React.createElement('path', {
        d: 'M4.49 78H.75v-2.37H11V78H7.24v10.18H4.49zm11.41.73l1.58 4.6h-3.21zm-6.11 9.45h2.78l1-2.8h4.67l.94 2.8H22l-4.65-12.55h-2.82zm16.06-10.4h3c1.22 0 1.89.53 1.89 1.74s-.67 1.79-1.89 1.79h-3zm-2.75 10.4h2.75v-4.9h2.75c1.38 0 1.89.58 2.08 1.89a13.74 13.74 0 0 0 .43 3h2.75a7 7 0 0 1-.52-3c-.09-1.27-.47-2.58-1.82-2.93a3.09 3.09 0 0 0 2-3.15 3.44 3.44 0 0 0-3.65-3.46H23.1zm21.31-1.43A4.2 4.2 0 0 1 41 88.48c-3.86 0-6.13-2.9-6.13-6.52s2.27-6.62 6.13-6.62a5 5 0 0 1 5.27 4.43h-2.65A2.56 2.56 0 0 0 41 77.66c-2.46 0-3.39 2.11-3.39 4.3s.93 4.2 3.39 4.2a2.71 2.71 0 0 0 3-2.7h-2.81v-2.07h5.25v6.79h-1.75zm3.91-11.12h9.34V78h-6.6v2.68h6v2.14h-6v3.08h6.73v2.32h-9.47zM62.76 78H59v-2.37h10.25V78h-3.74v10.18h-2.75z',
      }),
    ),
  )
}
