import Button from '@mui/material/Button'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import FlagIcon from '@mui/icons-material/Flag'

import DisplayCard from 'components/common/DisplayCard'

import {
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
  formatDate,
} from 'services/dateService'

import { SmsSeller } from 'types/Seller'
import { Role } from 'types/SellerUser'
import {
  SellerVerification,
  SellerVerificationStatus,
} from 'types/SellerVerification'

export type Props = {
  seller: SmsSeller
  role: Role | undefined
  verification: SellerVerification | undefined
  onReviewClick: (seller: SmsSeller, verification: SellerVerification) => void
}

export const InformActCard = ({
  seller,
  role,
  verification,
  onReviewClick,
}: Props) => {
  let status = 'Verified by Partner, No Action Needed'
  let icon = CheckCircleOutlineIcon
  let iconColor: 'success' | 'error' = 'success'

  if (verification?.status === SellerVerificationStatus.PENDING) {
    status = 'Pending'
    icon = FlagIcon
    iconColor = 'error'
  }

  const title = `${seller.display_name}: ${status}`

  const description =
    verification?.status === SellerVerificationStatus.PENDING
      ? `Verfication is required by law. Failure to comply will result in suspension from the Target Plus platform.`
      : `Submitted by ${verification?.attributes[0]
          ?.requested_by} on ${formatDate(
          verification?.attributes[0]?.requested,
          DATE_FORMAT_MONTH_DAY_YEAR_TIME,
        )}`

  const hasAction =
    role === Role.ADMIN &&
    verification?.status === SellerVerificationStatus.PENDING

  const handleClick = () => {
    if (verification) {
      onReviewClick(seller, verification)
    }
  }

  return (
    <DisplayCard
      title={title}
      description={description}
      icon={icon}
      iconColor={iconColor}
      actions={
        hasAction ? (
          <Button color="primary" variant="contained" onClick={handleClick}>
            Review & verify attributes
          </Button>
        ) : undefined
      }
    >
      {role === Role.READ &&
        verification?.status === SellerVerificationStatus.PENDING && (
          <p data-testid="message">
            Only admin users can complete the verification process. Please reach
            out to account admins for more information.
          </p>
        )}
    </DisplayCard>
  )
}

export default InformActCard
