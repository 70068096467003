import TIMEZONES from 'constants/timezones'

export function timezoneCode(input: string = ''): string {
  const timezone = TIMEZONES.find((zone) => zone.name === input)
  return timezone ? timezone.code : input
}

export function timezoneAbbreviation(input: string = ''): string {
  const timezone = TIMEZONES.find((zone) => zone.name === input)
  return timezone ? timezone.abbreviation : input
}

export function getLocalTimezoneAbbreviation(): string {
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  let localTimeZoneAbbrev = timezoneAbbreviation(localTimeZone)
  if (localTimeZoneAbbrev.length > 3) {
    localTimeZoneAbbrev = 'Local Time'
  }
  return localTimeZoneAbbrev
}
