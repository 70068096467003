import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/fp/isEmpty'

import ErrorCodesTable from '../ErrorCodesTable'
import EditErrorCodeAside from './EditErrorCodeAside'

import FullScreenDialogContainer from 'components/common/Dialog/FullScreenDialogContainer'
import FullscreenDialogContent from 'components/common/Dialog/FullscreenDialogContent'

import { isUserRoleProductAdmin } from 'services/authorization'

import { closeDialog } from 'store/dialog/actionCreator'
import { getMemberOf } from 'store/selectors'

import { ErrorCode } from 'types/ErrorCode'

interface Props {
  isOpen: boolean
}

export const EditErrorCode = ({ isOpen }: Props) => {
  const memberOf = useSelector(getMemberOf)
  const isAdmin = isUserRoleProductAdmin(memberOf)

  const reduxDispatch = useDispatch()
  const [reloadToggle, setReloadToggle] = React.useState(false)
  const [isAsideOpen, setIsAsideOpen] = React.useState(false)
  const [errorCode, setErrorCode] = React.useState<Partial<ErrorCode>>({})
  const [isDirty, setIsDirty] = React.useState(false)

  const isNew = isEmpty(errorCode)

  const confirmAction = (): boolean => {
    if (!isAsideOpen) {
      return true
    }

    return !isDirty || window.confirm('Are you sure?')
  }

  const handleCloseAside = () => {
    setIsAsideOpen(false)
  }

  const handleAddNew = () => {
    if (confirmAction()) {
      setErrorCode({})
      setIsAsideOpen(true)
    }
  }

  const handleEdit = (errorCode: ErrorCode) => () => {
    if (confirmAction()) {
      setErrorCode(errorCode)
      setIsAsideOpen(true)
    }
  }

  const onSubmitHook = () => {
    setReloadToggle(!reloadToggle)
    if (!isNew) {
      handleCloseAside()
    }
  }

  const onRequestClose = () => {
    if (confirmAction()) {
      reduxDispatch(closeDialog())
    }
  }

  return (
    <FullScreenDialogContainer
      title="Edit Error Codes"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      isAsideOpen={isAsideOpen}
      asideTitle={isNew ? 'Add New' : 'Edit'}
      aside={
        <EditErrorCodeAside
          isAdmin={isAdmin}
          isNew={isNew}
          errorCode={errorCode}
          onSubmitHook={onSubmitHook}
          onCancel={handleCloseAside}
          onDirtyChange={setIsDirty}
        />
      }
    >
      <FullscreenDialogContent
        subtext="These error codes are used while manually reviewing items in the item queue"
        addNewButtonText="Add New Error Code"
        addNewHandler={handleAddNew}
      >
        <ErrorCodesTable onEdit={handleEdit} reload={reloadToggle} />
      </FullscreenDialogContent>
    </FullScreenDialogContainer>
  )
}

export default EditErrorCode
