import React from 'react'
import { useSelector } from 'react-redux'

import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import useTable from 'components/common/EnhancedTable/useTable'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import TableSpacer from 'components/common/TableSpacer'

import { isUserRoleProductAdmin } from 'services/authorization'
import {
  getErrorCodes,
  getNonSystemErrors,
  isProtectedError,
} from 'services/errorCodes'
import { Direction } from 'services/pageableHelper'

import { getMemberOf } from 'store/selectors'

import { ErrorCode } from 'types/ErrorCode'

import StyledIcon from 'components/common/StyledIcon'

type onEditFunc = (errorCode: ErrorCode) => (event: any) => void
interface Props {
  reload: boolean
  onEdit?: onEditFunc
}

const getTableFieldList = (
  hasEdit: boolean,
  onEdit?: onEditFunc,
): EnhancedTableFieldType<ErrorCode>[] => [
  {
    key: 'error_code',
    heading: 'Error Code',
    hasSort: true,
    width: 8,
  },
  {
    key: 'error_category',
    heading: 'Error Category',
    hasSort: true,
    width: 8,
  },
  {
    key: 'error_severity',
    heading: 'Severity',
    hasSort: true,
    width: 8,
  },
  {
    key: 'reason',
    heading: 'Error Text for Partner',
    hasSort: true,
    width: 35,
  },
  {
    key: 'error_resolution',
    heading: 'Partner Action',
    width: 25,
  },
  {
    key: 'status',
    heading: 'Error Code Status',
    hasSort: true,
    width: 8,
  },
  {
    key: '',
    width: 8,
    formatCell: (error) => {
      const hasProtectedErrorEdit = !hasEdit && isProtectedError(error)
      if (!onEdit || hasProtectedErrorEdit) {
        return
      }

      return (
        <IconButton
          aria-label={`edit ${error.error_code}`}
          data-testid="edit-button"
          onClick={onEdit(error)}
        >
          <StyledIcon iconType="edit" />
        </IconButton>
      )
    },
  },
]

export const ErrorCodesTable = ({ reload, onEdit }: Props) => {
  const theme = useTheme()
  const memberOf = useSelector(getMemberOf)
  const [pending, setPending] = React.useState(false)
  const [errorCodes, setErrorCodes] = React.useState<ErrorCode[]>([])
  const [total, setTotal] = React.useState(0)

  const { table } = useTable({
    direction: Direction.ASC,
    orderBy: 'error_code',
    page: 0,
    perPage: 100,
  })

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))

  const hasSystemErrorEdit = isUserRoleProductAdmin(memberOf)

  const fieldList = React.useMemo(
    () => getTableFieldList(hasSystemErrorEdit, onEdit),
    [hasSystemErrorEdit, onEdit],
  )

  React.useEffect(() => {
    setPending(true)
    getErrorCodes(table.state).then((response) => {
      if (onEdit && !hasSystemErrorEdit) {
        const nonSystemErrors = getNonSystemErrors(response.data)
        setErrorCodes(nonSystemErrors)
        setTotal(nonSystemErrors.length)
      } else {
        setErrorCodes(response.data)
        setTotal(response.total)
      }
      setPending(false)
    })
  }, [table.state, reload, onEdit, hasSystemErrorEdit])

  return (
    <TableSpacer>
      <EnhancedTable
        onChangePage={table.actions.changePage}
        onChangeRowsPerPage={table.actions.changePerPage}
        onRequestSort={table.actions.sort}
        orderBy={table.state.orderBy}
        order={table.state.direction}
        page={table.state.page}
        rowsPerPage={table.state.perPage}
        paginationDisabled={false}
        data={errorCodes}
        total={total}
        isLoading={pending}
        fieldList={fieldList}
        tableLayoutFixed={isLargeScreen}
      />
    </TableSpacer>
  )
}

export default ErrorCodesTable
