import EnterpriseIcon, {
  BadgeIcon,
  PyramidApparelAccessoriesIcon,
  TruckIcon,
  DollarSignCircleIcon,
  UndoIcon,
  ChartLineIcon,
  PriceChangeIcon,
  CommIcon,
  MarketplaceIcon,
} from '@enterprise-ui/icons'

import styled from '@emotion/styled'
import { primary, grey } from 'config/themeConfig'

import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'

import Text from 'components/common/Text'
import Link from 'components/common/Link'

import { KnowledgeCategory } from 'types/KnowledgeArticle'

import { RoutePath } from 'services/NavigationHelper'

const StyledDiv = styled('div')(({ theme }) => ({
  padding: theme.spacing(5.8),
}))

const StyledCard = styled(Card)(({ theme }) => ({
  margin: theme.spacing(1),
}))

const StyledText = styled(Text)(({ theme }) => ({
  color: primary.main,
  marginLeft: theme.spacing(2),
}))

const StyledIconContainer = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(3),
  color: grey[700],
  height: 40,
}))

export interface Props {
  category: KnowledgeCategory
}

export const KnowledgeCategoryCard = ({ category }: Props) => {
  const getIcon = (categoryName: string) => {
    switch (categoryName) {
      case 'Payouts':
        return <EnterpriseIcon icon={DollarSignCircleIcon} size="xl" />
      case 'Returns_TargetPlus':
        return <EnterpriseIcon icon={UndoIcon} size="xl" />
      case 'Performance':
        return <EnterpriseIcon icon={ChartLineIcon} size="xl" />
      case 'Items':
        return <EnterpriseIcon icon={PyramidApparelAccessoriesIcon} size="xl" />
      case 'Account_Setup':
        return <EnterpriseIcon icon={BadgeIcon} size="xl" />
      case 'Orders':
        return <EnterpriseIcon icon={TruckIcon} size="xl" />
      case 'Services':
        return <EnterpriseIcon icon={PriceChangeIcon} size="xl" />
      case 'About_Target_Plus':
        return <EnterpriseIcon icon={MarketplaceIcon} size="xl" />
      case 'Newsletters':
        return <EnterpriseIcon icon={CommIcon} size="xl" />
      default:
        return <StyledDiv />
    }
  }

  return (
    <StyledCard>
      <Link
        to={`${RoutePath.KNOWLEDGE_SEARCH_RELATIVE}?category=${category.name}`}
      >
        <Grid container alignItems="center">
          <StyledIconContainer item>
            {getIcon(category.name)}
          </StyledIconContainer>
          <Grid item>
            <StyledText type="bold">{category.label}</StyledText>
          </Grid>
        </Grid>
      </Link>
    </StyledCard>
  )
}

export default KnowledgeCategoryCard
