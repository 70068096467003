import axios from 'axios'

import uniq from 'lodash/fp/uniq'

import apiConfig from 'config/apiConfig'

import { getNodesById } from './itemTaxonomies'
import { Direction, getPageable, PagingParams } from './pageableHelper'

import Attribute from 'types/Attribute'
import { ReferralPercentage } from 'types/ReferralFee'
import { CollectionResponse } from 'types/Response'

export async function getReferralPercentages(
  sellerId: string,
  pagingParams: PagingParams = {
    page: 0,
    perPage: 20,
    orderBy: 'created',
    direction: Direction.DESC,
  },
  params: { item_type_id?: string; latest: boolean } = { latest: true },
): Promise<CollectionResponse<ReferralPercentage>> {
  const url = `${apiConfig.sms}/sellers/${sellerId}/referral_percentages`

  const config = {
    params: {
      ...getPageable(pagingParams),
      ...params,
    },
  }
  const response = await axios.get(url, config)
  const { data, headers } = response

  const total = headers['x-total-count']
    ? parseInt(headers['x-total-count'], 10)
    : 0

  let referralPercentages = []
  if (data) {
    const itemTypeIds: string[] = response.data.map(
      (item: ReferralPercentage) => item.item_type_id,
    )

    const nodes = await getNodesById(uniq(itemTypeIds))
    referralPercentages = data.map((referralPercentage: ReferralPercentage) => {
      const node = nodes.find(
        (n: Attribute) => n.id === referralPercentage.item_type_id,
      )
      return {
        ...referralPercentage,
        itemTypeName: node ? node.name : 'Unknown',
      }
    })
  }

  return { data: referralPercentages, total }
}

export const buildReferralPercentage = ({
  itemTypeId,
  percent,
}: {
  itemTypeId: string
  percent: string
}): Partial<ReferralPercentage> => ({
  item_type_id: itemTypeId,
  referral_percentage: parseInt(percent, 10) / 100,
})

export const updateReferralPercentages = async (
  sellerId: string,
  data: Partial<ReferralPercentage>,
) => {
  const response = await axios.post(
    `${apiConfig.sms}/sellers/${sellerId}/referral_percentages`,
    data,
  )

  return response.data
}
