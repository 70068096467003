import { flag, FlagName } from 'flag'
import { Report, ReportType, SellerReportTableRow } from 'types/Report'

const isItemErrorsEnabled = flag(FlagName.ITEM_ERRORS)

export const formatReportType = (report: Report | SellerReportTableRow) => {
  let templateUsedName
  switch (report.type) {
    case ReportType.PAYOUT_RECONCILIATION:
      templateUsedName = 'Financial Reconciliation'
      break
    case ReportType.INVENTORY:
      templateUsedName = 'Inventory & Price'
      break
    case ReportType.ORDERS:
      templateUsedName = 'Orders'
      break
    case ReportType.ALLOWED_ITEM_TYPES:
      templateUsedName = 'Allowed Item Types'
      break
    case ReportType.ITEM_ERRORS:
      templateUsedName = 'Item Errors'
      break
    case ReportType.ITEM_ERRORS_V2:
      templateUsedName = isItemErrorsEnabled ? 'Item Errors' : 'Item Errors V2'
      break
    case ReportType.ATTRIBUTE_VALUES:
      templateUsedName = 'Attributes & Values'
      break
    case ReportType.RETURN_ORDERS_EXTERNAL:
      templateUsedName = 'Returns'
      break
    case ReportType.ORDER_DEFECTS:
      templateUsedName = 'Order Defect Rate'
      break
    case ReportType.PARTNER_REVIEWS:
      templateUsedName = 'Partner Ratings & Reviews (Product Admin Only)'
      break
    case ReportType.PRODUCT_LOGISTIC_ERRORS:
      templateUsedName = 'Items with Logistics Errors'
      break
    case ReportType.SALES_METRICS:
      templateUsedName = 'Sales Metrics'
      break
    case ReportType.CONTENT_SCORE:
      templateUsedName = 'Content Score'
      break
    case ReportType.SALES_TAX:
      templateUsedName = 'Partner Sales and Tax'
      break
    default:
      templateUsedName = report.type
  }
  return templateUsedName
}
