import { useState, useEffect } from 'react'

import sortBy from 'lodash/fp/sortBy'
import last from 'lodash/fp/last'
import startCase from 'lodash/fp/startCase'

import styled from '@emotion/styled'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

import DisplayCard from 'components/common/DisplayCard'
import Image from 'components/common/Image'
import TabularData, { FieldList } from 'components/common/TabularData'
import Text from 'components/common/Text'

import { ITEM_FIELDS } from 'constants/itemFields'
import { FieldError, getFieldErrors } from 'services/itemHelper'
import { getItemAttributeById } from 'services/itemTaxonomies'
import { decodeUriSafe } from 'services/urlHelper'
import { ListingStatus, ProductField, SmsProduct } from 'types/Item'
import { error } from 'config/themeConfig'

export enum ItemDataEnum {
  LATEST,
  PUBLISHED,
}

const StyledGridContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(0),
}))

const StyledListItem = styled(ListItem)({ paddingLeft: 0 })

const StyledWarningText = styled(Typography)({
  color: error.main,
  fontStyle: 'italic',
  fontSize: '0.8rem',
})

const StyledBoldText = styled('span')({ fontWeight: 'bold' })

export interface Props {
  item: SmsProduct
  itemDataType: ItemDataEnum
}

const showErrorStatuses = [
  ListingStatus.APPROVED,
  ListingStatus.REJECTED,
  ListingStatus.SUSPENDED,
  ListingStatus.UNLISTED,
]

interface ItemFieldsType {
  displayName: string
  value: string
  order: number
  isImage: boolean
  warning?: string
}

const getItemAttributeName = async (fieldName: string) => {
  if (!isNaN(fieldName as any)) {
    const attribute = await getItemAttributeById(fieldName).catch(() => {
      return undefined
    })
    return attribute ? `${fieldName}: ${attribute.name}` : fieldName
  } else {
    return fieldName
  }
}

const isVariationAttributeField = (field: ProductField) => {
  const variationAttributeRegEx = /^variation_attribute_\d+$/

  return variationAttributeRegEx.test(field.name)
}

export const getVariationAttributesMap = async (
  fields: ProductField[],
): Promise<Record<string, string>> => {
  const variationAttributesMap: Record<string, string> = {}
  const size = fields.length
  let i = 0

  while (i < size) {
    const currentField = fields[i]

    if (isVariationAttributeField(currentField)) {
      if (variationAttributesMap[currentField.value] === undefined) {
        const attributeName = await getItemAttributeName(currentField.value)
        const productValue =
          fields.find((field) => field.name === currentField.value)?.value ??
          'Product Value Not Found'

        variationAttributesMap[
          currentField.value
        ] = `${attributeName}: ${productValue}`
      }
    }

    i++
  }

  return variationAttributesMap
}

export const collapseVariationAttributeFields = async (
  fields?: ProductField[],
): Promise<ProductField[]> => {
  const results: ProductField[] = []

  if (!fields?.length) {
    return results
  }

  const variationAttributesMap = await getVariationAttributesMap(fields)

  for (let i = 0; i < fields.length; i++) {
    const currentField = fields[i]

    if (variationAttributesMap[currentField.name]) {
      // The current field.name is in our map so skip this one
      continue
    } else if (currentField.name === 'version_num') {
      // exclude field
      continue
    } else if (variationAttributesMap[currentField.value]) {
      results.push({
        name: startCase(currentField.name),
        value: variationAttributesMap[currentField.value],
      })
    } else {
      results.push(currentField)
    }
  }

  return results
}

const getItemFields = async (item: SmsProduct) => {
  const fields = await collapseVariationAttributeFields(item?.fields)

  const itemFields =
    fields.map(async (field) => {
      const fieldData = ITEM_FIELDS[field.name]
      let fieldValue
      if (
        field.name === ITEM_FIELDS['descriptions.long_description'].fieldName
      ) {
        fieldValue = decodeUriSafe(field.value)
      } else {
        fieldValue = field.value
      }

      return {
        displayName:
          fieldData?.displayName ?? (await getItemAttributeName(field.name)),
        value: fieldValue,
        order: fieldData?.order ?? 900,
        isImage: fieldData?.isImage ?? false,
        warning: fieldData?.warning,
      } as ItemFieldsType
    }) ?? []
  return Promise.all(itemFields)
}

export const ItemDataTable = ({ item, itemDataType }: Props) => {
  const [fieldData, setFieldData] = useState<ItemFieldsType[]>([])

  const fieldList: FieldList<ItemFieldsType>[] = [
    {
      key: 'displayName',
      displayName: 'Field',
      width: 30,
    },
    {
      key: 'value',
      displayName: 'Value',
      width: 70,
      formatCell: ({ isImage, value, displayName, warning }) => {
        if (isImage || warning) {
          return (
            <>
              {isImage && (
                <div>
                  <Image
                    src={value}
                    alt={`Image of ${displayName}`}
                    thumbnail
                  />
                  <Text type="micro">{value}</Text>
                </div>
              )}
              {warning && (
                <div>
                  <Typography>{value}</Typography>
                  <StyledWarningText>{warning}</StyledWarningText>
                </div>
              )}
            </>
          )
        } else {
          return value
        }
      },
    },
  ]

  useEffect(() => {
    let canceled = false

    getItemFields(item).then((response) => {
      if (canceled) return
      setFieldData(response)
    })
    return () => {
      canceled = true
    }
  }, [item])

  const data = fieldData.length ? sortBy('order', fieldData) : fieldData

  const productStatus =
    itemDataType === ItemDataEnum.LATEST
      ? last(sortBy('version', item.product_statuses))!
      : item.product_statuses.find((status) => status.current)!

  const showErrors =
    itemDataType === ItemDataEnum.LATEST
      ? showErrorStatuses.includes(
          productStatus.listing_status as ListingStatus,
        )
      : true

  const fieldErrors: FieldError[] = getFieldErrors(productStatus.errors)

  return (
    <StyledGridContainer container spacing={5}>
      <Grid item md={12} lg={8}>
        <DisplayCard title="Data Fields">
          <TabularData fieldList={fieldList} data={data} />
        </DisplayCard>
      </Grid>
      <Grid item md={12} lg={4}>
        <DisplayCard title="Listing Errors">
          {itemDataType === ItemDataEnum.LATEST && (
            <Typography>
              Listing errors are only displayed once the latest item data has
              either been approved, rejected, or suspended.
            </Typography>
          )}
          {showErrors && (
            <List data-testid="listing-errors-list">
              {fieldErrors.map((error, index) => (
                <StyledListItem key={index}>
                  <ListItemText>
                    <StyledBoldText>{error.displayName}:</StyledBoldText>{' '}
                    {error.message}
                  </ListItemText>
                </StyledListItem>
              ))}
            </List>
          )}
        </DisplayCard>
      </Grid>
    </StyledGridContainer>
  )
}

export default ItemDataTable
