import React from 'react'
import { useDispatch } from 'react-redux'
import isEqual from 'lodash/fp/isEqual'

import { setPageData } from 'services/fireflyInsights'
import { setHeaderTitle } from 'store/layout/actionCreators'
import { Breadcrumb } from 'types/Layout'
import usePrevious from 'hooks/usePrevious'

interface Props {
  title: string | Breadcrumb[]
}

export const HeaderTitle = ({ title }: Props) => {
  const dispatch = useDispatch()
  const prevTitle = usePrevious(title)

  React.useEffect(() => {
    if (title && !isEqual(prevTitle, title)) {
      dispatch(setHeaderTitle(title))
      setPageData(title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title])

  return null
}

export default HeaderTitle
