import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAttributesAndValuesPageAttributesQueryKeys,
  REQUESTED_VALUES,
} from './queries'

import { currentSeller, email, getMemberOf } from 'store/selectors'
import { openDialog, closeDialog } from 'store/dialog/actionCreator'

import theme, { success, violet, error } from 'config/themeConfig'

import Grid from '@mui/material/Grid'
import { Button, Chip } from '@mui/material'

import { DialogEnum } from 'components/common/Dialog'
import DisplayCard from 'components/common/DisplayCard'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import { formatDateMDYT } from 'components/common/EnhancedTable/formatters'

import Link from 'components/common/Link'
import {
  getEnhancedTablePageableProps,
  TableState,
  useSearchParams,
} from 'components/common/FilterBar/useSearchParams'
import HeaderTitle from 'components/common/HeaderTitle'
import { StaticTypeahead, TaxonomyTypeahead } from 'components/common/Typeahead'

import TitleBar from 'components/common/TitleBar'

import AttributeValues from './Dialogs/AttributeValues'

import { AttributeValueRequest } from 'types/AttributeValue'
import Attribute, { ITEM_TYPE } from 'types/Attribute'
import { FireflyEvent } from 'types/FireflyInsights'

import { trackCustomEvent } from 'services/fireflyInsights'
import { getTaxonomyAssignmentAttributes } from 'services/itemTaxonomies'
import { Direction } from 'services/pageableHelper'
import { getSellerRequestedValues } from 'services/securityGateway'
import { isOneOfUserRoles } from 'services/authorization'
import {
  USER_ROLE_ADMIN,
  USER_ROLE_APP_SMS_ADMIN,
  USER_ROLE_APP_SMS_READ,
} from 'services/roles'

const title = 'Available Values for a Merch Type Attribute'
const subtitle =
  "Example: Enter item type 'Shirts' & attribute 'Color Family' to view available color values for Color Family."

export const statusColor = (status: string | undefined) => {
  switch (status) {
    case 'APPROVED':
      return success.main
    case 'DENIED':
      return error.main
    case 'INITIATED':
    case 'INPROGRESS':
      return violet.main
  }
}

const AttributesAndValuesPage = () => {
  const seller = useSelector(currentSeller)
  const memberOf = useSelector(getMemberOf)
  const userEmail = useSelector(email)
  const reduxDispatch = useDispatch()
  const [itemType, setItemType] = useState<Nullable<Attribute>>(null)
  const [attribute, setAttribute] = useState<Nullable<Attribute>>(null)

  const initialSearchParams: TableState = {
    perPage: 20,
    page: 0,
    orderBy: 'created',
    direction: Direction.DESC,
  }

  const [searchParams, searchParamActions] =
    useSearchParams<TableState>(initialSearchParams)

  const { data = [], isLoading: isLoadingAttributesAndValues } = useQuery(
    getAttributesAndValuesPageAttributesQueryKeys(itemType),
    () => {
      if (itemType?.id) {
        return getTaxonomyAssignmentAttributes(itemType.id)
      }
      return []
    },
  )

  const { data: requestedValues, isLoading: isLoadingRequestedValues } =
    useQuery([REQUESTED_VALUES, searchParams], () => {
      if (seller) {
        return getSellerRequestedValues(seller.id, searchParams)
      }
    })

  const handleItemTypeChange = (value: Nullable<Attribute>) => {
    if (!value) return
    setItemType(value)
  }

  const handleAttributeChange = (value: Attribute) => {
    setAttribute(value)
  }

  const handleItemTypeClear = () => {
    setItemType(null)
    setAttribute(null)
  }

  const handleViewValuesClick = () => {
    trackCustomEvent(FireflyEvent.VIEW_VALUES_BUTTON, 'event', 'click')

    reduxDispatch(
      openDialog({
        dialogEnum: DialogEnum.ATTRIBUTE_VALUES,
        componentProps: {
          itemType,
          attribute,
        },
      }),
    )
  }

  const handleRequestNewValueClick = () => {
    if (seller?.id) {
      reduxDispatch(
        openDialog({
          dialogEnum: DialogEnum.REQUEST_ATTRIBUTE_VALUES,
          componentProps: {
            sellerId: seller.id,
            userEmail,
            closeDialog: () => reduxDispatch(closeDialog()),
          },
        }),
      )
    }
  }

  const onOpenRequestDetailDialog = (requestId: string) => {
    if (seller) {
      reduxDispatch(
        openDialog({
          dialogEnum: DialogEnum.VALUE_REQUEST_DETAIL,
          componentProps: {
            sellerId: seller.id,
            valueRequestId: requestId,
          },
        }),
      )
    }
  }

  const fieldList: EnhancedTableFieldType<AttributeValueRequest>[] = [
    {
      key: 'id',
      heading: 'Request ID',
      formatCell: (item) => {
        if (
          item.request_status === 'APPROVED' ||
          item.request_status === 'DENIED'
        ) {
          return (
            <Link
              onClick={(event) => {
                event.preventDefault()
                onOpenRequestDetailDialog(item.id)
              }}
              to=""
            >
              {item.id}
            </Link>
          )
        } else {
          return item.id
        }
      },
    },
    {
      key: 'item_type_name',
      heading: 'Item Type',
    },
    {
      key: 'attribute_name',
      heading: 'Attribute',
    },
    {
      key: 'requested_value',
      heading: 'Value Requested',
    },
    {
      key: 'request_status',
      heading: 'Status',
      formatCell: ({ request_status }) => {
        return (
          <Chip
            label={
              request_status === 'INPROGRESS' || request_status === 'INITIATED'
                ? 'IN PROGRESS'
                : request_status
            }
            variant="outlined"
            sx={{ color: statusColor(request_status) }}
          />
        )
      },
    },
    {
      key: 'created',
      heading: 'Request Date',
      formatCell: formatDateMDYT('created'),
    },
  ]
  return (
    <div data-testid="attributes-and-values">
      <HeaderTitle title="Attributes & Values" />
      <TitleBar title="Attributes & Values" />
      <Grid container>
        <Grid item xs={12}>
          <DisplayCard title={title} description={subtitle}>
            <Grid item xs={12} md={4}>
              <TaxonomyTypeahead
                label="Item Type *"
                searchType={ITEM_TYPE}
                placeholder="Search Item Type"
                onChange={handleItemTypeChange}
                onClear={handleItemTypeClear}
                data-testid="item-type-typeahead"
              />

              <div style={{ marginTop: theme.spacing(4) }}>
                <StaticTypeahead<Attribute>
                  label="Merch Type Attribute *"
                  placeholder="Search Merch Type Attribute"
                  isDisabled={!itemType || isLoadingAttributesAndValues}
                  data={data}
                  onChange={handleAttributeChange}
                  value={attribute}
                  filterBy="STARTS_WITH"
                  data-testid="merch-type-attribute-typeahead"
                />
              </div>
            </Grid>

            <Button
              sx={{ marginTop: 3 }}
              color="primary"
              variant="contained"
              disabled={!attribute}
              onClick={handleViewValuesClick}
            >
              View Values
            </Button>

            <AttributeValues
              isOpen={false}
              itemType={itemType}
              attribute={attribute}
            />
          </DisplayCard>
        </Grid>
      </Grid>

      <div>
        <TitleBar
          title="Value Requests"
          actionButtons={[
            isOneOfUserRoles(memberOf, [
              USER_ROLE_ADMIN,
              USER_ROLE_APP_SMS_ADMIN,
              USER_ROLE_APP_SMS_READ,
            ]) && (
              <Button
                key="value-request-button"
                variant="outlined"
                onClick={handleRequestNewValueClick}
              >
                Request New Value
              </Button>
            ),
          ]}
        />
        <EnhancedTable
          data={requestedValues?.data ?? []}
          fieldList={fieldList}
          isLoading={isLoadingRequestedValues}
          total={requestedValues?.total}
          {...getEnhancedTablePageableProps(searchParams, searchParamActions)}
        />
      </div>
    </div>
  )
}

export default AttributesAndValuesPage
