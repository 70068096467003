import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'

import { PagingParams } from 'services/pageableHelper'
import {
  fetchReturnDisputes,
  fetchReturnDisputeForReview,
  fetchReviewDataForDispute,
  updateReturnDisputeCase,
} from 'services/returnDisputes'
import { getDateBefore, isoStringStartOfDay } from 'services/dateService'
import {
  ReturnDispute,
  ReturnDisputeCasesSearchParams,
  ReturnDisputeDaysPending,
} from 'types/disputes'

import {
  getInternalReturnDisputeCasesQueryKey,
  getReturnDisputeReviewQueryKey,
  RETURN_DISPUTE_REVIEW_QUERY_KEY,
} from './queries'

export const getLastModifiedFromDaysPending = (
  daysPending?: ReturnDisputeDaysPending,
) => {
  if (!daysPending) {
    return
  }

  let days = 5
  if (
    daysPending === ReturnDisputeDaysPending.OVER_SEVEN ||
    daysPending === ReturnDisputeDaysPending.ZERO_TO_SEVEN
  ) {
    days = 7
  }

  const date = isoStringStartOfDay(getDateBefore(new Date(), days))

  if (
    daysPending === ReturnDisputeDaysPending.ZERO_TO_FIVE ||
    daysPending === ReturnDisputeDaysPending.ZERO_TO_SEVEN
  ) {
    return `${date}/`
  } else {
    return `/${date}`
  }
}

export const useInternalReturnDisputesQuery = (
  searchParams: ReturnDisputeCasesSearchParams,
  pagingParams: PagingParams,
) => {
  const query = useQuery(
    getInternalReturnDisputeCasesQueryKey({
      ...searchParams,
      ...pagingParams,
    }),
    () => {
      return fetchReturnDisputes(
        {
          ...searchParams,
        },
        pagingParams,
      )
    },
  )

  return { ...query, data: query.data ?? null }
}

export const useReturnDisputeReviewQuery = (
  searchParams: ReturnDisputeCasesSearchParams,
  pagingParams: PagingParams,
  dispute?: ReturnDispute,
) => {
  const query = useQuery(
    getReturnDisputeReviewQueryKey({
      ...searchParams,
      ...pagingParams,
    }),
    () => {
      if (!dispute) {
        // If no dispute provided then get one based on the params
        return fetchReturnDisputeForReview(
          {
            ...searchParams,
          },
          pagingParams,
        )
      } else {
        return fetchReviewDataForDispute(dispute)
      }
    },
  )

  return { ...query, data: query.data ?? null }
}

type MutationVariables = {
  dispute: ReturnDispute
  invalidate: boolean
}

export const useUpdateReturnDisputeCaseQuery = () => {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation<any, any, MutationVariables>(
    ({ dispute }) => updateReturnDisputeCase(dispute),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([RETURN_DISPUTE_REVIEW_QUERY_KEY])
      },
    },
  )

  const updateDispute = ({ dispute, invalidate }: MutationVariables) => {
    mutate({ dispute, invalidate })
  }

  return {
    updateDispute,
    isLoading,
  }
}
