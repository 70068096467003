import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import Grid from '@mui/material/Grid'

import DialogContainer from 'components/common/Dialog/DialogContainer'

import { editSeller } from 'store/seller/actionCreators'
import { currentSeller, isSellersPending } from 'store/selectors'

import { LABEL_COMPANY_TYPE } from 'constants/labels'

import { CompanyType } from 'types/Seller'
import { useEffect, useState } from 'react'
import Select from 'components/common/Select'

const validationSchema = yup.object().shape({
  companyType: yup.string().label(LABEL_COMPANY_TYPE).required(),
})

interface Props {
  isOpen: boolean
}

const EditCompanyType = ({ isOpen }: Props) => {
  const dispatch = useDispatch()
  const seller = useSelector(currentSeller)
  const isPending = useSelector(isSellersPending)

  const [selectedCompanyType, setSelectedCompanyType] = useState('')
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(false)

  useEffect(() => {
    if (seller?.is_private === true) {
      setSelectedCompanyType(CompanyType.PRIVATE)
    } else if (seller?.is_private === false) {
      setSelectedCompanyType(CompanyType.PUBLIC)
    }
  }, [seller])

  useEffect(() => {
    // prevent the user from submitting the selected company type if it isn't a new value
    const isStoredCompanyTypePrivate = seller?.is_private
    const hasCompanyTypeChanged =
      (selectedCompanyType === CompanyType.PRIVATE &&
        isStoredCompanyTypePrivate !== true) ||
      (selectedCompanyType === CompanyType.PUBLIC &&
        isStoredCompanyTypePrivate !== false)

    validationSchema
      .isValid({ companyType: selectedCompanyType })
      .then((valid) => {
        setIsSubmitBtnDisabled(valid && hasCompanyTypeChanged)
      })
  }, [selectedCompanyType, seller])

  const handleSubmit = () => {
    const isPrivate = selectedCompanyType === CompanyType.PRIVATE
    if (seller) {
      const updatedSeller = {
        ...seller,
        is_private: isPrivate,
      }
      dispatch(editSeller(updatedSeller))
    }
  }

  const handleSelectionChange = (value: string) => {
    setSelectedCompanyType(value)
  }

  return (
    <DialogContainer
      title="Edit Company Type"
      isOpen={isOpen}
      isPending={isPending}
      onSubmit={handleSubmit}
      isSubmitDisabled={!isSubmitBtnDisabled}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Select
            isRequired
            isDisabled={isPending}
            options={[
              { label: 'Private', value: CompanyType.PRIVATE },
              { label: 'Public', value: CompanyType.PUBLIC },
            ]}
            onChange={handleSelectionChange}
            value={selectedCompanyType}
            id="companyType"
            data-testid="companyType"
            name="companyType"
            keyName="label"
            valueName="value"
            label={LABEL_COMPANY_TYPE}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export default EditCompanyType
