import { useQuery } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'

import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import StyledIcon from 'components/common/StyledIcon'
import { DialogEnum } from 'components/common/Dialog'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import { formatDateMDYT } from 'components/common/EnhancedTable/formatters'
import HeaderTitle from 'components/common/HeaderTitle'
import LabeledDataList from 'components/common/LabeledDataList'
import TableSpacer from 'components/common/TableSpacer'
import {
  useSearchParams,
  TableState,
  getEnhancedTablePageableProps,
} from 'components/common/FilterBar/useSearchParams'

import { isOneOfUserRoles } from 'services/authorization'
import {
  USER_ROLE_ADMIN,
  USER_ROLE_OPS,
  USER_ROLE_ACQUISITIONS,
} from 'services/roles'
import { getSellerSources } from 'services/seller'

import { openDialog } from 'store/dialog/actionCreator'
import { getMemberOf } from 'store/selectors'

import { SellerSource } from 'types/Seller'
import { getSourcesQueryKeys } from './queries'
import { Grid } from '@enterprise-ui/canvas-ui-react'

const StyledButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const StyledContainer = styled('div')({ width: '100%', display: 'flex' })

const initialSearchParams: TableState = {
  perPage: 20,
  page: 0,
}

const fieldList: EnhancedTableFieldType<SellerSource>[] = [
  {
    key: 'description',
    heading: 'Name',
  },
  {
    key: 'status',
  },
  {
    key: 'multi_ship_node',
    heading: 'Multiple Ship Nodes',
    formatCell: ({ multi_ship_node }) =>
      multi_ship_node ? 'Enabled' : 'Disabled',
  },
  {
    key: 'created',
    heading: 'Created Date',
    formatCell: formatDateMDYT('created'),
  },
  {
    key: 'last_modified',
    heading: 'Last Modified Date',
    formatCell: formatDateMDYT('last_modified'),
  },
  {
    key: 'last_modified_by',
  },
]

export const SourcesPage = () => {
  const reduxDispatch = useDispatch()

  const memberOf = useSelector(getMemberOf)
  const canEdit = isOneOfUserRoles(memberOf, [
    USER_ROLE_OPS,
    USER_ROLE_ADMIN,
    USER_ROLE_ACQUISITIONS,
  ])

  const [searchParams, searchParamActions] =
    useSearchParams<TableState>(initialSearchParams)

  const { data, isLoading } = useQuery(
    getSourcesQueryKeys(searchParams.page, searchParams.perPage),
    () =>
      getSellerSources(
        {},
        {
          page: searchParams.page,
          perPage: searchParams.perPage,
        },
      ),
  )

  const handleAddNew = () => {
    reduxDispatch(
      openDialog({
        dialogEnum: DialogEnum.ADD_INTEGRATION,
      }),
    )
  }

  const handleEdit = (source: SellerSource) => () => {
    reduxDispatch(
      openDialog({
        dialogEnum: DialogEnum.EDIT_INTEGRATION,
        componentProps: { source },
      }),
    )
  }

  const labeledData = [{ label: 'Total:', data: data?.total ?? 0 }]

  return (
    <Grid.Container>
      <Grid.Item xs={12}>
        <HeaderTitle title="Integrations" />
        <StyledContainer>
          <LabeledDataList data={labeledData} />
          {canEdit && (
            <StyledButton
              data-testid="add-integration-button"
              onClick={handleAddNew}
              color="primary"
              variant="contained"
              aria-label="add a new integration"
              disabled={isLoading}
            >
              <AddIcon />
              ADD INTEGRATION
            </StyledButton>
          )}
        </StyledContainer>
        <Grid.Container>
          <Grid.Item xs={12}>
            <div>
              <TableSpacer>
                <EnhancedTable
                  data={data?.data ?? []}
                  fieldList={fieldList}
                  isLoading={isLoading}
                  total={data?.total}
                  actions={
                    canEdit
                      ? [
                          {
                            callback: handleEdit,
                            label: 'Edit',
                            icon: <StyledIcon iconType="edit" />,
                          },
                        ]
                      : undefined
                  }
                  {...getEnhancedTablePageableProps(
                    searchParams,
                    searchParamActions,
                  )}
                />
              </TableSpacer>
            </div>
          </Grid.Item>
        </Grid.Container>
      </Grid.Item>
    </Grid.Container>
  )
}

export default SourcesPage
