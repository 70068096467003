import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'

import DateRangeText from 'components/common/DateRangeText'
import Text from 'components/common/Text'
import ReportProgress from './ReportProgress'

import { DATE_DISPLAY_FORMAT_TIME, formatDate } from 'services/dateService'

import { Report, ReportStatus } from 'types/Report'

import DownloadReportButton from './DownloadReportButton'

const StyledReportDownload = styled(Grid)(({ theme }) => ({
  display: 'flex',
  paddingLeft: theme.spacing(1),
}))

const StyledReportProgress = styled('div')(({ theme }) => ({
  barColor: 'primary',
  marginLeft: theme.spacing(1),
}))

interface Props {
  completedText?: string
  isGeneratingReport: boolean
  hasDateRange?: boolean
  hasError?: boolean
  isAuthorized: boolean
  latestReport: Report
  sellerName?: string
  title: string
}

export const SingleReportContent = ({
  completedText,
  isGeneratingReport,
  hasDateRange,
  hasError,
  isAuthorized,
  latestReport,
  sellerName,
  title,
}: Props) => {
  if (isGeneratingReport) {
    return (
      <Grid container spacing={2} sx={{ marginBottom: 1 }}>
        <Grid item xs={8}>
          <Text
            type="micro"
            sx={{ marginLeft: 1 }}
            data-testid="generating-text"
          >
            New report generating...
          </Text>
          <StyledReportProgress>
            <ReportProgress />
          </StyledReportProgress>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{ marginBottom: 1 }}
      data-testid="report-card-single"
    >
      <Grid item xs={7}>
        {hasError && (
          <>
            <Text type="micro" sx={{ marginLeft: 1 }}>
              Error: Unable to generate report
            </Text>
            <StyledReportProgress>
              <ReportProgress status={ReportStatus.ERROR} />
            </StyledReportProgress>
          </>
        )}
        {!hasError && (
          <>
            <Text type="micro" sx={{ marginLeft: 1 }}>
              {completedText ||
                `Latest Report Generated ${formatDate(
                  latestReport.created,
                  DATE_DISPLAY_FORMAT_TIME,
                )}`}
            </Text>
            <StyledReportProgress>
              <ReportProgress status={ReportStatus.COMPLETE} />
            </StyledReportProgress>
          </>
        )}
      </Grid>
      <StyledReportDownload item xs={4}>
        {!hasError && (
          <DownloadReportButton
            text="Download last report"
            sellerName={sellerName}
            report={latestReport}
            title={title}
            hasDateRange={!!hasDateRange}
            isDisabled={!isAuthorized}
          />
        )}
        {hasDateRange && (
          <DateRangeText
            data-testid="report-card-date"
            label="Report for date range"
            startDate={latestReport.start_date}
            endDate={latestReport.end_date}
          />
        )}
      </StyledReportDownload>
    </Grid>
  )
}

export default SingleReportContent
