import React from 'react'
import * as yup from 'yup'

import styled from '@emotion/styled'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'

import Input from 'components/common/Input'
import RadioGroup from 'components/common/RadioGroup/RadioGroup'

import { SUSPENSION_CODES } from 'constants/suspendErrorCodes'

import { getErrorCode } from 'services/errorCodes'
import { validationHandler, ValidationSchema } from 'services/validation'

import { ListingStatus, ProductError } from 'types/Item'
import { Validation } from 'types/Validation'

const StyledDescription = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))

export interface Props {
  // can't use dialog dispatch actions for open/close as this is used inside other dialogs (review queue)
  isOpen: boolean
  itemCount?: number
  // TODO: update the review queue and item details to use same format for updating statuses
  onSubmit?: (error: ProductError) => void | undefined
  onSubmitReviewQueue?: (status: ListingStatus, error?: ProductError) => void
  onSubmitItemDetails?: (
    listingStatus: ListingStatus,
  ) => (errors?: ProductError[]) => void
  onClose: () => void
}

const validationSchema: ValidationSchema = yup.object().shape({
  otherSuspensionReason: yup
    .string()
    .label('Reason')
    .min(20)
    .max(100)
    .required(),
})

export const SuspendItemDialog = ({
  isOpen,
  itemCount,
  onSubmit,
  onSubmitReviewQueue,
  onSubmitItemDetails,
  onClose,
}: Props) => {
  const [suspensionError, setSuspensionError] = React.useState<string>('')
  const [otherSuspensionReason, setOtherSuspensionReason] =
    React.useState<string>('')
  const [validation, setValidation] = React.useState<Validation>({})

  const handleInputChange = (value: string) => {
    setSuspensionError(value)
  }

  const handleSubmit = async () => {
    if (suspensionError === '806') {
      const { validation: newValidation, isValid } = validationHandler(
        validationSchema,
        { otherSuspensionReason },
      )

      setValidation(newValidation)

      if (!isValid) return
    }

    try {
      const errorResp = await getErrorCode(suspensionError)
      const { error_code, error_severity, reason } = errorResp
      const errorReason =
        suspensionError === '806' ? otherSuspensionReason : reason

      if (onSubmitReviewQueue) {
        onSubmitReviewQueue(ListingStatus.SUSPENDED, {
          category: 'DATA_VALIDATION',
          type: 'LISTING',
          error_code,
          error_severity,
          reason: errorReason,
        })
      } else if (onSubmitItemDetails) {
        onSubmitItemDetails(ListingStatus.SUSPENDED)([
          {
            category: 'DATA_VALIDATION',
            type: 'LISTING',
            error_code,
            error_severity,
            reason: errorReason,
          },
        ])
      } else if (onSubmit) {
        onSubmit({
          category: 'DATA_VALIDATION',
          type: 'LISTING',
          error_code,
          error_severity,
          reason: errorReason,
        })
      }
    } catch (e) {
      if (onSubmitReviewQueue) {
        onSubmitReviewQueue(ListingStatus.SUSPENDED)
      }
      if (onSubmitItemDetails) {
        onSubmitItemDetails(ListingStatus.SUSPENDED)()
      }
    }
  }

  const handleOtherReasonInputChanged = (value: string) => {
    setOtherSuspensionReason(value)
  }

  const title = itemCount
    ? `SUSPEND ${itemCount} ITEM(S)`
    : 'CONFIRM ITEM SUSPENSION'

  const description = itemCount
    ? 'Suspending will un-publish these items if currently published and block any new versions from being published'
    : 'Suspending this item will un-publish this item if currently published and block any new versions from being published.'

  const errorOptions = SUSPENSION_CODES.map((opt) => {
    return {
      label: opt.reason,
      value: opt.error_code.toString(),
    }
  })

  return (
    <Dialog data-testid="suspend-item-dialog" open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <StyledDescription>{description}</StyledDescription>
        <RadioGroup
          id="suspension-error"
          name="suspensionError"
          options={errorOptions}
          value={suspensionError}
          onChange={handleInputChange}
          label="Reason for suspension (this is visible to external partners)*"
        />
        {suspensionError === '806' && (
          <Input
            isRequired
            id="reason"
            name="otherSuspensionReason"
            value={otherSuspensionReason}
            onChange={handleOtherReasonInputChanged}
            validation={validation}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button data-testid="cancel" onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          data-testid="submit"
          variant="contained"
          onClick={handleSubmit}
          color="primary"
          autoFocus
          disabled={!suspensionError}
        >
          Suspend
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SuspendItemDialog
