import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'

const StyledTypography = styled(Typography)(({ theme }) => ({
  ...theme.micro,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

interface Props {
  contentName?: string
  requiredText?: string
  hasRequiredText?: boolean
  description: string
}

export const RequiredContent = ({
  contentName = 'content',
  requiredText = 'Required.',
  hasRequiredText = true,
  description,
}: Props) => {
  const message = description || `No ${contentName} entered yet.`
  return (
    <div>
      {hasRequiredText && (
        <StyledTypography data-testid="required-content-text">
          {requiredText}
        </StyledTypography>
      )}
      <StyledTypography data-testid="required-content-description">
        {message}
      </StyledTypography>
    </div>
  )
}

export default RequiredContent
