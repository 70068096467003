import React from 'react'

import styled from '@emotion/styled'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import GetAppIcon from '@mui/icons-material/GetApp'
import PieChartIcon from '@mui/icons-material/PieChart'
import LaunchIcon from '@mui/icons-material/Launch'

import DisplayCard from 'components/common/DisplayCard'
import TabularData, { FieldList } from 'components/common/TabularData'
import LinearGauge from 'components/common/LinearGauge'
import { DialogEnum } from 'components/common/Dialog'

import { target, success } from 'config/themeConfig'

import {
  formatDate,
  DATE_FORMAT_MONTH_DAY_YEAR,
  DATE_FORMAT_YEAR_MONTH_DAY_TIME,
} from 'services/dateService'

import { ScorecardGroup, Scorecard } from 'types/BiReporting'
import { FireflyEventInfo } from 'types/FireflyInsights'

import { insightCardData } from './insightCardData'
import { ScoreInfo } from './Dialogs/dialogHelpers'

export type InsightDialogProps = {
  dialog: DialogEnum
  dialogProps: Dictionary<string | ScoreInfo[]>
  eventInfo: FireflyEventInfo
}

export type Props = {
  group: ScorecardGroup
  onDownloadClick: (eventInfo: FireflyEventInfo) => void
  onOpenDialogClick: ({
    dialog,
    dialogProps,
    eventInfo,
  }: InsightDialogProps) => void
  onPageChangeClick: (eventInfo: FireflyEventInfo) => void
}

const fieldList: FieldList<Scorecard>[] = [
  {
    key: 'dateCreated',
    displayName: 'Date of Report',
    formatCell: (item) => {
      return formatDate(
        item.dateCreated,
        DATE_FORMAT_MONTH_DAY_YEAR,
        DATE_FORMAT_YEAR_MONTH_DAY_TIME,
      )
    },
  },
  {
    key: 'skuCount',
    displayName: 'Number of Items',
    formatCell: (item) => item.skuCount,
  },
  {
    key: 'chScore',
    displayName: 'Score',
    formatCell: (item) => item.chScore,
  },
]

type StyledProps = {
  currentScore: number
  previousScore: number
}

const StyledPrevScore = styled('span', {
  shouldForwardProp: (prop) =>
    prop !== 'currentScore' && prop !== 'previousScore',
})<StyledProps>((props) => ({
  color: props.currentScore >= props.previousScore ? success.main : target.main,
}))

const StyledScore = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(8),
  paddingLeft: theme.spacing(8),
  paddingRight: theme.spacing(8),
}))

const StyledCardTextSpace = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4),
}))

export const InsightCard: React.FC<Props> = ({
  group,
  onDownloadClick,
  onOpenDialogClick,
  onPageChangeClick,
}) => {
  const {
    currentScore,
    previousScore,
    displayDate,
    currentSkus,
    currentDownloadLink,
    templateName,
    scorecards,
  } = group
  const data = insightCardData[templateName]

  if (!data) {
    console.error(
      `CHECKPOINT: templateName ${templateName} is missing from card data`,
    )
    return null
  }
  const {
    title,
    description,
    icon,
    dialog,
    dialogProps,
    downloadFireflyInfo,
    dialogFireflyInfo,
    pageChangeFireflyInfo,
  } = data

  const handleDownloadClick = () => {
    onDownloadClick(downloadFireflyInfo)
  }

  const handleOpenDialog = () => {
    onOpenDialogClick({ dialog, dialogProps, eventInfo: dialogFireflyInfo })
  }

  const handlePageTransition = () => {
    onPageChangeClick(pageChangeFireflyInfo)
  }

  const sign = currentScore >= previousScore ? '+' : '-'
  const difference = Math.abs(currentScore - previousScore)

  return (
    <DisplayCard
      title={title}
      description={description}
      icon={icon}
      actions={
        scorecards.length > 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Button
                color="primary"
                href={currentDownloadLink}
                onClick={handleDownloadClick}
              >
                <GetAppIcon sx={{ marginRight: 1 }} /> Download score report
              </Button>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button color="primary" onClick={handleOpenDialog}>
                <PieChartIcon sx={{ marginRight: 1 }} /> View weighting & best
                practices
              </Button>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button color="primary" onClick={handlePageTransition}>
                <LaunchIcon sx={{ marginRight: 1 }} /> Increase score using
                services
              </Button>
            </Grid>
          </Grid>
        )
      }
    >
      <Grid container>
        <StyledScore item xs={6}>
          <Typography variant="h3">
            {currentScore}{' '}
            <StyledPrevScore
              currentScore={currentScore}
              previousScore={previousScore}
            >
              {sign}
              {difference}
            </StyledPrevScore>
          </Typography>
          <StyledCardTextSpace>
            For {currentSkus} SKUs evaluated on {displayDate}
          </StyledCardTextSpace>
          {currentScore && (
            <LinearGauge
              score={currentScore}
              pins={[
                { value: 0, label: 'Min' },
                { value: 80, label: 'Recommendation' },
                { value: 100, label: 'Max' },
              ]}
            />
          )}
        </StyledScore>
        <Grid item xs={6}>
          <TabularData
            borderTop
            hasHeader
            extraPadding={true}
            fieldList={fieldList}
            data={group.scorecards}
          />
        </Grid>
      </Grid>
    </DisplayCard>
  )
}

export default InsightCard
