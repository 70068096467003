import React from 'react'

import Typography from '@mui/material/Typography'

interface Info {
  componentStack: Nullable<string>
}

interface Props {
  page: string
  children: React.ReactNode
}

interface State {
  hasError: boolean
  error: Error | null
  info: Info | null
}

const MISSING_ERROR = 'Error was swallowed during propagation.'

export class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    hasError: false,
    error: null,
    info: null,
  }
  public static getDerivedStateFromError(error?: Error) {
    // Update state so the next render will show the fallback UI.

    return { hasError: true, error }
  }

  public componentDidCatch(error: Error | null, info: Info) {
    this.setState((prev: State) => ({
      ...prev,
      error: error ?? new Error(MISSING_ERROR),
      info,
      hasError: !!error,
    }))
  }

  public render() {
    const { page, children } = this.props
    const { hasError, error, info } = this.state

    if (hasError) {
      if (error?.name === 'ChunkLoadError') {
        return (
          <>
            <Typography variant="h1">Please refresh your browser.</Typography>
            <Typography>A file failed to load.</Typography>
          </>
        )
      }
      return (
        <div>
          <Typography variant="h2">
            Oops, something went wrong on the {page} page 😿
          </Typography>
          <Typography>
            The error: <>{error}</>
          </Typography>
          <Typography>
            Where it occurred: {info && info.componentStack}
          </Typography>
        </div>
      )
    }

    return children
  }
}

export default ErrorBoundary
