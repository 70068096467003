import { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'

import ContentSpacer from 'components/common/ContentSpacer'
import DataList, { DataListItem } from 'components/common/DataList'
import DialogContainer from 'components/common/Dialog/DialogContainer'

import {
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
  formatDate,
} from 'services/dateService'
import { getAttributeValuesById } from 'services/itemTaxonomies'

import { AttributeValue } from 'types/AttributeValue'
import { SeasonalEvent } from 'types/SeasonalEvent'

export type Props = {
  isOpen: boolean
  seasonalEvent: SeasonalEvent
}

export const ViewSeasonalEvent = ({ seasonalEvent, isOpen }: Props) => {
  const [seasonalValues, setSeasonalValues] = useState<AttributeValue[]>([])

  useEffect(() => {
    let mounted = true

    if (seasonalEvent.seasonal_attribute_value_id) {
      getAttributeValuesById([seasonalEvent.seasonal_attribute_value_id]).then(
        (attributes) => {
          if (mounted) {
            setSeasonalValues(attributes)
          }
        },
      )
    }

    return () => {
      mounted = false
    }
  }, [seasonalEvent.seasonal_attribute_value_id])

  const data1: DataListItem[] = [
    {
      title: 'Event Name:',
      value: seasonalEvent.name,
    },
    {
      title: 'Guest Mindset:',
      value: seasonalEvent.description,
    },
  ]
  const data2: DataListItem[] = [
    {
      title: 'Value for "Season or event depicted":',
      value: seasonalEvent.seasonal_attribute_value_id
        ? seasonalValues.find(
            (sv) => sv.id === seasonalEvent.seasonal_attribute_value_id,
          )?.name
        : 'None',
    },
    {
      title: 'Suggested Assortment:',
      value: seasonalEvent.suggested_assortment,
    },
  ]
  const data3: DataListItem[] = [
    {
      title: 'Start Date:',
      value: formatDate(
        seasonalEvent.start_date,
        DATE_FORMAT_MONTH_DAY_YEAR_TIME,
      ),
    },
    {
      title: 'End Date:',
      value: formatDate(
        seasonalEvent.end_date,
        DATE_FORMAT_MONTH_DAY_YEAR_TIME,
      ),
    },
  ]

  return (
    <DialogContainer
      isOpen={isOpen}
      title="Event Details"
      maxWidth="lg"
      closeButtonText="close"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DataList data={data1} />
          <ContentSpacer />
          <DataList data={data2} />
          <ContentSpacer />
          <DataList data={data3} />
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export default ViewSeasonalEvent
