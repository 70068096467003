import React, { Suspense } from 'react'
import chunk from 'lodash/fp/chunk'

import { EnhancedTableFieldType } from 'components/common/EnhancedTable'

import { DataType, formatValue, ValueFormats } from './SalesMetricsCardContent'

import EnhancedTable from 'components/common/EnhancedTable'

interface Props {
  graphData?: DataType[]
  isCompare: boolean
  valueFormat: ValueFormats
}

const SalesMetricsGraphTable = ({
  graphData,
  isCompare,
  valueFormat,
}: Props) => {
  const [pagination, setPagination] = React.useState({
    page: 0,
    rowsPerPage: 5,
  })
  const [tableData, setTableData] = React.useState<DataType[]>()

  React.useEffect(() => {
    const dataSegment = chunk(pagination.rowsPerPage, graphData)[
      pagination.page
    ]
    const tableData =
      isCompare && dataSegment ? calcDiffForTable(dataSegment) : dataSegment
    setTableData(tableData)
  }, [graphData, isCompare, pagination])

  const fieldList = [
    {
      key: 'date',
      heading: isCompare ? 'Base Date' : 'Date',
    },
    isCompare
      ? {
          key: 'compareDate',
          heading: 'Comparison Date',
        }
      : undefined,
    {
      key: 'base',
      heading: isCompare ? 'Base Value' : 'Value',
      formatCell: (rowItem: DataType, context: any) =>
        formatValue(rowItem.base as number, context.valueFormat),
    },
    isCompare
      ? {
          key: 'compare',
          heading: 'Comparison Value',
          formatCell: (rowItem: DataType, context: any) =>
            rowItem.compare
              ? formatValue(rowItem.compare as number, context.valueFormat)
              : '',
        }
      : undefined,
    isCompare
      ? {
          key: 'diff',
          heading: 'Difference (Base - Comparison)',
          formatCell: (rowItem: DataType, context: any) =>
            rowItem.diff
              ? formatValue(rowItem.diff as number, context.valueFormat)
              : '',
        }
      : undefined,
  ].filter((elem) => elem) as EnhancedTableFieldType<DataType>[]

  const calcDiffForTable = (graphData: DataType[]) =>
    graphData.map((item) => ({
      ...item,
      diff: item.compare
        ? (item.base as number) - (item.compare as number)
        : '',
    }))

  const handleRowsPerPageChange = (event: any) => {
    setPagination((prev) => ({
      ...prev,
      page: 0,
      rowsPerPage: event.target.value,
    }))
  }

  const handleChangePage = (_event: any, page?: number) => {
    if (page === undefined) return

    setPagination((prev) => ({ ...prev, page }))
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <EnhancedTable
        isLoading={false}
        data={tableData as object[]}
        fieldList={fieldList}
        fieldListContext={{ valueFormat }}
        rowsPerPage={pagination.rowsPerPage}
        rowsPerPageOptions={[5, 10, 20, 50]}
        onChangeRowsPerPage={handleRowsPerPageChange}
        page={pagination.page}
        onChangePage={handleChangePage}
        total={graphData?.length ?? 0}
      />
    </Suspense>
  )
}

export default SalesMetricsGraphTable
