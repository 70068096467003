import React from 'react'
import styled from '@emotion/styled'
import Button from '@mui/material/Button'

import SuspendItemDialog from './SuspendItemDialog'

import { trackCustomEvent } from 'services/fireflyInsights'

import { FireflyEvent } from 'types/FireflyInsights'
import { ListingStatus, ValidationStatus, ProductError } from 'types/Item'

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))

const StyledButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}))

interface Props {
  validationStatus: string
  isApproveDisabled: boolean
  interactionStart?: number
  submitReview: (
    status: ListingStatus,
    error?: ProductError,
  ) => void | undefined
  skipToNext: () => void
  reprocess: () => void
}

let cachedStart: number

export const ActionButtons = ({
  validationStatus,
  submitReview,
  isApproveDisabled,
  skipToNext,
  reprocess,
  interactionStart,
}: Props) => {
  if (interactionStart) {
    cachedStart = interactionStart
  }

  const [open, setOpen] = React.useState(false)

  const trackInteractionTime = (type: string) => {
    if (!cachedStart) return
    trackCustomEvent(
      FireflyEvent.ITEM_REVIEW_INTERACTION_TIME,
      type,
      Math.ceil((Date.now() - cachedStart) / 1000),
    )
  }

  const handleSubmitReview = (status: ListingStatus) => () => {
    trackInteractionTime(status)
    setOpen(false)
    submitReview(status)
  }

  const handleSkipToNext = () => {
    trackInteractionTime('SKIP')
    skipToNext()
  }

  const handleReEnrich = () => {
    trackInteractionTime('REPROCESS')
    reprocess()
  }

  const handleSuspendReview = (status: ListingStatus, error?: ProductError) => {
    trackInteractionTime(status)
    setOpen(false)
    submitReview(status, error)
  }

  const handleCancelSuspend = () => {
    setOpen(false)
  }

  const openSuspendDialog = () => {
    setOpen(true)
  }

  const disableApprove =
    isApproveDisabled ||
    ![
      ValidationStatus.REVIEW.toString(),
      ValidationStatus.VALIDATED.toString(),
    ].includes(validationStatus)

  const disableReprocess = validationStatus !== ValidationStatus.BLOCKED

  return (
    <>
      <StyledButtonContainer>
        <StyledButton
          color="primary"
          onClick={handleSubmitReview(ListingStatus.REJECTED)}
          data-testid="reject-button"
        >
          Reject
        </StyledButton>
        <StyledButton
          variant="contained"
          color="primary"
          disabled={disableApprove}
          onClick={handleSubmitReview(ListingStatus.APPROVED)}
          data-testid="approve-button"
        >
          Approve
        </StyledButton>
      </StyledButtonContainer>
      <StyledButtonContainer>
        <StyledButton
          color="primary"
          onClick={handleSkipToNext}
          data-testid="skip-to-next-button"
        >
          Skip to next
        </StyledButton>
      </StyledButtonContainer>
      <StyledButtonContainer>
        <StyledButton
          color="primary"
          onClick={openSuspendDialog}
          data-testid="suspend-button"
        >
          Suspend
        </StyledButton>
        <StyledButton
          color="primary"
          onClick={handleReEnrich}
          disabled={disableReprocess}
          data-testid="reprocessButton"
        >
          Reprocess
        </StyledButton>
      </StyledButtonContainer>
      <SuspendItemDialog
        isOpen={open}
        onSubmitReviewQueue={handleSuspendReview}
        onClose={handleCancelSuspend}
      />
    </>
  )
}

export default ActionButtons
