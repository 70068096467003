import React from 'react'

import styled from '@emotion/styled'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import { CONTENT_PADDING } from 'constants/layout'

import { MarketplaceProduct, SmsProduct } from 'types/Item'

import EnrichedPublishedData from './EnrichedPublishedData'
import ItemOrders from './ItemOrders'
import LatestItemData from './LatestItemData'
import Relationships from './Relationships'
import { LogisticsData } from './LogisticsData'
import { Logistics, ProductLogistics } from 'types/ProductLogistics'
import { white } from 'config/themeConfig'
import Sticky from 'components/common/Sticky'

const StyledTabContainer = styled(Tabs)({
  margin: `0 -${CONTENT_PADDING}px`,
  padding: `0 ${CONTENT_PADDING}px`,
  background: white.main,
})

const StyledTabContent = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(3),
}))

export type Props = {
  marketplaceProduct?: MarketplaceProduct
  smsProduct?: SmsProduct
  showEnrichedDataTab: boolean
  productLogistics: ProductLogistics | undefined
  logistics: Logistics[]
  hasProductLogisticsTab: boolean
  isItemPending: boolean
}

enum ItemTab {
  ITEM_DATA = 'ITEM_DATA',
  RELATIONSHIPS = 'RELATIONSHIPS',
  ORDERS = 'ORDER',
  ENRICHED_PUBLISHED_DATA = 'ENRICHED_PUBLISHED_DATA',
  LOGISTICS_DATA = 'LOGISTICS_DATA',
}

export const ItemDetailsTabs: React.FC<Props> = ({
  showEnrichedDataTab,
  productLogistics,
  logistics,
  hasProductLogisticsTab,
  marketplaceProduct,
  smsProduct,
  isItemPending,
}) => {
  const [tabValue, setTabValue] = React.useState<ItemTab>(ItemTab.ITEM_DATA)

  const handleChange = (_event: any, newValue: ItemTab) => {
    setTabValue(newValue)
  }

  return (
    <>
      <Sticky enabled={true} top={112}>
        <StyledTabContainer
          value={tabValue}
          onChange={handleChange}
          indicatorColor="primary"
        >
          <Tab
            value={ItemTab.ITEM_DATA}
            label="Latest Item Data &amp; Errors"
          />
          <Tab value={ItemTab.RELATIONSHIPS} label="Relationships" />
          <Tab value={ItemTab.ORDERS} label="Orders" />
          {showEnrichedDataTab && (
            <Tab
              value={ItemTab.ENRICHED_PUBLISHED_DATA}
              label="Enriched Published Data (Internal)"
            />
          )}
          {hasProductLogisticsTab && (
            <Tab value={ItemTab.LOGISTICS_DATA} label="Logistics Data" />
          )}
        </StyledTabContainer>
      </Sticky>
      <StyledTabContent>
        {tabValue === ItemTab.ITEM_DATA && (
          <LatestItemData
            smsProduct={smsProduct}
            isItemPending={isItemPending}
          />
        )}
        {tabValue === ItemTab.RELATIONSHIPS && (
          <Relationships
            isItemPending={isItemPending}
            marketplaceProduct={marketplaceProduct}
          />
        )}
        {tabValue === ItemTab.ORDERS && (
          <ItemOrders tcin={marketplaceProduct?.tcin} />
        )}
        {tabValue === ItemTab.ENRICHED_PUBLISHED_DATA &&
          showEnrichedDataTab &&
          marketplaceProduct &&
          smsProduct && (
            <EnrichedPublishedData
              marketplaceProduct={marketplaceProduct}
              smsProduct={smsProduct}
            />
          )}
        {tabValue === ItemTab.LOGISTICS_DATA && hasProductLogisticsTab && (
          <LogisticsData
            logistics={logistics}
            lastModified={productLogistics?.last_modified}
          />
        )}
      </StyledTabContent>
    </>
  )
}

export default ItemDetailsTabs
