import { forwardRef, ChangeEvent } from 'react'
import { Field, FieldProps } from 'formik'
import NumberFormat from 'react-number-format'

import TextField, { TextFieldProps } from '@mui/material/TextField'

interface FormProps {
  name: string
  maxCharacters?: number
}

type Props = FormProps &
  Omit<TextFieldProps, 'error' | 'name' | 'onChange' | 'value'>

type CustomProps = {
  name: string
}

const NumberFormatCustom = forwardRef<NumberFormat<any>, CustomProps>(
  function NumberFormatCustom(props: any, ref: any) {
    return (
      <NumberFormat
        {...props}
        getInputRef={ref}
        isNumericString
        suffix="%"
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }}
      />
    )
  },
)

const NumberMaskInputField = ({
  name,
  helperText,
  disabled,
  ...props
}: Props) => (
  <Field name={name}>
    {({ form, field }: FieldProps) => {
      const { touched, errors, isSubmitting, setFieldTouched, handleChange } =
        form

      const isDisabled =
        typeof disabled === 'undefined' ? isSubmitting : disabled
      const error = errors[name]
      const hasError = !!(touched[name] && error)

      let hint = undefined

      if (hasError) {
        hint = error
      } else {
        hint = helperText
      }

      const onChangeHandler = (event: ChangeEvent<any>) => {
        // formik does not set the initial field to "touched" for
        // validation on the initial input focus so force it
        setFieldTouched(name)
        handleChange(event)
      }

      return (
        <TextField
          {...props}
          {...field}
          variant="standard"
          error={hasError}
          helperText={hint?.toString()}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: NumberFormatCustom as any,
          }}
          inputProps={{
            'data-testid': name,
          }}
          disabled={isDisabled}
          onChange={onChangeHandler}
          fullWidth
        />
      )
    }}
  </Field>
)

export default NumberMaskInputField
