import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'

import { DialogEnum } from 'components/common/Dialog'
import HeaderTitle from 'components/common/HeaderTitle'

import {
  DateRange,
  getRangeConfig,
  generateOrderDefectMetricRange,
  Range,
} from 'components/common/DateRangePicker/rangeConfig'

import {
  getTimePeriod,
  getVendorFilter,
  getOrderDefectRateOverTime,
} from 'services/biReporting'
import { DATE_PICKER_FORMAT, formatDate } from 'services/dateService'
import { startOfDay, getDateOneYearAgo } from 'services/dateService'
import { formatGreenfieldDayInterval } from 'services/greenfieldHelpers'
import { allOrderDefectMetricsTypes } from 'services/vendorStatsHelper'

import { openDialog } from 'store/dialog/actionCreator'
import { currentSellerId, currentSellerVmmId } from 'store/selectors'

import { BiReportingCards } from 'types/BiReporting'

import OverallDefectRateCard from './OverallDefectRateCard'
import RateOverTimeCard from './RateOverTimeCard'

const StyledSpacer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

export interface MetricsDataItem {
  title: string
  value: number | string
}

const odrKey = 'ODR Rate'

const dateRangeOptions = generateOrderDefectMetricRange()

export const OrderDefectsPage = () => {
  const dispatch = useDispatch()

  const sellerId = useSelector(currentSellerId)
  const vmmId = useSelector(currentSellerVmmId)

  const dateOneYearAgo = startOfDay(getDateOneYearAgo())
  const last90Days = getRangeConfig(
    DateRange.LAST_90_DAYS,
    undefined,
    'odrMetric',
  )
  const defaultRange = {
    from: startOfDay(last90Days.from),
    to: startOfDay(last90Days.to),
  }
  const greenfieldInterval = formatGreenfieldDayInterval(last90Days)
  const [overallDefectRate, setOverallDefectRate] = React.useState<number>()
  const [range, setRange] = React.useState<Range>(defaultRange)
  const [isCustomRange, setIsCustomRange] = React.useState(false)

  React.useEffect(() => {
    let mounted = true

    if (sellerId && vmmId) {
      const vendorFilter = getVendorFilter(vmmId)
      const timePeriod = getTimePeriod({ interval: greenfieldInterval })

      getOrderDefectRateOverTime({
        key: odrKey,
        cardId: BiReportingCards.OVERALL_ORDER_DEFECT_RATE,
        sellerId,
        filters: vendorFilter,
        timePeriod,
      }).then((res) => {
        if (mounted && res !== undefined) {
          setOverallDefectRate(res)
        }
      })
    }

    return () => {
      mounted = false
    }
  }, [sellerId, vmmId, greenfieldInterval])

  const onRequestDateRangeChange = ({
    from,
    to,
    isCustom,
  }: {
    from: Date
    to: Date
    isCustom: boolean
  }) => {
    setRange({ from, to })
    setIsCustomRange(isCustom)
  }

  const onRequestReportGenerate = ({ from, to }: { from: Date; to: Date }) => {
    const startDate = formatDate(from, DATE_PICKER_FORMAT)
    const endDate = formatDate(to, DATE_PICKER_FORMAT)
    dispatch(
      openDialog({
        dialogEnum: DialogEnum.ORDER_DEFECT_RATE_GENERATE_DIALOG,
        componentProps: {
          sellerId,
          metricType: allOrderDefectMetricsTypes,
          startDate,
          endDate,
          timeframeLabel: `All ODR Categories (${startDate} to ${endDate})`,
        },
      }),
    )
  }

  return (
    <div data-testid="performance-page">
      <HeaderTitle title="Order Defect Rate" />
      <StyledSpacer>
        <OverallDefectRateCard
          defectRate={overallDefectRate}
          greenfieldInterval={greenfieldInterval}
        />
      </StyledSpacer>

      {sellerId && vmmId && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RateOverTimeCard
              title="Overall ODR Over Time"
              sellerId={sellerId}
              vmmId={vmmId}
              cardId={BiReportingCards.OVERALL_ORDER_DEFECT_RATE}
              graphCardId={
                BiReportingCards.OVERALL_ORDER_DEFECT_RATE_LINE_CHART
              }
              property={odrKey}
              dateRangeOptions={dateRangeOptions}
              defaultRange={DateRange.LAST_90_DAYS}
              minDate={dateOneYearAgo}
              from={range.from}
              to={range.to}
              isCustomDateRange={isCustomRange}
              onDateRangeChange={onRequestDateRangeChange}
              onReportGenerate={onRequestReportGenerate}
            />
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default OrderDefectsPage
