import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Typography from '@mui/material/Typography'

import ContentSpacer from 'components/common/ContentSpacer'
import { DialogEnum } from 'components/common/Dialog'
import DisplayCard from 'components/common/DisplayCard'
import Link from 'components/common/Link'

import { openDialog } from 'store/dialog/actionCreator'
import { isRoleExternalUserSelector } from 'store/selectors'

interface Props {
  articleId: string
  articleTitle: string
}

const GetHelp = ({ articleId, articleTitle }: Props) => {
  const dispatch = useDispatch()

  const isExternalUser = useSelector(isRoleExternalUserSelector)
  const [submitted, setSubmitted] = React.useState<boolean>(false)

  const onSubmit = () => {
    setSubmitted(true)
  }

  const linkText = 'Open a case within Marketplace Portal'
  const link = (
    <Link
      data-testid="open-case"
      onClick={(event) => {
        event.preventDefault()
        dispatch(
          openDialog({
            dialogEnum: DialogEnum.ADD_NEW_CASE,
            componentProps: { articleId, articleTitle, onSubmit },
          }),
        )
      }}
      to=""
    >
      {linkText}
    </Link>
  )

  return (
    <DisplayCard title="More Ways to Get Help">
      <Typography>
        {!submitted && isExternalUser ? link : linkText} if you need personal
        assistance.
      </Typography>
      <ContentSpacer />
      <Typography>
        Work with your dedicated Support & Performance Team member to address
        any questions you may have.
      </Typography>
    </DisplayCard>
  )
}

export default GetHelp
