import { DataListItem } from 'components/common/DataList'
import { Direction } from 'services/pageableHelper'

import { SellerStatus } from 'types/Seller'
import { HeliosItem } from 'types/HeliosItem'

// products endpoint does not have product_id so have the service populate it from id in the response
// product histories endpoint has product_id as the product's id, the id will be {product_id}-{product_status_id}
export interface SmsProductResponse extends SmsProduct {
  id: string
}

export interface SmsProduct {
  id?: string
  product_id: string
  external_id: string
  tcin?: string
  seller_id: string
  relationship_type: string
  fields?: ProductField[] // TODO: if the api makes this required then we can remove the optional `?`
  parent_id?: string
  product_statuses: ProductStatus[]
  quantities?: Inventory[]
  price?: Price
  item_type_id?: number
  created: string
  created_by: string
  last_modified: string
  last_modified_by: string
}

export interface Inventory {
  quantity: number
  distribution_center_id: string
  last_modified: string
  last_modified_by: string
}

export interface Price {
  list_price: number
  offer_price: number
  map_price: number
  last_modified: string
}

export interface ProductStatus {
  created: string
  created_by?: string
  current: boolean
  id: string
  last_modified: string
  last_modified_by: string
  listing_status: ListingStatus
  validation_status: string
  version?: number
  errors: ProductError[]
}

export interface Variation {
  refinement: string
  value?: string
}

export interface ProductError {
  category?: string // "DATA_VALIDATION"
  field_name?: string
  reason: string
  type: string // "ITEM, LISTING"
  error_severity?: string //CRITICAL, NON_CRITICAL only for LISTING type
  error_code?: number // only for LISTING type
  decision_id?: string
}

export interface ProductField {
  name: string
  value: string
}

export interface ItemData {
  product_id?: string
  seller_id?: string
  external_id?: string
  tcin?: string
  title?: string
  primary_image?: string
  barcode?: string
}

export interface MarketplaceProduct extends ItemData {
  id: string
  product_id: string
  tcin?: string
  external_id: string
  parent_tcin: string
  title: string
  buy_url: string
  item_type_id: number
  item_type_name: string
  product_type_name: string
  product_status_id: string
  parent_product_id: string
  relationship_type: string
  variation_theme: string
  variations?: Variation[]
  inventory?: number
  helios_version: number
  published_helios_version: number
  published: boolean
  listing_status: ListingStatus
  validation_status: ValidationStatus
  seller_id: string
  seller_name: string
  seller_status?: SellerStatus
  primary_image: string
  barcode: string
  fields: ProductField[]
  created: string
  previously_approved: boolean
  last_modified: string
  current_status?: {
    listing_status: ListingStatus
    product_status_id: string
    validation_status: ValidationStatus
    last_modified: string
  }
}

export interface ProductSearchParams {
  seller_id?: string | string[]
  item_type_id?: string | string[]
  validation_status?: string | string[]
  listing_status?: string | string[]
  current_listing_status?: string[]
  relationship_type?: string | string[]
  product_id?: string
  parent_product_id?: string
  tcin?: string
  published?: boolean
  query?: string
  has_inventory?: boolean
  previously_approved?: boolean
  orderBy?: string
  direction?: Direction
}

export interface ItemDetailsResponse {
  smsProduct: SmsProduct
  marketplaceProduct: MarketplaceProduct
}

export enum RelationshipType {
  SA = 'SA',
  VAP = 'VAP',
  VC = 'VC',
}

export const RelationshipTypeLabel: Dictionary<string> = {
  [RelationshipType.SA]: 'SA',
  [RelationshipType.VAP]: 'VAP',
  [RelationshipType.VC]: 'VC',
}

export enum ListingStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  SUSPENDED = 'SUSPENDED',
  UNLISTED = 'UNLISTED',
}

export enum ValidationStatus {
  ERROR = 'ERROR',
  INITIATED = 'INITIATED',
  VALIDATED = 'VALIDATED',
  BLOCKED = 'BLOCKED',
  REVIEW = 'REVIEW',
}

export const ValidationStatusLabel: Dictionary<string> = {
  [ValidationStatus.ERROR]: 'ERROR',
  [ValidationStatus.INITIATED]: 'INITIATED',
  [ValidationStatus.VALIDATED]: 'VALIDATED',
  [ValidationStatus.BLOCKED]: 'BLOCKED',
  [ValidationStatus.REVIEW]: 'REVIEW',
}

export interface ProductStatusUpdate {
  listing_status: ListingStatus
  errors: ProductError[]
}

export interface UpdateProductStatusParams {
  sellerId: string
  productId: string
  statusId: string
  productStatusUpdate: ProductStatusUpdate
}

export interface UpdateItemListingStatusParams {
  sellerId: string
  productId: string
  statusId: string
  tcin?: string
  listingStatus: ListingStatus
  errors: ProductError[]
}
export interface EnrichedValue {
  raw: string
  enriched?: string
  helperText?: string
}

export interface EnrichedProductField extends ProductField {
  displayName: string | DataListItem
  displayValue: EnrichedValue
  isMerchandiseTypeAttribute: boolean
  isChecked?: boolean
  isRequired?: boolean
  isImage?: boolean
  order: number
}

export interface EnrichedPublishedProductField extends ProductField {
  displayName: string
  displayValue: string | undefined
  enrichedValue: string | undefined
  rawValue: string | undefined
  isMerchandiseTypeAttribute: boolean
  order: number
  isImage: boolean
  hasRawValue?: boolean
  helperText: string | undefined
}

export interface ProductReviewData {
  reviewProduct: MarketplaceProduct
  itemHistories: SmsProduct[]
  heliosItem: HeliosItem
  smsProduct: SmsProduct
}

export interface MarketplaceProductLock {
  id: string
  resource_id: string
  locked_by: string
  locked_on: string
  created: string
  expires_on: string
}

export enum LockStatus {
  LOCKED = 'LOCKED',
  UNLOCKED = 'UNLOCKED',
  FAILED = 'FAILED',
}

export interface MarketplaceProductLockWithStatus {
  resource_id: string
  lock_status: LockStatus
  lock: MarketplaceProductLock
}

export interface MarketplaceReviewProduct {
  marketplace_product: MarketplaceProduct
  lock?: MarketplaceProductLock
}
