import {
  MarketplaceProduct,
  ProductReviewData,
  ProductSearchParams,
} from 'types/Item'

export const SET_ITEM_REVIEW_PENDING = 'SET_ITEM_REVIEW_PENDING'
export const FETCH_NEXT_ITEM = 'FETCH_NEXT_ITEM'
export const FETCH_NEXT_ITEM_DONE = 'FETCH_NEXT_ITEM_DONE'
export const SET_ITEM_PROCESSED = 'SET_ITEM_PROCESSED'
export const SET_PREVIOUS_ITEM = 'SET_PREVIOUS_ITEM'
export const SET_PARENT = 'SET_PARENT'
export const RESET_QUEUE = 'RESET_QUEUE'

export interface FetchNextItemAction {
  readonly type: typeof FETCH_NEXT_ITEM
  params: {
    searchParams: ProductSearchParams
  }
}

export interface FetchNextItemDoneAction {
  readonly type: typeof FETCH_NEXT_ITEM_DONE
  payload: {
    productReviewData: ProductReviewData | undefined
  }
}

export interface SetItemReviewPendingAction {
  readonly type: typeof SET_ITEM_REVIEW_PENDING
  payload: boolean
}

export interface SetParentAction {
  readonly type: typeof SET_PARENT
  payload: string | undefined
}

export interface SetPreviousItemAction {
  readonly type: typeof SET_PREVIOUS_ITEM
  payload: string | undefined
}
export interface SetItemProcessedAction {
  readonly type: typeof SET_ITEM_PROCESSED
  payload: {
    product: MarketplaceProduct
    skip: boolean
  }
}

export interface ResetQueueAction {
  readonly type: typeof RESET_QUEUE
}

export const setItemReviewPending = (
  pending: boolean,
): SetItemReviewPendingAction =>
  ({
    type: SET_ITEM_REVIEW_PENDING,
    payload: pending,
  }) as const

export const fetchNextItem = ({
  searchParams,
}: {
  searchParams: ProductSearchParams
}): FetchNextItemAction =>
  ({
    type: FETCH_NEXT_ITEM,
    params: {
      searchParams,
    },
  }) as const

export const fetchNextItemDone = (
  productReviewData: ProductReviewData | undefined,
): FetchNextItemDoneAction =>
  ({
    type: FETCH_NEXT_ITEM_DONE,
    payload: {
      productReviewData,
    },
  }) as const

export const setItemProcessed = (
  product: MarketplaceProduct,
  skip: boolean,
): SetItemProcessedAction =>
  ({
    type: SET_ITEM_PROCESSED,
    payload: { product, skip },
  }) as const

export const setPreviousItem = (productId: string): SetPreviousItemAction =>
  ({
    type: SET_PREVIOUS_ITEM,
    payload: productId,
  }) as const

export const setParentId = (productId: string | undefined): SetParentAction =>
  ({
    type: SET_PARENT,
    payload: productId,
  }) as const

export const resetQueue = (): ResetQueueAction =>
  ({
    type: RESET_QUEUE,
  }) as const

export type ItemReviewActions = ReturnType<
  | typeof setItemReviewPending
  | typeof fetchNextItem
  | typeof fetchNextItemDone
  | typeof setItemProcessed
  | typeof setPreviousItem
  | typeof setParentId
  | typeof resetQueue
>
