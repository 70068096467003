import { useEffect, useState } from 'react'

import isEmpty from 'lodash/fp/isEmpty'

import styled from '@emotion/styled'
import WarningIcon from '@mui/icons-material/Warning'
import RemoveIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import { CheckCircle } from '@mui/icons-material'

import DisplayCard from 'components/common/DisplayCard'

import {
  getFieldErrors,
  getLatestProductStatus,
  FieldError,
} from 'services/itemHelper'
import { overrideItemType } from 'services/itemOverrides'
import { getNodeById } from 'services/itemTaxonomies'

import { itemTypeErrorCode } from 'constants/listingError'

import Attribute from 'types/Attribute'
import { SmsProduct, ValidationStatus, ProductError } from 'types/Item'

export const itemTypeOverrideMessage = (itemTypeName: string | undefined) => {
  return ` If 'X' is clicked, "${itemTypeName ?? ''}" will be the predicted
  item type for this TCIN and this error will not show on
  future versions.`
}

const StyledLabel = styled('span')({
  fontWeight: 'bold',
})

const StyledError = styled(ListItemText)({
  overflowWrap: 'break-word',
})

const StyledFlexContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const StyledIcon = styled(CheckCircle)(({ theme }) => ({
  marginRight: theme.spacing(2),
  fontSize: 44,
}))

const StyledWarningIcon = styled(WarningIcon)(({ theme }) => ({
  color: theme.palette.error.main,
}))

const StyledSuccessListItemText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.success.main,
}))

const StyledSuccessTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main,
}))

const StyledCriticalListItemText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.error.main,
  overflowWrap: 'break-word',
}))
export interface Props {
  latestVersion: SmsProduct
  errors: ProductError[]
  removeError: (error: FieldError) => void
}

export const LatestErrors = ({ latestVersion, errors, removeError }: Props) => {
  const [itemOverridden, setItemOverridden] = useState(false)
  const [itemType, setItemType] = useState<Attribute>()

  const productStatus = getLatestProductStatus(latestVersion)
  const fieldErrors = getFieldErrors(errors)

  useEffect(() => {
    let mounted = true

    if (
      latestVersion.item_type_id &&
      fieldErrors.some((error) => error.code === itemTypeErrorCode)
    ) {
      getNodeById(latestVersion.item_type_id.toString()).then((node) => {
        if (mounted) {
          setItemType(node)
        }
      })
    }

    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setNewItemTypeOverride = (error: FieldError) => {
    let mounted = true

    if (latestVersion.tcin && latestVersion.item_type_id) {
      removeError(error)
      overrideItemType(latestVersion.tcin, latestVersion.item_type_id).then(
        () => {
          if (mounted) {
            setItemOverridden(true)
          }
        },
      )
    }
    return () => {
      mounted = false
    }
  }

  const status = productStatus?.validation_status ?? ''
  const canRemove =
    status === ValidationStatus.REVIEW || status === ValidationStatus.VALIDATED

  return (
    <DisplayCard title="Latest Errors" fullHeight={false}>
      {itemOverridden && (
        <ListItem disableGutters>
          <StyledSuccessListItemText data-testid="item-type-overridden">
            <StyledFlexContainer>
              <StyledIcon data-testid="icon-complete" />
              <StyledSuccessTypography>
                The item type prediction has been updated to "{itemType?.name}"
                and this error will not show on future versions.
              </StyledSuccessTypography>
            </StyledFlexContainer>
          </StyledSuccessListItemText>
        </ListItem>
      )}
      {isEmpty(fieldErrors) ? (
        <Typography>No errors on latest version.</Typography>
      ) : (
        <List>
          {fieldErrors.map((error, index) => (
            <div key={index}>
              <ListItem disableGutters>
                {error.critical && (
                  <ListItemAvatar data-testid={`critical-${index}`}>
                    <StyledWarningIcon />
                  </ListItemAvatar>
                )}
                <StyledError data-testid={`message-${index}`}>
                  <StyledLabel>{error.displayName}</StyledLabel>
                  {`: ${error.message}`}
                </StyledError>
                {canRemove && error.code !== itemTypeErrorCode && (
                  <ListItemSecondaryAction>
                    <IconButton
                      data-testid={`remove-${index}`}
                      edge="end"
                      aria-label="Remove error"
                      onClick={() => removeError(error)}
                      size="large"
                    >
                      <RemoveIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
                {canRemove && error.code === itemTypeErrorCode && (
                  <ListItemSecondaryAction>
                    <IconButton
                      data-testid="item-override"
                      edge="end"
                      aria-label="override item type"
                      onClick={() => setNewItemTypeOverride(error)}
                      size="large"
                    >
                      <RemoveIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              {canRemove && error.code === itemTypeErrorCode && (
                <ListItem disableGutters>
                  <StyledCriticalListItemText data-testid={`message-${index}`}>
                    {itemTypeOverrideMessage(itemType?.name)}
                  </StyledCriticalListItemText>
                </ListItem>
              )}
              <Divider />
            </div>
          ))}
        </List>
      )}
    </DisplayCard>
  )
}

export default LatestErrors
