import { useDispatch } from 'react-redux'
import getOr from 'lodash/fp/getOr'

import Typography from '@mui/material/Typography'

import ItemDataTable, { ItemDataEnum } from '../ItemDataTable'
import FullScreenDialogContainer from 'components/common/Dialog/FullScreenDialogContainer'

import { closeDialog } from 'store/dialog/actionCreator'
import {
  formatDate,
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
} from 'services/dateService'
import { SmsProduct } from 'types/Item'

interface Props {
  publishedItem: SmsProduct
  isOpen: boolean
}

export const PublishedItemData = ({ publishedItem, isOpen }: Props) => {
  const dispatch = useDispatch()

  const createdDate = getOr(
    '',
    'created',
    publishedItem.product_statuses.find((status) => status.current),
  )

  const handleClose = () => {
    dispatch(closeDialog())
  }

  return (
    <FullScreenDialogContainer
      title="Published Item Data"
      onRequestClose={handleClose}
      isOpen={isOpen}
    >
      <Typography paragraph>
        The published version of this item was received on{' '}
        {formatDate(createdDate, DATE_FORMAT_MONTH_DAY_YEAR_TIME)}
      </Typography>
      <ItemDataTable
        item={publishedItem}
        itemDataType={ItemDataEnum.PUBLISHED}
      />
    </FullScreenDialogContainer>
  )
}

export default PublishedItemData
