import React from 'react'

import FeatureWrapper from 'components/common/FeatureWrapper'
import ReportCard from 'components/common/ReportCard'

import uniq from 'lodash/fp/uniq'

import Grid from '@mui/material/Grid'

import {
  USER_ROLE_ADMIN,
  USER_ROLE_FINANCE,
  USER_ROLE_OPS,
} from 'services/roles'
import { getSeller } from 'services/seller'

import { Report, ReportType } from 'types/Report'
import { ALL_ENVIRONMENTS } from 'config/apiConfig'

const ALL_PARTNERS = 'All Partners'

const getReportSellerName = async (reports: Report[]) => {
  const sellerIds = reports
    .filter((report) => report.seller_id)
    .map((report) => report.seller_id)

  const uniqSellerIds = uniq(sellerIds)

  const sellers = await Promise.all(
    uniqSellerIds.map((sellerId) => getSeller(sellerId)),
  )

  const reportsWithSellerNames = reports.map((report) => {
    const newReport = { ...report }
    const seller = sellers.find((target) => target?.id === newReport.seller_id)

    if (seller) {
      newReport.report_name =
        seller?.display_name ?? seller?.legal_business_name ?? 'Unknown Seller'
    } else {
      newReport.report_name = ALL_PARTNERS
    }
    return newReport
  })
  return reportsWithSellerNames
}

export const InternalReports = () => (
  <React.Fragment>
    <Grid container spacing={2} justifyContent="space-between">
      <Grid data-testid="assortment-analysis" item sm={12} md={8} lg={6}>
        <ReportCard reportType={ReportType.ASSORTMENT_ANALYSIS} />
      </Grid>
      <Grid data-testid="return-orders" item sm={12} md={8} lg={6}>
        <ReportCard
          reportType={ReportType.RETURN_ORDERS_INTERNAL}
          getAdditionalInfo={getReportSellerName}
        />
      </Grid>
      <Grid data-testid="seller-contact" item sm={12} md={8} lg={6}>
        <ReportCard reportType={ReportType.MARKETPLACE_USERS} />
      </Grid>
      <Grid data-testid="seller-operations" item sm={12} md={8} lg={6}>
        <ReportCard reportType={ReportType.SELLER_OPERATIONS_DETAILS} />
      </Grid>
      <FeatureWrapper
        conditions={[
          {
            environments: ALL_ENVIRONMENTS,
            roles: [USER_ROLE_ADMIN, USER_ROLE_FINANCE, USER_ROLE_OPS],
          },
        ]}
      >
        <Grid
          data-testid="return-dispute-financials"
          item
          sm={12}
          md={8}
          lg={6}
        >
          <ReportCard reportType={ReportType.VALID_RETURN_DISPUTES} />
        </Grid>
      </FeatureWrapper>
      <Grid data-testid="auto-approval-blocked-list" item sm={12} md={8} lg={6}>
        <ReportCard reportType={ReportType.AUTO_APPROVAL_BLOCKED_LIST} />
      </Grid>
      <Grid data-testid="partner-diversity" item sm={12} md={8} lg={6}>
        <ReportCard reportType={ReportType.PARTNER_DIVERSITY} />
      </Grid>
      <Grid data-testid="orders-past-sla" item sm={12} md={8} lg={6}>
        <ReportCard reportType={ReportType.ORDERS_PAST_SLA} />
      </Grid>
      <Grid data-testid="partner-price-details" item sm={12} md={8} lg={6}>
        <ReportCard reportType={ReportType.PARTNER_PRICE_DETAILS} />
      </Grid>
      <FeatureWrapper
        conditions={[
          {
            environments: ALL_ENVIRONMENTS,
            roles: [USER_ROLE_ADMIN, USER_ROLE_FINANCE, USER_ROLE_OPS],
          },
        ]}
      >
        <Grid data-testid="finance-details" item sm={12} md={8} lg={6}>
          <ReportCard reportType={ReportType.PARTNER_FINANCIAL_DETAILS} />
        </Grid>
      </FeatureWrapper>
      <Grid data-testid="unpaid-orders" item sm={12} md={8} lg={6}>
        <ReportCard reportType={ReportType.ORDERS_MISSING_TRANSFERS} />
      </Grid>
      <Grid data-testid="partner-return-policies" item sm={12} md={8} lg={6}>
        <ReportCard reportType={ReportType.PARTNER_RETURN_POLICIES} />
      </Grid>
      <Grid data-testid="return-policy-errors" item sm={12} md={8} lg={6}>
        <ReportCard reportType={ReportType.PRODUCT_LOGISTIC_ERRORS_INTERNAL} />
      </Grid>
      <Grid data-testid="item-quota-and-counts" item sm={12} md={8} lg={6}>
        <ReportCard reportType={ReportType.PARTNER_QUOTA_COUNTS} />
      </Grid>
      <Grid data-testid="inform-act" item sm={12} md={8} lg={6}>
        <ReportCard reportType={ReportType.INFORM_ACT} />
      </Grid>
    </Grid>
  </React.Fragment>
)

export default InternalReports
