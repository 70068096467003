import React from 'react'
import isNumber from 'lodash/fp/isNumber'

import TablePagination from '@mui/material/TablePagination'
import Typography from '@mui/material/Typography'
import styled from '@emotion/styled'

interface Props {
  count: number
  items: React.ReactNode[]
  onChangePage: (page: number) => void
  onChangeRowsPerPage: (rowsPerPage: number) => void
  page: number
  rowsPerPage: number
}

const StyledDiv = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(3),
}))

const PaginatedList = ({
  count,
  items,
  page,
  onChangePage,
  onChangeRowsPerPage,
  rowsPerPage,
}: Props) => {
  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number,
  ) => {
    onChangePage(page)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (event.target.value) {
      const value = parseInt(event.target.value, 10)
      if (isNumber(value)) {
        onChangeRowsPerPage(value)
      }
    }
  }

  return (
    <div>
      {items.map((item, index) => (
        <StyledDiv key={index}>
          {typeof item === 'string' ? <Typography>{item}</Typography> : item}
        </StyledDiv>
      ))}
      <TablePagination
        component="div"
        count={count}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        nextIconButtonProps={{ id: 'nextButton' }}
      />
    </div>
  )
}

export default PaginatedList
