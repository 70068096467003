import React from 'react'

import CardHeader from '@mui/material/CardHeader'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { SvgIconProps } from '@mui/material/SvgIcon'

import styled from '@emotion/styled'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { target, error, success } from 'config/themeConfig'

type StyledProps = {
  expanded?: boolean
}

const StyledCardHeader = styled(CardHeader)(() => ({
  marginTop: 0,
  marginRight: 0,
}))

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})<StyledProps>((props) => ({
  transform: props.expanded ? 'rotate(180deg)' : 'rotate(0deg)',
  marginLeft: !props.expanded ? 'auto' : '',
  transition: !props.expanded ? 'transform .15s' : '',
}))

const StyledHeading = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 600,
  marginBottom: '4px',
}) as typeof Typography

function getIconStyle(iconColor?: string): {
  width: string
  height: string
  color: string
} {
  let color = ''
  switch (iconColor) {
    case 'target':
      color = target.main
      break
    case 'success':
      color = success.main
      break
    case 'error':
      color = error.main
      break
    default:
      color = '#212121'
  }
  return {
    width: '1.25em',
    height: '1.25em',
    color: color,
  }
}

interface Props {
  collapsible?: boolean
  description?: string
  expanded?: boolean
  icon?: React.ComponentType<SvgIconProps>
  iconColor?: 'target' | 'success' | 'error'
  onRequestExpand: () => void
  title: string
  sidecar?: React.ReactNode
  headerActions?: React.ReactNode
}

export const DisplayCardHeader = ({
  collapsible = false,
  expanded = true,
  description,
  icon: Icon,
  iconColor,
  onRequestExpand,
  title,
  sidecar,
  headerActions,
}: Props) => {
  let actions: React.ReactNode
  if (collapsible) {
    actions = (
      <StyledIconButton
        expanded={expanded}
        onClick={onRequestExpand}
        aria-expanded={expanded}
        aria-label={`Expand ${title}`}
        data-testid="expand-icon"
        data-expanded={expanded}
        size="large"
      >
        <ExpandMoreIcon />
      </StyledIconButton>
    )
  } else if (headerActions) {
    actions = headerActions
  }

  return (
    <StyledCardHeader
      data-testid="display-card-header"
      avatar={Icon && <Icon sx={getIconStyle(iconColor)} fontSize="large" />}
      title={
        <StyledHeading variant="h6" component="h2">
          {title}
          {sidecar}
        </StyledHeading>
      }
      subheader={description}
      action={actions}
    />
  )
}

export default DisplayCardHeader
