import axios from 'axios'

import apiConfig from 'config/apiConfig'

import { ConfidenceScore } from 'types/Confidence'

import { getBrandAttributes } from './brands'
import { getNodesById } from 'services/itemTaxonomies'
import { getPageable, PagingParams } from './pageableHelper'

export type ConfidenceSearchParams = {
  q: string
  attribute_id?: string
}

interface ConfidenceResponse<T> {
  total_count: number
  results: T[]
  q: string
  attribute_id?: string
}

export async function getConfidenceForBrands(
  pagingParams: PagingParams,
  searchParams: ConfidenceSearchParams,
): Promise<ConfidenceResponse<ConfidenceScore>> {
  const pageable = getPageable(pagingParams)

  const config = {
    params: {
      ...pageable,
      ...searchParams,
    },
  }

  const response = await axios.get(
    `${apiConfig.itemAttributesSearches}/brands_search`,
    config,
  )

  return response.data
}

export async function getConfidenceForBrandsWithAttributes(
  pagingParams: PagingParams,
  searchParams: ConfidenceSearchParams,
): Promise<ConfidenceResponse<ConfidenceScore>> {
  const response = await getConfidenceForBrands(pagingParams, searchParams)
  const results = response.results

  const ids = results.map((result: ConfidenceScore) => result.id)
  const attributes = await getBrandAttributes(ids)

  results.forEach((result: ConfidenceScore) => {
    const target = attributes.find((attribute) => attribute.id === result.id)
    result.description = target ? target.description : ''
  })

  return {
    ...response,
    results,
  }
}

export async function getConfidenceForItemTypes(
  pagingParams: PagingParams,
  searchParams: ConfidenceSearchParams,
): Promise<ConfidenceResponse<ConfidenceScore>> {
  const pageable = getPageable(pagingParams)

  const config = {
    params: {
      ...pageable,
      ...searchParams,
    },
  }

  const response = await axios.get(
    `${apiConfig.itemAttributesSearches}/taxonomy_nodes_search`,
    config,
  )

  return response.data
}

export async function getConfidenceForItemTypesWithAttributes(
  pagingParams: PagingParams,
  searchParams: ConfidenceSearchParams,
): Promise<ConfidenceResponse<ConfidenceScore>> {
  const response = await getConfidenceForItemTypes(pagingParams, searchParams)
  const results = response.results

  const ids = results.map((result: ConfidenceScore) => result.id)
  const attributes = await getNodesById(ids)

  results.forEach((result: ConfidenceScore) => {
    const target = attributes.find((attribute) => attribute.id === result.id)
    result.description = target ? target.description : ''
  })

  return {
    ...response,
    results,
  }
}

export async function getConfidenceForMerchTypeAttributes(
  pagingParams: PagingParams,
  searchParams: ConfidenceSearchParams,
): Promise<ConfidenceResponse<ConfidenceScore>> {
  const pageable = getPageable(pagingParams)

  const config = {
    params: {
      ...pageable,
      ...searchParams,
    },
  }

  const response = await axios.get(
    `${apiConfig.itemAttributesSearches}/merch_type_attributes_search`,
    config,
  )

  return response.data
}
