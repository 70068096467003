import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'

import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import DisplayCard from 'components/common/DisplayCard'
import Link from 'components/common/Link'

import { RoutePath } from 'services/NavigationHelper'

import { registerWithPaymentService } from 'store/paymentAccount/actionCreators'

import AccessStripeLink from './AccessStripeLink'
import PaymentsWarningMessage from './PaymentsWarningMessage'

const StyledStripeButton = styled(Grid)(({ theme }) => ({
  marginRight: theme.spacing(3),
  marginTop: theme.spacing(3),
}))

interface Props {
  isExternalAdmin: boolean
  sellerId: string | undefined
  hasStripeEnabled: boolean
  isRegistrationPending: boolean
  stripeBalance: string | undefined
}

const StripeAccountContent = ({
  sellerId,
  hasStripeEnabled,
  isRegistrationPending,
  isExternalAdmin,
  stripeBalance,
}: Props) => {
  const dispatch = useDispatch()
  const currentBalance = stripeBalance ? stripeBalance : 'N/A'

  const handleRegisterWithPaymentService = () => {
    if (sellerId && isExternalAdmin) {
      dispatch(registerWithPaymentService(window.location.pathname))
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <DisplayCard
          title={`Current Stripe Balance: ${currentBalance}`}
          data-testid="manage-payment-account-card"
        >
          {!hasStripeEnabled && <PaymentsWarningMessage />}
          <Grid container alignItems="center" alignContent="flex-start">
            <Grid item xs={12}>
              {hasStripeEnabled ? (
                <Typography data-testid="account-message">
                  Manage your company payments account in Stripe. In the event
                  that the account owner needs to be changed, a support case can
                  be opened using{' '}
                  <Link
                    to={`/${sellerId}${RoutePath.OPEN_CASES}`}
                    target="_blank"
                  >
                    Open Cases
                  </Link>{' '}
                  .
                </Typography>
              ) : (
                <Typography data-testid="no-account-message">
                  Manage your company payments account in Stripe. The individual
                  who sets up the Stripe account will be the account owner and
                  should have significant responsibility for managing the
                  company's finances.
                </Typography>
              )}
            </Grid>
            <StyledStripeButton item xs={3}>
              {hasStripeEnabled && sellerId && (
                <AccessStripeLink
                  disabled={!isExternalAdmin}
                  sellerId={sellerId}
                />
              )}
              {!hasStripeEnabled && (
                <Tooltip title="Opens an external website in a new tab">
                  <span>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      data-testid="setup-stripe-button"
                      aria-label="Set up Stripe - opens in a new tab"
                      disabled={!isExternalAdmin || isRegistrationPending}
                      onClick={handleRegisterWithPaymentService}
                    >
                      {isRegistrationPending ? (
                        <CircularProgress size={14} />
                      ) : (
                        'SET UP STRIPE'
                      )}
                    </Button>
                  </span>
                </Tooltip>
              )}
            </StyledStripeButton>
          </Grid>
        </DisplayCard>
      </Grid>
    </Grid>
  )
}

export default StripeAccountContent
