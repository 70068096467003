import React from 'react'

import isEmpty from 'lodash/fp/isEmpty'
import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'

import { validationHandler, ValidationSchema } from 'services/validation'
import { Validation } from 'types/Validation'

import getValidationSchema from './getValidationSchema'
import DatePickerInput from './DatePickerInput'

const StyledButton = styled(Button)({
  minWidth: 50,
  marginBottom: '15px',
})
export interface Props {
  actionHandler: (from: Date, to: Date, isValid: boolean) => void
  actionLabel?: string
  isPending: boolean
  from?: Date
  to?: Date
  fromId?: string
  toId?: string
  dayRange?: number
  minDate?: Date
  minDateError?: string
  maxDate?: Date
  maxDateError?: string
  disabledDatesBefore?: Date // Set date that `From` input cannot go past
  hideLabels?: boolean
  helperTexts?: {
    from: string
    to: string
  }
}

const DateRangePicker = ({
  actionHandler,
  actionLabel,
  isPending,
  from: defaultFrom,
  to: defaultTo,
  fromId = 'from',
  toId = 'to',
  dayRange,
  disabledDatesBefore,
  hideLabels,
  helperTexts,
  minDate,
  minDateError,
  maxDate,
  maxDateError,
}: Props) => {
  const [range, setRange] = React.useState<{ from?: Date; to?: Date }>({
    from: defaultFrom,
    to: defaultTo,
  })
  const [validation, setValidation] = React.useState<Validation>({})

  const validationSchema: ValidationSchema = getValidationSchema({
    fromId,
    toId,
    dayRange,
    minDate,
    minDateError,
    maxDate,
    maxDateError,
  })

  const handleActionClick = () => {
    if (actionHandler && isEmpty(validation)) {
      actionHandler(range.from!, range.to!, true)
    }
  }

  const handleChange = (property: string) => (value: Date) => {
    let newRange = range
    setRange((prev) => {
      return (newRange = {
        ...prev,
        [property]: value,
      })
    })
    const { validation: formValidation, isValid } = validationHandler(
      validationSchema,
      {
        [fromId]: newRange.from,
        [toId]: newRange.to,
      },
    )

    if (!actionLabel && actionHandler) {
      actionHandler(newRange.from!, newRange.to!, isValid)
    }

    setValidation(formValidation)
  }
  const selectedDays =
    range.from && range.to ? [{ from: range.from, to: range.to }] : undefined

  const disabled = isPending || !range.from || !range.to || !isEmpty(validation)

  return (
    <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
            <DatePickerInput
              value={range.from}
              placeholder="From"
              id={fromId}
              label={hideLabels ? '' : 'From'}
              validation={validation}
              disabled={
                disabledDatesBefore
                  ? { before: disabledDatesBefore }
                  : undefined
              }
              toMonth={range.to}
              onChange={handleChange('from')}
              helpertext={helperTexts?.from}
              selected={selectedDays}
            />
          </Grid>
          <Grid item>
            <DatePickerInput
              value={range.to}
              placeholder="To"
              id={toId}
              label={hideLabels ? '' : 'To'}
              validation={validation}
              disabled={range.from ? { before: range.from } : undefined}
              month={range.from}
              fromMonth={range.from}
              onChange={handleChange('to')}
              helpertext={helperTexts?.to}
              selected={selectedDays}
            />
          </Grid>
        </Grid>
      </Grid>
      {actionLabel && (
        <Grid item>
          <StyledButton
            data-testid="date-range-action-button"
            onClick={handleActionClick}
            variant="contained"
            color="primary"
            disabled={disabled}
          >
            {actionLabel}
          </StyledButton>
        </Grid>
      )}
    </Grid>
  )
}

export default DateRangePicker
