import { SyntheticEvent, useState, useEffect } from 'react'

import { getCases } from 'services/cases'

import { Case } from 'types/Case'

import usePrevious from 'hooks/usePrevious'
import Typeahead from '.'

interface Props {
  placeholder?: string
  sellerId?: string
  caseType?: string
  onChange: (value: Nullable<Case>, enteredValue: string) => void
  value?: Nullable<Case>
  onClear?: () => void
}

const CaseTypeahead = ({
  placeholder = 'Search Case Number',
  sellerId,
  caseType,
  onChange,
  value: valueFromProps,
  onClear,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedValue, setSelectedValue] =
    useState<Nullable<Case>>(valueFromProps)
  const [options, setOptions] = useState<Case[]>([])
  const [loading, setLoading] = useState(false)

  const prevValue = usePrevious(valueFromProps)

  useEffect(() => {
    if (prevValue !== valueFromProps) {
      setSelectedValue(valueFromProps)
    }

    if (!valueFromProps) {
      setOptions([])
    }
  }, [prevValue, valueFromProps])

  useEffect(() => {
    if (options.length && !inputValue) {
      setOptions([])
    }
  }, [inputValue, options])

  const handleInputChange = async (
    _event: SyntheticEvent,
    enteredValue: string,
    reason: string,
  ) => {
    if (
      onClear &&
      (reason === 'clear' ||
        (selectedValue && reason === 'input' && enteredValue === ''))
    ) {
      onClear()
      setOptions([])
      setInputValue('')
      setSelectedValue(null)
      return
    }

    setInputValue(enteredValue)

    const invalidInputValue =
      !enteredValue || enteredValue.length < 3 || reason === 'reset'
    if (invalidInputValue) {
      return
    }

    const params = {
      seller_id: sellerId,
      case_number: enteredValue.trim(),
      caseType,
      page: 0,
      perPage: 20,
    }

    setLoading(true)
    const { data } = await getCases(params)
    setOptions(data)
    setLoading(false)
  }

  const handleSelectedOptionChange = (_event: SyntheticEvent, value: Case) => {
    const searchParamValue = getOptionLabel(value as Case)
    setSelectedValue(value as Nullable<Case>)
    onChange(value as Nullable<Case>, searchParamValue)
  }

  const getOptionLabel = (returnedCase: Case) => returnedCase?.case_number

  return (
    <Typeahead
      aria-label="Case Filter"
      placeholder={placeholder}
      label={placeholder}
      getOptionLabel={getOptionLabel}
      options={options}
      loading={loading}
      onInputChange={handleInputChange}
      onChange={handleSelectedOptionChange}
      isOptionEqualToValue={(option: Case, value: Nullable<Case>) =>
        option.case_number === value?.case_number
      }
      inputValue={inputValue}
      value={selectedValue}
    />
  )
}

export default CaseTypeahead
