const TIMEZONES: Array<{ name: string; code: string; abbreviation: string }> = [
  {
    name: 'America/New_York',
    code: 'Eastern Standard Time - New York (UTC-05:00)',
    abbreviation: 'EST',
  },
  {
    name: 'America/Chicago',
    code: 'Central Standard Time - Minnesota (UTC-06:00)',
    abbreviation: 'CST',
  },
  {
    name: 'America/Denver',
    code: 'Mountain Standard Time - Arizona (UTC-07:00)',
    abbreviation: 'MST',
  },
  {
    name: 'America/Los_Angeles',
    code: 'Pacific Standard Time - California (UTC-08:00)',
    abbreviation: 'PST',
  },
  {
    name: 'America/Anchorage',
    code: 'Alaska Standard Time - Alaska (UTC-09:00)',
    abbreviation: 'AKST',
  },
  {
    name: 'Pacific/Honolulu',
    code: 'Hawaii-Aleutian Standard Time - Hawaii (UTC-10:00)',
    abbreviation: 'HAST',
  },
]

export default TIMEZONES
