import axios from 'axios'

import apiConfig, {
  isExternalUrl,
  EXTERNAL_PROD_HOSTNAME,
} from 'config/apiConfig'

import { KnowledgeArticle, KnowledgeCategory } from 'types/KnowledgeArticle'
import { CollectionResponse, SingleResponse } from 'types/Response'

const OLD_EXTERNAL_PROD_URL_PATTERN = 'https?://plus.target.com/portal'
const EXTERNAL_PROD_PATTERN = `https?://${EXTERNAL_PROD_HOSTNAME}`

export const getKnowledgeCategories = (): Promise<KnowledgeCategory[]> => {
  return axios
    .get(`${apiConfig.securityGateway}/knowledge_articles/data_categories`)
    .then(({ data }) => data.children)
}

export const getKnowledgeArticles = (
  term: string,
  page: number,
  perPage: number,
): Promise<CollectionResponse<KnowledgeArticle>> => {
  const config = {
    params: {
      q: term,
      page,
      per_page: perPage,
    },
  }
  return axios
    .get(`${apiConfig.securityGateway}/knowledge_articles/search`, config)
    .then(({ data }) => ({
      data: data.items,
      total: data.total_count,
    }))
}

export const formatArticleLinks = ({
  str,
  hostname,
  port,
  protocol,
  isExternalUrl,
}: {
  str: string
  hostname: string
  port: string
  protocol: string
  isExternalUrl: boolean
}): string => {
  let domain = `${protocol}//${hostname}${port ? `:${port}` : ''}`

  if (isExternalUrl) {
    domain = `${domain}`
  }

  const test = new RegExp(
    `(href=")${OLD_EXTERNAL_PROD_URL_PATTERN}|${EXTERNAL_PROD_PATTERN}([^"]*)`,
    `gim`,
  )

  const formattedStr = str.replace(test, `$1${domain}$2`)
  return formattedStr
}

export const getKnowledgeArticle = (id: string): Promise<KnowledgeArticle> => {
  return axios
    .get(`${apiConfig.securityGateway}/knowledge_articles/${id}`)
    .then((results: SingleResponse<KnowledgeArticle>) => {
      const article = results.data

      const formattedContents = article.contents.map((item) => {
        // format article links to use the current url domain
        const formattedContent = formatArticleLinks({
          str: item.content,
          hostname: window.location.hostname,
          port: window.location.port,
          protocol: window.location.protocol,
          isExternalUrl,
        })

        return {
          ...item,
          content: formattedContent,
        }
      })

      article.contents = formattedContents

      return article
    })
}

export const getKnowledgeArticlesByCategory = async ({
  category,
  page,
  perPage,
}: {
  category: string
  page: number
  perPage: number
}): Promise<CollectionResponse<KnowledgeArticle>> => {
  const config = {
    params: {
      data_categories: category,
      page,
      per_page: perPage,
    },
  }

  const response = await axios.get(
    `${apiConfig.securityGateway}/knowledge_articles`,
    config,
  )

  return {
    data: response.data.items,
    total: response.data.total_count,
  }
}
