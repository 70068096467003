import { SyntheticEvent, useState, useEffect } from 'react'

import usePrevious from 'hooks/usePrevious'

import { Direction, PagingParams } from 'services/pageableHelper'
import { searchProductReturns } from 'services/productReturns'

import { Return } from 'types/Orders'

import Typeahead from '.'

interface Props {
  onChange: (value: Nullable<Return>, enteredValue: string) => void
  isDisabled?: boolean
  clearOnSelect?: boolean
  value?: Nullable<Return>
  sellerId: string | undefined
  onClear?: () => void
}

const BillOfLadingTypeahead = ({
  onChange,
  isDisabled,
  clearOnSelect = false,
  value: valueFromProps,
  sellerId,
  onClear,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedValue, setSelectedValue] =
    useState<Nullable<Return>>(valueFromProps)
  const [options, setOptions] = useState<Return[]>([])
  const [loading, setLoading] = useState(false)

  const prevValue = usePrevious(valueFromProps)
  useEffect(() => {
    if (prevValue !== valueFromProps) {
      setSelectedValue(valueFromProps)
    }

    if (!valueFromProps) {
      setOptions([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevValue, valueFromProps])

  useEffect(() => {
    if (options.length && !inputValue) {
      setOptions([])
    }
  }, [inputValue, options])

  const handleInputChange = async (
    _event: SyntheticEvent,
    enteredValue: string,
    reason: string,
  ) => {
    if (
      onClear &&
      (reason === 'clear' ||
        (selectedValue && reason === 'input' && enteredValue === ''))
    ) {
      onClear()
      setOptions([])
      setInputValue('')
      setSelectedValue(null)
      return
    }

    setInputValue(enteredValue)

    const invalidInputValue =
      !enteredValue || enteredValue.length < 3 || reason === 'reset'
    if (invalidInputValue) {
      return
    }

    const paging: PagingParams = {
      page: 0,
      perPage: 1,
      direction: Direction.ASC,
      orderBy: 'return_date',
    }

    const { data } = await searchProductReturns(paging, {
      bill_of_lading: enteredValue,
      seller_id: sellerId,
    })

    setOptions(data)
    setLoading(false)
  }

  const handleSelectedOptionChange = (
    _event: SyntheticEvent,
    value: Return,
  ) => {
    if (!(value instanceof Array)) {
      const searchParamValue = getReturnOptionLabel(value as Return)

      setSelectedValue(clearOnSelect ? null : (value as Nullable<Return>))
      onChange(value as Nullable<Return>, searchParamValue)
    }
  }

  const getReturnOptionLabel = (returnItem: Return) => {
    const trackingInfo = returnItem?.tracking_data?.find((trackingItem) => {
      if (trackingItem.bill_of_lading) {
        return trackingItem.bill_of_lading.startsWith(inputValue)
      }

      return ''
    })
    return trackingInfo?.bill_of_lading ?? ''
  }

  return (
    <Typeahead
      aria-label="Bill of Lading Filter"
      label="BOL #"
      placeholder="BOL #"
      options={options}
      loading={loading}
      getOptionLabel={getReturnOptionLabel}
      onInputChange={handleInputChange}
      onChange={handleSelectedOptionChange}
      isOptionEqualToValue={(option: Return, value: Nullable<Return>) =>
        option.id === value?.id
      }
      disabled={!!isDisabled}
      inputValue={inputValue}
      value={selectedValue}
    />
  )
}

export default BillOfLadingTypeahead
