import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import flatMap from 'lodash/fp/flatMap'
import * as yup from 'yup'

import Grid from '@mui/material/Grid'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import Input from 'components/common/Input'
import Select from 'components/common/Select'

import { Direction } from 'services/pageableHelper'
import { getShippingMethods } from 'services/shippingMethod'
import { validationHandler } from 'services/validation'

import { Shipment } from 'types/Orders'
import { Validation } from 'types/Validation'
import { bulkUpdateFulfillments } from 'services/orders'

import { closeDialog } from 'store/dialog/actionCreator'

interface Props {
  isOpen: boolean
  shipment: Shipment
  orderShipments: Shipment[]
}

const validationSchema = yup.object().shape({
  shippingMethod: yup.string().required().label('Shipping Method'),
  trackingNumber: yup.string().required().label('Tracking Number'),
})

const editShipments = (
  orderShipments: Shipment[],
  selectedShipment: Shipment,
  trackingNumber: string,
  shippingMethod: string,
) => {
  return flatMap('fulfillments', orderShipments).map((fulfillment) => {
    if (fulfillment.tracking_number === selectedShipment.tracking_number) {
      return {
        ...fulfillment,
        tracking_number: trackingNumber,
        shipping_method: shippingMethod,
      }
    } else {
      return fulfillment
    }
  })
}

const EditShipment = ({ isOpen, shipment, orderShipments }: Props) => {
  const dispatch = useDispatch()
  const [updatedShipment, setUpdatedShipment] = useState<{
    shippingMethod: string
    trackingNumber: string
  }>({
    shippingMethod: shipment?.shipping_method ?? '',
    trackingNumber: shipment?.tracking_number ?? '',
  })
  const [validation, setValidation] = useState<Validation>({})
  const [shippingMethodOptions, setShippingMethodOptions] = useState<string[]>(
    [],
  )
  const [isPending, setIsPending] = useState<boolean>(true)

  useEffect(() => {
    setIsPending(true)
    getShippingMethods({
      direction: Direction.ASC,
      orderBy: 'shipping_method',
      page: 0,
      perPage: 100,
    }).then((response) => {
      setShippingMethodOptions(
        response.data.map((method) => method.shipping_method),
      )
      setIsPending(false)
    })
  }, [])

  const handleInputChange = (value: any, property: string) => {
    setUpdatedShipment((prev) => ({
      ...prev,
      [property]: value,
    }))
  }

  const handleInputTrim = (value: any, property: string) => {
    const trimmedValue = value.trim()
    setUpdatedShipment((prev) => ({
      ...prev,
      [property]: trimmedValue,
    }))
  }

  const handleSubmit = async () => {
    const { validation: formValidation, isValid } = validationHandler(
      validationSchema,
      updatedShipment,
    )

    setValidation(formValidation)

    if (isValid) {
      const updatedFulfillments = editShipments(
        orderShipments,
        shipment,
        updatedShipment.trackingNumber,
        updatedShipment.shippingMethod,
      )
      try {
        await bulkUpdateFulfillments(
          shipment.seller_id,
          shipment.order_id,
          updatedFulfillments,
        )
      } catch (e) {
        console.error(e)
      }
      dispatch(closeDialog())
    }
  }

  return (
    <DialogContainer
      title="Edit Shipment"
      isOpen={isOpen}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Select
            id="shipping-method"
            name="shippingMethod"
            label="Shipping Method"
            isDisabled={isPending}
            value={
              shippingMethodOptions.length > 0
                ? updatedShipment.shippingMethod
                : ''
            }
            options={shippingMethodOptions}
            valueName="shipping_method"
            onChange={handleInputChange}
            validation={validation}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            isRequired
            id="tracking-number"
            name="trackingNumber"
            label="Tracking Number"
            value={updatedShipment.trackingNumber}
            isDisabled={isPending}
            onChange={handleInputChange}
            onBlur={handleInputTrim}
            validation={validation}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export default EditShipment
