export interface ReturnDispute {
  id: string
  tcin: string
  case_id: string
  case_number: string
  order_id: string
  quantity: number
  judgement: JudgementValues
  seller_id: string
  dispute_reason: string //enum?
  dispute_sub_reason?: string
  final_response: string | null
  final_response_description: string | null
  product_return_id: string
  chargeback_amount: number
  return_order_number: string
  seller_refund_amount: number
  service_fee_reversal: number
  service_fee: number
  created: string
  created_by: string
  last_modified: string
  last_modified_by: string
  responses?: string[]
  notes?: string
}

export type ReturnDisputeResolution = {
  judgement: JudgementValues
  notes?: string
  seller_refund_amount?: number
  service_fee_reversal?: number
  final_response?: string
}
export type ReturnDisputeUpdate = ReturnDisputeResolution & {
  final_response: string
}

export type ReturnDisputeSource = 'CASE' | 'RETURN_DISPUTE'

export interface ReturnDisputeSearchParams {
  case_id?: string
  judgement?: string[]
  return_order_number?: string
  order_id?: string
  page?: number
  per_page?: number
  sort?: string
  direction?: string
  orderBy?: string
  source: ReturnDisputeSource
}

export interface ReturnDisputeCasesSearchParams {
  seller_id?: string
  final_response?: string
  tcin?: string
  judgement?: string
  case_id?: string
  order_id?: string
  dispute_reason?: string
  return_order_number?: string
  source: 'CASE' | 'RETURN_DISPUTE'
  last_modified?: string
  created?: string
}

export enum JudgementValues {
  INVALID_CLAIM = 'INVALID_CLAIM',
  VALID_CLAIM = 'VALID_CLAIM',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  WAITING_ON_PARTNER = 'WAITING_ON_PARTNER',
}

export const judgements = [
  {
    name: 'Invalid Claim',
    value: JudgementValues.INVALID_CLAIM,
  },
  {
    name: 'Valid Claim',
    value: JudgementValues.VALID_CLAIM,
  },
  {
    name: 'Needs Review',
    value: JudgementValues.NEEDS_REVIEW,
  },
]

export const disputeReasons = [
  {
    name: 'Return Not Received',
    value: 'RETURN_NOT_RECEIVED',
  },
  {
    name: 'Unsellable',
    value: 'UNSELLABLE',
  },
  {
    name: 'Value Reduced',
    value: 'VALUE_REDUCED',
  },
  {
    name: 'Wrong Item',
    value: 'WRONG_ITEM',
  },
  {
    name: 'Appeal',
    value: 'APPEAL',
  },
]

export enum ReturnDisputeDaysPending {
  ZERO_TO_FIVE = 'ZERO_TO_FIVE',
  ZERO_TO_SEVEN = 'ZERO_TO_SEVEN',
  OVER_FIVE = 'OVER_FIVE',
  OVER_SEVEN = 'OVER_SEVEN',
}
