import axios from 'axios'

import apiConfig from 'config/apiConfig'

import formatCurrency from 'services/formatCurrency'

import {
  NewShippingRates,
  NewShippingRatesTableRow,
  ShippingRate,
  ShippingRateParams,
  ShippingRatesTable,
  ShippingRatesTableRow,
} from 'types/ShippingRates'

import { getPageable, PagingParams } from './pageableHelper'

export const getShippingRates = (
  rateParams: ShippingRateParams,
  pagingParams: PagingParams,
): Promise<ShippingRatesTable[]> => {
  const config = { params: { ...getPageable(pagingParams), ...rateParams } }
  return axios
    .get(`${apiConfig.sms}/shipping_rates`, config)
    .then((res) => res.data)
}

export const getShippingRateById = (
  id: string,
): Promise<ShippingRatesTable> => {
  return axios.get(`${apiConfig.sms}/shipping_rates/${id}`).then((res) => {
    return res.data
  })
}

export const getFutureShippingRates = async ({
  id,
  rateParams,
  pagingParams,
}: {
  id: string | undefined
  rateParams: ShippingRateParams
  pagingParams: PagingParams
}): Promise<ShippingRatesTable | undefined> => {
  let shippingRatesTableId = id

  if (!shippingRatesTableId) {
    const shippingRates = await getShippingRates(rateParams, pagingParams)
    if (shippingRates.length === 0) {
      return
    } else {
      shippingRatesTableId = shippingRates[0].id
    }
  }

  const futureShippingRate = await getShippingRateById(shippingRatesTableId)

  return futureShippingRate
}

export const updateShippingRates = async (
  id: string | undefined,
  futureShippingRates: NewShippingRates,
): Promise<ShippingRatesTable> => {
  const response = id
    ? await axios.put(
        `${apiConfig.sms}/shipping_rates/${id}`,
        futureShippingRates,
      )
    : await axios.post(`${apiConfig.sms}/shipping_rates`, futureShippingRates)

  return response.data
}

export const formatShippingRatesForTable = (
  shippingRatesTable: ShippingRatesTable,
): ShippingRatesTableRow[] => {
  let ratesTable: ShippingRatesTableRow[] = []
  const shippingRatesTableData = shippingRatesTable
  if (shippingRatesTableData && shippingRatesTableData.rates) {
    ratesTable = shippingRatesTableData.rates.map((rate, idx) => {
      return {
        min_pounds: rate.min_pounds,
        maxPounds:
          idx === shippingRatesTableData.rates.length - 1
            ? undefined
            : shippingRatesTableData.rates[idx + 1].min_pounds,
        rateDescription:
          idx === shippingRatesTableData.rates.length - 1
            ? `>= ${rate.min_pounds} lbs`
            : `${rate.min_pounds} to < ${
                shippingRatesTableData.rates[idx + 1].min_pounds
              } lbs`,
        currentRateInPennies: rate.amount_in_pennies,
        start_date: shippingRatesTableData.start_date,
        last_modified: shippingRatesTableData.last_modified,
        last_modified_by: shippingRatesTableData.last_modified_by,
      }
    })
  }
  return ratesTable
}

export const getRateInPennies = (
  index: number,
  rates: ShippingRatesTableRow[],
): number | undefined => {
  let currentRate: number | undefined = undefined
  if (rates.length >= 0) {
    if (rates[index] && rates[index].currentRateInPennies) {
      currentRate = rates[index].currentRateInPennies!
    }
  }
  return currentRate
}

export const getNewFutureRatesForTable = (
  minWeight: number = 0,
  maxWeight: number = 150,
  startDate: string,
  currentRates: ShippingRatesTableRow[],
  existingFutureRates?: ShippingRatesTableRow[],
): NewShippingRatesTableRow[] => {
  let futureRatesTable: NewShippingRatesTableRow[] = []
  let weight = minWeight
  while (weight < maxWeight) {
    let futureRate = existingFutureRates
      ? getRateInPennies(weight, existingFutureRates)
      : 0
    futureRatesTable.push({
      min_pounds: weight,
      maxPounds: weight + 1,
      start_date:
        existingFutureRates && existingFutureRates.length > 0
          ? existingFutureRates[0].start_date
          : startDate,
      rateDescription: `${weight} to < ${weight + 1} lbs`,
      currentRateInPennies: getRateInPennies(weight, currentRates),
      futureRateText: futureRate ? formatCurrency(futureRate / 100) : '',
      futureRateInPennies: futureRate,
    })
    weight += 1
  }
  let futureRate = existingFutureRates
    ? getRateInPennies(maxWeight, existingFutureRates)
    : 0
  futureRatesTable.push({
    min_pounds: maxWeight,
    start_date:
      existingFutureRates && existingFutureRates.length > 0
        ? existingFutureRates[0].start_date
        : startDate,
    rateDescription: `>= ${maxWeight} lbs`,
    currentRateInPennies: getRateInPennies(weight, currentRates),
    futureRateText: futureRate ? formatCurrency(futureRate / 100) : '',
    futureRateInPennies: futureRate,
  })

  return futureRatesTable
}

export const prepareShippingRateDataForSave = (
  rates: ShippingRatesTableRow[],
): ShippingRate[] => {
  let futureShippingRates: ShippingRate[] = []
  for (let i = 0; i <= rates.length - 1; i++) {
    if (
      rates[i].futureRateInPennies !== undefined &&
      rates[i].futureRateInPennies! >= 0
    ) {
      futureShippingRates.push({
        min_pounds: rates[i].min_pounds,
        amount_in_pennies: rates[i].futureRateInPennies!,
      })
    }
  }
  return futureShippingRates
}
