import { useState } from 'react'
import { useDispatch } from 'react-redux'

import Grid from '@mui/material/Grid'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import DateRangeSelector from 'components/common/DateRangePicker/DateRangeSelector'
import {
  DateRange,
  generateReturnDisputeFinancialsRangeConfig,
  getRangeConfig,
} from 'components/common/DateRangePicker/rangeConfig'

import {
  DATE_FORMAT_MONTH_DAY,
  endOfYesterday,
  formatDate,
  startOfDay,
} from 'services/dateService'
import { GenerateReportRequest } from 'services/reports'

import { closeDialog } from 'store/dialog/actionCreator'

import { ReportType } from 'types/Report'

export type Props = {
  isOpen: boolean
  reportType: ReportType
  onRequestSubmit: (report: Partial<GenerateReportRequest>) => void
}

export const ApprovedReturnDisputeFinancialsDialog = ({
  isOpen,
  reportType,
  onRequestSubmit,
}: Props) => {
  const reduxDispatch = useDispatch()

  const maxDate = endOfYesterday(new Date())

  const dateRangeOptions = generateReturnDisputeFinancialsRangeConfig()
  const last7Days = getRangeConfig(
    DateRange.LAST_7_DAYS,
    undefined,
    'returnDisputeFinancials',
  )
  const defaultRange = {
    from: startOfDay(last7Days.from),
    to: startOfDay(last7Days.to),
  }

  const [pending, setPending] = useState(false)
  const [dates, setDates] = useState(defaultRange)

  const [isDatesValid, setIsDatesValid] = useState(true)

  const handleInvalidSelection = () => {
    setIsDatesValid(false)
  }

  const handleDateChange = (from: Date, to: Date, isValid: boolean) => {
    if (isValid) {
      setIsDatesValid(true)
      setDates({
        from,
        to,
      })
    }
  }

  const handleSubmit = async () => {
    setPending(true)
    try {
      onRequestSubmit({
        type: reportType,
        startDate: dates.from,
        endDate: dates.to,
        parameters: {
          source: 'RETURN_DISPUTE',
        },
        reportName: `${formatDate(
          dates.from,
          DATE_FORMAT_MONTH_DAY,
        )} - ${formatDate(dates.to, DATE_FORMAT_MONTH_DAY)}`,
      })
    } catch (e) {
      console.error(
        `Error generating Approved Return Dispute Financials Report: ${e}`,
      )
    } finally {
      setPending(false)
      reduxDispatch(closeDialog())
    }
  }

  const isDisabled = pending || !dates.from || !dates.to || !isDatesValid

  return (
    <DialogContainer
      title="Generate Approved Return Dispute Financials Report"
      subtitle="Disputes approved today will not be in this report to prevent duplicates."
      isOpen={isOpen}
      isSubmitDisabled={isDisabled}
      onSubmit={handleSubmit}
      submitButtonText="Generate"
      disableScroll
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DateRangeSelector
            description="Filter Report by Time Period"
            type="returnDisputeFinancials"
            descriptor="date-range"
            defaultRange={DateRange.LAST_7_DAYS}
            defaultFrom={dates.from}
            defaultTo={maxDate}
            maxDate={maxDate}
            maxDateError="Must be yesterday or earlier"
            onDateChange={handleDateChange}
            dateRangeOptions={dateRangeOptions}
            dayRange={30}
            onInvalidSelection={handleInvalidSelection}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export default ApprovedReturnDisputeFinancialsDialog
