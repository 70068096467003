import { useState, useMemo, useEffect } from 'react'
import { useAuth } from '@praxis/component-auth'
import { Button } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { BrandIcon } from '@enterprise-ui/icons'

import apiConfig, { ENVIRONMENT_PRODUCTION } from 'config/apiConfig'
import { setPageData, trackPageView } from 'services/fireflyInsights'
import { FireflyEvent } from 'types/FireflyInsights'
import Chatbot from 'components/Chatbot'

export const ChatBotWidget = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { session, isAuthenticated } = useAuth()
  const { accessToken, userInfo } = session ?? {}

  const cookieExpirationTime = useMemo(() => {
    return new Date(userInfo?.expires * 1000).toUTCString()
  }, [userInfo?.expires])

  useEffect(() => {
    if (isAuthenticated()) {
      const cookieName =
        apiConfig.environment === ENVIRONMENT_PRODUCTION
          ? 'chatAccessToken'
          : 'stage-chatAccessToken'
      document.cookie = `${cookieName}=${accessToken}; Domain=target.com; Path=/; SameSite=None; Secure; Expires=${cookieExpirationTime}`
    }
  }, [isAuthenticated, cookieExpirationTime, accessToken])

  const onQuickHelpClick = () => {
    setIsOpen(true)
    setPageData('quick_chat')
    trackPageView(FireflyEvent.QUICK_CHAT_BUTTON)
  }

  return (
    <>
      {isOpen ? (
        <Chatbot setIsOpen={setIsOpen} />
      ) : (
        <div className="button-container">
          <Button
            className="border-radius-expanded hc-fs-md --canvas-elevation--4"
            onClick={onQuickHelpClick}
          >
            <EnterpriseIcon icon={BrandIcon} />
            <strong>
              + <span className="hc-ml-min">Quick Help</span>
            </strong>
          </Button>
        </div>
      )}
    </>
  )
}

export default ChatBotWidget
