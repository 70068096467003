// From: https://overreacted.io/making-setinterval-declarative-with-react-hooks/

import React from 'react'

type Callback = () => void

function useInterval(callback: Callback, delay: number | null) {
  const savedCallback = React.useRef<Callback>()

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      savedCallback.current!()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export default useInterval
