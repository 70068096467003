import { useQuery } from '@tanstack/react-query'

import { Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import { formatDateMDYT } from 'components/common/EnhancedTable/formatters'
import HeaderTitle from 'components/common/HeaderTitle'

import { memoizeGetSellersByVendorId } from 'services/seller'
import { getReturnsResearch } from 'services/sellerReturns'
import { submitReport } from 'services/reports'

import { ReturnsResearch } from 'types/Orders'
import { ReportType } from 'types/Report'

import FilterBar from 'components/common/FilterBar'
import {
  TableState,
  getEnhancedTablePageableProps,
  useSearchParams,
} from 'components/common/FilterBar/useSearchParams'
import TypeaheadFilter from 'components/common/FilterBar/TypeaheadFilter'
import { formatDateRange } from 'services/dateService'
import DateRangeFilter from 'components/common/FilterBar/DateRangeFilter'
import SelectFilter from 'components/common/FilterBar/SelectFilter'
import { DialogEnum } from 'components/common/Dialog'

import { getReturnCenters } from 'store/selectors'
import { openDialog } from 'store/dialog/actionCreator'

type SearchParams = TableState & {
  vendor_id: number | undefined
  license_plate: string | undefined
  store_reference_field: string | undefined
  shipment_id: string | undefined
  shipToPartnerStartDate: string | undefined
  shipToPartnerEndDate: string | undefined
  tcin: string | undefined
  pro_number: string | undefined
  receiving_location_id: number | undefined
}

const initialSearchParams: SearchParams = {
  perPage: 100,
  page: 0,
  orderBy: undefined,
  direction: undefined,
  vendor_id: undefined,
  license_plate: undefined,
  store_reference_field: undefined,
  shipment_id: undefined,
  shipToPartnerStartDate: undefined,
  shipToPartnerEndDate: undefined,
  tcin: undefined,
  pro_number: undefined,
  receiving_location_id: undefined,
}

const buildSearchParams = ({
  vendor_id,
  license_plate,
  store_reference_field,
  shipment_id,
  shipToPartnerStartDate,
  shipToPartnerEndDate,
  tcin,
  pro_number,
  receiving_location_id,
}: {
  vendor_id: number | undefined
  license_plate: string | undefined
  store_reference_field: string | undefined
  shipment_id: string | undefined
  shipToPartnerStartDate: string | undefined
  shipToPartnerEndDate: string | undefined
  tcin: string | undefined
  pro_number: string | undefined
  receiving_location_id: number | undefined
}) => {
  const shipToPartnerDateRange = formatDateRange(
    shipToPartnerStartDate,
    shipToPartnerEndDate,
  )
  const shipToPartnerDate = shipToPartnerDateRange || undefined

  return {
    vendor_id,
    license_plate,
    store_reference_field,
    shipment_id,
    event_date: shipToPartnerDate,
    tcin,
    pro_number,
    receiving_location_id,
  }
}

const fieldList: EnhancedTableFieldType<ReturnsResearch>[] = [
  {
    key: 'store_reference_field',
    heading: 'Unit LP',
  },
  {
    key: 'license_plate',
    heading: 'License Plate',
  },
  {
    key: 'tcin',
    heading: 'TCIN',
  },
  {
    key: 'quantity',
    heading: 'Qty',
  },
  {
    key: 'shipment_id',
    heading: 'Shipment ID',
  },
  {
    key: 'pro_number',
    heading: 'Tracking/Pro#',
  },
  {
    key: 'event_timestamp',
    heading: 'Ship to Partner Date',
    formatCell: formatDateMDYT('event_timestamp'),
  },
  {
    key: 'message_type',
    heading: 'Message Type',
  },
  {
    key: 'physical_disposition',
    heading: 'Physical Disposition',
  },
  {
    key: 'vendor_id',
    heading: 'Partner',
    formatCellAsync: async (rowItem) => {
      if (rowItem.vendor_id) {
        const data = await memoizeGetSellersByVendorId(
          rowItem.vendor_id.toString(),
        )
        if (data && data.display_name) return data.display_name
      }
    },
  },
]

const ReturnsResearchPage = () => {
  const returnCenters = useSelector(getReturnCenters)

  const [searchParams, searchParamActions, appliedFilterCount] =
    useSearchParams<SearchParams>(initialSearchParams)

  const reduxDispatch = useDispatch()

  const handleFilterClear = () => {
    searchParamActions.updateSearchParam(initialSearchParams)
  }

  const params = buildSearchParams({
    vendor_id: searchParams.vendor_id,
    license_plate: searchParams.license_plate,
    store_reference_field: searchParams.store_reference_field,
    shipment_id: searchParams.shipment_id,
    shipToPartnerStartDate: searchParams.shipToPartnerStartDate,
    shipToPartnerEndDate: searchParams.shipToPartnerEndDate,
    tcin: searchParams.tcin,
    pro_number: searchParams.pro_number,
    receiving_location_id: searchParams.receiving_location_id,
  })

  const { data: returnsResearchData, isLoading } = useQuery(
    ['RETURNS_RESEARCH', searchParams],
    () => {
      return getReturnsResearch(
        {
          direction: searchParams.direction,
          orderBy: searchParams.orderBy,
          page: searchParams.page,
          perPage: searchParams.perPage,
        },
        { ...params },
      )
    },
  )

  const handleCreateReport = () => () => {
    const params = buildSearchParams(searchParams)
    return submitReport({
      type: ReportType.RETURNS_RESEARCH,
      parameters: params,
    })
  }

  const handleDownload = () => {
    const params = {
      dialogEnum: DialogEnum.REPORT_DOWNLOAD_DIALOG,
      componentProps: {
        title: 'GENERATING REPORT...PLEASE WAIT',
        reportTitle: 'Returns Research',
        createReport: handleCreateReport(),
      },
    }
    reduxDispatch(openDialog(params))
  }

  return (
    <div data-testid="returns-research-page">
      <HeaderTitle title="Returns Research" />
      <em>
        <p className="hc-fs-sm">
          Returns on this page could not be matched to a Guest Return Order #
          (unmatched return), as a result, these returns have NOT been charged
          back to the Partner.
        </p>
        <p className="hc-fs-sm hc-pb-expanded">
          <strong>NOTE: </strong>
          This current list reflects a specific scenario of Returns not matched
          to a Return Order# because the Returns were received at the Return
          Center without a Store License Plate (LP#). The Return Center requires
          a LP# for all products received in the building. As a workaround to
          this requirement, the return center systematically generates LP#s that
          are like Store License Plates but are not generated by the stores. At
          this time, the Returns Research page only contains returns that fit
          the above scenario, in future iterations, other scenarios of unmatched
          returns will be added.
        </p>
      </em>

      <Grid item xs={12}>
        <FilterBar
          count={returnsResearchData?.total}
          onClear={handleFilterClear}
          appliedFilterCount={appliedFilterCount}
          onDownload={handleDownload}
        >
          <Grid
            container
            justifyContent="space-between"
            spacing={1}
            sx={{ mb: 3 }}
          >
            <Grid item xs={3}>
              <TypeaheadFilter
                label="Partner"
                value={searchParams.vendor_id?.toString()}
                searchParam="vendor_id"
                onChange={searchParamActions.updateSearchParam}
              />
            </Grid>
            <Grid item xs={3}>
              <TypeaheadFilter
                label="License Plate"
                value={searchParams.license_plate}
                searchParam="license_plate"
                onChange={searchParamActions.updateSearchParam}
                returnsResearch
              />
            </Grid>
            <Grid item xs={3}>
              <TypeaheadFilter
                label="Unit LP"
                value={searchParams.store_reference_field}
                searchParam="store_reference_field"
                onChange={searchParamActions.updateSearchParam}
              />
            </Grid>
            <Grid item xs={3}>
              <TypeaheadFilter
                label="Shipment ID"
                value={searchParams.shipment_id}
                searchParam="shipment_id"
                onChange={searchParamActions.updateSearchParam}
                returnsResearch
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between" spacing={1}>
            <Grid item xs={3}>
              <DateRangeFilter
                label="Ship To Partner Date"
                startValue={searchParams.shipToPartnerStartDate}
                startSearchParam="shipToPartnerStartDate"
                endValue={searchParams.shipToPartnerEndDate}
                endSearchParam="shipToPartnerEndDate"
                onChange={searchParamActions.updateSearchParam}
              />
            </Grid>
            <Grid item xs={3}>
              <TypeaheadFilter
                label="Item"
                value={searchParams.tcin}
                searchParam="tcin"
                onChange={searchParamActions.updateSearchParam}
              />
            </Grid>
            <Grid item xs={3}>
              <TypeaheadFilter
                label="Tracking or PRO #"
                value={searchParams.pro_number}
                searchParam="pro_number"
                onChange={searchParamActions.updateSearchParam}
              />
            </Grid>

            <Grid item xs={3}>
              <SelectFilter
                label="Returns Center Location"
                placeholder="Ex.: Indianapolis"
                value={searchParams.receiving_location_id?.toString()}
                searchParam="receiving_location_id"
                onChange={searchParamActions.updateSearchParam}
                data={returnCenters.map((returnCenter) => {
                  return {
                    name: returnCenter.properties.location,
                    value: returnCenter.properties.id.toString(),
                  }
                })}
              />
            </Grid>
          </Grid>
        </FilterBar>
      </Grid>
      <EnhancedTable
        data-testid="returns-research-table"
        data={returnsResearchData?.data ?? []}
        fieldList={fieldList}
        isLoading={isLoading}
        total={returnsResearchData?.total ?? 0}
        {...getEnhancedTablePageableProps(searchParams, searchParamActions)}
      />
    </div>
  )
}

export default ReturnsResearchPage
