import Typography from '@mui/material/Typography'

import ContentSpacer from 'components/common/ContentSpacer'
import DialogContainer from 'components/common/Dialog/DialogContainer'

export interface Props {
  isOpen: boolean
}

export const CaseCreated = (props: Props) => {
  const { isOpen } = props

  return (
    <DialogContainer isOpen={isOpen} title="Saved!" closeButtonText="Close">
      <Typography>
        It may take up to 10 minutes for your case to display in the Portal.
      </Typography>
      <ContentSpacer />
    </DialogContainer>
  )
}

export default CaseCreated
