import React from 'react'

import Grid from '@mui/material/Grid'

import styled from '@emotion/styled'

import Select from 'components/common/Select'

import { startOfDay } from 'services/dateService'

import DateRangePicker from '.'
import {
  getRangeConfig,
  DateRange,
  DateRangeDictionary,
  DateRangeConfigType,
} from './rangeConfig'
import { trackCustomEvent } from 'services/fireflyInsights'
import { FireflyEvent } from 'types/FireflyInsights'

const StyledSelect = styled(Select)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))

interface Props {
  onDateChange: (
    from: Date,
    to: Date,
    isValid: boolean,
    selectedIndex: number,
  ) => void
  descriptor: string
  defaultRange: DateRange
  defaultFrom?: Date
  defaultTo?: Date
  disableDatesBefore?: Date
  dateRangeOptions: DateRangeDictionary
  description?: string
  type?: DateRangeConfigType
  maxDate?: Date
  maxDateError?: string
  dayRange?: number
  onInvalidSelection?: () => void
}

export const DateRangeSelector = ({
  onDateChange,
  descriptor,
  defaultRange,
  defaultFrom,
  defaultTo,
  disableDatesBefore,
  dateRangeOptions,
  description = 'Display Range',
  type,
  maxDate,
  maxDateError,
  dayRange,
  onInvalidSelection,
}: Props) => {
  const rangeConfig = getRangeConfig(defaultRange, undefined, type)

  const rangeFrom = startOfDay(rangeConfig?.from)
  const rangeTo = startOfDay(rangeConfig?.to)

  const options = Object.keys(dateRangeOptions).map((range: string) => ({
    name:
      getRangeConfig(range as DateRange, undefined, type)?.label ??
      'Missing Name',
    value: range,
  }))

  const [from, setFrom] = React.useState<Date>(defaultFrom || rangeFrom)
  const [to, setTo] = React.useState<Date>(defaultTo || rangeTo)
  const [dateType, setDateType] = React.useState<string>(defaultRange)

  const handleDateChange = (
    newFrom: Date,
    newTo: Date,
    isValid: boolean,
    selectedIndex?: number,
  ) => {
    if (!isValid) {
      if (onInvalidSelection) {
        onInvalidSelection()
      }
      return
    }

    const utcNewFrom = startOfDay(newFrom)
    if (from !== utcNewFrom) setFrom(utcNewFrom)

    const utcNewTo = startOfDay(newTo)
    if (to !== utcNewTo) setTo(utcNewTo)

    const index = selectedIndex ?? options.length - 1 // return last option if none provided
    onDateChange(utcNewFrom, utcNewTo, true, index)
  }

  const handleDateTypeChange = (value: DateRange) => {
    setDateType(value)
    trackCustomEvent(FireflyEvent.DATE_RANGE_SELECTED, descriptor, value)

    if (DateRange.CUSTOM === value) {
      return
    }

    const newConfig = getRangeConfig(value, undefined, type)

    if (newConfig) {
      const index = options.findIndex((option) => option.value === value)

      handleDateChange(newConfig.from, newConfig.to, true, index)
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={dateType === DateRange.CUSTOM ? 4 : 6}>
        <StyledSelect
          id={`date-type-select-${descriptor}`}
          name="date-type-select"
          keyName="name"
          valueName="value"
          label={description}
          options={options}
          value={dateType}
          onChange={handleDateTypeChange}
        />
      </Grid>
      <Grid item xs={8}>
        {dateType === DateRange.CUSTOM && (
          <DateRangePicker
            disabledDatesBefore={disableDatesBefore}
            to={to}
            from={from}
            maxDate={maxDate}
            maxDateError={maxDateError}
            isPending={false}
            actionHandler={handleDateChange}
            helperTexts={{
              from: '(mm/dd/yyyy)',
              to: '(mm/dd/yyyy)',
            }}
            dayRange={dayRange}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default DateRangeSelector
