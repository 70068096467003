import startCase from 'lodash/fp/startCase'

import styled from '@emotion/styled'

import Grid from '@mui/material/Grid'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'

import { skuLink, tcinLink } from 'components/common/EnhancedTable/formatters'
import Image from 'components/common/Image'
import TabularData, { FieldList } from 'components/common/TabularData'
import Text from 'components/common/Text'
import StatusText from 'components/common/StatusText'

import { OrderItemData, OrderLineStatus } from 'types/Orders'

const StyledImageContainer = styled('div')(({ theme }) => ({
  maxWidth: theme.spacing(15),
}))

const StyledStatusContainer = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(3),
}))

const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingBottom: theme.spacing(3),
}))

interface OrderLineItem extends OrderItemData {
  unit_price?: number
  order_line_statuses?: OrderLineStatus[]
  cancellation_reason?: string
  is_registry_item?: boolean
}

interface Props {
  orderItem: OrderLineItem
}

export const OrderItem = ({ orderItem }: Props) => {
  const fieldList: FieldList<OrderLineItem>[] = [
    {
      key: 'external_id',
      displayName: 'Partner SKU',
      width: orderItem.unit_price ? 25 : 33,
      formatCell: skuLink(),
    },
    {
      key: 'tcin',
      displayName: 'TCIN',
      width: orderItem.unit_price ? 25 : 33,
      formatCell: tcinLink(),
    },
    ...(orderItem.unit_price
      ? [{ key: 'unit_price', displayName: 'Unit Price', width: 33 }]
      : []),
    {
      key: 'quantity',
      displayName: 'QTY',
      width: orderItem.unit_price ? 25 : 33,
    },
    {
      key: 'is_registry_item',
      displayName: 'Registry Item',
      width: orderItem.unit_price ? 25 : 33,
      formatCell: ({ is_registry_item }) => (is_registry_item ? 'Yes' : 'No'),
    },
  ]

  return (
    <StyledListItem data-testid="order" disableGutters>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={12} md={3} lg={2}>
          <StyledImageContainer>
            <Image
              alt={`image of ${orderItem.title}`}
              src={orderItem.primary_image}
            />
          </StyledImageContainer>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h3">{orderItem.title}</Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={8}>
              <TabularData hasHeader fieldList={fieldList} data={[orderItem]} />
            </Grid>
            {orderItem.order_line_statuses && (
              <StyledStatusContainer container>
                <Grid item xs={12} md={12} lg={8}>
                  <Text type="bold">Line Statuses</Text>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TabularData
                        hasHeader
                        fieldList={
                          [
                            {
                              key: 'quantity',
                              displayName: 'QTY',
                              width: 50,
                            },
                            {
                              key: 'status',
                              displayName: 'Status',
                              width: 50,
                              formatCell: ({ status }) => (
                                <StatusText label={status} />
                              ),
                            },
                          ] as FieldList<OrderLineStatus>[]
                        }
                        data={orderItem.order_line_statuses}
                      />
                    </Grid>
                    {orderItem.cancellation_reason && (
                      <Grid item xs={6}>
                        <TabularData
                          hasHeader
                          fieldList={
                            [
                              {
                                key: 'cancellation_reason',
                                displayName: 'Cancellation Reason',
                                width: 50,
                                formatCell: ({ cancellation_reason }) =>
                                  startCase(cancellation_reason!.toLowerCase()),
                              },
                            ] as FieldList<OrderLineItem>[]
                          }
                          data={[orderItem]}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </StyledStatusContainer>
            )}
          </Grid>
        </Grid>
      </Grid>
    </StyledListItem>
  )
}

export default OrderItem
