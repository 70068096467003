import styled from '@emotion/styled'

export const StyledCheckbox = styled.div`
  .C-Checkbox {
    color: #000;
    width: auto;
    font-size: 14px;
  }

  .C-Checkbox__Box {
    background: #fff;
  }

  .C-Checkbox:hover .C-Checkbox__Box {
    border: 1px solid var(--canvas-border-color--input);
  }

  .C-Tooltip__Content {
    background: #2b2d31;
    color: #fff;
  }
`
