import startCase from 'lodash/fp/startCase'

import Typography from '@mui/material/Typography'
import { ListingStatus } from 'types/Item'

interface Props {
  label: string
}

export const StatusText = (props: Props) => {
  const { label } = props

  const formattedLabel = startCase(label)
  const rejected = label === ListingStatus.REJECTED

  return (
    <Typography
      data-testid="status-text"
      color={rejected ? 'error' : 'inherit'}
      variant="inherit"
    >
      {formattedLabel}
    </Typography>
  )
}

export default StatusText
