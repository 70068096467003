import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import Grid from '@mui/material/Grid'

import Input from 'components/common/Input'
import { TaxonomyTypeahead } from 'components/common/Typeahead'

import Attribute, { ITEM_TYPE } from 'types/Attribute'
import { Validation } from 'types/Validation'

export interface Props {
  validation: Validation
  valid: boolean
  isEdit: boolean
  itemType: Nullable<Attribute>
  quotaValue: string
  onRequestCancel: () => void
  onItemTypeChange: (attribute: Nullable<Attribute>) => void
  onRequestQuotaChange: (value: string) => void
  onRequestSubmitNewQuota: () => void
}

export const AddGlobalQuotasAside = ({
  validation,
  valid,
  isEdit,
  itemType,
  quotaValue,
  onRequestCancel,
  onItemTypeChange,
  onRequestQuotaChange,
  onRequestSubmitNewQuota,
}: Props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TaxonomyTypeahead
          placeholder="Select an Item Type"
          searchType={ITEM_TYPE}
          onChange={onItemTypeChange}
          value={itemType}
          isDisabled={isEdit}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          id="editQuotaValue"
          data-testid="editQuotaValue"
          name="quota"
          type="number"
          value={quotaValue}
          validation={validation}
          onChange={onRequestQuotaChange}
        />
      </Grid>
      <Grid item xs={12}>
        <DialogActions>
          <Button
            color="primary"
            onClick={onRequestCancel}
            data-testid="cancel"
          >
            cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onRequestSubmitNewQuota}
            disabled={!itemType || !valid}
            data-testid="submit"
          >
            save
          </Button>
        </DialogActions>
      </Grid>
    </Grid>
  )
}

export default AddGlobalQuotasAside
