import uniqBy from 'lodash/fp/uniqBy'

import Attribute from 'types/Attribute'
import { Legalization } from 'types/Legalization'
import { Validation } from 'types/Validation'

export const openLimitAside = () =>
  ({
    type: 'OPEN_LIMIT_ASIDE',
  }) as const

export const openTypeAside = () =>
  ({
    type: 'OPEN_TYPE_ASIDE',
  }) as const

export const closeAside = () =>
  ({
    type: 'CLOSE_ASIDE',
  }) as const

export const setPending = (pending: boolean) =>
  ({
    type: 'SET_PENDING',
    payload: {
      pending,
    },
  }) as const

export const setValidation = ({
  validation,
  isValid,
}: {
  validation: Validation
  isValid: boolean
}) =>
  ({
    type: 'SET_VALIDATION',
    payload: {
      validation,
      isValid,
    },
  }) as const

export const updateOnInputChange = (value: string) =>
  ({
    type: 'UPDATE_ON_INPUT_CHANGE',
    payload: {
      value,
    },
  }) as const

export const setAutoApprovalEnabled = (value: boolean) =>
  ({
    type: 'AUTO_APPROVAL_ENABLED',
    payload: {
      value,
    },
  }) as const

export const setItemTypeHolds = (itemTypeHolds: Legalization[]) =>
  ({
    type: 'SET_ITEM_TYPE_HOLDS',
    payload: {
      itemTypeHolds,
    },
  }) as const

export const setItemLimit = (itemLimit: string) =>
  ({
    type: 'SET_ITEM_LIMIT',
    payload: {
      itemLimit,
    },
  }) as const

export const addNewItemTypeHold = (attribute: Attribute) =>
  ({
    type: 'ADD_NEW_ITEM_TYPE_HOLD',
    payload: {
      attribute,
    },
  }) as const

export const removeNewItemTypeHold = (key: string) =>
  ({
    type: 'REMOVE_NEW_ITEM_TYPE_HOLD',
    payload: {
      key,
    },
  }) as const

export interface ModifyLegalization extends Legalization {
  remove?: boolean
}

export type ApprovalSettingsActions = ReturnType<
  | typeof openLimitAside
  | typeof openTypeAside
  | typeof closeAside
  | typeof setPending
  | typeof setValidation
  | typeof updateOnInputChange
  | typeof setAutoApprovalEnabled
  | typeof setItemTypeHolds
  | typeof setItemLimit
  | typeof addNewItemTypeHold
  | typeof removeNewItemTypeHold
>

interface State {
  isAsideOpen: boolean
  pending: boolean
  totalHolds: number
  itemTypeHolds: ModifyLegalization[]
  itemLimit: string
  autoApprovalEnabled: boolean
  validation: Validation
  isValid: boolean
  asideTitle: string
  isItemLimitAside: boolean
  editItemLimit: string
  editItemTypeHolds: Legalization[]
  newItemTypeHolds: Attribute[]
}

export const initialReducerState: State = {
  isAsideOpen: false,
  pending: false,
  totalHolds: 0,
  itemTypeHolds: [],
  itemLimit: '',
  autoApprovalEnabled: false,
  validation: {},
  isValid: false,
  asideTitle: '',
  isItemLimitAside: false,
  editItemLimit: '',
  editItemTypeHolds: [],
  newItemTypeHolds: [],
}

export const reducer = (
  state: State,
  action: ApprovalSettingsActions,
): State => {
  switch (action.type) {
    case 'OPEN_LIMIT_ASIDE': {
      return {
        ...state,
        isAsideOpen: true,
        asideTitle: 'Edit Item Limit',
        editItemLimit: state.itemLimit,
        isItemLimitAside: true,
      }
    }

    case 'OPEN_TYPE_ASIDE': {
      return {
        ...state,
        isAsideOpen: true,
        asideTitle: 'Turn On Auto Approval',
        editItemTypeHolds: state.itemTypeHolds,
        isItemLimitAside: false,
        newItemTypeHolds: [],
      }
    }

    case 'CLOSE_ASIDE': {
      return {
        ...state,
        isAsideOpen: false,
      }
    }

    case 'SET_PENDING': {
      const { payload } = action
      const newState = {
        ...state,
        pending: payload.pending,
      }

      return newState
    }

    case 'SET_VALIDATION': {
      const { payload } = action
      const newState = {
        ...state,
        validation: payload.validation,
        isValid: payload.isValid,
      }

      return newState
    }

    case 'UPDATE_ON_INPUT_CHANGE': {
      const newState = {
        ...state,
        editItemLimit: action.payload.value ? action.payload.value : '',
      }

      return newState
    }

    case 'AUTO_APPROVAL_ENABLED': {
      const newState = {
        ...state,
        autoApprovalEnabled: action.payload.value,
        isAsideOpen: state.isAsideOpen && !action.payload.value,
      }

      return newState
    }

    case 'SET_ITEM_TYPE_HOLDS': {
      const { payload } = action
      const newState = {
        ...state,
        itemTypeHolds: payload.itemTypeHolds.map((hold) => ({
          ...hold,
          id: hold.primary_attribute.id,
        })),
      }
      return newState
    }

    case 'SET_ITEM_LIMIT': {
      const { payload } = action
      const newState = {
        ...state,
        itemLimit: payload.itemLimit,
      }
      return newState
    }

    case 'ADD_NEW_ITEM_TYPE_HOLD': {
      const { payload } = action
      const newState = {
        ...state,
        newItemTypeHolds: uniqBy('id', [
          ...state.newItemTypeHolds,
          payload.attribute,
        ]),
      }
      return newState
    }

    case 'REMOVE_NEW_ITEM_TYPE_HOLD': {
      const { payload } = action
      const newState = {
        ...state,
        newItemTypeHolds: state.newItemTypeHolds.filter(
          (hold) => hold.id !== payload.key,
        ),
      }
      return newState
    }

    default:
      return state
  }
}

export default reducer
