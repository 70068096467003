import { useState, useEffect } from 'react'

import { useDispatch } from 'react-redux'

import isEqual from 'lodash/fp/isEqual'

import Grid from '@mui/material/Grid'
import InfoIcon from '@mui/icons-material/Info'

import { DialogEnum } from 'components/common/Dialog'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import { formatDateMDYT } from 'components/common/EnhancedTable/formatters'
import FilterBar from 'components/common/FilterBar'
import DateRangeFilter from 'components/common/FilterBar/DateRangeFilter'
import SelectFilter from 'components/common/FilterBar/SelectFilter'
import TextFilter from 'components/common/FilterBar/TextFilter'
import {
  useSearchParams,
  TableState,
  getEnhancedTablePageableProps,
} from 'components/common/FilterBar/useSearchParams'
import HeaderTitle from 'components/common/HeaderTitle'
import TableSpacer from 'components/common/TableSpacer'

import { getMessages, getTopics } from 'services/beetle'
import { getIsoString } from 'services/dateService'
import { Direction } from 'services/pageableHelper'

import usePrevious from 'hooks/usePrevious'

import { openDialog } from 'store/dialog/actionCreator'

import { BeetleResult, BeetleMessageParams, BeetleTopic } from 'types/Beetle'

type SearchParams = TableState & {
  topicId: string | undefined
  startingDate: string | undefined
  endingDate: string | undefined
  searchField: string | undefined
  query: string | undefined
}

const initialSearchParams: SearchParams = {
  direction: Direction.DESC,
  orderBy: 'message_date',
  page: 0,
  perPage: 200,
  topicId: undefined,
  startingDate: undefined,
  endingDate: undefined,
  searchField: undefined,
  query: undefined,
}

const fieldList: EnhancedTableFieldType<BeetleResult>[] = [
  {
    key: 'key',
    heading: 'Key',
    hasSort: true,
  },
  {
    key: 'message_date',
    hasSort: true,
    formatCell: formatDateMDYT('message_date'),
  },
  {
    key: 'partition',
    hasSort: true,
  },
  {
    key: 'offset',
    hasSort: true,
  },
]

const buildSearchParams = ({
  direction,
  orderBy,
  startingDate,
  endingDate,
  searchField,
  query,
}: SearchParams) => {
  const params: BeetleMessageParams = {
    size: 200,
    starting_date: getIsoString(startingDate),
    ending_date: getIsoString(endingDate),
    sort: `${orderBy}(${direction})`,
  }

  if (searchField && query) {
    if (searchField === 'key' || searchField === 'starting_offset') {
      params[searchField] = query
    } else {
      params.q = `${searchField}:${query}`
    }
  }

  return params
}

export const KafkaTopicsSearchPage = () => {
  const reduxDispatch = useDispatch()

  const [searchParams, searchParamActions] =
    useSearchParams<SearchParams>(initialSearchParams)

  const [pending, setPending] = useState<boolean>(false)
  const [total, setTotal] = useState<number>()
  const [topics, setTopics] = useState<Nullable<any>>([])
  const [searchFields, setSearchFields] = useState<Nullable<any>>([])
  const [messages, setMessages] = useState<Nullable<any>>([])

  const onFilterClear = () => {
    searchParamActions.updateSearchParam(initialSearchParams)
  }

  useEffect(() => {
    let mounted = true
    setPending(true)

    getTopics({ size: 100 }).then((response) => {
      if (mounted) {
        setTopics(
          response.map((topic: BeetleTopic) => {
            const name = topic.description?.includes('TTCE')
              ? `${topic.name} - TTCE`
              : topic.description?.includes('TTC')
              ? `${topic.name} - TTC`
              : topic.name
            return { name, value: topic.id }
          }),
        )
        setPending(false)
      }
    })

    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const prevTopicId = usePrevious(searchParams.topicId)
  useEffect(() => {
    let mounted = true
    if (searchParams.topicId) {
      if (searchParams.query && !searchParams.searchField) {
        searchParamActions.updateSearchParam({
          ...searchParams,
          query: undefined,
        })
        return
      }

      if (
        isEqual(prevTopicId, searchParams.topicId) &&
        messages.length > 0 &&
        searchParams.searchField &&
        !searchParams.query
      ) {
        return
      }

      setPending(true)

      getMessages(searchParams.topicId, buildSearchParams(searchParams)).then(
        (response) => {
          if (mounted) {
            setMessages(response.results)
            setTotal(response.total)
            setPending(false)
          }
        },
      )
    } else if (
      !searchParams.topicId &&
      !isEqual(searchParams, initialSearchParams)
    ) {
      onFilterClear()
      setMessages([])
      setTotal(0)
    } else if (!searchParams.topicId) {
      setMessages([])
      setTotal(0)
    }

    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  useEffect(() => {
    let mounted = true

    if (messages[0]) {
      setPending(true)

      if (mounted) {
        const searchFields = [
          'key',
          'starting_offset',
          ...getAllObjectFieldNames(messages[0].message),
        ]

        if (searchFields) {
          setSearchFields(
            searchFields.map((searchField) => {
              return { name: searchField, value: searchField }
            }),
          )
        }
        setPending(false)
      }
    } else if (
      !searchParams.topicId &&
      !isEqual(searchParams, initialSearchParams)
    ) {
      onFilterClear()
      setMessages([])
      setTotal(0)
    }

    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.topicId, messages])

  const handleMessageDetails = (message: any) => () => {
    reduxDispatch(
      openDialog({
        dialogEnum: DialogEnum.MESSAGE_DETAILS_DIALOG,
        componentProps: {
          timelineMessage: message.message,
        },
      }),
    )
  }

  const getAllObjectFieldNames = (data?: any, parent?: string) => {
    let objectFieldDataList: string[] = []

    if (data) {
      for (var key in data) {
        if (typeof data[key] !== 'object' && !Array.isArray(data[key])) {
          objectFieldDataList.push(parent ? `${parent}.${key}` : key)
        } else if (typeof data[key] === 'object' && !Array.isArray(data[key])) {
          objectFieldDataList.push(
            ...getAllObjectFieldNames(
              data[key],
              parent ? `${parent}.${key}` : key,
            )!,
          )
        } else if (Array.isArray(data[key])) {
          if (data[key].length) {
            objectFieldDataList.push(
              ...getAllObjectFieldNames(
                data[key][0],
                parent ? `${parent}.${key}` : key,
              )!,
            )
          } else {
            objectFieldDataList.push(parent ? `${parent}.${key}` : key)
          }
        }
      }
    }

    return objectFieldDataList
  }

  return (
    <div data-testid="search-topics">
      <HeaderTitle title="Search Topics" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FilterBar count={total} onClear={onFilterClear}>
            <Grid item xs={4}>
              <SelectFilter
                label="Topic"
                placeholder="Topic"
                value={searchParams.topicId}
                searchParam="topicId"
                onChange={searchParamActions.updateSearchParam}
                data={topics}
              />
            </Grid>
            <Grid item xs={6}>
              <DateRangeFilter
                label="Message Date"
                startValue={searchParams.startingDate}
                startSearchParam="startingDate"
                endValue={searchParams.endingDate}
                endSearchParam="endingDate"
                onChange={searchParamActions.updateSearchParam}
                isDisabled={!searchParams.topicId}
              />
            </Grid>
            <>
              <Grid item xs={4}>
                <SelectFilter
                  label="Search Field"
                  placeholder="Search Field"
                  disabled={!searchParams.topicId}
                  value={searchParams.searchField}
                  searchParam="searchField"
                  onChange={searchParamActions.updateSearchParam}
                  data={searchFields}
                />
              </Grid>
              <Grid item xs={4}>
                <TextFilter
                  label="Search Query"
                  fullWidth
                  disabled={!searchParams.topicId}
                  value={searchParams.query}
                  searchParam="query"
                  onChange={searchParamActions.updateSearchParam}
                />
              </Grid>
            </>
          </FilterBar>
          <TableSpacer>
            <EnhancedTable
              data={messages}
              fieldList={fieldList}
              total={total}
              isLoading={pending}
              {...getEnhancedTablePageableProps(
                searchParams,
                searchParamActions,
              )}
              rowsPerPageOptions={[200]}
              actions={[
                {
                  callback: handleMessageDetails,
                  label: 'Message Details',
                  icon: <InfoIcon color="primary" />,
                },
              ]}
              hidePagination
            />
          </TableSpacer>
        </Grid>
      </Grid>
    </div>
  )
}

export default KafkaTopicsSearchPage
