import { UserInfo as PraxisUserInfo } from '@praxis/component-auth'

import { PhoneNumber, VmmMetadata } from './SellerUser'

export interface UserInfo extends PraxisUserInfo {
  id: string
  phone_numbers?: PhoneNumber[]
  vmm_metadata?: VmmMetadata[]
  userRole?: string
}

export enum ExternalUserLogin {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}
