import { call, put, select, takeEvery } from 'redux-saga/effects'

import {
  CONFIRM_PAYMENT_ACCOUNT,
  REGISTER_WITH_PAYMENT_SERVICE,
  confirmPaymentAccountDone,
  ConfirmPaymentAccountAction,
  registerWithPaymentServiceError,
  RegisterWithPaymentServiceAction,
} from './actionCreators'

import {
  openFullPageLoader,
  closeFullPageLoader,
} from 'store/layout/actionCreators'

import { currentSellerId } from 'store/selectors'

import { getRegistrationUrl, confirmAccount } from 'services/payments'
import { setItem } from 'services/storageHelper'

import {
  KEY_PAYMENT_REDIRECT_PATH,
  KEY_SELLER_ID,
} from 'components/StripeAccountSuccessPage'

export function* registerWithPaymentServiceSaga(
  action: RegisterWithPaymentServiceAction,
) {
  const sellerId: string = yield select(currentSellerId)

  try {
    setItem(KEY_SELLER_ID, sellerId)
    setItem(KEY_PAYMENT_REDIRECT_PATH, action.payload.redirectPath)

    const registrationUrl: string = yield call(getRegistrationUrl, sellerId)

    window.open(registrationUrl, '_blank')
  } catch (e) {
    yield put(registerWithPaymentServiceError())
  }
}

export function* confirmPaymentAccountSaga(
  action: ConfirmPaymentAccountAction,
) {
  const { payload } = action
  const { sellerId, registrationResult } = payload

  yield put(openFullPageLoader())

  try {
    yield call(confirmAccount, sellerId, registrationResult)
    yield put(confirmPaymentAccountDone(true))
  } catch (e) {
    console.error(`Error in confirmPaymentAccountSaga: ${e}`)
    yield put(confirmPaymentAccountDone(true))
  } finally {
    yield put(closeFullPageLoader())
  }
}

export function* paymentAccountSagas() {
  yield takeEvery(REGISTER_WITH_PAYMENT_SERVICE, registerWithPaymentServiceSaga)
  yield takeEvery(CONFIRM_PAYMENT_ACCOUNT, confirmPaymentAccountSaga)
}
