import React from 'react'

import { useSelector, useDispatch } from 'react-redux'
import * as yup from 'yup'

import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import Input from 'components/common/Input'

import { isReviewed } from 'services/reviewIndicators'
import { approvedToListStatuses } from 'services/seller'
import { validationHandler, ValidationSchema } from 'services/validation'

import { editReviewIndicator, editSeller } from 'store/seller/actionCreators'
import { currentSeller, isSellersPending } from 'store/selectors'

import { ReviewIndicators, SmsSeller } from 'types/Seller'
import { Validation } from 'types/Validation'

export const reviewIndicatorText = 'About Company has been reviewed'

interface Props {
  isOpen: boolean
  canReview: boolean
}

const validationSchema: ValidationSchema = yup.object().shape({
  description: yup.string().label('Description').required(),
})

export const EditAboutCompany = ({ isOpen, canReview }: Props) => {
  const seller = useSelector(currentSeller)
  const isPending = useSelector(isSellersPending)

  const dispatch = useDispatch()

  const [description, setDescription] = React.useState<string>(
    seller?.description || '',
  )
  const [reviewed, setReviewed] = React.useState<boolean>(
    isReviewed(seller?.review_indicators, ReviewIndicators.DESCRIPTION),
  )
  const [validation, setValidation] = React.useState<Validation>({})

  const handleInputChanged = (value: string) => {
    setDescription(value)
  }

  const handleReviewChanged = (event: any, checked: boolean) => {
    if (event) {
      setReviewed(checked)
    }
  }

  const handleInputBlur = (value: string) => {
    setDescription(value.trim())
  }

  const handleSubmit = () => {
    const { validation: newValidation, isValid } = validationHandler(
      validationSchema,
      { description },
    )

    setValidation(newValidation)

    if (!seller || !isValid) return

    const newSeller: SmsSeller = {
      ...seller,
      description,
    }

    // If we are approving, use editReviewIndicator instead of editSeller
    const oldStatus = isReviewed(
      seller?.review_indicators,
      ReviewIndicators.DESCRIPTION,
    )
    if (
      reviewed !== oldStatus &&
      !approvedToListStatuses.includes(seller?.status)
    ) {
      dispatch(
        editReviewIndicator(newSeller, ReviewIndicators.DESCRIPTION, reviewed),
      )
    } else {
      dispatch(editSeller(newSeller))
    }
  }

  return (
    <DialogContainer
      title="Edit About Partner"
      isOpen={isOpen}
      subtitle="A description about the partner that appears on Target.com."
      isPending={isPending}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input
            isRequired
            isMultiline
            id="seller-description"
            name="description"
            label="About Company"
            isDisabled={isPending}
            value={description}
            onChange={handleInputChanged}
            onBlur={handleInputBlur}
            validation={validation}
          />
          {canReview && (
            <FormControlLabel
              control={
                <Checkbox
                  id="about-seller-reviewed"
                  data-testid="about-seller-reviewed"
                  checked={reviewed}
                  onChange={handleReviewChanged}
                  inputProps={{
                    'aria-label': reviewIndicatorText,
                  }}
                />
              }
              label={reviewIndicatorText}
            />
          )}
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export default EditAboutCompany
