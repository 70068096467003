// ex: 1:30 PM output: 13:30
export const convert12To24Hour = (time: string): string => {
  const matched = time.match(/(?<hours>\d+):(?<minutes>\d+)\s(?<period>\w{2})/)

  let hours = matched?.groups?.hours

  if (hours) {
    if (hours === '12') {
      hours = '00'
    }

    if (matched?.groups?.period === 'PM') {
      hours = `${parseInt(hours, 10) + 12}`
    }

    if (
      matched?.groups?.period === 'AM' &&
      matched?.groups?.hours.length === 1
    ) {
      hours = `0${hours}`
    }
  }

  return `${hours}:${matched?.groups?.minutes}`
}

type Period = 'AM' | 'PM'
export const convert24To12Hour = (
  time: string,
): { time: string; period: Period } => {
  const matched = time.match(/(?<hours>\d+):(?<minutes>\d+)/)

  const hours = matched?.groups?.hours

  let numHours = 0
  let strHours = ''
  if (hours) {
    numHours = parseInt(hours, 10)

    if (numHours === 0) {
      strHours = '12'
    } else if (numHours > 12) {
      strHours = `${numHours - 12}`
    } else {
      strHours = `${numHours}`
    }
  }

  const period = numHours >= 12 ? 'PM' : 'AM'

  return {
    time: `${strHours}:${matched?.groups?.minutes}`,
    period,
  }
}
