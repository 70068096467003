import { useEffect, useRef, forwardRef } from 'react'

import MuiInput from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

import ValidationErrorText from '../ValidationErrorText/ValidationErrorText'
import DateTextMask from './DateTextMask'

import usePrevious from 'hooks/usePrevious'

import { Validation } from 'types/Validation'

interface Props {
  id: string
  label: string
  validation: Validation
  value: string
  isRequired?: boolean
  isDisabled?: boolean
  helpertext?: string
}

const InputForDatePicker = forwardRef((props: Props, _ref) => {
  const {
    id,
    label,
    validation,
    value,
    isRequired = false,
    isDisabled = false,
    helpertext = 'mm/dd/yyyy',
  } = props
  const inputRef = useRef<HTMLInputElement>(null)
  const prevValue = usePrevious<string>(value)

  useEffect(() => {
    if (prevValue && inputRef.current?.focus) {
      inputRef.current.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <FormControl
      style={{
        minWidth: 120,
        maxWidth: 175,
      }}
      required={isRequired}
    >
      {label && (
        <InputLabel shrink htmlFor={id}>
          {label}
        </InputLabel>
      )}
      <MuiInput
        inputRef={inputRef}
        data-testid={`date-input-${id}`}
        id={id}
        value={value}
        disabled={isDisabled}
        inputComponent={DateTextMask as any}
        inputProps={{
          'aria-label': label,
          autoComplete: 'off',
          ...props,
        }}
      />
      {!validation || !validation[id] ? (
        <FormHelperText data-testid="helper-text">{helpertext}</FormHelperText>
      ) : null}
      {validation && (
        <ValidationErrorText
          data-testid="validation-error-text"
          errors={validation}
          field={id}
        />
      )}
    </FormControl>
  )
})

export default InputForDatePicker
