import React from 'react'

import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

import ContentWithLineBreaks from 'components/common/ContentWithLineBreaks'

interface Props {
  text: string
  limit?: number
  showLineBreaks?: boolean
}

const TruncatedText = ({
  text,
  limit = 100,
  showLineBreaks = false,
}: Props) => {
  const isShort = text.length <= limit
  const [expanded, setIsExpanded] = React.useState(isShort)

  const shortText = React.useMemo(() => text.substring(0, limit), [limit, text])

  const displayText = expanded ? text : shortText

  let content = <Typography display="inline">{displayText}</Typography>
  if (expanded && showLineBreaks) {
    content = <ContentWithLineBreaks content={displayText} />
  }

  return (
    <div>
      {content}
      {!isShort && (
        <Link>
          <Typography
            display="inline"
            onClick={() => {
              setIsExpanded((prev: boolean) => !prev)
            }}
          >{`...read ${expanded ? 'less' : 'more'}`}</Typography>
        </Link>
      )}
    </div>
  )
}

export default TruncatedText
