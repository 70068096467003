import { KnowledgeArticle } from 'types/KnowledgeArticle'

export const updateSearchTerm = (
  searchTerm: string,
  page: number,
  perPage: number,
) =>
  ({
    type: 'UPDATE_SEARCH_TERM',
    payload: {
      searchTerm,
      page,
      perPage,
    },
  }) as const

export const updateCategory = (
  category: string,
  page: number,
  perPage: number,
) =>
  ({
    type: 'UPDATE_CATEGORY',
    payload: {
      category,
      page,
      perPage,
    },
  }) as const

export const changePage = (page: number) =>
  ({
    type: 'CHANGE_PAGE',
    payload: { page },
  }) as const

export const changePerPage = (perPage: number) =>
  ({
    type: 'CHANGE_PER_PAGE',
    payload: { perPage },
  }) as const

export const setPending = (pending: boolean) =>
  ({
    type: 'SET_PENDING',
    payload: {
      pending,
    },
  }) as const

export const setArticles = (articles: KnowledgeArticle[], total: number) =>
  ({
    type: 'SET_ARTICLES',
    payload: {
      articles,
      total,
    },
  }) as const

export const browserAction = ({
  searchTerm,
  category,
  page,
  perPage,
}: Dictionary<string | number | undefined>) =>
  ({
    type: 'BROWSER_ACTION',
    payload: {
      searchTerm,
      category,
      page,
      perPage,
    },
  }) as const

type Action = ReturnType<
  | typeof updateSearchTerm
  | typeof updateCategory
  | typeof changePage
  | typeof changePerPage
  | typeof setPending
  | typeof setArticles
  | typeof browserAction
>

export type State = {
  pending: boolean
  page: number
  perPage: number
  total: number
  searchTerm: string | undefined
  category: string | undefined
  articles: KnowledgeArticle[]
}

export const initialReducerState = {
  pending: false,
  page: 0,
  perPage: 10,
  total: 0,
  searchTerm: undefined,
  category: undefined,
  articles: [],
}

export const reducer = (
  state: State = initialReducerState,
  action: Action,
): State => {
  switch (action.type) {
    case 'SET_PENDING': {
      return {
        ...state,
        pending: action.payload.pending,
      }
    }

    case 'SET_ARTICLES': {
      return {
        ...state,
        articles: action.payload.articles,
        total: action.payload.total,
      }
    }
    case 'UPDATE_SEARCH_TERM': {
      return {
        ...state,
        page: action.payload.page,
        perPage: action.payload.perPage,
        searchTerm: action.payload.searchTerm,
        category: undefined,
      }
    }

    case 'UPDATE_CATEGORY': {
      return {
        ...state,
        page: action.payload.page,
        perPage: action.payload.perPage,
        category: action.payload.category,
        searchTerm: undefined,
      }
    }

    case 'CHANGE_PAGE': {
      return {
        ...state,
        page: action.payload.page,
      }
    }

    case 'CHANGE_PER_PAGE': {
      return {
        ...state,
        page: initialReducerState.page,
        perPage: action.payload.perPage,
      }
    }

    case 'BROWSER_ACTION': {
      const { searchTerm, category, page, perPage } = action.payload

      let nextSearchTerm: string | undefined = undefined
      let nextCategory: string | undefined = undefined

      if (typeof searchTerm === 'string') {
        nextSearchTerm = searchTerm
      } else if (typeof category === 'string') {
        nextCategory = category
      }

      const nextPage = typeof page === 'number' ? page : state.page

      const nextPerPage = typeof perPage === 'number' ? perPage : state.perPage

      const nextState: State = {
        ...state,
        searchTerm: nextSearchTerm,
        category: nextCategory,
        page: nextPage,
        perPage: nextPerPage,
      }

      return nextState
    }
  }
}
