import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'

import Layout from 'components/Layout'

import { RoutePath } from 'services/NavigationHelper'

import { externalSellerLogin } from 'store/user/actionCreators'
import {
  isRoleExternalUserSelector,
  currentSellerId,
  getExternalUserLogin,
} from 'store/selectors'
import { ExternalUserLogin } from 'types/UserInfo'

const indexRegex = /^\/$/

export const UserHome = () => {
  const reduxDispatch = useDispatch()
  const location = useLocation()

  const sellerId = useSelector(currentSellerId)
  const isExternalUser = useSelector(isRoleExternalUserSelector)
  const externalLogin = useSelector(getExternalUserLogin)

  const redirect = indexRegex.test(location.pathname)
  const sellerRedirect =
    isExternalUser && sellerId ? (
      <Navigate to={`/${sellerId}${RoutePath.DASHBOARD}`} replace />
    ) : (
      <Navigate to={RoutePath.USER_PROFILE} replace />
    )

  useEffect(() => {
    if (isExternalUser && externalLogin === ExternalUserLogin.IDLE) {
      // This should only fire for external users on successful login
      reduxDispatch(externalSellerLogin())
    }
  }, [isExternalUser, externalLogin, reduxDispatch])

  if (isExternalUser) {
    if (externalLogin !== ExternalUserLogin.SUCCESS) {
      return null
    }
  }

  return isExternalUser && redirect ? sellerRedirect : <Layout />
}

export default UserHome
