import axios from 'axios'

import apiConfig from 'config/apiConfig'

import { PartnerDiscount, PartnerDiscountHistory } from 'types/Discounts'
import { CollectionResponse } from 'types/Response'

import { getPageable, PagingParams } from './pageableHelper'

export const getPartnerDiscounts = (
  pagingParams: PagingParams,
): Promise<CollectionResponse<PartnerDiscount>> => {
  const config = { params: { ...getPageable(pagingParams) } }
  return axios
    .get(`${apiConfig.sms}/seller_discounts`, config)
    .then(({ data, headers }) => ({
      data,
      total: parseInt(headers['x-total-count'], 10),
    }))
}

export const updatePartnerDiscount = async ({
  sellerId,
  discountId,
  discountPercentage,
}: {
  sellerId?: string
  discountId?: string
  discountPercentage: number
}): Promise<PartnerDiscount> => {
  const payload = {
    ...(sellerId ? { seller_id: sellerId } : {}),
    discount_percentage: discountPercentage,
  }

  const result = discountId
    ? await axios.put(
        `${apiConfig.sms}/seller_discounts/${discountId}`,
        payload,
      )
    : await axios.post(`${apiConfig.sms}/seller_discounts`, payload)

  return result.data
}

export const getPartnerDiscountHistory = (
  sellerId: string,
  pagingParams: PagingParams,
): Promise<PartnerDiscountHistory[]> => {
  const pageableParams = { ...getPageable(pagingParams) }
  const config = { params: { seller_id: sellerId, ...pageableParams } }
  return axios
    .get(`${apiConfig.sms}/seller_discount_histories`, config)
    .then((res) => res.data)
}
