import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'

import ContentSpacer from 'components/common/ContentSpacer'

import formatCurrency from 'services/formatCurrency'

import { JudgementValues, ReturnDispute } from 'types/disputes'

const StyledTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
}))

interface Props {
  returnDispute: ReturnDispute
}

const ReturnReversal = ({ returnDispute }: Props) => {
  return (
    <>
      <ContentSpacer />
      <StyledTypography>Return Reversal:</StyledTypography>
      {returnDispute.judgement === JudgementValues.INVALID_CLAIM && (
        <Typography>
          N/A - this dispute has been denied. Please see communication history
          for more details.
        </Typography>
      )}
      {returnDispute.judgement === JudgementValues.VALID_CLAIM && (
        <Typography>
          {`${formatCurrency(
            returnDispute.seller_refund_amount,
          )} of Item Chargeback and ${formatCurrency(
            returnDispute.service_fee_reversal,
          )} of Return Service Fee will be reimbursed for Qty ${
            returnDispute.quantity
          }
      within 2-3 payouts after approval.`}
        </Typography>
      )}
    </>
  )
}

export default ReturnReversal
