import styled from '@emotion/styled'
import DoneIcon from '@mui/icons-material/Done'
import ErrorIcon from '@mui/icons-material/Error'
import Typography from '@mui/material/Typography'

const StyledTextAlign = styled('span')({
  display: 'flex',
  alignItems: 'center',
})

const StyledDoneIcon = styled(DoneIcon)(({ theme }) => ({
  color: theme.palette.success.main,
}))

const StyledErrorIcon = styled(ErrorIcon)(({ theme }) => ({
  color: theme.palette.error.main,
}))

interface Props {
  message: string
  isError: boolean | undefined
}

function SimpleMessage(props: Props) {
  const { message, isError } = props

  return (
    <StyledTextAlign data-testid="simple-message">
      {isError !== undefined &&
        (!isError ? (
          <StyledDoneIcon data-testid="message-icon" />
        ) : (
          <StyledErrorIcon data-testid="message-icon" />
        ))}
      <Typography color={isError ? 'error' : 'inherit'} component="span">
        {message}
      </Typography>
    </StyledTextAlign>
  )
}

export default SimpleMessage
