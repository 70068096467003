import { AlertColor } from '@mui/material'
import { createSlice } from '@reduxjs/toolkit'

import { HttpError } from 'types/HttpError'
export interface NotificationState {
  isShown: boolean
  message: string | HttpError
  isBannerOpen: boolean
  severity: AlertColor
  anchorOrigin: any
  autoClose: boolean
}

export const initialState: NotificationState = {
  isShown: false,
  message: '',
  isBannerOpen: true,
  severity: 'error',
  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  autoClose: false,
}

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    showNotification: (state, action) => {
      const { payload } = action
      const { isShown, message, severity, anchorOrigin, autoClose } = payload
      state.isShown = isShown
      state.message = message
      state.severity = severity ?? initialState.severity
      state.anchorOrigin = anchorOrigin ?? initialState.anchorOrigin
      state.autoClose = autoClose ?? initialState.autoClose
    },
    hideBanner: (state) => {
      state.isBannerOpen = false
      state.isShown = false
    },
    resetBanner: () => initialState,
  },
})

export const { showNotification, hideBanner, resetBanner } =
  notificationSlice.actions
export default notificationSlice.reducer
