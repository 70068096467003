import React from 'react'
import throttle from 'lodash/fp/throttle'

export type Props = {
  enabled?: boolean
  top?: number
  children: React.ReactNode
}

function getStickyStyle(top: number, isSticky: boolean): React.CSSProperties {
  if (isSticky) {
    return {
      position: 'sticky',
      zIndex: 1000,
      top: top,
      left: 0,
      right: 0,
    }
  } else {
    return {
      position: 'relative',
    }
  }
}

export const Sticky = ({ enabled, top = 0, children }: Props) => {
  const [isSticky, setSticky] = React.useState(false)

  const ref = React.useRef<HTMLDivElement | null>(null)

  const handleScroll = throttle(50, () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= top)
    }
  })

  React.useEffect(() => {
    if (enabled) {
      window.addEventListener('scroll', handleScroll)
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div ref={ref} style={getStickyStyle(top, isSticky)}>
      {children}
    </div>
  )
}

export default Sticky
