import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AddressInput } from 'components/common/AddressInput'
import DialogContainer from 'components/common/Dialog/DialogContainer'
import {
  getAddressValidationHandler,
  hydrateAddress,
  hydrateSmsAddress,
} from 'services/addressHelper'

import { currentSeller, isSellersPending } from 'store/selectors'
import { editSeller } from 'store/seller/actionCreators'

import { Validation } from 'types/Validation'

interface Props {
  isOpen: boolean
}

export const EditSellerPrimaryAddress = ({ isOpen }: Props) => {
  const seller = useSelector(currentSeller)
  const isPending = useSelector(isSellersPending)

  const dispatch = useDispatch()

  const [address, setAddress] = React.useState(
    hydrateAddress(seller?.primary_address ?? {}),
  )
  const [validation, setValidation] = React.useState<Validation>({})

  const handleInputChange = (value: any, property: string) => {
    setAddress((prev) => ({
      ...prev,
      [property]: value,
    }))
  }

  const handleSubmit = () => {
    const { validation: formValidation, isValid } =
      getAddressValidationHandler(address)
    setValidation(formValidation)

    if (isValid && seller) {
      const updatedSeller = {
        ...seller,
        primary_address: hydrateSmsAddress(address),
      }

      dispatch(editSeller(updatedSeller))
    }
  }

  return (
    <DialogContainer
      title="Edit Primary Address"
      isOpen={isOpen}
      onSubmit={handleSubmit}
      isPending={isPending}
    >
      <AddressInput
        handleInputChange={handleInputChange}
        isPending={isPending}
        address={address}
        validation={validation}
      />
    </DialogContainer>
  )
}

export default EditSellerPrimaryAddress
