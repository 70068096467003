import { ITEM_SUBTYPE, ITEM_TYPE } from './Attribute'

export const TARGET_INTERNAL: string = 'internal'
export const TARGET_EXTERNAL: string = 'external'
export const CONTAINS: string = 'CONTAINS'
export const STARTS_WITH: string = 'STARTS_WITH'

export interface TaxonomySearchParams {
  name?: string
  nameSearchType?: typeof CONTAINS | typeof STARTS_WITH
  searchType: typeof ITEM_TYPE | typeof ITEM_SUBTYPE
  target?: typeof TARGET_INTERNAL | typeof TARGET_EXTERNAL
  status?: string
  id?: string | string[]
  perPage?: number
}
