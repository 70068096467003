import { lighten, createTheme } from '@mui/material/styles'

const fontFamily = '"Roboto", "Helvetica", "Arial", sans-serif'
const lineHeight = '1.46429em'

export const primary = {
  light: '#E5F4FF',
  main: '#366CD9',
  dark: '#0037A6',
}

export const target = {
  light: '#FFF1F0',
  main: '#CC0000',
}

export const error = {
  light: '#FCEDE0',
  main: '#B85300',
}

export const alert = {
  light: '#FDEDED',
  main: '#5F2120',
}

export const warning = {
  light: '#FEF7B6',
  main: '#EBC202',
}

export const success = {
  light: '#E0F4E0',
  main: '#008300',
  dark: '#0C5D0C',
}

export const white = {
  main: '#FFFFFF',
}

export const grey = {
  100: '#F7F7F7',
  200: '#EEEEEE',
  300: '#D6D6D6',
  400: '#BDBDBD',
  500: '#888888',
  700: '#666666',
  900: '#333333',
}

export const filterChip = {
  main: '#E5F4FF',
}

export const backgroundGrey = {
  main: '#EEEEEE',
}

export const odrNonCompliant = {
  main: '#B85300',
}
export const violet = { main: '#6E23B3' }
export const teal = { main: '#188294' }
export const pink = { main: '#A824A5' }
export const olive = { main: '#857400' }

export const boxShadow =
  '0 4px 6px rgba(50,50,93,.11), 0 3px 3px rgba(0,0,0,.08)'

const body1 = {
  fontSize: '0.875rem',
  fontWeight: 400,
}

declare module '@mui/material/styles' {
  interface Theme {
    micro: {
      fontSize: string
      fontStyle: string
      fontWeight: number
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    micro?: {
      fontSize?: string
      fontStyle?: string
      fontWeight?: number
    }
  }
}

const theme = createTheme({
  micro: {
    fontSize: '0.875rem',
    fontStyle: 'italic',
    fontWeight: 300,
  },
  palette: {
    primary,
    error,
    grey,
    action: {
      active: primary.main,
    },
    text: {
      primary: grey[900],
      secondary: grey[700],
    },
    background: {
      default: '#FFFFFF',
    },
  },
  mixins: {},
  components: {
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...((ownerState.severity === 'error' && {
            backgroundColor: alert.light,
            '.MuiAlert-icon': {
              color: '#EF5350',
            },
            '.MuiIconButton-root': {
              color: alert.main,
              ':hover': {
                backgroundColor: 'rgba(0,0,0,0.04)',
                color: alert.main,
              },
            },
            '.MuiTypography-body1': {
              color: alert.main,
            },
          }) ||
            (ownerState.severity === 'success' && {
              backgroundColor: success.main,
              '.MuiAlert-icon': {
                color: white.main,
              },
              '.MuiIconButton-root': {
                color: success.main,
                ':hover': {
                  backgroundColor: 'rgba(0,0,0,0.04)',
                  color: success.main,
                },
              },
              '.MuiTypography-body1': {
                color: white.main,
              },
            })),
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          '&:before': {
            backgroundImage: grey[700],
            borderRadius: '2px 0 0 2px',
            bottom: 0,
            content: '""',
            left: 0,
            top: 0,
            position: 'absolute',
            width: 8,
          },
          minWidth: 500,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          color: grey[300],
          backgroundColor: grey[900],
          overflowX: 'hidden',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: grey[900],
            backgroundColor: white.main,
          },
        },
        contained: {
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: 'none',
          },
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: white.main,
            color: primary.main,
            boxShadow: `0 0 0 1px #366cd9`,
          },
        },
        containedSecondary: {
          color: primary.main,
          backgroundColor: white.main,
          '&:hover': {
            backgroundColor: grey[100],
          },
        },
        textPrimary: {
          '&:hover': {
            backgroundColor: primary.main,
            color: white.main,
          },
        },
        outlinedPrimary: {
          '&:hover': {
            backgroundColor: primary.main,
            color: white.main,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: grey[700],
          padding: 0,
          '&:hover': {
            backgroundColor: white.main,
            color: primary.main,
          },
        },
        colorPrimary: {
          '&:hover': {
            backgroundColor: primary.main,
            color: white.main,
          },
        },
        colorSecondary: {
          '&:hover': {
            backgroundColor: primary.light,
            color: primary.main,
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorPrimary: {
          '&:hover': {
            color: grey[300],
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: primary.main,
        },
        colorSecondary: {
          '&:hover': {
            backgroundColor: primary.light,
          },
          '&$checked': {
            color: primary.main,
            '&:hover': {
              backgroundColor: primary.light,
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: primary.main,
        },
        colorSecondary: {
          '&:hover': {
            backgroundColor: primary.light,
          },
          '&$checked': {
            color: primary.main,
            '&:hover': {
              backgroundColor: primary.light,
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: { ...body1, backgroundColor: grey[900] },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontWeight: 400,
          fontSize: '1.3125rem',
        },
        h2: {
          fontSize: '1.6875rem',
          fontWeight: 400,
          textTransform: 'capitalize',
        },
        h3: {
          fontWeight: 400,
          fontSize: '1.375rem',
        },
        h4: {
          fontSize: '1.125rem',
          fontWeight: 400,
        },
        h5: {
          lineHeight,
          fontFamily,
          fontSize: '0.75rem',
          fontWeight: 700,
          textTransform: 'capitalize',
        },
        h6: {
          fontWeight: 400,
          fontSize: '1.125rem',
        },
        caption: {
          fontWeight: 400,
          fontSize: '0.75rem',
          color: grey[700],
          fontStyle: 'italic',
        },
        subtitle1: {
          fontWeight: 700,
          color: grey[700],
        },
        body1,
        body2: {
          fontSize: '0.875rem',
          fontWeight: 400,
        },
        overline: {
          fontSize: '0.9375rem',
          fontWeight: 500,
          letterSpacing: '0.7px',
          textTransform: 'uppercase',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        colorPrimary: {
          '&$disabled': {
            '&$checked': {
              color: lighten(primary.main, 0.6),
            },
            '&$checked + $track': {
              backgroundColor: primary.main,
            },
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          boxShadow,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: primary.main,
          },
          '&$selected': {
            color: primary.main,
          },
        },
        textColorInherit: {
          opacity: 1,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: body1,
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: body1,
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: body1,
      },
    },
    MuiChip: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            border: '1px solid',
          },
        },
      ],
      styleOverrides: {
        root: {
          fontWeight: 500,
          letterSpacing: '0.7px',
          textTransform: 'uppercase',
        },
      },
    },
    // TODO: Remove this since it takes priority over styled(TableCell) but not over the sx prop. Update the component that requires this style instead to reduce confusion over which style is applied
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 700,
          fontSize: '0.9375rem',
        },
      },
    },
  },
})

export default theme
