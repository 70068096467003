import { useState, useEffect, useCallback } from 'react'
import { useDispatch, connect } from 'react-redux'

import { error, success } from 'config/themeConfig'

import { currentSellerId, getDisputeSubResponses } from 'store/selectors'
import { openDialog, closeDialog } from 'store/dialog/actionCreator'

import CreateDisputeSummary from './CreateDisputeSummary'
import DialogContainer from 'components/common/Dialog/DialogContainer'
import { DialogEnum } from 'components/common/Dialog'
import { ProductReturnTypeahead } from 'components/common/Typeahead'
import SelectField from 'components/common/form/SelectField'
import InputField from 'components/common/form/InputField'

import { CircularProgress, Grid, Typography } from '@mui/material'

import * as yup from 'yup'
import { Form, FormikProps, withFormik } from 'formik'

import { ValidationSchema } from 'services/validation'
import { createReturnDisputeRequest } from 'services/returnDisputes'
import { searchProductReturns } from 'services/productReturns'

import { Return } from 'types/Orders'
import StoreState from 'types/state'
import { DisputeSubReason } from 'types/Enumeration'
import { JudgementValues, ReturnDispute } from 'types/disputes'

interface FormValues {
  returnOrderNumber: string
  tcinLicensePlate: string
  disputeReason: string
  disputeQuantity: number
  disputeResponse: string | ReturnDispute
}

export interface ComponentProps extends FormValues {
  sellerId: string | undefined
  disputeSubReasons: DisputeSubReason[]
  isOpen: boolean
  isPending: boolean
  handleSubmit: any
  handleClose: () => void
}

type Props = ComponentProps & FormikProps<FormValues>

export type SelectItem = {
  label: string
  value: string
}

export type ResponseText = {
  text: string
  color: string
}

const validationSchema: ValidationSchema = yup.object().shape({
  returnOrderNumber: yup.string().label('Return Order Number').required(),
  tcinLicensePlate: yup.string().label('TCIN').required(),
  disputeReason: yup.string().label('Dispute Reason').required(),
  disputeQuantity: yup
    .number()
    .label('Dispute Quantity')
    .positive()
    .test({
      name: 'isDisputeQuantity',
      test: function (this: yup.TestContext, input: any) {
        const quantity = this.parent?.order?.quantity
        if (!quantity) {
          return true
        }

        if (input > quantity) {
          return this.createError({
            path: 'disputeQuantity',
            message: `Value must be ${quantity} or less`,
          })
        }
        return input <= quantity
      },
    })
    .required(),
})

const FileReturnDisputeForm = ({
  sellerId,
  disputeSubReasons,
  isOpen,
  setFieldValue,
  isValid,
  dirty,
  values,
  handleSubmit,
  isSubmitting,
  resetForm,
  handleClose,
}: Props) => {
  const reduxDispatch = useDispatch()

  const [title, setTitle] = useState<string>('FILE A NEW RETURN DISPUTE')
  const [order, setOrder] = useState<Return>()
  const [orders, setOrders] = useState<Return[]>([])
  const [tcinOptions, setTcinOptions] = useState<SelectItem[]>([])
  const [returnQtyHelperText, setReturnQtyHelperText] = useState<string>('')
  const [returnQtyDisabled, setReturnQtyDisabled] = useState<boolean>(true)

  const [response, setResponse] = useState<ResponseText>()
  const [dispute, setDispute] = useState<ReturnDispute>()
  const [attachmentsRequired, setAttachmentsRequired] = useState<boolean>(false)

  const SEPARATOR = '-'

  const disputeSubReasonOptions: SelectItem[] = disputeSubReasons.map(
    (reason) => {
      return {
        label: reason.properties.description,
        value: reason.value,
      }
    },
  )

  const prettyDisputeReason =
    disputeSubReasonOptions.find((val) => val.value === values.disputeReason)
      ?.label || ''

  const licensePlate = values.tcinLicensePlate.split('-')[1]

  useEffect(() => {
    let mounted = true

    if (values.returnOrderNumber) {
      searchProductReturns(
        { page: 0, perPage: 20 },
        { return_order_number: values.returnOrderNumber, seller_id: sellerId },
      ).then((res) => {
        let tempArray: SelectItem[] = []

        res.data.forEach((item) => {
          if (!item.is_online && item.tracking_data?.length) {
            item.tracking_data.forEach((val) => {
              if (val.license_plate) {
                tempArray.push({
                  label: `TCIN ${item.tcin} - License Plate ${val.license_plate}`,
                  value: `${item.tcin}${SEPARATOR}${val.license_plate}`,
                })
              }
            })
          } else {
            tempArray.push({ label: `TCIN ${item.tcin}`, value: item.tcin })
          }
        })

        if (mounted) {
          setOrders(res.data)

          setTcinOptions((prevState) => [...prevState, ...tempArray])
        }
      })
    }

    return () => {
      mounted = false
    }
  }, [values.returnOrderNumber, sellerId])

  useEffect(() => {
    let mounted = true

    if (orders.length && values.tcinLicensePlate) {
      const tcin = values.tcinLicensePlate.split(SEPARATOR)[0]

      const selectedOrder = orders.find((o) => o.tcin === tcin)

      if (selectedOrder) {
        setOrder(selectedOrder)

        if (!selectedOrder?.is_online || selectedOrder?.quantity === 1) {
          if (mounted) {
            setReturnQtyHelperText('')
          }
        } else {
          if (mounted) {
            setReturnQtyHelperText(`Return Qty: ${selectedOrder?.quantity}`)
            setReturnQtyDisabled(false)
          }
        }
      }
    }

    return () => {
      mounted = false
    }
  }, [orders, values.tcinLicensePlate])

  const handleAttachmentsRequired = useCallback(() => {
    closeDialog()
    reduxDispatch(
      openDialog({
        dialogEnum: DialogEnum.NEEDS_REVIEW_DIALOG,
        componentProps: {
          sellerId: sellerId,
          order: order,
          licensePlate: licensePlate,
          disputeReason: values.disputeReason,
          prettyDisputeReason: prettyDisputeReason,
          disputeQuantity: values.disputeQuantity,
          response: response,
          handleCloseDialog: handleClose,
        },
      }),
    )
  }, [
    prettyDisputeReason,
    handleClose,
    licensePlate,
    order,
    reduxDispatch,
    response,
    sellerId,
    values.disputeQuantity,
    values.disputeReason,
  ])

  useEffect(() => {
    if (attachmentsRequired) handleAttachmentsRequired()
  }, [attachmentsRequired, handleAttachmentsRequired])

  useEffect(() => {
    if (values.disputeResponse) {
      if (typeof values.disputeResponse !== 'string') {
        setTitle('VALID DISPUTE')
        setDispute(values.disputeResponse)

        setResponse({
          text: `Success! Target will reimburse item chargeback 
               $${values.disputeResponse.seller_refund_amount} to your account within the next 2-3 payouts.`,
          color: success.main,
        })
      } else {
        if (values.disputeResponse.includes('needs review')) {
          setAttachmentsRequired(true)
          setResponse({
            color: error.main,
            text: values.disputeResponse.replace('needs review', ''),
          })
        } else {
          setTitle('CANNOT BE DISPUTED')
          setResponse({
            color: error.main,
            text: values.disputeResponse,
          })
        }
      }
    }
  }, [values.disputeResponse])

  const handleReturnOrderNumberChange = (value: Nullable<Return>) => {
    if (!value) return
    setOrder(value)
    setFieldValue('order', value)
    setFieldValue('returnOrderNumber', value.return_order_number)
  }

  const handleClear = () => {
    setOrder(undefined)
    setOrders([])
    setReturnQtyHelperText('')
    setReturnQtyDisabled(true)
    setTcinOptions([])
    resetForm()
  }

  return (
    <>
      <DialogContainer
        title={title}
        isOpen={isOpen}
        onCancel={dispute ? handleClose : undefined}
        disableClickAway
        closeButtonText={values.disputeResponse ? 'Close' : 'Cancel'}
        onSubmit={values.disputeResponse ? undefined : handleSubmit}
        submitButtonText={
          values.disputeResponse ? undefined : 'Check Eligibility'
        }
        isSubmitDisabled={!isValid || !dirty}
        fullWidth={true}
        maxWidth={'sm'}
        isPending={isSubmitting}
      >
        {!isSubmitting && !values.disputeResponse && (
          <Form>
            <Grid container>
              <Grid item xs={12} sx={{ mb: 4 }}>
                <ProductReturnTypeahead
                  label="Return Order Number *"
                  placeholder="Return Order #"
                  sellerId={sellerId}
                  onChange={handleReturnOrderNumberChange}
                  value={order ?? null}
                  onClear={handleClear}
                  perPage={1}
                />
              </Grid>
              <Grid item xs={12} sx={{ mb: 4 }}>
                <SelectField
                  required
                  name="tcinLicensePlate"
                  data-testid="tcinLicensePlate"
                  label="TCIN"
                  options={tcinOptions}
                  keyName="label"
                  valueName="value"
                  disabled={!values.returnOrderNumber}
                />
              </Grid>
              <Grid item xs={12} sx={{ mb: 4 }}>
                <SelectField
                  required
                  name="disputeReason"
                  data-testid="disputeReason"
                  label="Dispute Reason"
                  options={disputeSubReasonOptions}
                  keyName="label"
                  valueName="value"
                  disabled={!values.returnOrderNumber && returnQtyDisabled}
                />
              </Grid>

              <Grid item xs={12} sx={{ mb: 4 }}>
                <InputField
                  required
                  name="disputeQuantity"
                  label="Dispute Quantity"
                  helperText={returnQtyHelperText}
                  enableOnChangeValidation
                  disabled={returnQtyDisabled}
                />
              </Grid>
            </Grid>
          </Form>
        )}

        {isSubmitting && (
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        )}

        {values.disputeResponse && order && response && (
          <Grid container>
            <CreateDisputeSummary
              order={order}
              licensePlate={licensePlate}
              disputeReason={prettyDisputeReason}
              disputeQuantity={values.disputeQuantity}
            />
            <Grid item sx={{ pt: 3 }}>
              <Typography sx={{ color: response?.color, fontWeight: 'bold' }}>
                {response?.text}
              </Typography>
            </Grid>
          </Grid>
        )}
      </DialogContainer>
    </>
  )
}

export const FileReturnDisputeDialog = withFormik<ComponentProps, FormValues>({
  mapPropsToValues: () => ({
    returnOrderNumber: '',
    order: {},
    tcinLicensePlate: '',
    disputeReason: '',
    disputeQuantity: 1,
    disputeResponse: '',
  }),
  handleSubmit: async (values, { props, setSubmitting, setFieldValue }) => {
    if (!props.sellerId) {
      return
    }

    const {
      returnOrderNumber,
      tcinLicensePlate,
      disputeReason,
      disputeQuantity,
    } = values
    const { sellerId } = props

    let tempArray = tcinLicensePlate.split('-')
    let tcin = tempArray[0]
    let licensePlate = tempArray[1] ?? ''

    const initialResponse = await createReturnDisputeRequest(
      true,
      sellerId,
      returnOrderNumber,
      tcin,
      disputeReason,
      disputeQuantity,
      licensePlate,
    )

    const disputePreview: ReturnDispute = initialResponse.data

    if (
      disputePreview.judgement === JudgementValues.INVALID_CLAIM &&
      disputePreview.final_response_description !== undefined
    ) {
      setFieldValue(
        'disputeResponse',
        disputePreview.final_response_description,
      )
    } else if (disputePreview.judgement === JudgementValues.VALID_CLAIM) {
      const response = await createReturnDisputeRequest(
        false,
        sellerId,
        returnOrderNumber,
        tcin,
        disputeReason,
        disputeQuantity,
        licensePlate,
      )
      setFieldValue('disputeResponse', response.data)
    } else if (disputePreview.judgement === JudgementValues.NEEDS_REVIEW) {
      setFieldValue(
        'disputeResponse',
        'needs review' + disputePreview.final_response_description,
      )
    }

    setSubmitting(false)
  },
  enableReinitialize: true,
  validationSchema,
})(FileReturnDisputeForm)

const mapStateToProps = (state: StoreState) => {
  const sellerId = currentSellerId(state)
  const disputeSubReasons = getDisputeSubResponses(state)

  return {
    sellerId,
    disputeSubReasons,
  }
}

export default connect(mapStateToProps)(FileReturnDisputeDialog)
