import { JudgementValues } from 'types/disputes'
import { DisputeSubReason } from 'types/Enumeration'
import { Return } from 'types/Orders'

export const displaySystemMessage = (judgement: JudgementValues) => {
  return (
    judgement === JudgementValues.INVALID_CLAIM ||
    judgement === JudgementValues.NEEDS_REVIEW ||
    judgement === JudgementValues.WAITING_ON_PARTNER
  )
}
export const getDisputeByPhysicalDisposition = (
  disputeReturn: Return,
  storePhysicalDisposition: string,
) => {
  return disputeReturn.tracking_data?.find(
    (tracking) =>
      tracking.store_physical_disposition === storePhysicalDisposition,
  )
}

export const getDisputeSubReasonDescription = (
  disputeSubReason: string,
  disputeSubReasons: DisputeSubReason[],
) => {
  return disputeSubReasons.find(
    (subReason) => subReason.value === disputeSubReason,
  )?.properties.description
}
