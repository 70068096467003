export enum EnumerationName {
  BUSINESS_STRUCTURE = 'business_structure',
  SOURCE = 'source',
  REPORT_TYPE = 'report_type',
  LISTING_STATUS = 'listing_status',
  SELLER_STATUS = 'seller_status',
  ORDER_STATUS = 'order_status',
  PRODUCT_ERROR_CODE = 'product_error_code',
  ERROR_CATEGORY = 'error_category',
  STATE_CODE = 'state_code',
  RETURN_REASON = 'return_reason',
  DISPUTE_RESPONSE = 'dispute_response',
  CASE_TYPE = 'case_type',
  ORDER_DEFECT_CATEGORY = 'order_defect_category',
  RETURN_CENTERS = 'return_center_location',
  DISPUTE_SUB_REASON = 'dispute_sub_reason',
  CARRIER = 'carrier',
}

export interface Enumeration {
  name: string
  values: EnumerationValue[]
}

export interface EnumerationValue {
  value: string
  properties: Dictionary<any>
}

export type EnumerationDict = Dictionary<EnumerationValue[]>

export interface StateCode extends EnumerationValue {
  properties: {
    name: string
  }
}

export interface ReturnReason extends EnumerationValue {
  properties: {
    description: string
  }
}

export interface DisputeResponse extends EnumerationValue {
  properties: {
    responseText: string
  }
}

export interface CaseType extends EnumerationValue {
  properties: {
    name: string
    level1: string
    level2: string
  }
}

export interface ReturnCenter extends EnumerationValue {
  properties: {
    id: number
    location: string
  }
}

export interface DisputeSubReason extends EnumerationValue {
  properties: {
    description: string
    disputeReason: string
  }
}
