import { getStore } from 'store'
import { showNotification } from 'store/notification/reducer'

export const SEARCH_TIMEOUT = 10000

const defaultOptions = {
  rejectOnTimeout: true,
  showNofication: true,
  timeoutMessage: 'A service timed out waiting for a response',
  timeoutResponse: undefined,
}

export const timeoutPromise = <T>(
  ms: number,
  promise: Promise<T>,
  options?: {
    rejectOnTimeout?: boolean
    showNofication?: boolean
    timeoutMessage?: string
    timeoutResponse?: T
  },
) =>
  new Promise<T>((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      if (options?.showNofication ?? defaultOptions.showNofication) {
        getStore().dispatch(
          showNotification({
            isShown: true,
            message: options?.timeoutMessage ?? defaultOptions.timeoutMessage,
          }),
        )
      }

      if (options?.rejectOnTimeout ?? defaultOptions.rejectOnTimeout) {
        reject(options?.timeoutResponse ?? defaultOptions.timeoutResponse)
      } else {
        // TODO: Figure out how to remove the `as any` cast for defaultOptions.timeoutRepsonse.
        resolve(
          options?.timeoutResponse ?? (defaultOptions.timeoutResponse as any),
        )
      }
    }, ms)
    promise.then(
      (res) => {
        clearTimeout(timeoutId)
        resolve(res)
      },
      (err) => {
        clearTimeout(timeoutId)
        reject(err)
      },
    )
  })
