import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import { TableHook } from 'components/common/EnhancedTable/useTable'
import TableSpacer from 'components/common/TableSpacer'
import {
  formatDateMDY,
  formatPercentNotFixed,
} from 'components/common/EnhancedTable/formatters'

import { Markup } from 'types/Markup'

interface Props {
  table: TableHook
  data: Markup[]
  total: number
  isPending: boolean
  highlightRow?: number
}

const fieldList: EnhancedTableFieldType<Markup>[] = [
  {
    key: 'start_date',
    heading: 'Effective Start Date',
    hasSort: true,
    formatCell: formatDateMDY('start_date'),
  },
  {
    key: 'end_date',
    heading: 'Effective End Date',
    hasSort: true,
    formatCell: formatDateMDY('end_date'),
  },
  {
    key: 'rate',
    heading: 'Markup %',
    hasSort: true,
    formatCell: formatPercentNotFixed('rate'),
  },
  {
    key: 'last_modified_by',
    heading: 'Last Modified By',
    hasSort: true,
  },
  {
    key: 'last_modified',
    heading: 'Last Modified',
    hasSort: true,
    formatCell: formatDateMDY('last_modified'),
  },
]

export const CarrierMarkupHistoryTable = ({
  table,
  data,
  total,
  isPending,
  highlightRow,
}: Props) => {
  return (
    <TableSpacer>
      <EnhancedTable
        total={total}
        onChangePage={table.actions.changePage}
        onChangeRowsPerPage={table.actions.changePerPage}
        onRequestSort={table.actions.sort}
        orderBy={table.state.orderBy}
        order={table.state.direction}
        page={table.state.page}
        rowsPerPage={table.state.perPage}
        fieldList={fieldList}
        paginationDisabled={false}
        data={data}
        isLoading={isPending}
        hover={false}
        stripedRows={false}
        highlightRow={highlightRow}
      />
    </TableSpacer>
  )
}

export default CarrierMarkupHistoryTable
