import React from 'react'
import getOr from 'lodash/fp/getOr'
import isNil from 'lodash/fp/isNil'

import { EnhancedTableFieldType } from './index'

import { Direction, PagingParams } from 'services/pageableHelper'

const SORT: string = 'SORT'
const CHANGE_PAGE: string = 'CHANGE_PAGE'
const CHANGE_PER_PAGE: string = 'CHANGE_PER_PAGE'
const RESET_STATE: string = 'RESET_STATE'

interface Sort {
  readonly type: typeof SORT
  payload: {
    field: EnhancedTableFieldType
  }
}

interface ChangePage {
  readonly type: typeof CHANGE_PAGE
  payload: {
    event: React.MouseEvent<HTMLElement, MouseEvent> | null
    page?: number
  }
}

interface ChangePerPage {
  readonly type: typeof CHANGE_PER_PAGE
  payload: {
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  }
}

interface ResetState {
  readonly type: typeof RESET_STATE
  payload: PagingParams
}

type TableActions = Sort | ChangePage | ChangePerPage | ResetState

type TableDispatch = React.Dispatch<TableActions>

const sort = (dispatch: TableDispatch) => (field: EnhancedTableFieldType) =>
  dispatch({ type: SORT, payload: { field } })

const changePage =
  (dispatch: TableDispatch) =>
  (event: React.MouseEvent<HTMLElement, MouseEvent> | null, page?: number) =>
    dispatch({ type: CHANGE_PAGE, payload: { event, page } })

const changePerPage =
  (dispatch: TableDispatch) =>
  (event: React.ChangeEvent<{ name?: string; value: unknown }>) =>
    dispatch({ type: CHANGE_PER_PAGE, payload: { event } })

const resetState = (dispatch: TableDispatch) => (pagingParams: PagingParams) =>
  dispatch({ type: RESET_STATE, payload: pagingParams })

const actions = (dispatch: TableDispatch) => ({
  sort: sort(dispatch),
  changePage: changePage(dispatch),
  changePerPage: changePerPage(dispatch),
  resetState: resetState(dispatch),
})

export interface TableState {
  page: number
  perPage: number
  direction?: Direction
  orderBy?: string
}

export interface TableHook {
  state: TableState
  actions: ReturnType<typeof actions>
}

const defaultState: TableState = {
  page: 0,
  perPage: 20,
}

const tableReducer = (state: TableState, action: TableActions) => {
  switch (action.type) {
    case SORT: {
      const {
        payload: {
          field: { key },
        },
      } = action as Sort
      const newDirection =
        state.orderBy === key && state.direction === Direction.DESC
          ? Direction.ASC
          : Direction.DESC

      return {
        ...state,
        direction: newDirection,
        orderBy: key,
      }
    }

    case CHANGE_PAGE: {
      const {
        payload: { page, event },
      } = action as ChangePage
      if (isNil(page) || !event) {
        return state
      }

      return {
        ...state,
        page,
      }
    }

    case CHANGE_PER_PAGE: {
      const {
        payload: { event },
      } = action as ChangePerPage
      const newPerPage = getOr('', 'target.value', event) as string

      return {
        ...state,
        perPage: newPerPage ? parseInt(newPerPage, 10) : defaultState.perPage,
        page: defaultState.page,
      }
    }

    case RESET_STATE: {
      const {
        payload: { page, perPage, direction, orderBy },
      } = action as ResetState
      return {
        page,
        perPage,
        direction,
        orderBy,
      }
    }

    default:
      return state
  }
}

const useTable = (initialState: TableState = defaultState) => {
  const [state, dispatch] = React.useReducer(tableReducer, initialState)
  return { table: { state, actions: actions(dispatch) } }
}

export default useTable
