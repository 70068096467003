import React from 'react'

import { Field, FieldProps } from 'formik'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'

interface FormProps {
  name: string
  label: string
  disabled?: boolean
  required?: boolean
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void
}

type Props = FormProps & Omit<CheckboxProps, 'name' | 'value' | 'disabled'>

export const CheckboxField = ({
  name,
  label,
  disabled,
  required,
  onChange,
  ...props
}: Props) => (
  <Field name={name}>
    {({ form, field }: FieldProps) => {
      const color = disabled ? 'disabled' : 'primary'

      const onCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean,
      ) => {
        form.setFieldValue(name, checked)

        if (onChange) {
          onChange(event, checked)
        }
      }
      return (
        <FormControlLabel
          control={
            <Checkbox
              {...props}
              {...field}
              icon={<CheckBoxOutlineBlankIcon color={color} />}
              required={required}
              disabled={disabled}
              checked={field.value}
              onChange={onCheckboxChange}
              name={name}
              data-testid={name}
            />
          }
          label={<Typography>{label}</Typography>}
        />
      )
    }}
  </Field>
)

export default CheckboxField
