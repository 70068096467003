import { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid'

import ContentSpacer from 'components/common/ContentSpacer'
import DialogContainer from 'components/common/Dialog/DialogContainer'
import EnhancedTable from 'components/common/EnhancedTable'
import {
  formatPercentTwoDecimals,
  formatDateMDYT,
} from 'components/common/EnhancedTable/formatters'

import { getPartnerDiscountHistory } from 'services/discounts'
import { Direction, PagingParams } from 'services/pageableHelper'

import { PartnerDiscountHistory } from 'types/Discounts'

export interface Props {
  isOpen: boolean
  sellerId: string
  displayName: string
}

export const ViewPartnerDiscountHistoryDialog = ({
  isOpen,
  sellerId,
  displayName,
}: Props) => {
  const [isPending, setIsPending] = useState<boolean>(false)
  const [discountHistory, setDiscountHistory] = useState<
    PartnerDiscountHistory[]
  >([])

  const fieldList = [
    {
      key: 'discount_percentage',
      heading: 'Discount (%)',
      formatCell: formatPercentTwoDecimals('discount_percentage'),
    },
    {
      key: 'last_modified',
      heading: 'Start Date',
      formatCell: formatDateMDYT('last_modified'),
    },
    {
      key: 'last_modified_by',
      heading: 'Last Modified By',
    },
  ]

  useEffect(() => {
    let mounted = true
    setIsPending(true)
    const pagingParams: PagingParams = {
      page: 0,
      perPage: 10,
      orderBy: 'last_modified',
      direction: Direction.DESC,
    }

    getPartnerDiscountHistory(sellerId, pagingParams)
      .then((res) => {
        if (mounted) {
          setDiscountHistory(res)
        }
      })
      .finally(() => {
        setIsPending(false)
      })

    return () => {
      mounted = false
    }
  }, [sellerId])

  return (
    <DialogContainer
      data-testid="add-partner-discount-dialog"
      isOpen={isOpen}
      title={`${displayName} Return Discount History`}
      closeButtonText="Close"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ContentSpacer>
            <EnhancedTable
              data={discountHistory}
              fieldList={fieldList}
              isLoading={isPending}
            />
          </ContentSpacer>
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export default ViewPartnerDiscountHistoryDialog
