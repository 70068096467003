import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Tooltip from '@mui/material/Tooltip'

import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'

import { formatReportType } from './formatReportType'
import FavoriteReportButton from './FavoriteReportButton'

import { SellerReportsTab, hasReport } from './hasReport'

import { ReportType, SellerReportTableRow } from 'types/Report'
import { UserSettings } from 'types/UserSettings'
import { flag, FlagName } from 'flag'

export type Props = {
  data: SellerReportTableRow[]
  userSettings?: UserSettings
  tab: SellerReportsTab
  memberOf: string[]
  handleClick: (reportType: ReportType) => () => void
}

export const SellerReportTable = ({
  data,
  userSettings,
  tab,
  memberOf,
  handleClick,
}: Props) => {
  const favoriteReports = userSettings?.reports?.favorites ?? []
  const isItemErrorsEnabled = flag(FlagName.ITEM_ERRORS)

  let tableData: SellerReportTableRow[] = []

  if (isItemErrorsEnabled && tab === SellerReportsTab.ITEM_DATA) {
    data = data.filter((item) => {
      return !(item.type === ReportType.ITEM_ERRORS)
    })
  }

  if (tab === SellerReportsTab.FAVORITES) {
    tableData = data.filter(
      (item) =>
        favoriteReports.includes(item.type) && hasReport(memberOf, item.type),
    )
  } else if (tab === SellerReportsTab.ALL_REPORTS) {
    tableData = data.filter((item) => hasReport(memberOf, item.type))
  } else {
    tableData = data.filter(
      (item) => item.tab === tab && hasReport(memberOf, item.type),
    )
  }

  const nullText =
    tab === SellerReportsTab.FAVORITES && tableData.length === 0
      ? 'No favorites added. Click the heart icon next to a template in any other category tab to add that template as a favorite.'
      : ''

  const fieldList: EnhancedTableFieldType<SellerReportTableRow>[] = [
    {
      key: '',
      formatCell: (item) => {
        return <FavoriteReportButton row={item} userSettings={userSettings} />
      },
    },
    {
      key: 'type',
      heading: 'Report Template',
      formatCell: (item) => {
        return item.type === ReportType.SALES_TAX ? (
          <div>Partner Sales and Tax</div>
        ) : (
          formatReportType(item)
        )
      },
    },
    {
      key: 'description',
      heading: 'Usage',
      formatCell: (item) => {
        return item.description
      },
    },
    {
      key: '',
      formatCell: (item) => {
        const buttonText = item.isPending ? 'Generating' : 'Generate Report'

        if (item.isDisabled && item.tooltip) {
          return (
            <Tooltip title={item.tooltip}>
              <span>
                <Button
                  data-testid={`${item.type}-btn`}
                  variant="outlined"
                  disabled={item.isDisabled}
                >
                  Generate Report
                </Button>
              </span>
            </Tooltip>
          )
        }

        return (
          <Button
            data-testid={`${item.type}-btn`}
            variant="outlined"
            disabled={item.isDisabled || item.isPending}
            onClick={handleClick(item.type)}
          >
            {buttonText}
            {item.isPending && (
              <CircularProgress
                sx={{
                  color: 'primary',
                  position: 'absolute',
                }}
                data-testid="report-card-generating-progress"
                size={20}
                title="Generating Report"
              />
            )}
          </Button>
        )
      },
    },
  ]

  return (
    <EnhancedTable
      data={tableData}
      fieldList={fieldList}
      isLoading={false}
      paginationDisabled
      nullText={nullText}
    />
  )
}

export default SellerReportTable
