import { Field, FieldProps } from 'formik'

import TextField, { TextFieldProps } from '@mui/material/TextField'
import { ChangeEvent } from 'react'

interface FormProps {
  name: string
  maxCharacters?: number
  enableOnChangeValidation?: boolean
  touchedFieldName?: string
  min?: string
  max?: string
  onChange?: (e: ChangeEvent<any>) => void
}

type Props = FormProps &
  Omit<TextFieldProps, 'error' | 'name' | 'onChange' | 'value'>

const InputField = ({
  name,
  helperText,
  disabled,
  type = 'text',
  maxCharacters,
  enableOnChangeValidation,
  touchedFieldName,
  min,
  max,
  onChange,
  ...props
}: Props) => (
  <Field name={name}>
    {({ form, field }: FieldProps) => {
      const { touched, errors, isSubmitting, setFieldTouched, handleChange } =
        form

      const isDisabled =
        typeof disabled === 'undefined' ? isSubmitting : disabled
      const error = errors[name]
      const hasError = !!(touched[name] && error)

      let hint = undefined

      if (hasError) {
        hint = error
      } else if (maxCharacters) {
        const len = maxCharacters - field.value.length

        const subject = len === 1 ? 'character' : 'characters'
        hint = `${len} ${subject} left (Maximum of ${maxCharacters}).`
      } else {
        hint = helperText
      }

      const onChangeHandler = (event: React.ChangeEvent<any>) => {
        // formik does not set the initial field to "touched" for
        // validation on the initial input focus so force it
        if (enableOnChangeValidation || maxCharacters) {
          setFieldTouched(name)
        }

        if (touchedFieldName) {
          setFieldTouched(touchedFieldName)
        }

        handleChange(event)
      }

      const variant = props.variant ? props.variant : 'standard'

      return (
        <TextField
          {...props}
          {...field}
          error={hasError}
          helperText={hint?.toString()}
          variant={variant}
          InputLabelProps={{ shrink: true }}
          type={type}
          inputProps={{
            'data-testid': name,
            min,
            max,
          }}
          disabled={isDisabled}
          onChange={onChange ?? onChangeHandler}
          fullWidth
        />
      )
    }}
  </Field>
)

export default InputField
