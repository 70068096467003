import { useEffect, useState } from 'react'
import camelCase from 'lodash/fp/camelCase'
import startCase from 'lodash/fp/startCase'

import styled from '@emotion/styled'

import Button from '@mui/material/Button'
import FilterListIcon from '@mui/icons-material/FilterList'

import DisplayCard from 'components/common/DisplayCard'
import TabularData from 'components/common/TabularData'

import { getSellerVerifications } from 'services/sellerVerification'

import { SellerVerificationStatus } from 'types/SellerVerification'

const StyledDisplayCard = styled(DisplayCard)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const StyledCell = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const StyledCount = styled('div')({
  minWidth: '20%',
})

export type Props = {
  onFilterByStatus: (status: SellerVerificationStatus) => () => void
}

export type statusData = {
  status: SellerVerificationStatus
  count: number
}

export const VerifiedStatus = ({ onFilterByStatus }: Props) => {
  const [isPending, setIsPending] = useState(false)
  const [data, setData] = useState<statusData[]>([])

  const fieldList = [
    {
      key: 'status',
      displayName: '',
      width: 25,
      formatCell: (item: {
        status: SellerVerificationStatus
        count: number
      }) => {
        return startCase(camelCase(item.status))
      },
    },
    {
      key: 'count',
      displayName: '',
      width: 75,
      formatCell: (item: {
        status: SellerVerificationStatus
        count: number
      }) => {
        return (
          <StyledCell>
            <StyledCount>{item.count}</StyledCount>
            <Button
              color="primary"
              data-testid={`${item.status}-btn`}
              onClick={onFilterByStatus(item.status)}
            >
              <FilterListIcon />
            </Button>
          </StyledCell>
        )
      },
    },
  ]

  useEffect(() => {
    setIsPending(true)
    let mounted = true

    const pageParams = {
      page: 0,
      perPage: 1,
    }

    Promise.all([
      getSellerVerifications(pageParams, {
        status: SellerVerificationStatus.PENDING,
      }),
      getSellerVerifications(pageParams, {
        status: SellerVerificationStatus.NEEDS_REVIEW,
      }),
      getSellerVerifications(pageParams, {
        status: SellerVerificationStatus.VERIFIED,
      }),
    ]).then(([pending, needsReview, verified]) => {
      if (mounted) {
        setData([
          {
            status: SellerVerificationStatus.PENDING,
            count: pending.total,
          },
          {
            status: SellerVerificationStatus.NEEDS_REVIEW,
            count: needsReview.total,
          },
          {
            status: SellerVerificationStatus.VERIFIED,
            count: verified.total,
          },
        ])

        setIsPending(false)
      }
    })

    return () => {
      mounted = false
    }
  }, [])

  return (
    <StyledDisplayCard
      title="Verification Status Overview"
      isLoading={isPending}
    >
      <TabularData borderTop extraPadding fieldList={fieldList} data={data} />
    </StyledDisplayCard>
  )
}

export default VerifiedStatus
