import axios from 'axios'

import apiConfig from 'config/apiConfig'

import { Response, SingleResponse } from 'types/Response'
import { ApiToken } from 'types/ApiTokens'

export async function getApiTokens(sellerId: string): Promise<ApiToken[]> {
  const { data }: Response<ApiToken> = await axios.get(
    `${apiConfig.sms}/sellers/${sellerId}/tokens`,
  )
  return data
}

export async function createApiToken(
  sellerId: string,
  description: string,
): Promise<ApiToken> {
  const { data }: SingleResponse<ApiToken> = await axios.post(
    `${apiConfig.sms}/sellers/${sellerId}/tokens`,
    { description },
  )
  return data
}

export async function deleteApiToken(sellerId: string, tokenId: string) {
  return await axios.delete(
    `${apiConfig.sms}/sellers/${sellerId}/tokens/${tokenId}`,
  )
}
