import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as yup from 'yup'
import startCase from 'lodash/fp/startCase'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import Select from 'components/common/Select'
import WarningIcon from 'components/common/WarningIcon'

import styled from '@emotion/styled'
import { warning } from 'config/themeConfig'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { ValidationSchema, validationHandler } from 'services/validation'

import {
  currentSeller,
  currentSellerStatus,
  getEnumerationValues,
  isSellersPending,
} from 'store/selectors'

import { editSellerStatus } from 'store/seller/actionCreators'

import { EnumerationName } from 'types/Enumeration'
import { Validation } from 'types/Validation'
import { SellerStatus } from 'types/Seller'
import StoreState from 'types/state'

const StyledFlexContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(2),
}))

const StyledDefunctWarningText = styled(Typography)({
  color: warning.main,
})

const StyledDiv = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
}))

interface Props {
  isOpen: boolean
}

const validationSchema: ValidationSchema = yup.object().shape({
  status: yup.string().label('Status').required(),
})

export const EditSellerStatus = ({ isOpen }: Props) => {
  const seller = useSelector(currentSeller)
  const isPending = useSelector(isSellersPending)

  const sellerStatus = useSelector(currentSellerStatus)
  const sellerStatuses = useSelector((state: StoreState) =>
    getEnumerationValues(state, EnumerationName.SELLER_STATUS),
  )

  const dispatch = useDispatch()

  const options = sellerStatuses.map((status: string) => {
    return { status, display: startCase(status) }
  })
  const [status, setStatus] = useState<SellerStatus>(sellerStatus!)
  const [validation, setValidation] = useState<Validation>({})

  const handleInputChange = (value: SellerStatus) => {
    setStatus(value)
  }

  const handleSubmit = () => {
    const { validation: newValidation, isValid } = validationHandler(
      validationSchema,
      { status },
    )

    setValidation(newValidation)

    if (isValid && seller && status) {
      dispatch(editSellerStatus(seller.id, status))
    }
  }

  return (
    <DialogContainer
      title="Edit Partner Status"
      isOpen={isOpen}
      onSubmit={handleSubmit}
      isPending={isPending}
      maxWidth="xs"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Select
            data-testid="seller-status"
            id="seller-status"
            label="Status"
            name="status"
            isDisabled={isPending}
            value={status}
            options={options}
            keyName="display"
            valueName="status"
            onChange={handleInputChange}
            validation={validation}
          />
        </Grid>
        {status === SellerStatus.KICK_OFF && (
          <Grid item xs={12}>
            <Typography>
              Please complete all sections of information for seller before
              changing status to KICK OFF
            </Typography>
          </Grid>
        )}
        {status === SellerStatus.DEFUNCT && (
          <StyledDiv data-testid="defunct-status">
            <Grid item xs={12}>
              <Typography>
                On moving a partner to defunct, all items will be suspended,
                barcodes will be released, and the partner's portal access will
                be removed.
              </Typography>
            </Grid>
            <StyledFlexContainer>
              <WarningIcon>
                <StyledDefunctWarningText>
                  Partner status cannot be edited in the future if it is changed
                  to defunct.
                </StyledDefunctWarningText>
              </WarningIcon>
            </StyledFlexContainer>
          </StyledDiv>
        )}
      </Grid>
    </DialogContainer>
  )
}

export default EditSellerStatus
