import capitalize from 'lodash/fp/capitalize'

import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import DataList, { DataListItem } from 'components/common/DataList'
import Link from 'components/common/Link'

import { getLatestProductStatus } from 'services/itemHelper'
import { RoutePath } from 'services/NavigationHelper'

import { MarketplaceProduct, SmsProduct } from 'types/Item'

const StyledRelationship = styled(Typography)(({ theme }) => ({
  borderLeft: `1px solid #000`,
  marginLeft: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  display: 'inline',
}))

export interface Props {
  marketplaceProduct: MarketplaceProduct
  smsProduct: SmsProduct
}

export const InfoBlock = ({ marketplaceProduct, smsProduct }: Props) => {
  const { relationship_type } = marketplaceProduct
  const relationshipComponent =
    relationship_type === 'VC' ? (
      <StyledRelationship>
        <Link
          to={`/${marketplaceProduct.seller_id}${RoutePath.ALL_ITEMS}/${marketplaceProduct.parent_product_id}`}
        >
          View VAP
        </Link>
      </StyledRelationship>
    ) : undefined

  const latestProductStatus = getLatestProductStatus(smsProduct)

  const itemData: DataListItem[] = [
    { title: 'Partner SKU', value: marketplaceProduct.external_id },
    { title: 'Barcode', value: marketplaceProduct.barcode },
    { title: 'TCIN', value: marketplaceProduct.tcin },
  ]
  const statusData: DataListItem[] = [
    {
      title: 'Latest Item Data',
      value: latestProductStatus
        ? capitalize(latestProductStatus.listing_status)
        : '',
    },
    {
      title: 'Relationship',
      value: marketplaceProduct.relationship_type,
      element: relationshipComponent,
    },
    { title: 'Item Type', value: marketplaceProduct.item_type_name },
  ]

  return (
    <Grid container spacing={4} direction="row" justifyContent="flex-start">
      <Grid item>
        <DataList data={itemData} />
      </Grid>
      <Grid item>
        <DataList data={statusData} />
      </Grid>
    </Grid>
  )
}

export default InfoBlock
