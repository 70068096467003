import { useEffect, useState } from 'react'

import styled from '@emotion/styled'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import ContentSpacer from 'components/common/ContentSpacer'
import DataList from 'components/common/DataList'
import Image, { IMAGE_SRC_ERROR } from 'components/common/Image'

import {
  getAllMarketplaceProducts,
  getMarketplaceProductById,
  getSmsProduct,
} from 'services/items'

import {
  ListingStatus,
  MarketplaceProduct,
  RelationshipType,
  ProductStatus,
  SmsProduct,
} from 'types/Item'

import ItemOverview from './ItemOverview'
import VariationOverview from './VariationOverview'
import ActionButtons from '../ActionButtons'

const StyledContainer = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(1),
}))

const StyledDivider = styled(Grid)(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.grey[700]}`,
  paddingLeft: theme.spacing(2),
}))

const StyledImageBorder = styled(Grid)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
}))

const StyledImageCaption = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  textAlign: 'center',
  width: '100%',
}))

const StyledInfo = styled(Grid)(({ theme }) => ({
  border: `3px solid ${theme.palette.grey[700]}`,
  borderRadius: 2,
  marginBottom: theme.spacing(4),
  padding: theme.spacing(2),
}))

const StyledStatus = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}))

const StyledHeading = styled(Typography)({
  fontWeight: 600,
}) as typeof Typography

interface Props {
  product: MarketplaceProduct
  smsProduct: SmsProduct
  onSubmitReview: (status: ListingStatus) => void | undefined
  skipToNext: () => void
  reprocess: () => void
  isApproveDisabled: boolean
  title: string
  fallbackImage?: string
  interactionStart?: number
}

export const OverviewContent = ({
  product,
  smsProduct,
  onSubmitReview,
  isApproveDisabled,
  title,
  skipToNext,
  reprocess,
  fallbackImage,
  interactionStart,
}: Props) => {
  const {
    relationship_type,
    seller_id,
    seller_name,
    published,
    buy_url,
    validation_status,
    seller_status,
  } = product

  const [children, setChildren] = useState<MarketplaceProduct[]>([])
  const [parent, setParent] = useState(
    product.relationship_type === RelationshipType.VAP ? product : undefined,
  )
  const [parentCurrentStatus, setParentCurrentStatus] =
    useState<ProductStatus>()
  const [sampleImage, setSampleImage] = useState(IMAGE_SRC_ERROR)

  useEffect(() => {
    let unmounted = false

    const parentId =
      product.relationship_type === RelationshipType.VAP
        ? product.id
        : product.parent_product_id
    if (parentId) {
      getAllMarketplaceProducts({ parent_product_id: parentId }).then((res) => {
        const children = res.data

        if (!unmounted) {
          setChildren(children)

          if (children.length > 0 && children[0].primary_image) {
            setSampleImage(children[0].primary_image)
          } else if (fallbackImage) {
            setSampleImage(fallbackImage)
          }
        }
      })
    }

    if (product.relationship_type === RelationshipType.VC && !unmounted) {
      getSmsProduct({
        sellerId: product.seller_id,
        productId: product.parent_product_id,
        params: { expand: 'product_statuses' },
      }).then((smsProduct) => {
        setParentCurrentStatus(
          smsProduct.product_statuses?.find(
            (status) => status.current === true,
          ),
        )
      })

      getMarketplaceProductById(product.parent_product_id).then((res) =>
        setParent(res),
      )
    }

    return () => {
      unmounted = true
    }
  }, [product, fallbackImage])

  return (
    <StyledContainer container data-testid="overview-content">
      <StyledInfo item xs={12} md={9}>
        <StyledHeading data-testid="dialog-title" variant="h6" component="h2">
          {title}
        </StyledHeading>
        <ContentSpacer />
        <Grid container alignContent="space-between">
          <Grid item>
            <ItemOverview
              relationshipType={relationship_type}
              sellerId={seller_id}
              sellerName={seller_name}
              published={published}
              smsProduct={smsProduct}
              buyUrl={buy_url}
              sellerStatus={seller_status}
            />
          </Grid>
          {relationship_type !== RelationshipType.SA && parent && (
            <StyledDivider item xs={7}>
              <VariationOverview
                product={product}
                children={children}
                parent={parent}
                parentCurrentStatus={parentCurrentStatus?.listing_status}
              />
            </StyledDivider>
          )}
          {relationship_type === RelationshipType.VAP && (
            <StyledImageBorder item xs={2} data-testid="primary-image">
              <Image
                src={sampleImage}
                alt="Sample image of item family"
                data-testid="primary-image"
              />
              <StyledImageCaption>
                <Typography>Example VC Image</Typography>
              </StyledImageCaption>
            </StyledImageBorder>
          )}
        </Grid>
      </StyledInfo>
      <StyledStatus item xs={12} md={3}>
        <DataList data={[{ title: 'Status:', value: validation_status }]} />
        <ActionButtons
          validationStatus={validation_status}
          submitReview={onSubmitReview}
          isApproveDisabled={isApproveDisabled}
          skipToNext={skipToNext}
          reprocess={reprocess}
          interactionStart={interactionStart}
        />
      </StyledStatus>
    </StyledContainer>
  )
}

export default OverviewContent
