import { useEffect } from 'react'
import { connect } from 'react-redux'
import { withFormik, Form, FormikProps } from 'formik'
import * as yup from 'yup'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import DialogContainer from 'components/common/Dialog/DialogContainer'

import Link from 'components/common/Link'
import InputField from 'components/common/form/InputField'
import SelectField from 'components/common/form/SelectField'

import { RoutePath } from 'services/NavigationHelper'
import {
  createJiraIssue,
  JiraFields,
  JiraFeedbackType,
} from 'services/jiraIssues'
import { maxCharacterCount } from 'services/validation'

import { closeDialog, CloseDialogAction } from 'store/dialog/actionCreator'

import {
  currentSellerId,
  currentSellerVmmId,
  email,
  currentSellerDisplayName,
} from 'store/selectors'

import StoreState from 'types/state'

type FormValues = {
  feedbackType: string
  description: string
  email: string
}

type Status = {
  success: boolean
}

type ComponentProps = {
  sellerId: string | undefined
  vmmId: string | undefined
  sellerDisplayName: string | undefined
  userEmail: string
  isOpen: boolean
  status: Status | undefined
  closeDialog: () => CloseDialogAction
  handleSubmit: () => void
}

type Props = ComponentProps & FormikProps<FormValues>

const validationSchema = yup.object().shape({
  feedbackType: yup.string().label('This feedback is related to').required(),
  description: yup
    .string()
    .label('Describe your feedback')
    .test({
      name: 'isDescription',
      test: maxCharacterCount('description', 1000),
    })
    .required(),
  email: yup.string().label('Submitted by').required(),
})

export const feedbackTypeOptions = [
  {
    label: 'Bugs or Software Defects',
    value: JiraFeedbackType.BUGS_OR_SOFTWARE_DEFECTS,
  },
  {
    label: 'Feature Suggestions (for new or expanded capabilities)',
    value: JiraFeedbackType.FEATURE_SUGGESTIONS,
  },
  { label: 'Other', value: JiraFeedbackType.OTHER },
]

const FeedbackForm = ({
  sellerId,
  isOpen,
  isSubmitting,
  isValid,
  dirty,
  status,
  closeDialog,
  handleSubmit,
}: Props) => {
  const title = status?.success ? `Thank you` : `Send feedback about the portal`

  useEffect(() => {
    let timeout: NodeJS.Timeout

    if (status?.success) {
      timeout = setTimeout(() => {
        closeDialog()
      }, 3000)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [status, closeDialog])

  return (
    <DialogContainer
      isOpen={isOpen}
      title={title}
      isPending={isSubmitting}
      isSubmitDisabled={!isValid || !dirty}
      onSubmit={handleSubmit}
      hideActions={status?.success}
    >
      {status?.success && (
        <Typography>Your input is greatly appreciated.</Typography>
      )}
      {!status?.success && (
        <>
          <Typography sx={{ mb: 2 }}>
            Please note that this is not a way to contact suppport.
          </Typography>

          <Typography sx={{ mb: 2 }}>
            If you need help, please access the{' '}
            <Link to={`${RoutePath.HELP}`} target="_blank">
              Help Center
            </Link>{' '}
            or submit a case using{' '}
            <Link to={`/${sellerId}${RoutePath.OPEN_CASES}`} target="_blank">
              Open Cases
            </Link>{' '}
            to receive a response.
          </Typography>

          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SelectField
                  required
                  name="feedbackType"
                  options={feedbackTypeOptions}
                  keyName="label"
                  valueName="value"
                  label="This feedback is related to"
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  required
                  multiline
                  enableOnChangeValidation
                  rows="4"
                  maxCharacters={1000}
                  name="description"
                  label="Describe your feedback"
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  required
                  name="email"
                  label="Submitted By"
                  inputProps={{ 'data-testid': 'email' }}
                  disabled={true}
                />
              </Grid>
            </Grid>
          </Form>
        </>
      )}
    </DialogContainer>
  )
}

export const FeedbackDialog = withFormik<ComponentProps, FormValues>({
  mapPropsToValues: (props) => {
    return {
      feedbackType: '',
      description: '',
      email: props.userEmail,
    }
  },
  handleSubmit: async (values, { props, ...actions }) => {
    if (!props.sellerId || !props.vmmId || !props.sellerDisplayName) {
      return
    }

    const { feedbackType, description, email } = values
    const { sellerDisplayName, sellerId, vmmId } = props

    const data = {
      summary: 'Feedback from',
      sellerId,
      description,
      email,
      vmmId,
      partnerName: sellerDisplayName,
      jiraField: JiraFields.FEEDBACK_TYPE_FIELD,
      jiraFieldValue: { id: feedbackType },
    }

    await createJiraIssue(data)

    setTimeout(() => {
      actions.setStatus({ success: true })
    }, 2000)
  },
  enableReinitialize: true,
  validationSchema,
})(FeedbackForm)

const mapStateToProps = (state: StoreState) => {
  const sellerId = currentSellerId(state)
  const sellerDisplayName = currentSellerDisplayName(state)
  const vmmId = currentSellerVmmId(state)
  const userEmail = email(state)

  return {
    sellerId,
    sellerDisplayName,
    vmmId,
    userEmail,
  }
}

const mapDispatchToProps = {
  closeDialog,
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackDialog)
