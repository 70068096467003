export enum ResultingStatus {
  ERROR = 'ERROR',
  BLOCKED = 'BLOCKED',
  REVIEW = 'REVIEW',
  VALIDATED = 'VALIDATED',
}

export interface ProductValidationRule {
  id: string
  seller_id: string
  error_code: string
  must_be_on_required: boolean
  allow_new_versions: boolean
  min_score: number
  max_score: number
  resulting_status: ResultingStatus
  created: string
  created_by: string
  last_modified: string
  last_modified_by: string
}

export interface ErrorConfiguration {
  id: string
  errorCode: string
  suppressedDate: string
  suppressed: boolean
  lastModifiedBy: string
}
