import { useEffect, useState } from 'react'

import styled from '@emotion/styled'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import DialogActions from '@mui/material/DialogActions'
import { Button } from '@enterprise-ui/canvas-ui-react'

import { EditContainerProps } from './AllowedItemSettingsDialog'

import { BrandTypeahead, TaxonomyTypeahead } from 'components/common/Typeahead'

import { Brand } from 'types/Brand'
import Attribute, { BRAND, ITEM_SUBTYPE, ITEM_TYPE } from 'types/Attribute'

const StyledChip = styled(Chip)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginRight: theme.spacing(1),
}))

export const EditBrandContainer = ({
  legalization,
  primary,
  paired,
  doPrimaryChange,
  doAddPaired,
  doRemovePaired,
  doCancel,
  doSubmit,
}: EditContainerProps) => {
  const [brand, setBrand] = useState<Nullable<Brand>>()

  useEffect(() => {
    if (primary) {
      setBrand({
        brandId: primary.id,
        brandName: primary.name ? primary.name : '',
        brandDescription: primary.description,
      })
    } else {
      setBrand(null)
    }
  }, [primary])

  const handleBrandChange = (brand: Nullable<Brand>) => {
    if (brand) {
      doPrimaryChange({ id: brand.brandId, name: brand.brandName, type: BRAND })
    } else {
      doPrimaryChange(undefined)
    }
  }

  const handleAddPairedAttribute = (attribute: Nullable<Attribute>) => {
    // TODO set error if its a duplicate
    if (attribute) {
      doAddPaired(attribute)
    }
  }

  const handleRemovePairedAttribute = (attribute: Attribute) => () => {
    if (attribute) {
      doRemovePaired(attribute)
    }
  }

  const handleSubmit = () => {
    // TODO validate the legalization
    const updated = {
      id: legalization.id,
      primary_attribute: primary!,
      paired_attributes: paired,
    }

    doSubmit(updated)
  }

  const subtypes = paired.filter((attr) => attr.type === ITEM_SUBTYPE)
  const itemTypes = paired.filter((attr) => attr.type === ITEM_TYPE)

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <BrandTypeahead
                label="Enter Brand Name*"
                placeholder="Select a Brand"
                onChange={handleBrandChange}
                value={brand}
              />
            </Grid>
            <Grid item xs={12}>
              <TaxonomyTypeahead
                label="Add Allowed Subtypes *"
                placeholder="Search Subtypes"
                searchType={ITEM_SUBTYPE}
                onChange={handleAddPairedAttribute}
                clearOnSelect
              />
            </Grid>
            {subtypes.length ? (
              <Grid item xs={12}>
                {subtypes.map((attr: Attribute, idx: number) => {
                  return (
                    <StyledChip
                      key={idx}
                      label={attr.name}
                      onDelete={handleRemovePairedAttribute(attr)}
                    />
                  )
                })}
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <TaxonomyTypeahead
                label="Add Allowed Item Types *"
                placeholder="Search Item Types"
                searchType={ITEM_TYPE}
                onChange={handleAddPairedAttribute}
                clearOnSelect
              />
            </Grid>
            {itemTypes ? (
              <Grid item xs={12}>
                {itemTypes.map((attr: Attribute, idx: number) => {
                  return (
                    <StyledChip
                      key={idx}
                      label={attr.name}
                      onDelete={handleRemovePairedAttribute(attr)}
                      data-testid="item-type-chip"
                    />
                  )
                })}
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DialogActions>
            <Button
              className="hc-mr-dense hc-pr-expanded hc-pl-expanded"
              data-testid="dialog-cancel-button"
              type="secondary"
              onClick={doCancel}
            >
              Cancel
            </Button>
            <Button
              className="hc-mr-dense hc-pr-expanded hc-pl-expanded"
              data-testid="dialog-submit-button"
              type="primary"
              disabled={!primary || !paired.length}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </>
  )
}

export default EditBrandContainer
