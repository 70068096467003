import startCase from 'lodash/fp/startCase'
import Typography from '@mui/material/Typography'

import Link from 'components/common/Link'

import { RoutePath } from 'services/NavigationHelper'
import { trackCustomEvent } from 'services/fireflyInsights'
import { removeTags } from 'services/htmlHelper'

import { FireflyEvent } from 'types/FireflyInsights'

import { KnowledgeArticle, KnowledgeContent } from 'types/KnowledgeArticle'
import { createMarkup } from 'services/createMarkup'

interface Props {
  article: KnowledgeArticle
  searchTerm?: string
  category?: string
  platform?: string
}

const logFireflyEvent = (
  articleId: string,
  articleTitle: string,
  searchTerm: string | undefined,
  category: string | undefined,
) => {
  if (searchTerm) {
    trackCustomEvent(
      FireflyEvent.HELP_ARTICLE_SEARCH_CLICK,
      searchTerm,
      articleId,
      articleTitle,
    )
  } else if (category) {
    trackCustomEvent(
      FireflyEvent.HELP_ARTCILE_CATEGORY_CLICK,
      category,
      articleId,
      articleTitle,
    )
  }
}

const KnowledgeResult = ({
  article,
  searchTerm,
  category,
  platform = 'GENERAL',
}: Props) => {
  const getContent = (contents: KnowledgeContent[]) => {
    const targetContent = contents.find(
      (content) => content.platform === platform,
    )

    const taglessContent = removeTags(targetContent?.content ?? '')

    return `${taglessContent.substr(0, 200)}...`
  }

  const categoryName = category ? startCase(category) : undefined

  const handleClick = () => {
    logFireflyEvent(article.id, article.title, searchTerm, category)
  }

  return (
    <div data-testid={`article-${article.id}`}>
      <Link
        to={{
          pathname: `${RoutePath.KNOWLEDGE_ARTICLE}/${article.id}`,
        }}
        state={{
          searchTerm: searchTerm,
          category,
          categoryName,
        }}
        onClick={handleClick}
      >
        <Typography variant="h4">{article.title}</Typography>
      </Link>
      <Typography
        dangerouslySetInnerHTML={createMarkup(getContent(article.contents))}
      />
    </div>
  )
}

export default KnowledgeResult
