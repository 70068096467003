import React from 'react'

import capitalize from 'lodash/fp/capitalize'
import sortBy from 'lodash/fp/sortBy'

import Grid from '@mui/material/Grid'

import DataList from 'components/common/DataList'
import RequiredContent from 'components/common/RequiredContent'

import { militaryToPeriodTime } from 'services/dateService'
import { timezoneCode } from 'services/timezoneCode'

import { DaysOfOperation, WorkingHours } from 'types/Seller'

export const getDaysOfOperation = (workingHours?: WorkingHours[]) => {
  if (!workingHours) return

  const sorter: Dictionary<number> = {
    SUNDAY: 0,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
  }

  const data = workingHours.map(({ day, open_time, close_time }) => ({
    title: capitalize(day),
    value: `${militaryToPeriodTime(open_time)} - ${militaryToPeriodTime(
      close_time,
    )}`,
    day,
    open_time,
    close_time,
  }))

  return sortBy(({ day }) => sorter[day], data)
}

interface Props {
  daysOfOperation?: DaysOfOperation
  timezone?: string
}

export const HoursOfOperationContent = ({
  daysOfOperation,
  timezone,
}: Props) => {
  if (!daysOfOperation) return null

  const { working_hours, days_closed } = daysOfOperation

  if (!working_hours?.length && !days_closed?.length) {
    return <RequiredContent description="None provided." />
  }

  const sortedWorkingHours = getDaysOfOperation(working_hours)

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DataList
            data={[{ title: 'Time Zone:', value: timezoneCode(timezone) }]}
          />
        </Grid>
        <Grid item xs={12}>
          {sortedWorkingHours && <DataList data={sortedWorkingHours} />}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default HoursOfOperationContent
