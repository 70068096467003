import Typeahead from '.'

import { useState, useEffect, SyntheticEvent } from 'react'
import usePrevious from 'hooks/usePrevious'

import { Return, ReturnsResearch } from 'types/Orders'

import { Direction, PagingParams } from 'services/pageableHelper'
import { searchProductReturns } from 'services/productReturns'
import { getReturnsResearch } from 'services/sellerReturns'

interface Props {
  onChange: (
    value: Nullable<Return | ReturnsResearch>,
    enteredValue: string,
  ) => void
  isDisabled?: boolean
  clearOnSelect?: boolean
  value?: Nullable<Return | ReturnsResearch>
  sellerId: string | undefined
  onClear?: () => void
  returnsResearch?: boolean
}

const TrackingNumberTypeahead = ({
  onChange,
  isDisabled,
  clearOnSelect = false,
  value: valueFromProps,
  sellerId,
  onClear,
  returnsResearch,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedValue, setSelectedValue] =
    useState<Nullable<Return | ReturnsResearch>>(valueFromProps)
  const [options, setOptions] = useState<Return[] | ReturnsResearch[]>([])
  const [loading, setLoading] = useState(false)

  const prevValue = usePrevious(valueFromProps)

  useEffect(() => {
    if (prevValue !== valueFromProps) {
      setSelectedValue(valueFromProps)
    }

    if (!valueFromProps) {
      setOptions([])
    }
  }, [prevValue, valueFromProps])

  useEffect(() => {
    if (options.length && !inputValue) {
      setOptions([])
    }
  }, [inputValue, options])

  const handleInputChange = async (
    _event: SyntheticEvent,
    enteredValue: string,
    reason: string,
  ) => {
    if (
      onClear &&
      (reason === 'clear' ||
        (selectedValue && reason === 'input' && enteredValue === ''))
    ) {
      onClear()
      setOptions([])
      setInputValue('')
      setSelectedValue(null)
      return
    }

    setInputValue(enteredValue)

    const invalidInputValue =
      !enteredValue || enteredValue.length < 2 || reason === 'reset'
    if (invalidInputValue) {
      return
    }

    const paging: PagingParams = {
      page: 0,
      perPage: 20,
      direction: Direction.ASC,
      orderBy: 'return_date',
    }
    setLoading(true)
    const { data } = returnsResearch
      ? await getReturnsResearch(paging, { pro_number: enteredValue })
      : await searchProductReturns(paging, {
          tracking_number: enteredValue,
          seller_id: sellerId,
        })

    setOptions(data)
    setLoading(false)
  }

  const handleSelectedOptionChange = (
    _event: SyntheticEvent,
    value: Return | ReturnsResearch,
  ) => {
    if (!(value instanceof Array)) {
      const searchParamValue = returnsResearch
        ? getReturnsResearchOptionLabel(value as ReturnsResearch)
        : getReturnOptionLabel(value as Return)

      setSelectedValue(
        clearOnSelect ? null : (value as Nullable<Return | ReturnsResearch>),
      )
      onChange(value as Nullable<Return | ReturnsResearch>, searchParamValue)
    }
  }

  const getReturnsResearchOptionLabel = (returnItem: ReturnsResearch) =>
    returnItem?.pro_number ?? ''

  const getReturnOptionLabel = (returnItem: Return) => {
    const trackingInfo = returnItem?.tracking_data?.find((trackingItem) => {
      if (trackingItem.tracking_number) {
        return trackingItem.tracking_number.startsWith(inputValue)
      }

      return ''
    })
    return trackingInfo?.tracking_number ?? ''
  }

  return (
    <Typeahead
      aria-label="Tracking Number Filter"
      label="Tracking #, PRO#"
      placeholder="Tracking #, PRO#"
      options={options}
      loading={loading}
      getOptionLabel={
        returnsResearch ? getReturnsResearchOptionLabel : getReturnOptionLabel
      }
      onInputChange={handleInputChange}
      onChange={handleSelectedOptionChange}
      isOptionEqualToValue={(
        option: Return | ReturnsResearch,
        value: Nullable<Return | ReturnsResearch>,
      ) => option.id === value?.id}
      disabled={!!isDisabled}
      inputValue={inputValue}
      value={selectedValue}
    />
  )
}

export default TrackingNumberTypeahead
