import { Link } from 'react-router-dom'

import Button, { ButtonProps } from '@mui/material/Button'
import styled from '@emotion/styled'

const StyledLink = styled(Link)({
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
  },
})

interface Props extends ButtonProps {
  to: string
  target?: string
  rel?: string
  color?: 'inherit' | 'primary' | 'secondary'
  children: any
}

export const LinkButton = ({
  to,
  target,
  rel,
  color,
  children,
  ...restProps
}: Props) => {
  return (
    <StyledLink to={to} target={target} rel={rel}>
      <Button {...restProps} color={color}>
        {children}
      </Button>
    </StyledLink>
  )
}

export default LinkButton
