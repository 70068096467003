import React from 'react'

import styled from '@emotion/styled'

import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import LaunchIcon from '@mui/icons-material/Launch'

import DisplayCard from 'components/common/DisplayCard'
import Image from 'components/common/Image'

import {
  backgroundGrey,
  grey,
  primary,
  success,
  warning,
} from 'config/themeConfig'

import { SUSPENSION_CODES } from 'constants/suspendErrorCodes'

import { formatDate, DATE_FORMAT_MONTH_DAY_YEAR } from 'services/dateService'
import { getCurrentProductStatus } from 'services/itemHelper'

import {
  MarketplaceProduct,
  SmsProduct,
  ListingStatus,
  RelationshipType,
} from 'types/Item'

import InfoBlock from './InfoBlock'
import PriceBlock from './PriceBlock'

const StyledLaunchIcon = styled(LaunchIcon)({
  color: primary.main,
  verticalAlign: 'middle',
})

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  color: grey[300],
}))

const StyledItemTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  paddingBottom: theme.spacing(2),
})) as typeof Typography

const StyledPriceBlock = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(1),
}))

const StyledImageContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'bgColor',
})<{ bgColor: string }>((props) => ({
  backgroundColor: props.bgColor,
  borderRadius: '0 0 4px 4px',
  padding: '16px 16px 12px 16px',
  maxWidth: '200px',
}))

const StyledImageStatus = styled(Typography)({
  color: 'white',
  borderRadius: '4px 4px 0 0',
  fontWeight: 600,
  maxWidth: '200px',
}) as typeof Typography

export interface Props {
  marketplaceProduct: MarketplaceProduct
  smsProduct: SmsProduct
  showSuspendButton: boolean
  showUnsuspendButton: boolean
  showRejectButton: boolean
  onClick: (
    listingStatus: ListingStatus,
  ) => (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const ItemDetailsContent = ({
  marketplaceProduct,
  smsProduct,
  showSuspendButton,
  showUnsuspendButton,
  showRejectButton,
  onClick,
}: Props) => {
  let status: string
  let statusColor: string
  let containerColor: string

  let itemStatusMessage: React.ReactNode

  const currentProductStatus = getCurrentProductStatus(smsProduct)

  const lastModifiedDate = currentProductStatus
    ? `${formatDate(
        currentProductStatus.last_modified,
        DATE_FORMAT_MONTH_DAY_YEAR,
      )}`
    : ''
  if (marketplaceProduct.published) {
    status = 'published'
    statusColor = success.main
    containerColor = success.light

    itemStatusMessage = (
      <div>
        <Typography color={primary.main}>
          <a
            href={marketplaceProduct.buy_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Published on {lastModifiedDate} <StyledLaunchIcon />
          </a>
        </Typography>
      </div>
    )
  } else if (
    marketplaceProduct.current_status?.listing_status ===
    ListingStatus.SUSPENDED
  ) {
    status = 'suspended'
    statusColor = warning.main
    containerColor = warning.light

    const suspendErrorCodes = SUSPENSION_CODES.map((code) => code.error_code)
    let suspensionReason =
      currentProductStatus?.errors.find((error) =>
        suspendErrorCodes.includes(error?.error_code ?? 0),
      )?.reason ?? ''
    suspensionReason = suspensionReason.replace(/^Suspended: /, '')

    itemStatusMessage = (
      <div>
        <Typography color={grey[700]}>
          Suspended on {lastModifiedDate}
          {suspensionReason && `: ${suspensionReason}`}
        </Typography>
      </div>
    )
  } else if (
    marketplaceProduct.current_status?.listing_status === ListingStatus.UNLISTED
  ) {
    status = 'unlisted'
    statusColor = grey[900]
    containerColor = grey[300]

    itemStatusMessage = (
      <div>
        <Typography color={grey[700]}>Not Published on Target.com</Typography>
      </div>
    )
  } else {
    status = 'not published'
    statusColor = grey[500]
    containerColor = backgroundGrey.main

    itemStatusMessage = (
      <div>
        <Typography color={grey[700]}>Not Published on Target.com</Typography>
      </div>
    )
  }

  const actions = (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={2} lg={1}>
        {/* These two buttons are mutually exclusive */}
        {showSuspendButton && (
          <Button
            data-testid="suspend-button"
            color="primary"
            onClick={onClick(ListingStatus.SUSPENDED)}
          >
            Suspend
          </Button>
        )}
        {showUnsuspendButton && (
          <Button
            data-testid="unsuspend-button"
            color="primary"
            onClick={onClick(ListingStatus.REJECTED)}
          >
            Unsuspend
          </Button>
        )}
      </Grid>
      <Grid item xs={12} sm={2} lg={1}>
        {showRejectButton && (
          <Button
            data-testid="reject-button"
            color="primary"
            onClick={onClick(ListingStatus.REJECTED)}
          >
            Reject
          </Button>
        )}
      </Grid>
    </Grid>
  )

  return (
    <DisplayCard
      title=""
      hasHeader={false}
      actions={actions}
      actionsStyles={{
        borderTop: `1px solid ${grey[300]}`,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <StyledImageStatus
            variant="overline"
            component="div"
            align="center"
            data-testid="image-title"
            sx={{ backgroundColor: statusColor }}
          >
            {status}
          </StyledImageStatus>
          <StyledImageContainer bgColor={containerColor}>
            <Image
              src={marketplaceProduct.primary_image}
              alt={`Image of ${marketplaceProduct.title}`}
            />
          </StyledImageContainer>
        </Grid>
        <Grid item xs={10}>
          <StyledItemTitle variant="h4" component="h2">
            {marketplaceProduct.title}
          </StyledItemTitle>
          {itemStatusMessage}
          <StyledDivider variant="fullWidth" />
          <InfoBlock
            marketplaceProduct={marketplaceProduct}
            smsProduct={smsProduct}
          />
        </Grid>
        <StyledPriceBlock item xs={12}>
          <Divider variant="fullWidth" />
          {smsProduct.relationship_type !== RelationshipType.VAP && (
            <PriceBlock smsProduct={smsProduct} itemOverview={false} />
          )}
        </StyledPriceBlock>
      </Grid>
    </DisplayCard>
  )
}

export default ItemDetailsContent
