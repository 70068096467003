import { saveAs } from 'file-saver'

export const openFileNewTab = (blob: Blob) => {
  const fileObjectURL = URL.createObjectURL(blob)
  window.open(fileObjectURL)
  URL.revokeObjectURL(fileObjectURL)
}

export const saveFile = ({
  data,
  name,
  type = 'text/csv',
}: {
  data: any
  name: string
  type?: string
}) => {
  saveAs(new Blob([data], { type }), name)
}
