import React, { PropsWithChildren } from 'react'
import styled from '@emotion/styled'

const StyledSpacer = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(4),
}))

export const ContentSpacer: React.FC<PropsWithChildren<any>> = ({
  children,
}) => {
  return <StyledSpacer>{children}</StyledSpacer>
}

export default ContentSpacer
