import { Box } from '@mui/material'
import { ReactNode } from 'react'

export const TabPanel = ({
  children,
  value,
  selectedValue,
}: {
  children: ReactNode
  value: any
  selectedValue: any
}) => (
  <div
    role="tabpanel"
    hidden={value !== selectedValue}
    id={`tabpanel-${value}`}
    aria-labelledby={`tab-${value}`}
  >
    {value === selectedValue && <Box p={2}>{children}</Box>}
  </div>
)
