import axios from 'axios'

import apiConfig from 'config/apiConfig'

import { BarcodeInfo } from 'types/Barcodes'

export const getBarcodeDetails = async (
  barcode: string,
): Promise<BarcodeInfo> => {
  const config = {
    params: {},
  }

  const response = await axios.get(
    `${apiConfig.barcodeValidation}/details/${barcode}`,
    config,
  )

  return response.data
}
