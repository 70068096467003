import { Cancel, Check, Search } from '@mui/icons-material'
import {
  styled,
  Autocomplete,
  TextField,
  Popper,
  TextFieldProps,
} from '@mui/material'

import { white, filterChip } from 'config/themeConfig'

import AddressTypeahead from './AddressTypeahead'
import AttributeValueTypeahead from './AttributeValueTypeahead'
import BillOfLadingTypeahead from './BillOfLadingTypeahead'
import BrandTypeahead from './BrandTypeahead'
import CaseTypeahead from './CaseTypeahead'
import DivisionTypeahead from './DivisionTypeahead'
import ErrorCodeTypeahead from './ErrorCodeTypeahead'
import HeliosItemTypeahead from './HeliosItemTypeahead'
import ItemTypeahead from './ItemTypeahead'
import LicensePlateTypeahead from './LicensePlateTypeahead'
import MerchTypeAttributeTypeahead from './MerchTypeAttributeTypeahead'
import OmniTypeahead from './OmniTypeahead'
import ProductReturnTypeahead from './ProductReturnTypeahead'
import PromotionTypeahead from './PromotionTypeahead'
import SellerTypeahead from './SellerTypeahead'
import ShipmentIdTypeahead from './ShipmentIdTypeahead'
import StateCodeTypeahead from './StateCodeTypeahead'
import StaticTypeahead from './StaticTypeahead'
import StoreNumberTypeahead from './StoreNumberTypeahead'
import TaxonomyTypeahead from './TaxonomyTypeahead'
import TrackingNumberTypeahead from './TrackingNumberTypeahead'

const StyledAutocomplete = styled(Autocomplete)((props) => ({
  backgroundColor: props.value ? filterChip.main : white.main,
  borderRadius: '4px',
  '& button.MuiButtonBase-root': {
    visibility: 'visible',
  },
  '.MuiAutocomplete-endAdornment': {
    top: 'auto',
    bottom: 'auto',
  },
}))

const StyledPopper = styled(Popper)(() => ({
  '.MuiAutocomplete-paper': {
    fontSize: '14px',
  },
}))

interface TypeaheadProps {
  getOptionLabel: (option: any) => string
  onChange: (event: React.SyntheticEvent, value: any, reason: string) => void
  onInputChange?: (
    event: React.SyntheticEvent,
    value: string,
    reason: string,
  ) => void
  isOptionEqualToValue?: (option: any, value: any) => boolean
  getChip?: (option: any, index: number) => JSX.Element
  placeholder?: string
  options: Array<any>
  disabled?: boolean
  inputValue: string
  loading?: boolean
  label?: string
  error?: string
  helperText?: string
  noResultsMessage?: string
  noOptionsText?: string
  id?: string
  value?: any
  disableClearable?: boolean
  multiple?: boolean
  filterOptions?: any
  filterSelectedOptions?: boolean
  renderOption?: any
  groupBy?: any
  freeSolo?: boolean
  hideStartAdornment?: boolean
  variant?: TextFieldProps['variant']
  displayLabel?: boolean
  blurOnSelect?: boolean
}

const Typeahead = ({
  hideStartAdornment,
  displayLabel,
  ...props
}: TypeaheadProps) => {
  const getStartAdornment = () => {
    let startAdornment = <Search color="primary" />
    if (!props.multiple) {
      startAdornment = props.value ? (
        <Check color="primary" />
      ) : (
        <Search color={props.disabled ? 'disabled' : 'primary'} />
      )
    }
    return startAdornment
  }

  return (
    <StyledAutocomplete
      {...props}
      autoComplete
      autoHighlight
      blurOnSelect={
        typeof props.blurOnSelect === 'boolean' ? props.blurOnSelect : true
      }
      disabled={props.disabled}
      options={props.options}
      loading={props.loading}
      noOptionsText={props.noOptionsText ?? 'No results found.'}
      forcePopupIcon={false}
      clearIcon={<Cancel color="primary" />}
      PopperComponent={StyledPopper}
      multiple={props.multiple}
      disableClearable={props.disableClearable}
      sx={{ backgroundColor: props.multiple ? white.main : undefined }}
      filterOptions={props.filterOptions ?? undefined}
      renderOption={props.renderOption ?? undefined}
      renderInput={(params) => (
        <TextField
          {...params}
          value={props.inputValue}
          label={displayLabel && props.label ? props.label : null}
          placeholder={props.placeholder}
          data-testid="typeahead-container"
          variant={props.variant ?? 'outlined'}
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            style: {
              fontSize: 14,
            },
            startAdornment: hideStartAdornment ? null : getStartAdornment(),
          }}
        />
      )}
    />
  )
}

export {
  AddressTypeahead,
  AttributeValueTypeahead,
  BillOfLadingTypeahead,
  BrandTypeahead,
  CaseTypeahead,
  DivisionTypeahead,
  ErrorCodeTypeahead,
  HeliosItemTypeahead,
  ItemTypeahead,
  LicensePlateTypeahead,
  MerchTypeAttributeTypeahead,
  OmniTypeahead,
  ProductReturnTypeahead,
  PromotionTypeahead,
  SellerTypeahead,
  ShipmentIdTypeahead,
  StateCodeTypeahead,
  StaticTypeahead,
  StoreNumberTypeahead,
  TaxonomyTypeahead,
  TrackingNumberTypeahead,
}

export default Typeahead
