import { AuthConfig } from 'types/AuthConfig'

interface FullAuthConfig {
  dev: AuthConfig
  stage: AuthConfig
  prod: AuthConfig
}

const getAuthConfig = (
  isExternal: boolean,
  mockServer: string,
): FullAuthConfig => {
  const commonAuth: any = {
    responseType: 'token id_token',
    scope: ['openid profile email'],
    storageType: 'localStorage',
    tokenType: 'Bearer',
    prompt: 'login',
    nonce: '1234',
    logoutPath: '/login/responses/logoff.html',
  }

  // TODO: Switch back when POL login is figured out
  // const authDomain = isExternal ? 'partnersonline' : 'target'
  const authDomain = 'target'

  const envAuthConfig: any = {
    stage: {
      ...commonAuth,
      authHost: `${mockServer}https://oauth.iam.perf.${authDomain}.com`,
      logoutHost: `${mockServer}https://logonservices.iam.perf.target.com`,
      roles: [
        'APP-OAUTH2-MKP-ACQUISITIONS-STG',
        'APP-OAUTH2-MKP-ITEMMANAGER-STG',
        'APP-OAUTH2-MKP-DIGITALEXPSUP-STG',
        'APP-OAUTH2-MKP-ITEMAPPROVER-STG',
        'APP-OAUTH2-MKP-OPS-STG',
        'APP-OAUTH2-MKP-PROGRAMMGR-STG',
        'APP-OAUTH2-MKP-COMMS-STG',
        'APP-OAUTH2-MKP-REPORTING-STG',
        'APP-OAUTH2-MKP-PRODUCTADMIN-STG',
        'APP-OAUTH2-MKP-FINANCE-STG',
        'APP-SMS',
        'APP-SMS-READ',
        'APP-SMS-ADMIN',
      ],
    },
    prod: {
      ...commonAuth,
      authHost: `${mockServer}https://oauth.iam.${authDomain}.com`,
      logoutHost: `${mockServer}https://logonservices.iam.target.com`,
      roles: [
        'APP-OAUTH2-MKP-ACQUISITIONS',
        'APP-OAUTH2-MKP-ITEMMANAGER',
        'APP-OAUTH2-MKP-DIGITALEXPSUP',
        'APP-OAUTH2-MKP-ITEMAPPROVER',
        'APP-OAUTH2-MKP-OPS',
        'APP-OAUTH2-MKP-PROGRAMMGR',
        'APP-OAUTH2-MKP-COMMUNICATIONS',
        'APP-OAUTH2-MKP-REPORTING',
        'APP-OAUTH2-MKP-PRODUCTADMIN',
        'APP-OAUTH2-MKP-FINANCE',
        'APP-SMS',
        'APP-SMS-READ',
        'APP-SMS-ADMIN',
      ],
    },
  }
  const commonMoAuth: any = {
    ...envAuthConfig.stage,
    authPath: '/auth/oauth/v2/authorize',
    clientId: 'marketplaceops_npe_im',
    redirectUri: `${window.location.origin}`,
    basename: '',
  }
  const moAuthConfig: any = {
    dev: commonMoAuth,
    stage: commonMoAuth,
    prod: {
      ...commonMoAuth,
      ...envAuthConfig.prod,
      clientId: 'marketplaceops_prod_im',
    },
  }

  const commonPortalAuth: any = {
    ...envAuthConfig.stage,
    // TODO: Switch back when POL login is figured out
    // authPath: '/auth/oauth/v2/tgt/authorize/we2w/1',
    authPath: '/auth/oauth/v2/tgt/authorize/pol/1',
    clientId: 'partnerportal_npe_im',
    redirectUri: `${window.location.origin}`,
    basename: '',
  }
  const portalAuthConfig: any = {
    dev: commonPortalAuth,
    stage: commonPortalAuth,
    prod: {
      ...commonPortalAuth,
      ...envAuthConfig.prod,
      clientId: 'partnerportal_prod_im',
    },
  }

  return isExternal ? portalAuthConfig : moAuthConfig
}

export default getAuthConfig
