import { useReducer, useEffect, useCallback } from 'react'

import { isAttribute, isSmsSeller } from 'types/Guards'

export type Value = string | string[] | undefined
export type RequiredProps = {
  id: string
}

type Status = 'IDLE' | 'LOADING' | 'DONE' | 'ERROR'

interface TogglePopper {
  readonly type: 'TOGGLE_POPPER'
  payload: {
    isOpen: boolean
  }
}

interface SetStatus {
  readonly type: 'SET_STATUS'
  payload: {
    status: Status
  }
}

interface SetData<T> {
  readonly type: 'SET_DATA'
  payload: {
    data: T[]
  }
}

interface AddItem<T> {
  readonly type: 'ADD_ITEM'
  payload: {
    item: T
  }
}

interface RemoveItem<T> {
  readonly type: 'REMOVE_ITEM'
  payload: {
    item: T
  }
}

function togglePopper(isOpen: boolean): TogglePopper {
  return {
    type: 'TOGGLE_POPPER',
    payload: {
      isOpen,
    },
  }
}

function setStatus(status: Status): SetStatus {
  return {
    type: 'SET_STATUS',
    payload: {
      status,
    },
  }
}

function setData<T>(data: T[]): SetData<T> {
  return {
    type: 'SET_DATA',
    payload: {
      data,
    },
  }
}

function addItem<T>(item: T): AddItem<T> {
  return {
    type: 'ADD_ITEM',
    payload: {
      item,
    },
  }
}

function removeItem<T>(item: T): RemoveItem<T> {
  return {
    type: 'REMOVE_ITEM',
    payload: {
      item,
    },
  }
}

type Action<T> =
  | TogglePopper
  | SetStatus
  | SetData<T>
  | AddItem<T>
  | RemoveItem<T>

export type State<T> = {
  open: boolean
  data: T[]
  status: Status
  isLoading: boolean
  isError: boolean
}

function createReducer<T>() {
  return function (state: State<T>, action: Action<T>): State<T> {
    switch (action.type) {
      case 'TOGGLE_POPPER': {
        return {
          ...state,
          open: action.payload.isOpen,
        }
      }
      case 'SET_STATUS': {
        const status = action.payload.status
        return {
          ...state,
          isLoading: status === 'LOADING' ? true : false,
          isError: status === 'ERROR' ? true : false,
          status: action.payload.status,
        }
      }
      case 'SET_DATA': {
        return {
          ...state,
          data: action.payload.data,
        }
      }
      case 'ADD_ITEM': {
        return {
          ...state,
          data: [...state.data, action.payload.item],
        }
      }
      case 'REMOVE_ITEM': {
        const payloadItem = action.payload.item
        return {
          ...state,
          data: state.data.filter((item) => {
            if (
              (isSmsSeller(item) || isAttribute(item)) &&
              (isSmsSeller(payloadItem) || isAttribute(payloadItem))
            ) {
              return item.id !== payloadItem.id
            }

            return true
          }),
        }
      }
      default: {
        return state
      }
    }
  }
}

export function useMultiSearch<T extends RequiredProps>(
  value: Value,
  funcAsync: (id: string) => Promise<T | undefined>,
) {
  const reducer = createReducer<T>()
  const [state, dispatch] = useReducer(reducer, {
    open: false,
    data: [],
    status: 'IDLE',
    isLoading: false,
    isError: false,
  })

  const dispatchTogglePopper = useCallback((isOpen: boolean) => {
    dispatch(togglePopper(isOpen))
  }, [])
  const dispatchSetStatus = useCallback((status: Status) => {
    dispatch(setStatus(status))
  }, [])
  const dispatchSetData = useCallback((data: T[]) => {
    dispatch(setData(data))
  }, [])
  const dispatchAddItem = useCallback((item: T) => {
    dispatch(addItem(item))
  }, [])
  const dispatchRemoveItem = useCallback((item: T) => {
    dispatch(removeItem(item))
  }, [])

  useEffect(() => {
    let mounted = true

    let list: string[] = []

    if (Array.isArray(value)) {
      list = value
    } else if (typeof value === 'string') {
      list = [value]
    }

    if (list.length && state.status === 'LOADING') {
      Promise.all(list.map((id) => funcAsync(id)))
        .then((res) => {
          if (mounted) {
            const data = res.filter((i) => i)

            dispatch(setData(data as T[]))
            dispatch(setStatus('DONE'))
          }
        })
        .catch(() => {
          dispatch(setStatus('ERROR'))
        })
    }

    return () => {
      mounted = false
    }
  }, [funcAsync, state.status, value, state.data.length])

  return {
    ...state,
    dispatchTogglePopper,
    dispatchSetStatus,
    dispatchSetData,
    dispatchAddItem,
    dispatchRemoveItem,
  }
}
