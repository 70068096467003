import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { DataListItem } from '../DataList'

const StyledGrid = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'hasBorder',
})<{ hasBorder: boolean }>(
  ({ theme }) => ({
    color: theme.palette.grey[700],
    textAlign: 'center',
  }),
  (props) => ({
    borderRight: props.hasBorder
      ? `1px solid ${props.theme.palette.grey[300]}`
      : undefined,
  }),
)

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  marginBottom: theme.spacing(2),
}))

export interface Props {
  data: DataListItem[]
  tileSize?: 1 | 2 | 3 | 4 | 6 | 12
}

export const DataTiles = ({ data, tileSize = 4 }: Props) => {
  return (
    <Grid container spacing={2}>
      {data.map((metric, index) => {
        return (
          <StyledGrid
            item
            hasBorder={index !== data.length - 1}
            key={index}
            sm={tileSize}
          >
            <StyledTypography data-testid="metadata-item">
              {metric.title}
            </StyledTypography>
            <Typography variant="h2" data-testid="metadata-item-element">
              {metric?.element ?? metric?.value ?? ''}
            </Typography>
          </StyledGrid>
        )
      })}
    </Grid>
  )
}

export default DataTiles
