import React, { PropsWithChildren } from 'react'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

import styled from '@emotion/styled'

const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: `0 0 10px 3px ${theme.palette.grey[100]}`,
  margin: theme.spacing(1.5),
}))

export const RaisedCard: React.FC<PropsWithChildren<any>> = ({
  children,
  ...restProps
}) => {
  return (
    <StyledCard {...restProps}>
      <CardContent>{children}</CardContent>
    </StyledCard>
  )
}

export default RaisedCard
