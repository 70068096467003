import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { getReturnCenters } from 'store/selectors'

import styled from '@emotion/styled'

import { Grid, Typography } from '@mui/material'

import DataList, { DataListItem } from 'components/common/DataList'
import TrackingNumberLink from 'components/common/TrackingNumberLink'
import Stepper from 'components/common/Stepper'

import {
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
  formatDate,
} from 'services/dateService'

import { ReturnCenter } from 'types/Enumeration'
import { InboundTrackingData, Return, ReturnTrackingData } from 'types/Orders'
import { StoreLocation } from 'types/Locations'
import { ReturnCenters } from 'constants/returnCenters'

interface Props {
  returnOrder: Partial<Return>
  returnTracking?: ReturnTrackingData
  returnLocation?: StoreLocation
}

enum ReturnTrackingStep {
  RETURN_INITIATED = 'Return Initiated',
  SHIPPED_TO_PARTNER = 'Shipped to Partner',
  SHIPPED_TO_RETURN_CENTER = 'Shipped to Returns Center',
  RECEIVED_BY_RETURNS_CENTER = 'Received by Returns Center',
  DESTROYED = 'Destroyed',
}

const StyledTypography = styled(Typography)(() => ({
  fontWeight: 500,
}))

const StyledGrid = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(1, 0, 1, 2),
  padding: theme.spacing(2, 0, 2, 0),
  borderRadius: theme.spacing(0.5),
  backgroundColor: theme.palette.grey[200],
}))

const StyledStepper = styled(Stepper)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
}))

const getTrackingNumberContent = (
  tracking: ReturnTrackingData,
  isOnline: boolean | undefined,
): ReactNode => {
  if (tracking.tracking_number) {
    if (isOnline) {
      if (tracking.ship_date) {
        return (
          <TrackingNumberLink
            trackingNumber={tracking.tracking_number}
            scac={tracking.scac}
          />
        )
      } else {
        return
      }
    } else {
      return (
        <TrackingNumberLink
          trackingNumber={tracking.tracking_number}
          scac={tracking.scac}
        />
      )
    }
  }
}

const getReturnCenter = (
  returnLocationId: number,
  returnLocations: ReturnCenter[],
) => {
  return returnLocations.find(
    (returnLocation) => returnLocation.properties.id === returnLocationId,
  )
}

const ReturnStepper = ({
  returnOrder,
  returnTracking,
  returnLocation,
}: Props) => {
  const returnCenters = useSelector(getReturnCenters)
  const inboundTracking = returnOrder.inbound_tracking_data
  const returnCenterLocation = returnTracking?.receiving_location_id

  const orderDestroyed =
    returnOrder.physical_disposition === 'Destroy' ||
    returnTracking?.store_physical_disposition === 'DEST'

  const hasShippedToReturnCenterStep =
    returnOrder.is_online &&
    ((inboundTracking?.inbound_ship_date &&
      inboundTracking?.inbound_ship_date !== returnTracking?.ship_date) ||
      returnCenterLocation === ReturnCenters.IRC ||
      returnCenterLocation === ReturnCenters.URC) &&
    !orderDestroyed

  const makeStep = (
    title: ReturnTrackingStep,
    date: string = '',
    trackingNumber: string = '',
    scac: string = '',
  ) => {
    return (
      <div>
        <StyledTypography style={{ fontWeight: 700 }}>{title}</StyledTypography>
        <Typography data-testid={`ship-date-${title}`}>
          {formatDate(date, DATE_FORMAT_MONTH_DAY_YEAR_TIME)}
        </Typography>
        {trackingNumber && !orderDestroyed && (
          <Typography>
            <TrackingNumberLink trackingNumber={trackingNumber} scac={scac} />
          </Typography>
        )}
        {scac && <Typography>{`${scac} (Carrier)`}</Typography>}
        {returnLocation && title === ReturnTrackingStep.RETURN_INITIATED && (
          <Typography>
            {returnLocation.address[0].city}, {returnLocation.address[0].region}
          </Typography>
        )}
      </div>
    )
  }

  const createSteps = (
    returnOrder: Partial<Return>,
    tracking?: ReturnTrackingData,
    inboundTracking?: InboundTrackingData,
  ) => {
    let currentStep = 0
    const steps = [
      makeStep(ReturnTrackingStep.RETURN_INITIATED, returnOrder.return_date),
      makeStep(
        ReturnTrackingStep.SHIPPED_TO_PARTNER,
        tracking?.ship_date,
        tracking?.tracking_number,
        tracking?.scac,
      ),
    ]

    if (hasShippedToReturnCenterStep) {
      const shipDate = inboundTracking?.inbound_ship_date
      const trackingNumber = inboundTracking?.inbound_tracking_number
      const scac = inboundTracking?.inbound_scac

      steps.splice(
        1,
        0,
        makeStep(
          ReturnTrackingStep.SHIPPED_TO_RETURN_CENTER,
          shipDate,
          trackingNumber,
          scac,
        ),
      )

      if (inboundTracking && inboundTracking.inbound_ship_date) {
        currentStep = 1
      } else {
        currentStep = 0
      }
    }

    if (orderDestroyed) {
      if (returnOrder.is_online && tracking) {
        steps.splice(
          1,
          1,
          makeStep(ReturnTrackingStep.DESTROYED, tracking.ship_date),
        )
      } else {
        steps.splice(1, 1, makeStep(ReturnTrackingStep.DESTROYED))
      }

      currentStep = 2
    }
    if (
      hasShippedToReturnCenterStep ||
      (!orderDestroyed && (tracking?.crc_received || !returnOrder.is_online))
    ) {
      steps.splice(
        hasShippedToReturnCenterStep ? 2 : 1,
        0,
        makeStep(
          ReturnTrackingStep.RECEIVED_BY_RETURNS_CENTER,
          tracking?.crc_received,
        ),
      )
    }

    if (tracking?.ship_date) {
      currentStep = steps.length
    } else if (tracking?.crc_received) {
      currentStep = hasShippedToReturnCenterStep ? 2 : 1
    }

    return { steps, currentStep }
  }

  const { steps, currentStep } = createSteps(
    returnOrder,
    returnTracking,
    inboundTracking,
  )

  const trackingNumberContent = returnTracking
    ? getTrackingNumberContent(returnTracking, returnOrder.is_online)
    : undefined
  const scac = returnTracking?.scac ?? undefined
  const licensePlate = returnTracking?.license_plate ?? undefined
  const shipmentId = returnTracking?.shipment_id ?? undefined

  let dataList: DataListItem[] = orderDestroyed
    ? [
        {
          title: 'Tracking Number: ',
          element: trackingNumberContent ?? 'N/A',
        },
        { title: 'SCAC (Carrier): ', value: scac ?? 'N/A' },
      ]
    : []

  if (!returnOrder.is_online) {
    dataList.push({
      title: 'License Plate Number: ',
      value: licensePlate ?? 'N/A',
    })
  }

  if (shipmentId)
    dataList.unshift({ title: 'Shipment ID: ', value: shipmentId })

  if (returnTracking?.receiving_location_id) {
    const returnCenter = getReturnCenter(
      returnTracking.receiving_location_id,
      returnCenters,
    )

    if (returnCenter) {
      dataList.push({
        title: 'Returns Center Location: ',
        value: returnCenter.properties.location,
      })
    }
  }

  return (
    <StyledGrid
      item
      xs={12}
      container
      spacing={1}
      alignItems="center"
      data-testid="return-stepper"
    >
      <Grid item xs={8}>
        <StyledStepper steps={steps} currentStep={currentStep} />
      </Grid>
      <Grid item xs={4}>
        <DataList data={dataList} />
      </Grid>
    </StyledGrid>
  )
}

export default ReturnStepper
