import { Direction } from 'services/pageableHelper'

export const sortRowsByColumnKey = (
  arr: any[],
  key: string,
  direction: Direction,
): any[] => {
  let newArr = arr.slice()

  newArr.sort((a, b) => {
    const valA = a[key]
    const valB = b[key]

    if (valA === null && valB === null) return 0
    if (valA === null) return direction === 'asc' ? 1 : -1
    if (valB === null) return direction === 'asc' ? -1 : 1

    if (typeof valA === 'number' && typeof valB === 'number')
      return direction === 'asc' ? valA - valB : valB - valA

    if (typeof valA === 'string' && typeof valB === 'string')
      return direction === 'asc'
        ? valA.localeCompare(valB)
        : valB.localeCompare(valA)

    return 0
  })
  return newArr
}
