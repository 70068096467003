import { EnhancedTableFieldType } from 'components/common/EnhancedTable'
import {
  formatDate,
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
} from 'services/dateService'
import { BRAND, ITEM_SUBTYPE, ITEM_TYPE } from 'types/Attribute'
import { Legalization } from 'types/Legalization'

export interface AllowedItemTypeProps {
  type: typeof BRAND | typeof ITEM_TYPE | typeof ITEM_SUBTYPE
  title: string
  perPage: number
  orderBy: string
  subtitle: string
  fieldList: EnhancedTableFieldType<Legalization>[]
}

export const getItemTypeAllowedListType = ({
  isInternal,
}: {
  isInternal: boolean
}): AllowedItemTypeProps => {
  return {
    type: ITEM_TYPE,
    title: 'Item Types',
    subtitle: `Item types are the lowest level within Target's item taxonomy, and are best used to authorize a partner for specific items.`,
    orderBy: 'itemTypes',
    perPage: 10,
    fieldList: [
      {
        key: 'itemTypes',
        heading: 'Item Type',
        hasSort: true,
        minWidth: 20,
      },
      {
        key: 'brands',
        heading: 'Brands',
        hasSort: true,
        minWidth: 40,
      },
      {
        key: 'last_modified',
        heading: 'Last Modified',
        minWidth: 40,
        formatCell: ({ id }, context: Legalization[]) => {
          const legalization = context.find((c) => c.id === id)

          if (!legalization) return ''

          return formatDate(
            legalization.last_modified,
            DATE_FORMAT_MONTH_DAY_YEAR_TIME,
          )
        },
      },
      ...(isInternal
        ? [
            {
              key: 'last_modified_by',
              heading: 'Last Modified By (Internal)',
              minWidth: 40,
              formatCell: (item: Legalization, context: Legalization[]) => {
                const legalization = context.find((c) => c.id === item.id)

                if (!legalization) return ''

                return legalization.last_modified_by
              },
            },
          ]
        : []),
    ],
  }
}

export const getSubtypeAllowedListType = ({
  isInternal,
}: {
  isInternal: boolean
}): AllowedItemTypeProps => {
  return {
    type: ITEM_SUBTYPE,
    title: 'Product Subtypes',
    subtitle: `Product subtypes are a higher level concept within Target's item taxonomy, and are best used to authorize a partner for many item types at once.`,
    orderBy: 'subtypes',
    perPage: 10,
    fieldList: [
      {
        key: 'subtypes',
        heading: 'Subtype',
        hasSort: true,
        minWidth: 20,
      },
      {
        key: 'brands',
        heading: 'Brands',
        hasSort: true,
        minWidth: 40,
      },
      {
        key: 'last_modified',
        heading: 'Last Modified',
        minWidth: 40,
        formatCell: (item, context: Legalization[]) => {
          const legalization = context.find((c) => c.id === item.id)

          if (!legalization) return ''

          return formatDate(
            legalization.last_modified,
            DATE_FORMAT_MONTH_DAY_YEAR_TIME,
          )
        },
      },
      ...(isInternal
        ? [
            {
              key: 'last_modified_by',
              heading: 'Last Modified By (Internal)',
              minWidth: 40,
              formatCell: (item: Legalization, context: Legalization[]) => {
                const legalization = context.find((c) => c.id === item.id)

                if (!legalization) return ''

                return legalization.last_modified_by
              },
            },
          ]
        : []),
    ],
  }
}

export const getBrandAllowedListType = ({
  isInternal,
}: {
  isInternal: boolean
}): AllowedItemTypeProps => {
  return {
    type: BRAND,
    title: 'Brands',
    subtitle:
      'Brand rules must also contain either subtype or item type rules as secondary conditions',
    orderBy: 'brands',
    perPage: 5,
    fieldList: [
      {
        key: 'brands',
        heading: 'Brand',
        hasSort: true,
      },
      {
        key: 'subtypes',
        heading: 'Subtypes',
        hasSort: true,
      },
      {
        key: 'itemTypes',
        heading: 'Item Types',
        hasSort: true,
      },
      {
        key: 'last_modified',
        heading: 'Last Modified',
        formatCell: (item, context: Legalization[]) => {
          const legalization = context.find((c) => c.id === item.id)

          if (!legalization) return ''

          return formatDate(
            legalization.last_modified,
            DATE_FORMAT_MONTH_DAY_YEAR_TIME,
          )
        },
      },
      ...(isInternal
        ? [
            {
              key: 'last_modified_by',
              heading: 'Last Modified By (Internal)',
              minWidth: 40,
              formatCell: (item: Legalization, context: Legalization[]) => {
                const legalization = context.find((c) => c.id === item.id)

                if (!legalization) return ''

                return legalization.last_modified_by
              },
            },
          ]
        : []),
    ],
  }
}
