import DialogContainer from 'components/common/Dialog/DialogContainer'
import InternationalDialogWrapper from './InternationalDialogWrapper'

import { DiversityAnswer } from 'types/PartnerDiversity'

type Props = {
  isOpen: boolean
  answers: DiversityAnswer[]
}

export const InternationalDiversitySurvey: React.FC<Props> = ({
  isOpen,
  answers,
}) => {
  return (
    <DialogContainer
      isOpen={isOpen}
      title="Diversity Information"
      subtitle="This will help Target recognize diverse-owned businesses
      (enterprises that are at least 51% owned, controlled & operated by a
      diverse entity)."
      maxWidth="xl"
      hideActions
    >
      <div data-testid="international-partner-diversity">
        <InternationalDialogWrapper answers={answers} />
      </div>
    </DialogContainer>
  )
}

export default InternationalDiversitySurvey
