import React from 'react'

import styled from '@emotion/styled'
import { useTheme, Theme } from '@mui/material/styles'

import useMediaQuery from '@mui/material/useMediaQuery'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import Input from 'components/common/Input'
import TabularData, { FieldList } from 'components/common/TabularData'

import { getConfidenceForItemTypesWithAttributes } from 'services/confidence'

import { ConfidenceScore } from 'types/Confidence'

const StyledForm = styled('form')(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const fieldList: FieldList[] = [
  {
    key: 'name',
    displayName: 'Item Type Name',
  },
  {
    key: 'description',
    displayName: 'Description',
  },
  {
    key: 'confidence',
    displayName: 'Confidence',
  },
]

export const ItemTypeSearch = () => {
  const theme = useTheme<Theme>()
  const matchesMediaQuery = useMediaQuery(theme.breakpoints.down('md'))

  const [value, setValue] = React.useState('')
  const [itemTypes, setItemTypes] = React.useState<ConfidenceScore[] | null>(
    null,
  )
  const [isPending, setIsPending] = React.useState(false)

  const handleChange = (value: string) => {
    setValue(value)
  }

  const handleBlur = (value: string) => {
    setValue(value.trim())
  }

  const handleSubmit = async (
    event: React.MouseEvent | React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault()

    if (!value) return

    setIsPending(true)
    const { results } = await getConfidenceForItemTypesWithAttributes(
      { page: 0, perPage: 30 },
      { q: value },
    )

    setItemTypes(results)
    setIsPending(false)
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      data-testid="container"
    >
      <Grid item xs={12}>
        <StyledForm onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Input
                id="item-type-search"
                name="item-type-search"
                data-testid="item-type-search"
                helperText="Enter the Item Type name as provided by the partner"
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                type="submit"
                data-testid="submit-btn"
                variant="contained"
                color="primary"
                fullWidth={matchesMediaQuery}
              >
                search
              </Button>
            </Grid>
          </Grid>
        </StyledForm>
        {isPending && <LinearProgress />}
        {itemTypes && (
          <>
            <StyledTypography>
              Target is looking for a confidence rate of 80 or higher to be able
              to assign an item type.
            </StyledTypography>
            <ul>
              <li>The confidence rate is below 80.</li>
              <li>
                The confidence rate is above 80, but there is a score tie.
              </li>
            </ul>
            <StyledTypography>
              If there is an appropriate item type below which should be
              assigned, have the partner submit the item with the item type name
              exactly as it appears below.
            </StyledTypography>
            <TabularData data={itemTypes} fieldList={fieldList} />
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default ItemTypeSearch
