import apiConfig from 'config/apiConfig'

export const USER_ROLE_ADMIN: string = getRole('APP-OAUTH2-MKP-PRODUCTADMIN')
export const USER_ROLE_ITEM_APPROVER: string = getRole(
  'APP-OAUTH2-MKP-ITEMAPPROVER',
)
export const USER_ROLE_ITEM_MANAGER: string = getRole(
  'APP-OAUTH2-MKP-ITEMMANAGER',
)

export const USER_ROLE_OPS: string = getRole('APP-OAUTH2-MKP-OPS')
export const USER_ROLE_PROGRAM_MANAGER: string = getRole(
  'APP-OAUTH2-MKP-PROGRAMMGR',
)

export const USER_ROLE_ACQUISITIONS: string = getRole(
  'APP-OAUTH2-MKP-ACQUISITIONS',
)
export const USER_ROLE_COMMUNICATIONS: string = getRole('APP-OAUTH2-MKP-COMM')
export const USER_ROLE_REPORTER: string = getRole('APP-OAUTH2-MKP-REPORTING')
export const USER_ROLE_DIGITAL_EXP_SUPPORT: string = getRole(
  'APP-OAUTH2-MKP-DIGITALEXPSUP',
)
export const USER_ROLE_FINANCE: string = getRole('APP-OAUTH2-MKP-FINANCE')
export const USER_ROLE_APP_SMS = 'APP-SMS'
export const USER_ROLE_APP_SMS_ADMIN = 'APP-SMS-ADMIN'
export const USER_ROLE_APP_SMS_READ = 'APP-SMS-READ'

export const ALL_USER_ROLES = [
  USER_ROLE_ADMIN,
  USER_ROLE_ACQUISITIONS,
  USER_ROLE_ITEM_APPROVER,
  USER_ROLE_ITEM_MANAGER,
  USER_ROLE_OPS,
  USER_ROLE_PROGRAM_MANAGER,
  USER_ROLE_COMMUNICATIONS,
  USER_ROLE_REPORTER,
  USER_ROLE_DIGITAL_EXP_SUPPORT,
  USER_ROLE_FINANCE,
  USER_ROLE_APP_SMS,
  USER_ROLE_APP_SMS_ADMIN,
  USER_ROLE_APP_SMS_READ,
]

function getRole(prefix: string): string {
  const found = apiConfig.auth.roles.find((role: string) =>
    role.startsWith(prefix),
  )
  if (!found) {
    throw Error(
      `Invalid configuration. Role ${prefix} has not been setup in apiConfig`,
    )
  }
  return found
}

export interface UserRole {
  name: string
  code: string | undefined
}
export const USER_ROLES: UserRole[] = [
  {
    name: 'Product Admin',
    code: USER_ROLE_ADMIN,
  },
  {
    name: 'Acquisitions',
    code: USER_ROLE_ACQUISITIONS,
  },
  {
    name: 'Item Approver',
    code: USER_ROLE_ITEM_APPROVER,
  },
  {
    name: 'Item Manager',
    code: USER_ROLE_ITEM_MANAGER,
  },
  {
    name: 'Operations',
    code: USER_ROLE_OPS,
  },
  {
    name: 'Program Manager',
    code: USER_ROLE_PROGRAM_MANAGER,
  },
  {
    name: 'Communications',
    code: USER_ROLE_COMMUNICATIONS,
  },
  {
    name: 'Reporter',
    code: USER_ROLE_REPORTER,
  },
  {
    name: 'Digital Exp Support',
    code: USER_ROLE_DIGITAL_EXP_SUPPORT,
  },
  {
    name: 'Finance',
    code: USER_ROLE_FINANCE,
  },
  {
    name: 'Partner',
    code: USER_ROLE_APP_SMS,
  },
]
