import { useState, useEffect, memo } from 'react'
import TableSpacer from 'components/common/TableSpacer'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import { getBulkItemUnlistReports, pollReport } from 'services/reports'
import { Direction } from 'services/pageableHelper'
import { Report, ReportStatus, ReportType } from '../../../src/types/Report'
import DownloadReportButton from 'components/common/ReportCard/DownloadReportButton'
import { useStateValue } from 'stateManager/StateProvider'
import useInterval from 'hooks/useInterval'
import GenerationBar from './GenerationBar'
import { HeadingBorder } from './styles'

export const ReportsTable = () => {
  const [reports, setReports] = useState<Report[]>([])
  const [inProgressReport, setInProgressReport] = useState<Report>()
  const [isLoading, setIsLoading] = useState(false)
  const { state, updateState } = useStateValue()

  const fieldList: EnhancedTableFieldType<Report>[] = [
    {
      key: 'type',
      heading: 'File Name',
      minWidth: 20,
      formatCell: (report: Report) => {
        return <p className="hc-mb-none">{report.report_name}</p>
      },
    },
    {
      key: 'created_by',
      heading: 'Last modified by (internal)',
      minWidth: 40,
    },
    {
      key: 'last_modified',
      heading: 'Last modified',
      minWidth: 40,
      formatCell: (report: Report) => {
        return <GenerationBar report={report} />
      },
    },
    {
      key: 'feedback',
      heading: 'Processing Comments',
      minWidth: 40,
      formatCell: (report: Report) => {
        return (
          report.feedback && <p className="hc-mb-none">{report?.feedback}</p>
        )
      },
    },
    {
      key: 'download_url',
      heading: 'Action',
      minWidth: 20,
      formatCell: (report: Report) => {
        return (
          <DownloadReportButton
            text="Download"
            report={report}
            isDisabled={!report.download_url}
            title="Download Report"
            hasDateRange={false}
          />
        )
      },
    },
  ]

  useInterval(
    () => {
      if (!inProgressReport) return

      pollReport(inProgressReport.id).then(async (report) => {
        if (
          report.status === ReportStatus.PENDING ||
          report.status === ReportStatus.PROCESSING
        ) {
          return
        }

        setInProgressReport(undefined)
      })
    },
    inProgressReport ? 5000 : null,
  )

  useEffect(() => {
    if (!inProgressReport) {
      setIsLoading(true)
      getBulkItemUnlistReports(
        {
          page: 0,
          perPage: 100,
          direction: Direction.DESC,
          orderBy: 'created',
        },
        { type: ReportType.BULK_ITEM_UNLIST },
      ).then((reports: any) => {
        setIsLoading(false)
        setReports(
          reports.data.filter(
            (report: Report) => !(report.status === ReportStatus.CANCELED),
          ),
        )
      })
    }
  }, [inProgressReport])

  useEffect(() => {
    if (state.isBulkUnlistNewFileUpload) {
      setIsLoading(true)
      getBulkItemUnlistReports(
        {
          page: 0,
          perPage: 100,
          direction: Direction.DESC,
          orderBy: 'created',
        },
        { type: ReportType.BULK_ITEM_UNLIST },
      )
        .then((reports: any) => {
          setIsLoading(false)
          setReports(
            reports.data.filter(
              (report: Report) => !(report.status === ReportStatus.CANCELED),
            ),
          )
          updateState({
            isBulkUnlistNewFileUpload: false,
          })
          const inProgress = reports.data.find(
            (report: Report) =>
              report.status === ReportStatus.PENDING ||
              report.status === ReportStatus.PROCESSING,
          )
          setInProgressReport(inProgress)
        })
        .finally(() => setIsLoading(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isBulkUnlistNewFileUpload])

  return (
    <>
      <HeadingBorder className="hc-fs-xl hc-mt-expanded">
        Item Bulk Unlisting History
      </HeadingBorder>
      <p className="hc-mb-none hc-mt-expanded hc-clr-grey01">
        This section allows the download of summary files for items unlisted
        using bulk unlist utility in the marketplace portal.
      </p>
      <p className="hc-mb-none hc-clr-grey01">
        Large file uploads may need upto an hour for processing and the files
        generated will be available for a period of 6 months (180 days) from the
        date of upload.
      </p>
      <TableSpacer>
        <EnhancedTable
          fieldList={fieldList}
          data={reports}
          isLoading={isLoading}
        />
      </TableSpacer>
    </>
  )
}

export default memo(ReportsTable)
