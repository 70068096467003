import { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useParams } from 'react-router-dom'

import groupBy from 'lodash/fp/groupBy'
import getOr from 'lodash/fp/getOr'

import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import { grey, success, warning } from 'config/themeConfig'

import { RoutePath } from 'services/NavigationHelper'
import { DialogEnum } from 'components/common/Dialog'
import EditButton from 'components/common/EditButton'
import { formatLocaleNumber } from 'services/formatNumber'
import HeaderTitle from 'components/common/HeaderTitle'
import TitleBar from 'components/common/TitleBar/TitleBar'

import { getMemberOf } from 'store/selectors'

import { isUserRoleExternal, isOneOfUserRoles } from 'services/authorization'
import { getTotalSellerProductCount } from 'services/productCounts'
import {
  getSellerAutoApproval,
  getSellerQuotas,
} from 'services/sellerListingSettings'
import {
  USER_ROLE_ADMIN,
  USER_ROLE_ITEM_MANAGER,
  USER_ROLE_OPS,
  USER_ROLE_PROGRAM_MANAGER,
  USER_ROLE_ACQUISITIONS,
} from 'services/roles'
import { getSellerLegalizationsWithNames } from 'services/legalizations'
import { StateProvider } from 'stateManager/StateProvider'

import { Legalization } from 'types/Legalization'
import { BRAND, ITEM_SUBTYPE, ITEM_TYPE } from 'types/Attribute'

import LegalizationTableContainer from './LegalizationTableContainer'
import {
  getBrandAllowedListType,
  getItemTypeAllowedListType,
  getSubtypeAllowedListType,
} from './ItemSettingsTableFieldTypes'
import ReportsTable from './ReportsTable'

const StyledBold = styled(Typography)({
  fontWeight: 'bold',
})
const StyledParagraph = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))
const StyledTitle = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))
const StyledApprovalStatus = styled(Typography)({
  color: grey[700],
})
const StyledApprovalStatusContainer = styled('span', {
  shouldForwardProp: (prop) => prop !== 'autoApprovalEnabled',
})<{ autoApprovalEnabled: string }>(({ theme, autoApprovalEnabled }) => {
  return {
    textAlign: 'center',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
    backgroundColor:
      autoApprovalEnabled === 'on' ? success.light : warning.light,
  }
})
const StyledFlexContainer = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
})
const StyledTooltop = styled(Tooltip)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}))

const allowedListEditRoles = [
  USER_ROLE_ADMIN,
  USER_ROLE_PROGRAM_MANAGER,
  USER_ROLE_ACQUISITIONS,
]
const allowedItemLimitAutoApprovalEditRoles = [
  USER_ROLE_ADMIN,
  USER_ROLE_OPS,
  USER_ROLE_ITEM_MANAGER,
  USER_ROLE_PROGRAM_MANAGER,
]

export const ItemListingSettingsPage = () => {
  const memberOf = useSelector(getMemberOf)
  const { sellerId } = useParams()

  const [isPending, setIsPending] = useState<boolean>(false)
  const [brandAllowedList, setBrandAllowedList] = useState<Legalization[]>([])
  const [subtypeAllowedList, setSubtypeAllowedList] = useState<Legalization[]>(
    [],
  )
  const [itemTypeAllowedList, setItemTypeAllowedList] = useState<
    Legalization[]
  >([])
  const [quota, setQuota] = useState<number>(-1)
  const [currentCount, setCurrentCount] = useState<number>()
  const [autoApprovalEnabled, setAutoApprovalEnabled] = useState<string>('')
  const [itemTypesBlockedList, setItemTypesBlockedList] = useState<
    Legalization[]
  >([])
  const [legalizations, setLegalizations] = useState<Legalization[]>([])

  const isInternal = !isUserRoleExternal(memberOf)

  const getData = useCallback(() => {
    if (sellerId) {
      setIsPending(true)
      getSellerLegalizationsWithNames(sellerId)
        .then((data) => {
          setLegalizations(data)
        })
        .finally(() => setIsPending(false))
    }
  }, [sellerId, setIsPending, setLegalizations])

  useEffect(() => {
    getData()
  }, [getData])

  const getAllowedListRules = () => {
    const typeMap = groupBy('primary_attribute.type', legalizations)
    const brandAllowedList = getOr([], BRAND, typeMap)
    const subtypeAllowedList = getOr([], ITEM_SUBTYPE, typeMap)
    const itemTypeAllowedList = getOr([], ITEM_TYPE, typeMap)

    setBrandAllowedList(brandAllowedList)
    setSubtypeAllowedList(subtypeAllowedList)
    setItemTypeAllowedList(itemTypeAllowedList)
  }

  const getAutoApprovalSettings = () => {
    if (sellerId) {
      Promise.all([
        getSellerAutoApproval(sellerId),
        getSellerQuotas(sellerId),
        getTotalSellerProductCount(sellerId),
      ]).then((res) => {
        const { enabled, blockedList: itemTypesBlockedList } = res[0]
        const quota = res[1]
        const totalProduct = res[2]

        setCurrentCount(totalProduct)

        setQuota(quota)
        setAutoApprovalEnabled(enabled ? 'on' : 'off')
        setItemTypesBlockedList(itemTypesBlockedList)
      })
    }
  }

  useEffect(() => {
    getAllowedListRules()
    getAutoApprovalSettings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legalizations])

  const hasAllowedListEdit = isOneOfUserRoles(memberOf, allowedListEditRoles)
  const hasItemLimitAutoApprovalEdit = isOneOfUserRoles(
    memberOf,
    allowedItemLimitAutoApprovalEditRoles,
  )

  return (
    <StateProvider>
      <HeaderTitle title="Item Listing Settings" />
      <StyledTitle>
        <StyledTitle>
          <TitleBar
            title="ITEM LIMIT & AUTO APPROVAL"
            actionButtons={[
              <EditButton
                key="edit-auto-approvals"
                aria-label={`edit-auto-approvals`}
                data-testid={`edit-auto-approvals-button`}
                dialogComponent={DialogEnum.EDIT_APPROVAL_SETTINGS}
                componentProps={{ currentItemCount: currentCount }}
                hide={!hasItemLimitAutoApprovalEdit}
                closeCallback={getAutoApprovalSettings}
              />,
            ]}
          />
        </StyledTitle>
        <StyledBold variant="h4" gutterBottom>
          Item Limit: {quota < 0 ? '' : `${quota.toLocaleString()} items`}
        </StyledBold>
        <Typography component="div" gutterBottom>
          <StyledFlexContainer>
            Current Count (VAPs & SAs):{' '}
            {currentCount ? formatLocaleNumber(currentCount) : ''}
            <StyledTooltop
              title="Number of VAPs & SAs currently published or currently pending with no previous approved versions"
              placement="bottom"
            >
              <InfoOutlinedIcon color="primary" />
            </StyledTooltop>
          </StyledFlexContainer>
        </Typography>
        <StyledParagraph>
          Partner item limit is the maximum number of variation parents and
          standalone items that can be submitted at any time, regardless of
          autoapproval.
        </StyledParagraph>
        <Typography component="div">
          <StyledBold display="inline" variant="h4">
            Auto Approvals:
          </StyledBold>
          <StyledApprovalStatusContainer
            autoApprovalEnabled={autoApprovalEnabled}
          >
            <StyledApprovalStatus display="inline" variant="overline">
              {autoApprovalEnabled}
            </StyledApprovalStatus>
          </StyledApprovalStatusContainer>
        </Typography>
        <StyledParagraph>
          When autoapproval is off, all submitted items are subject to manual
          review. When autoapproval is on, submitted items that are error-free
          will be automatically approved.
        </StyledParagraph>
        {autoApprovalEnabled === 'on' && (
          <>
            <StyledBold variant="h4" gutterBottom>
              Item Type Holds: {itemTypesBlockedList.length}
            </StyledBold>
            <StyledParagraph>
              When auto approval is on, error-free submitted items of the
              following item types will be subjected to manual review.
            </StyledParagraph>
            <StyledBold>Item Types on Hold:</StyledBold>
            <Typography gutterBottom>
              {itemTypesBlockedList
                .map(
                  (blockedItemType) => blockedItemType.primary_attribute.name,
                )
                .join(', ')}
            </Typography>
          </>
        )}
      </StyledTitle>
      <StyledTitle>
        <TitleBar title="ALLOWED ITEM TYPES" hideDivider />
      </StyledTitle>
      <StyledParagraph>
        <Typography component="span">
          Allowed Item Type rules determine which categories a partner is
          allowed to submit items under. To view the complete list of categories
          this partner can submit items under, download the allowed item type
          report.
          <NavLink to={`/${sellerId}${RoutePath.REPORTS}`}>
            <Typography color="primary" display="inline">
              {' Access Reports'}
            </Typography>
          </NavLink>
        </Typography>
      </StyledParagraph>

      <div data-testid="brand-table">
        <LegalizationTableContainer
          legalizations={brandAllowedList}
          setLegalizations={setLegalizations}
          isPending={isPending}
          hasEdit={hasAllowedListEdit}
          {...getBrandAllowedListType({
            isInternal: !isUserRoleExternal(memberOf),
          })}
          onCloseDialog={getData}
        />
      </div>
      <div data-testid="subtype-table">
        <LegalizationTableContainer
          legalizations={subtypeAllowedList}
          setLegalizations={setLegalizations}
          isPending={isPending}
          hasEdit={hasAllowedListEdit}
          {...getSubtypeAllowedListType({
            isInternal: !isUserRoleExternal(memberOf),
          })}
          onCloseDialog={getData}
        />
      </div>
      <div data-testid="itemtype-table">
        <LegalizationTableContainer
          legalizations={itemTypeAllowedList}
          setLegalizations={setLegalizations}
          isPending={isPending}
          hasEdit={hasAllowedListEdit}
          {...getItemTypeAllowedListType({
            isInternal: !isUserRoleExternal(memberOf),
          })}
          onCloseDialog={getData}
        />
      </div>
      {isInternal && (
        <div data-testid="generate-reports-table">
          <ReportsTable title="Item Type Bulk Upload Summary​" />
        </div>
      )}
    </StateProvider>
  )
}

export default ItemListingSettingsPage
