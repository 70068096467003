import React from 'react'
import { useDispatch } from 'react-redux'

import { DataListItem } from 'components/common/DataList'
import DisplayCard from 'components/common/DisplayCard'
import Link from 'components/common/Link'
import TabularData, { FieldList } from 'components/common/TabularData'
import Text from 'components/common/Text'
import { DialogEnum } from 'components/common/Dialog'

import { error, odrNonCompliant, success } from 'config/themeConfig'

import { GENERIC_API_ERROR } from 'constants/errors'
import Typography from '@mui/material/Typography'

import WarningIcon from '@mui/icons-material/Warning'
import { CancelOutlined, CheckCircleOutlined } from '@mui/icons-material'

import { RoutePath } from 'services/NavigationHelper'
import {
  getSingleBiReportingValue,
  getTimePeriod,
  getVendorFilter,
} from 'services/biReporting'
import { trackCustomEvent } from 'services/fireflyInsights'
import { decimalRound } from 'services/number'
import { fetchCardDataThruSecurityGateway } from 'services/securityGateway'
import { getGreenfield90DayInterval } from 'services/greenfieldHelpers'

import { openDialog } from 'store/dialog/actionCreator'

import { BiReportingCards } from 'types/BiReporting'
import { OrderDefectMetricType } from 'types/VendorStats'
import { FireflyEvent } from 'types/FireflyInsights'

import { DefectRate } from '../OrderDefectsPage/OverallDefectRateLabel'
import styled from '@emotion/styled'

const StyledTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(4),
}))

const StyledText = styled(Text)(({ theme }) => ({
  fontSize: 12,
  marginBottom: theme.spacing(2),
  color: theme.palette.grey[700],
  textAlign: 'center',
}))

const StyledCompliantIcon = styled(CheckCircleOutlined)(({ theme }) => ({
  color: success.main,
  marginLeft: theme.spacing(2),
}))

const StyledNonCompliantIcon = styled(CancelOutlined)(({ theme }) => ({
  color: odrNonCompliant.main,
  marginLeft: theme.spacing(2),
}))

const StyledProbationIcon = styled(WarningIcon)(({ theme }) => ({
  color: error.main,
  marginLeft: theme.spacing(2),
}))

export type Props = {
  sellerId: string
  vmmId?: string
}

type OdrState = {
  overall: number | undefined
  lateDeliveries: number | undefined
  defectiveReturns: number | undefined
  cancellations: number | undefined
  defectiveContacts: number | undefined
}

export const OrderDefectsCard: React.FC<Props> = ({ sellerId, vmmId }) => {
  const reduxDispatch = useDispatch()

  const [pending, setPending] = React.useState(false)
  const [error, setError] = React.useState('')
  const [odr, setOdr] = React.useState<OdrState>({
    overall: undefined,
    lateDeliveries: 0,
    defectiveReturns: 0,
    cancellations: 0,
    defectiveContacts: 0,
  })

  React.useEffect(() => {
    if (!sellerId) {
      return
    }

    let mounted = true

    if (vmmId) {
      const vendorFilter = getVendorFilter(vmmId)
      const timePeriod = getTimePeriod({
        interval: getGreenfield90DayInterval(),
      })

      setPending(true)

      Promise.all([
        fetchCardDataThruSecurityGateway(
          BiReportingCards.OVERALL_ORDER_DEFECT_RATE,
          sellerId,
          vendorFilter,
          timePeriod,
        ),
        fetchCardDataThruSecurityGateway(
          BiReportingCards.LATE_DELIVERY,
          sellerId,
          vendorFilter,
          timePeriod,
        ),
        fetchCardDataThruSecurityGateway(
          BiReportingCards.RETURN_RATE,
          sellerId,
          vendorFilter,
          timePeriod,
        ),
        fetchCardDataThruSecurityGateway(
          BiReportingCards.CANCEL_RATE,
          sellerId,
          vendorFilter,
          timePeriod,
        ),
        fetchCardDataThruSecurityGateway(
          BiReportingCards.CONTACT_RATE,
          sellerId,
          vendorFilter,
          timePeriod,
        ),
      ])
        .then((res) => {
          if (mounted) {
            const odrRate = getSingleBiReportingValue<number>(
              'ODR Rate',
              res[0],
            )

            const lateDeliveries = getSingleBiReportingValue<number>(
              'late_rate',
              res[1],
            )
            const defectiveReturns = getSingleBiReportingValue<number>(
              'return_rate',
              res[2],
            )
            const cancellations = getSingleBiReportingValue<number>(
              'cancel_rate',
              res[3],
            )
            const defectiveContacts = getSingleBiReportingValue<number>(
              'contact_rate',
              res[4],
            )

            setOdr((prev) => ({
              overall:
                odrRate !== undefined ? decimalRound(odrRate, 2) : prev.overall,
              lateDeliveries:
                lateDeliveries !== undefined
                  ? decimalRound(lateDeliveries, 2)
                  : prev.lateDeliveries,
              defectiveReturns:
                defectiveReturns !== undefined
                  ? decimalRound(defectiveReturns, 2)
                  : prev.defectiveReturns,
              cancellations:
                cancellations !== undefined
                  ? decimalRound(cancellations, 2)
                  : prev.cancellations,
              defectiveContacts:
                defectiveContacts !== undefined
                  ? decimalRound(defectiveContacts, 2)
                  : prev.defectiveContacts,
            }))
          }
        })
        .catch((err) => {
          if (mounted) {
            setError(err.message)
          }
        })
        .finally(() => {
          if (mounted) {
            setPending(false)
          }
        })
    }

    return () => {
      mounted = false
    }
  }, [sellerId, vmmId])

  const onRequestOpenDialog =
    (type: OrderDefectMetricType, defectRate: number | undefined) => () => {
      const params = {
        dialogEnum: DialogEnum.PERFORMANCE_METRICS,
        componentProps: {
          type,
          defectRate,
        },
      }

      reduxDispatch(openDialog(params))

      const trackRate =
        typeof defectRate !== 'number' ? 'undefined' : defectRate

      trackCustomEvent(FireflyEvent.ODR_HOMEPAGE_NAV, type, trackRate)
    }

  const handleOverallOdrTracking = () => {
    if (typeof odr.overall === 'number') {
      trackCustomEvent(FireflyEvent.ODR_HOMEPAGE_NAV, 'OVERALL', odr.overall)
    }
  }

  let overallRatingLabel = ''
  let overallRatingIcon = undefined
  if (typeof odr.overall === 'number') {
    if (odr.overall < DefectRate.NON_COMPLIANT) {
      overallRatingIcon = <StyledCompliantIcon />
      overallRatingLabel = 'Compliant'
    } else if (
      odr.overall >= DefectRate.NON_COMPLIANT &&
      odr.overall < DefectRate.PROBATION
    ) {
      overallRatingIcon = <StyledNonCompliantIcon />
      overallRatingLabel = 'Non-Compliant'
    } else if (odr.overall >= DefectRate.PROBATION) {
      overallRatingIcon = <StyledProbationIcon />
      overallRatingLabel = 'Probation'
    }
  }
  return (
    <DisplayCard
      title="Order Defect Rate in the Last 90 Days"
      iconColor="target"
      icon={WarningIcon}
      isLoading={pending}
      hasWarning={false}
      warningTooltip="An error occurred while getting the information."
    >
      {error && <Text>{GENERIC_API_ERROR}</Text>}
      {!error && (
        <>
          {typeof odr.overall === 'number' && (
            <StyledTypography variant="h2" data-testid="currentOdr">
              <StyledText type="bodySm" data-testid="currentOdrComparisonText">
                {overallRatingLabel}
              </StyledText>
              <div>
                <Link
                  to={`/${sellerId}${RoutePath.PERFORMANCE}`}
                  onClick={handleOverallOdrTracking}
                >
                  {odr.overall}%
                </Link>
                {overallRatingIcon}
              </div>
            </StyledTypography>
          )}
          <TabularData
            borderTop
            hasHeader={false}
            extraPadding={true}
            fieldList={
              [
                {
                  key: 'title',
                  displayName: '',
                  width: 100,
                },
                {
                  key: 'value',
                  displayName: '',
                  width: 50,
                  formatCell: ({ element }) => element,
                },
              ] as FieldList<DataListItem>[]
            }
            data={[
              {
                title: 'Late Deliveries',
                element: (
                  <Link
                    data-testid="late-deliveries"
                    onClick={onRequestOpenDialog(
                      OrderDefectMetricType.LATE_DELIVERY,
                      odr.lateDeliveries,
                    )}
                    to={`/${sellerId}${RoutePath.PERFORMANCE}`}
                  >
                    {odr.lateDeliveries}%
                  </Link>
                ),
              },
              {
                title: 'Defective Returns',
                element: (
                  <Link
                    data-testid="defective-returns"
                    onClick={onRequestOpenDialog(
                      OrderDefectMetricType.RETURN_RATE,
                      odr.defectiveReturns,
                    )}
                    to={`/${sellerId}${RoutePath.PERFORMANCE}`}
                  >
                    {odr.defectiveReturns}%
                  </Link>
                ),
              },
              {
                title: 'Cancellations',
                element: (
                  <Link
                    data-testid="cancellations"
                    to={`/${sellerId}${RoutePath.PERFORMANCE}`}
                  >
                    {odr.cancellations}%
                  </Link>
                ),
              },
              {
                title: 'Defective Contacts',
                element: (
                  <Link
                    data-testid="defective-contacts"
                    to={`/${sellerId}${RoutePath.PERFORMANCE}`}
                  >
                    {odr.defectiveContacts}%
                  </Link>
                ),
              },
            ]}
          />
        </>
      )}
    </DisplayCard>
  )
}

export default OrderDefectsCard
