import React from 'react'
import { useDispatch } from 'react-redux'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import { TaxonomyTypeahead } from 'components/common/Typeahead'

import { FormControlLabel } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'

import styled from '@emotion/styled'

import { submitReport } from 'services/reports'

import { closeDialog } from 'store/dialog/actionCreator'

import Attribute, { ITEM_TYPE } from 'types/Attribute'
import { Report, ReportType } from 'types/Report'

const StyledItemTypeDropdownBuffer = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(1),
}))
const StyledIncludeItemsCheckbox = styled(FormControlLabel)(({ theme }) => ({
  paddingTop: theme.spacing(2),
}))
export interface Props {
  isOpen: boolean
  sellerId: string
  reportType: ReportType
  onRequestSubmit: (report: Report) => void
}

export const ItemTypeSearchDialog = ({
  isOpen,
  sellerId,
  reportType,
  onRequestSubmit,
}: Props) => {
  const dispatch = useDispatch()
  const [pending, setPending] = React.useState(false)
  const [itemType, setItemType] = React.useState<Attribute>()
  const [includeItems, setIncludeItems] = React.useState<boolean>(false)

  const title = pending
    ? 'Generating... Please wait'
    : 'Generate Item Type Template'

  const handleItemTypeChange = (value: Nullable<Attribute>) => {
    if (!value) return
    setItemType(value)
  }

  const handleIncludeItemsChanged = (event: any, checked: boolean) => {
    if (event) {
      setIncludeItems(checked)
    }
  }

  const handleSubmit = async () => {
    if (!itemType) return

    setPending(true)
    try {
      const report = await submitReport({
        sellerId,
        type: reportType,
        parameters: {
          item_type_id: itemType.id,
          get_products: includeItems,
        },
        reportName: itemType.name,
        format: 'EXCEL',
      })

      onRequestSubmit(report)
    } catch (e) {
      console.error(
        `Error generating item type ${
          itemType!.name
        } template report for partner ${sellerId}: ${e}`,
      )
    } finally {
      setPending(false)
      dispatch(closeDialog())
    }
  }

  return (
    <DialogContainer
      title={title}
      isOpen={isOpen}
      isSubmitDisabled={!itemType}
      onSubmit={handleSubmit}
      submitButtonText="Generate"
      disableScroll
    >
      <StyledItemTypeDropdownBuffer container>
        <Grid item xs={12}>
          <TaxonomyTypeahead
            searchType={ITEM_TYPE}
            placeholder="Item Type"
            onChange={handleItemTypeChange}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledIncludeItemsCheckbox
            control={
              <Checkbox
                id="include-all-items-checkbox"
                data-testid="include-all-items-checkbox"
                checked={includeItems}
                onChange={handleIncludeItemsChanged}
                inputProps={{
                  'aria-label': 'Include current items',
                }}
              />
            }
            label="Include current items"
          />
        </Grid>
      </StyledItemTypeDropdownBuffer>
    </DialogContainer>
  )
}

export default ItemTypeSearchDialog
