import Address from 'components/common/Address'
import RequiredContent from 'components/common/RequiredContent'

import { hydrateAddress } from 'services/addressHelper'

import { SmsAddress } from 'types/Address'

interface Props {
  address?: Partial<SmsAddress>
}
export const ShippingAddressContent = (props: Props) => {
  const { address } = props

  const emptyState = <RequiredContent description="None provided." />
  return (
    <>
      {address && Object.keys(address).length ? (
        <Address address={hydrateAddress(address)} />
      ) : (
        emptyState
      )}
    </>
  )
}

export default ShippingAddressContent
