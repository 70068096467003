import axios from 'axios'

import apiConfig from 'config/apiConfig'
import { ListingStatus, ValidationStatus, RelationshipType } from 'types/Item'
import { isRelationshipType } from 'types/Guards'
import { ProductCountResponse } from 'types/productCounts'

export const getProductCounts = (hierarchy: string[], filters: object) => {
  const body = {
    counts: [
      {
        label: 'counts',
        count_requests: hierarchy,
      },
    ],
    filters,
  }

  return axios
    .post(`${apiConfig.marketplaceProducts}/product_counts`, body)
    .then(
      (res: { data: ProductCountResponse }) => {
        return res.data
      },
      (error) => {
        return {
          transaction_id: 'error',
          product_counts: [],
          error: error.response.data?.message ?? 'Error getting product counts',
        } as ProductCountResponse
      },
    )
}

type ListingStatusCount = {
  seller_id: string
  relationship_types?: RelationshipType[]
}

export const getProductCountsForItemsByListingStatus = ({
  sellerId,
  relationshipTypes,
}: {
  sellerId: string
  relationshipTypes?: string[] | string
}) => {
  const filters: ListingStatusCount = {
    seller_id: sellerId,
  }

  if (isRelationshipType(relationshipTypes)) {
    if (typeof relationshipTypes === 'string') {
      filters.relationship_types = [relationshipTypes]
    } else if (Array.isArray(relationshipTypes)) {
      filters.relationship_types = relationshipTypes
    }
  }

  return getProductCounts(['listing_status'], filters)
}

export const getProductCountForReviewQueue = () => {
  return getProductCounts(
    ['listing_status', 'validation_status', 'seller_name', 'item_type_name'],
    {
      listing_statuses: [ListingStatus.PENDING],
      validation_statuses: [
        ValidationStatus.BLOCKED,
        ValidationStatus.REVIEW,
        ValidationStatus.VALIDATED,
      ],
    },
  )
}

export const getProductCountForDownload = () => {
  return getProductCounts(
    ['seller_name', 'item_type_name', 'validation_status'],
    {
      listing_statuses: [ListingStatus.PENDING],
      validation_statuses: [
        ValidationStatus.BLOCKED,
        ValidationStatus.REVIEW,
        ValidationStatus.VALIDATED,
      ],
    },
  )
}

export const getCurrentProductCounts = () => {
  return getProductCounts(['item_type_id'], {
    current_listing_statuses: [ListingStatus.APPROVED],
    relationship_types: [RelationshipType.VAP, RelationshipType.SA],
  })
}

export const getPendingProductCounts = () => {
  return getProductCounts(['item_type_id'], {
    listing_statuses: [ListingStatus.PENDING],
    current_listing_statuses: [
      ListingStatus.PENDING,
      ListingStatus.REJECTED,
      ListingStatus.UNLISTED,
    ],
    relationship_types: [RelationshipType.VAP, RelationshipType.SA],
  })
}

export const getProductCountForQuotas = async () => {
  const [current, pending] = await Promise.all([
    getCurrentProductCounts(),
    getPendingProductCounts(),
  ])

  const totalCounts: Record<string, number> = {}

  current.product_counts[0].property_values.property_value_counts.forEach(
    (item) => {
      totalCounts[item.value] = item.count
    },
  )

  pending.product_counts[0].property_values.property_value_counts.forEach(
    (item) => {
      if (Object.prototype.hasOwnProperty.call(totalCounts, item.value)) {
        let val = totalCounts[item.value] + item.count
        totalCounts[item.value] = val
      } else {
        totalCounts[item.value] = item.count
      }
    },
  )

  return totalCounts
}

export const getSellerPublishedProductCounts = (sellerId: string) => {
  return getProductCounts(['relationship_type'], {
    seller_id: sellerId,
    current_listing_statuses: [ListingStatus.APPROVED],
    relationship_types: [RelationshipType.VAP, RelationshipType.SA],
  })
}

export const getSellerPendingProductCounts = (sellerId: string) => {
  return getProductCounts(['relationship_type'], {
    seller_id: sellerId,
    current_listing_statuses: [
      ListingStatus.PENDING,
      ListingStatus.REJECTED,
      ListingStatus.UNLISTED,
    ],
    listing_statuses: [ListingStatus.PENDING],
    relationship_types: [RelationshipType.VAP, RelationshipType.SA],
  })
}

export const getTotalSellerProductCount = async (sellerId: string) => {
  const [current, pending] = await Promise.all([
    getSellerPublishedProductCounts(sellerId),
    getSellerPendingProductCounts(sellerId),
  ])

  let totalCounts: number = 0

  current.product_counts[0].property_values.property_value_counts.forEach(
    (item) => {
      totalCounts = totalCounts + item.count
    },
  )

  pending.product_counts[0].property_values.property_value_counts.forEach(
    (item) => {
      totalCounts = totalCounts + item.count
    },
  )

  return totalCounts
}
