import {
  call,
  put,
  retry,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'

import uniqBy from 'lodash/fp/uniqBy'

import { isOneOfUserRoles } from 'services/authorization'
import { setUserSeller, trackCustomEvent } from 'services/fireflyInsights'
import { filterResponsibilityBySellerId } from 'services/functionalResponsibilities'
import { getStripeAccountId } from 'services/payments'
import { updateReturnPolicy } from 'services/returnPoliciesService'
import { updateReviewIndicators } from 'services/reviewIndicators'
import {
  USER_ROLE_ADMIN,
  USER_ROLE_APP_SMS_ADMIN,
  USER_ROLE_APP_SMS_READ,
  USER_ROLE_FINANCE,
  USER_ROLE_OPS,
  USER_ROLE_ACQUISITIONS,
} from 'services/roles'
import {
  getAllSellers,
  getSeller,
  saveSeller,
  updateSeller,
  updateSellerDistributionCenter,
  updateShipNode,
} from 'services/seller'
import { updateSellerStatus } from 'services/sellerStatus'
import {
  getSellerEntitlements,
  getSellerUsers,
  mapUsersToEntitlements,
  updateSellerUser,
  updateSellerUserEntitlement,
  updateSellerUserFunctionalResponsibility,
  editContactPermissions,
  deleteSellerUserEntitlement,
  searchSellerUser,
  updateEntitlementByEmail,
} from 'services/sellerUser'
import { updateInternalTaxSetup } from 'services/taxSettings'

import {
  currentSellerId,
  getMemberOf,
  getSellerContacts,
  getUserId,
} from 'store/selectors'

import { closeDialog } from 'store/dialog/actionCreator'
import { resetBanner } from 'store/notification/reducer'
import { clearPartnerDiversityAnswers } from 'store/partnerDiversity/actionCreators'
import { resetPaymentAccount } from 'store/paymentAccount/actionCreators'
import { updateEntitlementsBySellerId } from 'store/user/actionCreators'

import { FireflyEvent } from 'types/FireflyInsights'
import { SellerStatus, SmsReturnPolicy } from 'types/Seller'
import SellerUser, { Entitlement, Role, PhoneNumber } from 'types/SellerUser'

import {
  createSeller,
  SELLERS_IS_PENDING_DONE,
  createSellerContact,
  CREATE_SELLER_CONTACT_DONE,
  sellersIsPending,
  sellersIsPendingDone,
  EDIT_SELLER,
  EDIT_SELLER_STATUS,
  EDIT_SELLER_STATUS_DONE,
  CREATE_SELLER_DISTRIBUTION_CENTER_AND_SHIP_NODE,
  EDIT_SELLER_DISTRIBUTION_CENTER,
  EditSellerAction,
  EditSellerDistributionCenterAction,
  CreateSellerDistributionCenterAndShipNodeAction,
  EditReturnPolicyAction,
  EditSellerStatusAction,
  editSellerStatus,
  editSellerStatusDone,
  FETCH_ALL_SELLERS,
  fetchAllSellers,
  SET_CURRENT_SELLER,
  SetCurrentSellerAction,
  setCurrentSellerDone,
  FETCH_SELLER_CONTACTS,
  FETCH_SELLER_CONTACTS_DONE,
  fetchSellerContacts,
  fetchSellerContactsDone,
  CREATE_SELLER_CONTACT,
  CreateSellerContactAction,
  createSellerContactDone,
  EDIT_SELLER_CONTACT,
  EditSellerContactAction,
  editSellerContactDone,
  DELETE_CONTACT_ENTITLEMENT,
  DeleteContactEntitlementAction,
  deleteContactEntitlementDone,
  EditSellerShipNodeAction,
  EDIT_SELLER_SHIP_NODE,
  setCurrentSeller,
  EDIT_RETURN_POLICY,
  fetchAllSellersDone,
  CreateSellerAction,
  CREATE_SELLER_AND_ADMIN_CONTACT,
  CreateSellerAndAdminContactAction,
  editSellerDistributionCenter,
  CREATE_SELLER,
  SET_CURRENT_SELLER_DONE,
  EditInternalTaxSetupAction,
  EDIT_INTERNAL_TAX_SETUP,
  EditReviewIndicatorAction,
  EDIT_REVIEW_INDICATOR,
  FetchSellerContactsAction,
  clearSellerContacts,
} from './actionCreators'

import { SmsDistributionCenter, SmsSeller } from 'types/Seller'

export function* fetchAllSellersSaga() {
  try {
    const { data } = yield call(getAllSellers)
    yield put(fetchAllSellersDone(data))
  } catch (e) {
    console.error(`Error in fetchAllSellersSaga: ${e}`)
  }
}

export function* setCurrentSellerSaga({ payload }: SetCurrentSellerAction) {
  const userId: string = yield select(getUserId)
  const currentSeller: string = yield select(currentSellerId)

  if (payload !== currentSeller) {
    yield put(clearSellerContacts())
  }

  if (payload && currentSeller !== payload) {
    yield put(fetchSellerContacts(payload))
  }

  yield put(resetBanner())
  yield put(clearPartnerDiversityAnswers())
  yield put(resetPaymentAccount())

  try {
    yield put(sellersIsPending())
    const currentSeller: SmsSeller = payload
      ? yield call(getSeller, payload)
      : undefined

    if (currentSeller) {
      const memberOf: string[] = yield select(getMemberOf)
      const hasStripeIdReadPrivileges: boolean = yield call(
        isOneOfUserRoles,
        memberOf,
        [
          USER_ROLE_ADMIN,
          USER_ROLE_OPS,
          USER_ROLE_FINANCE,
          USER_ROLE_APP_SMS_ADMIN,
          USER_ROLE_APP_SMS_READ,
        ],
      )
      if (hasStripeIdReadPrivileges) {
        currentSeller.stripe_account_id = yield call(
          getStripeAccountId,
          currentSeller.id,
        )
      }

      if (currentSeller.return_policies) {
        const sellerUsers: SellerUser[] = yield call(
          getSellerUsers,
          currentSeller.id,
        )

        currentSeller.return_policies = currentSeller.return_policies.map(
          (policy: SmsReturnPolicy) => {
            const returnContact = (sellerUsers as SellerUser[]).find(
              (sellerUser) =>
                policy.return_seller_user_id &&
                sellerUser.id === policy.return_seller_user_id,
            )

            return {
              ...policy,
              returnContact,
            }
          },
        )
      }

      yield call(setUserSeller, {
        name: currentSeller?.display_name ?? currentSeller.legal_business_name,
      })
    }

    if (userId && payload) {
      // User with multiple entitlements should use the entitlement for the current seller
      yield put(updateEntitlementsBySellerId(payload))
    }

    yield put(setCurrentSellerDone(currentSeller))
  } catch (e) {
    console.error(`Error in setCurrentSellerSaga: ${e}`)
  } finally {
    yield put(sellersIsPendingDone())
  }
}

export function* editSellerSaga({ seller }: EditSellerAction) {
  const id: string = yield select(currentSellerId)
  try {
    yield put(sellersIsPending())
    yield call(updateSeller, id, seller)
    yield put(fetchAllSellers())
    yield put(setCurrentSeller(id))
  } catch (e) {
    console.error(`Error in editSellerSaga: ${e}`)
  } finally {
    yield put(closeDialog())
    yield put(sellersIsPendingDone())
  }
}

export function* createSellerDistributionCenterAndShipNodeSaga({
  distributionCenter,
  shipNode,
}: CreateSellerDistributionCenterAndShipNodeAction) {
  try {
    const id: string = yield select(currentSellerId)
    const data: SmsDistributionCenter = yield call(
      updateSellerDistributionCenter,
      id,
      distributionCenter,
    )
    yield call(updateShipNode, id, data.id, shipNode, false)
    yield put(setCurrentSeller(id))
  } catch (e) {
    console.error(
      `Error in createSellerDistributionCenterAndShipNodeSaga: ${e}`,
    )
  } finally {
    yield put(closeDialog())
    yield put(sellersIsPendingDone())
  }
}

export function* editSellerDistributionCenterSaga({
  distributionCenter,
}: EditSellerDistributionCenterAction) {
  try {
    const id: string = yield select(currentSellerId)
    yield call(updateSellerDistributionCenter, id, distributionCenter)
    yield put(setCurrentSeller(id))
  } catch (e) {
    console.error(`Error in editSellerDistributionCenterSaga: ${e}`)
  } finally {
    yield put(closeDialog())
    yield put(sellersIsPendingDone())
  }
}

export function* editSellerShipNodeSaga({
  distributionCenterId,
  shipNode,
}: EditSellerShipNodeAction) {
  const id: string = yield select(currentSellerId)
  const memberOf: string[] = yield select(getMemberOf)
  const isInternalShipNodesApi = isOneOfUserRoles(memberOf, [
    USER_ROLE_OPS,
    USER_ROLE_ADMIN,
    USER_ROLE_ACQUISITIONS,
  ])

  try {
    yield call(
      updateShipNode,
      id,
      distributionCenterId,
      shipNode,
      isInternalShipNodesApi,
    )
    yield put(setCurrentSeller(id))
  } catch (e) {
    console.error(`Error in editSellerShipNodeSaga: ${e}`)
  } finally {
    yield put(closeDialog())
    yield put(sellersIsPendingDone())
  }
}

export function* editReturnPolicySaga({
  returnPolicy,
}: EditReturnPolicyAction) {
  const id: string = yield select(currentSellerId)
  try {
    yield put(sellersIsPending())
    yield call(updateReturnPolicy, id, returnPolicy)
    yield put(setCurrentSeller(id))
  } catch (e) {
    console.error(`Error in editReturnPolicySaga: ${e}`)
  } finally {
    yield put(closeDialog())
    yield put(sellersIsPendingDone())
  }
}

export function* editSellerStatusSaga({
  sellerId,
  status,
}: EditSellerStatusAction) {
  try {
    const payload: SellerStatus = yield retry(
      5,
      1000,
      updateSellerStatus,
      sellerId,
      status,
    )
    yield put(editSellerStatusDone(payload))
  } catch (e) {
    console.error(`Error in editSellerStatusSaga: ${e}`)
    trackCustomEvent(FireflyEvent.SET_SELLER_STATUS_ERROR, sellerId, status)
    yield put(sellersIsPendingDone())
  } finally {
    yield put(closeDialog())
  }
}

export function* fetchSellerContactsSaga(action: FetchSellerContactsAction) {
  const { sellerId } = action

  try {
    const contacts: SellerUser[] = yield call(getSellerUsers, sellerId)

    // The /seller_users api gives us back all of the vmm metadata so get the one by sellerId
    const contactsWithFilteredVmmData: SellerUser[] = yield call(
      filterResponsibilityBySellerId,
      sellerId,
      contacts,
    )
    const entitlements: Entitlement[] = yield call(
      getSellerEntitlements,
      sellerId,
    )
    // remove duplicate IDs
    const filteredEntitlements = uniqBy('seller_user_id', entitlements)

    const contactsWithEntitlements: SellerUser[] = yield call(
      mapUsersToEntitlements,
      contactsWithFilteredVmmData,
      filteredEntitlements,
    )

    yield put(fetchSellerContactsDone(contactsWithEntitlements))
  } catch (e) {
    console.error(`Error in fetchSellerContactsSaga: ${e}`)
  }
}

export function* createSellerContactSaga(action: CreateSellerContactAction) {
  const { payload } = action
  const { sellerId, contact, role, responsibility, hasUpdatedUser } = payload

  yield put(sellersIsPending())

  try {
    let sellerUserData = contact
    if (!hasUpdatedUser) {
      sellerUserData = yield call(updateSellerUser, sellerUserData)
    }

    if (sellerUserData.status && sellerUserData.status === 409) {
      yield call(updateEntitlementByEmail, {
        sellerId,
        email: contact.email,
        role,
      })
      sellerUserData = yield call(searchSellerUser, contact.email)
    } else {
      yield call(updateSellerUserEntitlement, {
        sellerId,
        userId: sellerUserData.id,
        role,
      })
    }

    if (responsibility) {
      yield call(updateSellerUserFunctionalResponsibility, {
        sellerId,
        userId: sellerUserData.id,
        data: responsibility,
      })
    }
    yield put(createSellerContactDone())
    yield put(fetchSellerContacts(sellerId))
    yield take(FETCH_SELLER_CONTACTS_DONE)
    yield put(closeDialog())
  } catch (e) {
    console.error(`Error in createSellerContactSaga: ${e}`)
  } finally {
    yield put(sellersIsPendingDone())
  }
}

export function* editSellerContactSaga(action: EditSellerContactAction) {
  const { contact, userId, role, responsibility } = action.payload
  const sellerId: string = yield select(currentSellerId)
  const sellerContacts: SellerUser[] = yield select(getSellerContacts)

  yield put(sellersIsPending())

  try {
    let entitlementData: Entitlement[] | undefined
    let responsibilityData: number[] | undefined
    let contactData: SellerUser | undefined

    if (role) {
      if (role !== Role.NONE) {
        // May need to update contact information before we are allowed to update the entitlement
        if (contact) {
          contactData = yield call(updateSellerUser, contact)
        }

        entitlementData = yield call(updateSellerUserEntitlement, {
          sellerId,
          userId,
          role,
        })
      } else {
        entitlementData = yield call(updateSellerUserEntitlement, {
          sellerId,
          userId,
          role,
        })

        if (contact) {
          contactData = yield call(updateSellerUser, contact)
        }
      }
    }

    responsibilityData = yield call(updateSellerUserFunctionalResponsibility, {
      sellerId,
      userId,
      data: responsibility ? responsibility : [],
    })

    const newSellerContacts: SellerUser[] = yield call(editContactPermissions, {
      sellerId,
      userId,
      contacts: contactData
        ? [...sellerContacts, contactData]
        : [...sellerContacts],
      entitlement: entitlementData,
      responsibility: responsibilityData,
    })

    yield put(editSellerContactDone(newSellerContacts))
    yield put(closeDialog())
    yield put(fetchSellerContacts(sellerId))
    yield take(FETCH_SELLER_CONTACTS_DONE)
  } catch (e) {
    console.error(`Error in editSellerContactSaga: ${e}`)
  } finally {
    yield put(sellersIsPendingDone())
  }
}

export function* deleteContactEntitlementSaga(
  action: DeleteContactEntitlementAction,
) {
  const { contact } = action.payload
  const { id: userId } = contact
  const sellerId: string = yield select(currentSellerId)

  yield put(sellersIsPending())

  try {
    yield call(deleteSellerUserEntitlement, {
      sellerId,
      userId,
    })

    yield put(deleteContactEntitlementDone())
    yield put(fetchSellerContacts(sellerId))
    yield take(FETCH_SELLER_CONTACTS_DONE)
  } catch (e) {
    console.error(`Error in deleteContactEntitlementSaga: ${e}`)
  } finally {
    yield put(sellersIsPendingDone())
  }
}

export function* createSellerSaga(action: CreateSellerAction) {
  const { seller } = action

  yield put(sellersIsPending())
  try {
    const savedSeller: SmsSeller = yield call(saveSeller, seller)
    yield put(setCurrentSeller(savedSeller.id))
    yield take(SET_CURRENT_SELLER_DONE)

    const distributionCenter = {
      days_of_operation: {
        working_hours: [],
        days_closed: [],
      },
    }
    yield put(editSellerDistributionCenter(distributionCenter))
  } catch (e) {
    console.error(`Error in createSellerSaga: ${e}`)
  } finally {
    yield put(sellersIsPendingDone())
  }
}

export function* createSellerAndAdminContactSaga(
  action: CreateSellerAndAdminContactAction,
) {
  const { payload } = action
  const { seller, contact } = payload

  yield put(sellersIsPending())

  try {
    // We create the user first so the saga will fail if the the email exists in POL.
    // If we get an error unrelated to POL merge the error with contact and pass it on.
    let sellerUserData: SellerUser = yield call(updateSellerUser, contact)

    if (
      sellerUserData?.errors &&
      sellerUserData.errors[0] ===
        `Object of type SellerUser with login id '${contact.email}' exists already`
    ) {
      // if user already exists, make sure it has a mobile number
      // if not, add given mobile number
      const existingUser: SellerUser = yield call(
        searchSellerUser,
        contact.email,
      )
      const hasMobileNumber = !!existingUser.phone_numbers?.some(
        (num: PhoneNumber) => num.type === 'MOBILE',
      )
      if (!hasMobileNumber) {
        sellerUserData = yield call(updateSellerUser, {
          ...existingUser,
          phone_numbers: [
            contact.phone_numbers?.find(
              (num: PhoneNumber) => num.type === 'MOBILE',
            ),
            // @ts-ignore
            ...existingUser.phone_numbers,
          ],
        })
      }
    }

    const contactForSeller = {
      ...contact,
      ...sellerUserData,
    }

    yield put(createSeller(seller))
    yield take(SELLERS_IS_PENDING_DONE)

    const sellerId: string = yield select(currentSellerId)

    yield put(
      createSellerContact({
        sellerId,
        contact: contactForSeller,
        role: Role.ADMIN,
        responsibility: undefined,
        hasUpdatedUser: true,
      }),
    )
    yield take(CREATE_SELLER_CONTACT_DONE)

    yield put(editSellerStatus(sellerId, SellerStatus.PARTNER_SETUP))
    yield take(EDIT_SELLER_STATUS_DONE)
  } catch (err) {
    console.error(`Error creating a new seller: ${err}`)
    yield put(sellersIsPendingDone())
  }
}

export function* editInternalTaxSetupSaga({
  setupComplete,
}: EditInternalTaxSetupAction) {
  try {
    const sellerId: string = yield select(currentSellerId)
    yield call(updateInternalTaxSetup, sellerId, setupComplete)
    yield put(setCurrentSeller(sellerId))
  } catch (e) {
    console.error(`Error in editInternalTaxSetupSaga: ${e}`)
  } finally {
    yield put(closeDialog())
    yield put(sellersIsPendingDone())
  }
}

export function* editReviewIndicatorSaga(action: EditReviewIndicatorAction) {
  const { seller, indicator, reviewed } = action.payload
  try {
    yield put(sellersIsPending())
    yield call(updateReviewIndicators, seller.id, indicator, reviewed)
    yield call(updateSeller, seller.id, seller)
    yield put(fetchAllSellers())
    yield put(setCurrentSeller(seller.id))
  } catch (e) {
    console.error(`Error in editReviewIndicatorSaga: ${e}`)
  } finally {
    yield put(closeDialog())
    yield put(sellersIsPendingDone())
  }
}

export function* sellerSagas() {
  yield takeEvery(FETCH_ALL_SELLERS, fetchAllSellersSaga)
  yield takeLatest(SET_CURRENT_SELLER, setCurrentSellerSaga)
  yield takeEvery(EDIT_SELLER, editSellerSaga)
  yield takeEvery(EDIT_SELLER_STATUS, editSellerStatusSaga)
  yield takeEvery(FETCH_SELLER_CONTACTS, fetchSellerContactsSaga)
  yield takeEvery(CREATE_SELLER_CONTACT, createSellerContactSaga)
  yield takeEvery(EDIT_SELLER_CONTACT, editSellerContactSaga)
  yield takeEvery(DELETE_CONTACT_ENTITLEMENT, deleteContactEntitlementSaga)
  yield takeEvery(
    EDIT_SELLER_DISTRIBUTION_CENTER,
    editSellerDistributionCenterSaga,
  )
  yield takeEvery(
    CREATE_SELLER_DISTRIBUTION_CENTER_AND_SHIP_NODE,
    createSellerDistributionCenterAndShipNodeSaga,
  )
  yield takeEvery(EDIT_SELLER_SHIP_NODE, editSellerShipNodeSaga)
  yield takeEvery(EDIT_RETURN_POLICY, editReturnPolicySaga)
  yield takeEvery(CREATE_SELLER, createSellerSaga)
  yield takeEvery(
    CREATE_SELLER_AND_ADMIN_CONTACT,
    createSellerAndAdminContactSaga,
  )
  yield takeEvery(EDIT_INTERNAL_TAX_SETUP, editInternalTaxSetupSaga)
  yield takeEvery(EDIT_REVIEW_INDICATOR, editReviewIndicatorSaga)
}
