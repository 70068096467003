import startCase from 'lodash/fp/startCase'
import join from 'lodash/fp/join'
import lowerCase from 'lodash/fp/lowerCase'
import map from 'lodash/fp/map'
import replace from 'lodash/fp/replace'
import flow from 'lodash/fp/flow'

import {
  DATE_PICKER_FORMAT,
  formatDate,
  DATE_FORMAT_ABBREVIATED_MONTH_DAY_YEAR,
} from 'services/dateService'

import { Report, ReportType } from 'types/Report'
import { getNodeById } from 'services/itemTaxonomies'

const wordAfterLastComma = /, ([^,]*)$/

export const getOrderDefectTypeStr: (list: string[]) => string = flow(
  map((str: string) => startCase(lowerCase(str))),
  join(', '),
  replace(wordAfterLastComma, ' and $1'),
)

export const getAttributesItemTypeName = async (itemTypeId: string) => {
  const type = await getNodeById(itemTypeId)
  return type?.name ?? `Item Type ${itemTypeId}`
}

export const getSalesMetricsStr = (metric: string) => {
  switch (metric) {
    case 'ITEM_CONVERSION':
      return 'Item Conversion'
    case 'ITEM_LEVEL_GMV':
      return 'Item Level GMV'
    case 'ITEM_RETURN_RATE':
      return 'Item Return Rate'
    default:
      return 'No sales metric type provided'
  }
}

export const formatReportParameters = async (
  report: Report,
): Promise<string | string[]> => {
  switch (report.type) {
    case ReportType.PAYOUT_RECONCILIATION:
    case ReportType.RETURN_ORDERS_EXTERNAL:
    case ReportType.ORDERS: {
      return `${formatDate(
        report.start_date,
        DATE_FORMAT_ABBREVIATED_MONTH_DAY_YEAR,
      )} - ${formatDate(
        report.end_date,
        DATE_FORMAT_ABBREVIATED_MONTH_DAY_YEAR,
      )}`
    }
    case ReportType.ALLOWED_ITEM_TYPES:
    case ReportType.PRODUCT_LOGISTIC_ERRORS:
    case ReportType.ITEM_ERRORS:
    case ReportType.PARTNER_REVIEWS: {
      return 'N/A'
    }
    case ReportType.ORDER_DEFECTS: {
      const dates = `${formatDate(
        report.start_date,
        DATE_FORMAT_ABBREVIATED_MONTH_DAY_YEAR,
      )} - ${formatDate(
        report.end_date,
        DATE_FORMAT_ABBREVIATED_MONTH_DAY_YEAR,
      )}`

      const orderDefectTypes = report?.parameters?.order_defect_types
        ? getOrderDefectTypeStr(report.parameters.order_defect_types)
        : 'No order defect type provided'

      return [dates, orderDefectTypes]
    }
    case ReportType.SALES_METRICS: {
      const dates = `${formatDate(
        report.parameters?.start_day,
        DATE_FORMAT_ABBREVIATED_MONTH_DAY_YEAR,
        DATE_PICKER_FORMAT,
      )} - ${formatDate(
        report.parameters?.end_day,
        DATE_FORMAT_ABBREVIATED_MONTH_DAY_YEAR,
        DATE_PICKER_FORMAT,
      )}`
      const salesMetricType = report?.parameters?.metrics_type
        ? getSalesMetricsStr(report?.parameters?.metrics_type)
        : 'No sales metric type provided'
      return [dates, salesMetricType]
    }
    case ReportType.SALES_TAX: {
      return `${formatDate(
        report.parameters?.start_day,
        DATE_FORMAT_ABBREVIATED_MONTH_DAY_YEAR,
        DATE_PICKER_FORMAT,
      )} - ${formatDate(
        report.parameters?.end_day,
        DATE_FORMAT_ABBREVIATED_MONTH_DAY_YEAR,
        DATE_PICKER_FORMAT,
      )}`
    }
    case ReportType.ATTRIBUTE_VALUES: {
      const itemTypeName = report?.parameters?.item_type_id
        ? await getAttributesItemTypeName(report?.parameters?.item_type_id)
        : 'No item type id provided'
      return itemTypeName
    }
    case ReportType.ITEM_ERRORS_V2: {
      if (report.parameters?.error_severity) {
        return startCase(lowerCase(report.parameters.error_severity))
      }
      return 'All Item Errors'
    }
    case ReportType.INVENTORY: {
      if (report?.parameters?.is_published !== undefined) {
        if (report?.parameters?.is_published) {
          return 'Published Items'
        } else {
          return 'Not Published Items'
        }
      } else {
        return 'All Items'
      }
    }
    default:
      return ''
  }
}
