import axios from 'axios'

import apiConfig from 'config/apiConfig'

import { ItemOverride } from 'types/ItemOverride'

export function overrideItemType(
  tcin: string,
  itemTypeId: number,
): Promise<ItemOverride> {
  const data = {
    tcin,
    attribute: 'product_classification.item_type',
    new_values: [itemTypeId],
  }
  return axios
    .post(`${apiConfig.itemOverrides}/overrides`, data)
    .then((res) => res.data)
}
