import React from 'react'

import Typography from '@mui/material/Typography'
import { TypographyVariant } from '@mui/material/styles'
import styled from '@emotion/styled'

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(4),
}))

const StyledTitle = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(2),
  marginBottom: 0,
})) as typeof Typography

interface Props {
  variant: TypographyVariant
  title: string
  children?: React.ReactNode
  component?: string
}

export const Heading = (props: Props) => {
  const { variant, title, children, component } = props

  return (
    <StyledRoot>
      <StyledTitle variant={variant} component={component as React.ElementType}>
        {title}
      </StyledTitle>
      {children}
    </StyledRoot>
  )
}

export default Heading
