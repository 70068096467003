import Button from '@mui/material/Button'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'

import AddIcon from '@mui/icons-material/Add'
import MoreVert from '@mui/icons-material/MoreVert'

import ContentSpacer from 'components/common/ContentSpacer'
import { DialogEnum } from 'components/common/Dialog'
import Message from 'components/common/Message'
import TableSpacer from 'components/common/TableSpacer'
import TitleBar from 'components/common/TitleBar'
import RequiredContent from 'components/common/RequiredContent'
import EditButton from 'components/common/EditButton'

import UserTable from './UserTable'
import UserResponsibilities from './UserResponsibilities'

import usePrevious from 'hooks/usePrevious'

import { isOneOfUserRoles } from 'services/authorization'
import {
  USER_ROLE_OPS,
  USER_ROLE_ADMIN,
  USER_ROLE_APP_SMS_ADMIN,
} from 'services/roles'

import {
  getAllResponsibilities,
  hasAllResponsibilities,
} from 'services/usersHelper'

import { deleteContactEntitlement } from 'store/seller/actionCreators'
import { closeDialog, openDialog } from 'store/dialog/actionCreator'

import SellerUser from 'types/SellerUser'

import {
  getMemberOf,
  getSellerContacts,
  getReturnPolicies,
  isSellersPending,
  currentSellerId,
  isDefunctSellerStatus,
} from 'store/selectors'

const StyledDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    color: theme.palette.common.black,
  },
}))

const StyledEditButton = styled(EditButton)(({ theme }) => ({
  fontSize: 14,
  marginRight: theme.spacing(0.5),
  marginBottom: theme.spacing(0.35),
}))

const StyledText = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
}))

const UserContent = () => {
  const dispatch = useDispatch()

  const contacts = useSelector(getSellerContacts)
  const isPending = useSelector(isSellersPending)
  const memberOf = useSelector(getMemberOf)
  const returnPolicies = useSelector(getReturnPolicies)
  const sellerId = useSelector(currentSellerId) ?? ''
  const isDefunct = useSelector(isDefunctSellerStatus)

  const [completed, setCompleted] = useState<boolean>()
  const [successFlag, setSuccessFlag] = useState(false)

  useEffect(() => {
    if (contacts) {
      setCompleted(hasAllResponsibilities(getAllResponsibilities(contacts)))
    }
  }, [contacts])

  useEffect(() => {
    const timer = setTimeout(() => setSuccessFlag(false), 5000)
    return () => clearTimeout(timer)
  }, [successFlag])

  const prevCompleted = usePrevious(completed)
  useEffect(() => {
    if (isPending) {
      if (!prevCompleted && completed === true) {
        setSuccessFlag(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPending, completed])

  const onDeleteEntitlement = (contact: SellerUser) => {
    const returnsContacts = returnPolicies.map((policy) =>
      policy.returnContact ? policy.returnContact.id : null,
    )

    if (returnsContacts.includes(contact.id)) {
      dispatch(
        openDialog({
          dialogEnum: DialogEnum.ERROR_RETURNS_CONTACT,
        }),
      )

      return
    }

    if (
      window.confirm(
        `Are you sure you want to remove ${contact.first_name} ${contact.last_name}?`,
      )
    ) {
      dispatch(deleteContactEntitlement(contact))
    }
  }

  const handleCloseDialog = () => {
    dispatch(closeDialog())
  }

  const handleAddContact = () => {
    dispatch(
      openDialog({
        dialogEnum: DialogEnum.CREATE_SELLER_CONTACT,
        componentProps: {
          handleCloseDialog,
        },
      }),
    )
  }

  const onEditContact = (contact: SellerUser) => {
    const componentProps = {
      contact,
      contactMobilePhone: contact?.phone_numbers?.find((phoneNumber) => {
        return phoneNumber.type === 'MOBILE'
      }),
      contactOfficePhone: contact?.phone_numbers?.find((phoneNumber) => {
        return phoneNumber.type === 'OFFICE'
      }),
      sellerVmmData: contact?.vmm_metadata?.find(
        (vmmData) => vmmData.seller_id === sellerId,
      ),
      sellerEntitlement: contact?.entitlements?.find(
        (entitlement) => entitlement.seller_user_id === contact.id,
      ),
    }

    dispatch(
      openDialog({
        dialogEnum: DialogEnum.CREATE_SELLER_CONTACT,
        componentProps,
      }),
    )
  }

  const getUserRolesMessage = () => {
    if (!contacts || (!successFlag && completed)) return null

    let title = successFlag
      ? 'Admin Action Complete: Responsibilities Added'
      : 'Admin Action Required: Responsibilities Missing'
    let type: 'success' | 'alert' = successFlag ? 'success' : 'alert'
    let testId = successFlag ? 'added' : 'missing'

    return (
      <Message title={title} type={type}>
        <StyledDiv data-testid={`responsibilities-${testId}`}>
          Admins can click
          {hasEdit && (
            <StyledEditButton
              dialogComponent={DialogEnum.CREATE_SELLER_CONTACT}
              disabled={isPending}
              color="primary"
            >
              Add user
            </StyledEditButton>
          )}
          {!hasEdit && <StyledText>Add user</StyledText>}
          or click
          <MoreVert /> to Manage Details and setup at least 1 or more contacts
          responsible for:
        </StyledDiv>
        <UserResponsibilities
          responsibilities={getAllResponsibilities(contacts)}
        />
      </Message>
    )
  }

  const hasEdit = isOneOfUserRoles(memberOf, [
    USER_ROLE_ADMIN,
    USER_ROLE_OPS,
    USER_ROLE_APP_SMS_ADMIN,
  ])

  return (
    <div data-testid="seller-users">
      {getUserRolesMessage()}
      <TitleBar
        hideDivider
        title="Portal Users and Support Contacts"
        actionButtons={
          hasEdit
            ? [
                <Button
                  key="add-user-button"
                  data-testid="add-user-button"
                  onClick={handleAddContact}
                  color="primary"
                  variant="contained"
                >
                  <AddIcon />
                  Add User
                </Button>,
              ]
            : undefined
        }
      />
      <ContentSpacer>
        {(!contacts || !contacts.length) && (
          <RequiredContent
            description="No users added."
            hasRequiredText={false}
          />
        )}
        {contacts && (
          <TableSpacer>
            <UserTable
              users={contacts}
              isPending={isPending}
              isDefunct={isDefunct}
              onDelete={onDeleteEntitlement}
              onEdit={onEditContact}
            />
          </TableSpacer>
        )}
      </ContentSpacer>
    </div>
  )
}

export default UserContent
