import { useState, useEffect } from 'react'

import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Chip from '@mui/material/Chip'

import DataList, { DataListItem } from 'components/common/DataList'
import { DialogEnum } from 'components/common/Dialog'
import HeaderTitle from 'components/common/HeaderTitle'
import TypeaheadFilter from 'components/common/FilterBar/TypeaheadFilter'
import TextFilter from 'components/common/FilterBar/TextFilter'
import {
  TableState,
  useSearchParams,
} from 'components/common/FilterBar/useSearchParams'

import { primary, success } from 'config/themeConfig'

import { getMessagesForTimeline, TopicMessageWrapper } from 'services/beetle'

import { openDialog } from 'store/dialog/actionCreator'

import { HeliosTopicMessage, SmsTopicMessage } from 'types/Beetle'

type SearchParams = {
  tcin: string | undefined
  productId: string | undefined
}

type UrlSearchParams = SearchParams & TableState

const initialSearchParams = {
  perPage: 1,
  page: 0,
  tcin: undefined,
  productId: undefined,
}

const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'latest',
})<{ latest: boolean | undefined }>((props) => ({
  marginLeft: props.theme.spacing(1),
  borderColor: props.latest ? success.main : undefined,
  color: props.latest ? success.main : undefined,
}))

const StyledPathLine = styled(TableCell)(({ theme }) => ({
  padding: 0,
  width: 30,
  borderRight: `2px solid ${theme.palette.primary.main}`,
}))

const StyledPathOpposite = styled(TableCell)({
  padding: 0,
  width: 30,
})

const StyledIndicator = styled('line')(({ theme }) => ({
  stroke: theme.palette.primary.main,
  strokeWidth: 2,
}))

const StyledIndicatorRight = styled('svg')({
  marginLeft: 6,
  marginRight: -5,
})

const StyledIndicatorLeft = styled('svg')({ marginLeft: -5 })

const StyledDataList = styled(DataList)({ display: 'inline-block' })

const TimelineChip = ({
  label,
  latest,
}: {
  label: string
  latest?: boolean
}) => {
  return (
    <StyledChip latest={latest} label={label} variant="outlined" size="small" />
  )
}

const TimelinePage = () => {
  const reduxDispatch = useDispatch()
  const [searchParams, searchParamActions] =
    useSearchParams<UrlSearchParams>(initialSearchParams)

  const [isLoading, setIsLoading] = useState(false)
  const [messages, setMessages] = useState<TopicMessageWrapper[]>([])

  useEffect(() => {
    let mounted = true

    const getMessagesByParams = async (params: SearchParams) => {
      if (params.productId || params.tcin) {
        setIsLoading(true)
        setMessages([])

        const messages = await getMessagesForTimeline({
          productId: params.productId,
          tcin: params.tcin,
        })

        if (mounted) {
          if (messages) {
            setMessages(messages)
          }
          setIsLoading(false)
        }
      } else {
        setMessages([])
      }
    }

    getMessagesByParams({
      productId: searchParams.productId,
      tcin: searchParams.tcin,
    })

    return () => {
      mounted = false
    }
  }, [searchParams.productId, searchParams.tcin])

  const createDataBlock = (wrapper: TopicMessageWrapper) => {
    let data: HeliosTopicMessage | SmsTopicMessage
    let dataList: DataListItem[] = []

    if (wrapper.source === 'Helios') {
      data = wrapper.data as HeliosTopicMessage
      dataList = [
        {
          title: 'Time',
          value: data.message.update_datetime,
        },
        {
          title: 'Product Status ID',
          element: (
            <div>
              {data.message.product_status_id}
              {wrapper.isCurrentProductStatus && (
                <TimelineChip label="Current" />
              )}
              {wrapper.isLatestProductStatus && (
                <TimelineChip latest label="Latest" />
              )}
            </div>
          ),
        },
        {
          title: 'Helios Status',
          value: data.message.helios_status,
        },
        {
          title: 'Version',
          value: data.message.version,
        },
      ]
    } else {
      data = wrapper.data as SmsTopicMessage
      dataList = [
        {
          title: 'Time',
          value: data.message.updated_datetime,
        },
        {
          title: 'Product Status ID',
          element: (
            <div>
              {data.message.product_status_id}
              {wrapper.isCurrentProductStatus && (
                <TimelineChip label="Current" />
              )}
              {wrapper.isLatestProductStatus && (
                <TimelineChip latest label="Latest" />
              )}
            </div>
          ),
        },
        {
          title: 'Validation Status',
          value: data.message.validation_status,
        },
        {
          title: 'Listing Status',
          value: data.message.listing_status,
        },
        {
          title: 'Version',
          value: data.message.version,
        },
      ]
    }

    return (
      <>
        <StyledDataList key={wrapper.date} data={dataList} />
        <br />
        <Button
          onClick={() => {
            reduxDispatch(
              openDialog({
                dialogEnum: DialogEnum.MESSAGE_DETAILS_DIALOG,
                componentProps: {
                  timelineMessage: data,
                },
              }),
            )
          }}
          color="primary"
          variant="outlined"
          size="small"
          data-testid="detail-button"
        >
          See Details
        </Button>
      </>
    )
  }

  const createIndicators = (source: string) => {
    if (source === 'Helios') {
      return (
        <>
          <StyledPathLine>
            <StyledIndicatorRight height="10" width="30">
              <StyledIndicator x1="-5" y1="5" x2="30" y2="5"></StyledIndicator>
              <circle cx="26" cy="5" r="4" fill={primary.main}></circle>
            </StyledIndicatorRight>
          </StyledPathLine>
          <StyledPathOpposite />
        </>
      )
    } else {
      return (
        <>
          <StyledPathLine />
          <StyledPathOpposite>
            <StyledIndicatorLeft height="10" width="30">
              <StyledIndicator x1="0" y1="5" x2="35" y2="5"></StyledIndicator>
              <circle cx="4" cy="5" r="4" fill={primary.main}></circle>
            </StyledIndicatorLeft>
          </StyledPathOpposite>
        </>
      )
    }
  }

  return (
    <div>
      <HeaderTitle title="Timeline" />
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextFilter
              id="product-id"
              fullWidth={true}
              label="Product ID"
              value={searchParams.productId}
              searchParam="productId"
              onChange={searchParamActions.updateSearchParam}
              paramsToClearOnChange={['tcin']}
            />
          </Grid>
          <Grid item xs={12}>
            <TypeaheadFilter
              label="TCIN"
              value={searchParams.tcin}
              searchParam="tcin"
              paramsToClearOnChange={['productId']}
              onChange={searchParamActions.updateSearchParam}
            />
          </Grid>
        </Grid>
      </div>
      {isLoading && <LinearProgress variant="indeterminate" />}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="right">Helios</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>SMS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {messages.length > 0 &&
            messages.map((message, index) => (
              <TableRow key={index}>
                <TableCell align="right">
                  {message.source === 'Helios'
                    ? createDataBlock(message)
                    : null}
                </TableCell>
                {createIndicators(message.source)}
                <TableCell>
                  {message.source === 'SMS' ? createDataBlock(message) : null}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default TimelinePage
