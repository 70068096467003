import { Field, FieldProps } from 'formik'

import styled from '@emotion/styled'

import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

import { MarketplaceProduct } from 'types/Item'
import { ItemTypeahead } from 'components/common/Typeahead'

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  component: 'legend',
  fontSize: '0.725rem',
  marginBottom: theme.spacing(1),
}))

interface FormProps {
  name: string
  required: boolean
  isDisabled: boolean
  label?: string
  itemTypeId?: string
  sellerId?: string
}

const onFieldChange =
  (name: string, setFieldValue: any) =>
  (event: Nullable<MarketplaceProduct>) => {
    setFieldValue(name, event)
  }

const ItemTypeaheadField = ({
  name,
  required,
  isDisabled,
  label,
  itemTypeId,
  sellerId,
  ...props
}: FormProps) => (
  <Field name={name}>
    {({ form, field }: FieldProps) => {
      const { setFieldValue } = form

      return (
        <FormControl fullWidth required={required} disabled={isDisabled}>
          {label && <StyledFormLabel>{label}</StyledFormLabel>}
          <ItemTypeahead
            {...props}
            {...field}
            placeholder={'Search TCIN, within this Item Type'}
            itemTypeId={itemTypeId}
            sellerId={sellerId}
            onChange={onFieldChange(name, setFieldValue)}
          />
        </FormControl>
      )
    }}
  </Field>
)

export default ItemTypeaheadField
