import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'

import MuiInput from '@mui/material/Input'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import styled from '@emotion/styled'

import Input from 'components/common/Input'

import reducer, {
  initialState,
  setDescription,
  setNewToken,
  setValidation,
} from './EditApiTokensReducer'
import getValidationSchema from './getValidationSchema'

import { createApiToken } from 'services/apiTokens'
import { validationHandler } from 'services/validation'
import { ApiToken } from 'types/ApiTokens'

const StyledButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
}))

const StyledWarning = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
}))

const StyledDescription = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginBottom: theme.spacing(1),
}))

const StyledInput = styled('div')({
  width: '100%',
})

export interface Props {
  sellerId: string
  tokens?: ApiToken[]
  onCloseAside: () => void
  onTokenGenerated: () => void
}

export const AddApiTokensAside = ({
  sellerId,
  tokens,
  onCloseAside,
  onTokenGenerated,
}: Props) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const handleChange = (value: Nullable<string>) => {
    const { validation, isValid } = validationHandler(
      getValidationSchema(tokens),
      { description: value },
    )

    dispatch(setDescription(value || ''))
    dispatch(setValidation(validation, isValid))
  }

  const handleCancel = () => {
    let confirm = true
    if (state.newToken) {
      confirm = window.confirm(
        'Remember to store your token somewhere secure, the full token will never be displayed again. Are you sure you want to proceed?',
      )
    }

    if (confirm) {
      dispatch(setNewToken(undefined))
      dispatch(setDescription(''))
      onCloseAside()
    }
  }

  const handleGenerate = () => {
    if (!state.isValid) return

    createApiToken(sellerId, state.description).then((newToken) => {
      dispatch(setNewToken(newToken))
      onTokenGenerated()
    })
  }

  return (
    <div>
      <Input
        id="description"
        name="description"
        helperText="Short Description ex: Integrator"
        data-testid="description-input"
        onChange={handleChange}
        validation={state.validation}
        isDisabled={!!state.newToken}
        isRequired
        value={state.description}
      />
      <StyledButtonContainer>
        <Button color="primary" onClick={handleCancel} data-testid="cancel">
          Close
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleGenerate}
          data-testid="generate"
          disabled={!!state.newToken || !state.isValid}
        >
          Generate Token
        </Button>
      </StyledButtonContainer>
      {state.newToken && state.newToken.token && (
        <React.Fragment>
          <StyledWarning>
            Copy the token, and paste it somewhere secure. For security seasons,
            once you close this panel, the full token will never be displayed
            again.
          </StyledWarning>
          <StyledDescription>Description</StyledDescription>
          <Typography>{state.newToken.description}</Typography>
          <StyledInput>
            <MuiInput
              id="newToken"
              name="newToken"
              value={state.newToken.token}
              disabled
              data-testid="new-token-input"
            />
          </StyledInput>
          <CopyToClipboard text={state.newToken.token}>
            <StyledButtonContainer>
              <Button color="primary" variant="contained">
                Copy
              </Button>
            </StyledButtonContainer>
          </CopyToClipboard>
        </React.Fragment>
      )}
    </div>
  )
}

export default AddApiTokensAside
