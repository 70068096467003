import { Grid } from '@mui/material'
import Link from '@mui/material/Link'

import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { getFilenameFromId, saveAttachment } from 'services/cases'

interface Props {
  sellerId: string
  attachmentId: string
}

export const DownloadAttachment = ({ sellerId, attachmentId }: Props) => {
  const attachmentName = getFilenameFromId(attachmentId)

  return (
    <Grid container>
      <Link
        data-testid="download-attachment"
        onClick={() => saveAttachment(sellerId, attachmentId)}
      >
        <Grid container spacing={1}>
          <Grid item>
            <CloudDownloadIcon color="primary" />
          </Grid>
          <Grid item>{attachmentName}</Grid>
        </Grid>
      </Link>
    </Grid>
  )
}

export default DownloadAttachment
