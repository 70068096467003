import difference from 'lodash/fp/difference'
import MessageWithPopover from './MessageWithPopover'
import { MarketplaceProduct, RelationshipType } from 'types/Item'
import SimpleMessage from './SimpleMessage'

interface Props {
  refinements: string[]
  product: MarketplaceProduct
  variationChildren: MarketplaceProduct[]
  variationTheme: string
}

const getRefinements = (child: MarketplaceProduct) => {
  return child.variations ? child.variations.map((it) => it.refinement) : []
}

export const ThemeAlignment = ({
  refinements,
  product,
  variationChildren,
  variationTheme,
}: Props) => {
  const { relationship_type } = product

  if (relationship_type === RelationshipType.VC) {
    const childRefinements = getRefinements(product)
    const theme = childRefinements.join(' & ')
    if (difference(refinements, childRefinements).length === 0) {
      return <SimpleMessage isError={false} message={`All align (${theme})`} />
    } else {
      return <SimpleMessage isError message={`${theme} - Does not align`} />
    }
  }

  const childrenWithConflicts = variationChildren
    .filter((child) => {
      return difference(refinements, getRefinements(child)).length > 0
    })
    .map((child) => {
      return {
        tcin: child.tcin,
        theme: difference(refinements, getRefinements(child)).join(' & '),
      }
    })

  if (
    relationship_type === RelationshipType.VAP &&
    childrenWithConflicts.length === 0
  ) {
    return (
      <SimpleMessage
        isError={false}
        message={`All align (${variationTheme})`}
      />
    )
  }

  return (
    <MessageWithPopover
      body={`VAP Variation Theme: ${refinements.join(' & ')}`}
      data={childrenWithConflicts}
      errorMessage={`${childrenWithConflicts.length} VC conflicts`}
      fieldList={[
        { key: 'tcin', heading: 'TCIN', hasSort: false },
        { key: 'theme', heading: 'Attribute', hasSort: false },
      ]}
      title={'VC Theme Conflicts'}
    />
  )
}
