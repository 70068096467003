import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import EligibleStateCodeContent from '../EligibleStateCodeContent'

import { editSellerDistributionCenter } from 'store/seller/actionCreators'

import { StateCode } from 'types/Enumeration'
import { SmsDistributionCenter } from 'types/Seller'
import { getStateCodes } from 'store/selectors'
import { StateCodeTypeahead } from 'components/common/Typeahead'

interface Props {
  isOpen: boolean
  isPending: boolean
  distributionCenter: Partial<SmsDistributionCenter>
}

export const EditEligibleStates = ({
  distributionCenter,
  isOpen,
  isPending,
}: Props) => {
  const dispatch = useDispatch()

  const twoDayStateCodes = distributionCenter.two_day_state_codes || []
  const [eligibleStateCodes, setEligibleStateCodes] = useState(twoDayStateCodes)
  const stateCodes = useSelector(getStateCodes)

  const handleDelete = (code: string) => {
    const updatedStateCodes = eligibleStateCodes.filter(
      (stateCode) => stateCode !== code,
    )
    setEligibleStateCodes(updatedStateCodes)
  }

  const handleAdd = (selectedStateCodes: Nullable<StateCode[]>) => {
    const selectedCodes = selectedStateCodes
      ? selectedStateCodes.map((x) => x.value)
      : []
    setEligibleStateCodes(selectedCodes)
  }

  const handleSubmit = () => {
    const updatedDistributionCenter = {
      ...distributionCenter,
      two_day_state_codes: eligibleStateCodes,
    }

    dispatch(editSellerDistributionCenter(updatedDistributionCenter))
  }

  const filteredStateCodes = eligibleStateCodes.map((selectedCode) => {
    return stateCodes.find((sc) => sc.value === selectedCode)
  })

  return (
    <DialogContainer
      title="Edit Eligible States"
      isOpen={isOpen}
      isPending={isPending}
      onSubmit={handleSubmit}
      maxWidth={false}
      disableScroll
    >
      <StateCodeTypeahead
        onChange={handleAdd}
        eligibleStateCodes={filteredStateCodes as Nullable<StateCode[]>}
      />
      <EligibleStateCodeContent
        eligibleStateCodes={eligibleStateCodes}
        handleDelete={handleDelete}
      />
    </DialogContainer>
  )
}

export default EditEligibleStates
