import {
  parse,
  format,
  isValid,
  isAfter,
  isBefore,
  isEqual,
  startOfYear,
  startOfMonth,
  startOfQuarter,
  endOfYear,
  endOfMonth,
  endOfQuarter,
  sub,
  subQuarters,
  startOfDay as startOfDayDateFns,
  endOfDay as endOfDayDateFns,
  isWithinInterval,
  addYears,
  subYears,
  add,
  parseISO,
  differenceInDays as differenceInDaysDateFns,
} from 'date-fns'

import enUsLocale from 'date-fns/locale/en-US'

import { getCSTDateAndTimeNow } from 'utilities/date'

// date-fns formatting: https://date-fns.org/v1.30.1/docs/format
export const DATE_FORMAT_MONTH_DAY_YEAR_TIME = 'MMM do yyyy, h:mm a'
export const DATE_PICKER_FORMAT = 'yyyy-MM-dd'
export const DATE_DISPLAY_FORMAT = 'MM/dd/yyyy'
export const DATE_DISPLAY_FORMAT_TIME = 'MM/dd/yyyy hh:mm a'
export const DATE_FORMAT_MONTH_DAY_YEAR = 'MMMM do yyyy'
export const DATE_FORMAT_MONTH_DAY = 'MMM d'
export const DATE_FORMAT_YEAR_MONTH_DAY_TIME = 'yyyy-MM-dd HH:mm:ss'
export const DATE_FORMAT_ABBREVIATED_MONTH_DAY_YEAR = 'MMM do, yyyy'
export const DATE_FORMAT_YEAR = 'yyyy'
export const TIME_FORMAT_12_HOUR = 'h:mm'

export function militaryToPeriodTime(time?: string): string {
  if (!time) {
    return ''
  }

  const parts = time.split(':')
  const militaryFormat = parts.length === 2 ? 'HH:mm' : 'HH:mm:ss'

  const parsedTime = parse(time, militaryFormat, new Date())
  return format(parsedTime, 'h:mm a')
}

function isISOString(str: string) {
  // Regular expression to match ISO 8601 format
  const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z?$/
  return isoRegex.test(str)
}

export function formatDate(
  date: string | number | Date | undefined,
  formatStr: string,
  inputFormat?: string,
): string {
  if (!date) {
    return ''
  }

  if (typeof date === 'number' || typeof date === 'object') {
    return format(date, formatStr)
  } else {
    return isISOString(date)
      ? format(new Date(date), formatStr)
      : format(
          parse(date, inputFormat ? inputFormat : '', new Date()),
          formatStr,
        )
  }
}

export function formatDateLocalTime(
  date: string | number | Date | undefined,
  formatStr: string,
  inputFormat?: string,
): string {
  if (!date) {
    return ''
  }
  if (typeof date === 'number' || typeof date === 'object') {
    return format(date, formatStr)
  } else {
    return inputFormat
      ? format(
          parse(date, inputFormat ? inputFormat : '', new Date()),
          formatStr,
        )
      : format(new Date(date), formatStr)
  }
}

export function parseDate(
  str: string | undefined,
  format = DATE_DISPLAY_FORMAT,
) {
  if (typeof str === 'string') {
    const parsedDate = parse(str, format, new Date(), { locale: enUsLocale })

    if (isValid(parsedDate)) {
      return parsedDate
    }
  }

  return undefined
}

export function endOfYesterday(date: string | number | Date | undefined) {
  return sub(startOfDay(date), { seconds: 1 })
}

export function endOfToday(date: string | number | Date | undefined) {
  return endOfDay(date)
}

export function formatDateString(
  date: string,
  inputFormat: string,
  formatStr: string,
): string {
  const parsedDate = parse(date, inputFormat, new Date())
  return format(parsedDate, formatStr)
}

export function isValidAndAfterToday(date: string, formatStr: string): boolean {
  const parsedDate = parse(date, formatStr, new Date())
  return isValid(parsedDate) && isAfter(parsedDate, new Date())
}

export function datesEqual(
  date1?: Nullable<Date | number>,
  date2?: Nullable<Date | number>,
): boolean {
  if (!date1 && !date2) {
    return true
  }
  if (!date1 || !date2) {
    return false
  }

  return isEqual(date1 as Date, date2 as Date)
}

export function formatDateRange(
  startDate: string | number | Date | undefined,
  endDate: string | number | Date | undefined,
): string {
  let from = ''
  let to = ''
  if (startDate) {
    from = isoStringStartOfDay(startDate, DATE_DISPLAY_FORMAT)
  }
  if (endDate) {
    to = `/${isoStringEndOfDay(endDate, DATE_DISPLAY_FORMAT)}`
  }
  if (from && to) {
    return `${from}${to}`
  }
  return from || to
}

export function getDateBefore(
  date: Date,
  amount: number,
  unit: string = 'days',
): Date {
  return unit === 'quarters'
    ? subQuarters(date, amount)
    : sub(date, { [unit]: amount })
}

export function getDateAfter(
  date: Date,
  amount: number,
  unit: 'days' | 'weeks' | 'months' | 'years' = 'days',
): Date {
  return add(date, { [unit]: amount })
}

export function startOf(date: Date, unit: string): Date {
  switch (unit) {
    case 'days':
      return startOfDay(date)
    case 'year':
      return startOfYear(date)
    case 'month':
      return startOfMonth(date)
    case 'quarter':
      return startOfQuarter(date)
    default:
      return startOfDay(date)
  }
}

export function endOf(date: Date, unit: string): Date {
  switch (unit) {
    case 'days':
      return endOfDay(date)
    case 'year':
      return endOfYear(date)
    case 'month':
      return endOfMonth(date)
    case 'quarter':
      return endOfQuarter(date)
    default:
      return endOfDay(date)
  }
}

export function parseDateRange(
  range: string | undefined,
): Array<Date | undefined> {
  if (!range) return []

  const [start, end] = range.split('/')
  const startDate = start ? new Date(start) : undefined
  const endDate = end ? new Date(end) : undefined

  return [startDate, endDate]
}

export function startOfDay(
  date: Date | number | string | undefined,
  format?: string,
): Date {
  if (date === undefined) return new Date()
  else if (format && typeof date === 'string') {
    return startOfDayDateFns(parse(date, format, new Date()))
  } else {
    return startOfDayDateFns(new Date(date))
  }
}

export function endOfDay(
  date: Date | number | string | undefined,
  format?: string,
): Date {
  if (date === undefined) return new Date()
  else if (format && typeof date === 'string') {
    return endOfDayDateFns(parse(date, format, new Date()))
  } else {
    return endOfDayDateFns(new Date(date))
  }
}

export function isoStringStartOfDay(
  date: Date | number | string | undefined,
  format?: string,
): string {
  return startOfDay(date, format).toISOString()
}

export function isoStringEndOfDay(
  date: Date | number | string | undefined,
  format?: string,
): string {
  return endOfDay(date, format).toISOString()
}

export function isAfterDate(to: Date, from: Date): boolean {
  return isAfter(to, from)
}

export function isBeforeDate(to: Date, from: Date): boolean {
  return isBefore(from, to)
}

export const differenceInDays = (from: Date, to: Date) => {
  return Math.abs(differenceInDaysDateFns(from, to))
}

export const getDateOneYearAgo = () => {
  return new Date(subYears(new Date(), 1))
}

export const getDateOneYearFromToday = () => {
  return new Date(addYears(new Date(), 1))
}

export function isBetweenDates(date: Date, start: Date, end: Date): boolean {
  return isWithinInterval(date, { start, end })
}

export const getTimePeriod = (str: string): undefined | 'AM' | 'PM' => {
  if (!str) {
    return
  }

  const date = new Date(str)
  const period = format(date, 'a')

  if (period === 'AM' || period === 'PM') {
    return period.toUpperCase() as 'AM' | 'PM'
  }
}

export const getTimeIn12Hour = (str: string) => {
  if (!str) {
    return
  }

  const date = new Date(str)
  return format(date, TIME_FORMAT_12_HOUR)
}

export const getIsoString = (str?: string): string | undefined | null => {
  if (!str) {
    return
  }

  return new Date(str).toISOString()
}

export const formatISODate = (date: string, formatString: string) => {
  if (!date) {
    return
  }

  const [year, month, day] = date.slice(0, 10).split('-').map(Number)

  return format(new Date(year, month - 1, day), formatString)
}

export const assignDiscountStatus = (data: any) => {
  const currentCSTDateAndTime = getCSTDateAndTimeNow()
  return data.map((item: { start_date: string; end_date: string }) => {
    const startDate = item?.start_date ? parseISO(item.start_date) : null
    const endDate = item?.end_date ? parseISO(item.end_date) : null

    if (!startDate) {
      return {
        ...item,
        status: null,
      }
    }

    let status
    if (
      isAfter(startDate, currentCSTDateAndTime) ||
      (endDate && isBefore(endDate, currentCSTDateAndTime))
    ) {
      status = 'INACTIVE'
    } else {
      status = 'ACTIVE'
    }

    return { ...item, status }
  })
}
