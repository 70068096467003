import FullScreenDialogContainer from 'components/common/Dialog/FullScreenDialogContainer'
import { useDispatch } from 'react-redux'

import { UpdatedPromotion } from 'types/Promotion'
import { closeDialog } from 'store/dialog/actionCreator'

import EditPromotion from '../EditPromotion'

interface EditPromotionModalProps {
  isOpen: boolean
  promotion: UpdatedPromotion
}

export const EditPromotionModal = ({
  isOpen,
  promotion,
}: EditPromotionModalProps) => {
  const dispatch = useDispatch()

  const onRequestClose = () => {
    dispatch(closeDialog())
  }

  return (
    <FullScreenDialogContainer
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      title="Edit Promotion"
    >
      <EditPromotion promotion={promotion} />
    </FullScreenDialogContainer>
  )
}

export default EditPromotionModal
