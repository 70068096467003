import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as yup from 'yup'

import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import Input from 'components/common/Input'

import { isReviewed } from 'services/reviewIndicators'
import { approvedToListStatuses } from 'services/seller'
import { validationHandler, ValidationSchema } from 'services/validation'

import { ReviewIndicators, SmsSeller } from 'types/Seller'
import { Validation } from 'types/Validation'

import { currentSeller, isSellersPending } from 'store/selectors'
import { editSeller, editReviewIndicator } from 'store/seller/actionCreators'

export const reviewIndicatorText = 'Privacy policy has been reviewed'

interface Props {
  isOpen: boolean
  canReview: boolean
}

const validationSchema: ValidationSchema = yup.object().shape({
  privacyPolicy: yup.string().label('Privacy policy').required(),
})

export const EditPrivacyPolicy = ({ isOpen, canReview }: Props) => {
  const seller = useSelector(currentSeller)
  const isPending = useSelector(isSellersPending)

  const dispatch = useDispatch()

  const [privacyPolicy, setPrivacyPolicy] = useState(
    seller?.privacy_policy || '',
  )
  const [reviewed, setReviewed] = useState<boolean>(
    isReviewed(seller?.review_indicators, ReviewIndicators.PRIVACY_POLICY),
  )
  const [validation, setValidation] = useState<Validation>({})

  const handleInputChange = (value: string) => {
    setPrivacyPolicy(value)
  }

  const handleReviewChanged = (event: any, checked: boolean) => {
    if (event) {
      setReviewed(checked)
    }
  }

  const handleInputBlur = (value: string) => {
    const trimmedValue = value.trim()
    setPrivacyPolicy(trimmedValue)
  }

  const handleSubmit = () => {
    const { validation: newValidation, isValid } = validationHandler(
      validationSchema,
      { privacyPolicy },
    )
    setValidation(newValidation)

    if (!seller || !isValid) return

    const newSeller: SmsSeller = {
      ...seller,
      privacy_policy: privacyPolicy,
    }

    // If we are approving, use editReviewIndicator instead of editSeller
    const oldStatus = isReviewed(
      seller.review_indicators,
      ReviewIndicators.PRIVACY_POLICY,
    )
    if (
      reviewed !== oldStatus &&
      !approvedToListStatuses.includes(seller?.status)
    ) {
      dispatch(
        editReviewIndicator(
          newSeller,
          ReviewIndicators.PRIVACY_POLICY,
          reviewed,
        ),
      )
    } else {
      dispatch(editSeller(newSeller))
    }
  }

  return (
    <DialogContainer
      title="Edit Privacy Policy"
      isOpen={isOpen}
      subtitle="The partner privacy policy that appears on Target.com."
      isPending={isPending}
      onSubmit={handleSubmit}
    >
      <Grid container>
        <Grid item xs={12}>
          <Input
            isRequired
            isMultiline
            id="privacy-policy"
            name="privacyPolicy"
            label="Privacy Policy"
            isDisabled={isPending}
            value={privacyPolicy}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            validation={validation}
          />
          {canReview && (
            <FormControlLabel
              control={
                <Checkbox
                  id="privacy-policy-reviewed"
                  data-testid="privacy-policy-reviewed"
                  checked={reviewed}
                  onChange={handleReviewChanged}
                  inputProps={{
                    'aria-label': reviewIndicatorText,
                  }}
                />
              }
              label={reviewIndicatorText}
            />
          )}
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export default EditPrivacyPolicy
