import { useEffect, useState } from 'react'

import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'

import EnhancedTable from 'components/common/EnhancedTable'
import { formatDateMDYT } from 'components/common/EnhancedTable/formatters'
import Link from 'components/common/Link'
import { TableHook } from 'components/common/EnhancedTable/useTable'
import TableSpacer from 'components/common/TableSpacer'

import { trackCustomEvent } from 'services/fireflyInsights'
import { getAttributeValuesById } from 'services/itemTaxonomies'

import { AttributeValue } from 'types/AttributeValue'
import { FireflyEvent } from 'types/FireflyInsights'
import { SeasonalEvent } from 'types/SeasonalEvent'

const StyledTitleContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignContent: 'center',
})

export type Filters = {
  start_date: string | undefined
  end_date: string | undefined
}

interface Props {
  handleEventAddEdit: (seasonalEvent?: SeasonalEvent) => void
  seasonalEvents: SeasonalEvent[]
  pending: boolean
  total: number
  table: TableHook
}

export const SeasonalEventsList = ({
  handleEventAddEdit,
  seasonalEvents,
  pending,
  total,
  table,
}: Props) => {
  const [seasonalValues, setSeasonalValues] = useState<AttributeValue[]>([])

  const fieldList = [
    {
      key: 'name',
      heading: 'Event Name & Guest Mindset',
      formatCell: (item: SeasonalEvent) => {
        return (
          <>
            <Link
              onClick={(event) => {
                event.preventDefault()
                handleEventAddEdit(item)
              }}
              to=""
            >
              {item.name}
            </Link>
            <Typography>{item.description}</Typography>
          </>
        )
      },
    },
    {
      key: 'seasonal_attribute_value_id',
      heading: 'Value for "Season or event depicted"',
      formatCell: (item: SeasonalEvent) => {
        const season = seasonalValues.find(
          (sv) => sv.id === item.seasonal_attribute_value_id,
        )
        if (season) {
          return <Typography>{season.name}</Typography>
        } else {
          return <Typography>{item.seasonal_attribute_value_id}</Typography>
        }
      },
    },
    {
      key: 'start_date',
      heading: 'Start Date',
      hasSort: true,
      formatCell: formatDateMDYT('start_date'),
    },
    {
      key: 'end_date',
      heading: 'End Date',
      hasSort: true,
      formatCell: formatDateMDYT('end_date'),
    },
    {
      key: 'suggested_assortment',
      heading: 'Suggested Assortment',
    },
  ]

  useEffect(() => {
    trackCustomEvent(FireflyEvent.EVENTS_LIST, 'event', 'click')
  }, [])

  useEffect(() => {
    let mounted = true

    let seasonalAttributeIds: string[] = []

    seasonalEvents.forEach((seasonalEvent) => {
      if (
        seasonalEvent.seasonal_attribute_value_id &&
        !seasonalAttributeIds.includes(
          seasonalEvent.seasonal_attribute_value_id,
        )
      ) {
        seasonalAttributeIds.push(seasonalEvent.seasonal_attribute_value_id)
      }
    })

    getAttributeValuesById(seasonalAttributeIds).then((seasonalValues) => {
      if (mounted) {
        setSeasonalValues(seasonalValues)
      }
    })

    return () => {
      mounted = false
    }
  }, [seasonalEvents])

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StyledTitleContainer>
            <Typography>
              Event dates are approximate and subject to change by Target.
            </Typography>
          </StyledTitleContainer>
        </Grid>
        <Grid item xs={12}>
          <TableSpacer />
          <EnhancedTable
            onChangePage={table.actions.changePage}
            onChangeRowsPerPage={table.actions.changePerPage}
            order={table.state.direction}
            page={table.state.page}
            rowsPerPage={table.state.perPage}
            total={total}
            data={seasonalEvents}
            fieldList={fieldList}
            onRequestSort={table.actions.sort}
            orderBy={table.state.orderBy}
            isLoading={pending}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default SeasonalEventsList
