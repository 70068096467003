import { useEffect, useState } from 'react'

import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import {
  formatDateMDYT,
  orderStatus,
} from 'components/common/EnhancedTable/formatters'
import TableSpacer from 'components/common/TableSpacer'

import { getOrderHistory } from 'services/orders'
import { OrderHistory } from 'types/Orders'

export interface Props {
  sellerId: string
  orderId: string
}

export const OrderHistories = ({ sellerId, orderId }: Props) => {
  const [pending, setPending] = useState(false)
  const [histories, setHistories] = useState([] as OrderHistory[])

  useEffect(() => {
    setPending(true)
    getOrderHistory(sellerId, orderId)
      .then((res: OrderHistory[]) => {
        setHistories(res)
      })
      .finally(() => {
        setPending(false)
      })
  }, [sellerId, orderId])

  const fieldList: EnhancedTableFieldType<OrderHistory>[] = [
    {
      key: 'event_timestamp',
      heading: 'Date',
      hasSort: true,
      formatCell: formatDateMDYT('event_timestamp'),
    },
    {
      key: 'status',
      heading: 'Status',
      hasSort: true,
      formatCell: orderStatus('status'),
    },
  ]

  return (
    <TableSpacer>
      <EnhancedTable
        data-testid="histories-table"
        data={histories}
        fieldList={fieldList}
        isLoading={pending}
        total={histories.length}
      />
    </TableSpacer>
  )
}

export default OrderHistories
