import axios from 'axios'

import apiConfig from 'config/apiConfig'
import { InternalTaxSetting } from 'types/Seller'

export function updateInternalTaxSetup(
  sellerId: string,
  setupComplete: boolean,
): Promise<InternalTaxSetting> {
  return axios
    .put(`${apiConfig.sms}/seller_internal_tax_settings/${sellerId}`, {
      setup_complete: setupComplete,
    })
    .then((res) => res.data)
}
