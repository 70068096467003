import * as yup from 'yup'
import isNil from 'lodash/fp/isNil'

import {
  isAfterDate,
  getDateAfter,
  endOfToday,
  formatDate,
  DATE_DISPLAY_FORMAT,
} from 'services/dateService'

const getValidationSchema = ({
  fromId = 'from',
  toId = 'to',
  dayRange,
  minDate = new Date(2017, 0, 1),
  minDateError = `Must be after ${formatDate(minDate, DATE_DISPLAY_FORMAT)}`,
  maxDate = new Date(),
  maxDateError = 'Must be today or earlier',
}: {
  fromId?: string
  toId?: string
  dayRange?: number
  minDate?: Date
  minDateError?: string
  maxDate?: Date
  maxDateError?: string
} = {}) => {
  return yup.object().shape({
    [fromId]: yup.date().min(minDate, minDateError).max(maxDate, maxDateError),
    [toId]: yup
      .date()
      .min(minDate, minDateError)
      .max(endOfToday(maxDate), maxDateError)
      .test({
        name: 'isAfter',
        message: 'Must be after "from" date',
        test(this: yup.TestContext, value: any) {
          const fromDate = this.resolve(yup.ref(fromId)) as Date
          // If from or to are not defined, auto-pass
          if (!fromDate || !value) {
            return true
          } else {
            return isAfterDate(endOfToday(value), fromDate)
          }
        },
      })
      .test({
        name: 'withinRange',
        message: `Date range cannot exceed ${dayRange} days`,
        test(this: yup.TestContext, value: any) {
          const fromDate = this.resolve(yup.ref(fromId)) as Date
          // If from or range is not defined, auto-pass
          if (!fromDate || isNil(dayRange)) {
            return true
          } else {
            const rangeEnd = getDateAfter(fromDate, dayRange)
            return isAfterDate(rangeEnd, value)
          }
        },
      }),
  })
}

export default getValidationSchema
