import { TableState } from 'components/common/FilterBar/useSearchParams'

export const ALL_ITEMS = 'ALL_ITEMS'
export const PRODUCT_COUNT_PUBLISHED = 'PRODUCT_COUNT_PUBLISHED'
export const PRODUCT_COUNT_TOTAL = 'PRODUCT_COUNT_TOTAL'

export const getAllItemsQueryKeys = (
  searchParams: TableState & {
    item_type_id: string | undefined
    published: string | undefined
    listing_status: string[] | undefined
    has_inventory: string | undefined
    relationship_type: string | undefined
  },
  sellerId?: string,
) => [ALL_ITEMS, sellerId, searchParams]

export const getSellerIdQueryKeys = (key: string, sellerId?: string) => [
  key,
  sellerId,
]
