import { DiversityAnswer } from 'types/PartnerDiversity'

import { Actions } from './actionCreators'

export type PartnerDiversityState = {
  isDiversityAnswersPending: boolean
  diversityAnswers: DiversityAnswer[]
}

export const initialState: PartnerDiversityState = {
  isDiversityAnswersPending: false,
  diversityAnswers: [],
}

const partnerDiversityReducer = (
  state: PartnerDiversityState = initialState,
  action: Actions,
): PartnerDiversityState => {
  switch (action.type) {
    case 'FETCH_PARTNER_DIVERSITY_ANSWERS': {
      return {
        ...state,
        isDiversityAnswersPending: true,
      }
    }

    case 'FETCH_PARTNER_DIVERSITY_ANSWERS_DONE': {
      const { payload } = action
      const { diversityAnswers } = payload

      return {
        ...state,
        isDiversityAnswersPending: false,
        diversityAnswers,
      }
    }

    case 'FETCH_PARTNER_DIVERSITY_ANSWERS_ERROR': {
      return {
        ...state,
        isDiversityAnswersPending: false,
      }
    }

    case 'EDIT_PARTNER_DIVERSITY_ANSWERS': {
      return {
        ...state,
        isDiversityAnswersPending: true,
      }
    }

    case 'EDIT_PARTNER_DIVERSITY_ANSWERS_DONE': {
      const { payload } = action
      const { diversityAnswers } = payload

      return {
        ...state,
        isDiversityAnswersPending: false,
        diversityAnswers,
      }
    }

    case 'EDIT_PARTNER_DIVERSITY_ANSWERS_ERROR': {
      return {
        ...state,
        isDiversityAnswersPending: false,
      }
    }

    case 'CLEAR_PARTNER_DIVERSITY_ANSWERS': {
      return initialState
    }

    default:
      return state
  }
}

export default partnerDiversityReducer
