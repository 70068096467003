import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import Select from 'components/common/Select'

import { submitReport } from 'services/reports'

import { closeDialog } from 'store/dialog/actionCreator'

import { ReportType, Report } from 'types/Report'

interface Props {
  isOpen: boolean
  onRequestSubmit: (report: Report) => void
}

const DaysPastSLADialog = ({ isOpen, onRequestSubmit }: Props) => {
  const [acknowledgedBySellerDays, setAcknowlegedBySellerDays] = useState(10)
  const [pendingDays, setPendingDays] = useState(1)
  const [pending, setPending] = useState(false)

  const dispatch = useDispatch()

  const handleSubmit = async () => {
    setPending(true)

    try {
      const report = await submitReport({
        type: ReportType.ORDERS_PAST_SLA,
        parameters: {
          acknowledged_partially_shipped_sla_hours:
            acknowledgedBySellerDays * 24,
          pending_sla_hours: pendingDays * 24,
        },
      })

      onRequestSubmit(report)
    } catch (e) {
      console.error(`Error generating Orders Past SLA Report`, e)
    } finally {
      setPending(false)
      dispatch(closeDialog())
    }
  }
  function handleChange(value: number, name: string): void {
    if (name === 'pendingDays') {
      setPendingDays(value)
    } else {
      setAcknowlegedBySellerDays(value)
    }
  }
  return (
    <DialogContainer
      title="Orders Past SLA"
      isOpen={isOpen}
      onSubmit={handleSubmit}
      submitButtonText="Generate"
      isPending={pending}
    >
      <Grid container alignItems="flex-end" spacing={2}>
        <Grid item xs={6}>
          <Select
            label="Acknowledged by Seller"
            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            onChange={handleChange}
            value={acknowledgedBySellerDays}
            id="acknowledged-days"
            name="acknowledgedBySellerDays"
          />
        </Grid>
        <Grid item xs={6}>
          <Typography>Days</Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-end" spacing={2}>
        <Grid item xs={6}>
          <Select
            label="Pending"
            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            onChange={handleChange}
            value={pendingDays}
            id="pending-days"
            name="pendingDays"
          />
        </Grid>
        <Grid item xs={6}>
          <Typography>Days</Typography>
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export default DaysPastSLADialog
