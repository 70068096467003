import axios from 'axios'

import orderBy from 'lodash/fp/orderBy'
import apiConfig from 'config/apiConfig'

import {
  getMarketplaceProductByTcin,
  getMarketplaceProductById,
} from 'services/items'

import { MarketplaceProduct } from 'types/Item'
import {
  BeetleMessageParams,
  BeetleMessages,
  BeetleTopic,
  BeetleTopicParams,
  SmsTopicMessage,
  HeliosTopicMessage,
} from 'types/Beetle'

const path = `${apiConfig.beetle}/topics`

export const getTopics = (
  params?: BeetleTopicParams,
): Promise<BeetleTopic[]> => {
  const config = {
    params,
  }

  return axios.get(path, config).then((response) => response.data.results)
}

export const getMessages = (
  topicId: string,
  params?: BeetleMessageParams,
): Promise<BeetleMessages> => {
  const config = {
    params,
  }

  return axios
    .get(`${path}/${topicId}/messages`, config)
    .then((response) => response.data)
}

export const getSmsMessages = (productId: string) => {
  const config = {
    params: {
      q: `id:${productId}`,
      sort: 'message_date(desc),offset(desc)',
      size: '50',
    },
  }

  return axios
    .get(`${path}/${apiConfig.smsTopic}/messages`, config)
    .then((result) => result.data.results as SmsTopicMessage[])
}

export const getHeliosMessages = (productId: string) => {
  const config = {
    params: {
      q: `product_id:${productId}`,
      sort: 'message_date(desc),offset(desc)',
      size: '50',
    },
  }

  return axios
    .get(`${path}/${apiConfig.heliosTopic}/messages`, config)
    .then((result) => result.data.results as HeliosTopicMessage[])
}

export interface TopicMessageWrapper {
  source: 'Helios' | 'SMS'
  date: number
  data: HeliosTopicMessage | SmsTopicMessage
  isCurrentProductStatus: boolean
  isLatestProductStatus: boolean
}

export const getMessagesForTimeline = async ({
  productId,
  tcin,
}: {
  productId?: string
  tcin?: string
}) => {
  let product: MarketplaceProduct | undefined

  if (productId) {
    product = await getMarketplaceProductById(productId)
  } else if (tcin) {
    product = await getMarketplaceProductByTcin(tcin)
  }

  if (!product) {
    console.error(
      `Failed to get product for product_id: ${productId}, tcin: ${tcin}`,
    )
    return
  }

  const currentProductStatusId = product.current_status?.product_status_id
  const latestProductStatusId = product.product_status_id

  const [rawSms, rawHelios] = await Promise.all([
    getSmsMessages(product.product_id),
    getHeliosMessages(product.product_id),
  ])

  const mappedHelios = rawHelios.map((heliosMessage) => ({
    source: 'Helios',
    date: new Date(heliosMessage.message.update_datetime).getTime(),
    data: heliosMessage,
    isCurrentProductStatus:
      heliosMessage.message.product_status_id === currentProductStatusId,
    isLatestProductStatus:
      heliosMessage.message.product_status_id === latestProductStatusId,
  })) as TopicMessageWrapper[]

  const mappedSms = rawSms.map((smsMessage) => ({
    source: 'SMS',
    date: new Date(smsMessage.message.updated_datetime).getTime(),
    data: smsMessage,
    isCurrentProductStatus:
      smsMessage.message.product_status_id === currentProductStatusId,
    isLatestProductStatus:
      smsMessage.message.product_status_id === latestProductStatusId,
  })) as TopicMessageWrapper[]

  const messages = orderBy<TopicMessageWrapper>(
    'date',
    'desc',
  )([...mappedSms, ...mappedHelios])
  //console.log(rawSms[0])
  return messages
}
