import { useSelector } from 'react-redux'

import styled from '@emotion/styled'

import Chip from '@mui/material/Chip'

import { getStateCodes } from 'store/selectors'

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(1, 1, 0, 0),
}))

interface Props {
  eligibleStateCodes?: string[]
  handleDelete?: (code: string) => void
}

export const EligibleStateCodeContent = ({
  eligibleStateCodes,
  handleDelete,
}: Props) => {
  const stateCodes = useSelector(getStateCodes)

  if (!eligibleStateCodes?.length) return null

  const onDelete = (code: string) => () => {
    if (handleDelete) {
      handleDelete(code)
    }
  }

  const sorted = eligibleStateCodes.sort()
  return (
    <div>
      {sorted.map((code: string, index: number) => {
        const stateCode = stateCodes.find((sc) => sc.value === code)
        const label = stateCode ? stateCode.properties.name : code
        return (
          <StyledChip
            data-testid={`state-chip-${code}`}
            key={index}
            label={label}
            onDelete={handleDelete ? onDelete(code) : undefined}
          />
        )
      })}
    </div>
  )
}

export default EligibleStateCodeContent
