import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'

const StyledContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 0),
  display: 'flex',
}))

const StyledLabel = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isFirstElement',
})<{ isFirstElement?: boolean }>(
  {
    display: 'flex',
  },
  (props) => ({
    '& :first-of-type': props.isFirstElement
      ? {
          paddingLeft: 0,
          borderLeft: 'none',
        }
      : undefined,
  }),
)

const StyledItemLabel = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(1),
  borderLeft: `1px solid ${theme.palette.common.black}`,
  fontWeight: 'bold',
}))

const StyledItemData = styled(Typography)(({ theme }) => ({
  paddingRight: theme.spacing(3),
}))

export type LabeledDataItem = { label: string; data: string | number }

interface Props {
  data: LabeledDataItem[]
}

export const LabeledDataList = ({ data }: Props) => {
  return (
    <StyledContainer>
      {data.map((item, index) => {
        return (
          <StyledLabel isFirstElement={index === 0} key={index}>
            <StyledItemLabel variant="h4" data-testid="label">
              {item.label}
            </StyledItemLabel>
            <StyledItemData variant="h4" data-testid="labeled-data">
              {item.data}
            </StyledItemData>
          </StyledLabel>
        )
      })}
    </StyledContainer>
  )
}

export default LabeledDataList
