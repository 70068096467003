import axios from 'axios'
import findLastIndex from 'lodash/fp/findLastIndex'
import apiConfig from 'config/apiConfig'

import { isOneOfUserRoles, isUserRoleExternal } from 'services/authorization'
import { RoutePath } from 'services/NavigationHelper'
import { createLoginLink, getPaymentAccounts } from 'services/payments'
import {
  USER_ROLE_ADMIN,
  USER_ROLE_ACQUISITIONS,
  USER_ROLE_ITEM_APPROVER,
  USER_ROLE_ITEM_MANAGER,
  USER_ROLE_OPS,
  USER_ROLE_PROGRAM_MANAGER,
  USER_ROLE_REPORTER,
  USER_ROLE_COMMUNICATIONS,
  USER_ROLE_DIGITAL_EXP_SUPPORT,
  USER_ROLE_FINANCE,
  USER_ROLE_APP_SMS_ADMIN,
} from 'services/roles'
import {
  getAllResponsibilities,
  hasAllResponsibilities,
} from 'services/usersHelper'
import { PagingParams, getPageable } from 'services/pageableHelper'

import {
  Notification,
  NotificationPriority,
  NotificationUpdate,
  NotificationDisplayOn,
} from 'types/Notifications'
import { PaymentAccount } from 'types/PaymentAccount'
import SellerUser from 'types/SellerUser'
import { CollectionResponse } from 'types/Response'

const allInternalRolesPlusExternalAdmin = [
  USER_ROLE_ADMIN,
  USER_ROLE_ACQUISITIONS,
  USER_ROLE_ITEM_APPROVER,
  USER_ROLE_ITEM_MANAGER,
  USER_ROLE_OPS,
  USER_ROLE_PROGRAM_MANAGER,
  USER_ROLE_REPORTER,
  USER_ROLE_COMMUNICATIONS,
  USER_ROLE_DIGITAL_EXP_SUPPORT,
  USER_ROLE_FINANCE,
  USER_ROLE_APP_SMS_ADMIN,
]

export async function getNotifications(
  pagingParams: PagingParams,
): Promise<CollectionResponse<Notification>> {
  const pageableParams = getPageable(pagingParams)

  const config = {
    params: pageableParams,
  }

  const response = await axios.get(
    `${apiConfig.notifications}/notifications`,
    config,
  )

  const total = response.headers['x-total-count']
    ? parseInt(response.headers['x-total-count'], 10)
    : 0

  return {
    total,
    data: response.data,
  }
}

export const updateNotification = async (
  data: Notification | NotificationUpdate,
): Promise<Notification> => {
  const result = data.id
    ? await axios.put(
        `${apiConfig.notifications}/notifications/${data.id}`,
        data,
      )
    : await axios.post(`${apiConfig.notifications}/notifications`, data)

  return result.data
}

export const deleteNotification = async (id: string) => {
  return await axios.delete(`${apiConfig.notifications}/notifications/${id}`)
}

export async function getEffectiveNotifications({
  contacts,
  hasStripeEnabled,
  memberOf,
  sellerId,
  displayOn,
}: {
  contacts: SellerUser[]
  hasStripeEnabled: boolean
  memberOf: string[]
  sellerId?: string
  displayOn: NotificationDisplayOn
}): Promise<Notification[]> {
  const isExternal = isUserRoleExternal(memberOf)

  const config = {
    params: {
      effective_date: new Date().toISOString(),
      display_on: displayOn,
    },
  }

  const { data } = await axios.get(
    `${apiConfig.notifications}/effective_notifications`,
    config,
  )

  const lastPriorityCriticalIndex = findLastIndex<Notification>(
    { priority: NotificationPriority.CRITICAL },
    data,
  )
  const insertIndex = lastPriorityCriticalIndex + 1

  if (!hasAllResponsibilities(getAllResponsibilities(contacts)) && sellerId) {
    const contactNotification = getContactsNotification(sellerId)
    data.splice(insertIndex, 0, contactNotification)
  }

  if (hasStripeEnabled && sellerId) {
    const stripeLink = await createLoginLink(sellerId)
    try {
      const paymentAccounts = await getPaymentAccounts(sellerId)

      if (paymentAccounts && paymentAccounts.verification_fields?.length) {
        const stripeNotification = getStripeNotification(
          paymentAccounts,
          stripeLink,
        )
        data.splice(insertIndex, 0, stripeNotification)
      }
    } catch (e) {
      // move on without assigning stripe notifications if errors encountered
      console.error(`Unable to assign payment notificaitons: ${e}`)
    }
  }

  if (isExternal) {
    return data.filter((msg: Notification) =>
      isOneOfUserRoles(memberOf, msg.display_for),
    )
  } else {
    return data
  }
}

export function getStripeNotification(
  paymentAccounts: PaymentAccount,
  stripeLink: string | undefined,
): Notification {
  const linkText = stripeLink
    ? `[Stripe portal](${stripeLink})`
    : 'Stripe portal'
  const message = paymentAccounts.verification_reason
    ? `Your Stripe account is missing information and payments have been halted. The Stripe ADMIN on your account will need to log into the ${linkText} and provide the required information.`
    : `Your Stripe account is missing information. If the Stripe ADMIN on your account doesn't take required action in the ${linkText}, payments will be halted.`

  return {
    id: 'stripeNotificationId',
    title: 'Action Required in Stripe Portal: Missing Account Information',
    message,
    priority: NotificationPriority.CRITICAL,
    display_for: allInternalRolesPlusExternalAdmin,
    display_on: [NotificationDisplayOn.PARTNER_DASHBOARD],
    start_date: '',
    end_date: '',
    created: '',
    created_by: '',
    last_modified: '',
    last_modified_by: '',
  }
}

export function getContactsNotification(sellerId: string): Notification {
  const usersPath = `/${sellerId}${RoutePath.USER_ACCESS}`

  return {
    id: 'contactNotificationId',
    title: 'Missing Contact Responsibilities',
    message: `Your Account is missing key contacts. Visit the [Users & Contacts](${usersPath}) page to update this information`,
    priority: NotificationPriority.CRITICAL,
    display_for: allInternalRolesPlusExternalAdmin,
    display_on: [NotificationDisplayOn.PARTNER_DASHBOARD],
    start_date: '',
    end_date: '',
    created: '',
    created_by: '',
    last_modified: '',
    last_modified_by: '',
  }
}
