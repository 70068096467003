import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'

import styled from '@emotion/styled'
import { filterChip, white, error } from 'config/themeConfig'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CancelIcon from '@mui/icons-material/Cancel'
import Chip from '@mui/material/Chip'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'

import TextField from '@mui/material/TextField'

import { Search, Check } from '@mui/icons-material'

import Text from 'components/common/Text'

import { WarningIcon } from '../WarningIcon'
import { CircularProgress } from '@mui/material'

import { getMarketplaceProductByTcin } from 'services/items'

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'hasValue',
})<{ hasValue: string | string[] | boolean | undefined }>(
  (props) => ({
    backgroundColor: white.main,
    color: props.theme.palette.grey[700],
    '& .MuiOutlinedInput-input': {
      fontSize: 14,
      padding: props.theme.spacing(1, 2, 1, 1),
      '&:focus': {
        backgroundColor: 'initial',
      },
    },
  }),
  (props) => ({
    backgroundColor: props.hasValue ? filterChip.main : undefined,
    color: props.hasValue ? props.theme.palette.grey[900] : undefined,
  }),
)

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  color: error.main,
  alignItems: 'center',
  flexWrap: 'wrap',
  paddingBottom: theme.spacing(1),
}))

const StyledBtnRow = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: theme.spacing(0.5),
}))

const StyledSpace = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

const StyledChip = styled(Chip)<{ component: string }>(({ theme }) => ({
  margin: `${theme.spacing(0.5)} ${theme.spacing(0.5)} ${theme.spacing(0.5)} 0`,
  boxSizing: 'content-box',
  padding: theme.spacing(0.25),
  whiteSpace: 'break-spaces',
  '& .MuiChip-label': {
    whiteSpace: 'break-spaces',
  },
}))

export type Props = {
  fullWidth?: boolean
  placeholder?: string
  value: string | string[] | undefined
  maxValues: number
  searchParam: string
  onChange: (params: Dictionary<string | string[] | undefined>) => void
}

export function MultiItemFilter({
  fullWidth,
  placeholder,
  value,
  maxValues,
  searchParam,
  onChange,
}: Props) {
  const anchorRef = useRef<HTMLDivElement | null>(null)

  const [input, setInput] = useState<string>()

  const [focused, setFocused] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [invalidEntries, setInvalidEntries] = useState<string[]>([])
  const [maxExceeded, setMaxExceeded] = useState<boolean>(false)
  const [data, setData] = useState<string[]>([])
  const [initialRender, setInitialRender] = useState(true)

  useEffect(() => {
    let mounted = true

    let list: string[] = []

    if (!value || value === '') {
      setData([])
      return
    }

    if (Array.isArray(value)) {
      list = value
    } else if (typeof value === 'string') {
      list = [value]
    }

    if (list.length && initialRender) {
      Promise.all(list.map((tcin) => getMarketplaceProductByTcin(tcin))).then(
        (res) => {
          if (mounted) {
            const data = res.map((i) => i?.tcin)

            setData(data as string[])
          }
        },
      )
    }

    return () => {
      mounted = false
      setInitialRender(false)
    }
  }, [initialRender, value])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInvalidEntries([])
    setMaxExceeded(false)

    setInput(event.target.value)
  }

  const handleFocus = () => {
    setInput('')
    setFocused(true)
  }

  const handleClear = () => {
    setData([])
    setFocused(false)
    setInput('')
    onChange({ [searchParam]: undefined })
  }

  const handleDelete = (item: string) => () => {
    setData(data.filter((elem) => elem !== item))
  }

  const handleDone = () => {
    if (!value && data.length === 0) {
      setInput('')
      setFocused(false)
      return
    }

    onChange({ [searchParam]: data.length ? data : undefined })

    setInput('')
    setFocused(false)
    setInvalidEntries([])
    setMaxExceeded(false)
  }

  const handleClickAway = (event: any) => {
    if (event.target.localName === 'body') {
      return
    }
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setInput('')
    handleDone()
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setInput('')

    if (!input) return

    // Create an array of strings from input. Remove duplicates, commas, and whitespace
    const tcins = [...new Set(input.trim().split(/[ ,]+/))]

    if (tcins.length > maxValues || tcins.length + data.length > maxValues) {
      setMaxExceeded(true)
      return
    }

    setIsLoading(true)

    // Validate each tcin
    let temp: string[] = []

    for (const tcin of tcins) {
      const item = await getMarketplaceProductByTcin(tcin)
      if (!item) invalidEntries.push(tcin)
      else {
        const isDuplicateValue = data.some((elem) => elem === tcin)
        if (isDuplicateValue) {
          return
        }

        temp.push(tcin)
      }
    }
    if (temp.length > 0) setData(data.concat(temp))
    setIsLoading(false)
  }

  const getStartAdornment = () => {
    let startAdornment = <Search color="primary" />
    startAdornment = value ? (
      <Check color="primary" />
    ) : (
      <Search color="primary" />
    )
    return startAdornment
  }

  const getValue = () => {
    if (value && !focused) {
      if (typeof value === 'string') return '1 TCIN'
      else if (value instanceof Array) {
        if (value.length === 1) return `${value.length} TCIN`
        else if (value.length > 1) return `${value.length} TCINS`
      }
    }
    if (input) return input
    else return ''
  }

  const endAdornment = value ? (
    <IconButton onClick={handleClear} data-testid="remove-filter" size="large">
      <CancelIcon color="primary" />
    </IconButton>
  ) : undefined

  const id =
    focused && Boolean(anchorRef.current) ? 'transition-popper' : undefined

  return (
    <form onSubmit={handleSubmit} name="multi-item-filter">
      <StyledTextField
        fullWidth={fullWidth}
        placeholder={placeholder}
        value={getValue()}
        hasValue={!!value}
        onChange={handleChange}
        InputProps={{
          startAdornment: getStartAdornment(),
          endAdornment,
        }}
        onFocus={handleFocus}
        ref={anchorRef}
      />

      <Popper
        transition
        id={id}
        open={focused}
        anchorEl={anchorRef.current}
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper
              sx={{
                p: 2,
                width: anchorRef && anchorRef.current?.offsetWidth,
              }}
              data-testid="popper-root"
            >
              <ClickAwayListener onClickAway={handleClickAway}>
                <div>
                  {!isLoading && (
                    <div>
                      {maxExceeded && (
                        <StyledBox>
                          <WarningIcon />
                          <Text type="bodySm">
                            {`Error: Only up to ${maxValues} TCINs can be entered `}
                          </Text>
                        </StyledBox>
                      )}
                      {invalidEntries.length >= 1 && (
                        <StyledBox>
                          <WarningIcon />
                          <Text type="bodySm" sx={{ m: 0.5 }}>
                            {`Invalid Entries:`}
                          </Text>
                          {invalidEntries.map((entry, index) => (
                            <Text sx={{ mr: 0.5 }} type="bodySm" key={index}>
                              {index === invalidEntries.length - 1
                                ? `${entry}`
                                : `${entry},`}
                            </Text>
                          ))}
                        </StyledBox>
                      )}
                      <Text type="bodySm">
                        {`${data.length} Added, Max of ${maxValues} allowed`}
                      </Text>
                      <StyledSpace>
                        {data.map((item: string) => {
                          const label = item
                          return (
                            <StyledChip
                              key={item}
                              component="span"
                              label={label}
                              data-testid={`chip-${label}`}
                              onDelete={handleDelete(item)}
                            />
                          )
                        })}
                      </StyledSpace>
                      <StyledBtnRow>
                        <Button
                          onClick={handleClear}
                          data-testid="clear-button"
                        >
                          Clear all TCINs
                        </Button>
                        <Button onClick={handleDone} data-testid="done-button">
                          Done
                        </Button>
                      </StyledBtnRow>
                    </div>
                  )}
                  {isLoading && (
                    <Box sx={{ textAlign: 'center' }}>
                      <CircularProgress />
                    </Box>
                  )}
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </form>
  )
}

export default MultiItemFilter
