import React from 'react'

import styled from '@emotion/styled'
import { useMediaQuery } from '@mui/material'
import Grid from '@mui/material/Grid'

import DisplayCard from 'components/common/DisplayCard'
import LinearGauge, { LinearGaugeVariant } from 'components/common/LinearGauge'
import Text from 'components/common/Text'
import Link from 'components/common/Link'

import { RoutePath } from 'services/NavigationHelper'

import OrderDefectMetricsTiles from './OrderDefectMetricsTiles'
import OverallDefectRateLabel, { DefectRate } from './OverallDefectRateLabel'

const StyledDisplayCard = styled(DisplayCard)(({ theme }) => ({
  padding: theme.spacing(3),
}))

const StyledDividerGrid = styled(Grid)(({ theme }) => ({
  borderRight: useMediaQuery(theme.breakpoints.up('lg'))
    ? `1px solid ${theme.palette.grey[300]}`
    : undefined,
}))

const StyledPaddedDiv = styled('div')(({ theme }) => ({
  paddingRight: theme.spacing(3),
}))

export type Props = {
  defectRate: number | undefined
  greenfieldInterval: string
}

export const OverallDefectRateCard: React.FC<Props> = ({
  defectRate,
  greenfieldInterval,
}) => {
  let variant: LinearGaugeVariant = 'default'

  if (defectRate !== undefined) {
    if (defectRate < DefectRate.NON_COMPLIANT) {
      variant = 'success'
    } else if (
      defectRate >= DefectRate.NON_COMPLIANT &&
      defectRate < DefectRate.PROBATION
    ) {
      variant = 'indeterminate'
    } else if (defectRate >= DefectRate.PROBATION) {
      variant = 'error'
    }
  }
  const scoreAsPercent = defectRate !== undefined ? (defectRate / 7) * 100 : 0

  return (
    <StyledDisplayCard
      title="Order Defect Rate (ODR)"
      description="Last 90 Days"
    >
      <Grid container spacing={2}>
        <StyledDividerGrid item xs={12} lg={6}>
          {defectRate !== undefined && (
            <StyledPaddedDiv>
              <OverallDefectRateLabel defectRate={defectRate} />

              <LinearGauge
                hideScore
                variant={variant}
                score={scoreAsPercent}
                pins={[
                  { value: 0, title: '0.00', label: 'Compliant' },
                  { value: 71.4, title: '5.00', label: 'Non-compliant' },
                  { value: 100, title: '7.00', label: 'Probation' },
                ]}
              />
            </StyledPaddedDiv>
          )}
        </StyledDividerGrid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Text type="bold">
                ODR is not a sum of defect category percentages
              </Text>
            </Grid>
            <Grid item xs={12}>
              <Text>
                ODR is calculated as defect orders divided by total number of
                orders in a time frame. It is not a sum because one order can
                belong to multiple defect categories.{' '}
                <Link to={`${RoutePath.KNOWLEDGE_ARTICLE}/000087082`}>
                  Learn more about ODR.
                </Link>
              </Text>
            </Grid>
          </Grid>
        </Grid>
        <OrderDefectMetricsTiles greenfieldInterval={greenfieldInterval} />
      </Grid>
    </StyledDisplayCard>
  )
}

export default OverallDefectRateCard
