import React, { CSSProperties } from 'react'
import styled from '@emotion/styled'
import Typography, { TypographyProps } from '@mui/material/Typography'

const StyledTypography = styled(Typography)<{ type: string }>({}, (props) => {
  switch (props.type) {
    case 'micro':
      return {
        ...(props.theme.micro as CSSProperties),
      }
    case 'bold':
      return {
        fontSize: '0.875rem',
        fontWeight: 700,
      }
    case 'emphasis':
      return {
        fontStyle: 'italic',
      }
    case 'error':
      return {
        color: props.theme.palette.error.main,
      }
    case 'header':
      return {
        ...(props.theme.typography.h6 as CSSProperties),
        fontSize: '1.125rem',
        fontWeight: 600,
      }
    case 'warning':
      return {
        color: '#AF491E',
      }
    case 'bodySm':
      return {
        fontSize: '0.75rem',
        lineHeight: 1,
      }
    default:
      return
  }
}) as unknown as typeof Typography

interface Props {
  type?:
    | 'micro'
    | 'bold'
    | 'emphasis'
    | 'error'
    | 'header'
    | 'warning'
    | 'bodySm'
  component?: React.ElementType<any>
  children: React.ReactNode
  [key: string]: any
}

export const Text = ({
  type,
  component = 'div',
  children,
  ...restProps
}: Props & TypographyProps) => {
  return (
    <StyledTypography {...restProps} component={component} type={type}>
      {children}
    </StyledTypography>
  )
}

export default Text
