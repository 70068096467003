import DataList, { DataListItem } from 'components/common/DataList'

import {
  ReturnDisputeCasesSearchParams,
  ReturnDisputeDaysPending,
} from 'types/disputes'

type Props = {
  searchParams: ReturnDisputeCasesSearchParams
  sellerDisplayName?: string
}

export const EmptyDisputeQueueContent = ({
  searchParams,
  sellerDisplayName,
}: Props) => {
  const dataListItems = Object.keys(searchParams).reduce<DataListItem[]>(
    (acc, curr) => {
      const currentValue =
        searchParams[curr as keyof ReturnDisputeCasesSearchParams]

      if (currentValue) {
        if (curr === 'daysPending') {
          const text =
            currentValue === ReturnDisputeDaysPending.ZERO_TO_FIVE
              ? '0-5 Days'
              : 'Over 5 Days'
          acc.push({ title: 'Days Pending:', value: text })
        }

        if (curr === 'seller_id') {
          acc.push({
            title: 'Partner:',
            value: sellerDisplayName ?? currentValue,
          })
        }
        if (curr === 'case_number') {
          acc.push({ title: 'Dispute Number:', value: currentValue })
        }

        if (curr === 'return_order_number') {
          acc.push({ title: 'Return Order Number:', value: currentValue })
        }
      }

      return acc
    },
    [],
  )

  return <DataList data={dataListItems} />
}

export default EmptyDisputeQueueContent
