import React, { useEffect, useState } from 'react'

import { TargetPlusBarcodeDetails } from '../../BarcodeRelease'

import styled from '@emotion/styled'
import { error } from 'config/themeConfig'
import Typography from '@mui/material/Typography'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import RejectReleaseBarcodesTitle from './RejectReleaseBarcodesTitle'

import { releaseItem } from 'services/helios'
import { getSmsProduct } from 'services/items'
import { getCurrentProductStatus } from 'services/itemHelper'

import { WebSocketInterface } from 'websockets/core'
import {
  SmsBulkApiWebSocket,
  REJECT_FAILURE,
  REJECT_REQUEST,
  REJECT_SUCCESS,
} from 'websockets/smsBulkApi'

import { ListingStatus } from 'types/Item'

import { barcodeChangeRejectError } from 'constants/rejectErrorCodes'

const StyledDescription = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))

export interface Props {
  isOpen: boolean
  releaseBarcodes: TargetPlusBarcodeDetails[]
  onClose: () => void
}

interface BarcodeTcin {
  barcode: string
  tcin?: string
  rejected?: boolean
}

let ws: WebSocketInterface

enum LoadingState {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  SUCCESS_WITHOUT_ERRORS = 'SUCCESS_WITHOUT_ERRORS',
  SUCCESS_WITH_ERRORS = 'SUCCESS_WITH_ERRORS',
}

const getLoadingState = ({
  pending,
  success,
  errored,
}: {
  pending: boolean
  success: boolean
  errored: number
}): LoadingState => {
  if (pending) {
    return LoadingState.PENDING
  } else if (success && errored === 0) {
    return LoadingState.SUCCESS_WITHOUT_ERRORS
  } else if (success && errored > 0) {
    return LoadingState.SUCCESS_WITH_ERRORS
  } else {
    return LoadingState.IDLE
  }
}

export const RejectReleaseBarcodes = ({
  isOpen,
  releaseBarcodes,
  onClose,
}: Props) => {
  const [barcodeTcins, setBarcodeTcins] = useState<BarcodeTcin[]>([])
  const [barcodeReleaseFailure, setBarcodeReleaseFailure] = useState<
    BarcodeTcin[]
  >([])
  const [isPending, setIsPending] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [errored, setErrored] = useState<number>(0)
  const [completed, setCompleted] = useState<number>(0)

  const loadingState = getLoadingState({
    success,
    errored,
    pending: isPending,
  })

  const handleItemRelease = async (tcin: string, barcodes: BarcodeTcin[]) => {
    try {
      await releaseItem(tcin)
    } catch (e) {
      setErrored((value) => value + 1)
      const barcode = barcodes.find((b) => b.tcin === tcin)

      if (barcode) {
        setBarcodeReleaseFailure((existingBarcodeReleaseFailure) => [
          ...existingBarcodeReleaseFailure,
          barcode,
        ])
      }
    }

    setCompleted((prev) => prev + 1)
  }

  useEffect(() => {
    ws = SmsBulkApiWebSocket(() => {
      ws.subscribe(REJECT_SUCCESS, (message: any) => {
        const tcin = message?.body?.tcin

        if (tcin) {
          handleItemRelease(tcin, barcodeTcins)
        }
      })

      ws.subscribe(REJECT_FAILURE, () => {
        setCompleted((value) => value + 1)
        setErrored((value) => value + 1)
      })
    })

    return () => {
      ws.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setIsPending(true)
    releaseBarcodes.forEach((releaseBarcode) => {
      setBarcodeTcins((existingBarcodeTcinsTcins) => [
        ...existingBarcodeTcinsTcins,
        { barcode: releaseBarcode.barcode },
      ])
      getSmsProduct({
        sellerId: releaseBarcode.productInfo!.sellerId,
        productId: releaseBarcode.productInfo!.productId,
        params: { expand: 'product_statuses' },
      }).then((smsProduct) => {
        if (smsProduct.tcin) {
          setBarcodeTcins((existingBarcodeTcinsTcins) =>
            existingBarcodeTcinsTcins.map((barcodeTcin) =>
              barcodeTcin.barcode === releaseBarcode.barcode
                ? { ...barcodeTcin, tcin: smsProduct.tcin }
                : barcodeTcin,
            ),
          )
        }
        const currentProductStatus = getCurrentProductStatus(smsProduct)
        if (currentProductStatus) {
          setBarcodeTcins((existingBarcodeTcinsTcins) =>
            existingBarcodeTcinsTcins.map((barcodeTcin) => {
              if (barcodeTcin.barcode === releaseBarcode.barcode) {
                return currentProductStatus.listing_status !==
                  ListingStatus.REJECTED
                  ? { ...barcodeTcin, rejected: false }
                  : { ...barcodeTcin, rejected: true }
              } else {
                return barcodeTcin
              }
            }),
          )
        }
        setIsPending(false)
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releaseBarcodes])

  React.useEffect(() => {
    if (isPending) {
      if (completed === barcodeTcins.length) {
        setSuccess(true)
        setIsPending(false)
      }
    }
  }, [barcodeTcins, completed, isPending])

  const handleSubmit = async () => {
    setIsPending(true)

    const tcins = barcodeTcins
      .filter((barcodeTcin) => barcodeTcin.rejected === false)
      .map((rejectedItem) => rejectedItem.tcin)

    const rejectedBarcodes = barcodeTcins.filter((barcode) => barcode.rejected)

    if (rejectedBarcodes.length) {
      for (const barcode of rejectedBarcodes) {
        if (barcode.tcin) {
          await handleItemRelease(barcode.tcin, barcodeTcins)
        }
      }
    }

    ws.send(
      REJECT_REQUEST,
      JSON.stringify({
        tcins,
        rejection_reasons: [barcodeChangeRejectError],
      }),
      {
        'content-type': 'application/json',
      },
    )

    onClose()
  }

  return (
    <DialogContainer
      title={
        <RejectReleaseBarcodesTitle
          isPending={isPending}
          success={success}
          errored={errored}
          total={releaseBarcodes.length}
        />
      }
      isOpen={isOpen}
      onSubmit={loadingState === LoadingState.IDLE ? handleSubmit : undefined}
      onCancel={onClose}
      submitButtonText="Reject and release barcodes"
      closeButtonText={
        loadingState === LoadingState.SUCCESS_WITH_ERRORS ? 'Close' : 'Cancel'
      }
      hideActions={loadingState === LoadingState.PENDING}
      autoClose={loadingState === LoadingState.SUCCESS_WITHOUT_ERRORS}
    >
      {loadingState === LoadingState.IDLE && (
        <StyledDescription data-testid="idle">
          Items will be rejected and their barcodes will be released. Partners
          will need to send new item versions with new barcodes.
        </StyledDescription>
      )}
      {loadingState === LoadingState.SUCCESS_WITH_ERRORS && (
        <>
          <Typography sx={{ color: error.main }}>
            Barcodes that cound not be released:{' '}
          </Typography>
          <Typography component="div" sx={{ color: error.main }}>
            {barcodeTcins
              .filter((barcodeTcin) => barcodeTcin.rejected === false)
              .map((nonRejected) => (
                <div key={nonRejected.barcode}>{nonRejected.barcode}</div>
              ))}
            {barcodeReleaseFailure.map((barcodeReleaseFail) => (
              <div key={barcodeReleaseFail.barcode}>
                {barcodeReleaseFail.barcode}
              </div>
            ))}
          </Typography>
        </>
      )}
    </DialogContainer>
  )
}

export default RejectReleaseBarcodes
