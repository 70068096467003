import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import styled from '@emotion/styled'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'

import DataList from 'components/common/DataList'
import FullScreenDialogContainer from 'components/common/Dialog/FullScreenDialogContainer'
import DisplayCard from 'components/common/DisplayCard'
import {
  tcinLink,
  returnOrderNumberLink,
} from 'components/common/EnhancedTable/formatters'
import Image from 'components/common/Image'
import TrackingNumberLink from 'components/common/TrackingNumberLink'

import PartnerSubmittedDetails from './PartnerSubmittedDetails'
import ReturnReversal from './ReturnReversal'
import ReturnDisputeStatusChip from '../ReturnDisputeStatusChip'

import formatCurrency from 'services/formatCurrency'
import { getMarketplaceProductByTcin } from 'services/items'
import { getProductReturn } from 'services/productReturns'

import { getDisputeSubResponses, getReturnReasons } from 'store/selectors'

import { JudgementValues, ReturnDispute } from 'types/disputes'
import { MarketplaceProduct } from 'types/Item'
import { Return, ReturnTrackingData } from 'types/Orders'
import StoreState from 'types/state'

import { backgroundGrey, grey } from 'config/themeConfig'
import CreatedContextInfo from './CreatedContextInfo'
import CommunicationHistory from 'components/OpenCases/OpenCaseDetail/CommunicationHistory'
import ContentSpacer from 'components/common/ContentSpacer'

import { Box } from '@mui/material'

import SystemMessage from './SystemMesage'
import { Case } from 'types/Case'
import {
  displaySystemMessage,
  getDisputeByPhysicalDisposition,
} from './returnDisputeHelper'

const StyledImageContainer = styled('div')(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(2),
  maxWidth: '200px',
}))

const StyledHorizontalDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  color: grey[300],
}))

const StyledVerticalDivider = styled(Divider)(() => ({
  color: grey[300],
}))

const StyledGrid = styled(Grid)(({ theme }) => ({
  marginLeft: 0,
  paddingBottom: theme.spacing(2),
  width: '100%',
  backgroundColor: backgroundGrey.main,
}))

const StyledDisplayCard = styled(DisplayCard)({
  height: 'auto',
})

interface Props {
  isOpen: boolean
  handleCloseDialog: () => void
  returnDispute: ReturnDispute
  selectedCase: Case | undefined
}

const ExternalReturnDisputeDialog = ({
  isOpen,
  handleCloseDialog,
  returnDispute,
  selectedCase,
}: Props) => {
  const [item, setItem] = useState<MarketplaceProduct>()
  const [disputeReturn, setDisputeReturn] = useState<Return>()

  useEffect(() => {
    let mounted = true

    getMarketplaceProductByTcin(returnDispute.tcin).then((res) => {
      if (mounted) {
        setItem(res)
      }
    })

    getProductReturn(
      returnDispute.seller_id,
      returnDispute.product_return_id,
    ).then((res) => {
      if (mounted) {
        setDisputeReturn(res)
      }
    })

    return () => {
      mounted = false
    }
  }, [
    returnDispute.product_return_id,
    returnDispute.seller_id,
    returnDispute.tcin,
  ])

  const returnReasons = useSelector((state: StoreState) =>
    getReturnReasons(state),
  )

  const disputeSubReasons = useSelector((state: StoreState) =>
    getDisputeSubResponses(state),
  )

  const returnSubReasonDescription = disputeSubReasons.find(
    (subReason) => subReason.value === returnDispute.dispute_sub_reason,
  )?.properties.description

  const displayReturnReversal = (judgement: JudgementValues) => {
    return (
      judgement === JudgementValues.INVALID_CLAIM ||
      judgement === JudgementValues.VALID_CLAIM
    )
  }

  const getTrackingNumberContent = (trackingData?: ReturnTrackingData[]) => {
    if (trackingData) {
      const trackingNumber = trackingData.find(
        (tracking) => tracking.tracking_number,
      )?.tracking_number
      const scac = trackingData.find((tracking) => tracking.scac)?.scac

      if (trackingNumber) {
        return (
          <TrackingNumberLink trackingNumber={trackingNumber} scac={scac} />
        )
      }
    }
  }

  return (
    <FullScreenDialogContainer
      title={`Dispute ${returnDispute.case_number}`}
      isOpen={isOpen}
      onRequestClose={handleCloseDialog}
    >
      {returnSubReasonDescription && item && disputeReturn && (
        <>
          <StyledDisplayCard
            title={returnSubReasonDescription}
            description={item.title}
            topLevelComponent={
              <Grid item sx={{ paddingTop: 2, paddingLeft: 2 }}>
                <ReturnDisputeStatusChip
                  returnDisputeJudgement={returnDispute.judgement}
                />
              </Grid>
            }
          >
            <StyledGrid container spacing={2}>
              <Grid item xs={2}>
                <StyledImageContainer>
                  <Image src={item.primary_image} alt="primary image"></Image>
                </StyledImageContainer>
              </Grid>
              <Grid item xs={3}>
                <DataList
                  data={[
                    { title: 'Original Order:', value: returnDispute.order_id },
                    {
                      title: 'Return Order Number:',
                      element: returnOrderNumberLink(false)({
                        seller_id: returnDispute.seller_id,
                        order_id: returnDispute.order_id,
                        return_order_number: returnDispute.return_order_number,
                      }),
                    },
                    {
                      title: 'TCIN:',
                      element: tcinLink(false)({
                        product_id: item.product_id,
                        seller_id: returnDispute.seller_id,
                        tcin: returnDispute.tcin,
                      }),
                    },
                    {
                      title: 'Tracking/PRO:',
                      element:
                        getTrackingNumberContent(disputeReturn.tracking_data) ??
                        'N/A',
                    },
                    {
                      title: 'License Plate:',
                      value: disputeReturn.is_online
                        ? 'N/A'
                        : disputeReturn.tracking_data
                        ? disputeReturn.tracking_data?.find(
                            (tracking) => tracking.license_plate,
                          )?.license_plate ?? 'N/A'
                        : 'N/A',
                    },
                    {
                      title: 'BOL:',
                      value: disputeReturn.is_online
                        ? 'N/A'
                        : disputeReturn.tracking_data
                        ? disputeReturn.tracking_data?.find(
                            (tracking) => tracking.bill_of_lading,
                          )?.bill_of_lading ?? 'N/A'
                        : 'N/A',
                    },
                    {
                      title: 'Guest Return Reason:',
                      value: returnReasons.find(
                        (reason) =>
                          reason.value === disputeReturn.return_reason,
                      )?.properties.description,
                    },
                    {
                      title: 'Registry Item:',
                      value: disputeReturn.registry_item ? 'Yes' : 'No',
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={1}>
                <StyledVerticalDivider orientation="vertical" />
              </Grid>
              <Grid item xs={6} sx={{ paddingRight: 2 }}>
                <DataList
                  data={[
                    {
                      title: 'Return Policy:',
                      value: `${disputeReturn.financial_disposition}, ${disputeReturn.physical_disposition}`,
                    },
                    {
                      title: 'Item Chargeback:',
                      value: `${formatCurrency(
                        returnDispute.chargeback_amount,
                      )} (Qty ${returnDispute.quantity})`,
                    },
                    {
                      title: 'Return Service Fee:',
                      value: `${formatCurrency(
                        returnDispute.service_fee,
                      )} (Qty ${returnDispute.quantity})`,
                    },
                  ]}
                />
                <StyledHorizontalDivider orientation="horizontal" />
                <DataList
                  data={[
                    {
                      title: 'Total Chargeback:',
                      value: `${formatCurrency(
                        returnDispute.chargeback_amount +
                          returnDispute.service_fee,
                      )} (Qty ${returnDispute.quantity})`,
                    },
                    {
                      title: 'Dispute Qty:',
                      value: returnDispute.quantity,
                    },
                  ]}
                />
              </Grid>
            </StyledGrid>
            {displayReturnReversal(returnDispute.judgement) && (
              <ReturnReversal returnDispute={returnDispute} />
            )}
            {displaySystemMessage(returnDispute.judgement) && (
              <SystemMessage
                returnDispute={returnDispute}
                tracking={getDisputeByPhysicalDisposition(
                  disputeReturn,
                  'DEST',
                )}
              />
            )}
            <PartnerSubmittedDetails
              sellerId={returnDispute.seller_id}
              selectedCase={selectedCase}
            />
            <CreatedContextInfo returnDispute={returnDispute} />
          </StyledDisplayCard>

          <ContentSpacer />

          <StyledDisplayCard title="Communication History">
            <Box sx={{ marginLeft: 1, paddingRight: 1 }}>
              <CommunicationHistory
                sellerId={returnDispute.seller_id}
                selectedCase={selectedCase}
              />
            </Box>
          </StyledDisplayCard>
        </>
      )}
    </FullScreenDialogContainer>
  )
}

export default ExternalReturnDisputeDialog
