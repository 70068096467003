import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

import apiConfig, {
  ENVIRONMENT_PRODUCTION,
  EXTERNAL_PROD_HOSTNAME,
  EXTERNAL_STAGE_HOSTNAME,
} from 'config/apiConfig'

import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import ArrowBack from '@mui/icons-material/ArrowBack'

import Breadcrumb from 'components/common/Breadcrumb'
import CopyLinkButton from 'components/common/CopyLinkButton'
import HeaderTitle from 'components/common/HeaderTitle'
import Text from 'components/common/Text'
import ArticleSurvey from './ArticleSurvey'
import GetHelp from './GetHelp'

import { createMarkup } from 'services/createMarkup'
import { trackCustomEvent } from 'services/fireflyInsights'
import { getKnowledgeArticle } from 'services/knowledgeArticles'
import { RoutePath } from 'services/NavigationHelper'
import { isRoleExternalUserSelector } from 'store/selectors'

import { FireflyEvent } from 'types/FireflyInsights'
import { KnowledgeArticle } from 'types/KnowledgeArticle'
import HelpSearchBar from '../HelpSearchBar'

const StyledHelpSearch = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}))

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0, -1.5),
}))

const StyledButtonContainer = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(3),
}))

const StyledClearButton = styled(ArrowBack)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))

const StyledFlexContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const StyledText = styled(Text)(({ theme }) => ({
  margin: theme.spacing(3, 0),
}))

const StyledSpacer = styled('hr')(({ theme }) => ({
  margin: theme.spacing(3, 0),
}))

export const ArticleDetailsPage = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { articleId } = useParams()

  const isExternalUser = useSelector(isRoleExternalUserSelector)

  const [article, setArticle] = useState<KnowledgeArticle>()
  const [error, setError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [surveyAnswered, setSurveyAnswered] = useState(false)

  let source = 'direct'

  if (location.state?.searchTerm) {
    source = 'search'
  } else if (location.state?.category) {
    source = 'category'
  }

  useEffect(() => {
    setIsLoading(true)
    setError(false)
    getKnowledgeArticle(articleId ?? '')
      .then((article) => {
        setArticle(article)
        trackCustomEvent(
          FireflyEvent.HELP_ARTICLE_VISIT,
          source,
          article.id,
          article.title,
        )
      })
      .catch(() => setError(true))
      .finally(() => setIsLoading(false))
  }, [articleId, source])

  const handleSuverySubmit = (value: string) => {
    trackCustomEvent(
      FireflyEvent.HELP_WAS_THIS_ARTICLE_HELPFUL,
      value,
      article?.id ?? articleId ?? '',
      article?.title ?? '',
    )
    setSurveyAnswered(true)
  }

  const articleContent =
    article?.contents.find((content) => content.platform === 'GENERAL')
      ?.content ?? ''

  let content = <LinearProgress data-testid="article-loading" />

  if (isLoading) {
    content = <LinearProgress data-testid="article-loading" />
  } else if (error) {
    content = <Typography>Article not found.</Typography>
  } else if (article) {
    let url
    if (apiConfig.environment === ENVIRONMENT_PRODUCTION) {
      url = `https://${EXTERNAL_PROD_HOSTNAME}/article/${article.id}`
    } else {
      url = `https://${EXTERNAL_STAGE_HOSTNAME}/article/${article.id}`
    }
    content = (
      <>
        <StyledFlexContainer>
          <StyledText type="header">{article.title}</StyledText>
          {!isExternalUser && (
            <CopyLinkButton
              title="copy external article link"
              successTitle="external link copied!"
              link={url}
            />
          )}
        </StyledFlexContainer>
        <div
          className="help-center-article"
          dangerouslySetInnerHTML={createMarkup(articleContent)}
        />
      </>
    )
  }

  return (
    <div>
      <StyledHelpSearch>
        <HelpSearchBar />
      </StyledHelpSearch>
      <HeaderTitle title="Help Center" />
      {location.state?.searchTerm && (
        <StyledButtonContainer>
          <StyledButton
            data-testid="search-clear"
            onClick={() => navigate(-1)}
            color="primary"
          >
            <StyledClearButton />
            Back to search results
          </StyledButton>
        </StyledButtonContainer>
      )}
      <Breadcrumb
        breadcrumbs={[
          {
            href: `${RoutePath.HELP}`,
            name: 'Help Center',
          },
          ...(location.state?.category && location.state?.categoryName
            ? [
                {
                  href: `${RoutePath.HELP}${RoutePath.KNOWLEDGE_SEARCH}?category=${location.state.category}`,
                  name: location.state.categoryName,
                },
              ]
            : []),
          ...(article?.title
            ? [
                {
                  name: article.title,
                },
              ]
            : []),
        ]}
      />
      {content}
      {!isLoading && article && (
        <>
          <StyledSpacer />
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <ArticleSurvey
                articleId={article.id}
                answered={surveyAnswered}
                onSubmit={handleSuverySubmit}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <GetHelp articleId={article.id} articleTitle={article.title} />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}

export default ArticleDetailsPage
