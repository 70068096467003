import { useState, useEffect } from 'react'

import chunk from 'lodash/fp/chunk'
import differenceWith from 'lodash/fp/differenceWith'
import map from 'lodash/fp/map'

import styled from '@emotion/styled'
import LinearProgress from '@mui/material/LinearProgress'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import ContentSpacer from 'components/common/ContentSpacer'
import DataList, { DataListItem } from 'components/common/DataList'
import { ErrorCodeTypeahead } from 'components/common/Typeahead'

import { ErrorCodeLineItem } from 'components/ReviewQueuePage/Dialogs/ErrorCodeLineItem'

import { getTcinsForBulkReprocessing } from 'services/items'
import { getNodeById } from 'services/itemTaxonomies'
import { pluralize } from 'services/pluralizationHelper'
import { getSeller } from 'services/seller'

import { ErrorCode } from 'types/ErrorCode'
import { Validation } from 'types/Validation'

import { WebSocketInterface } from 'websockets/core'
import {
  SmsBulkApiWebSocket,
  BUMP_FAILURE,
  BUMP_REQUEST,
  BUMP_SUCCESS,
  REJECT_FAILURE,
  REJECT_REQUEST,
  REJECT_SUCCESS,
} from 'websockets/smsBulkApi'

const StyledDescription = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))
const StyledProgress = styled('div')(({ theme }) => ({
  marginButton: theme.spacing(2),
}))

export interface SearchParams {
  seller_id?: string
  item_type_id?: string
  validation_status?: string
  listing_status?: string
  published?: boolean
  tcin?: string
}

export interface Props {
  isOpen: boolean
  searchParams: SearchParams
  filteredItems: number
  reject?: boolean
  onCloseHook?: () => void
}

const isErrorEqual = (error: ErrorCode, other: ErrorCode) => {
  return error.error_code === other.error_code
}

const getSellerName = async (id: string) => {
  const seller = await getSeller(id)
  const name = seller?.display_name || seller?.legal_business_name

  return name
}

const getItemTypeName = async (id: string) => {
  const itemType = await getNodeById(id)
  const itemTypeName = itemType?.name ?? id

  return itemTypeName
}

const getDataList = async ({
  seller_id,
  item_type_id,
  validation_status,
  listing_status,
  published,
  tcin,
}: SearchParams): Promise<DataListItem[]> => {
  const list = []

  if (seller_id) {
    const sellerName = await getSellerName(seller_id)
    list.push({
      title: 'Partner:',
      value: sellerName,
    })
  }

  if (item_type_id) {
    const itemTypeName = await getItemTypeName(item_type_id)
    list.push({
      title: 'Item Type:',
      value: itemTypeName,
    })
  }

  if (validation_status) {
    list.push({
      title: 'Validation Status:',
      value: validation_status,
    })
  }

  if (listing_status) {
    list.push({
      title: 'Listing Status:',
      value: listing_status,
    })
  }

  if (published !== undefined) {
    list.push({
      title: 'Publish Status:',
      value: published === true ? 'YES' : 'NO',
    })
  }

  if (tcin) {
    list.push({
      title: 'TCIN:',
      value: tcin,
    })
  }

  return list
}

let ws: WebSocketInterface

export const BulkReprocessDialog = ({
  isOpen,
  filteredItems,
  searchParams,
  reject,
  onCloseHook,
}: Props) => {
  const [isPending, setIsPending] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [errored, setErrored] = useState<number>(0)
  const [completed, setCompleted] = useState<number>(0)
  const [total, setTotal] = useState<number>(filteredItems)
  const [errors, setErrors] = useState<ErrorCode[]>([])
  const [validation, setValidation] = useState<Validation>({})

  useEffect(() => {
    ws = SmsBulkApiWebSocket(() => {
      ws.subscribe(reject ? REJECT_SUCCESS : BUMP_SUCCESS, () => {
        setCompleted((value) => value + 1)
      })

      ws.subscribe(reject ? REJECT_FAILURE : BUMP_FAILURE, () => {
        setCompleted((value) => value + 1)
        setErrored((value) => value + 1)
      })
    })

    return () => {
      ws.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (completed === total) {
      setSuccess(true)
      setIsPending(false)
    }
  }, [completed, total])

  const [dataList, setDataList] = useState<DataListItem[]>([])

  useEffect(() => {
    getDataList(searchParams).then((data) => {
      setDataList(data)
    })
  }, [searchParams])

  const removeError = (error: ErrorCode) => () => {
    setErrors(differenceWith(isErrorEqual, errors, [error]))
  }

  const editError = (error: ErrorCode) => {
    const updatedErrors = map(
      (e) => (isErrorEqual(e, error) ? error : e),
      errors,
    )
    setErrors(updatedErrors)
  }

  const addError = (value: Nullable<ErrorCode>) => {
    if (value) {
      if (errors.find((e) => e.error_code === value.error_code)) {
        setValidation({ add_error: ['Duplicate error code.'] })
      } else {
        setValidation({})
        setErrors((prevErrors: any[]) => [...prevErrors, value])
      }
    }
  }

  const handleSubmit = async () => {
    const tcins = await getTcinsForBulkReprocessing(searchParams)
    const bumpParams: Dictionary<string | string[]> = {}
    if (searchParams.seller_id) {
      bumpParams.sellerId = searchParams.seller_id
    }
    if (searchParams.item_type_id) {
      bumpParams.itemTypeId = searchParams.item_type_id
    }

    const chunks = chunk(500, tcins)
    chunks.forEach((tcinSet) => {
      if (tcins.length < 1) return

      ws.send(
        reject ? REJECT_REQUEST : BUMP_REQUEST,
        JSON.stringify({
          ...bumpParams,
          tcins: tcinSet,
          ...(reject && {
            rejection_reasons: errors.map(({ optionalNotes, ...rest }) => {
              if (optionalNotes) {
                return { ...rest, reason: `${rest.reason} ${optionalNotes}` }
              } else {
                return rest
              }
            }),
          }),
        }),
        {
          'content-type': 'application/json',
        },
      )
    })

    setIsPending(true)
    setTotal(tcins.length)
  }

  const getDialogTitle = () => {
    const plural = pluralize(total, 'ITEM', 'ITEMS')

    if (reject) {
      if (success) {
        return `SUCCESS! REJECTION REQUEST COMPLETE`
      }

      if (isPending) {
        return `REQUESTING REJECTION FOR ${total} ${plural}...`
      }

      return `REJECT ${total} ${plural}`
    } else {
      if (success) {
        return `BULK REPROCESS REQUEST COMPLETE`
      }

      if (isPending) {
        return `REQUESTING BULK REPROCESS FOR ${total} ${plural}...`
      }

      return 'BULK REPROCESS'
    }
  }

  const getDialogContent = () => {
    if (isPending && total > 0) {
      return (
        <>
          <StyledProgress>
            <LinearProgress
              variant="determinate"
              value={(completed / total) * 100}
            />
          </StyledProgress>
          <Typography>
            {completed} of {total} requests processed
          </Typography>
          {errored > 0 && <Typography>{errored} requests failed</Typography>}
        </>
      )
    }

    if (success) {
      return (
        <>
          <Typography>
            {completed - errored} items were successfully{' '}
            {reject ? 'rejected' : 'reprocessed'}.
          </Typography>
          {errored > 0 && (
            <Typography>
              {errored} items could not be {reject ? 'rejected' : 'reprocessed'}
              .
            </Typography>
          )}
          <StyledDescription>
            It can take up to 2 hours for the items to be removed from the
            review queue.
          </StyledDescription>
        </>
      )
    }

    return (
      <>
        {!reject && (
          <StyledDescription>
            Bulk reprocessing items will cause their item data to be reprocessed
            through the multiple item validation layers.
          </StyledDescription>
        )}
        <StyledDescription>
          Items matching the following criteria will be{' '}
          {reject
            ? 'rejected and have the error codes as added in the section below'
            : 'reprocessed'}
          :
        </StyledDescription>
        <DataList data={dataList} />
        {reject && (
          <div>
            <ContentSpacer />
            <ErrorCodeTypeahead
              name="add_error"
              onChange={addError}
              clearOnSelect
              validation={validation}
              hideDeleted
            />
            <List data-testid="item-errors-list">
              {errors.map((error, idx) => {
                return (
                  <ErrorCodeLineItem
                    key={idx}
                    error={error}
                    handleRemove={removeError}
                    handleEdit={editError}
                  />
                )
              })}
            </List>
          </div>
        )}
      </>
    )
  }

  return (
    <DialogContainer
      disableScroll
      title={getDialogTitle()}
      isOpen={isOpen}
      isPending={isPending}
      submitButtonText={reject ? 'Reject Items' : 'Reprocess Items'}
      closeButtonText={success || isPending ? 'Close' : 'Cancel'}
      onSubmit={success || isPending ? undefined : handleSubmit}
      isSubmitDisabled={isPending || (reject && errors.length < 1)}
      onCancel={onCloseHook}
    >
      <div>{getDialogContent()}</div>
    </DialogContainer>
  )
}

export default BulkReprocessDialog
