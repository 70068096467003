import React from 'react'

import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'

import styled from '@emotion/styled'

import { createLoginLink } from 'services/payments'

const StyledButton = styled(Button)(({ theme }) => ({
  '&:visited': {
    color: theme.palette.common.white,
  },
})) as typeof Button

interface Props {
  sellerId: string
  disabled: boolean
}

const AccessStripeLink = ({ sellerId, disabled }: Props) => {
  const [loginLink, setLoginLink] = React.useState('')

  React.useEffect(() => {
    let mounted = true

    if (mounted && !disabled) {
      createLoginLink(sellerId).then((res) => {
        if (res) {
          if (mounted) {
            setLoginLink(res)
          }
        }
      })
    }

    return () => {
      mounted = false
    }
  }, [sellerId, disabled])

  return (
    <Tooltip
      title="Opens an external website in a new tab"
      aria-label="Access Stripe. Opens an external website in a new tab."
    >
      <div>
        <StyledButton
          variant="contained"
          color="primary"
          data-testid="access-stripe-button"
          href={loginLink}
          disabled={disabled}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Access Stripe - opens in a new tab"
          fullWidth
        >
          ACCESS STRIPE
        </StyledButton>
      </div>
    </Tooltip>
  )
}

export default AccessStripeLink
