import React from 'react'
import { useSelector } from 'react-redux'

import LinearProgress from '@mui/material/LinearProgress'

import styled from '@emotion/styled'

import DisplayCard from 'components/common/DisplayCard'

import { isOneOfUserRoles } from 'services/authorization'

import {
  currentSellerStatus,
  currentSellerId,
  currentSellerDisplayName,
  getMemberOf,
} from 'store/selectors'

import { Report, ReportParameters, ReportType } from 'types/Report'

import { cardData } from './cardData'
import useReports from './useReports'
import ReportTable from './ReportTable'
import SingleReportContent from './SingleReportContent'
import ReportCardActions from './ReportCardActions'
import { Box } from '@mui/material'

const StyledRoot = styled(DisplayCard)(({ theme }) => ({
  padding: theme.spacing(1),
  height: '100%',
}))

export interface Props {
  reportType: ReportType
  children?: React.ReactNode
  getParameters?: () => ReportParameters
  getAdditionalInfo?: (reports: Report[]) => Promise<Report[]>
  getReportName?: () => string | undefined
  getRelevantReports?: (reports: Report[]) => Report[]
}

export const ReportCard = ({
  reportType,
  children,
  getParameters,
  getAdditionalInfo,
  getReportName,
  getRelevantReports,
}: Props) => {
  const sellerId = useSelector(currentSellerId)
  const sellerName = useSelector(currentSellerDisplayName)
  const memberOf = useSelector(getMemberOf)
  const sellerStatus = useSelector(currentSellerStatus)

  const {
    title,
    description,
    dialogEnum,
    hasDateRange,
    hasTable,
    isInternal,
    limitToRoles,
    limitToStatus,
    generateText,
    completedText,
    icon: Icon,
    templateLink,
    templateName,
  } = cardData[reportType]

  const isAuthorized =
    (!limitToRoles || isOneOfUserRoles(memberOf, limitToRoles)) &&
    (!limitToStatus || (sellerStatus && limitToStatus.includes(sellerStatus)))

  const { actions, results, statuses } = useReports({
    dialogEnum,
    getAdditionalInfo,
    getParameters,
    getRelevantReports,
    getReportName,
    hasDateRange,
    hasTable,
    isInternal,
    reportType,
    sellerId,
  })

  if (statuses.isGettingReports) {
    return (
      <StyledRoot title={title} icon={Icon} description={description}>
        <LinearProgress data-testid="report-progress" />
      </StyledRoot>
    )
  }

  return (
    <StyledRoot
      title={title}
      icon={Icon}
      description={description}
      actions={
        <ReportCardActions
          reportActions={actions}
          reportStatuses={statuses}
          generateText={generateText}
          inProgressReport={results.inProgressReport}
          hasTable={hasTable}
          isAuthorized={!!isAuthorized}
          sellerId={sellerId}
          templateLink={templateLink}
          templateName={templateName}
        />
      }
    >
      <Box sx={{ flexWrap: 'wrap', justifyContent: 'space-between' }}>
        <Box sx={{ flex: '0 1 300px' }}>
          {children && (
            <Box data-testid="report-card-content" sx={{ marginBottom: 2 }}>
              {children}
            </Box>
          )}
        </Box>
        {!hasTable && results.latestReport && (
          <SingleReportContent
            completedText={completedText}
            isGeneratingReport={!!results.inProgressReport}
            hasDateRange={hasDateRange}
            hasError={statuses.hasError}
            isAuthorized={!!isAuthorized}
            latestReport={results.latestReport}
            sellerName={sellerName}
            title={title}
          />
        )}
        {hasTable && (
          <Box sx={{ flex: '1', marginLeft: 2 }}>
            <ReportTable
              sellerId={sellerId}
              isLoading={statuses.isGettingReports}
              reports={results.reports}
              isDisabled={!isAuthorized}
              title={title}
              onRequestCancel={actions.cancel}
              completedText={completedText}
            />
          </Box>
        )}
      </Box>
    </StyledRoot>
  )
}

export default ReportCard
