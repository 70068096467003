import { EnumerationDict } from 'types/Enumeration'
import {
  EnumerationActions,
  FETCH_ENUMERATIONS,
  FETCH_ENUMERATIONS_DONE,
  FETCH_ENUMERATIONS_ERROR,
} from './actionCreators'

export interface EnumerationState {
  readonly isEnumerationsPending: boolean
  readonly enumerations: EnumerationDict | undefined
}

export const initialState: EnumerationState = {
  isEnumerationsPending: false,
  enumerations: undefined,
}

export default function enumerationReducer(
  state: EnumerationState = initialState,
  action: EnumerationActions,
) {
  switch (action.type) {
    case FETCH_ENUMERATIONS: {
      const newState = {
        ...state,
        isEnumerationsPending: true,
      }

      return newState
    }

    case FETCH_ENUMERATIONS_DONE: {
      const { payload } = action
      const { enumerations } = payload

      const newState = {
        ...state,
        isEnumerationsPending: false,
        enumerations,
      }

      return newState
    }

    case FETCH_ENUMERATIONS_ERROR: {
      const newState = {
        ...state,
        isEnumerationsPending: false,
      }

      return newState
    }

    default:
      return state
  }
}
