import Typography from '@mui/material/Typography'

import TabularData, { FieldList } from 'components/common/TabularData'

import { getListingFieldErrors } from 'services/itemHelper'

import { SmsProduct } from 'types/Item'

interface Props {
  previousVersion?: SmsProduct
}

const fieldList: FieldList[] = [
  {
    key: 'displayName',
    displayName: 'Field Name',
  },
  {
    key: 'message',
    displayName: 'Error Message',
  },
]

const ErrorsOnPrevious = ({ previousVersion }: Props) => {
  if (!previousVersion) {
    return <Typography>No previous version.</Typography>
  }
  const errors = getListingFieldErrors(previousVersion)

  return <TabularData data={errors} fieldList={fieldList} />
}

export default ErrorsOnPrevious
