/**
 * The object should replace values that match the tester
 * with the newValue
 *
 * Example: setsObjectValues({ name: '' }, '', null) // returns { name: null }
 *
 * @param {Object} obj
 * @param testValue
 * @param newValue
 * @returns {Object}
 */

export function updateObjectValues(
  obj: any,
  testValue: any,
  newValue: any,
): any {
  const normalizedObject = Object.keys(obj).reduce((acc: any, curr: any) => {
    const currValue: any = obj[curr]
    if (currValue === testValue) {
      acc[curr] = newValue
    } else {
      if (
        currValue !== null &&
        !Array.isArray(currValue) &&
        typeof currValue === 'object'
      ) {
        acc[curr] = updateObjectValues(currValue, testValue, newValue) // handle nested objects
      } else if (currValue !== null && Array.isArray(currValue)) {
        acc[curr] = obj[curr].map((arrayItem: any) => {
          if (
            arrayItem !== null &&
            !Array.isArray(arrayItem) &&
            typeof arrayItem === 'object'
          ) {
            return updateObjectValues(arrayItem, testValue, newValue) // handle nested objects
          } else if (arrayItem === testValue) {
            return newValue
          } else {
            return arrayItem
          }
        })
      } else {
        acc[curr] = obj[curr]
      }
    }
    return acc
  }, {})

  return normalizedObject
}

export default updateObjectValues
