import getOr from 'lodash/fp/getOr'
import get from 'lodash/fp/get'

import { ReferralBasePercentage } from 'types/BaseReferralFee'
import { ReferralPercentage } from 'types/ReferralFee'
import Attribute from 'types/Attribute'

interface Props {
  referralPercentage:
    | ReferralPercentage
    | ReferralBasePercentage
    | undefined
    | null
  attribute: Nullable<Attribute>
  percent: string
}
export const isFormDirty = ({
  referralPercentage,
  attribute,
  percent,
}: Props): boolean => {
  const referralPercent =
    getOr(0, 'referral_percentage', referralPercentage) * 100

  const referralPercentString = referralPercent ? referralPercent.toFixed() : ''
  const attributeId = get('id', attribute)
  const attributeName = get('name', attribute)

  const referralTypeId =
    get('item_type_id', referralPercentage) ||
    get('sub_type_id', referralPercentage)
  const referralTypeName =
    get('itemTypeName', referralPercentage) ||
    get('subtypeName', referralPercentage)

  return (
    referralTypeId !== attributeId ||
    referralTypeName !== attributeName ||
    referralPercentString !== percent
  )
}
