import { Component } from 'react'
import styled from '@emotion/styled'

import { ZoomedImage } from './ZoomedImage'
import { ZoomableImageProps, ZoomableImageState } from 'types/PictureZoomable'

interface ScalableImageProps {
  fixedHeight?: number | string | boolean
  fixedWidth?: number | string | boolean
  hasRatio?: boolean
}

const StyledScalableImage = styled('img', {
  shouldForwardProp: (prop) =>
    prop !== 'fixedHeight' && prop !== 'fixedWidth' && prop !== 'hasRatio',
})<ScalableImageProps>((props) => ({
  width:
    props.fixedWidth || (props.hasRatio && props.fixedHeight)
      ? '100%'
      : undefined,
  height:
    props.fixedHeight || (props.hasRatio && props.fixedWidth)
      ? '100%'
      : undefined,
}))

export const getBaseUrl = (url: string) => url.split('?')?.[0]

export const getBackgroundPosition = (
  pos: number,
  ratio: number,
  renderedDimension: number,
) =>
  -((pos / renderedDimension) * (ratio * renderedDimension - renderedDimension))

const MAX_HEIGHT_WIDTH = 4000

export const getImageMeasurement = (measure: number, ratio: number) =>
  MAX_HEIGHT_WIDTH < measure * ratio ? MAX_HEIGHT_WIDTH : measure * ratio

const getZoomedPosition = (pos: number, ratio: number, size: number) =>
  getBackgroundPosition(pos, ratio, size)

export const generateZoomedSrc = (
  source: string,
  width: number,
  height: number,
  ratio: number,
) => {
  const zoomedImageWidth = getImageMeasurement(width, ratio)
  const zoomedImageHeight = getImageMeasurement(height, ratio)
  return `${getBaseUrl(
    source,
  )}?wid=${zoomedImageWidth}&hei=${zoomedImageHeight}&fmt=pjpeg`
}

const cacheZoomedImage = (source: string) => {
  const cachedZoom = new Image()
  cachedZoom.src = source
}

const StyledZoomableImageContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hasHeight' && prop !== 'hasRatio',
})<{ hasHeight: boolean; hasRatio: boolean }>((props) => ({
  position: 'relative',
  height: props.hasHeight || props.hasRatio ? '100%' : undefined,
}))

export class ZoomableImage extends Component<
  ZoomableImageProps,
  ZoomableImageState
> {
  public static displayName = 'ZoomableImage'

  static defaultProps = {
    position: { x: 0, y: 0 },
    zoomRatio: 3,
  }

  private constructor(props: ZoomableImageProps) {
    super(props)
    this.smallImageEl = {} as HTMLImageElement
    this.state = { offsetWidth: -1, offsetHeight: -1 }
  }

  componentDidUpdate() {
    // Whenever the component is changed, store its new dimensions and update the zoomUrl if necessary.
    // Sometimes on initial render, the size of the image is something tiny like 200x20, which makes the "zoomed in" image really small as well.
    this.updateZoomUrl()
  }

  smallImageEl: HTMLImageElement

  setSmallImageEl = (el: HTMLImageElement) => {
    if (!el) return

    this.smallImageEl = el
    this.updateZoomUrl()
  }

  updateZoomUrl() {
    // If nothing's changed, don't modify state.
    if (!this.smallImageEl || !this.dimensionsHaveChanged()) return

    const { zoomRatio, src, zoomedSrc } = this.props
    const { offsetWidth, offsetHeight } = this.smallImageEl

    const zoomURL =
      zoomedSrc ??
      generateZoomedSrc(src, offsetWidth, offsetHeight, zoomRatio as number)
    cacheZoomedImage(zoomURL)

    this.setState({ zoomURL, offsetWidth, offsetHeight })
  }

  dimensionsHaveChanged() {
    return (
      this.smallImageEl.offsetHeight !== this.state.offsetHeight ||
      this.smallImageEl.offsetWidth !== this.state.offsetWidth
    )
  }

  render() {
    const {
      src,
      alt,
      hasRatio = false,
      hasWidth = false,
      hasHeight = false,
      loaded,
      noZoom,
      position = { x: 0, y: 0 },
      zoomRatio = 3,
    } = this.props

    const { zoomURL, offsetWidth, offsetHeight } = this.state

    return (
      <StyledZoomableImageContainer hasHeight={hasHeight} hasRatio={hasRatio}>
        <StyledScalableImage
          alt={alt}
          fixedHeight={hasHeight}
          fixedWidth={hasWidth}
          hasRatio={hasRatio}
          ref={this.setSmallImageEl}
          src={src}
        />
        {!noZoom && loaded && this.smallImageEl && (
          <ZoomedImage
            xPos={getZoomedPosition(
              position.x,
              zoomRatio,
              offsetWidth as number,
            )}
            yPos={getZoomedPosition(
              position.y,
              zoomRatio,
              offsetHeight as number,
            )}
            zoomedWidth={getImageMeasurement(offsetWidth as number, zoomRatio)}
            zoomURL={zoomURL}
          />
        )}
      </StyledZoomableImageContainer>
    )
  }
}
