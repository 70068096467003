import React from 'react'

import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import MuiRadioGroup from '@mui/material/RadioGroup'
import Typography from '@mui/material/Typography'

export interface Props {
  className?: string
  id: string
  label?: string
  name: string
  onChange: (value: string, name: string) => void
  options: {
    disabled?: boolean
    label: string
    labelPlacement?: 'top' | 'start' | 'bottom' | 'end'
    value: string
  }[]
  value: string
  row?: boolean
  defaultValue?: string
}

const RadioGroup = ({
  className,
  id,
  label,
  name,
  onChange,
  options,
  value,
  row,
  defaultValue,
}: Props) => {
  const onInputChange = (event: React.ChangeEvent<{}>) => {
    onChange((event.target as HTMLInputElement).value, name)
  }

  return (
    <FormControl className={className} component="fieldset" fullWidth>
      {label && (
        <FormLabel component="legend">
          <Typography>{label}</Typography>
        </FormLabel>
      )}
      <MuiRadioGroup
        aria-label={label}
        onChange={onInputChange}
        defaultValue={defaultValue}
        name={name}
        value={value}
        row={row ?? false}
        data-testid={id}
      >
        {options.map((option, key) => (
          <FormControlLabel
            key={key}
            value={option.value}
            control={<Radio />}
            label={option.label}
            labelPlacement={option.labelPlacement}
            disabled={option.disabled}
            data-testid={`${id}-${key}`}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  )
}

export default RadioGroup
