import React from 'react'
import { useDispatch } from 'react-redux'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import ReportProgress from 'components/common/ReportCard/ReportProgress'

import useInterval from 'hooks/useInterval'

import { pollReport } from 'services/reports'
import { saveReportAsFile } from 'services/reports'

import { closeDialog } from 'store/dialog/actionCreator'

import { Report, ReportStatus } from 'types/Report'

export type Props = {
  isOpen: boolean
  title: string
  reportTitle: string
  sellerId?: string
  createReport: () => Promise<Report>
}

export const ReportDownloadDialog = ({
  isOpen,
  title,
  reportTitle,
  sellerId,
  createReport,
}: Props) => {
  const reduxDispatch = useDispatch()

  const [report, setReport] = React.useState<Report>()

  React.useEffect(() => {
    if (!report) {
      createReport()
        .then((res) => {
          setReport(res)
        })
        .catch(() => {
          reduxDispatch(closeDialog())
        })
    }
  }, [report, reduxDispatch, createReport])

  useInterval(
    () => {
      if (
        report?.status !== ReportStatus.PENDING &&
        report?.status !== ReportStatus.PROCESSING
      ) {
        return
      }

      pollReport(report.id, sellerId).then((res) => {
        setReport(res)
      })
    },
    report?.status === ReportStatus.PENDING ||
      report?.status === ReportStatus.PROCESSING
      ? 5000
      : null,
  )

  const onSubmit = () => {
    if (report) {
      saveReportAsFile({
        report,
        title: reportTitle,
        hasDateRange: false,
      })
      reduxDispatch(closeDialog())
    }
  }

  return (
    <DialogContainer
      title={title}
      isOpen={isOpen}
      maxWidth="md"
      onSubmit={report?.status === ReportStatus.COMPLETE ? onSubmit : undefined}
      submitButtonText={`Download`}
      disableScroll
    >
      <div data-testid="report-date-range">
        <ReportProgress status={report?.status} />
      </div>
    </DialogContainer>
  )
}

export default ReportDownloadDialog
