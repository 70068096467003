import React from 'react'
import { useDispatch } from 'react-redux'

import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'

import styled from '@emotion/styled'
import { grey } from 'config/themeConfig'

import { unmountDialog } from 'store/dialog/actionCreator'

import Aside from '../Aside'
import DialogHeader from './DialogHeader'
import DialogTransition from './DialogTransition'

const StyledGridItem = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(5),
}))

export interface Props {
  title: string
  isOpen: boolean
  isAsideOpen?: boolean
  asideTitle?: string
  onRequestClose: () => void
  aside?: React.ReactNode
  children?: React.ReactNode
}

export const FullScreenDialogContainer = ({
  title,
  isOpen,
  isAsideOpen,
  asideTitle,
  onRequestClose,
  aside,
  children,
}: Props) => {
  const dispatch = useDispatch()

  const handleExited = () => {
    dispatch(unmountDialog())
  }

  const childrenWidth = aside && isAsideOpen ? 8 : 12

  return (
    <Dialog
      open={isOpen}
      onClose={onRequestClose}
      TransitionComponent={DialogTransition}
      fullScreen
      data-testid="fullscreen-dialog"
      TransitionProps={{
        onExited: handleExited,
      }}
    >
      <DialogHeader title={title} onClose={onRequestClose} />
      <Grid container sx={{ height: '100%' }}>
        <StyledGridItem item xs={childrenWidth}>
          {children}
        </StyledGridItem>
        {aside && (
          <StyledGridItem
            item
            xs={4}
            sx={{
              backgroundColor: isAsideOpen ? grey[100] : undefined,
            }}
          >
            <Aside isOpen={isAsideOpen} title={asideTitle}>
              {aside}
            </Aside>
          </StyledGridItem>
        )}
      </Grid>
    </Dialog>
  )
}

export default FullScreenDialogContainer
