import { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { DataListItem } from 'components/common/DataList'
import DisplayCard from 'components/common/DisplayCard'
import Link from 'components/common/Link'
import AccessStripeLink from 'components/PayoutsAndAccountPage/AccessStripeLink'
import Text from 'components/common/Text'
import WarningIcon from 'components/common/WarningIcon'

import { GENERIC_API_ERROR } from 'constants/errors'

import { MonetizationOn } from '@mui/icons-material'

import { isUserRole } from 'services/authorization'
import formatCurrency from 'services/formatCurrency'
import { RoutePath } from 'services/NavigationHelper'
import { getPaymentAccounts } from 'services/payments'
import { getPayouts } from 'services/payouts'
import { USER_ROLE_APP_SMS_ADMIN } from 'services/roles'
import {
  DATE_FORMAT_MONTH_DAY_YEAR,
  formatDate,
  getDateAfter,
} from 'services/dateService'

import {
  currentSellerId,
  getMemberOf,
  isDefunctSellerStatus,
  stripeAccountIdSelector,
} from 'store/selectors'

import TabularData, { FieldList } from '../common/TabularData'
import styled from '@emotion/styled'

const StyledCardActions = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(8),
}))

const StyledDisplayCard = styled(DisplayCard)({
  height: 'unset',
})

const StyledPayoutsTitle = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(3),
}))

const StyledRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const StyledText = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2),
}))

const fromDate = null
const toDate = getDateAfter(new Date(), 1)
const resultsOffsetId = undefined

const formatFinancials = (amount: number, currency: string) => {
  return formatCurrency(amount, currency)
}

export const SellerFinancialsCard = () => {
  const sellerId = useSelector(currentSellerId)
  const stripeAccountId = useSelector(stripeAccountIdSelector)
  const memberOf = useSelector(getMemberOf)
  const isExternalAdmin = isUserRole(memberOf, USER_ROLE_APP_SMS_ADMIN)
  const isDefunct = useSelector(isDefunctSellerStatus)

  const [loading, setLoading] = useState(false)
  const [verification, setVerification] = useState(false)
  const [tooltip, setTooltip] = useState('')
  const [previousPayout, setPreviousPayout] = useState('No Payouts')
  const [stripeBalance, setStripeBalance] = useState<string | undefined>()
  const [error, setError] = useState(false)
  const [previousPayoutDate, setPreviousPayoutDate] = useState('')

  const fetchFinancials = useCallback(async (sellerId: string) => {
    setLoading(true)

    try {
      const [accounts, payouts] = await Promise.all([
        getPaymentAccounts(sellerId),
        getPayouts({
          sellerId,
          resultsOffsetId,
          fromDate,
          toDate,
        }),
      ])

      const balance = formatFinancials(accounts.balance, 'USD')
      setVerification(accounts.needs_verification)
      if (accounts.verification_reason) {
        setTooltip(accounts.verification_reason)
      }
      setStripeBalance(balance)

      if (payouts && payouts.length) {
        const payoutAmount = formatFinancials(
          payouts[0].payout_amount,
          payouts[0].currency,
        )
        const payoutDate = formatDate(
          payouts[0].arrival_date,
          DATE_FORMAT_MONTH_DAY_YEAR,
        )
        setPreviousPayoutDate(payoutDate)
        setPreviousPayout(payoutAmount)
      }
    } catch (e) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (sellerId && stripeAccountId) {
      fetchFinancials(sellerId)
    }
  }, [fetchFinancials, sellerId, stripeAccountId])

  const data: DataListItem[] = [
    {
      title: 'Current Stripe Balance',
      element: <Text>{stripeBalance}</Text>,
    },
    {
      title: `Previous Payout ${previousPayoutDate}`,
      element: (
        <Link to={`/${sellerId}${RoutePath.PAYOUTS_AND_ACCOUNT}`}>
          {previousPayout}
        </Link>
      ),
    },
  ]

  let title: string | React.ReactNode = 'Payouts'

  if (verification) {
    title = (
      <>
        <StyledPayoutsTitle>Payouts</StyledPayoutsTitle>
        <WarningIcon tooltip={tooltip} />
      </>
    )
  }

  let content = <Text>{GENERIC_API_ERROR}</Text>

  if (!error) {
    content = (
      <TabularData
        hasHeader={false}
        extraPadding={true}
        fieldList={
          [
            {
              key: 'title',
              displayName: '',
              width: 100,
            },
            {
              key: 'element',
              displayName: '',
              width: 50,
              formatCell: ({ element }) => element,
            },
          ] as FieldList<DataListItem>[]
        }
        data={data}
      />
    )
  }

  const actions = isDefunct === false && sellerId && (
    <StyledCardActions data-testid="access-stripe">
      <StyledRow>
        <AccessStripeLink disabled={!isExternalAdmin} sellerId={sellerId} />

        <StyledText>
          <Text data-testid="admin-only-text" type="bold">
            Admin Only
          </Text>
        </StyledText>
      </StyledRow>
    </StyledCardActions>
  )

  return (
    <StyledDisplayCard
      iconColor="target"
      title={title as string}
      icon={MonetizationOn}
      isLoading={loading}
      actions={actions}
    >
      {content}
    </StyledDisplayCard>
  )
}

export default SellerFinancialsCard
