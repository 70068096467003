import apiConfig, { ENVIRONMENT_PRODUCTION } from 'config/apiConfig'

import { isTargetDomain } from '@praxis/component-auth'

import {
  findUserRole,
  isUserRoleExternal,
  isUserAuthorized,
} from 'services/authorization'
import { setUserData } from 'services/fireflyInsights'
import { getParam, hasParam } from 'services/urlHelper'

import { UserInfo } from 'types/UserInfo'

const QUERY_PARAM_KEY_USER_ROLE = 'user_role'

export function formatUserInfo(user: UserInfo): {
  userInfo: UserInfo
  isAuthorized: boolean
} {
  const memberOf = formatGroup(user.memberOf)
  const externalUser = isUserRoleExternal(memberOf)
  const isAuthorized = isUserAuthorized(memberOf) && !externalUser
  const userRole = findUserRole(memberOf)

  setUserData({
    id: externalUser ? user.email : user.lanId,
    segment: userRole || '',
    external: externalUser,
  })

  return {
    userInfo: {
      id: user.id,
      email: user.email,
      firstName: user.firstName,
      fullName: `${user.firstName} ${user.lastName}`,
      lanId: user.lanId,
      lastName: user.lastName,
      accessToken: user.accessToken,
      memberOf,
      userRole,
    },
    isAuthorized,
  }
}

function formatGroup(ldapString: string[]): string[] {
  // To toggle roles simply add the query param '?user_role=<USER_ROLE_CONSTANT>
  if (
    apiConfig.environment !== ENVIRONMENT_PRODUCTION &&
    hasParam(window.location, QUERY_PARAM_KEY_USER_ROLE)
  ) {
    const userRoleParam = getParam(
      window.location,
      QUERY_PARAM_KEY_USER_ROLE,
    ) as string

    if (apiConfig.auth.roles.includes(userRoleParam.toUpperCase())) {
      return [userRoleParam]
    }
  }

  return ldapString
}

export const shouldSendAuthoriationHeader = (url: string): boolean => {
  const isTarget = isTargetDomain(url)
  const tests = [
    /\/item_taxonomies\/v2\/taxonomies/,
    /\/item_taxonomies\/v2\/assignments/,
    /\/barcode_validations\/v1\/details/,
    /\/target_plus_items/,
    /\/locations/,
  ]

  if (isTarget) {
    return tests.every((item) => item.test(url) === false)
  }

  return isTarget
}
