import { FormikProps, withFormik } from 'formik'
import * as yup from 'yup'

import { useState } from 'react'

import { Grid, InputAdornment, Typography } from '@mui/material'

import DateInput from 'components/common/DateInput'
import InputField from 'components/common/form/InputField'
import DialogContainer from 'components/common/Dialog/DialogContainer'

import { Carrier } from 'types/Carrier'
import { Markup } from 'types/Markup'

import { add } from 'date-fns'
import { DATE_DISPLAY_FORMAT, formatDateLocalTime } from 'services/dateService'
import { ValidationSchema } from 'services/validation'
import { createCarrierMarkup } from 'services/sellerShippingManagement'

interface FormValues {
  startDate: string
  rate: string
}

interface ComponentProps extends FormValues {
  isOpen: boolean
  carrierMarkup: Markup
  handleClose: (newMarkup: Markup) => void
}

type Props = ComponentProps & FormikProps<FormValues>

const rateRegex = /^(?:[1-9]\d?(?:\.\d)?|100(?:\.0)?)$/ // numbers between 1 and 100 inclusive, to the tenths place

export const validationSchema: ValidationSchema = yup.object().shape({
  startDate: yup.string().required(),
  rate: yup
    .string()
    .required()
    .matches(rateRegex, 'Please enter a markup between 1% and 100%'),
})

export const EditCarrierMarkupForm = ({
  isOpen,
  carrierMarkup,
  dirty,
  isValid,
  setFieldValue,
  handleSubmit,
}: Props) => {
  const [inputDate, setInputDate] = useState<string>('')

  let carrierName =
    carrierMarkup.carrier === Carrier.FEDEX ? 'FedEx' : carrierMarkup.carrier

  const minPickerDate = {
    disabled: { before: add(new Date(), { days: 1 }) },
  }

  const handleDateChange = (date: Date | undefined) => {
    if (!date) return

    const value = date ? formatDateLocalTime(date, DATE_DISPLAY_FORMAT) : ''

    setInputDate(value)
    setFieldValue('startDate', value)
  }

  const handleDateDelete = () => {
    setInputDate('')
    setFieldValue('startDate', '')
  }

  return (
    <DialogContainer
      title={`Edit ${carrierName} Carrier Markup`}
      isOpen={isOpen}
      submitButtonText="Save"
      onSubmit={handleSubmit}
      isSubmitDisabled={!isValid || !dirty}
      maxWidth="sm"
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography sx={{ pb: 2 }} className="hc-clr-grey03 hc-fs-sm">
            {`This markup will apply to all ${carrierName} service-level rates starting 12am CST of the selected effective date and will override the markup floor.`}
          </Typography>
          <strong className="hc-fs-sm">{`Current ${carrierName} carrier markup is set to ${
            carrierMarkup?.rate ? carrierMarkup.rate * 100 : undefined
          }%`}</strong>
        </Grid>
        <Grid item xs={4}>
          <Typography
            sx={{ pt: 2, pb: 0.5 }}
            className="hc-clr-grey03 hc-fs-xs"
          >
            Effective Start Date
          </Typography>
          <DateInput
            isDisabled={false}
            value={inputDate}
            placeholder="Start Date"
            onChange={handleDateChange}
            dayPickerProps={{ ...minPickerDate }}
            onDelete={handleDateDelete}
          />
          <Typography
            sx={{ pt: 2, pb: 0.5 }}
            className="hc-clr-grey03 hc-fs-xs"
          >
            Effective Shipping Markup %
          </Typography>
          <InputField
            name="rate"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            enableOnChangeValidation
          />
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export const EditCarrierMarkup = withFormik<ComponentProps, FormValues>({
  mapPropsToValues: () => ({ startDate: '', rate: '' }),
  handleSubmit: async (values, { props }) => {
    const { carrierMarkup, handleClose } = props
    const { startDate, rate } = values

    const newRate = parseFloat(rate) / 100

    await createCarrierMarkup(carrierMarkup.carrier, newRate, startDate).then(
      (res) => {
        handleClose(res)
      },
    )
  },
  validationSchema,
  enableReinitialize: true,
})(EditCarrierMarkupForm)

export default EditCarrierMarkup
