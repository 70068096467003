import { useState } from 'react'
import { withFormik, Form, FormikProps } from 'formik'
import * as yup from 'yup'

import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import InputField from 'components/common/form/InputField'
import SelectField from 'components/common/form/SelectField'
import { TIME, PERIOD } from 'components/common/TimePicker'
import Text from 'components/common/Text'
import Banner from 'components/common/Banner'
import { ValidationSchema, maxCharacterCount } from 'services/validation'
import { updateNotification } from 'services/notifications'
import {
  getTimePeriod,
  getIsoString,
  getTimeIn12Hour,
  isAfterDate,
  formatDate,
  DATE_PICKER_FORMAT,
} from 'services/dateService'

import {
  NotificationPriority,
  NotificationUpdate,
  Notification,
  NotificationDisplayOn,
} from 'types/Notifications'

import { USER_ROLE_APP_SMS_ADMIN, USER_ROLE_APP_SMS_READ } from 'services/roles'

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
}))

const StyledRightContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.grey[100],
}))

const validationSchema: ValidationSchema = yup.object().shape({
  title: yup
    .string()
    .label('Title')
    .test({
      name: 'isTitle',
      test: maxCharacterCount('title', 50),
    })
    .required(),
  message: yup
    .string()
    .label('Message')
    .test({
      name: 'isMessage',
      test: maxCharacterCount('message', 500),
    })
    .required(),
  priority: yup.string().label('Priority').required(),
  displayFor: yup.string().label('Display For').required(),
  displayOn: yup.string().label('Display On').required(),
  startDate: yup.string().label('Start Date').required(),
  startTime: yup.string().label('Start Time').required(),
  startPeriod: yup.string().label('AM/PM').required(),
  endDate: yup.string().label('End Date').required(),
  endTime: yup.string().label('End Time').required(),
  endPeriod: yup.string().label('AM/PM').required(),
})

export interface FormValues {
  title: string
  message: string
  priority: string
  displayFor: string
  displayOn: string
  startDate: string
  startTime: string
  startPeriod: string
  endDate: string
  endTime: string
  endPeriod: string
}

export interface ComponentProps extends FormValues {
  notification?: Notification
  isOpen: boolean
  handleCloseDialog: () => void
  isValid: boolean
  handleSubmit: any
}

export type Props = ComponentProps & FormikProps<FormValues>

export enum DisplayFor {
  ADMINS_AND_INTERNAL = 'ADMINS_AND_TEAM_MEMBERS',
  ALL = 'ALL',
  INTERNAL = 'INTERNAL',
}

export enum DisplayOn {
  PARTNER_DASHBOARD = 'PARTNER_DASHBOARD',
  HOMEPAGE = 'HOMEPAGE',
  ALL = 'ALL',
}

const getDisplayFor = (displayFor: string) => {
  switch (displayFor) {
    case DisplayFor.ADMINS_AND_INTERNAL:
      return [USER_ROLE_APP_SMS_ADMIN, DisplayFor.INTERNAL]
    case DisplayFor.ALL:
      return [
        USER_ROLE_APP_SMS_ADMIN,
        USER_ROLE_APP_SMS_READ,
        DisplayFor.INTERNAL,
      ]
    case DisplayFor.INTERNAL:
      return [DisplayFor.INTERNAL]
    default:
      return []
  }
}

const getDisplayOn = (displayOn: string) => {
  switch (displayOn) {
    case DisplayOn.PARTNER_DASHBOARD:
      return [NotificationDisplayOn.PARTNER_DASHBOARD]
    case DisplayOn.HOMEPAGE:
      return [NotificationDisplayOn.HOMEPAGE]
    case DisplayOn.ALL:
      return [
        NotificationDisplayOn.PARTNER_DASHBOARD,
        NotificationDisplayOn.HOMEPAGE,
      ]
    default:
      return []
  }
}

const EditNotificationForm = ({
  notification,
  isOpen,
  isValid,
  dirty,
  handleSubmit,
  values,
  isSubmitting,
  setFieldError,
  setSubmitting,
}: Props) => {
  const [page, setPage] = useState(1)

  const submitText = page === 1 ? 'Preview' : 'Save'
  const title = notification?.id
    ? 'Edit Banner Message'
    : 'Add new Banner Message'

  const onDisplayOnChange = (value: string) => {
    switch (value) {
      case DisplayOn.PARTNER_DASHBOARD:
        return { dependentField: 'displayFor', dependentValue: '' }
      case DisplayOn.HOMEPAGE:
        return {
          dependentField: 'displayFor',
          dependentValue: DisplayFor.INTERNAL,
        }
      case DisplayOn.ALL:
        return { dependentField: 'displayFor', dependentValue: '' }
      default:
        return { dependentField: 'displayFor', dependentValue: '' }
    }
  }

  const onNextPage = () => {
    const { startDate, startTime, startPeriod, endDate, endTime, endPeriod } =
      values

    const isoStartDate = getIsoString(
      `${startDate} ${startTime} ${startPeriod}`,
    )
    const isoEndDate = getIsoString(`${endDate} ${endTime} ${endPeriod}`)

    if (isoStartDate && isoEndDate) {
      const startDateObj = new Date(isoStartDate)
      const endDateObj = new Date(isoEndDate)

      if (isAfterDate(startDateObj, endDateObj)) {
        setFieldError('startDate', 'Start date cannot be after end date')
        setSubmitting(false)
        return
      }
    }

    setPage((prev) => prev + 1)
  }

  const onPreviousPage = () => {
    if (page > 1) {
      setPage((prev) => prev - 1)
    }
  }

  return (
    <DialogContainer
      isOpen={isOpen}
      title={title}
      isPending={false}
      onPrevious={page > 1 ? onPreviousPage : undefined}
      previousButtonText={page > 1 ? 'Back to previous' : undefined}
      onSubmit={page === 1 ? onNextPage : handleSubmit}
      submitButtonText={submitText}
      isSubmitDisabled={!isValid || !dirty}
      maxWidth="lg"
    >
      <Form>
        {page === 1 && (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <StyledPaper elevation={0}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputField
                      required
                      name="title"
                      label="Title"
                      disabled={isSubmitting}
                      maxCharacters={50}
                      enableOnChangeValidation
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      required
                      multiline
                      rows={4}
                      name="message"
                      label="Message"
                      disabled={isSubmitting}
                      maxCharacters={500}
                      enableOnChangeValidation
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Text>
                      To hyperlink text, type in the following format: [link
                      text](link url)
                    </Text>
                  </Grid>
                </Grid>
              </StyledPaper>
            </Grid>
            <Grid item xs={6}>
              <StyledRightContainer elevation={0}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SelectField
                      required
                      name="priority"
                      options={[
                        {
                          label: '1 - Critical',
                          value: NotificationPriority.CRITICAL,
                        },
                        {
                          label: '2 - Warning',
                          value: NotificationPriority.WARNING,
                        },
                        {
                          label: '3 - Inform',
                          value: NotificationPriority.INFORM,
                        },
                      ]}
                      keyName="label"
                      valueName="value"
                      label="Priority"
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectField
                      required
                      name="displayOn"
                      options={[
                        {
                          label: 'Partner Dashboard',
                          value: DisplayOn.PARTNER_DASHBOARD,
                        },
                        {
                          label: 'Internal Homepage',
                          value: DisplayOn.HOMEPAGE,
                        },
                        {
                          label: 'Internal Homepage & Partner Dashboard',
                          value: DisplayOn.ALL,
                        },
                      ]}
                      keyName="label"
                      valueName="value"
                      label="Display Location"
                      disabled={isSubmitting}
                      changeDependentField={onDisplayOnChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectField
                      required
                      name="displayFor"
                      options={[
                        {
                          label: 'Partner Admins & Target TMs',
                          value: DisplayFor.ADMINS_AND_INTERNAL,
                        },
                        {
                          label: 'Partner Admins, Read Only & Target TMs',
                          value: DisplayFor.ALL,
                        },
                        {
                          label: 'Only Target TMs',
                          value: DisplayFor.INTERNAL,
                        },
                      ]}
                      optionDisableCallback={(item: any) => {
                        return (
                          (values.displayOn === DisplayOn.HOMEPAGE &&
                            item.value === DisplayFor.ADMINS_AND_INTERNAL) ||
                          (values.displayOn === DisplayOn.HOMEPAGE &&
                            item.value === DisplayFor.ALL) ||
                          (values.displayOn === DisplayOn.PARTNER_DASHBOARD &&
                            item.value === DisplayFor.INTERNAL) ||
                          (values.displayOn === DisplayOn.ALL &&
                            item.value === DisplayFor.INTERNAL)
                        )
                      }}
                      keyName="label"
                      valueName="value"
                      label="Displays For"
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputField
                      required
                      type="date"
                      name="startDate"
                      label="Start Date"
                      max={values.endDate}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <SelectField
                      required
                      name="startTime"
                      label="Start Time"
                      options={TIME}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <SelectField
                      required
                      name="startPeriod"
                      label="AM/PM"
                      options={PERIOD}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputField
                      required
                      type="date"
                      name="endDate"
                      label="End Date"
                      min={values.startDate}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <SelectField
                      required
                      name="endTime"
                      label="End Time"
                      options={TIME}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <SelectField
                      required
                      name="endPeriod"
                      label="AM/PM"
                      options={PERIOD}
                      disabled={isSubmitting}
                    />
                  </Grid>
                </Grid>
              </StyledRightContainer>
            </Grid>
          </Grid>
        )}
        {page === 2 && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Banner
                disabled
                notifications={[
                  {
                    title: values.title,
                    message: values.message,
                    priority: values.priority as NotificationPriority,
                    display_for: getDisplayFor(values.displayFor),
                    display_on: getDisplayOn(values.displayOn),
                    start_date:
                      getIsoString(
                        `${values.startDate} ${values.startTime} ${values.startPeriod}`,
                      ) ?? '',
                    end_date:
                      getIsoString(
                        `${values.endDate} ${values.endTime} ${values.endPeriod}`,
                      ) ?? '',
                  },
                ]}
              />
            </Grid>
          </Grid>
        )}
      </Form>
    </DialogContainer>
  )
}

export const EditNotification = withFormik<ComponentProps, FormValues>({
  mapPropsToValues: (props) => {
    const { notification } = props

    let startDate = ''
    let startTime = ''
    let startPeriod = ''
    let endDate = ''
    let endTime = ''
    let endPeriod = ''
    let displayFor = ''
    let displayOn = ''

    if (notification?.start_date) {
      startDate = formatDate(notification.start_date, DATE_PICKER_FORMAT)
      startTime = getTimeIn12Hour(notification.start_date) ?? startTime
      startPeriod = getTimePeriod(notification.start_date) ?? startPeriod
    }

    if (notification?.end_date) {
      endDate = formatDate(notification.end_date, DATE_PICKER_FORMAT)
      endTime = getTimeIn12Hour(notification.end_date) ?? endTime
      endPeriod = getTimePeriod(notification.end_date) ?? endPeriod
    }

    if (notification?.display_for) {
      if (
        notification.display_for.includes(USER_ROLE_APP_SMS_ADMIN) &&
        notification.display_for.includes(USER_ROLE_APP_SMS_READ) &&
        notification.display_for.includes(DisplayFor.INTERNAL)
      ) {
        displayFor = DisplayFor.ALL
      } else if (
        notification.display_for.includes(USER_ROLE_APP_SMS_ADMIN) &&
        !notification.display_for.includes(USER_ROLE_APP_SMS_READ) &&
        notification.display_for.includes(DisplayFor.INTERNAL)
      ) {
        displayFor = DisplayFor.ADMINS_AND_INTERNAL
      } else if (
        !notification.display_for.includes(USER_ROLE_APP_SMS_ADMIN) &&
        !notification.display_for.includes(USER_ROLE_APP_SMS_READ) &&
        notification.display_for.includes(DisplayFor.INTERNAL)
      ) {
        displayFor = DisplayFor.INTERNAL
      }
    }

    if (notification?.display_on) {
      if (
        notification.display_on.includes(NotificationDisplayOn.HOMEPAGE) &&
        notification.display_on.includes(
          NotificationDisplayOn.PARTNER_DASHBOARD,
        )
      ) {
        displayOn = DisplayOn.ALL
      } else if (
        notification.display_on.includes(NotificationDisplayOn.HOMEPAGE) &&
        !notification.display_on.includes(
          NotificationDisplayOn.PARTNER_DASHBOARD,
        )
      ) {
        displayOn = DisplayOn.HOMEPAGE
      } else if (
        !notification.display_on.includes(NotificationDisplayOn.HOMEPAGE) &&
        notification.display_on.includes(
          NotificationDisplayOn.PARTNER_DASHBOARD,
        )
      ) {
        displayOn = DisplayOn.PARTNER_DASHBOARD
      }
    }

    return {
      title: notification?.title ?? '',
      message: notification?.message ?? '',
      priority: notification?.priority ?? '',
      displayFor,
      displayOn,
      startDate,
      startTime,
      startPeriod,
      endDate,
      endTime,
      endPeriod,
    }
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    const {
      title,
      message,
      priority,
      displayFor,
      displayOn,
      startDate,
      startTime,
      startPeriod,
      endDate,
      endTime,
      endPeriod,
    } = values

    const { notification } = props

    const usersToDisplayFor = getDisplayFor(displayFor)
    const locationToDisplay = getDisplayOn(displayOn)

    const isoStartDate = getIsoString(
      `${startDate} ${startTime} ${startPeriod}`,
    )
    const isoEndDate = getIsoString(`${endDate} ${endTime} ${endPeriod}`)

    if (isoStartDate && isoEndDate) {
      const notificationUpdate: NotificationUpdate = {
        ...(notification?.id
          ? {
              id: notification.id,
            }
          : {}),
        title,
        message,
        priority,
        display_for: usersToDisplayFor,
        display_on: locationToDisplay,
        start_date: isoStartDate,
        end_date: isoEndDate,
      } as any

      try {
        await updateNotification(notificationUpdate)

        props.handleCloseDialog()
      } catch (e) {
        setSubmitting(false)
      }
    }
  },
  enableReinitialize: true,
  validationSchema,
})(EditNotificationForm)

export default EditNotification
