import { useEffect, useState } from 'react'
import styled from '@emotion/styled'

import {
  caseAttachmentImageUrl,
  getFilenameFromId,
  saveAttachment,
} from 'services/cases'
import { SkeletonLoader } from 'components/common/SkeletonLoader'

const StyledImageContainer = styled('img')(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  width: '100%',
  height: 'auto',
  cursor: 'pointer',
  borderColor: theme.palette.grey[300],
}))

interface Props {
  sellerId: string
  attachmentId: string
}

export const ImageAttachment = ({ sellerId, attachmentId }: Props) => {
  const [imageSrc, setImageSrc] = useState('')
  const [isImageLoader, setImageLoader] = useState(true)
  const attachmentName = getFilenameFromId(attachmentId)

  useEffect(() => {
    caseAttachmentImageUrl(sellerId, attachmentId)
      .then((resp) => {
        setImageSrc(resp)
        setImageLoader(false)
      })
      .catch(() => {
        setImageLoader(false)
      })
  }, [sellerId, attachmentId])

  return (
    <>
      {isImageLoader ? (
        <SkeletonLoader height="300px" />
      ) : (
        <StyledImageContainer
          data-testid="image-attachment"
          id={attachmentId}
          src={imageSrc}
          alt={attachmentName}
          onClick={() => saveAttachment(sellerId, attachmentId)}
        />
      )}
    </>
  )
}

export default ImageAttachment
