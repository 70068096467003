import { ITEM_FIELDS } from 'constants/itemFields'
import filter from 'lodash/fp/filter'
import find from 'lodash/fp/find'
import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import getOr from 'lodash/fp/getOr'
import head from 'lodash/fp/head'
import map from 'lodash/fp/map'
import sortBy from 'lodash/fp/sortBy'
import some from 'lodash/fp/some'

import { Severity } from 'types/ErrorCode'
import {
  ProductError,
  ProductField,
  ProductStatus,
  SmsProduct,
  ListingStatus,
} from 'types/Item'

const GENERAL = 'General'

export interface FieldError {
  fieldName?: string
  displayName: string
  message: string
  notes?: string
  order: number
  critical: boolean
  code?: number
}

export const getLatestProductStatus: (
  product?: SmsProduct,
) => ProductStatus | undefined = flow(get('product_statuses'), head)

export const getCurrentProductStatus: (
  product?: SmsProduct,
) => ProductStatus | undefined = flow(
  get('product_statuses'),
  find<ProductStatus>('current'),
)

export const getPublishedProductStatus: (
  product?: SmsProduct,
) => ProductStatus | undefined = flow(
  get('product_statuses'),
  find<ProductStatus>(
    (status) =>
      status.current && status.listing_status === ListingStatus.APPROVED,
  ),
)

export const hasPublishedProductStatus: (product?: SmsProduct) => boolean =
  flow(
    get('product_statuses'),
    some<ProductStatus>(
      (status) =>
        status.current && status.listing_status === ListingStatus.APPROVED,
    ),
  )
export const getLatestListingErrors: (product: SmsProduct) => ProductError[] =
  flow(
    getLatestProductStatus,
    getOr([], 'errors'),
    filter((error: ProductError) => error.type !== 'ITEM'),
  )

export const getCurrentListingErrors: (product: SmsProduct) => ProductError[] =
  flow(
    getCurrentProductStatus,
    getOr([], 'errors'),
    filter((error: ProductError) => error.type !== 'ITEM'),
  )

export const getFieldErrors: (errors: ProductError[]) => FieldError[] = flow(
  map<ProductError, FieldError>((error) => {
    const fieldName = getOr(GENERAL, 'field_name', error)
    const fieldData = get(fieldName, ITEM_FIELDS)

    return {
      fieldName,
      displayName: getOr(fieldName, 'displayName', fieldData),
      message: error.reason,
      order: getOr(900, 'order', fieldData),
      critical: error.error_severity === Severity.CRITICAL,
      code: error.error_code,
    }
  }),
  sortBy<FieldError>('order'),
)

export const getDisplayName = (field: ProductField) => {
  const fieldName = get('name', field)
  return getOr(fieldName, 'displayName', get(fieldName, ITEM_FIELDS))
}

export const getListingFieldErrors: (product: SmsProduct) => FieldError[] =
  flow(getLatestListingErrors, getFieldErrors)

export const isEqualError = (
  productError: ProductError,
  fieldError: FieldError,
) => {
  const isEqual =
    productError.error_code === fieldError.code &&
    getOr(GENERAL, 'field_name', productError) === fieldError.fieldName &&
    productError.reason === fieldError.message

  return isEqual
}
export const isEqualProductError = (
  productError: ProductError,
  other: ProductError,
) => {
  return (
    productError.error_code === other.error_code &&
    productError.field_name === other.field_name
  )
}

export const getProductErrors = (fieldErrors: FieldError[]): ProductError[] => {
  return fieldErrors.map((e) => {
    return {
      field_name: getOr(GENERAL, 'fieldName', e),
      reason: `${e.message}${e.notes ? ' ' + e.notes : ''}`,
      type: 'LISTING',
      error_code: e.code,
      error_severity: e.critical ? Severity.CRITICAL : Severity.NON_CRITICAL,
    }
  })
}
