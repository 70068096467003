import React from 'react'
import styled from '@emotion/styled'

import TablePagination, {
  TablePaginationBaseProps,
} from '@mui/material/TablePagination'
import Button from '@mui/material/Button'
import TablePaginationActions from './TablePaginationActions'

export enum PAGINATION {
  BOTH = 'BOTH',
  BOTTOM = 'BOTTOM',
  NONE = 'NONE',
  TOP = 'TOP',
}

export enum PAGINATION_TYPE {
  LOAD_MORE = 'LOAD_MORE',
  PAGED = 'PAGED',
}

const StyledLoadMore = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
})

export interface StyledTablePaginationProps extends TablePaginationBaseProps {
  component?: string
}

const StyledTablePagination = styled(
  TablePagination,
)<StyledTablePaginationProps>({
  borderBottom: 'none',
  fontWeight: 300,
  '& .MuiTablePagination-displayedRows': {
    borderBottom: 'none',
    fontWeight: 300,
    marginBottom: 0,
  },
  '& .MuiTablePagination-selectLabel': {
    borderBottom: 'none',
    fontWeight: 300,
    marginBottom: 0,
  },
})

export interface PaginationProps {
  page?: number
  paginationDisabled?: boolean
  paginationDisplayLocation?: PAGINATION
  paginationType?: PAGINATION_TYPE
  onChangePage?: (
    event: React.MouseEvent<HTMLElement, MouseEvent> | null,
    page?: number,
  ) => void
  onChangeRowsPerPage?: (event: any) => void
  rowsPerPage?: number
  rowsPerPageOptions?: number[]
  total?: number
}

interface Props extends PaginationProps {
  isLoading: boolean
  location: PAGINATION.TOP | PAGINATION.BOTTOM
}

const ROW_PER_PAGE_LABEL = 'table-pagination-rows-per-page'

const rowsPerPageLabel = (
  <label
    htmlFor={ROW_PER_PAGE_LABEL}
    aria-label="rows per page"
    style={{ marginBottom: 0 }}
  >
    Rows per page:
  </label>
)

export const EnhancedTablePagination = ({
  isLoading,
  location,
  page = 0,
  paginationDisabled = false,
  paginationDisplayLocation: displayLocation = PAGINATION.BOTTOM,
  paginationType: type = PAGINATION_TYPE.PAGED,
  onChangePage,
  onChangeRowsPerPage,
  rowsPerPage = 20,
  rowsPerPageOptions = [5, 10, 20, 50, 100],
  total = 0,
}: Props) => {
  if (displayLocation === PAGINATION.NONE || !onChangePage) {
    return null
  }

  const renderPagedPaginationComponent = () => {
    if (total === 0) return null

    // hide rows per page if the onChange handler is not provided
    const rowsPerPageValues = onChangeRowsPerPage ? rowsPerPageOptions : []

    return (
      <StyledTablePagination
        data-testid="table-pagination"
        component="div"
        count={total}
        page={page}
        labelRowsPerPage={rowsPerPageLabel}
        SelectProps={
          {
            id: ROW_PER_PAGE_LABEL,
            'data-testid': 'select-rows-per-page',
          } as any // TODO: figure out a way to remove the any type cast
        }
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageValues}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    )
  }

  const renderLoadMorePaginationComponent = () => {
    if (isLoading) return null

    return (
      <StyledLoadMore>
        <Button
          disableRipple
          disableFocusRipple
          data-testid="view-more-button"
          disabled={paginationDisabled}
          color="primary"
          onClick={onChangePage}
        >
          View More
        </Button>
      </StyledLoadMore>
    )
  }

  switch (type) {
    case PAGINATION_TYPE.LOAD_MORE:
      return location === PAGINATION.BOTTOM
        ? renderLoadMorePaginationComponent()
        : null
    case PAGINATION_TYPE.PAGED:
      if (displayLocation === PAGINATION.BOTH || displayLocation === location) {
        return renderPagedPaginationComponent()
      }

      return null
  }
}

export default EnhancedTablePagination
