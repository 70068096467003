import { Direction } from 'services/pageableHelper'

export const EXTERNAL_SELLER_REPORTS = 'EXTERNAL_SELLER_REPORTS'
export const POLL_REPORT = 'POLL_REPORT'
export const PAYOUTS = 'PAYOUTS'

export const getExternalSellerReportsQueryKey = ({
  page,
  perPage,
  orderBy,
  direction,
  sellerId,
  userEmail,
}: {
  page: number
  perPage: number
  orderBy: string | undefined
  direction: Direction | undefined
  sellerId: string | undefined
  userEmail: string
}) => [
  EXTERNAL_SELLER_REPORTS,
  page,
  perPage,
  orderBy,
  direction,
  sellerId,
  userEmail,
]

export const getPollSellerReportsQueryKey = ({
  sellerId,
  reportId,
}: {
  sellerId: string | undefined
  reportId: string | undefined
}) => [POLL_REPORT, sellerId, reportId]

export const getPayoutsQueryKey = ({
  sellerId,
}: {
  sellerId: string | undefined
}) => [PAYOUTS, sellerId]
