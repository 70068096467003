import * as H from 'history'
import queryString, { ParsedQuery } from 'query-string'
import URI from 'urijs'

import {
  decrementNumber,
  Direction,
  getDirectionFromSort,
  getOrderByFromSort,
  getPageable,
  PagingParams,
} from './pageableHelper'

export function createUrl(url: string, params: object = {}): string {
  if (!url) {
    return ''
  }
  const uri = new URI(url)

  const currentParams = uri.search(true)
  const newParams = {
    ...currentParams,
    ...params,
  }

  return uri.search(newParams).normalizeSearch().toString()
}

export function getParams(
  location: Partial<Location> = { search: '', pathname: '' },
): ParsedQuery {
  const { search = '' } = location
  return queryString.parse(search)
}

export function getParam(
  location: Partial<Location> = { search: '', pathname: '' },
  key: string,
): any {
  const parsed: ParsedQuery = getParams(location)

  return parsed[key]
}

export function hasParam(
  location: Partial<Location> = { search: '', pathname: '' },
  key: string,
): boolean {
  if (!location.search) {
    return false
  }

  const gotValue = getParam(location, key)

  return typeof gotValue !== 'undefined'
}

export function getPagingParams(location: Location | H.Location): PagingParams {
  const pagingParams: PagingParams = {
    page: 0,
    perPage: 20,
    direction: Direction.DESC,
  }

  if (location.search) {
    const urlParams = getParams(location)

    if (urlParams.sort) {
      pagingParams.orderBy = getOrderByFromSort(urlParams.sort as string)
      pagingParams.direction = getDirectionFromSort(urlParams.sort as string)
    }

    if (urlParams.page) {
      pagingParams.page = decrementNumber(
        parseInt(urlParams.page as string, 10),
      )
    }

    if (urlParams.per_page) {
      pagingParams.perPage = parseInt(urlParams.per_page as string, 10)
    }
  }

  return pagingParams
}

export function createQueryString(params: Dictionary<any>): string {
  return queryString.stringify(params)
}

export function createPagingQueryString(params: PagingParams) {
  return createQueryString(getPageable(params))
}

export function updateUrl(
  history: H.History,
  location: H.Location,
  pagingParams: PagingParams,
) {
  const queryParamString = createPagingQueryString(pagingParams)
  const path = `${location.pathname}?${queryParamString}`
  history.push(path)
}

export const fullyEncodeFilename = (value: string) => {
  return value.replace(/[^0-9a-zA-Z\-._~]/g, (string) =>
    string === ' ' ? '_' : '',
  )
}

export function decodeUriSafe(input: string) {
  if (!input) {
    return
  }
  return decodeURIComponent(input.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'))
}
