import React from 'react'
import { useDispatch } from 'react-redux'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import ReportProgress from 'components/common/ReportCard/ReportProgress'

import useInterval from 'hooks/useInterval'

import { submitReport, pollReport } from 'services/reports'
import { saveReportAsFile } from 'services/reports'

import { closeDialog } from 'store/dialog/actionCreator'

import { ReportType, Report, ReportStatus } from 'types/Report'
import { OrderDefectMetricType } from 'types/VendorStats'

export type Props = {
  sellerId: string
  isOpen: boolean
  metricType: OrderDefectMetricType[]
  startDate: string
  endDate: string
  timeframeLabel: string
}

export const OrderDefectRateReportGenerate = ({
  sellerId,
  isOpen,
  metricType,
  startDate,
  endDate,
  timeframeLabel,
}: Props) => {
  const dispatch = useDispatch()
  const [report, setReport] = React.useState<Report>()

  const getDefectType = (type: OrderDefectMetricType) => {
    switch (type) {
      case OrderDefectMetricType.LATE_DELIVERY:
        return 'LATE_DELIVERIES'
      case OrderDefectMetricType.RETURN_RATE:
        return 'DEFECTIVE_RETURNS'
      case OrderDefectMetricType.CANCELLATION_RATE:
        return 'ORDER_CANCELLATIONS'
      case OrderDefectMetricType.CONTACT_RATE:
        return 'DEFECTIVE_CONTACTS'
    }
  }

  React.useEffect(() => {
    if (!report) {
      submitReport({
        type: ReportType.ORDER_DEFECTS,
        startDate: startDate,
        endDate: endDate,
        sellerId,
        parameters: {
          order_defect_types: metricType.map(getDefectType),
        },
      })
        .then((res) => {
          setReport(res)
        })
        .catch(() => {
          dispatch(closeDialog())
        })
    }
  }, [dispatch, endDate, metricType, report, sellerId, startDate])

  useInterval(
    () => {
      if (
        report?.status !== ReportStatus.PENDING &&
        report?.status !== ReportStatus.PROCESSING
      ) {
        return
      }

      pollReport(report.id, sellerId).then((res) => {
        setReport(res)
      })
    },
    report?.status === ReportStatus.PENDING ||
      report?.status === ReportStatus.PROCESSING
      ? 5000
      : null,
  )

  const title =
    report?.status === ReportStatus.COMPLETE
      ? 'SUCCESS! REPORT GENERATED'
      : 'GENERATING ODR REPORT... PLEASE WAIT'

  const onSubmit =
    report?.status === ReportStatus.COMPLETE
      ? () => {
          saveReportAsFile({
            report,
            title: metricType.join(', '),
            hasDateRange: false,
          })
          dispatch(closeDialog())
        }
      : undefined

  return (
    <DialogContainer
      title={title}
      subtitle={timeframeLabel}
      isOpen={isOpen}
      maxWidth="md"
      onSubmit={onSubmit}
      submitButtonText="Download Report"
      disableScroll
    >
      <div>
        <ReportProgress status={report?.status} />
      </div>
    </DialogContainer>
  )
}

export default OrderDefectRateReportGenerate
