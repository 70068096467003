import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import WarningIcon from 'components/common/WarningIcon'

import { pluralize } from 'services/pluralizationHelper'

import { StoreLocation } from 'types/Locations'
import { Return, ReturnTrackingData } from 'types/Orders'
import ReturnStepper from './ReturnStepper'

const ReturnTracking = ({
  returnOrder,
  returnLocation,
}: {
  returnOrder: Partial<Return>
  returnLocation?: StoreLocation
}) => {
  const defaultStats = {
    validTracking: [] as ReturnTrackingData[],
    destroyed: 0,
  }

  const isDestroyedByPhysicalDisposition =
    returnOrder.is_online === true &&
    returnOrder.physical_disposition === 'Destroy'

  const { validTracking, destroyed } =
    returnOrder?.tracking_data
      ?.filter((data) => data.inventory_removal_reason !== 'VOID')
      .reduce((c, trackingData) => {
        if (
          trackingData.store_physical_disposition === 'DEST' ||
          returnOrder.physical_disposition === 'DEST'
        ) {
          c.destroyed = c.destroyed + 1
          c.validTracking.push(trackingData)
        } else {
          c.validTracking.push(trackingData)
        }

        return c
      }, defaultStats) ?? defaultStats

  return (
    <>
      {destroyed > 0 && (
        <Grid item xs={12}>
          <WarningIcon>
            <Typography>
              {`${destroyed} ${pluralize(
                destroyed,
                'item was',
                'items were',
              )} destroyed in store based on the service agreement.`}
            </Typography>
          </WarningIcon>
        </Grid>
      )}

      {isDestroyedByPhysicalDisposition && (
        <Grid item xs={12}>
          <WarningIcon>
            <Typography>
              {`${returnOrder.quantity} ${pluralize(
                returnOrder.quantity ?? 0,
                'item',
                'item(s)',
              )} destroyed based on the return policy.`}
            </Typography>
          </WarningIcon>
        </Grid>
      )}

      {validTracking.map((tracking, index) => {
        return (
          <ReturnStepper
            key={index}
            returnOrder={returnOrder}
            returnTracking={tracking}
            returnLocation={returnLocation}
          />
        )
      })}

      {validTracking.length === 0 && (
        <ReturnStepper
          returnOrder={returnOrder}
          returnLocation={returnLocation}
        />
      )}
    </>
  )
}

export default ReturnTracking
