import Grid from '@mui/material/Grid'

import DialogContainer from 'components/common/Dialog/DialogContainer'

import Banner from 'components/common/Banner'

import { Notification } from 'types/Notifications'

export type Props = {
  isOpen: boolean
  notification: Notification
}

export const PreviewNotification: React.FC<Props> = ({
  notification,
  isOpen,
}) => {
  return (
    <DialogContainer
      isOpen={isOpen}
      title="Preview Banner Message"
      maxWidth="lg"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Banner disabled notifications={[notification]} />
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export default PreviewNotification
