import React from 'react'
import styled from '@emotion/styled'

import Table from '@mui/material/Table'

import EnhancedTableBody from './EnhancedTableBody'
import { TableActionItem } from './EnhancedTableActionButtonCell'
import EnhancedTableHead from './EnhancedTableHead'
import TitleBar from '../TitleBar'

import { Direction } from 'services/pageableHelper'
import EnhancedTablePagination, {
  PaginationProps,
  PAGINATION,
} from './EnhancedTablePagination'

export type VerticalAlign = 'baseline' | 'top' | 'middle' | 'bottom'
export interface EnhancedTableFieldType<T = {}> extends Object {
  key: string
  formatHeader?: (rowItem: T, context?: any) => React.ReactNode
  heading?: string
  subHeading?: string
  hasSort?: boolean
  tooltip?: string
  formatCell?: (rowItem: T, context?: any) => React.ReactNode
  formatCellAsync?: (rowItem: T, context?: any) => Promise<React.ReactNode>
  minWidth?: number
  width?: number
  cellPadding?: string
}

export interface Props extends PaginationProps {
  actions?: TableActionItem[]
  data: object[]
  fieldList: EnhancedTableFieldType<any>[]
  fieldListContext?: any
  isLoading: boolean
  nullText?: string
  onRequestSort?: (event: any) => void
  order?: Direction
  orderBy?: string
  padding?: 'checkbox' | 'none' | 'normal' | undefined
  title?: string
  verticalAlign?: VerticalAlign
  tableLayoutFixed?: boolean
  'data-testid'?: string
  hidePagination?: boolean
  hover?: boolean
  stripedRows?: boolean
  isUpdatedTable?: boolean
  highlightRow?: number
}

const StyledContainer = styled('div')({
  overflowX: 'auto',
})

const StyledTable = styled(Table, {
  shouldForwardProp: (prop) => prop !== 'tableLayoutFixed',
})<{ tableLayoutFixed?: boolean; isUpdatedTable?: boolean }>((props) => ({
  tableLayout: props.tableLayoutFixed ? 'fixed' : undefined,
  border: props.isUpdatedTable ? '1px solid #D3D5D8' : undefined,
}))

export const EnhancedTable = ({
  actions,
  data,
  fieldList,
  fieldListContext,
  isLoading,
  nullText,
  onRequestSort,
  order,
  orderBy,
  padding = 'normal',
  title,
  verticalAlign,
  tableLayoutFixed,
  hidePagination,
  'data-testid': testId = 'enhanced-table',
  hover,
  stripedRows,
  highlightRow,
  isUpdatedTable,
  ...paginationProps
}: Props) => {
  if (!data) return null

  const filteredActions =
    actions?.filter((action) => action.callback) ?? undefined
  const displayActionColumn =
    filteredActions !== undefined && filteredActions.length > 0

  return (
    <>
      {title && <TitleBar hideDivider title={title} />}
      <StyledContainer data-testid={testId}>
        <StyledTable
          tableLayoutFixed={tableLayoutFixed}
          isUpdatedTable={isUpdatedTable}
          padding={padding}
        >
          <EnhancedTableHead
            direction={order}
            orderBy={orderBy}
            fieldList={fieldList}
            onClick={onRequestSort}
            isUpdatedTable={isUpdatedTable}
            paginationComponent={
              <EnhancedTablePagination
                isLoading={isLoading}
                location={PAGINATION.TOP}
                {...paginationProps}
              />
            }
            displayActionColumn={displayActionColumn}
          />

          <EnhancedTableBody
            data={data}
            displayActionColumn={displayActionColumn}
            actions={filteredActions}
            fieldList={fieldList}
            fieldListContext={fieldListContext}
            isLoading={isLoading}
            nullText={nullText}
            verticalAlign={verticalAlign}
            hover={hover}
            stripedRows={stripedRows}
            highlightRow={highlightRow}
          />
        </StyledTable>
      </StyledContainer>
      {!hidePagination && (
        <EnhancedTablePagination
          isLoading={isLoading}
          location={PAGINATION.BOTTOM}
          {...paginationProps}
        />
      )}
    </>
  )
}

export default EnhancedTable
