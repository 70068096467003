import React from 'react'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import DomesticDialogWrapper from './DomesticDialogWrapper'

import { DiversityAnswer } from 'types/PartnerDiversity'

type Props = {
  isOpen: boolean
  answers: DiversityAnswer[]
}

export const DomesticDiversitySurvey: React.FC<Props> = ({
  isOpen,
  answers,
}) => {
  return (
    <DialogContainer
      isOpen={isOpen}
      title="Diversity Information"
      subtitle="This will help Target recognize diverse-owned businesses
      (enterprises that are at least 51% owned, controlled & operated by a
      diverse entity)."
      aria-labelledby="diversity-information"
      maxWidth="xl"
      hideActions
    >
      <div data-testid="partner-diversity-survey">
        <DomesticDialogWrapper answers={answers} />
      </div>
    </DialogContainer>
  )
}

export default DomesticDiversitySurvey
