import {
  DiversityAnswer,
  DiversitySurveyPageOne,
  DiversitySurveyPageTwo,
  DiversitySurveyPageThree,
} from 'types/PartnerDiversity'

export const fetchPartnerDiversityAnswers = (sellerId: string) =>
  ({
    type: 'FETCH_PARTNER_DIVERSITY_ANSWERS',
    payload: {
      sellerId,
    },
  }) as const

export const fetchPartnerDiversityAnswersDone = (
  diversityAnswers: DiversityAnswer[],
) =>
  ({
    type: 'FETCH_PARTNER_DIVERSITY_ANSWERS_DONE',
    payload: {
      diversityAnswers,
    },
  }) as const

export const fetchPartnerDiversityAnswersError = () =>
  ({
    type: 'FETCH_PARTNER_DIVERSITY_ANSWERS_ERROR',
  }) as const

export const editPartnerDiversityAnswers = ({
  sellerId,
  answers,
}: {
  sellerId: string
  answers: Partial<
    DiversitySurveyPageOne & DiversitySurveyPageTwo & DiversitySurveyPageThree
  >
}) =>
  ({
    type: 'EDIT_PARTNER_DIVERSITY_ANSWERS',
    payload: {
      sellerId,
      answers,
    },
  }) as const

export const editPartnerDiversityAnswersDone = (
  diversityAnswers: DiversityAnswer[],
) =>
  ({
    type: 'EDIT_PARTNER_DIVERSITY_ANSWERS_DONE',
    payload: {
      diversityAnswers,
    },
  }) as const

export const editPartnerDiversityAnswersError = () =>
  ({
    type: 'EDIT_PARTNER_DIVERSITY_ANSWERS_ERROR',
  }) as const

export const clearPartnerDiversityAnswers = () =>
  ({
    type: 'CLEAR_PARTNER_DIVERSITY_ANSWERS',
  }) as const

export type Actions = ReturnType<
  | typeof fetchPartnerDiversityAnswers
  | typeof fetchPartnerDiversityAnswersDone
  | typeof fetchPartnerDiversityAnswersError
  | typeof editPartnerDiversityAnswers
  | typeof editPartnerDiversityAnswersDone
  | typeof editPartnerDiversityAnswersError
  | typeof clearPartnerDiversityAnswers
>
