import intersectionWith from 'lodash/fp/intersectionWith'
import differenceWith from 'lodash/fp/differenceWith'
import isEqual from 'lodash/fp/isEqual'
import keyBy from 'lodash/fp/keyBy'
import flow from 'lodash/fp/flow'
import keys from 'lodash/fp/keys'
import uniq from 'lodash/fp/uniq'
import getOr from 'lodash/fp/getOr'
import sortBy from 'lodash/fp/sortBy'
import map from 'lodash/fp/map'

import Typography from '@mui/material/Typography'

import TabularData, { FieldList } from 'components/common/TabularData'

import { ProductField } from 'types/Item'
import { ITEM_FIELDS } from 'constants/itemFields'

export interface Props {
  latestVersion?: ProductField[]
  previousVersion?: ProductField[]
}

interface FieldDiff {
  field: string
  previous: string
  latest: string
  order: number
}

type FieldVersion = Record<string, ProductField>

const getUnchangedFields: (
  previous: ProductField[],
  latest: ProductField[],
) => ProductField[] = intersectionWith(isEqual)

const getDifferentFields: (
  fields: ProductField[],
  unchangedFields: ProductField[],
) => FieldVersion = flow(differenceWith(isEqual), keyBy<ProductField>('name'))

const getChangedFieldNames: (
  previous: FieldVersion,
  latest: FieldVersion,
) => string[] = flow(
  (previous, latest) => keys(previous).concat(keys(latest)),
  uniq,
)

const fieldList: FieldList[] = [
  {
    key: 'field',
    displayName: 'Field Name',
    width: 20,
  },
  {
    key: 'previous',
    displayName: 'Previous',
    width: 40,
  },
  {
    key: 'latest',
    displayName: 'Latest',
    width: 40,
  },
]

const DataChanges = ({ latestVersion, previousVersion }: Props) => {
  if (!latestVersion || !previousVersion) {
    return <Typography>No previous version.</Typography>
  }

  const unchangedFields = getUnchangedFields(previousVersion, latestVersion)

  const previousFields = getDifferentFields(previousVersion, unchangedFields)
  const latestFields = getDifferentFields(latestVersion, unchangedFields)

  const data = flow(
    () => getChangedFieldNames(previousFields, latestFields),
    map<string, FieldDiff>((fieldName) => {
      const fieldData = ITEM_FIELDS[fieldName]

      return {
        field: getOr(fieldName, 'displayName', fieldData),
        previous: getOr('-', [fieldName, 'value'], previousFields),
        latest: getOr('-', [fieldName, 'value'], latestFields),
        order: getOr(900, 'order', fieldData),
      }
    }),
    sortBy<FieldDiff>('order'),
  )()

  return <TabularData data={data} fieldList={fieldList} />
}

export default DataChanges
