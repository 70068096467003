import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import Grid from '@mui/material/Grid'

import ContentSpacer from 'components/common/ContentSpacer'
import DialogContainer from 'components/common/Dialog/DialogContainer'
import Select from 'components/common/Select'

import { updateSellerSource } from 'services/seller'

import { SellerSource, SellerSourceStatus } from 'types/Seller'

import { closeDialog } from 'store/dialog/actionCreator'

import { SOURCES } from '../queries'

export interface Props {
  isOpen: boolean
  source: SellerSource
}

type MutationVariables = {
  source: SellerSource
  status: SellerSourceStatus
  multiShipNode: boolean
}

export const EditIntegrationDialog = ({ isOpen, source }: Props) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const [status, setStatus] = useState<SellerSourceStatus>(source.status)
  const [multiShipNode, setMultiShipNode] = useState(source.multi_ship_node)

  const { mutate, isLoading } = useMutation<
    SellerSource,
    any,
    MutationVariables
  >(({ source, status, multiShipNode }) =>
    updateSellerSource({ ...source, status, multi_ship_node: multiShipNode }),
  )

  const handleStatusChange = (status: SellerSourceStatus) => setStatus(status)
  const handleMultiShipNodeChange = (multiShipNodeStatus: boolean) =>
    setMultiShipNode(multiShipNodeStatus)

  const handleSubmit = () => {
    mutate(
      {
        source,
        status,
        multiShipNode,
      },
      {
        onSuccess: () => {
          // Passing only the query key invalidates all queries with the same key
          // Can take an array and will invalidate only queries that match each element in the array.
          // Ex: queryClient.invalidateQueries(['sources', page, perPage])
          queryClient.invalidateQueries([SOURCES])
          dispatch(closeDialog())
        },
      },
    )
  }

  return (
    <DialogContainer
      data-testid="edit-integration-status-dialog"
      isOpen={isOpen}
      title={`Edit ${source.description} Status`}
      isPending={isLoading}
      onSubmit={handleSubmit}
      isSubmitDisabled={
        status === source.status && multiShipNode === source.multi_ship_node
      }
      subtitle="If an integration is set to inactive, partners will NOT be able to use them to send data."
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Select
            isRequired
            id="source-status"
            name="sourceStatus"
            label="Status"
            isDisabled={isLoading}
            onChange={handleStatusChange}
            options={[
              {
                label: 'Active',
                value: 'ACTIVE',
              },
              {
                label: 'Inactive',
                value: 'INACTIVE',
              },
            ]}
            value={status}
            keyName="label"
            valueName="value"
          />
          <ContentSpacer />
          <Select
            isRequired
            id="multi-ship-node-status"
            name="multiShipNodeStatus"
            label="Ability to have multiple ship nodes/distribution centers"
            isDisabled={isLoading}
            onChange={handleMultiShipNodeChange}
            options={[
              {
                label: 'Enabled',
                value: true,
              },
              {
                label: 'Disabled',
                value: false,
              },
            ]}
            value={multiShipNode}
            keyName="label"
            valueName="value"
          />
        </Grid>
      </Grid>
    </DialogContainer>
  )
}

export default EditIntegrationDialog
