import { useState, ChangeEvent } from 'react'
import Typography from '@mui/material/Typography'
import DialogContainer from 'components/common/Dialog/DialogContainer'
import EnhancedTable from 'components/common/EnhancedTable'
import styled from '@emotion/styled'
import Attribute from 'types/Attribute'
import { FieldList } from 'components/common/TabularData'
import useTable from 'components/common/EnhancedTable/useTable'
import { useQuery } from '@tanstack/react-query'
import {
  getAttributeValues,
  getFilteredAttributeValues,
  getTaxonomyAssignmentByItemTypeIdAndAttributeId,
} from 'services/itemTaxonomies'
import { Box, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { AttributeValue } from 'types/AttributeValue'
import { ATTRIBUTES_AND_VALUES } from '../queries'

const StyledTitle = styled('span')({
  fontWeight: 'bold',
})

const fieldList: FieldList[] = [
  {
    key: 'name',
    displayName: 'Value Name',
  },
  {
    key: 'id',
    displayName: 'Value ID',
  },
]

type Props = {
  isOpen: boolean
  itemType?: Nullable<Attribute>
  attribute?: Nullable<Attribute>
}

const defaultPagingParams = {
  page: 0,
  perPage: 10,
}

const AttributeValues = ({ isOpen, itemType, attribute }: Props) => {
  const [searchVal, setSearchVal] = useState<string>('')

  const { table } = useTable(defaultPagingParams)

  const { data: results, isLoading: isLoadingAllAttributes } = useQuery(
    [ATTRIBUTES_AND_VALUES, table.state, itemType, attribute, searchVal],
    () => {
      if (itemType && attribute && !searchVal) {
        return getAttributeValues(table.state, itemType?.id, attribute?.id)
      }
      return null
    },
  )

  const { data: filteredResults, isLoading: isLoadingFilteredAttributes } =
    useQuery(
      [
        'FILTERED_ATTRIBUTE_AND_VALUES',
        table.state,
        itemType,
        attribute,
        searchVal,
      ],
      () => {
        if (searchVal) {
          return getFilteredAttributeValues(table.state, {
            itemTypeId: itemType?.id,
            attributeId: attribute?.id,
            searchVal: searchVal,
          })
        }
        return null
      },
    )

  const { data: assignments } = useQuery(
    ['GET_ASSIGNMENT', itemType?.id, attribute?.id],
    () => {
      if (itemType && attribute) {
        return getTaxonomyAssignmentByItemTypeIdAndAttributeId(
          itemType.id,
          attribute.id,
        )
      }
      return null
    },
  )

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchVal(e.target.value)
    table.actions.resetState(defaultPagingParams)
  }

  let tableData: AttributeValue[] = []
  if (filteredResults?.data.length) {
    tableData = filteredResults.data
  } else if (results?.data.length) {
    tableData = results.data
  }

  let tableTotal = 0
  if (filteredResults?.total) {
    tableTotal = filteredResults.total
  } else if (results?.total) {
    tableTotal = results.total
  }

  const dialogTitle = tableTotal
    ? `${tableTotal.toLocaleString()} Values Available`
    : 'Values Available'

  return (
    <>
      <DialogContainer
        title={dialogTitle}
        isOpen={isOpen}
        closeButtonText="close"
      >
        <Typography>
          <StyledTitle>Item Type</StyledTitle>: {itemType?.name}
        </Typography>
        <Typography>
          <StyledTitle>Attribute</StyledTitle>: {attribute?.name}
        </Typography>
        <Typography>
          <StyledTitle>Required Attribute</StyledTitle>:{' '}
          {assignments?.[0]?.required ? 'Yes' : 'No'}
        </Typography>
        <Typography>
          <StyledTitle>New Values Can be Requested</StyledTitle>:{' '}
          {assignments?.[0].allow_value_requests ? 'Yes' : 'No'}
        </Typography>

        <Box sx={{ mt: 4, width: '100%' }}>
          <TextField
            name="search"
            label="Lookup values"
            placeholder="Search by name"
            variant="standard"
            fullWidth
            onChange={handleOnChange}
            InputProps={{
              startAdornment: <SearchIcon color="primary" />,
            }}
            data-testid="search-field"
          />
        </Box>

        <Box sx={{ mt: 4 }}>
          <EnhancedTable
            data={tableData}
            fieldList={fieldList}
            isLoading={isLoadingAllAttributes || isLoadingFilteredAttributes}
            total={tableTotal}
            page={table?.state.page}
            rowsPerPage={table?.state.perPage}
            paginationDisabled={false}
            onChangePage={table?.actions.changePage}
            onChangeRowsPerPage={table?.actions.changePerPage}
          />
        </Box>
      </DialogContainer>
    </>
  )
}

export default AttributeValues
