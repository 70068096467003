import axios from 'axios'

import apiConfig from 'config/apiConfig'

import {
  DATE_FORMAT_YEAR,
  formatDate,
  DATE_FORMAT_MONTH_DAY,
} from 'services/dateService'
import { PagingParams, getPageable } from './pageableHelper'

import { primary } from 'config/themeConfig'

import { ChartSeasonalEvent, SeasonalEvent } from 'types/SeasonalEvent'
import { CollectionResponse } from 'types/Response'

type SeasonalEventParams = {
  effectiveDate?: string
}

export const getSeasonalEvents = async (
  pagingParams: PagingParams,
  searchParams: SeasonalEventParams,
): Promise<CollectionResponse<SeasonalEvent>> => {
  const pageable = pagingParams ? getPageable(pagingParams) : {}

  const config = {
    params: {
      ...pageable,
      effective_date: searchParams.effectiveDate,
    },
  }

  const res = await axios.get(`${apiConfig.sellerPromotions}/events`, config)

  return { data: res.data, total: parseInt(res.headers['x-total-count'], 10) }
}

export const getSeasonalEventsForGanttChart = (
  SeasonalEventData: SeasonalEvent[],
): ChartSeasonalEvent[] => {
  const chartData = SeasonalEventData.map((item: SeasonalEvent) => {
    // format labels for amcharts
    // see: https://www.amcharts.com/docs/v4/concepts/formatters/formatting-strings/#List_of_codes
    const title = `[bold ${primary.main}]${item.name}[/]\n[gray]${formatDate(
      item.start_date,
      DATE_FORMAT_MONTH_DAY,
    )} - ${formatDate(item.end_date, DATE_FORMAT_MONTH_DAY)} (${formatDate(
      item.start_date,
      DATE_FORMAT_YEAR,
    )})[/]`

    return {
      ...item,
      title,
    }
  })

  return chartData
}

export const updateSeasonalEvent = async (seasonalEvent: SeasonalEvent) => {
  const results = seasonalEvent.id
    ? await axios.put(
        `${apiConfig.sellerPromotions}/events/${seasonalEvent.id}`,
        seasonalEvent,
      )
    : await axios.post(`${apiConfig.sellerPromotions}/events`, seasonalEvent)

  return results.data
}

export const deleteSeasonalEvent = async (seasonalEvent: SeasonalEvent) => {
  return axios.delete(
    `${apiConfig.sellerPromotions}/events/${seasonalEvent.id}`,
  )
}
