import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'

import DisplayCard from 'components/common/DisplayCard'
import Link from 'components/common/Link'
import TabularData from 'components/common/TabularData'

import { getLastModifiedFromDaysPending } from 'components/ReturnDisputeCasesPage/useReturnDisputesQuery'

import { fetchReturnDisputes } from 'services/returnDisputes'
import { RoutePath } from 'services/NavigationHelper'
import { hasReturnDisputeCasesPage } from 'services/pageAccess'

import { getMemberOf } from 'store/selectors'

import { JudgementValues, ReturnDisputeDaysPending } from 'types/disputes'

const fieldList = [
  {
    key: 'status',
    displayName: 'Status',
  },
  {
    key: 'total',
    displayName: 'Total',
  },
  {
    key: 'pastDue',
    displayName: 'Past Due',
  },
]

export const ReturnDisputesCard = () => {
  const memberOf = useSelector(getMemberOf)

  const [loading, setLoading] = useState(true)
  const [needsReviewTotal, setNeedsReviewTotal] = useState<Number>()
  const [needsReviewPastDue, setNeedsReviewPastDue] = useState<Number>()
  const [waitingOnPartnerTotal, setWaitingOnPartnerTotal] = useState<Number>()
  const [waitingOnPartnerPastDue, setWaitingOnPartnerPastDue] =
    useState<Number>()

  useEffect(() => {
    let mounted = true

    const pagingParams = {
      page: 0,
      perPage: 1,
    }

    Promise.all([
      fetchReturnDisputes(
        { judgement: JudgementValues.NEEDS_REVIEW, source: 'RETURN_DISPUTE' },
        pagingParams,
      ),
      fetchReturnDisputes(
        {
          judgement: JudgementValues.NEEDS_REVIEW,
          last_modified: getLastModifiedFromDaysPending(
            ReturnDisputeDaysPending.OVER_FIVE,
          ),
          source: 'RETURN_DISPUTE',
        },
        pagingParams,
      ),
      fetchReturnDisputes(
        {
          judgement: JudgementValues.WAITING_ON_PARTNER,
          source: 'RETURN_DISPUTE',
        },
        pagingParams,
      ),
      fetchReturnDisputes(
        {
          judgement: JudgementValues.WAITING_ON_PARTNER,
          last_modified: getLastModifiedFromDaysPending(
            ReturnDisputeDaysPending.OVER_SEVEN,
          ),
          source: 'RETURN_DISPUTE',
        },
        pagingParams,
      ),
    ]).then(
      ([
        needsReviewTotalResponse,
        needsReviewPastDueResponse,
        waitingOnPartnerTotalResponse,
        waitingOnPartnerPastDueResponse,
      ]) => {
        if (mounted) {
          setNeedsReviewTotal(needsReviewTotalResponse.total)
          setNeedsReviewPastDue(needsReviewPastDueResponse.total)
          setWaitingOnPartnerTotal(waitingOnPartnerTotalResponse.total)
          setWaitingOnPartnerPastDue(waitingOnPartnerPastDueResponse.total)

          setLoading(false)
        }
      },
    )

    return () => {
      mounted = false
    }
  }, [])

  const data = [
    {
      status: 'Needs review',
      total: hasReturnDisputeCasesPage(memberOf) ? (
        <Link
          data-testid="needsReviewTotalLink"
          to={RoutePath.INTERNAL_RETURN_DISPUTE_CASES}
        >
          {needsReviewTotal?.toString()}
        </Link>
      ) : (
        <div data-testid="needsReviewTotalCount">
          {needsReviewTotal?.toString()}
        </div>
      ),
      pastDue: (
        <div data-testid="needsReviewPastDueCount">
          {needsReviewPastDue?.toString()}
        </div>
      ),
    },
    {
      status: 'Waiting on partner',
      total: (
        <div data-testid="waitingOnPartnerTotalCount">
          <>{waitingOnPartnerTotal}</>
        </div>
      ),
      pastDue: (
        <div data-testid="waitingOnPartnerPastDueCount">
          <>{waitingOnPartnerPastDue}</>
        </div>
      ),
    },
  ]

  return (
    <DisplayCard
      title="Return Disputes"
      isLoading={loading}
      iconColor="target"
      icon={BusinessCenterIcon}
    >
      <TabularData
        borderTop
        extraPadding
        hasHeader
        fieldList={fieldList}
        data={data}
      />
    </DisplayCard>
  )
}

export default ReturnDisputesCard
