import { useState, useMemo, useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { Grid, Box, Button, Divider, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import { useUserSettingsContext } from 'components/context/UserSettingsProvider'
import OutlinedInput from '../Input/OutlinedInput'

import { useUpdateSavedSearchMutation } from 'hooks/useUserSettingsQuery'
import { closeDialog, openDialog } from 'store/dialog/actionCreator'

import { removeNonASCII } from 'services/sanatizeStringHelper'
import {
  sortBySavedSearchName,
  isDuplicateSavedSearchName,
} from 'services/savedSearchHelper'

import { SavedSearch } from 'types/SavedSearch'
import { DialogEnum } from '.'
import theme from 'config/themeConfig'

interface Props {
  isOpen: boolean
  handleClose: () => void
  page: string
  message?: string
}

export const ManageSavedSearchDialog = ({ isOpen, page, message }: Props) => {
  const dispatch = useDispatch()
  const { updateSavedSearches } = useUpdateSavedSearchMutation()
  const { userSettings, updateSelectedSavedSearch, selectedSavedSearches } =
    useUserSettingsContext()
  const [editedSavedSearch, setEditedSavedSearch] = useState<
    SavedSearch | undefined
  >(undefined)
  const [editField, setEditField] = useState('')
  const [successMessage, setSuccessMessage] = useState(message)

  const currentPageUserSettings = useMemo(
    () => userSettings.data?.settings?.[`${page}_saved_searches`] || {},
    [userSettings, page],
  )
  const existingFilterNames = useMemo(
    () =>
      Object.keys(currentPageUserSettings).map(
        (key: string) => currentPageUserSettings[key].saved_search_name[0],
      ),
    [currentPageUserSettings],
  )

  const savedSearches: SavedSearch[] = Object.values(currentPageUserSettings)
  const sortedSavedSearches = sortBySavedSearchName(savedSearches)
  const encodedFilterName = editedSavedSearch
    ? window.btoa(editField).trim()
    : ''
  const isNewNameDuplicate = isDuplicateSavedSearchName(
    existingFilterNames,
    editField.trim(),
  )

  useEffect(() => {
    setTimeout(() => {
      setSuccessMessage('')
    }, 5000)
  }, [successMessage])

  const handleEdit = (savedSearch: SavedSearch) => {
    setEditedSavedSearch(savedSearch)
    setEditField(window.atob(savedSearch.saved_search_name[0]))
  }

  const handleNameChange = (value: string) => {
    const sanatizedStr = removeNonASCII(value)
    setEditField(sanatizedStr)
  }

  const findSavedSearchByName = (name: string) => {
    let savedSearchKey = ''
    for (const key in currentPageUserSettings) {
      if (currentPageUserSettings[key].saved_search_name[0] === name) {
        savedSearchKey = key
      }
    }
    return savedSearchKey
  }

  const handleSave = (savedSearch: SavedSearch) => {
    const savedSearchKey = findSavedSearchByName(
      savedSearch.saved_search_name[0],
    )

    if (
      savedSearch.saved_search_name[0] ===
      selectedSavedSearches[page]?.saved_search_name
    ) {
      updateSelectedSavedSearch(
        {
          ...currentPageUserSettings[savedSearchKey],
          saved_search_name: encodedFilterName,
        },
        page,
      )
    }

    const updatedSettings = {
      ...userSettings.data,
      settings: {
        [`${page}_saved_searches`]: {
          ...(currentPageUserSettings || {}),
          [savedSearchKey]: {
            ...currentPageUserSettings[savedSearchKey],
            saved_search_name: [encodedFilterName],
          },
        },
      },
    }

    setEditedSavedSearch(undefined)
    setEditField('')
    updateSavedSearches({ userSettings: updatedSettings })
  }

  const handleDelete = (savedSearch: SavedSearch) => {
    const savedSearchName = savedSearch.saved_search_name[0]
    const savedSearchKey = findSavedSearchByName(
      savedSearch.saved_search_name[0],
    )
    dispatch(
      openDialog({
        dialogEnum: DialogEnum.DELETE_SAVED_SEARCH_DIALOG,
        componentProps: {
          page,
          savedSearchKey,
          savedSearchName: window.atob(savedSearchName),
        },
      }),
    )
  }

  const isSaveBtnDisabled =
    !editField ||
    editField.trim() ===
      window.atob(editedSavedSearch?.saved_search_name[0] ?? '')
  return (
    <DialogContainer
      title="Manage Saved Filters"
      subtitle="Edit your saved filter titles and/or delete the saved filters."
      isOpen={isOpen}
      onSubmit={() => dispatch(closeDialog())}
      submitButtonText="Done"
      showCancelButton={false}
    >
      <div style={{ height: '8px', marginBottom: '16px' }}>
        <Typography color={theme.palette.success.main}>
          {successMessage}
        </Typography>
      </div>
      <Divider sx={{ marginBottom: 1, marginTop: 1 }} />
      <Box>
        {sortedSavedSearches.map((savedSearch: SavedSearch, index: number) => {
          return (
            <div key={index}>
              <Grid container>
                {editedSavedSearch?.saved_search_name?.length &&
                editedSavedSearch?.saved_search_name[0] ===
                  savedSearch.saved_search_name[0] ? (
                  <>
                    <Grid item xs={10}>
                      <OutlinedInput
                        id="saved-search-name"
                        name="saved-search-name"
                        maxCharacters={50}
                        value={editField}
                        onChange={handleNameChange}
                        autoFocus
                        inputProps={{
                          maxLength: 50,
                          'data-testid': 'saved-search-input',
                        }}
                        helperText={
                          isNewNameDuplicate && !isSaveBtnDisabled
                            ? `"${editField.trim()}" already exists. Create a new name for the filters.`
                            : ''
                        }
                        isError={isNewNameDuplicate && !isSaveBtnDisabled}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        disabled={isSaveBtnDisabled || isNewNameDuplicate}
                        variant="contained"
                        sx={{ mb: 3, ml: 2 }}
                        onClick={() => handleSave(savedSearch)}
                        data-testid="save-btn"
                      >
                        SAVE
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid container>
                      <Grid
                        item
                        xs={6}
                        alignSelf={'center'}
                        style={{ overflowWrap: 'break-word' }}
                      >
                        {window.atob(savedSearch.saved_search_name[0])}
                      </Grid>
                      <Grid item xs={3}>
                        <Button
                          sx={{ ml: 2 }}
                          onClick={() => handleEdit(savedSearch)}
                          startIcon={<EditIcon />}
                          data-testid="edit-btn"
                        >
                          EDIT
                        </Button>
                      </Grid>
                      <Grid item xs={3}>
                        <Button
                          onClick={() => handleDelete(savedSearch)}
                          startIcon={<DeleteIcon />}
                          data-testid="delete-btn"
                        >
                          DELETE
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
              <Divider sx={{ marginBottom: 1, marginTop: 1 }} />
            </div>
          )
        })}
      </Box>
    </DialogContainer>
  )
}

export default ManageSavedSearchDialog
