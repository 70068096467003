import React from 'react'

import apiConfig, {
  ENVIRONMENT_DEVELOPMENT,
  ENVIRONMENT_STAGE,
} from 'config/apiConfig'

import { USER_ROLE_ADMIN } from 'services/roles'

import { getMemberOf } from 'store/selectors'
import { isOneOfUserRoles } from 'services/authorization'
import { useSelector } from 'react-redux'

interface Conditions {
  environments: string[]
  roles: string[]
}

interface Props {
  children: React.ReactElement
  conditions?: Conditions[]
}

export const FeatureWrapper = ({
  children,
  conditions = [
    {
      environments: [ENVIRONMENT_DEVELOPMENT, ENVIRONMENT_STAGE],
      roles: [USER_ROLE_ADMIN],
    },
  ],
}: Props) => {
  const memberOf = useSelector(getMemberOf)
  const currentEnvironment = apiConfig.environment

  let returnValue = null

  conditions.forEach((condition) => {
    if (
      condition.environments.some((env) => currentEnvironment.includes(env))
    ) {
      if (isOneOfUserRoles(memberOf, condition.roles)) {
        returnValue = children
      }
    }
  })

  return returnValue
}

export default FeatureWrapper
