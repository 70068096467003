import { useState, useEffect } from 'react'

import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'

import { useUpdateFavoriteReportsMutation } from 'hooks/useUserSettingsQuery'

import { SellerReportTableRow } from 'types/Report'
import { UserSettings } from 'types/UserSettings'

export type Props = {
  row: SellerReportTableRow
  userSettings?: UserSettings
}

const ADD_TO_FAVORITES = 'Add to favorites'
const REMOVE_FAVORITE = 'Remove favorite'
const FAVORITE_ADDED = 'Favorite added'

export const FavoriteReportButton = ({ row, userSettings }: Props) => {
  const isFavorite = !!userSettings?.reports?.favorites?.includes(row.type)

  const [open, setOpen] = useState(false)
  const [text, setText] = useState(
    isFavorite ? REMOVE_FAVORITE : ADD_TO_FAVORITES,
  )

  const { updateFavoriteReports, isLoading, status, reset } =
    useUpdateFavoriteReportsMutation()

  useEffect(() => {
    let mounted = true
    let timer: NodeJS.Timeout

    if (mounted && isFavorite && status === 'success') {
      setText((prev) =>
        prev === ADD_TO_FAVORITES ? FAVORITE_ADDED : REMOVE_FAVORITE,
      )
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
        reset()
      }, 3000)
    }

    return () => {
      clearTimeout(timer)

      mounted = false
    }
  }, [isFavorite, status, reset])

  const handleOpen = () => {
    if (!isFavorite) {
      setText(ADD_TO_FAVORITES)
    } else if (isFavorite && status === 'idle') {
      setText(REMOVE_FAVORITE)
    }
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleClick = () => {
    if (userSettings) {
      updateFavoriteReports({ reportType: row.type, userSettings })
    }
  }

  return (
    <Tooltip
      title={text}
      placement="top"
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      disableHoverListener={isFavorite && status === 'success'}
    >
      <IconButton
        data-testid={`favorite-${row.type}`}
        disabled={isLoading}
        onClick={handleClick}
        color="primary"
        sx={{
          '& .MuiButtonBase-root:hover': {
            bgcolor: 'primary',
          },
        }}
      >
        {isFavorite ? (
          <FavoriteIcon color="primary" />
        ) : (
          <FavoriteBorderIcon color="primary" />
        )}
      </IconButton>
    </Tooltip>
  )
}

export default FavoriteReportButton
