import { isOneOfUserRoles } from 'services/authorization'
import {
  USER_ROLE_ADMIN,
  USER_ROLE_ITEM_APPROVER,
  USER_ROLE_ITEM_MANAGER,
  USER_ROLE_OPS,
  USER_ROLE_PROGRAM_MANAGER,
  USER_ROLE_REPORTER,
  USER_ROLE_FINANCE,
  USER_ROLE_APP_SMS_ADMIN,
} from 'services/roles'

import { ReportType } from 'types/Report'

export enum SellerReportsTab {
  FAVORITES = 'FAVORITES',
  FINANCE = 'FINANCE',
  ORDERS = 'ORDERS',
  RETURNS = 'RETURNS',
  ITEM_DATA = 'ITEM_DATA',
  PERFORMANCE = 'PERFORMANCE',
  ALL_REPORTS = 'ALL_REPORTS',
}

type LimitToRolesType = {
  [key in ReportType]?: string[]
}

export const hasReport = (memberOf: string[], type: ReportType) => {
  const limitToRoles: LimitToRolesType = {
    [ReportType.PAYOUT_RECONCILIATION]: [
      USER_ROLE_ADMIN,
      USER_ROLE_OPS,
      USER_ROLE_APP_SMS_ADMIN,
      USER_ROLE_FINANCE,
      USER_ROLE_PROGRAM_MANAGER,
    ],
    [ReportType.SALES_METRICS]: [
      USER_ROLE_ADMIN,
      USER_ROLE_OPS,
      USER_ROLE_APP_SMS_ADMIN,
      USER_ROLE_FINANCE,
      USER_ROLE_PROGRAM_MANAGER,
      USER_ROLE_REPORTER,
      USER_ROLE_ITEM_APPROVER,
      USER_ROLE_ITEM_MANAGER,
    ],
    [ReportType.PARTNER_REVIEWS]: [USER_ROLE_ADMIN],
  }

  const roles = limitToRoles[type]

  return roles ? isOneOfUserRoles(memberOf, roles) : true
}

type LimitTabToReportsType = { [key in SellerReportsTab]?: boolean }

export const hasReportsTab = (memberOf: string[], tab: SellerReportsTab) => {
  const limitTabToReports: LimitTabToReportsType = {
    [SellerReportsTab.FINANCE]:
      hasReport(memberOf, ReportType.PAYOUT_RECONCILIATION) ||
      hasReport(memberOf, ReportType.SALES_METRICS),
    [SellerReportsTab.PERFORMANCE]:
      hasReport(memberOf, ReportType.INVENTORY) ||
      hasReport(memberOf, ReportType.ASSORTMENT_ANALYSIS),
  }

  return limitTabToReports[tab] ?? true
}
