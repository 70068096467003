import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import keyBy from 'lodash/fp/keyBy'

import styled from '@emotion/styled'
import { backgroundGrey } from 'config/themeConfig'

import { Grid } from '@mui/material'

import DataList from 'components/common/DataList'
import Image from 'components/common/Image'

import { ReturnDispute } from 'types/disputes'
import { MarketplaceProduct } from 'types/Item'
import { Return } from 'types/Orders'
import { getMarketplaceProductByTcin } from 'services/items'
import { getReturnReasons } from 'store/selectors'

const StyledGreyContainer = styled(Grid)(({ theme }) => ({
  marginLeft: 0,
  paddingBottom: theme.spacing(2),
  width: '100%',
  backgroundColor: backgroundGrey.main,
}))

const StyledImageContainer = styled('div')(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(0.5),
  maxWidth: '200px',
}))

const keyByValue = keyBy<{ value: string; description: string }>(
  (reason) => reason.value,
)

interface Props {
  dispute?: ReturnDispute
  order: Return
  licensePlate?: string
  disputeReason: string
  disputeQuantity: number
}

export const CreateDisputeSummary = ({
  dispute,
  order,
  licensePlate,
  disputeReason,
  disputeQuantity,
}: Props) => {
  const [item, setItem] = useState<MarketplaceProduct>()

  const rawReasons = useSelector(getReturnReasons)

  const returnReasons = keyByValue(
    rawReasons.map((reason) => ({
      value: reason.value,
      description: reason?.properties.description ?? reason.value,
    })),
  )

  const bol = order?.tracking_data?.find((element) => element.license_plate)
    ?.bill_of_lading

  let dataList = [
    {
      title: 'Return Order Number:',
      element: order?.return_order_number,
    },
    {
      title: 'TCIN:',
      element: order?.tcin,
    },
    {
      title: 'License Plate: ',
      element:
        licensePlate && bol
          ? `${licensePlate} (BOL ${bol})`
          : licensePlate ?? 'N/A',
    },
    {
      title: 'Guest Return Reason:',
      element:
        order && returnReasons[order.return_reason]
          ? returnReasons[order.return_reason].description
          : '',
    },
    {
      title: 'Dispute Reason:',
      element: disputeReason,
    },
    {
      title: 'Dispute Qty:',
      element: disputeQuantity,
    },
  ]

  const chargebackAmount = {
    title: 'Item Chargeback:',
    element: `$${dispute?.chargeback_amount}`,
  }

  if (dispute) {
    dataList.splice(3, 0, chargebackAmount)
  }

  useEffect(() => {
    let mounted = true
    if (order) {
      getMarketplaceProductByTcin(order.tcin).then((res) => {
        if (mounted) {
          setItem(res)
        }
      })
    }

    return () => {
      mounted = false
    }
  }, [order])

  return (
    <>
      {item && (
        <Grid container sx={{ pt: 3 }}>
          <StyledGreyContainer container spacing={2}>
            <Grid item xs={3} sx={{ alignSelf: 'center' }}>
              <StyledImageContainer>
                <Image src={item?.primary_image} alt="primary image"></Image>
              </StyledImageContainer>
            </Grid>
            <Grid item xs={9}>
              <DataList data={dataList} />
            </Grid>
          </StyledGreyContainer>
        </Grid>
      )}
    </>
  )
}

export default CreateDisputeSummary
