import { ReactNode } from 'react'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import Typography from '@mui/material/Typography'

import { SetupTask } from 'types/SetupTask'

import SetupTaskTableRow from './SetupTaskTableRow'
import styled from '@emotion/styled'

const StyledTableBody = styled(TableBody)(({ theme }) => ({
  '& tr:nth-child(even)': {
    backgroundColor: theme.palette.grey[100],
  },
}))

export interface Props {
  className?: any
  title?: string
  subtitle?: string | ReactNode
  tasks: SetupTask[]
}

export const SetupTaskTable = ({
  className,
  title,
  subtitle,
  tasks,
}: Props) => {
  return (
    <div className={className}>
      {title && <Typography variant="h6">{title}</Typography>}
      {subtitle && <Typography variant="caption">{subtitle}</Typography>}
      <Table>
        <StyledTableBody>
          {tasks.map((task, index) => (
            <SetupTaskTableRow key={index} task={task} />
          ))}
        </StyledTableBody>
      </Table>
    </div>
  )
}

export default SetupTaskTable
