import { createContext, useContext, useState } from 'react'
import { useUserSettingsQuery } from 'hooks/useUserSettingsQuery'
import { UserSettings } from 'types/UserSettings'
import { REVIEW_QUEUE } from 'constants/userSettings'

export type UserSettingsContextType = {
  userSettings: {
    data?: UserSettings
    status: string
    isLoading: boolean
  }
  selectedSavedSearches: any
  setSelectedSavedSearches: (savedSearches: any) => void
  updateSelectedSavedSearch: (updatedFilters: any, page: string) => void
}

const UserSettingsContext = createContext<null | UserSettingsContextType>(null)

export const UserSettingsProvider = ({ children }: any) => {
  const { data, isLoading, status } = useUserSettingsQuery()
  const [selectedSavedSearches, setSelectedSavedSearches] = useState({
    [REVIEW_QUEUE]: {},
  })

  const updateSelectedSavedSearch = (updatedFilters: any, page: string) => {
    setSelectedSavedSearches({
      ...selectedSavedSearches,
      [page]: updatedFilters,
    })
  }

  return (
    <UserSettingsContext.Provider
      value={{
        userSettings: { data, isLoading, status },
        selectedSavedSearches,
        setSelectedSavedSearches,
        updateSelectedSavedSearch,
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  )
}

export const useUserSettingsContext = () => {
  const context = useContext(UserSettingsContext)
  if (!context) {
    throw new Error('a must be used within a AppProvider')
  }
  return context
}

export default UserSettingsContext
