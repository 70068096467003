import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import uniq from 'lodash/fp/uniq'

import styled from '@emotion/styled'
import { warning } from 'config/themeConfig'

import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'

import OverridesAsideOrderSearchInput from './OverridesAsideOrderSearchInput'

import { trackCustomEvent } from 'services/fireflyInsights'
import { getOrderById } from 'services/orders'
import { createOverride } from 'services/orderDefectOverrides'

import { currentSeller as getCurrentSeller } from 'store/selectors'

import { FireflyEvent } from 'types/FireflyInsights'
import { Order } from 'types/Orders'
import { OrderDefectMetricType } from 'types/VendorStats'

const StyledContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(3, 0),
}))

const StyledDescription = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}))

const StyledWarningText = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  color: warning.main,
}))

const StyledOrderLimit = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}))

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
}))

export interface Props {
  type: OrderDefectMetricType
  onCancel: () => void
  onSubmit: () => void
}

export const ApplyOverridesAside = ({ type, onCancel, onSubmit }: Props) => {
  const currentSeller = useSelector(getCurrentSeller)
  const [overrideStep, setOverrideStep] = useState(0)
  const [enteredOrders, setEnteredOrders] = useState<string[]>([])
  const [orders, setOrders] = useState<Order[]>([])
  const [invalidOrders, setInvalidOrders] = useState<string[]>([])
  const [continueEnabled, setContinueEnabled] = useState<boolean>(false)
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true)
  const [orderInputSubmit, setOrderInputSubmit] = useState<boolean>(false)

  const handleCancel = () => {
    setOrderInputSubmit(false)
    setEnteredOrders([])
    setOrders([])
    setInvalidOrders([])
    setOverrideStep(0)
    onCancel()
  }

  const handleSave = async () => {
    if (!currentSeller) {
      return
    }

    await Promise.all(
      orders.map((order) =>
        createOverride(currentSeller.id, type, order).catch(() => {
          trackCustomEvent(FireflyEvent.ODR_OVERRIDE_FAIL, type, order.id)
        }),
      ),
    )

    setOrderInputSubmit(false)
    setEnteredOrders([])
    setOrders([])
    setInvalidOrders([])
    setOverrideStep(0)
    onSubmit()
  }

  const handleOrderInputSubmit = (orderEntries: string[]) => {
    setEnteredOrders(orderEntries)
    setOverrideStep(1)
  }

  const handleContinue = () => {
    setOrderInputSubmit(true)
  }

  const handleContinueEnabled = (isEnabled: boolean) => {
    setContinueEnabled(isEnabled)
  }

  useEffect(() => {
    let canceled = false
    uniq(enteredOrders).forEach((enteredOrder, index, array) => {
      if (currentSeller) {
        getOrderById(currentSeller.id, enteredOrder)
          .then((order) => {
            if (canceled) return
            setOrders((existingOrders) => [...existingOrders, order])
            if (index === array.length - 1) {
              setSubmitDisabled(false)
            }
          })
          .catch(() => {
            if (canceled) return
            setInvalidOrders((existingInvalidOrders) => [
              ...existingInvalidOrders,
              enteredOrder,
            ])
            if (index === array.length - 1) {
              setSubmitDisabled(false)
            }
          })
      }
    })
    return () => {
      canceled = true
    }
  }, [enteredOrders, currentSeller])

  return (
    <StyledContainer>
      <StyledDescription>
        Overriding a defective order removes it from the defect rate
        calculation.
      </StyledDescription>
      {invalidOrders.length > 0 && (
        <StyledWarningText>
          Order(s) not found: {invalidOrders.join(', ')}
        </StyledWarningText>
      )}
      <StyledOrderLimit>Order(s) (Limited to 100)*</StyledOrderLimit>
      {overrideStep === 0 && (
        <OverridesAsideOrderSearchInput
          submit={orderInputSubmit}
          handleSubmit={handleOrderInputSubmit}
          handleEnabled={handleContinueEnabled}
        />
      )}
      {overrideStep === 1 && (
        <div>
          {orders.map((order, index) => (
            <StyledChip key={index} data-testid="order-chip" label={order.id} />
          ))}
        </div>
      )}
      <DialogActions>
        <Button
          color="primary"
          type="reset"
          onClick={handleCancel}
          data-testid="cancel"
        >
          Cancel
        </Button>
        {overrideStep === 0 && (
          <Button
            type="submit"
            onClick={handleContinue}
            variant="contained"
            disabled={!continueEnabled}
            color="primary"
            data-testid="continue"
          >
            Continue
          </Button>
        )}
        {overrideStep === 1 && (
          <Button
            type="submit"
            onClick={handleSave}
            variant="contained"
            disabled={submitDisabled}
            color="primary"
            data-testid="submit"
          >
            Save Changes
          </Button>
        )}
      </DialogActions>
    </StyledContainer>
  )
}

export default ApplyOverridesAside
