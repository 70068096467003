import axios from 'axios'

import apiConfig from 'config/apiConfig'

export enum JiraFields {
  FEEDBACK_TYPE_FIELD = 'customfield_13200',
  PARTNER_FIELD = 'customfield_10100',
  RATING_FIELD = 'customfield_10002',
}

export enum JiraFeedbackType {
  BUGS_OR_SOFTWARE_DEFECTS = '10603',
  FEATURE_SUGGESTIONS = '10604',
  OTHER = '10605',
}

export type JiraIssue = {
  summary: string
  sellerId: string
  description: string
  email: string
  vmmId: string
  partnerName: string
  jiraField: JiraFields
  jiraFieldValue: any
  environment?: string
}

type JiraResponse = {
  id: string
  key: string
  self: string
}

export const projectId = '14506'
export const issueTypeId = '10600'

export const createJiraIssue = async ({
  summary,
  sellerId,
  description,
  email,
  vmmId,
  partnerName,
  jiraField,
  jiraFieldValue,
  environment,
}: JiraIssue): Promise<JiraResponse> => {
  const payload = {
    fields: {
      project: {
        id: projectId,
      },
      issuetype: {
        id: issueTypeId,
      },
      summary: `${summary} ${email}`,
      description,
      environment: environment ? environment : document.title,
      [JiraFields.PARTNER_FIELD]: `${vmmId}:${partnerName}`,
      [jiraField]: jiraFieldValue,
    },
  }

  const response = await axios.post(
    `${apiConfig.securityGateway}/sellers/${sellerId}/issue`,
    payload,
  )

  return response.data
}
