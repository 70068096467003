export const ITEM_TYPE: string = 'ITEM_TYPE'
export const ITEM_SUBTYPE: string = 'PRODUCT_SUBTYPE'
export const PRODUCT_TYPE: string = 'PRODUCT_TYPE'
export const BRAND: string = 'BRAND'
export const MERCH_TYPE: string = 'MERCHANDISE_TYPE'

export default interface Attribute {
  type?:
    | typeof ITEM_TYPE
    | typeof BRAND
    | typeof ITEM_SUBTYPE
    | typeof PRODUCT_TYPE
  id: string
  name?: string
  description?: string
  deprecated?: boolean
  status?: string
}

export const defaultNode = {
  id: 'DEFAULT',
  name: 'DEFAULT',
}
