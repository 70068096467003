import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid'

import ItemVersionsTable from './ItemVersionsTable'

import HeaderTitle from 'components/common/HeaderTitle'
import TypeaheadFilter from 'components/common/FilterBar/TypeaheadFilter'
import {
  TableState,
  useSearchParams,
} from 'components/common/FilterBar/useSearchParams'

import { getMarketplaceProductByTcin } from 'services/items'

import { MarketplaceProduct } from 'types/Item'

type SearchParams = TableState & {
  tcin: string | undefined
}

const initialSearchParams = {
  page: 0,
  perPage: 4,
  tcin: undefined,
}

export const ItemVersionsPage = () => {
  const [searchParams, searchParamActions] =
    useSearchParams<SearchParams>(initialSearchParams)

  const [item, setItem] = useState<Nullable<MarketplaceProduct>>()

  useEffect(() => {
    let mounted = true
    if (searchParams.tcin) {
      getMarketplaceProductByTcin(searchParams.tcin).then((res) => {
        if (mounted) {
          setItem(res)
        }
      })
      searchParamActions.changePage(
        {} as React.MouseEvent<HTMLButtonElement, MouseEvent>,
        0,
      )
    } else {
      setItem(undefined)
    }

    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.tcin])

  return (
    <div data-testid="item-versions">
      <HeaderTitle title="Item Versions" />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <TypeaheadFilter
            label="Search by SKU, Barcode, or TCIN"
            value={searchParams.tcin}
            searchParam="tcin"
            onChange={searchParamActions.updateSearchParam}
          />
        </Grid>
      </Grid>
      {item && (
        <ItemVersionsTable
          item={item}
          page={searchParams.page}
          onChangePage={searchParamActions.changePage}
        />
      )}
    </div>
  )
}

export default ItemVersionsPage
