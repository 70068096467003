import styled from '@emotion/styled'
import { primary } from 'config/themeConfig'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import HistoryIcon from '@mui/icons-material/History'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import GetAppIcon from '@mui/icons-material/GetApp'

type Props = {
  iconType: 'edit' | 'delete' | 'history' | 'menu' | 'download'
}

const StyledDiv = styled.div({
  color: primary.main,
  fontSize: '1rem',
  '& :hover': {
    backgroundColor: primary.main,
    borderRadius: '4px',
  },
})

export const StyledIcon: React.FC<Props> = ({ iconType }) => {
  const icon =
    iconType === 'edit' ? (
      <EditIcon color="primary" data-testid="edit-icon" />
    ) : iconType === 'delete' ? (
      <DeleteIcon color="primary" data-testid="delete-icon" />
    ) : iconType === 'history' ? (
      <HistoryIcon color="primary" data-testid="history-icon" />
    ) : iconType === 'menu' ? (
      <MoreVertIcon color="primary" data-testid="menu-icon" />
    ) : iconType === 'download' ? (
      <GetAppIcon color="primary" data-testid="download-icon" />
    ) : null

  return <StyledDiv data-testid="styled-icon-container">{icon}</StyledDiv>
}

export default StyledIcon
