import { connect } from 'react-redux'
import { withFormik, Form, FormikProps } from 'formik'
import some from 'lodash/fp/some'
import * as yup from 'yup'

import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import InputField from 'components/common/form/InputField'
import SelectField from 'components/common/form/SelectField'

import {
  CATEGORY_FINANCIAL_DISPOSITION,
  CATEGORY_PHYSICAL_DISPOSITION,
  FinancialDisposition,
  PhysicalDisposition,
} from 'constants/categories'
import COUNTRIES from 'constants/countries'
import { getLocationsFromCountryCode } from 'constants/locations'
import { ReturnDescription } from 'constants/returnPolicies'

import {
  editReturnPolicy,
  EditReturnPolicyAction,
} from 'store/seller/actionCreators'

import { DEFAULT_COUNTRY } from 'services/addressHelper'
import { getCategoryCodes } from 'services/codes'
import { getVendorCategories } from 'services/marketplaceVendors'
import { ValidationSchema } from 'services/validation'

import { IdLabelPair } from 'types/IdLabelPair'
import { SmsReturnPolicy } from 'types/Seller'
import SellerUser from 'types/SellerUser'
import StoreState from 'types/state'

const StyledFieldsBackDrop = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
}))

const StyledInputField = styled(InputField)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

const StyledSelectField = styled(SelectField)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

interface FormValues {
  returnContactId: string
  inCareOf: string
  address1: string
  address2: string
  city: string
  state: string
  postalCode: string
  countryCode: string
}

interface ComponentProps {
  isOpen: boolean
  editReturnPolicy: (returnPolicy: SmsReturnPolicy) => EditReturnPolicyAction
  handleSubmit: any
  contacts: any
  sellerId: string
}

type Props = ComponentProps & FormikProps<FormValues>

const validationSchema: ValidationSchema = yup.object().shape({
  returnContactId: yup.string().label('User Contact Name').required(),
  inCareOf: yup.string().label('In Care Of').max(35).required(),
  address1: yup.string().label('Address Line 1').max(75).required(),
  address2: yup.string().label('Address Line 2').max(75),
  city: yup.string().label('City').required(),
  state: yup.string().label('State').required(),
  postalCode: yup.string().label('Zip Code').min(5).max(9).required(),
  countryCode: yup.string().label('Country').required(),
})

const EditReturnAddressForm = ({
  isOpen,
  isSubmitting,
  handleSubmit,
  values,
  dirty,
  contacts,
  isValid,
}: Props) => {
  const sellerContacts: IdLabelPair[] = contacts?.map((user: SellerUser) => ({
    id: user.id,
    label: `${user.last_name}, ${user.first_name}`,
  }))

  return (
    <DialogContainer
      title="Add Return Address"
      subtitle="This will be printed on the return label"
      isOpen={isOpen}
      isPending={isSubmitting}
      onSubmit={handleSubmit}
      isSubmitDisabled={!isValid || !dirty}
    >
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid item xs={6}>
              <StyledSelectField
                required
                name="returnContactId"
                options={sellerContacts ?? []}
                keyName="label"
                valueName="id"
                label="User Contact Name"
                inputProps={{ 'data-testid': 'user-contact-id' }}
                data-testid="userContactSelector"
                disabled={isSubmitting}
              />
              <StyledInputField
                required
                name="inCareOf"
                label="In Care Of"
                inputProps={{ 'data-testid': 'in-care-of' }}
                disabled={isSubmitting}
                maxCharacters={35}
              />
            </Grid>
            <StyledFieldsBackDrop elevation={0}>
              Partner Return Address
              <StyledInputField
                required
                name="address1"
                label="Address Line 1"
                inputProps={{ 'data-testid': 'address-1' }}
                disabled={isSubmitting}
              />
              <StyledInputField
                name="address2"
                label="Address Line 2"
                inputProps={{ 'data-testid': 'address-2' }}
                disabled={isSubmitting}
              />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <StyledInputField
                    required
                    name="city"
                    label="City"
                    inputProps={{ 'data-testid': 'city' }}
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledSelectField
                    required
                    name="state"
                    options={getLocationsFromCountryCode(values.countryCode)}
                    keyName="name"
                    valueName="abbreviation"
                    label="State"
                    inputProps={{ 'data-testid': 'state' }}
                    data-testid="stateSelector"
                    disabled={isSubmitting}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <StyledSelectField
                    required
                    name="countryCode"
                    options={COUNTRIES}
                    keyName="name"
                    valueName="code"
                    label="Country"
                    inputProps={{ 'data-testid': 'country' }}
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledInputField
                    required
                    name="postalCode"
                    label="Zip Code"
                    inputProps={{ 'data-testid': 'postal-code' }}
                    disabled={isSubmitting}
                  />
                </Grid>
              </Grid>
            </StyledFieldsBackDrop>
          </Grid>
        </Grid>
      </Form>
    </DialogContainer>
  )
}

export const EditReturnAddress = withFormik<ComponentProps, FormValues>({
  mapPropsToValues: () => ({
    returnContactId: '',
    inCareOf: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postalCode: '',
    countryCode: DEFAULT_COUNTRY,
  }),

  handleSubmit: async (values, { props }) => {
    const {
      returnContactId,
      inCareOf,
      address1,
      address2,
      city,
      state,
      postalCode,
      countryCode,
    } = values
    const { editReturnPolicy } = props

    const vendorCategories = await getVendorCategories()

    const financialDispositionCodes = getCategoryCodes(
      vendorCategories,
      CATEGORY_FINANCIAL_DISPOSITION,
    )
    const physicalDispositionCodes = getCategoryCodes(
      vendorCategories,
      CATEGORY_PHYSICAL_DISPOSITION,
    )

    //make sure the dispositions we've hardcoded exist in vmm
    const destroyPhysicalDisposition = {
      code_id: PhysicalDisposition.Destroy,
      code_name: 'DD',
    }
    const crcReturnPhysicalDisposition = {
      code_id: PhysicalDisposition.ReturnViaCrc,
      code_name: 'MC',
    }
    const writeOffFinancialDisposition = {
      code_id: FinancialDisposition.WriteOff,
      code_name: ReturnDescription.WRITE_OFF,
    }
    const partnerChargeFinancialDisposition = {
      code_id: FinancialDisposition.ChargeToPartner,
      code_name: 'C',
    }

    const physicalDispositionsExist =
      some(destroyPhysicalDisposition, physicalDispositionCodes) &&
      some(crcReturnPhysicalDisposition, physicalDispositionCodes)
    const financialDispositionsExist =
      some(writeOffFinancialDisposition, financialDispositionCodes) &&
      some(partnerChargeFinancialDisposition, financialDispositionCodes)

    if (physicalDispositionsExist && financialDispositionsExist) {
      const chargeAndReturnToCrcReturnPolicy = {
        financial_disposition_id: partnerChargeFinancialDisposition.code_id,
        physical_disposition_id: crcReturnPhysicalDisposition.code_id,
        return_seller_user_id: returnContactId,
        in_care_of: inCareOf,
        return_address: {
          address1: address1.trim(),
          address2: address2.trim(),
          city: city.trim(),
          country_code: countryCode,
          postal_code: postalCode.trim(),
          state,
        },
      }

      const writeOffAndDestroyReturnPolicy = {
        financial_disposition_id: writeOffFinancialDisposition.code_id,
        physical_disposition_id: destroyPhysicalDisposition.code_id,
      }

      const chargeToSellerAndDestroyReturnPolicy = {
        financial_disposition_id: partnerChargeFinancialDisposition.code_id,
        physical_disposition_id: destroyPhysicalDisposition.code_id,
      }

      editReturnPolicy(chargeAndReturnToCrcReturnPolicy)
      editReturnPolicy(writeOffAndDestroyReturnPolicy)
      editReturnPolicy(chargeToSellerAndDestroyReturnPolicy)
    } else {
      window.alert(
        'There was a problem creating your return policies, please contact plus.support@target.com',
      )
    }
  },
  enableReinitialize: true,
  validationSchema,
})(EditReturnAddressForm)

const mapStateToProps = (state: StoreState) => {
  const { seller } = state
  const { currentSeller, contacts } = seller
  return { contacts, sellerId: currentSeller!.id }
}

const mapDispatchToProps = {
  editReturnPolicy,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditReturnAddress)
