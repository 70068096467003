import { useSelector } from 'react-redux'

import { Navigate } from 'react-router-dom'

import { RoutePath } from 'services/NavigationHelper'

import {
  hasOneSellerSelector,
  getSellers,
  currentSellerId,
} from 'store/selectors'

import { isExternalUrl } from 'config/apiConfig'

export interface Props {
  path: string
  location: any
}

export const GenerateRedirect = ({ path, location }: Props) => {
  const sellerId = useSelector(currentSellerId)
  const sellers = useSelector(getSellers)
  const hasOneSeller = useSelector(hasOneSellerSelector)

  let toPath = RoutePath.HOME as string

  // Wait for the sellers array to be populated
  if (sellers.length === 0) {
    return null
  }

  if (isExternalUrl) {
    toPath = hasOneSeller ? `/${sellerId}${path}` : `/${sellers[0].id}${path}`
  }

  toPath = location.search ? `${toPath}${location.search}` : toPath

  return <Navigate to={toPath} replace />
}

export default GenerateRedirect
