import axios from 'axios'
import apiConfig from 'config/apiConfig'

import { Carrier } from 'types/Carrier'
import { Discount, Markup } from 'types/Markup'
import { CollectionResponse } from 'types/Response'

import { isoStringStartOfDay, assignDiscountStatus } from './dateService'
import { PagingParams, getPageable } from './pageableHelper'

export function getEffectiveMarkupByCarrier(carrier: Carrier): Promise<Markup> {
  const today = new Date()

  const config = {
    params: {
      carrier,
      effective_date: today,
    },
  }

  return axios
    .get(`${apiConfig.sellerShippingManagement}/markups`, config)
    .then((res) => {
      return res.data[0]
    })
}

export function getMarkupsByCarrier(carrier: Carrier): Promise<Markup[]> {
  const config = {
    params: {
      carrier,
    },
  }

  return axios
    .get(`${apiConfig.sellerShippingManagement}/markups`, config)
    .then((res) => res.data)
}

export function createCarrierMarkup(
  carrier: Carrier,
  rate: number,
  startDate: string,
): Promise<Markup> {
  const body = {
    carrier,
    rate,
    start_date: isoStringStartOfDay(startDate),
    type: 'CARRIER',
  }

  return axios
    .post(`${apiConfig.sellerShippingManagement}/markups`, body)
    .then((res) => res.data)
}

export async function getCarrierMarkupHistory(
  carrier: Carrier,
  pagingParams: PagingParams,
): Promise<CollectionResponse<Markup>> {
  const config = {
    params: {
      ...getPageable(pagingParams),
      carrier,
    },
  }

  const response = await axios
    .get(`${apiConfig.sellerShippingManagement}/markups`, config)
    .then((resp): CollectionResponse<Markup> => {
      const { data, headers } = resp

      const total = headers['x-total-count']
        ? parseInt(headers['x-total-count'], 10)
        : 0

      return {
        total,
        data,
      }
    })

  if (!response.data) {
    return response
  }

  return response
}

type ParterDiscountSearchParams = {
  seller_id?: string
  carrier?: Carrier
  effective_date?: Date
}

export async function getPartnerDiscounts(
  pagingParams: PagingParams,
  searchParams: ParterDiscountSearchParams,
): Promise<CollectionResponse<Discount>> {
  const config = {
    params: {
      ...getPageable(pagingParams),
      seller_id: searchParams.seller_id,
      carrier: searchParams.carrier,
      effective_date: searchParams.effective_date,
      eligible: true,
    },
  }

  return axios
    .get(`${apiConfig.sellerShippingManagement}/discounts`, config)
    .then((resp): CollectionResponse<Discount> => {
      const { data, headers } = resp

      const total = headers['x-total-count']
        ? parseInt(headers['x-total-count'], 10)
        : 0

      return {
        total,
        data,
      }
    })
}

export async function getDiscountsBySellerId(
  sellerId: string,
  carrier: Carrier,
  pagingParams: PagingParams,
): Promise<CollectionResponse<Discount>> {
  const config = {
    params: {
      carrier,
      seller_id: sellerId,
      ...getPageable(pagingParams),
    },
  }

  return axios
    .get(`${apiConfig.sellerShippingManagement}/discounts`, config)
    .then((resp): CollectionResponse<Discount> => {
      const { data, headers } = resp

      const total = headers['x-total-count']
        ? parseInt(headers['x-total-count'], 10)
        : 0

      return {
        total,
        data: assignDiscountStatus(data),
      }
    })
}

export async function calculatePartnerDiscountedMarkup(payload: {
  sellerId: string
  carrier: Carrier
  effectiveDate: Date
}): Promise<number> {
  const { carrier, sellerId, effectiveDate } = payload

  const body = {
    carrier,
    seller_id: sellerId,
    effective_date: effectiveDate,
  }

  return axios
    .post(`${apiConfig.sellerShippingManagement}/markups:calculate`, body)
    .then((res) => res.data.rate)
}

export function updatePartnerDiscount(
  discount: Partial<Discount>,
): Promise<Discount> {
  return axios
    .post(`${apiConfig.sellerShippingManagement}/discounts`, discount)
    .then((res) => res.data)
}
