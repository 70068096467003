import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import getOr from 'lodash/fp/getOr'
import get from 'lodash/fp/get'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import LinearProgress from '@mui/material/LinearProgress'

import HeaderTitle from 'components/common/HeaderTitle'
import Link from 'components/common/Link'
import Message from 'components/common/Message'
import ItemDetailsContent from './ItemDetailsContent'
import ItemDetailsTabs from './ItemDetailsTabs'
import SuspendItemDialog from 'components/ReviewQueuePage/Dialogs/SuspendItemDialog'
import ErrorNotSavedDialog from 'components/ReviewQueuePage/Dialogs/ErrorNotSavedDialog'

import { openDialog, closeDialog } from 'store/dialog/actionCreator'
import { getMemberOf } from 'store/selectors'

import { DialogEnum } from 'components/common/Dialog'

import {
  getCurrentProductStatus,
  hasPublishedProductStatus,
} from 'services/itemHelper'
import { RoutePath } from 'services/NavigationHelper'
import { isOneOfUserRoles } from 'services/authorization'
import {
  USER_ROLE_ADMIN,
  USER_ROLE_ACQUISITIONS,
  USER_ROLE_COMMUNICATIONS,
  USER_ROLE_ITEM_APPROVER,
  USER_ROLE_ITEM_MANAGER,
  USER_ROLE_OPS,
  USER_ROLE_PROGRAM_MANAGER,
  USER_ROLE_REPORTER,
  USER_ROLE_FINANCE,
} from 'services/roles'

import { ListingStatus, ProductError, RelationshipType } from 'types/Item'
import {
  getFormattedProductLogistics,
  getItemDetails,
  getProductLogistics,
} from 'services/items'
import {
  ProductLogistics,
  ProductLogisticsError,
  Logistics,
} from 'types/ProductLogistics'
import { isProductLogistics, isProductLogisticsError } from 'types/Guards'
import { MarketplaceProduct } from 'types/Item'
import { SmsProduct } from 'types/Item'
import { updateProductListingStatus } from 'services/itemReview'

const rejectItemRoles = [
  USER_ROLE_ADMIN,
  USER_ROLE_ITEM_APPROVER,
  USER_ROLE_ITEM_MANAGER,
]

const suspendItemRoles = [
  ...rejectItemRoles,
  USER_ROLE_OPS,
  USER_ROLE_PROGRAM_MANAGER,
]

const enrichedPublishedDataRoles = [
  USER_ROLE_ACQUISITIONS,
  USER_ROLE_ITEM_MANAGER,
  USER_ROLE_ITEM_APPROVER,
  USER_ROLE_PROGRAM_MANAGER,
  USER_ROLE_REPORTER,
  USER_ROLE_COMMUNICATIONS,
  USER_ROLE_OPS,
  USER_ROLE_FINANCE,
  USER_ROLE_ADMIN,
]

export const ItemDetailsPage = () => {
  const params = useParams()
  const reduxDispatch = useDispatch()
  const memberOf = useSelector(getMemberOf)

  const [openSuspend, setOpenSuspend] = useState(false)
  const [errorNotSavedOpen, setErrorNotSavedOpen] = useState(false)
  const [returnPolicyAlert, setReturnPolicyAlert] = useState<string>()

  const [isItemPending, setIsItemPending] = useState(true)
  const [hasProductLogisticsTab, setHasProductLogisticsTab] = useState(false)
  const [productLogistics, setProductLogistics] = useState<
    ProductLogistics | undefined
  >()
  const [formattedProductLogistics, setFormattedProductLogistics] = useState<
    Logistics[]
  >([])
  const [marketplaceProduct, setMarketplaceProduct] =
    useState<MarketplaceProduct>()
  const [smsProduct, setSmsProduct] = useState<SmsProduct>()
  const [listingStatus, setListingStatus] = useState<string>('')
  const statusErrors = getOr([], 'product_statuses[0].errors', smsProduct)

  useEffect(() => {
    let mounted = true
    const returnPolicyLogistic = formattedProductLogistics.find(
      (logistic) =>
        logistic.name === 'seller_return_policy' &&
        logistic.code !== 'RETURN_POLICY_OVERRIDE',
    )

    if (returnPolicyLogistic) {
      if (mounted) {
        setReturnPolicyAlert(returnPolicyLogistic.message)
      }
    }

    return () => {
      mounted = false
    }
  }, [formattedProductLogistics])

  const closeSuspendDialog = () => {
    setOpenSuspend(false)
  }

  useEffect(() => {
    const fetchItemDetails = async () => {
      setIsItemPending(true)
      try {
        const itemDetailsResponse = await getItemDetails({
          sellerId: params.sellerId ?? '',
          productId: params.productId ?? '',
        })

        setMarketplaceProduct(itemDetailsResponse.marketplaceProduct)
        setSmsProduct(itemDetailsResponse.smsProduct)

        const relationshipType =
          itemDetailsResponse?.marketplaceProduct.relationship_type

        setLogisticsTab(relationshipType)
        setIsItemPending(false)
      } catch (e) {
        console.error(`Item details failed: ${e}`)
        setIsItemPending(false)
      }
    }

    fetchItemDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.productId, params.sellerId, listingStatus])

  const setLogisticsTab = async (relationshipType: string) => {
    let hasLogistics =
      relationshipType === RelationshipType.VC ||
      relationshipType === RelationshipType.SA

    setHasProductLogisticsTab(hasLogistics)

    if (hasLogistics) {
      try {
        const productLogistics: ProductLogistics | ProductLogisticsError =
          await getProductLogistics({
            sellerId: params.sellerId ?? '',
            productId: params.productId ?? '',
          })

        if (isProductLogisticsError(productLogistics)) {
          if (productLogistics.status === 404) {
            setProductLogistics(undefined)
            setFormattedProductLogistics([])
          }
        } else if (isProductLogistics(productLogistics)) {
          const formattedProductLogistics =
            getFormattedProductLogistics(productLogistics)
          setProductLogistics(productLogistics)
          setFormattedProductLogistics(formattedProductLogistics)
        }
      } catch (e) {
        console.error(`Product logistics failed: ${e}`)
      }
    }
  }

  const currentProductStatus = getCurrentProductStatus(smsProduct)

  const updateStatus =
    (listingStatus: ListingStatus) => async (errors?: ProductError[]) => {
      const updatedErrors = errors ? [...statusErrors, ...errors] : statusErrors

      if (!currentProductStatus) {
        console.error('No current product status found')
        return
      }

      if (listingStatus === ListingStatus.SUSPENDED) {
        setOpenSuspend(false)
      } else if (listingStatus === ListingStatus.REJECTED) {
        reduxDispatch(closeDialog())
      }

      await updateProductListingStatus({
        sellerId: marketplaceProduct!.seller_id,
        productId: marketplaceProduct!.product_id,
        statusId: currentProductStatus.id,
        tcin: marketplaceProduct!.tcin,
        listingStatus,
        errors: updatedErrors,
      })
        .then(() => {
          setListingStatus(listingStatus)
        })
        .catch((e) =>
          console.error(`Update product listing status failed: ${e}`),
        )

      if (listingStatus === ListingStatus.SUSPENDED && !errors) {
        setErrorNotSavedOpen(true)
      }
    }

  const handleUnsuspend = async () => {
    if (!currentProductStatus) {
      return
    }

    await updateProductListingStatus({
      sellerId: marketplaceProduct!.seller_id,
      productId: marketplaceProduct!.product_id,
      statusId: currentProductStatus.id,
      tcin: marketplaceProduct!.tcin,
      listingStatus: ListingStatus.REJECTED,
      errors: statusErrors,
    })
      .then(() => {
        setListingStatus(ListingStatus.REJECTED)
      })
      .catch((e) => console.error(`Update product listing status failed: ${e}`))

    reduxDispatch(closeDialog())
  }

  const handleOpenDialog = (listingStatus: ListingStatus) => () => {
    if (listingStatus === ListingStatus.SUSPENDED) {
      setOpenSuspend(true)
      return
    }

    if (listingStatus === ListingStatus.REJECTED) {
      if (!currentProductStatus) {
        console.error('No current product status found')
        return
      }

      if (currentProductStatus.listing_status === ListingStatus.SUSPENDED) {
        reduxDispatch(
          openDialog({
            dialogEnum: DialogEnum.CONFIRMATION_DIALOG,
            componentProps: {
              title: 'Confirm Unsuspend',
              submitText: 'Unsuspend',
              content:
                'Unsuspending this item will put it in a rejected status. If the item needs to be re-listed, the seller must be contacted for a new version.',
              onRequestSubmit: handleUnsuspend,
            },
          }),
        )
      } else if (
        window.confirm(
          'This will only reject the published version, do you want to proceed?',
        )
      ) {
        reduxDispatch(
          openDialog({
            dialogEnum: DialogEnum.ADD_ERROR_CODES,
            componentProps: { onSubmit: updateStatus(ListingStatus.REJECTED) },
          }),
        )
      }
    }
  }

  const showRejectButton =
    isOneOfUserRoles(memberOf, rejectItemRoles) &&
    getOr(false, 'published', marketplaceProduct)

  const showSuspendButton =
    isOneOfUserRoles(memberOf, suspendItemRoles) &&
    get('listing_status', currentProductStatus) !== ListingStatus.SUSPENDED

  const showUnsuspendButton =
    isOneOfUserRoles(memberOf, suspendItemRoles) &&
    get('listing_status', currentProductStatus) === ListingStatus.SUSPENDED

  const showEnrichedDataTab =
    isOneOfUserRoles(memberOf, enrichedPublishedDataRoles) &&
    hasPublishedProductStatus(smsProduct)

  const closeErrorNotSavedDialog = () => {
    setErrorNotSavedOpen(false)
  }

  let content = <Typography>No data found.</Typography>

  if (isItemPending) {
    content = <LinearProgress />
  } else if (marketplaceProduct && smsProduct) {
    content = (
      <ItemDetailsContent
        marketplaceProduct={marketplaceProduct}
        smsProduct={smsProduct}
        showSuspendButton={showSuspendButton}
        showUnsuspendButton={showUnsuspendButton}
        showRejectButton={showRejectButton}
        onClick={handleOpenDialog}
      />
    )
  }

  return (
    <>
      <HeaderTitle
        title={[
          {
            text: 'Items',
            route: `/${params.sellerId}${RoutePath.ALL_ITEMS}`,
          },
          { text: 'Item Detail' },
        ]}
      />
      <Grid container spacing={2}>
        {returnPolicyAlert && (
          <Grid item xs={12} data-testid="return-policy-alert">
            <Message title="Action Required: Update Return Policy" type="alert">
              {returnPolicyAlert}. Please send a{' '}
              <Link
                to={`/${params.sellerId}${RoutePath.RETURN_POLICIES}`}
                target="_blank"
              >
                valid return policy ID
              </Link>{' '}
              as a value.
            </Message>
          </Grid>
        )}
        <Grid item xs={12}>
          {content}
        </Grid>
        <Grid item xs={12}>
          <ItemDetailsTabs
            showEnrichedDataTab={showEnrichedDataTab}
            logistics={formattedProductLogistics}
            productLogistics={productLogistics}
            hasProductLogisticsTab={hasProductLogisticsTab}
            marketplaceProduct={marketplaceProduct}
            smsProduct={smsProduct}
            isItemPending={isItemPending}
          />
        </Grid>
      </Grid>
      <SuspendItemDialog
        isOpen={openSuspend}
        onSubmitItemDetails={updateStatus}
        onClose={closeSuspendDialog}
      />
      <ErrorNotSavedDialog
        isOpen={errorNotSavedOpen}
        onClose={closeErrorNotSavedDialog}
      />
    </>
  )
}

export default ItemDetailsPage
