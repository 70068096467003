import { useRef, forwardRef, useImperativeHandle } from 'react'

import MaskedInput from 'react-text-mask'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'

const DateTextMask = forwardRef((props, _ref) => {
  const inputRef = useRef<HTMLElement | null>(null)
  const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy')

  useImperativeHandle(
    _ref,
    () => ({
      focus: () => {
        if (inputRef?.current?.focus) {
          inputRef.current.focus()
        }
      },
    }),
    [],
  )

  return (
    <MaskedInput
      render={(textMaskRef, props) => (
        <input
          {...props}
          ref={(node) => {
            if (node) {
              textMaskRef(node)

              inputRef.current = node
            }
          }}
        />
      )}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'_'}
      pipe={autoCorrectedDatePipe}
      keepCharPositions
      showMask
      {...props}
    />
  )
})
export default DateTextMask
