import { useState, useEffect, SyntheticEvent } from 'react'

import Typeahead from './index'

import { PagingParams } from 'services/pageableHelper'
import { getReturnsResearch } from 'services/sellerReturns'

import { ReturnsResearch } from 'types/Orders'
import usePrevious from 'hooks/usePrevious'

interface Props {
  placeholder?: string
  value?: Nullable<ReturnsResearch>
  clearOnSelect?: boolean
  onChange: (value: Nullable<ReturnsResearch>, enteredValue: string) => void
  onClear?: () => void
}

export const StoreReferenceFieldTypeahead = ({
  placeholder = 'Unit LP',
  value: valueFromProps,
  clearOnSelect = false,
  onChange,
  onClear,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedValue, setSelectedValue] =
    useState<Nullable<ReturnsResearch>>(valueFromProps)
  const [options, setOptions] = useState<ReturnsResearch[]>([])
  const [loading, setLoading] = useState(false)

  const prevValue = usePrevious(valueFromProps)
  useEffect(() => {
    if (prevValue !== valueFromProps) {
      setSelectedValue(valueFromProps)
    }

    if (!valueFromProps) {
      setOptions([])
    }
  }, [prevValue, valueFromProps])

  useEffect(() => {
    if (options.length && !inputValue) {
      setOptions([])
    }
  }, [inputValue, options])

  const handleInputChange = async (
    _event: SyntheticEvent,
    enteredValue: string,
    reason: string,
  ) => {
    if (
      onClear &&
      (reason === 'clear' ||
        (selectedValue && reason === 'input' && enteredValue === ''))
    ) {
      onClear()
      setOptions([])
      setInputValue('')
      setSelectedValue(null)
      return
    }

    setInputValue(enteredValue)

    const invalidInputValue =
      !enteredValue || enteredValue.length < 3 || reason === 'reset'
    if (invalidInputValue) {
      return
    }

    const paging: PagingParams = {
      page: 0,
      perPage: 20,
    }

    setLoading(true)
    const { data } = await getReturnsResearch(paging, {
      store_reference_field: enteredValue,
    })

    setOptions(data)
    setLoading(false)
  }

  const handleSelectedOptionChange = (
    _event: SyntheticEvent,
    value: ReturnsResearch,
  ) => {
    if (!(value instanceof Array)) {
      const searchParamValue = getOptionLabel(value as ReturnsResearch)

      setSelectedValue(
        clearOnSelect ? null : (value as Nullable<ReturnsResearch>),
      )
      onChange(value as Nullable<ReturnsResearch>, searchParamValue)
    }
  }

  const getOptionLabel = (returnItem: ReturnsResearch) =>
    returnItem?.store_reference_field ?? ''

  return (
    <Typeahead
      aria-label="Unit LP Filter"
      placeholder={placeholder}
      getOptionLabel={getOptionLabel}
      options={options}
      loading={loading}
      onInputChange={handleInputChange}
      onChange={handleSelectedOptionChange}
      inputValue={inputValue}
      value={selectedValue}
      isOptionEqualToValue={(
        option: ReturnsResearch,
        value: Nullable<ReturnsResearch>,
      ) => option.id === value?.id}
    />
  )
}

export default StoreReferenceFieldTypeahead
