import apiConfig, { ENVIRONMENT_PRODUCTION } from 'config/apiConfig'
export const stageGargantuaSellers = [
  // Thunder Brewing
  '5d9b63230b782d009720a6cb',
  //Salsify
  '6137d7acade0fa0c8e2973ab',
]

export const prodGargantuaSellers = [
  //Test accounts
  // Chaco Chaco 27
  '5c6335cc89cbfe024bdeecee',
  // Target
  '5aafd3eef243400552a5d8b9',

  //List of actual partners
  //Costway
  '60091a2b0dde462386d7803b',
  //Fifth Sun
  '5e0a23704cb5ce009759b23a',
  //Nuloom
  '5dcc71c371dc7a008d3cc45a',
  //Safavieh
  '5d949496fcd4b70097dfad5e',
  //Spreetail
  '5b5b1d3cbc563e03bad44170',
  //Tangkula Inc
  '627370716bb4d457cc5a95d1',
  //Unique Bargains
  '5ff3f1d4193ba547e817a366',
  //Yaheetech
  '6298af4d12e0625a84a39c94',
  //Aosom
  '6041ad6855bf895b23576f3c',
  //Bare Home
  '5e20855ffad87400973fcc79',
  //Best Choice Products
  '5fb4e972fd35233bfd8806e9',
  //Blue Nile Mills
  '5f201e069702827e5ddae797',
  //First Choice Home
  '5da9cda34d98ab0098af4831',
  //iEnjoy Home,
  '61dd12d55d89c53cffa3e7b3',
  //Lamps Plus
  '5dcc709d5a66c3009793db4c',
  //Levtex Home
  '5f9ac873fd35233bfd4ccdd8',
  //ModernLuxe
  '628dd96ade246c4a33f1ec3a',
  //Nourison
  '5c5d9b9b802fef024ac5b6b5',
  //POP Maison
  '6270d047b4a2301a0abd86a0',
  //Jonathan Y & Happimess
  '5ff2ac6827f74d40e7ddd5bb',
  //Diddly Deals
  '60c7e9317f2aab7ac194b76c',
  //RGA Ben
  '62e150ef64c5d310130c2b19',
  //Toynk
  '5b3290e63320f503ba531d0f',
]

const stageGargantuaUrl = 'https://extgargantua.perf.target.com'
const prodGargantuaUrl = 'https://extgargantua.target.com'

export const isGargantuaSeller = (sellerId: string) => {
  const gargantuaSeller =
    apiConfig.environment !== ENVIRONMENT_PRODUCTION
      ? stageGargantuaSellers
      : prodGargantuaSellers

  return gargantuaSeller.includes(sellerId)
}

export const getGargantuaUrl = () => {
  const baseUrl = window.location.origin

  return apiConfig.environment !== ENVIRONMENT_PRODUCTION
    ? `${stageGargantuaUrl}?redirectUrl=${baseUrl}`
    : `${prodGargantuaUrl}?redirectUrl=${baseUrl}`
}
