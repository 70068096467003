import DOMPurify from 'dompurify'

import styled from '@emotion/styled'

const StyledContainer = styled.div({
  whiteSpace: 'pre-wrap',
})

export interface Props {
  content: string
}
export const PrintHTML = ({ content }: Props) => {
  const textBody = DOMPurify.sanitize(content, {
    ALLOWED_TAGS: ['p', 'br', 'ul', 'ol', 'li'],
  })

  return <StyledContainer dangerouslySetInnerHTML={{ __html: textBody }} />
}

export default PrintHTML
