import React from 'react'

import Grid from '@mui/material/Grid'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'

interface Props {
  isOpen?: boolean
  title?: string
  children?: React.ReactNode
}

export const Aside = ({ isOpen, title, children }: Props) => {
  return (
    <Collapse
      data-testid={isOpen ? 'aside-opened' : 'aside-closed'}
      in={isOpen}
    >
      <aside data-testid="aside">
        {title && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography data-testid="aside-title" variant="h3">
                {title}
              </Typography>
            </Grid>
          </Grid>
        )}
        {children}
      </aside>
    </Collapse>
  )
}

export default Aside
