import React from 'react'
import { useSelector } from 'react-redux'

import { warning } from 'config/themeConfig'

import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

import DisplayCard from 'components/common/DisplayCard'
import WarningIcon from 'components/common/WarningIcon'

import {
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
  formatDate,
} from 'services/dateService'
import { getSellerStatusHistories } from 'services/seller'

import { currentSeller } from 'store/selectors'

import { SellerStatus } from 'types/Seller'

import { getDefunctTasks } from './defunctTasks'
import SetupTaskTable from '../GuidedSellerSetupCard/SetupTaskTable'
import styled from '@emotion/styled'

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, -3),
}))

const StyledWarningContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const StyledWarningText = styled(Typography)({
  color: warning.main,
})

const sellerStatusHistory = async (id: string) => {
  const statusHistories = await getSellerStatusHistories(id)
  return statusHistories.filter(
    (statusHistory) => statusHistory.approval_status === SellerStatus.DEFUNCT,
  )[0]
}

export const DefunctSellerCard = () => {
  const seller = useSelector(currentSeller)

  const [defunctDate, setDefunctDate] = React.useState<string>('')
  React.useEffect(() => {
    seller
      ? sellerStatusHistory(seller.id).then((statusHistory) => {
          setDefunctDate(statusHistory.created)
        })
      : setDefunctDate('')
  }, [seller])

  if (seller === undefined) return null

  const sellerName = seller.display_name
    ? seller.display_name
    : seller.legal_business_name

  return (
    <DisplayCard
      data-testid="defunct-dashboard"
      title={`Target Plus™ Program Status: ${seller.status}`}
      description={`Defunct as of: ${formatDate(
        defunctDate,
        DATE_FORMAT_MONTH_DAY_YEAR_TIME,
      )}`}
    >
      <StyledWarningContainer>
        <WarningIcon>
          <StyledWarningText>
            Account is defunct and {sellerName} is no longer a Target Plus
            Partner.
          </StyledWarningText>
        </WarningIcon>
      </StyledWarningContainer>
      <StyledDivider />
      <SetupTaskTable tasks={getDefunctTasks()} />
    </DisplayCard>
  )
}

export default DefunctSellerCard
