import yup, { ValidationError, SchemaOf } from 'yup'

import mapValues from 'lodash/fp/mapValues'
import groupBy from 'lodash/fp/groupBy'
import flatMap from 'lodash/fp/flatMap'

import { Validation } from 'types/Validation'

export type ValidationSchema = SchemaOf<object>

export const validationHandler = (
  schema: ValidationSchema,
  data: object,
): { validation: Validation; isValid: boolean } => {
  try {
    schema.validateSync(data, { abortEarly: false })
    return {
      validation: {},
      isValid: true,
    }
  } catch (error) {
    if (error instanceof ValidationError) {
      const validation: Validation = mapValues<
        Record<string, ValidationError[]>,
        string[]
      >(flatMap('errors'), groupBy('path', error.inner))

      return {
        validation,
        isValid: false,
      }
    } else {
      return {
        validation: {},
        isValid: false,
      }
    }
  }
}

export function maxCharacterCount(path: string, count: number) {
  return function (this: yup.TestContext, value: any) {
    if (!value) {
      return true
    }

    const length = value.toString().length

    if (length > count) {
      const len = length - count

      const subject = len === 1 ? 'character' : 'characters'
      return this.createError({
        path,
        message: `${len} ${subject} over (Maximum limit of ${count}).`,
      })
    }

    return length <= count
  }
}
