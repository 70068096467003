/**
 * getCSTDateAndTime() method is used to convert time to CST.
 *
 * Date string needs to be ISO.
 *
 * @param date
 * @returns
 *
 */
export const getCSTDateAndTime = (date: Date | number) => {
  if (!date) {
    throw new Error(
      'Invalid argument: A valid date or timestamp must be provided.',
    )
  }

  const cstTime = new Date(date).toLocaleString('en-US', {
    timeZone: 'America/Chicago',
  })
  return cstTime.toLocaleString()
}

/**
 * getCSTDateAndTimeNow method return current CST date and time
 * @returns
 */
export const getCSTDateAndTimeNow = () =>
  new Date(getCSTDateAndTime(Date.now()))
